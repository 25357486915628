import {useEffect, useState} from 'react'
import {sysModel} from '@/store/models/Sys'

export function useApprovalConfigList() {
  const [approvalConfigList, setApprovalConfigList] = useState<ApprovalListVO[]>([])
  useEffect(() => {
    sysModel.approvalConfigList().then(({response: {data}}) => {
      setApprovalConfigList(data)
    })
  }, [])
  return approvalConfigList
}
