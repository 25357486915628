import React, {useCallback, useEffect, useState} from 'react'
import {Page} from '@/components/Page'
import {Button, Checkbox, Col, Form, Input, message, Modal, Popover, Row, Select, Space, Table} from 'antd'
import {Breadcrumb} from '@/components/Breadcrumb'
import commonStyles from '@/assets/styles/common.module.less'
import {sysModel} from '@/store/models/Sys'
import {useCodeArraySync, useCodeSync} from '@/store/models/Common'
import {getPaginationProps} from '@/common/biz/getPaginationProps'

export default function SysBlackList() {
  const blackListTypeArr = useCodeArraySync('system.blackList.type')
  const blackListTypeCode = useCodeSync('system.blackList.type')

  // ------列表相关-------
  const [form] = Form.useForm()
  const [blackTotal, setBlackTotal] = useState(0)
  const [blackList, setBlackList] = useState<SysBlackListVO[]>([])

  const queryBlackList = useCallback(() => {
    const formValuse = form.getFieldsValue(true)
    sysModel.sysSysBlackListList(formValuse).then(({response: {data, total}}) => {
      setBlackList(data)
      setBlackTotal(total)
    })
  }, [form])

  useEffect(() => {
    queryBlackList()
  }, [queryBlackList])

  const listLoading = sysModel.sysSysBlackListList.useLoading()
  const insertLoading = sysModel.sysSysBlackListInsert.useLoading()
  const updateLoading = sysModel.sysSysBlackListUpdate.useLoading()

  // ------新增、编辑相关-------
  const [eForm] = Form.useForm()
  const [upsert, setUpsert] = useState<'insert' | 'update'>(null)

  return (
    <Page breadcrumb={<Breadcrumb right={<Button onClick={() => setUpsert('insert')}>新增黑名单</Button>} />}>
      <div className={commonStyles.filterSection}>
        <Form
          form={form}
          initialValues={{pageNum: 1, pageSize: 10, pageable: true}}
          onFinish={queryBlackList}
          onReset={queryBlackList}
        >
          <Row gutter={20}>
            <Col span={8}>
              <Form.Item name={'mobile'} label={'手机号'}>
                <Input placeholder={'请输入'} allowClear />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name={'type'} label={'应用模块'}>
                <Select
                  placeholder={'请选择'}
                  options={blackListTypeArr.map(item => ({label: item.label, value: item.id}))}
                  mode={'multiple'}
                  allowClear
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name={'status'} label={'状态'}>
                <Select
                  placeholder={'请选择'}
                  options={[
                    {label: '启用', value: '1'},
                    {label: '禁用', value: '0'},
                  ]}
                  allowClear
                />
              </Form.Item>
            </Col>
          </Row>
          <Row justify={'end'}>
            <Space>
              <Button type={'primary'} htmlType={'submit'} onClick={() => form.setFieldsValue({pageNum: 1})}>
                查询
              </Button>
              <Button htmlType={'reset'}>重置</Button>
            </Space>
          </Row>
        </Form>
      </div>

      <div className={commonStyles.tableSection}>
        <Table
          rowKey={'blackListId'}
          dataSource={blackList}
          loading={listLoading}
          scroll={{x: 'max-content'}}
          columns={[
            {title: '姓名', dataIndex: 'name'},
            {title: '手机号', dataIndex: 'mobile'},
            {
              title: '应用模块',
              dataIndex: 'typeList',
              render: value => value?.map(item => blackListTypeCode[item.blackListType])?.join(','),
            },
            {
              title: '备注',
              dataIndex: 'remark',
              render: value => (
                <Popover content={<div style={{width: '500px'}}>{value}</div>}>
                  <div style={{overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', width: '300px'}}>
                    {value}
                  </div>
                </Popover>
              ),
            },
            {
              title: '状态',
              dataIndex: 'status',
              render: value => (value === '1' && '启用') || (value === '0' && '禁用'),
            },
            {
              title: '操作',
              fixed: 'right',
              width: '60px',
              dataIndex: 'blackListId',
              render: (value, record) => {
                const status = record.status
                return (
                  <Space>
                    <Button
                      type={'link'}
                      size={'small'}
                      style={{padding: 0}}
                      onClick={() => {
                        Modal.confirm({
                          title: '提示',
                          icon: null,
                          centered: true,
                          content: `是否${(status === '0' && '启用') || (status === '1' && '禁用')}？`,
                          onOk: async () => {
                            await sysModel.sysSysBlackListEnable({id: value, status: status === '0' ? 1 : 0})
                            message.success('操作成功！')
                            queryBlackList()
                          },
                        })
                      }}
                    >
                      {(status === '0' && '启用') || (status === '1' && '禁用')}
                    </Button>
                    <Button
                      type={'link'}
                      size={'small'}
                      style={{padding: 0}}
                      onClick={() => {
                        eForm.setFieldsValue({
                          name: record.name,
                          blackListId: record.blackListId,
                          mobile: record.mobile,
                          type: record.typeList?.map(item => item.blackListType),
                          remark: record.remark,
                        })
                        setUpsert('update')
                      }}
                    >
                      编辑
                    </Button>
                  </Space>
                )
              },
            },
          ]}
          pagination={getPaginationProps(form, blackTotal)}
        />
      </div>

      <Modal
        title={(upsert === 'insert' && '新增黑名单') || (upsert === 'update' && '编辑黑名单')}
        visible={['insert', 'update'].includes(upsert)}
        centered
        footer={null}
        onCancel={() => setUpsert(null)}
        afterClose={() => eForm.resetFields()}
      >
        <Form
          form={eForm}
          labelCol={{span: 4}}
          onReset={() => setUpsert(null)}
          onFinish={async () => {
            const formValues = eForm.getFieldsValue(true)
            // console.log('formValues', formValues)
            if (upsert === 'insert') {
              await sysModel.sysSysBlackListInsert(formValues)
            }
            if (upsert === 'update') {
              await sysModel.sysSysBlackListUpdate(formValues)
            }
            message.success('操作成功！')
            setUpsert(null)
            queryBlackList()
          }}
        >
          <Form.Item label={'姓名'} name={'name'}>
            <Input placeholder={'请输入'} />
          </Form.Item>
          <Form.Item
            label={'手机号'}
            name={'mobile'}
            rules={[
              {required: true, message: '请输入手机号'},
              {len: 11, message: '请输入正确的手机号'},
            ]}
          >
            <Input placeholder={'请输入'} />
          </Form.Item>
          <Form.Item label={'应用模块'} name={'type'} rules={[{required: true, message: '请选择应用模块'}]}>
            <Checkbox.Group>
              {blackListTypeArr.map(item => (
                <Checkbox key={item.id} value={item.id}>
                  {item.label}
                </Checkbox>
              ))}
            </Checkbox.Group>
          </Form.Item>
          <Form.Item label={'备注'} name={'remark'}>
            <Input.TextArea placeholder={'请输入'} maxLength={200} showCount />
          </Form.Item>
          <Row justify={'end'}>
            <Space>
              <Button htmlType={'reset'}>取消</Button>
              <Button type={'primary'} htmlType={'submit'} loading={insertLoading || updateLoading}>
                提交
              </Button>
            </Space>
          </Row>
        </Form>
      </Modal>
    </Page>
  )
}
