import {Model} from '@redux-model/web'
import {$api} from '@/store/service'

export interface LesseeModel {}

class Lessee extends Model<LesseeModel> {
  //租客列表
  queryLesseeList = $api.action((params: Partial<LesseeListDTO>) =>
    this.post<ApiResponse<LesseeListVO[]>>('/user/lessee/query-list').body({
      ...params,
    })
  )

  // 开启或禁用租客状态
  lesseeChangeStatus = $api.action((status: string, userId: string) =>
    this.post<ApiResponse>('/user/lessee/change-status').body({
      status,
      userId,
    })
  )

  // 创建、编辑租客
  lesseeUpsert = $api.action((args: LesseeInsertDTO) => this.post<ApiResponse>('/user/lessee/upsert').body({...args}))

  // 租客详情
  queryLesseeInfo = $api.action((lesseeId: string) =>
    this.post<ApiResponse<LesseeInfoVO>>('/user/lessee/query-info').body({lesseeId})
  )

  // 租客绑定列表
  userLesseePropertyList = $api.action((args: Partial<LesseePropertyQueryDTO>) =>
    this.post<ApiResponse<LesseePropertyVO[]>>('/user/lessee/property/list').body({
      pageNum: 1,
      pageSize: 10,
      pageable: true,
      ...args,
    })
  )

  // 租客房源绑定  |  userId,propertyCode
  userLesseePropertyBind = $api.action((args: {userId: string; propertyCode: string}) =>
    this.post('/user/lessee/property/bind').body(args)
  )

  // 租客绑定详情
  userLesseePropertyDetail = $api.action((args: {userId: string}) =>
    this.post<ApiResponse<LesseePropertyVO>>('/user/lessee/property/detail').body(args)
  )

  // 租客地址列表
  userLesseeAddressList = $api.action((args: Partial<LesseeAddressQueryDTO>) =>
    this.post<ApiResponse<LesseeAddressVO[]>>('/user/lessee/address/list' as const).body({
      pageNum: 1,
      pageSize: 10,
      pageable: true,
      ...args,
    })
  )

  // 租客地址客服确认
  userLesseeAddressConfirm = $api.action((args: LesseePropertyConfirmDTO) =>
    this.post<ApiResponse<any>>('/user/lessee/address/confirm' as const).body(args)
  )

  initReducer() {
    return {}
  }
}

export const lesseeModel = new Lessee()
