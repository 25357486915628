import {HttpService} from '@redux-model/web'
import {message} from 'antd'
import {getModels} from '@/utils/globalService'
import {baseUrl, bizUrl, isProd} from '@/common/config'
import sha1 from 'crypto-js/sha1'
import hex from 'crypto-js/enc-hex'
import {aes} from '@/utils/aes'
import {HEADER_CHANNEL, HEADER_PLATFORM} from '@/common/constants'
import {userModel} from '@/store/models/User'

/**
 * 传给后端 header 的基础字段
 */
export const createHeaders = () => ({
  'nyg8-auth': getModels()?.userModel.data?.token,
  'nyg8-channel': HEADER_CHANNEL.ADMIN,
  'nyg8-platform': HEADER_PLATFORM.WEB,
})

const whiteList = isProd ? ['刘长清'] : ['admin']

export const $api = new HttpService({
  baseUrl,
  headers: () => ({
    ...createHeaders(),
    'Content-Type': 'application/json;charset=utf-8',
  }),
  ...(process.env.NODE_ENV === 'development'
    ? {}
    : {
        beforeSend: action => {
          if (action.requestOptions?.headers?.['Content-Type'] === 'multipart/form-data') {
            return action
          }

          if (whiteList.includes(getModels()?.userModel?.data?.userInfo?.userName)) {
            return action
          }

          const body = JSON.stringify(action.body)
          const sign = hex.stringify(sha1(body))
          const timestamp = Date.now()
          const begin = Number(timestamp) % 9
          const key = hex.stringify(sha1(sign + timestamp)).substr(begin, 32)
          action.requestOptions.headers = {
            ...action.requestOptions.headers,
            'nyg8-sign': sign,
            'nyg8-timestamp': timestamp,
          }
          // @ts-ignore
          action.body = aes.encrypt(body, key)
        },
        transformSuccessData: (data, headers) => {
          const sign = headers['nyg8-sign']
          const timestamp = headers['nyg8-timestamp']
          const begin = Number(timestamp) % 9
          const key = hex.stringify(sha1(sign + timestamp)).substr(begin, 32)
          if (data?.code === 200 && sign && timestamp) {
            const body = aes.decrypt(data.data, key)
            try {
              data.data = JSON.parse(body)
            } catch {
              data.data = body
            }
          }
          return data
        },
      }),
  onRespondError(response, transform) {
    const code = response.data?.code
    if (code !== 200) {
      transform.message = response.data?.msg
    }
    if (code === 401) {
      userModel.clearUserInfo()
    }
  },
  onShowError(errorText, action: any) {
    if (!action.hideError) {
      // console.log(errorText)
      message.error(errorText)
    }
  },
  onShowSuccess() {
    //
  },
  isSuccess(response) {
    return response.data?.code === 200
  },
})

export const $biz = $api.clone({
  baseUrl: bizUrl,
})
