import React, {useEffect, useState} from 'react'
import commonStyles from '@/assets/styles/common.module.less'
import {omsModel} from '@/store/models/Oms'
import {Button, Checkbox, Col, PageHeader, Row, Space, Tabs} from 'antd'
import {Page} from '@/components/Page'
import {useAppHistory} from '@/common/hooks/useRouter'

interface PermissionConfig {
  permissionIdList: string[]
  confirm?: (ids: string[]) => void
}

function toDeepCheck(tree: TreeModel[], resList: string[] = []): string[] {
  for (let i = 0; i < tree.length; i++) {
    const item = tree[i]
    resList.push(item.value)
    if (item.children && item.children.length) {
      toDeepCheck(item.children, resList)
    }
  }
  return resList
}

export const PermissionConfig: React.FC<PermissionConfig> = ({permissionIdList, confirm}) => {
  const history = useAppHistory()
  const [permissionTree, setPermissionTree] = useState<TreeModel[]>([])
  const [checkedList, setCheckedList] = useState<string[]>(permissionIdList ?? [])
  useEffect(() => {
    omsModel.omsCompanyContractGetPermissionTree({}).then(({response: {data}}) => {
      setPermissionTree(data)
    })
  }, [])

  function isCheck(item: TreeModel): boolean {
    return checkedList.includes(item.value)
  }

  function parentCheck(item: TreeModel): boolean {
    const deepCheckList = toDeepCheck([item]).filter(item => item.length === 14)
    return deepCheckList.every(item => checkedList.includes(item))
  }

  function checkOnChange(checked: boolean, item: TreeModel) {
    const deepCheckList = toDeepCheck([item])
    setCheckedList(prevState =>
      checked ? [...prevState, ...deepCheckList] : prevState.filter(value => !deepCheckList.includes(value))
    )
  }

  function renderTree(tree: TreeModel[], level = 0): React.ReactNode {
    if (!tree) return null
    return tree.map(item => {
      if (!item.children) {
        return (
          <Checkbox
            key={item.value}
            checked={isCheck(item)}
            onChange={({target: {checked}}) => checkOnChange(checked, item)}
          >
            {item.label}
          </Checkbox>
        )
      } else {
        return (
          <React.Fragment key={item.value}>
            <Col span={24}>
              <Checkbox checked={parentCheck(item)} onChange={({target: {checked}}) => checkOnChange(checked, item)}>
                {item.label}
              </Checkbox>
            </Col>
            <Col span={24 - 1} offset={1} style={{marginTop: '12px', marginBottom: '12px'}}>
              {renderTree(item.children, level + 1)}
            </Col>
          </React.Fragment>
        )
      }
    })
  }

  return (
    <Page
      header={
        <PageHeader
          title={'返回'}
          ghost={false}
          onBack={() => history.goBack()}
          extra={
            <Button
              type={'primary'}
              onClick={() => {
                // console.log(checkedList, 'checkedList')
                confirm(checkedList)
              }}
            >
              确定
            </Button>
          }
        />
      }
    >
      <div className={commonStyles.tableSection}>
        <Tabs>
          {permissionTree.map(item => {
            return (
              <Tabs.TabPane
                tab={
                  <Space>
                    <Checkbox
                      checked={parentCheck(item)}
                      onChange={({target: {checked}}) => checkOnChange(checked, item)}
                    />
                    <span>{item.label}</span>
                  </Space>
                }
                tabKey={item.value}
                key={item.value}
              >
                {item.children?.length ? <Row>{renderTree(item.children, 0)}</Row> : <span>暂无配置权限</span>}
              </Tabs.TabPane>
            )
          })}
        </Tabs>
      </div>
    </Page>
  )
}
