import React, {useState, useEffect, useCallback} from 'react'
import {Form, Row, Col, Input, Button, Table, Space, Tabs} from 'antd'
import {useCodeSync} from '@/store/models/Common'
import {useHistory, Link} from 'react-router-dom'
import AreaCascader from '@/components/AreaCascader'
import './index.less'
import {Breadcrumb} from '@/components/Breadcrumb'
import commonStyles from '@/assets/styles/common.module.less'
import {Permission} from '@/components/Permission'
import {sysModel} from '@/store/models/Sys'
import {Page} from '@/components/Page'
import {ColumnsType} from 'antd/lib/table/interface'
import {getPaginationProps} from '@/common/biz/getPaginationProps'
import {useFocusEffect} from '@/common/hooks/useFocusEffect'

export default function CommunityDataList() {
  const history = useHistory()
  //待核查
  const [form] = Form.useForm()
  const [total, setTotal] = useState(0)
  const [dataSource, setDataSource] = useState<CommunityVO[]>()
  const [tabKey, setTabKey] = useState('1')
  // 已核查
  const [form2] = Form.useForm()
  const [total2, setTotal2] = useState(0)
  const [dataSource2, setDataSource2] = useState<CommunityVO[]>()
  // 无需核查
  const [form3] = Form.useForm()
  const [total3, setTotal3] = useState(0)
  const [dataSource3, setDataSource3] = useState<CommunityVO[]>()
  const loading = sysModel.communityList.useLoading()

  const communityType = useCodeSync('community.type')

  const search = useCallback(() => {
    const {district, ...rest} = form.getFieldsValue(true)
    const formValue = {
      ...rest,
      cityCode: district?.cityCode ?? '',
      districtCode: district?.districtCode ?? '',
      bizCircleCode: district?.bizCircleCode ?? '',
      checkStatus: '2',
    }

    sysModel.communityList(formValue).then(({response: {data, total}}) => {
      setDataSource(data)
      setTotal(total)
    })
  }, [form])

  const search2 = useCallback(() => {
    const {district, ...rest} = form2.getFieldsValue(true)
    const formValue = {
      ...rest,
      cityCode: district?.cityCode ?? '',
      districtCode: district?.districtCode ?? '',
      bizCircleCode: district?.bizCircleCode ?? '',
      checkStatus: '3',
    }

    sysModel.communityList(formValue).then(({response: {data, total}}) => {
      setDataSource2(data)
      setTotal2(total)
    })
  }, [form2])

  const search3 = useCallback(() => {
    const {district, ...rest} = form3.getFieldsValue(true)
    const formValue = {
      ...rest,
      cityCode: district?.cityCode ?? '',
      districtCode: district?.districtCode ?? '',
      bizCircleCode: district?.bizCircleCode ?? '',
      checkStatus: '1',
    }

    sysModel.communityList(formValue).then(({response: {data, total}}) => {
      setDataSource3(data)
      setTotal3(total)
    })
  }, [form3])

  useFocusEffect(() => {
    switch (tabKey) {
      case '1':
        return form.submit()
      case '2':
        return form2.submit()
      case '3':
        return form3.submit()
    }
  })

  useEffect(() => {
    search()
    search2()
    search3()
  }, [search, search2, search3])

  const columns: ColumnsType<CommunityVO> = [
    {title: '城市code', dataIndex: 'cityCode', key: 'cityCode'},
    {title: '城市名称', dataIndex: 'cityName', key: 'cityName'},
    {title: '区域code', dataIndex: 'districtCode', key: 'districtCode'},
    {title: '区域名称', dataIndex: 'districtName', key: 'districtName'},
    {title: '小区code', dataIndex: 'code', key: 'code'},
    {title: '名称描述', dataIndex: 'name', key: 'name'},
    {title: '地址', dataIndex: 'address', key: 'address'},
    {title: '纬度', dataIndex: 'lat', key: 'lat'},
    {title: '经度', dataIndex: 'lng', key: 'lng'},
    {
      title: '小区类型',
      dataIndex: 'type',
      key: 'type',
      render: text => {
        return <>{communityType[text]}</>
      },
    },
    {
      title: '操作',
      dataIndex: 'operation',
      fixed: 'right',
      width: '100px',
      render: (text, record, index) => {
        return (
          <Button
            type={'link'}
            size={'small'}
            onClick={() => {
              const location = {
                pathname: '/community/detail',
                state: {code: record.code},
              }
              history.push(location)
            }}
            style={{padding: 0}}
          >
            查看
          </Button>
        )
      },
    },
  ]

  return (
    <Page
      breadcrumb={
        <Breadcrumb
          right={
            tabKey === '3' && (
              <Permission code={'003037002'}>
                <Button>
                  <Link to={{pathname: '/community/detail'}}>新增</Link>
                </Button>
              </Permission>
            )
          }
        />
      }
    >
      <Tabs className={'tabs'} activeKey={tabKey} onChange={key => setTabKey(key)}>
        <Tabs.TabPane tab={'待核查'} key={'1'}>
          <Form form={form} onFinish={search} onReset={search} initialValues={{pageNum: 1, pageSize: 10}}>
            <div className={commonStyles.filterSection}>
              <Row gutter={12}>
                <Col span={14}>
                  <Form.Item name={'district'} label={'所在地区'}>
                    <AreaCascader type={['city', 'district', 'bizCircle']} value={{}} />
                  </Form.Item>
                </Col>
                <Col span={7}>
                  <Form.Item name={'name'} label={'小区名称'}>
                    <Input allowClear placeholder={'请输入小区名称'} />
                  </Form.Item>
                </Col>
                <Col span={7}>
                  <Form.Item name={'address'} label={'小区地址'}>
                    <Input allowClear placeholder={'小区地址'} />
                  </Form.Item>
                </Col>
                <Col span={7}>
                  <Form.Item name={'code'} label={'小区code'}>
                    <Input allowClear placeholder={'请输入小区code'} />
                  </Form.Item>
                </Col>
              </Row>
              <Row justify={'end'}>
                <Space>
                  <Button type={'primary'} htmlType={'submit'} onClick={() => form.setFieldsValue({pageNum: 1})}>
                    查询
                  </Button>
                  <Button htmlType={'reset'}>重置</Button>
                </Space>
              </Row>
            </div>

            <div className={commonStyles.filterSection}>
              <Table
                loading={loading}
                rowKey='code'
                dataSource={dataSource}
                columns={columns}
                pagination={getPaginationProps(form, total)}
              />
            </div>
          </Form>
        </Tabs.TabPane>
        <Tabs.TabPane tab={'已核查'} key={'2'}>
          <Form form={form2} onFinish={search2} onReset={search2} initialValues={{pageNum: 1, pageSize: 10}}>
            <div className={commonStyles.filterSection}>
              <Row gutter={12}>
                <Col span={14}>
                  <Form.Item name={'district'} label={'所在地区'}>
                    <AreaCascader type={['city', 'district', 'bizCircle']} value={{}} />
                  </Form.Item>
                </Col>
                <Col span={7}>
                  <Form.Item name={'name'} label={'小区名称'}>
                    <Input allowClear placeholder={'请输入小区名称'} />
                  </Form.Item>
                </Col>
                <Col span={7}>
                  <Form.Item name={'address'} label={'小区地址'}>
                    <Input allowClear placeholder={'小区地址'} />
                  </Form.Item>
                </Col>
                <Col span={7}>
                  <Form.Item name={'code'} label={'小区code'}>
                    <Input allowClear placeholder={'请输入小区code'} />
                  </Form.Item>
                </Col>
              </Row>
              <Row justify={'end'}>
                <Space>
                  <Button type={'primary'} htmlType={'submit'} onClick={() => form2.setFieldsValue({pageNum: 1})}>
                    查询
                  </Button>
                  <Button htmlType={'reset'}>重置</Button>
                </Space>
              </Row>
            </div>

            <div className={commonStyles.filterSection}>
              <Table
                loading={loading}
                rowKey='code'
                dataSource={dataSource2}
                columns={columns}
                pagination={getPaginationProps(form2, total2)}
              />
            </div>
          </Form>
        </Tabs.TabPane>
        <Tabs.TabPane tab={'无需核查'} key={'3'}>
          <Form form={form3} onFinish={search3} onReset={search3} initialValues={{pageNum: 1, pageSize: 10}}>
            <div className={commonStyles.filterSection}>
              <Row gutter={12}>
                <Col span={14}>
                  <Form.Item name={'district'} label={'所在地区'}>
                    <AreaCascader type={['city', 'district', 'bizCircle']} value={{}} />
                  </Form.Item>
                </Col>
                <Col span={7}>
                  <Form.Item name={'name'} label={'小区名称'}>
                    <Input allowClear placeholder={'请输入小区名称'} />
                  </Form.Item>
                </Col>
                <Col span={7}>
                  <Form.Item name={'address'} label={'小区地址'}>
                    <Input allowClear placeholder={'小区地址'} />
                  </Form.Item>
                </Col>
                <Col span={7}>
                  <Form.Item name={'code'} label={'小区code'}>
                    <Input allowClear placeholder={'请输入小区code'} />
                  </Form.Item>
                </Col>
              </Row>
              <Row justify={'end'}>
                <Space>
                  <Button type={'primary'} htmlType={'submit'} onClick={() => form3.setFieldsValue({pageNum: 1})}>
                    查询
                  </Button>
                  <Button htmlType={'reset'}>重置</Button>
                </Space>
              </Row>
            </div>

            <div className={commonStyles.filterSection}>
              <Table
                loading={loading}
                rowKey='code'
                dataSource={dataSource3}
                columns={columns}
                pagination={getPaginationProps(form3, total3)}
              />
            </div>
          </Form>
        </Tabs.TabPane>
      </Tabs>
    </Page>
  )
}
