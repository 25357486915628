import {Model} from '@redux-model/web'
import {$api} from '@/store/service'

export interface PurchaseModelProps {}

class PurchaseModel extends Model<PurchaseModelProps> {
  protected initReducer(): PurchaseModelProps {
    return {}
  }

  // 采购-列表
  stewardAppPurchaseList = $api.action((args: PurchaseListQueryDTO) =>
    this.post<ApiResponse<PurchaseVO[]>>('/steward/app/purchase/list').body(args)
  )
}

export const purchaseModel = new PurchaseModel()
