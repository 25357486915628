import React from 'react'
import {Image} from 'antd'
import {getImg} from '@/utils/util'

const CleanImagePreview = React.memo(({imgList}: {imgList: {imgId: string; imgUrl: string}[]}) => {
  return (
    <Image.PreviewGroup>
      {imgList?.map(item => {
        return (
          <div key={item.imgId} style={{margin: '0 10px 10px 0'}}>
            <Image
              width={88}
              height={88}
              alt={'图片'}
              src={getImg(item.imgUrl, {w: 88 * 2})}
              preview={{
                src: getImg(item.imgUrl, {q: 75}),
              }}
              style={{border: '1px solid rgba(0, 0, 0, 0.15)'}}
            />
          </div>
        )
      })}
    </Image.PreviewGroup>
  )
})

export default CleanImagePreview
