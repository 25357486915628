import React, {useEffect, useState} from 'react'
import commonStyles from '@/assets/styles/common.module.less'
import {Button, Col, DatePicker, Form, Input, Row, Select, Space, Table} from 'antd'
import styles from './index.module.less'
import {customerModel} from '@/store/models/Customer'
import {useCodeArraySync, useCodeSync} from '@/store/models/Common'
import moment from 'moment'
import {toFixed} from '@/utils/amount'

const {Option} = Select
const {RangePicker} = DatePicker
interface AccountDetailProps {
  userId: string
}

export default function AccountDetail(props: AccountDetailProps) {
  const [balance, setBalance] = useState('')
  const [list, setList] = useState<TradeVO[]>([])
  const [params, setParams] = useState({pageNum: 1, pageSize: 10} as TradeListQueryDTO)
  const [form] = Form.useForm()
  const tradeFlow = useCodeArraySync('trade.folw')
  const tradeType = useCodeSync('trade.folw')
  // 小区列表
  const [communityList, setCommunityList] = useState<CommunityVO[]>([])
  const [totals, setTotals] = useState(0)

  useEffect(() => {
    customerModel.customerTradeWalletBalance({userId: props.userId}).then(({response: {data}}) => {
      setBalance(toFixed(+data.allAmount / 100))
    })

    customerModel.customerCommunityList({userId: props.userId}).then(({response: {data}}) => {
      setCommunityList(data)
    })
  }, [props.userId])

  useEffect(() => {
    customerModel.customerTradeWalletList({userId: props.userId, ...params}).then(({response: {data, total}}) => {
      setList(data)
      setTotals(total)
    })
  }, [params, props.userId])

  return (
    <>
      <div className={commonStyles.filterSection} style={{paddingTop: 10, paddingBottom: 10}}>
        <Space direction={'vertical'} size={0}>
          <span>账户余额（元）</span>
          <span className={styles.h2}>{balance ?? '0'}</span>
        </Space>
      </div>

      <div className={commonStyles.tableSection}>
        <Form
          form={form}
          onFinish={() => {
            const {time, ...rest} = form.getFieldsValue(true)
            const [flowBeginDate, flowEndDate] = time
              ? time.map(item => moment(item).format('YYYY-MM-DD HH:mm:ss'))
              : []
            setParams(state => ({...state, ...rest, pageNum: 1, flowBeginDate, flowEndDate}))
          }}
          onReset={() => setParams({pageNum: 1, pageSize: 10} as TradeListQueryDTO)}
        >
          <Row>
            <Col span={8}>
              <Form.Item label={'房源'} name={'propertyTitle'}>
                <Input placeholder='请输入' allowClear />
              </Form.Item>
            </Col>
            <Col span={8} offset={2}>
              <Form.Item label={'收支类型'} name={'tradeFlow'}>
                <Select placeholder={'请选择'} allowClear>
                  {tradeFlow.map(item => (
                    <Option key={item.id} value={item.id}>
                      {item.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={'收支时间'} name={'time'}>
                <RangePicker showTime />
              </Form.Item>
            </Col>
            <Col span={8} offset={2}>
              <Form.Item label={'租客'} name={'lesseeCertName'}>
                <Input placeholder='请输入' allowClear />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={'小区/公寓名'} name={'communityCode'}>
                <Select placeholder={'请选择'} allowClear>
                  {communityList.map(item => (
                    <Option key={item.code} value={item.code}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8} offset={2}>
              <Form.Item label={'账单项目'} name={'tradeTypeName'}>
                <Input placeholder='请输入' allowClear />
              </Form.Item>
            </Col>
          </Row>
          <Row justify='end'>
            <Space>
              <Button type={'primary'} htmlType={'submit'}>
                查询
              </Button>
              <Button htmlType={'reset'}>重置</Button>
            </Space>
          </Row>
        </Form>
        <Table
          style={{marginTop: '30px'}}
          rowKey={'tradeId'}
          columns={[
            {title: '房源', dataIndex: 'propertyTitle', key: 'propertyTitle', render: text => text ?? '/'},
            {title: '收支时间', dataIndex: 'tradeTime', key: 'tradeTime'},
            {title: '收支类型', dataIndex: 'tradeFlow', key: 'tradeFlow', render: text => tradeType[text]},
            {title: '租客', dataIndex: 'lesseeCertName', key: 'lesseeCertName', render: text => text ?? '/'},
            {title: '账单项目', dataIndex: 'tradeTypeName', key: 'tradeTypeName'},
            {title: '收支金额（元）', dataIndex: 'tradeAmt', key: 'tradeAmt'},
          ]}
          scroll={{x: 'max-content'}}
          dataSource={list}
          pagination={{
            pageSize: params.pageSize,
            current: params.pageNum,
            total: totals,
            defaultCurrent: 1,
            defaultPageSize: 10,
            position: ['bottomCenter'],
            showSizeChanger: true,
            onChange: (pageNum, pageSize) => setParams(state => ({...state, pageNum, pageSize})),
          }}
        />
      </div>
    </>
  )
}
