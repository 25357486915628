import React, {useCallback, useEffect, useState} from 'react'
import {Breadcrumb} from '@/components/Breadcrumb'
import {Button, Col, Form, Input, Row, Select, Space, Table} from 'antd'
import commonStyles from '@/assets/styles/common.module.less'
import {useAppHistory} from '@/common/hooks/useRouter'
import {useCodeArraySync, useCodeSync} from '@/store/models/Common'
import {condoModel} from '@/store/models/Condo'
import {usePermission} from '@/components/Permission'
import {Page} from '@/components/Page'
import {getPaginationProps} from '@/common/biz/getPaginationProps'
import {useFocusEffect} from '@/common/hooks/useFocusEffect'

export default function ConcentrateProjectList() {
  const [form] = Form.useForm()
  const history = useAppHistory()
  const [list, setList] = useState<CondoProjectVO[]>([])
  const [total, setTotal] = useState(0)
  const [auth] = usePermission()
  //维修服务平台
  const repairSourceArr = useCodeArraySync('condo.project.repairSource')
  const repairSource = useCodeSync('condo.project.repairSource')

  const queryProjectList = useCallback(() => {
    const values = form.getFieldsValue(true)
    condoModel.condoProjectList(values).then(({response: {data, total}}) => {
      setList(data)
      setTotal(total)
    })
  }, [form])

  useFocusEffect(() => {
    form.submit()
  })

  useEffect(() => {
    form.submit()
  }, [form])

  return (
    <Page
      breadcrumb={
        <Breadcrumb
          right={
            auth('003073002') && (
              <Button
                type={'primary'}
                onClick={() => {
                  history.push({pathname: '/concentrate-pm/add'})
                }}
              >
                创建项目
              </Button>
            )
          }
        />
      }
    >
      <div className={commonStyles.filterSection}>
        <Form
          form={form}
          initialValues={{
            pageNum: 1,
            pageSize: 10,
            pageable: true,
            self: true, // 仅查询自己的项目
          }}
          onFinish={queryProjectList}
          onReset={queryProjectList}
        >
          <Row>
            <Col span={7}>
              <Form.Item label={'项目编号'} name={'projectCode'}>
                <Input placeholder='请输入' allowClear />
              </Form.Item>
            </Col>
            <Col span={7} offset={1}>
              <Form.Item label={'项目名称'} name={'projectName'}>
                <Input placeholder='请输入' allowClear />
              </Form.Item>
            </Col>
            <Col span={7} offset={1}>
              <Form.Item label={'项目地址'} name={'address'}>
                <Input placeholder='请输入' allowClear />
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item label={'客户名称'} name={'lessorName'}>
                <Input placeholder='请输入' allowClear />
              </Form.Item>
            </Col>
            <Col span={7} offset={1}>
              <Form.Item label={'公寓名称'} name={'condoName'}>
                <Input placeholder='请输入' allowClear />
              </Form.Item>
            </Col>
            <Col span={7} offset={1}>
              <Form.Item label={'维修服务平台'} name={'repairSource'}>
                <Select placeholder={'请选择'} allowClear>
                  {repairSourceArr.map(item => (
                    <Select.Option key={item.id} value={item.id}>
                      {item.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row justify={'end'}>
            <Space>
              <Button type={'primary'} htmlType={'submit'} onClick={() => form.setFieldsValue({pageNum: 1})}>
                查询
              </Button>
              <Button htmlType={'reset'}>重置</Button>
            </Space>
          </Row>
        </Form>
      </div>
      <div className={commonStyles.filterSection}>
        <Table
          rowKey={'condoProjectId'}
          columns={[
            {title: '项目名称', dataIndex: 'projectName', key: 'projectName'},
            {title: '项目编号', dataIndex: 'projectCode', key: 'projectCode'},
            {title: '创建时间', dataIndex: 'insertTime', key: 'insertTime'},
            {title: '项目地址', dataIndex: 'address', key: 'address'},
            {title: '客户名称', dataIndex: 'lessorName', key: 'lessorName'},
            {title: '公寓名称', dataIndex: 'condoName', key: 'condoName'},
            {title: '维修服务平台', dataIndex: 'repairSource', key: 'repairSource', render: text => repairSource[text]},
            {
              title: '操作',
              render: (_, record) => {
                return (
                  <Space size={0}>
                    {auth('001074001') && (
                      <Button
                        size={'small'}
                        type={'link'}
                        onClick={() => {
                          history.push({
                            pathname: '/concentrate-pm/detail',
                            state: {condoProjectId: record.condoProjectId},
                          })
                        }}
                      >
                        查看
                      </Button>
                    )}
                    {auth('002074002') && (
                      <Button
                        size={'small'}
                        type={'link'}
                        onClick={() => {
                          history.push({
                            pathname: '/concentrate-pm/add',
                            state: {condoProjectId: record.condoProjectId},
                          })
                        }}
                      >
                        编辑
                      </Button>
                    )}
                    {auth(['006073002', '001095001', '001096001', '001097001']) && (
                      <Button
                        size={'small'}
                        type={'link'}
                        onClick={() => {
                          history.push({
                            pathname: '/concentrate-pm/set',
                            state: {condoProjectId: record.condoProjectId},
                          })
                        }}
                      >
                        设置
                      </Button>
                    )}
                  </Space>
                )
              },
            },
          ]}
          dataSource={list}
          pagination={getPaginationProps(form, total)}
        />
      </div>
    </Page>
  )
}
