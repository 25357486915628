import React, {useCallback, useEffect, useState} from 'react'
import {Page} from '@/components/Page'
import {Breadcrumb} from '@/components/Breadcrumb'
import commonStyles from '@/assets/styles/common.module.less'
import {
  Button,
  Cascader,
  Col,
  DatePicker,
  Descriptions,
  Drawer,
  Form,
  Input,
  message,
  Modal,
  Row,
  Select,
  Space,
  Table,
  Typography,
} from 'antd'
import {getPaginationProps} from '@/common/biz/getPaginationProps'
import {Album, UploadButton} from '@/components/Album'
import DividerLine from '@/pages/property/component/DividerLine'
import {zzajModel} from '@/store/models/Zzaj'
import {CloseCircleTwoTone, PlusCircleTwoTone} from '@ant-design/icons'
import {mobileValid} from '@/utils/util'
import {stewardModel} from '@/store/models/Steward'
import moment from 'moment'
import {usePermission} from '@/components/Permission'
import {baseUrl} from '@/common/config'
import {onAlbumUploadEvent, onAlbumUploadProps} from '@/pages/service/utils/albumUpload'
import {userModel} from '@/store/models/User'

export default function AnJuPropertyListManage() {
  const userId = userModel.useData(data => data.userInfo?.userId)
  const [form] = Form.useForm()
  const [total, setTotal] = useState(0)
  const [list, setList] = useState<ZzajQaPropertyDetailVO[]>([])
  const loading = zzajModel.zzajQaPropertyList.useLoading()
  const [propertyDetail, setPropertyDetail] = useState({} as ZzajQaPropertyDetailVO)
  const [orderForm] = Form.useForm()
  const [orderMakeOpen, setOrderMakeOpen] = useState<boolean>(false)
  const [tags, setTags] = useState<string[]>([])
  const [addItemOpen, setAddItemOpen] = useState<boolean>(false)
  const [itemForm] = Form.useForm()
  const [companyList, setCompanyList] = useState<ZzajQaCompanyListVO[]>([])
  const [itemList, setItemList] = useState<ZzajQaItemListVO[]>([])
  const [auth] = usePermission()

  const [serviceTree, setServiceTree] = useState<StewardServiceVO[]>([])
  useEffect(() => {
    stewardModel.stewardServiceTree({}).then(({response: {data}}) => setServiceTree(data))
  }, [])

  const search = useCallback(() => {
    const values = form.getFieldsValue(true)
    zzajModel.zzajQaPropertyList(values).then(({response: {data, total}}) => {
      setList(data)
      setTotal(total)
    })
  }, [form])

  const searchTags = useCallback((serviceId: string) => {
    stewardModel.stewardAdminServiceDetail({serviceId}).then(({response: {data}}) => {
      setTags(data.tags.split(','))
    })
  }, [])

  useEffect(() => {
    search()

    zzajModel
      .zzajQaCompanyList({pageNum: 1, pageSize: 1, pageable: false, status: 1} as ZzajQaCompanyListDTO)
      .then(({response: {data}}) => {
        setCompanyList(data)
      })

    zzajModel
      .zzajQaItemList({pageNum: 1, pageSize: 1, pageable: false} as ZzajQaItemListDTO)
      .then(({response: {data}}) => {
        setItemList(data)
      })
  }, [search])

  const searchDetail = useCallback(async (propertyCode: string) => {
    const {
      response: {data},
    } = await zzajModel.zzajQaPropertyGet({propertyCode})
    setPropertyDetail(data)
  }, [])

  return (
    <Page breadcrumb={<Breadcrumb />}>
      <div className={commonStyles.filterSection}>
        <Form form={form} onFinish={search} onReset={search} initialValues={{pageNum: 1, pageSize: 10, pageable: true}}>
          <Row>
            <Col span={7}>
              <Form.Item label={'房源编号'} name={'propertyCode'}>
                <Input placeholder={'请输入'} allowClear />
              </Form.Item>
            </Col>
            <Col span={7} offset={1}>
              <Form.Item label={'所属公司'} name={'userName'}>
                <Input placeholder={'请输入'} allowClear />
              </Form.Item>
            </Col>
            <Col span={7} offset={1}>
              <Form.Item label={'项目'} name={'communityName'}>
                <Input placeholder={'请输入'} allowClear />
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item label={'房间号'} name={'houseNo'}>
                <Input placeholder={'请输入'} allowClear />
              </Form.Item>
            </Col>
          </Row>
          <Row justify={'end'}>
            <Space>
              <Button htmlType={'submit'} type={'primary'} onClick={() => form.setFieldsValue({pageNum: 1})}>
                查询
              </Button>
              <Button htmlType={'reset'}>重置</Button>
            </Space>
          </Row>
        </Form>
      </div>
      <div className={commonStyles.filterSection}>
        <Table
          rowKey={'propertyCode'}
          loading={loading}
          dataSource={list}
          columns={[
            {title: '房源编号', dataIndex: 'propertyCode'},
            {title: '所属公司', dataIndex: 'userName'},
            {title: '项目', dataIndex: 'projectName'},
            {title: '楼栋', dataIndex: 'buildingNo'},
            {title: '单元', dataIndex: 'unit'},
            {title: '楼层', dataIndex: 'buildingNoRank'},
            {title: '房间号', dataIndex: 'houseNo'},
            {
              title: '操作',
              render: (_, {propertyCode, propertyName}) => {
                return (
                  <Space>
                    {auth('001177002') && (
                      <Button
                        type={'link'}
                        style={{paddingLeft: 0, paddingRight: 0}}
                        onClick={async () => {
                          await searchDetail(propertyCode)
                        }}
                      >
                        查看
                      </Button>
                    )}
                    {auth('003177003') && (
                      <Button
                        type={'link'}
                        style={{paddingLeft: 0, paddingRight: 0}}
                        onClick={() => {
                          orderForm.setFieldsValue({propertyCode, propertyName})
                          setOrderMakeOpen(true)
                        }}
                      >
                        报修
                      </Button>
                    )}
                  </Space>
                )
              },
            },
          ]}
          pagination={getPaginationProps(form, total)}
        />
      </div>

      <Modal
        open={orderMakeOpen}
        title={'报修'}
        centered
        width={600}
        onCancel={() => setOrderMakeOpen(false)}
        afterClose={() => orderForm.resetFields()}
        destroyOnClose
        onOk={async () => {
          await orderForm.validateFields()
          const {orderTags, orderNotes, imgList, videoList, ...values} = orderForm.getFieldsValue(true)
          if (![orderTags, orderNotes].some(item => item)) return message.warning('故障现象和需求描述必填一项！')
          if (![imgList, videoList].some(item => item)) return message.warning('照片和视频必传一项！')
          Modal.confirm({
            title: '提示',
            content: '确认提交？',
            icon: null,
            centered: true,
            onOk: async () => {
              await zzajModel.zzajOpenOrderInsert({
                ...values,
                orderTags: orderTags?.join(','),
                orderNotes,
                orderImgUrlList: [imgList, videoList]
                  .flat()
                  .filter(item => item)
                  .map(item => item.imgUrl),
              } as StewardOpenOrderZzajMakeDTO)
              setOrderMakeOpen(false)
            },
          })
        }}
      >
        <Form form={orderForm} labelCol={{flex: '150px'}} wrapperCol={{span: 15}}>
          <Form.Item label={'服务房源'}>{orderForm.getFieldValue('propertyName')}</Form.Item>
          <Form.Item label={'租客手机号'} name={'lesseeMobile'} required rules={[{validator: mobileValid}]}>
            <Input placeholder={'请输入'} allowClear />
          </Form.Item>
          <Form.Item label={'服务联系手机号'} name={'contactMobile'} required rules={[{validator: mobileValid}]}>
            <Input placeholder={'请输入'} allowClear />
          </Form.Item>
          <Form.Item label={'服务项目'} rules={[{required: true, message: '请选择服务项目'}]} name={'_serviceId'}>
            <Cascader
              options={serviceTree}
              fieldNames={{label: 'name', value: 'id', children: 'stewardServiceList'}}
              onChange={value => {
                if (value?.length) {
                  const serviceId = value[2]
                  orderForm.setFieldsValue({serviceId, orderTags: undefined})
                  searchTags(serviceId)
                  return
                }
                orderForm.setFieldsValue({serviceId: undefined, orderTags: undefined})
                setTags([])
              }}
              placeholder={'请选择'}
            />
          </Form.Item>
          <Form.Item label={'故障现象'} name={'orderTags'}>
            <Select
              placeholder={'请选择'}
              mode={'multiple'}
              options={tags.map(item => ({label: item, value: item}))}
              allowClear
            />
          </Form.Item>
          <Form.Item label={'需求描述'} name={'orderNotes'}>
            <Input.TextArea rows={3} maxLength={500} showCount placeholder={'请输入'} allowClear />
          </Form.Item>
          <Form.Item
            label={'图片'}
            name={'imgList'}
            getValueFromEvent={onAlbumUploadEvent}
            getValueProps={onAlbumUploadProps}
          >
            <Album
              data={{userId}}
              limit={10}
              action={baseUrl + '/steward/admin/order/upload-file'}
              accept={'.jpg, .jpeg, .png'}
            >
              <UploadButton title={'上传照片'} />
            </Album>
          </Form.Item>
          <Form.Item
            label={'视频'}
            name={'videoList'}
            getValueFromEvent={onAlbumUploadEvent}
            getValueProps={onAlbumUploadProps}
            style={{marginBottom: 0}}
          >
            <Album
              limit={10}
              multiple={true}
              data={{userId}}
              action={baseUrl + '/steward/admin/order/upload-file'}
              accept={'.mp4, .mkv, .avi'}
            >
              <UploadButton title={'上传视频'} />
            </Album>
          </Form.Item>
          <Form.Item label={' '} colon={false}>
            <Typography.Text style={{color: 'rgba(0, 0, 0, 0.6)'}}>图片或者视频必传其中一项</Typography.Text>
          </Form.Item>
        </Form>
      </Modal>

      <Drawer
        open={!!propertyDetail.propertyCode}
        title={'房源详情'}
        width={800}
        onClose={() => {
          setPropertyDetail({} as ZzajQaPropertyDetailVO)
        }}
        destroyOnClose
        zIndex={600}
      >
        <DividerLine title={'基本信息'} bottom={20} />
        <Descriptions column={2}>
          <Descriptions.Item label={'房源编号'}>{propertyDetail.propertyCode}</Descriptions.Item>
          <Descriptions.Item label={'所属公司'}>{propertyDetail.userName}</Descriptions.Item>
          <Descriptions.Item label={'项目'}>{propertyDetail.projectName}</Descriptions.Item>
          <Descriptions.Item label={'楼栋'}>{propertyDetail.buildingNo}</Descriptions.Item>
          <Descriptions.Item label={'单元'}>{propertyDetail.unit}</Descriptions.Item>
          <Descriptions.Item label={'楼层'}>{propertyDetail.buildingNoRank}</Descriptions.Item>
          <Descriptions.Item label={'房间号'}>{propertyDetail.houseNo}</Descriptions.Item>
        </Descriptions>
        <DividerLine
          title={'质保项目'}
          right={
            auth('003177015') && (
              <Button
                type={'link'}
                onClick={() => {
                  itemForm.setFieldsValue({propertyCode: propertyDetail.propertyCode})
                  setAddItemOpen(true)
                }}
              >
                新增
              </Button>
            )
          }
          bottom={20}
        />
        <Table
          rowKey={'id'}
          dataSource={propertyDetail.propertyItemList}
          columns={[
            {title: '质保项目', dataIndex: 'itemName'},
            {title: '质保项目唯一编号', dataIndex: 'deviceCode'},
            {title: '质保单位', dataIndex: 'companyName'},
            {
              title: '质保起止时间',
              render: (_, {qaTimeBegin, qaTimeEnd}) =>
                `${moment(qaTimeBegin).format('YYYY-MM-DD')}至${moment(qaTimeEnd).format('YYYY-MM-DD')}`,
            },
            {
              title: '操作',
              render: (_, record) => {
                return (
                  <Space>
                    {auth('002177004') && (
                      <Button
                        type={'link'}
                        style={{paddingLeft: 0, paddingRight: 0}}
                        onClick={() => {
                          itemForm.setFieldsValue({
                            itemList: [{...record, _qaTime: [moment(record.qaTimeBegin), moment(record.qaTimeEnd)]}],
                            edit: true,
                            propertyCode: record.propertyCode,
                          })
                          setAddItemOpen(true)
                        }}
                      >
                        编辑
                      </Button>
                    )}
                    {auth('004177014') && (
                      <Button
                        type={'link'}
                        style={{paddingLeft: 0, paddingRight: 0}}
                        onClick={() => {
                          Modal.confirm({
                            title: '提示',
                            icon: null,
                            centered: true,
                            content: '确认删除？',
                            onOk: async () => {
                              await zzajModel.zzajQaPropertyItemDelete({
                                id: record.id,
                              })
                              await searchDetail(propertyDetail.propertyCode)
                              message.success('删除成功！')
                            },
                          })
                        }}
                      >
                        删除
                      </Button>
                    )}
                  </Space>
                )
              },
            },
          ]}
          pagination={false}
        />
      </Drawer>

      <Modal
        open={addItemOpen}
        onOk={async () => {
          await itemForm.validateFields()
          const {propertyCode, itemList, edit} = itemForm.getFieldsValue(true)
          const codeArr = itemList.map(item => item.deviceCode).filter(item => item)
          const newLength = new Set(codeArr).size
          if (codeArr.length && codeArr.length > newLength) return message.warning('质保项目唯一编码不能重复！')
          await zzajModel.zzajQaPropertyItemUpset({itemList: itemList.map(item => ({...item, propertyCode}))})
          searchDetail(propertyDetail.propertyCode)
          setAddItemOpen(false)
          message.success(edit ? '编辑成功' : '新增成功')
        }}
        afterClose={() => itemForm.resetFields()}
        destroyOnClose
        onCancel={() => setAddItemOpen(false)}
        centered
        title={itemForm.getFieldValue('edit') ? '编辑' : '新增'}
        width={950}
        zIndex={1000}
      >
        <Form form={itemForm} initialValues={{itemList: [{}]}}>
          <Form.List name={'itemList'}>
            {(fields, {add, remove}) => (
              <>
                {fields.map(({key, name, ...filed}, index) => (
                  <div key={key}>
                    <Space>
                      <Form.Item
                        name={[name, 'itemId']}
                        {...filed}
                        label={'质保项目'}
                        rules={[{required: true, message: '请选择质保项目'}]}
                      >
                        <Select
                          style={{width: 150}}
                          options={itemList}
                          fieldNames={{label: 'itemName', value: 'itemId'}}
                          placeholder={'请选择'}
                          showSearch
                          filterOption={(inputValue, option) => {
                            return option.itemName.indexOf(inputValue) > -1
                          }}
                        />
                      </Form.Item>
                      <Form.Item name={[name, 'deviceCode']} {...filed}>
                        <Input style={{width: 180}} placeholder={'请输入质保项目唯一编码'} />
                      </Form.Item>
                      <Form.Item
                        name={[name, 'companyId']}
                        {...filed}
                        rules={[{required: true, message: '请选择质保单位'}]}
                      >
                        <Select
                          style={{width: 150}}
                          options={companyList}
                          fieldNames={{label: 'companyName', value: 'companyId'}}
                          placeholder={'请选择质保单位'}
                          showSearch
                          filterOption={(inputValue, option) => {
                            return option.companyName.indexOf(inputValue) > -1
                          }}
                        />
                      </Form.Item>
                      <Form.Item
                        name={[name, '_qaTime']}
                        {...filed}
                        rules={[{required: true, message: '请选择质保起止'}]}
                      >
                        <DatePicker.RangePicker
                          onChange={(_, dateStrings) => {
                            const [qaTimeBegin, qaTimeEnd] = dateStrings ?? []
                            itemForm.setFields([
                              {
                                name: ['itemList', name, 'qaTimeBegin'],
                                value: qaTimeBegin ? qaTimeBegin + ' 00:00:00' : undefined,
                              },
                              {
                                name: ['itemList', name, 'qaTimeEnd'],
                                value: qaTimeEnd ? qaTimeEnd + ' 23:59:59' : undefined,
                              },
                            ])
                          }}
                        />
                      </Form.Item>
                      {!itemForm.getFieldValue('edit') && (
                        <>
                          {index === 0 && (
                            <Form.Item>
                              <PlusCircleTwoTone twoToneColor='#00B7AE' onClick={() => add()} />
                            </Form.Item>
                          )}
                          {fields.length > 1 && (
                            <Form.Item>
                              <CloseCircleTwoTone
                                twoToneColor='#A8A7AA'
                                onClick={() => {
                                  remove(name)
                                }}
                              />
                            </Form.Item>
                          )}
                        </>
                      )}
                    </Space>
                  </div>
                ))}
              </>
            )}
          </Form.List>
        </Form>
      </Modal>
    </Page>
  )
}
