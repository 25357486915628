import React, {useState} from 'react'
import styles from './index.module.less'
import {CloseCircleFilled} from '@ant-design/icons'
import {Input} from 'antd'

export interface SortableServiceProps {
  sortMode?: boolean
  value: string[]
  onChange: (value: string[]) => void
  emergency: string[]
  onChangeEmergency: (value: string[]) => void
}

export const SortableService: React.FC<SortableServiceProps> = ({
  value,
  onChange,
  children,
  sortMode,
  emergency,
  onChangeEmergency,
  ...rest
}) => {
  const [editIndex, setEditIndex] = useState(-1)

  return (
    <div className={styles.container}>
      {value.map((item, i) =>
        editIndex === i ? (
          <div key={i} className={styles.sortItemInput}>
            <Input
              autoFocus
              maxLength={6}
              defaultValue={item}
              onBlur={event => {
                if (event.target.value) {
                  onChange?.(value.map((val, index) => (index === i ? event.target.value : val)))
                  onChangeEmergency?.(emergency.map(val => (val === item ? event.target.value : val)))
                }
                setEditIndex(-1)
              }}
            />
          </div>
        ) : (
          <div
            key={i}
            draggable={sortMode}
            className={`${styles.item} ${sortMode && styles.sortItemTransform}`}
            onDragStart={event => {
              event.dataTransfer.setData('index', `${i}`)
              event.currentTarget.style.opacity = '0.3'
            }}
            onDragOver={event => {
              event.preventDefault()
              event.dataTransfer.dropEffect = 'copy'
            }}
            onDragEnd={event => {
              event.currentTarget.style.opacity = ''
            }}
            onDrop={event => {
              const moveIndex = +event.dataTransfer.getData('index')
              const current = i
              if (moveIndex !== current) {
                const newArray = value.reduce<string[]>((result, item, index) => {
                  if (index !== moveIndex) {
                    if (index === current) {
                      if (moveIndex > current) {
                        result.push(value[moveIndex], item)
                      } else {
                        result.push(item, value[moveIndex])
                      }
                    } else {
                      result.push(item)
                    }
                  }
                  return result
                }, [])
                onChange?.(newArray)
              }
            }}
          >
            <div
              onClick={() => !sortMode && setEditIndex(i)}
              className={`${styles.sortItemBase} ${styles.sortItem} ${!sortMode && styles.sortItemHover}`}
            >
              <span className={styles.sortItemText}>{item}</span>
              <span className={styles.sortItemEditText}>编辑</span>
            </div>
            <div
              className={`${styles.fastItem} ${emergency.includes(item) && styles.activeFast}`}
              onClick={() => {
                let newArray = []
                if (emergency.includes(item)) {
                  newArray = emergency.filter(_item => _item !== item)
                } else {
                  newArray = [...emergency, item]
                }
                onChangeEmergency?.(newArray)
              }}
            >
              <span className={`${styles.fastText} ${emergency.includes(item) && styles.activeText}`}>急</span>
            </div>
            <CloseCircleFilled
              className={styles.sortItemIcon}
              onClick={event => {
                event.stopPropagation()
                onChange?.(value.filter((_, index) => index !== i))
                onChangeEmergency?.(emergency.filter(value => value !== item))
              }}
            />
          </div>
        )
      )}
      {children}
    </div>
  )
}
