import React, {useEffect, useMemo, useState} from 'react'
import {Button, Col, Modal, Row} from 'antd'
import {useHistory, useLocation} from 'react-router'
import {propertyModel} from '@/store/models/Property'
import {getImg} from '@/utils/util'
import '../index.less'
import {commonModel} from '@/store/models/Common'
import commonStyles from '@/assets/styles/common.module.less'
import {Page} from '@/components/Page'
import BackButton from '@/components/BackButton'

export default function BillDetail() {
  const history = useHistory()
  const location = useLocation<any>()

  // 账单id
  const billId = location.state?.billId
  // 房源标题
  const propertyTitle = location.state?.propertyTitle
  // 账单状态
  const billStatusCode = useMemo(() => commonModel.getCodeSync('bill.status'), [])

  // 详情数据
  const [detailData, setDetailData] = useState({} as ContractBillVO)
  // 选中的图片
  const [selectImg, setSelectImg] = useState<{imgUrl: string; visible: boolean}>({imgUrl: '', visible: false})

  useEffect(
    function () {
      propertyModel.queryBillDetail({billId}).then(({response: {data}}) => {
        setDetailData(data)
      })
    },
    [billId]
  )

  // 账单周期
  function billPeriod(billBegin: string, billEnd: string) {
    return billBegin && billEnd ? [billBegin, billEnd].filter(item => item).join(' 至 ') : '无账期'
  }

  return (
    <Page>
      <BackButton />
      <div className={`${commonStyles.tableSection} pageDetail`}>
        <div className='table'>
          <Row className='table--rowHeader'>
            <Col>基础信息：</Col>
            <Col>（账单ID：{detailData.billId}）</Col>
          </Row>
          {detailData.billAmtOrigin ? (
            <Row className='table--rowNormal'>
              <Col span={6}>账单名：{detailData.billTypeName}</Col>
              <div className='vertical' />
              <Col span={6}>账单周期：{billPeriod(detailData.billBegin, detailData.billEnd)}</Col>
              <div className='vertical' />
              <Col span={6}>账单原金额：{detailData.billAmtOrigin}</Col>
              <div className='vertical' />
              <Col span={5}>修改后金额：{detailData.billAmtTotal}</Col>
            </Row>
          ) : (
            <Row className='table--rowNormal'>
              <Col span={8}>账单名：{detailData.billTypeName}</Col>
              <div className='vertical' />
              <Col span={8}>账单周期：{billPeriod(detailData.billBegin, detailData.billEnd)}</Col>
              <div className='vertical' />
              <Col span={7}>账单金额：{detailData.billAmtTotal}</Col>
            </Row>
          )}
          {!!detailData.billAmtOrigin && (
            <Row className='table--rowNormal'>修改原因：{detailData.billRemarkChange}</Row>
          )}
          <Row className='table--rowNormal'>
            <Col span={8}>账单状态：{billStatusCode[detailData.billStatus]}</Col>
            <div className='vertical' />
            <Col span={8}>应收款日：{detailData.billDate}</Col>
            <div className='vertical' />
            <Col span={7}>账单起止日：{billPeriod(detailData.billBegin, detailData.billEnd)}</Col>
          </Row>
          <Row className='table--rowNormal'>房源地址：{propertyTitle}</Row>
          <Row className='table--rowNormal'>
            <Col span={8}>承租人：{detailData.lesseeCertName}</Col>
            <div className='vertical' />
            <Col span={8}>承租人电话：{detailData.lesseeMobile}</Col>
            <div className='vertical' />
            <Col span={7}>
              坏账冲抵：
              {detailData.billDetailList
                ?.filter(item => item.billPayWayCode === '06')
                .reduce((sum, item) => sum + item.billPayAmt, 0)}
            </Col>
          </Row>
          {detailData.billDetailList?.map(item => {
            return (
              <div key={item.billDetailId}>
                <Row className='table--rowHeader'>收款明细</Row>
                <Row className='table--rowNormal'>
                  <Col flex={1}>收款方式：{item.billPayWayName}</Col>
                  <div className='vertical' />
                  <Col flex={1}>收款金额：{item.billPayAmt}</Col>
                  <div className='vertical' />
                  <Col flex={1}>收款时间：{item.billPayTime}</Col>
                  <div className='vertical' />
                  <Col flex={1}>操作人：{item.userName}</Col>
                </Row>
                <Row className='table--rowNormal'>收款备注：{detailData.billRemark}</Row>
                <Row className='table--rowImg'>
                  支付凭证：
                  {item.imgList?.map(item => {
                    return (
                      <div key={item.imgId} className='table--img'>
                        <img
                          src={getImg(item.path)}
                          width={88}
                          height={88}
                          alt={'支付凭证'}
                          onClick={() => setSelectImg({imgUrl: item.path, visible: true})}
                        />
                      </div>
                    )
                  })}
                </Row>
              </div>
            )
          })}

          {/** 账单备注 */}
          <Row className='table--rowHeader'>账单备注</Row>
          <Row className='table--rowNormal'>账单备注：{detailData.billRemark}</Row>
          <Row className='table--rowImg'>
            图片备注：
            {detailData.imgList?.map(item => {
              return (
                <div key={item.imgId} className='table--img'>
                  <img
                    src={getImg(item.path)}
                    width={88}
                    height={88}
                    alt={'图片备注'}
                    onClick={() => setSelectImg({imgUrl: item.path, visible: true})}
                  />
                </div>
              )
            })}
          </Row>
        </div>
        <div className='backView'>
          <Button type={'primary'} className='backBtn' onClick={() => history.goBack()}>
            返回
          </Button>
        </div>
      </div>

      {/** 预览图片 */}
      <Modal
        visible={selectImg.visible}
        title={'图片预览'}
        footer={null}
        onCancel={() => setSelectImg(state => ({...state, visible: false}))}
      >
        <img src={getImg(selectImg.imgUrl)} alt={'图片预览'} width='100%' />
      </Modal>
    </Page>
  )
}
