import React, {useCallback, useEffect, useRef, useState} from 'react'
import {Page} from '@/components/Page'
import Map from 'react-bmapgl/dist/Map/Map'
import MapApiLoaderHOC from 'react-bmapgl/dist/Map/MapApiLoaderHOC'
import {ak} from '@/common/config'
import {MapvglLayer, MapvglView} from 'react-bmapgl/dist'
import {propertyModel} from '@/store/models/Property'
import styleJson from './custom_map_config.json'
import {storageModel} from '@/store/models/Storage'
import warehouse from '@/assets/warehouse.png'
import CustomOverlay from 'react-bmapgl/dist/Overlay/CustomOverlay'
import {Button, message, Upload} from 'antd'
import {Breadcrumb} from '@/components/Breadcrumb'
import * as XLSX from 'xlsx'

function MapPropertyLayer() {
  const mapRef = useRef(null)
  const [communityMapList, setCommunityMapList] = useState<PropertyMapCommunityVO[]>([])
  const [warehouseList, setWarehouseList] = useState<StewardWarehouseListItemVO[]>([])
  const [uploading, setUploading] = useState(false)
  const [importData, setImportData] = useState([])
  const [dataIndex, setDataIndex] = useState<number>(-1)

  const search = useCallback((ne: Point, sw: Point) => {
    propertyModel
      .propertyCommunityMap({lat1: ne.lat, lng1: ne.lng, lat2: sw.lat, lng2: sw.lng})
      .then(({response: {data}}) => setCommunityMapList(data))
  }, [])

  useEffect(() => {
    mapRef.current?.map?.addEventListener('moveend', () => {
      const {ne, sw} = mapRef.current?.map?.getBounds()
      search(ne, sw)
    })

    mapRef.current?.map?.addEventListener('zoomend', () => {
      const {ne, sw} = mapRef.current?.map?.getBounds()
      search(ne, sw)
    })
  }, [])

  useEffect(() => {
    const timer = setTimeout(() => {
      const {ne, sw} = mapRef.current?.map?.getBounds?.() ?? {}
      search(ne, sw)
    }, 1000)

    storageModel
      .stewardWarehouseList({pageable: false, status: '1', pageNum: 1, pageSize: 1})
      .then(({response: {data}}) => {
        setWarehouseList(data)
      })

    return () => clearTimeout(timer)
  }, [search])

  return (
    <Page
      style={{height: 'calc(100vh - 102px)'}}
      breadcrumb={
        <Breadcrumb
          right={
            <Upload
              accept={'.xlsx, .xls'}
              multiple={false}
              showUploadList={false}
              customRequest={options => {
                const {name} = options.file as any
                if (
                  !['.xlsx', '.xls'].includes(name && name.substring(name.lastIndexOf('.'), name.length).toLowerCase())
                ) {
                  message.warning('请上传正确格式Excel')
                  return false
                }
                setUploading(true)

                const fileRender = new FileReader()
                fileRender.onload = event => {
                  try {
                    const {result} = event.target
                    const workbook = XLSX.read(result, {type: 'binary'})
                    let data = []
                    for (const sheet in workbook.Sheets) {
                      if (workbook.Sheets?.hasOwnProperty(sheet)) {
                        data = data.concat(XLSX.utils.sheet_to_json(workbook.Sheets[sheet]))
                      }
                    }
                    message.success('导入成功！')
                    if (data.filter(item => item.lat && item.lng).length) {
                      setImportData(data)
                    }
                    setUploading(false)
                  } catch (e) {
                    message.warning('文件类型不正确')
                    setUploading(false)
                    return false
                  }
                }

                fileRender.readAsBinaryString(options.file as any)
              }}
            >
              <Button type={'primary'} loading={uploading}>
                导入
              </Button>
            </Upload>
          }
        />
      }
    >
      <Map
        ref={mapRef}
        center={'上海'}
        zoom={10}
        style={{height: '100%'}}
        enableScrollWheelZoom
        enableDoubleClickZoom
        mapStyleV2={{styleJson: styleJson as any}}
      >
        <MapvglView map={null}>
          <MapvglLayer
            type={'HeatmapLayer'}
            options={{
              size: 1000,
              max: 200,
              unit: 'm',
              height: 0,
              gradient: {
                0.25: 'rgba(0, 0, 255, 1)',
                0.5: 'rgba(0, 255, 0, 1)',
                0.75: 'rgba(255, 255, 0, 1)',
                1: 'rgba(255, 0, 0, 1)',
              },
            }}
            map={null}
            view={null}
            data={communityMapList.map(item => ({
              geometry: {type: 'Point', coordinates: [item.lng, item.lat]},
              properties: {count: item.num},
            }))}
          />
          {/* 导入的数据 */}
          <MapvglLayer
            type={'HeatmapLayer'}
            options={{
              size: 1000,
              max: 200,
              unit: 'm',
              height: 0,
              gradient: {
                0.25: 'rgba(0, 0, 255, 1)',
                0.5: 'rgba(0, 255, 0, 1)',
                0.75: 'rgba(255, 255, 0, 1)',
                1: 'rgba(255, 0, 0, 1)',
              },
            }}
            map={null}
            view={null}
            data={importData.map(item => ({
              geometry: {type: 'Point', coordinates: [item.lng, item.lat]},
              properties: {count: item.num || 0},
            }))}
          />

          <MapvglLayer
            type={'IconLayer'}
            options={{
              width: 20,
              height: 32,
              icon: warehouse,
              enablePicked: true,
              onClick: e => {
                setDataIndex(e?.dataIndex)
              },
            }}
            map={null}
            view={null}
            data={warehouseList.map(item => ({
              geometry: {type: 'Point', coordinates: [item.lng, item.lat]},
            }))}
          />
        </MapvglView>

        {/* 站点数据 */}
        {/*{warehouseList.map(value => {*/}
        {/*  return (*/}
        {/*    <Marker*/}
        {/*      key={value.warehouseId}*/}
        {/*      position={{lng: value.lng, lat: value.lat} as any}*/}
        {/*      icon={new BMapGL.Icon(warehouse, new BMapGL.Size(20, 40))}*/}
        {/*      map={null}*/}
        {/*      onMouseover={() => setLabel(value)}*/}
        {/*      onMouseout={() => {*/}
        {/*        if (label?.warehouseId === value.warehouseId) {*/}
        {/*          setLabel(null)*/}
        {/*        }*/}
        {/*      }}*/}
        {/*      isTop={true}*/}
        {/*    />*/}
        {/*  )*/}
        {/*})}*/}

        {dataIndex > -1 &&
          warehouseList
            .filter((_, index) => dataIndex === index)
            .map(item => (
              <CustomOverlay
                position={{lng: item.lng, lat: item.lat} as any}
                map={null}
                key={item.warehouseId}
                offset={{width: 0, height: -30 / 2 - 2} as any}
              >
                <div
                  style={{
                    // transform: 'translate(calc(-50% + 8px), 0%)',
                    whiteSpace: 'nowrap',
                    backgroundColor: '#fff',
                    padding: '10px',
                    borderRadius: '4px',
                  }}
                >
                  <span style={{color: '#00B7AE'}}>{item.name}</span>
                </div>
              </CustomOverlay>
            ))}
      </Map>
    </Page>
  )
}

export default MapApiLoaderHOC({ak})(MapPropertyLayer)
