import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import MapApiLoaderHOC from 'react-bmapgl/dist/Map/MapApiLoaderHOC'
import {ak} from '@/common/config'
import commonStyles from '@/assets/styles/common.module.less'
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Descriptions,
  Drawer,
  Form,
  message,
  Row,
  Select,
  Space,
  Table,
  Tabs,
  Typography,
} from 'antd'
import moment from 'moment/moment'
import Map from 'react-bmapgl/dist/Map/Map'
import Polyline from 'react-bmapgl/dist/Overlay/Polyline'
import CustomOverlay from 'react-bmapgl/dist/Overlay/CustomOverlay'
import DividerLine from '@/pages/property/component/DividerLine'
import {stewardModel} from '@/store/models/Steward'
import {useAppHistory} from '@/common/hooks/useRouter'
import {useCodeSync} from '@/store/models/Common'

interface TrackListProps {
  lat: number
  lng: number
  appoint: StewardOrderTrackJobVO[]
  finished: StewardOrderTrackJobVO[]
}

function MapServiceTrack() {
  const history = useAppHistory()
  const [form] = Form.useForm()
  const [list, setList] = useState<StewardOrderTrackJobVO[]>([])
  const mapRef = useRef(null)
  const [open, setOpen] = useState(false)
  const [appointPath, setAppointPath] = useState<BMapGL.Point[]>([])
  const [finishedPath, setFinishedPath] = useState<BMapGL.Point[]>([])
  const [stewardList, setStewardList] = useState<StewardUserVO[]>([])
  const orderStatus = useCodeSync('steward.orderStatus')

  const renderTrackList: TrackListProps[] = useMemo(() => {
    const arr: TrackListProps[] = []
    list.forEach(item => {
      const findItem = arr.find(_item => item.propertyLat === _item.lat && item.propertyLng === _item.lng)
      if (findItem) {
        if (item.trackType === '1') {
          findItem.appoint.push(item)
        } else {
          findItem.finished.push(item)
        }
        return
      }

      return arr.push({
        lat: item.propertyLat,
        lng: item.propertyLng,
        appoint: item.trackType === '1' ? [item] : [],
        finished: item.trackType === '2' ? [item] : [],
      })
    })
    return arr
  }, [list])

  const search = useCallback(() => {
    const values = form.getFieldsValue(true)
    stewardModel.stewardAdminOrderTrackList(values).then(({response: {data}}) => {
      setList(data)
      setAppointPath(
        data.filter(item => item.trackType === '1').map(item => new BMapGL.Point(item.propertyLng, item.propertyLat))
      )
      setFinishedPath(
        data.filter(item => item.trackType === '2').map(item => new BMapGL.Point(item.propertyLng, item.propertyLat))
      )
      mapRef.current?.map?.setViewport?.(data.map(item => new BMapGL.Point(item.propertyLng, item.propertyLat)))
    })
  }, [form])

  useEffect(() => {
    stewardModel.stewardUserList({pageNum: 1, pageSize: 10, pageable: false}).then(({response: {data}}) => {
      setStewardList(data)
    })
  }, [])

  return (
    <div style={{height: '100%', width: '100%', display: 'flex', flexDirection: 'column', position: 'relative'}}>
      <div className={commonStyles.filterSection}>
        <Form form={form} onFinish={search} initialValues={{pageable: false}}>
          <Row gutter={[80, 0]}>
            <Col span={8}>
              <Form.Item name={'serverId'} label={'服务人员'} rules={[{required: true, message: '请选择服务人员'}]}>
                <Select
                  placeholder={'请输入查询服务人员'}
                  allowClear
                  showSearch
                  filterOption={(inputValue, option) => {
                    return option?.children?.indexOf(inputValue as any) > -1
                  }}
                >
                  {stewardList.map(item => (
                    <Select.Option key={item.userId} value={item.userId}>
                      {`${item.name}(${item.mobile})`}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col>
              <Form.Item
                label={'日期'}
                name={'trackDate'}
                getValueFromEvent={(_, dateString) => dateString}
                getValueProps={value => ({value: value ? moment(value) : value})}
                rules={[{required: true, message: '请选择日期'}]}
              >
                <DatePicker placeholder={'请选择'} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name={'trackTypeList'}>
                <Checkbox.Group>
                  <Checkbox value={'1'}>最新约定上门</Checkbox>
                  <Checkbox value={'2'}>完成</Checkbox>
                </Checkbox.Group>
              </Form.Item>
            </Col>
          </Row>
          <Row justify={'end'}>
            <Space>
              <Button type={'primary'} htmlType={'submit'}>
                查询
              </Button>
            </Space>
          </Row>
        </Form>
      </div>

      <div style={{flex: 1, position: 'relative'}}>
        <Map ref={mapRef} enableScrollWheelZoom style={{height: '100%'}} center={'上海市'} zoom={12}>
          {appointPath.length > 1 && (
            <Polyline path={appointPath} strokeColor={'#00B7AE'} map={null} strokeWeight={4} />
          )}

          {finishedPath.length > 1 && (
            <Polyline path={finishedPath} strokeColor={'#FF961D'} map={null} strokeWeight={4} />
          )}

          {renderTrackList.map(item => {
            return (
              <React.Fragment key={item.lng + item.lng}>
                <CustomOverlay
                  offset={new BMapGL.Size(0, 8)}
                  position={new BMapGL.Point(item.lng, item.lat)}
                  map={null}
                >
                  <div
                    style={{
                      transform: 'translate(calc(-50% + 8px), 0%)',
                    }}
                  >
                    <div style={{boxShadow: '4px 0px 8px 0px rgba(190, 203, 209, 0.5)'}}>
                      {!!item.appoint?.length && (
                        <div style={{height: 30, display: 'flex', flexDirection: 'row', backgroundColor: '#fff'}}>
                          <div
                            style={{
                              height: '100%',
                              width: 30,
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              backgroundColor: '#00B7AE',
                            }}
                          >
                            <span style={{color: '#fff'}}>约</span>
                          </div>
                          <div style={{height: '100%', padding: '3px 12px'}}>
                            <Space>
                              {item.appoint
                                .filter(item => item.trackType === '1')
                                .map(item => (
                                  <div
                                    key={item.trackId}
                                    style={{
                                      height: 24,
                                      width: 24,
                                      borderRadius: 12,
                                      border: '1px solid #D9D9D9',
                                      display: 'flex',
                                      justifyContent: 'center',
                                    }}
                                  >
                                    <span>{item.rank}</span>
                                  </div>
                                ))}
                            </Space>
                          </div>
                        </div>
                      )}
                      {!!item.finished?.length && (
                        <div style={{height: 30, display: 'flex', flexDirection: 'row', backgroundColor: '#fff'}}>
                          <div
                            style={{
                              width: 30,
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              backgroundColor: '#FF961D',
                            }}
                          >
                            <span style={{color: '#fff'}}>完</span>
                          </div>
                          <div style={{padding: '3px 12px'}}>
                            <Space>
                              {item.finished
                                .filter(item => item.trackType === '2')
                                .map(item => (
                                  <div
                                    key={item.trackId}
                                    style={{
                                      height: 24,
                                      width: 24,
                                      borderRadius: 12,
                                      border: '1px solid #D9D9D9',
                                      display: 'flex',
                                      justifyContent: 'center',
                                    }}
                                  >
                                    <span>{item.rank}</span>
                                  </div>
                                ))}
                            </Space>
                          </div>
                        </div>
                      )}
                    </div>

                    <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                      <div
                        style={{
                          backgroundColor: '#00B7AE',
                          border: '2px solid #FFFFFF',
                          width: 16,
                          height: 16,
                          borderRadius: 8,
                          marginTop: 8,
                        }}
                      />
                    </div>
                  </div>
                </CustomOverlay>
              </React.Fragment>
            )
          })}
        </Map>
        <div style={{position: 'absolute', zIndex: 100, top: 20, right: 20}}>
          <Button
            type={'primary'}
            onClick={() => {
              if (!list.length) return message.warning('暂无轨迹信息！')
              setOpen(true)
            }}
          >
            查看详情
          </Button>
        </div>
      </div>

      <Drawer width={700} title={'服务单详情'} open={open} onClose={() => setOpen(false)}>
        <DividerLine title={'基本信息'} bottom={20} />
        <Descriptions column={2}>
          <Descriptions.Item label={'日期'}>{moment(list[0]?.trackDate).format('YYYY-MM-DD')}</Descriptions.Item>
          <Descriptions.Item label={'服务人员'}>{list[0]?.serverName}</Descriptions.Item>
        </Descriptions>
        <DividerLine title={'详细信息'} bottom={10} top={10} />
        <Tabs>
          <Tabs.TabPane tab={'最新约定上门'} key={'1'}>
            <Table
              rowKey={'trackId'}
              columns={[
                {title: '编号', dataIndex: 'rank'},
                {
                  title: 'ID',
                  dataIndex: 'orderId',
                  render: (value, _) => {
                    return (
                      <Typography.Text
                        underline
                        onClick={() => {
                          history.push({pathname: '/steward/detail', state: {orderId: value}})
                          setOpen(false)
                        }}
                      >
                        {value}
                      </Typography.Text>
                    )
                  },
                },
                {title: '服务地址', dataIndex: 'propertyAddr'},
                {title: '下单时间', dataIndex: 'timeMake'},
                {title: '最新约定上门时间', dataIndex: 'timeAppoint'},
                {
                  title: '状态',
                  dataIndex: 'orderStatus',
                  render: (value, _) => orderStatus[value],
                  width: 100,
                  fixed: 'right',
                },
              ]}
              scroll={{x: 'max-content'}}
              dataSource={list.filter(item => item.trackType === '1').reverse()}
              pagination={false}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab={'实际完成'} key={'2'}>
            <Table
              rowKey={'trackId'}
              columns={[
                {title: '编号', dataIndex: 'rank'},
                {
                  title: 'ID',
                  dataIndex: 'orderId',
                  render: (value, _) => {
                    return (
                      <Typography.Text
                        underline
                        onClick={() => {
                          history.push({pathname: '/steward/detail', state: {orderId: value}})
                          setOpen(false)
                        }}
                      >
                        {value}
                      </Typography.Text>
                    )
                  },
                },
                {title: '服务地址', dataIndex: 'propertyAddr'},
                {title: '下单时间', dataIndex: 'timeMake'},
                {title: '最新约定上门时间', dataIndex: 'timeAppoint'},
                {title: '完成时间', dataIndex: 'timeFinished'},
              ]}
              scroll={{x: 'max-content'}}
              dataSource={list.filter(item => item.trackType === '2').reverse()}
              pagination={false}
            />
          </Tabs.TabPane>
        </Tabs>
      </Drawer>
    </div>
  )
}

export default MapApiLoaderHOC({ak})(MapServiceTrack)
