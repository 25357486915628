import {Model} from '@redux-model/web'
import {$api} from '@/store/service'

interface CleanModel {}

class Clean extends Model<CleanModel> {
  protected initReducer(): CleanModel {
    return {}
  }

  // 列表
  cleanOrderList = $api.action((args: CleanOrderQueryDTO) =>
    this.post<ApiResponse<CleanOrderVO[]>>('/clean-order/list').body(args)
  )

  // 详情  |  object:{orderId:服务单ID}
  cleanOrderGet = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<CleanOrderVO>>('/clean-order/get').body(args)
  )

  // 取消
  cleanOrderCancel = $api.action((args: CleanOrderCancelDTO) =>
    this.post<ApiResponse<Record<string, any>>>('/clean-order/cancel').body(args)
  )

  // 保洁确认
  cleanOrderCheck = $api.action((args: CleanOrderCheckDTO) =>
    this.post<ApiResponse<Record<string, any>>>('/clean-order/check').body(args)
  )

  // 派单
  cleanOrderAssign = $api.action((args: CleanOrderAssignDTO) =>
    this.post<ApiResponse<Record<string, any>>>('/clean-order/assign').body(args)
  )

  // 转单
  cleanOrderAssignAgain = $api.action((args: CleanOrderAssignDTO) =>
    this.post<ApiResponse<Record<string, any>>>('/clean-order/assign-again').body(args)
  )

  // 统计保洁员未完成的服务单数  |  object:{serverId:服务单ID}
  cleanOrderCountUnfinished = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<Record<string, any>>>('/clean-order/count-unfinished').body(args)
  )

  // 创建线下质检流程  |  object:{orderId:服务单ID}
  cleanOrderCreateOfflineQa = $api.action((args: CleanOrderQaOfflineCreateDTO) =>
    this.post<ApiResponse<Record<string, any>>>('/clean-order/create-offline-qa').body(args)
  )

  // 统计现场质检未完成的服务单数  |  object:{qaOfflineServerId:现场质检人员ID}
  cleanOrderCountOfflineUnfinished = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<number>>('/clean-order/count-offline-unfinished').body(args)
  )

  // 保存图片/视频(服务前,服务后)
  cleanOrderSaveFile = $api.action((args: CleanOrderAttachSaveDTO) =>
    this.post<ApiResponse<Record<string, any>>>('/clean-order/save-file').body(args)
  )

  // 计算垃圾清运提成  |  object:{garbagePkgNum:垃圾清运(包)}
  cleanOrderCalculateGarbageCommission = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<number>>('/clean-order/calculate-garbage-commission').body(args)
  )

  // 计算收费服务提成
  cleanOrderCalculateServiceCommission = $api.action((args: CleanOrderCalculateCommission4ServiceDTO) =>
    this.post<ApiResponse<number>>('/clean-order/calculate-service-commission').body(args)
  )

  // 提交处理结果
  cleanOrderFinish = $api.action((args: CleanOrderFinishDTO) =>
    this.post<ApiResponse<Record<string, any>>>('/clean-order/finish').body(args)
  )

  // 创建返工单  |  object:{orderId:服务单ID}
  cleanOrderRework = $api.action((args: CleanOrderReworkDTO) =>
    this.post<ApiResponse<string>>('/clean-order/rework').body(args)
  )

  // 确认扣款
  cleanOrderSubmitAmtCheck = $api.action((args: CleanOrderAmtCheckSubmitDTO) =>
    this.post<ApiResponse<Record<string, any>>>('/clean-order/submit-amt-check').body(args)
  )

  // 提交在线质检结果
  cleanOrderSubmitOnlineQa = $api.action((args: CleanOrderAttachSaveDTO) =>
    this.post<ApiResponse<Record<string, any>>>('/clean-order/submit-online-qa').body(args)
  )

  // 根据服务查询需要进行配置的房间  |  object:{propertyCode:房源编号,serviceId:服务ID}
  cleanOrderGetCleanHouseType = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<CleanHouseTypeVO>>('/clean-order/get-clean-house-type').body(args)
  )

  // 设置房型
  cleanOrderSetCleanHouseType = $api.action((args: CleanHouseTypeSetDTO) =>
    this.post<ApiResponse<Record<string, any>>>('/clean-order/set-clean-house-type').body(args)
  )

  // 查询组织账户余额  |  object:{orderId:服务单ID}
  cleanOrderGetTeamBalance = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<number>>('/clean-order/get-team-balance').body(args)
  )

  // 服务单-门店保洁扣款记录
  cleanOrderWalletRecord = $api.action((args: CleanOrderWalletRecordListDTO) =>
    this.post<ApiResponse<CleanOrderWalletRecordVO[]>>('/clean-order/wallet-record').body(args)
  )
}

export const cleanModel = new Clean()
