import React, {useCallback, useEffect, useImperativeHandle, useState} from 'react'
import {
  Button,
  Col,
  DatePicker,
  Descriptions,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Row,
  Select,
  Space,
  Table,
  Tabs,
  Typography,
} from 'antd'
import DividerLine from '@/pages/property/component/DividerLine'
import {stewardModel} from '@/store/models/Steward'
import {propertyModel} from '@/store/models/Property'
import {lessorModel} from '@/store/models/Lessor'
import moment from 'moment'
import {useCodeArraySync, useCodeSync} from '@/store/models/Common'
import {getPaginationProps} from '@/common/biz/getPaginationProps'
import {customerModel} from '@/store/models/Customer'
import locale from 'antd/es/date-picker/locale/zh_CN'
import {storageModel} from '@/store/models/Storage'

const {Text} = Typography

function AddServiceBill(props, ref) {
  const serviceStatus = useCodeSync('steward.orderStatus')
  const lessorBillSubTypeOptions = useCodeArraySync('lessor.billSubType')
  const purchaseStatusCode = useCodeSync('purchase.status')

  const [visible, setVisible] = useState(false)
  const [propertyList, setPropertyList] = useState<PropertyAdminListWithStewardVO[]>([])
  const [propertyTotal, setPropertyTotal] = useState(0)
  const propertyLoading = propertyModel.propertyListWithSteward.useLoading()
  const [basicBillForm] = Form.useForm()
  const [orderId, setOrderId] = useState('')
  const [extraServiceForm] = Form.useForm()
  const [serviceDetail, setServiceDetail] = useState({} as StewardOrderQueryVO)
  const [propertyForm] = Form.useForm()
  const [propertyCodeList, setPropertyCodeList] = useState<PropertyAdminListWithStewardVO[]>([])

  const [bizIdExtra, setBizIdExtra] = useState('')
  const [purchaseDetail, setPurchaseDetail] = useState({} as PurchaseVO)

  const searchProperty = useCallback(() => {
    const values = propertyForm.getFieldsValue(true)
    propertyModel.propertyListWithSteward({...values, monthlyContract: true}).then(({response: {data, total}}) => {
      setPropertyList(data)
      setPropertyTotal(total)
    })
  }, [propertyForm])

  useImperativeHandle(ref, () => ({
    onDisplay: () => {
      setVisible(true)
    },

    onSetInfo: lessorId => {
      propertyForm.setFieldsValue({lessorId})
      basicBillForm.setFieldsValue({lessorId})
      extraServiceForm.setFieldsValue({lessorId})
      searchProperty()
    },
  }))

  useEffect(() => {
    if (propertyCodeList.length) {
      const billAmtTotal = propertyCodeList.reduce((sum, item) => {
        return (sum += +item.stewardPrice)
      }, 0)
      basicBillForm.setFieldsValue({billAmtTotal})
    } else {
      basicBillForm.setFieldsValue({billAmtTotal: undefined})
    }
  }, [basicBillForm, propertyCodeList])

  const closeReset = () => {
    propertyForm.resetFields()
    basicBillForm.resetFields()
    extraServiceForm.resetFields()
    setPropertyCodeList([])
  }

  return (
    <Modal
      open={visible}
      centered
      footer={null}
      onCancel={() => setVisible(false)}
      destroyOnClose
      afterClose={closeReset}
      width={800}
      title={'添加服务账单'}
    >
      <Tabs
        items={[
          {
            key: '1',
            label: '基础服务账单',
            children: (
              <>
                <Form
                  form={basicBillForm}
                  initialValues={{billType: '1'}}
                  onFinish={async () => {
                    const {
                      response: {
                        data: {certName},
                      },
                    } = await customerModel.customerInfo({userId: basicBillForm.getFieldValue('lessorId')})
                    Modal.confirm({
                      icon: null,
                      title: '添加服务账单提示',
                      content: `确定添加"${certName}"基础服务账单"${basicBillForm.getFieldValue('billAmtTotal')}元"？`,
                      centered: true,
                      onOk: async () => {
                        const values = basicBillForm.getFieldsValue(true)
                        await lessorModel.userLessorBillAdd({
                          ...values,
                          propertyCodeList: propertyCodeList.map(item => item.propertyCode),
                        })
                        props.onSuccess()
                        setVisible(false)
                      },
                    })
                  }}
                >
                  <Row>
                    <Col span={12}>
                      <Form.Item
                        name={'billName'}
                        label={'账单名称'}
                        rules={[{required: true, message: '请输入账单名称'}]}
                      >
                        <Input placeholder={'请输入'} allowClear style={{maxWidth: 240}} maxLength={60} />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name={'billBegin'}
                        label={'账单周期'}
                        getValueFromEvent={(_, formatString) => {
                          basicBillForm.setFieldValue('billEnd', formatString?.[1])
                          return formatString?.[0]
                        }}
                        getValueProps={value => ({
                          value: value ? [moment(value), moment(basicBillForm.getFieldValue('billEnd'))] : value,
                        })}
                        rules={[{required: true, message: '请输入账单周期'}]}
                      >
                        <DatePicker.RangePicker style={{maxWidth: 240}} />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name={'billDate'}
                        label={'应收款日'}
                        getValueFromEvent={(_, formatString) => formatString}
                        getValueProps={value => ({value: value ? moment(value) : value})}
                        rules={[{required: true, message: '请输入应收款日'}]}
                      >
                        <DatePicker style={{width: 240}} />
                      </Form.Item>
                    </Col>
                    {/* 添加服务账单：最后付款日 */}
                    <Col span={12}>
                      <Form.Item
                        name={'lastPayDate'}
                        label={'最后付款日'}
                        getValueFromEvent={(_, formatString) => formatString}
                        getValueProps={value => ({value: value ? moment(value) : value})}
                        rules={[{required: true, message: '请输入最后付款日'}]}
                      >
                        <DatePicker style={{width: 240}} />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name={'billAmtTotal'}
                        label={'账单金额'}
                        rules={[{required: true, message: '请输入账单金额'}]}
                      >
                        <InputNumber placeholder={'请输入'} style={{minWidth: 240}} />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name={'remark'}
                        label={'备注原因'}
                        rules={[{required: true, message: '请输入备注原因'}]}
                      >
                        <Input.TextArea rows={3} placeholder={'请输入'} />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
                <DividerLine title={'房源详情'} bottom={20} />
                <Form
                  form={propertyForm}
                  onFinish={searchProperty}
                  initialValues={{pageNum: 1, pageSize: 10, pageable: true}}
                >
                  <Row>
                    <Col span={10}>
                      <Form.Item label={'房源地址'} name={'propertyTitle'}>
                        <Input placeholder={'房源地址'} allowClear />
                      </Form.Item>
                    </Col>
                    <Col offset={1}>
                      <Button
                        type={'primary'}
                        htmlType={'submit'}
                        onClick={() => propertyForm.setFieldsValue({pageNum: 1})}
                      >
                        查询
                      </Button>
                    </Col>
                  </Row>
                </Form>
                <Table
                  rowKey={'propertyCode'}
                  loading={propertyLoading}
                  dataSource={propertyList}
                  rowSelection={{
                    selectedRowKeys: propertyCodeList.map(item => item.propertyCode),
                    onChange: (selectedRowKeys, selectedRows) => {
                      if (selectedRowKeys.length) {
                        setPropertyCodeList(state => {
                          const codes = []
                          const setArr = new Set([...state.map(item => item.propertyCode), ...selectedRowKeys])
                          for (const code of setArr) {
                            if (selectedRowKeys.includes(code)) {
                              codes.push(selectedRows.find(item => item.propertyCode === code))
                            } else {
                              codes.push(state.find(item => item.propertyCode === code))
                            }
                          }
                          return codes
                        })
                      } else {
                        const codes = propertyList.map(item => item.propertyCode)
                        setPropertyCodeList(state => state.filter(code => !codes.includes(code.propertyCode)))
                      }
                    },
                    onSelect: (record, selected) => {
                      if (selected) {
                        setPropertyCodeList(state => [...state, record])
                      } else {
                        setPropertyCodeList(state => state.filter(code => code.propertyCode !== record.propertyCode))
                      }
                    },
                  }}
                  columns={[
                    {
                      title: '房源code',
                      dataIndex: 'propertyCode',
                    },
                    {
                      title: '所属团队',
                      dataIndex: 'teamName',
                    },
                    {
                      title: '所属区域',
                      dataIndex: 'districtName',
                    },
                    {
                      title: '房源标题',
                      dataIndex: 'propertyTitle',
                    },
                    {
                      title: '包月费用',
                      dataIndex: 'stewardPrice',
                      render: value => `${value}元`,
                    },
                  ]}
                  pagination={getPaginationProps(propertyForm, propertyTotal)}
                  footer={() => {
                    const totalAmt = propertyCodeList.reduce((sum, item) => {
                      return (sum += +item.stewardPrice)
                    }, 0)
                    return (
                      <Row justify={'space-between'}>
                        <Col>
                          <span
                            style={{color: 'red'}}
                          >{`选择房间数量：${propertyCodeList.length}间，基础费用${totalAmt}元`}</span>
                        </Col>
                        <Col>
                          <Space>
                            <Button onClick={() => setPropertyCodeList([])}>重置</Button>
                            <Button
                              type={'primary'}
                              onClick={async () => {
                                const values = propertyForm.getFieldsValue(true)
                                const {
                                  response: {data},
                                } = await propertyModel.propertyListWithSteward({
                                  ...values,
                                  monthlyContract: true,
                                  pageable: false,
                                })
                                const arr: PropertyAdminListWithStewardVO[] = []
                                for (const item of data) {
                                  if (!propertyCodeList.find(code => code.propertyCode === item.propertyCode)) {
                                    arr.push(item)
                                  }
                                }
                                setPropertyCodeList([...propertyCodeList, ...arr])
                              }}
                            >
                              全选所有包月
                            </Button>
                          </Space>
                        </Col>
                      </Row>
                    )
                  }}
                />
                <Row justify={'end'}>
                  <Space>
                    <Button onClick={() => setVisible(false)}>取消</Button>
                    <Button type={'primary'} onClick={() => basicBillForm.submit()}>
                      确认提交
                    </Button>
                  </Space>
                </Row>
              </>
            ),
          },
          {
            key: '2',
            label: '额外服务账单',
            children: (
              <Form
                form={extraServiceForm}
                onFinish={() => {
                  Modal.confirm({
                    icon: null,
                    title: '提示',
                    content: '是否确认生成账单？',
                    centered: true,
                    onOk: async () => {
                      const values = extraServiceForm.getFieldsValue(true)
                      await lessorModel.userLessorBillAdd({
                        ...values,
                        billType: '2',
                        bizIdExtra: values.billSubtype === '1' ? values.stewardOrderId : values.bizIdExtra,
                      })
                      props.onSuccess()
                      setVisible(false)
                    },
                  })
                }}
              >
                <Row>
                  <Col span={12}>
                    <Form.Item label={'类型'} name={'billSubtype'} rules={[{required: true, message: '请选择类型'}]}>
                      <Select placeholder={'请选择'} options={lessorBillSubTypeOptions} fieldNames={{value: 'id'}} />
                    </Form.Item>
                  </Col>
                  <Col span={12} />
                  <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, nextValues) => prevValues.billSubtype !== nextValues.billSubtype}
                  >
                    {form1 => {
                      const billSubtype = form1.getFieldValue('billSubtype')
                      if (billSubtype === '1')
                        return (
                          <>
                            <Col span={12}>
                              <Form.Item
                                name={'stewardOrderId'}
                                label={'服务单号'}
                                rules={[{required: true, message: '请输入服务单号'}]}
                              >
                                <Input
                                  placeholder={'请输入'}
                                  onChange={e => setOrderId(e.target.value)}
                                  onBlur={() => {
                                    if (orderId) {
                                      stewardModel.stewardOrderDetail({orderId}).then(({response: {data}}) => {
                                        if (!data) {
                                          return message.error('请输入正确的服务单')
                                        }
                                        setServiceDetail(data)
                                        const billAmtTotal = data.feeCarriage + data.feeStaff
                                        billAmtTotal && extraServiceForm.setFieldsValue({billAmtTotal})
                                      })
                                    }
                                  }}
                                  allowClear
                                  style={{maxWidth: 200}}
                                />
                              </Form.Item>
                            </Col>
                            <Col span={12}>
                              <Form.Item
                                name={'billName'}
                                label={'账单名称'}
                                rules={[{required: true, message: '请输入账单名称'}]}
                              >
                                <Input placeholder={'请输入'} allowClear style={{maxWidth: 200}} />
                              </Form.Item>
                            </Col>
                            <Col span={12}>
                              <Form.Item
                                name={'billCreateTime'}
                                label={'账单生成日期'}
                                getValueFromEvent={(_, formatString) => formatString}
                                getValueProps={value => ({value: value ? moment(value) : value})}
                                rules={[{required: true, message: '请输入账单生成日期'}]}
                              >
                                <DatePicker showTime style={{width: 200}} />
                              </Form.Item>
                            </Col>
                            <Col span={12}>
                              <Form.Item
                                name={'billAmtTotal'}
                                label={'账单金额'}
                                rules={[{required: true, message: '请输入账单金额'}]}
                              >
                                <InputNumber placeholder={'请输入'} style={{minWidth: 200}} />
                              </Form.Item>
                            </Col>
                            <Col span={12}>
                              <Form.Item
                                name={'billDate'}
                                label={'应收款日'}
                                rules={[{required: true, message: '请输入应收款日'}]}
                                getValueFromEvent={(_, formatString) => formatString}
                                getValueProps={value => ({value: value ? moment(value) : value})}
                              >
                                <DatePicker style={{width: 200}} />
                              </Form.Item>
                            </Col>
                          </>
                        )

                      if (billSubtype === '2')
                        return (
                          <>
                            <Col span={12}>
                              <Form.Item
                                label={'采购ID'}
                                name={'bizIdExtra'}
                                rules={[{required: true, message: '请输入采购ID'}]}
                              >
                                <Input
                                  placeholder={'请输入'}
                                  onChange={e => setBizIdExtra(e.target.value)}
                                  onBlur={async () => {
                                    if (bizIdExtra) {
                                      const {
                                        response: {data},
                                      } = await storageModel.purchaseDetail({purchaseId: bizIdExtra})
                                      setPurchaseDetail(data)
                                    }
                                  }}
                                  allowClear
                                />
                              </Form.Item>
                            </Col>
                            <Col span={12}>
                              <Form.Item
                                label={'账单名称'}
                                name={'billName'}
                                rules={[{required: true, message: '请输入账单名称'}]}
                              >
                                <Input placeholder={'请输入'} />
                              </Form.Item>
                            </Col>
                            <Col span={12}>
                              <Form.Item
                                label={'账单生成日期'}
                                name={'billCreateTime'}
                                getValueFromEvent={(_, formatString) => formatString}
                                getValueProps={value => ({value: value ? moment(value) : value})}
                                rules={[{required: true, message: '请选择账单生成日期'}]}
                              >
                                <DatePicker showTime locale={locale} style={{width: '100%'}} />
                              </Form.Item>
                            </Col>
                            <Col span={12}>
                              <Form.Item
                                label={'账单金额'}
                                name={'billAmtTotal'}
                                rules={[{required: true, message: '请输入账单金额'}]}
                              >
                                <InputNumber style={{width: '100%'}} placeholder={'请输入'} />
                              </Form.Item>
                            </Col>
                            <Col span={12}>
                              <Form.Item
                                label={'应收款日'}
                                name={'billDate'}
                                getValueFromEvent={(_, formatString) => formatString}
                                getValueProps={value => ({value: value ? moment(value) : value})}
                                rules={[{required: true, message: '请选择应收款日'}]}
                              >
                                <DatePicker mode={'date'} locale={locale} style={{width: '100%'}} />
                              </Form.Item>
                            </Col>
                          </>
                        )
                    }}
                  </Form.Item>
                </Row>
                <Form.Item
                  noStyle
                  shouldUpdate={(prevValues, nextValues) => prevValues.billSubtype !== nextValues.billSubtype}
                >
                  {form1 => {
                    const billSubtype = form1.getFieldValue('billSubtype')
                    if (billSubtype === '1')
                      return (
                        <>
                          <DividerLine title={'服务单信息'} style={{marginBottom: 15}} />
                          <Row gutter={[0, 15]}>
                            <Col span={12}>
                              <Text>房东姓名：</Text>
                              <Text style={{color: '#000000'}}>{serviceDetail?.lessorName ?? '无'}</Text>
                            </Col>
                            <Col span={12}>
                              <Text>服务单号：</Text>
                              <Text style={{color: '#000000'}}>{serviceDetail?.orderId ?? '无'}</Text>
                            </Col>
                            <Col span={12}>
                              <Text>订单状态：</Text>
                              <Text style={{color: '#000000'}}>
                                {serviceStatus[serviceDetail?.orderStatus] ?? '无'}
                              </Text>
                            </Col>
                            <Col span={12}>
                              <Text>房源地址：</Text>
                              <Text style={{color: '#000000'}}>{serviceDetail?.propertyAddr ?? '无'}</Text>
                            </Col>
                            <Col span={12}>
                              <Text>服务项目：</Text>
                              <Text style={{color: '#000000'}}>{serviceDetail?.categoryThiName ?? '无'}</Text>
                            </Col>
                          </Row>
                        </>
                      )

                    if (billSubtype === '2')
                      return (
                        <>
                          <DividerLine title={'采购信息'} bottom={20} />
                          <Descriptions column={2}>
                            <Descriptions.Item label={'订单状态'}>
                              {purchaseStatusCode[purchaseDetail.purchaseStatus]}
                            </Descriptions.Item>
                            <Descriptions.Item label={'订单金额'}>{purchaseDetail.purchaseAmtSum}元</Descriptions.Item>
                            <Descriptions.Item label={'收货信息'}>
                              {[
                                purchaseDetail.consigneeName,
                                purchaseDetail.consigneeMobile,
                                purchaseDetail.warehouseName,
                              ]
                                .filter(Boolean)
                                .join(' ')}
                            </Descriptions.Item>
                          </Descriptions>
                        </>
                      )
                  }}
                </Form.Item>

                <Row justify={'end'}>
                  <Space>
                    <Button
                      onClick={() => {
                        setVisible(false)
                        extraServiceForm.resetFields()
                      }}
                    >
                      取消
                    </Button>
                    <Button type={'primary'} htmlType={'submit'}>
                      确认提交
                    </Button>
                  </Space>
                </Row>
              </Form>
            ),
          },
        ]}
      />
    </Modal>
  )
}

export default React.forwardRef(AddServiceBill)
