import React, {useState, useEffect, useMemo} from 'react'
import {Form, Input, Select, Button} from 'antd'
import {useHistory} from 'react-router-dom'
import './index.less'
import {lesseeModel} from '@/store/models/Lessee'
import {commonModel} from '@/store/models/Common'
import {Breadcrumb} from '@/components/Breadcrumb'
import commonStyles from '@/assets/styles/common.module.less'
import {Page} from '@/components/Page'
import BackButton from '@/components/BackButton'

const {TextArea} = Input
const {Option} = Select

interface ManagerProps {
  userId: string
  name: string
}

export default function AddorEditLessor(props: any) {
  const history = useHistory()
  const [form] = Form.useForm()
  const [userId, setUserId] = useState('')
  const [realVerify, setRealVerify] = useState('0')
  const formItemLayout = {
    labelCol: {span: 2},
    wrapperCol: {span: 8},
  }
  const loading = lesseeModel.lesseeUpsert.useLoading()
  const gender = useMemo(() => commonModel.getCodeArraySync('gender'), [])

  useEffect(() => {
    const state = props.history.location?.state
    setUserId(state?.userId)

    if (state?.userId) {
      lesseeModel.queryLesseeInfo(state.userId).then(({response: {data}}) => {
        const {loginTime, nickName, realVerify, userId, ...rest} = data
        setRealVerify(realVerify)
        form.setFieldsValue({...rest})
      })
    }
  }, [form, props.history.location])

  function onFinish() {
    lesseeModel
      .lesseeUpsert({
        ...(form.getFieldsValue() as LesseeInsertDTO),
        lesseeId: userId ?? null,
      })
      .then(() => {
        history.goBack()
      })
  }

  function certNoValid(rule: any, value: any, callback: Function) {
    const certNoReg = /^[1-9]\d{5}(18|19|20|(3\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/

    if (!value) {
      callback('证件号不能为空')
      return
    }

    if (!certNoReg.test(value)) {
      callback('证件号格式不正确')
      return
    }

    callback()
  }

  function mobileValid(rule: any, value: any, callback: Function) {
    const mobileReg = /^1[0-9]{10}$/

    if (!value) {
      callback('手机号不能为空')
      return
    }

    if (!mobileReg.test(value)) {
      callback('手机号格式不正确')
      return
    }

    callback()
  }

  return (
    <Page breadcrumb={<Breadcrumb path={['用户管理', '租客用户列表', userId ? '编辑用户' : '添加用户']} />}>
      <BackButton />

      <div className={commonStyles.filterSection}>
        <Form {...formItemLayout} form={form} onFinish={onFinish}>
          <Form.Item name={'certName'} label={'用户姓名'} rules={[{required: true, message: '用户姓名不能为空'}]}>
            <Input allowClear disabled={realVerify === '1' ? true : false} placeholder={'请输入用户姓名'} />
          </Form.Item>
          <Form.Item name={'mobile'} label={'手机号'} rules={[{required: true, validator: mobileValid}]}>
            <Input allowClear disabled={realVerify === '1' ? true : false} placeholder={'请输入11位手机号'} />
          </Form.Item>
          <Form.Item name={'certNo'} label={'身份证号'} rules={[{required: true, validator: certNoValid}]}>
            <Input allowClear disabled={realVerify === '1' ? true : false} placeholder={'请输入身份证号'} />
          </Form.Item>
          <Form.Item name={'gender'} label={'性别'} rules={[{required: true, message: '性别不能为空'}]}>
            <Select allowClear placeholder={'请选择'}>
              {gender.map((item, index) => (
                <Option key={index} value={item.id}>
                  {item.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name={'remark'} label={'备注'}>
            <TextArea placeholder={'请输入备注'} rows={6} allowClear />
          </Form.Item>
          <Form.Item wrapperCol={{offset: 2}}>
            <Button type='primary' htmlType='submit' loading={loading}>
              保存并提交审核
            </Button>
            <Button style={{marginLeft: '10px'}} onClick={() => history.goBack()}>
              取消
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Page>
  )
}
