import React, {useEffect, useMemo, useRef, useState} from 'react'
import {Page} from '@/components/Page'

import Map from 'react-bmapgl/dist/Map/Map'
import MapApiLoaderHOC from 'react-bmapgl/dist/Map/MapApiLoaderHOC'
import Polygon from 'react-bmapgl/dist/Overlay/Polygon'
import {ak, baseUrl} from '@/common/config'
import Label from 'react-bmapgl/dist/Overlay/Label'
import {Breadcrumb} from '@/components/Breadcrumb'
import {Button, Drawer, Form, message, Modal, Space, Upload, Timeline} from 'antd'
import {useAppHistory} from '@/common/hooks/useRouter'
import {Album, UploadButton} from '@/components/Album'
import {stewardModel} from '@/store/models/Steward'
import {getImg} from '@/utils/util'
import {usePermission} from '@/components/Permission'

// 获取中心点
const centerPoint = (path: BMapGL.Point[]) => {
  const total = path.reduce(
    (previousValue, currentValue) => {
      previousValue[0] += currentValue.lng
      previousValue[1] += currentValue.lat

      return previousValue
    },
    [0, 0]
  )
  return new BMapGL.Point(total[0] / path.length, total[1] / path.length)
}

function CleanServerArea() {
  // todo: 返回后刷新
  const history = useAppHistory()

  const [form] = Form.useForm()
  const [visible, setVisible] = useState(false)

  const containerRef = useRef(null)

  const [areaList, setAreaList] = useState<StewardCleanAreaVO[]>([])
  useEffect(() => {
    stewardModel.stewardCleanAreaList().then(({response: {data}}) => setAreaList(data))
  }, [])

  const [queue, setQueue] = useState<string[]>([])

  const points = useMemo(() => {
    return areaList.map(value => ({
      ...value,
      pointList: value.pointList.map(item => new BMapGL.Point(item.lng, item.lat)),
    }))
  }, [areaList])

  const infoLoading = stewardModel.stewardCleanAreaPriceSheetInfo.useLoading()
  const [sheetList, setSheetList] = useState<StewardCleanAreaPriceSheetVO[]>(null)
  // const [sheetVisible, setSheetVisible] = useState(false)

  const [auth] = usePermission()

  return (
    <Page
      breadcrumb={
        <Breadcrumb
          right={
            <Space>
              {auth('001129001') && (
                <Button
                  onClick={async () => {
                    const {
                      response: {data},
                    } = await stewardModel.stewardCleanAreaPriceSheetList()
                    setSheetList(data)
                  }}
                >
                  报价单上传记录
                </Button>
              )}
              {auth('001128001') && (
                <Button
                  loading={infoLoading}
                  onClick={async () => {
                    const {
                      response: {data},
                    } = await stewardModel.stewardCleanAreaPriceSheetInfo()
                    form.setFieldsValue(data)
                    setVisible(true)
                  }}
                >
                  保洁服务报价
                </Button>
              )}
              {auth('003127002') && (
                <Button type={'primary'} onClick={() => history.push('/clean-server-area/add')}>
                  新增服务区域
                </Button>
              )}
            </Space>
          }
        />
      }
    >
      <div ref={containerRef} style={{height: 'calc(100vh - 54px - 48px - 20px)', position: 'relative'}}>
        <Map enableScrollWheelZoom style={{height: '100%'}} center={'上海市'} zoom={13}>
          {points.map(value => {
            const isActive = queue[queue.length - 1] === value.areaId

            return (
              <React.Fragment key={value.areaId}>
                <Polygon
                  map={null}
                  path={value.pointList}
                  strokeColor={isActive ? '#bd0000' : '#00B7AE'}
                  onClick={() => {
                    if (isActive) {
                      history.push('/clean-server-area/add', {areaId: value.areaId})
                    }
                  }}
                  onMouseover={() => setQueue(prevState => [...prevState, value.areaId])}
                  onMouseout={() => setQueue(prevState => prevState.filter(item => item !== value.areaId))}
                />
                <Label
                  map={null}
                  position={centerPoint(value.pointList)}
                  offset={new BMapGL.Size(0, -6)}
                  text={value.areaName}
                  style={{color: '#000', borderColor: 'transparent', backgroundColor: 'transparent'}}
                />
              </React.Fragment>
            )
          })}
        </Map>
      </div>

      <Modal
        //
        visible={visible}
        title={'保洁服务报价'}
        okText={'保存'}
        onOk={() => form.submit()}
        onCancel={() => setVisible(false)}
        afterClose={() => form.resetFields()}
        confirmLoading={stewardModel.stewardCleanAreaPriceSheetInsert.useLoading()}
      >
        <Form
          form={form}
          onFinish={async () => {
            const values = form.getFieldsValue(true)
            await stewardModel.stewardCleanAreaPriceSheetInsert(values.imgList)
            message.success('保存成功')
            setVisible(false)
          }}
        >
          <Form.Item
            label={'报价单'}
            name={'imgList'}
            rules={[{required: true, message: '请上传报价单'}]}
            valuePropName={'fileList'}
            getValueFromEvent={info => {
              return info.fileList.map(value => {
                return 'imgUrl' in value
                  ? value.imgUrl
                  : 'response' in value && value.status === 'done'
                  ? value.response.data.imgUrl
                  : value
              })
            }}
            getValueProps={value => ({
              fileList: value?.map(item => {
                return typeof item === 'string' ? {uid: item, imgUrl: item, url: getImg(item)} : item
              }),
            })}
          >
            <Album
              action={baseUrl + '/steward/clean/area/price-sheet/upload'}
              accept={'image/*'}
              beforeUpload={file => {
                if (file.size > 1024 * 1024 * 20) {
                  return Upload.LIST_IGNORE
                }
              }}
            >
              <UploadButton />
            </Album>
          </Form.Item>
        </Form>
      </Modal>

      <Drawer visible={!!sheetList} width={698} title={'报价单上传记录'} onClose={() => setSheetList(null)}>
        <Timeline>
          {sheetList?.map((value, index) => (
            <Timeline.Item
              key={value.id}
              dot={
                <div
                  style={{
                    width: 16,
                    height: 16,
                    borderRadius: '50%',
                    backgroundColor: index === 0 ? '#10BBB3' : '#D8D8D8',
                  }}
                />
              }
            >
              <Space direction={'vertical'} size={7} style={{paddingBottom: 46}}>
                <Album disabled fileList={value.imgList.map(item => ({uid: item, url: getImg(item)}))}>
                  {null}
                </Album>
                <span style={{color: '#909399'}}>操作人: {value.operatorName}</span>
                <span style={{color: '#606266'}}>操作时间: {value.insertTime}</span>
              </Space>
            </Timeline.Item>
          ))}
        </Timeline>
      </Drawer>
    </Page>
  )
}

export default MapApiLoaderHOC({ak})(CleanServerArea)
