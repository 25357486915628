import React, {useCallback, useEffect, useState} from 'react'
import commonStyle from '@/assets/styles/common.module.less'
import {Button, Col, DatePicker, Form, Input, Row, Select, Space, Table} from 'antd'
import {useAppHistory} from '@/common/hooks/useRouter'
import {userModel} from '@/store/models/User'
import {useCodeArraySync, useCodeSync} from '@/store/models/Common'
import {useFocusEffect} from '@/common/hooks/useFocusEffect'
import {getPaginationProps} from '@/common/biz/getPaginationProps'
import {Page} from '@/components/Page'

export default function CompanyAccountApprove() {
  const history = useAppHistory()

  const [form] = Form.useForm()
  const companyApplyStatusArr = useCodeArraySync('lessor.companyApplyStatus')
  const companyApplyStatus = useCodeSync('lessor.companyApplyStatus')
  const [total, settTotal] = useState(0)
  const [companyApplyList, setCompanyApplyList] = useState<LessorCompanyApplyVO[]>([])

  const search = useCallback(
    () =>
      userModel.userLessorCompanyApplyList(form.getFieldsValue(true)).then(({response: {data, total}}) => {
        setCompanyApplyList(data)
        settTotal(total)
      }),
    [form]
  )

  useFocusEffect(() => {
    form.submit()
  })

  useEffect(() => {
    form.submit()
  }, [form])

  return (
    <Page>
      <div className={commonStyle.filterSection}>
        <Form form={form} initialValues={{pageNum: 1, pageSize: 10}} onFinish={search} onReset={search}>
          <Row>
            <Col span={5}>
              <Form.Item label={'企业名称'} name={'companyName'}>
                <Input placeholder='请输入' allowClear />
              </Form.Item>
            </Col>
            <Col offset={1}>
              <Form.Item label={'提交日期'} name={'_time'}>
                <DatePicker.RangePicker
                  onChange={(_, formatString) => {
                    form.setFieldsValue({
                      applyTimeBegin: formatString?.[0],
                      applyTimeEnd: formatString?.[1],
                    })
                  }}
                />
              </Form.Item>
            </Col>
            <Col offset={1} span={5}>
              <Form.Item label={'状态'} name={'auditStatus'}>
                <Select placeholder={'请选择'} allowClear>
                  {companyApplyStatusArr.map(item => (
                    <Select.Option value={item.id} key={item.id}>
                      {item.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row justify={'end'}>
            <Space>
              <Button type={'primary'} htmlType={'submit'} onClick={() => form.setFieldsValue({pageNum: 1})}>
                查询
              </Button>
              <Button htmlType={'reset'}>重置</Button>
            </Space>
          </Row>
        </Form>
      </div>
      <div className={commonStyle.filterSection}>
        <Table
          rowKey={'applyId'}
          columns={[
            {title: '申请人', dataIndex: 'applicantName', key: 'applicantName'},
            {title: '手机号码', dataIndex: 'legalPhone', key: 'legalPhone'},
            {title: '企业名称', dataIndex: 'companyName', key: 'companyName'},
            {title: '申请时间', dataIndex: 'applyTime', key: 'applyTime'},
            {title: '状态', dataIndex: 'auditStatus', key: 'auditStatus', render: text => companyApplyStatus[text]},
            {
              title: '操作',
              render: (_, render) => (
                <Button
                  type={'link'}
                  onClick={() => history.push({pathname: '/company_account/detail', state: render})}
                >
                  查看
                </Button>
              ),
            },
          ]}
          dataSource={companyApplyList}
          pagination={getPaginationProps(form, total)}
        />
      </div>
    </Page>
  )
}
