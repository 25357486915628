import React, {useEffect, useState} from 'react'
import {Page} from '@/components/Page'
import {Button, Form, Input, message, Row, Select, Space} from 'antd'
import styles from '@/assets/styles/common.module.less'
import {useAppHistory, useAppLocation} from '@/common/hooks/useRouter'
import {feedbackModel} from '@/store/models/FeedBack'
import {commonModel, useCodeArraySync} from '@/store/models/Common'
import * as listener from '@/common/hooks/listener'

export default function PayOrder() {
  const history = useAppHistory()
  const location = useAppLocation<'/complaint/pay'>()
  const orderId = location.state?.orderId
  const [form] = Form.useForm()
  const [detail, setDetail] = useState({} as CsWorkOrderVO)
  const compensationArr = useCodeArraySync('cs.work.order.compensation.status')

  useEffect(() => {
    feedbackModel.csWorkOrderGet({id: orderId}).then(({response: {data}}) => {
      setDetail(data)
    })
  }, [orderId])

  return (
    <Page>
      <div className={styles.filterSection}>
        <Form
          form={form}
          labelCol={{span: 8}}
          wrapperCol={{span: 10}}
          onFinish={async () => {
            const {compensation2, compensation1, compensationOtherStatusRemark} = form.getFieldsValue(true)
            await feedbackModel.csWorkOrderCompensation({
              id: orderId,
              compensationStatus: compensation1 === 'other' ? compensation1 : compensation2,
              compensationOtherStatusRemark,
            })
            listener.emit('ComplaintWorkOrder')
            message.success('提交成功！')
            history.goBack()
          }}
        >
          <Form.Item label={'用户名称'}>{`${detail.userName}-${detail.userMobile}`}</Form.Item>
          {detail.serviceOrderId && <Form.Item label={'服务单号'}>{detail.serviceOrderId}</Form.Item>}
          <Form.Item label={'投诉时间'}>{detail.complaintTime}</Form.Item>
          <Form.Item label={'投诉部门'}>{detail.complainedDept}</Form.Item>
          <Form.Item label={'投诉人员'}>{detail.complainedEmp ?? '/'}</Form.Item>
          <Form.Item label={'责任判定'}>{detail.responsibilityJudgment === '0' ? '无责' : '有责'}</Form.Item>
          <Form.Item label={'投诉情况描述'}>{detail.complaintDescription}</Form.Item>
          <Form.Item name={'compensation1'} label={'赔付类型'} rules={[{required: true, message: '请选择赔付类型'}]}>
            <Select
              placeholder={'请选择'}
              style={{width: 200}}
              onChange={value => {
                if (value) {
                  const list = commonModel.getCodeArraySync(value as string)
                  form.setFieldsValue({list, compensation2: undefined, compensationOtherStatusRemark: undefined})
                }
              }}
            >
              {compensationArr.map(item => (
                <Select.Option value={item.id} key={item.id}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item noStyle shouldUpdate={(prev, next) => prev.compensation1 !== next.compensation1}>
            {f => {
              const compensation1 = f.getFieldValue('compensation1')
              return compensation1 === 'other' ? (
                <Form.Item
                  label={'赔付情况'}
                  name={'compensationOtherStatusRemark'}
                  rules={[{required: true, message: '请输入'}]}
                >
                  <Input.TextArea placeholder={'请输入'} rows={3} maxLength={200} showCount allowClear />
                </Form.Item>
              ) : (
                <Form.Item label={'赔付情况'} shouldUpdate required>
                  {f => {
                    const list = f.getFieldValue('list') ?? []
                    return (
                      <Form.Item rules={[{required: true, message: '请选择赔付情况'}]} name={'compensation2'}>
                        <Select placeholder={'请选择'} style={{width: 200}}>
                          {list.map(item => (
                            <Select.Option value={item.id} key={item.id}>
                              {item.label}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    )
                  }}
                </Form.Item>
              )
            }}
          </Form.Item>
          <Row justify={'center'}>
            <Space>
              <Button htmlType={'submit'} type={'primary'}>
                提交
              </Button>
              <Button htmlType={'reset'}>重置</Button>
            </Space>
          </Row>
        </Form>
      </div>
    </Page>
  )
}
