import React, {useCallback, useEffect, useState} from 'react'
import {Button, Col, DatePicker, Form, Input, Modal, Row, Select, Space, Table, Tabs, Typography} from 'antd'
import {storageModel} from '@/store/models/Storage'
import {Breadcrumb} from '@/components/Breadcrumb'
import commonStyles from '@/assets/styles/common.module.less'
import {commonModel, useCodeArraySync} from '@/store/models/Common'
import styles from './index.module.less'
import {downloadBlob} from '@/utils/util'
import {Page} from '@/components/Page'
import {FORMAT_DATE, FORMAT_DATE_END, FORMAT_DATE_START} from '@/common/date'
import moment from 'moment/moment'
import {getPaginationProps} from '@/common/biz/getPaginationProps'
import {useDebounce} from '@/common/hooks/useDebounce'
import {usePermission} from '@/components/Permission'

const {RangePicker} = DatePicker

export default function StorageReport() {
  const [form] = Form.useForm()
  const [form2] = Form.useForm()
  const [activeKey, setActiveKey] = useState('1')
  const [total, setTotal] = useState(0)
  const [reportList, setReportList] = useState<StewardWarehouseReportVO[]>([])
  const loading = storageModel.stewardWarehouseReport.useLoading()
  const [visible, setVisible] = useState(false)
  const [reasonInfo, setReasonInfo] = useState({} as {batchRemark: string; batchReason: string})
  const reasonType = useCodeArraySync('warehouse.reason')

  const [auth] = usePermission()

  const queryList = useCallback(() => {
    const values = activeKey === '1' ? form.getFieldsValue(true) : form2.getFieldsValue(true)
    storageModel.stewardWarehouseReport(values).then(({response: {data, total}}) => {
      setReportList(data)
      setTotal(total)
    })
  }, [form, form2, activeKey])

  useEffect(() => {
    // 仓库报表
    queryList()
  }, [queryList])

  const [siteList, setSiteList] = useState<StewardWarehouseListItemVO[]>([])

  // 查询站点
  const searchWarehouseList = useDebounce(
    useCallback((name: string) => {
      storageModel
        .stewardWarehouseList({pageNum: 1, pageable: false, pageSize: 1, name} as StewardWarehouseListDTO)
        .then(({response: {data}}) => {
          setSiteList(data)
        })
    }, []),
    800
  )

  const [cityList, setCityList] = useState<CityDataProps[]>([])
  useEffect(() => {
    commonModel
      .queryCityCode({type: '2', parentCode: null})
      .then(({response: {data}}) => setCityList(data.map(item => ({...item, key: item.code}))))
  }, [])

  const [form3] = Form.useForm()
  const [historyList, setHistoryList] = useState<StewardWarehouseCheckRecordVO[]>([])
  const [historyTotal, setHistoryTotal] = useState(0)
  const historyLoading = storageModel.stewardWarehouseCheckHistory.useLoading()
  const recordLoadings = storageModel.stewardWarehouseCheckRecord.useLoadings()

  const queryWarehouseCheckHistory = useCallback(async () => {
    const values = form3.getFieldsValue(true)
    const {
      response: {data, total},
    } = await storageModel.stewardWarehouseCheckHistory(values)
    setHistoryList(data)
    setHistoryTotal(total)
  }, [form3])

  useEffect(() => {
    auth('001146001') && form3.submit()
  }, [auth, form3])

  return (
    <Page
      breadcrumb={
        <Breadcrumb
          right={
            ['1', '2'].includes(activeKey) && (
              <Button
                onClick={async () => {
                  if (activeKey === '1') {
                    await form.validateFields()
                  }
                  if (activeKey === '2') {
                    await form2.validateFields()
                  }
                  const values = activeKey === '1' ? form.getFieldsValue(true) : form2.getFieldsValue(true)
                  downloadBlob({
                    url: '/steward/warehouse/report-excel',
                    body: {...values, pageable: false},
                  })
                }}
              >
                导出
              </Button>
            )
          }
        />
      }
    >
      <Tabs
        activeKey={activeKey}
        onChange={setActiveKey}
        className={styles.tabs}
        items={[
          {
            label: '仓储统计',
            key: '1',
            forceRender: true,
            children: (
              <>
                <div className={styles.form}>
                  <Form
                    form={form}
                    onFinish={queryList}
                    onReset={queryList}
                    labelCol={{flex: '80px'}}
                    initialValues={{
                      pageNum: 1,
                      pageSize: 10,
                      pageable: true,
                      startTime: moment().subtract(31, 'day').format(FORMAT_DATE_START),
                      endTime: moment().format(FORMAT_DATE_END),
                      warehouseType: '1',
                    }}
                  >
                    <Row gutter={30}>
                      <Col span={8}>
                        <Form.Item label={'城市'} name={'warehouseCityCode'}>
                          <Select
                            allowClear
                            options={cityList}
                            fieldNames={{label: 'name', value: 'code'}}
                            placeholder={'请选择城市'}
                            showSearch
                            filterOption={(inputValue, option) => option.name?.includes(inputValue)}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item label={'站点名称'} name={'warehouseIdList'}>
                          <Select
                            options={siteList.map(item => ({label: item.name, value: item.warehouseId}))}
                            placeholder={'请输入查询站点'}
                            showSearch
                            onSearch={searchWarehouseList}
                            filterOption={false}
                            mode={'multiple'}
                            maxTagCount={1}
                            allowClear
                          />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item label={'耗材名称'} name={'materialName'}>
                          <Input placeholder={'请输入耗材名称'} allowClear />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item label={'变仓原因'} name={'reasonList'}>
                          <Select
                            placeholder={'请选择变仓原因'}
                            mode={'multiple'}
                            maxTagCount={1}
                            style={{minWidth: 160}}
                            allowClear
                            options={reasonType.map(item => ({label: item.label, value: item.id}))}
                            filterOption={(inputValue, option) => {
                              return option?.label?.indexOf(inputValue as any) > -1
                            }}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item
                          label={'变仓时间'}
                          name={'startTime'}
                          getValueFromEvent={(_, formatString) => {
                            form.setFieldsValue({
                              endTime: formatString?.[1]
                                ? moment(formatString?.[1]).format(FORMAT_DATE_END)
                                : formatString?.[1],
                            })
                            return formatString?.[0]
                              ? moment(formatString?.[0]).format(FORMAT_DATE_START)
                              : formatString?.[0]
                          }}
                          getValueProps={value => ({
                            value: value ? [moment(value), moment(form.getFieldValue('endTime'))] : value,
                          })}
                          rules={[
                            {
                              validator: (_, value, callback) => {
                                if (value) {
                                  const [startTime, endTime] = value
                                  const intervalDay = moment(endTime).diff(startTime, 'day')
                                  if (intervalDay > 31) return callback('变仓时间间隔不超过31天')
                                } else {
                                  return callback('变仓时间不能为空')
                                }
                                return callback()
                              },
                            },
                          ]}
                        >
                          <RangePicker format={'YYYY-MM-DD'} allowClear={false} />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item label={'操作人'} name={'userName'}>
                          <Input placeholder={'请输入操作人姓名'} allowClear />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row justify={'end'}>
                      <Space>
                        <Button type={'primary'} htmlType={'submit'} onClick={() => form.setFieldsValue({pageNum: 1})}>
                          查询
                        </Button>
                        <Button htmlType={'reset'}>重置</Button>
                      </Space>
                    </Row>
                  </Form>
                </div>

                <div className={commonStyles.tableSection}>
                  <Table
                    rowKey={'recordId'}
                    loading={loading}
                    scroll={{x: 'max-content'}}
                    columns={[
                      {title: '编号', dataIndex: 'recordId'},
                      {title: '城市', dataIndex: 'warehouseCityName'},
                      {title: '仓库名称', dataIndex: 'warehouseName'},
                      {title: 'sku', dataIndex: 'materialSku'},
                      {title: '耗材名称', dataIndex: 'materialName'},
                      {title: '单位', dataIndex: 'materialUnit'},
                      {title: '变仓原因', dataIndex: 'reasonDesc'},
                      {title: '变动前数量', dataIndex: 'numBefore'},
                      {title: '变仓数量', dataIndex: 'numChange'},
                      {title: '变仓后数量', dataIndex: 'numAfter'},
                      {title: '变仓时间', dataIndex: 'insertTime'},
                      {
                        title: '操作人',
                        dataIndex: 'userName',
                      },
                      {
                        title: '操作',
                        dataIndex: 'reason',
                        fixed: 'right',
                        align: 'center',
                        render: (text, record) => {
                          const {batchReason, batchRemark} = record
                          return text === '2' ? (
                            <Button
                              type={'link'}
                              onClick={() => {
                                setReasonInfo({batchReason, batchRemark})
                                setVisible(true)
                              }}
                            >
                              查看备注
                            </Button>
                          ) : (
                            '/'
                          )
                        },
                      },
                    ]}
                    pagination={getPaginationProps(form, total)}
                    dataSource={reportList}
                  />
                </div>
              </>
            ),
          },
          {
            key: '2',
            label: '管家统计',
            forceRender: true,
            children: (
              <>
                <div className={styles.form}>
                  <Form
                    form={form2}
                    onFinish={queryList}
                    onReset={queryList}
                    labelCol={{flex: '80px'}}
                    initialValues={{
                      pageNum: 1,
                      pageSize: 10,
                      pageable: true,
                      startTime: moment().subtract(31, 'day').format(FORMAT_DATE_START),
                      endTime: moment().format(FORMAT_DATE_END),
                      warehouseType: '2',
                    }}
                  >
                    <Row gutter={30}>
                      <Col span={8}>
                        <Form.Item label={'管家名称'} name={'warehouseName'}>
                          <Input placeholder={'请输入管家名称'} allowClear />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item label={'耗材名称'} name={'materialName'}>
                          <Input placeholder={'请输入耗材名称'} allowClear />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item label={'变仓原因'} name={'reasonList'}>
                          <Select
                            placeholder={'请选择变仓原因'}
                            mode={'multiple'}
                            maxTagCount={1}
                            style={{minWidth: 160}}
                            allowClear
                            options={reasonType.map(item => ({label: item.label, value: item.id}))}
                            filterOption={(inputValue, option) => {
                              return option?.label?.indexOf(inputValue as any) > -1
                            }}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item
                          label={'变仓时间'}
                          name={'startTime'}
                          getValueFromEvent={(_, formatString) => {
                            form2.setFieldsValue({
                              endTime: formatString?.[1]
                                ? moment(formatString?.[1]).format(FORMAT_DATE_END)
                                : formatString?.[1],
                            })
                            return formatString?.[0]
                              ? moment(formatString?.[0]).format(FORMAT_DATE_START)
                              : formatString?.[0]
                          }}
                          getValueProps={value => ({
                            value: value ? [moment(value), moment(form2.getFieldValue('endTime'))] : value,
                          })}
                          rules={[
                            {
                              validator: (rule, value, callback) => {
                                if (value) {
                                  const [startTime, endTime] = value
                                  const intervalDay = moment(endTime).diff(startTime, 'day')
                                  if (intervalDay > 31) return callback('变仓时间间隔不超过31天')
                                } else {
                                  return callback('变仓时间不能为空')
                                }
                                return callback()
                              },
                            },
                          ]}
                        >
                          <RangePicker format={'YYYY-MM-DD'} allowClear={false} />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item label={'变仓对象'} name={'extraWarehouseName'}>
                          <Input placeholder={'请输入变仓对象'} allowClear />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item label={'操作人'} name={'userName'}>
                          <Input placeholder={'请输入操作人姓名'} allowClear />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item label={'批次号'} name={'batchId'}>
                          <Input placeholder={'请输入服务单号/批次号'} allowClear />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row justify={'end'}>
                      <Space>
                        <Button type={'primary'} htmlType={'submit'} onClick={() => form2.setFieldsValue({pageNum: 1})}>
                          查询
                        </Button>
                        <Button htmlType={'reset'}>重置</Button>
                      </Space>
                    </Row>
                  </Form>
                </div>
                <div className={commonStyles.tableSection}>
                  <Table
                    dataSource={reportList}
                    rowKey={'recordId'}
                    loading={loading}
                    scroll={{x: 'max-content'}}
                    columns={[
                      {title: '管家', dataIndex: 'warehouseName'},
                      {title: 'sku', dataIndex: 'materialSku'},
                      {title: '耗材名称', dataIndex: 'materialName'},
                      {title: '单位', dataIndex: 'materialUnit'},
                      {title: '变仓原因', dataIndex: 'reasonDesc'},
                      {title: '变动前数量', dataIndex: 'numBefore'},
                      {title: '变仓数量', dataIndex: 'numChange'},
                      {title: '变仓后数量', dataIndex: 'numAfter'},
                      {
                        title: '变仓对象',
                        dataIndex: 'extraWarehouseName',
                        render: text => <Typography.Text>{text || '/'}</Typography.Text>,
                      },
                      {title: '批次号', dataIndex: 'batchId'},
                      {title: '变仓时间', dataIndex: 'insertTime'},
                      {title: '操作人', dataIndex: 'userName', fixed: 'right'},
                    ]}
                    pagination={getPaginationProps(form2, total)}
                  />
                </div>
              </>
            ),
          },
          {
            key: '3',
            label: '盘点记录',
            forceRender: true,
            disabled: !auth('001146001'),
            children: (
              <>
                <div className={styles.form}>
                  <Form
                    form={form3}
                    onFinish={queryWarehouseCheckHistory}
                    onReset={queryWarehouseCheckHistory}
                    labelCol={{flex: '80px'}}
                    initialValues={{pageNum: 1, pageSize: 10, pageable: true}}
                  >
                    <Row gutter={30}>
                      <Col span={8}>
                        <Form.Item label={'城市'} name={'cityCode'}>
                          <Select
                            allowClear
                            options={cityList}
                            fieldNames={{label: 'name', value: 'code'}}
                            placeholder={'请选择城市'}
                            showSearch
                            filterOption={(inputValue, option) => option.name?.includes(inputValue)}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item label={'站点名称'} name={'name'}>
                          <Input allowClear placeholder={'请输入'} />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item
                          label={'盘点日期'}
                          name={'insertTimeBegin'}
                          getValueFromEvent={(_, formatString) => {
                            form3.setFieldsValue({insertTimeEnd: formatString?.[1]})
                            return formatString?.[0]
                          }}
                          getValueProps={value => ({
                            value: value ? [moment(value), moment(form3.getFieldValue('insertTimeEnd'))] : value,
                          })}
                        >
                          <RangePicker allowClear format={FORMAT_DATE} />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item label={'盘点人'} name={'userName'}>
                          <Input allowClear placeholder={'请输入'} />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row justify={'end'}>
                      <Space>
                        <Button type={'primary'} htmlType={'submit'} onClick={() => form3.setFieldsValue({pageNum: 1})}>
                          查询
                        </Button>
                        <Button htmlType={'reset'}>重置</Button>
                      </Space>
                    </Row>
                  </Form>
                </div>

                <div className={commonStyles.tableSection}>
                  <Table
                    rowKey={'historyId'}
                    loading={historyLoading}
                    scroll={{x: 'max-content'}}
                    dataSource={historyList}
                    columns={[
                      {title: '编号', dataIndex: 'historyId'},
                      {title: '城市', dataIndex: 'warehouseCityName'},
                      {title: '站点名称', dataIndex: 'warehouseName'},
                      {title: '盘点时间', dataIndex: 'insertTime'},
                      {title: '盘点人', dataIndex: 'userName'},
                      {
                        title: '操作',
                        render: (_, record) => (
                          <Button
                            type={'link'}
                            loading={recordLoadings.pick(record.historyId)}
                            onClick={async () => {
                              const {
                                response: {data},
                              } = await storageModel.stewardWarehouseCheckRecord({
                                warehouseId: record.warehouseId,
                                historyId: record.historyId,
                              })
                              Modal.confirm({
                                icon: null,
                                title: '盘点记录',
                                width: 800,
                                closable: true,
                                content: (
                                  <Table
                                    rowKey={'materialId'}
                                    dataSource={data?.[0]?.list}
                                    columns={[
                                      {title: 'SKU', dataIndex: 'materialSku'},
                                      {title: '物品名称', dataIndex: 'materialName'},
                                      {title: '规格', dataIndex: 'materialSpecification'},
                                      {title: '品牌', dataIndex: 'materialBrand'},
                                      {title: '系统剩余数量', dataIndex: 'numBefore'},
                                      {title: '实际剩余数量', dataIndex: 'numAfter'},
                                      {title: '盈亏数', dataIndex: 'numChange'},
                                    ]}
                                  />
                                ),
                              })
                            }}
                          >
                            查看
                          </Button>
                        ),
                      },
                    ]}
                    pagination={getPaginationProps(form3, historyTotal)}
                  />
                </div>
              </>
            ),
          },
        ]}
      />

      {/* 查看备注 */}
      <Modal
        title={'备注'}
        open={visible}
        footer={null}
        centered
        onCancel={() => {
          setVisible(false)
        }}
      >
        <div>
          <Space direction={'vertical'}>
            <Typography.Text style={{color: 'rgba(0, 0, 0, 0.65)'}}>
              入仓原因：<Typography.Text>{reasonInfo.batchReason}</Typography.Text>
            </Typography.Text>
            <Typography.Text style={{color: 'rgba(0, 0, 0, 0.65)'}}>
              入仓备注：<Typography.Text>{reasonInfo.batchRemark || '无'}</Typography.Text>
            </Typography.Text>
          </Space>
        </div>
      </Modal>
    </Page>
  )
}
