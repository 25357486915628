import React, {useCallback, useEffect, useRef, useState} from 'react'
import {useAppHistory, useAppLocation} from '@/common/hooks/useRouter'
import {condoModel} from '@/store/models/Condo'
import {Page} from '@/components/Page'
import {Button, Col, Divider, Form, Input, message, PageHeader, Row, Space, Typography} from 'antd'
import commonStyles from '@/assets/styles/common.module.less'
import {Marker} from '@/components/Map/Marker'
import {Mapview} from '@/components/Map'
import {useDebounce} from '@/common/hooks/useDebounce'

const DetailSectionTitle = React.memo(function (props: {title: string; top?: number}) {
  const {title, top} = props
  return (
    <div style={{marginTop: top}}>
      <Divider type={'vertical'} style={{borderColor: '#00B7AE', borderWidth: '6px', marginLeft: 0}} />
      <Typography.Text style={{fontWeight: 'bold', fontSize: '14px', color: '#000'}}>{title}</Typography.Text>
    </div>
  )
})

export default function ApartmentDataDetail() {
  const history = useAppHistory()
  const location = useAppLocation<'/apartment/detail'>()
  const condoId = location.state?.condoId

  const [form] = Form.useForm()
  const mapRef = useRef(null)

  const [condoDetail, setCondoDetail] = useState({} as CondoInfoVO)

  useEffect(() => {
    condoModel.condoGet({condoId}).then(({response: {data}}) => {
      setCondoDetail(data)
      mapRef.current?.panTo(data.lng, data.lat)
      form.setFieldsValue({
        condoId: data.condoId,
        lat: data.lat,
        lng: data.lng,
        condoAddress: data.condoAddress,
        address: data.condoAddress, // TODO：显示上次搜索的结果
      })
    })
  }, [condoId, form])

  const updateLoading = condoModel.condoUpdateLocation.useLoading()

  const enterSelectAddress = useCallback(
    async (address: string) => {
      if (address) {
        const point = await mapRef.current?.geocoder(address)
        mapRef.current?.panTo(point.lng, point.lat)
        form.setFieldsValue({lng: point.lng, lat: point.lat})
      }
    },
    [form]
  )

  const selectAddressDebounce = useDebounce(enterSelectAddress, 800, [form])

  return (
    <Page header={<PageHeader ghost={false} title={condoDetail.condoName} onBack={() => history.goBack()} />}>
      <div className={commonStyles.tableSection}>
        <DetailSectionTitle title={'当前定位信息'} />
        <Row style={{margin: '15px 0 30px'}}>
          <Col>地址：{condoDetail.condoAddress}</Col>
          <Col offset={1}>经度：{condoDetail.lng}</Col>
          <Col offset={1}>纬度：{condoDetail.lat}</Col>
        </Row>

        <DetailSectionTitle title={'新定位信息'} />
        <Form form={form}>
          <Row gutter={40} style={{marginTop: '30px'}}>
            <Col span={12}>
              <Form.Item name={'condoAddress'} label={'地址'} rules={[{required: true, message: '请输入地址'}]}>
                <Input
                  placeholder={'请输入'}
                  allowClear
                  onChange={event => {
                    const address = event.target.value
                    form.setFieldsValue({address})
                    selectAddressDebounce(address)
                  }}
                />
              </Form.Item>
              <Form.Item name={'lng'} label={'经度'} rules={[{required: true, message: '请输入经度'}]}>
                <Input placeholder={'请输入'} allowClear />
              </Form.Item>
              <Form.Item name={'lat'} label={'纬度'} rules={[{required: true, message: '请输入纬度'}]}>
                <Input placeholder={'请输入'} allowClear />
              </Form.Item>
              <Space style={{marginLeft: '42px'}}>
                <Button
                  type={'primary'}
                  loading={updateLoading}
                  onClick={async () => {
                    const formValues = form.getFieldsValue(true)
                    await condoModel.condoUpdateLocation(formValues)
                    message.success('更新成功！')
                    history.goBack()
                  }}
                >
                  更新并返回
                </Button>
                <Button onClick={() => history.goBack()}>取消</Button>
              </Space>
              <div style={{margin: '20px 0 0 42px'}}>
                <span>提示：更新定位后，若有需要，请在对应的服务单详情进行信息更新</span>
              </div>
            </Col>
            <Col span={12}>
              <Form.Item name={'address'} label={'选点'}>
                <Input
                  allowClear
                  placeholder={'输入搜索条件，回车搜索'}
                  onPressEnter={(event: any) => enterSelectAddress(event.target.value)}
                  onChange={event => selectAddressDebounce(event.target.value)}
                />
              </Form.Item>
              <Mapview
                onClick={point => form.setFieldsValue({lat: point.lat, lng: point.lng})}
                style={{height: 290, marginBottom: '20px'}}
                ref={mapRef}
              >
                <Form.Item noStyle shouldUpdate={(prev, next) => prev.lng !== next.lng || prev.lat !== next.lat}>
                  {f => {
                    const {lng, lat} = f.getFieldsValue()
                    return !!(lng && lat) && <Marker lng={lng} lat={lat} />
                  }}
                </Form.Item>
              </Mapview>
            </Col>
          </Row>
        </Form>
      </div>
    </Page>
  )
}
