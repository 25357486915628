import React, {useImperativeHandle, useState} from 'react'
import {Modal} from 'antd'
import {getImg} from '@/utils/util'

function LookImage(props, ref) {
  const [imagePreview, setImagePreview] = useState({show: false, imgUrl: ''})

  useImperativeHandle(ref, () => ({
    onDisplay: imgUrl => {
      setImagePreview({show: true, imgUrl})
    },
  }))

  return (
    <Modal
      visible={imagePreview.show}
      centered
      footer={null}
      zIndex={3000}
      onCancel={() => setImagePreview({show: false, imgUrl: ''})}
    >
      <img src={getImg(imagePreview.imgUrl)} alt={'图片详情'} style={{width: '472px', height: '472px'}} />
    </Modal>
  )
}

export default React.forwardRef(LookImage)
