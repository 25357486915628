import React from 'react'

interface Props {
  title: string
  style?: any
  right?: React.ReactNode
  bottom: number
  top: number
}
export default function DividerLine({title, style, right, bottom, top}: Props) {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginBottom: bottom,
        marginTop: top,
        ...style,
      }}
    >
      <div style={{height: 14, width: 6, backgroundColor: '#00B7AE'}} />
      <p style={{fontSize: '14px', fontWeight: 600, margin: '0px 0px 0px 5px', color: '#000000'}}>{title}</p>
      {right}
    </div>
  )
}

DividerLine.defaultProps = {
  top: 0,
  bottom: 0,
}
