import React, {useCallback, useEffect, useState} from 'react'
import {Page} from '@/components/Page'
import commonStyles from '@/assets/styles/common.module.less'
import {feedbackModel} from '@/store/models/FeedBack'
import {Button, Col, DatePicker, Form, Input, message, Modal, Row, Select, Space, Table, Tabs, Typography} from 'antd'
import {getPaginationProps} from '@/common/biz/getPaginationProps'
import {useAppHistory, useAppLocation} from '@/common/hooks/useRouter'
import {commonModel, useCodeArraySync, useCodeSync} from '@/store/models/Common'
import {usePermission} from '@/components/Permission'
import {useListener} from '@/common/hooks/listener'

export default function ComplaintWorkOrder() {
  const location = useAppLocation<'/complaint'>()
  const history = useAppHistory()
  const [list1, setList1] = useState<CsWorkOrderVO[]>([])
  const [total1, setTotal1] = useState(0)
  const [list2, setList2] = useState<CsWorkOrderVO[]>([])
  const [total2, setTotal2] = useState(0)
  const [list3, setList3] = useState<CsWorkOrderVO[]>([])
  const [total3, setTotal3] = useState(0)
  const [form1] = Form.useForm()
  const [form2] = Form.useForm()
  const [form3] = Form.useForm()
  const [auth] = usePermission()
  const [key, setKey] = useState(() => {
    const permissionMap = {
      '001173001': '1',
      '001174001': '2',
      '001175001': '3',
    }
    const permission = Object.keys(permissionMap).find(value => auth(value))
    return permissionMap[permission]
  })
  const loading = feedbackModel.csWorkOrderList.useLoading()
  const complaintType = useCodeSync('cs.work.order.complaint.type')
  const complaintTypeArr = useCodeArraySync('cs.work.order.complaint.type')
  const compensation = useCodeSync('cs.work.order.compensation.status')
  const punishmentReason = useCodeSync('cs.work.order.punishment.reason')

  const [resultForm] = Form.useForm()
  const [cancelForm] = Form.useForm()
  const [cancelVisible, setCancelVisible] = useState(false)

  const [cityList, setCityList] = useState<CityDataProps[]>([])

  useEffect(() => {
    commonModel
      .queryCityCode({type: '2', parentCode: null})
      .then(({response: {data}}) => setCityList(data.map(item => ({...item, key: item.code}))))
  }, [])

  const search = useCallback(async () => {
    let values = {}
    switch (key) {
      case '1':
        values = form1.getFieldsValue(true)
        break
      case '2':
        values = form2.getFieldsValue(true)
        break
      case '3':
        values = form3.getFieldsValue(true)
        break
    }
    const {
      response: {data, total},
    } = await feedbackModel.csWorkOrderList(values as CsWorkOrderDTO)
    if (key === '1') {
      setTotal1(total)
      setList1(data)
    }
    if (key === '2') {
      setTotal2(total)
      setList2(data)
    }
    if (key === '3') {
      setTotal3(total)
      setList3(data)
    }
  }, [form1, form2, form3, key])

  useEffect(() => {
    if (location.state?.orderId && location.state?.key) {
      location.state?.key === '2'
        ? form2.setFieldsValue({parentOrderId: location.state?.orderId})
        : form3.setFieldsValue({parentOrderId: location.state?.orderId})
      setKey(location.state?.key)
      window.history.replaceState(null, '')
    }
  }, [form2, form3, location])

  useEffect(() => {
    search()
  }, [search])

  useListener('ComplaintWorkOrder', () => {
    search()
  })

  return (
    <Page>
      <Tabs
        className={commonStyles.tabs}
        onChange={key => setKey(key)}
        activeKey={key}
        tabBarExtraContent={
          key === '1' &&
          auth('002173002') && (
            <Button type={'primary'} onClick={() => history.push('complaint/add')}>
              新增
            </Button>
          )
        }
        items={[
          auth('001173001') && {
            label: '投诉工单',
            key: '1',
            forceRender: true,
            children: (
              <>
                <div className={commonStyles.filterSection}>
                  <Form
                    form={form1}
                    initialValues={{pageNum: 1, pageSize: 10, pageable: true}}
                    onFinish={search}
                    onReset={search}
                  >
                    <Row>
                      <Col span={7}>
                        <Form.Item label={'输入查询'} name={'userName'}>
                          <Input placeholder={'人员名称'} allowClear />
                        </Form.Item>
                      </Col>
                      <Col span={7} offset={1}>
                        <Form.Item label={'工单号'} name={'orderId'}>
                          <Input placeholder={'请输入'} allowClear />
                        </Form.Item>
                      </Col>
                      <Col span={7} offset={1}>
                        <Form.Item label={'投诉日期'} name={'_complaintTime'}>
                          <DatePicker.RangePicker
                            onChange={(_, dateStrings) => {
                              const [complaintTimeBegin, complaintTimeEnd] = dateStrings ?? []
                              form1.setFieldsValue({
                                complaintTimeBegin: complaintTimeBegin ? complaintTimeBegin + ' 00:00:00' : '',
                                complaintTimeEnd: complaintTimeEnd ? complaintTimeEnd + ' 23:59:59' : '',
                              })
                            }}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={7}>
                        <Form.Item label={'投诉类别'} name={'complaintType'}>
                          <Select placeholder={'请选择'} allowClear>
                            {complaintTypeArr.map(item => (
                              <Select.Option value={item.id} key={item.id}>
                                {item.label}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={7} offset={1}>
                        <Form.Item label={'状态'} name={'cancel'}>
                          <Select placeholder={'请选择'} allowClear>
                            <Select.Option value={1}>无效</Select.Option>
                            <Select.Option value={0}>有效</Select.Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={7} offset={1}>
                        <Form.Item label={'城市'} name={'cityCode'}>
                          <Select
                            options={cityList}
                            showSearch
                            filterOption={(inputValue, option: any) => option.name?.indexOf(inputValue) > -1}
                            placeholder={'请选择'}
                            fieldNames={{value: 'code', label: 'name'}}
                            allowClear
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row justify={'end'}>
                      <Space>
                        <Button type={'primary'} htmlType={'submit'} onClick={() => form1.setFieldsValue({pageNum: 1})}>
                          查询
                        </Button>
                        <Button htmlType={'reset'}>重置</Button>
                      </Space>
                    </Row>
                  </Form>
                </div>
                <div className={commonStyles.filterSection}>
                  <Table
                    rowKey={'orderId'}
                    loading={loading}
                    scroll={{x: 'max-content'}}
                    columns={[
                      {title: '工单号', dataIndex: 'orderId'},
                      {title: '投诉日期', dataIndex: 'complaintTime'},
                      {
                        title: '客户类型',
                        dataIndex: 'customerType',
                        render: value => ({lessor: '房东', lessee: '租客'}[value]),
                      },
                      {title: '用户名称', dataIndex: 'userName'},
                      {title: '手机号', dataIndex: 'userMobile'},
                      {title: '城市', dataIndex: 'cityName'},
                      {title: '投诉类别', dataIndex: 'complaintType', render: value => complaintType[value]},
                      {
                        title: '责任判定',
                        dataIndex: 'responsibilityJudgment',
                        render: value => (value === '0' ? '无责' : '有责'),
                      },
                      {
                        title: '赔付情况',
                        dataIndex: 'compensationOrder',
                        render: value => (value === 0 ? '未赔付' : '已赔付'),
                      },
                      {
                        title: '处罚情况',
                        dataIndex: 'punishmentOrder',
                        render: value => (value === 0 ? '未处罚' : '已处罚'),
                      },
                      {
                        title: '处理结果',
                        dataIndex: 'processResult',
                        render: value => (value ? (value === '0' ? '不满意' : '满意') : '/'),
                      },
                      {title: '处理人', dataIndex: 'processBy', render: value => value ?? '/'},
                      {title: '处理时间', dataIndex: 'processTime', render: value => value ?? '/'},
                      {title: '状态', dataIndex: 'cancelOrder', render: value => (value === 0 ? '有效' : '无效')},
                      {
                        title: '操作',
                        fixed: 'right',
                        render: (_, record) => {
                          const {cancelOrder, orderId, compensationOrder, punishmentOrder, processResult} = record
                          return (
                            <Space>
                              <Button
                                type={'link'}
                                style={{paddingLeft: 0, paddingRight: 0}}
                                onClick={() => history.push('/complaint/detail', {orderId, orderType: 0})}
                              >
                                查看
                              </Button>

                              {cancelOrder === 0 && (
                                <>
                                  {punishmentOrder === 0 && auth('003174002') && (
                                    <Button
                                      type={'link'}
                                      style={{paddingLeft: 0, paddingRight: 0}}
                                      onClick={() => history.push('/complaint/punish', {orderId})}
                                    >
                                      处罚
                                    </Button>
                                  )}
                                  {compensationOrder === 0 && auth('003175002') && (
                                    <Button
                                      type={'link'}
                                      style={{paddingLeft: 0, paddingRight: 0}}
                                      onClick={() => history.push('/complaint/pay', {orderId})}
                                    >
                                      赔付
                                    </Button>
                                  )}
                                  {!processResult && auth('018173002') && (
                                    <Button
                                      type={'link'}
                                      style={{paddingLeft: 0, paddingRight: 0}}
                                      onClick={() => {
                                        Modal.confirm({
                                          title: '处理结果',
                                          icon: null,
                                          centered: true,
                                          width: 500,
                                          onOk: async () => {
                                            const {processDescription, processResult} = resultForm.getFieldsValue(true)
                                            await feedbackModel.csWorkOrderProcess({
                                              id: orderId,
                                              processDescription,
                                              processResult,
                                            })
                                            await search()
                                          },
                                          afterClose: () => resultForm.resetFields(),
                                          content: (
                                            <Form form={resultForm} labelCol={{flex: '120px'}}>
                                              <Form.Item
                                                label={'处理流程问题'}
                                                name={'processDescription'}
                                                rules={[{required: true, message: '请输入处理流程问题'}]}
                                              >
                                                <Input.TextArea
                                                  rows={3}
                                                  placeholder={'请输入'}
                                                  maxLength={500}
                                                  showCount
                                                />
                                              </Form.Item>
                                              <Form.Item label={'处理结果'} name={'processResult'}>
                                                <Select placeholder={'请选择'}>
                                                  <Select.Option value={'0'}>不满意</Select.Option>
                                                  <Select.Option value={'1'}>满意</Select.Option>
                                                </Select>
                                              </Form.Item>
                                            </Form>
                                          ),
                                        })
                                      }}
                                    >
                                      处理结果
                                    </Button>
                                  )}
                                  {auth('021173002') && (
                                    <Button
                                      type={'link'}
                                      style={{paddingLeft: 0, paddingRight: 0}}
                                      onClick={() => {
                                        cancelForm.setFieldsValue({orderId, cancelOrder: 1})
                                        setCancelVisible(true)
                                      }}
                                    >
                                      作废
                                    </Button>
                                  )}
                                </>
                              )}
                            </Space>
                          )
                        },
                      },
                    ]}
                    dataSource={list1}
                    pagination={getPaginationProps(form1, total1)}
                  />
                </div>
              </>
            ),
          },
          auth('001174001') && {
            label: '赔付工单',
            key: '2',
            forceRender: true,
            children: (
              <>
                <div className={commonStyles.filterSection}>
                  <Form
                    form={form2}
                    initialValues={{pageNum: 1, pageSize: 10, pageable: true, orderType: 1}}
                    onFinish={search}
                    onReset={search}
                  >
                    <Row>
                      <Col span={7}>
                        <Form.Item label={'输入查询'} name={'userName'}>
                          <Input placeholder={'人员名称'} allowClear />
                        </Form.Item>
                      </Col>
                      <Col span={7} offset={1}>
                        <Form.Item label={'原单号'} name={'parentOrderId'}>
                          <Input placeholder={'请输入'} allowClear />
                        </Form.Item>
                      </Col>
                      <Col span={7} offset={1}>
                        <Form.Item label={'状态'} name={'cancel'}>
                          <Select placeholder={'请选择'} allowClear>
                            <Select.Option value={1}>无效</Select.Option>
                            <Select.Option value={0}>有效</Select.Option>
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row justify={'end'}>
                      <Space>
                        <Button type={'primary'} htmlType={'submit'} onClick={() => form2.setFieldsValue({pageNum: 1})}>
                          查询
                        </Button>
                        <Button htmlType={'reset'}>重置</Button>
                      </Space>
                    </Row>
                  </Form>
                </div>
                <div className={commonStyles.filterSection}>
                  <Table
                    rowKey={'orderId'}
                    loading={loading}
                    columns={[
                      {
                        title: '原单号',
                        dataIndex: 'parentOrderId',
                      },
                      {
                        title: '服务单号',
                        dataIndex: 'serviceOrderId',
                        render: value => {
                          return value ? (
                            <Space direction={'vertical'} size={0}>
                              {value.split(',').map(item => (
                                <Typography.Text key={item}>{item}</Typography.Text>
                              ))}
                            </Space>
                          ) : (
                            '/'
                          )
                        },
                      },
                      {title: '赔付客户', dataIndex: 'userName'},
                      {title: '手机号', dataIndex: 'userMobile'},
                      {
                        title: '赔付类型',
                        dataIndex: 'compensationStatus',
                        render: value => {
                          const status = value.split('-')[0]
                          return compensation[status]
                        },
                      },
                      {
                        title: '赔付情况',
                        dataIndex: 'compensationStatus',
                        render: (value, record) => {
                          const status = value.split('-')[0]
                          if (status === 'other') {
                            return record.compensationOtherStatusRemark
                          }
                          const compensationStatus = commonModel.getCodeSync(status)
                          return compensationStatus[value]
                        },
                      },
                      {title: '被投诉部门', dataIndex: 'complainedDept'},
                      {title: '被投诉人员', dataIndex: 'complainedEmp', render: value => value ?? '/'},
                      {title: '操作人', dataIndex: 'compensationBy'},
                      {title: '操作时间', dataIndex: 'compensationTime'},
                      {
                        title: '状态',
                        dataIndex: 'cancelOrder',
                        render: value => ([1, 3].includes(value) ? '无效' : '有效'),
                      },
                      {
                        title: '操作',
                        fixed: 'right',
                        render: (_, {orderId, parentOrderId, cancelOrder}) => {
                          return (
                            <Space>
                              <Button
                                style={{paddingLeft: 0, paddingRight: 0}}
                                type={'link'}
                                onClick={() => history.push('/complaint/detail', {orderId, orderType: 1})}
                              >
                                查看
                              </Button>
                              {![1, 3].includes(cancelOrder) && auth('021175002') && (
                                <Button
                                  type={'link'}
                                  onClick={() => {
                                    cancelForm.setFieldsValue({orderId: parentOrderId, cancelOrder: 3})
                                    setCancelVisible(true)
                                  }}
                                >
                                  作废
                                </Button>
                              )}
                            </Space>
                          )
                        },
                      },
                    ]}
                    scroll={{x: 'max-content'}}
                    dataSource={list2}
                    pagination={getPaginationProps(form2, total2)}
                  />
                </div>
              </>
            ),
          },
          auth('001175001') && {
            label: '处罚工单',
            key: '3',
            forceRender: true,
            children: (
              <>
                <div className={commonStyles.filterSection}>
                  <Form
                    form={form3}
                    initialValues={{pageNum: 1, pageSize: 10, pageable: true, orderType: 2}}
                    onFinish={search}
                    onReset={search}
                  >
                    <Row>
                      <Col span={7}>
                        <Form.Item label={'输入查询'} name={'userName'}>
                          <Input placeholder={'人员名称'} allowClear />
                        </Form.Item>
                      </Col>
                      <Col span={7} offset={1}>
                        <Form.Item label={'原单号'} name={'parentOrderId'}>
                          <Input placeholder={'请输入'} allowClear />
                        </Form.Item>
                      </Col>
                      <Col span={7} offset={1}>
                        <Form.Item label={'状态'} name={'cancel'}>
                          <Select placeholder={'请选择'} allowClear>
                            <Select.Option value={1}>无效</Select.Option>
                            <Select.Option value={0}>有效</Select.Option>
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row justify={'end'}>
                      <Space>
                        <Button type={'primary'} htmlType={'submit'} onClick={() => form3.setFieldsValue({pageNum: 1})}>
                          查询
                        </Button>
                        <Button htmlType={'reset'}>重置</Button>
                      </Space>
                    </Row>
                  </Form>
                </div>
                <div className={commonStyles.filterSection}>
                  <Table
                    rowKey={'orderId'}
                    scroll={{x: 'max-content'}}
                    loading={loading}
                    columns={[
                      {
                        title: '原单号',
                        dataIndex: 'parentOrderId',
                      },
                      {
                        title: '服务单号',
                        dataIndex: 'serviceOrderId',
                        render: value => {
                          return value ? (
                            <Space direction={'vertical'} size={0}>
                              {value.split(',').map(item => (
                                <Typography.Text key={item}>{item}</Typography.Text>
                              ))}
                            </Space>
                          ) : (
                            '/'
                          )
                        },
                      },
                      {title: '用户名称', dataIndex: 'userName'},
                      {title: '处罚理由', dataIndex: 'punishmentReason', render: value => punishmentReason[value]},
                      {title: '处罚金额', dataIndex: 'punishmentAmt'},
                      {title: '被投诉部门', dataIndex: 'complainedDept'},
                      {title: '被投诉人员', dataIndex: 'complainedEmp', render: value => value ?? '/'},
                      {title: '操作人', dataIndex: 'punishmentBy'},
                      {title: '操作时间', dataIndex: 'punishmentTime'},
                      {
                        title: '状态',
                        dataIndex: 'cancelOrder',
                        render: value => ([1, 2].includes(value) ? '无效' : '有效'),
                      },
                      {
                        title: '操作',
                        fixed: 'right',
                        render: (_, {orderId, parentOrderId, cancelOrder}) => {
                          return (
                            <Space>
                              <Button
                                style={{paddingLeft: 0, paddingRight: 0}}
                                type={'link'}
                                onClick={() => history.push('/complaint/detail', {orderId, orderType: 2})}
                              >
                                查看
                              </Button>
                              {![1, 2].includes(cancelOrder) && auth('021174002') && (
                                <Button
                                  type={'link'}
                                  onClick={() => {
                                    cancelForm.setFieldsValue({orderId: parentOrderId, cancelOrder: 2})
                                    setCancelVisible(true)
                                  }}
                                >
                                  作废
                                </Button>
                              )}
                            </Space>
                          )
                        },
                      },
                    ]}
                    dataSource={list3}
                    pagination={getPaginationProps(form3, total3)}
                  />
                </div>
              </>
            ),
          },
        ]}
      />

      <Modal
        open={cancelVisible}
        title={'作废'}
        width={500}
        centered
        footer={null}
        onCancel={() => setCancelVisible(false)}
        destroyOnClose
        afterClose={() => cancelForm.resetFields()}
      >
        <Form
          form={cancelForm}
          onFinish={async () => {
            const {cancelResult, orderId, cancelOrder} = cancelForm.getFieldsValue(true)
            await feedbackModel.csWorkOrderCancel({id: orderId, cancelResult, cancelOrder})
            await search()
            setCancelVisible(false)
            message.success('作废成功！')
          }}
        >
          <Form.Item name={'cancelResult'} label={'作废原因'} rules={[{required: true, message: '请输入作废原因'}]}>
            <Input.TextArea showCount maxLength={500} rows={3} placeholder={'请输入'} />
          </Form.Item>
          <Row justify={'end'}>
            <Space>
              <Button type={'primary'} htmlType={'submit'}>
                确认
              </Button>
              <Button onClick={() => setCancelVisible(false)}>取消</Button>
            </Space>
          </Row>
        </Form>
      </Modal>
    </Page>
  )
}
