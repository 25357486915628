import React, {useEffect, useState, useRef, useCallback} from 'react'
import {
  Form,
  Input,
  DatePicker,
  Col,
  Row,
  Button,
  Space,
  Typography,
  Table,
  message,
  Divider,
  Select,
  Tabs,
  Modal,
  Drawer,
  Descriptions,
  Tag,
  InputNumber,
  Checkbox,
} from 'antd'
import {stewardModel} from '@/store/models/Steward'
import {debounce, downloadBlob, getImg, timeInterval} from '@/utils/util'
import {serviceListTempUrl} from '@/common/config'

import './index.less'
import {Breadcrumb} from '@/components/Breadcrumb'
import commonStyles from '@/assets/styles/common.module.less'
import {useCodeArraySync, useCodeSync} from '@/store/models/Common'
import {usePermission} from '@/components/Permission'
import {sysUserModel} from '@/store/models/SysUser'
import {customerModel} from '@/store/models/Customer'
import moment from 'moment'
import CutServiceDetail from '@/pages/client/components/CutServiceDetail'
import CutService from '@/pages/client/components/CutService'
import DividerLine from '@/pages/property/component/DividerLine'
import {useAppHistory, useAppLocation} from '@/common/hooks/useRouter'
import {useFocusEffect} from '@/common/hooks/useFocusEffect'
import {getPaginationProps} from '@/common/biz/getPaginationProps'
import {Page} from '@/components/Page'
import {DownOutlined, UpOutlined, InfoCircleFilled} from '@ant-design/icons'
import {DetailSectionImage} from '@/pages/steward/StewardDetail'
import {FORMAT_DATE_END, FORMAT_DATE_START, FORMAT_DATETIME} from '@/common/date'
import {APP_ID} from '@/common/constants'
import {sysModel} from '@/store/models/Sys'
import ChargeApprovalList from '@/pages/steward/components/ChargeApproalList'

const {Option} = Select

const orderStatusCode = [
  {id: '', label: '全部'},
  {id: '7', label: '待支付'},
  {id: '1', label: '待沟通'},
  {id: '2', label: '已沟通'},
  {id: '3', label: '上门中'},
  {id: '4', label: '服务中'},
  {id: '99', label: '待评价'},
  {id: '5', label: '已完成'},
  {id: '6', label: '已取消'},
]

export type StewardOrderVO = StewardOrderQueryVO & {orderMaker: string; orderMakerMobile: string}

const TimeRender = React.memo((props: {time: string}) => {
  const {time} = props
  if (time) {
    const [item1, item2] = time.split(' ')
    return (
      <Space direction={'vertical'} size={0}>
        <span>{item1}</span>
        <span>{item2}</span>
      </Space>
    )
  } else return <span>/</span>
})

export default function ServiceOrderList() {
  const [auth] = usePermission()
  const history = useAppHistory()
  const location = useAppLocation<'/order-list'>()

  const orderStatusType = useCodeSync('steward.orderStatus')
  const orderVerifyArr = useCodeArraySync('steward.order.verify')
  const stewardOrderReassignReasonArr = useCodeArraySync('steward.order.reassignReason')
  const stewardOrderReassignReasonCode = useCodeSync('steward.order.reassignReason')

  const [collapse, setCollapse] = useState(false)

  const [form] = Form.useForm()
  const [tableList, setTableList] = useState<StewardOrderVO[]>([])

  const [listTotal, setListTotal] = useState(0)
  const loading = stewardModel.stewardAdminOrderList.useLoading()
  const [selectedRows, setSelectedRows] = useState<StewardOrderQueryVO[]>([])
  // const uploadExcelRef = useRef(null)

  const [landlordOption, setLandlordOption] = useState<LessorCustomerListVO[]>([])

  const [appList, setAppList] = useState<{label: string; value: string}[]>([])

  useEffect(() => {
    stewardModel
      .stewardOpenAppList({appStatus: 1, pageable: false, pageSize: 1, pageNum: 1})
      .then(({response: {data}}) => setAppList(data.map(item => ({value: item.appId, label: item.appName}))))
  }, [])

  // 导出所有结果
  const [isExport, setIsExport] = useState<boolean>(false)
  const [exportForm] = Form.useForm()
  // 默认选中
  const [selectList, setSelectList] = useState<{label: string; value: string}[]>([])
  // 可选列表
  const [remainList, setRemainList] = useState<{label: string; value: string}[]>([])

  // 重新分配
  const [userList, setUserList] = useState<SysUserVO[]>([])
  const [redistributionVisible, setRedistributionVisible] = useState(false)
  const [rForm] = Form.useForm()

  const [activeKey, setActiveKey] = useState(() => {
    const permissionMap = {
      '001041001': '1',
      '001102001': '3',
      '001202001': '4',
    }
    const key = Object.keys(permissionMap).find(item => auth(item))
    return permissionMap[key]
  })

  const searchLandlord = useCallback(
    debounce(async value => {
      const {
        response: {data},
      } = await customerModel.customerList({certName: value} as LessorCustomerListDTO)
      setLandlordOption(data)
    }),
    []
  )

  const [cutVisible, setCutVisible] = useState(null)
  const addRef = useRef(null)

  const queryStewardOrderList = useCallback(() => {
    const {_timeMake, _timeFinished, _timeAppointBegin, ...formValue} = form.getFieldsValue(true)
    const [timeMakeBegin, timeMakeEnd] = _timeMake
    const [timeFinishedBegin, timeFinishedEnd] = _timeFinished ?? []
    const [timeAppointBegin, timeAppointEnd] = _timeAppointBegin ?? []
    let propertyAddr = formValue.propertyAddr
    if (propertyAddr) {
      propertyAddr = propertyAddr?.replace(/-\s?/, '-')
    }
    stewardModel
      .stewardAdminOrderList({
        ...formValue,
        propertyAddr,
        timeMakeBegin,
        timeMakeEnd,
        timeFinishedBegin,
        timeFinishedEnd,
        timeAppointBegin,
        timeAppointEnd,
      })
      .then(({response: {data, total}}) => {
        setListTotal(total)
        setTableList(
          data.map(item => {
            return {
              ...item,
              orderMaker: item.orderMakerType === 'lessor' ? item.lessorName : item.lesseeName,
              orderMakerMobile: item.orderMakerType === 'lessor' ? item.lessorMobile : item.lesseeMobile,
            }
          })
        )
      })
  }, [form])

  useEffect(function () {
    // 服务人员列表
    sysUserModel.sysUserList({roleId: 'steward,cleaner', status: '1'}).then(({response: {data}}) => setUserList(data))
  }, [])

  // 查询导出所有结果的标题列表
  useEffect(() => {
    stewardModel.stewardAdminOrderExportKeyValue().then(({response: {data}}) => {
      const selectArr = []
      const arr = []
      Object.keys(data).map(item =>
        data[item].includes('*')
          ? selectArr.push({label: data[item].substring(0, data[item].length - 1), value: item})
          : arr.push({label: data[item], value: item})
      )
      setSelectList(selectArr)
      setRemainList(arr)
    })
  }, [])

  // 拿本地记录的上次选中的标题
  useEffect(() => {
    exportForm.setFieldsValue({
      list2: JSON.parse(localStorage.getItem('exportList')) || [],
    })
  }, [exportForm])

  // 导出所有结果
  async function exportAllResult(param) {
    await form.validateFields()
    const {_timeMake, _timeFinished, _timeAppointBegin, ...formValue} = form.getFieldsValue(true)
    const [timeMakeBegin, timeMakeEnd] = _timeMake
    const [timeFinishedBegin, timeFinishedEnd] = _timeFinished ?? []
    const [timeAppointBegin, timeAppointEnd] = _timeAppointBegin ?? []
    downloadBlob({
      url: '/steward/admin/order/export',
      body: {
        ...formValue,
        pageable: false,
        orderStatus: formValue.orderStatus === '0' ? '' : formValue.orderStatus,
        timeMakeBegin,
        timeMakeEnd,
        timeFinishedBegin,
        timeFinishedEnd,
        timeAppointBegin,
        timeAppointEnd,
        param,
      },
    })
  }

  // 重新分配
  function redistribution() {
    if (selectedRows.length === 0) return message.error('请选择服务单')
    if (selectedRows.some(item => ['5', '6', '7', '99'].includes(item.orderStatus))) {
      return message.error('仅待沟通、已沟通、上门中、服务中的服务单支持重新分配，请重新选择')
    }
    if (selectedRows.some(item => item.connectionCheckStatus === '1')) {
      return message.error('所选服务单中存在待处理的电联核实申请，请检查后重新分配')
    }
    if (selectedRows.some(item => !!item.cancelAuditId)) return message.error('不可重新分配待取消服务单')
    setRedistributionVisible(true)
  }

  // 导出验房报告
  function exportInspectReport() {
    if (selectedRows.length === 0) return message.error('请选择服务单！')
    if (selectedRows.some(item => item.categoryThi !== '92d9de26c34d4f7f91f61cfa52e6ded8'))
      return message.error('该功能仅支持验房服务单，请检查所选项！')
    if (selectedRows.some(item => !['5', '96', '99'].includes(item.orderStatus)))
      return message.error('请选择已完成的验房服务单！')

    downloadBlob({
      url: '/steward/admin/inspect-excel',
      body: selectedRows.map(item => item.orderId),
    }).then(() => {
      setSelectedRows([])
    })
  }

  // 导出转单记录
  async function exportReassign() {
    await form.validateFields()
    const {_timeMake, _timeFinished, _timeAppointBegin, ...formValue} = form.getFieldsValue(true)
    const [timeMakeBegin, timeMakeEnd] = _timeMake
    const [timeFinishedBegin, timeFinishedEnd] = _timeFinished ?? []
    const [timeAppointBegin, timeAppointEnd] = _timeAppointBegin ?? []
    downloadBlob({
      url: '/steward/admin/order/export-reassign',
      body: {
        ...formValue,
        pageable: false,
        orderStatus: formValue.orderStatus === '0' ? '' : formValue.orderStatus,
        timeMakeBegin,
        timeMakeEnd,
        timeFinishedBegin,
        timeFinishedEnd,
        timeAppointBegin,
        timeAppointEnd,
      },
    }).catch(e => {
      message.error(e.message)
    })
  }

  // 服务申请
  const applicationStatus = useCodeArraySync('steward.order.application.status')
  const applicationStatusCode = useCodeSync('steward.order.application.status')
  const applicationResult = useCodeArraySync('steward.order.application.result')
  const applicationResultType = useCodeSync('steward.order.application.result')
  const [applyForm] = Form.useForm()
  const [applyList, setApplyList] = useState<StewardOrderApplicationVO[]>([])
  const [applyTotal, setApplyTotal] = useState(0)
  const [applyInfo, setApplyInfo] = useState({} as StewardOrderApplicationVO)
  const applySearchLoading = stewardModel.stewardOrderApplicationList.useLoading()
  // 选中的图片
  const [selectImg, setSelectImg] = useState<{imgUrl: string; visible: boolean}>({imgUrl: '', visible: false})

  const applyListSearch = useCallback(() => {
    const formValues = applyForm.getFieldsValue(true)
    stewardModel.stewardOrderApplicationList(formValues).then(({response: {data, total}}) => {
      setApplyList(data)
      setApplyTotal(total)
    })
  }, [applyForm])

  const {propertyCode, lessorMobile, lesseeMobile, orderMakerType, orderStatus, timeMakeEnd, timeMakeBegin} =
    location.state ?? {}
  useEffect(() => {
    if (propertyCode || lesseeMobile || lessorMobile || orderMakerType) {
      form.setFieldsValue({
        propertyCode,
        lessorMobile,
        lesseeMobile,
        orderMakerType,
        timeMakeBegin,
        timeMakeEnd,
        orderStatus,
        _timeMake: [timeMakeBegin, timeMakeEnd],
      })
      location.state = {}
    }
  }, [
    form,
    lesseeMobile,
    lessorMobile,
    location,
    location.state,
    orderMakerType,
    orderStatus,
    propertyCode,
    timeMakeBegin,
    timeMakeEnd,
  ])

  useFocusEffect(() => {
    switch (activeKey) {
      case '1':
        return form.submit()
      case '3':
        return applyForm.submit()
    }
  }, 'PUSH|POP')

  useEffect(() => {
    switch (activeKey) {
      case '1':
        return form.submit()
      case '3':
        return applyForm.submit()
    }
  }, [activeKey, applyForm, form])

  // 外部订单信息
  const [outOrderForm] = Form.useForm()

  const [modal, contextHolder] = Modal.useModal()
  const [takeOrderForm] = Form.useForm()
  const takeLoading = stewardModel.stewardOrderTake.useLoading()
  const takeOrders = async () => {
    if (!selectedRows.length) return message.warning('请选择服务单')
    const msg = '请选择渠道为龙湖冠寓，服务单状态为待沟通且服务单有管家的服务单'
    if (selectedRows.some(value => value.appId !== APP_ID.GUANYU)) return message.warning(msg)
    if (selectedRows.some(value => value.orderStatus !== '1')) return message.warning(msg)
    if (selectedRows.some(value => !value.stewardId)) return message.warning(msg)

    const handle = modal.confirm({
      icon: null,
      title: '接单',
      width: 500,
      content: (
        <Form form={takeOrderForm} labelCol={{flex: '110px'}}>
          <Form.Item
            name={'appointTime'}
            label={'约定上门时间'}
            rules={[
              {required: true, message: '请选择约定上门时间'},
              {
                validator: (rule, value, callback) => {
                  if (value && moment(value).isBefore(new Date(), 'second'))
                    return callback('约定上门时间不能大于当前时间')
                  callback()
                },
              },
            ]}
            getValueFromEvent={(event, dateString) => dateString}
            getValueProps={value => ({value: value ? moment(value) : value})}
          >
            <DatePicker
              showTime
              format={FORMAT_DATETIME}
              style={{width: '100%'}}
              placeholder={'请选择'}
              disabledDate={date => !date.isSameOrAfter(new Date(), 'date')}
            />
          </Form.Item>
          <Form.Item name={'reassignReason'} label={'订单备注'}>
            <Input.TextArea placeholder={'请输入'} />
          </Form.Item>
        </Form>
      ),
      onCancel: () => {
        takeOrderForm.resetFields()
      },
      onOk: async () => {
        await takeOrderForm.validateFields()
        const values: StewardOrderAssignServerDTO = takeOrderForm.getFieldsValue(true)
        handle.update({okButtonProps: {loading: true}})
        try {
          for (const row of selectedRows) {
            await stewardModel.stewardOrderTake({
              appointTime: values.appointTime,
              reassignReason: values.reassignReason,
              cleanerId: row.stewardId,
              orderId: row.orderId,
            } as StewardOrderAssignServerDTO)
          }
          message.success('提交成功！')
          form.submit()
          setSelectedRows([])
          takeOrderForm.resetFields()
        } finally {
          handle.update({okButtonProps: {loading: false}})
        }
      },
    })
  }

  return (
    <Page
      breadcrumb={
        <Breadcrumb
          right={
            <>
              {activeKey === '1' && (
                <Space>
                  {auth('002182001') && (
                    <Button loading={takeLoading} onClick={takeOrders}>
                      接单
                    </Button>
                  )}
                  {auth('002162003') && (
                    <Button
                      onClick={async () => {
                        outOrderForm.resetFields()
                        const {
                          response: {data},
                        } = await stewardModel.stewardOpenAppList({pageNum: 1, pageSize: 10, pageable: false})

                        Modal.confirm({
                          title: '外部订单信息同步',
                          icon: null,
                          centered: true,
                          width: 500,
                          onOk: async () => {
                            await outOrderForm.validateFields()
                            const values = outOrderForm.getFieldsValue(true)
                            await stewardModel.stewardOpenOrderSync(values)
                            queryStewardOrderList()
                            message.success('同步成功！')
                          },
                          content: (
                            <Form form={outOrderForm}>
                              <Form.Item
                                label={'渠道选择'}
                                name={'appId'}
                                rules={[{required: true, message: '请选择渠道'}]}
                              >
                                <Select
                                  options={data}
                                  fieldNames={{label: 'appName', value: 'appId'}}
                                  placeholder={'请输入'}
                                />
                              </Form.Item>
                              <Form.Item
                                label={'外部订单号'}
                                name={'appOrderId'}
                                rules={[{required: true, message: '请输入订单号'}]}
                              >
                                <Input placeholder={'请输入'} />
                              </Form.Item>
                            </Form>
                          ),
                        })
                      }}
                    >
                      外部订单信息同步
                    </Button>
                  )}
                  {auth('007075001') && <Button onClick={exportReassign}>导出转单记录</Button>}
                  {auth('007066001') && <Button onClick={exportInspectReport}>导出验房报告</Button>}
                  {auth('022041002') && <Button onClick={redistribution}>重新分配</Button>}
                  {auth('007020001') && (
                    <Button href={serviceListTempUrl} download>
                      下载导入模版
                    </Button>
                  )}
                  {/*{auth('011019001') && (*/}
                  {/*  <Button onClick={() => uploadExcelRef.current.uploadExcel()}>导入已沟通服务单</Button>*/}
                  {/*)}*/}
                  {/*<UploadExcel*/}
                  {/*  uri={'/steward/admin/upload-excel'}*/}
                  {/*  onSuccess={() => {*/}
                  {/*    message.success('导入成功')*/}
                  {/*    queryStewardOrderList()*/}
                  {/*  }}*/}
                  {/*  title={'导入已沟通服务单'}*/}
                  {/*  ref={uploadExcelRef}*/}
                  {/*/>*/}
                  {auth('007041001') && (
                    <Button
                      onClick={() => {
                        setIsExport(true)
                      }}
                    >
                      导出所有结果
                    </Button>
                  )}
                </Space>
              )}
            </>
          }
        />
      }
    >
      {contextHolder}
      <Tabs
        activeKey={activeKey}
        onChange={activeKey => setActiveKey(activeKey)}
        tabBarStyle={{backgroundColor: '#fff', margin: 0, paddingLeft: '20px'}}
      >
        {auth('001041001') && (
          <Tabs.TabPane tab={'常规服务单'} key={'1'}>
            <Form
              form={form}
              onFinish={queryStewardOrderList}
              onReset={queryStewardOrderList}
              labelCol={{flex: '124px'}}
              initialValues={{
                pageNum: 1,
                pageSize: 10,
                pageable: true,
                sortType: '2',
                orderStatus: '',
                _timeMake: [moment().subtract(31, 'day').format(FORMAT_DATE_START), moment().format(FORMAT_DATE_END)],
              }}
            >
              <div className={commonStyles.filterSection} style={{marginTop: 20}}>
                <Form.Item
                  noStyle
                  shouldUpdate={(prevValues, nextValues) => prevValues.orderStatus !== nextValues.orderStatus}
                >
                  {form1 => {
                    return (
                      <>
                        <span style={{fontSize: '14px', marginRight: '20px', color: '#000'}}>所属类目：</span>
                        {orderStatusCode.map(item => (
                          <Button
                            key={item.id}
                            type={form1.getFieldValue('orderStatus') === item.id ? 'primary' : 'text'}
                            size={'small'}
                            onClick={() => {
                              form1.setFields([
                                {name: 'orderStatus', value: item.id},
                                {name: 'pageNum', value: 1},
                              ])
                              form1.submit()
                            }}
                            style={{minWidth: '70px', margin: '0 10px'}}
                          >
                            {item.label}
                          </Button>
                        ))}
                      </>
                    )
                  }}
                </Form.Item>
                <Divider dashed />

                <Row gutter={20} style={{flexWrap: 'wrap'}}>
                  <Col span={8}>
                    <Form.Item name={'propertyAddr'} label={'名称'}>
                      <Input placeholder={'请输入房源标题'} allowClear />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name={'_timeMake'}
                      label={'下单时间'}
                      getValueProps={value => ({value: value ? value.map(item => moment(item)) : []})}
                      getValueFromEvent={(_, dateStrings) => {
                        const [timeMakeBegin, timeMakeEnd] = dateStrings
                        if (timeMakeBegin && timeMakeEnd) {
                          return [
                            moment(timeMakeBegin).format(FORMAT_DATE_START),
                            moment(timeMakeEnd).format(FORMAT_DATE_END),
                          ]
                        }
                        return dateStrings?.[0]
                      }}
                    >
                      <DatePicker.RangePicker />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name={'categorySearch'} label={'下单类别'}>
                      <Input placeholder={'请输入下单类别'} allowClear />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name={'customerId'} label={'房东姓名'}>
                      <Select
                        showSearch
                        placeholder={'房东姓名'}
                        defaultActiveFirstOption={false}
                        showArrow={false}
                        filterOption={false}
                        onSearch={searchLandlord}
                        notFoundContent={null}
                        allowClear
                      >
                        {landlordOption.map(value => (
                          <Select.Option key={value.userId} value={value.userId}>
                            {`${value.certName}  -  ${value.mobile}`}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name={'lesseeName'} label={'租客姓名'}>
                      <Input placeholder={'租客姓名'} allowClear />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name={'stewardName'} label={'管家姓名'}>
                      <Input placeholder={'管家姓名'} allowClear />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name={'_timeFinished'}
                      label={'完成时间'}
                      getValueProps={value => ({value: value ? value.map(item => moment(item)) : []})}
                      getValueFromEvent={(_, dateStrings) => {
                        const [timeFinishedBegin, timeFinishedEnd] = dateStrings
                        if (timeFinishedBegin && timeFinishedEnd) {
                          return [
                            moment(timeFinishedBegin).format(FORMAT_DATE_START),
                            moment(timeFinishedEnd).format(FORMAT_DATE_END),
                          ]
                        }
                        return undefined
                      }}
                      rules={[
                        {
                          validator: (rule, value, callback) => {
                            if (value) {
                              const [timeFinishedBegin, timeFinishedEnd] = value
                              const intervalDay = moment(timeFinishedEnd).diff(timeFinishedBegin, 'day')
                              if (intervalDay > 31) return callback('完成时间间隔不超过31天')
                            }
                            return callback()
                          },
                        },
                      ]}
                    >
                      <DatePicker.RangePicker />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name={'orderId'} label={'服务单id'}>
                      <Input placeholder={'请输入服务单id'} allowClear />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      name={'_timeAppointBegin'}
                      label={'最新约定上门时间'}
                      getValueProps={value => ({value: value ? value.map(item => moment(item)) : []})}
                      getValueFromEvent={(_, dateStrings) => {
                        const [timeAppointBegin, timeAppointEnd] = dateStrings
                        if (timeAppointBegin && timeAppointEnd) {
                          return [
                            moment(timeAppointBegin).format(FORMAT_DATE_START),
                            moment(timeAppointEnd).format(FORMAT_DATE_END),
                          ]
                        }
                        return undefined
                      }}
                      rules={[
                        {
                          validator: (rule, value, callback) => {
                            if (value) {
                              const [timeAppointBegin, timeAppointEnd] = value
                              const intervalDay = moment(timeAppointEnd).diff(timeAppointBegin, 'day')
                              if (intervalDay > 31) return callback('最新约定上门时间间隔不超过31天')
                            }
                            return callback()
                          },
                        },
                      ]}
                    >
                      <DatePicker.RangePicker />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name={'propertyCode'} label={'房源code'}>
                      <Input placeholder={'请输入房源code'} allowClear />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name={'repairTag'} label={'返修单'}>
                      <Select placeholder={'请选择'} allowClear>
                        <Option value={1}>是</Option>
                        <Option value={0}>否</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name={'cleanerName'} label={'当前服务人员'}>
                      <Input placeholder={'请输入当前服务人员'} allowClear />
                    </Form.Item>
                  </Col>
                  {collapse && (
                    <>
                      <Col span={8}>
                        <Form.Item name={'lessorMobile'} label={'房东手机号'}>
                          <Input placeholder={'请输入'} allowClear />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item name={'lesseeMobile'} label={'租客手机号'}>
                          <Input placeholder={'请输入'} allowClear />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item name={'orderMakerType'} label={'下单人类型'}>
                          <Select placeholder={'请选择'} allowClear>
                            <Option value={'lessee'}>租客</Option>
                            <Option value={'lessor'}>房东</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item
                          name={'remarkGoodOrNot'}
                          label={'评价情况'}
                          getValueFromEvent={event => (event ? event === '1' : event)}
                          getValueProps={value => ({
                            value: value === true ? '1' : value === false ? '0' : value,
                          })}
                        >
                          <Select placeholder={'请选择'} allowClear>
                            <Option value={'1'}>好评</Option>
                            <Option value={'0'}>差评</Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item name={'reworkTag'} label={'是否返工'}>
                          <Select placeholder={'请选择'} allowClear>
                            <Option value={1}>是</Option>
                            <Option value={0}>否</Option>
                          </Select>
                        </Form.Item>
                      </Col>

                      <Col span={8}>
                        <Form.Item name={'appId'} label={'渠道来源'}>
                          <Select placeholder={'请选择'} options={appList} allowClear />
                        </Form.Item>
                      </Col>
                      <Col span={8} style={{height: 56}}>
                        <Form.Item name={'verifyStatus'} label={'服务核验'}>
                          <Select placeholder={'请选择'} allowClear>
                            {orderVerifyArr.map(item => (
                              <Option key={item.id} value={item.id}>
                                {item.label}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={8} style={{height: 56}}>
                        <Form.Item name={'appOrderId'} label={'渠道订单号'}>
                          <Input placeholder={'请输入'} allowClear />
                        </Form.Item>
                      </Col>
                      <Col span={8} style={{height: 56}}>
                        <Form.Item label={'服务收费'}>
                          <Row align={'top'}>
                            <Col>
                              <Form.Item name={'feeStaffMin'}>
                                <InputNumber
                                  min={0}
                                  placeholder='请输入最小值'
                                  controls={false}
                                  precision={0}
                                  style={{width: 110}}
                                />
                              </Form.Item>
                            </Col>
                            <Col style={{width: 20, margin: '-8px 4px 0'}}>
                              <Divider style={{borderTop: '1px solid #d9d9d9'}} />
                            </Col>
                            <Col>
                              <Form.Item name={'feeStaffMax'}>
                                <InputNumber
                                  min={0}
                                  placeholder='请输入最大值'
                                  controls={false}
                                  precision={0}
                                  style={{width: 110}}
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item label={'服务人员二次上门'} style={{marginBottom: 0}}>
                          <Row align={'top'}>
                            <Col>
                              <Form.Item name={'secondAppointCounterMin'}>
                                <InputNumber
                                  min={0}
                                  placeholder='请输入最小值'
                                  controls={false}
                                  precision={0}
                                  style={{width: 110}}
                                />
                              </Form.Item>
                            </Col>
                            <Col style={{width: 20, margin: '-8px 4px 0'}}>
                              <Divider style={{borderTop: '1px solid #d9d9d9'}} />
                            </Col>
                            <Col>
                              <Form.Item name={'secondAppointCounterMax'}>
                                <InputNumber
                                  min={0}
                                  placeholder='请输入最大值'
                                  controls={false}
                                  precision={0}
                                  style={{width: 110}}
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item name={'stewardNameFirst'} label={'第一接单人'}>
                          <Input placeholder={'请输入'} allowClear />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item name={'urgeTag'} label={'是否有催单'}>
                          <Select
                            placeholder={'请选择'}
                            options={[
                              {value: true, label: '有'},
                              {value: false, label: '无'},
                            ]}
                            allowClear
                          />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item name={'outsourcingTag'} label={'外包处理'}>
                          <Select
                            placeholder={'请选择'}
                            options={[
                              {value: 1, label: '是'},
                              {value: 0, label: '否'},
                            ]}
                            allowClear
                          />
                        </Form.Item>
                      </Col>
                    </>
                  )}
                </Row>
                <Row justify={'center'}>
                  <Space size={10}>
                    <Button type={'link'} style={{padding: 0}} onClick={() => setCollapse(prevState => !prevState)}>
                      更多筛选条件
                    </Button>
                    {collapse ? <UpOutlined style={{color: '#00B7AE'}} /> : <DownOutlined style={{color: '#00B7AE'}} />}
                  </Space>
                </Row>
                <Row justify={'end'}>
                  <Space>
                    <Button type={'primary'} htmlType={'submit'} onClick={() => form.setFieldsValue({pageNum: 1})}>
                      查询
                    </Button>
                    <Button htmlType={'reset'}>重置</Button>
                  </Space>
                </Row>
              </div>

              <div className={commonStyles.tableSection}>
                <Table
                  rowSelection={{
                    type: 'checkbox',
                    selectedRowKeys: selectedRows.map(item => item.orderId),
                    onChange(_, selectedRows) {
                      setSelectedRows(selectedRows)
                    },
                  }}
                  rowKey={'orderId'}
                  columns={[
                    {
                      title: 'ID',
                      dataIndex: 'orderId',
                      render: (value, record) => (
                        <Space>
                          <Typography.Text>{value}</Typography.Text>
                          {/*{record.customerSource === '1' && <Tag color={'blue'}>贝壳</Tag>}*/}
                        </Space>
                      ),
                    },
                    {
                      title: '下单人',
                      dataIndex: 'orderMakerName',
                      render: (value, record) => (
                        <Space direction={'vertical'} size={0}>
                          <span>{value}</span>
                          <span>{record.orderMakerMobile}</span>
                        </Space>
                      ),
                    },
                    {
                      title: '类别',
                      dataIndex: 'categoryFirName',
                      render: (value, record) => (
                        <Space direction={'vertical'} size={0}>
                          <span>{`${value}-${record.categorySecName}-`}</span>
                          <Space>
                            <span>{record.categoryThiName}</span>
                            {record.stewardIdFirst === 'CUSTOMER_CENTER' && <Tag color={'blue'}>客服</Tag>}
                          </Space>
                        </Space>
                      ),
                    },
                    {
                      title: '下单时间',
                      dataIndex: 'timeMake',
                      render: (value, record) => (
                        <>
                          <TimeRender time={value} />
                          {record.appId === 'GUANYU' &&
                            ['1', '2', '3', '4'].includes(record.orderStatus) &&
                            !!record.guanyuExpireAt && (
                              <div style={{color: '#f00'}}>{timeInterval(record.guanyuExpireAt)}</div>
                            )}
                        </>
                      ),
                    },
                    {
                      title: '最新催单时间',
                      dataIndex: 'urgeAt',
                      render: value => (value ? moment(value).format('YYYY-MM-DD HH:mm:ss') : '/'),
                    },
                    {title: '催单次数', dataIndex: 'urgeTimes', render: value => value ?? '/'},
                    ...(!['1', '7'].includes(form.getFieldValue('orderStatus'))
                      ? [
                          {
                            title: '最新约定上门时间',
                            dataIndex: 'timeAppoint',
                            render: value => <TimeRender time={value} />,
                          },
                        ]
                      : []),
                    ...(form.getFieldValue('orderStatus') === '5'
                      ? [
                          {
                            title: '服务单完成时间',
                            dataIndex: 'timeFinished',
                            render: (value, record: StewardOrderVO) => (
                              <Space direction={'vertical'} size={0}>
                                <TimeRender time={value} />
                                {record.timeoutStatus === 1 && record.appId === APP_ID.GUANYU && (
                                  <div style={{color: '#f00'}}>{record.timeoutTime}超时</div>
                                )}
                              </Space>
                            ),
                          },
                        ]
                      : []),
                    {title: '渠道', dataIndex: 'appName'},
                    {title: '渠道订单号', dataIndex: 'appOrderId'},
                    {
                      title: '房源',
                      dataIndex: 'propertyAddr',
                      render: (value, record) => {
                        if (value) {
                          const [first, ...rest] = value.split('-')
                          return (
                            <Space direction={'vertical'} size={0}>
                              <span>{first}-</span>
                              <span>{rest.join('-')}</span>
                              {record.lastDateRepair && (
                                <span style={{color: '#f00'}}>{`${record.lastDateRepair}报修过相同服务`}</span>
                              )}
                            </Space>
                          )
                        } else return <span>/</span>
                      },
                    },
                    {title: '第一接单人', dataIndex: 'stewardNameFirst', key: 'stewardNameFirst'},
                    {title: '管家', dataIndex: 'stewardName', key: 'stewardName'},
                    {title: '当前服务人员', dataIndex: 'cleanerName'},
                    {
                      title: '状态',
                      key: 'orderStatus',
                      dataIndex: 'orderStatus',
                      render: tags => <Typography.Text>{orderStatusType[tags]}</Typography.Text>,
                    },
                    {title: '当前服务人员二次上门', dataIndex: 'secondAppointCounter', key: 'secondAppointCounter'},
                    {
                      title: '操作',
                      dataIndex: 'orderId',
                      fixed: 'right',
                      render: (value, record) => (
                        <Space>
                          {auth('001041002') && (
                            <Button
                              type={'link'}
                              size={'small'}
                              onClick={() =>
                                history.push({
                                  pathname: '/steward/detail',
                                  state: {orderId: value},
                                })
                              }
                              style={{padding: 0}}
                            >
                              查看
                            </Button>
                          )}
                          {auth('001054001') && (
                            <Button
                              type={'link'}
                              size={'small'}
                              style={{padding: 0}}
                              onClick={() => history.push({pathname: '/call-manage', state: {orderId: value}})}
                            >
                              呼叫记录
                            </Button>
                          )}
                          {['96', '99'].includes(record.orderStatus) && (
                            <>
                              <Button
                                type={'link'}
                                style={{padding: 0}}
                                onClick={() => setCutVisible({orderId: record.orderId, teamPid: record.teamPid})}
                              >
                                查看扣款
                              </Button>
                              <Button
                                type={'link'}
                                style={{padding: 0}}
                                onClick={async () => {
                                  const {orderId, cleanTag} = record
                                  if (cleanTag === 0) {
                                    const {
                                      response: {data},
                                    } = await stewardModel.stewardOrderChargeDetail({orderId})
                                    if (data && ['1', '2'].includes(data.auditStatus)) {
                                      return message.warning('请联系管家主管或者总监，存在待审核或审核中的记录！')
                                    }
                                  }
                                  if (cleanTag === 1) {
                                    const {
                                      response: {data},
                                    } = await stewardModel.stewardOrderChargeCleanDetail({orderId})
                                    if (data && ['1', '3'].includes(data.recordStatus)) {
                                      return message.warning('请联系保洁主管，存在待扣款或复核中的记录！')
                                    }
                                  }
                                  addRef.current?.add(record)
                                }}
                              >
                                添加扣款
                              </Button>
                            </>
                          )}
                        </Space>
                      ),
                    },
                  ]}
                  scroll={{x: 'max-content'}}
                  pagination={getPaginationProps(form, listTotal)}
                  dataSource={tableList}
                  loading={loading}
                />
              </div>
            </Form>
          </Tabs.TabPane>
        )}
        {auth('001102001') && (
          <Tabs.TabPane tab={'服务申请'} key={'3'}>
            <Form
              form={applyForm}
              onFinish={applyListSearch}
              onReset={applyListSearch}
              initialValues={{pageNum: 1, pageSize: 10}}
            >
              <div className={commonStyles.filterSection} style={{marginTop: 20}}>
                <Row style={{flexWrap: 'wrap'}} gutter={[50, 0]}>
                  <Col span={8}>
                    <Form.Item name={'orderId'} label={'关键字'}>
                      <Input placeholder={'请输入服务单ID/申请ID'} allowClear />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name={'lesseeName'} label={'申请人'}>
                      <Input placeholder={'请输入'} allowClear />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name={'lesseeMobile'} label={'申请人手机号'}>
                      <Input placeholder={'请输入'} allowClear />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name={'applyStatus'} label={'申请状态'}>
                      <Select placeholder={'请选择'}>
                        {applicationStatus.map(item => (
                          <Select.Option value={item.id} key={item.id}>
                            {item.label}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name={'categoryName'} label={'服务项目'}>
                      <Input placeholder={'请输入'} allowClear />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name={'_time'} label={'申请时间'}>
                      <DatePicker.RangePicker
                        format={'YYYY-MM-DD'}
                        allowClear
                        onChange={(_, formatString) => {
                          applyForm.setFieldsValue({
                            insertTimeBegin: formatString?.[0] ? formatString?.[0] + ' 00:00:00' : formatString?.[0],
                            insertTimeEnd: formatString?.[1] ? formatString?.[1] + ' 23:59:59' : formatString?.[1],
                          })
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name={'customerName'} label={'房东'}>
                      <Input placeholder={'请输入'} allowClear />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name={'propertyAddr'} label={'服务地址'}>
                      <Input placeholder={'请输入'} allowClear />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name={'applyResult'} label={'确认结果'}>
                      <Select placeholder={'请选择'} allowClear>
                        {applicationResult.map(item => (
                          <Option value={item.id} key={item.id}>
                            {item.label}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item name={'_cancelTime'} label={'撤销时间'}>
                      <DatePicker.RangePicker
                        format={'YYYY-MM-DD'}
                        allowClear
                        onChange={(_, formatString) => {
                          const [cancelTimeBegin, cancelTimeEnd] = formatString ?? []
                          applyForm.setFieldsValue({
                            cancelTimeBegin: cancelTimeBegin ? cancelTimeBegin + ' 00:00:00' : cancelTimeBegin,
                            cancelTimeEnd: cancelTimeEnd ? cancelTimeEnd + ' 23:59:59' : cancelTimeEnd,
                          })
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row justify={'end'}>
                  <Space>
                    <Button type={'primary'} htmlType={'submit'} onClick={() => form.setFieldsValue({pageNum: 1})}>
                      查询
                    </Button>
                    <Button htmlType={'reset'}>重置</Button>
                  </Space>
                </Row>
              </div>

              <div className={commonStyles.filterSection}>
                <Table
                  loading={applySearchLoading}
                  rowKey={'orderId'}
                  dataSource={applyList}
                  pagination={getPaginationProps(applyForm, applyTotal)}
                  scroll={{x: 'max-content'}}
                  columns={[
                    {title: '申请ID', dataIndex: 'orderId'},
                    {title: '申请人', dataIndex: 'lesseeName'},
                    {title: '申请人手机号', dataIndex: 'lesseeMobile'},
                    {
                      title: '服务项目',
                      render: record => `${record.categoryFirName}-${record.categorySecName}-${record.categoryThiName}`,
                    },
                    {title: '申请时间', dataIndex: 'insertTime'},
                    {title: '撤销时间', dataIndex: 'cancelTime', render: text => text ?? '/'},
                    {title: '房东', dataIndex: 'customerName'},
                    {title: '服务地址', dataIndex: 'propertyAddr'},
                    {
                      title: '确认结果',
                      render: record => (record.applyResult ? applicationResultType[record.applyResult] : '/'),
                    },
                    {title: '申请状态', dataIndex: 'applyStatus', render: text => applicationStatusCode[text]},
                    {
                      title: '操作',
                      fixed: 'right',
                      width: 100,
                      render: record => {
                        return (
                          <Space>
                            <Button
                              type={'link'}
                              style={{paddingLeft: '0', paddingRight: '0'}}
                              onClick={async () => {
                                const {
                                  response: {data},
                                } = await stewardModel.stewardOrderApplicationGet({orderId: record.orderId})
                                setApplyInfo(data)
                              }}
                            >
                              申请详情
                            </Button>
                            {record.applyResult === '2' && (
                              <Button
                                type={'link'}
                                style={{paddingLeft: '0', paddingRight: '0'}}
                                onClick={() =>
                                  history.push({
                                    pathname: '/steward/detail',
                                    state: {orderId: record.orderId},
                                  })
                                }
                              >
                                服务单详情
                              </Button>
                            )}
                          </Space>
                        )
                      },
                    },
                  ]}
                />
              </div>
            </Form>
          </Tabs.TabPane>
        )}
        {auth('001202001') && (
          <Tabs.TabPane key={'4'} tab={'收费审核'}>
            <ChargeApprovalList />
          </Tabs.TabPane>
        )}
      </Tabs>

      {/** 导出所有结果 */}
      <Modal
        centered
        destroyOnClose
        keyboard
        width={900}
        maskClosable
        open={isExport}
        title={'导出'}
        okText='导出'
        onOk={async () => {
          const {list1, list2} = exportForm.getFieldsValue()
          localStorage.setItem('exportList', JSON.stringify(list2) || '[]')

          const param = {}
          list1.forEach(item => (param[item] = true))
          list2?.forEach(item => (param[item] = true))

          exportAllResult(param)
        }}
        onCancel={() => setIsExport(false)}
      >
        <Form
          form={exportForm}
          initialValues={{
            list1: selectList.map(({value}) => value),
          }}
          layout='vertical'
          colon
        >
          <Form.Item label='默认字段：' name={'list1'}>
            <Checkbox.Group options={selectList} disabled />
          </Form.Item>
          <Form.Item noStyle shouldUpdate>
            {f => (
              <Form.Item
                label={
                  <Space>
                    可选字段：
                    <Checkbox
                      checked={f.getFieldValue('list2')?.length === remainList.length}
                      onChange={e => {
                        if (e.target.checked) {
                          f.setFieldsValue({list2: remainList.map(({value}) => value)})
                        } else {
                          f.resetFields(['list2'])
                        }
                      }}
                    >
                      全选
                    </Checkbox>
                  </Space>
                }
                name={'list2'}
              >
                <Checkbox.Group options={remainList} />
              </Form.Item>
            )}
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        open={redistributionVisible}
        centered
        title={'重新分配'}
        onCancel={() => setRedistributionVisible(false)}
        footer={null}
        afterClose={() => rForm.resetFields()}
      >
        <Form
          form={rForm}
          labelCol={{span: 6}}
          onFinish={async () => {
            const formValues = rForm.getFieldsValue(true)
            for (const item of selectedRows) {
              const {
                response: {data},
              } = await sysModel.approvalOrderBeforeCheckTransferOrder({
                orderId: item.orderId,
                stewardId: formValues.stewardId,
              })

              if (data) {
                stewardModel.approvalOrderInsert({
                  approvalConfigId: '2',
                  approvalTransferOrderDTO: {
                    appointTime: item.timeAppoint,
                    lat: item.propertyLat,
                    lng: item.propertyLng,
                    orderId: item.orderId,
                    originalStewardId: item.stewardId,
                    originalStewardName: item.stewardName,
                    takeOverStewardId: formValues.stewardId,
                    takeOverStewardName: formValues._stewardName,
                    transferReason:
                      formValues.dispatchReason === '99'
                        ? formValues.otherReason
                        : stewardOrderReassignReasonCode[formValues.dispatchReason],
                    transferType: '1',
                  },
                } as ApprovalOrderInsertDTO)
                message.success('提交审批成功！')
              } else {
                await stewardModel.stewardServiceDispatch({
                  orderId: item.orderId,
                  stewardId: formValues.stewardId,
                  dispatchReason:
                    formValues.dispatchReason === '99'
                      ? formValues.otherReason
                      : stewardOrderReassignReasonCode[formValues.dispatchReason],
                })
                message.success('重新分配成功！')
              }
            }
            setRedistributionVisible(false)
            queryStewardOrderList()
            setSelectedRows([])
          }}
          onReset={() => setRedistributionVisible(false)}
        >
          <Row>
            <Col span={20}>
              <Form.Item>
                <Space>
                  <InfoCircleFilled />
                  <span>服务单转给不同站点的管家需要审批</span>
                </Space>
              </Form.Item>
              <Form.Item name={'stewardId'} label={'服务人员'} rules={[{required: true, message: '请选择服务人员'}]}>
                <Select
                  placeholder={'请选择'}
                  showSearch
                  filterOption={(inputValue, option) => {
                    return option.children?.indexOf(inputValue as any) > -1
                  }}
                  onChange={async (_, option) => {
                    if (!Array.isArray(option)) {
                      if (option && option.value) {
                        const {
                          response: {data},
                        } = await sysModel.sysOrgMemberOrgPath({userId: option.value})
                        rForm.setFieldsValue({_stewardName: option.children, _orgPath: data})
                      } else {
                        rForm.setFieldsValue({_stewardName: option?.children, _orgPath: ''})
                      }
                    }
                  }}
                  allowClear
                >
                  {userList.map(item => (
                    <Option key={item.userId} value={item.userId}>
                      {`${item.name}（${item.mobile}）`}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={20}>
              <Form.Item noStyle shouldUpdate>
                {form1 => {
                  const _orgPath = form1.getFieldValue('_orgPath')
                  return (
                    !!_orgPath && (
                      <Form.Item label={' '} colon={false} style={{margin: '-24px 0 0'}}>
                        <span style={{color: '#f00'}}>{_orgPath}</span>
                      </Form.Item>
                    )
                  )
                }}
              </Form.Item>
            </Col>
            <Col span={20}>
              <Form.Item
                name={'dispatchReason'}
                label={'分配原因'}
                rules={[{required: true, message: '请选择分配原因'}]}
              >
                <Select placeholder={'请选择'} allowClear>
                  {stewardOrderReassignReasonArr.map(item => (
                    <Option key={item.id} value={item.id}>
                      {item.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={20}>
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, nextValues) => prevValues.dispatchReason !== nextValues.dispatchReason}
              >
                {form1 => {
                  const dispatchReason = form1.getFieldValue('dispatchReason')
                  return dispatchReason === '99' ? (
                    <Form.Item
                      name={'otherReason'}
                      label={' '}
                      colon={false}
                      required={false}
                      rules={[{required: true, message: '请输入分配原因'}]}
                    >
                      <Input.TextArea placeholder={'请输入分配原因'} allowClear maxLength={200} showCount />
                    </Form.Item>
                  ) : null
                }}
              </Form.Item>
            </Col>
          </Row>
          <Row justify={'end'}>
            <Space>
              <Button htmlType={'reset'}>取消</Button>
              <Button type={'primary'} htmlType={'submit'} loading={stewardModel.stewardServiceDispatch.useLoading()}>
                确定
              </Button>
            </Space>
          </Row>
        </Form>
      </Modal>

      {/* 服务单扣款详情 */}
      <CutServiceDetail
        orderId={cutVisible?.orderId}
        teamPid={cutVisible?.teamPid}
        onCancel={() => {
          setCutVisible(null)
        }}
      />

      {/* 添加扣款 */}
      <CutService
        onSuccess={() => {
          queryStewardOrderList()
        }}
        ref={addRef}
      />
      <Drawer
        title={'申请详情'}
        width={700}
        visible={!!applyInfo.orderId}
        onClose={() => setApplyInfo({} as StewardOrderApplicationVO)}
      >
        <DividerLine title='基本信息' style={{marginBottom: 10}} />
        <Descriptions column={2}>
          <Descriptions.Item label={'申请ID'}>{applyInfo.orderId}</Descriptions.Item>
          <Descriptions.Item label={'申请人'}>{applyInfo.lesseeName}</Descriptions.Item>
          <Descriptions.Item label={'申请人手机号'}>{applyInfo.lesseeMobile}</Descriptions.Item>
          <Descriptions.Item label={'申请时间'}>{applyInfo.insertTime}</Descriptions.Item>
          {applyInfo.applyStatus === '2' && (
            <Descriptions.Item label={'确认时间'}>{applyInfo.updateTime}</Descriptions.Item>
          )}
          {applyInfo.applyStatus === '3' && (
            <Descriptions.Item label={'撤销时间'}>{applyInfo.cancelTime}</Descriptions.Item>
          )}
          {applyInfo.applyStatus === '2' && (
            <Descriptions.Item label={'确认结果'}>{applicationResultType[applyInfo.applyResult]}</Descriptions.Item>
          )}

          <Descriptions.Item label={'房东'}>{applyInfo.customerName}</Descriptions.Item>
          <Descriptions.Item label={'服务地址'}>{applyInfo.propertyAddr}</Descriptions.Item>
          <Descriptions.Item
            label={'服务项目'}
          >{`${applyInfo.categoryFirName}-${applyInfo.categorySecName}-${applyInfo.categoryThiName}`}</Descriptions.Item>
          <Descriptions.Item label={'故障现象'}>{applyInfo.tags}</Descriptions.Item>
        </Descriptions>
        <Descriptions column={1}>
          <Descriptions.Item label={'需求描述'}>{applyInfo.orderDesc}</Descriptions.Item>
        </Descriptions>
        <Descriptions column={1}>
          <Descriptions.Item label={'照片'}>
            {applyInfo.imgListOfDetail?.length ? (
              <DetailSectionImage
                title={''}
                imgList={applyInfo.imgListOfDetail}
                onClickVideo={img => setSelectImg({imgUrl: img.imgUrl, visible: true})}
              />
            ) : (
              '无'
            )}
          </Descriptions.Item>
        </Descriptions>
      </Drawer>

      {/** 预览视频 */}
      <Modal
        centered
        destroyOnClose
        visible={selectImg.visible}
        title={'视频预览'}
        footer={null}
        onCancel={() => setSelectImg(state => ({...state, visible: false}))}
      >
        <video controls src={getImg(selectImg.imgUrl)} width='100%' />
      </Modal>
    </Page>
  )
}
