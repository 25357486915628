import React, {useCallback, useEffect, useState} from 'react'
import {Button, Col, Form, Input, InputNumber, message, Modal, Radio, Row, Select, Space, Table} from 'antd'
import {Breadcrumb} from '@/components/Breadcrumb'
import commonStyles from '@/assets/styles/common.module.less'
import {stewardModel} from '@/store/models/Steward'
// import {usePermission} from '@/components/Permission'
import {Page} from '@/components/Page'
import {getPaginationProps} from '@/common/biz/getPaginationProps'
import {usePermission} from '@/components/Permission'

const type = [
  {label: '奖', value: 1},
  {label: '罚', value: 0},
]

const enableOption = [
  {label: '启用', value: 1},
  {label: '停用', value: 0},
]

export default function RewardAndPunishmentList() {
  const [auth] = usePermission()
  // 查看
  const [isView, setIsView] = useState(false)
  //  停用启用
  const [handleObj, setHandleObj] = useState({
    title: '停用',
    open: false,
  })
  const [appList, setAppList] = useState<{label: string; value: string}[]>()
  const [insertAppList, setInsertAppList] = useState<{label: string; value: string}[]>()
  const [typeId, setTypeId] = useState<string>('')
  const [detailData, setDetailData] = useState<StewardSalaryTypeVO>()

  /** 列表相关 */
  const [form] = Form.useForm<StewardServiceChargeQueryDTO>()
  const [total, setTotal] = useState(0)
  const [chargeList, setChargeList] = useState<StewardSalaryTypeVO[]>([])

  const queryServiceChargeList = useCallback(() => {
    stewardModel.stewardSalaryTypeList(form.getFieldsValue(true)).then(({response: {data, total}}) => {
      setTotal(total)
      setChargeList(data)
    })
  }, [form])

  useEffect(() => {
    form.submit()
  }, [form])

  /** 新增相关 */
  const [insertForm] = Form.useForm()
  const [insertVisible, setInsertVisible] = useState(false)

  const listLoading = stewardModel.stewardSalaryTypeList.useLoading()
  const insertLoading = stewardModel.stewardSalaryTypeUpsert.useLoading()
  const enableLoading = stewardModel.stewardSalaryTypeEnable.useLoading()

  // 所有标签列表
  useEffect(() => {
    stewardModel
      .stewardOpenAppList({pageable: false, pageSize: 1, pageNum: 1} as StewardAppOpenListDTO)
      .then(({response}) => {
        setAppList(response?.data?.map(item => ({label: item.appName, value: item.appId})))
      })
  }, [])
  // 标签列表
  useEffect(() => {
    if (!insertVisible) return
    stewardModel
      .stewardOpenAppList({appStatus: 1, pageable: false, pageSize: 1, pageNum: 1} as StewardAppOpenListDTO)
      .then(({response}) => {
        setInsertAppList(response?.data?.map(item => ({label: item.appName, value: item.appId})))
      })
  }, [insertVisible])

  return (
    <Page
      breadcrumb={
        <Breadcrumb
          right={
            auth('003187002') && (
              <Button type={'primary'} onClick={() => setInsertVisible(true)}>
                新增
              </Button>
            )
          }
        />
      }
    >
      <div className={commonStyles.filterSection}>
        <Form
          form={form}
          labelCol={{flex: '120px'}}
          initialValues={{pageNum: 1, pageSize: 10, pageable: true}}
          onFinish={queryServiceChargeList}
          onReset={queryServiceChargeList}
        >
          <Row gutter={30}>
            <Col span={7}>
              <Form.Item name={'typeName'} label={'名称'}>
                <Input placeholder={'请输入绩效方案名称'} allowClear />
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item name={'appId'} label={'标签'}>
                <Select placeholder={'全部'} allowClear options={appList} />
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item name={'enable'} label={'状态'}>
                <Select placeholder={'不限'} allowClear options={enableOption} />
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item name={'gtOrLt'} label={'奖惩类型'}>
                <Select placeholder={'不限'} allowClear options={type} />
              </Form.Item>
            </Col>
          </Row>
          <Row justify={'end'}>
            <Space>
              <Button type={'primary'} htmlType={'submit'} onClick={() => form.setFieldsValue({pageNum: 1})}>
                查询
              </Button>
              <Button htmlType={'reset'}>重置</Button>
            </Space>
          </Row>
        </Form>
      </div>

      <div className={commonStyles.tableSection}>
        <Table
          rowKey={'typeId'}
          loading={listLoading}
          dataSource={chargeList}
          columns={[
            {title: '标签', dataIndex: 'appId', render: value => appList?.find(item => item?.value === value)?.label},
            {title: '名称', dataIndex: 'typeName'},
            {title: '奖罚类型', dataIndex: 'amt', render: value => (value < 0 ? '罚' : '奖')},
            {title: '金额', dataIndex: 'amt', render: value => Math.abs(value)},
            {title: '状态', dataIndex: 'enable', render: value => (value === 1 ? '启用' : '停用')},
            {title: '创建人', dataIndex: 'insertBy'},
            {title: '创建时间', dataIndex: 'insertTime'},
            {
              title: '操作',
              dataIndex: 'typeId',
              render: (value, record) => {
                return (
                  <Space>
                    {auth('001187002') && (
                      <Button
                        type='link'
                        style={{padding: 0}}
                        onClick={() => {
                          setDetailData(record)
                          setIsView(true)
                        }}
                      >
                        查看
                      </Button>
                    )}
                    {record.enable
                      ? auth('023187002') && (
                          <Button
                            type={'link'}
                            style={{padding: 0}}
                            onClick={() => {
                              setTypeId(value)
                              setDetailData(record)
                              setHandleObj({
                                title: '停用',
                                open: true,
                              })
                            }}
                          >
                            停用
                          </Button>
                        )
                      : auth('023187002') && (
                          <Button
                            type={'link'}
                            style={{padding: 0}}
                            onClick={() => {
                              setDetailData(record)
                              setTypeId(value)
                              setHandleObj({
                                title: '启用',
                                open: true,
                              })
                            }}
                          >
                            启用
                          </Button>
                        )}
                  </Space>
                )
              },
            },
          ]}
          pagination={getPaginationProps(form, total)}
        />
      </div>

      {/* 新增收费项目 */}
      <Modal
        open={insertVisible}
        confirmLoading={insertLoading}
        centered
        title={'新增奖惩'}
        okText='保存'
        onOk={async () => {
          await insertForm.validateFields()
          const {gtOrLt, amt, ...rest} = insertForm.getFieldsValue(true)
          await stewardModel.stewardSalaryTypeUpsert({
            ...rest,
            amt: gtOrLt ? amt : -amt,
            appName: appList?.find(item => item?.value === rest.appId)?.label,
          })
          setInsertVisible(false)
          form.submit() // 查询的表单
          message.success('操作成功！')
          setInsertVisible(false)
        }}
        onCancel={() => {
          if (insertVisible) setInsertVisible(false)
        }}
        afterClose={() => insertForm.resetFields()}
      >
        <Form form={insertForm} labelCol={{flex: '110px'}} validateTrigger={['onBlur', 'onChange']}>
          <Form.Item label='标签' name={'appId'} rules={[{required: true}]}>
            <Radio.Group>
              {insertAppList?.map(item => (
                <Radio key={item.value} value={item.value}>
                  {item.label}
                </Radio>
              ))}
            </Radio.Group>
          </Form.Item>
          <Form.Item name={'typeName'} label={'名称'} rules={[{required: true}]}>
            <Input placeholder={'请输入额外奖金名称'} allowClear />
          </Form.Item>
          <Form.Item label='奖罚类型' name={'gtOrLt'} rules={[{required: true}]}>
            <Radio.Group>
              <Radio value={1}>奖</Radio>
              <Radio value={0}>罚</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item label={'金额'} required>
            <Row align={'middle'}>
              <Col>
                <Form.Item name={'amt'} rules={[{required: true, message: '请输入金额'}]} noStyle>
                  <InputNumber placeholder={'请输入'} min={1} />
                </Form.Item>
              </Col>
              <Col style={{marginLeft: 8}}>
                <Form.Item noStyle>元</Form.Item>
              </Col>
            </Row>
          </Form.Item>
          <Form.Item name={'remark'} label='备注'>
            <Input.TextArea rows={4} placeholder={'请输入'} maxLength={500} showCount style={{resize: 'none'}} />
          </Form.Item>
        </Form>
      </Modal>

      {/* 查看 */}
      <Modal
        open={isView}
        title='查看奖惩'
        centered
        footer={null}
        onCancel={() => setIsView(false)}
        key={JSON.stringify(detailData)}
      >
        <Form colon labelAlign='right' labelCol={{span: 6}}>
          <Form.Item label='名称'>{detailData?.typeName}</Form.Item>
          <Form.Item label='奖罚类型'>
            <Radio.Group value={detailData?.amt < 0 ? 0 : 1}>
              <Radio value={1}>奖</Radio>
              <Radio value={0}>罚</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item label='金额'>{Math.abs(detailData?.amt) + '元'}</Form.Item>
          <Form.Item label='备注'>{detailData?.remark}</Form.Item>
          <Form.Item label='创建人'>{detailData?.insertBy}</Form.Item>
          <Form.Item label='创建时间'>{detailData?.insertTime}</Form.Item>
        </Form>
      </Modal>

      {/* 启用/停用 */}
      <Modal
        open={handleObj.open}
        title={handleObj.title}
        confirmLoading={enableLoading}
        centered
        okText='保存'
        onOk={async () => {
          // 调用接口
          await stewardModel.stewardSalaryTypeEnable({typeId, enable: handleObj.title === '启用' ? 1 : 0})
          message.success('操作成功！')
          form.submit() // 查询的表单
          setHandleObj({...handleObj, open: false})
        }}
        onCancel={() => setHandleObj({...handleObj, open: false})}
      >
        {`确定要${handleObj.title}＂${detailData?.typeName}＂？`}
      </Modal>
    </Page>
  )
}
