import React, {useEffect, useState} from 'react'
import commonStyles from '@/assets/styles/common.module.less'
import {Button, Checkbox, Col, Form, Input, InputNumber, Select, Space, Row, message, PageHeader} from 'antd'
import {condoModel} from '@/store/models/Condo'
import {useAppHistory, useAppLocation} from '@/common/hooks/useRouter'
import {useCodeArraySync} from '@/store/models/Common'
import {Album, UploadButton} from '@/components/Album'
import {baseUrl} from '@/common/config'
import {getImg} from '@/utils/util'
import * as listener from '@/common/hooks/listener'
import {Page} from '@/components/Page'

const electricalEquipmentList = [
  {id: '01', label: '电视'},
  {id: '02', label: '空调'},
  {id: '03', label: '热水器'},
  {id: '04', label: '洗衣机'},
  {id: '05', label: '冰箱'},
  {id: '06', label: '电磁炉'},
  {id: '07', label: '微波炉'},
  {id: '08', label: '油烟机'},
  {id: '09', label: '电吹风'},
  {id: '10', label: '暖气'},
]
const furnitureEquipmentList = [
  {id: '11', label: '书桌'},
  {id: '12', label: '衣柜'},
  {id: '13', label: '沙发'},
  {id: '14', label: '化妆台'},
  {id: '15', label: '餐桌'},
  {id: '16', label: '1.2m宽床'},
  {id: '17', label: '1.5m宽床'},
  {id: '18', label: '1.8m宽床'},
  {id: '19', label: '床头柜'},
  {id: '20', label: '鞋柜'},
]

export default function AddHouseTemplate() {
  const location = useAppLocation<'/concentrate-pm/template'>()
  const history = useAppHistory()
  const condoProjectId = location.state?.condoProjectId
  const templateId = location.state?.templateId
  const readonly = location.state?.readonly
  const [form] = Form.useForm()
  const [projectInfo, setProjectInfo] = useState({} as CondoProjectVO)
  // 窗户
  const propertyWindow = useCodeArraySync('property.window')
  // 朝向
  const propertyOrientation = useCodeArraySync('property.orientation')
  useEffect(() => {
    condoModel.condoProjectInfo({condoProjectId}).then(({response: {data}}) => {
      setProjectInfo(data)
    })
  }, [condoProjectId])

  useEffect(() => {
    if (templateId) {
      condoModel.condoProjectTemplateInfo({templateId}).then(({response: {data}}) => {
        form.setFieldsValue(data)
      })
    }
  }, [form, templateId])
  return (
    <Page header={<PageHeader ghost={false} title={'添加户型模板'} onBack={() => history.goBack()} />}>
      <div className={commonStyles.filterSection}>
        <Form
          form={form}
          labelCol={{span: 3}}
          labelAlign={'right'}
          wrapperCol={{span: 10}}
          onFinish={async () => {
            const {imgList, electricalEquipmentList, furnitureEquipmentList, ...formValues} = form.getFieldsValue(true)
            await condoModel.condoProjectTemplateUpsert({
              ...formValues,
              condoId: condoProjectId,
              imgList: imgList ?? [],
              electricalEquipmentList: electricalEquipmentList ?? [],
              furnitureEquipmentList: furnitureEquipmentList ?? [],
            })
            message.success(`模板${templateId ? '编辑' : '添加'}成功！`)
            listener.emit('ProjectSet:template')
            history.goBack()
          }}
        >
          <Form.Item label={'项目名称'}>
            <span>{projectInfo.projectName}</span>
          </Form.Item>
          <Form.Item label={'模板名称'} name={'templateName'} rules={[{required: true, message: '请输入模板名称'}]}>
            <Input
              style={{width: 450}}
              maxLength={30}
              placeholder='请输入模板名称'
              disabled={!!templateId}
              allowClear
            />
          </Form.Item>
          <Form.Item label={'房屋面积'} style={{marginBottom: 0}} required>
            <Space>
              <Form.Item name={'floorage'} rules={[{required: true, message: '请输入房屋面积'}]}>
                <InputNumber
                  disabled={readonly}
                  maxLength={3}
                  max={999}
                  style={{width: 450}}
                  min={0}
                  placeholder={'请输入'}
                />
              </Form.Item>
              <Form.Item>
                <span>m²</span>
              </Form.Item>
            </Space>
          </Form.Item>
          <Form.Item label={'户型'} style={{marginBottom: 0}} required>
            <Space>
              <Form.Item name={'bedroom'} rules={[{required: true, message: '请输入室'}]}>
                <InputNumber disabled={readonly} style={{width: 100}} min={0} placeholder='请输入' />
              </Form.Item>
              <Form.Item>
                <span>室</span>
              </Form.Item>
              <Form.Item name={'parlor'} rules={[{required: true, message: '请输入厅'}]}>
                <InputNumber disabled={readonly} style={{width: 100}} min={0} placeholder='请输入' />
              </Form.Item>
              <Form.Item>
                <span>厅</span>
              </Form.Item>
              <Form.Item name={'bathroom'} rules={[{required: true, message: '请输入卫'}]}>
                <InputNumber disabled={readonly} style={{width: 100}} min={0} placeholder='请输入' />
              </Form.Item>
              <Form.Item>
                <span>卫</span>
              </Form.Item>
            </Space>
          </Form.Item>
          <Form.Item label={'房屋朝向'} name={'orientation'} rules={[{required: true, message: '请输入房屋朝向'}]}>
            <Select disabled={readonly} placeholder={'请选择'} style={{width: 450}} allowClear>
              {propertyOrientation.map(item => (
                <Select.Option key={item.id} value={item.id}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label={'室内大窗'} name={'window'} rules={[{required: true, message: '请输入室内大窗'}]}>
            <Select disabled={readonly} placeholder={'请选择'} style={{width: 450}} allowClear>
              {propertyWindow.map(item => (
                <Select.Option key={item.id} value={item.id}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label={'家电设备'} wrapperCol={{span: 12}} name={'electricalEquipmentList'}>
            <Checkbox.Group disabled={readonly}>
              <Row gutter={[0, 10]}>
                {electricalEquipmentList.map(item => (
                  <Col span={5} key={item.id}>
                    <Checkbox value={item.id}>{item.label}</Checkbox>
                  </Col>
                ))}
              </Row>
            </Checkbox.Group>
          </Form.Item>
          <Form.Item label={'家居设备'} wrapperCol={{span: 12}} name={'furnitureEquipmentList'}>
            <Checkbox.Group disabled={readonly}>
              <Row gutter={[0, 10]}>
                {furnitureEquipmentList.map(item => (
                  <Col span={5} key={item.id}>
                    <Checkbox value={item.id}>{item.label}</Checkbox>
                  </Col>
                ))}
              </Row>
            </Checkbox.Group>
          </Form.Item>
          <Form.Item label={'照片'} shouldUpdate wrapperCol={{span: 12}}>
            {() => {
              const imgList = form.getFieldValue('imgList') ?? []
              return (
                <Album
                  disabled={readonly}
                  accept={'image/png, image/jpeg, image/jpg'}
                  action={baseUrl + '/condo/project/template/upload-file'}
                  data={{video: '0', type: '2'}}
                  limit={9}
                  fileList={imgList
                    ?.filter(item => item.video === '0')
                    .map?.(item => ({...item, uid: item.imgId, url: getImg(item.imgUrl)}))}
                  onDone={fileList => {
                    form.setFieldsValue({
                      imgList: [
                        ...fileList.map(item => ({
                          video: '0',
                          imgId: item.imgId,
                          imgUrl: item.imgUrl,
                        })),
                        ...imgList.filter(item => item.video === '1'),
                      ],
                    })
                  }}
                >
                  {readonly || imgList.filter(item => item.video === '0').length === 9 ? null : (
                    <UploadButton title={'上传照片'} />
                  )}
                </Album>
              )
            }}
          </Form.Item>
          <Form.Item label={'视频'} shouldUpdate>
            {() => {
              const imgList = form.getFieldValue('imgList') ?? []
              return (
                <Album
                  disabled={readonly}
                  accept={'video/mp4'}
                  action={baseUrl + '/condo/project/template/upload-file'}
                  data={{video: '1', type: '2'}}
                  limit={2}
                  iconRender={() => (
                    <div style={{display: 'flex', alignItems: 'center', height: '100%', justifyContent: 'center'}}>
                      <video
                        src={getImg((imgList as PropertyImgVO[])?.filter(item => item.video === '1')[0]?.imgUrl)}
                      />
                    </div>
                  )}
                  fileList={imgList
                    ?.filter(item => item.video === '1')
                    .map?.(item => ({...item, uid: item.imgId, url: getImg(item.imgUrl)}))}
                  onDone={fileList => {
                    form.setFieldsValue({
                      imgList: [
                        ...fileList.map(item => ({
                          video: '1',
                          imgId: item.imgId,
                          imgUrl: item.imgUrl,
                        })),
                        ...imgList.filter(item => item.video === '0'),
                      ],
                    })
                  }}
                >
                  {readonly || imgList.filter(item => item.video === '1').length === 2 ? null : (
                    <UploadButton title={'上传视频'} />
                  )}
                </Album>
              )
            }}
          </Form.Item>
          <Form.Item label={' '} colon={false}>
            <Space>
              {!readonly && (
                <Button type={'primary'} htmlType={'submit'}>
                  提交
                </Button>
              )}
              <Button onClick={() => history.goBack()}>返回</Button>
            </Space>
          </Form.Item>
        </Form>
      </div>
    </Page>
  )
}
