import React, {useCallback, useImperativeHandle, useState} from 'react'
import {Button, Col, DatePicker, Form, Input, InputNumber, Modal, Row, Space, Table} from 'antd'
import moment from 'moment'
import {lessorModel} from '@/store/models/Lessor'
import DividerLine from '@/pages/property/component/DividerLine'
import {getPaginationProps} from '@/common/biz/getPaginationProps'

function EditBill(props, ref) {
  const [visible, setVisible] = useState(false)
  const [editForm] = Form.useForm()
  const [propertyList, setPropertyList] = useState<LessorBillPropertyVO[]>([])
  const [propertyTotal, setPropertyTotal] = useState(0)
  const [form] = Form.useForm()
  const [deletePropertyCodeList, setDeletePropertyCodeList] = useState<LessorBillPropertyVO[]>([])
  const [billPropertyList, setBillPropertyList] = useState<LessorBillPropertyVO[]>([])
  const searchProperty = useCallback(() => {
    const values = form.getFieldsValue(true)
    lessorModel.userLessorBillPropertyList(values).then(({response: {data, total}}) => {
      setPropertyList(data)
      setPropertyTotal(total)
    })
  }, [form])

  useImperativeHandle(ref, () => ({
    onDisplay: () => {
      setVisible(true)
    },
    onSetInfo: (detail: LessorBillVO) => {
      editForm.setFieldsValue(detail)
      form.setFieldsValue({billId: detail.billId})
      searchProperty()
      lessorModel
        .userLessorBillPropertyList({...form.getFieldsValue(true), pageable: false})
        .then(({response: {data}}) => {
          setBillPropertyList(data)
        })
    },
  }))

  // 账单金额不需要重新再计算一遍
  // useEffect(() => {
  //   const deleteCodes = deletePropertyCodeList.map(item => item.propertyCode)
  //   const currentPropertyList = billPropertyList.filter(item => !deleteCodes.includes(item.propertyCode))
  //   const billAmtTotal = currentPropertyList.reduce((sum, item) => {
  //     return (sum += +item.basePrice)
  //   }, 0)
  //   editForm.setFieldsValue({billAmtTotal})
  // }, [billPropertyList, deletePropertyCodeList, editForm])

  return (
    <Modal
      title={editForm.getFieldValue('billType') === '1' ? '编辑基础服务账单' : '编辑额外服务账单'}
      open={visible}
      width={800}
      centered
      footer={null}
      onCancel={() => setVisible(false)}
      afterClose={() => {
        editForm.resetFields()
        form.resetFields()
        setDeletePropertyCodeList([])
      }}
      destroyOnClose
    >
      <Form form={editForm} labelCol={{flex: '120px'}}>
        <Row>
          <Col span={12}>
            <Form.Item name={'billName'} label={'账单名称'} rules={[{required: true, message: '请输入账单名称'}]}>
              <Input style={{maxWidth: 200}} allowClear />
            </Form.Item>
          </Col>
          {editForm.getFieldValue('stewardOrderId') && (
            <Col span={12}>
              <Form.Item label={'服务订单号'}>
                <span>{editForm.getFieldValue('stewardOrderId')}</span>
              </Form.Item>
            </Col>
          )}
          <Col span={12}>
            <Form.Item label={'账单类型'}>
              <span>{editForm.getFieldValue('billType') === '1' ? '基础服务' : '额外服务'}</span>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label={'账单生成日期'}>
              <span>{editForm.getFieldValue('billCreateTime')}</span>
            </Form.Item>
          </Col>
          {editForm.getFieldValue('billBegin') && (
            <Col span={12}>
              <Form.Item label={'账单周期'}>
                <span>{`${editForm.getFieldValue('billBegin')}至${editForm.getFieldValue('billEnd')}`}</span>
              </Form.Item>
            </Col>
          )}
          <Col span={12}>
            <Form.Item name={'billAmtTotal'} label={'账单金额'} rules={[{required: true, message: '请输入账单金额'}]}>
              <InputNumber style={{maxWidth: 200}} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name={'billDate'}
              getValueProps={value => ({value: value ? moment(value) : value})}
              getValueFromEvent={(_, value) => value}
              label={'应付款日'}
              rules={[{required: true, message: '请输入应付款日'}]}
            >
              <DatePicker style={{width: 200}} />
            </Form.Item>
          </Col>
          {/* 编辑：最后付款日 */}
          {editForm.getFieldValue('billType') === '1' && (
            <>
              <Col span={12}>
                <Form.Item
                  name={'lastPayDate'}
                  getValueProps={value => ({value: value ? moment(value) : value})}
                  getValueFromEvent={(_, value) => value}
                  label={'最后付款日'}
                  rules={[{required: true, message: '请输入最后付款日'}]}
                >
                  <DatePicker style={{width: 200}} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name={'remark'} label={'备注原因'} rules={[{required: true, message: '请输入备注原因'}]}>
                  <Input.TextArea rows={3} placeholder={'请输入'} allowClear />
                </Form.Item>
              </Col>
            </>
          )}
        </Row>
      </Form>
      {editForm.getFieldValue('billType') === '1' && (
        <>
          <DividerLine title={'房源详情'} top={10} bottom={10} />
          <Form
            form={form}
            onFinish={searchProperty}
            onReset={searchProperty}
            initialValues={{pageNum: 1, pageSize: 10, pageable: true}}
          >
            <Row>
              <Col span={10}>
                <Form.Item label={'房源地址'} name={'propertyTitle'}>
                  <Input placeholder={'房源地址'} allowClear />
                </Form.Item>
              </Col>
              <Col offset={1}>
                <Button type={'primary'} htmlType={'submit'} onClick={() => form.setFieldsValue({pageNum: 1})}>
                  查询
                </Button>
              </Col>
            </Row>
          </Form>
          <Table
            rowKey={'propertyCode'}
            dataSource={propertyList}
            columns={[
              {title: '房源code', dataIndex: 'propertyCode'},
              {title: '所属团队', dataIndex: 'teamName'},
              {title: '所属区域', dataIndex: 'districtName'},
              {title: '房源地址', dataIndex: 'propertyTitle'},
              {title: '包月费用', dataIndex: 'basePrice'},
              {
                title: '操作',
                dataIndex: 'propertyCode',
                render: (value, record) => (
                  <Button
                    type={'link'}
                    onClick={() => {
                      if (!deletePropertyCodeList.map(item => item.propertyCode).includes(value)) {
                        setDeletePropertyCodeList(state => [...state, record])
                      }
                    }}
                  >
                    删除
                  </Button>
                ),
              },
            ]}
            rowSelection={{
              selectedRowKeys: deletePropertyCodeList.map(item => item.propertyCode),
              onChange: (selectedRowKeys, selectedRows) => {
                if (selectedRowKeys.length) {
                  setDeletePropertyCodeList(state => {
                    const codes = []
                    const setArr = new Set([...state.map(item => item.propertyCode), ...selectedRowKeys])
                    for (const code of setArr) {
                      if (selectedRowKeys.includes(code)) {
                        codes.push(selectedRows.find(item => item.propertyCode === code))
                      } else {
                        codes.push(state.find(item => item.propertyCode === code))
                      }
                    }
                    return codes
                  })
                } else {
                  const codes = propertyList.map(item => item.propertyCode)
                  setDeletePropertyCodeList(state => state.filter(code => !codes.includes(code.propertyCode)))
                }
              },
              onSelect: (record, selected) => {
                if (selected) {
                  setDeletePropertyCodeList(state => [...state, record])
                } else {
                  setDeletePropertyCodeList(state => state.filter(code => code.propertyCode !== record.propertyCode))
                }
              },
            }}
            pagination={getPaginationProps(form, propertyTotal)}
            footer={() => {
              const deleteCodes = deletePropertyCodeList.map(item => item.propertyCode)
              const currentPropertyList = billPropertyList.filter(item => !deleteCodes.includes(item.propertyCode))
              const totalAmt = currentPropertyList.reduce((sum, item) => {
                return (sum += +item.basePrice)
              }, 0)
              const deleteAmt = deletePropertyCodeList.reduce((sum, item) => {
                return (sum += +item.basePrice)
              }, 0)

              return (
                <span
                  style={{color: 'red'}}
                >{`选择删除房间数量：${deletePropertyCodeList.length}间/${deleteAmt}元，剩余房间数量：${currentPropertyList.length}间/${totalAmt}元`}</span>
              )
            }}
          />
        </>
      )}
      <Row justify={'end'} style={{marginTop: 20}}>
        <Space>
          <Button onClick={() => setVisible(false)}>取消</Button>
          <Button
            type={'primary'}
            onClick={async () => {
              await editForm.validateFields()
              Modal.confirm({
                title: '修改房源账单提醒',
                icon: null,
                width: 500,
                centered: true,
                content: `确认修改“${editForm.getFieldValue('lessorName')}”的基础费用账单！`,
                onOk: async () => {
                  const {
                    billDate,
                    billName,
                    billAmtTotal,
                    billId,
                    lessorId,
                    billType,
                    remark,
                    lastPayDate,
                  } = editForm.getFieldsValue(true)
                  await lessorModel.userLessorBillUpdate({
                    billDate,
                    billName,
                    billAmtTotal,
                    billId,
                    lessorId,
                    billType,
                    remark,
                    lastPayDate,
                    idDeleteList: deletePropertyCodeList.map(item => item.id),
                  })
                  props.onSuccess()
                  setVisible(false)
                },
              })
            }}
          >
            确认
          </Button>
        </Space>
      </Row>
    </Modal>
  )
}

export default React.forwardRef(EditBill)
