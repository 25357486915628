import React, {useEffect, useState} from 'react'
import {Page} from '@/components/Page'
import styles from '@/assets/styles/common.module.less'
import {Button, Form, Input, InputNumber, message, Row, Select, Space, Typography} from 'antd'
import {feedbackModel} from '@/store/models/FeedBack'
import {useAppHistory, useAppLocation} from '@/common/hooks/useRouter'
import {useCodeArraySync} from '@/store/models/Common'
import * as listener from '@/common/hooks/listener'

export default function PunishOrder() {
  const history = useAppHistory()
  const location = useAppLocation<'/complaint/punish'>()
  const orderId = location.state?.orderId
  const [form] = Form.useForm()
  const [detail, setDetail] = useState({} as CsWorkOrderVO)
  const punishmentReason = useCodeArraySync('cs.work.order.punishment.reason')

  useEffect(() => {
    feedbackModel.csWorkOrderGet({id: orderId}).then(({response: {data}}) => {
      setDetail(data)
    })
  }, [orderId])
  return (
    <Page>
      <div className={styles.filterSection}>
        <Form
          form={form}
          labelCol={{span: 8}}
          wrapperCol={{span: 10}}
          onFinish={async () => {
            const values = form.getFieldsValue(true)
            await feedbackModel.csWorkOrderPunishment({...values, id: orderId})
            listener.emit('ComplaintWorkOrder')
            message.success('提交成功！')
            history.goBack()
          }}
        >
          <Form.Item label={'处罚部门'}>{detail.complainedDept}</Form.Item>
          <Form.Item label={'处罚人员'}>{detail.complainedEmp ?? '/'}</Form.Item>
          {detail.serviceOrderId && (
            <Form.Item label={'服务单号'}>
              <Space direction={'vertical'}>
                {detail.serviceOrderId.split(',').map(item => (
                  <Typography.Text key={item}>{item}</Typography.Text>
                ))}
              </Space>
            </Form.Item>
          )}
          <Form.Item label={'处罚理由'} name={'punishmentReason'} rules={[{required: true, message: '请选择处罚理由'}]}>
            <Select placeholder={'请选择'} onChange={() => form.setFieldsValue({punishmentOtherReason: undefined})}>
              {punishmentReason.map(item => (
                <Select.Option value={item.id} key={item.id}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item noStyle shouldUpdate={(prev, next) => prev.punishmentReason !== next.punishmentReason}>
            {f => {
              const punishmentReason = f.getFieldValue('punishmentReason')
              return (
                punishmentReason === 'other' && (
                  <Form.Item
                    label={'其他处罚理由'}
                    name={'punishmentOtherReason'}
                    rules={[{required: true, message: '请输入'}]}
                  >
                    <Input.TextArea placeholder={'请输入'} rows={3} maxLength={200} showCount allowClear />
                  </Form.Item>
                )
              )
            }}
          </Form.Item>
          <Form.Item label={'处罚金额'} name={'punishmentAmt'} rules={[{required: true, message: '请输入处罚金额'}]}>
            <InputNumber placeholder={'请输入'} style={{width: '200px'}} />
          </Form.Item>
          <Row justify={'center'}>
            <Space>
              <Button type={'primary'} htmlType={'submit'}>
                提交
              </Button>
              <Button htmlType={'reset'}>重置</Button>
            </Space>
          </Row>
        </Form>
      </div>
    </Page>
  )
}
