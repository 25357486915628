import {Model} from '@redux-model/web'
import {$api} from '@/store/service'

interface LogModel {}

class Log extends Model<LogModel> {
  protected initReducer(): LogModel {
    return {}
  }

  // 管家分配日志列表
  logDistributionList = $api.action((args: Partial<LogDistributionQueryDTO>) =>
    this.post<ApiResponse<LogDistributionVO[]>>('/log/distribution/list').body({
      pageNum: 1,
      pageSize: 10,
      pageable: true,
      ...args,
    })
  )
}

export const logModel = new Log()
