import {useMemo} from 'react'
import {Model} from '@redux-model/web'
import {$api, $biz} from '@/store/service'
import {baseCodes} from '@/store/models/Common/baseCodes'

export interface CommonModel {
  codes: Codes
  codesV2: CodesV2
}

const intialState = {
  codes: {},
  codesV2: {},
}

const empty = {}
const emptyArray = []

class Common extends Model<CommonModel> {
  // 查询客户经理列表
  // queryCusManager = $api.action(() => this.post<ApiResponse<SysUserVO[]>>('/sys-user/query-cus-manager'))

  // 百度-逆地理编码  |  object:{lat:维度,lng:经度}
  commonAppHomeMapGeocoder = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<BaiduMapGeoCoderVO>>('/common-app/home/map/geocoder').body(args)
  )

  // 中文转拼音  |  object:{key:中文}
  commonAppFuncZhToPinyin = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<string>>('/common-app/func/zh-to-pinyin').body(args)
  )

  // 添加/修改客户活动备注  |  object:{userId:客户id}
  commonAppCustomerActivityRemarkGet = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<string>>('/common-app/customer/activity-remark/get').body(args)
  )

  // 查询省、市、区、商圈码表
  queryCityCode = $biz.action((args: {type: '1' | '2' | '3' | '4'; parentCode: string}) =>
    this.post<ApiResponse<CityDataProps[]>>('/common/query-city-code').body(args)
  )

  setCodes = this.action((state, payload: Codes) => {
    state.codes = {...state.codes, ...payload}
    const v2 = Object.keys(payload).reduce((prev, key) => ({...prev, [key]: objectToArray(payload[key])}), {})
    state.codesV2 = {...state.codesV2, ...v2}
  })
  setCodesV2 = this.action((state, payload: CodesV2) => {
    state.codesV2 = {...state.codesV2, ...payload}
    const v1 = Object.keys(payload).reduce((prev, key) => ({...prev, [key]: arrayToObject(payload[key])}), {})
    state.codes = {...state.codes, ...v1}
  })

  queryCodeList = $biz.action((category?: string) => {
    return this.post<ApiResponse<CodesV2>>('/common/query-code-list/v2')
      .body({...(category && {category})})
      .onSuccess((state, action) => {
        // state.codes = {...state.codes, ...action.response.data}
        this.setCodesV2(action.response.data)
      })
  })

  getCodeArraySync = (category: CategoryKey, codeArrayProps = {} as CodeArrayProps) => {
    if (!this.data.codesV2[category]) return emptyArray
    return optionsPerhapsDefault(this.data.codesV2[category], codeArrayProps)
  }

  useCodeArraySync = (category: CategoryKey, codeArrayProps = {} as CodeArrayProps) => {
    if (!this.data.codesV2[category]) return emptyArray
    return optionsPerhapsDefault(this.data.codesV2[category], codeArrayProps)
  }

  /**
   * 同步返回 code
   * 如果失败，返回 空对象
   */
  getCodeSync = (category: CategoryKey) => {
    if (!this.data.codes[category]) return empty
    return this.data.codes[category]
  }

  useCodeSync = (category: CategoryKey) => {
    if (!this.data.codes[category]) return empty
    return this.data.codes[category]
  }

  initCodes = () => {
    this.setCodes?.(baseCodes)
    this.queryCodeList()
  }

  protected onReducerCreated(/*store: Store*/): void {
    this.initCodes && this.initCodes()
  }

  protected initReducer() {
    return intialState
  }
}

export const commonModel = new Common()

type CategoryKey = keyof typeof baseCodes | string

interface CodeArrayProps {
  keyParseToInt?: boolean
  defaultItem?: boolean | Option
}

export function objectToArray(code: CodeItem, {keyParseToInt = false, defaultItem = false} = {} as CodeArrayProps) {
  const result = !code ? [] : Object.keys(code).map(key => ({id: keyParseToInt ? Number(key) : key, label: code[key]}))
  if (typeof defaultItem === 'object') return [defaultItem, ...result]
  if (defaultItem === true) return [{id: '', label: '全部'}, ...result]
  return result
}

export function arrayToObject(options: Option[] = []): CodeItem {
  return options.reduce((prev, item) => ({...prev, [item.id]: item.label}), {})
}

// 可能给选项带上默认值
function optionsPerhapsDefault(options: Option[] = [], codeArrayProps = {} as CodeArrayProps) {
  const code = codeArrayProps.keyParseToInt ? options.map(value => ({...value, id: Number(value.id)})) : options
  if (typeof codeArrayProps.defaultItem === 'object') return [codeArrayProps.defaultItem, ...code]
  if (codeArrayProps.defaultItem === true) return [{id: null, label: '全部'}, ...code]

  return code
}

export function useCodeArraySync(category: CategoryKey, codeArrayProps = {} as CodeArrayProps) {
  const codesV2 = commonModel.useData(data => data.codesV2?.[category] ?? emptyArray)
  return useMemo(() => optionsPerhapsDefault(codesV2, codeArrayProps), [codesV2, codeArrayProps])
}

export function useCodeSync(category: CategoryKey) {
  return commonModel.useData(data => data.codes?.[category] ?? empty)
}
