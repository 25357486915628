import React, {useCallback, useEffect, useState} from 'react'
import {Button, Col, DatePicker, Form, Input, message, Modal, Row, Select, Space, Table, TreeSelect} from 'antd'
import configStyles from './index.module.less'
import {PlusCircleFilled, MinusCircleFilled} from '@ant-design/icons'
import {saleModel} from '@/store/models/Sale'
import {useCodeArraySync, useCodeSync} from '@/store/models/Common'
import {getPaginationProps} from '@/common/biz/getPaginationProps'
import moment from 'moment'
import {usePermission} from '@/components/Permission'
import {downloadBlob} from '@/utils/util'
import {customerModel} from '@/store/models/Customer'

export default function ClientContractConfig({customerId}: {customerId: string}) {
  const rentTypeCode = useCodeSync('property.rentType')
  const rentTypeArr = useCodeArraySync('property.rentType')
  const cleanFeeTypeCode = useCodeSync('clean.fee.type')
  const cleanFeeTypeArr = useCodeArraySync('clean.fee.type')
  const stewardFeeTypeCode = useCodeSync('steward.fee.type')
  const stewardFeeTypeArr = useCodeArraySync('steward.fee.type')
  const [auth] = usePermission()

  // 客户房源列表
  const [form] = Form.useForm()
  const [propertyTotal, setPropertyTotal] = useState(0)
  const [propertyList, setPropertyList] = useState<SaleCustomerContractPropertyListVO[]>([])

  const queryContractPropertyList = useCallback(() => {
    const formValues = form.getFieldsValue(true)
    // 查房源列表
    saleModel.saleCustomerContractPropertyList(formValues).then(({response: {data, total}}) => {
      setPropertyList(data)
      setPropertyTotal(total)
    })
  }, [form])

  useEffect(() => {
    queryContractPropertyList()
  }, [queryContractPropertyList])

  // 客户默认合同
  const [defaultInfo, setDefaultInfo] = useState({} as SaleCustomerContractPropertyVO)

  const queryContractPropertyDefaultInfo = useCallback(() => {
    // 查询默认合同 没有合同会返回null
    saleModel.saleCustomerContractPropertyDefaultInfo({customerId}).then(({response: {data}}) => setDefaultInfo(data))
  }, [customerId])

  useEffect(() => {
    queryContractPropertyDefaultInfo()
  }, [queryContractPropertyDefaultInfo])

  // 客户合同列表
  const [contractList, setContractList] = useState<SaleCustomerContractVO[]>([])

  const queryCustomerContractList = useCallback(() => {
    // 查询客户合同列表：待签约和合作中的
    saleModel
      .saleCustomerContractList({customerId, pageNum: 1, pageSize: 50, pageable: false, contractStatusList: ['1']})
      .then(({response: {data}}) => setContractList(data))
  }, [customerId])

  useEffect(() => {
    queryCustomerContractList()
  }, [queryCustomerContractList])

  // 编辑默认合同
  const [isDefaultEdit, setIsDefaultEdit] = useState(false)
  const [selectedContract, setSelectedContract] = useState('')

  // 绑定合同 || 批量配置合同
  const [configForm] = Form.useForm()
  const [bindVisible, setBindVisible] = useState(false)
  const [bindProperty, setBindProperty] = useState<{propertyCode: string; title: string}>({
    propertyCode: null,
    title: null,
  })
  // 批量绑定
  const [isBatchConfig, setIsBatchConfig] = useState(false)
  const [bindPropertys, setBindPropertys] = useState([])
  // 批量解绑
  const [isBatchUnbind, setIsBatchUnbind] = useState(false)

  // 查看合同
  const [checkContract, setCheckContract] = useState<{visible: boolean; title?: string}>({visible: false})
  const [bindContractList, setBindContractList] = useState<SaleCustomerContractPropertyVO[]>([])

  const queryContractPropertyBindList = useCallback(async (propertyCode: string) => {
    const {
      response: {data},
    } = await saleModel.saleCustomerContractPropertyBindList({propertyCode})
    return data
  }, [])

  // 历史记录
  const [historyParams, setHistoryParams] = useState<SaleCustomerContractPropertyHistoryListDTO>({
    pageNum: 1,
    pageSize: 10,
    pageable: true,
    propertyCode: null,
  })
  const [historyTotal, setHistoryTotal] = useState(0)
  const [historyModal, setHistoryModal] = useState<{visible: boolean; title: string}>({visible: false, title: null})
  const [historyList, setHistoryList] = useState<SaleCustomerContractPropertyHistoryVO[]>([])

  useEffect(() => {
    if (historyParams.propertyCode) {
      saleModel.saleCustomerContractPropertyHistoryList(historyParams).then(({response: {data, total}}) => {
        setHistoryList(data)
        setHistoryTotal(total)
      })
    }
  }, [historyParams])

  // 团队接口
  const [teamTree, setTeamTree] = useState<TeamTreeNodeVO[]>([])
  useEffect(() => {
    customerModel.customerTeamGetTeamTree({ownerId: customerId}).then(({response: {data}}) => {
      setTeamTree(data)
    })
  }, [customerId])

  const propertyListLoading = saleModel.saleCustomerContractPropertyList.useLoading()
  const defaultUpdateLoading = saleModel.saleCustomerContractPropertyDefaultUpdate.useLoading()
  const bindContractListLoading = saleModel.saleCustomerContractPropertyBindList.useLoadings()
  const bindLoading = saleModel.saleCustomerContractPropertyBind.useLoading()
  const historyListLoading = saleModel.saleCustomerContractPropertyHistoryList.useLoading()

  return (
    <div>
      <div className={configStyles.formContainer}>
        <Form
          form={form}
          onFinish={queryContractPropertyList}
          onReset={queryContractPropertyList}
          initialValues={{pageNum: 1, pageSize: 10, pageable: true, customerId}}
        >
          <Row gutter={30}>
            <Col span={8}>
              <Form.Item name={'title'} label={'房源标题'}>
                <Input placeholder={'请输入'} allowClear />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name={'propertyCodeListStr'} label={'房源code'}>
                <Input placeholder={'请输入房源code:code1,code2...'} allowClear />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name={'contractCode'} label={'合同编号'}>
                <Input placeholder={'请输入'} allowClear />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name={'rentType'} label={'房源类型'}>
                <Select placeholder={'请选择'} allowClear>
                  {rentTypeArr.map(item => (
                    <Select.Option key={item.id} value={item.id}>
                      {item.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name={'stewardFeeType'} label={'管家收费'}>
                <Select placeholder={'请选择'} allowClear>
                  {stewardFeeTypeArr.map(item => (
                    <Select.Option key={item.id} value={item.id}>
                      {item.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name={'cleanFeeType'} label={'保洁收费'}>
                <Select placeholder={'请选择'} allowClear>
                  {cleanFeeTypeArr.map(item => (
                    <Select.Option key={item.id} value={item.id}>
                      {item.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name={'teamIdList'} label={'团队选择'}>
                <TreeSelect
                  fieldNames={{label: 'name', value: 'teamId', children: 'children'}}
                  allowClear
                  treeDefaultExpandAll
                  treeData={teamTree}
                  multiple
                  placeholder={'请选择'}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row justify={'end'}>
            <Space>
              <Button type={'primary'} htmlType={'submit'} onClick={() => form.setFieldsValue({pageNum: 1})}>
                查询
              </Button>
              <Button htmlType={'reset'}>重置</Button>
            </Space>
          </Row>
        </Form>
      </div>

      <div className={configStyles.tableContainer}>
        <div className={configStyles.headerContainer}>
          {isBatchConfig || isBatchUnbind ? (
            isBatchConfig ? (
              <>
                <span />
                <Space>
                  <Button
                    onClick={() => {
                      setIsBatchConfig(false)
                      setBindPropertys([])
                    }}
                  >
                    取消配置
                  </Button>
                  <Button
                    type={'primary'}
                    onClick={() => {
                      if (!bindPropertys.length) return message.warning('请选择房源')
                      // 设置默认项
                      configForm.setFieldsValue({
                        contractListForBind: [{bindBeginDate: null, bindEndDate: null, contractId: null}],
                      })
                      setBindVisible(true)
                    }}
                  >
                    批量绑定合同
                  </Button>
                </Space>
              </>
            ) : (
              <>
                <span />
                <Space>
                  <Button
                    onClick={() => {
                      setIsBatchUnbind(false)
                      setBindPropertys([])
                    }}
                  >
                    取消
                  </Button>
                  <Button
                    type={'primary'}
                    onClick={() => {
                      if (!bindPropertys.length) return message.warning('请选择房源')
                      Modal.confirm({
                        title: '批量解绑合同',
                        icon: null,
                        centered: true,
                        content: '确认批量解绑所有合同？',
                        onOk: async () => {
                          await saleModel.saleCustomerContractRescindBatch(bindPropertys)
                          message.success('批量解绑成功！')

                          Modal.confirm({
                            title: '已解绑合同房源',
                            icon: null,
                            centered: true,
                            content: (
                              <div>
                                <span>{bindPropertys.join(',')}</span>
                                <Button
                                  type={'link'}
                                  onClick={() => {
                                    navigator.clipboard.writeText(bindPropertys.join(',')).then(value => {
                                      message.success('复制成功！')
                                    })
                                  }}
                                >
                                  复制
                                </Button>
                              </div>
                            ),
                            okCancel: false,
                          })

                          setBindPropertys([])
                          setIsBatchUnbind(false)
                          queryContractPropertyList()
                        },
                      })
                    }}
                  >
                    解绑合同
                  </Button>
                </Space>
              </>
            )
          ) : isDefaultEdit ? (
            <Space>
              <label>全部房源默认合同：</label>
              <Select
                placeholder={'请选择'}
                style={{width: '200px'}}
                onSelect={(value: string) => setSelectedContract(value)}
              >
                {contractList.map(item => (
                  <Select.Option key={item.contractId} value={item.contractId}>
                    {item.contractCode}
                  </Select.Option>
                ))}
              </Select>
              <Button type={'link'} style={{padding: 0}} onClick={() => setIsDefaultEdit(false)}>
                取消
              </Button>
              <Button
                type={'link'}
                style={{padding: 0}}
                loading={defaultUpdateLoading}
                onClick={async () => {
                  if (!selectedContract) return message.warning('请选择合同')
                  await saleModel.saleCustomerContractPropertyDefaultUpdate({customerId, contractId: selectedContract})
                  message.success('编辑成功！')
                  setIsDefaultEdit(false)
                  queryContractPropertyDefaultInfo()
                }}
              >
                保存
              </Button>
            </Space>
          ) : (
            <>
              <Space>
                <span>
                  全部房源默认合同：
                  <span className={configStyles.contractEdit}>{defaultInfo?.contractCode}</span>
                </span>
                <Button type={'link'} onClick={() => setIsDefaultEdit(true)}>
                  <span className={configStyles.contractEdit}>编辑</span>
                </Button>
              </Space>
              <Space>
                {auth('007064001') && (
                  <Button
                    type={'primary'}
                    onClick={() =>
                      downloadBlob({
                        url: '/sale/customer/contract/property/list-excel',
                        body: {...form.getFieldsValue(true), pageable: false},
                      })
                    }
                  >
                    导出
                  </Button>
                )}
                <Button type={'primary'} onClick={() => setIsBatchConfig(true)}>
                  批量配置合同
                </Button>
                <Button type={'primary'} onClick={() => setIsBatchUnbind(true)}>
                  批量解绑合同
                </Button>
              </Space>
            </>
          )}
        </div>
        <Table
          rowSelection={
            isBatchConfig || isBatchUnbind
              ? {
                  selectedRowKeys: bindPropertys,
                  onChange: selectedRowKeys => setBindPropertys(selectedRowKeys),
                }
              : null
          }
          rowKey={'propertyCode'}
          loading={propertyListLoading}
          dataSource={propertyList}
          columns={[
            {
              title: '组织-团队',
              dataIndex: 'teamPname',
              render: (value, record) => [value, record.teamName].filter(item => item).join('-'),
            },
            {title: '房源code', dataIndex: 'propertyCode'},
            {title: '房源标题', dataIndex: 'title'},
            {title: '房源类型', dataIndex: 'rentType', render: value => rentTypeCode[value]},
            {
              title: '合同类型',
              dataIndex: 'contractSteward',
              render: (value, record) =>
                [value === '1' && '管家', record.contractClean === '1' && '保洁'].filter(item => item).join('、'),
            },
            {title: '管家收费类型', dataIndex: 'stewardFeeType', render: value => stewardFeeTypeCode[value]},
            {title: '保洁收费类型', dataIndex: 'cleanFeeType', render: value => cleanFeeTypeCode[value]},
            {
              title: '当前绑定合同',
              dataIndex: 'contractCode',
              render: value => value || `${defaultInfo?.contractCode}（默认）`,
            },
            {
              title: '绑定使用期',
              dataIndex: 'bindBeginDate',
              render: (value, record) =>
                [value, record.bindEndDate]
                  .filter(item => item)
                  .map(item => moment(item).format('YYYY-MM-DD'))
                  .join('至'),
            },
            {
              title: '合同时间',
              dataIndex: 'contractBeginDate',
              render: (value, record) =>
                [value, record.contractEndDate]
                  .filter(item => item)
                  .map(item => moment(item).format('YYYY-MM-DD'))
                  .join('至'),
            },
            {
              fixed: 'right',
              title: '操作',
              render: (_, record) => {
                return (
                  <Space>
                    <Button
                      type={'link'}
                      style={{padding: 0}}
                      disabled={isBatchConfig}
                      onClick={async () => {
                        const data = await queryContractPropertyBindList(record.propertyCode)
                        configForm.setFieldsValue({
                          contractListForBind: [
                            ...data.map(item => ({
                              bindBeginDate: moment(item.bindBeginDate).format('YYYY-MM-DD'),
                              bindEndDate: moment(item.bindEndDate).format('YYYY-MM-DD'),
                              _bindDate: [moment(item.bindBeginDate), moment(item.bindEndDate)],
                              contractBeginDate: moment(item.contractBeginDate).format('YYYY-MM-DD'),
                              contractEndDate: moment(item.contractEndDate).format('YYYY-MM-DD'),
                              contractId: item.contractId,
                              contractCode: item.contractCode,
                              id: item.id,
                            })),
                            undefined,
                          ],
                        })
                        setBindContractList(data)
                        setBindVisible(true)
                        setBindProperty({propertyCode: record.propertyCode, title: record.title})
                      }}
                    >
                      绑定合同
                    </Button>
                    <Button
                      type={'link'}
                      style={{padding: 0}}
                      loading={bindContractListLoading.pick(record.propertyCode)}
                      onClick={async () => {
                        const data = await queryContractPropertyBindList(record.propertyCode)
                        setBindContractList(data)
                        setCheckContract({visible: true, title: record.title})
                      }}
                    >
                      查看合同
                    </Button>
                    <Button
                      type={'link'}
                      style={{padding: 0}}
                      onClick={() => {
                        setHistoryModal({visible: true, title: record.title})
                        setHistoryParams(prevState => ({...prevState, propertyCode: record.propertyCode}))
                      }}
                    >
                      历史记录
                    </Button>
                  </Space>
                )
              },
            },
          ]}
          scroll={{x: 'max-content'}}
          pagination={getPaginationProps(form, propertyTotal)}
        />
      </div>

      <Modal
        open={bindVisible}
        title={'绑定合同'}
        centered
        footer={null}
        width={'860px'}
        onCancel={() => setBindVisible(false)}
        afterClose={() => configForm.resetFields()}
        destroyOnClose
      >
        <div className={configStyles.modalHeader}>
          {bindPropertys.length ? (
            <span className={configStyles.modalTitle}>{`已选${bindPropertys.length}间房`}</span>
          ) : (
            <span className={configStyles.modalTitle}>{bindProperty.title}</span>
          )}
        </div>
        <Form
          form={configForm}
          onFinish={async () => {
            const formValues = configForm.getFieldsValue(true)
            // 先过滤一遍
            const contractListForBind = formValues.contractListForBind.filter(item => item)
            if (contractListForBind.some(item => !item.contractId || !item.bindBeginDate))
              return message.warning('请选择合同信息！')
            const idListForDelete = []
            if (!bindPropertys.length) {
              // 过滤出绑定过的合同
              const isBindContractId = contractListForBind.filter(item => item.id).map(item => item.id)
              // 过滤出删除的绑定过的合同
              const deleteIds = bindContractList
                .filter(item => !isBindContractId.includes(item.id))
                .map(item => item.id)
              idListForDelete.push(...deleteIds)
            }
            await saleModel.saleCustomerContractPropertyBind({
              contractListForBind: contractListForBind.filter(item => !item.id), // 过滤掉已绑定的合同
              idListForDelete,
              propertyCodeList: bindPropertys.length ? bindPropertys : [bindProperty.propertyCode],
            })
            message.success('绑定成功！')
            // 批量绑定需要置空的数据
            setBindPropertys([])
            setIsBatchConfig(false)
            setBindVisible(false)
            queryContractPropertyList()
          }}
          onReset={() => setBindVisible(false)}
        >
          <Form.List name={'contractListForBind'}>
            {(fields, {add, remove}) => {
              return (
                <>
                  <div className={configStyles.modalTableHeader}>
                    <div className={configStyles.tableHeaderItem}>合同</div>
                    <div className={configStyles.tableHeaderItem}>绑定使用期</div>
                    <div className={configStyles.tableHeaderItem}>合同时间</div>
                    <div className={configStyles.tableHeaderOperate} />
                  </div>
                  {fields.map((field, index) => {
                    return (
                      <div key={field.key} className={configStyles.modalTableContent}>
                        <div className={configStyles.tableContentItem}>
                          <Form.Item
                            name={[field.name, 'contractId']}
                            fieldKey={[field.fieldKey, 'contractId']}
                            className={configStyles.tableForm}
                          >
                            <Select
                              placeholder={'请选择'}
                              style={{width: '100%'}}
                              bordered={false}
                              onChange={(id, option) => {
                                const currentContract = contractList.find(item => item.contractId === id)
                                configForm.setFields([
                                  {
                                    name: ['contractListForBind', field.name, 'contractCode'],
                                    value: ((option as unknown) as {key: string; value: string; children: string})
                                      .children,
                                  },
                                  {
                                    name: ['contractListForBind', field.name, '_bindDate'],
                                    value: [
                                      moment(currentContract.contractBeginDate),
                                      moment(currentContract.contractEndDate),
                                    ],
                                  },
                                  {
                                    name: ['contractListForBind', field.name, 'bindBeginDate'],
                                    value: currentContract.contractBeginDate,
                                  },
                                  {
                                    name: ['contractListForBind', field.name, 'bindEndDate'],
                                    value: currentContract.contractEndDate,
                                  },
                                  {
                                    name: ['contractListForBind', field.name, 'contractBeginDate'],
                                    value: currentContract.contractBeginDate,
                                  },
                                  {
                                    name: ['contractListForBind', field.name, 'contractEndDate'],
                                    value: currentContract.contractEndDate,
                                  },
                                ])
                              }}
                            >
                              {contractList.map(item => (
                                <Select.Option key={item.contractId} value={item.contractId}>
                                  {item.contractCode}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </div>
                        <div className={configStyles.tableContentItem}>
                          <Form.Item noStyle shouldUpdate>
                            {form1 => {
                              // 合同开始时间
                              const contractBeginDate = form1.getFieldValue([
                                'contractListForBind',
                                field.name,
                                'contractBeginDate',
                              ])
                              // 合同结束时间
                              const contractEndDate = form1.getFieldValue([
                                'contractListForBind',
                                field.name,
                                'contractEndDate',
                              ])
                              // 先选合同 再选时间
                              const contractId = form1.getFieldValue(['contractListForBind', field.name, 'contractId'])
                              return (
                                <Form.Item
                                  name={[field.name, '_bindDate']}
                                  fieldKey={[field.fieldKey, '_bindDate']}
                                  className={configStyles.tableForm}
                                >
                                  <DatePicker.RangePicker
                                    bordered={false}
                                    onChange={(_, formatString) => {
                                      form1.setFields([
                                        {
                                          name: ['contractListForBind', field.name, 'bindBeginDate'],
                                          value: formatString[0],
                                        },
                                        {
                                          name: ['contractListForBind', field.name, 'bindEndDate'],
                                          value: formatString[1],
                                        },
                                      ])
                                    }}
                                    disabled={!contractId}
                                    disabledDate={date => {
                                      // 使用期限制在租约开始到租约结束范围内
                                      return !!(
                                        date.isBefore(moment(contractBeginDate) as any) ||
                                        date.isAfter(moment(contractEndDate).add(1, 'day') as any)
                                      )
                                    }}
                                  />
                                </Form.Item>
                              )
                            }}
                          </Form.Item>
                        </div>
                        <div className={configStyles.tableContentItem}>
                          <Form.Item noStyle style={{width: '100%'}} shouldUpdate>
                            {f =>
                              [
                                f.getFieldValue(['contractListForBind', field.name, 'contractBeginDate']),
                                f.getFieldValue(['contractListForBind', field.name, 'contractEndDate']),
                              ]
                                .filter(item => item)
                                .join('至')
                            }
                          </Form.Item>
                        </div>
                        {/*<div className={configStyles.tableContentItem}>*/}
                        {/*  <span style={{marginLeft: '20px'}}>/</span>*/}
                        {/*</div>*/}
                        <div className={configStyles.tableContentOperate}>
                          <Space>
                            <MinusCircleFilled style={{color: '#FF4D4D'}} onClick={() => remove(index)} />
                            {fields.length - index === 1 && (
                              <PlusCircleFilled
                                style={{color: '#00B7AE'}}
                                onClick={async () => {
                                  const contractId = configForm.getFieldValue([
                                    'contractListForBind',
                                    field.name,
                                    'contractId',
                                  ])
                                  const _bindDate = configForm.getFieldValue([
                                    'contractListForBind',
                                    field.name,
                                    '_bindDate',
                                  ])
                                  if (!contractId) return message.warning('请选择合同')
                                  if (!_bindDate) return message.warning('请选择绑定使用期')
                                  add()
                                }}
                              />
                            )}
                          </Space>
                        </div>
                      </div>
                    )
                  })}
                </>
              )
            }}
          </Form.List>
          <Row justify={'end'} style={{marginTop: '30px'}}>
            <Space>
              <Button htmlType={'reset'}>取消</Button>
              <Button type={'primary'} htmlType={'submit'} loading={bindLoading}>
                保存
              </Button>
            </Space>
          </Row>
        </Form>
      </Modal>

      {/** 查看合同 */}
      <Modal
        open={checkContract.visible}
        title={'查看合同'}
        centered
        footer={null}
        width={'860px'}
        onCancel={() => setCheckContract({visible: false})}
      >
        <div className={configStyles.modalHeader}>
          <span className={configStyles.modalTitle}>{checkContract.title}</span>
        </div>
        <Table
          rowKey={'id'}
          dataSource={bindContractList}
          columns={[
            {title: '合同', dataIndex: 'contractCode'},
            {
              title: '绑定使用期',
              dataIndex: 'bindBeginDate',
              render: (value, record) =>
                [value, record.bindEndDate]
                  .filter(item => item)
                  .map(item => moment(item).format('YYYY-MM-DD'))
                  .join('至'),
            },
            {
              title: '合同时间',
              dataIndex: 'contractBeginDate',
              render: (value, record) =>
                [value, record.contractEndDate]
                  .filter(item => item)
                  .map(item => moment(item).format('YYYY-MM-DD'))
                  .join('至'),
            },
          ]}
          pagination={false}
        />
      </Modal>

      <Modal
        open={historyModal.visible}
        title={'历史记录'}
        centered
        footer={null}
        width={'860px'}
        onCancel={() => setHistoryModal({visible: false, title: null})}
        afterClose={() => setHistoryParams({pageNum: 1, pageSize: 10, pageable: true, propertyCode: null})}
      >
        <Space direction={'vertical'} className={configStyles.modalHeader}>
          <span style={{fontSize: '16px', color: '#000'}}>{historyModal.title}</span>
        </Space>
        <Table
          rowKey={'historyId'}
          loading={historyListLoading}
          dataSource={historyList}
          columns={[
            {title: '类型', dataIndex: 'historyType'},
            {title: '绑定合同', dataIndex: 'contractCode'},
            {
              title: '绑定使用期',
              dataIndex: 'bindBeginDate',
              render: (value, record) =>
                [value, record.bindEndDate]
                  .filter(item => item)
                  .map(item => moment(item).format('YYYY-MM-DD'))
                  .join('至'),
            },
            {title: '时间', dataIndex: 'insertTime'},
            {title: '操作人', dataIndex: 'operatorName'},
          ]}
          pagination={{
            total: historyTotal,
            pageSize: historyParams.pageSize,
            current: historyParams.pageNum,
            position: ['bottomCenter'],
            hideOnSinglePage: false,
            showSizeChanger: true,
            onChange: (pageNum, pageSize) => {
              setHistoryParams(prevState => ({...prevState, pageNum, pageSize}))
            },
          }}
        />
      </Modal>
    </div>
  )
}
