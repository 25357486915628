import React from 'react'
import img1 from '@/assets/stewardHome/stewardOrder.png'
import img2 from '@/assets/stewardHome/serviceTypeManage.png'
import img3 from '@/assets/stewardHome/serviceCharge.png'
import img4 from '@/assets/stewardHome/property.png'
import img5 from '@/assets/stewardHome/siteManage.png'
import img6 from '@/assets/stewardHome/siteReport.png'
import img7 from '@/assets/stewardHome/siteMarital.png'
import img8 from '@/assets/stewardHome/qaManage.png'
import img9 from '@/assets/stewardHome/stewardList.png'
import img10 from '@/assets/stewardHome/stewardFirst.png'
import img11 from '@/assets/stewardHome/stewardWork.png'
import img12 from '@/assets/stewardHome/stewardPerformance.png'
import {useAppHistory} from '@/common/hooks/useRouter'
import styles from './index.module.less'
import {userModel} from '@/store/models/User'
import {dashboardUrl} from '@/common/config'

function MenuBar() {
  const history = useAppHistory()
  const roleIdList = userModel.data.userInfo?.roleIdList
  const token = userModel.useData(data => data.token)
  // 快捷入口
  const fastEntrances: QuickEntranceProp[] = [
    {
      label: '数字大屏',
      img: img1,
      onClick: () => {
        window.open(`${dashboardUrl}?token=${token}#/`)
      },
    },
    {
      label: '服务单列表',
      img: img1,
      onClick: () => {
        history.push({pathname: '/order-list'})
      },
    },
    {
      label: '服务类型管理',
      img: img2,
      onClick: () => {
        history.push({pathname: '/steward_serviceManager'})
      },
    },
    {
      label: '服务收费类目',
      img: img3,
      onClick: () => {
        history.push({pathname: '/service-charges'})
      },
    },
    {
      label: '房源分配',
      img: img4,
      onClick: () => {
        history.push({pathname: '/steward_list'})
      },
    },
    {
      label: '站点管理',
      img: img5,
      onClick: () => {
        history.push({pathname: '/site'})
      },
    },
    {
      label: '仓储报表',
      img: img6,
      onClick: () => {
        history.push({pathname: '/report'})
      },
    },
    {
      label: '物品管理',
      img: img7,
      onClick: () => {
        history.push({pathname: '/goods'})
      },
    },
    {
      label: 'QA管理',
      img: img8,
      onClick: () => {
        history.push({pathname: '/qa'})
      },
    },
    {
      label: '管家列表',
      img: img9,
      onClick: () => {
        history.push({pathname: '/steward-list'})
      },
    },
    {
      label: '管家首次接单',
      img: img10,
      onClick: () => {
        history.push({pathname: '/steward-daily-log'})
      },
    },
    {
      label: '管家排班',
      img: img11,
      onClick: () => {
        history.push({pathname: '/schedule'})
      },
    },
    {
      hidden: roleIdList.includes('steward_manager'), // 管家主管隐藏管家绩效入口
      label: '管家绩效',
      img: img12,
      onClick: () => {
        history.push({pathname: '/performance'})
      },
    },
  ]

  return (
    <div className={styles.quickView}>
      {fastEntrances
        .filter(item => !item.hidden)
        .map((item, index) => (
          <QuickEntrance key={index} {...item} />
        ))}
    </div>
  )
}

interface QuickEntranceProp {
  label: string
  img: string
  onClick?: () => void
  hidden?: boolean
}

const QuickEntrance: React.FC<QuickEntranceProp> = ({label, img, onClick}) => {
  return (
    <div className={styles.col} onClick={() => onClick?.()}>
      <img src={img} width={32} height={32} />
      <span style={{marginTop: 8, whiteSpace: 'nowrap'}}>{label}</span>
    </div>
  )
}

export default React.forwardRef(MenuBar)
