import React, {useEffect, useState} from 'react'
import {Button, Col, DatePicker, Form, Input, Modal, Row, Space, Switch, Table} from 'antd'
import {ExclamationCircleFilled} from '@ant-design/icons'
import {Link, useHistory} from 'react-router-dom'
import {lesseeModel} from '@/store/models/Lessee'
import {useCodeSync} from '@/store/models/Common'

import './index.less'
import {Breadcrumb} from '@/components/Breadcrumb'
import commonStyles from '@/assets/styles/common.module.less'
import {Permission, usePermission} from '@/components/Permission'
import {Page} from '@/components/Page'
import {getPaginationProps} from '@/common/biz/getPaginationProps'
import {useFocusEffect} from '@/common/hooks/useFocusEffect'
import moment from 'moment/moment'
import {FORMAT_DATE_END, FORMAT_DATE_START} from '@/common/date'

const {confirm} = Modal

export default function LesseeList() {
  const [auth] = usePermission()
  const history = useHistory()
  const [form] = Form.useForm()
  const [dataSource, setDataSource] = useState<LesseeListVO[]>()
  const [total, setTotal] = useState(0)
  const loading = lesseeModel.queryLesseeList.useLoading()

  const gender = useCodeSync('gender')
  const userStatus = useCodeSync('userStatus')

  function queryLesseeList() {
    const {_time, ...formValue} = form.getFieldsValue(true)
    const [startTime, endTime] = _time
    lesseeModel.queryLesseeList({...formValue, startTime, endTime}).then(({response: {data, total}}) => {
      setDataSource(data)
      setTotal(total)
    })
  }

  useFocusEffect(() => {
    form.submit()
  })

  useEffect(() => {
    form.submit()
  }, [form])

  function changeStatus(checked: boolean, record: LesseeListVO, event) {
    const {userId, certName, mobile, status} = record
    confirm({
      title: '提示',
      icon: <ExclamationCircleFilled />,
      content: (
        <>
          请确认已经核实过租客用户<span style={{color: '#FAAD14'}}>{certName}</span>手机号
          <span style={{color: '#FAAD14'}}>{mobile}</span>的相关信息，并符合{status === '1' ? '禁用' : '启用'}标准
        </>
      ),
      onOk() {
        lesseeModel.lesseeChangeStatus(checked ? '1' : '0', userId).then(() => {
          form.submit()
        })
      },
      // onCancel() {},
    })
    event.stopPropagation()
  }

  function edit(record, event) {
    const location = {
      pathname: '/lessee/addoredit',
      state: {userId: record.userId},
    }
    history.push(location)
    event.stopPropagation()
  }

  return (
    <Page
      breadcrumb={
        <Breadcrumb
          right={
            <Permission code={'003008002'}>
              <Button>
                <Link to={{pathname: '/lessee/addoredit'}}>添加用户</Link>
              </Button>
            </Permission>
          }
        />
      }
    >
      <div className={commonStyles.filterSection}>
        <Form
          form={form}
          autoComplete={'off'}
          initialValues={{
            pageNum: 1,
            pageSize: 10,
            pageable: true,
            _time: [moment().subtract(31, 'day').format(FORMAT_DATE_START), moment().format(FORMAT_DATE_END)],
          }}
          onFinish={queryLesseeList}
          onReset={queryLesseeList}
        >
          <Row gutter={20}>
            <Col span={7}>
              <Form.Item label={'用户姓名'} name={'name'}>
                <Input allowClear placeholder={'请输入用户姓名'} />
              </Form.Item>
            </Col>
            <Col span={7} offset={1}>
              <Form.Item label={'手机号'} name={'mobile'}>
                <Input allowClear placeholder={'请输入手机号'} />
              </Form.Item>
            </Col>
            <Col span={7} offset={1}>
              <Form.Item
                label={'注册时间'}
                name={'_time'}
                getValueProps={value => ({value: value ? value.map(item => moment(item)) : []})}
                getValueFromEvent={(_, dateStrings) => {
                  const [startTime, endTime] = dateStrings
                  if (startTime && endTime) {
                    return [moment(startTime).format(FORMAT_DATE_START), moment(endTime).format(FORMAT_DATE_END)]
                  }
                  return undefined
                }}
                rules={[
                  {
                    validator: (rule, value, callback) => {
                      if (value) {
                        const [startTime, endTime] = value
                        const intervalDay = moment(endTime).diff(startTime, 'day')
                        if (intervalDay > 31) return callback('注册时间间隔不超过31天')
                      } else {
                        return callback('注册时间不能为空')
                      }
                      return callback()
                    },
                  },
                ]}
              >
                <DatePicker.RangePicker />
              </Form.Item>
            </Col>
          </Row>
          <Row justify={'end'}>
            <Space>
              <Button type={'primary'} htmlType={'submit'} onClick={() => form.setFieldsValue({pageNum: 1})}>
                查询
              </Button>
              <Button htmlType={'reset'}>重置</Button>
            </Space>
          </Row>
        </Form>
      </div>

      <div className={commonStyles.tableSection}>
        <Table
          rowKey={'userId'}
          dataSource={dataSource}
          loading={loading}
          scroll={{x: 'max-content'}}
          columns={[
            {title: 'ID', dataIndex: 'userId', key: 'userId'},
            {title: '用户姓名', dataIndex: 'certName', key: 'certName'},
            {
              title: '性别',
              dataIndex: 'gender',
              key: 'gender',
              render: text => {
                return <span>{text ? gender[text] : ''}</span>
              },
            },
            {title: '注册手机', dataIndex: 'mobile', key: 'mobile'},
            {title: '注册时间', dataIndex: 'registerTime', key: 'registerTime'},
            {
              title: '状态',
              dataIndex: 'status',
              key: 'status',
              render: text => {
                return <span>{userStatus[text]}</span>
              },
            },
            {
              title: '操作',
              dataIndex: 'operation',
              width: 180,
              fixed: 'right',
              render: (text, record, index) => {
                return (
                  <Space>
                    {auth('005008002') && (
                      <Switch
                        checked={record.status === '1'}
                        onChange={(checked, event) => changeStatus(checked, record, event)}
                        size={'small'}
                        style={{marginRight: '10px'}}
                      />
                    )}
                    {auth('002008002') && (
                      <Button type={'link'} size={'small'} onClick={event => edit(record, event)}>
                        编辑
                      </Button>
                    )}
                    {auth('001008002') && (
                      <Button
                        type={'link'}
                        size={'small'}
                        onClick={() =>
                          history.push({
                            pathname: '/lessee/detail',
                            state: {userId: record.userId},
                          })
                        }
                      >
                        查看
                      </Button>
                    )}
                  </Space>
                )
              },
            },
          ]}
          pagination={getPaginationProps(form, total)}
        />
      </div>
    </Page>
  )
}
