import React, {useState, useImperativeHandle} from 'react'
import {$api, createHeaders} from '@/store/service'
import {Upload, message, Button, Modal, Typography, Space} from 'antd'
import {UploadOutlined} from '@ant-design/icons'

const {Text} = Typography

interface UploadProps {
  onSuccess?: (value) => unknown
  title: string
  uri: string
}

export async function uploadExcelData({
  info,
  uri,
  onLoading,
  onSuccess,
  onError,
}: {
  info: any
  uri: string
  onLoading?: (loading: boolean) => void
  onSuccess?: (value) => void
  onError?: () => void
}) {
  const {name} = info.file
  if (!['.xlsx', '.xls'].includes(name && name.substring(name.lastIndexOf('.'), name.length).toLowerCase())) {
    message.warning('请上传正确格式Excel')
    return false
  }
  // setUpLoading(true)
  onLoading?.(true)
  const fd = new FormData()
  fd.append('file', info.file)
  for (const key in info.data) {
    fd.append(key, info.data?.[key])
  }
  const res: any = await $api
    .postAsync({
      uri,
      body: fd,
      requestOptions: {
        headers: {
          ...createHeaders(),
          'Content-Type': 'multipart/form-data',
        },
      },
    })
    .catch(e => {
      message.error(e.message)
      onError?.()
      onLoading?.(false)
    })

  if (res) {
    const {
      response: {code, data},
    } = res
    if (code === 200) {
      message.success('导入成功！')
      onLoading?.(false)
      onSuccess?.(data)
    }
  }
}

function UploadExcel({onSuccess, title, uri}: UploadProps, ref) {
  const [visible, setVisible] = useState(false)
  const [loading, setLoading] = useState(false)

  //上传excel
  // async function uploadChange(info) {
  //   const {name} = info.file
  //   if (!['.xlsx', '.xls'].includes(name && name.substring(name.lastIndexOf('.'), name.length).toLowerCase())) {
  //     message.warning('请上传正确格式Excel')
  //     return false
  //   }
  //   setLoading(true)
  //   const fd = new FormData()
  //   fd.append('file', info.file)
  //   const res: any = await $api
  //     .postAsync({
  //       uri,
  //       body: fd,
  //       requestOptions: {
  //         headers: {
  //           ...createHeaders(),
  //           'Content-Type': 'multipart/form-data',
  //         },
  //       },
  //     })
  //     .catch(e => {
  //       message.error(e.message)
  //       setLoading(false)
  //     })
  //
  //   if (res) {
  //     const {
  //       response: {code, data},
  //     } = res
  //     if (code === 200) {
  //       ref.current.onHidden()
  //       setLoading(false)
  //       onSuccess && onSuccess(data)
  //     }
  //   }
  // }

  useImperativeHandle(ref, () => ({
    uploadExcel: () => {
      setVisible(true)
    },
    onHidden: () => {
      setVisible(false)
    },
  }))

  return (
    <Modal
      visible={visible}
      maskClosable={false}
      centered
      title={title}
      footer={null}
      width={300}
      bodyStyle={{display: 'flex', justifyContent: 'center'}}
      onCancel={() => ref.current.onHidden()}
    >
      <Space direction={'vertical'}>
        <Text>请选择Excel表格(.xlsx, .xls格式)</Text>
        <Upload
          customRequest={options =>
            uploadExcelData({
              info: options,
              uri,
              onLoading: loading => setLoading(loading),
              onSuccess: value => {
                ref.current.onHidden()
                onSuccess?.(value)
              },
            })
          }
          multiple={false}
          showUploadList={false}
        >
          <Button type={'primary'} loading={loading}>
            <UploadOutlined /> {title}
          </Button>
        </Upload>
      </Space>
    </Modal>
  )
}

export default React.forwardRef(UploadExcel)
