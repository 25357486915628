import React, {useCallback, useEffect, useRef, useState} from 'react'
import {
  Button,
  Cascader,
  Col,
  DatePicker,
  Form,
  Input,
  message,
  Modal,
  Row,
  Select,
  Space,
  Table,
  Typography,
} from 'antd'
import {stewardModel} from '@/store/models/Steward'
import {commonModel, useCodeSync} from '@/store/models/Common'
import {Breadcrumb} from '@/components/Breadcrumb'
import commonStyles from '@/assets/styles/common.module.less'
import {Permission} from '@/components/Permission'
import {Page} from '@/components/Page'
import moment from 'moment/moment'
import {FORMAT_DATE_END, FORMAT_DATE_START} from '@/common/date'
import {getPaginationProps} from '@/common/biz/getPaginationProps'
import {sysModel} from '@/store/models/Sys'

export default function StewardList() {
  const [form] = Form.useForm()
  const [visible, setVisible] = useState(false)

  const [list, setList] = useState<StewardPropertyManageQueryVO[]>([])
  const [total, setTotal] = useState(0)
  const rentType = useCodeSync('rentType')
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const loading = stewardModel.stewardAdminPropertyManageList.useLoading()
  const [allotForm] = Form.useForm()
  const [auto, setAuto] = useState(false)

  const [stewardList, setStewardList] = useState<StewardUserVO[]>([])
  const timer = useRef<any>()

  useEffect(() => {
    return () => clearTimeout(timer.current)
  }, [])

  const queryList = useCallback(args => {
    stewardModel.stewardUserList({pageable: false, ...args}).then(({response: {data}}) => {
      setStewardList(data)
    })
  }, [])

  useEffect(() => {
    queryList({})
  }, [queryList])

  const search = useCallback(() => {
    const {time = [], ...formValues} = form.getFieldsValue(true)
    const [insertTimeBegin, insertTimeEnd] = time
    stewardModel
      .stewardAdminPropertyManageList({...formValues, insertTimeBegin, insertTimeEnd})
      .then(({response: {data, total}}) => {
        setList(data)
        setTotal(total)
      })
  }, [form])

  useEffect(() => {
    search()
  }, [search])

  const [options, setOptions] = useState([])
  useEffect(() => {
    // 初始查询城市列表
    commonModel.queryCityCode({type: '2', parentCode: null}).then(({response: {data}}) => {
      setOptions(data.map(value => ({...value, isLeaf: false})))
    })
  }, [])

  return (
    <Page
      breadcrumb={
        <Breadcrumb
          right={
            <Permission code={'005018002'}>
              <Space>
                <Button
                  loading={stewardModel.stewardAdminPropertyManageAllotAuto.useLoading()}
                  disabled={!selectedRowKeys.length}
                  type={'primary'}
                  onClick={() => {
                    setAuto(true)
                    setVisible(true)
                  }}
                >
                  自动分配
                </Button>
                <Button
                  loading={stewardModel.stewardAdminPropertyManageAllot.useLoading()}
                  disabled={!selectedRowKeys.length}
                  type={'primary'}
                  onClick={() => {
                    setVisible(true)
                    setAuto(false)
                  }}
                >
                  配置管家
                </Button>
              </Space>
            </Permission>
          }
        />
      }
    >
      <Form
        form={form}
        initialValues={{
          pageNum: 1,
          pageSize: 10,
          pageable: true,
          cityCode: null,
          districtCode: null,
          time: [moment().subtract(3, 'month').format(FORMAT_DATE_START), moment().format(FORMAT_DATE_END)],
        }}
        onReset={search}
        onFinish={search}
      >
        <div className={commonStyles.filterSection}>
          <Row>
            <Col span={7}>
              <Form.Item name={'title'} label={'房源地址'}>
                <Input placeholder={'请输入房屋标题/小区/公寓名'} allowClear />
              </Form.Item>
            </Col>
            <Col span={7} offset={1}>
              <Form.Item name={'stewardName'} label={'管家姓名'}>
                <Input placeholder={'请输入管家姓名'} allowClear />
              </Form.Item>
            </Col>
            <Col span={7} offset={1}>
              <Form.Item name={'lessorName'} label={'房东姓名'}>
                <Input placeholder={'请输入房东姓名'} allowClear />
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item name={'propertyCode'} label={'房源ID'}>
                <Input placeholder='请输入房源ID' allowClear />
              </Form.Item>
            </Col>
            <Col span={7} offset={1}>
              <Form.Item
                name={'allocation'}
                getValueFromEvent={e => (e === '1' ? true : e === '0' ? false : undefined)}
                getValueProps={value => ({
                  value: value ? '1' : value === false ? '0' : undefined,
                })}
                label={'分配状态'}
              >
                <Select placeholder={'请选择'} allowClear>
                  <Select.Option value={'0'}>未分配</Select.Option>
                  <Select.Option value={'1'}>已分配</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={7} offset={1}>
              <Form.Item label={'区域'} name={'_cityCode'}>
                <Cascader
                  allowClear
                  fieldNames={{label: 'name', value: 'code', children: 'children'}}
                  options={options}
                  loadData={async selectedOptions => {
                    const last = selectedOptions[selectedOptions?.length - 1]
                    last.loading = true
                    const {
                      response: {data},
                      // 后端非树形结构数据，只能写死 3
                    } = await commonModel.queryCityCode({type: '3', parentCode: last.code})
                    last.loading = false
                    last.children = data.map(value => ({...value, isLeaf: true})) as any[]
                    setOptions(prevState => [...prevState])
                  }}
                  onChange={(_, selectedOptions) => {
                    form.setFieldsValue({
                      cityCode: selectedOptions?.[0]?.code,
                      districtCode: selectedOptions?.[1]?.code,
                    })
                  }}
                  placeholder={'请选择'}
                  showSearch={{
                    filter: (inputValue: string, path) => {
                      return path.some(option => (option.name as string).indexOf(inputValue) > -1)
                    },
                  }}
                  changeOnSelect
                />
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item
                label={'添加时间'}
                name={'time'}
                getValueProps={value => ({value: value ? value.map(item => moment(item)) : []})}
                getValueFromEvent={(_, dateStrings) => {
                  const [insertTimeBegin, insertTimeEnd] = dateStrings
                  if (insertTimeBegin && insertTimeEnd) {
                    return [
                      moment(insertTimeBegin).format(FORMAT_DATE_START),
                      moment(insertTimeEnd).format(FORMAT_DATE_END),
                    ]
                  }
                  return undefined
                }}
              >
                <DatePicker.RangePicker allowClear />
              </Form.Item>
            </Col>
            <Col span={7} offset={1}>
              <Form.Item name={'buildingNo'} label={'楼栋'}>
                <Input placeholder={'请输入'} />
              </Form.Item>
            </Col>
          </Row>
          <Row justify={'end'}>
            <Space>
              <Button type={'primary'} htmlType={'submit'} onClick={() => form.setFieldsValue({pageNum: 1})}>
                查询
              </Button>
              <Button htmlType={'reset'}>重置</Button>
            </Space>
          </Row>
        </div>

        <div className={commonStyles.tableSection}>
          <Table
            rowKey={'propertyCode'}
            loading={loading}
            rowSelection={{
              type: 'checkbox',
              selectedRowKeys: selectedRowKeys,
              onChange(selectedRowKeys, selectedRows) {
                setSelectedRowKeys(selectedRowKeys)
              },
            }}
            title={() => (
              <span>
                <span style={{fontWeight: 'bold'}}>
                  查询结果
                  <span style={{fontWeight: 'normal', marginLeft: 10}}>
                    共计<span style={{color: '#00B7AE'}}>{total}</span>条符合条件的结果
                  </span>
                </span>
              </span>
            )}
            dataSource={list}
            columns={[
              {title: '房源ID', dataIndex: 'propertyCode'},
              {title: '添加时间', dataIndex: 'insertTime'},
              {title: '房东名称', dataIndex: 'lessorName', render: val => (val ? <span>{val}</span> : '-')},
              {title: '房东手机号', dataIndex: 'lessorMobile'},
              {title: '类别', dataIndex: 'rentType', render: val => rentType[val]},
              {title: '城市', dataIndex: 'cityName'},
              {title: '区域', dataIndex: 'districtName'},
              {title: '楼栋', dataIndex: 'buildingNo', render: val => val ?? '/'},
              {title: '层数', dataIndex: 'floor', render: val => val ?? '/'},
              {title: '门牌号', dataIndex: 'title', key: 'title'},
              {title: '管家', dataIndex: 'stewardName', render: val => (val ? <span>{val}</span> : '-')},
              {title: '所属团队', dataIndex: 'teamName'},
              {
                title: '状态',
                dataIndex: 'status',
                render: (val, record) => {
                  return (
                    <Space>
                      {/*<div style={{width: 8, height: 8, borderRadius: 4, backgroundColor: '#409EFF'}}></div>*/}
                      <Typography.Text>{record.rentStatus}</Typography.Text>
                      <Typography.Text>{`(${record.contractStatus})`}</Typography.Text>
                    </Space>
                  )
                },
              },
            ]}
            scroll={{x: 'max-content'}}
            pagination={getPaginationProps(form, total, {pageSizeOptions: [10, 20, 50, 100, 1000]})}
          />
        </div>
      </Form>
      <Modal
        title='管家配置'
        visible={visible}
        onOk={async () => {
          await allotForm.validateFields()
          const {stewardId, reason} = allotForm.getFieldsValue(true)
          if (auto) {
            await stewardModel.stewardAdminPropertyManageAllotAuto({
              propertyCodeList: selectedRowKeys,
              reason,
            } as StewardPropertyManageAllotDTO)
          } else {
            await stewardModel.stewardAdminPropertyManageAllot({
              propertyCodeList: selectedRowKeys,
              stewardId,
              reason,
            })
          }

          message.success('分配成功！')
          setVisible(false)
          setSelectedRowKeys([])
          search()
        }}
        onCancel={() => setVisible(false)}
        afterClose={() => {
          allotForm.resetFields()
        }}
        centered
      >
        <Form form={allotForm} labelCol={{span: 4}}>
          {!auto && (
            <>
              <Form.Item>
                <Space direction={'vertical'}>
                  <Typography.Text>
                    已经选中
                    <Typography.Text type={'warning'}>{selectedRowKeys.length}</Typography.Text>
                    条房源
                  </Typography.Text>
                  <Typography.Text>配置好管家后，未联系的订单会转到新管家接单</Typography.Text>
                </Space>
              </Form.Item>
              <Form.Item label={'选择管家'} name={'stewardId'}>
                <Select
                  showSearch
                  onSearch={(value: string) => {
                    if (timer.current) {
                      clearTimeout(timer.current)
                      timer.current = null
                    }
                    timer.current = setTimeout(() => {
                      queryList({userName: value ?? ''})
                    }, 300)
                  }}
                  onChange={async (_, option) => {
                    if (!Array.isArray(option)) {
                      if (option && option.value) {
                        const {
                          response: {data},
                        } = await sysModel.sysOrgMemberOrgPath({userId: option.value})
                        allotForm.setFieldsValue({_orgPath: data})
                      } else {
                        allotForm.setFieldsValue({_orgPath: ''})
                      }
                    }
                  }}
                  style={{width: 200}}
                  filterOption={false}
                  allowClear
                >
                  {stewardList.map(item => (
                    <Select.Option key={item.userId} value={item.userId}>
                      {`${item.name}（${item.mobile}）`}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item noStyle shouldUpdate>
                {form1 => {
                  const _orgPath = form1.getFieldValue('_orgPath')
                  return (
                    !!_orgPath && (
                      <Form.Item label={' '} colon={false} style={{margin: '-24px 0 0'}}>
                        <span style={{color: '#f00'}}>{_orgPath}</span>
                      </Form.Item>
                    )
                  )
                }}
              </Form.Item>
            </>
          )}
          <Form.Item name={'reason'} label={'分配原因'} rules={[{required: true, message: '请输入分配原因'}]}>
            <Input.TextArea rows={3} placeholder={'请输入'} maxLength={200} showCount />
          </Form.Item>
        </Form>
      </Modal>
    </Page>
  )
}
