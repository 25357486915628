import React, {useState, useEffect, useCallback} from 'react'
import {Badge, Button} from 'antd'
import {BellOutlined} from '@ant-design/icons'
import {msgModel} from '@/store/models/Msg'
import {useListener} from '@/common/hooks/listener'
import {useHistory} from 'react-router-dom'

export default function MessageBadge() {
  const history = useHistory()

  const [unread, setUnread] = useState(0)

  const querySysMsgListMsg = useCallback(() => {
    msgModel
      .commonAppSysMsgListMsg({pageable: false, pageNum: 1, pageSize: 1, read: 0, counter: true})
      .then(({response: {total}}) => setUnread(total))
  }, [])

  useEffect(() => {
    querySysMsgListMsg()
  }, [querySysMsgListMsg])

  useListener('MessageList:SetCategoryRead', () => {
    querySysMsgListMsg()
  })

  return (
    <Badge count={unread}>
      <Button icon={<BellOutlined />} type={'link'} onClick={() => history.push('/message')} />
    </Badge>
  )
}
