import React, {useCallback, useEffect, useState} from 'react'
import {
  Button,
  Col,
  DatePicker,
  Form,
  FormInstance,
  Input,
  InputNumber,
  message,
  Modal,
  Radio,
  Row,
  Select,
  Space,
  Switch,
  Typography,
} from 'antd'
import {sysUserModel} from '@/store/models/SysUser'
import {commonModel, useCodeArraySync} from '@/store/models/Common'
import {lessorModel} from '@/store/models/Lessor'
import moment from 'moment'
import {customerModel} from '@/store/models/Customer'
import {useAppHistory} from '@/common/hooks/useRouter'
import {ExclamationCircleFilled} from '@ant-design/icons'
import {Album, UploadButton} from '@/components/Album'
import {getImg} from '@/utils/util'
import {baseUrl} from '@/common/config'
import {Permission, usePermission} from '@/components/Permission'

const {Option} = Select

interface ClientAddProps {
  userId: string
  isEdit?: boolean
  form: FormInstance
}

export default function ClientAdd(props: ClientAddProps) {
  const history = useAppHistory()
  const userId = props.userId
  const isEdit = props.isEdit

  const [auth] = usePermission()

  //付费方式
  const chargePayWayArr = useCodeArraySync('steward.orderAuditChargeWay')

  const form = props.form

  const [customer, setCustomer] = useState({} as Pick<LessorCustomerListVO, 'userId' | 'propertyPromote'>)
  const [attach, setAttach] = useState({visible: false, imgUrl: '', userId: ''})

  // 管家列表
  const [stewardList, setStewardList] = useState<SysUserSimpleVO[]>([])
  useEffect(function () {
    // 管家列表
    sysUserModel.sysUserQuerySteward({}).then(({response: {data}}) => {
      setStewardList(data)
    })
  }, [])

  // 地址相关
  const [province, setProvince] = useState<CityDataProps[]>()
  const [city, setCity] = useState<CityDataProps[]>()
  const [district, setDistrict] = useState<CityDataProps[]>()
  const [bizCircle, setBizCircle] = useState<CityDataProps[]>()

  // 查地址
  const queryCityCode = useCallback(function (params: {type: '1' | '2' | '3' | '4'; parentCode: string}) {
    commonModel.queryCityCode(params).then(({response: {data}}) => {
      switch (params.type) {
        case '1':
          setProvince(data)
          break
        case '2':
          setCity(data)
          break
        case '3':
          setDistrict(data)
          break
        case '4':
          setBizCircle(data)
          break
      }
    })
  }, [])

  useEffect(
    function () {
      queryCityCode({type: '1', parentCode: ''})
    },
    [queryCityCode]
  )

  //  BD列表
  const [managerList, setManagerList] = useState<SysUserVO[]>([])
  // 查BD列表
  useEffect(function () {
    sysUserModel.queryCusManager().then(({response: {data}}) => {
      setManagerList(data)
    })
  }, [])

  const [initValues, setInitValues] = useState(null)

  // 查客户详情
  const queryCustomerInfo = useCallback(
    function (userId: string) {
      customerModel.customerInfo({userId}).then(({response: {data}}) => {
        const values = {
          ...data,
          stewardIdList: data.customerStewardInfoList?.map(item => item.stewardId),
          // contractTime: [data.contractBegin, data.contractEnd].filter(item => item).map(item => moment(item)),
          stewardWeighExpiration: data.stewardWeighExpiration ? moment(data.stewardWeighExpiration) : undefined,
          // firstPayDate: data.firstPayDate ? moment(data.firstPayDate) : data.firstPayDate,
        }
        setInitValues(values)
        form.setFieldsValue(values)

        // setMaterialType(data.materialSource)

        if (data.manageId && data.manageName) {
          setManagerList(prevState =>
            prevState.some(item => item.userId === data.manageId)
              ? prevState
              : [...prevState, {userId: data.manageId, name: data.manageName} as SysUserVO]
          )
        }

        queryCityCode({type: '2', parentCode: ''})
        queryCityCode({type: '3', parentCode: data.cityCode})
        queryCityCode({type: '4', parentCode: data.districtCode})

        // setIsDisable(true)
      })
    },
    [queryCityCode, form]
  )

  useEffect(() => {
    if (userId) {
      queryCustomerInfo(userId)
    }
  }, [queryCustomerInfo, userId])

  useEffect(() => {
    if (userId) {
      commonModel.commonAppCustomerActivityRemarkGet({userId}).then(({response: {data}}) => {
        form.setFieldsValue({activityRemark: data})
      })
    }
  }, [form, userId])

  // useEffect(
  //   function () {
  //     if (userId) {
  //       // setIsDisable(true)
  //       queryCustomerInfo(userId)
  //     } else {
  //       form.setFieldsValue({chargeType: '0'})
  //     }
  //   },
  //   [queryCustomerInfo, form, userId]
  // )

  // useEffect(() => {
  //   if (userId) {
  //     saleModel
  //       .saleCustomerContractList({customerId: userId, contractStatus: '1', pageable: false})
  //       .then(({response: {data}}) => setContractList(data))
  //   }
  // }, [userId])

  // 校验规则
  // function certNoValid(rule: any, value: any, callback: Function) {
  //   const certNoReg = /^[1-9]\d{5}(18|19|20|(3\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/
  //   if (!value) return callback('证件号不能为空')
  //   if (!certNoReg.test(value)) return callback('证件号格式不正确')
  //   callback()
  // }

  // function mobileValid(rule: any, value: any, callback: Function) {
  //   const mobileReg = /^1[0-9]{10}$/
  //   if (!value) return callback('手机号不能为空')
  //   if (!mobileReg.test(value)) return callback('手机号格式不正确')
  //   callback()
  // }

  // loading
  // const upsertLoading = customerModel.customerUpsert.useLoading()
  return (
    <div style={{position: 'relative'}}>
      <div style={{backgroundColor: '#fff', padding: '20px 0'}}>
        <Form
          form={form}
          labelAlign={'left'}
          labelCol={{flex: '78px'}}
          initialValues={initValues}
          onFinish={async () => {
            const values = form.getFieldsValue(true)
            let stewardWeigh, stewardWeighExpiration
            values.stewardWeigh ? (stewardWeigh = 100) : (stewardWeigh = 0)
            if (values.stewardWeighExpiration) {
              stewardWeighExpiration = moment(values.stewardWeighExpiration).format('YYYY-MM-DD HH:mm:ss')
            }
            const params = {...values, stewardWeigh, stewardWeighExpiration} as LessorInsertDTO
            const {emdEnable, emdLimitNum, emdAmt} = values
            await customerModel.customerUpsert(params)
            // const {
            //   response: {data},
            // } = await customerModel.customerUpsert(params)
            // if (isSet) {
            //   await stewardModel.stewardAdminServiceCustomSave({
            //     customerId: data,
            //     serviceList: serviceList.map(item => ({...item, feeList: item.serviceFeeList})),
            //   })
            //   await stewardModel.stewardAdminServiceChargeCustomSave(customChargeList)
            // }
            // 添加/修改客户活动备注
            if (values.activityRemark) {
              await customerModel.customerUpsertActivityRemark({userId, activityRemark: values.activityRemark})
            }
            await customerModel.customerUpdateEmd({emdEnable, emdLimitNum, emdAmt, userId})
            message.success('操作成功！')
            if (userId) {
              history.goBack()
            } else {
              history.push('/client')
            }
          }}
        >
          <Row
            gutter={49}
            wrap={false}
            //
            style={{margin: 0}}
          >
            <Col flex={'1 1 0%'}>
              <Mask active={!isEdit}>
                <Typography.Title level={4} style={{marginBottom: 37}}>
                  客户信息
                </Typography.Title>
                <Form.Item name={'certName'} label={'客户姓名'} rules={[{required: true, message: '请输入客户姓名'}]}>
                  <Input disabled placeholder={'请输入客户姓名'} />
                </Form.Item>
                <Form.Item name={'gender'} label={'性别'} rules={[{required: true, message: '请选择性别'}]}>
                  <Select placeholder={'请选择性别'}>
                    <Option value={'1'}>男</Option>
                    <Option value={'2'}>女</Option>
                  </Select>
                </Form.Item>
                <Form.Item name={'manageId'} label={'BD姓名'} rules={[{required: true, message: '请输入BD'}]}>
                  <Select placeholder={'请选择BD'}>
                    {managerList?.map(item => (
                      <Option key={item.userId} value={item.userId}>
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item label={'地址'} style={{marginBottom: 0, flexWrap: 'nowrap'}} required={true}>
                  <Row gutter={10} wrap={false}>
                    <Col flex={1}>
                      <Form.Item name={'provinceCode'} rules={[{required: true, message: '请选择省'}]}>
                        <Select
                          placeholder={'请选择省'}
                          onSelect={(value: string, option: any) => {
                            queryCityCode({type: '2', parentCode: value})
                            form.setFieldsValue({
                              province: option.children,
                              city: undefined,
                              cityCode: undefined,
                              district: undefined,
                              districtCode: undefined,
                              bizCircle: undefined,
                              bizCircleCode: undefined,
                            })
                          }}
                        >
                          {province?.map(item => (
                            <Option key={item.code} value={item.code}>
                              {item.name}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col flex={1}>
                      <Form.Item name={'cityCode'} rules={[{required: true, message: '请选择市'}]}>
                        <Select
                          placeholder={'请选择市'}
                          onSelect={(value: string, option: any) => {
                            queryCityCode({type: '3', parentCode: value})
                            form.setFieldsValue({
                              city: option.children,
                              district: undefined,
                              districtCode: undefined,
                              bizCircle: undefined,
                              bizCircleCode: undefined,
                            })
                          }}
                        >
                          {city?.map(item => (
                            <Option key={item.code} value={item.code}>
                              {item.name}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col flex={1}>
                      <Form.Item name={'districtCode'} rules={[{required: true, message: '请选择区'}]}>
                        <Select
                          placeholder={'请选择区'}
                          onSelect={(value: string, option: any) => {
                            queryCityCode({type: '4', parentCode: value})
                            form.setFieldsValue({
                              district: option.children,
                              bizCircle: undefined,
                              bizCircleCode: undefined,
                            })
                          }}
                        >
                          {district?.map(item => (
                            <Option key={item.code} value={item.code}>
                              {item.name}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col flex={1}>
                      <Form.Item name={'bizCircleCode'} rules={[{required: true, message: '请选择商圈'}]}>
                        <Select
                          placeholder={'请选择商圈'}
                          onSelect={(value: string, option: any) => form.setFieldsValue({bizCircle: option.children})}
                        >
                          {bizCircle?.map(item => (
                            <Option key={item.code} value={item.code}>
                              {item.name}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form.Item>
                <Form.Item name={'remark'} label={'备注信息'}>
                  <Input.TextArea rows={5} placeholder={'请输入备注信息，最少10个字符'} />
                </Form.Item>
                {auth('002178001') && (
                  <Form.Item name={'activityRemark'} label={'活动充值'}>
                    <Input.TextArea rows={5} placeholder={'请输入'} maxLength={500} showCount />
                  </Form.Item>
                )}
              </Mask>
            </Col>
            <Col flex={'1 1 0%'}>
              <Typography.Title level={4} style={{marginBottom: 37}}>
                条件配置
              </Typography.Title>
              <Typography.Title level={5} style={{marginBottom: 20}}>
                账号相关
              </Typography.Title>
              <Form.Item label={'账号状态'} shouldUpdate>
                {f => (
                  <Switch
                    checked={f.getFieldValue('status') === '1'}
                    onClick={value => {
                      const {userId, certName, mobile, status, propertyPromote} = form.getFieldsValue(true)
                      Modal.confirm({
                        title: '提示',
                        icon: <ExclamationCircleFilled />,
                        content: (
                          <>
                            请确认已经核实过房东用户<span style={{color: '#FAAD14'}}>{certName}</span>手机号
                            <span style={{color: '#FAAD14'}}>{mobile}</span>的相关信息，并符合
                            {status === '1' ? '禁用' : '启用'}标准
                          </>
                        ),
                        onOk: async () => {
                          if (propertyPromote === '1') {
                            await customerModel.customerPropertyPromoteUpdate({userId, propertyPromote: '0'})
                          }
                          await lessorModel.lessorChangeStatus(value ? '1' : '0', userId)
                          queryCustomerInfo(userId)
                        },
                      })
                    }}
                  />
                )}
              </Form.Item>
              <Form.Item label='推广房源' shouldUpdate>
                {f => (
                  <Switch
                    checked={f.getFieldValue('propertyPromote') === '1'}
                    onClick={() => setCustomer(form.getFieldsValue(true))}
                  />
                )}
              </Form.Item>
              <Permission code={'003082001'}>
                <Form.Item label='增值服务' shouldUpdate>
                  {f => (
                    <Switch
                      checked={f.getFieldValue('lesseeMember') === '1'}
                      onClick={checked => {
                        Modal.confirm({
                          icon: null,
                          title: '提示',
                          content: checked ? '确认打开？' : '确认关闭？',
                          onOk: async () => {
                            await customerModel.customerLesseeMemberChange({
                              userId,
                              lesseeMember: checked ? '1' : '0',
                            })
                            queryCustomerInfo(userId)
                          },
                        })
                      }}
                    />
                  )}
                </Form.Item>
              </Permission>
              <Permission code={'006158001'}>
                <Form.Item label='可贴海报' shouldUpdate>
                  {f => (
                    <Switch
                      checked={f.getFieldValue('enablePosters') === 1}
                      onClick={checked => {
                        Modal.confirm({
                          icon: null,
                          title: '提示',
                          content: checked ? '是否确认启用？' : '是否确认禁用？',
                          onOk: async () => {
                            await customerModel.customerPostersEnable({userId, enablePosters: checked ? 1 : 0})
                            queryCustomerInfo(userId)
                          },
                        })
                      }}
                    />
                  )}
                </Form.Item>
              </Permission>
              <Permission code={'006172001'}>
                <Form.Item label='服务核验' shouldUpdate>
                  {f => (
                    <Switch
                      checked={f.getFieldValue('serviceVerifyTag') === 1}
                      onClick={checked => {
                        Modal.confirm({
                          icon: null,
                          title: '提示',
                          content: checked ? '是否确认启用？' : '是否确认禁用？',
                          onOk: async () => {
                            await customerModel.customerUpdateServiceVerifyTag({
                              userId,
                              serviceVerifyTag: checked ? 1 : 0,
                            })
                            queryCustomerInfo(userId)
                          },
                        })
                      }}
                    />
                  )}
                </Form.Item>
              </Permission>
              <Permission code={'006177018'}>
                <Form.Item label='服务免联' shouldUpdate>
                  {f => (
                    <Switch
                      checked={f.getFieldValue('enableNotRelation') === 1}
                      onClick={checked => {
                        Modal.confirm({
                          icon: null,
                          title: '提示',
                          content: checked ? '确认启用？' : '确认禁用？',
                          onOk: async () => {
                            await customerModel.customerUpdateNotRelation({
                              userId,
                              enableNotRelation: checked ? 1 : 0,
                            })
                            queryCustomerInfo(userId)
                          },
                        })
                      }}
                    />
                  )}
                </Form.Item>
              </Permission>
              <Permission code={'006081001'}>
                <Form.Item label='公寓图片'>
                  <Button
                    style={{paddingLeft: 0}}
                    type={'link'}
                    onClick={() => setAttach({visible: true, imgUrl: form.getFieldValue('condoBannerUrl'), userId})}
                  >
                    附件
                  </Button>
                </Form.Item>
              </Permission>
              <Mask active={!isEdit}>
                <Form.Item name={'onlineRate'} label={'流水费率'} rules={[{required: true, message: '请输入流水费率'}]}>
                  <InputNumber
                    min={0 as number}
                    max={100 as number}
                    defaultValue={100 as number}
                    formatter={value => `${value}%`}
                    parser={(value: string) => (value ? +value.replace('%', '') : value)}
                    style={{width: '100%'}}
                  />
                </Form.Item>
              </Mask>

              <Typography.Title level={5} style={{marginBottom: 20, marginTop: 29}}>
                服务相关
              </Typography.Title>
              <Mask active={!isEdit}>
                <Permission code={'002179001'}>
                  <Form.Item noStyle shouldUpdate={true}>
                    {f => {
                      const emdEnable = f.getFieldValue('emdEnable')
                      return (
                        <Space direction={'vertical'}>
                          <Space>
                            <Form.Item
                              name={'emdEnable'}
                              getValueProps={value => ({checked: value === 1})}
                              getValueFromEvent={value => (value ? 1 : 0)}
                              label={'维修超量扣款配置'}
                              labelCol={{flex: '120px'}}
                            >
                              <Switch />
                            </Form.Item>
                            <Form.Item>
                              <span>下单月（维修）下的单里完成（不考虑完成时间）</span>
                            </Form.Item>
                          </Space>
                          <Space>
                            <Form.Item>
                              <span>超过</span>
                            </Form.Item>
                            <Form.Item
                              name={'emdLimitNum'}
                              rules={[
                                {
                                  validator: (_, value, callback) => {
                                    if (emdEnable === 1) {
                                      if (value < 1) {
                                        return callback('该值最小值为1！')
                                      }
                                    }
                                    return callback()
                                  },
                                },
                              ]}
                            >
                              <InputNumber />
                            </Form.Item>
                            <Form.Item>
                              <span>单后完成的每单扣款</span>
                            </Form.Item>
                            <Form.Item
                              name={'emdAmt'}
                              rules={[
                                {
                                  validator: (_, value, callback) => {
                                    if (emdEnable === 1) {
                                      if (value < 1) {
                                        return callback('该值最小值为1！')
                                      }
                                    }
                                    return callback()
                                  },
                                },
                              ]}
                            >
                              <InputNumber />
                            </Form.Item>
                            <Form.Item>
                              <span>元</span>
                            </Form.Item>
                          </Space>
                        </Space>
                      )
                    }}
                  </Form.Item>
                </Permission>
                <Form.Item label={'管家分配'} name={'stewardDistribution'} labelCol={{flex: '112px'}}>
                  <Radio.Group>
                    <Radio value={'0'}>手动</Radio>
                    <Radio value={'1'}>自动</Radio>
                  </Radio.Group>
                </Form.Item>
                <Form.Item
                  noStyle
                  shouldUpdate={(prevValues, nextValues) =>
                    prevValues.stewardDistribution !== nextValues.stewardDistribution
                  }
                >
                  {f =>
                    f.getFieldValue('stewardDistribution') === '0' && (
                      <Form.Item
                        label={'优先分配管家'}
                        labelCol={{flex: '112px'}}
                        name={'stewardIdList'}
                        rules={[{required: true}]}
                      >
                        <Select
                          placeholder={'请选择管家(多选)'}
                          allowClear
                          showArrow
                          mode='multiple'
                          filterOption={(inputValue, option) => {
                            return option?.children?.indexOf(inputValue as any) >= 0
                          }}
                        >
                          {stewardList?.map(item => (
                            <Option key={item.userId} value={item.userId}>
                              {`${item.name}（${item.mobile}）`}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                    )
                  }
                </Form.Item>
                {userId && (
                  <>
                    <Form.Item
                      label={'服务单优先展示'}
                      name={'stewardWeigh'}
                      labelCol={{flex: '112px'}}
                      valuePropName={'checked'}
                      getValueProps={value => ({checked: value === 100})}
                      getValueFromEvent={event => (event ? 100 : 0)}
                    >
                      <Switch />
                    </Form.Item>
                    <Form.Item
                      noStyle
                      shouldUpdate={(prevValues, nextValues) => prevValues.stewardWeigh !== nextValues.stewardWeigh}
                    >
                      {f =>
                        !!f.getFieldValue('stewardWeigh') && (
                          <Form.Item
                            name={'stewardWeighExpiration'}
                            label={'截止日期'}
                            labelCol={{flex: '112px'}}
                            rules={[{required: true}]}
                          >
                            <DatePicker format={'YYYY-MM-DD HH:mm:ss'} style={{width: '100%'}} placeholder='选择日期' />
                          </Form.Item>
                        )
                      }
                    </Form.Item>
                  </>
                )}
                <Form.Item label={'优先付款方式'} name={'chargePayWay'} labelCol={{flex: '112px'}}>
                  <Select placeholder={'请选择'} allowClear>
                    {chargePayWayArr.map(item => (
                      <Option key={item.id} value={item.id}>
                        {item.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Mask>
            </Col>
          </Row>
          {/* {!!isEdit && (
            <div style={{display: 'flex', justifyContent: 'center'}}>
              <Button type={'primary'} htmlType={'submit'} loading={upsertLoading}>
                提交
              </Button>
            </div>
          )}*/}
        </Form>
      </div>

      {/* <SetServiceType
        ref={setRef}
        onSuccess={({feeList, customChargeList: chargeList}) => {
          setIsSet(true)
          setServiceList(feeList)
          setCustomChargeList(chargeList)
        }}
        customerId={userId}
      /> */}

      <Modal
        visible={attach.visible}
        title={'添加附件'}
        centered
        onCancel={() => setAttach({visible: false, imgUrl: null, userId: null})}
        onOk={async () => {
          await customerModel.customerCondoBannerUpdate({userId: attach.userId, imgUrl: attach.imgUrl})
          setAttach({visible: false, imgUrl: null, userId: null})
          message.success('操作成功！')
          queryCustomerInfo(userId)
        }}
      >
        <Space align={'start'}>
          <span>图片：</span>
          <Album
            limit={1}
            fileList={attach.imgUrl ? [{url: getImg(attach.imgUrl), uid: attach.imgUrl}] : []}
            action={baseUrl + '/customer/condo-banner/upload'}
            accept={'.jpg, .jpeg, .png'}
            onChange={info => {
              if (info.file.status === 'done') {
                const {
                  response: {data},
                } = info.file
                setAttach(prevState => ({...prevState, imgUrl: data.imgUrl}))
              }
              if (info.file.status === 'removed') {
                setAttach(prevState => ({...prevState, imgUrl: ''}))
              }
            }}
          >
            {attach.imgUrl ? null : <UploadButton />}
          </Album>
        </Space>
      </Modal>

      <Modal
        visible={!!customer.userId}
        title={'提示'}
        onCancel={() => setCustomer({} as LessorCustomerListVO)}
        footer={null}
      >
        {customer.propertyPromote === '1' ? '是否确认设置当前客户房源不可推广？' : '是否确认设置当前客户房源可推广？ '}
        <Row justify={'end'} style={{marginTop: '30px'}}>
          <Space>
            <Button onClick={() => setCustomer({} as LessorCustomerListVO)}>取消</Button>
            <Button
              type={'primary'}
              onClick={async () => {
                await customerModel.customerPropertyPromoteUpdate({
                  userId: customer.userId,
                  propertyPromote: customer.propertyPromote === '1' ? '0' : '1',
                })
                setCustomer({} as LessorCustomerListVO)
                queryCustomerInfo(userId)
                message.success('操作成功！')
              }}
              loading={customerModel.customerPropertyPromoteUpdate.useLoading()}
            >
              确认
            </Button>
          </Space>
        </Row>
      </Modal>
    </div>
  )
}

const Mask: React.FC<{active: boolean}> = props => {
  return (
    <div style={{position: 'relative'}}>
      {!!props.active && <div style={{position: 'absolute', left: 0, right: 0, bottom: 0, top: 0, zIndex: 9}} />}
      {props.children}
    </div>
  )
}
