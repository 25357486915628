import React, {useCallback, useEffect, useState} from 'react'
import commonStyles from '@/assets/styles/common.module.less'
import {Breadcrumb} from '@/components/Breadcrumb'
import {Button, Checkbox, Form, message, Modal, Radio, Row, Select, Space, Table, Tabs} from 'antd'
import EngineeringAdd from '@/pages/project/EngineeringAdd'
import {projectModel} from '@/store/models/Project'
import {useCodeSync} from '@/store/models/Common'
import {Album, UploadButton} from '@/components/Album'
import {getImg} from '@/utils/util'
import {baseUrl} from '@/common/config'
import {Page} from '@/components/Page'
import {useAppHistory, useAppLocation} from '@/common/hooks/useRouter'
import {usePermission} from '@/components/Permission'
import BackButton from '@/components/BackButton'

const {Option} = Select
const {TabPane} = Tabs

const visibleCode = {
  add: '新增节点',
  edit: '编辑节点',
}

export default function EngineeringDetail() {
  const history = useAppHistory()
  const location = useAppLocation<'/engineering/detail'>()
  const {workCode, workStatus, operate, workName} = location.state

  const [auth] = usePermission()

  const projectWorkNodeStatusCode = useCodeSync('project.workNodeStatus')

  const [tabIndex, setTabIndex] = useState('1')

  const [mVisible, setMVisible] = useState<'add' | 'edit' | 'confirm'>(null)
  const [nodeForm] = Form.useForm<ProjectWorkNodeDTO>()

  const [delNode, setDelNode] = useState<{workNodeId: string; visible: boolean}>({workNodeId: null, visible: false})
  const [isAllFinish, setIsAllFinish] = useState(false)

  // 节点列表
  const [nodeList, setNodeList] = useState<ProjectNodeVO[]>([])
  const queryNodeList = useCallback((nodeTitle?: string) => {
    projectModel
      .projectAdminNodeList({pageable: false, nodeStatus: '1', nodeTitle} as ProjectAdminNodeListDTO)
      .then(({response: {data}}) => setNodeList(data))
  }, [])
  useEffect(() => {
    queryNodeList()
  }, [queryNodeList])

  // 项目节点列表
  const [workNodeList, setWorkNodeList] = useState<ProjectWorkNodeVO[]>([])
  const queryWorkNodeList = useCallback(() => {
    projectModel.projectAdminWorkNodeList({workCode}).then(({response: {data}}) => {
      setWorkNodeList(data)
      setIsAllFinish(data?.every(item => item.workNodeStatus === '5'))
    })
  }, [workCode])

  useEffect(() => {
    queryWorkNodeList()
  }, [queryWorkNodeList])

  // 人员列表
  const [operatorList, setOperatorList] = useState<SysUserVO[]>([])
  const queryWorkNodeOperatorList = useCallback(
    async (nodeCode: string) => {
      const {
        response: {data},
      } = await projectModel.projectAdminWorkNodeOperatorList({workCode, nodeCode})
      setOperatorList(data)
    },
    [workCode]
  )

  const upsertLoading = projectModel.projectAdminWorkNodeUpsert.useLoading()
  const nodeListLoading = projectModel.projectAdminNodeList.useLoading()
  const workNodeListLoading = projectModel.projectAdminWorkNodeList.useLoading()
  // const workNodeInfoLoading = projectModel.projectAdminWorkNodeInfo.useLoading()
  const nodeMoveLoading = projectModel.projectAdminWorkNodeMove.useLoading()
  const workStatusLoading = projectModel.projectAdminWorkStatus.useLoading()
  const deleteLoading = projectModel.projectAdminWorkNodeDelete.useLoading()

  const renderOperate = (args: Pick<ProjectWorkNodeVO, 'workNodeId' | 'workNodeStatus'>) => {
    const {workNodeId, workNodeStatus} = args
    return [
      {
        when: workNodeStatus === '1' && auth('002078002') && workStatus !== '5',
        title: '编辑',
        onClick: async () => {
          const {
            response: {data},
          } = await projectModel.projectAdminWorkNodeInfo({workNodeId})
          await queryWorkNodeOperatorList(data.nodeCode)
          nodeForm.setFieldsValue({
            attach: data.attach,
            auditList: data.auditList,
            auditNecessary: data.auditNecessary,
            infoTypeList: data.infoTypeList,
            nodeCode: data.nodeCode,
            nodeName: data.nodeName,
            operatorList: data.operatorList,
            workCode: data.workCode,
            workNodeId: data.workNodeId,
            // @ts-ignore
            _audit: data.auditList?.[0]?.userId,
            _operator: data.operatorList?.[0]?.userId,
          })
          setMVisible('edit')
        },
      },
      {
        when: auth('001078002'),
        title: '查看',
        onClick: () => history.push('/engineering/node-detail', {workNodeId, workName}),
      },
      {
        when: workNodeStatus === '1' && auth('004078002') && workStatus !== '5',
        title: '删除',
        onClick: () => setDelNode({workNodeId, visible: true}),
      },
    ].filter(item => item.when)
  }

  const onMove = async (args: {workNodeId: string; up: boolean}) => {
    await projectModel.projectAdminWorkNodeMove(args)
    queryWorkNodeList()
  }

  return (
    <Page
      breadcrumb={
        <Breadcrumb
          right={
            <>
              {tabIndex === '1' && isAllFinish && auth('018077002') && (
                <Button type={'primary'} onClick={() => setMVisible('confirm')}>
                  完成
                </Button>
              )}

              {tabIndex === '2' && ['1', '2', '3'].includes(workStatus) && auth('003078002') && (
                <Button type={'primary'} onClick={() => setMVisible('add')}>
                  新增项目节点
                </Button>
              )}
            </>
          }
        />
      }
    >
      <BackButton />
      <Tabs
        defaultValue={'1'}
        onChange={value => setTabIndex(value)}
        tabBarStyle={{backgroundColor: '#fff', paddingLeft: '20px'}}
      >
        <TabPane tab={'项目信息'} key={'1'}>
          <EngineeringAdd operate={operate} workCode={workCode} />
        </TabPane>
        <TabPane tab={'项目节点'} key={'2'}>
          <div className={commonStyles.tableSection}>
            <Table
              rowKey={'order'}
              loading={workNodeListLoading}
              columns={[
                {
                  title: '移动',
                  key: 'workNodeId',
                  dataIndex: 'workNodeId',
                  render: (values, _, index) => {
                    return (
                      <Space>
                        <Button
                          type={'link'}
                          style={{padding: 0}}
                          loading={nodeMoveLoading}
                          onClick={() => onMove({workNodeId: values, up: true})}
                          disabled={index === 0}
                        >
                          上移
                        </Button>
                        <Button
                          type={'link'}
                          style={{padding: 0}}
                          loading={nodeMoveLoading}
                          onClick={() => onMove({workNodeId: values, up: false})}
                          disabled={index === workNodeList.length - 1}
                        >
                          下移
                        </Button>
                      </Space>
                    )
                  },
                },
                {title: '序号', key: 'order', dataIndex: 'order'},
                {title: '节点名称', key: 'nodeName', dataIndex: 'nodeName'},
                {
                  title: '节点状态',
                  key: 'workNodeStatus',
                  dataIndex: 'workNodeStatus',
                  render: value => projectWorkNodeStatusCode[value],
                },
                {
                  title: '操作',
                  render: values => {
                    return (
                      <Space>
                        {renderOperate(values).map((item, index) => (
                          <Button key={index} type={'link'} style={{padding: 0}} onClick={item.onClick}>
                            {item.title}
                          </Button>
                        ))}
                      </Space>
                    )
                  },
                },
              ]}
              dataSource={workNodeList}
              pagination={false}
            />
          </div>
        </TabPane>
      </Tabs>

      {/** 新增工程节点、编辑工程节点 */}
      <Modal
        centered
        footer={null}
        title={visibleCode[mVisible]}
        visible={['add', 'edit'].includes(mVisible)}
        onCancel={() => setMVisible(null)}
        afterClose={() => nodeForm.resetFields()}
      >
        <Form
          form={nodeForm}
          labelCol={{flex: '80px'}}
          onFinish={async () => {
            const formValues = nodeForm.getFieldsValue(true)
            // console.log('formValues', formValues)
            await projectModel.projectAdminWorkNodeUpsert({...formValues})
            setMVisible(null)
            queryWorkNodeList()
          }}
          onReset={() => setMVisible(null)}
          initialValues={{workCode}}
        >
          <Form.Item name={'nodeCode'} label={'节点'} rules={[{required: true, message: '请选择节点'}]}>
            <Select
              showSearch
              filterOption={false}
              placeholder={'请选择节点'}
              onSearch={queryNodeList}
              loading={nodeListLoading}
              onSelect={async (value: string, option) => {
                await queryWorkNodeOperatorList(value)
                nodeForm.setFieldsValue({
                  nodeName: option.children as any,
                  auditList: [],
                  operatorList: [],
                  // @ts-ignore
                  _audit: null,
                  _operator: null,
                })
              }}
              disabled={mVisible === 'edit'}
            >
              {nodeList.map(item => (
                <Option key={item.nodeCode} value={item.nodeCode}>
                  {item.nodeTitle}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item noStyle shouldUpdate={(prevValues, nextValues) => prevValues.nodeCode !== nextValues.nodeCode}>
            {form1 => {
              return (
                <>
                  <Form.Item
                    name={'_operator'}
                    label={'提交人员'}
                    rules={[{required: true, message: '请选择提交人员'}]}
                  >
                    <Select
                      placeholder={'请选择提交人员'}
                      onSelect={(value, option) => {
                        form1.setFieldsValue({operatorList: [{name: option.children, userId: value}]})
                      }}
                      disabled={!form1.getFieldValue('nodeCode')}
                    >
                      {operatorList.map(item => (
                        <Option key={item.userId} value={item.userId}>
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item name={'_audit'} label={'审批人员'} rules={[{required: true, message: '请选择审批人员'}]}>
                    <Select
                      placeholder={'请选择审批人员'}
                      onSelect={(value, option) => {
                        form1.setFieldsValue({auditList: [{name: option.children, userId: value}]})
                      }}
                      disabled={!form1.getFieldValue('nodeCode')}
                    >
                      {operatorList.map(item => (
                        <Option key={item.userId} value={item.userId}>
                          {item.name}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </>
              )
            }}
          </Form.Item>
          <Form.Item name={'auditNecessary'} label={'审批要求'} rules={[{required: true, message: '请选择审批要求'}]}>
            <Radio.Group>
              <Radio value={'1'}>必须审批</Radio>
              <Radio value={'0'}>可跳过审批</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item label={'信息'}>请选择以下节点所需上传的信息</Form.Item>
          <Form.Item
            name={'infoTypeList'}
            label={' '}
            colon={false}
            rules={[{required: true, message: '请选择上传的信息类型'}]}
          >
            <Checkbox.Group>
              <Checkbox value={'image'}>图片</Checkbox>
              <Checkbox value={'video'}>视频</Checkbox>
              {/*<Checkbox value={'application'}>文件</Checkbox>*/}
              <Checkbox value={'text'}>文字描述</Checkbox>
            </Checkbox.Group>
          </Form.Item>
          {['2', '3'].includes(workStatus) && (
            <Form.Item label={'添加凭证'} shouldUpdate required>
              {from1 => {
                const {attach = '', _attach = []} = from1.getFieldsValue(['attach', '_attach'])
                return (
                  <Album
                    limit={1}
                    accept={'.jpg, .jpeg, .png'}
                    action={baseUrl + '/project/admin/file/upload'}
                    fileList={_attach.map(item => ({url: getImg(item.imgUrl), uid: item.imgId}))}
                    onChange={info => {
                      if (info.file.status === 'done') {
                        const {
                          response: {data},
                        } = info.file
                        from1.setFieldsValue({_attach: [{imgUrl: data.imgUrl, imgId: data.imgId}], attach: data.imgId})
                      }
                      if (info.file.status === 'removed') {
                        from1.setFieldsValue({_attach: [], attach: ''})
                      }
                    }}
                  >
                    {attach ? null : <UploadButton />}
                  </Album>
                )
              }}
            </Form.Item>
          )}

          <Row justify={'end'}>
            <Space>
              <Button htmlType={'reset'}>取消</Button>
              <Button type={'primary'} htmlType={'submit'} loading={upsertLoading}>
                保存
              </Button>
            </Space>
          </Row>
        </Form>
      </Modal>

      {/** 删除工程节点 */}
      <Modal
        centered
        footer={null}
        title={'提示'}
        visible={delNode.visible}
        onCancel={() => setDelNode({workNodeId: null, visible: false})}
      >
        <Row>
          <span>您确定要删除此节点？</span>
        </Row>
        <Row justify={'end'} style={{marginTop: '30px'}}>
          <Space>
            <Button onClick={() => setDelNode({workNodeId: null, visible: false})}>取消</Button>
            <Button
              type={'primary'}
              loading={deleteLoading}
              onClick={async () => {
                await projectModel.projectAdminWorkNodeDelete({workNodeId: delNode.workNodeId})
                setDelNode({workNodeId: delNode.workNodeId, visible: false})
                message.success('操作成功！')
                queryWorkNodeList()
              }}
            >
              确定
            </Button>
          </Space>
        </Row>
      </Modal>

      {/** 完成 */}
      <Modal centered footer={null} title={'提示'} visible={mVisible === 'confirm'} onCancel={() => setMVisible(null)}>
        <Row>
          <span>您确定完成此工程？</span>
        </Row>
        <Row justify={'end'} style={{marginTop: '30px'}}>
          <Space>
            <Button onClick={() => setMVisible(null)}>取消</Button>
            <Button
              type={'primary'}
              loading={workStatusLoading}
              onClick={async () => {
                await projectModel.projectAdminWorkStatus({workStatus: '4', workCode})
                message.success('操作成功！')
                setMVisible(null)
                history.goBack()
              }}
            >
              确定
            </Button>
          </Space>
        </Row>
      </Modal>
    </Page>
  )
}
