import React, {useCallback, useEffect, useState} from 'react'
import {Page} from '@/components/Page'
import {stewardModel} from '@/store/models/Steward'
import {
  Button,
  Col,
  DatePicker,
  Descriptions,
  Form,
  Image,
  Input,
  message,
  Modal,
  Radio,
  Row,
  Select,
  Space,
  Table,
  Tabs,
} from 'antd'
import commonStyles from '@/assets/styles/common.module.less'
import {useCodeArraySync, useCodeSync} from '@/store/models/Common'
import {getPaginationProps} from '@/common/biz/getPaginationProps'
import {getImg} from '@/utils/util'

export default function AppliancesList() {
  const appliancesApprovalStatusArr = useCodeArraySync('steward.order.appliances.approval.status')
  const appliancesApprovalStatusCode = useCodeSync('steward.order.appliances.approval.status')

  const [auditForm] = Form.useForm()

  const [form] = Form.useForm()
  const [approvalTotal, setApprovalTotal] = useState(0)
  const [approvalList, setApprovalList] = useState<StewardOrderAppliancesApprovalListVO[]>([])

  const queryApprovalList = useCallback(() => {
    const formValues = form.getFieldsValue(true)
    stewardModel.stewardOrderAppliancesApprovalList(formValues).then(({response: {data, total}}) => {
      setApprovalList(data)
      setApprovalTotal(total)
    })
  }, [form])

  useEffect(() => {
    queryApprovalList()
  }, [queryApprovalList])

  const dataLoading = stewardModel.stewardOrderAppliancesApprovalList.useLoading()

  const loadings = stewardModel.stewardOrderAppliancesRoomDetail.useLoadings()

  // 审核
  function appliancesApprovalAudit(approvalId, modal: ReturnType<typeof Modal.confirm> = null) {
    Modal.confirm({
      title: '审核',
      icon: null,
      centered: true,
      content: (
        <div style={{marginTop: '20px'}}>
          <Form form={auditForm}>
            <Form.Item name={'approvalStatus'} label={'审核结果'} rules={[{required: true, message: '请选择审核结果'}]}>
              <Radio.Group>
                <Radio value={'2'}>通过</Radio>
                <Radio value={'3'}>驳回</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item noStyle shouldUpdate>
              {form1 => {
                const approvalStatus = form1.getFieldValue('approvalStatus')
                return (
                  <Form.Item
                    name={'approvalRemark'}
                    label={'备注'}
                    rules={[{required: approvalStatus === '3', message: '请输入备注'}]}
                  >
                    <Input.TextArea placeholder={'请输入'} maxLength={200} showCount />
                  </Form.Item>
                )
              }}
            </Form.Item>
          </Form>
        </div>
      ),
      afterClose: () => auditForm.resetFields(),
      onOk: async () => {
        await auditForm.validateFields()
        const approvalStatus = auditForm.getFieldValue('approvalStatus')
        const approvalRemark = auditForm.getFieldValue('approvalRemark')
        await stewardModel.stewardOrderAppliancesApprovalAudit({
          approvalId,
          approvalStatus,
          approvalRemark,
        })
        message.success('操作成功！')
        queryApprovalList()
        modal?.update({
          title: (
            <Space>
              <span>家电信息</span>
              <Button type={'link'} onClick={() => appliancesAuditInfo(approvalId)}>
                审核信息
              </Button>
            </Space>
          ),
        })
      },
    })
  }

  // 审核信息
  async function appliancesAuditInfo(approvalId) {
    const {
      response: {data},
    } = await stewardModel.stewardOrderAppliancesApprovalDetail({approvalId})

    Modal.confirm({
      title: '审核信息',
      icon: null,
      centered: true,
      okCancel: false,
      okText: '关闭',
      content: (
        <Descriptions column={1} style={{marginTop: '20px'}}>
          <Descriptions.Item label={'审核结果'}>{appliancesApprovalStatusCode[data.approvalStatus]}</Descriptions.Item>
          <Descriptions.Item label={'备注'}>{data.approvalRemark}</Descriptions.Item>
          <Descriptions.Item label={'审核人'}>{data.approvalUserName}</Descriptions.Item>
          <Descriptions.Item label={'审核时间'}>{data.approvalTime}</Descriptions.Item>
        </Descriptions>
      ),
    })
  }

  return (
    <Page>
      <div className={commonStyles.filterSection}>
        <Form
          form={form}
          initialValues={{pageNum: 1, pageSize: 10, pageable: true}}
          onFinish={queryApprovalList}
          onReset={queryApprovalList}
          labelCol={{flex: '80px'}}
        >
          <Row gutter={20}>
            <Col span={8}>
              <Form.Item name={'orderId'} label={'服务单号'}>
                <Input placeholder={'请输入'} allowClear />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name={'categorySearch'} label={'服务项目'}>
                <Input placeholder={'请输入'} allowClear />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name={'propertyCode'} label={'房源ID'}>
                <Input placeholder={'请输入'} allowClear />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name={'cleanerName'} label={'服务人员'}>
                <Input placeholder={'请输入'} allowClear />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name={'insertTime'} label={'创建日期'}>
                <DatePicker.RangePicker
                  allowClear
                  onChange={(_, formatString) => {
                    const [insertTimeBegin, insertTimeEnd] = formatString ?? []
                    form.setFieldsValue({
                      insertTimeBegin: insertTimeBegin ? insertTimeBegin + ' 00:00:00' : undefined,
                      insertTimeEnd: insertTimeEnd ? insertTimeEnd + ' 23:59:59' : undefined,
                    })
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name={'approvalStatus'} label={'审核状态'}>
                <Select placeholder={'请选择'} allowClear>
                  {appliancesApprovalStatusArr.map(item => (
                    <Select.Option key={item.id} value={item.id}>
                      {item.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name={'approvalUserName'} label={'审核人'}>
                <Input placeholder={'请输入'} allowClear />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name={'approvalTime'} label={'审核日期'}>
                <DatePicker.RangePicker
                  allowClear
                  onChange={(_, formatString) => {
                    const [approvalTimeBegin, approvalTimeEnd] = formatString ?? []
                    form.setFieldsValue({
                      approvalTimeBegin: approvalTimeBegin ? approvalTimeBegin + ' 00:00:00' : undefined,
                      approvalTimeEnd: approvalTimeEnd ? approvalTimeEnd + ' 23:59:59' : undefined,
                    })
                  }}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row justify={'end'}>
            <Space>
              <Button type={'primary'} htmlType={'submit'} onClick={() => form.setFieldsValue({pageNum: 1})}>
                查询
              </Button>
              <Button htmlType={'reset'}>重置</Button>
            </Space>
          </Row>
        </Form>
      </div>

      <div className={commonStyles.tableSection}>
        <Table
          rowKey={'approvalId'}
          loading={dataLoading}
          dataSource={approvalList}
          columns={[
            {title: '服务单号', dataIndex: 'orderId'},
            {
              title: '服务项目',
              dataIndex: 'categoryFirName',
              render: (value, record) =>
                [value, record.categorySecName, record.categoryThiName].filter(item => item).join('-'),
            },
            {title: '房源ID', dataIndex: 'propertyCode'},
            {
              title: '服务人员',
              dataIndex: 'cleanerName',
              render: (value, record) => `${value}（${record.cleanerMobile}）`,
            },
            {title: '创建时间', dataIndex: 'insertTime'},
            {title: '审核状态', dataIndex: 'approvalStatus', render: value => appliancesApprovalStatusCode[value]},
            {title: '审核人', dataIndex: 'approvalUserName'},
            {title: '审核时间', dataIndex: 'approvalTime'},
            {
              title: '操作',
              dataIndex: 'approvalId',
              fixed: 'right',
              render: (approvalId: string, record) => {
                const approvalStatus = record.approvalStatus
                return (
                  <Space>
                    <Button
                      type={'link'}
                      style={{padding: 0}}
                      loading={loadings.pick(approvalId)}
                      onClick={async () => {
                        const {
                          response: {data},
                        } = await stewardModel.stewardOrderAppliancesRoomDetail({approvalId})
                        const modal = Modal.confirm({
                          title: (
                            <Space>
                              <span>家电信息</span>
                              <Button
                                type={'link'}
                                onClick={() => {
                                  if (approvalStatus === '1') appliancesApprovalAudit(approvalId, modal)
                                  if (['2', '3'].includes(approvalStatus)) appliancesAuditInfo(approvalId)
                                }}
                              >
                                {(approvalStatus === '1' && '审核') ||
                                  (['2', '3'].includes(approvalStatus) && '审核信息')}
                              </Button>
                            </Space>
                          ),
                          icon: null,
                          centered: true,
                          width: '75%',
                          okCancel: false,
                          okText: '关闭',
                          content: (
                            <div>
                              <Tabs>
                                {data.map(item => (
                                  <Tabs.TabPane key={item.roomCode} tab={item.roomName}>
                                    <Table
                                      rowKey={'appliancesCode'}
                                      dataSource={item.appliancesList}
                                      columns={[
                                        {title: '名称', dataIndex: 'appliancesName'},
                                        {title: '品牌', dataIndex: 'appliancesBrand'},
                                        {title: '规格', dataIndex: 'appliancesTp'},
                                        {
                                          title: '图片',
                                          dataIndex: 'appliancesImgUrlList',
                                          render: value =>
                                            value?.map(item => (
                                              <Image
                                                key={item}
                                                src={getImg(item)}
                                                style={{width: '100px', height: '80px', marginRight: '10px'}}
                                              />
                                            )),
                                        },
                                      ]}
                                      pagination={false}
                                    />
                                  </Tabs.TabPane>
                                ))}
                              </Tabs>
                            </div>
                          ),
                        })
                      }}
                    >
                      家电信息
                    </Button>
                    {/*{approvalStatus === '1' && (*/}
                    {/*  <Button type={'link'} style={{padding: 0}} onClick={() => appliancesApprovalAudit(approvalId)}>*/}
                    {/*    审核*/}
                    {/*  </Button>*/}
                    {/*)}*/}
                    {/*{['2', '3'].includes(approvalStatus) && (*/}
                    {/*  <Button type={'link'} style={{padding: 0}} onClick={() => appliancesAuditInfo(approvalId)}>*/}
                    {/*    审核信息*/}
                    {/*  </Button>*/}
                    {/*)}*/}
                  </Space>
                )
              },
            },
          ]}
          scroll={{x: 'max-content'}}
          pagination={getPaginationProps(form, approvalTotal)}
        />
      </div>
    </Page>
  )
}
