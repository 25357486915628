import React, {useCallback, useEffect, useState} from 'react'
import {Button, Col, Form, Input, message, Modal, Row, Select, Space, Switch, Table, Tooltip} from 'antd'
import {sysUserModel} from '@/store/models/SysUser'
import '@/pages/App.less'
import {Breadcrumb} from '@/components/Breadcrumb'
import commonStyles from '@/assets/styles/common.module.less'
import {clientModel} from '@/store/models/Client'
import {usePermission} from '@/components/Permission'
import {sysRoleModel} from '@/store/models/SysRole'
import {Page} from '@/components/Page'
import {getPaginationProps} from '@/common/biz/getPaginationProps'
import {useAppHistory} from '@/common/hooks/useRouter'
import {useFocusEffect} from '@/common/hooks/useFocusEffect'

const {Option} = Select

export default function SysUserListPage() {
  const history = useAppHistory()
  const [auth] = usePermission()

  const [total, setTotal] = useState(0)
  const [dataSource, setDataSource] = useState<SysUserVO[]>([])
  const loading = sysUserModel.querySysUserList.useLoading()
  const syncLoading = clientModel.weworkSyncAddrBook.useLoading()
  const switchLoading = sysUserModel.changeUserStatus.useLoading()

  const [form] = Form.useForm()

  const [roleList, setRoleList] = useState<SysRoleVO[]>([])

  const querySysUserList = useCallback(() => {
    const values = form.getFieldsValue(true)
    sysUserModel.querySysUserList(values).then(({response: {data, total}}) => {
      setDataSource(data)
      setTotal(total)
    })
  }, [form])

  useFocusEffect(() => {
    form.submit()
  })

  useEffect(() => {
    form.submit()
  }, [form])

  useEffect(function () {
    sysRoleModel
      .sysRoleList({pageNum: 1, pageSize: 10, pageable: false} as SysListDTO)
      .then(({response: {data}}) => setRoleList(data))
  }, [])

  const onResetPassword = record => {
    return () => {
      Modal.confirm({
        title: '确定重置密码吗?',
        onOk() {
          sysUserModel.resetPassword({userId: record.userId}).then(({response: {code, data, total}}) => {
            message.success('重置密码成功', 5)
          })
        },
      })
    }
  }

  const onUserStatusChange = (checked: boolean, record: SysUserVO, event) => {
    Modal.confirm({
      title: '提示',
      centered: true,
      content: checked
        ? `是否确认启用${record.name}的账号？启用后账号可以正常登录并进行相关操作`
        : `是否确认禁用${record.name}的账号？禁用后账号将无法进行登录`,
      onOk: async () => {
        // 需求：ID1003560
        if (!checked && record.roleNameList?.includes('分散式-管家')) {
          return message.error('禁止直接禁用，请使用管家工作交接功能')
        }

        await sysUserModel.changeUserStatus({userId: record.userId, status: checked ? '1' : '0'})
        form.submit()
      },
    })
    event.stopPropagation()
  }

  return (
    <Page
      breadcrumb={
        <Breadcrumb
          right={
            <Space>
              {auth('003031002') && (
                <Button type='primary' onClick={() => history.push({pathname: '/sys-user/add'})}>
                  新增
                </Button>
              )}
              {auth('017031002') && (
                <Button
                  loading={syncLoading}
                  onClick={async () => {
                    await clientModel.weworkSyncAddrBook()
                    message.success('成功')
                  }}
                >
                  同步企业微信
                </Button>
              )}
            </Space>
          }
        />
      }
    >
      <div className={commonStyles.filterSection}>
        <Form
          form={form}
          initialValues={{pageNum: 1, pageSize: 10, pageable: true}}
          onFinish={querySysUserList}
          onReset={querySysUserList}
        >
          <Row gutter={40}>
            <Col span={8}>
              <Form.Item label={'姓名'} name={'name'}>
                <Input placeholder={'请输入姓名'} allowClear />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={'手机号'} name={'mobile'}>
                <Input placeholder={'请输入手机号'} allowClear />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={'状态'} name={'status'}>
                <Select placeholder={'请选择'} allowClear>
                  <Option value={'0'}>禁用</Option>
                  <Option value={'1'}>正常</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={'用户组'} name={'roleId'}>
                <Select placeholder={'请选择'} allowClear>
                  {roleList.map(item => (
                    <Option key={item.roleId} value={item.roleId}>
                      {item.roleName}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={'工作性质'} name={'fullTime'}>
                <Select placeholder={'请选择'} allowClear>
                  <Option value={1}>全职</Option>
                  <Option value={0}>兼职</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row justify={'end'}>
            <Space>
              <Button type={'primary'} htmlType={'submit'} onClick={() => form.setFieldsValue({pageNum: 1})}>
                查询
              </Button>
              <Button htmlType={'reset'}>重置</Button>
            </Space>
          </Row>
        </Form>
      </div>

      <div className={commonStyles.tableSection}>
        <Table
          rowKey={'userId'}
          dataSource={dataSource}
          loading={loading}
          columns={[
            {title: 'ID', dataIndex: 'userId', key: 'userId'},
            {title: '账号', dataIndex: 'userName', key: 'userName'},
            {
              title: '姓名',
              dataIndex: 'name',
              key: 'name',
              render: (text, record, index) => {
                return <span>{text}</span>
              },
            },
            {title: '手机号', dataIndex: 'mobile', key: 'mobile'},
            {
              title: '用户组',
              dataIndex: 'roleNameList',
              key: 'roleNameList',
              render: text => {
                const arr = text?.split(',') || []
                if (arr.length > 1) {
                  return <Tooltip title={text}>{arr[0] + '...'}</Tooltip>
                }
                return text
              },
            },
            {title: '工作性质', dataIndex: 'fullTime', render: value => (value === 1 ? '全职' : '兼职')},
            {
              title: '状态',
              dataIndex: 'status',
              key: 'status',
              render: (text, record, index) => {
                return text === '1' ? <span>正常</span> : <span>禁用</span>
              },
            },
            {title: '新建时间', dataIndex: 'insertTime', key: 'insertTime'},
            {title: '更新时间', dataIndex: 'updateTime', key: 'updateTime'},
            {
              title: '操作',
              dataIndex: 'userId',
              key: 'handle',
              fixed: 'right',
              width: '200px',
              render: (text, record) => {
                return (
                  <Space>
                    {auth('005031002') && (
                      <Switch
                        size={'small'}
                        checked={record.status === '1'}
                        loading={switchLoading}
                        onChange={(checked, event) => onUserStatusChange(checked, record, event)}
                      />
                    )}

                    {auth('002031002') && (
                      <Button
                        type={'link'}
                        size={'small'}
                        onClick={() => {
                          history.push({
                            pathname: '/sys-user/add',
                            state: {userId: text},
                          })
                        }}
                      >
                        编辑
                      </Button>
                    )}
                    {auth('016031002') && (
                      <Button type={'link'} size={'small'} onClick={onResetPassword(record)}>
                        重置密码
                      </Button>
                    )}
                  </Space>
                )
              },
            },
          ]}
          scroll={{x: 'max-content'}}
          pagination={getPaginationProps(form, total)}
        />
      </div>
    </Page>
  )
}
