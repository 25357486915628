import {Page} from '@/components/Page'
import React from 'react'
import {PageHeader} from 'antd'
import {StoreInfo} from '../StoreInfo'

export default function AddStore() {
  return (
    <Page header={<PageHeader style={{background: '#fff'}} title={'新增门店'} />}>
      <StoreInfo />
    </Page>
  )
}
