import React from 'react'
import {ArrowLeftOutlined} from '@ant-design/icons'
import {Button, ButtonProps} from 'antd'
import './index.less'

export type BackButtonProps = ButtonProps

const BackButton: React.FC<BackButtonProps> = ({className = '', ...props}) => {
  return (
    <Button
      className={`BackButton ${className}`}
      type={'text'}
      icon={<ArrowLeftOutlined className={'BackButtonIcon'} />}
      size={'large'}
      onClick={() => window.history.back()}
      {...props}
    >
      返回
    </Button>
  )
}

export default BackButton
