import React from 'react'
import * as listener from '@/common/hooks/listener'
import {PermissionConfig} from '@/pages/company/components/PermissionConfig'
import {useAppHistory, useAppLocation} from '@/common/hooks/useRouter'

export default function SetPermissionIdList() {
  const history = useAppHistory()
  const location = useAppLocation<'/oms-permission'>()
  const {permissionIdList} = location.state ?? {}
  return (
    <>
      <PermissionConfig
        permissionIdList={permissionIdList}
        confirm={async ids => {
          listener.emit('AddSysUser: ids', ids)
          history.goBack()
        }}
      />
    </>
  )
}
