import {fileUploadModel} from '@/store/models/FileUpload'
import {lessorModel} from '@/store/models/Lessor'
// import {sysPermissionModel} from '@/store/models/SysPermission'
import {sysRoleModel} from '@/store/models/SysRole'
import {sysUserModel} from '@/store/models/SysUser'
import {userModel} from '@/store/models/User'
import {commonModel} from '@/store/models/Common'
import {setModels} from '@/utils/globalService'

const models = {
  fileUploadModel,
  lessorModel,
  // sysPermissionModel,
  sysRoleModel,
  sysUserModel,
  userModel,
  commonModel,
}
setModels(models)

export default models
