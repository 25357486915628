import React, {useEffect} from 'react'
import {sysModel} from '@/store/models/Sys'
import {useState} from 'react'
import {Select, SelectProps} from 'antd'

/**
 * Form.Item 子项
 * 选择组织组件，可搜索
 */
const OrgSelect: React.FC<Pick<SysOrgListQueryDTO, 'orgPid'> & SelectProps> = ({orgPid, ...props}) => {
  const [orgList, setOrgList] = useState<SysOrgVO[]>([])

  useEffect(() => {
    sysModel.sysOrgList({orgIdLike: orgPid}).then(({response: {data}}) => {
      setOrgList(data)
    })
  }, [orgPid])

  return (
    <Select
      showSearch
      filterOption={(inputValue, option: any) => option.children?.toLowerCase()?.includes(inputValue?.toLowerCase())}
      {...props}
    >
      {orgList.map(item => (
        <Select.Option key={item.orgId} value={item.orgId}>
          {item.orgName}
        </Select.Option>
      ))}
    </Select>
  )
}

OrgSelect.defaultProps = {
  orgPid: '',
}

export default OrgSelect
