import React, {useEffect, useState, useCallback} from 'react'
import {useHistory} from 'react-router-dom'
import {Button, Col, DatePicker, Form, Input, Row, Select, Space, Table, Typography} from 'antd'
import {useCodeArraySync, useCodeSync} from '@/store/models/Common'
import commonStyles from '@/assets/styles/common.module.less'
import {usePermission} from '@/components/Permission'
import {teamModel} from '@/store/models/Team'
import {useFocusEffect} from '@/common/hooks/useFocusEffect'
import {getPaginationProps} from '@/common/biz/getPaginationProps'
import {Page} from '@/components/Page'

const {Text} = Typography

export default function AuthList() {
  const history = useHistory()
  const [form] = Form.useForm()
  const [total, setTotal] = useState(0)
  const [dataSource, setDataSource] = useState<TeamAuthRecordVO[]>()
  const loading = teamModel.teamAuthRecordList.useLoading()
  const [auth] = usePermission()

  const teamAuthStatusList = useCodeArraySync('team.status')
  const teamAuthStatus = useCodeSync('team.status')
  const teamAuthType = useCodeSync('team.auth.type')

  const search = useCallback(() => {
    const formValue = form.getFieldsValue(true)
    teamModel.teamAuthRecordList(formValue).then(({response: {data, total}}) => {
      setDataSource(data)
      setTotal(total)
    })
  }, [form])

  useFocusEffect(() => {
    form.submit()
  })

  useEffect(() => {
    form.submit()
  }, [form])

  return (
    <Page>
      <div className={commonStyles.filterSection}>
        <Form
          form={form}
          autoComplete={'off'}
          initialValues={{pageNum: 1, pageSize: 10}}
          onFinish={search}
          onReset={search}
        >
          <Row gutter={20}>
            <Col span={7}>
              <Form.Item name={'companyName'} label={'企业名称'}>
                <Input allowClear placeholder={'请输入企业名称'} />
              </Form.Item>
            </Col>
            <Col span={7} offset={1}>
              <Form.Item name={'_insertTime'} label={'提交时间'}>
                <DatePicker.RangePicker
                  getPopupContainer={trigger => trigger}
                  showTime={{format: 'HH:mm:ss'}}
                  onChange={(_, formatString) => {
                    form.setFieldsValue({
                      insertTimeBegin: formatString?.[0],
                      insertTimeEnd: formatString?.[1],
                    })
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={7} offset={1}>
              <Form.Item name={'authStatus'} label={'状态'}>
                <Select getPopupContainer={triggerNode => triggerNode.parentNode} allowClear placeholder={'请选择状态'}>
                  {teamAuthStatusList.map((item, index) => (
                    <Select.Option key={index} value={item.id}>
                      {item.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row justify={'end'}>
            <Space>
              <Button htmlType={'submit'} onClick={() => form.setFieldsValue({pageNum: 1})} type={'primary'}>
                查询
              </Button>
              <Button htmlType={'reset'}>重置</Button>
            </Space>
          </Row>
        </Form>
      </div>

      <div className={commonStyles.tableSection}>
        <Table
          rowKey={'recordId'}
          dataSource={dataSource}
          loading={loading}
          scroll={{x: 'max-content'}}
          columns={[
            {title: '申请人', dataIndex: 'applicantName', key: 'applicantName'},
            {title: '企业名称', dataIndex: 'companyName', key: 'companyName'},
            {title: '注册手机', dataIndex: 'applicantMobile', key: 'applicantMobile'},
            {title: '认证时间', dataIndex: 'operatorTime', key: 'operatorTime'},
            {title: '地址', dataIndex: 'applicantAddr', key: 'applicantAddr'},
            {
              title: '商户类型',
              dataIndex: 'teamType',
              key: 'teamType',
              render: text => {
                return <Text>{teamAuthType[text]}</Text>
              },
            },
            {
              title: '状态',
              dataIndex: 'authStatus',
              key: 'authStatus',
              render: text => {
                return <span>{teamAuthStatus[text]}</span>
              },
            },
            {
              title: '操作',
              dataIndex: 'operation',
              fixed: 'right',
              width: '100px',
              render: (text, record, index) => {
                return (
                  auth('001007002') && (
                    <Button
                      type={'link'}
                      size={'small'}
                      onClick={() => {
                        const location = {
                          pathname: '/auth/detail',
                          state: {recordId: record.recordId},
                        }
                        history.push(location)
                      }}
                      style={{padding: 0}}
                    >
                      查看
                    </Button>
                  )
                )
              },
            },
          ]}
          pagination={getPaginationProps(form, total)}
        />
      </div>
    </Page>
  )
}
