import {ECOption} from '@/common/lib/echarts'
import React, {useCallback, useEffect, useRef, useState} from 'react'
import styles from './index.module.less'
import * as echarts from 'echarts/core'
import {Button, Col, DatePicker, Form, Input, Radio, Row, Space, Table, Tabs, Tooltip} from 'antd'
import moment from 'moment/moment'
import {InfoCircleOutlined} from '@ant-design/icons'
import {homeModel} from '@/store/models/Home'
import {stewardModel} from '@/store/models/Steward'
import {FORMAT_DATE_END, FORMAT_DATE_START} from '@/common/date'

const icon = 'path://M0 0 L8 0 L8 1 L0 1 Z'
const tipLabel = {
  '1': '当前所有分散式管家账号里的待沟通服务单数量和已沟通服务单数量',
  '2': '当前管家完成的服务单的完成时间在当前时间范围内的服务单数量',
  '3': '当前管家完成的服务单的评价时间在当前时间范围内的好评和差评数量',
  '4': '展示所选日期范围内每天下单量和完成单量的变化情况',
}

const labelObj = {
  not: {name: '待沟通', color: '#5B8FF9'},
  in: {name: '已沟通', color: '#61D9AA'},
}
const markLabel = {
  bad: {name: '差评', color: '#FF8585'},
  lessorRate: {name: '房东好评', color: '#5B8FF9'},
  lesseeRate: {name: '租客好评', color: '#10b691'},
}
const orderMap = {
  make: {name: '下单量', color: '#1890FF'},
  do: {name: '完成量', color: '#2FC25B'},
}

const initialDates = [moment().subtract(1, 'month').add(1, 'day'), moment()]
const beginFormat = time => moment(time).format(FORMAT_DATE_START)
const endFormat = time => moment(time).format(FORMAT_DATE_END)

export default function ChartView() {
  const [tabKey, setTabKey] = useState('1')

  const [time4, setTime4] = useState<moment.Moment[]>(initialDates)

  return (
    <div style={{background: '#fff'}}>
      <Tabs
        className={styles.tabs}
        onChange={key => {
          setTabKey(key)
        }}
        tabBarExtraContent={
          tabKey === '4' && (
            <DatePicker.RangePicker
              value={[time4[0], time4[1]] as any}
              style={{marginRight: 20}}
              onChange={dates => {
                if (dates && dates.every(item => item)) {
                  if (tabKey === '4') setTime4(dates)
                }
              }}
              disabledDate={currentDate => {
                return currentDate && (currentDate < moment().subtract(1, 'year') || currentDate > moment())
              }}
            />
          )
        }
      >
        <Tabs.TabPane tab={'持单分布'} key={'1'}>
          <StatisticSection1 />
        </Tabs.TabPane>
        <Tabs.TabPane tab={'完成单量'} key={'2'}>
          <StatisticSection2 />
        </Tabs.TabPane>
        <Tabs.TabPane tab={'评价情况'} key={'3'}>
          <StatisticSection3 />
        </Tabs.TabPane>
        <Tabs.TabPane tab={'单量吞吐'} key={'4'}>
          <StatisticSection4 time={time4} />
        </Tabs.TabPane>
      </Tabs>
    </div>
  )
}

const ChartBar: React.FC<{option: ECOption}> = ({option}) => {
  const chartDomRef = useRef(null)
  const chartRef = useRef<echarts.ECharts>(null)

  const setOption = useCallback(() => {
    chartRef.current = echarts.init(chartDomRef.current)

    chartRef.current?.showLoading('default', {text: '加载中...', color: '#017BFF', textColor: '#2C3542'})

    chartRef.current?.setOption(option, true)

    chartRef.current?.hideLoading()
  }, [option])

  useEffect(() => {
    setTimeout(() => {
      setOption()
    })
    function onResize() {
      chartRef.current?.resize?.()
    }

    window.addEventListener('resize', onResize)

    return () => window.removeEventListener('resize', onResize)
  }, [setOption])

  return (
    <div className={styles.chart}>
      <div ref={chartDomRef} style={{width: '100%', height: '100%'}}></div>
    </div>
  )
}

type StatisticBarProps = {
  tabKey: string
  chartType: 1 | 2
  onChartTypeChange: (type: 1 | 2) => void
  onFinish: (value: StatisticFilter) => void
  onReset: () => void
}

type StatisticFilter = {stewardName: string; time: moment.Moment[]}

const StatisticBar = React.memo(({tabKey, chartType, onChartTypeChange, onFinish, onReset}: StatisticBarProps) => {
  const [form] = Form.useForm<StatisticFilter>()

  return (
    <Form form={form} onFinish={onFinish} onReset={onReset} initialValues={{time: initialDates}}>
      <Row gutter={10}>
        <Col>
          <Tooltip title={tipLabel[tabKey]}>
            <InfoCircleOutlined style={{color: '#06B6AF', marginTop: '8px'}} />
          </Tooltip>
        </Col>
        <Col>
          <Form.Item>
            <Radio.Group value={chartType} onChange={({target: {value}}) => onChartTypeChange(value)}>
              <Radio value={1}>图表分析</Radio>
              <Radio value={2}>列表分析</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
        <Col>
          <Form.Item name={'stewardName'} label={'管家'}>
            <Input placeholder={'请输入'} style={{width: '120px'}} />
          </Form.Item>
        </Col>
        {tabKey !== '1' && (
          <Col>
            <Form.Item label={'完成日期'} name={'time'}>
              <DatePicker.RangePicker
                disabledDate={currentDate => {
                  return currentDate && (currentDate < moment().subtract(1, 'year') || currentDate > moment())
                }}
              />
            </Form.Item>
          </Col>
        )}
        <Col>
          <Space>
            <Button htmlType={'submit'} type={'primary'}>
              查询
            </Button>
            <Button htmlType={'reset'}>重置</Button>
          </Space>
        </Col>
      </Row>
    </Form>
  )
})

// 持单分布
type Statistic1 = {mobile: string; name: string; num1: number; num2: number; userId: string}

const StatisticSection1 = React.memo(() => {
  const [dataList, setDataList] = useState<Statistic1[]>([])
  const [originList, setOriginList] = useState<Statistic1[]>([])

  const [option, setOption] = useState({} as ECOption)
  const [chartType, setChartType] = useState<1 | 2>(1)

  useEffect(() => {
    homeModel.homeStewardStatistic6({}).then(({response: {data}}) => {
      // 待沟通数量从高到低排序
      const sortList = data.sort((item1, item2) => item2.num1 - item1.num1)
      setDataList(sortList as Statistic1[])
      setOriginList(sortList as Statistic1[])
    })
  }, [])

  useEffect(() => {
    if (dataList.length) {
      const obj = dataList.reduce(
        (acc, item) => {
          acc['not'].push(item.num1)
          acc['in'].push(item.num2)
          return acc
        },
        {not: [], in: []}
      )

      const series: ECOption['series'] = Object.keys(obj).map(item => {
        const label = labelObj[item]
        return {
          name: label.name,
          type: 'bar',
          data: obj[item],
          barWidth: '6px',
          itemStyle: {
            borderRadius: [5, 5, 0, 0],
            color: label.color,
          },
        }
      })
      setOption({
        legend: {
          data: ['not', 'in'].map(item => ({name: labelObj[item].name, icon})),
          right: 20,
          itemGap: 24,
          textStyle: {
            color: 'rgba(44, 53, 66, 0.65)',
          },
        },
        grid: {
          left: 20,
          right: 20,
          containLabel: true,
        },
        tooltip: {
          trigger: 'axis',
        },
        xAxis: {
          type: 'category',
          data: dataList.map(item => `${item.name} ${item.mobile}`),
          axisTick: {
            alignWithLabel: true,
          },
          axisLine: {
            lineStyle: {
              color: '#D9D9D9',
            },
          },
          axisLabel: {
            color: 'rgba(0, 0, 0, 0.65)',
            formatter: value => value.split(' ')[0],
          },
          offset: 10,
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            color: 'rgba(0, 0, 0, 0.65)',
          },
          splitLine: {
            lineStyle: {
              type: 'dotted',
              color: '#E9E9E9',
            },
          },
        },
        series,
        dataZoom: [
          {type: 'slider', show: true, xAxisIndex: [0]},
          {type: 'inside', xAxisIndex: [0]},
        ],
      })
    }
  }, [dataList])

  return (
    <div style={{position: 'relative', paddingBottom: '20px'}}>
      <div style={{position: 'absolute', zIndex: 2, left: 20}}>
        <StatisticBar
          tabKey={'1'}
          chartType={chartType}
          onChartTypeChange={setChartType}
          onFinish={({stewardName}) =>
            setDataList(prevState => prevState.filter(value => value.name.includes(stewardName)))
          }
          onReset={() => setDataList(originList)}
        />
      </div>
      {chartType === 1 && <ChartBar option={option} />}
      {chartType === 2 && (
        <div style={{padding: '50px 20px 0'}}>
          <Table
            rowKey={'userId'}
            dataSource={dataList}
            columns={[
              {title: '管家', dataIndex: 'name'},
              {title: '手机号', dataIndex: 'mobile'},
              {title: '待沟通', dataIndex: 'num1', sorter: (a, b) => a.num1 - b.num1},
              {title: '已沟通', dataIndex: 'num2', sorter: (a, b) => a.num2 - b.num2},
            ]}
          />
        </div>
      )}
    </div>
  )
})

// 完成单量
type Statistic2 = {mobile: string; name: string; num: number; userId: string}

const StatisticSection2 = React.memo(() => {
  const [dataList, setDataList] = useState<Statistic2[]>([])

  const [option, setOption] = useState({} as ECOption)
  const [chartType, setChartType] = useState<1 | 2>(1)

  const queryStewardStatistic7 = useCallback((stewardName = '', time: moment.Moment[]) => {
    homeModel
      .homeStewardStatistic7({beginTime: beginFormat(time[0]), endTime: endFormat(time[1])})
      .then(({response: {data}}) => {
        // 已完成单量从高到低
        const sortList = (data as Statistic2[]).sort((item1, item2) => item2.num - item1.num)
        if (stewardName.length) {
          setDataList(sortList.filter(value => value.name.includes(stewardName)))
        } else {
          setDataList(sortList)
        }
      })
  }, [])

  useEffect(() => {
    queryStewardStatistic7('', initialDates)
  }, [queryStewardStatistic7])

  useEffect(() => {
    if (dataList.length) {
      const series: ECOption['series'] = [
        {
          name: '完成单量',
          type: 'bar',
          data: dataList.map(item => item.num),
          barWidth: '6px',
          itemStyle: {
            borderRadius: [5, 5, 0, 0],
            color: '#61D9AA',
          },
        },
      ]
      setOption({
        tooltip: {
          trigger: 'axis',
        },
        grid: {
          left: 20,
          right: 20,
          containLabel: true,
        },
        legend: {show: false},
        xAxis: [
          {
            type: 'category',
            data: dataList.map(item => `${item.name} ${item.mobile}`),
            axisTick: {
              alignWithLabel: true,
            },
            axisLine: {
              lineStyle: {
                color: '#D9D9D9',
              },
            },
            axisLabel: {
              color: 'rgba(0, 0, 0, 0.65)',
              formatter: value => value.split(' ')[0],
            },
            offset: 10,
          },
        ],
        yAxis: [
          {
            type: 'value',
            axisLine: {
              show: false,
            },
            splitLine: {
              lineStyle: {
                type: 'dotted',
                color: '#E9E9E9',
              },
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              color: 'rgba(0, 0, 0, 0.65)',
            },
          },
        ],
        series,
        dataZoom: [
          {type: 'slider', show: true, xAxisIndex: [0]},
          {type: 'inside', xAxisIndex: [0]},
        ],
      })
    }
  }, [dataList])

  return (
    <div style={{position: 'relative', paddingBottom: '20px'}}>
      <div style={{position: 'absolute', zIndex: 2, left: 20}}>
        <StatisticBar
          tabKey={'2'}
          chartType={chartType}
          onChartTypeChange={setChartType}
          onFinish={({stewardName, time}) => queryStewardStatistic7(stewardName, time)}
          onReset={() => queryStewardStatistic7('', initialDates)}
        />
      </div>
      {chartType === 1 && <ChartBar option={option} />}
      {chartType === 2 && (
        <div style={{padding: '50px 20px 0'}}>
          <Table
            rowKey={'userId'}
            dataSource={dataList}
            columns={[
              {title: '管家', dataIndex: 'name'},
              {title: '手机号', dataIndex: 'mobile'},
              {title: '完成单量', dataIndex: 'num', sorter: (a, b) => a.num - b.num},
            ]}
          />
        </div>
      )}
    </div>
  )
})

// 评价情况
type Statistic3 = {mobile: string; name: string; num1: number; num2: number; num3: number; userId: string}

const StatisticSection3 = React.memo(() => {
  const [dataList, setDataList] = useState<Statistic3[]>([])

  const [option, setOption] = useState({} as ECOption)
  const [chartType, setChartType] = useState<1 | 2>(1)

  const queryStewardStatistic8 = useCallback((stewardName = '', time: moment.Moment[]) => {
    homeModel
      .homeStewardStatistic8({beginTime: beginFormat(time[0]), endTime: endFormat(time[1])})
      .then(({response: {data}}) => {
        // 差评数从高到低
        const sortList = (data as Statistic3[]).sort((item1, item2) => item2.num1 - item1.num1)
        if (stewardName.length) {
          setDataList(sortList.filter(value => value.name.includes(stewardName)))
        } else {
          setDataList(sortList)
        }
      })
  }, [])

  useEffect(() => {
    queryStewardStatistic8('', initialDates)
  }, [queryStewardStatistic8])

  useEffect(() => {
    if (dataList.length) {
      const object = dataList.reduce(
        (result, item) => {
          result['lessorRate'].push(item.num2)
          result['lesseeRate'].push(item.num3)
          result['bad'].push(item.num1)
          return result
        },
        {lessorRate: [], lesseeRate: [], bad: []}
      )

      const series: ECOption['series'] = Object.keys(object).map(item => {
        const label = markLabel[item]
        return {
          name: label.name,
          type: 'bar',
          data: object[item],
          barWidth: '6px',
          itemStyle: {
            borderRadius: [5, 5, 0, 0],
            color: label.color,
          },
        }
      })

      setOption({
        legend: {
          data: ['lessorRate', 'lesseeRate', 'bad'].map(item => ({name: markLabel[item].name, icon})),
          right: 20,
          textStyle: {
            color: 'rgba(44, 53, 66, 0.65)',
          },
          itemGap: 20,
        },
        tooltip: {
          trigger: 'axis',
        },
        grid: {
          left: 20,
          right: 20,
          containLabel: true,
        },
        xAxis: [
          {
            type: 'category',
            data: dataList.map(item => `${item.name} ${item.mobile}`),
            axisTick: {
              alignWithLabel: true,
            },
            axisLine: {
              lineStyle: {
                color: '#D9D9D9',
              },
            },
            axisLabel: {
              color: 'rgba(0, 0, 0, 0.65)',
              formatter: value => value.split(' ')[0],
            },
            offset: 10,
          },
        ],
        yAxis: [
          {
            type: 'value',
            axisLine: {
              show: false,
            },
            splitLine: {
              lineStyle: {
                type: 'dotted',
                color: '#E9E9E9',
              },
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              color: 'rgba(0, 0, 0, 0.65)',
            },
          },
        ],
        series,
        dataZoom: [
          {type: 'slider', show: true, xAxisIndex: [0]},
          {type: 'inside', xAxisIndex: [0]},
        ],
      })
    }
  }, [dataList])

  return (
    <div style={{position: 'relative', paddingBottom: '20px'}}>
      <div style={{position: 'absolute', zIndex: 2, left: 20}}>
        <StatisticBar
          tabKey={'3'}
          chartType={chartType}
          onChartTypeChange={setChartType}
          onFinish={({stewardName, time}) => queryStewardStatistic8(stewardName, time)}
          onReset={() => queryStewardStatistic8('', initialDates)}
        />
      </div>
      {chartType === 1 && <ChartBar option={option} />}
      {chartType === 2 && (
        <div style={{padding: '50px 20px 0'}}>
          <Table
            rowKey={'userId'}
            dataSource={dataList}
            columns={[
              {title: '管家', dataIndex: 'name'},
              {title: '手机号', dataIndex: 'mobile'},
              {title: '房东好评', dataIndex: 'num2', sorter: (a, b) => a.num2 - b.num2},
              {title: '租客好评', dataIndex: 'num3', sorter: (a, b) => a.num3 - b.num3},
              {title: '差评', dataIndex: 'num1', sorter: (a, b) => a.num1 - b.num1},
            ]}
          />
        </div>
      )}
    </div>
  )
})

const StatisticSection4 = React.memo(({time}: {time: moment.Moment[]}) => {
  const [option4, setOption4] = useState({} as ECOption)

  const queryStewardStatistic9 = useCallback(() => {
    const [beginTime, endTime] = time
    const duration = endTime.diff(beginTime, 'day') + 1
    const dateArr = Array.from({length: duration}).map((item, index) => {
      return moment(beginTime).add(index, 'day').format('YYYY-MM-DD')
    })

    Promise.all([
      stewardModel.homeStewardStatistic9({
        beginTime: beginFormat(beginTime),
        endTime: endFormat(endTime),
      }),
      stewardModel.homeStewardStatistic10({
        beginTime: beginFormat(beginTime),
        endTime: endFormat(endTime),
      }),
    ]).then(arr => {
      const [data1, data2] = arr.map(item => item.response.data)
      const lineData1 = dateArr.map(item => data1[item] ?? 0)
      const lineData2 = dateArr.map(item => data2[item] ?? 0)

      const series: ECOption['series'] = Object.keys(orderMap).map(item => {
        const label = orderMap[item]
        return {
          name: label.name,
          type: 'line',
          data: item === 'make' ? lineData1 : lineData2,
          symbolSize: 2,
          itemStyle: {
            color: label.color,
          },
        }
      })

      setOption4({
        legend: {
          data: ['make', 'do'].map(item => ({name: orderMap[item].name, icon})),
          right: 20,
          textStyle: {
            color: 'rgba(44, 53, 66, 0.65)',
          },
          itemGap: 20,
        },
        tooltip: {
          trigger: 'axis',
        },
        grid: {
          left: 20,
          right: 20,
          containLabel: true,
        },
        xAxis: [
          {
            type: 'category',
            data: dateArr,
            axisTick: {
              alignWithLabel: true,
            },
            axisLine: {
              lineStyle: {
                color: '#D9D9D9',
              },
            },
            axisLabel: {
              color: 'rgba(0, 0, 0, 0.65)',
              formatter: value => moment(value).format('MM-DD'),
            },
            offset: 10,
          },
        ],
        yAxis: [
          {
            type: 'value',
            axisLine: {
              show: false,
            },
            splitLine: {
              lineStyle: {
                type: 'dotted',
                color: '#E9E9E9',
              },
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              color: 'rgba(0, 0, 0, 0.65)',
            },
          },
        ],
        series,
      })
    })
  }, [time])

  useEffect(() => {
    queryStewardStatistic9()
  }, [queryStewardStatistic9])

  return (
    <div style={{position: 'relative', paddingBottom: '20px'}}>
      <div style={{position: 'absolute', zIndex: 2, left: 20}}>
        <Space>
          <span style={{color: 'rgba(0, 0, 0, 0.85)', fontWeight: 'bold'}}>图表分析</span>
          <Tooltip title={'展示所选日期范围内每天下单量和完成单量的变化情况'}>
            <InfoCircleOutlined style={{color: '#06B6AF'}} />
          </Tooltip>
        </Space>
      </div>
      <ChartBar option={option4} />
    </div>
  )
})
