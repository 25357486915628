import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {
  Button,
  Cascader,
  Col,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Radio,
  Row,
  Select,
  Space,
  Switch,
  Table,
  Tooltip,
  Transfer,
} from 'antd'
import {Breadcrumb} from '@/components/Breadcrumb'
import commonStyles from '@/assets/styles/common.module.less'
import {stewardModel} from '@/store/models/Steward'
import {useCodeArraySync, arrayToObject} from '@/store/models/Common'
import {usePermission} from '@/components/Permission'
import {Page} from '@/components/Page'
import {getPaginationProps} from '@/common/biz/getPaginationProps'
import {sysModel} from '@/store/models/Sys'
import {useAppHistory} from '@/common/hooks/useRouter'
import {
  chargeDetailToList,
  ServiceChargeDetailVO,
  ServiceChargeUpdateService,
} from '@/pages/steward/ServiceChargeDetail'

const {Option} = Select

// const cityCodeList = [
//   {id: '310100', label: '上海'},
//   {id: '440100', label: '广州'},
//   {id: '320500', label: '苏州'},
//   {id: '610100', label: '西安'},
//   {id: '430100', label: '长沙'},
//   {id: '440300', label: '深圳'},
//   {id: '370100', label: '济南'},
//   {id: '330200', label: '宁波'},
// ]

// const cityCode = arrayToObject(cityCodeList)
const chargeTypeCode = {steward: '管家', clean: '保洁'}

const mustMaterialOptions = [
  {label: '是', value: 1},
  {label: '否', value: 0},
]

export default function ServiceChargeItems() {
  const history = useAppHistory()
  const [auth] = usePermission()

  const serviceChargeCategoryArr = useCodeArraySync('steward.serviceChargeCategory')
  // const serviceChargeCategoryCode = useCodeSync('steward.serviceChargeCategory')

  /** 列表相关 */
  const [form] = Form.useForm<StewardServiceChargeQueryDTO>()
  const [total, setTotal] = useState(0)
  // const [reqParams, setReqParams] = useState({pageNum: 1, pageSize: 10} as StewardServiceChargeQueryDTO)
  const [chargeList, setChargeList] = useState<StewardServiceChargeVO[]>([])
  const listLoading = stewardModel.stewardAdminServiceChargeList.useLoading()

  const queryServiceChargeList = useCallback(() => {
    stewardModel.stewardAdminServiceChargeList(form.getFieldsValue(true)).then(({response: {data, total}}) => {
      setTotal(total)
      setChargeList(data)
    })
  }, [form])

  useEffect(() => {
    form.submit()
  }, [form])

  const [serviceTree, setServiceTree] = useState<StewardServiceVO[]>([])
  useEffect(() => {
    stewardModel.stewardServiceTree({}).then(({response: {data}}) => setServiceTree(data))
  }, [])

  const [cityCodeList, setCityCodeList] = useState<{id: string; label: string}[]>([])

  useEffect(() => {
    sysModel.sysConfigGet({k: 'CONFIG_SYS_CITY'}).then(({response: {data}}) => {
      setCityCodeList(data?.city || [])
    })
  }, [])

  const cityCode = useMemo(() => arrayToObject(cityCodeList), [cityCodeList])

  /** 新增、编辑相关 */
  const [insertForm] = Form.useForm()
  const [insertVisible, setInsertVisible] = useState(false)
  // 编辑服务
  const [addService, setAddService] = useState<{
    isOpen: boolean
    opt: 'insert' | 'batch'
    updateValue: ServiceChargeDetailVO
    cityCodeList?: string[]
  }>({
    opt: null,
    isOpen: false,
    updateValue: null,
    cityCodeList: [],
  })
  // const [editVisible, setEditVisible] = useState(false)
  const [deleteId, setDeleteId] = useState('')
  const [updateEnable, setUpdateEnable] = useState({chargeId: null, enable: null})

  const insertLoading = stewardModel.stewardAdminServiceChargeInsert.useLoading()
  const detailLoading = stewardModel.stewardAdminServiceChargeDetail.useLoading()
  const deleteLoading = stewardModel.stewardAdminServiceChargeDelete.useLoading()
  const enableLoading = stewardModel.stewardAdminServiceChargeUpdateEnable.useLoading()

  /** 保修信息 */
  const [warrantyForm] = Form.useForm()
  const [warrantyVisible, setWarrantyVisible] = useState(false)

  // 垃圾清运提成设置
  const [rubbishForm] = Form.useForm()

  // 批量设置
  const [selectRows, setSelectRows] = useState([])
  const [batchForm] = Form.useForm()

  return (
    <Page
      breadcrumb={
        <Breadcrumb
          right={
            <Space>
              <Button
                onClick={() => {
                  if (selectRows.length === 0) return message.warning('请选择服务收费类目')
                  Modal.confirm({
                    title: '批量设置',
                    icon: null,
                    closable: true,
                    centered: true,
                    onOk: async () => {
                      const mustMaterial = batchForm.getFieldValue('mustMaterial')
                      await stewardModel.stewardAdminServiceChargeUpdateMustMaterial({
                        mustMaterial: mustMaterial ? 1 : 0,
                        chargeIdList: selectRows,
                      })
                      message.success('批量设置成功！')
                      setSelectRows([])
                      form.submit()
                    },
                    content: (
                      <Form form={batchForm}>
                        <Form.Item label={'必须使用耗材'} name={'mustMaterial'}>
                          <Switch />
                        </Form.Item>
                      </Form>
                    ),
                    afterClose: () => batchForm.resetFields(),
                  })
                }}
              >
                批量设置
              </Button>
              <Button
                onClick={async () => {
                  const {
                    response: {data},
                  } = await stewardModel.stewardAdminServiceChargeGetCleanFreightCommission({})
                  rubbishForm.setFieldsValue({commissionVal: data})

                  Modal.confirm({
                    title: '垃圾清运提成设置',
                    icon: null,
                    closable: true,
                    centered: true,
                    okText: '保存',
                    onOk: async () => {
                      await rubbishForm.validateFields()
                      rubbishForm.submit()
                    },
                    onCancel: () => form.resetFields(),
                    content: (
                      <Form
                        form={rubbishForm}
                        style={{marginTop: '20px'}}
                        onFinish={async () => {
                          await stewardModel.stewardAdminServiceChargeSetCleanFreightCommission(
                            rubbishForm.getFieldsValue(true)
                          )
                          message.success('保存成功！')
                        }}
                      >
                        <Space>
                          <Form.Item
                            name={'commissionVal'}
                            label={'单包价格'}
                            style={{marginBottom: 0}}
                            rules={[{required: true, type: 'number', message: '请输入单包价格'}]}
                          >
                            <InputNumber placeholder={'请输入'} />
                          </Form.Item>
                          元
                        </Space>
                        <div style={{color: '#00B7AE', marginTop: '15px'}}>垃圾清运提成=（垃圾清运包数-3）*单价</div>
                      </Form>
                    ),
                  })
                }}
              >
                垃圾清运提成设置
              </Button>
              {auth('003069002') && (
                <Button type={'primary'} onClick={() => setInsertVisible(true)}>
                  新增收费项目
                </Button>
              )}
            </Space>
          }
        />
      }
    >
      <div className={commonStyles.filterSection}>
        <Form
          form={form}
          labelCol={{flex: '120px'}}
          initialValues={{pageNum: 1, pageSize: 10}}
          onFinish={queryServiceChargeList}
          onReset={queryServiceChargeList}
        >
          <Row>
            <Col span={8}>
              <Form.Item name={'chargeName'} label={'名称'}>
                <Input placeholder={'请输入'} allowClear />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name={'chargeId'} label={'编号'}>
                <Input placeholder={'请输入'} allowClear />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name={'categoryId'} label={'收费类型'}>
                <Select placeholder={'请选择'} allowClear>
                  {serviceChargeCategoryArr.map(item => (
                    <Option key={item.id} value={item.id}>
                      {item.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name={'cityCode'} label={'服务地区'}>
                <Select placeholder={'请选择'} allowClear>
                  {cityCodeList.map(item => (
                    <Option key={item.id} value={item.id}>
                      {item.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name={'chargeType'} label={'服务类型'}>
                <Select placeholder={'请选择'} allowClear>
                  <Option value={'steward'}>管家</Option>
                  <Option value={'clean'}>保洁</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name={'_serviceId'} label={'关联服务类型'}>
                <Cascader
                  options={serviceTree}
                  fieldNames={{label: 'name', value: 'id', children: 'stewardServiceList'}}
                  onChange={value => {
                    if (value.length) {
                      form.setFieldsValue({serviceId: value[2] as string})
                    } else form.setFieldsValue({serviceId: ''})
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name={'mustMaterial'} label={'必须使用耗材'}>
                <Select options={mustMaterialOptions} placeholder={'请选择'} allowClear />
              </Form.Item>
            </Col>
          </Row>
          <Row justify={'end'}>
            <Space>
              <Button type={'primary'} htmlType={'submit'} onClick={() => form.setFieldsValue({pageNum: 1})}>
                查询
              </Button>
              <Button htmlType={'reset'}>重置</Button>
            </Space>
          </Row>
        </Form>
      </div>

      <div className={commonStyles.tableSection}>
        <Table
          rowKey={'chargeId'}
          rowSelection={{
            selectedRowKeys: selectRows,
            onChange: selectedRowKeys => setSelectRows(selectedRowKeys),
          }}
          loading={listLoading}
          dataSource={chargeList}
          columns={[
            {title: '服务编号', dataIndex: 'chargeId'},
            {title: '服务类型', dataIndex: 'chargeType', render: value => chargeTypeCode[value]},
            {
              title: '必须使用耗材',
              dataIndex: 'mustMaterial',
              render: value => mustMaterialOptions.find(item => item.value === value)?.label,
            },
            {
              title: '关联服务类型',
              dataIndex: 'serviceList',
              render: value => {
                if (value?.length) {
                  const resList = value.map(item => item.split('-')?.[2])
                  const title = resList.length > 2 ? resList.slice(0, 2).join(',') + '...' : resList.join(',')
                  return (
                    <Tooltip title={value.join(',')}>
                      <span>{title}</span>
                    </Tooltip>
                  )
                } else return null
              },
            },
            {title: '服务名称', dataIndex: 'chargeName'},
            {
              title: '服务地区',
              dataIndex: 'city',
              render: value =>
                value
                  ?.split(',')
                  ?.map(item => cityCode[item])
                  ?.join(','),
            },
            {title: '单位', dataIndex: 'chargeUnit'},
            {title: '收费类型', dataIndex: 'categoryName'},
            {title: '状态', dataIndex: 'enable', render: value => (value === 1 ? '使用' : '禁用')},
            {
              title: '操作',
              render: value => {
                const {enable, chargeId} = value
                return (
                  <Space>
                    {auth('002069002') && (
                      <Button
                        type={'link'}
                        style={{padding: 0}}
                        onClick={async () => {
                          // const {
                          //   response: {data},
                          // } = await stewardModel.stewardAdminServiceChargeDetail({chargeId})
                          // insertForm.setFieldsValue(data)
                          // setEditVisible(true)
                          history.push('/service-charges/detail', {chargeId})
                        }}
                        loading={detailLoading}
                      >
                        编辑
                      </Button>
                    )}
                    {auth('004069002') && (
                      <Button type={'link'} style={{padding: 0}} onClick={() => setDeleteId(chargeId)}>
                        删除
                      </Button>
                    )}
                    {auth('023069002') && (
                      <Button type={'link'} style={{padding: 0}} onClick={() => setUpdateEnable({chargeId, enable})}>
                        {enable === 1 ? '禁用' : '启用'}
                      </Button>
                    )}
                    <Button
                      type={'link'}
                      style={{padding: 0}}
                      onClick={() => {
                        warrantyForm.setFieldsValue({
                          chargeId,
                          warrantyTime: value.warrantyTime,
                          warrantyRange: value.warrantyRange,
                          warrantyExceptions: value.warrantyExceptions,
                          chargeName: value.chargeName,
                        })
                        setWarrantyVisible(true)
                      }}
                    >
                      保修信息
                    </Button>
                  </Space>
                )
              },
            },
          ]}
          pagination={getPaginationProps(form, total)}
        />
      </div>

      {/* 新增收费项目 */}
      <Modal
        open={insertVisible}
        centered
        width={650}
        title={'新增收费项目'}
        footer={null}
        onCancel={() => setInsertVisible(false)}
        afterClose={() => insertForm.resetFields()}
      >
        <Form
          form={insertForm}
          labelCol={{flex: '110px'}}
          validateTrigger={['onBlur', 'onChange']}
          initialValues={{chargeType: 'steward', mustMaterial: 0}}
          onFinish={async () => {
            const formValues = insertForm.getFieldsValue(true)

            const targetKeys = formValues.targetKeys
            for (let i = 0; i < targetKeys.length; i++) {
              if (!formValues[targetKeys[i]]?.cityCode) {
                return message.error('请填写未编辑的项！')
              }
            }

            const detailList = []
            for (let i = 0; i < targetKeys.length; i++) {
              const newItem = chargeDetailToList(formValues[targetKeys[i]]) ?? []
              detailList.push(...newItem)
            }

            // console.log('detailList', detailList)
            // console.log('formValues', formValues)

            await stewardModel.stewardAdminServiceChargeInsert({...formValues, detailList})
            setInsertVisible(false)

            form.submit() // 查询的表单
            message.success('操作成功！')
          }}
          onReset={() => setInsertVisible(false)}
        >
          <Form.Item name={'chargeType'} label={'服务'} rules={[{required: true, message: '请选择服务类型'}]}>
            <Radio.Group
              onChange={() =>
                insertForm.resetFields(['chargeName', 'categoryId', 'chargeUnit', 'detailList', 'remark'])
              }
            >
              <Radio value={'steward'}>管家</Radio>
              <Radio value={'clean'}>保洁</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item name={'chargeName'} label={'名称'} rules={[{required: true}]}>
            <Input placeholder={'请输入'} allowClear />
          </Form.Item>
          <Form.Item noStyle shouldUpdate>
            {form1 => (
              <Form.Item name={'categoryId'} label={'类型'} rules={[{required: true}]}>
                <Select
                  placeholder={'请选择'}
                  onChange={(_, option) => {
                    // @ts-ignore
                    const {children} = option ?? {}
                    insertForm.setFieldsValue({categoryName: children})
                  }}
                  allowClear
                >
                  {serviceChargeCategoryArr.map(item => (
                    <Option
                      key={item.id}
                      value={item.id}
                      disabled={item.id !== '1' && form1.getFieldValue('chargeType') === 'clean'}
                    >
                      {item.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            )}
          </Form.Item>

          <Form.Item
            name={'mustMaterial'}
            label={'必须使用耗材'}
            valuePropName={'checked'}
            rules={[{required: true}]}
            getValueProps={value => ({checked: value === 1})}
            getValueFromEvent={event => (event ? 1 : 0)}
          >
            <Switch />
          </Form.Item>
          <Form.Item name={'chargeUnit'} label={'单位'} rules={[{required: true}]}>
            <Input placeholder={'请输入'} allowClear />
          </Form.Item>

          <Form.Item noStyle shouldUpdate>
            {form1 => {
              const targetKeys = form1.getFieldValue('targetKeys') ?? []
              const targetSelectedKeys = form1.getFieldValue('targetSelectedKeys') ?? []
              return (
                <Form.Item
                  name={'targetKeys'}
                  label={'服务地区'}
                  rules={[{required: true, type: 'array', message: '请选择服务地区'}]}
                >
                  <Transfer
                    showSearch
                    dataSource={cityCodeList.map(item => ({key: item.id, title: item.label}))}
                    render={item => item.title}
                    targetKeys={targetKeys}
                    titles={[
                      null,
                      <Button
                        key={'批量编辑'}
                        type={'link'}
                        style={{padding: 0}}
                        onClick={() => {
                          if (targetSelectedKeys.length === 0) return message.warning('请选择批量编辑的项')
                          setAddService({
                            isOpen: true,
                            opt: 'batch',
                            updateValue: {} as ServiceChargeDetailVO,
                            cityCodeList: targetSelectedKeys,
                          })
                        }}
                      >
                        批量编辑
                      </Button>,
                    ]}
                    locale={{itemUnit: '项', itemsUnit: '项', searchPlaceholder: '请输入搜索内容'}}
                    onChange={targetKeys => {
                      form1.setFieldsValue({targetKeys})
                    }}
                    onSelectChange={(_, targetSelectedKeys) =>
                      form1.setFieldValue('targetSelectedKeys', targetSelectedKeys)
                    }
                  >
                    {({filteredItems, direction, selectedKeys, onItemSelect}) => {
                      const columns =
                        direction === 'left'
                          ? [{title: '城市', dataIndex: 'title'}]
                          : [
                              {title: '城市', dataIndex: 'title'},
                              {
                                title: '状态',
                                dataIndex: 'key',
                                render: value => {
                                  const charge = form1.getFieldValue(value)
                                  return charge?.cityCode ? '已编辑' : '未编辑'
                                },
                              },
                              {
                                title: '操作',
                                dataIndex: 'key',
                                render: value => (
                                  <Button
                                    type={'link'}
                                    style={{padding: 0}}
                                    onClick={() => {
                                      const charges =
                                        form1.getFieldValue(value) ?? ({cityCode: value} as ServiceChargeDetailVO)

                                      setAddService({isOpen: true, opt: 'insert', updateValue: charges})
                                    }}
                                  >
                                    编辑
                                  </Button>
                                ),
                              },
                            ]
                      return (
                        <Table
                          rowKey={'key'}
                          size={'small'}
                          columns={columns}
                          dataSource={filteredItems}
                          rowSelection={{
                            hideSelectAll: true,
                            selectedRowKeys: selectedKeys,
                            onSelect: ({key}, selected) => onItemSelect(key, selected),
                          }}
                          pagination={{pageSize: 5}}
                        />
                      )
                    }}
                  </Transfer>
                </Form.Item>
              )
            }}
          </Form.Item>

          <Form.Item name={'remark'} label={'备注'}>
            <Input.TextArea allowClear placeholder={'请输入'} />
          </Form.Item>
          <Row justify={'end'}>
            <Space>
              <Button htmlType={'reset'}>取消</Button>
              <Button type={'primary'} htmlType={'submit'} loading={insertLoading}>
                确认
              </Button>
            </Space>
          </Row>
        </Form>
      </Modal>

      <ServiceChargeUpdateService
        isOpen={addService.isOpen}
        updateValue={addService.updateValue}
        onOk={values => {
          if (addService.opt === 'insert') {
            const cityCode = values.cityCode
            insertForm.setFieldsValue({[cityCode]: values})
          }

          if (addService.opt === 'batch') {
            addService.cityCodeList?.forEach(item => {
              insertForm.setFieldsValue({[item]: {...values, cityCode: item}})
            })
          }
          setAddService({isOpen: false, opt: null, updateValue: null, cityCodeList: []})
        }}
        onCancel={() => setAddService({isOpen: false, opt: null, updateValue: null, cityCodeList: []})}
      />

      {/* 删除 */}
      <Modal open={!!deleteId} title={'提示'} centered footer={null} onCancel={() => setDeleteId(null)}>
        您确认要删除？
        <Row justify={'end'} style={{marginTop: '24px'}}>
          <Space>
            <Button onClick={() => setDeleteId(null)}>取消</Button>
            <Button
              type={'primary'}
              onClick={async () => {
                await stewardModel.stewardAdminServiceChargeDelete({chargeId: deleteId})
                setDeleteId(null)
                message.success('操作成功！')
                form.submit()
              }}
              loading={deleteLoading}
            >
              确定
            </Button>
          </Space>
        </Row>
      </Modal>

      {/* 启用/禁用 */}
      <Modal
        open={!!updateEnable.chargeId}
        title={'提示'}
        centered
        footer={null}
        onCancel={() => setUpdateEnable({chargeId: null, enable: null})}
      >
        {`您确认要${updateEnable.enable === 1 ? '禁用' : '启用'}？`}
        <Row justify={'end'} style={{marginTop: '24px'}}>
          <Space>
            <Button onClick={() => setUpdateEnable({chargeId: null, enable: null})}>取消</Button>
            <Button
              type={'primary'}
              onClick={async () => {
                await stewardModel.stewardAdminServiceChargeUpdateEnable({
                  chargeId: updateEnable.chargeId,
                  enable: updateEnable.enable === 1 ? 0 : 1,
                })
                setUpdateEnable({chargeId: null, enable: null})
                form.submit()
                message.success('操作成功！')
              }}
              loading={enableLoading}
            >
              确定
            </Button>
          </Space>
        </Row>
      </Modal>

      {/* 保修信息 */}
      <Modal
        open={warrantyVisible}
        title={'编辑'}
        centered
        footer={null}
        onCancel={() => setWarrantyVisible(false)}
        afterClose={() => warrantyForm.resetFields()}
        destroyOnClose
      >
        <Form
          form={warrantyForm}
          onReset={() => setWarrantyVisible(false)}
          onFinish={async () => {
            const formValues = warrantyForm.getFieldsValue(true)
            await stewardModel.stewardAdminServiceChargeUpdateWarranty(formValues)
            message.success('操作成功！')
            form.submit()
            setWarrantyVisible(false)
          }}
        >
          <Form.Item label={'处理内容'} name={'chargeName'}>
            <Input readOnly bordered={false} />
          </Form.Item>
          <Form.Item label={'保修期限'} name={'warrantyTime'} required>
            <Radio.Group>
              <Radio value={0}>无保修</Radio>
              <Radio value={30}>30天</Radio>
              <Radio value={180}>180天</Radio>
              <Radio value={365}>365天</Radio>
            </Radio.Group>
          </Form.Item>
          <Row>
            <Col span={18}>
              <Form.Item
                label={'保修范围'}
                name={'warrantyRange'}
                rules={[{required: true, message: '请输入保修说明'}]}
              >
                <Input.TextArea placeholder={'请输入'} allowClear maxLength={200} autoSize={{minRows: 3}} />
              </Form.Item>
            </Col>
            <Col span={18}>
              <Form.Item
                label={'免责条款'}
                name={'warrantyExceptions'}
                rules={[{required: true, message: '请输入免责条款'}]}
              >
                <Input.TextArea placeholder={'请输入'} allowClear maxLength={200} autoSize={{minRows: 3}} />
              </Form.Item>
            </Col>
          </Row>

          <Row justify={'end'}>
            <Space>
              <Button htmlType={'reset'}>取消</Button>
              <Button type={'primary'} htmlType={'submit'}>
                保存
              </Button>
            </Space>
          </Row>
        </Form>
      </Modal>
    </Page>
  )
}
