import {wss} from '@/common/config'
import {HEADER_CHANNEL, HEADER_PLATFORM} from '@/common/constants'

export interface PushProps {
  host: string
  channel: string
  platform: string
  // device: string
}

export interface PushMessage<T = any> {
  msgType: string
  onMessage: (data: T) => void
}

export class Push {
  private auth: string
  private readonly channel: string
  private readonly platform: string
  private readonly host: string
  // private readonly device: string

  private ws: WebSocket
  private subscriptions: PushMessage[] = []

  constructor(props: PushProps) {
    this.channel = props.channel
    this.platform = props.platform
    this.host = props.host
    // this.device = props.device
  }

  private login = () => {
    this.publish({
      msgType: '00000001',
      data: {
        'nyg8-auth': this.auth,
        'nyg8-channel': this.channel,
        'nyg8-platform': this.platform,
        // 'nyg8-device': this.device,
      },
    })

    this.startPing()
  }

  private onClose = (ev: CloseEvent) => {
    if (ev.code !== 200) {
      this.reset()
    }
  }

  private onError = () => {
    this.reset()
  }

  private reset = () => {
    this.destroy()
    this.init()
  }

  private onMessage = (ev: MessageEvent) => {
    try {
      const response = JSON.parse(ev.data)
      this.subscriptions.forEach(value => {
        if (response.msgType === value.msgType) {
          value.onMessage?.(response.data)
        }
      })
    } catch (e) {
      console.log('push onMessage:', e)
    }
  }

  private timeoutTimer: any
  private pingTimer: any
  private startPing = () => {
    return new Promise<void>((resolve, reject) => {
      clearTimeout(this.timeoutTimer)
      if ([this.ws.CLOSED, this.ws.CLOSING].includes(this.ws.readyState)) {
        reject('连接已关闭')
        return
      }

      this.publish({msgType: '00000002'})
      this.timeoutTimer = setTimeout(() => reject('ping超时'), 1000 * 5)
      const unsubscribe = this.subscribe({
        msgType: '00000003',
        onMessage: () => {
          unsubscribe()
          clearTimeout(this.timeoutTimer)
          this.pingTimer = setTimeout(this.startPing, 1000 * 20)
          resolve()
        },
      })
    })
  }

  private stopPing = () => {
    clearTimeout(this.timeoutTimer)
    clearTimeout(this.pingTimer)
  }

  public destroy = () => {
    if (this.ws) {
      this.ws.removeEventListener('open', this.login)
      this.ws.removeEventListener('message', this.onMessage)
      this.ws.removeEventListener('close', this.onClose)
      this.ws.removeEventListener('error', this.onError)
      this.ws.close(1001, '主动关闭')
      this.stopPing()

      this.ws = null
    }
  }

  public init(props = {} as {auth: string}) {
    if (!this.ws) {
      this.auth = props.auth ?? this.auth
      this.ws = new WebSocket(this.host)
      this.ws.addEventListener('open', this.login)
      this.ws.addEventListener('message', this.onMessage)
      this.ws.addEventListener('close', this.onClose)
      this.ws.addEventListener('error', this.onError)
    }
  }

  public publish = (value: Record<string, any>) => {
    this.ws.send(JSON.stringify(value))
  }

  public subscribe<T = any>(value: PushMessage<T>) {
    this.subscriptions.push(value)
    return () => {
      this.subscriptions = this.subscriptions.filter(item => item !== value)
    }
  }

  public removeAllSubscriptions = () => {
    this.subscriptions = []
  }
}

// 先创建对象，接收各种监听，等连接成功后再触发回调
export const push = new Push({
  host: wss,
  channel: HEADER_CHANNEL.ADMIN,
  platform: HEADER_PLATFORM.WEB,
  // device: `${model}/${systemVersion}`,
})
