import React, {useCallback, useEffect, useState} from 'react'
import {Button, Col, Descriptions, Form, Input, InputNumber, message, Modal, Row, Select, Space, Table} from 'antd'
import {Page} from '@/components/Page'
import {Breadcrumb} from '@/components/Breadcrumb'
import commonStyles from '@/assets/styles/common.module.less'
import {CloseCircleTwoTone, PlusCircleTwoTone} from '@ant-design/icons'
import {storageModel} from '@/store/models/Storage'
import BackButton from '@/components/BackButton'

const {Option} = Select

const operateCode = {
  add: '新增模版',
  edit: '编辑模版',
}

export default function PurchaseTemplateSetting() {
  const [form] = Form.useForm()
  const [tForm] = Form.useForm()

  const [visible, setVisible] = useState<{operate: 'add' | 'edit' | 'check' | 'delete'; templateId?: string}>({
    operate: null,
  })

  const [dataList, setDataList] = useState<PurchaseTemplateVO[]>([])
  const [templateList, setTemplateList] = useState<PurchaseTemplateVO[]>([])

  const queryPurchaseTemplateList = useCallback(() => {
    storageModel.purchaseTemplateList().then(({response: {data}}) => {
      setDataList(data)
      setTemplateList(data)
    })
  }, [])

  useEffect(() => {
    queryPurchaseTemplateList()
  }, [queryPurchaseTemplateList])

  // 耗材列表
  const [materialList, setMaterialList] = useState<StewardMaterialListVO[]>([])
  // 耗材列表
  function onMaterialSearch(value) {
    storageModel
      .stewardWarehouseMaterialList({pageable: false, pageNum: 1, pageSize: 100, name: value})
      .then(({response: {data}}) => setMaterialList(data))
  }

  const materialLoading = storageModel.stewardWarehouseMaterialList.useLoading()
  const dataListLoading = storageModel.purchaseTemplateList.useLoading()
  const deleteLoading = storageModel.purchaseTemplateDelete.useLoading()
  const saveLoading = storageModel.purchaseTemplateSave.useLoading()

  return (
    <Page
      breadcrumb={
        <Breadcrumb
          right={
            <Button type={'primary'} onClick={() => setVisible({operate: 'add'})}>
              新增模版
            </Button>
          }
        />
      }
    >
      <BackButton />
      <div className={commonStyles.filterSection}>
        <Form
          form={form}
          onReset={() => {
            form.resetFields()
            setTemplateList(dataList)
          }}
          onFinish={() => {
            const templateName = form.getFieldValue('templateName')
            if (templateName) {
              setTemplateList(dataList.filter(item => item.templateName.indexOf(templateName) !== -1))
            } else setTemplateList(dataList)
          }}
        >
          <Row gutter={30}>
            <Col span={7}>
              <Form.Item name={'templateName'} label={'模版名称'}>
                <Input placeholder={'请输入模版名称'} allowClear />
              </Form.Item>
            </Col>
          </Row>
          <Row justify={'end'}>
            <Space>
              <Button type={'primary'} htmlType={'submit'}>
                查询
              </Button>
              <Button htmlType={'reset'}>重置</Button>
            </Space>
          </Row>
        </Form>
      </div>

      <div className={commonStyles.tableSection}>
        <Table
          rowKey={'templateId'}
          loading={dataListLoading}
          columns={[
            {title: '模版名称', dataIndex: 'templateName', key: 'templateName'},
            {title: '采购项总数', dataIndex: 'itemNum', key: 'itemNum'},
            {title: '录入时间', dataIndex: 'updateTime', key: 'updateTime'},
            {
              title: '操作',
              render: values => {
                const {templateId} = values
                return (
                  <Space>
                    <Button
                      type={'link'}
                      style={{padding: 0}}
                      onClick={() => {
                        setVisible({operate: 'check'})
                        tForm.setFieldsValue(values)
                      }}
                    >
                      查看
                    </Button>
                    <Button
                      type={'link'}
                      style={{padding: 0}}
                      onClick={() => {
                        setMaterialList(values?.itemList)
                        setVisible({operate: 'edit'})
                        tForm.setFieldsValue(values)
                      }}
                    >
                      编辑
                    </Button>
                    <Button
                      type={'link'}
                      style={{padding: 0}}
                      onClick={() => setVisible({operate: 'delete', templateId})}
                    >
                      删除
                    </Button>
                  </Space>
                )
              },
            },
          ]}
          scroll={{x: 'max-content'}}
          dataSource={templateList}
          pagination={{total: templateList.length, position: ['bottomCenter']}}
        />
      </div>

      <Modal
        visible={['add', 'edit'].includes(visible.operate)}
        title={operateCode[visible.operate]}
        centered
        footer={null}
        onCancel={() => setVisible({operate: null})}
        afterClose={() => tForm.resetFields()}
      >
        <Form
          form={tForm}
          labelCol={{flex: '80px'}}
          labelAlign={'left'}
          initialValues={{itemList: [{}]}}
          onFinish={async () => {
            const formValues = tForm.getFieldsValue(true)
            // console.log('formValues', formValues)
            await storageModel.purchaseTemplateSave(formValues)
            message.success('操作成功！')
            setVisible({operate: null})
            queryPurchaseTemplateList()
          }}
          onReset={() => setVisible({operate: null})}
        >
          {visible.operate === 'edit' && (
            <>
              <Form.Item name={'templateId'} label={'模板编号'}>
                <Input bordered={false} readOnly />
              </Form.Item>
              <Form.Item name={'insertTime'} label={'录入时间'}>
                <Input bordered={false} readOnly />
              </Form.Item>
            </>
          )}

          <Form.Item label={'模版名称'} style={{marginBottom: 0}}>
            <Space align={'center'} size={0}>
              <div style={{width: '22px'}} />
              <Form.Item name={'templateName'} rules={[{required: true, message: '请输入模版名称'}]}>
                <Input placeholder={'请输入模版名称'} allowClear style={{width: '240px'}} />
              </Form.Item>
            </Space>
          </Form.Item>

          <Form.Item label={'采购物品'}>
            <Form.List name='itemList'>
              {(fields, {add, remove}) => {
                return (
                  <div>
                    {fields.map((field, index) => (
                      <Space key={field.key} direction={'vertical'}>
                        <Space align='start'>
                          <PlusCircleTwoTone
                            twoToneColor='#00B7AE'
                            onClick={() => add()}
                            style={{marginTop: '9px', visibility: index === 0 ? 'visible' : 'hidden'}}
                          />
                          <Form.Item
                            {...field}
                            name={[field.name, 'materialId']}
                            fieldKey={[field.fieldKey, 'materialId']}
                            rules={[{required: true, message: '请选择采购物品'}]}
                            shouldUpdate
                            style={{width: '240px'}}
                          >
                            <Select
                              placeholder='请选择物品'
                              showSearch
                              filterOption={false}
                              onSearch={onMaterialSearch}
                              loading={materialLoading}
                              allowClear
                              onChange={value => {
                                const option = materialList.find(item => item.materialId === value)
                                tForm.setFields([
                                  {name: ['itemList', index, 'brand'], value: option?.brand},
                                  {name: ['itemList', index, 'materialId'], value: option?.materialId},
                                  {name: ['itemList', index, 'name'], value: option?.name},
                                  {name: ['itemList', index, 'sku'], value: option?.sku},
                                  {name: ['itemList', index, 'specification'], value: option?.specification},
                                  {name: ['itemList', index, 'unit'], value: option?.unit},
                                ])
                              }}
                            >
                              {materialList?.map(item => (
                                <Option value={item.materialId} key={item.materialId}>
                                  {`${item.name}${item.brand ? `-${item.brand}` : ''}${
                                    item.unit ? `-${item.unit}` : ''
                                  }${item.specification ? `-${item.specification}` : ''}`}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                          <Form.Item
                            {...field}
                            name={[field.name, 'num']}
                            fieldKey={[field.fieldKey, 'num']}
                            rules={[{required: true, message: '请输入数量'}]}
                            style={{width: '100px'}}
                          >
                            <InputNumber placeholder='数量' min={1} style={{width: '100%'}} />
                          </Form.Item>
                          {tForm.getFieldValue('itemList').length > 1 && (
                            <CloseCircleTwoTone
                              style={{marginTop: '9px'}}
                              twoToneColor='#A8A7AA'
                              onClick={() => {
                                remove(field.name)
                              }}
                            />
                          )}
                        </Space>
                      </Space>
                    ))}
                  </div>
                )
              }}
            </Form.List>
          </Form.Item>

          <Row justify={'end'}>
            <Space>
              <Button htmlType={'reset'}>取消</Button>
              <Button type={'primary'} htmlType={'submit'} loading={saveLoading}>
                确定
              </Button>
            </Space>
          </Row>
        </Form>
      </Modal>

      <Modal
        visible={visible.operate === 'delete'}
        title={'提示'}
        centered
        footer={null}
        onCancel={() => setVisible({operate: null})}
      >
        <span>是否确认删除当前模板？</span>
        <Row justify={'end'} style={{marginTop: '24px'}}>
          <Space>
            <Button onClick={() => setVisible({operate: null})}>取消</Button>
            <Button
              type={'primary'}
              loading={deleteLoading}
              onClick={async () => {
                await storageModel.purchaseTemplateDelete({templateId: visible.templateId})
                message.success('操作成功！')
                setVisible({operate: null})
                queryPurchaseTemplateList()
              }}
            >
              确认
            </Button>
          </Space>
        </Row>
      </Modal>

      <Modal
        visible={visible.operate === 'check'}
        title={'模板详情'}
        centered
        footer={null}
        onCancel={() => setVisible({operate: null})}
        afterClose={() => tForm.resetFields()}
      >
        <Space direction={'vertical'}>
          <Descriptions column={1}>
            <Descriptions.Item label='模版编号'>{tForm.getFieldValue('templateId')}</Descriptions.Item>
            <Descriptions.Item label='模版名称'>{tForm.getFieldValue('templateName')}</Descriptions.Item>
            <Descriptions.Item label='采购项总数'>{tForm.getFieldValue('itemNum')}</Descriptions.Item>
            <Descriptions.Item label='录入时间'>{tForm.getFieldValue('insertTime')}</Descriptions.Item>
            <Descriptions.Item label='采购物品'> </Descriptions.Item>
          </Descriptions>
          <Table
            rowKey={'materialId'}
            dataSource={tForm.getFieldValue('itemList') ?? []}
            columns={[
              {title: '名称', dataIndex: 'name', key: 'name'},
              {title: '采购数量', dataIndex: 'num', key: 'num'},
            ]}
            pagination={{pageSize: 5}}
          />
        </Space>
      </Modal>
    </Page>
  )
}
