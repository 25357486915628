export enum OrgRoleId {
  cus_manager = 'cus_manager', // 销售
  steward = 'steward', // 管家
}

/**
 * 应用类型
 */
export const HEADER_CHANNEL = {
  LESSOR: 'lessor', // 房东
  LESSEE: 'lessee', // 租客
  STEWARD: 'steward', // 管家
  ADMIN: 'admin', // 后台
}

/**
 * 平台
 */
export const HEADER_PLATFORM = {
  IOS: 'ios', // iOS
  ANDROID: 'android', // 安卓
  MOBILE: 'mobile', // 移动设备
  WEB: 'web', // 网页
  MINI: 'mini', // 微信小程序
}

export const APP_ID = {
  NYG: 'NYG', // 牛油果
  GUANYU: 'GUANYU', // 龙湖冠寓
  BEIKE: 'BEIKE', // 贝壳
}

export const PRIVATE_CODE = {
  '0': '接通后主动挂机',
  '8013': '无效AX模式呼叫（存在AX绑定，但无临时被叫）',
  '8014': '无效AXB/X模式呼叫（绑定关系不存在，号码格式错误等）',
  '8016': 'AX/AXB模式呼叫方向不允许',
  '8019': '回呼记录不存在\n' + 'AXE模式，A呼叫X场景\n' + 'AX模式，A回呼X场景',
  '8022': '绑定关系不存在或已过期（AXE模式，B呼X场景）',
  '8030': 'AXYB模式绑定关系不存在',
  '8031': 'AXYB模式呼叫过程中分配Y号码失败',
  '7501': '被叫振铃前主叫挂机',
  '7502': '被叫振铃后主叫挂机',
  '7516': '呼叫转接前主叫挂机。例如AXE模式主叫输入分机号前主动挂机',
  '8032': 'AXYB模式呼叫过程中原号码拨打自己绑定的隐私号导致呼叫失败',
  '8033': '收到分机号为空',
  '8025': '呼叫的X号码不存在（AXE模式）',
  '7503': '被叫拒接',
  '7504':
    '呼叫被拒绝，可能的原因如下：\n' +
    '被叫拒接\n' +
    '被叫开通免打扰、黑名单一类的呼叫限制业务\n' +
    '被叫系统拦截，可能原因是安装了拦截软件，或者设置了黑名单',
  '8035': '隐私保护呼叫平台接续超时，超时时间为呼叫转接后90秒左右',
  '8100': '被叫号码不存在',
  '8101': '被叫振铃后无应答，运营商网络原因，超时时间由运营商决定',
  '8102': '被叫用户正忙（被叫正在通话、振铃等）',
  '8103': '被叫信号异常，导致运营商未接续到被叫',
  '8104': '被叫已关机（被叫处于关机、飞行模式、无网络等状态）',
  '8105': '被叫已停机',
  '7100': '应用信息不存在',
  '7101': '应用未申请隐私号码资源',
  '7105': '业务类型不匹配',
  '7106': '应用无隐私保护通话能力',
  '7108': '用户状态已冻结',
  '7507': '无放音文件',
  '8006': 'X模式放音文件不存在',
  '8010': '超过通话最大时长挂机',
  '7001': '开发者呼叫频次管控',
  '7002': '应用呼叫频次管控',
  '7003': '主显号码呼叫频次管控',
  '7004': '被叫黑名单呼叫管控',
  '7005': '主叫黑名单管控',
  '7107': '安全管控提示音放音失败',
  '7109': '租户语音端口不足',
  '7500': '呼叫前缀被限制',
  '8018': '业务无权限（包含：不存在AX绑定关系）',
  '8023': '不允许固话号码作为主叫号码',
  '8024': '不允许使用显示真实主叫号码模式',
  '8042': '国际呼入限制',
  '8043': '国际呼出限制',
  '8044': '通话时间异常管控',
  '8046': '接通率异常管控',
  '8047': '主叫呼叫频次管控',
  '8048': '被叫呼叫频次管控',
  '8049': '业务号码呼叫频次管控',
  '8050': '呼叫时段限制',
  '8051': '子企业被暂停',
  '8055': 'X号码不可用，呼叫和短信功能不可用',
  '8056': '拨测号码管控',
  '8057': '呼叫方向管控',
  '522': '平台语音端口不足',
  '8007': '最后一分钟放音失败',
  '8008': '给用户放音收号失败',
  '8015': '给用户录音失败',
  '8041': '给用户录音失败',
  '7102': '其他异常行为',
  '8011': '其他异常行为',
  '8020': '其他异常行为',
  '8040': '其他异常行为',
  '8139': '其他异常行为',
  '7505': '主叫侧网络异常',
  '7506': '被叫侧网络异常（例如绑定的号码格式错误）',
  '7508': '路由失败',
}
