import React, {useCallback} from 'react'
import {Col, Form, Input, message, Modal, Radio, Row, Tabs} from 'antd'
import CleanImagePreview from '@/pages/cleanManagement/components/CleanImagePreview'
import CleanVideoPreview from '@/pages/cleanManagement/components/CleanVideoPreview'
import {cleanModel} from '@/store/models/Clean'

export function useCreateOnlineQa() {
  const [form] = Form.useForm()
  return useCallback(
    ({
      payloadList,
      orderId,
      onSuccess,
    }: {
      payloadList: CleanOrderAttachPayloadDTO[]
      orderId: string
      onSuccess?: () => void
    }) => {
      payloadList = payloadList || []
      console.log('payloadList', payloadList)
      const isSub = payloadList.every(item => item.subUpload)
      form.setFieldsValue({_config: payloadList})

      Modal.confirm({
        title: '线上质检',
        icon: null,
        closable: true,
        centered: true,
        width: '75%',
        bodyStyle: {height: '600px', overflow: 'scroll'},
        onOk: async () => {
          await form.validateFields()
          const _config = form.getFieldValue('_config')
          if (_config.some(item => item.subList.some(item => item.onlineQaResult === -1))) {
            message.warning('请先完成线上质检')
            await Promise.reject('')
          } else form.submit()
        },
        content: (
          <Form
            form={form}
            onFinish={async () => {
              const formValues = form.getFieldsValue(true)
              // console.log('formValues', formValues)
              await cleanModel.cleanOrderSubmitOnlineQa({attachList: formValues._config, orderId, saveType: 3})
              message.success('提交成功！')
              onSuccess?.()
            }}
          >
            <Form.Item noStyle shouldUpdate>
              {form1 => {
                const renderList = form1.getFieldValue('_config')
                return (
                  <Tabs>
                    {renderList.map((item, index) => {
                      if (isSub) {
                        return (
                          <Tabs.TabPane tab={item.name} key={item.name}>
                            <Row>
                              <Col span={10} offset={2}>
                                <span style={{fontWeight: 500, color: 'rgba(0,0,0,0.85)'}}>保洁前照片/视频</span>
                              </Col>
                              <Col span={10}>
                                <span style={{fontWeight: 500, color: 'rgba(0,0,0,0.85)'}}>保洁后照片/视频</span>
                              </Col>
                            </Row>
                            {item.subList?.map((value, idx) => {
                              const mediaList = value.mediaList
                              const onlineQaResult = value.onlineQaResult
                              console.log('mediaList', mediaList)
                              return (
                                <Row key={idx} style={{marginTop: '15px'}}>
                                  <Col span={2}>{value.name}</Col>
                                  <Col span={10} style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
                                    <CleanImagePreview
                                      imgList={mediaList?.find(ele => ele.saveType === 1)?.imageList}
                                    />
                                    <CleanVideoPreview
                                      videoList={mediaList?.find(ele => ele.saveType === 1)?.videoList}
                                    />
                                  </Col>
                                  <Col span={10} style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
                                    <CleanImagePreview
                                      imgList={mediaList?.find(ele => ele.saveType === 2)?.imageList}
                                    />
                                    <CleanVideoPreview
                                      videoList={mediaList?.find(ele => ele.saveType === 2)?.videoList}
                                    />
                                  </Col>
                                  <Col span={2}>
                                    <Form.Item
                                      name={['_config', index, 'subList', idx, 'onlineQaResult']}
                                      fieldKey={['_config', index, 'subList', idx, 'onlineQaResult']}
                                      rules={[{required: true, message: '请选择', type: 'number'}]}
                                    >
                                      <Radio.Group>
                                        <Radio value={1}>合格</Radio>
                                        <Radio value={0}>不合格</Radio>
                                      </Radio.Group>
                                    </Form.Item>
                                  </Col>
                                  {onlineQaResult === 0 && (
                                    <Col offset={2} span={10}>
                                      <Form.Item
                                        name={['_config', index, 'subList', idx, 'onlineQaNotes']}
                                        fieldKey={['_config', index, 'subList', idx, 'onlineQaNotes']}
                                        rules={[{required: true, message: '请输入不合格原因'}]}
                                      >
                                        <Input.TextArea placeholder={'请输入不合格原因'} maxLength={200} />
                                      </Form.Item>
                                    </Col>
                                  )}
                                </Row>
                              )
                            })}
                          </Tabs.TabPane>
                        )
                      } else {
                        const isSign = item.name === '签到表'
                        return (
                          <Tabs.TabPane tab={item.name} key={item.name}>
                            {!isSign && (
                              <Row>
                                <Col span={10}>
                                  <span style={{fontWeight: 500, color: 'rgba(0,0,0,0.85)'}}>保洁前视频</span>
                                </Col>
                                <Col span={12}>
                                  <span style={{fontWeight: 500, color: 'rgba(0,0,0,0.85)'}}>保洁后视频</span>
                                </Col>
                              </Row>
                            )}
                            <Row style={{marginTop: '15px'}}>
                              <Col span={10} style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
                                {/*{isSign && (*/}
                                {/*  <CleanImagePreview*/}
                                {/*    imgList={item.mediaList?.find(ele => ele.saveType === 1)?.imageList}*/}
                                {/*  />*/}
                                {/*)}*/}
                                <CleanImagePreview
                                  imgList={item.mediaList?.find(ele => ele.saveType === 1)?.imageList}
                                />
                                <CleanVideoPreview
                                  videoList={item.mediaList?.find(ele => ele.saveType === 1)?.videoList}
                                />
                              </Col>
                              <Col span={12} style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
                                {/*{isSign && (*/}
                                {/*  <CleanImagePreview*/}
                                {/*    imgList={item.mediaList?.find(ele => ele.saveType === 2)?.imageList}*/}
                                {/*  />*/}
                                {/*)}*/}
                                <CleanImagePreview
                                  imgList={item.mediaList?.find(ele => ele.saveType === 2)?.imageList}
                                />
                                <CleanVideoPreview
                                  videoList={item.mediaList?.find(ele => ele.saveType === 2)?.videoList}
                                />
                              </Col>
                            </Row>
                            <Form.List name={['_config', index, 'subList']}>
                              {fields =>
                                fields.map((field, idx) => {
                                  const subItem = form1.getFieldValue(['_config', index, 'subList', idx])
                                  const {name, onlineQaResult} = subItem ?? {}
                                  return (
                                    <div key={idx}>
                                      <Form.Item
                                        label={name}
                                        name={[idx, 'onlineQaResult']}
                                        fieldKey={[idx, 'onlineQaResult']}
                                        rules={[{required: true, message: '请选择', type: 'number'}]}
                                      >
                                        <Radio.Group>
                                          <Radio value={1}>合格</Radio>
                                          <Radio value={0}>不合格</Radio>
                                        </Radio.Group>
                                      </Form.Item>
                                      {onlineQaResult === 0 && (
                                        <Form.Item
                                          name={[idx, 'onlineQaNotes']}
                                          fieldKey={[idx, 'onlineQaNotes']}
                                          rules={[{required: true, message: '请输入不合格原因'}]}
                                        >
                                          <Input.TextArea placeholder={'请输入不合格原因'} maxLength={200} />
                                        </Form.Item>
                                      )}
                                    </div>
                                  )
                                })
                              }
                            </Form.List>
                          </Tabs.TabPane>
                        )
                      }
                    })}
                  </Tabs>
                )
              }}
            </Form.Item>
          </Form>
        ),
      })
    },
    [form]
  )
}
