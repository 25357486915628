import {useCallback, useEffect, useRef} from 'react'

/**
 * 防抖 hook
 * @example
 * ```ts
 * const fn = useDebounce(() => {}, 1000)
 * ```
 */
export function useDebounce<T extends Function>(fn: T, delay = 1000, dep = []): T {
  const ref = useRef({fn, timer: null})
  useEffect(() => {
    ref.current.fn = fn
  }, [fn])

  useEffect(() => {
    return () => {
      if (ref.current?.timer) {
        clearTimeout(ref.current.timer)
      }
    }
  }, [])

  return (useCallback(function f(this: any, ...args) {
    if (ref.current.timer) {
      clearTimeout(ref.current.timer)
    }
    ref.current.timer = setTimeout(() => {
      ref.current.fn.apply(this, args)
    }, delay)
  }, dep) as unknown) as T
}
