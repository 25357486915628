import React, {useCallback, useEffect, useRef, useState} from 'react'
import {stewardModel} from '@/store/models/Steward'
import {Button, Input, List, message, Modal, Space} from 'antd'
import {EditOutlined, DeleteOutlined, InfoCircleOutlined, CaretUpOutlined, CaretDownOutlined} from '@ant-design/icons'
import styles from './index.module.less'
import {useHistory} from 'react-router-dom'
import {Breadcrumb} from '@/components/Breadcrumb'
import commonStyles from '@/assets/styles/common.module.less'
import {Permission, usePermission} from '@/components/Permission'
import {Page} from '@/components/Page'

interface ServiceVO extends StewardServiceVO {
  active?: boolean
}

export default function ServiceType() {
  const [auth] = usePermission()
  // const inputRef = useRef<Input>(null)
  const inputRef = useRef<any>(null)
  const [inputVal, setInputVal] = useState<{[key: string]: any}>({})

  const [list, setList] = useState<ServiceVO[][]>([[], [], []])
  const history = useHistory()
  const [sortMode, setSortMode] = useState(false)
  const orderLoading = stewardModel.stewardAdminServiceListOrder.useLoading()

  const search = useCallback(
    (pid, index = 0) => {
      stewardModel.stewardServiceList({pid}, index).then(({response: {data}}) => {
        function getActiveId(newList: ServiceVO[], oldList: ServiceVO[]) {
          // return newList[0]?.id
          const oldActiveId = oldList?.find(item => item.active)?.id
          if (newList.some(item => item.id === oldActiveId)) return oldActiveId
          return newList[0]?.id
        }

        setList(prevState => {
          const activeId = getActiveId(data, prevState[index])
          return prevState.map((item, i) => {
            if (i === index) return data.map((item, i) => ({...item, active: item.id === activeId}))
            else if (i < index) return item
            else return []
          })
        })
        // 递归查询子项（不为最后一项）
        if (data.length && index !== list.length - 1) {
          const activeId = getActiveId(data, list[index])
          search(activeId, index + 1)
        }
      })
    },
    [list]
  )

  const toAddOrEdit = useCallback(
    (state: Partial<StewardServiceVO>) => {
      history.push('/steward/service/add', state)
    },
    [history]
  )

  function setActive(id, index) {
    setList(prevState =>
      prevState.map((item, i) =>
        i === index ? item.map(_item => ({..._item, active: id === _item.id})) : i > index ? [] : item
      )
    )
    if (index !== list.length - 1) {
      search(id, index + 1)
    }
  }

  async function insertOrUpdate(params: Partial<StewardServiceUpsetDTO>) {
    const index = params.level - 1
    await stewardModel.stewardServiceUpset(
      {
        permLessee: '1',
        permLessor: '1',
        ...params,
        name: params.name,
        level: params.level, // index + 1
      } as StewardServiceUpsetDTO,
      index
    )
    message.success('操作成功！')
    const pid = list[index - 1] ? list[index - 1].find(item => !!item.active)?.id : null
    // 如果是一级，pid 为 null，继续触发查询
    if (pid !== undefined) {
      search(pid, index)
    }
    setInputVal(prevState => ({...prevState, [index]: ''}))
  }

  useEffect(function () {
    search(null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const setOrder = useCallback((col: number, sourceIndex: number, targetIndex: number) => {
    setList(prevState =>
      prevState.map((item, index) =>
        index === col
          ? item.map((_item, i) =>
              i === sourceIndex ? item[targetIndex] : i === targetIndex ? item[sourceIndex] : _item
            )
          : item
      )
    )
  }, [])

  const renderList = useCallback(
    function (listVO: ServiceVO[], index = 0) {
      // const isSave = list.length - 1 !== index && !!list[Math.max(0, index - 1)]?.length
      // 是否显示保持按钮 index === 0 单独处理，第一项有保存按钮，或其他项须在前一项的列表不为空的情况下才有保存按钮
      const isSave = list.length - 1 !== index && (index === 0 || !!list[Math.max(0, index - 1)]?.length)
      // 是否显示添加服务按钮
      const isAdd = index === list.length - 1 && !!list[list.length - 2]?.length
      const saveLoading = stewardModel.stewardServiceUpset.useLoadings(index)
      const pid = list[index - 1] ? list[index - 1].find(item => item.active)?.id : null

      return (
        <List
          className={styles.listItem}
          loading={stewardModel.stewardServiceList.useLoadings(index)}
          bordered
          dataSource={listVO}
          renderItem={(listItem, i) => (
            <List.Item
              className={`${listItem.active && styles.itemActive}`}
              onClick={() => !listItem.active && setActive(listItem.id, index)}
              actions={[
                <Button
                  key='edit'
                  disabled={!auth('002022001')}
                  size={'small'}
                  type={'link'}
                  onClick={event => {
                    event.stopPropagation()
                    // 最后一项跳页面新增服务
                    if (list.length - 1 === index) {
                      toAddOrEdit(listItem)
                    } else {
                      Modal.confirm({
                        title: '修改名称',
                        icon: <InfoCircleOutlined />,
                        content: <Input ref={inputRef} defaultValue={listItem.name} allowClear />,
                        onOk() {
                          insertOrUpdate({
                            ...listItem,
                            name: inputRef.current.input.value,
                          })
                        },
                      })
                    }
                  }}
                >
                  <EditOutlined />
                </Button>,
                <Button
                  key='del'
                  disabled={!auth('004022001')}
                  size={'small'}
                  type={'link'}
                  onClick={event => {
                    event.stopPropagation()
                    Modal.confirm({
                      title: '提示',
                      icon: <InfoCircleOutlined />,
                      content: `确认是否要删除 "${listItem.name}" 及相关服务？`,
                      onOk() {
                        stewardModel.stewardServiceDelete(listItem.id).then(() => search(listItem.pid, index))
                      },
                    })
                  }}
                >
                  <DeleteOutlined />
                </Button>,
              ]}
            >
              <Space size={12}>
                {sortMode && (
                  <div className={styles.order}>
                    {i !== 0 && (
                      <CaretUpOutlined
                        className={styles.orderIcon}
                        onClick={event => {
                          event.stopPropagation()
                          setOrder(index, i, i - 1)
                        }}
                      />
                    )}
                    {listVO.length - 1 !== i && (
                      <CaretDownOutlined
                        className={styles.orderIcon}
                        onClick={event => {
                          event.stopPropagation()
                          setOrder(index, i, i + 1)
                        }}
                      />
                    )}
                  </div>
                )}
                <div>{listItem.name}</div>
              </Space>
            </List.Item>
          )}
          footer={
            <Permission code={'003022001'}>
              {isAdd ? (
                <div style={{textAlign: 'center'}}>
                  <Button
                    ghost
                    type={'primary'}
                    onClick={() =>
                      toAddOrEdit({
                        pid,
                        level: index + 1,
                        permLessee: '1',
                        permLessor: '1',
                      })
                    }
                  >
                    新增服务
                  </Button>
                </div>
              ) : isSave ? (
                <List.Item
                  style={{padding: 0}}
                  actions={[
                    <Button
                      key='save'
                      type={'primary'}
                      loading={saveLoading}
                      ghost
                      onClick={() => {
                        insertOrUpdate({
                          pid,
                          name: inputVal[index],
                          level: index + 1,
                        })
                      }}
                    >
                      保存
                    </Button>,
                  ]}
                >
                  <Input
                    value={inputVal[index]}
                    onChange={({target: {value}}) => setInputVal(prevState => ({...prevState, [index]: value}))}
                    placeholder={'请输入分类名'}
                    allowClear
                    style={{border: 'none', paddingLeft: 0}}
                  />
                </List.Item>
              ) : null}
            </Permission>
          }
        />
      )
    },
    [list, inputVal, sortMode, toAddOrEdit, insertOrUpdate, search, setOrder]
    // [list, inputVal, sortMode, setOrder]
  )

  return (
    <Page
      breadcrumb={
        <Breadcrumb
          path={['首页', '管家服务', '服务类型管理']}
          right={
            <Permission code={'012022001'}>
              {sortMode ? (
                <Space>
                  <Button
                    loading={orderLoading}
                    type={'primary'}
                    onClick={async () => {
                      await Promise.all(
                        list.map(item =>
                          stewardModel.stewardAdminServiceListOrder({serviceIdList: item.map(_item => _item.id)})
                        )
                      )
                      setSortMode(false)
                      message.success('保存成功！')
                    }}
                  >
                    保存
                  </Button>
                  <Button onClick={() => setSortMode(false)}>取消</Button>
                </Space>
              ) : (
                <Button type={'primary'} onClick={() => setSortMode(true)}>
                  排序
                </Button>
              )}
            </Permission>
          }
        />
      }
    >
      <div className={commonStyles.tableSection}>
        <Space size={0} align={'start'}>
          {renderList(list[0], 0)}
          {renderList(list[1], 1)}
          {renderList(list[2], 2)}
        </Space>
      </div>
    </Page>
  )
}
