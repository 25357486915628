import React, {useEffect, useRef} from 'react'
import './App.less'

import {BrowserRouter as Router, Switch, Redirect, Route, RouteProps, useLocation} from 'react-router-dom'

import LoginPage from '@/pages/login/LoginPage'
import AppLayout from '@/pages/layout/AppLayout'

import {userModel} from '@/store/models/User'
import {RouterCacheProvider} from '@/common/hooks/useCacheManager'
import {push} from '@/utils/push'
import {Badge, notification, Space} from 'antd'

function App() {
  // const token = userModel.useData(data => data.token)

  return (
    <Router>
      <Switch>
        <Route exact path='/login' component={LoginPage} />
        <PrivateRoute path={'/'}>
          <RouterCacheProvider>
            <AppLayout />
            <NotificationMessage />
          </RouterCacheProvider>
        </PrivateRoute>
      </Switch>
    </Router>
  )
}

export default App

type PrivateRouteProps = RouteProps

function NotificationMessage() {
  const [contextHolder] = useNotification()
  return <>{contextHolder}</>
}

/**
 * 权限路由
 */
function PrivateRoute(props: PrivateRouteProps) {
  const token = userModel.useData(data => data.token)
  const location = useLocation()

  return token ? <Route {...props} /> : <Redirect to={{pathname: '/login', state: {from: location}}} />
}

function useNotification() {
  const token = userModel.useData(data => data.token)
  const [api, contextHolder] = notification.useNotification()

  const msgRef = useRef<{[key: string]: {description: string; count: number}}>({})

  useEffect(() => {
    async function getConfig() {
      // const {
      //   response: {data: data1}, // data1.time 这个time是前端传给后端的，是分钟
      // } = await sysModel.sysConfigGet({k: 'MSG_TIME_END'})
      // console.log('data1', data1)

      push.init({auth: token})

      push.subscribe({
        msgType: '00000009',
        onMessage: data => {
          const msg = data.msg // 消息当做 key

          const msgObj = msgRef.current
          const count = msgObj[msg]?.count ?? 0
          msgRef.current = {...msgObj, [msg]: {description: msg, count: count + 1}}
          api.info({
            message: (
              <Space>
                <span>提示</span>
                {!!count && <Badge count={count + 1} />}
              </Space>
            ),
            key: msg,
            description: msg,
            duration: null,
            onClose: () => {
              const msgObj = msgRef.current
              msgRef.current = {...msgObj, [msg]: {description: msg, count: 0}}
            },
          })
        },
      })
    }

    if (token) {
      getConfig()
    } else {
      push.destroy()
    }
  }, [api, token])

  return [contextHolder]
}
