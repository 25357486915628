import React, {useCallback, useEffect, useState} from 'react'
import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Space,
  Table,
  Tabs,
  DatePicker,
  Modal,
  InputNumber,
  Select,
  Radio,
  message,
} from 'antd'
import {lesseeModel} from '@/store/models/Lessee'
import {useHistory} from 'react-router'
import commonStyles from '@/assets/styles/common.module.less'
import moment from 'moment'
import {usePermission} from '@/components/Permission'
import {Page} from '@/components/Page'
import {getPaginationProps} from '@/common/biz/getPaginationProps'
import {useFocusEffect} from '@/common/hooks/useFocusEffect'
import type {FormInstance} from 'antd/es/form'
import {useAppLocation} from '@/common/hooks/useRouter'

const {RangePicker} = DatePicker

const tabs = [
  {title: '申请单记录', key: '1', permission: '001023001'},
  {title: '租客地址', key: '2', permission: '001024001'},
]

const confirmStatus = [
  {label: '未确认', value: '1'},
  {label: '部分确认', value: '2'},
  {label: '已确认', value: '3'},
]
const confirmStatusObject = Object.fromEntries(confirmStatus.map(value => [value.value, value.label]))

export default function TenantBindList() {
  const history = useHistory()
  const location = useAppLocation()
  const key = location.state?.key
  const [auth] = usePermission()

  const defaultTabKey = tabs.find(value => auth(value.permission))?.key ?? tabs[0].key
  const [tabKey, setTabKey] = useState(key || defaultTabKey)

  const [propertyForm] = Form.useForm()
  // const [propertyParams, setPropertyParams] = useState<Partial<LesseePropertyQueryDTO>>({pageNum: 1, pageSize: 10})
  const [propertyTotal, setPropertyTotal] = useState(0)
  const [propertyList, setPropertyList] = useState<LesseePropertyVO[]>([])
  const propertyLoading = lesseeModel.userLesseePropertyList.useLoading()

  const [addressForm] = Form.useForm()
  // const [addressParams, setAddressParams] = useState<Partial<LesseeAddressQueryDTO>>({})
  const [addressTotal, setAddressTotal] = useState(0)
  const [addressList, setAddressList] = useState<LesseeAddressVO[]>([])
  const addressLoading = lesseeModel.userLesseeAddressList.useLoading()

  const lesseePropertyList = useCallback(() => {
    const values = propertyForm.getFieldsValue(true)
    lesseeModel.userLesseePropertyList(values).then(({response: {data, total}}) => {
      setPropertyTotal(total)
      setPropertyList(data)
    })
  }, [propertyForm])

  const lesseeAddressList = useCallback(() => {
    const values = addressForm.getFieldsValue(true)
    lesseeModel.userLesseeAddressList(values).then(({response: {data, total}}) => {
      setAddressTotal(total)
      setAddressList(data)
    })
  }, [addressForm])

  useFocusEffect(() => {
    switch (tabKey) {
      case '1':
        return propertyForm.submit()
      case '2':
        return addressForm.submit()
    }
  })

  useEffect(() => {
    switch (tabKey) {
      case '1':
        propertyForm.setFieldsValue({pageNum: 1})
        return propertyForm.submit()
      case '2':
        addressForm.setFieldsValue({pageNum: 1})
        return addressForm.submit()
    }
  }, [addressForm, propertyForm, tabKey])

  const [modal, contextHolder] = Modal.useModal()

  return (
    <Page>
      {contextHolder}
      <Tabs
        activeKey={tabKey}
        onChange={key => setTabKey(key)}
        tabBarStyle={{backgroundColor: '#fff', paddingLeft: '20px'}}
      >
        <Tabs.TabPane tab={tabs[0].title} key={tabs[0].key} disabled={!auth(tabs[0].permission)}>
          <div className={commonStyles.filterSection}>
            <Form
              form={propertyForm}
              initialValues={{pageNum: 1, pageSize: 10}}
              onReset={lesseePropertyList}
              onFinish={lesseePropertyList}
            >
              <Row>
                <Col span={7}>
                  <Form.Item name={'nickName'} label={'称呼'}>
                    <Input placeholder={'请选择下单人'} allowClear />
                  </Form.Item>
                </Col>
                <Col span={7} offset={1}>
                  <Form.Item name={'mobile'} label={'手机号'}>
                    <Input placeholder={'手机号'} allowClear />
                  </Form.Item>
                </Col>
              </Row>
              <Row justify={'end'}>
                <Space>
                  <Button
                    type={'primary'}
                    htmlType={'submit'}
                    onClick={() => propertyForm.setFieldsValue({pageNum: 1})}
                  >
                    查询
                  </Button>
                  <Button htmlType={'reset'}>重置</Button>
                </Space>
              </Row>
            </Form>
          </div>

          <div className={commonStyles.tableSection}>
            <Table
              rowKey={'userId'}
              loading={propertyLoading}
              columns={[
                {title: '申请时间', dataIndex: 'insertTime', key: 'insertTime'},
                {title: '房源标题', dataIndex: 'propertyTitle', key: 'propertyTitle'},
                {title: '称呼', dataIndex: 'nickName', key: 'nickName'},
                {title: '手机号', dataIndex: 'mobile', key: 'mobile'},
                {title: '房源ID', dataIndex: 'propertyCode', key: 'propertyCode'},
                {
                  title: '操作',
                  dataIndex: 'status',
                  key: 'status',
                  align: 'left',
                  width: '180px',
                  fixed: 'right',
                  render: (text, record, index) => {
                    return (
                      <Space>
                        {auth('002024002') && (
                          <Button
                            type={'link'}
                            size={'small'}
                            onClick={() => history.push('/tenant/bind', {userId: record.userId})}
                            style={{padding: 0}}
                          >
                            重新绑定
                          </Button>
                        )}
                        {record.orderId && auth('001040002') && (
                          <Button
                            type={'link'}
                            size={'small'}
                            onClick={() =>
                              history.push({pathname: '/steward/detail', state: {orderId: record.orderId}})
                            }
                            style={{padding: 0}}
                          >
                            查看服务单
                          </Button>
                        )}
                      </Space>
                    )
                  },
                },
              ]}
              scroll={{x: 'max-content'}}
              pagination={getPaginationProps(propertyForm, propertyTotal)}
              dataSource={propertyList}
            />
          </div>
        </Tabs.TabPane>

        <Tabs.TabPane tab={tabs[1].title} key={tabs[1].key} disabled={!auth(tabs[1].permission)}>
          <div className={commonStyles.filterSection}>
            <Form
              form={addressForm}
              initialValues={{pageNum: 1, pageSize: 10}}
              onReset={lesseeAddressList}
              onFinish={lesseeAddressList}
            >
              <Row>
                <Col span={7}>
                  <Form.Item name={'nickName'} label={'姓名'}>
                    <Input placeholder={'请输入租客姓名'} allowClear />
                  </Form.Item>
                </Col>
                <Col span={7} offset={1}>
                  <Form.Item name={'contactMobile'} label={'手机号'}>
                    <Input placeholder={'请输入手机号'} allowClear />
                  </Form.Item>
                </Col>
                <Col span={7} offset={1}>
                  <Form.Item name={'teamName'} label={'房东信息'}>
                    <Input placeholder={'请输入所属团队'} allowClear />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={7}>
                  <Form.Item name={'_contractDate'} label={'租约起止'}>
                    <RangePicker
                      allowClear
                      format={'YYYY-MM-DD'}
                      onChange={(_, formatString) => {
                        addressForm.setFieldsValue({
                          contractBegin: formatString?.[0],
                          contractEnd: formatString?.[1],
                        })
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col span={7} offset={1}>
                  <Form.Item name={'propertyTitle'} label={'房源地址'}>
                    <Input placeholder={'房源地址：'} allowClear />
                  </Form.Item>
                </Col>
                <Col span={7} offset={1}>
                  <Form.Item name={'confirmStatus'} label={'确认状态'}>
                    <Select options={confirmStatus} placeholder={'请选择'} />
                  </Form.Item>
                </Col>
              </Row>
              <Row justify={'end'}>
                <Space>
                  <Button type={'primary'} htmlType={'submit'} onClick={() => addressForm.setFieldsValue({pageNum: 1})}>
                    查询
                  </Button>
                  <Button htmlType={'reset'}>重置</Button>
                </Space>
              </Row>
            </Form>
          </div>

          <div className={commonStyles.tableSection}>
            <Table
              rowKey={'addrId'}
              loading={addressLoading}
              dataSource={addressList}
              columns={[
                {title: '姓名', dataIndex: 'nickName'},
                {title: '手机号', dataIndex: 'contactMobile'},
                {title: '房源地址', dataIndex: 'propertyTitle'},
                {title: '租金（元/月）', dataIndex: 'rentPrice'},
                {title: '房东信息', dataIndex: 'teamName'},
                {
                  title: '租约起止',
                  render: (_, record) => {
                    const {contractBegin, contractEnd} = record
                    return (
                      <div>
                        {[contractBegin, contractEnd]
                          .filter(item => item)
                          ?.map(itm => moment(itm).format('YYYY-MM-DD'))
                          ?.join('至')}
                      </div>
                    )
                  },
                },
                {title: '添加时间', dataIndex: 'insertTime'},
                {title: '确认状态', dataIndex: 'confirmStatus', render: value => confirmStatusObject[value]}, // todo
                {
                  title: '操作',
                  render: (_, record) => {
                    return (
                      <Button
                        type={'link'}
                        style={{padding: 0}}
                        onClick={() => {
                          const form = React.createRef<FormInstance>()

                          modal.confirm({
                            title: '确认信息',
                            icon: null,
                            width: 500,
                            onOk: async () => {
                              await form.current?.validateFields()
                              const values = form.current?.getFieldsValue(true)

                              await lesseeModel.userLesseeAddressConfirm(values)
                              message.success('操作成功')
                              lesseeAddressList()
                            },
                            content: (
                              <Form
                                ref={form}
                                labelCol={{span: 6}}
                                initialValues={{
                                  // addrId: record.addrId,
                                  // confirmRentPrice: record.confirmRentPrice,
                                  ...record,
                                  _confirmContract: record.confirmContractBegin
                                    ? [moment(record.confirmContractBegin), moment(record.confirmContractEnd)]
                                    : undefined,
                                }}
                                style={{paddingTop: 40}}
                              >
                                <Form.Item
                                  label={'租金（元/月）'}
                                  name={'confirmRentPrice'}
                                  rules={[{type: 'number', min: 1}]}
                                >
                                  <InputNumber style={{width: '100%'}} />
                                </Form.Item>
                                <Form.Item label={'租期'} name={'_confirmContract'}>
                                  <DatePicker.RangePicker
                                    style={{width: '100%'}}
                                    onChange={(_, formatString) => {
                                      form.current?.setFieldsValue({
                                        confirmContractBegin: formatString?.[0],
                                        confirmContractEnd: formatString?.[1],
                                      })
                                    }}
                                  />
                                </Form.Item>
                                <Form.Item
                                  label={'确认状态'}
                                  name={'confirmStatus'}
                                  rules={[{required: true, message: '请选择确认状态'}]}
                                >
                                  <Radio.Group options={confirmStatus} />
                                </Form.Item>
                              </Form>
                            ),
                          })
                        }}
                      >
                        信息确认
                      </Button>
                    )
                  },
                }, // todo
              ]}
              scroll={{x: 'max-content'}}
              pagination={getPaginationProps(addressForm, addressTotal)}
            />
          </div>
        </Tabs.TabPane>
      </Tabs>
    </Page>
  )
}
