import React, {useState, useEffect, useMemo} from 'react'
import {Row, Col, Button, Tag} from 'antd'
import {lesseeModel} from '@/store/models/Lessee'
import {commonModel} from '@/store/models/Common'

import './index.less'
import {Page} from '@/components/Page'
import BackButton from '@/components/BackButton'
import {useAppHistory, useAppLocation} from '@/common/hooks/useRouter'

export default function LesseeDetail() {
  const history = useAppHistory()
  const location = useAppLocation<'/lessee/detail'>()

  const userId = location.state?.userId

  const [detail, setDetail] = useState<LesseeInfoVO>()
  const gender = useMemo(() => commonModel.getCodeSync('gender'), [])
  const userLevel = useMemo(() => commonModel.getCodeSync('userLevel'), [])

  useEffect(() => {
    if (userId) {
      lesseeModel.queryLesseeInfo(userId).then(({response: {data}}) => {
        setDetail(data)
      })
    }
  }, [userId])

  const userLevelStatus = useMemo(() => {
    if (!detail?.loginTime) {
      return '1'
    }

    if (detail?.realVerify === '0') {
      return '2'
    }

    if (detail?.realVerify === '1') {
      return '3'
    }
  }, [detail])

  return (
    <Page>
      <BackButton />
      <section className={'detail'}>
        <article className={'content'}>
          <header className={'header'}>基础信息</header>
          <Row className={'row border-top'}>
            <Col span={8} className={'col border-right'}>
              用户昵称：{detail?.nickName}
            </Col>
            <Col span={8} className={'col border-right'}>
              手机号：{detail?.mobile}
            </Col>
            <Col span={8} className={'col'}>
              性别：{detail?.gender ? gender[detail?.gender] : ''}
            </Col>
          </Row>
          <Row className={'row border-top-bottom'}>
            <Col span={12} className={'col border-right'}>
              真实姓名：{detail?.certName} {detail?.realVerify === '1' ? <Tag color='red'>已实名</Tag> : null}
            </Col>
            <Col span={12} className={'col'}>
              用户等级：{userLevel[userLevelStatus]}
            </Col>
          </Row>
          <Row className={'row border-bottom'}>
            <Col span={12} className={'col border-right'}>
              身份证号：{detail?.certNo} {detail?.realVerify === '1' ? <Tag color='red'>已实名</Tag> : null}
            </Col>
            <Col span={12} className={'col'}>
              邮箱：{detail?.email}
            </Col>
          </Row>
          <Row className={'row border-bottom remark'}>
            <Col>备注：{detail?.remark}</Col>
          </Row>
          <header className={'header'}>租约信息</header>
          <section className={'section border-top-bottom'}>
            <Button
              type={'link'}
              onClick={() => {
                // updateForm({lessee: detail.mobile})
                history.push({pathname: '/contract', state: {lessee: detail?.mobile}})
              }}
            >
              查看租客为承租人的租约列表
            </Button>
          </section>
          <header className={'header'}>账务信息</header>
          <section className={'section border-top'}>
            <Button
              type={'link'}
              onClick={() => {
                history.push({pathname: '/bill', state: {lessee: detail?.mobile}})
              }}
            >
              查看该租客为承租人的账务列表
            </Button>
          </section>
        </article>

        <footer className={'footer'}>
          <Button type={'primary'} onClick={() => history.goBack()}>
            返回
          </Button>
        </footer>
      </section>
    </Page>
  )
}
