import {Model} from '@redux-model/web'
import {$api} from '@/store/service'

interface CondoModel {}

class Condo extends Model<CondoModel> {
  protected initReducer(): CondoModel {
    return {}
  }

  // 公寓列表
  condoList = $api.action((args: CondoInfoAdminQueryDTO) =>
    this.post<ApiResponse<CondoInfoAdminQueryResultVO[]>>('/condo/list').body(args)
  )

  // 公寓详情  |  object:{condoId:公寓id}
  condoGet = $api.action((args: {condoId: string}) => this.post<ApiResponse<CondoInfoVO>>('/condo/get').body(args))

  // 修改定位
  condoUpdateLocation = $api.action((args: CondoInfoUpdateLocationDTO) =>
    this.post<ApiResponse<Record<string, any>>>('/condo/update-location').body(args)
  )

  // 集中式项目-财务流水  |  object:{condoProjectId: 公寓项目, month:月份（yyyy-MM）}
  condoProjectFinanceInfo = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<CondoProjectFinanceVO>>('/condo/project/finance/info').body(args)
  )

  // 集中式项目-财务流水新增/更新
  condoProjectFinanceUpsert = $api.action((args: CondoProjectFinanceDTO) =>
    this.post<ApiResponse<Record<string, any>>>('/condo/project/finance/upsert').body(args)
  )

  // 集中式项目列表
  condoProjectList = $api.action((args: CondoProjectListDTO) =>
    this.post<ApiResponse<CondoProjectVO[]>>('/condo/project/list').body(args)
  )

  // 集中式项目新增/更新
  condoProjectUpsert = $api.action((args: CondoProjectDTO) =>
    this.post<ApiResponse<Record<string, any>>>('/condo/project/upsert').body(args)
  )

  // 集中式项目详情  |  object:{condoProjectId: 公寓项目}
  condoProjectInfo = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<CondoProjectVO>>('/condo/project/info').body(args)
  )

  //新增费用类型
  condoProjectFinanceType = $api.action(() => this.get<ApiResponse<any[]>>('/condo-project-finance-type.json'))

  // 日常数据列表
  condoStatisticDayList = $api.action((args: CondoStatisticDayQueryDTO) =>
    this.post<ApiResponse<CondoStatisticDayVO[]>>('/condo/statistic/day/list').body(args)
  )

  // 集中式项目-配置
  condoProjectConfig = $api.action((args: CondoProjectConfigDTO) =>
    this.post<ApiResponse<Record<string, any>>>('/condo/project/config').body(args)
  )

  // 人员列表
  condoProjectTeamUserList = $api.action((args: CondoProjectUserListDTO) =>
    this.post<ApiResponse<CondoProjectUserVO[]>>('/condo/project/team/user/list').body(args)
  )

  // 人员更新
  condoProjectTeamUserUpdate = $api.action((args: CondoProjectUserDTO) =>
    this.post<ApiResponse<Record<string, any>>>('/condo/project/team/user/update').body(args)
  )

  // 人员启用/禁用
  condoProjectTeamUserUpdateStatus = $api.action((args: CondoProjectUserStatusDTO) =>
    this.post<ApiResponse<Record<string, any>>>('/condo/project/team/user/update-status').body(args)
  )

  // 岗位列表
  condoProjectTeamRoleList = $api.action((args: CondoProjectRoleListDTO) =>
    this.post<ApiResponse<CondoProjectRoleVO[]>>('/condo/project/team/role/list').body(args)
  )

  // 岗位新增/更新
  condoProjectTeamRoleUpsert = $api.action((args: CondoProjectRoleDTO) =>
    this.post<ApiResponse<Record<string, any>>>('/condo/project/team/role/upsert').body(args)
  )

  // 岗位启用/禁用  |  object:{roleId: 岗位id, enable:启用状态（1：启用、0：禁用）
  condoProjectTeamRoleUpdateStatus = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<Record<string, any>>>('/condo/project/team/role/update-status').body(args)
  )

  // 岗位详情  |  object:{roleId: 岗位id}
  condoProjectTeamRoleInfo = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<CondoProjectRoleVO>>('/condo/project/team/role/info').body(args)
  )

  // 岗位删除  |  object:{roleId: 岗位id}
  condoProjectTeamRoleDelete = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<CondoProjectRoleVO>>('/condo/project/team/role/delete').body(args)
  )

  // 岗位角色-查询所有的权限列表树
  condoProjectTeamRoleListAllPermissionTree = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<TeamPermissionTreeNodeVO[]>>('/condo/project/team/role/list-all-permission-tree').body(args)
  )

  // 集中式项目-财务公司-列表
  condoProjectCompanyList = $api.action((args: CondoProjectCompanyListDTO) =>
    this.post<ApiResponse<CondoProjectCompanyVO[]>>('/condo/project/company/list').body(args)
  )

  // 集中式项目-财务公司-详情  |  object:{uniCredit: 统一社会信用码}
  condoProjectCompanyInfo = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<CondoProjectCompanyVO>>('/condo/project/company/info').body(args)
  )

  // 集中式项目-财务公司-新增
  condoProjectCompanyInsert = $api.action((args: CondoProjectCompanyDTO) =>
    this.post<ApiResponse<Record<string, any>>>('/condo/project/company/insert').body(args)
  )

  // 集中式项目-财务公司-更新
  condoProjectCompanyUpdate = $api.action((args: CondoProjectCompanyDTO) =>
    this.post<ApiResponse<Record<string, any>>>('/condo/project/company/update').body(args)
  )

  // 集中式项目-财务公司-修改状态  |  object:{uniCredit: 统一社会信用码, status:状态（1：启用、0：禁用）}
  condoProjectCompanyUpdateStatus = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<Record<string, any>>>('/condo/project/company/update-status').body(args)
  )

  // 集中式项目-财务公司-删除  |  object:{uniCredit: 统一社会信用码}
  condoProjectCompanyDelete = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<Record<string, any>>>('/condo/project/company/delete').body(args)
  )

  // 营业科目设置-保存设置
  condoProjectContractConfigSave = $api.action((args: CondoProjectContractConfigDTO) =>
    this.post<ApiResponse<Record<string, any>>>('/condo/project/contract/config/save').body(args)
  )

  // 营业科目设置-查询配置列表(查询全部)  |  object:{condoProjectId:公寓项目id}
  condoProjectContractConfigListSelected = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<CondoProjectContractConfigVO>>('/condo/project/contract/config/list-selected').body(args)
  )

  // 同步人员和岗位到`房东端`V2(人员和项目的关系直接配置)
  condoProjectTeamSyncV2 = $api.action(() => this.post<ApiResponse<Record<string, any>>>('/condo/project/team/sync-v2'))

  // 优惠活动list
  condoProjectDiscountList = $api.action((args: CondoProjectDiscountQueryDTO) =>
    this.post<ApiResponse<CondoProjectDiscountVO[]>>('/condo/project/discount/list').body(args)
  )

  // 优惠活动编辑
  condoProjectDiscountEdit = $api.action((args: CondoProjectDiscountEditDTO) =>
    this.post<ApiResponse<string>>('/condo/project/discount/edit').body(args)
  )

  // 优惠活动启动/禁用  |  object:{discountId:优惠id,enable:true或者false}
  condoProjectDiscountEnableOrDisable = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<Record<string, any>>>('/condo/project/discount/enable-or-disable').body(args)
  )

  // 优惠活动详情  |  object:{discountId:优惠id}
  condoProjectDiscountGet = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<CondoProjectDiscountVO>>('/condo/project/discount/get').body(args)
  )

  // 优惠活动新增  |  文件上传见/file/upload[category:condo_project_discount]
  condoProjectDiscountInsert = $api.action((args: CondoProjectDiscountInsertDTO) =>
    this.post<ApiResponse<string>>('/condo/project/discount/insert').body(args)
  )

  // 集中式项目-房源模板-列表
  condoProjectTemplateList = $api.action((args: CondoProjectTemplateListDTO) =>
    this.post<ApiResponse<CondoProjectTemplateVO[]>>('/condo/project/template/list').body(args)
  )

  // 集中式项目详情  |  object:{templateId: 公寓id}
  condoProjectTemplateInfo = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<CondoProjectTemplateVO>>('/condo/project/template/info').body(args)
  )

  // 集中式项目-房源模板-启用/禁用  |  object:{templateId: 公寓id, templateEnable:1启用0禁用}
  condoProjectTemplateEnable = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<Record<string, any>>>('/condo/project/template/enable').body(args)
  )

  // 集中式项目-房源模板-新增/更新
  condoProjectTemplateUpsert = $api.action((args: CondoProjectTemplateDTO) =>
    this.post<ApiResponse<Record<string, any>>>('/condo/project/template/upsert').body(args)
  )

  // 集中式项目-户型模板-列表
  condoProjectPropertyList = $api.action((args: CondoProjectPropertyListDTO) =>
    this.post<ApiResponse<CondoProjectPropertyVO[]>>('/condo/project/property/list').body(args)
  )

  // 集中式项目-房源-删除房源  |  object:{propertyCode: 房源编号}
  condoProjectPropertyDelete = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<Record<string, any>>>('/condo/project/property/delete').body(args)
  )

  // 集中式项目-房源-上/下架房源
  condoProjectPropertyEnable = $api.action((args: CondoProjectPropertyEnableDTO) =>
    this.post<ApiResponse<Record<string, any>>>('/condo/project/property/enable').body(args)
  )

  // 集中式项目-房源新增
  condoProjectPropertyInsert = $api.action((args: CondoProjectPropertyInsertDTO) =>
    this.post<ApiResponse<Record<string, any>>>('/condo/project/property/insert').body(args)
  )

  // 集中式项目-房源修改
  condoProjectPropertyUpdate = $api.action((args: CondoProjectPropertyUpdateDTO) =>
    this.post<ApiResponse<Record<string, any>>>('/condo/project/property/update').body(args)
  )

  // 集中式项目-房源-房源详情  |  object:{propertyCode: 房源编号}
  condoProjectPropertyInfo = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<CondoProjectPropertyVO>>('/condo/project/property/info').body(args)
  )

  // 集中式项目-房源修改-图片
  condoProjectPropertyUpdateImg = $api.action((args: CondoProjectPropertyUpdateImgDTO) =>
    this.post<ApiResponse<Record<string, any>>>('/condo/project/property/update-img').body(args)
  )

  // 外部人员列表
  condoProjectTeamUserExtraList = $api.action((args: CondoProjectUserListDTO) =>
    this.post<ApiResponse<CondoProjectUserExtraVO[]>>('/condo/project/team/user-extra/list').body(args)
  )

  // 外部人员更新
  condoProjectTeamUserExtraUpdate = $api.action((args: CondoProjectUserExtraDTO) =>
    this.post<ApiResponse<Record<string, any>>>('/condo/project/team/user-extra/update').body(args)
  )

  // 外部人员启用/禁用
  condoProjectTeamUserExtraUpdateStatus = $api.action((args: CondoProjectUserExtraDTO) =>
    this.post<ApiResponse<Record<string, any>>>('/condo/project/team/user-extra/update-status').body(args)
  )

  // 新增优惠活动的使用次数  |  object:{discountId:折扣id,num:增加的次数}
  condoProjectDiscountAddLimitNum = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<Record<string, any>>>('/condo/project/discount/add-limit-num').body(args)
  )

  // 优惠活动操作日志列表  |  object:{discountId:优惠id}
  condoProjectDiscountLogList = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<CondoProjectDiscountLogVO[]>>('/condo/project/discount/log-list').body(args)
  )

  // 集中式项目-房源-房源详情  |  object:{propertyCode: 房源编号}
  condoProjectPropertyHistory = $api.action((args: CondoProjectPropertyHistoryListDTO) =>
    this.post<ApiResponse<CondoProjectPropertyHistoryVO[]>>('/condo/project/property/history').body(args)
  )

  // 集中式项目-设备配置
  condoProjectDeviceConfig = $api.action((args: CondoProjectConfigDeviceDTO) =>
    this.post<ApiResponse<Record<string, any>>>('/condo/project/device-config').body(args)
  )

  // 集中式项目-设备配置详情  |  object:{condoProjectId: 公寓项目}
  condoProjectDeviceConfigInfo = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<CondoProjectDeviceConfigVO>>('/condo/project/device-config/info').body(args)
  )
  
  // 人员详情  |  object:{sysUserId:系统用户ID}
  condoProjectTeamUserGet = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<CondoProjectUserVO>>('/condo/project/team/user/get').body(args)
  )
}

export const condoModel = new Condo()
