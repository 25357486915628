import React, {useCallback, useEffect, useState} from 'react'
import styles from './index.module.less'
import {
  Breadcrumb as AntBreadcrumb,
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Space,
  Table,
  Typography,
} from 'antd'
import {sysModel} from '@/store/models/Sys'
import commonStyles from '@/assets/styles/common.module.less'
import {Permission, usePermission} from '@/components/Permission'
import {Page} from '@/components/Page'
import {Breadcrumb} from '@/components/Breadcrumb'

export default function SysCode() {
  const [form] = Form.useForm()
  const [subForm] = Form.useForm()
  const [auth] = usePermission()

  const [editForm, setEditForm] = useState({} as SysCodeVO)
  function updateEditForm(partialForm: Partial<SysCodeVO>) {
    setEditForm(prevState => ({...prevState, ...partialForm}))
  }

  const [stack, setStack] = useState<string[]>([])

  const [visible, setVisible] = useState(false)
  const [dataSource, setDataSource] = useState<SysCodeVO[]>([])

  const search = useCallback((args = {} as Partial<SysCodeListDTO>, skipStack = false) => {
    sysModel.sysCodeList(args).then(({response: {data}}) => {
      setDataSource(data)

      const category = args.category ?? data[0]?.category
      if (!skipStack && category) {
        setStack(prevState => {
          if (!prevState.includes(category)) {
            return [...prevState, category]
          } else {
            return prevState.slice(0, prevState.findIndex(item => item === category) + 1)
          }
        })
      }
    })
  }, [])

  useEffect(() => search(), [search])

  const isActiveColumn = (id: string) => editForm.id === id

  // 保存码表
  async function handleSave() {
    await sysModel.sysCodeUpset(editForm)
    setEditForm({} as SysCodeVO)
    search({category: stack[stack.length - 1]})
  }

  // 新增码表
  async function handleAdd() {
    await sysModel.sysCodeUpset(subForm.getFieldsValue(true) as SysCodeDTO)
    subForm.resetFields()
    setVisible(false)
    search({category: stack[stack.length - 1]})
  }
  const options = {
    '0': '无效',
    '1': '有效',
  }

  return (
    <Page
      breadcrumb={
        <Breadcrumb
          right={
            <Permission code={'001036002'}>
              <Button
                onClick={() => {
                  subForm.setFieldsValue({category: stack[stack.length - 1]})
                  setVisible(true)
                }}
              >
                添加
              </Button>
            </Permission>
          }
        />
      }
    >
      <div className={commonStyles.filterSection}>
        <Form form={form} onFinish={values => search(values, !!values.name)}>
          <Row>
            <Col span={7}>
              <Form.Item name={'name'} label={'名称'}>
                <Input placeholder={'名称'} allowClear />
              </Form.Item>
            </Col>
          </Row>
          <Row justify={'end'}>
            <Space>
              <Button type={'primary'} htmlType={'submit'}>
                查询
              </Button>
              <Button
                onClick={() => {
                  form.resetFields()
                  search({name: ''}, false)
                }}
              >
                重置
              </Button>
            </Space>
          </Row>
        </Form>
      </div>

      <Space style={{marginBottom: '20px'}}>
        <span>当前码表：</span>
        <AntBreadcrumb className={styles.stack}>
          {stack.map((item, index) => (
            <AntBreadcrumb.Item key={item} onClick={() => search({category: item})}>
              {item}
            </AntBreadcrumb.Item>
          ))}
        </AntBreadcrumb>
      </Space>

      <div className={commonStyles.tableSection}>
        <Table
          dataSource={dataSource}
          rowKey={'id'}
          pagination={false}
          loading={sysModel.sysCodeList.useLoading()}
          columns={[
            {
              title: '上级',
              dataIndex: 'category',
              key: 'category',
            },
            {
              title: 'Code',
              dataIndex: 'code',
              key: 'code',
              width: 160,
              render: (value, record) =>
                isActiveColumn(record.id) ? (
                  <Input
                    defaultValue={value}
                    onChange={event => updateEditForm({code: event.target.value})}
                    allowClear
                  />
                ) : (
                  value
                ),
            },
            {
              title: '名称',
              dataIndex: 'name',
              key: 'name',
              width: 160,
              render: (value, record) =>
                isActiveColumn(record.id) ? (
                  <Input
                    defaultValue={value}
                    allowClear
                    onChange={event => updateEditForm({name: event.target.value})}
                  />
                ) : (
                  value
                ),
            },
            {
              title: '排序',
              dataIndex: 'rank',
              key: 'rank',
              width: 160,
              render: (value, record) =>
                isActiveColumn(record.id) ? (
                  <InputNumber min={1} defaultValue={value} onChange={value1 => updateEditForm({rank: +value1})} />
                ) : (
                  value
                ),
            },
            {
              title: '状态',
              dataIndex: 'status',
              key: 'status',
              width: 160,
              render: (value, record) =>
                isActiveColumn(record.id) ? (
                  <Select
                    style={{width: '100%'}}
                    value={editForm.status}
                    onChange={value1 => updateEditForm({status: value1})}
                    allowClear
                  >
                    {Object.keys(options).map(key => (
                      <Select.Option key={key} value={key}>
                        {options[key]}
                      </Select.Option>
                    ))}
                  </Select>
                ) : (
                  options[value]
                ),
            },
            {
              title: '操作',
              dataIndex: 'id',
              key: 'id',
              render: (value, record) => (
                <Space>
                  {auth('002036002') && (
                    <>
                      {isActiveColumn(value) ? (
                        <Button type={'primary'} ghost size={'small'} onClick={handleSave} style={{padding: 0}}>
                          保存
                        </Button>
                      ) : (
                        <Button type={'link'} size={'small'} onClick={() => setEditForm(record)} style={{padding: 0}}>
                          编辑
                        </Button>
                      )}
                    </>
                  )}
                  <Button
                    type={'link'}
                    size={'small'}
                    onClick={() => search({category: record.code})}
                    style={{padding: 0}}
                  >
                    子表
                  </Button>
                </Space>
              ),
            },
          ]}
        />
      </div>

      <Modal
        title='添加'
        visible={visible}
        onOk={() => subForm.validateFields().then(handleAdd)}
        width={420}
        destroyOnClose
        confirmLoading={sysModel.sysCodeUpset.useLoading()}
        okButtonProps={{htmlType: 'submit'}}
        onCancel={() => setVisible(false)}
        bodyStyle={{padding: '15px 116px 0', textAlign: 'center'}}
      >
        <Form form={subForm}>
          <Space style={{marginBottom: 5}}>
            <Typography.Text style={{color: '#BFBFBF'}}>上级:</Typography.Text>
            <Typography.Text>{subForm.getFieldValue('category')}</Typography.Text>
          </Space>
          <Form.Item name={'code'} rules={[{required: true, message: '请输入Code'}]}>
            <Input placeholder={'请输入Code'} allowClear />
          </Form.Item>
          <Form.Item name={'name'} rules={[{required: true, message: '请输入名称'}]}>
            <Input placeholder={'请输入名称'} allowClear />
          </Form.Item>
          <Form.Item name={'rank'} rules={[{required: true, message: '请输入排序'}]}>
            <InputNumber min={1} placeholder={'请输入排序'} style={{width: '100%'}} />
          </Form.Item>
        </Form>
      </Modal>
    </Page>
  )
}
