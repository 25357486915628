import {useCallback, useEffect, useState} from 'react'

/**
 * 倒计时 hook
 */
let timer: any // timer 不需要作为依赖出现在 useState 里面，不然会出问题
export function useCountDown(initialCountDown = 0) {
  const [countDown, setCount] = useState(initialCountDown)

  const startCountDown = useCallback(function startCountDown(startNum = 60) {
    clearTimeout(timer)
    setCount(count => (count === startNum ? startNum + 1 : startNum))
  }, [])

  function pauseCountDown() {
    clearTimeout(timer)
  }

  function stopCountDown() {
    clearTimeout(timer)
    setCount(0)
  }

  useEffect(
    function () {
      if (countDown >= 1) {
        timer = setTimeout(() => {
          setCount(count => count - 1)
          // if (countDown === 1 && typeof fn === 'function') fn()
        }, 1000)
      }
      return function () {
        clearTimeout(timer)
      }
    },
    [countDown]
  )

  /**
   * @example:
   * useMemo(() => onFinish(function () {
   *   alert('finish' + count)
   * }), [count])
   */
  // function onFinish(fn) {
  //   setFn(() => fn)
  // }

  return {
    countDown,
    startCountDown,
    pauseCountDown,
    stopCountDown,
    // onFinish,
  }
}
