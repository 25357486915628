import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {Breadcrumb} from '@/components/Breadcrumb'
import commonStyles from '@/assets/styles/common.module.less'
import {
  AutoComplete,
  Button,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  message,
  Modal,
  Pagination,
  Row,
  Select,
  Space,
  Switch,
  Table,
  Typography,
  Upload,
} from 'antd'
import {feedbackModel} from '@/store/models/FeedBack'
import {useCodeArraySync, useCodeSync} from '@/store/models/Common'
import {Permission, usePermission} from '@/components/Permission'
import {Page} from '@/components/Page'
import {Album} from '@/components/Album'
import {baseUrl} from '@/common/config'
import {getImg, isFile, isUploaded} from '@/utils/util'

const contentRule = [
  {
    required: true,
    validator: (rule, value) => {
      if (!value) {
        return Promise.reject('请输入答案')
      } else if (value.length >= 2000) {
        return Promise.reject('字数不超过2000')
      } else {
        return Promise.resolve()
      }
    },
  },
]

export default function QAManagement() {
  const [auth] = usePermission()
  const platform = useCodeSync('sys.platform')
  const platformList = useCodeArraySync('sys.platform')

  const availablePlatforms = useMemo(
    () => platformList.filter(value => value.id !== '05').map(value => ({label: value.label, value: value.id})),
    [platformList]
  )

  const [modalVisible, setModalVisible] = useState(false)
  const [visible, setVisible] = useState(false)

  const [qaList, setQaList] = useState([] as SysHelpVO[])
  const [total, setTotal] = useState(0)
  const [form] = Form.useForm()
  const [modalForm] = Form.useForm()
  const [options, setOptions] = useState([] as {value: string}[])
  const [detail, setDetail] = useState({} as SysHelpVO)
  const [categoryList, setCategoryList] = useState([] as string[])

  const [helpObj, setHelpObj] = useState({} as Pick<SysHelpSaveDTO, 'helpStatus' | 'helpId'>)

  const loading = feedbackModel.stewardAppSysHelpList.useLoading()
  const saveLoading = feedbackModel.sysHelpSave.useLoading()
  const statusLoading = feedbackModel.sysHelpSetStatus.useLoading()

  useEffect(() => {
    feedbackModel.stewardAppSysHelpListCategory().then(({response: {data}}) => {
      setCategoryList(data)
      setOptions(data.map(item => ({value: item})))
    })
  }, [])

  const search = useCallback(async () => {
    const value = form.getFieldsValue(true)
    const {
      response: {data, total},
    } = await feedbackModel.stewardAppSysHelpList(value)
    setQaList(data)
    setTotal(total)
  }, [form])

  useEffect(() => {
    form.submit()
  }, [form])

  return (
    <Page
      breadcrumb={
        <Breadcrumb
          right={
            <Permission code={'003044002'}>
              <Button
                type={'primary'}
                onClick={() => {
                  modalForm.setFieldsValue({helpStatus: 1})
                  setModalVisible(true)
                }}
              >
                新增
              </Button>
            </Permission>
          }
        />
      }
    >
      <Form form={form} initialValues={{pageNum: 1, pageSize: 10}} onReset={search} onFinish={search}>
        <div className={commonStyles.filterSection}>
          <Row gutter={70}>
            <Col span={8}>
              <Form.Item label={'关键字'} name={'keyword'}>
                <Input placeholder={'请输入问题标题或内容'} allowClear />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={'问题类型'} name={'category'}>
                <Input placeholder={'请输入问题类型'} allowClear />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={'状态'} name={'helpStatus'}>
                <Select placeholder={'请选择'} allowClear>
                  <Select.Option value={0}>已禁用</Select.Option>
                  <Select.Option value={1}>已启用</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={'发布平台'} name={'platforms'}>
                <Select placeholder={'请选择'} allowClear>
                  {platformList.map(item => (
                    <Select.Option value={item.id} key={item.id}>
                      {item.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={'发布时间'} name={'_time'}>
                <DatePicker.RangePicker
                  style={{width: '100%'}}
                  showTime
                  allowClear
                  format={'YYYY-MM-DD HH:mm:ss'}
                  onChange={(_, formatString) => {
                    form.setFieldsValue({insertTimeBegin: formatString?.[0], insertTimeEnd: formatString?.[1]})
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row justify={'end'}>
            <Space>
              <Button type={'primary'} htmlType={'submit'}>
                查询
              </Button>
              <Button htmlType={'reset'}>重置</Button>
            </Space>
          </Row>
        </div>
        <div className={commonStyles.tableSection}>
          <Table
            loading={loading}
            rowKey={'helpId'}
            dataSource={qaList}
            scroll={{x: 'max-content'}}
            columns={[
              {title: 'ID', dataIndex: 'helpId', key: 'helpId'},
              {title: '标题', dataIndex: 'title', key: 'title'},
              {title: '创建时间', dataIndex: 'insertTime', key: 'insertTime'},
              {
                title: '平台',
                dataIndex: 'platforms',
                key: 'platforms',
                render: text =>
                  text
                    .split(',')
                    .map(item => platform[item])
                    .join('、'),
              },
              {title: '类型', dataIndex: 'category', key: 'category'},
              {
                title: '状态',
                dataIndex: 'helpStatus',
                key: 'helpStatus',
                render: text => (text === 1 ? '已启用' : '已禁用'),
              },
              {
                title: '操作',
                dataIndex: 'operation',
                key: 'operation',
                fixed: 'right',
                render: (text, record, index) => (
                  <Space>
                    {auth('005044002') && (
                      <div onClick={() => setHelpObj({helpStatus: record.helpStatus, helpId: record.helpId})}>
                        <Switch
                          size={'small'}
                          checked={record.helpStatus === 1}
                          onChange={(_, event) => event.preventDefault()}
                        />
                      </div>
                    )}
                    {auth('002044002') && (
                      <>
                        <Divider type={'vertical'} style={{margin: 0}} />
                        <Button
                          style={{padding: 0}}
                          type={'link'}
                          onClick={() => {
                            modalForm.setFieldsValue(record)
                            setModalVisible(true)
                          }}
                        >
                          编辑
                        </Button>
                      </>
                    )}
                    {auth('001044002') && (
                      <>
                        <Divider type={'vertical'} style={{margin: 0}} />
                        <Button
                          style={{padding: 0}}
                          type={'link'}
                          onClick={() => {
                            setVisible(true)
                            setDetail(record)
                          }}
                        >
                          查看
                        </Button>
                      </>
                    )}
                  </Space>
                ),
              },
            ]}
            pagination={false}
          />
          <Row justify={'center'} style={{marginTop: 24}}>
            <Form.Item
              noStyle
              shouldUpdate={(prev, next) => prev.pageSize !== next.pageSize || prev.pageNum !== next.pageNum}
            >
              {f => (
                <Pagination
                  current={f.getFieldValue('pageNum')}
                  pageSize={f.getFieldValue('pageSize')}
                  total={total}
                  showSizeChanger={true}
                  onChange={(pageNum, pageSize) => {
                    f.setFieldsValue({pageNum, pageSize})
                    search()
                  }}
                />
              )}
            </Form.Item>
          </Row>
        </div>
      </Form>

      {/* 启用禁用 */}
      <Modal
        visible={!!helpObj.helpId}
        centered
        title={'提示'}
        footer={null}
        onCancel={() => setHelpObj({} as Pick<SysHelpSaveDTO, 'helpStatus' | 'helpId'>)}
      >
        <span>确认{helpObj.helpStatus === 1 ? '禁用' : '启用'}？</span>
        <Row justify={'end'}>
          <Space>
            <Button onClick={() => setHelpObj({} as Pick<SysHelpSaveDTO, 'helpStatus' | 'helpId'>)}>取消</Button>
            <Button
              type={'primary'}
              loading={statusLoading}
              onClick={async () => {
                await feedbackModel.sysHelpSetStatus({
                  ...helpObj,
                  helpStatus: helpObj.helpStatus === 1 ? 0 : 1,
                })
                setHelpObj({} as Pick<SysHelpSaveDTO, 'helpStatus' | 'helpId'>)
                search()
              }}
            >
              确认
            </Button>
          </Space>
        </Row>
      </Modal>

      {/* 新增/编辑 */}
      <Modal
        visible={modalVisible}
        title={modalForm.getFieldValue('helpId') ? '编辑' : '新增'}
        centered
        width={680}
        footer={null}
        destroyOnClose
        onCancel={() => setModalVisible(false)}
        afterClose={() => modalForm.resetFields()}
      >
        <div>
          <Form
            form={modalForm}
            labelCol={{flex: '85px'}}
            initialValues={{videoUrlList: [], imgUrlList: []}}
            wrapperCol={{span: 15}}
            onFinish={async () => {
              const value: SysHelpSaveDTO = modalForm.getFieldsValue(true)
              await feedbackModel.sysHelpSave(value)
              message.success('编辑成功')
              search()
              setModalVisible(false)
            }}
          >
            <Form.Item noStyle shouldUpdate>
              {f =>
                !!f.getFieldValue('helpId') && (
                  <>
                    <Form.Item label={'ID'}>{f.getFieldValue('helpId')}</Form.Item>
                    <Form.Item label={'创建时间:'}>{f.getFieldValue('insertTime')}</Form.Item>
                  </>
                )
              }
            </Form.Item>
            <Form.Item
              label={'发布平台'}
              name={'platforms'}
              // 重置 Form.Item 右边的宽度
              wrapperCol={{}}
              rules={[{required: true, message: '请选择发布平台'}]}
              getValueFromEvent={event => event?.join(',')}
              getValueProps={value => ({value: value?.split(',')})}
            >
              <Checkbox.Group options={availablePlatforms} />
            </Form.Item>
            <Form.Item label={'状态'} name={'helpStatus'} rules={[{required: true, message: '请选择状态'}]}>
              <Select placeholder={'请选择'} allowClear>
                <Select.Option value={0}>已禁用</Select.Option>
                <Select.Option value={1}>已启用</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item
              label={'问题类型'}
              name={'category'}
              rules={[{required: true, message: '请选择或者输入问题类型'}]}
            >
              <AutoComplete
                options={options}
                placeholder={'请输入'}
                onSearch={value => {
                  const arr = categoryList.filter(item => item.indexOf(value) !== -1).map(item => ({value: item}))
                  setOptions(arr)
                }}
                allowClear
              />
            </Form.Item>
            <Form.Item label={'问题标题'} name={'title'} rules={[{required: true, message: '请输入问题标题'}]}>
              <Input placeholder={'请输入'} allowClear />
            </Form.Item>
            <Form.Item label={'答案'} name={'content'} rules={contentRule}>
              <Input.TextArea placeholder={'请输入'} allowClear />
            </Form.Item>
            {/*解决 getValueProps 返回 [] 时，Upload 没有实时更新的情况*/}
            <Form.Item noStyle shouldUpdate>
              {() => (
                <Form.Item
                  label={'图片'}
                  name={'imgUrlList'}
                  // 重置 Form.Item 右边的宽度
                  wrapperCol={{}}
                  rules={[{required: true, message: '请上传图片'}]}
                  getValueFromEvent={event => {
                    return event.fileList?.reduce((prev, item) => {
                      if (isFile(item) && item.status === 'done') {
                        if (item.response?.code === 200) {
                          prev.push(item.response?.data?.imgUrl)
                        } else {
                          // 未上传成功丢掉该项目
                          message.error(item.response?.msg)
                        }
                        return prev
                      }
                      // 已上传的文件取 uid
                      if (isUploaded(item)) return [...prev, item.uid]

                      // file 对象原封不动
                      return [...prev, item]
                    }, [])
                  }}
                  getValueProps={value => ({
                    fileList: value?.map(item => (typeof item === 'string' ? {uid: item, url: getImg(item)} : item)),
                  })}
                >
                  <Album
                    limit={20}
                    action={`${baseUrl}/sys-help/upload-file`}
                    beforeUpload={file => {
                      if (file.size > 1024 * 1024 * 10) {
                        message.warn('文件大小不能超过10m')
                        return Upload.LIST_IGNORE
                      }
                      return true
                    }}
                  />
                </Form.Item>
              )}
            </Form.Item>
            <Form.Item
              label={'视频'}
              name={'videoUrlList'}
              getValueFromEvent={event => {
                const value = event.target.value
                return value ? [value] : []
              }}
              getValueProps={value => ({value: value?.[0]})}
            >
              <Input placeholder={'请输入'} allowClear />
            </Form.Item>
            <Row justify={'end'}>
              <Space>
                <Button onClick={() => setModalVisible(false)}>取消</Button>
                <Button type={'primary'} htmlType={'submit'} loading={saveLoading}>
                  保存
                </Button>
              </Space>
            </Row>
          </Form>
        </div>
      </Modal>

      {/* 查看 */}
      <Modal
        visible={visible}
        title={
          <div style={{textAlign: 'center'}}>
            <Typography.Text style={{color: '#000000'}}>查看</Typography.Text>
          </div>
        }
        centered
        footer={null}
        onCancel={() => setVisible(false)}
      >
        <Row gutter={[0, 12]}>
          <Col span={5} style={{textAlign: 'right'}}>
            <Typography.Text style={{color: 'rgba(0, 0, 0, 0.85)'}}>ID：</Typography.Text>
          </Col>
          <Col span={19}>
            <Typography.Text>{detail.helpId}</Typography.Text>
          </Col>
          <Col span={5} style={{textAlign: 'right'}}>
            <Typography.Text style={{color: 'rgba(0, 0, 0, 0.85)'}}>创建时间：</Typography.Text>
          </Col>
          <Col span={19}>
            <Typography.Text>{detail.insertTime}</Typography.Text>
          </Col>
          <Col span={5} style={{textAlign: 'right'}}>
            <Typography.Text style={{color: 'rgba(0, 0, 0, 0.85)'}}>发布平台：</Typography.Text>
          </Col>
          <Col span={19}>
            <Typography.Text>
              {detail.platforms
                ?.split(',')
                ?.map(item => platform[item])
                ?.join('、')}
            </Typography.Text>
          </Col>
          <Col span={5} style={{textAlign: 'right'}}>
            <Typography.Text style={{color: 'rgba(0, 0, 0, 0.85)'}}>状态：</Typography.Text>
          </Col>
          <Col span={19}>
            <Typography.Text>{!detail.helpStatus ? '已禁用' : '已启用'}</Typography.Text>
          </Col>
          <Col span={5} style={{textAlign: 'right'}}>
            <Typography.Text style={{color: 'rgba(0, 0, 0, 0.85)'}}>问题类型：</Typography.Text>
          </Col>
          <Col span={19}>
            <Typography.Text>{detail.category}</Typography.Text>
          </Col>
          <Col span={5} style={{textAlign: 'right'}}>
            <Typography.Text style={{color: 'rgba(0, 0, 0, 0.85)'}}>问题标题：</Typography.Text>
          </Col>
          <Col span={19}>
            <Typography.Text>{detail.title}</Typography.Text>
          </Col>
          <Col span={5} style={{textAlign: 'right'}}>
            <Typography.Text style={{color: 'rgba(0, 0, 0, 0.85)'}}>答案：</Typography.Text>
          </Col>
          <Col span={19}>
            <Typography.Text>{detail.content}</Typography.Text>
          </Col>
          <Col span={5} style={{textAlign: 'right'}}>
            <Typography.Text style={{color: 'rgba(0, 0, 0, 0.85)'}}>图片：</Typography.Text>
          </Col>
          <Col span={19}>
            <Album disabled fileList={detail.imgUrlList?.map(value => ({uid: value, url: getImg(value)}))}>
              {null}
            </Album>
          </Col>
          <Col span={5} style={{textAlign: 'right'}}>
            <Typography.Text style={{color: 'rgba(0, 0, 0, 0.85)'}}>视频：</Typography.Text>
          </Col>
          <Col span={19}>
            <Typography.Text>
              {detail.videoUrlList?.map((value, i) => (
                <a key={i} href={value} target={'_blank'} rel='noreferrer'>
                  {value}
                </a>
              ))}
            </Typography.Text>
          </Col>
          {/*todo: 图片*/}
        </Row>
      </Modal>
    </Page>
  )
}
