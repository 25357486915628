import React, {useCallback, useEffect, useState} from 'react'
import {Button, Descriptions, Divider, Form, Image, Input, message, Modal, PageHeader, Row, Space} from 'antd'
import commonStyles from '@/assets/styles/common.module.less'
import {projectModel} from '@/store/models/Project'
import {getImg} from '@/utils/util'
import {useCodeSync} from '@/store/models/Common'
import {Page} from '@/components/Page'
import {useAppHistory, useAppLocation} from '@/common/hooks/useRouter'

const {Item} = Descriptions

const LogDetailTitle = React.memo(function (props: {title: string}) {
  const {title} = props
  return (
    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
      <div style={{width: '6px', height: '14px', backgroundColor: '#00B7AE'}} />
      <span style={{color: '#000000', marginLeft: '5px', fontSize: '14px', fontWeight: 'bold'}}>{title}</span>
    </div>
  )
})

const NodeAudit = React.memo(
  (args: Pick<ProjectWorkNodeHistoryVO, 'auditComplete' | 'auditTime' | 'auditUser' | 'auditRemark'>) => {
    const {auditComplete, auditTime, auditUser, auditRemark} = args
    return (
      <Descriptions column={1} title={<LogDetailTitle title={'审批信息'} />}>
        <Item label={'审批状态'}>{auditComplete}</Item>
        {!!auditRemark && <Item label={'驳回意见'}>{auditRemark}</Item>}
        <Item label={'审批时间'}>{auditTime}</Item>
        <Item label={'审批人员'}>{auditUser}</Item>
      </Descriptions>
    )
  }
)

const NodeSubmit = React.memo(
  (
    args: Pick<
      ProjectWorkNodeHistoryVO,
      'imageList' | 'videoList' | 'submitTime' | 'submitName' | 'text' | 'infoTypeList'
    > & {
      onClick?: (imgUrl: string) => void
    }
  ) => {
    const {imageList = [], videoList = [], submitTime, submitName, onClick, text, infoTypeList} = args
    return (
      <Descriptions column={1} title={<LogDetailTitle title={'提交信息'} />}>
        {infoTypeList.includes('text') && <Item label={'文字描述'}>{text}</Item>}
        {infoTypeList.includes('image') && (
          <Item label={'图片'}>
            <Image.PreviewGroup>
              {imageList.map(item => (
                <div key={item.workFileId} style={{margin: '0 10px'}}>
                  <Image
                    width={88}
                    height={88}
                    src={getImg(item.url, {w: 88 * 2})}
                    preview={{src: getImg(item.url, {q: 75})}}
                    alt={'图片'}
                  />
                </div>
              ))}
            </Image.PreviewGroup>
          </Item>
        )}
        {infoTypeList.includes('video') && (
          <Item label={'视频'}>
            <Image.PreviewGroup>
              {videoList.map(item => (
                <video
                  width={'88px'}
                  height={'88px'}
                  key={item.workFileId}
                  src={getImg(item.url)}
                  style={{marginRight: '10px'}}
                  onClick={() => onClick?.(item.url)}
                />
              ))}
            </Image.PreviewGroup>
          </Item>
        )}
        <Item label={'提交时间'}>{submitTime}</Item>
        <Item label={'提交人员'}>{submitName}</Item>
      </Descriptions>
    )
  }
)

export default function EngineeringNodeDetail() {
  const history = useAppHistory()
  const location = useAppLocation<'/engineering/node-detail'>()
  const workNodeId = location.state?.workNodeId
  const workName = location.state?.workName

  const projectWorkNodeStatusCode = useCodeSync('project.workNodeStatus')

  const [form] = Form.useForm()
  const [rejectVisible, setRejectVisible] = useState(false)

  const [nodeInfo, setNodeInfo] = useState({} as ProjectWorkNodeVO)
  const [nodeCurrent, setNodeCurrent] = useState({} as ProjectWorkNodeHistoryVO)
  const [nodeHistory, setNodeHistory] = useState<ProjectWorkNodeHistoryVO[]>([])

  // 选中的图片
  const [selectImg, setSelectImg] = useState<{imgUrl: string; visible: boolean}>({imgUrl: '', visible: false})

  // 工程节点-历史
  const queryWorkNodeHistory = useCallback(
    (nodeStatus: string) => {
      projectModel.projectAdminWorkNodeHistory({workNodeId}).then(({response: {data}}) => {
        if (data.length !== 0) {
          if (['3', '5'].includes(nodeStatus)) {
            const [first, ...rest] = data
            setNodeCurrent(first)
            setNodeHistory(rest)
          }
          if (nodeStatus === '4') {
            setNodeHistory(data)
          }
        }
      })
    },
    [workNodeId]
  )

  const initRequest = useCallback(async () => {
    // 工程节点-详情
    const {
      response: {data},
    } = await projectModel.projectAdminWorkNodeInfo({workNodeId})
    setNodeInfo(data)

    queryWorkNodeHistory(data.workNodeStatus)
  }, [queryWorkNodeHistory, workNodeId])

  useEffect(() => {
    initRequest()
  }, [initRequest])

  const onNodeAudit = async (args: {workHistoryId: string; workNodeStatus: string; auditRemark?: string}) => {
    await projectModel.projectAdminWorkNodeAudit(args)
    message.success('操作成功！')
    queryWorkNodeHistory(nodeInfo.workNodeStatus)
  }

  const nodeAuditLoading = projectModel.projectAdminWorkNodeAudit.useLoading()

  return (
    <Page header={<PageHeader title={workName} ghost={false} />}>
      <div className={commonStyles.filterSection}>
        <Descriptions column={1} title={<LogDetailTitle title={nodeInfo.nodeName} />}>
          <Item label={'提交人员'}>{nodeInfo.operatorList?.map(item => item.name)?.join('、') ?? '无'}</Item>
          <Item label={'审批人员'}>{nodeInfo.auditList?.map(item => item.name)?.join('、') ?? '无'}</Item>
          <Item label={'说明'}>{nodeInfo.remark ?? '无'}</Item>
        </Descriptions>

        {!!nodeCurrent.workHistoryId && (
          <>
            <NodeSubmit
              infoTypeList={nodeCurrent.infoTypeList}
              text={nodeCurrent.text}
              imageList={nodeCurrent.imageList}
              submitName={nodeCurrent.submitName}
              submitTime={nodeCurrent.submitTime}
              videoList={nodeCurrent.videoList}
              onClick={imgUrl => setSelectImg({imgUrl, visible: true})}
            />
            <NodeAudit
              auditComplete={projectWorkNodeStatusCode[nodeCurrent.auditComplete]}
              auditRemark={nodeCurrent.auditRemark}
              auditTime={nodeCurrent.auditTime}
              auditUser={nodeCurrent.auditName}
            />
          </>
        )}

        {/* 待审核状态：审核和驳回 */}
        {nodeInfo.workNodeStatus === '3' && (
          <Row justify={'center'}>
            <Space>
              <Button
                type={'primary'}
                loading={nodeAuditLoading}
                onClick={async () => {
                  await onNodeAudit({workHistoryId: nodeCurrent.workHistoryId, workNodeStatus: '5'})
                  history.goBack()
                }}
              >
                通过
              </Button>
              <Button onClick={() => setRejectVisible(true)}>驳回</Button>
            </Space>
          </Row>
        )}

        {!!nodeHistory.length && <Divider>历史信息</Divider>}

        {nodeHistory.map(item => (
          <div key={item.workHistoryId}>
            <NodeAudit
              auditComplete={projectWorkNodeStatusCode[item.auditComplete]}
              auditRemark={item.auditRemark}
              auditTime={item.auditTime}
              auditUser={item.auditName}
            />
            <NodeSubmit
              infoTypeList={item.infoTypeList}
              text={item.text}
              imageList={item.imageList}
              submitName={item.submitName}
              submitTime={item.submitTime}
              videoList={item.videoList}
              onClick={imgUrl => setSelectImg({imgUrl, visible: true})}
            />
          </div>
        ))}
      </div>

      <Modal
        centered
        footer={null}
        title={'驳回'}
        visible={rejectVisible}
        onCancel={() => setRejectVisible(false)}
        afterClose={() => form.resetFields()}
      >
        <Form
          form={form}
          onFinish={async () => {
            const auditRemark = form.getFieldValue('auditRemark')
            await onNodeAudit({workHistoryId: nodeCurrent.workHistoryId, workNodeStatus: '4', auditRemark})
            setRejectVisible(false)
            history.goBack()
          }}
          onReset={() => setRejectVisible(false)}
        >
          <Form.Item name={'auditRemark'} label={'驳回说明'}>
            <Input.TextArea placeholder={'请输入'} allowClear />
          </Form.Item>
          <Row justify={'end'}>
            <Space>
              <Button htmlType={'reset'}>取消</Button>
              <Button type={'primary'} htmlType={'submit'} loading={nodeAuditLoading}>
                提交
              </Button>
            </Space>
          </Row>
        </Form>
      </Modal>

      {/** 预览视频 */}
      <Modal
        centered
        destroyOnClose
        visible={selectImg.visible}
        title={'视频预览'}
        footer={null}
        onCancel={() => setSelectImg(state => ({...state, visible: false}))}
      >
        <video controls src={getImg(selectImg.imgUrl)} width='100%' autoPlay />
      </Modal>
    </Page>
  )
}
