import React, {useCallback, useEffect, useState} from 'react'
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  message,
  PageHeader,
  Row,
  Select,
  Space,
  Tooltip,
  Typography,
} from 'antd'
import {MinusCircleOutlined, PlusCircleOutlined, QuestionCircleOutlined} from '@ant-design/icons'
import {Page} from '@/components/Page'
import commonStyle from '@/assets/styles/common.module.less'
import {sysModel} from '@/store/models/Sys'
import {stewardModel} from '@/store/models/Steward'
import {useAppHistory, useAppLocation} from '@/common/hooks/useRouter'
import moment from 'moment'
import {emit} from '@/common/hooks/listener'

// 奖惩措施
const modeList = [
  {label: '奖', value: 1},
  {label: '扣', value: 0},
]

// 工资类型
const salaryList = [
  // {label: '额外', value: '1'},
  {label: '绩效工资', value: '2'},
]

const unitList = [
  {label: '元', value: '1'},
  {label: '%', value: '2'},
]

export default function BeikeAddPerformance() {
  const history = useAppHistory()
  const location = useAppLocation<'/performance-beike/add-performance'>()
  const mode = location.state?.mode
  const planId = location.state?.planId

  const isInsert = mode === 'insert'
  const isEdit = mode === 'edit'
  const isCopy = mode === 'copy'
  const isReadonly = mode === 'readonly'

  const [form] = Form.useForm()

  // 城市列表
  const [cityList, setCityList] = useState<{id: string; label: string}[]>([])
  // 选中的城市列表
  const [selectedCityCodeList, setSelectedCityCodeList] = useState<string[]>([])

  const queryDetail = useCallback(async () => {
    const {
      response: {data: data1},
    } = await sysModel.sysConfigGet({k: 'CONFIG_SYS_CITY'})

    setCityList(data1?.city || [])

    if (planId) {
      const {
        response: {data: data2},
      } = await stewardModel.stewardSalaryPlanDetail({planId})

      form.setFieldsValue({
        planName: data2.planName,
        applyBeginDate: data2.applyBeginDate,
        applyEndDate: data2.applyEndDate,
        cityCodeList: data2.cityCodeList,
        bkConfig: data2.bkConfig,
      })
      setSelectedCityCodeList(data2.cityCodeList)
    }
  }, [form, planId])

  useEffect(() => {
    queryDetail()
  }, [queryDetail])

  const subLoading = stewardModel.stewardSalaryPlanUpsertBk.useLoading()

  return (
    <Page header={<PageHeader ghost={false} title={'返回'} onBack={() => history.goBack()} />}>
      <div className={commonStyle.filterSection}>
        <Form
          form={form}
          labelAlign={'right'}
          labelCol={{flex: '120px'}}
          disabled={isReadonly || isEdit}
          onFinish={async () => {
            await form.validateFields()
            const formValuse = form.getFieldsValue(true)
            // console.log('formValuse', formValuse)
            if (isInsert || isCopy) {
              await stewardModel.stewardSalaryPlanUpsertBk(formValuse)
            }
            if (isEdit) {
              await stewardModel.stewardSalaryPlanUpsertBk({...formValuse, planId})
            }
            message.success('提交成功！')
            emit('BeikeAddPerformance:Beike')
            history.goBack()
          }}
          initialValues={{
            bkConfig: {
              performanceIndex: ['1', '2', '3', '4', '5', '6', '7'],
              cityManagerRatingInfo: [{ladder: 1, level: 'A', rule: {salaryType: '2'}}],
              lateRateInfo: {rule: {salaryType: '2'}},
              oneTimeResolutionRateInfo: {rule: {salaryType: '2'}},
              lateOrdersInfo: {rule: {salaryType: '2'}, rule2: {salaryType: '2'}},
              participationRateInfo: {rule: {salaryType: '2'}},
              negativeReviewsInfo: {diffValue: '1', rule: {salaryType: '2'}},
              complaintOrdersInfo: {diffValue: '1', rule: {salaryType: '2'}},
            },
          }}
        >
          <Row gutter={20}>
            <Col span={12}>
              <Form.Item name={'planName'} label={'方案名称'} rules={[{required: true, message: '请输入方案名称'}]}>
                <Input placeholder={'请输入'} maxLength={100} showCount />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, nextValues) => prevValues.cityCodeList !== nextValues.cityCodeList}
          >
            {form1 => {
              const cityCodeList = form1.getFieldValue('cityCodeList') ?? []
              const options = isEdit
                ? cityList.map(item => ({...item, disabled: selectedCityCodeList.includes(item.id)}))
                : cityList

              return (
                <Row gutter={20}>
                  <Col span={12}>
                    <Form.Item
                      name={'cityCodeList'}
                      label={'适用城市'}
                      rules={[{required: true, message: '请选择适用城市'}]}
                    >
                      <Select
                        placeholder={'请选择'}
                        options={options}
                        fieldNames={{value: 'id'}}
                        showSearch
                        mode={'multiple'}
                        filterOption={(inputValue, option: any) => {
                          return option.label?.indexOf(inputValue) > -1
                        }}
                        disabled={isEdit ? false : undefined}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={3}>
                    <Checkbox
                      checked={cityList.length === cityCodeList.length}
                      onChange={({target: {checked}}) => {
                        if (checked) {
                          form1.setFieldValue(
                            'cityCodeList',
                            cityList.map(item => item.id)
                          )
                        } else {
                          if (isEdit) {
                            form1.setFieldValue('cityCodeList', selectedCityCodeList)
                          } else {
                            form1.setFieldValue('cityCodeList', [])
                          }
                        }
                      }}
                      disabled={isEdit ? false : undefined}
                    >
                      选择所有城市
                    </Checkbox>
                  </Col>
                </Row>
              )
            }}
          </Form.Item>

          <Form.Item
            name={'applyBeginDate'}
            label={'适用日期'}
            rules={[{required: true, message: '请选择适用时间'}]}
            getValueFromEvent={(_, formatString) => {
              form.setFieldValue('applyEndDate', formatString?.[1])
              return formatString?.[0]
            }}
            getValueProps={value => ({
              value: value ? [moment(value), moment(form.getFieldValue('applyEndDate'))] : value,
            })}
          >
            <DatePicker.RangePicker
              picker={'month'}
              disabled={isEdit ? [true, false] : isReadonly}
              disabledDate={date => (isEdit ? date.isBefore(moment().add(1, 'month').toDate()) : false)}
              allowClear={!isEdit}
            />
          </Form.Item>

          {/* 城市经理评级 */}
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, nextValues) =>
              prevValues.bkConfig.cityManagerRatingInfo !== nextValues.bkConfig.cityManagerRatingInfo
            }
          >
            {form1 => {
              return (
                <>
                  <Form.Item label={'城市经理评级'} required>
                    <Space>
                      <Tooltip
                        title={
                          <PerformanceTip
                            tipList={[
                              '城市经理评级',
                              `管家绩效工资X。`,
                              `评级为A，奖励10%，则绩效额外增加X*10%；评级为E，扣10%，则绩效扣除X*10%。`,
                            ]}
                          />
                        }
                        placement={'topLeft'}
                      >
                        <QuestionCircleOutlined />
                      </Tooltip>
                      <Button
                        type={'link'}
                        style={{padding: 0}}
                        onClick={() => {
                          form1.resetFields([['bkConfig', 'cityManagerRatingInfo']])
                        }}
                      >
                        重置
                      </Button>
                    </Space>
                  </Form.Item>
                  <Form.Item label={' '} colon={false}>
                    <Form.List name={['bkConfig', 'cityManagerRatingInfo']}>
                      {(fields, {add, remove}) =>
                        fields.map((field, index) => {
                          // 阶梯
                          const ladder = form1.getFieldValue(['bkConfig', 'cityManagerRatingInfo', index, 'ladder'])
                          // 级别
                          const level = form1.getFieldValue(['bkConfig', 'cityManagerRatingInfo', index, 'level'])

                          return (
                            <Row key={index}>
                              <Col span={2}>
                                <Space size={20}>
                                  <span>{`第${ladder}档`}</span>
                                  <span>{level}</span>
                                </Space>
                              </Col>
                              <PerformanceCombineItem
                                rewardPunishment={[index, 'rule', 'rewardPunishment']}
                                salaryType={[index, 'rule', 'salaryType']}
                                quantity={[index, 'rule', 'quantity']}
                                unit={[index, 'rule', 'unit']}
                              />
                              {fields.length - 1 === index && fields.length < 26 && (
                                <Col>
                                  <Space>
                                    <Button
                                      type={'link'}
                                      style={{padding: 0}}
                                      onClick={() => {
                                        add({
                                          ladder: index + 2,
                                          level: String.fromCharCode(66 + index),
                                          rule: {salaryType: '2'},
                                        })
                                      }}
                                    >
                                      <PlusCircleOutlined />
                                    </Button>
                                    {fields.length > 1 && (
                                      <Button type={'link'} style={{padding: 0}} onClick={() => remove(index)}>
                                        <MinusCircleOutlined />
                                      </Button>
                                    )}
                                  </Space>
                                </Col>
                              )}
                            </Row>
                          )
                        })
                      }
                    </Form.List>
                  </Form.Item>
                </>
              )
            }}
          </Form.Item>

          {/* 迟到率（严） */}
          <Form.Item label={'迟到率（严）'}>
            <Row>
              <Space>
                <Tooltip
                  title={
                    <PerformanceTip
                      tipList={[
                        '迟到率',
                        `①迟到率-基线，>0时，每超*%，绩效工资扣**元
                                  如：基线5%，迟到率6.7%，每1%扣100元，则扣除绩效为⌈(6.7%-5%）/1%⌉*100=200元`,
                        `②迟到率-基线，<=0时，不扣`,
                      ]}
                    />
                  }
                  placement={'topLeft'}
                >
                  <QuestionCircleOutlined />
                </Tooltip>
                <Button
                  type={'link'}
                  style={{padding: 0}}
                  onClick={() => {
                    form.resetFields([['bkConfig', 'lateRateInfo']])
                  }}
                >
                  重置
                </Button>
              </Space>
            </Row>
            <Row>
              <Col span={4}>
                <Form.Item
                  name={['bkConfig', 'lateRateInfo', 'baseValue']}
                  label={'基线'}
                  rules={[{required: true, message: '请输入基线'}]}
                >
                  <InputNumber style={{width: '100%'}} addonAfter={'%'} min={0} max={100} />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={4}>
                <Form.Item
                  name={['bkConfig', 'lateRateInfo', 'diffValue']}
                  label={'每超'}
                  rules={[{required: true, message: '请输入'}]}
                >
                  <InputNumber style={{width: '100%'}} addonAfter={'%'} min={0} max={100} />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item label={'（不足向上取整），'} colon={false} />
              </Col>
              <PerformanceCombineItem
                rewardPunishment={['bkConfig', 'lateRateInfo', 'rule', 'rewardPunishment']}
                salaryType={['bkConfig', 'lateRateInfo', 'rule', 'salaryType']}
                quantity={['bkConfig', 'lateRateInfo', 'rule', 'quantity']}
                unit={['bkConfig', 'lateRateInfo', 'rule', 'unit']}
              />
            </Row>
          </Form.Item>

          {/* 一次性解决率 */}
          <Form.Item label={'一次性解决率'}>
            <Row>
              <Space>
                <Tooltip
                  title={
                    <PerformanceTip
                      tipList={[
                        '一次性解决率',
                        `①基线-一次性解决率，>0时，每超*%，绩效工资扣**元
如：基线90%，一次性解决率86.7%，每1%扣100元，则扣除绩效为⌈(90%-86.7%）/1%⌉*100=400元`,
                        `②基线-一次性解决率，<=0时，不扣`,
                      ]}
                    />
                  }
                  placement={'topLeft'}
                >
                  <QuestionCircleOutlined />
                </Tooltip>
                <Button
                  type={'link'}
                  style={{padding: 0}}
                  onClick={() => {
                    form.resetFields([['bkConfig', 'oneTimeResolutionRateInfo']])
                  }}
                >
                  重置
                </Button>
              </Space>
            </Row>
            <Row>
              <Col span={4}>
                <Form.Item
                  name={['bkConfig', 'oneTimeResolutionRateInfo', 'baseValue']}
                  label={'基线'}
                  rules={[{required: true, message: '请输入基线'}]}
                >
                  <InputNumber style={{width: '100%'}} addonAfter={'%'} min={0} max={100} />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={4}>
                <Form.Item
                  name={['bkConfig', 'oneTimeResolutionRateInfo', 'diffValue']}
                  label={'每低'}
                  rules={[{required: true, message: '请输入'}]}
                >
                  <InputNumber style={{width: '100%'}} addonAfter={'%'} min={0} max={100} />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item label={'（不足向上取整），'} colon={false} />
              </Col>
              <PerformanceCombineItem
                rewardPunishment={['bkConfig', 'oneTimeResolutionRateInfo', 'rule', 'rewardPunishment']}
                salaryType={['bkConfig', 'oneTimeResolutionRateInfo', 'rule', 'salaryType']}
                quantity={['bkConfig', 'oneTimeResolutionRateInfo', 'rule', 'quantity']}
                unit={['bkConfig', 'oneTimeResolutionRateInfo', 'rule', 'unit']}
              />
            </Row>
          </Form.Item>

          {/* 迟到单 */}
          <Form.Item label={'迟到单'}>
            <Row>
              <Space>
                <Tooltip
                  title={
                    <PerformanceTip
                      tipList={[
                        '迟到单',
                        `第N单，应扣：50+（N-1）*40；N单，总应扣：N*50+[N*(N-1)*40]/2
如：迟到6单，首单扣50，第2单开始，每单额外扣40，则扣除绩效为6*50+6*（6-1）*40/2=900元`,
                      ]}
                    />
                  }
                  placement={'topLeft'}
                >
                  <QuestionCircleOutlined />
                </Tooltip>
                <Button
                  type={'link'}
                  style={{padding: 0}}
                  onClick={() => {
                    form.resetFields([['bkConfig', 'lateOrdersInfo']])
                  }}
                >
                  重置
                </Button>
              </Space>
            </Row>
            <Row>
              <Col span={4}>
                <Form.Item
                  name={['bkConfig', 'lateOrdersInfo', 'baseValue']}
                  label={'基线'}
                  rules={[{required: true, message: '请输入基线'}]}
                >
                  <InputNumber style={{width: '100%'}} addonAfter={'单'} min={0} />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={4}>
                <Form.Item
                  name={['bkConfig', 'lateOrdersInfo', 'diffValue']}
                  label={'第'}
                  rules={[{required: true, message: '请输入'}]}
                >
                  <InputNumber style={{width: '100%'}} addonAfter={'单'} min={0} />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item label={'，'} colon={false} />
              </Col>
              <PerformanceCombineItem
                rewardPunishment={['bkConfig', 'lateOrdersInfo', 'rule', 'rewardPunishment']}
                salaryType={['bkConfig', 'lateOrdersInfo', 'rule', 'salaryType']}
                quantity={['bkConfig', 'lateOrdersInfo', 'rule', 'quantity']}
                unit={['bkConfig', 'lateOrdersInfo', 'rule', 'unit']}
              />
            </Row>
            <Row>
              <Col span={4}>
                <Form.Item
                  name={['bkConfig', 'lateOrdersInfo', 'diff2Value']}
                  label={'第'}
                  rules={[{required: true, message: '请输入'}]}
                >
                  <InputNumber style={{width: '100%'}} addonAfter={'单'} min={0} />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item label={'开始，每单比前一单额外'} colon={false} />
              </Col>
              <PerformanceCombineItem
                rewardPunishment={['bkConfig', 'lateOrdersInfo', 'rule2', 'rewardPunishment']}
                salaryType={['bkConfig', 'lateOrdersInfo', 'rule2', 'salaryType']}
                quantity={['bkConfig', 'lateOrdersInfo', 'rule2', 'quantity']}
                unit={['bkConfig', 'lateOrdersInfo', 'rule2', 'unit']}
              />
            </Row>
          </Form.Item>

          {/* 参评率 */}
          <Form.Item label={'参评率'}>
            <Row>
              <Space>
                <Tooltip
                  title={
                    <PerformanceTip
                      tipList={[
                        '参评率',
                        `①基线-参评率，>0时，每超*%，绩效工资扣**元
如：基线80%，参评率76.7%，每1%扣100元，则扣除绩效为⌈(80%-76.7%）/1%⌉*100=400元`,
                        `②基线-参评率，<=0时，不扣`,
                      ]}
                    />
                  }
                  placement={'topLeft'}
                >
                  <QuestionCircleOutlined />
                </Tooltip>
                <Button
                  type={'link'}
                  style={{padding: 0}}
                  onClick={() => {
                    form.resetFields([['bkConfig', 'participationRateInfo']])
                  }}
                >
                  重置
                </Button>
              </Space>
            </Row>
            <Row>
              <Col span={4}>
                <Form.Item
                  name={['bkConfig', 'participationRateInfo', 'baseValue']}
                  label={'基线'}
                  rules={[{required: true, message: '请输入基线'}]}
                >
                  <InputNumber style={{width: '100%'}} addonAfter={'%'} min={0} max={100} />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col span={4}>
                <Form.Item
                  name={['bkConfig', 'participationRateInfo', 'diffValue']}
                  label={'每低'}
                  rules={[{required: true, message: '请输入'}]}
                >
                  <InputNumber style={{width: '100%'}} addonAfter={'%'} min={0} max={100} />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item label={'（不足向上取整），'} colon={false} />
              </Col>
              <PerformanceCombineItem
                rewardPunishment={['bkConfig', 'participationRateInfo', 'rule', 'rewardPunishment']}
                salaryType={['bkConfig', 'participationRateInfo', 'rule', 'salaryType']}
                quantity={['bkConfig', 'participationRateInfo', 'rule', 'quantity']}
                unit={['bkConfig', 'participationRateInfo', 'rule', 'unit']}
              />
            </Row>
          </Form.Item>

          {/* 差评单 */}
          <Form.Item label={'差评单'}>
            <Row>
              <Space>
                <Tooltip
                  title={
                    <PerformanceTip tipList={['差评单N单，基线n单', `①N-n>0时，扣（N-n）*100`, `②N-n<=0时，不扣`]} />
                  }
                  placement={'topLeft'}
                >
                  <QuestionCircleOutlined />
                </Tooltip>
                <Button
                  type={'link'}
                  style={{padding: 0}}
                  onClick={() => {
                    form.resetFields([['bkConfig', 'negativeReviewsInfo']])
                  }}
                >
                  重置
                </Button>
              </Space>
            </Row>
            <Row>
              <Col span={4}>
                <Form.Item
                  name={['bkConfig', 'negativeReviewsInfo', 'baseValue']}
                  label={'基线'}
                  rules={[{required: true, message: '请输入基线'}]}
                >
                  <InputNumber style={{width: '100%'}} addonAfter={'单'} min={0} />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Item label={'超出，每单'} required={true} />
              </Col>
              <PerformanceCombineItem
                rewardPunishment={['bkConfig', 'negativeReviewsInfo', 'rule', 'rewardPunishment']}
                salaryType={['bkConfig', 'negativeReviewsInfo', 'rule', 'salaryType']}
                quantity={['bkConfig', 'negativeReviewsInfo', 'rule', 'quantity']}
                unit={['bkConfig', 'negativeReviewsInfo', 'rule', 'unit']}
              />
            </Row>
          </Form.Item>

          {/* 投诉单 */}
          <Form.Item label={'投诉单'}>
            <Row>
              <Space>
                <Tooltip
                  title={
                    <PerformanceTip tipList={['投诉单N单，基线n单', `①N-n>0时，扣（N-n）*200`, `②N-n<=0时，不扣`]} />
                  }
                  placement={'topLeft'}
                >
                  <QuestionCircleOutlined />
                </Tooltip>
                <Button
                  type={'link'}
                  style={{padding: 0}}
                  onClick={() => {
                    form.resetFields([['bkConfig', 'complaintOrdersInfo']])
                  }}
                >
                  重置
                </Button>
              </Space>
            </Row>
            <Row>
              <Col span={4}>
                <Form.Item
                  name={['bkConfig', 'complaintOrdersInfo', 'baseValue']}
                  label={'基线'}
                  rules={[{required: true, message: '请输入基线'}]}
                >
                  <InputNumber style={{width: '100%'}} addonAfter={'单'} min={0} />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Item label={'超出，每单'} required={true} />
              </Col>
              <PerformanceCombineItem
                rewardPunishment={['bkConfig', 'complaintOrdersInfo', 'rule', 'rewardPunishment']}
                salaryType={['bkConfig', 'complaintOrdersInfo', 'rule', 'salaryType']}
                quantity={['bkConfig', 'complaintOrdersInfo', 'rule', 'quantity']}
                unit={['bkConfig', 'complaintOrdersInfo', 'rule', 'unit']}
              />
            </Row>
          </Form.Item>

          {!isReadonly && (
            <Row justify={'center'}>
              <Space>
                <Button disabled={isReadonly} type={'primary'} htmlType={'submit'} loading={subLoading}>
                  提交
                </Button>
                {!isEdit && (
                  <Button disabled={isReadonly} htmlType={'reset'}>
                    重置
                  </Button>
                )}
              </Space>
            </Row>
          )}
        </Form>
      </div>
    </Page>
  )
}

// 奖惩组件
function PerformanceCombineItem({
  rewardPunishment,
  salaryType,
  quantity,
  unit,
}: {
  rewardPunishment: (string | number)[]
  salaryType: (string | number)[]
  quantity: (string | number)[]
  unit: (string | number)[]
}) {
  return (
    <>
      <Col span={3}>
        <Form.Item name={rewardPunishment} rules={[{required: true, message: '请选择'}]}>
          <Select options={modeList} placeholder={'请选择'} />
        </Form.Item>
      </Col>
      <Col span={3}>
        <Form.Item name={salaryType}>
          <Select options={salaryList} placeholder={'请选择'} />
        </Form.Item>
      </Col>
      <Col span={3}>
        <Form.Item name={quantity} rules={[{required: true, message: '请输入'}]}>
          <InputNumber style={{width: '100%'}} placeholder={'请输入'} min={0} />
        </Form.Item>
      </Col>
      <Col span={3}>
        <Form.Item name={unit} rules={[{required: true, message: '请选择'}]}>
          <Select options={unitList} placeholder={'请选择'} />
        </Form.Item>
      </Col>
    </>
  )
}

function PerformanceTip({tipList}: {tipList: string[]}) {
  return (
    <>
      {tipList.map(item => (
        <Typography.Paragraph key={item} style={{color: '#fff'}}>
          {item}
        </Typography.Paragraph>
      ))}
    </>
  )
}
