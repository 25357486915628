import {Album} from '@/components/Album'
import DividerLine from '@/pages/property/component/DividerLine'
import {teamModel} from '@/store/models/Team'
import {getImg} from '@/utils/util'
import {Button, Descriptions, Drawer, message, Popconfirm, Space} from 'antd'
import React, {useCallback, useEffect, useState} from 'react'

interface CutServiceDetailProps extends Pick<StewardOrderQueryVO, 'orderId' | 'teamPid'> {
  onCancel: () => void
}

function CutServiceDetail({onCancel, orderId, teamPid}: CutServiceDetailProps, ref) {
  const [list, setList] = useState<TeamWalletRecordVO[]>([])
  const [pointInfo, setPointInfo] = useState({} as TeamPointsVO)
  const queryInfo = useCallback(() => {
    if (orderId && teamPid) {
      teamModel
        .teamBalanceRecordList({pageable: false, pageNum: 1, pageSize: 1, stewardOrderId: orderId, deductType: '2'})
        .then(({response: {data}}) => {
          setList(data)
        })

      teamModel.teamPointsGetByTeamId({teamId: teamPid}).then(({response: {data}}) => {
        setPointInfo(data)
      })
    }
  }, [orderId, teamPid])

  useEffect(() => {
    queryInfo()
  }, [queryInfo])

  return (
    <Drawer title={'服务单扣款详情'} visible={!!orderId} width={700} onClose={onCancel}>
      {list.map(item => (
        <div key={item.recordId} style={{marginBottom: 50}}>
          <DividerLine title='基本信息' style={{marginBottom: 10}} />
          <Descriptions column={2}>
            <Descriptions.Item label={'客户名称'}>{item.teamOwnerName}</Descriptions.Item>
            <Descriptions.Item label={'组织'}>{item.teamPname}</Descriptions.Item>
            <Descriptions.Item label={'账户余额'}>{item.walletBalance}元</Descriptions.Item>
            <Descriptions.Item label={'果币数量'}>{pointInfo.points}</Descriptions.Item>
            <Descriptions.Item label={'费用类型'}>{item.recordCategoryName}</Descriptions.Item>
            <Descriptions.Item label={'服务单号'}>{item.stewardOrderId}</Descriptions.Item>
            <Descriptions.Item label={'扣款金额'}>{item.recordAmtTotal}元</Descriptions.Item>
            <Descriptions.Item label={'账户支付'}>{item.recordAmt}元</Descriptions.Item>
            <Descriptions.Item label={'果币支付'}>
              {item.pointsRecord ? item.pointsRecord * pointInfo.pointsRate : 0}元
            </Descriptions.Item>
          </Descriptions>
          <Descriptions column={1}>
            <Descriptions.Item label={'备注'}>{item.remark ?? '无'}</Descriptions.Item>
          </Descriptions>
          <Descriptions column={1}>
            <Descriptions.Item label={'图片'}>
              {item.recordImgUrl ? (
                <Album disabled fileList={[{uid: item.recordImgUrl, url: getImg(item.recordImgUrl)}]}>
                  {null}
                </Album>
              ) : (
                '无'
              )}
            </Descriptions.Item>
          </Descriptions>
          <Descriptions column={2}>
            <Descriptions.Item label={'创建人'}>{item.creatorName}</Descriptions.Item>
            {item.status === '0' && <Descriptions.Item label={'撤销人'}>{item.cancelName}</Descriptions.Item>}
            <Descriptions.Item label={'创建时间'}>{item.insertTime}</Descriptions.Item>
            {item.status === '0' && <Descriptions.Item label={'撤销时间'}>{item.cancelTime}</Descriptions.Item>}
            <Descriptions.Item label={'状态'}>{item.status === '1' ? '有效' : '无效'}</Descriptions.Item>
            {item.status === '0' && (
              <Descriptions.Item label={'撤销后余额'}>{item.cancelWalletBalance}元</Descriptions.Item>
            )}
          </Descriptions>
          {item.status === '1' && (
            <Space style={{marginTop: 20}}>
              <Button onClick={onCancel}>返回</Button>
              <Popconfirm
                title={`你确定撤销这笔扣款吗？`}
                placement={'top'}
                okText={'确定'}
                cancelText={'取消'}
                onConfirm={async () => {
                  await teamModel.teamBalanceRecordCancel(item.recordId)
                  message.success('撤销扣款成功！')
                  queryInfo()
                }}
              >
                <Button type={'primary'}>撤销扣款</Button>
              </Popconfirm>
            </Space>
          )}
        </div>
      ))}
    </Drawer>
  )
}

export default React.forwardRef(CutServiceDetail)
