import React, {useCallback, useEffect, useImperativeHandle, useState} from 'react'
import {Button, Form, InputNumber, message, Modal, Row, Space, Tag, Tooltip, Transfer, Typography} from 'antd'
import {stewardModel} from '@/store/models/Steward'
import {getImg} from '@/utils/util'
import {useCodeArraySync, useCodeSync} from '@/store/models/Common'

interface EditServiceProps {
  serviceId: string
  customerId: string
  onSuccess: (args: {feeList: any[]; customChargeList: StewardServiceCustomChargeBindVO[]}) => void
  chargeCustomList?: StewardServiceCustomChargeBindVO[]
}

function EditService(props: EditServiceProps, ref) {
  const {serviceId, customerId, onSuccess, chargeCustomList} = props

  const [form] = Form.useForm()
  const [visible, setVisible] = useState(false)
  const [detail, setDetail] = useState({} as StewardServiceVO)

  const stewardFeeTypeCode = useCodeSync('steward.fee.type')
  const stewardFeeTypeArr = useCodeArraySync('steward.fee.type')

  const [chargeForm] = Form.useForm()
  const [chargeVisible, setChargeVisible] = useState(false)
  const [chargeList, setChargeList] = useState<(StewardServiceChargeVO & {description: string})[]>([])
  const [chargeKeys, setChargeKeys] = useState<string[]>([])

  useImperativeHandle(ref, () => ({
    onDisplay: () => {
      setVisible(true)
    },
    onSetForm: (serviceId, feeList, chargeCustomList: StewardServiceCustomChargeBindVO[] = []) => {
      const obj = chargeCustomList.reduce((acc, item) => {
        if (acc[item.chargeId]) {
          acc[item.chargeId] = [...acc[item.chargeId], item]
        } else {
          acc[item.chargeId] = [item]
        }
        return acc
      }, {})
      setChargeKeys(Object.keys(obj))
      form.setFieldsValue({feeList: [], ...obj})
      stewardModel.stewardAdminServiceDetail({serviceId}).then(({response: {data}}) => {
        const {serviceFeeList} = data
        setDetail(data)
        form.setFieldsValue({
          ...(feeList?.length
            ? {feeList: feeList}
            : serviceFeeList.length
            ? {feeList: serviceFeeList.map(item => ({name: item?.name, desc: item?.desc}))}
            : {feeList: []}),
        })
      })
    },
  }))

  useEffect(() => {
    const newList = chargeCustomList.map(i => {
      if (i.serviceId === serviceId) return i.chargeId
    })
    stewardModel
      .stewardAdminServiceChargeList({pageable: false, enable: 1, categoryId: '1'} as StewardServiceChargeQueryDTO)
      .then(({response: {data}}) => {
        setChargeList(
          data.map(item => {
            if (newList.includes(item.chargeId)) {
              return {
                ...item,
                description: '已编辑',
              }
            } else {
              return {
                ...item,
                description: '未编辑',
              }
            }
          })
        )
      })
  }, [chargeCustomList, serviceId])

  const newDetailList = useCallback(
    id => {
      return stewardFeeTypeArr.map(item => ({
        chargeAmt: null,
        chargeType: item.id,
        chargeId: id,
        customerId,
        serviceId,
      }))
    },
    [customerId, serviceId, stewardFeeTypeArr]
  )

  return (
    <Modal
      open={visible}
      centered
      width={800}
      title={'编辑服务'}
      footer={null}
      onCancel={() => setVisible(false)}
      afterClose={() => {
        form.resetFields()
        setChargeKeys([])
      }}
    >
      <div>
        <Form
          labelCol={{span: 5}}
          form={form}
          onFinish={() => {
            const len = chargeKeys.length

            const arr = []
            chargeList.forEach(item => {
              if (chargeKeys.includes(item.chargeId)) {
                arr.push(item)
              }
            })
            if (arr.some(item => item.description === '未编辑')) return message.warn('有已选的收费项的价格未编辑')
            const formValues = form.getFieldsValue(true)

            if (!len) return message.error('请添加收费项')
            const tipList: string[] = []
            for (let i = 0; i < len; i++) {
              const key = chargeKeys[i]
              const list = formValues[key]

              if (!list.every(item => item.chargeAmt >= 0)) {
                const item = chargeList.find(item => item.chargeId === chargeKeys[i])
                tipList.push(item.chargeName)
              }
            }
            if (tipList.length) return message.error(`请编辑${tipList.join('、')}后再提交！`)

            const customChargeList = []
            for (let i = 0; i < len; i++) {
              const key = chargeKeys[i]
              const list = formValues[key]
              customChargeList.push(...list)
            }

            onSuccess?.({feeList: formValues.feeList, customChargeList})
            setVisible(false)
          }}
        >
          <Form.Item label={'服务名称'} style={{marginBottom: 0}}>
            <Typography.Text>{detail.name}</Typography.Text>
          </Form.Item>
          <Form.Item label={'服务内容'} style={{marginBottom: 0}}>
            <Typography.Text>{detail.desc}</Typography.Text>
          </Form.Item>
          <Form.Item label={'故障现象'}>
            <div>
              <Space>
                {detail.tags?.split(',').map((item, index) => (
                  <Tag key={index}>{item}</Tag>
                ))}
              </Space>
            </div>
          </Form.Item>
          {detail.imgUrlThumb ? (
            <Form.Item label={'缩略图'}>
              <img src={getImg(detail.imgUrlThumb)} width={80} height={80} />
            </Form.Item>
          ) : null}
          {detail.imgUrlContent ? (
            <Form.Item label={'大图'}>
              <img src={getImg(detail.imgUrlContent)} width={80} height={80} />
            </Form.Item>
          ) : null}
          <Form.Item label={' '} colon={false}>
            <Transfer
              rowKey={item => item.chargeId}
              dataSource={chargeList}
              render={item => {
                if (chargeKeys.includes(item.chargeId)) {
                  return (
                    <div style={{display: 'flex', alignItems: 'center'}}>
                      <div style={{width: 116, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>
                        <Tooltip title={item.chargeName}>{item.chargeName}</Tooltip>
                      </div>
                      <span style={{color: 'red', margin: '0 15px'}}>{item.description}</span>
                      <div
                        style={{color: '#00B7AE'}}
                        onClick={event => {
                          event.stopPropagation()
                          const detailList = form.getFieldValue(item.chargeId)
                          chargeForm.setFieldsValue({detailList})
                          setChargeVisible(true)
                        }}
                      >
                        编辑
                      </div>
                    </div>
                    // <div
                    //   style={{
                    //     display: 'flex',
                    //     flexDirection: 'row',
                    //     alignItems: 'center',
                    //     width: '100%',
                    //     flexWrap: 'nowrap',
                    //   }}
                    // >
                    //   <div style={{flex: 1, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap'}}>
                    //     {item.chargeName}
                    //   </div>
                    //   <Button
                    //     type={'link'}
                    //     style={{padding: 0}}
                    //     onClick={event => {
                    //       event.stopPropagation()
                    //       const detailList = form.getFieldValue(item.chargeId)
                    //       chargeForm.setFieldsValue({detailList})
                    //       setChargeVisible(true)
                    //     }}
                    //   >
                    //     编辑
                    //   </Button>
                    // </div>
                  )
                }

                return item.chargeName
              }}
              targetKeys={chargeKeys}
              onChange={targetKeys => {
                const newKeys = targetKeys.filter(item => !chargeKeys.includes(item))
                setChargeKeys(targetKeys)
                const obj = newKeys.reduce((acc, item) => {
                  acc[item] = newDetailList(item)
                  return acc
                }, {})
                form.setFieldsValue({...obj})
              }}
              showSearch
              filterOption={(inputValue, item) => item.chargeName.indexOf(inputValue as any) > -1}
              listStyle={{height: 300, width: '45%'}}
            />
          </Form.Item>
          <Row justify={'end'}>
            <Space>
              <Button type={'primary'} htmlType={'submit'}>
                保存并提交
              </Button>
              <Button
                onClick={() => {
                  setVisible(false)
                  // form.setFieldsValue({feeList: []})
                }}
              >
                返回
              </Button>
            </Space>
          </Row>
        </Form>
      </div>

      <Modal
        open={chargeVisible}
        centered
        title={'服务收费编辑'}
        footer={null}
        onCancel={() => setChargeVisible(false)}
        afterClose={() => chargeForm.resetFields()}
      >
        <Form
          form={chargeForm}
          onReset={() => setChargeVisible(false)}
          onFinish={() => {
            const {detailList} = chargeForm.getFieldsValue(true)

            const chargeId = detailList[0]?.chargeId
            form.setFieldsValue({[chargeId]: detailList})
            setChargeList(
              chargeList.map(item => {
                if (item.chargeId === chargeId) {
                  return {
                    ...item,
                    description: '已编辑',
                  }
                } else {
                  return item
                }
              })
            )
            setChargeVisible(false)
          }}
        >
          <Form.List name={'detailList'}>
            {fields => {
              return fields.map(field => {
                const chargeTypeId = chargeForm.getFieldValue(['detailList', field.name, 'chargeType'])
                const chargeTypeLabel = stewardFeeTypeCode[chargeTypeId]
                return (
                  <Form.Item
                    key={field.key}
                    label={chargeTypeLabel}
                    name={[field.name, 'chargeAmt']}
                    fieldKey={[field.fieldKey, 'chargeAmt']}
                    rules={[{required: true}]}
                  >
                    <InputNumber min={0} step={1} placeholder={'请输入服务单价（元）'} style={{width: '200px'}} />
                  </Form.Item>
                )
              })
            }}
          </Form.List>

          <Row justify={'end'}>
            <Space>
              <Button htmlType={'reset'}>取消</Button>
              <Button htmlType={'submit'} type={'primary'}>
                确定
              </Button>
            </Space>
          </Row>
        </Form>
      </Modal>
    </Modal>
  )
}

export default React.forwardRef(EditService)
