import React, {useCallback, useEffect, useState} from 'react'
import {Breadcrumb} from '@/components/Breadcrumb'
import commonStyles from '@/assets/styles/common.module.less'
import {Button, Col, Form, Input, message, Modal, Row, Select, Space, Table} from 'antd'
import {useCodeArraySync, useCodeSync} from '@/store/models/Common'
import {sysRoleModel} from '@/store/models/SysRole'
import {projectModel} from '@/store/models/Project'
import {Page} from '@/components/Page'
import {usePermission} from '@/components/Permission'
import {getPaginationProps} from '@/common/biz/getPaginationProps'

const {Option} = Select

const operateCode = {
  add: '新增节点',
  edit: '编辑节点',
  check: '节点信息',
  del: '您确定要删除？',
  enable: '您确定要启用？',
  disable: '您确定要禁用？',
}

export default function NodeManagement() {
  const [auth] = usePermission()

  const projectNodeTypeArr = useCodeArraySync('project.nodeType')
  const projectNodeTypeCode = useCodeSync('project.nodeType')
  const projectNodeStatusArr = useCodeArraySync('project.nodeStatus')
  const projectNodeStatusCode = useCodeSync('project.nodeStatus')

  const [form] = Form.useForm<ProjectAdminNodeListDTO>()
  const [nodeForm] = Form.useForm()
  const [mVisible, setMVisible] = useState<'add' | 'edit' | 'check' | 'del' | 'enable' | 'disable' | null>(null)

  // 用户组列表
  const [roleList, setRoleList] = useState<SysRoleVO[]>([])
  useEffect(() => {
    sysRoleModel
      .sysRoleList({status: '1', pageable: false} as SysListDTO)
      .then(({response: {data}}) => setRoleList(data))
  }, [])

  // 节点列表
  const [nodeTotal, setNodeTotal] = useState(0)
  const [nodeList, setNodeList] = useState<ProjectNodeVO[]>([])

  const queryNodeList = useCallback(() => {
    const values = form.getFieldsValue(true)
    projectModel.projectAdminNodeList(values).then(({response: {data, total}}) => {
      setNodeTotal(total)
      setNodeList(data)
    })
  }, [form])

  useEffect(() => {
    form.submit()
  }, [form])

  const dataLoading = projectModel.projectAdminNodeList.useLoading()
  const upsertLoading = projectModel.projectAdminNodeUpsert.useLoading()
  const statusLoading = projectModel.projectAdminNodeStatus.useLoading()
  const deleteLoading = projectModel.projectAdminNodeDelete.useLoading()
  const infoLoading = projectModel.projectAdminNodeInfo.useLoading()

  const renderOperate = (args: Pick<ProjectNodeVO, 'nodeCode' | 'nodeStatus'>) => {
    const {nodeCode, nodeStatus} = args
    return [
      {
        when: nodeStatus !== '1' && auth('023076002'),
        title: '启用',
        loading: false,
        onClick: () => {
          nodeForm.setFieldsValue({nodeCode})
          setMVisible('enable')
        },
      },
      {
        when: nodeStatus === '1' && auth('023076002'),
        title: '暂停',
        loading: false,
        onClick: () => {
          nodeForm.setFieldsValue({nodeCode})
          setMVisible('disable')
        },
      },
      {
        when: auth('004076002'),
        title: '删除',
        loading: false,
        onClick: () => {
          nodeForm.setFieldsValue({nodeCode})
          setMVisible('del')
        },
      },
      {
        when: auth('002076002'),
        title: '编辑',
        loading: infoLoading,
        onClick: async () => {
          const {
            response: {data},
          } = await projectModel.projectAdminNodeInfo({nodeCode})
          nodeForm.setFieldsValue(data)
          setMVisible('edit')
        },
      },
      {
        when: auth('001076002'),
        title: '查看',
        loading: infoLoading,
        onClick: async () => {
          const {
            response: {data},
          } = await projectModel.projectAdminNodeInfo({nodeCode})
          nodeForm.setFieldsValue(data)
          setMVisible('check')
        },
      },
    ].filter(item => item.when)
  }

  return (
    <Page
      breadcrumb={
        <Breadcrumb
          right={
            auth('003076002') && (
              <Button type={'primary'} onClick={() => setMVisible('add')}>
                新增节点
              </Button>
            )
          }
        />
      }
    >
      <Form
        form={form}
        labelCol={{flex: '75px'}}
        labelAlign={'left'}
        initialValues={{pageNum: 1, pageSize: 10}}
        onReset={queryNodeList}
        onFinish={queryNodeList}
      >
        <div className={commonStyles.filterSection}>
          <Row gutter={30}>
            <Col span={7}>
              <Form.Item name={'nodeTitle'} label={'节点名称'}>
                <Input placeholder={'请输入'} allowClear />
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item name={'nodeCode'} label={'节点编号'}>
                <Input placeholder={'请输入'} allowClear />
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item name={'nodeStatus'} label={'状态'}>
                <Select placeholder={'请选择'} allowClear>
                  {projectNodeStatusArr.map(item => (
                    <Option key={item.id} value={item.id}>
                      {item.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item name={'nodeType'} label={'类型'}>
                <Select placeholder={'请选择'} allowClear>
                  {projectNodeTypeArr.map(item => (
                    <Option key={item.id} value={item.id}>
                      {item.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row justify={'end'}>
            <Space>
              <Button type={'primary'} htmlType={'submit'} onClick={() => form.setFieldsValue({pageNum: 1})}>
                查询
              </Button>
              <Button htmlType={'reset'}>重置</Button>
            </Space>
          </Row>
        </div>

        <div className={commonStyles.tableSection}>
          <Table
            rowKey={'nodeCode'}
            loading={dataLoading}
            columns={[
              {title: '编号', dataIndex: 'nodeCode', key: 'nodeCode'},
              {title: '节点名称', dataIndex: 'nodeTitle', key: 'nodeTitle'},
              {title: '类型', dataIndex: 'nodeType', key: 'nodeType', render: value => projectNodeTypeCode[value]},
              {
                title: '状态',
                dataIndex: 'nodeStatus',
                key: 'nodeStatus',
                render: value => projectNodeStatusCode[value],
              },
              {
                title: '操作',
                render: values => {
                  return (
                    <Space>
                      {renderOperate(values).map((item, index) => (
                        <Button
                          key={index}
                          type={'link'}
                          loading={item.loading}
                          style={{padding: 0}}
                          onClick={item.onClick}
                        >
                          {item.title}
                        </Button>
                      ))}
                    </Space>
                  )
                },
              },
            ]}
            scroll={{x: 'max-content'}}
            dataSource={nodeList}
            pagination={getPaginationProps(form, nodeTotal)}
          />
        </div>
      </Form>

      {/** 新增、编辑、查看 */}
      <Modal
        title={operateCode[mVisible]}
        visible={['add', 'edit', 'check'].includes(mVisible)}
        centered
        footer={null}
        onCancel={() => setMVisible(null)}
        afterClose={() => nodeForm.resetFields()}
      >
        <Form
          form={nodeForm}
          labelCol={{flex: '75px'}}
          onFinish={async () => {
            const formValues = nodeForm.getFieldsValue(true)
            // console.log('formValues', formValues)
            await projectModel.projectAdminNodeUpsert(formValues)
            message.success('操作成功！')
            setMVisible(null)
            form.submit()
          }}
          onReset={() => setMVisible(null)}
        >
          <Form.Item
            name={'nodeTitle'}
            label={'节点名称'}
            getValueProps={value => ({value})}
            rules={[{required: true}]}
          >
            <Input
              placeholder={'请输入节点名称'}
              allowClear
              disabled={mVisible === 'edit'}
              readOnly={mVisible === 'check'}
            />
          </Form.Item>
          <Form.Item name={'nodeType'} label={'类型'} rules={[{required: true}]}>
            <Select
              placeholder={'请选择'}
              allowClear
              disabled={mVisible === 'edit'}
              style={{pointerEvents: mVisible === 'check' ? 'none' : 'auto'}}
            >
              {projectNodeTypeArr.map(item => (
                <Option key={item.id} value={item.id}>
                  {item.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name={'userGroupList'} label={'用户组'} rules={[{required: true}]}>
            <Select
              placeholder={'请选择'}
              mode={'multiple'}
              filterOption={(inputValue, option) => {
                return option?.children?.indexOf(inputValue as any) !== -1
              }}
              allowClear
              optionFilterProp={'roleName'}
              style={{pointerEvents: mVisible === 'check' ? 'none' : 'auto'}}
            >
              {roleList.map(item => (
                <Option key={item.roleId} value={item.roleId}>
                  {item.roleName}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name={'remark'} label={'说明'} rules={[{required: true, message: '请输入说明'}]}>
            <Input.TextArea allowClear placeholder={'请输入'} maxLength={200} readOnly={mVisible === 'check'} />
          </Form.Item>

          {mVisible !== 'check' && (
            <Row justify={'end'}>
              <Space>
                <Button htmlType={'reset'}>取消</Button>
                <Button type={'primary'} htmlType={'submit'} loading={upsertLoading}>
                  保存
                </Button>
              </Space>
            </Row>
          )}
        </Form>
      </Modal>

      {/** 启用、暂停、删除 */}
      <Modal
        centered
        footer={null}
        title={'提示'}
        visible={['del', 'enable', 'disable'].includes(mVisible)}
        onCancel={() => setMVisible(null)}
        afterClose={() => nodeForm.resetFields()}
      >
        {operateCode[mVisible]}
        <Row justify={'end'} style={{marginTop: '24px'}}>
          <Space>
            <Button onClick={() => setMVisible(null)}>取消</Button>
            <Button
              type={'primary'}
              onClick={async () => {
                const nodeCode = nodeForm.getFieldValue('nodeCode')
                if (['enable', 'disable'].includes(mVisible)) {
                  await projectModel.projectAdminNodeStatus({nodeCode, nodeStatus: mVisible === 'enable' ? '1' : '0'})
                } else {
                  await projectModel.projectAdminNodeDelete({nodeCode})
                }
                message.success('操作成功！')
                setMVisible(null)
                form.submit()
              }}
              loading={statusLoading || deleteLoading}
            >
              确定
            </Button>
          </Space>
        </Row>
      </Modal>
    </Page>
  )
}
