import React from 'react'
import {Page} from '@/components/Page'
import commonStyles from '@/assets/styles/common.module.less'
import {Button, Form, Space, Table} from 'antd'
import {getPaginationProps} from '@/common/biz/getPaginationProps'
import CleanFilterComponent from '@/pages/cleanManagement/components/CleanFilterComponent'
import {cleanServiceArr} from '@/utils/clean'
import {useAppHistory} from '@/common/hooks/useRouter'
import {useCleanOrderCheck} from '@/pages/cleanManagement/hooks/useCleanOrderCheck'
import {useCleanOrderList} from '@/pages/cleanManagement/hooks/useCleanOrderList'

export default function ServiceCleanConfirm() {
  const history = useAppHistory()

  const [form] = Form.useForm()
  const {dataList, dataTotal, queryCleanOrderList, orderListLoading} = useCleanOrderList(form)

  const cleanOrderCheck = useCleanOrderCheck()

  return (
    <Page>
      <div className={commonStyles.filterSection}>
        <CleanFilterComponent
          form={{
            form,
            labelCol: {span: '80px'},
            labelAlign: 'left',
            initialValues: {pageNum: 1, pageSize: 10, pageable: true, orderStatus: '1', sortType: 2},
            onFinish: queryCleanOrderList,
            onReset: queryCleanOrderList,
          }}
          filter={[
            {type: 'input', name: 'orderId', label: '服务单ID', placeholder: '请输入'},
            {type: 'select', name: 'categoryThi', label: '服务项目', placeholder: '请选择', options: cleanServiceArr},
            {type: 'input', name: 'propertyAddr', label: '房源', placeholder: '请输入'},
            {type: 'input', name: 'orderMakerName', label: '下单人', placeholder: '请输入'},
            {type: 'input', name: 'contactMobile', label: '服务联系手机号', placeholder: '请输入'},
            {
              type: 'range',
              name: 'insertTime',
              label: '下单时间',
              onChange: (_, dateString) => {
                form.setFieldsValue({
                  insertTimeBegin: dateString[0] ? dateString[0] + ' 00:00:00' : '',
                  insertTimeEnd: dateString[1] ? dateString[1] + ' 23:59:59' : '',
                })
              },
            },
            {type: 'input', name: 'customerName', label: '房东', placeholder: '请输入'},
          ]}
        />
      </div>

      <div className={commonStyles.tableSection}>
        <Table
          rowKey={'orderId'}
          loading={orderListLoading}
          dataSource={dataList}
          columns={[
            {title: '服务单ID', dataIndex: 'orderId'},
            {
              title: '服务项目',
              dataIndex: 'categoryFirName',
              render: (value, record) =>
                [value, record.categorySecName, record.categoryThiName].filter(item => item).join('-'),
            },
            {title: '下单人', dataIndex: 'orderMakerName'},
            {
              title: '下单时间',
              dataIndex: 'insertTime',
              sorter: {
                compare: (a, b) => new Date(a.insertTime).getTime() - new Date(b.insertTime).getTime(),
                multiple: 1,
              },
            },
            {title: '服务联系手机号', dataIndex: 'contactMobile'},
            {title: '房源', dataIndex: 'propertyAddr'},
            {title: '房东', dataIndex: 'customerName'},
            {
              title: '操作',
              fixed: 'right',
              render: record => (
                <Space>
                  <Button
                    type={'link'}
                    style={{padding: 0}}
                    onClick={() => history.push('/service-clean-confirm/detail', {orderId: record.orderId})}
                  >
                    查看
                  </Button>
                  <Button
                    type={'link'}
                    style={{padding: 0}}
                    onClick={() => {
                      cleanOrderCheck({
                        orderId: record.orderId,
                        serviceId: record.categoryThi,
                        customerId: record.customerId,
                        onSuccess: () => queryCleanOrderList(),
                      })
                    }}
                  >
                    确认
                  </Button>
                </Space>
              ),
            },
          ]}
          scroll={{x: 'max-content'}}
          pagination={getPaginationProps(form, dataTotal)}
        />
      </div>
    </Page>
  )
}
