import {applyMiddleware, compose, createReduxStore} from '@redux-model/web'
import {createLogger} from 'redux-logger'
import {setStore} from '@/utils/globalService'
import models from '@/store/models'

const middleware =
  process.env.NODE_ENV !== 'production'
    ? [
        createLogger({
          collapsed: true,
          diff: true,
          duration: true,
          colors: false,
        }),
      ]
    : []

export const store = createReduxStore({
  enhancer: compose(applyMiddleware(...middleware)),
  reducers: {},
  // 持久化设置
  persist: {
    version: '0.0.3',
    key: 'admin',
    storage: localStorage,
    restoreDelay: 500,
    whitelist: {
      userModel: models.userModel,
    },
  },
})

setStore(store)
