import React, {useState, useMemo, useEffect} from 'react'
import './index.less'
import {Row, Col, Typography, Table, Space, Button} from 'antd'
import {useLocation, useHistory} from 'react-router-dom'
import {storageModel} from '@/store/models/Storage'
import moment from 'moment'
import {Breadcrumb} from '@/components/Breadcrumb'
import commonStyles from '@/assets/styles/common.module.less'

export default function AllotDetail() {
  const location = useLocation<any>()
  const transferId = useMemo(() => location.state?.transferId, [location.state])
  const warehouseIn = useMemo(() => location.state?.warehouseIn, [location.state])
  const status = useMemo(() => location.state?.status, [location.state])
  const [dataSource, setDataSource] = useState<StewardWarehouseTransferMaterialVO[]>()
  const [transferInfo, setTransferInfo] = useState<StewardWarehouseTransferPendingVO>()
  const [list, setList] = useState<StewardWarehouseTransferMaterialVO[]>()
  const histroy = useHistory()

  useEffect(() => {
    Promise.all([
      storageModel.stewardWarehouseTransferInfo(transferId),
      storageModel.stewardWarehouseInventory({pageNum: 1, pageSize: 10, pageable: false, warehouseId: warehouseIn}),
    ]).then(response => {
      const {
        response: {data: info},
      } = response[0]
      const {
        response: {data: list},
      } = response[1]
      setTransferInfo(info)
      setList(info.materialVOList)
      const materialList =
        info?.materialVOList.length &&
        info?.materialVOList.map(item => ({
          ...item,
          beforeNum: list.find(_item => _item.materialId === item.materialId)
            ? list.find(_item => _item.materialId === item.materialId)?.num
            : 0,
          afterNum: list.find(_item => _item.materialId === item.materialId)
            ? list.find(_item => _item.materialId === item.materialId)?.num + item.num
            : 0 + item.num,
        }))

      setDataSource(materialList)
    })
  }, [transferId, warehouseIn])

  const statusType = useMemo(
    () => ({
      '1': '待审批',
      '2': '已通过',
      '3': '已拒绝',
      '4': '已取消',
    }),
    []
  )
  const loading = storageModel.stewardWarehouseTransferInfo.useLoading()
  return (
    <div className={'allotDetail'}>
      <Breadcrumb path={['仓库管理', '调拨申请']} />

      <div style={{marginTop: '20px'}} />
      <div className={commonStyles.tableSection}>
        <div className={'allotInfo'}>
          <Row gutter={[0, 10]}>
            <Col span={6}>
              <Typography.Text>{`出仓仓库：${transferInfo?.warehouseOutName}`}</Typography.Text>
            </Col>
            <Col span={6}>
              <Typography.Text>{`操作人：${transferInfo?.userName}`}</Typography.Text>
            </Col>
            {transferInfo?.approverName ? (
              <Col span={6}>
                <Typography.Text>{`审批人：${transferInfo?.approverName}`}</Typography.Text>
              </Col>
            ) : null}

            <Col span={6}>
              <Typography.Text>{`审批状态：${statusType[transferInfo?.pendingStatus]}`}</Typography.Text>
            </Col>
          </Row>
          <Row>
            <Col span={6}>
              <Typography.Text>{`入仓仓库：${transferInfo?.warehouseInName}`}</Typography.Text>
            </Col>
            <Col span={6}>
              <Typography.Text>{`申请时间：${moment(transferInfo?.insertTime).format(
                'YYYY-MM-DD HH:mm:ss'
              )}`}</Typography.Text>
            </Col>
            {transferInfo?.approverTime ? (
              <Col span={6}>
                <Typography.Text>{`审批时间：${moment(transferInfo?.approverTime).format(
                  'YYYY-MM-DD HH:mm:ss'
                )}`}</Typography.Text>
              </Col>
            ) : null}
          </Row>
        </div>
        {status === '2' || status === '3' ? (
          <Table
            rowKey={'materialId'}
            size={'middle'}
            loading={loading}
            columns={[
              {title: '耗材名称', dataIndex: 'name', key: 'name', align: 'center'},
              {title: '单位', dataIndex: 'unit', key: 'unit', align: 'center'},
              {title: '调拨前数量', dataIndex: 'beforeNum', key: 'beforeNum', align: 'center'},
              {title: '申请数量', dataIndex: 'num', key: 'num', align: 'center'},
              {title: '变动后数量', dataIndex: 'afterNum', key: 'afterNum', align: 'center'},
            ]}
            dataSource={list}
            pagination={{
              hideOnSinglePage: true,
            }}
          />
        ) : (
          <Table
            rowKey={'materialId'}
            size={'middle'}
            loading={loading}
            columns={[
              {title: '耗材名称', dataIndex: 'name', key: 'name', align: 'center'},
              {title: '单位', dataIndex: 'unit', key: 'unit', align: 'center'},
              {title: '调拨前数量', dataIndex: 'beforeNum', key: 'beforeNum', align: 'center'},
              {title: '申请数量', dataIndex: 'num', key: 'num', align: 'center'},
              {title: '变动后数量', dataIndex: 'afterNum', key: 'afterNum', align: 'center'},
            ]}
            dataSource={dataSource}
            pagination={{
              hideOnSinglePage: true,
            }}
          />
        )}
        {transferInfo?.pendingStatus === '1' ? (
          <div className={'footer'}>
            <Space>
              <Button
                type={'primary'}
                onClick={() => {
                  storageModel.stewardWarehouseTransferPending(transferId, '2').then(() => histroy.goBack())
                }}
              >
                通过并调拨
              </Button>
              <Button
                type={'primary'}
                danger
                onClick={() => {
                  storageModel.stewardWarehouseTransferPending(transferId, '3').then(() => histroy.goBack())
                }}
              >
                拒绝
              </Button>
            </Space>
          </div>
        ) : null}
      </div>
    </div>
  )
}
