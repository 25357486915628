import React, {useCallback, useEffect, useState} from 'react'
import {Page} from '@/components/Page'
import {Button, Checkbox, Form, Input, InputNumber, message, PageHeader, Select, Space, Upload} from 'antd'
import commonStyles from '@/assets/styles/common.module.less'
import {Album, UploadButton} from '@/components/Album'
import {useAppHistory, useAppLocation} from '@/common/hooks/useRouter'
import {ebassModel} from '@/store/models/Ebaas'
import {baseUrl} from '@/common/config'
import ebaas from '@/store/models/Common/ebaasCodes'
import {isFile, isUploaded} from '@/utils/util'
import Location from '@/assets/icons/location.svg'
import {MapLocation} from '@/pages/concentrateProject/component/MapLocation'
import AreaCascader, {addressValid} from '@/components/AreaCascader'

const serviceFacilitiesArr = ebaas.serviceFacilities.map(item => ({label: item.label, value: `${item.id}`}))

export default function StoreApartmentManageDetail() {
  const location = useAppLocation<'/store-manage/apartment-detail'>()
  const condoId = 'condoId' in location.state ? location.state.condoId : null
  const storeNo = location.state?.storeNo
  const history = useAppHistory()
  const [form] = Form.useForm()

  const [disable, setDisable] = useState(false)
  const [locVisible, setLocVisible] = useState(false)

  const [brokerList, setBrokerList] = useState<EbaasBrokerVO[]>([])

  useEffect(() => {
    ebassModel
      .ebaasBrokerList({storeNo, inServiceStatus: 1, pageNum: 1, pageSize: 10, pageable: false} as EbaasBrokerQueryDTO)
      .then(({response: {data}}) => setBrokerList(data))
  }, [storeNo])

  useEffect(() => {
    if (condoId) {
      ebassModel.ebaasApartmentInfo({condoId}).then(({response: {data}}) => {
        form.setFieldsValue({
          address: data.address,
          condoId: data.condoId,
          coordX: data.coordX,
          coordY: data.coordY,
          desc: data.desc,
          facadeImages: data.facadeImages,
          fitment: data.fitment,
          heatingTypes: data.heatingTypes,
          isElevator: data.isElevator,
          name: data.name,
          openStatus: data.openStatus,
          parkingTypes: data.parkingTypes,
          serviceFacilities: data.serviceFacilities,
          timeLimitMax: data.timeLimitMax,
          timeLimitMin: data.timeLimitMin,
          totalFloorNum: data.totalFloorNum,
          userId: data.userId,
          addressValue: {
            city: data.cityName,
            cityCode: data.cityCode,
            district: data.districtName,
            districtCode: data.districtCode,
            bizCircle: data.blockName,
            bizCircleCode: data.bizCircleCode,
          },
        })
        setDisable(!!data.apartmentId)
      })
    }
  }, [condoId, form])

  const onFinish = useCallback(async () => {
    const formValues = form.getFieldsValue(true)
    // console.log('formValues', formValues)
    const facadeImages = formValues.facadeImages
    if (facadeImages?.some(item => typeof item === 'object')) return message.warning('图片正在上传中!')
    const addressValue = {
      bizCircle: formValues.addressValue?.bizCircle,
      bizCircleCode: formValues.addressValue?.bizCircleCode,
      city: formValues.addressValue?.city,
      cityCode: formValues.addressValue?.cityCode,
      district: formValues.addressValue?.district,
      districtCode: formValues.addressValue?.districtCode,
    }
    if (condoId) {
      await ebassModel.ebaasApartmentUpdate({...formValues, ...addressValue})
    } else {
      await ebassModel.ebaasApartmentInsert({...formValues, ...addressValue, storeId: storeNo})
    }
    message.success('操作成功！')
    history.goBack()
  }, [condoId, form, history, storeNo])

  const updateLoading = ebassModel.ebaasApartmentUpdate.useLoading()

  return (
    <Page
      header={<PageHeader onBack={() => history.goBack()} ghost={false} title={condoId ? '公寓信息' : '新增公寓'} />}
    >
      <div className={commonStyles.content} style={{backgroundColor: '#fff', paddingTop: 20}}>
        <Form
          form={form}
          labelAlign={'right'}
          labelCol={{flex: '120px', offset: 2}}
          style={{width: '60%'}}
          onFinish={onFinish}
          initialValues={{addressValue: {provinceCode: '310000'}}}
        >
          <Space size={5} style={{margin: '5px 0 25px 25px'}}>
            <div style={{width: '6px', height: '14px', backgroundColor: '#00B7AE'}} />
            <span style={{fontSize: '14px', fontWeight: 'bold'}}>公寓信息</span>
          </Space>
          {!!condoId && (
            <Form.Item label={'公寓ID'} name={'condoId'}>
              {condoId}
            </Form.Item>
          )}
          <Form.Item label={'公寓名称'} name={'name'} rules={[{required: true, message: '请输入公寓名称'}]}>
            <Input placeholder={'请输入'} maxLength={20} disabled={!!condoId} />
          </Form.Item>
          <Form.Item name={'addressValue'} label={'所在地区'} rules={[{required: true, validator: addressValid}]}>
            <AreaCascader type={['city', 'district', 'bizCircle']} />
          </Form.Item>
          <Form.Item label={'公寓地址'} name={'address'} rules={[{required: true, message: '请输入公寓地址'}]}>
            <Input placeholder={'请输入'} disabled={disable} />
          </Form.Item>

          <Form.Item
            required
            label={'公寓坐标'}
            shouldUpdate={(prevValues, nextValues) =>
              prevValues.coordX !== nextValues.coordX || prevValues.coordY !== nextValues.coordY
            }
          >
            {form1 => {
              const {coordX, coordY} = form1.getFieldsValue(['coordX', 'coordY'])
              return (
                <Form.Item
                  name={'coordX'}
                  noStyle
                  rules={[{required: true, message: '请选择公寓坐标'}]}
                  getValueProps={() => null}
                  getValueFromEvent={() => null}
                >
                  <Input
                    placeholder='请选择'
                    onClick={() => setLocVisible(true)}
                    value={coordY ? `(${coordY},${coordX})` : ''}
                    suffix={<img src={Location} alt={'icon'} style={{height: 20, width: 15}} />}
                  />
                </Form.Item>
              )
            }}
          </Form.Item>
          <Form.Item label={'公寓绑定管家'} name={'userId'} rules={[{required: true, message: '请选择公寓管家'}]}>
            <Select placeholder={'请选择'} allowClear>
              {brokerList.map(item => (
                <Select.Option key={item.userId} value={item.userId}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label={'营业状态'} name={'openStatus'} rules={[{required: true, message: '请选择营业状态'}]}>
            <Select placeholder={'请选择'} allowClear>
              {ebaas.openStatus.map(item => (
                <Select.Option key={item.id} value={item.id}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label={'是否有车位'} name={'parkingTypes'} rules={[{required: true, message: '请选择是否有车位'}]}>
            <Select placeholder={'请选择'} allowClear>
              {ebaas.parkingTypes.map(item => (
                <Select.Option key={item.id} value={item.id}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label={'电梯'} name={'isElevator'} rules={[{required: true, message: '请选择'}]}>
            <Select placeholder={'请选择'} allowClear>
              {ebaas.isElevator.map(item => (
                <Select.Option key={item.id} value={item.id}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label={'公寓总层数'}
            name={'totalFloorNum'}
            rules={[
              {required: true, message: '请输入公寓总层数'},
              {type: 'number', min: 1, max: 100, message: '公寓总层数范围在1-100之间'},
            ]}
          >
            <InputNumber placeholder={'请输入'} style={{width: '100%'}} />
          </Form.Item>
          <Form.Item label={'供暖方式'} name={'heatingTypes'} rules={[{required: true, message: '请选择供暖方式'}]}>
            <Select placeholder={'请选择'} allowClear>
              {ebaas.heatingTypes.map(item => (
                <Select.Option key={item.id} value={item.id}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label={'装修情况'} name={'fitment'} rules={[{required: true, message: '请选择装修情况'}]}>
            <Select placeholder={'请选择'} allowClear>
              {ebaas.fitment.map(item => (
                <Select.Option key={item.id} value={item.id}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label={'公寓描述'}
            name={'desc'}
            rules={[
              {required: true, message: '请输入公寓描述'},
              {min: 10, max: 500, message: '公寓描述字数范围在10-500之间'},
            ]}
          >
            <Input.TextArea placeholder={'请输入'} allowClear />
          </Form.Item>
          <Form.Item
            label={'基础设施'}
            name={'serviceFacilities'}
            rules={[{required: true, message: '请选择基础设施'}]}
            getValueFromEvent={event => event?.join(',')}
            getValueProps={value => ({value: value?.split(',')})}
          >
            <Checkbox.Group options={serviceFacilitiesArr} />
          </Form.Item>
          <Form.Item
            label={'图片'}
            required
            shouldUpdate={(prevValues, nextValues) => prevValues.facadeImages !== nextValues.facadeImages}
          >
            {form1 => {
              const facadeImages = form1.getFieldValue('facadeImages') ?? []
              return (
                <Form.Item
                  name={'facadeImages'}
                  rules={[
                    {required: true, message: '图片不能为空'},
                    {
                      validator: (_, value) => {
                        if (value?.length < 3) return Promise.reject(new Error('图片不能少于3张'))
                        return Promise.resolve()
                      },
                    },
                  ]}
                  getValueFromEvent={event => {
                    return event.fileList?.reduce((prev, item) => {
                      if (isFile(item) && item.status === 'done') {
                        if (item.response?.code === 200) {
                          prev.push(item.response?.data?.imgUrl)
                        } else {
                          // 未上传成功丢掉该项目
                          message.error(item.response?.msg)
                        }
                        return prev
                      }
                      // 已上传的文件取 uid
                      if (isUploaded(item)) return [...prev, item.uid]

                      // file 对象原封不动
                      return [...prev, item]
                    }, [])
                  }}
                  getValueProps={value => ({
                    fileList: value?.map(item => (typeof item === 'string' ? {uid: item, url: item} : item)),
                  })}
                  noStyle
                >
                  <Album
                    limit={30}
                    action={baseUrl + '/ebaas/common/upload'}
                    accept={'.jpg, .jpeg, .png'}
                    beforeUpload={file => {
                      if (file.size > 1024 * 1024 * 2) {
                        message.warn('文件大小不能超过2M')
                        return Upload.LIST_IGNORE
                      }
                      return true
                    }}
                  >
                    {facadeImages.length > 30 ? null : <UploadButton />}
                  </Album>
                </Form.Item>
              )
            }}
          </Form.Item>
          <Space size={5} style={{margin: '5px 0 25px 25px'}}>
            <div style={{width: '6px', height: '14px', backgroundColor: '#00B7AE'}} />
            <span style={{fontSize: '14px', fontWeight: 'bold'}}>房源设置</span>
          </Space>
          <Form.Item label={'最短租期'} name={'timeLimitMin'} rules={[{required: true, message: '请选择最短租期'}]}>
            <Select placeholder={'请选择'} allowClear>
              {ebaas.timeLimitMin.map(item => (
                <Select.Option key={item.id} value={item.id}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label={'最长租期'} name={'timeLimitMax'} rules={[{required: true, message: '请选择最长租期'}]}>
            <Select placeholder={'请选择'} allowClear>
              {ebaas.timeLimitMax.map(item => (
                <Select.Option key={item.id} value={item.id}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label={' '} colon={false}>
            <Space>
              <Button type={'primary'} htmlType={'submit'} loading={updateLoading}>
                保存
              </Button>
              <Button onClick={() => history.goBack()}>取消</Button>
            </Space>
          </Form.Item>
        </Form>
      </div>

      <MapLocation
        visible={locVisible}
        onCancel={() => setLocVisible(false)}
        onOk={({lat, lng}) => {
          form.setFieldsValue({coordX: lng, coordY: lat})
          setLocVisible(false)
        }}
        point={{lng: form.getFieldValue('coordX'), lat: form.getFieldValue('coordY')}}
      />
    </Page>
  )
}
