import React, {useState} from 'react'
import {Button, Tabs} from 'antd'
import commonStyles from '@/assets/styles/common.module.less'

import LonghuPerformanceManagement from '@/pages/performance/Longhu/LonghuPerformanceManagement'
import {Page} from '@/components/Page'
import {Breadcrumb} from '@/components/Breadcrumb'
import {useAppHistory} from '@/common/hooks/useRouter'
import LonghuPerformance from '@/pages/performance/Longhu/LonghuPerformance'
import {usePermission} from '@/components/Permission'

export default function Longhu() {
  const history = useAppHistory()

  const [auth] = usePermission()

  // const [active, setActive] = useState('1')

  const [active, setActive] = useState(() => {
    const permissionObj = {
      '001183001': '1',
      '001184001': '2',
    }
    const key = Object.keys(permissionObj).filter(item => auth(item))[0]
    return permissionObj[key]
  })

  return (
    <Page
      breadcrumb={
        <Breadcrumb
          right={
            active === '2' &&
            auth('003184002') && <Button onClick={() => history.push('/performance-longhu/new')}>新增</Button>
          }
        />
      }
    >
      <Tabs className={commonStyles.tabs} activeKey={active} onChange={key => setActive(key)}>
        <Tabs.TabPane tab='龙湖绩效' key='1' disabled={!auth('001183001')}>
          <LonghuPerformance />
        </Tabs.TabPane>
        <Tabs.TabPane tab='绩效方案管理' key='2' disabled={!auth('001184001')}>
          <LonghuPerformanceManagement />
        </Tabs.TabPane>
      </Tabs>
    </Page>
  )
}
