import React, {useCallback, useEffect, useState} from 'react'
import {Page} from '@/components/Page'
import commonStyles from '@/assets/styles/common.module.less'
import {
  Button,
  Col,
  DatePicker,
  Form,
  FormInstance,
  Image,
  Input,
  message,
  Modal,
  Pagination,
  Row,
  Select,
  Space,
  Table,
  Tabs,
  Typography,
} from 'antd'
import {Breadcrumb} from '@/components/Breadcrumb'
import moment from 'moment'
import {useAppHistory} from '@/common/hooks/useRouter'
import {stewardModel} from '@/store/models/Steward'
import {usePermission} from '@/components/Permission'
import {sysUserModel} from '@/store/models/SysUser'
import {FORMAT_DATE} from '@/common/date'
import {downloadBlob, getImg} from '@/utils/util'
import holder from '@/assets/holder.png'
import {ExclamationCircleOutlined} from '@ant-design/icons'
import CalendarView from './CalendarView'
import ListView from './ListView'

type ModalForm = StewardTransferChangeDTO & {dateString: string; stewardName: string}

export default function Schedule() {
  const history = useAppHistory()

  const [auth] = usePermission()

  // 按周查看
  const [modalForm] = Form.useForm()

  const [form1] = Form.useForm()
  const [form2] = Form.useForm()

  const [loading, setLoading] = useState(false)

  // todo：modal 用不到
  const [exportVisible, setExportVisible] = useState(false)
  const [exportForm] = Form.useForm()

  // const [total, setTotal] = useState(0)
  const [visible, setVisible] = useState(false)
  // const [dataSource, setDataSource] = useState<StewardTransferWeekVO[]>([])

  // const queryTransferListByWeek = useCallback(async () => {
  //   const {pageNum = 1, pageSize = 10, pageable = true, ...rest} = form1.getFieldsValue(true)
  //   const {
  //     response: {data, total},
  //   } = await stewardModel.stewardAdminTransferListByWeek({pageNum, pageSize, pageable, ...rest})
  //   setDataSource(data)
  //   setTotal(total)
  // }, [form1])

  // useFocusEffect(() => {
  //   form1.submit()
  // })

  // useEffect(() => {
  //   form1.submit()
  // }, [form1])

  // const renderTable = useCallback(
  //   (record: typeof dataSource[number], week: string, midday: string) => {
  //     // const weekItem = weeksWithDate.find(([value]) => value === week)
  //     const prop = `${week}${midday}`
  //     const data: StewardTransferVO = record[prop]
  //     return data ? (
  //       <span className={styles.item}>
  //         <Space direction={'vertical'}>
  //           <span className={styles.status}>休（{data.vacationTypeName}）</span>
  //           {/*排班列表不显示【接手人】*/}
  //           {/*<span>{data.takerName}</span>*/}
  //         </Space>
  //       </span>
  //     ) : (
  //       <span className={styles.item}>班</span>
  //     )
  //   },
  //   [dataSource]
  // )

  const [stewardList, setStewardList] = useState<SysUserVO[]>([])

  useEffect(() => {
    sysUserModel.querySysUserList({roleId: 'steward', pageable: false}).then(({response: {data}}) =>
      // setStewardList(data.map(item => ({...item, name: `${item.name}（${item.mobile}）`})))
      setStewardList(data)
    )
  }, [])

  const [form3] = Form.useForm()
  const [checkInList, setCheckInList] = useState<StewardCheckInVO[]>([])
  const [checkInTotal, setCheckInTotal] = useState(0)
  const [checkInStatistic, setCheckInStatistic] = useState({} as {checked: number; delay: number; total: number})
  const [checkInVisible, setCheckInVisible] = useState(false)

  const queryCheckInList = useCallback(async () => {
    const values = form3.getFieldsValue(true)
    const [list, statistic] = await Promise.all([
      stewardModel.stewardCheckInList(values),
      stewardModel.stewardCheckInStatistic(values),
    ])
    setCheckInList(list.response.data)
    setCheckInTotal(list.response.total)
    setCheckInStatistic(statistic.response.data as any)
  }, [form3])

  useEffect(() => {
    auth('001205001') && form3.submit()
  }, [auth, form3])

  const [activeKey, setActiveKey] = useState(() => {
    const permissionMap = {
      '001072001': '1',
      // '001072001': '2',
      '001205001': '3',
    }
    const permission = Object.keys(permissionMap).find(value => auth(value))
    return permissionMap[permission]
  })

  return (
    <Page
      breadcrumb={
        <Breadcrumb
          right={
            <Space>
              {auth('007072003') && (
                <>
                  {activeKey === '1' && (
                    <Button
                      onClick={() =>
                        downloadBlob({
                          url: '/steward/admin/transfer/export',
                          body: {...form1.getFieldsValue(true), pageable: false},
                        })
                      }
                    >
                      导出考勤
                    </Button>
                  )}
                  {activeKey === '2' && (
                    <Button
                      onClick={async () => {
                        await form2.validateFields()
                        const {stewardId, ...values} = form2.getFieldsValue(true)
                        downloadBlob({
                          url: '/steward/admin/transfer/export',
                          body: {...values, pageNum: 1, pageSize: 10, pageable: false, stewardIdList: [stewardId]},
                        })
                      }}
                    >
                      导出考勤
                    </Button>
                  )}
                </>
              )}
              {auth('002072002') && ['1', '2'].includes(activeKey) && (
                <Button type={'primary'} onClick={() => history.push('/schedule/config')}>
                  排班配置
                </Button>
              )}
              {auth('007205002') && activeKey === '3' && (
                <Button
                  type={'primary'}
                  onClick={async () => {
                    // setCheckInVisible(true)
                    const values = form3.getFieldsValue(true)
                    await downloadBlob({url: '/steward/check-in/export', body: {...values, pageable: false}})
                  }}
                >
                  导出
                </Button>
              )}
            </Space>
          }
        />
      }
    >
      <Tabs className={commonStyles.tabs} activeKey={activeKey} onChange={setActiveKey}>
        <Tabs.TabPane tab={'列表查看'} key={'1'} disabled={!auth('001072001')}>
          <ListView form={form1} />
        </Tabs.TabPane>
        <Tabs.TabPane tab={'日历查看'} key={'2'} disabled={!auth('001072001')}>
          <CalendarView form={form2} />
        </Tabs.TabPane>
        <Tabs.TabPane forceRender tab={'签到记录'} key={'3'} disabled={!auth('001205001')}>
          <Form
            form={form3}
            labelCol={{flex: '80px'}}
            initialValues={{
              pageSize: 20,
              pageNum: 1,
              pageable: true,
              checkDateBegin: moment().format(FORMAT_DATE),
              checkDateEnd: moment().format(FORMAT_DATE),
            }}
            onFinish={queryCheckInList}
            onReset={queryCheckInList}
          >
            <div className={commonStyles.filterSection}>
              <Row gutter={30}>
                <Col span={8}>
                  <Form.Item
                    label={'签到日期'}
                    name={'checkDateBegin'}
                    getValueFromEvent={(_, formatString) => {
                      form3.setFieldValue('checkDateEnd', formatString?.[1])
                      return formatString?.[0]
                    }}
                    getValueProps={value => ({
                      value: value ? [moment(value), moment(form3.getFieldValue('checkDateEnd'))] : value,
                    })}
                  >
                    <DatePicker.RangePicker allowClear style={{width: '100%'}} />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label={'管家'} name={'stewardName'}>
                    <Input placeholder={'请输入'} allowClear />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label={'签到情况'} name={'checkStatusList'}>
                    <Select
                      allowClear
                      placeholder={'请选择'}
                      mode={'multiple'}
                      options={[
                        {label: '已签到', value: 1},
                        {label: '未签到', value: 0},
                      ]}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row justify={'end'}>
                <Space>
                  <Button type={'primary'} htmlType={'submit'} onClick={() => form3.setFieldsValue({pageNum: 1})}>
                    查询
                  </Button>
                  <Button htmlType={'reset'}>重置</Button>
                </Space>
              </Row>
            </div>

            <div className={commonStyles.filterSection}>
              <Row justify={'space-between'} style={{marginBottom: 12}}>
                <Col>
                  <Space>
                    <Typography.Title level={4} style={{marginBottom: 0}}>
                      统计：应签到{checkInStatistic.total}人，实签到{checkInStatistic.checked}人，未签到
                      {checkInStatistic.delay}人
                    </Typography.Title>
                    <ExclamationCircleOutlined
                      title={`应签到：所选签到日期内每一天的在职管家数量之和，每天0点记录
实签到：所选签到日期内的实际签到数量
未签到：所选签到日期内每一天里在职管家的未签到数量之和`}
                    />
                  </Space>
                </Col>
                <Col>
                  <Form.Item
                    noStyle
                    shouldUpdate={(prev, cur) => prev.pageNum !== cur.pageNum || cur.pageSize !== prev.pageSize}
                  >
                    {() => (
                      <Pagination
                        total={checkInTotal}
                        current={form3.getFieldValue('pageNum')}
                        pageSize={form3.getFieldValue('pageSize')}
                        style={{textAlign: 'center'}}
                        showSizeChanger
                        onChange={(pageNum, pageSize) => {
                          form3.setFieldsValue({pageNum, pageSize})
                          form3.submit()
                        }}
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={20}>
                {checkInList.map(value => {
                  const orgs = value.stewardOrgName.split(',')
                  return (
                    <Col key={value.checkId}>
                      <Image
                        src={value.checkPicUrl ? getImg(value.checkPicUrl) : holder}
                        fallback={holder}
                        width={160}
                        height={160}
                        preview={!!value.checkPicUrl}
                      />
                      <div>{`${value.stewardName} ${value.stewardMobile}`}</div>
                      <div style={{fontSize: '0.8em'}} title={value.stewardOrgName}>
                        {orgs[0]}
                        {orgs.length > 1 ? '...' : ''}
                      </div>
                    </Col>
                  )
                })}
              </Row>
            </div>
          </Form>
        </Tabs.TabPane>
      </Tabs>

      <Modal
        destroyOnClose
        open={visible}
        title={'班休调整'}
        onCancel={() => setVisible(false)}
        onOk={() => modalForm.submit()}
        afterClose={() => modalForm.resetFields()}
        confirmLoading={stewardModel.stewardAdminTransferChange.useLoading()}
      >
        <Form
          form={modalForm}
          labelCol={{flex: '68px'}}
          onFinish={async () => {
            const data: ModalForm = modalForm.getFieldsValue(true)
            await stewardModel.stewardAdminTransferChange(data)
            message.success('修改成功')
            setVisible(false)
            // queryTransferListByWeek()
          }}
        >
          <Form.Item label={'管家'}>{modalForm.getFieldValue('stewardName')}</Form.Item>
          <Form.Item label={'日期'}>{modalForm.getFieldValue('dateString')}</Form.Item>
          <Form.Item
            label={'班休'}
            name={'rest'}
            getValueFromEvent={event => event === '2'}
            getValueProps={value => ({value: value ? '2' : '1'})}
            rules={[{required: true}]}
          >
            <Select allowClear>
              <Select.Option value={'1'}>班</Select.Option>
              <Select.Option value={'2'}>休</Select.Option>
            </Select>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        open={exportVisible}
        width={900}
        title={'导出考勤'}
        onCancel={() => setExportVisible(false)}
        afterClose={() => exportForm.resetFields()}
        destroyOnClose
        confirmLoading={loading}
        onOk={async () => {
          await exportForm.validateFields()
          const values = exportForm.getFieldsValue(true)
          const params = {
            month: values.month,
            stewardIdList: [...(values._on || []), ...(values._off || [])],
          }
          if (!params.stewardIdList.length) return message.warning('请选择管家')
          try {
            setLoading(true)
            await downloadBlob({url: '/steward/admin/transfer/export', body: params})
            setExportVisible(false)
          } finally {
            setLoading(false)
          }
        }}
      >
        <Form form={exportForm}>
          <Row gutter={[12, 0]}>
            <Col span={7}>
              <Form.Item
                label={'月份选择'}
                name={'month'}
                rules={[{required: true}]}
                getValueFromEvent={(_, formatString) => formatString}
                getValueProps={value => ({value: value ? moment(value) : value})}
              >
                <DatePicker
                  picker={'month'}
                  format={'YYYY-MM'}
                  // disabledDate={date => date && date > moment().add(1, 'month')}
                />
              </Form.Item>
            </Col>
          </Row>
          <StewardFormItem form={exportForm} stewardList={stewardList} />
          {/*<Form.Item required label={'管家'} labelCol={{style: {paddingTop: 6}}}>*/}
          {/*  <Tabs>*/}
          {/*    <Tabs.TabPane tab={'在职'} key={'1'}>*/}
          {/*      <Table*/}
          {/*        rowKey={'userId'}*/}
          {/*        dataSource={stewardList.filter(item => item.status === '1')}*/}
          {/*        rowSelection={{*/}
          {/*          onSelect(record, selected, selectedRows) {*/}
          {/*            exportForm.setFieldsValue({_on: selectedRows.map(value => value.userId)})*/}
          {/*          },*/}
          {/*          onSelectAll(selected, selectedRows) {*/}
          {/*            exportForm.setFieldsValue({_on: selectedRows.map(value => value.userId)})*/}
          {/*          },*/}
          {/*        }}*/}
          {/*        columns={[*/}
          {/*          {*/}
          {/*            dataIndex: 'name',*/}
          {/*            filterMode: 'menu',*/}
          {/*            filters: stewardList*/}
          {/*              .filter(item => item.status === '1')*/}
          {/*              .map(value => ({text: `${value.name}（${value.mobile}）`, value: value.userId})),*/}
          {/*            render: (value, record) => `${value}（${record.mobile}）`,*/}
          {/*            filterSearch: true,*/}
          {/*            onFilter: (value, record) => record.userId === value,*/}
          {/*          },*/}
          {/*        ]}*/}
          {/*        size={'small'}*/}
          {/*        pagination={{position: ['bottomCenter']}}*/}
          {/*      />*/}
          {/*    </Tabs.TabPane>*/}
          {/*    <Tabs.TabPane tab={'离职'} key={'2'}>*/}
          {/*      <Table*/}
          {/*        rowKey={'userId'}*/}
          {/*        dataSource={stewardList.filter(item => item.status === '0')}*/}
          {/*        rowSelection={{*/}
          {/*          onSelect(record, selected, selectedRows) {*/}
          {/*            exportForm.setFieldsValue({_off: selectedRows.map(value => value.userId)})*/}
          {/*          },*/}
          {/*          onSelectAll(selected, selectedRows) {*/}
          {/*            exportForm.setFieldsValue({_off: selectedRows.map(value => value.userId)})*/}
          {/*          },*/}
          {/*        }}*/}
          {/*        columns={[*/}
          {/*          {*/}
          {/*            dataIndex: 'name',*/}
          {/*            filterMode: 'menu',*/}
          {/*            filters: stewardList*/}
          {/*              .filter(item => item.status === '0')*/}
          {/*              .map(value => ({text: `${value.name}（${value.mobile}）`, value: value.userId})),*/}
          {/*            render: (value, record) => `${value}（${record.mobile}）`,*/}
          {/*            filterSearch: true,*/}
          {/*            onFilter: (value, record) => record.userId === value,*/}
          {/*          },*/}
          {/*        ]}*/}
          {/*        size={'small'}*/}
          {/*        pagination={{position: ['bottomCenter']}}*/}
          {/*      />*/}
          {/*    </Tabs.TabPane>*/}
          {/*  </Tabs>*/}
          {/*</Form.Item>*/}
        </Form>
      </Modal>

      {/* 导出签到记录（改为直接导出，暂时不要这个弹窗） */}
      <Modal
        open={checkInVisible}
        width={900}
        title={'导出'}
        onCancel={() => setCheckInVisible(false)}
        afterClose={() => exportForm.resetFields()}
        destroyOnClose
        confirmLoading={loading}
        onOk={async () => {
          await exportForm.validateFields()
          const values = exportForm.getFieldsValue(true)
          const params = {...values, stewardIdList: [...(values._on || []), ...(values._off || [])]}
          if (!params.stewardIdList.length) return message.warning('请选择管家')
          try {
            setLoading(true)
            await downloadBlob({url: '/steward/check-in/export', body: params})
            setCheckInVisible(false)
          } finally {
            setLoading(false)
          }
        }}
      >
        <Form form={exportForm} initialValues={{pageNum: 1, pageSize: 10, pageable: false}}>
          <Row gutter={[12, 0]}>
            <Col span={12}>
              <Form.Item
                label={'签到日期'}
                name={'checkDateBegin'}
                rules={[{required: true}]}
                getValueFromEvent={(_, formatString) => {
                  exportForm.setFieldValue('checkDateEnd', formatString?.[1])
                  return formatString?.[0]
                }}
                getValueProps={value => ({
                  value: value ? [moment(value), moment(exportForm.getFieldValue('checkDateEnd'))] : value,
                })}
              >
                <DatePicker.RangePicker allowClear />
              </Form.Item>
            </Col>
          </Row>
          <StewardFormItem form={exportForm} stewardList={stewardList} />
        </Form>
      </Modal>
    </Page>
  )
}

const StewardFormItem: React.FC<{
  stewardList: SysUserVO[]
  form: FormInstance
}> = props => {
  const stewardList = props.stewardList
  const form = props.form
  return (
    <Form.Item required label={'管家'} labelCol={{style: {paddingTop: 6}}}>
      <Tabs>
        <Tabs.TabPane tab={'在职'} key={'1'}>
          <Table
            rowKey={'userId'}
            dataSource={stewardList.filter(item => item.status === '1')}
            rowSelection={{
              onSelect(record, selected, selectedRows) {
                form.setFieldsValue({_on: selectedRows.map(value => value.userId)})
              },
              onSelectAll(selected, selectedRows) {
                form.setFieldsValue({_on: selectedRows.map(value => value.userId)})
              },
            }}
            columns={[
              {
                dataIndex: 'name',
                filterMode: 'menu',
                filters: stewardList
                  .filter(item => item.status === '1')
                  .map(value => ({text: `${value.name}（${value.mobile}）`, value: value.userId})),
                render: (value, record) => `${value}（${record.mobile}）`,
                filterSearch: true,
                onFilter: (value, record) => record.userId === value,
              },
            ]}
            size={'small'}
            pagination={{position: ['bottomCenter']}}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab={'离职'} key={'2'}>
          <Table
            rowKey={'userId'}
            dataSource={stewardList.filter(item => item.status === '0')}
            rowSelection={{
              onSelect(record, selected, selectedRows) {
                form.setFieldsValue({_off: selectedRows.map(value => value.userId)})
              },
              onSelectAll(selected, selectedRows) {
                form.setFieldsValue({_off: selectedRows.map(value => value.userId)})
              },
            }}
            columns={[
              {
                dataIndex: 'name',
                filterMode: 'menu',
                filters: stewardList
                  .filter(item => item.status === '0')
                  .map(value => ({text: `${value.name}（${value.mobile}）`, value: value.userId})),
                render: (value, record) => `${value}（${record.mobile}）`,
                filterSearch: true,
                onFilter: (value, record) => record.userId === value,
              },
            ]}
            size={'small'}
            pagination={{position: ['bottomCenter']}}
          />
        </Tabs.TabPane>
      </Tabs>
    </Form.Item>
  )
}
