import React, {useCallback, useEffect, useState} from 'react'
import {Breadcrumb} from '@/components/Breadcrumb'
import {Page} from '@/components/Page'
import {Button, Col, DatePicker, Form, message, Pagination, Row, Select, Space, Table, Tabs, Tooltip} from 'antd'
import commonStyle from '@/assets/styles/common.module.less'
import styles from './index.module.less'
import {debounce, downloadBlob} from '@/utils/util'
import {condoModel} from '@/store/models/Condo'
import moment from 'moment'
import {Permission} from '@/components/Permission'
import {InfoCircleOutlined} from '@ant-design/icons'

export default function DataStatistics() {
  const [form] = Form.useForm()
  const [condoProjectList, setCondoProjectList] = useState<CondoProjectVO[]>([])
  const [statistics, setStatistics] = useState<CondoStatisticDayVO[]>([])
  const [total, setTotal] = useState(0)

  const searchCondoProject = useCallback(
    debounce(async (value?: string) => {
      const {
        response: {data},
      } = await condoModel.condoProjectList({projectName: value} as CondoProjectListDTO)
      setCondoProjectList(data)
    }),
    []
  )
  const search = useCallback(async () => {
    const value = form.getFieldsValue(true)
    const {
      response: {data, total},
    } = await condoModel.condoStatisticDayList(value)
    setStatistics(data)
    setTotal(total)
  }, [form])

  useEffect(() => {
    searchCondoProject()
    search()
  }, [search, searchCondoProject])

  return (
    <Page
      breadcrumb={
        <Breadcrumb
          right={
            <Permission code={'007088001'}>
              <Button
                onClick={() => {
                  const {recordDayMin, recordDayMax, ...rest} = form.getFieldsValue(true)
                  if (!recordDayMax && !recordDayMin) return message.warn('请选择日期')
                  downloadBlob({url: '/condo/statistic/day/export-excel', body: {recordDayMin, recordDayMax, ...rest}})
                }}
              >
                导出
              </Button>
            </Permission>
          }
        />
      }
    >
      <Tabs className={styles.tabs}>
        <Tabs.TabPane tab={'日常数据'} key={'1'}>
          <Form onFinish={search} onReset={search} form={form} initialValues={{pageNum: 1, pageSize: 10}}>
            <div className={commonStyle.filterSection}>
              <Row>
                <Col span={6}>
                  <Form.Item label={'项目'} name={'condoProjectId'}>
                    <Select
                      showSearch
                      onSearch={searchCondoProject}
                      filterOption={false}
                      placeholder={'请选择'}
                      allowClear
                    >
                      {condoProjectList.map(item => (
                        <Select.Option value={item.condoProjectId} key={item.condoProjectId}>
                          {item.projectName}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col offset={1} span={6}>
                  <Form.Item label={'日期'} name={'time'}>
                    <DatePicker.RangePicker
                      disabledDate={current => {
                        return current && current > moment().startOf('day')
                      }}
                      onChange={value => {
                        const [recordDayMin, recordDayMax] = value
                          ? value.map(item => moment(item).format('YYYY-MM-DD'))
                          : []

                        form.setFieldsValue({recordDayMin, recordDayMax})
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row justify={'end'}>
                <Space>
                  <Button type={'primary'} htmlType={'submit'} onClick={() => form.setFieldsValue({pageNum: 1})}>
                    查询
                  </Button>
                  <Button htmlType={'reset'}>重置</Button>
                </Space>
              </Row>
            </div>
            <div className={commonStyle.filterSection}>
              <Table
                rowKey={'recordId'}
                loading={condoModel.condoStatisticDayList.useLoading()}
                columns={[
                  {
                    title: '日期',
                    dataIndex: 'recordDay',
                    render: text => moment(text).format('YYYY-MM-DD'),
                  },
                  {title: '项目', dataIndex: 'projectName'},
                  {
                    title: (
                      <Tooltip title={'该项目关联的公寓录入的房间数'}>
                        <Space>
                          <span>房间数</span>
                          <InfoCircleOutlined style={{color: '#00B7AE'}} />
                        </Space>
                      </Tooltip>
                    ),
                    dataIndex: 'roomNum',
                  },
                  {
                    title: (
                      <Tooltip title={'已签约状态的房间数/房间数'}>
                        <Space>
                          <span>入住率</span>
                          <InfoCircleOutlined style={{color: '#00B7AE'}} />
                        </Space>
                      </Tooltip>
                    ),
                    render: record =>
                      record.checkinPercent ? (
                        <Tooltip
                          title={`${
                            record.roomNumSigned
                            // + record.roomNumSelf
                          } / ${record.roomNum} = ${record.checkinPercent}`}
                        >
                          <span>{`${record.checkinPercent}%`}</span>
                        </Tooltip>
                      ) : (
                        record.checkinPercent
                      ),
                  },
                  {
                    title: (
                      <Tooltip title={'已签约状态租约的月租金总额/已签约状态租约数'}>
                        <Space>
                          <span>均价（元）</span>
                          <InfoCircleOutlined style={{color: '#00B7AE'}} />
                        </Space>
                      </Tooltip>
                    ),
                    render: record =>
                      record.rentAvg ? (
                        <Tooltip
                          title={`${
                            record.rentAvg * record.roomNumSigned
                            // + record.roomNumSelf
                          } / ${
                            record.roomNumSigned
                            // + record.roomNumSelf
                          } = ${record.rentAvg}`}
                        >
                          <span>{record.rentAvg}</span>
                        </Tooltip>
                      ) : (
                        record.rentAvg
                      ),
                  },
                  {
                    title: (
                      <Tooltip title={'当天该项目绑定的公寓新增的签约数'}>
                        <Space>
                          <span>签约</span>
                          <InfoCircleOutlined style={{color: '#00B7AE'}} />
                        </Space>
                      </Tooltip>
                    ),
                    dataIndex: 'signNum',
                  },
                  {
                    title: (
                      <Tooltip title={'当天该项目绑定的公寓新增的续约数'}>
                        <Space>
                          <span>续约</span>
                          <InfoCircleOutlined style={{color: '#00B7AE'}} />
                        </Space>
                      </Tooltip>
                    ),
                    dataIndex: 'renewalNum',
                  },
                  {
                    title: (
                      <Tooltip title={'该项目绑定的公寓里目前存在的预订单数'}>
                        <Space>
                          <span>预定</span>
                          <InfoCircleOutlined style={{color: '#00B7AE'}} />
                        </Space>
                      </Tooltip>
                    ),
                    dataIndex: 'reserveNum',
                  },
                  {
                    title: (
                      <Tooltip title={'当天该项目绑定的公寓里租约状态变为已退房的房间数（包括已结账和未结账）'}>
                        <Space>
                          <span>退房</span>
                          <InfoCircleOutlined style={{color: '#00B7AE'}} />
                        </Space>
                      </Tooltip>
                    ),
                    dataIndex: 'checkoutNum',
                  },
                  {
                    title: (
                      <Tooltip title={'该项目绑定的公寓里30天内到期的房间数量'}>
                        <Space>
                          <span>30天到期</span>
                          <InfoCircleOutlined style={{color: '#00B7AE'}} />
                        </Space>
                      </Tooltip>
                    ),
                    dataIndex: 'dueNum',
                  },
                ]}
                dataSource={statistics}
                pagination={false}
              />

              <Form.Item
                noStyle
                shouldUpdate={(prev, next) => prev.pageNum !== next.pageNum || prev.pageSize !== next.pageSize}
              >
                {() => (
                  <Pagination
                    total={total}
                    style={{textAlign: 'center', marginTop: 20}}
                    current={form.getFieldValue('pageNum')}
                    pageSize={form.getFieldValue('pageSize')}
                    showSizeChanger
                    onChange={(pageNum, pageSize) => {
                      form.setFieldsValue({pageNum, pageSize})
                      search()
                    }}
                  />
                )}
              </Form.Item>
            </div>
          </Form>
        </Tabs.TabPane>
      </Tabs>
    </Page>
  )
}
