import React, {useCallback} from 'react'
import {DatePicker, Form, message, Modal, Select, Input, Col, Row} from 'antd'
import {cleanModel} from '@/store/models/Clean'
import moment from 'moment'
import locale from 'antd/es/date-picker/locale/zh_CN'
import {debounce} from '@/utils/util'
import {sysUserModel} from '@/store/models/SysUser'
import {userModel} from '@/store/models/User'
import {commonModel} from '@/store/models/Common'

const appointTimeCode = {
  '1': ['06:00:00', '12:00:00'],
  '2': ['12:00:00', '18:00:00'],
  '3': ['18:00:00', '21:00:00'],
}

export function useCleanOrderAssign(type: 'dispatch' | 'transfer' = 'dispatch') {
  const [form] = Form.useForm()
  const userInfo = userModel.useData(data => data.userInfo)

  const searchServerList = debounce((name: string) => {
    sysUserModel
      .sysUserList({
        name,
        status: '1',
        pageNum: 1,
        pageSize: 10,
        pageable: false,
        roleId: 'cleaner_manager,cleaner',
        orgIdLike: userInfo.orgId,
      })
      .then(({response: {data}}) => form.setFieldsValue({_userList: data}))
  }, 150)

  const searchCountUnfinished = useCallback(
    (serverId: string) => {
      cleanModel.cleanOrderCountUnfinished({serverId}).then(({response: {data}}) => {
        form.setFieldsValue({_unfinishedCount: data})
      })
    },
    [form]
  )

  const queryActivityRemarkGet = useCallback(
    customerId => {
      commonModel.commonAppCustomerActivityRemarkGet({userId: customerId}).then(({response: {data}}) => {
        form.setFieldsValue({activityRemark: data})
      })
    },
    [form]
  )

  return useCallback(
    ({orderIdList, customerId, onSuccess}: {orderIdList: string[]; customerId?: string; onSuccess?: () => void}) => {
      searchServerList('')

      if (customerId) {
        queryActivityRemarkGet(customerId)
      }

      Modal.confirm({
        title: type === 'dispatch' ? '派单' : '转派',
        icon: null,
        closable: true,
        centered: true,
        okText: '提交',
        onOk: async () => {
          await form.validateFields()
          form.submit()
        },
        afterClose: () => form.resetFields(),
        content: (
          <Form
            form={form}
            onFinish={async () => {
              const formValues = form.getFieldsValue(true)
              if (type === 'transfer') {
                await cleanModel.cleanOrderAssignAgain({
                  ...formValues,
                  orderIdList,
                  appointDate: moment(formValues.appointDate).format('YYYY-MM-DD'),
                })
              } else {
                await cleanModel.cleanOrderAssign({
                  ...formValues,
                  orderIdList,
                  appointDate: moment(formValues.appointDate).format('YYYY-MM-DD'),
                })
              }
              message.success(type === 'transfer' ? '转单成功！' : '派单成功！')
              onSuccess?.()
            }}
            style={{marginTop: '20px'}}
            labelAlign={'right'}
            labelCol={{span: 8}}
          >
            <Form.Item noStyle shouldUpdate>
              {form1 => {
                const activityRemark = form1.getFieldValue('activityRemark')
                if (activityRemark) {
                  return (
                    <Form.Item label={'活动充值'} name={'activityRemark'}>
                      <Input.TextArea readOnly bordered={false} style={{color: '#FF5454'}} maxLength={500} rows={5} />
                    </Form.Item>
                  )
                }
                return null
              }}
            </Form.Item>
            <Form.Item
              label={'约定上门时间'}
              required
              shouldUpdate={(prevValues, nextValues) => prevValues.appointDate !== nextValues.appointDate}
            >
              {form1 => {
                const appointDate = form1.getFieldValue('appointDate')
                return (
                  <Row gutter={10}>
                    <Col span={14}>
                      <Form.Item name={'appointDate'} noStyle rules={[{required: true, message: '请选择日期'}]}>
                        <DatePicker
                          placeholder={'请选择日期'}
                          format={'YYYY-MM-DD'}
                          allowClear
                          disabledDate={current =>
                            !current.isBetween(moment().subtract(1, 'days') as any, moment().add(10, 'days') as any)
                          }
                          onChange={value => {
                            if (!value) form1.resetFields(['appointTime', 'appointTimeBegin', 'appointTimeEnd'])
                          }}
                          locale={locale}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={10}>
                      <Form.Item
                        name={'appointTime'}
                        noStyle
                        rules={[{required: true, message: '请选择时间段'}]}
                        style={{width: '40%'}}
                      >
                        <Select
                          placeholder={'请选择时间段'}
                          allowClear
                          onChange={(value: string) => {
                            if (value) {
                              const date = moment(appointDate).format('YYYY-MM-DD')
                              const appointTimes = appointTimeCode[value]
                              const appointTimeBegin = date + ' ' + appointTimes[0]
                              const appointTimeEnd = date + ' ' + appointTimes[1]
                              form1.setFieldsValue({appointTimeBegin, appointTimeEnd})
                            }
                          }}
                          disabled={!appointDate}
                        >
                          <Select.Option value={'1'}>上午</Select.Option>
                          <Select.Option value={'2'}>下午</Select.Option>
                          <Select.Option value={'3'}>晚上</Select.Option>
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                )
              }}
            </Form.Item>
            <Form.Item noStyle shouldUpdate={(prevValues, nextValues) => prevValues._userList !== nextValues._userList}>
              {form1 => {
                const userList = form1.getFieldValue('_userList')
                return (
                  <Form.Item label={'保洁员'} name={'serverId'} rules={[{required: true, message: '请选择'}]}>
                    <Select
                      placeholder={'请选择'}
                      allowClear
                      onSearch={searchServerList}
                      showSearch
                      showArrow={false}
                      filterOption={false}
                      defaultActiveFirstOption={false}
                      onChange={(value: string, option) => {
                        const children = ((option as unknown) as {key: string; value: string; children: string})
                          ?.children
                        form1.setFieldsValue({_serverName: children?.split('-')?.[0]})
                        if (value) {
                          searchCountUnfinished(value)
                        } else form1.setFieldsValue({_unfinishedCount: null})
                      }}
                    >
                      {userList?.map(item => (
                        <Select.Option key={item.userId} value={item.userId}>
                          {`${item.name}-${item.mobile}`}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                )
              }}
            </Form.Item>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, nextValues) => prevValues._unfinishedCount !== nextValues._unfinishedCount}
            >
              {form1 => {
                const unfinishedCount = form1.getFieldValue('_unfinishedCount')
                const serverName = form1.getFieldValue('_serverName')
                return (
                  ![null, undefined].includes(unfinishedCount) && (
                    <Form.Item label={' '} colon={false}>
                      <span style={{color: '#00B7AE'}}>
                        {serverName}剩余{unfinishedCount}单未完成
                      </span>
                    </Form.Item>
                  )
                )
              }}
            </Form.Item>

            {type === 'transfer' && (
              <Form.Item label={'转单原因'} name={'assignNotes'} rules={[{required: true, message: '请输入'}]}>
                <Input.TextArea placeholder={'请输入'} allowClear maxLength={200} showCount />
              </Form.Item>
            )}
          </Form>
        ),
      })
    },
    [form, queryActivityRemarkGet, searchCountUnfinished, searchServerList, type]
  )
}
