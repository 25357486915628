import React, {useRef, useEffect, useState, useCallback} from 'react'
import {
  Form,
  Input,
  Select,
  Button,
  Space,
  Row,
  Col,
  Modal,
  message,
  Tabs,
  DatePicker,
  Table,
  Pagination,
  Typography,
} from 'antd'
import {ExclamationCircleFilled} from '@ant-design/icons'
import AreaCascader, {addressValid} from '@/components/AreaCascader'
import {Mapview} from '@/components/Map'
import {useCodeArraySync, useCodeSync} from '@/store/models/Common'
import {Model} from '@redux-model/web'

import './index.less'
import {Breadcrumb} from '@/components/Breadcrumb'
import commonStyles from '@/assets/styles/common.module.less'
import {Permission, usePermission} from '@/components/Permission'
import {sysModel} from '@/store/models/Sys'
import {Marker} from '@/components/Map/Marker'
import {useAppHistory, useAppLocation} from '@/common/hooks/useRouter'
import {Page} from '@/components/Page'
import moment from 'moment'
import BackButton from '@/components/BackButton'
import {useDebounce} from '@/common/hooks/useDebounce'

const {Option} = Select
const {confirm} = Modal

const formItemLayout = {
  labelCol: {span: 4},
  wrapperCol: {span: 18},
}

export default function CommunityDataDetail() {
  const [auth] = usePermission()
  const history = useAppHistory()
  const location = useAppLocation()
  const code = location.state?.code
  const [form] = Form.useForm()
  const [selectForm] = Form.useForm()
  const mapRef = useRef(null)
  const [communityHistoryList, setCommunityHistoryList] = useState<CommunityHistoryVO[]>([])
  const [communityHistoryTotal, setCommunityHistoryTotal] = useState(0)
  const changeTypeArr = useCodeArraySync('sys.community.change.type')
  const changeType = useCodeSync('sys.community.change.type')
  const changeCheck = useCodeSync('sys.community.change.check')

  const loading = Model.isLoading(sysModel.communityAdd.useLoading(), sysModel.communityUpdate.useLoading())
  const deleteLoading = sysModel.communityDelete.useLoading()
  const [historyForm] = Form.useForm()

  const [checkStatus, setCheckStatus] = useState('')

  const historySearch = useCallback(() => {
    const formValue = historyForm.getFieldsValue(true)

    if (code) {
      sysModel
        .sysCommunityHistoryList({code, ...formValue} as SysCommunityHistoryListDTO)
        .then(({response: {data, total}}) => {
          setCommunityHistoryList(data)
          setCommunityHistoryTotal(total)
        })
    }
  }, [code, historyForm])

  const type = useCodeArraySync('community.type')
  const typeObj = useCodeSync('community.type')

  const searchInfo = useCallback(() => {
    if (code) {
      sysModel.communityInfo(code).then(({response: {data}}) => {
        const {cityName, cityCode, districtName, districtCode, bizCircleName, bizCircleCode, ...rest} = data
        form.setFieldsValue({
          ...rest,
          addressValue: {
            cityName,
            cityCode,
            districtName,
            districtCode,
            bizCircleName,
            bizCircleCode,
          },
        })

        setCheckStatus(data.checkStatus)
        mapRef.current?.panTo(data.lng, data.lat)
        const selectFormValue = {lng: data.lng, lat: data.lat}
        if (!rest.address2) {
          selectFormValue['address2'] = cityName + districtName + rest.address
        }
        selectForm.setFieldsValue(selectFormValue)
      })
    }
  }, [code, form, selectForm])

  useEffect(() => {
    searchInfo()
  }, [searchInfo])

  const enterSelectAddress = useCallback(
    async (address: string) => {
      if (address) {
        const point = await mapRef.current?.geocoder(address)
        mapRef.current?.panTo(point.lng, point.lat)
        selectForm.setFieldsValue({lng: point.lng, lat: point.lat})
      }
    },
    [selectForm]
  )

  const selectAddressDebounce = useDebounce(enterSelectAddress, 800, [selectForm])

  function onSelectFinish() {
    const {lat, lng} = selectForm.getFieldsValue()
    form.setFieldsValue({lat, lng})
  }

  async function onFinish() {
    const {addressValue, ...rest} = form.getFieldsValue(true)
    const {address2} = selectForm.getFieldsValue()
    const {cityCode, districtCode, bizCircleCode} = addressValue
    const formValue = {
      ...rest,
      cityCode,
      districtCode,
      bizCircleCode,
      address2,
    }

    if (code) {
      await sysModel.communityUpdate({code, ...(formValue as SysCommunityDTO)})
      message.success('小区数据更新成功')
    } else {
      await sysModel.communityAdd(formValue as SysCommunityDTO)
      message.success('小区数据新增成功')
    }
    searchInfo()
  }

  function communityDelete() {
    if (code) {
      confirm({
        centered: true,
        title: '小区数据删除',
        icon: <ExclamationCircleFilled />,
        content: '是否确认删除该小区数据',
        onOk() {
          sysModel.communityDelete(code).then(() => {
            message.success('小区数据删除成功')
            history.goBack()
          })
        },
      })
    }
  }

  function contentInfo(record: CommunityHistoryVO) {
    const {beforeJson, afterJson} = record
    const beforeObj = JSON.parse(beforeJson)
    const afterObj = JSON.parse(afterJson)
    const before = {
      city: `${beforeObj.cityName}-${beforeObj.districtName}-${beforeObj.bizCircleName}`,
      name: beforeObj.name,
      address: beforeObj.address,
      lat: beforeObj.lat,
      lng: beforeObj.lng,
      type: typeObj[beforeObj.type],
    }
    const after = {
      city: `${afterObj.cityName}-${afterObj.districtName}-${afterObj.bizCircleName}`,
      name: afterObj.name,
      address: afterObj.address,
      lat: afterObj.lat,
      lng: afterObj.lng,
      type: typeObj[afterObj.type],
    }

    const titleObj = {
      city: '所在地区',
      name: '小区名称',
      address: '小区地址',
      lat: '纬度',
      lng: '经度',
      type: '房源类型',
    }

    const arr = Object.keys(before)
      .reduce((result, item) => {
        if (before[item] !== after[item]) {
          if (['cityName', 'districtName', 'bizCircleName'].includes(item)) {
            result.push('city')
          } else {
            result.push(item)
          }
        }
        return result
      }, [])
      .map(item => {
        return {
          title: titleObj[item],
          before: before[item],
          after: after[item],
        }
      })

    Modal.info({
      title: '详细信息',
      icon: null,
      width: 900,
      centered: true,
      content: (
        <Table
          rowKey={'title'}
          columns={[
            {title: '', dataIndex: 'title', key: 'title'},
            {title: '变更前', dataIndex: 'before', key: 'before'},
            {title: '变更后', dataIndex: 'after', key: 'after'},
          ]}
          dataSource={arr}
          pagination={false}
        />
      ),
      okText: '关闭',
    })
  }

  return (
    <Page
      breadcrumb={
        <Breadcrumb
          right={
            code &&
            ['1', '2'].includes(checkStatus) && (
              <Permission code={'019037002'}>
                <Button
                  type={'primary'}
                  onClick={() => {
                    confirm({
                      title: '提示',
                      content: '是否确认核查通过？',
                      icon: null,
                      closable: true,
                      onOk: async () => {
                        await sysModel.sysCommunityCheck({code})
                        // listener.emit('CommunityDataList')
                        history.goBack()
                      },
                    })
                  }}
                >
                  定位核查通过
                </Button>
              </Permission>
            )
          }
        />
      }
    >
      <BackButton />
      <Tabs
        className='tabs'
        onChange={key => {
          key === '2' && historySearch()
        }}
      >
        <Tabs.TabPane tab={'基本信息'} key={'1'}>
          <section>
            <div className={commonStyles.tableSection}>
              <Row>
                <Col span={12}>
                  <Form {...formItemLayout} form={form} onFinish={onFinish}>
                    {code && (
                      <>
                        <Form.Item label={'创建人'} rules={[{required: true, validator: addressValid}]}>
                          <span>{form.getFieldValue('insertName') ?? '/'}</span>
                        </Form.Item>
                        <Form.Item label={'创建时间'} rules={[{required: true, validator: addressValid}]}>
                          <span>{form.getFieldValue('insertTime')}</span>
                        </Form.Item>
                      </>
                    )}
                    <Form.Item
                      name={'addressValue'}
                      label={'所在地区'}
                      rules={[{required: true, validator: addressValid}]}
                    >
                      <AreaCascader
                        type={['city', 'district', 'bizCircle']}
                        onChange={area => {
                          const address = form.getFieldValue('address')
                          if (area.city && area.district && address) {
                            selectForm.setFieldsValue({address2: area.city + area.district + address})
                          }
                        }}
                      />
                    </Form.Item>
                    <Form.Item name={'name'} label={'小区名称'} rules={[{required: true, message: '小区名称不能为空'}]}>
                      <Input allowClear placeholder={'请输入小区名称'} />
                    </Form.Item>
                    <Form.Item
                      name={'address'}
                      label={'小区地址'}
                      rules={[{required: true, message: '小区地址不能为空'}]}
                    >
                      <Input
                        allowClear
                        placeholder={'请输入小区地址'}
                        onChange={event => {
                          const address = event.target.value
                          const {addressValue} = form.getFieldsValue(true)
                          if (addressValue) {
                            // AreaCascader 组件回调 city，后端回调 cityName
                            const {city, cityName, district, districtName} = addressValue
                            selectForm.setFieldsValue({
                              address2: (city || cityName) + (district || districtName) + address,
                            })
                          }
                          selectAddressDebounce(address)
                        }}
                      />
                    </Form.Item>
                    <Form.Item name={'lat'} label={'纬度'} rules={[{required: true, message: '纬度不能为空'}]}>
                      <Input allowClear type={'number'} disabled placeholder={'请输入纬度'} />
                    </Form.Item>
                    <Form.Item name={'lng'} label={'经度'} rules={[{required: true, message: '经度不能为空'}]}>
                      <Input allowClear type={'number'} disabled placeholder={'请输入经度'} />
                    </Form.Item>
                    <Form.Item name={'type'} label={'房源类型'} rules={[{required: true, message: '房源类型不能为空'}]}>
                      <Select allowClear placeholder={'请选择'}>
                        {type.map((item, index) => (
                          <Option key={index} value={item.id}>
                            {item.label}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item wrapperCol={{offset: 4}}>
                      <Space>
                        <Button type={'primary'} onClick={() => history.goBack()}>
                          返回
                        </Button>
                        {auth('002037002') && (
                          <Button type={'primary'} htmlType={'submit'} loading={loading}>
                            保存或修改
                          </Button>
                        )}
                        {code ? (
                          <Permission code={'004037002'}>
                            <Button type={'primary'} danger loading={deleteLoading} onClick={() => communityDelete()}>
                              删除
                            </Button>
                          </Permission>
                        ) : null}
                      </Space>
                    </Form.Item>
                  </Form>
                </Col>
                <Col span={10}>
                  <Form form={selectForm} onFinish={onSelectFinish}>
                    <Form.Item name={'address2'} label={'选点'}>
                      <Input
                        placeholder={'输入搜索条件，回车搜索'}
                        allowClear
                        onPressEnter={(event: any) => enterSelectAddress(event.target.value)}
                        onChange={event => selectAddressDebounce(event.target.value)}
                      />
                    </Form.Item>
                    <Mapview
                      onClick={point => selectForm.setFieldsValue({lat: point.lat, lng: point.lng})}
                      style={{height: 400, marginBottom: '20px'}}
                      ref={mapRef}
                    >
                      <Form.Item noStyle shouldUpdate={(prev, next) => prev.lng !== next.lng || prev.lat !== next.lat}>
                        {f => {
                          const {lng, lat} = f.getFieldsValue()
                          return !!(lng && lat) && <Marker lng={lng} lat={lat} />
                        }}
                      </Form.Item>
                    </Mapview>
                    <Space>
                      <Form.Item name={'lat'} label={'选点纬度'} rules={[{required: true, message: '纬度不能为空'}]}>
                        <Input type={'number'} disabled />
                      </Form.Item>
                      <Form.Item name={'lng'} label={'选点经度'} rules={[{required: true, message: '经度不能为空'}]}>
                        <Input type={'number'} disabled />
                      </Form.Item>
                    </Space>
                    <Form.Item>
                      <Button type={'primary'} htmlType={'submit'}>
                        使用选点坐标
                      </Button>
                    </Form.Item>
                  </Form>
                </Col>
              </Row>
            </div>
          </section>
        </Tabs.TabPane>

        {code && (
          <Tabs.TabPane tab={'变更历史'} key={'2'}>
            <Form
              form={historyForm}
              onFinish={historySearch}
              onReset={historySearch}
              initialValues={{pageNum: 1, pageSize: 10}}
            >
              <div className={commonStyles.filterSection}>
                <Row>
                  <Col span={5}>
                    <Form.Item label={'变更人'} name={'changeName'}>
                      <Input placeholder='请输入' allowClear />
                    </Form.Item>
                  </Col>
                  <Col span={8} offset={1}>
                    <Form.Item label={'变更时间'} name={'time'}>
                      <DatePicker.RangePicker
                        showTime
                        onChange={e => {
                          const [changeTimeBegin, changeTimeEnd] = e ?? []
                          historyForm.setFieldsValue({
                            changeTimeBegin: moment(changeTimeBegin).format('YYYY-MM-DD HH:mm:ss'),
                            changeTimeEnd: moment(changeTimeEnd).format('YYYY-MM-DD HH:mm:ss'),
                          })
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={5} offset={1}>
                    <Form.Item label={'变更类型'} name={'changeType'}>
                      <Select placeholder={'请选择'} allowClear>
                        {changeTypeArr.map(item => (
                          <Option value={item.id} key={item.id}>
                            {item.label}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row justify='end'>
                  <Space>
                    <Button type={'primary'} htmlType={'submit'}>
                      查询
                    </Button>
                    <Button htmlType={'reset'}>重置</Button>
                  </Space>
                </Row>
              </div>
              <div className={commonStyles.filterSection}>
                <Table
                  style={{marginBottom: 10}}
                  rowKey={'historyId'}
                  dataSource={communityHistoryList}
                  columns={[
                    {title: '变更人', dataIndex: 'changeName', key: 'changeName'},
                    {title: '变更类型', dataIndex: 'changeType', key: 'changeType', render: text => changeType[text]},
                    {title: '变更时间', dataIndex: 'changeTime', key: 'changeTime'},
                    {
                      title: '变更内容',
                      dataIndex: 'changeContent',
                      key: 'changeContent',
                      render: (text, record) =>
                        text === '1' ? (
                          <Typography.Text
                            underline
                            style={{color: '#00B7AE'}}
                            onClick={() => {
                              contentInfo(record)
                            }}
                          >
                            详细信息
                          </Typography.Text>
                        ) : (
                          '状态'
                        ),
                    },
                    {
                      title: '变更前',
                      dataIndex: 'changeContent',
                      key: 'changeContent',
                      render: (text, record) =>
                        text === '1' ? (
                          <Typography.Text
                            underline
                            style={{color: '#00B7AE'}}
                            onClick={() => {
                              contentInfo(record)
                            }}
                          >
                            详细信息
                          </Typography.Text>
                        ) : (
                          changeCheck[record.checkStatusBefore]
                        ),
                    },
                    {
                      title: '变更后',
                      dataIndex: 'changeContent',
                      key: 'changeContent',
                      render: (text, record) =>
                        text === '1' ? (
                          <Typography.Text
                            underline
                            style={{color: '#00B7AE'}}
                            onClick={() => {
                              contentInfo(record)
                            }}
                          >
                            详细信息
                          </Typography.Text>
                        ) : (
                          changeCheck[record.checkStatusAfter]
                        ),
                    },
                  ]}
                  pagination={false}
                />

                <Form.Item
                  noStyle
                  shouldUpdate={(prev, cur) => prev.pageNum !== cur.pageNum || cur.pageSize !== prev.pageSize}
                >
                  {() => (
                    <Pagination
                      total={communityHistoryTotal}
                      current={historyForm.getFieldValue('pageNum')}
                      pageSize={historyForm.getFieldValue('pageSize')}
                      style={{textAlign: 'center'}}
                      showSizeChanger
                      onChange={(pageNum, pageSize) => {
                        historyForm.setFieldsValue({pageNum, pageSize})
                        historySearch()
                      }}
                    />
                  )}
                </Form.Item>
              </div>
            </Form>
          </Tabs.TabPane>
        )}
      </Tabs>
    </Page>
  )
}
