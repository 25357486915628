import React, {useCallback, useEffect, useImperativeHandle, useState} from 'react'
import {Button, Form, Input, message, Select, Space} from 'antd'
import AreaCascader from '@/components/AreaCascader'
import {Album, UploadButton} from '@/components/Album'
import commonStyles from '@/assets/styles/common.module.less'
import {MapLocation} from '@/pages/concentrateProject/component/MapLocation'
import Location from '@/assets/icons/location.svg'
import {baseUrl} from '@/common/config'
import {useAppHistory} from '@/common/hooks/useRouter'
import {ebassModel} from '@/store/models/Ebaas'
import ebaas from '@/store/models/Common/ebaasCodes'

interface StoreInfoProps {
  storeNo?: string
  edit?: boolean
}

const storeType = ebaas.storeType
const storeStatus = ebaas.storeStatus

function _StoreInfo({storeNo, edit}: StoreInfoProps, ref) {
  const history = useAppHistory()
  const [form] = Form.useForm()

  const [visible, setVisible] = useState(false)
  useImperativeHandle(ref, () => ({
    onSubmit: async () => {
      await submit()
    },
    isToAli: () => form.getFieldValue('isToAli'),
  }))
  const [point, setPoint] = useState({} as {lat: string; lng: string})

  const queryInfo = useCallback(() => {
    ebassModel.ebaasStoreGet({storeNo}).then(({response: {data}}) => {
      const {
        storePhoto,
        checkImages,
        brandAuthorization,
        storeCity,
        storeCityCode,
        storeDistrict,
        storeDistrictCode,
        storeProvince,
        storeProvinceCode,
        ...values
      } = data
      form.setFieldsValue({
        storePhoto: storePhoto ? storePhoto.split(',') : [],
        checkImages: checkImages ? checkImages.split(',') : [],
        brandAuthorization: brandAuthorization ? brandAuthorization.split(',') : [],
        area: {
          city: storeCity,
          cityCode: storeCityCode,
          province: storeProvince,
          provinceCode: storeProvinceCode,
          district: storeDistrict,
          districtCode: storeDistrictCode,
        },
        ...values,
      })
    })
  }, [form, storeNo])

  useEffect(() => {
    if (storeNo) {
      queryInfo()
    }
  }, [queryInfo, storeNo])

  // 提交
  async function submit() {
    const {storePhoto, checkImages, brandAuthorization, area, ...values} = form.getFieldsValue(true)

    if (storeNo) {
      await ebassModel.ebaasStoreUpdate({
        ...values,
        storePhoto: storePhoto ? storePhoto.join(',') : '',
        checkImages: checkImages ? checkImages.join(',') : '',
        storeCityCode: area.cityCode,
        storeCity: area.city,
        storeProvinceCode: area.provinceCode,
        storeProvince: area.province,
        storeDistrictCode: area.districtCode,
        storeDistrict: area.district,
        brandAuthorization: brandAuthorization ? brandAuthorization.join(',') : '',
      } as EbaasStoreUpdateDTO)
      queryInfo()
      message.success('保存成功！')
    } else {
      await ebassModel.ebaasStoreInsert({
        ...values,
        storePhoto: storePhoto ? storePhoto.join(',') : '',
        checkImages: checkImages ? checkImages.join(',') : '',
        storeCityCode: area.cityCode,
        storeCity: area.city,
        storeProvinceCode: area.provinceCode,
        storeProvince: area.province,
        storeDistrictCode: area.districtCode,
        storeDistrict: area.district,
        brandAuthorization: brandAuthorization ? brandAuthorization.join(',') : '',
      } as EbaasStoreUpdateDTO)
      history.goBack()
    }
  }

  return (
    <div className={commonStyles.filterSection}>
      <Form
        form={form}
        labelCol={{span: 5}}
        wrapperCol={{span: 10}}
        onFinish={submit}
        onReset={() => {
          history.goBack()
        }}
        initialValues={{area: {provinceCode: '310000', province: '上海市', city: '上海市', cityCode: '310100'}}}
      >
        {storeNo && (
          <>
            <Form.Item label={'门店ID'} shouldUpdate>
              {() => <span>{form.getFieldValue('storeId') ?? '/'}</span>}
            </Form.Item>
          </>
        )}
        <Form.Item label={'门店名称'} name={'storeName'} rules={[{required: true, message: '请输入门店名称'}]}>
          <Input placeholder='请输入' disabled={edit} maxLength={20} allowClear />
        </Form.Item>
        <Form.Item label={'门店简称'} name={'storeShortName'} rules={[{required: true, message: '请输入门店简称'}]}>
          <Input placeholder='请输入' disabled={edit} maxLength={10} allowClear />
        </Form.Item>
        <Form.Item
          label={'省市'}
          name={'area'}
          rules={[
            {
              required: true,
              validator(rule, value, callback) {
                if (!value) return callback('省市区不能为空')
                if (!value?.provinceCode) return callback('省份不能为空')
                if (!value?.cityCode) return callback('城市不能为空')
                if (!value?.districtCode) return callback('区不能为空')

                callback()
              },
            },
          ]}
        >
          <AreaCascader type={['province', 'city', 'district']} disabled={edit} />
        </Form.Item>
        <Form.Item label={'门店地址'} name={'storeAddress'} rules={[{required: true, message: '请输入门店地址'}]}>
          <Input placeholder='请输入' disabled={edit} maxLength={50} allowClear />
        </Form.Item>
        <Form.Item label={'类型'} name={'storeType'} rules={[{required: true, message: '请选择类型'}]}>
          <Select placeholder={'请选择'} disabled={edit} allowClear>
            {storeType.map(item => (
              <Select.Option value={item.id} key={item.id}>
                {item.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label={'门店状态'} name={'storeStatus'} rules={[{required: true, message: '请选择门店状态'}]}>
          <Select placeholder={'请选择'} disabled={edit} allowClear>
            {storeStatus.map(item => (
              <Select.Option value={item.id} key={item.id}>
                {item.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label={'门店照片'} shouldUpdate>
          {() => {
            const storePhoto = form.getFieldValue('storePhoto') ?? []
            return (
              <Album
                limit={30}
                action={baseUrl + '/ebaas/common/upload'}
                accept={'.jpg, .jpeg, .png'}
                onChange={({file}) => {
                  if (file.status === 'done') {
                    const {
                      response: {data},
                    } = file
                    form.setFieldsValue({storePhoto: [...storePhoto, data.imgUrl]})
                  }
                  if (file.status === 'removed') {
                    form.setFieldsValue({storePhoto: storePhoto.filter(item => file.url !== item)})
                  }
                }}
                disabled={edit}
                fileList={storePhoto ? storePhoto.map(item => ({url: item, uid: item})) : []}
              >
                <UploadButton />
              </Album>
            )
          }}
        </Form.Item>
        <Form.Item label={'租赁资质照片'} shouldUpdate>
          {() => {
            const checkImages = form.getFieldValue('checkImages') ?? []
            return (
              <Album
                limit={10}
                action={baseUrl + '/ebaas/common/upload'}
                accept={'.jpg, .jpeg, .png'}
                disabled={edit}
                onChange={({file}) => {
                  if (file.status === 'done') {
                    const {
                      response: {data},
                    } = file
                    form.setFieldsValue({checkImages: [...checkImages, data.imgUrl]})
                  }
                  if (file.status === 'removed') {
                    form.setFieldsValue({checkImages: checkImages.filter(item => file.url !== item)})
                  }
                }}
                fileList={checkImages.map(item => ({url: item, uid: item}))}
              >
                <UploadButton />
              </Album>
            )
          }}
        </Form.Item>
        <Form.Item label={'门店坐标'} shouldUpdate>
          {() => {
            const {storeCoordinateX, storeCoordinateY} = form.getFieldsValue(true)
            return (
              <Input
                placeholder='请选择'
                onClick={() => {
                  setPoint({lat: storeCoordinateX, lng: storeCoordinateY})
                  setVisible(true)
                }}
                disabled={edit}
                value={
                  form.getFieldValue('storeCoordinateX')
                    ? `(${form.getFieldValue('storeCoordinateX')}，${form.getFieldValue('storeCoordinateY')})`
                    : ''
                }
                suffix={<img src={Location} alt={'icon'} style={{height: 20, width: 15}} />}
              />
            )
          }}
        </Form.Item>
        <Form.Item label={'外部公司ID'} name={'outerStoreId'}>
          <Input placeholder='请输入' disabled={edit} allowClear />
        </Form.Item>
        <Form.Item
          label={'结算公司ID'}
          name={'settleCompanyId'}
          rules={[
            () => ({
              validator(_, value) {
                if (value && isNaN(value)) return Promise.reject('结算公司ID应为数字')
                return Promise.resolve()
              },
            }),
          ]}
        >
          <Input placeholder='请输入' disabled={edit} allowClear />
        </Form.Item>
        <Form.Item label={'品牌授权书'} shouldUpdate>
          {() => {
            const brandAuthorization = form.getFieldValue('brandAuthorization') ?? []
            return (
              <Album
                disabled={edit}
                limit={9}
                action={baseUrl + '/ebaas/common/upload'}
                accept={'.jpg, .jpeg, .png'}
                onChange={({file}) => {
                  if (file.status === 'done') {
                    const {
                      response: {data},
                    } = file
                    form.setFieldsValue({brandAuthorization: [...brandAuthorization, data.imgUrl]})
                  }
                  if (file.status === 'removed') {
                    form.setFieldsValue({brandAuthorization: brandAuthorization.filter(item => file?.url !== item)})
                  }
                }}
                fileList={brandAuthorization.map(item => ({url: item, uid: item}))}
              >
                <UploadButton />
              </Album>
            )
          }}
        </Form.Item>
        {!edit && !storeNo && (
          <Form.Item label={' '} colon={false}>
            <Space>
              <Button type={'primary'} htmlType={'submit'}>
                保存
              </Button>
              <Button htmlType={'reset'}>取消</Button>
            </Space>
          </Form.Item>
        )}
      </Form>

      <MapLocation
        visible={visible}
        onCancel={() => setVisible(false)}
        onOk={({lat, lng}) => {
          form.setFieldsValue({storeCoordinateX: lat, storeCoordinateY: lng})
          setVisible(false)
        }}
        point={point}
      />
    </div>
  )
}

export const StoreInfo = React.forwardRef(_StoreInfo)
