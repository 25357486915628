import React, {useCallback, useEffect, useState} from 'react'
import {Button, Col, Form, Input, Row, Select, Space, Table, Typography, DatePicker, Modal, message} from 'antd'
import commonStyles from '@/assets/styles/common.module.less'
import {Permission} from '@/components/Permission'
import {stewardModel} from '@/store/models/Steward'
import {Page} from '@/components/Page'
import {Album, UploadButton} from '@/components/Album'
import {getImg} from '@/utils/util'
import {baseUrl} from '@/common/config'
import {useAppHistory} from '@/common/hooks/useRouter'
import {customerModel} from '@/store/models/Customer'
import {useFocusEffect} from '@/common/hooks/useFocusEffect'
import {getPaginationProps} from '@/common/biz/getPaginationProps'

const {Option} = Select
// const {RangePicker} = DatePicker
// const dateList = Array.from({length: 31}).map((_, index) => `${index + 1}`)

export default function ClientList() {
  const history = useAppHistory()

  const [customer, setCustomer] = useState({} as LessorCustomerListVO)

  // 费用类型
  // const formRef = useRef(null)
  const [form] = Form.useForm()

  // const [params, setParams] = useState({pageNum: 1, pageSize: 10, pageable: true} as LessorCustomerListDTO)
  const [total, setTotal] = useState(0)
  const [tableList, setTableList] = useState<LessorCustomerListVO[]>([])

  // const queryCustomerList = useCallback((args: LessorCustomerListDTO) => {
  //   customerModel.customerList(args).then(({response: {data, total}}) => {
  //     setTotal(total)
  //     setTableList(data)
  //   })
  // }, [])

  useFocusEffect(() => {
    form.submit()
  })

  const search = useCallback(() => {
    const values = form.getFieldsValue(true)
    customerModel.customerList(values).then(({response: {data, total}}) => {
      setTotal(total)
      setTableList(data)
    })
  }, [form])

  useEffect(() => {
    form.submit()
  }, [form])

  // useEffect(() => {
  //   queryCustomerList(params)
  // }, [params, queryCustomerList])

  // 管家列表
  const [stewardList, setStewardList] = useState<StewardUserVO[]>([])

  const handleSearch = (value: string) => {
    if (value) {
      stewardModel
        .stewardUserList({pageNum: 1, pageSize: 10, pageable: false, userName: value})
        .then(({response: {data}}) => setStewardList(data))
    } else setStewardList([])
  }

  const listLoading = customerModel.customerList.useLoading()
  const stewardLoading = stewardModel.stewardUserList.useLoading()

  const [attach, setAttach] = useState({visible: false, imgUrl: '', userId: ''})

  return (
    <Page>
      <div className={commonStyles.filterSection}>
        <Form
          form={form}
          autoComplete={'off'}
          labelCol={{flex: '80px'}}
          initialValues={{pageNum: 1, pageSize: 10, pageable: true}}
          onFinish={search}
          onReset={search}
        >
          <Row gutter={30}>
            <Col span={8}>
              <Form.Item label={'客户名称'} name={'certName'}>
                <Input placeholder={'请输入客户名称'} allowClear />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={'客户手机号'} name={'mobile'}>
                <Input placeholder={'请输入客户手机号'} allowClear />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={'BD'} name={'manageName'}>
                <Input placeholder={'请输入BD名称'} allowClear />
              </Form.Item>
            </Col>
            <Col span={16}>
              <Form.Item label={'录入时间'} name={'_time'}>
                <DatePicker.RangePicker
                  showTime
                  style={{width: '100%'}}
                  onChange={(_, formatString) => {
                    form.setFieldsValue({
                      registerTimeBegin: formatString?.[0],
                      registerTimeEnd: formatString?.[1],
                    })
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={'管家名称'} name={'stewardId'} shouldUpdate>
                <Select
                  showSearch
                  placeholder={'请输入管家名称'}
                  onSearch={handleSearch}
                  filterOption={false}
                  notFoundContent={null}
                  loading={stewardLoading}
                  allowClear
                >
                  {stewardList.map(item => (
                    <Option key={item.userId} value={item.userId}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            {/*<Col span={7} offset={1}>*/}
            {/*  <Form.Item label={'房源核算日'} name={'propertyCountDateNum'}>*/}
            {/*    <Select allowClear placeholder={'请选择'}>*/}
            {/*      {dateList.map(item => (*/}
            {/*        <Option key={item} value={item}>*/}
            {/*          {item}*/}
            {/*        </Option>*/}
            {/*      ))}*/}
            {/*    </Select>*/}
            {/*  </Form.Item>*/}
            {/*</Col>*/}
            <Col span={8}>
              <Form.Item label={'状态'} name={'status'}>
                <Select allowClear placeholder={'请选择状态'}>
                  <Option value={'0'}>禁用</Option>
                  <Option value={'1'}>正常</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={'房源推广'} name={'propertyPromote'}>
                <Select allowClear placeholder={'请选择'}>
                  <Option value={'1'}>是</Option>
                  <Option value={'0'}>否</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row justify={'end'}>
            <Space>
              <Button type={'primary'} htmlType={'submit'} onClick={() => form.setFieldsValue({pageNum: 1})}>
                查询
              </Button>
              <Button htmlType={'reset'}>重置</Button>
            </Space>
          </Row>
        </Form>
      </div>

      <div className={commonStyles.tableSection}>
        <Table
          rowKey={'userId'}
          dataSource={tableList}
          columns={[
            {
              title: '客户名称',
              dataIndex: 'certName',
              key: 'certName',
            },
            {
              title: '客户手机',
              dataIndex: 'mobile',
              key: 'mobile',
            },
            {
              title: '录入时间',
              dataIndex: 'insertTime',
              key: 'insertTime',
            },
            {
              title: 'BD',
              dataIndex: 'manageName',
              key: 'manageName',
              render: value => !!value && <span>{value}</span>,
            },
            // {
            //   title: '房源核算日',
            //   dataIndex: 'propertyCountDateNum',
            //   key: 'propertyCountDateNum',
            //   render: value => value ?? '-',
            // },
            {
              title: '服务房源数',
              dataIndex: 'propertyTotal',
              key: 'propertyTotal',
            },
            {
              title: '管家',
              dataIndex: 'stewardList',
              key: 'stewardList',
              render: value => {
                return value.length
                  ? value
                      .filter(item => item)
                      .map((_item, index) => (
                        <span key={index} color={'cyan'}>
                          {_item}
                        </span>
                      ))
                  : '-'
              },
            },
            {
              title: '状态',
              dataIndex: 'status',
              key: 'status',
              render: value => <Typography.Text>{value === '1' ? '正常' : '禁用'}</Typography.Text>,
            },
            {
              title: '房源推广',
              dataIndex: 'propertyPromote',
              key: 'propertyPromote',
              render: value => <Typography.Text>{value === '1' ? '是' : '否'}</Typography.Text>,
            },
            {
              title: '操作',
              fixed: 'right',
              render: (value, record) => {
                return (
                  <Permission code={'001001002'}>
                    <Button
                      type={'link'}
                      size={'small'}
                      onClick={() => {
                        history.push({
                          pathname: '/client/client-info',
                          state: {userId: record.userId, certName: record.certName},
                        })
                      }}
                    >
                      查看
                    </Button>
                  </Permission>
                )
              },
            },
          ]}
          loading={listLoading}
          pagination={getPaginationProps(form, total)}
          // pagination={{
          //   total: total,
          //   // 更新 dataSource 会帮我们更新 pageSize 和 current，重置也会更新 dataSource，所以 pageSize 和 current 能正常显示
          //   // 也解决另外一个问题，如果 table 也用表单更新的话，两个 form 互不影响
          //   pageSize: form.getFieldValue('pageSize'),
          //   current: form.getFieldValue('pageNum'),
          //   position: ['bottomCenter'],
          //   hideOnSinglePage: false,
          //   showSizeChanger: true,
          //   onChange: (pageNum, pageSize) => {
          //     form.setFieldsValue({pageNum, pageSize})
          //     form.submit()
          //   },
          // }}
          // pagination={false}
          /*pagination={{
              total: total,
              pageSize: params.pageSize,
              current: params.pageNum,
              position: ['bottomCenter'],
              hideOnSinglePage: false,
              showSizeChanger: true,
              onChange: (pageNum, pageSize) => setParams(prevState => ({...prevState, pageNum, pageSize})),
            }}*/
          scroll={{x: 'max-content'}}
        />
        {/*<FormPagination total={total} />*/}
      </div>

      <Modal
        visible={!!customer.userId}
        title={'提示'}
        onCancel={() => setCustomer({} as LessorCustomerListVO)}
        footer={null}
      >
        {customer.propertyPromote === '1' ? '是否确认设置当前客户房源不可推广？' : '是否确认设置当前客户房源可推广？ '}
        <Row justify={'end'} style={{marginTop: '30px'}}>
          <Space>
            <Button onClick={() => setCustomer({} as LessorCustomerListVO)}>取消</Button>
            <Button
              type={'primary'}
              onClick={async () => {
                await customerModel.customerPropertyPromoteUpdate({
                  userId: customer.userId,
                  propertyPromote: customer.propertyPromote === '1' ? '0' : '1',
                })
                setCustomer({} as LessorCustomerListVO)
                form.submit()
                message.success('操作成功！')
              }}
              loading={customerModel.customerPropertyPromoteUpdate.useLoading()}
            >
              确认
            </Button>
          </Space>
        </Row>
      </Modal>

      <Modal
        visible={attach.visible}
        title={'添加附件'}
        centered
        onCancel={() => setAttach({visible: false, imgUrl: null, userId: null})}
        onOk={async () => {
          await customerModel.customerCondoBannerUpdate({userId: attach.userId, imgUrl: attach.imgUrl})
          setAttach({visible: false, imgUrl: null, userId: null})
          message.success('操作成功！')
          form.submit()
        }}
      >
        <Space align={'start'}>
          <span>图片：</span>
          <Album
            limit={1}
            fileList={attach.imgUrl ? [{url: getImg(attach.imgUrl), uid: attach.imgUrl}] : []}
            action={baseUrl + '/customer/condo-banner/upload'}
            accept={'.jpg, .jpeg, .png'}
            onChange={info => {
              if (info.file.status === 'done') {
                const {
                  response: {data},
                } = info.file
                setAttach(prevState => ({...prevState, imgUrl: data.imgUrl}))
              }
              if (info.file.status === 'removed') {
                setAttach(prevState => ({...prevState, imgUrl: ''}))
              }
            }}
          >
            {attach.imgUrl ? null : <UploadButton />}
          </Album>
        </Space>
      </Modal>
    </Page>
  )
}
