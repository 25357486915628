import React from 'react'
import {Page} from '@/components/Page'
import SaleClientContractInput from '@/pages/saleManagement/SaleClientContractInput'
import {useAppHistory, useAppLocation} from '@/common/hooks/useRouter'
import {Breadcrumb} from '@/components/Breadcrumb'
import {Button, Space, Modal, Input, message} from 'antd'
import {saleModel} from '@/store/models/Sale'
import BackButton from '@/components/BackButton'
import {usePermission} from '@/components/Permission'

export default function SaleContractSign() {
  const history = useAppHistory()
  const location = useAppLocation<'/sale-client/contract_input'>()
  // 用来查询客户合同详情
  const contractId = location.state?.contractId
  // 签约id
  const signId = location.state?.signId
  const audit = location.state?.audit
  const [auth] = usePermission()

  return (
    <Page
      breadcrumb={
        <Breadcrumb
          // 签约列表过来 path: ['销售管理', '签约列表', '签约详情']
          // 合同列表过来 path: ['销售管理', '合同列表', '合同详情']
          path={
            contractId
              ? ['客户管理', '准客户管理', '签约列表', '合同详情']
              : signId
              ? ['客户管理', '准客户管理', '签约列表', '签约详情']
              : []
          }
          right={
            <>
              {/* 未充值终止 */}
              {audit === 'cancel' && (
                <Space>
                  {auth('002049001') && (
                    <Button
                      onClick={() => {
                        Modal.confirm({
                          title: '确认提示',
                          content: (
                            <Space direction={'vertical'}>
                              <span>确定跳过充值流程？</span>
                            </Space>
                          ),
                          icon: null,
                          centered: true,
                          onOk: async () => {
                            await saleModel.saleCustomerSignConfirmCharge({
                              signId,
                              audit: '1',
                            } as SaleCustomerSignAuditDTO)
                            message.success('操作成功')
                            history.goBack()
                          },
                        })
                      }}
                    >
                      跳过充值流程
                    </Button>
                  )}
                  <Button
                    onClick={() => {
                      Modal.confirm({
                        title: '确认提示',
                        content: (
                          <Space direction={'vertical'}>
                            <span>确认当前签约记录操作未充值终止</span>
                          </Space>
                        ),
                        icon: null,
                        centered: true,
                        onOk: async () => {
                          await saleModel.saleCustomerSignConfirmCharge({
                            signId,
                            audit: '0',
                          } as SaleCustomerSignAuditDTO)
                          message.success('操作成功')
                          history.goBack()
                        },
                      })
                    }}
                  >
                    未充值终止
                  </Button>
                </Space>
              )}
              {/* 签约详情 */}
              {audit === 'sign' && (
                <Space>
                  <Button
                    onClick={() => {
                      let auditRemark = ''
                      Modal.confirm({
                        title: '驳回意见',
                        content: (
                          <Input
                            placeholder={'请输入驳回意见'}
                            onChange={event => (auditRemark = event.target.value)}
                            allowClear
                          />
                        ),
                        icon: null,
                        onOk: async () => {
                          if (!auditRemark) return message.error('请输入驳回意见！')
                          await saleModel.saleCustomerSignConfirm({
                            audit: '0',
                            auditRemark,
                            signId,
                          } as SaleCustomerSignAuditDTO)
                          await message.success('操作成功')
                          history.goBack()
                        },
                      })
                    }}
                  >
                    驳回
                  </Button>
                  <Button
                    type={'primary'}
                    onClick={() => {
                      Modal.confirm({
                        title: '提示',
                        icon: null,
                        content: '是否确认通过该条签约申请？',
                        onOk: async () => {
                          await saleModel.saleCustomerSignConfirm({audit: '1', signId} as SaleCustomerSignAuditDTO)
                          await message.success('操作成功')
                          history.goBack()
                        },
                      })
                    }}
                  >
                    通过
                  </Button>
                </Space>
              )}
              {audit === 'contract' && (
                <Button
                  type={'primary'}
                  onClick={() => {
                    Modal.confirm({
                      title: '提示',
                      icon: null,
                      content: '是否确认签约？',
                      onOk: async () => {
                        await saleModel.saleCustomerContractConfirm({contractId})
                        await message.success('操作成功')
                        history.goBack()
                      },
                    })
                  }}
                >
                  签约
                </Button>
              )}
            </>
          }
        />
      }
    >
      <BackButton />
      <SaleClientContractInput contractId={contractId} signId={signId} />
    </Page>
  )
}
