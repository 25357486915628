import React, {useCallback, useEffect, useState} from 'react'
import {stewardModel} from '@/store/models/Steward'
import {Button, Col, message, Popover, Row, Space, Switch, Tree, Typography} from 'antd'
import {InfoCircleFilled} from '@ant-design/icons'

export default function TenantServiceConfig({lessorId}: {lessorId: string}) {
  const [treeData, setTreeData] = useState<StewardServiceVO[]>([])

  useEffect(() => {
    stewardModel
      .stewardAdminServiceTree({permLessee: '1'} as StewardServiceQueryDTO)
      .then(({response: {data}}) => setTreeData(data))
  }, [])

  const [templateEnable, setTemplateEnable] = useState<boolean>(false)
  const [lesseeTemplateList, setLesseeTemplateList] = useState<string[]>([])

  const queryLesseeTemplate = useCallback(() => {
    stewardModel.stewardAdminLessee_templateGet({lessorId}).then(({response: {data}}) => {
      setTemplateEnable(data.status === 1)
      setLesseeTemplateList(data.templateList?.map(item => item.serviceId))
    })
  }, [lessorId])

  useEffect(() => {
    queryLesseeTemplate()
  }, [queryLesseeTemplate])

  const [disabled, setDisabled] = useState(true)

  const saveLoading = stewardModel.stewardAdminLessee_templateSave.useLoading()

  return (
    <div style={{padding: 15, backgroundColor: '#fff'}}>
      <Row>
        <Col span={18}>
          <Typography>
            <Typography.Title level={3}>租客报修项目配置</Typography.Title>
          </Typography>
        </Col>
        <Col span={6} style={{display: 'flex', flexDirection: 'row-reverse'}}>
          {disabled ? (
            <Button onClick={() => setDisabled(prevState => !prevState)}>编辑</Button>
          ) : (
            <Space>
              <Button
                onClick={() => {
                  queryLesseeTemplate()
                  setDisabled(prevState => !prevState)
                }}
              >
                取消
              </Button>
              <Button
                type={'primary'}
                onClick={async () => {
                  await stewardModel.stewardAdminLessee_templateSave({
                    lessorId,
                    serviceIdList: templateEnable ? lesseeTemplateList : [],
                    status: templateEnable ? 1 : 0,
                  })
                  queryLesseeTemplate()
                  message.success('保存成功！')
                  setDisabled(prevState => !prevState)
                }}
                loading={saveLoading}
              >
                保存
              </Button>
            </Space>
          )}
        </Col>
      </Row>
      <Row>
        <Col>是否可报修：</Col>
        <Col>
          <Space>
            <Switch disabled={disabled} checked={templateEnable} onChange={checked => setTemplateEnable(checked)} />
            <Popover
              content={
                <>
                  <Typography.Paragraph>
                    ①关闭开关表示牛油果管家app&牛油果管家小程序不可报修当前客户所有房源所有服务项目
                  </Typography.Paragraph>
                  <Typography.Paragraph>
                    ②打开开关可选择牛油果管家app&牛油果管家小程序不可报修当前客户所有房源的服务项目
                  </Typography.Paragraph>
                </>
              }
            >
              <InfoCircleFilled style={{fontSize: '20px', marginTop: '3px'}} />
            </Popover>
          </Space>
        </Col>
      </Row>
      {templateEnable && (
        <Row style={{marginTop: '10px'}}>
          <Col>不可报修项目：</Col>
          <Col>
            <Tree
              checkable
              disabled={disabled}
              treeData={treeData as any[]}
              fieldNames={{title: 'name', key: 'id', children: 'stewardServiceList'}}
              checkedKeys={lesseeTemplateList}
              onCheck={(_, info) => {
                const checkedNodes = info.checkedNodes as StewardServiceVO[]
                const nodeLevel3 = checkedNodes.filter(item => item.level === 3)
                setLesseeTemplateList(nodeLevel3.map(item => item.id))
              }}
            />
          </Col>
        </Row>
      )}
    </div>
  )
}
