import React, {useCallback, useEffect, useState} from 'react'
import {Page} from '@/components/Page'
import {Badge, Col, Descriptions, Image, PageHeader, Row, Space, Table, Timeline} from 'antd'
import {useAppHistory, useAppLocation} from '@/common/hooks/useRouter'
import commonStyles from '@/assets/styles/common.module.less'
import DividerLine from '@/pages/property/component/DividerLine'
import {lhSettlementModel} from '@/store/models/LhSettlement'
import moment from 'moment'
import {FORMAT_DATE} from '@/common/date'
import {useCodeSync} from '@/store/models/Common'
import {primary} from '@/assets/styles/vars.less'
import {getImg} from '@/utils/util'

export default function SettlementDetail() {
  const history = useAppHistory()
  const location = useAppLocation<'/settlement-detail'>()
  const settlementId = location.state?.settlementId

  const approvalStatusCode = useCodeSync('wework.approvalStatus')
  const settlementCode = useCodeSync('lh.settlement')

  const [settlementInfo, setSettlementInfo] = useState({} as LhSettlementVO)

  const querySettlementInfo = useCallback(() => {
    lhSettlementModel.lhSettlementInfo({settlementId}).then(({response: {data}}) => {
      setSettlementInfo(data)
    })
  }, [settlementId])

  useEffect(() => {
    querySettlementInfo()
  }, [querySettlementInfo])

  return (
    <Page header={<PageHeader title={'返回'} ghost={false} onBack={() => history.goBack()} />}>
      <div className={commonStyles.tableSection}>
        <Row gutter={20}>
          <Col span={20}>
            <Descriptions title={<DividerLine title={'基本信息'} />} column={3}>
              <Descriptions.Item label='结算编号'>{settlementInfo.settlementId}</Descriptions.Item>
              <Descriptions.Item label='批次号'>{settlementInfo.batchId}</Descriptions.Item>
              <Descriptions.Item label='区域'>{settlementInfo.region}</Descriptions.Item>
              <Descriptions.Item label='城市'>{settlementInfo.city}</Descriptions.Item>
              <Descriptions.Item label='门店'>{settlementInfo.condoName}</Descriptions.Item>
              <Descriptions.Item label='期数'>{settlementInfo.period}</Descriptions.Item>
              <Descriptions.Item label='费用类型'>{settlementInfo.feeType}</Descriptions.Item>
              <Descriptions.Item label='含税金额'>{settlementInfo.taxIncludedAmount}</Descriptions.Item>
              <Descriptions.Item label='不含税金额'>{settlementInfo.taxExcludedAmount}</Descriptions.Item>
              <Descriptions.Item label='结算周期起'>
                {moment(settlementInfo.settlementPeriodStart).format(FORMAT_DATE)}
              </Descriptions.Item>
              <Descriptions.Item label='结算周期止'>
                {moment(settlementInfo.settlementPeriodEnd).format(FORMAT_DATE)}
              </Descriptions.Item>
              <Descriptions.Item label='应收款日'>
                {moment(settlementInfo.receivableDate).format(FORMAT_DATE)}
              </Descriptions.Item>
              <Descriptions.Item label='最后付款日'>
                {moment(settlementInfo.lastPaymentDate).format(FORMAT_DATE)}
              </Descriptions.Item>
              <Descriptions.Item label='人工统计房间数'>{settlementInfo.roomNumber}</Descriptions.Item>
              <Descriptions.Item label='人工统计公区数'>{settlementInfo.publicAreaNumber}</Descriptions.Item>
              <Descriptions.Item label='系统房间数'>{settlementInfo.roomCount}</Descriptions.Item>
              <Descriptions.Item label='系统公区数'>{settlementInfo.publicAreaCount}</Descriptions.Item>
              <Descriptions.Item label='开业日期'>
                {moment(settlementInfo.openingTime).format(FORMAT_DATE)}
              </Descriptions.Item>
              <Descriptions.Item label='服务开始日期'>
                {moment(settlementInfo.serviceStartTime).format(FORMAT_DATE)}
              </Descriptions.Item>
              <Descriptions.Item label='服务结束日期'>
                {moment(settlementInfo.serviceEndTime).format(FORMAT_DATE)}
              </Descriptions.Item>
              <Descriptions.Item label='发票号'>{settlementInfo.invoiceNumber}</Descriptions.Item>
              <Descriptions.Item label='创建人'>{settlementInfo.operatorName}</Descriptions.Item>
              <Descriptions.Item label='创建时间'>{settlementInfo.insertTime}</Descriptions.Item>
            </Descriptions>

            <Descriptions title={<DividerLine title={'开票信息'} top={15} />} column={3}>
              <Descriptions.Item label='抬头类型'>{settlementInfo.titleType}</Descriptions.Item>
              <Descriptions.Item label='发票类型'>{settlementInfo.invoiceType}</Descriptions.Item>
              <Descriptions.Item label='发票抬头'>{settlementInfo.invoiceTitle}</Descriptions.Item>
              <Descriptions.Item label='税号'>{settlementInfo.taxNumber}</Descriptions.Item>
              <Descriptions.Item label='发票备注栏内容'>{settlementInfo.invoiceRemarkContent}</Descriptions.Item>
            </Descriptions>

            <Table
              rowKey={'invoiceId'}
              columns={[
                {title: '审批编号', dataIndex: 'invoiceId'},
                {title: '创建时间', dataIndex: 'insertTime'},
                {title: '创建人', dataIndex: 'operatorName'},
                {title: '审批状态', dataIndex: 'invoiceStatus', render: value => approvalStatusCode[value]},
                {
                  title: '附件',
                  dataIndex: 'attachmentUrl',
                  render: value => (
                    <Space direction={'vertical'}>
                      {value?.map(value => (
                        <a key={value} href={getImg(value)} target={'_blank'} rel={'noopener noreferrer'}>
                          {getImg(value)}
                        </a>
                      ))}
                    </Space>
                  ),
                  width: '200px',
                },
                {
                  title: '备注',
                  dataIndex: 'remark',
                  render: value => (
                    <div style={{wordWrap: 'break-word', wordBreak: 'break-all', overflowWrap: 'break-word'}}>
                      {value}
                    </div>
                  ),
                  width: '300px',
                },
              ]}
              dataSource={settlementInfo.invoiceRecordList}
              scroll={{x: 'max-content'}}
              style={{margin: '20px 0'}}
            />

            <Descriptions title={<DividerLine title={'用章信息'} top={15} />} column={3}>
              <Descriptions.Item label='所在部门'>{settlementInfo.orgName}</Descriptions.Item>
              <Descriptions.Item label='公司名称'>{settlementInfo.company}</Descriptions.Item>
              <Descriptions.Item label='印章类型'>{settlementInfo.sealType}</Descriptions.Item>
            </Descriptions>

            <Table
              rowKey={'sealId'}
              columns={[
                {title: '审批编号', dataIndex: 'sealId'},
                {title: '创建时间', dataIndex: 'insertTime'},
                {title: '创建人', dataIndex: 'operatorName'},
                {title: '审批状态', dataIndex: 'sealStatus', render: value => approvalStatusCode[value]},
                {title: '用章事由', dataIndex: 'sealReason'},
                {title: '文件名称', dataIndex: 'fileName'},
                {title: '文件份数', dataIndex: 'fileCopies'},
                {title: '用印日期', dataIndex: 'sealDate'},
                {
                  title: '工作量确认单',
                  dataIndex: 'workloadConfirmationUrl',
                  render: value => (
                    <Space direction={'vertical'}>
                      {value?.map(value => (
                        <a key={value} href={getImg(value)} target={'_blank'} rel={'noopener noreferrer'}>
                          {getImg(value)}
                        </a>
                      ))}
                    </Space>
                  ),
                  width: '200px',
                },
                {
                  title: '附件',
                  dataIndex: 'attachmentUrl',
                  render: value => (
                    <Space direction={'vertical'}>
                      {value?.map(value => (
                        <a key={value} href={getImg(value)} target={'_blank'} rel={'noopener noreferrer'}>
                          {getImg(value)}
                        </a>
                      ))}
                    </Space>
                  ),
                  width: '200px',
                },
                {
                  title: '备注',
                  dataIndex: 'remark',
                  render: value => (
                    <div style={{wordWrap: 'break-word', wordBreak: 'break-all', overflowWrap: 'break-word'}}>
                      {value}
                    </div>
                  ),
                  width: '300px',
                },
              ]}
              dataSource={settlementInfo.sealRecordList}
              scroll={{x: 'max-content'}}
              style={{margin: '20px 0'}}
            />
          </Col>
          <Col span={4}>
            <DividerLine title={'结算跟进'} bottom={15} />
            <Timeline>
              {settlementInfo.followRecordList?.map((item, index) => {
                return (
                  <Timeline.Item
                    key={index}
                    dot={
                      <Badge
                        dot={true}
                        color={index === 0 ? primary : '#D8D8D8'}
                        style={{width: '16px', height: '16px'}}
                      />
                    }
                  >
                    <Space direction={'vertical'}>
                      <span>{settlementCode[item.followStatus]}</span>
                      <span>{item.userName}</span>
                      <span>{item.operatorName}</span>
                      <span>{item.insertTime}</span>
                      {item.pictureUrl?.length ? (
                        <Image.PreviewGroup>
                          {item.pictureUrl?.map(item => (
                            <Image key={item} width={100} height={100} src={getImg(item)} style={{padding: '5px'}} />
                          ))}
                        </Image.PreviewGroup>
                      ) : null}
                      {item.recordingUrl?.map(value => (
                        <a key={value} href={getImg(value)} target={'_blank'} rel={'noopener noreferrer'}>
                          {getImg(value)}
                        </a>
                      ))}
                      <span>{item.logisticsNumber}</span>
                      <span>{item.remark}</span>
                    </Space>
                  </Timeline.Item>
                )
              })}
            </Timeline>
          </Col>
        </Row>
      </div>
    </Page>
  )
}
