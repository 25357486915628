import React, {useCallback, useEffect, useState} from 'react'
import {Button, Col, DatePicker, Drawer, Form, Input, Row, Select, Space, Table, Tabs, Typography} from 'antd'
import {stewardModel} from '@/store/models/Steward'
import {useCodeSync} from '@/store/models/Common'
import {getPaginationProps} from '@/common/biz/getPaginationProps'
import {useAppHistory} from '@/common/hooks/useRouter'
import moment from 'moment'
import {cleanServiceArr} from '@/utils/clean'
import useSorter from '@/common/lib/useSorter'
import {FORMAT_DATE_END, FORMAT_DATE_START} from '@/common/date'

interface LookServiceListProps {
  title: string
  onClose: () => void
}

export default function LookServiceList({title, onClose}: LookServiceListProps) {
  return (
    <Drawer
      visible={!!title}
      width={1100}
      title={title}
      onClose={() => {
        onClose()
      }}
      destroyOnClose
    >
      {title === '服务时段' && <ServiceListComponent type={'1'} />}
      {title === '服务取消' && <ServiceCancelListComponent />}
      {title === '服务改约' && <ServiceListComponent type={'3'} />}
      {title === '服务完成' && <ServiceListComponent type={'4'} />}
      {title === '服务超时' && <ServiceListComponent type={'5'} />}
    </Drawer>
  )
}

// 服务时段
const ServiceListComponent: React.FC<{type: string}> = ({type}) => {
  const [tabKey, setTabKey] = useState('1')
  const [stewardForm] = Form.useForm()
  const [cleanForm] = Form.useForm()
  const [stewardList, setStewardList] = useState<ServiceDataBoardListVO[]>([])
  const [stewardTotal, setStewardTotal] = useState(0)
  const [cleanList, setCleanList] = useState<ServiceDataBoardListVO[]>([])
  const [cleanTotal, setCleanTotal] = useState(0)
  const orderStatus = useCodeSync('steward.orderStatus')
  const stewardLoading = stewardModel.serviceDataStewardList.useLoading()
  const cleanLoading = stewardModel.serviceDataCleanList.useLoading()
  const stewardSorter = useSorter(stewardForm)
  const cleanSorter = useSorter(cleanForm)
  const stewardSearch = useCallback(() => {
    const {stewardOrderStatusList, ...values} = stewardForm.getFieldsValue(true)
    if (type === '3') {
      stewardModel
        .serviceDataStewardChangeList({...values, stewardOrderStatusList})
        .then(({response: {data, total}}) => {
          setStewardList(data)
          setStewardTotal(total)
        })
    } else {
      const timeoutStatus = type === '5' ? '1' : null
      const statusList = type === '4' ? ['5'] : stewardOrderStatusList
      stewardModel
        .serviceDataStewardList({...values, timeoutStatus, stewardOrderStatusList: statusList})
        .then(({response: {data, total}}) => {
          setStewardList(data)
          setStewardTotal(total)
        })
    }
  }, [stewardForm, type])

  const cleanSearch = useCallback(() => {
    const {cleanOrderStatusList, ...values} = cleanForm.getFieldsValue(true)
    if (type === '3') {
      stewardModel.serviceDataCleanChangeList({...values, cleanOrderStatusList}).then(({response: {data, total}}) => {
        setCleanList(data)
        setCleanTotal(total)
      })
    } else {
      const timeoutStatus = type === '5' ? '1' : null
      const statusList = type === '4' ? ['4'] : cleanOrderStatusList
      stewardModel
        .serviceDataCleanList({...values, timeoutStatus, cleanOrderStatusList: statusList})
        .then(({response: {data, total}}) => {
          setCleanList(data)
          setCleanTotal(total)
        })
    }
  }, [cleanForm, type])

  useEffect(() => {
    if (tabKey === '1') {
      stewardSearch()
    } else {
      cleanSearch()
    }
  }, [cleanSearch, stewardSearch, tabKey])

  return (
    <Tabs activeKey={tabKey} onChange={key => setTabKey(key)}>
      <Tabs.TabPane tab={'维修'} key={'1'}>
        <Form
          form={stewardForm}
          onFinish={stewardSearch}
          onReset={stewardSearch}
          initialValues={{pageNum: 1, pageSize: 10, pageable: true}}
        >
          <Row>
            <Col span={7}>
              <Form.Item label={'服务单号'} name={'orderId'}>
                <Input placeholder={'请输入'} />
              </Form.Item>
            </Col>
            <Col span={7} offset={1}>
              <Form.Item label={'管家'} name={'stewardName'}>
                <Input placeholder={'请输入'} />
              </Form.Item>
            </Col>
            <Col span={7} offset={1}>
              <Form.Item label={'服务房源'} name={'propertyName'}>
                <Input placeholder={'请输入'} />
              </Form.Item>
            </Col>
            {type === '4' ? (
              <Col span={7}>
                <Form.Item label={'完成时间'} name={'_timeFinishTime'}>
                  <DatePicker.RangePicker
                    onChange={(_, dateStrings) => {
                      const [finishedTimeBegin, finishedTimeEnd] = dateStrings ?? []
                      stewardForm.setFieldsValue({
                        timeAppointBegin: finishedTimeBegin ? finishedTimeBegin + ' 00:00:00' : undefined,
                        timeAppointEnd: finishedTimeEnd ? finishedTimeEnd + ' 23:59:59' : undefined,
                      })
                    }}
                  />
                </Form.Item>
              </Col>
            ) : (
              <Col span={7}>
                <Form.Item label={'最新约定上门时间'} name={'_timeAppointTime'}>
                  <DatePicker.RangePicker
                    onChange={(_, dateStrings) => {
                      const [timeAppointBegin, timeAppointEnd] = dateStrings ?? []
                      stewardForm.setFieldsValue({
                        timeAppointBegin: timeAppointBegin ? timeAppointBegin + ' 00:00:00' : undefined,
                        timeAppointEnd: timeAppointEnd ? timeAppointEnd + ' 23:59:59' : undefined,
                      })
                    }}
                  />
                </Form.Item>
              </Col>
            )}
            <Col span={7} offset={1}>
              <Form.Item label={'服务项目'} name={'categorySearch'}>
                <Input placeholder={'请输入'} />
              </Form.Item>
            </Col>
            {type !== '4' && (
              <Col span={7} offset={1}>
                <Form.Item label={'服务状态'} name={'stewardOrderStatusList'}>
                  <Select placeholder={'请选择'} mode={'multiple'} allowClear>
                    <Select.Option value={'2'}>已沟通</Select.Option>
                    <Select.Option value={'3'}>上门中</Select.Option>
                    <Select.Option value={'4'}>服务中</Select.Option>
                    <Select.Option value={'5'}>已完成</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
            )}
          </Row>
          <Row justify={'end'}>
            <Space>
              <Button
                type={'primary'}
                htmlType={'submit'}
                onClick={() => {
                  stewardForm.setFieldsValue({pageNum: 1})
                }}
              >
                查询
              </Button>
              <Button htmlType={'reset'}>重置</Button>
            </Space>
          </Row>
        </Form>
        <Table
          rowKey={'orderId'}
          dataSource={stewardList}
          {...stewardSorter}
          style={{marginTop: 20}}
          scroll={{x: 'max-content'}}
          loading={stewardLoading}
          columns={[
            {
              title: '服务单号',
              dataIndex: 'orderId',
              render: (value, record) => (
                <Space direction={'vertical'}>
                  <Typography.Text>{value}</Typography.Text>
                  <Typography.Text>{`${record.categoryFir}-${record.categorySec}-${record.categoryThi}`}</Typography.Text>
                </Space>
              ),
            },
            {
              title: '服务状态',
              dataIndex: 'orderStatus',
              render: value => orderStatus[value],
            },
            {title: '管家', dataIndex: 'serviceName'},
            ...(type !== '4' ? [{title: '最新约定上门时间', dataIndex: 'timeAppoint', sorter: true}] : []),
            ...(type === '4' ? [{title: '完成时间', dataIndex: 'timeFinished', sorter: true}] : []),
            ...(type === '5' ? [{title: '超时时间', dataIndex: 'timeOutTime', sorter: true}] : []),
            ...(type === '3' ? [{title: '改约次数', dataIndex: 'appointChangeTimes', sorter: true}] : []),
            {title: '服务房源', dataIndex: 'propertyAddr'},
          ]}
          pagination={getPaginationProps(stewardForm, stewardTotal)}
        />
      </Tabs.TabPane>
      <Tabs.TabPane tab={'保洁'} key={'2'} forceRender>
        <Form
          form={cleanForm}
          onFinish={cleanSearch}
          onReset={cleanSearch}
          initialValues={{pageNum: 1, pageSize: 10, pageable: true}}
        >
          <Row>
            <Col span={7}>
              <Form.Item label={'服务单号'} name={'orderId'}>
                <Input placeholder={'请输入'} />
              </Form.Item>
            </Col>
            <Col span={7} offset={1}>
              <Form.Item label={'保洁员'} name={'stewardName'}>
                <Input placeholder={'请输入'} />
              </Form.Item>
            </Col>
            <Col span={7} offset={1}>
              <Form.Item label={'服务房源'} name={'propertyName'}>
                <Input placeholder={'请输入'} />
              </Form.Item>
            </Col>
            {type === '4' ? (
              <Col span={7}>
                <Form.Item label={'完成时间'} name={'_timeFinishTime'}>
                  <DatePicker.RangePicker
                    onChange={(_, dateStrings) => {
                      const [finishedTimeBegin, finishedTimeEnd] = dateStrings ?? []
                      cleanForm.setFieldsValue({
                        timeAppointBegin: finishedTimeBegin ? finishedTimeBegin + ' 00:00:00' : undefined,
                        timeAppointEnd: finishedTimeEnd ? finishedTimeEnd + ' 23:59:59' : undefined,
                      })
                    }}
                  />
                </Form.Item>
              </Col>
            ) : (
              <Col span={7}>
                <Form.Item label={'最新约定上门时间'} name={'_timeAppointTime'}>
                  <DatePicker.RangePicker
                    onChange={(_, dateStrings) => {
                      const [timeAppointBegin, timeAppointEnd] = dateStrings ?? []
                      cleanForm.setFieldsValue({
                        timeAppointBegin: timeAppointBegin ? timeAppointBegin + ' 00:00:00' : undefined,
                        timeAppointEnd: timeAppointEnd ? timeAppointEnd + ' 23:59:59' : undefined,
                      })
                    }}
                  />
                </Form.Item>
              </Col>
            )}
            <Col span={7} offset={1}>
              <Form.Item label={'服务项目'} name={'categoryThiList'}>
                <Select placeholder={'请选择'} mode={'multiple'} allowClear>
                  {cleanServiceArr.map(item => (
                    <Select.Option value={item.id} key={item.id}>
                      {item.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            {type !== '4' && (
              <Col span={7} offset={1}>
                <Form.Item label={'服务状态'} name={'cleanOrderStatusList'}>
                  <Select placeholder={'请选择'} mode={'multiple'} allowClear>
                    <Select.Option value={'3'}>已派单</Select.Option>
                    <Select.Option value={'4'}>已完成</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
            )}
          </Row>
          <Row justify={'end'}>
            <Space>
              <Button
                type={'primary'}
                htmlType={'submit'}
                onClick={() => {
                  cleanForm.setFieldsValue({pageNum: 1})
                }}
              >
                查询
              </Button>
              <Button htmlType={'reset'}>重置</Button>
            </Space>
          </Row>
        </Form>
        <Table
          rowKey={'orderId'}
          dataSource={cleanList}
          {...cleanSorter}
          style={{marginTop: 20}}
          scroll={{x: 'max-content'}}
          loading={cleanLoading}
          columns={[
            {
              title: '服务单号',
              dataIndex: 'orderId',
              render: (value, record) => (
                <Space direction={'vertical'}>
                  <Typography.Text>{value}</Typography.Text>
                  <Typography.Text>{`${record.categoryFir}-${record.categorySec}-${record.categoryThi}`}</Typography.Text>
                </Space>
              ),
            },
            {
              title: '服务状态',
              dataIndex: 'orderStatus',
              render: value => (value === '3' ? '已派单' : '已完成'),
            },
            {title: '保洁员', dataIndex: 'serviceName'},
            ...(type !== '4' ? [{title: '最新约定上门时间', dataIndex: 'timeAppoint', sorter: true}] : []),
            ...(type === '4' ? [{title: '完成时间', dataIndex: 'timeFinished', sorter: true}] : []),
            ...(type === '5' ? [{title: '超时时间', dataIndex: 'timeOutTime', sorter: true}] : []),
            ...(type === '3' ? [{title: '改约次数', dataIndex: 'appointChangeTimes', sorter: true}] : []),
            {title: '服务房源', dataIndex: 'propertyAddr'},
          ]}
          pagination={getPaginationProps(cleanForm, cleanTotal)}
        />
      </Tabs.TabPane>
    </Tabs>
  )
}

// 服务取消
const ServiceCancelListComponent = () => {
  const history = useAppHistory()
  const [form] = Form.useForm()
  const sorter = useSorter(form)
  const [cancelList, setCancelList] = useState<ServiceDataBoardStewardCancelListVO[]>([])
  const [cancelTotal, setCancelTotal] = useState<number>(0)
  const cancelLoading = stewardModel.serviceDataStewardCancelList.useLoading()
  const search = useCallback(() => {
    const values = form.getFieldsValue(true)
    stewardModel.serviceDataStewardCancelList({...values}).then(({response: {data, total}}) => {
      setCancelList(data)
      setCancelTotal(total)
    })
  }, [form])

  useEffect(() => {
    search()
  }, [search])

  return (
    <>
      <Form
        form={form}
        onFinish={search}
        onReset={search}
        initialValues={{
          pageNum: 1,
          pageSize: 10,
          pageable: true,
          timeMakeBegin: moment().subtract(30, 'day').format(FORMAT_DATE_START),
          timeMakeEnd: moment().format(FORMAT_DATE_END),
          _time: [moment().subtract(30, 'day'), moment()],
        }}
      >
        <Row>
          <Col span={7}>
            <Form.Item label={'客户名称'} name={'lessorName'}>
              <Input placeholder={'请输入'} />
            </Form.Item>
          </Col>
          <Col span={7} offset={1}>
            <Form.Item label={'客户手机号'} name={'lessorMobile'}>
              <Input placeholder={'请输入'} />
            </Form.Item>
          </Col>
          <Col span={7} offset={1}>
            <Form.Item label={'下单时间'} name={'_time'}>
              <DatePicker.RangePicker
                onChange={(_, dateStrings) => {
                  const [timeMakeBegin, timeMakeEnd] = dateStrings ?? []
                  form.setFieldsValue({
                    timeMakeBegin: timeMakeBegin ? timeMakeBegin + ' 00:00:00' : undefined,
                    timeMakeEnd: timeMakeEnd ? timeMakeEnd + ' 23:59:59' : undefined,
                  })
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row justify={'end'}>
          <Space>
            <Button type={'primary'} htmlType={'submit'} onClick={() => form.setFieldsValue({pageNum: 1})}>
              查询
            </Button>
            <Button htmlType={'reset'}>重置</Button>
          </Space>
        </Row>
      </Form>
      <Table
        rowKey={'lessorMobile'}
        dataSource={cancelList}
        loading={cancelLoading}
        {...sorter}
        style={{marginTop: 20}}
        scroll={{x: 'max-content'}}
        columns={[
          {
            title: '客户',
            dataIndex: 'lessorName',
          },
          {title: '手机号', dataIndex: 'lessorMobile'},
          {title: '下单数量', dataIndex: 'sum', sorter: true},
          {title: '取消数量', dataIndex: 'cancelSum', sorter: true},
          {title: '取消占比', dataIndex: 'cancelRatio', render: value => `${Math.floor(value * 100)}%`, sorter: true},
          {
            title: '操作',
            render: (_, record) => {
              const {lessorMobile} = record
              const {timeMakeBegin, timeMakeEnd} = form.getFieldsValue(true)
              return (
                <Button
                  type={'link'}
                  style={{paddingLeft: 0, paddingRight: 0}}
                  onClick={() =>
                    history.push({
                      pathname: '/order-list',
                      state: {
                        lessorMobile,
                        orderStatus: '6',
                        timeMakeBegin: timeMakeBegin
                          ? timeMakeBegin
                          : moment().subtract(30, 'day').format(FORMAT_DATE_START),
                        timeMakeEnd: timeMakeEnd ? timeMakeEnd : moment().format(FORMAT_DATE_END),
                      },
                    })
                  }
                >
                  查看
                </Button>
              )
            },
          },
        ]}
        pagination={getPaginationProps(form, cancelTotal)}
      />
    </>
  )
}
