import React, {useEffect} from 'react'
import {Page} from '@/components/Page'
import commonStyles from '@/assets/styles/common.module.less'
import {Button, Form, Input, InputNumber, PageHeader, Radio, Select, Space} from 'antd'
import {Breadcrumb} from '@/components/Breadcrumb'
import pageStyle from '@/components/Page/index.module.less'
import {SwapRightOutlined} from '@ant-design/icons'
import {adminModel} from '@/store/models/Admin'
import {useAppHistory, useAppLocation} from '@/common/hooks/useRouter'
import {useCodeArraySync} from '@/store/models/Common'

export default function AddClient() {
  const history = useAppHistory()
  const location = useAppLocation<'/crm/add-client'>()
  const customerId = location.state?.customerId
  const [form] = Form.useForm()
  const customerChannelArr = useCodeArraySync('condo.cms.customerChannel')

  useEffect(() => {
    if (customerId) {
      adminModel.adminCondoCustomerGet({customerId} as CondoCustomerGetDTO).then(({response: {data}}) => {
        const {
          customerName,
          customerGender,
          customerWechat,
          customerMobile,
          customerChannel,
          budgetMin,
          budgetMax,
          remark,
          customerId,
        } = data
        form.setFieldsValue({
          customerName,
          customerChannel,
          customerGender,
          customerWechat,
          customerMobile,
          budgetMin,
          budgetMax,
          remark,
          customerId,
        })
      })
    }
  }, [customerId, form])

  return (
    <Page
      breadcrumb={<Breadcrumb style={{background: '#fff'}} />}
      header={<PageHeader title={customerId ? '编辑客户' : '新增客户'} className={pageStyle.header} />}
    >
      <div className={commonStyles.tableSection}>
        <Form
          form={form}
          labelCol={{span: 3}}
          wrapperCol={{span: 10}}
          onFinish={async () => {
            const formValues = form.getFieldsValue(true)
            if (customerId) {
              await adminModel.adminCondoCustomerUpdate(formValues)
            } else {
              await adminModel.adminCondoCustomerInsert(formValues)
            }
            history.goBack()
          }}
        >
          <Form.Item label={'姓名'} name={'customerName'} rules={[{required: true, message: '请输入姓名'}]}>
            <Input placeholder='请输入' allowClear />
          </Form.Item>
          <Form.Item label={'性别'} name={'customerGender'} rules={[{required: true, message: '请选择性别'}]}>
            <Radio.Group>
              <Radio value={'1'}>男</Radio>
              <Radio value={'2'}>女</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            label={'手机号'}
            name={'customerMobile'}
            rules={[{pattern: /^1[0-9]{10}$/, message: '请输入正确手机号码'}]}
          >
            <Input placeholder='请输入' allowClear maxLength={11} />
          </Form.Item>
          <Form.Item label={'微信号'} name={'customerWechat'}>
            <Input placeholder='请输入' allowClear />
          </Form.Item>
          <Form.Item label={'获客渠道'} name={'customerChannel'} rules={[{required: true, message: '请选择获客渠道'}]}>
            <Select placeholder={'请选择'} allowClear>
              {customerChannelArr
                .filter(item => ['5', '6', '7', '8', '9', '10', '13', '14', '99'].includes(item.id))
                .map(item => (
                  <Select.Option key={item.id} value={item.id}>
                    {item.label}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item label={'预算金额'} required>
            <Space align={'start'}>
              <Form.Item
                style={{marginBottom: 0}}
                name={'budgetMin'}
                rules={[
                  ({getFieldValue}) => ({
                    validator(_, value, callback) {
                      const budgetMax = getFieldValue('budgetMax')
                      if (value && budgetMax && value >= budgetMax) {
                        return callback('最低金额应小于最高金额')
                      }
                      if (!value) return callback('请输入最低预算金额')
                      return callback()
                    },
                  }),
                ]}
              >
                <InputNumber style={{width: 150}} placeholder='最低' />
              </Form.Item>
              <SwapRightOutlined style={{color: 'rgba(0, 0, 0, 0.25)'}} />
              <Form.Item
                style={{marginBottom: 0}}
                name={'budgetMax'}
                rules={[
                  ({getFieldValue}) => ({
                    validator(_, value, callback) {
                      const budgetMin = getFieldValue('budgetMin')
                      if (value && budgetMin && value < budgetMin) {
                        return callback('最高金额应大于最低金额')
                      }
                      if (!value) return callback('请输入最高预算金额')
                      return callback()
                    },
                  }),
                ]}
              >
                <InputNumber style={{width: 150}} placeholder='最高' />
              </Form.Item>
            </Space>
          </Form.Item>
          <Form.Item label={'备注'} name={'remark'}>
            <Input.TextArea rows={3} maxLength={200} showCount />
          </Form.Item>
          <Form.Item label={' '} colon={false}>
            <Space>
              <Button type={'primary'} htmlType={'submit'}>
                提交
              </Button>
              <Button
                onClick={() => {
                  history.goBack()
                }}
              >
                取消
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </div>
    </Page>
  )
}
