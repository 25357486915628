import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {
  Button,
  Col,
  Divider,
  Dropdown,
  Form,
  Input,
  Menu,
  message,
  Modal,
  Row,
  Select,
  Space,
  Table,
  Tag,
  Tooltip,
  Tree,
  Typography,
} from 'antd'
import {MoreOutlined, TeamOutlined, UserOutlined} from '@ant-design/icons'
import './index.less'
import type {TreeProps} from 'antd/es/tree'
import {sysModel} from '@/store/models/Sys'
import {sysUserModel} from '@/store/models/SysUser'
import {Model} from '@redux-model/web'
import {debounce} from '@/utils/util'
import commonStyles from '@/assets/styles/common.module.less'
import {usePermission} from '@/components/Permission'
import {sysRoleModel} from '@/store/models/SysRole'
import {userModel} from '@/store/models/User'
import {Page} from '@/components/Page'

type TreeData = TreeProps['treeData'][number] & SysOrgTreeNodeVO

interface TreeForm {
  type: 'insert' | 'update' // 添加子组织 | 修改名称
  orgId: string
  orgName: string
  orgRoleId: string
}

const initAddForm = {
  query: '',
  tree: [] as SysOrgTreeNodeVO[],
  selected: [] as SysOrgTreeNodeVO[],
  queryList: [] as SysUserVO[],
}

type AddForm = typeof initAddForm

const OrgManage: React.FC = () => {
  // 添加子组织，修改名称 form
  const [form] = Form.useForm()
  const [auth] = usePermission()
  const [formVisible, setFormVisible] = useState(false)
  const [addVisible, setAddVisible] = useState(false)
  const formLoading = Model.isLoading(sysModel.sysOrgInsert.useLoading(), sysModel.sysOrgUpdateName.useLoading())
  const [addForm, setAddForm] = useState(initAddForm)
  const [roleList, setRoleList] = useState<SysRoleVO[]>([])
  const deleteLoading = sysModel.sysOrgRemoveMember.useLoading()

  const orgId = userModel.useData(data => data.userInfo?.orgId)

  const [showSearch, setShowSearch] = useState(true)
  const [searchValue, setSearchValue] = useState('')

  // table数组
  const [tableData, setTableData] = useState({
    org: {} as Pick<SysOrgTreeNodeVO, 'key' | 'label'>,
    data: [] as SysUserVO[],
    selected: [] as SysUserVO[],
  })

  useEffect(() => {
    sysRoleModel.sysRoleList({pageable: false} as SysListDTO).then(({response: {data}}) => setRoleList(data))
  }, [])

  const initPage = useCallback(() => {
    sysModel.sysOrgTree({fetchUser: false, orgIdLike: orgId} as SysOrgTreeQueryDTO).then(({response: {data}}) => {
      setTree(data)
    })
  }, [orgId])

  const renderNode = useCallback(
    (data: TreeData) => {
      // 【1001664】集中式公寓及以下写死最多四层，第四层组织更多操作里没有添加子组织这个操作，集中式公寓：0006
      const hideInsertGroup = data.key.startsWith('0006') && data.key.length > 8

      return (
        <>
          <span>{data.label}</span>
          <Dropdown
            overlay={
              <Menu onClick={param => param.domEvent.stopPropagation()}>
                {!hideInsertGroup && auth('003032002') && (
                  <Menu.Item
                    onClick={() => {
                      form.setFieldsValue({
                        type: 'insert',
                        orgId: data.key,
                      } as TreeForm)
                      setFormVisible(true)
                    }}
                  >
                    添加子组织
                  </Menu.Item>
                )}
                {auth('002032002') && (
                  <Menu.Item
                    onClick={() => {
                      form.setFieldsValue({
                        type: 'update',
                        orgName: data.label,
                        orgId: data.key,
                        orgRoleId: data.orgRoleId || '',
                      } as TreeForm)
                      setFormVisible(true)
                    }}
                  >
                    修改信息
                  </Menu.Item>
                )}
                {auth('004032002') && (
                  <Menu.Item
                    onClick={() => {
                      Modal.confirm({
                        title: '提示',
                        icon: null,
                        content: '是否确认删除？',
                        okText: '确认',
                        cancelText: '取消',
                        onOk: async () => {
                          await sysModel.sysOrgDelete({orgId: data.key})
                          initPage()
                        },
                      })
                    }}
                  >
                    删除
                  </Menu.Item>
                )}
              </Menu>
            }
            placement='bottomLeft'
          >
            <span onClick={event => event.stopPropagation()}>
              <MoreOutlined className='moreIcon' />
            </span>
          </Dropdown>
        </>
      ) as React.ReactNode
    },
    [auth, form, initPage]
  )

  // 页面树
  const [tree, setTree] = useState<SysOrgTreeNodeVO[]>([])
  const renderTree = useMemo(() => {
    return (function mapTree(t: SysOrgTreeNodeVO[]): TreeData[] {
      return t?.map(item => {
        return {
          ...item,
          title: renderNode(item),
          children: mapTree(item.children),
          icon: () => <TeamOutlined />,
        } as TreeData
      })
    })(tree)
  }, [renderNode, tree])

  const search = useCallback(
    debounce((params: Partial<SysListDTO>) => {
      sysUserModel.sysUserList(params).then(({response: {data}}) => {
        setAddForm(prevState => ({
          ...prevState,
          queryList: data,
        }))
      })
    }),
    []
  )

  // 弹窗树
  const renderAllTree = useMemo(() => {
    const selectKeys = addForm.selected.map(item => item.key)
    return addForm.query
      ? // 查询树
        (function mapTree(t: SysUserVO[]): TreeData[] {
          return t?.map(item => {
            const isActive = selectKeys.includes(item.userId)
            return ({
              key: item.userId,
              label: item.name,
              type: 'user',
              title: (
                <>
                  <span>{item.name}</span>
                  <span>{item.orgName}</span>
                </>
              ),
              isLeaf: true,
              className: isActive ? 'treeActive' : '',
              icon: () => null,
            } as unknown) as TreeData
          })
        })(addForm.queryList)
      : (function mapTree(t: SysOrgTreeNodeVO[]): TreeData[] {
          return t?.map(item => {
            const isActive = selectKeys.includes(item.key)
            const isUser = item.type === 'user'

            return {
              ...item,
              title: item.label,
              isLeaf: isUser,
              children: mapTree(item.children),
              className: isActive ? 'treeActive' : '',
              icon: () => (isUser ? <UserOutlined /> : <TeamOutlined />),
            } as TreeData
          })
        })(addForm.tree)
  }, [addForm.query, addForm.queryList, addForm.selected, addForm.tree])

  useEffect(initPage, [initPage])

  async function fetchUser(org: Pick<SysOrgTreeNodeVO, 'key' | 'label'>) {
    sysUserModel.querySysUserList({orgId: org.key, status: '1'}).then(({response: {data}}) => {
      // 查询用户列表清除已选数据
      setTableData({org, data, selected: []})
    })
  }

  const searchUser = (name?: string) => {
    sysUserModel.querySysUserList({name, status: '1'}).then(({response: {data}}) => {
      setTableData(prev => ({...prev, data, selected: []}))
    })
  }

  useEffect(() => {
    searchUser()
  }, [])

  return (
    <Page>
      <div className={commonStyles.tableSection}>
        <Row style={{flexWrap: 'nowrap'}}>
          <Col flex={'228px'} className='orgLeft'>
            <Tree.DirectoryTree
              onClick={(e, treeNode) => {
                fetchUser((treeNode as unknown) as TreeData)
                setShowSearch(false)
              }}
              className={'org'}
              defaultExpandAll
              expandAction={false}
              treeData={renderTree}
            />
          </Col>
          <Col flex={'auto'} className='orgRight'>
            <Row align={'middle'} justify={'space-between'} className='orgTitle'>
              <Typography.Title level={3} style={{marginBottom: 0}}>
                {showSearch
                  ? '人员搜索'
                  : `${tableData.org.label || ''}${tableData.data.length ? `（${tableData.data.length}人）` : ''}`}
              </Typography.Title>
              {showSearch ? (
                <Space>
                  <Form.Item label={'人员名称'} style={{marginBottom: 0}}>
                    <Input
                      placeholder='请输入'
                      value={searchValue}
                      onChange={e => setSearchValue(e.target.value)}
                      style={{width: 160}}
                    />
                  </Form.Item>
                  <Button
                    onClick={() => {
                      setSearchValue('')
                      searchUser()
                    }}
                  >
                    重置
                  </Button>
                  <Button
                    type={'primary'}
                    onClick={() => {
                      searchUser(searchValue)
                    }}
                  >
                    查询
                  </Button>
                </Space>
              ) : (
                <Space>
                  <Button
                    type={'primary'}
                    onClick={() => {
                      setShowSearch(true)
                      searchUser()
                    }}
                  >
                    人员搜索
                  </Button>
                  {auth('004033002') && (
                    <Button
                      disabled={!tableData.selected?.length}
                      loading={deleteLoading}
                      onClick={async () => {
                        Modal.confirm({
                          title: '提示',
                          content: `确认删除 ${tableData.selected.length} 人？`,
                          onOk: async () => {
                            await sysModel.sysOrgRemoveMember({
                              orgId: tableData.org.key,
                              userIdList: tableData.selected.map(item => item.userId),
                            } as SysOrgUpdateMemberDTO)
                            message.success('删除成功！')
                            fetchUser(tableData.org)
                          },
                        })
                      }}
                    >
                      删除
                    </Button>
                  )}
                  {auth('003033002') && (
                    <Button
                      disabled={!tableData.org.key}
                      type={'primary'}
                      onClick={() => {
                        setAddVisible(true)
                        setAddForm(
                          prevState =>
                            ({
                              ...prevState,
                              selected: tableData.data?.map(item => ({
                                key: item.userId,
                                label: item.name,
                                type: 'user',
                              })),
                            } as AddForm)
                        )
                        sysModel
                          .sysOrgTree({
                            fetchUser: true,
                            undistributedUser: true,
                            orgIdLike: orgId,
                          } as SysOrgTreeQueryDTO)
                          .then(({response: {data}}) => {
                            setAddForm(prevState => ({...prevState, tree: data}))
                          })
                      }}
                    >
                      添加成员
                    </Button>
                  )}
                </Space>
              )}
            </Row>
            <Divider />

            <Table
              rowKey={'userId'}
              rowSelection={{
                selectedRowKeys: tableData.selected.map(item => item.userId),
                onChange: (selectedRowKeys, selectedRows) => {
                  setTableData(prevState => ({
                    ...prevState,
                    selected: selectedRows,
                  }))
                },
              }}
              columns={[
                {title: 'ID', dataIndex: 'userId'},
                {title: '账号', dataIndex: 'userName'},
                {title: '姓名', dataIndex: 'name'},
                {title: '手机号', dataIndex: 'mobile'},
                {
                  title: '所在组织',
                  dataIndex: 'orgName',
                  render: text => {
                    const arr = text?.split(',') ?? []
                    if (arr.length > 1) {
                      return (
                        <Tooltip title={text}>
                          <span>{arr[0] + '...'}</span>
                        </Tooltip>
                      )
                    }
                    return text ?? '/'
                  },
                },
                {
                  title: '用户组',
                  dataIndex: 'roleNameList',
                  render: text => {
                    const arr = text?.split(',') ?? []
                    if (arr.length > 1) {
                      return (
                        <Tooltip title={text}>
                          <span>{arr[0] + '...'}</span>
                        </Tooltip>
                      )
                    }
                    return text ?? '/'
                  },
                },
                {
                  title: '状态',
                  dataIndex: 'status',
                  render: value => ['无效', '有效'][value],
                },
              ]}
              dataSource={tableData.data}
              pagination={{
                position: ['bottomCenter'],
                showSizeChanger: true,
              }}
            />
          </Col>
        </Row>
      </div>

      <Modal
        visible={addVisible}
        title={'添加组织成员'}
        width={639}
        destroyOnClose
        onOk={async () => {
          await sysModel.sysOrgUpdateMember({
            orgId: tableData.org.key,
            userIdList: addForm.selected.map(item => item.key),
          } as SysOrgUpdateMemberDTO)
          fetchUser(tableData.org)
          setAddVisible(false)
        }}
        confirmLoading={sysModel.sysOrgUpdateMember.useLoading()}
        afterClose={() => setAddForm(initAddForm)}
        onCancel={() => setAddVisible(false)}
      >
        <Row style={{flexWrap: 'nowrap'}}>
          <Col flex={'228px'} className='orgLeft'>
            <div style={{padding: '0 14px 14px 0'}}>
              <Input.Search
                placeholder='搜索成员、组织'
                allowClear
                onChange={event => {
                  const query = event.target.value
                  setAddForm(prevState => ({...prevState, query}))
                  if (query === '') {
                    setAddForm(prevState => ({...prevState, queryList: []}))
                  } else {
                    search({name: query, status: '1'})
                  }
                }}
              />
            </div>
            <Tree.DirectoryTree
              onClick={(e, tNode) => {
                const treeNode = (tNode as unknown) as TreeData

                if (treeNode.type === 'user') {
                  const key = treeNode.key
                  const label = treeNode.label
                  setAddForm(
                    prevState =>
                      ({
                        ...prevState,
                        selected: prevState.selected.some(item => item.key === key)
                          ? prevState.selected.filter(item => item.key !== key)
                          : [...prevState.selected, {key, label, type: 'user'}],
                      } as AddForm)
                  )
                }
              }}
              className={'org orgModal'}
              treeData={renderAllTree}
            />
          </Col>
          <Col flex={'auto'} className='orgRight'>
            <Typography.Text>已选择组织和成员</Typography.Text>
            <div>
              {addForm.selected.map(user => (
                <Tag
                  key={user.key}
                  style={{marginTop: 8}}
                  closable
                  onClose={() => {
                    setAddForm(prevState => ({
                      ...prevState,
                      selected: prevState.selected.filter(item => item.key !== user.key),
                    }))
                  }}
                >
                  {user.label}
                </Tag>
              ))}
            </div>
          </Col>
        </Row>
      </Modal>

      <Modal
        maskClosable={false}
        open={formVisible}
        destroyOnClose
        centered
        title={(form.getFieldValue('type') as TreeForm['type']) === 'insert' ? '添加子组织' : '修改信息'}
        onCancel={() => setFormVisible(false)}
        afterClose={() => form.resetFields()}
        confirmLoading={formLoading}
        onOk={async () => {
          const value: TreeForm = form.getFieldsValue(true)
          if (value.type === 'insert') {
            await sysModel.sysOrgInsert({
              orgPid: value.orgId,
              orgName: value.orgName,
              orgRoleId: value.orgRoleId,
            })
          } else {
            await sysModel.sysOrgUpdateName({
              orgId: value.orgId,
              orgName: value.orgName,
              orgRoleId: value.orgRoleId,
            })
          }
          initPage()
          setFormVisible(false)
        }}
      >
        <Form form={form} labelCol={{flex: '70px'}}>
          <Form.Item name={'orgName'} label={'组织名称'}>
            <Input allowClear placeholder={'请输入'} />
          </Form.Item>
          <Form.Item
            name={'orgRoleId'}
            label={'用户组'}
            getValueFromEvent={event => event?.join(',')}
            getValueProps={value => ({value: value ? value.split(',') : []})}
          >
            <Select
              allowClear
              mode={'multiple'}
              showArrow
              showSearch
              placeholder={'请选择'}
              filterOption={(inputValue, option: any) => option?.children?.indexOf(inputValue) > -1}
            >
              {roleList.map(value => (
                <Select.Option key={value.roleId} value={value.roleId}>
                  {value.roleName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </Page>
  )
}

export default OrgManage
