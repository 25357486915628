import React, {useCallback, useEffect, useRef, useState} from 'react'
import {LabelProps} from '@/components/Map/Label'
import {stewardModel} from '@/store/models/Steward'
import {Page} from '@/components/Page'
import {Button, Col, DatePicker, Form, message, Row, Select, Space} from 'antd'
import {sysModel} from '@/store/models/Sys'
import {OrgRoleId} from '@/common/constants'
import {Bubble} from '@/pages/steward/components/location_widget'
import commonStyles from '@/assets/styles/common.module.less'
import Map from 'react-bmapgl/dist/Map/Map'
import MapApiLoaderHOC from 'react-bmapgl/dist/Map/MapApiLoaderHOC'
import {ak} from '@/common/config'
import CustomOverlay from 'react-bmapgl/dist/Overlay/CustomOverlay'
import moment from 'moment'
import {sysUserModel} from '@/store/models/SysUser'
import Polyline from 'react-bmapgl/dist/Overlay/Polyline'

const iconSize = 76

interface NewStewardLocationVO extends StewardLocationVO {
  id: string
}

function StewardLocation() {
  const mapRef = useRef(null)
  const [locations, setLocations] = useState<StewardLocationVO[]>([])
  const [form] = Form.useForm()

  const [stewardList, setStewardList] = useState<StewardUserVO[]>([])
  const [locationRecord, setLocationRecord] = useState<NewStewardLocationVO[]>([])
  const [polyLineArr, setPolyLineArr] = useState<BMapGL.Point[]>([])

  useEffect(() => {
    sysModel.sysUserLocationList({roleId: OrgRoleId.steward}).then(({response: {data}}) => {
      setLocations(data)
      mapRef.current?.map?.setViewport?.(data.map(item => new BMapGL.Point(item.lng, item.lat)))
    })

    stewardModel.stewardUserList({pageNum: 1, pageSize: 10, pageable: false}).then(({response: {data}}) => {
      setStewardList(data)
    })
  }, [])

  const [detail, setDetail] = useState<
    Pick<StewardOrderQueryVO, 'orderId' | 'stewardId'> & {address: string; point: LabelProps['point']}
  >(null)
  const stewardOrderDetail = useCallback(async (stewardId: string, point: LabelProps['point']) => {
    const myGeo = new BMapGL.Geocoder()
    const location = new BMapGL.Point(point.lng, point.lat)
    const result: {address: string} = await new Promise(resolve => myGeo.getLocation(location, resolve))

    const baseParams = {pageable: true, pageSize: 1, pageNum: 1, stewardId} as StewardOrderQueryDTO
    const [{response: response1}, {response: response2}] = await Promise.all([
      stewardModel.stewardAdminOrderList({...baseParams, orderStatus: '3'}),
      stewardModel.stewardAdminOrderList({...baseParams, orderStatus: '4'}),
    ])
    const firstOrder = [...response1.data, ...response2.data][0]
    return {stewardId, point, orderId: firstOrder?.orderId, address: result?.address}
  }, [])

  const [recordDetail, setRecordDetail] = useState({} as StewardLocationVO & {address: string})

  return (
    <Page style={{height: 'calc(100vh - 102px)', display: 'flex', flexDirection: 'column'}}>
      <div className={commonStyles.filterSection}>
        <Form
          form={form}
          onFinish={async () => {
            setDetail(null)
            setRecordDetail(null)
            const values = form.getFieldsValue(true)
            const {
              response: {data},
            } = await sysUserModel.sysUserLocationListRecord(values)
            if (!data.length) return message.warning('当前管家该日期暂无轨迹！')
            const arr: NewStewardLocationVO[] = []
            data.forEach((item, index) => {
              const findItem = arr.find(_item => _item.lng === item.lng && _item.lat === item.lat)
              if (findItem) {
                findItem.id = `${findItem.id},${index + 1}`
              } else {
                arr.push({...item, id: `${index + 1}`})
              }
            })

            setPolyLineArr(data.map(item => new BMapGL.Point(item.lng, item.lat)))
            setLocationRecord(arr)
            mapRef.current?.map?.setViewport(data.map(item => new BMapGL.Point(item.lng, item.lat)))
          }}
          onReset={() => {
            setLocationRecord([])
            setRecordDetail(null)
            mapRef.current?.map?.setViewport?.(locations.map(item => new BMapGL.Point(item.lng, item.lat)))
          }}
        >
          <Row gutter={[50, 0]}>
            <Col span={8}>
              <Form.Item
                label={'管家名称'}
                name={'stewardId'}
                rules={[{required: true, message: '请选择查询管家名称'}]}
              >
                <Select
                  placeholder={'请输入管家名称搜索'}
                  showSearch
                  filterOption={(inputValue, option) => {
                    return option.children?.indexOf(inputValue as any) > -1
                  }}
                >
                  {stewardList.map(item => (
                    <Select.Option key={item.userId} value={item.userId}>
                      {`${item.name}(${item.mobile})`}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label={'日期'}
                name={'reportDate'}
                rules={[{required: true, message: '请选择查询日期'}]}
                getValueFromEvent={(_, dateString) => dateString}
                getValueProps={value => ({value: value ? moment(value) : value})}
              >
                <DatePicker />
              </Form.Item>
            </Col>
          </Row>
          <Row justify={'end'}>
            <Space>
              <Button type={'primary'} htmlType={'submit'}>
                查询
              </Button>
              <Button htmlType={'reset'}>重置</Button>
            </Space>
          </Row>
        </Form>
      </div>
      <div style={{flex: 1, position: 'relative'}}>
        <Map ref={mapRef} center={'上海市'} style={{height: '100%'}} zoom={13} enableScrollWheelZoom>
          {/* 管家定位 */}
          {!!detail && !locationRecord.length && (
            <CustomOverlay
              offset={new BMapGL.Size(0, -120)}
              position={new BMapGL.Point(detail.point.lng, detail.point.lat)}
              map={null}
            >
              <div>
                <Bubble>
                  <div style={{padding: '5px 8px'}}>位置：{detail.address}</div>
                  {!!detail.orderId && (
                    <div style={{padding: '5px 8px'}}>
                      服务单：
                      <a
                        style={{textDecoration: 'underline', color: '#262626'}}
                        href={`/steward/detail?orderId=${detail.orderId}`}
                      >
                        {detail.orderId}
                      </a>
                    </div>
                  )}
                  <div style={{padding: '5px 8px'}}>
                    上报时间：{locations.find(value => value.stewardId === detail.stewardId)?.reportTime}
                  </div>
                  <div style={{padding: '5px 8px'}}>
                    登录设备：{locations.find(value => value.stewardId === detail.stewardId)?.device}
                  </div>
                </Bubble>
              </div>
            </CustomOverlay>
          )}

          {!locationRecord.length &&
            locations.map(value => {
              const active = value.stewardId === detail?.stewardId
              return (
                <React.Fragment key={value.stewardId}>
                  <CustomOverlay
                    position={new BMapGL.Point(value.lng, value.lat)}
                    map={null}
                    zIndex={active ? 2 : undefined}
                  >
                    <div
                      onClick={async () => {
                        if (active) {
                          setDetail(null)
                        } else {
                          const data = await stewardOrderDetail(value.stewardId, {lat: value.lat, lng: value.lng})
                          setDetail(data)
                        }
                      }}
                    >
                      <Bubble
                        style={{
                          borderRadius: 100,
                          minWidth: 80,
                          textAlign: 'center',
                          padding: '5px',
                          ...(active
                            ? {backgroundColor: '#00B7AE', color: 'white'}
                            : {backgroundColor: 'white', color: '#262626'}),
                        }}
                      >
                        <span>{value.stewardName}</span>
                      </Bubble>
                      <img src={require('@/assets/steward.png')} style={{width: iconSize, height: iconSize}} alt={''} />
                    </div>
                  </CustomOverlay>
                </React.Fragment>
              )
            })}

          {/* 管家个人轨迹 */}
          {locationRecord.length &&
            locationRecord.map((item, index) => {
              const active = recordDetail?.lng === item.lng && recordDetail.lat === item.lat
              return (
                <CustomOverlay
                  offset={new BMapGL.Size(0, 14)}
                  key={index}
                  position={new BMapGL.Point(item.lng, item.lat)}
                  map={null}
                >
                  <div
                    onClick={async () => {
                      if (active) {
                        setRecordDetail(null)
                        return
                      }
                      const geo = new BMapGL.Geocoder()
                      const {address}: {address: string} = await new Promise(resolve =>
                        geo.getLocation(new BMapGL.Point(item.lng, item.lat), resolve)
                      )
                      setRecordDetail({...item, address})
                    }}
                    key={index}
                    style={{
                      width: 28,
                      height: 28,
                      borderRadius: 14,
                      border: active ? 'none' : '1px solid #D9D9D9',
                      backgroundColor: active ? '#00B7AE' : '#f7f7f7',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      color: active ? '#fff' : '#000000',
                    }}
                  >
                    <span style={{fontSize: 12}}>{item.id}</span>
                  </div>
                </CustomOverlay>
              )
            })}

          {locationRecord.length > 1 && (
            <Polyline path={polyLineArr} strokeColor={'#FF4242'} strokeWeight={2} map={null} />
          )}

          {!!recordDetail?.address && (
            <CustomOverlay
              offset={new BMapGL.Size(0, -30)}
              position={new BMapGL.Point(recordDetail.lng, recordDetail.lat)}
              map={null}
            >
              <div>
                <Bubble style={{padding: '15px 20px', transform: 'translate(50%, 0)', borderRadius: 5}} arrowPlace={40}>
                  <Space direction={'vertical'} style={{color: 'rgba(0, 0, 0, 0.65)'}}>
                    <span>位置：{recordDetail.address}</span>
                    <span>上报时间：{recordDetail.reportTime}</span>
                    <span>登录设备：{recordDetail.device}</span>
                  </Space>
                </Bubble>
              </div>
            </CustomOverlay>
          )}
        </Map>
      </div>
    </Page>
  )
}

export default MapApiLoaderHOC({ak})(StewardLocation)
Bubble.defaultProps = {
  arrowPlace: 'center',
}
