import React, {useCallback, useEffect, useState} from 'react'
import commonStyles from '@/assets/styles/common.module.less'
import {Breadcrumb} from '@/components/Breadcrumb'
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  Pagination,
  Row,
  Select,
  Space,
  Table,
  Tabs,
  TreeSelect,
  Tooltip,
} from 'antd'
import styles from './index.module.less'
import {Page} from '@/components/Page'
import {saleModel} from '@/store/models/Sale'
import {useCodeArraySync, useCodeSync} from '@/store/models/Common'
import {useAppHistory} from '@/common/hooks/useRouter'
import {sysModel} from '@/store/models/Sys'
import {userModel} from '@/store/models/User'
import {downloadBlob} from '@/utils/util'
import {Permission, usePermission} from '@/components/Permission'
import {OrgRoleId} from '@/common/constants'
import {useFocusEffect} from '@/common/hooks/useFocusEffect'

export default function FollowList() {
  const userInfo = userModel.useData(data => data.userInfo)
  const history = useAppHistory()
  const [auth] = usePermission()
  const [myList, setMyList] = useState<SaleCustomerFollowVO[]>([])
  const [myTotal, setMyTotal] = useState(0)
  const [teamList, setTeamList] = useState<SaleCustomerFollowVO[]>([])
  const [teamTotal, setTeamTotal] = useState(0)
  const [myForm] = Form.useForm()
  const [teamForm] = Form.useForm()
  const visitStatusArr = useCodeArraySync('sale.customer.visit.status')
  const visitStatus = useCodeSync('sale.customer.visit.status')
  const [teamTree, setTeamTree] = useState<SysOrgTreeNodeVO[]>([])

  const [tabKey, setTabKey] = useState(() => {
    const permissionMap = {
      '001083001': '1',
      '001084001': '2',
    }
    const permission = Object.keys(permissionMap).find(value => auth(value))
    return permissionMap[permission]
  })

  //查询我的跟进
  const mySearch = useCallback(async () => {
    const value = myForm.getFieldsValue(true)
    const {
      response: {data, total},
    } = await saleModel.saleCustomerFollowList({...value, userId: userInfo.userId})
    setMyTotal(total)
    setMyList(data)
  }, [myForm, userInfo.userId])

  //查询团队跟进
  const teamSearch = useCallback(async () => {
    const {orgId, userId, ...value} = teamForm.getFieldsValue(true)
    //选择了团队使用orgId，选择了销售使用userId、orgId为空，重置orgId使用userInfo.orgId、userId为空
    const {
      response: {data, total},
    } = await saleModel.saleCustomerFollowList({...value, orgId: orgId ? orgId : userId ? '' : userInfo.orgId, userId})
    setTeamTotal(total)
    setTeamList(data)
  }, [teamForm, userInfo.orgId])

  useEffect(() => {
    sysModel
      .sysOrgTree({fetchUser: true, orgIdLike: userInfo.orgId, orgRoleId: OrgRoleId.cus_manager} as SysOrgTreeQueryDTO)
      .then(({response: {data}}) => {
        setTeamTree(data)
      })
  }, [userInfo.orgId])

  //团队选择树
  const renderArr = (data: SysOrgTreeNodeVO[]) => {
    const arr = data.map(item => {
      if (item.children) {
        const newChildren = renderArr(item.children)
        return {
          key: item.key,
          title: item.label,
          value: item.key,
          children: newChildren,
          type: item.type,
        }
      } else {
        return {
          key: item.key,
          title: item.label,
          value: item.key,
          children: item.children,
          type: item.type,
        }
      }
    })

    return arr
  }

  useFocusEffect(() => {
    switch (tabKey) {
      case '1':
        return myForm.submit()
      case '2':
        return teamForm.submit()
    }
  })

  useEffect(() => {
    mySearch()
    teamSearch()
  }, [mySearch, teamSearch])

  return (
    <Page
      breadcrumb={
        <Breadcrumb
          right={
            tabKey === '2' && (
              <Permission code={'007084001'}>
                <Button
                  onClick={() => {
                    const value = teamForm.getFieldsValue(true)
                    downloadBlob({url: '/sale/customer/follow/list-excel', body: value})
                  }}
                >
                  导出
                </Button>
              </Permission>
            )
          }
        />
      }
    >
      <Tabs className={styles.tabs} activeKey={tabKey} onChange={key => setTabKey(key)}>
        <Tabs.TabPane tab={'我的'} disabled={!auth('001083001')} key={'1'} forceRender>
          <Form form={myForm} onFinish={mySearch} onReset={mySearch} initialValues={{pageNum: 1, pageSize: 10}}>
            <div className={commonStyles.filterSection}>
              <Row>
                <Col span={6}>
                  <Form.Item label={'客户名称'} name={'customerName'}>
                    <Input placeholder='请输入客户名称' allowClear />
                  </Form.Item>
                </Col>
                <Col offset={1} span={6}>
                  <Form.Item label={'邀约状态'} name={'visitStatus'}>
                    <Select placeholder={'请选择'} allowClear>
                      {visitStatusArr.map(item => (
                        <Select.Option value={item.id} key={item.id}>
                          {item.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col offset={1}>
                  <Form.Item label={'跟进时间'} name={'_time'}>
                    <DatePicker.RangePicker
                      showTime
                      onChange={(_, formatString) => {
                        myForm.setFieldsValue({
                          followDateBegin: formatString?.[0],
                          followDateEnd: formatString?.[1],
                        })
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row justify={'end'}>
                <Space>
                  <Button type={'primary'} htmlType={'submit'} onClick={() => myForm.setFieldsValue({pageNum: 1})}>
                    查询
                  </Button>
                  <Button htmlType={'reset'}>重置</Button>
                </Space>
              </Row>
            </div>
            <div className={commonStyles.filterSection}>
              <Table
                style={{marginBottom: 10}}
                dataSource={myList}
                rowKey={'followId'}
                columns={[
                  {title: 'ID', key: 'followId', dataIndex: 'followId'},
                  {title: '姓名', key: 'name', dataIndex: 'name'},
                  {title: '手机号', key: 'mobile', dataIndex: 'mobile'},
                  {
                    title: '邀约状态',
                    key: 'visitStatus',
                    dataIndex: 'visitStatus',
                    render: text => visitStatus[text] ?? '/',
                  },
                  {title: '跟进时间', key: 'followDate', dataIndex: 'followDate'},
                  {
                    title: '跟进详情',
                    key: 'remark',
                    dataIndex: 'remark',
                    render: text => (
                      <Tooltip title={text}>{`${
                        text ? (text?.length < 15 ? text : `${text.slice(0, 15)}...`) : '/'
                      }`}</Tooltip>
                    ),
                  },
                  {
                    title: '操作',
                    render: record => (
                      <Button
                        type='link'
                        onClick={() => {
                          history.push({
                            pathname: '/sale-client/detail',
                            state: {customerId: record.customerId, disable: true},
                          })
                        }}
                      >
                        客户信息
                      </Button>
                    ),
                  },
                ]}
                pagination={false}
              />

              <Form.Item
                noStyle
                shouldUpdate={(prev, next) => prev.pageSize !== next.pageSize || prev.pageNum !== next.pageNum}
              >
                {() => (
                  <Pagination
                    total={myTotal}
                    current={myForm.getFieldValue('pageNum')}
                    pageSize={myForm.getFieldValue('pageSize')}
                    style={{textAlign: 'center'}}
                    showSizeChanger
                    onChange={(pageNum, pageSize) => {
                      myForm.setFieldsValue({pageNum, pageSize})
                      mySearch()
                    }}
                  />
                )}
              </Form.Item>
            </div>
          </Form>
        </Tabs.TabPane>
        <Tabs.TabPane tab={'团队'} disabled={!auth('001084001')} key={'2'} forceRender>
          <Form form={teamForm} onFinish={teamSearch} onReset={teamSearch} initialValues={{pageNum: 1, pageSize: 10}}>
            <div className={commonStyles.filterSection}>
              <Row>
                <Col span={6}>
                  <Form.Item label={'客户名称'} name={'customerName'}>
                    <Input placeholder='请输入客户名称' allowClear />
                  </Form.Item>
                </Col>
                <Col offset={1} span={6}>
                  <Form.Item label={'邀约状态'} name={'visitStatus'}>
                    <Select placeholder={'请选择'} allowClear>
                      {visitStatusArr.map(item => (
                        <Select.Option value={item.id} key={item.id}>
                          {item.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col offset={1} span={6}>
                  <Form.Item label={'跟进时间'} name={'_time'}>
                    <DatePicker.RangePicker
                      showTime
                      onChange={(_, formatString) => {
                        teamForm.setFieldsValue({
                          followDateBegin: formatString?.[0],
                          followDateEnd: formatString?.[1],
                        })
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col span={15}>
                  <Form.Item label={'团队选择'} name={'org'}>
                    <TreeSelect
                      showSearch
                      style={{width: 300}}
                      dropdownStyle={{maxHeight: 400, overflow: 'auto'}}
                      placeholder='请选择'
                      allowClear
                      treeDefaultExpandAll
                      onSelect={(value, node) => {
                        if (node.type === 'org') {
                          teamForm.setFieldsValue({orgId: value, userId: ''})
                        } else {
                          teamForm.setFieldsValue({userId: value, orgId: ''})
                        }
                      }}
                      treeData={renderArr(teamTree)}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row justify={'end'}>
                <Space>
                  <Button type={'primary'} htmlType={'submit'} onClick={() => teamForm.setFieldsValue({pageNum: 1})}>
                    查询
                  </Button>
                  <Button htmlType={'reset'}>重置</Button>
                </Space>
              </Row>
            </div>
            <div className={commonStyles.filterSection}>
              <Table
                style={{marginBottom: 10}}
                dataSource={teamList}
                rowKey={'followId'}
                columns={[
                  {title: 'ID', key: 'followId', dataIndex: 'followId'},
                  {title: '姓名', key: 'name', dataIndex: 'name'},
                  {title: '手机号', key: 'mobile', dataIndex: 'mobile'},
                  {title: '跟进销售', key: 'holderName', dataIndex: 'holderName'},
                  {
                    title: '邀约状态',
                    key: 'visitStatus',
                    dataIndex: 'visitStatus',
                    render: text => visitStatus[text] || '/',
                  },
                  {title: '跟进时间', key: 'followDate', dataIndex: 'followDate'},
                  {
                    title: '跟进详情',
                    key: 'remark',
                    dataIndex: 'remark',
                    render: text => (
                      <Tooltip title={text}>{`${
                        text ? (text?.length < 15 ? text : `${text.slice(0, 15)}...`) : '/'
                      }`}</Tooltip>
                    ),
                  },
                  {
                    title: '操作',
                    render: record => (
                      <Button
                        type='link'
                        onClick={() => {
                          history.push({
                            pathname: '/sale-client/detail',
                            state: {customerId: record.customerId, disable: true},
                          })
                        }}
                      >
                        客户信息
                      </Button>
                    ),
                  },
                ]}
                pagination={false}
              />

              <Form.Item
                noStyle
                shouldUpdate={(prev, next) => prev.pageSize !== next.pageSize || prev.pageNum !== next.pageNum}
              >
                {() => (
                  <Pagination
                    total={teamTotal}
                    current={teamForm.getFieldValue('pageNum')}
                    pageSize={teamForm.getFieldValue('pageSize')}
                    style={{textAlign: 'center'}}
                    showSizeChanger
                    onChange={(pageNum, pageSize) => {
                      teamForm.setFieldsValue({pageNum, pageSize})
                      teamSearch()
                    }}
                  />
                )}
              </Form.Item>
            </div>
          </Form>
        </Tabs.TabPane>
      </Tabs>
    </Page>
  )
}
