import React, {useCallback, useEffect, useState} from 'react'
import {Button, Col, DatePicker, Descriptions, Drawer, Form, Input, Row, Select, Space, Table, Typography} from 'antd'
import {getPaginationProps} from '@/common/biz/getPaginationProps'
import DividerLine from '@/pages/property/component/DividerLine'
import commonStyles from '@/assets/styles/common.module.less'
import {stewardModel} from '@/store/models/Steward'
import {useCodeArraySync, useCodeSync} from '@/store/models/Common'
import moment from 'moment'
import {useAppHistory} from '@/common/hooks/useRouter'

export default function ChargeApprovalList() {
  const history = useAppHistory()
  const [form] = Form.useForm()
  const [list, setList] = useState<StewardOrderChargeVO[]>([])
  const [total, setTotal] = useState(0)
  const orderAuditStatus = useCodeSync('steward.orderAuditStatus')
  const orderAuditStatusArray = useCodeArraySync('steward.orderAuditStatus')
  const stewardFeeType = useCodeSync('steward.fee.type')
  const payWay = useCodeSync('steward.orderAuditChargeWay')
  const cancelType = useCodeSync('steward.orderAuditCancelType')

  const [open, setOpen] = useState<boolean>(false)
  const [detail, setDetail] = useState({} as StewardOrderChargeVO)
  const [serviceDetail, setServiceDetail] = useState({} as StewardOrderQueryVO)

  const search = useCallback(() => {
    const values = form.getFieldsValue(true)
    stewardModel.stewardOrderChargeList(values).then(({response: {data, total}}) => {
      setList(data)
      setTotal(total)
    })
  }, [form])

  useEffect(() => {
    search()
  }, [search])

  return (
    <>
      <div className={commonStyles.filterSection} style={{marginTop: 20}}>
        <Form form={form} onFinish={search} onReset={search} initialValues={{pageNum: 1, pageSize: 10, pageable: true}}>
          <Row>
            <Col span={7}>
              <Form.Item label={'订单编号'} name={'orderId'}>
                <Input placeholder={'请输入'} allowClear />
              </Form.Item>
            </Col>
            <Col span={7} offset={1}>
              <Form.Item label={'房东'} name={'customerName'}>
                <Input placeholder={'请输入'} allowClear />
              </Form.Item>
            </Col>
            <Col span={7} offset={1}>
              <Form.Item label={'处理管家'} name={'serverName'}>
                <Input placeholder={'请输入'} allowClear />
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item
                label={'完成日期'}
                getValueFromEvent={(_, formatString) => {
                  form.setFieldValue('timeFinishedEnd', formatString?.[1] && formatString?.[1] + ' 23:59:59')
                  return formatString?.[0] && formatString?.[0] + ' 00:00:00'
                }}
                getValueProps={value => ({
                  value: value ? [moment(value), moment(form.getFieldValue('timeFinishedEnd'))] : value,
                })}
                name={'timeFinishedBegin'}
              >
                <DatePicker.RangePicker />
              </Form.Item>
            </Col>
            <Col span={7} offset={1}>
              <Form.Item label={'服务地址'} name={'propertyAddr'}>
                <Input placeholder={'请输入'} allowClear />
              </Form.Item>
            </Col>
            <Col span={7} offset={1}>
              <Form.Item label={'审核状态'} name={'auditStatusList'}>
                <Select
                  placeholder={'请选择'}
                  options={orderAuditStatusArray}
                  mode={'multiple'}
                  fieldNames={{value: 'id'}}
                  allowClear
                />
              </Form.Item>
            </Col>
          </Row>
          <Row justify={'end'}>
            <Space>
              <Button htmlType={'submit'} type={'primary'} onClick={() => form.setFieldsValue({pageNum: 1})}>
                查询
              </Button>
              <Button htmlType={'reset'}>重置</Button>
            </Space>
          </Row>
        </Form>
      </div>
      <div className={commonStyles.tableSection}>
        <Table
          rowKey={'orderId'}
          columns={[
            {
              title: '订单编号',
              dataIndex: 'orderId',
              render: value => (
                <Button
                  type={'text'}
                  onClick={() => history.push({pathname: '/steward/detail', state: {orderId: value}})}
                >
                  <Typography.Text underline>{value}</Typography.Text>
                </Button>
              ),
            },
            {title: '服务项目', dataIndex: 'categoryThiName'},
            {title: '完成时间', dataIndex: 'timeFinished'},
            {title: '服务地址', dataIndex: 'propertyAddr'},
            {
              title: '处理管家',
              dataIndex: 'serverName',
              render: (value, record) => (
                <Space direction={'vertical'} size={5}>
                  <span>{value}</span>
                  <span>{record.serverMobile}</span>
                </Space>
              ),
            },
            {
              title: '房东',
              dataIndex: 'customerName',
              render: (value, record) => (
                <Space direction={'vertical'} size={5}>
                  <span>{value}</span>
                  <span>{record.customerMobile}</span>
                </Space>
              ),
            },
            {title: '审核状态', dataIndex: 'auditStatus', render: value => orderAuditStatus[value]},
            {
              title: '查看',
              dataIndex: 'orderId',
              render: value => (
                <Button
                  type={'link'}
                  onClick={async () => {
                    const res = await Promise.all([
                      stewardModel.stewardAppOrderChargeDetail({orderId: value}),
                      stewardModel.stewardOrderDetail({orderId: value}),
                    ])
                    const resList = res.map(item => item.response.data)
                    const chargeDetail = resList[0] as StewardOrderChargeVO
                    const orderDetail = resList[1] as StewardOrderQueryVO
                    setDetail(chargeDetail)
                    setServiceDetail(orderDetail)
                    setOpen(true)
                  }}
                >
                  查看
                </Button>
              ),
            },
          ]}
          dataSource={list}
          pagination={getPaginationProps(form, total)}
        />
      </div>

      <Drawer title={'审核详情'} open={open} width={900} destroyOnClose onClose={() => setOpen(false)}>
        <DividerLine title={'基本信息'} bottom={15} />
        <Descriptions column={2}>
          <Descriptions.Item label={'审核状态'}>{orderAuditStatus[detail.auditStatus]}</Descriptions.Item>
          <Descriptions.Item label={'管家服务'}>{stewardFeeType[serviceDetail.stewardFeeType]}</Descriptions.Item>
          <Descriptions.Item label={'总费用（元）'}>{`${
            detail.auditStatus === '1' ? detail.feeStaff : detail.feeStaffFinal
          }`}</Descriptions.Item>
          <Descriptions.Item label={'收费项目'}>
            {serviceDetail.payItemList?.length ? (
              <Space direction={'vertical'}>
                {serviceDetail.payItemList.map(item => (
                  <span
                    key={item.chargeId}
                  >{`${item.chargeName}${item.itemNum}${item.chargeUnit}${item.itemAmtTotal}元`}</span>
                ))}
              </Space>
            ) : (
              '/'
            )}
          </Descriptions.Item>
        </Descriptions>
        {['2', '3'].includes(detail.auditStatus) && (
          <>
            <DividerLine title={'初审信息'} bottom={15} />
            <Descriptions column={2}>
              <Descriptions.Item label={'确认费用（元）'}>{`${detail.feeStaffFinal ?? '/'}`}</Descriptions.Item>
              <Descriptions.Item label={'收款方式'}>{payWay[detail.chargePayWay] ?? '/'}</Descriptions.Item>
              {detail.chargePayWay === '1' ? (
                <>
                  <Descriptions.Item label={'费用类型'}>{detail.chargePay?.recordCategoryName}</Descriptions.Item>
                  <Descriptions.Item label={'备注'}>{detail.remark ?? '/'}</Descriptions.Item>
                </>
              ) : (
                <>
                  <Descriptions.Item label={'账单名称'}>{detail.chargePay?.billName}</Descriptions.Item>
                  <Descriptions.Item label={'应收款日'}>{detail.chargePay?.billDate}</Descriptions.Item>
                </>
              )}
              <Descriptions.Item label={'操作人'}>{detail.auditorName}</Descriptions.Item>
              <Descriptions.Item label={'操作时间'}>{detail.auditTime}</Descriptions.Item>
            </Descriptions>
          </>
        )}
        {detail.auditStatus === '3' && (
          <>
            <DividerLine title={'终审信息'} bottom={15} />
            <Descriptions column={2}>
              <Descriptions.Item label={'默认提成（元）'}>{detail.feeCommission}</Descriptions.Item>
              <Descriptions.Item label={'确认提成（元）'}>{detail.feeCommissionFinal}</Descriptions.Item>
              <Descriptions.Item label={'操作人'}>{detail.auditorNameFinal}</Descriptions.Item>
              <Descriptions.Item label={'操作时间'}>{detail.auditTimeFinal}</Descriptions.Item>
            </Descriptions>
          </>
        )}
        {detail.auditStatus === '4' && (
          <>
            <DividerLine title={'作废信息'} bottom={15} />
            <Descriptions column={2}>
              <Descriptions.Item label={'撤销类型'}>{cancelType[detail.cancelType]}</Descriptions.Item>
              <Descriptions.Item label={'备注'}>{detail.cancelNotes ?? '/'}</Descriptions.Item>
              <Descriptions.Item label={'操作人'}>{detail.cancelOperator}</Descriptions.Item>
              <Descriptions.Item label={'操作时间'}>{detail.auditTime}</Descriptions.Item>
            </Descriptions>
          </>
        )}
      </Drawer>
    </>
  )
}
