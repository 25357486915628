import React, {useCallback, useEffect, useState} from 'react'
import {Page} from '@/components/Page'
import commonStyles from '@/assets/styles/common.module.less'
import {Button, DatePicker, Form, Input, message, Radio, Row, Select, Space, TreeSelect} from 'antd'
import {feedbackModel} from '@/store/models/FeedBack'
import {sysModel} from '@/store/models/Sys'
import {userModel} from '@/store/models/User'
import {debounce} from '@/utils/util'
import {customerModel} from '@/store/models/Customer'
import {commonModel, useCodeArraySync} from '@/store/models/Common'
import moment from 'moment/moment'
import {useAppHistory} from '@/common/hooks/useRouter'
import {CloseCircleFilled, PlusCircleFilled} from '@ant-design/icons'
import {sysUserModel} from '@/store/models/SysUser'
import {lesseeModel} from '@/store/models/Lessee'
import {stewardModel} from '@/store/models/Steward'
import * as listener from '@/common/hooks/listener'

export default function AddComplaintOrder() {
  const history = useAppHistory()
  const [form] = Form.useForm()
  const loading = feedbackModel.csWorkOrderInsert.useLoading()
  const orgId = userModel.data.userInfo?.orgId
  const [orgTree, setOrgTree] = useState<SysOrgTreeNodeVO[]>([])
  const [customerList, setCustomerList] = useState<LessorCustomerListVO[]>([])
  const complaintTypeArr = useCodeArraySync('cs.work.order.complaint.type')
  const channelArr = useCodeArraySync('cs.work.order.feedback.channel')
  const [userList, setUserList] = useState<SysUserVO[]>([])
  const [lesseeList, setLesseeList] = useState<LesseeAddressVO[]>([])

  const [cityList, setCityList] = useState<CityDataProps[]>([])

  useEffect(() => {
    commonModel
      .queryCityCode({type: '2', parentCode: null})
      .then(({response: {data}}) => setCityList(data.map(item => ({...item, key: item.code}))))
  }, [])

  useEffect(() => {
    sysModel.sysOrgTree({fetchUser: false, orgIdLike: orgId} as SysOrgTreeQueryDTO).then(({response: {data}}) => {
      setOrgTree(data)
    })
  }, [orgId])

  const searchClientList = useCallback(
    debounce(async value => {
      const {
        response: {data},
      } = await customerModel.customerList({
        certName: value,
        pageSize: 1,
        pageable: false,
        status: '1',
        pageNum: 1,
      } as LessorCustomerListDTO)
      setCustomerList(data)
    }),
    []
  )
  const searchLesseeList = useCallback(
    debounce(async value => {
      const {
        response: {data},
      } = await lesseeModel.userLesseeAddressList({
        nickName: value,
        pageSize: 1,
        pageNum: 1,
        pageable: false,
      } as LesseeAddressQueryDTO)
      setLesseeList(data)
    }),
    []
  )

  async function fetchUser(orgId: string) {
    sysUserModel.querySysUserList({orgId, status: '1'}).then(({response: {data}}) => {
      setUserList(data)
    })
  }

  return (
    <Page>
      <div className={commonStyles.filterSection}>
        <Form
          form={form}
          onFinish={async () => {
            const {orderList, complaintType, ...values} = form.getFieldsValue(true)
            await feedbackModel.csWorkOrderInsert({
              ...values,
              complaintType,
              serviceOrderId: complaintType === '3' ? '' : orderList.map(item => item.orderId).join(','),
            } as CsWorkOrderInsertDTO)
            message.success('提交成功！')
            listener.emit('ComplaintWorkOrder')
            history.goBack()
          }}
          labelCol={{span: 8}}
          wrapperCol={{span: 10}}
        >
          <Form.Item label={'投诉类型'} name={'complaintType'} rules={[{required: true, message: '请选择投诉类型'}]}>
            <Radio.Group
              onChange={() => {
                form.setFieldsValue({
                  orderList: [{orderId: undefined}],
                })
              }}
            >
              {complaintTypeArr.map(item => (
                <Radio value={item.id} key={item.id}>
                  {item.label}
                </Radio>
              ))}
            </Radio.Group>
          </Form.Item>
          <Form.Item label={'客户类型'} name={'customerType'} rules={[{required: true, message: '请选择客户类型'}]}>
            <Radio.Group
              onChange={() => {
                form.setFieldsValue({
                  userId: undefined,
                  userName: undefined,
                  orderList: [{orderId: undefined}],
                })
              }}
            >
              <Radio value={'lessor'}>房东</Radio>
              <Radio value={'lessee'}>租客</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item noStyle shouldUpdate={(prev, next) => prev.customerType !== next.customerType}>
            {f => {
              const customerType = f.getFieldValue('customerType')
              return (
                <>
                  {customerType === 'lessor' && (
                    <Form.Item label={'用户名称'} name={'userId'} rules={[{required: true, message: '请选择用户名称'}]}>
                      <Select
                        placeholder={'请输入搜索'}
                        showSearch
                        allowClear
                        onSearch={searchClientList}
                        filterOption={false}
                        onChange={value => {
                          if (value) {
                            const {mobile, certName} = customerList.find(item => item.userId === value)
                            form.setFieldsValue({
                              orderList: [{orderId: undefined}],
                              userMobile: mobile,
                              userName: certName,
                            })
                          }
                        }}
                      >
                        {customerList.map(item => (
                          <Select.Option value={item.userId} key={item.userId}>
                            {`${item.certName}/${item.mobile}`}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  )}
                  {customerType === 'lessee' && (
                    <Form.Item label={'用户名称'} name={'userId'} rules={[{required: true, message: '请选择用户名称'}]}>
                      <Select
                        placeholder={'请输入搜索'}
                        showSearch
                        allowClear
                        onSearch={searchLesseeList}
                        filterOption={false}
                        onChange={value => {
                          if (value) {
                            const {nickName, contactMobile} = lesseeList.find(item => item.addrId === value)
                            form.setFieldsValue({
                              userMobile: contactMobile,
                              userName: nickName,
                              orderList: [{orderId: undefined}],
                            })
                          }
                        }}
                      >
                        {lesseeList.map(item => (
                          <Select.Option value={item.addrId} key={item.addrId}>
                            {`${item.nickName}/${item.contactMobile}`}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  )}
                </>
              )
            }}
          </Form.Item>
          <Form.Item noStyle shouldUpdate={(prev, next) => prev.complaintType !== next.complaintType}>
            {f => {
              const {complaintType} = f.getFieldsValue(true)
              return (
                ['1', '2'].includes(complaintType) && (
                  <Form.Item label={'服务单号'} shouldUpdate style={{marginBottom: 0}} required>
                    {() => {
                      return (
                        <Form.List name={'orderList'}>
                          {(fields, {remove, add}) => {
                            return (
                              <>
                                {fields.map(({name, key, ...field}, index) => {
                                  return (
                                    <div key={key}>
                                      <Space>
                                        <Form.Item noStyle shouldUpdate>
                                          {f => {
                                            const list = f.getFieldValue(['orderList', name, 'list']) ?? []
                                            return (
                                              <Form.Item
                                                name={[name, 'orderId']}
                                                {...field}
                                                rules={[{required: true, message: '请选择服务单号'}]}
                                              >
                                                <Select
                                                  placeholder={'请输入搜索'}
                                                  showSearch
                                                  allowClear
                                                  onSearch={async value => {
                                                    if (value) {
                                                      const {
                                                        response: {data},
                                                      } = await stewardModel.stewardAdminOrderList({
                                                        pageSize: 1,
                                                        pageNum: 1,
                                                        pageable: true,
                                                        // orderMakerType: customerType,
                                                        // customerId: customerType === 'lessor' ? userId : '',
                                                        // lesseeName: customerType === 'lessee' ? userName : '',
                                                        orderId: value,
                                                      } as StewardOrderQueryDTO)
                                                      f.setFields([{name: ['orderList', name, 'list'], value: data}])
                                                    }
                                                  }}
                                                  style={{width: '480px'}}
                                                  filterOption={false}
                                                >
                                                  {list.map(item => (
                                                    <Select.Option value={item.orderId} key={item.orderId}>
                                                      {`${item.orderId}${
                                                        item.cleanerName ? `/${item.cleanerName}` : ''
                                                      }/${item.categoryFirName}-${item.categorySecName}-${
                                                        item.categoryThiName
                                                      }`}
                                                    </Select.Option>
                                                  ))}
                                                </Select>
                                              </Form.Item>
                                            )
                                          }}
                                        </Form.Item>
                                        {fields.length > 1 && (
                                          <Form.Item>
                                            <CloseCircleFilled onClick={() => remove(name)} />
                                          </Form.Item>
                                        )}
                                        {index === fields.length - 1 && (
                                          <Form.Item>
                                            <PlusCircleFilled style={{color: '#00B7AE'}} onClick={() => add()} />
                                          </Form.Item>
                                        )}
                                      </Space>
                                    </div>
                                  )
                                })}
                              </>
                            )
                          }}
                        </Form.List>
                      )
                    }}
                  </Form.Item>
                )
              )
            }}
          </Form.Item>
          <Form.Item label={'城市'} name={'cityCode'} rules={[{required: true, message: '请选择城市'}]}>
            <Select
              options={cityList}
              showSearch
              filterOption={(inputValue, option: any) => option.name?.indexOf(inputValue) > -1}
              placeholder={'请选择'}
              fieldNames={{value: 'code', label: 'name'}}
              allowClear
            />
          </Form.Item>
          <Form.Item label={'反馈渠道'} name={'feedbackChannel'} rules={[{required: true, message: '请选择反馈渠道'}]}>
            <Select placeholder={'请选择'} allowClear>
              {channelArr.map(item => (
                <Select.Option value={item.id} key={item.id}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label={'投诉时间'}
            getValueFromEvent={(_, dateString) => dateString}
            getValueProps={value => ({value: value ? moment(value) : value})}
            name={'complaintTime'}
            rules={[{required: true, message: '请选择投诉时间'}]}
          >
            <DatePicker showTime />
          </Form.Item>
          <Form.Item
            label={'被投诉部门'}
            name={'complainedDeptId'}
            rules={[{required: true, message: '请选择被投诉部门'}]}
          >
            <TreeSelect
              placeholder={'请选择'}
              onChange={async (value, labelList) => {
                await fetchUser(value as string)
                form.setFieldsValue({complainedEmpId: undefined, complainedDept: labelList[0]})
              }}
              allowClear
              fieldNames={{label: 'label', value: 'key'}}
              treeData={orgTree}
            />
          </Form.Item>
          <Form.Item label={'被投诉人员'} name={'complainedEmpId'}>
            <Select
              placeholder={'请选择'}
              onChange={value => {
                const {name} = userList.find(item => item.userId === value) ?? {}
                form.setFieldsValue({complainedEmp: name})
              }}
              allowClear
              showSearch
              filterOption={(inputValue, option: any) => {
                return option.children?.indexOf(inputValue) > -1
              }}
            >
              {userList.map(item => (
                <Select.Option value={item.userId} key={item.userId}>
                  {`${item.name}（${item.mobile}）`}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label={'责任判定'}
            name={'responsibilityJudgment'}
            rules={[{required: true, message: '请选择责任判定'}]}
          >
            <Select placeholder={'请选择'} allowClear>
              <Select.Option value={'0'}>无责</Select.Option>
              <Select.Option value={'1'}>有责</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            label={'投诉情况概述'}
            name={'complaintDescription'}
            rules={[{required: true, message: '请输入投诉情况描述'}]}
          >
            <Input.TextArea placeholder={'请输入投诉情况描述'} allowClear rows={3} maxLength={500} showCount />
          </Form.Item>
          <Row justify={'center'}>
            <Space>
              <Button type={'primary'} htmlType={'submit'} loading={loading}>
                提交
              </Button>
              <Button htmlType={'reset'}>重置</Button>
            </Space>
          </Row>
        </Form>
      </div>
    </Page>
  )
}
