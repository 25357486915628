import React, {useCallback, useEffect, useState} from 'react'
import {Breadcrumb} from '@/components/Breadcrumb'
import {Page} from '@/components/Page'
import {
  Button,
  Col,
  DatePicker,
  Descriptions,
  Form,
  Input,
  InputNumber,
  Modal,
  Pagination,
  Row,
  Select,
  Space,
  Table,
} from 'antd'
import commonStyle from '@/assets/styles/common.module.less'
import {saleModel} from '@/store/models/Sale'
import moment from 'moment'
import {sysUserModel} from '@/store/models/SysUser'
import {toFixed} from '@/utils/amount'
import {Permission} from '@/components/Permission'

export default function SaleSalaryManageMent() {
  const [form] = Form.useForm()
  const [total, setTotal] = useState(0)
  const [list, setList] = useState<SaleSalaryPerformanceVO[]>([])
  const [holderList, setHolderList] = useState<SysUserVO[]>([])
  const [addForm] = Form.useForm()
  const [visible, setVisible] = useState(false)
  const [info, setInfo] = useState({} as SaleSalaryPerformanceVO)
  const [salePerformanceId, setSalePerformanceId] = useState('')

  const search = useCallback(async () => {
    const value = form.getFieldsValue(true)
    saleModel.saleSalaryPerformanceList(value).then(({response: {data, total}}) => {
      setList(data)
      setTotal(total)
    })
  }, [form])

  useEffect(() => {
    search()

    sysUserModel.querySysUserList({roleId: 'cus_manager', status: '1'}).then(({response: {data}}) => {
      setHolderList(data)
    })
  }, [search])

  return (
    <Page
      breadcrumb={
        <Breadcrumb
          right={
            <Permission code={'003091002'}>
              <Button
                type={'primary'}
                onClick={() => {
                  setVisible(true)
                }}
              >
                新增绩效
              </Button>
            </Permission>
          }
        />
      }
    >
      <Form form={form} onFinish={search} onReset={search} initialValues={{pageNum: 1, pageSize: 10}}>
        <div className={commonStyle.filterSection}>
          <Row>
            <Col span={6}>
              <Form.Item label={'销售姓名'} name={'userName'}>
                <Input placeholder='请输入' allowClear />
              </Form.Item>
            </Col>
            <Col offset={1}>
              <Form.Item label={'工作月份'} name={'time'}>
                <DatePicker.RangePicker
                  picker={'month'}
                  onChange={(_, dateStrings) => {
                    const [monthBegin, monthEnd] = dateStrings ?? []
                    form.setFieldsValue({monthBegin, monthEnd})
                  }}
                  allowClear
                />
              </Form.Item>
            </Col>
          </Row>
          <Row justify={'end'}>
            <Space>
              <Button type={'primary'} htmlType='submit' onClick={() => form.setFieldsValue({pageNum: 1})}>
                查询
              </Button>
              <Button htmlType={'reset'}>重置</Button>
            </Space>
          </Row>
        </div>

        <div className={commonStyle.filterSection}>
          <Table
            rowKey={'salePerformanceId'}
            style={{marginBottom: 20}}
            loading={saleModel.saleSalaryPerformanceList.useLoading()}
            dataSource={list}
            columns={[
              {title: '组织', dataIndex: 'orgName', key: 'orgName'},
              {title: '姓名', dataIndex: 'userName', key: 'userName'},
              {title: '手机号', dataIndex: 'mobile', key: 'mobile'},
              {title: '工作月份', dataIndex: 'month', key: 'month', render: text => moment(text).format('YYYY-MM')},
              {title: '绩效总额', dataIndex: 'performance', key: 'performance', render: text => `${toFixed(text)}元`},
              {
                title: '操作',
                render: record => (
                  <Space>
                    <Permission code={'001091002'}>
                      <Button
                        type={'link'}
                        onClick={() => {
                          setInfo(record)
                        }}
                        style={{paddingLeft: 0}}
                      >
                        查看
                      </Button>
                    </Permission>
                    <Permission code={'002091002'}>
                      <Button
                        type={'link'}
                        onClick={() => {
                          setVisible(true)
                          addForm.setFieldsValue({
                            orgName: record.orgName,
                            userId: record.userId,
                            month: moment(record.month),
                            performance: record.performance,
                            salePerformanceId: record.salePerformanceId,
                          })
                        }}
                        style={{paddingLeft: 0}}
                      >
                        编辑
                      </Button>
                    </Permission>
                    <Permission code={'004091002'}>
                      <Button
                        type={'link'}
                        style={{paddingLeft: 0}}
                        onClick={() => {
                          setSalePerformanceId(record.salePerformanceId)
                        }}
                      >
                        删除
                      </Button>
                    </Permission>
                  </Space>
                ),
              },
            ]}
            pagination={false}
          />

          <Form.Item
            noStyle
            shouldUpdate={(prev, next) => prev.pageSize !== next.pageSize || prev.pageNum !== next.pageNum}
          >
            {() => (
              <Pagination
                total={total}
                current={form.getFieldValue('pageNum')}
                pageSize={form.getFieldValue('pageSize')}
                style={{textAlign: 'center'}}
                showSizeChanger
                onChange={(pageNum, pageSize) => {
                  form.setFieldsValue({pageNum, pageSize})
                  search()
                }}
              />
            )}
          </Form.Item>
        </div>
      </Form>

      {/* 绩效详情 */}
      <Modal
        title={'绩效详情'}
        visible={!!info.salePerformanceId}
        destroyOnClose
        onCancel={() => setInfo({} as SaleSalaryPerformanceVO)}
        centered
        footer={null}
      >
        <Descriptions column={1}>
          <Descriptions.Item label={'组织'}>{info.orgName}</Descriptions.Item>
          <Descriptions.Item label={'销售'}>{info.userName}</Descriptions.Item>
          <Descriptions.Item label={'工作月份'}>{moment(info.month).format('YYYY-MM')}</Descriptions.Item>
          <Descriptions.Item label={'金额'}>{toFixed(info.performance)}元</Descriptions.Item>
        </Descriptions>
      </Modal>

      {/* 新增绩效 */}
      <Modal
        title={addForm.getFieldValue('salePerformanceId') ? '编辑绩效' : '新增绩效'}
        onCancel={() => {
          setVisible(false)
        }}
        onOk={async () => {
          const {month, performance, ...value} = addForm.getFieldsValue(true)
          await saleModel.saleSalaryPerformanceUpsert({
            month: moment(month).format('YYYY-MM'),
            performance: Number(performance),
            ...value,
          })
          search()
          setVisible(false)
        }}
        afterClose={() => {
          addForm.resetFields()
        }}
        destroyOnClose
        visible={visible}
        centered
        okText={'保存'}
      >
        <Form form={addForm} labelCol={{span: 5}} wrapperCol={{span: 15}}>
          <Form.Item noStyle shouldUpdate>
            {() =>
              addForm.getFieldValue('orgName') ? (
                <Form.Item label={'组织'}>
                  <span>{addForm.getFieldValue('orgName')}</span>
                </Form.Item>
              ) : null
            }
          </Form.Item>
          <Form.Item label={'销售'} name={'userId'}>
            <Select placeholder={'请选择'} disabled={addForm.getFieldValue('userId')} allowClear>
              {holderList.map(item => (
                <Select.Option key={item.userId} value={item.userId}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label={'工作月份'} name={'month'}>
            <DatePicker picker={'month'} disabled={addForm.getFieldValue('month')} />
          </Form.Item>
          <Form.Item label={'金额'} name={'performance'}>
            <InputNumber style={{width: '100%'}} placeholder='请输入金额（元）' />
          </Form.Item>
        </Form>
      </Modal>

      {/* 删除 */}
      <Modal
        visible={!!salePerformanceId}
        centered
        title={'提示'}
        onCancel={() => {
          setSalePerformanceId('')
        }}
        onOk={async () => {
          await saleModel.saleSalaryPerformanceDelete({salePerformanceId})
          setSalePerformanceId('')
          search()
        }}
      >
        <span>是否删除该条绩效？</span>
      </Modal>
    </Page>
  )
}
