import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {
  Button,
  Cascader,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  List,
  message,
  Modal,
  Radio,
  Row,
  Select,
  Space,
  Table,
  Tag,
} from 'antd'
import {Breadcrumb} from '@/components/Breadcrumb'
import commonStyles from '@/assets/styles/common.module.less'
import styles from './index.module.less'
import {propertyModel} from '@/store/models/Property'
import {communityTitle, renderSectionList} from '@/utils/render'
import {RightOutlined, LeftOutlined} from '@ant-design/icons'
import {stewardModel} from '@/store/models/Steward'
import moment from 'moment'
import {useHistory} from 'react-router'
import {sysUserModel} from '@/store/models/SysUser'
import {customerModel} from '@/store/models/Customer'
import {CloseCircleFilled} from '@ant-design/icons'
import {Page} from '@/components/Page'
import BackButton from '@/components/BackButton'
import {cleanServiceArr} from '@/utils/clean'

const {Option} = Select

type CustomerPropertyInfoVO = PropertyInfoVO & {isPublic: boolean}

const renderTitle = (val, unit) => {
  return val ? `${val}${unit}` : ''
}

function itemTitle(item: CustomerPropertyInfoVO) {
  // 集中式公寓
  if (item.isPublic) return '公区'
  if (item.rentType === '3')
    return communityTitle.condo({houseNo: item.houseNo, templateName: (item as any)?.templateName})
  if (item.rentType === '1') return communityTitle.scatterWhole(item)
  if (item.rentType === '2') return communityTitle.scatterJoin(item)
}

const cleanFrequencyArr = [
  {id: '4', label: '每天', children: [{id: '6', label: '1次'}]},
  {
    id: '1',
    label: '每周',
    children: [
      {id: '1', label: '1次'},
      {id: '5', label: '2次'},
    ],
  },
  {id: '2', label: '两周', children: [{id: '2', label: '1次'}]},
  {
    id: '3',
    label: '每月',
    children: [
      {id: '3', label: '1次'},
      {id: '4', label: '3次'},
    ],
  },
]

export const cleanPeriod1 = [
  {id: 4, label: '4周'},
  {id: 8, label: '8周'},
  {id: 12, label: '12周'},
  {id: 24, label: '24周'},
  {id: 48, label: '48周'},
]

// 周期
export const cleanPeriod2 = [
  {id: 2, label: '两个月'},
  {id: 4, label: '四个月'},
  {id: 6, label: '六个月'},
  {id: 12, label: '十二个月'},
]

const d1 = moment().add(1, 'month').diff(moment(), 'days')

export const serviceMap = {
  '1': cleanPeriod1,
  '2': cleanPeriod1,
  '3': [{id: 1, label: '一个月'}, ...cleanPeriod2],
  '4': cleanPeriod2,
  '5': cleanPeriod1,
  '6': [{id: d1, label: '一个月'}],
}

function isEqual(
  item1: Pick<CustomerPropertyInfoVO, 'propertyCode' | 'isPublic'>,
  item2: Pick<CustomerPropertyInfoVO, 'propertyCode' | 'isPublic'>
) {
  return item1.propertyCode === item2.propertyCode && item1.isPublic === item2.isPublic
}

export default function OrderBatchAdd() {
  const history = useHistory()
  const [form] = Form.useForm()

  const [customer, setCustomer] = useState({} as {lessorName: string; customerId: string})
  // 房东列表
  const [customerList, setCustomerList] = useState<LessorCustomerListVO[]>([])
  // 小区列表
  const [communityList, setCommunityList] = useState<CommunityVO[]>([])
  const [originCommunityList, setOriginCommunityList] = useState<CommunityVO[]>([])

  // 房源列表 {[小区code]: 小区房源列表}
  const [communitySection, setCommunitySection] = useState<{[key: string]: CustomerPropertyInfoVO[]}>({})

  // 当前选中的小区
  const [community, setCommunity] = useState<CommunityVO>(null)
  // 公区
  const [isPublic, setIsPublic] = useState(false)

  // 可选房源
  const [selectable, setSelectable] = useState<CustomerPropertyInfoVO[]>([])
  // 已选房源
  const [selected, setSelected] = useState<CustomerPropertyInfoVO[]>([])
  // 右边可选项
  const [rightSelectable, setRightSelectable] = useState<CustomerPropertyInfoVO[]>([])

  // 服务项目列表
  const [serviceTree, setServiceTree] = useState<StewardServiceVO[]>([])
  // 服务人员列表
  const [userList, setUserList] = useState<SysUserVO[]>([])

  const [isConfirm, setIsConfirm] = useState(false)

  const [open, setOpen] = useState(false)
  const [houseNo, setHouseNo] = useState('')

  // 查询房东列表
  const queryCustomerList = useCallback((certName: string) => {
    customerModel.customerList({certName} as LessorCustomerListDTO).then(({response: {data}}) => setCustomerList(data))
  }, [])

  // 查询小区里的房源列表
  const queryPropertyList = useCallback((args: {communityCode: string; lessorId: string; rentType: string}) => {
    propertyModel.queryPropertyList({...args, pageable: false}).then(({response: {data}}) => {
      const idCode = {}
      const floor = {}
      const list: CustomerPropertyInfoVO[] = data.reduce((res, item) => {
        // 如果是合租房间 生成"公区" 比如：RoomA,RoomB,RoomC => RoomA,RoomB,RoomC,公区
        // 注意注意注意  这个操作结束之后 propertyCode不再是唯一标识
        const id = `${item.houseNo ?? ''}-${item.buildingNo ?? ''}-${item.unitNo ?? ''}`
        if (item.rentType === '2' && !idCode[id]) {
          idCode[id] = id
          res.push({...item, isPublic: true})
        }
        // 如果是公寓房 注意注意注意是「每一层」生成一个"公区"
        if (item.rentType === '3' && !floor[item.floor]) {
          floor[item.floor] = item.floor
          res.push({...item, isPublic: true})
        }

        // 如果是周期保洁 只显示「合租房间的公区」和「公寓房的公区」；periodClean 为 true 时是周期保洁
        // if (!periodClean) {
        //   res.push({...item, isPublic: false})
        // }
        res.push({...item, isPublic: false})
        return res
      }, [])
      setCommunitySection(prevState => ({...prevState, [args.communityCode]: list}))
    })
  }, [])

  // 查询房东小区列表
  const queryCommunityAggregate = useCallback(
    (lessorId: string) => {
      if (lessorId) {
        propertyModel.propertyCommunityAggregate({lessorId}).then(({response: {data}}) => {
          if (data && data.length) {
            setCommunityList(data)
            setOriginCommunityList(data)
            setCommunity(data[0])
            queryPropertyList({
              communityCode: data[0].code,
              lessorId,
              rentType: data[0].communityType === '2' ? '3' : null,
            })
          } else {
            setCommunityList([])
            setOriginCommunityList([])
            setCommunity(null)
            setCommunitySection({})
          }
        })
      } else {
        setCommunityList([])
        setOriginCommunityList([])
        setCommunity(null)
      }
    },
    [queryPropertyList]
  )

  // 快捷开发用

  useEffect(() => {
    // 服务项目列表
    stewardModel.stewardServiceTree({}).then(({response: {data}}) => {
      setServiceTree(data.filter(item => item.id !== '5fe8a10b52b343dd8ee99686fad802ee'))
    })

    // 服务人员列表
    sysUserModel.sysUserList({roleId: 'steward,cleaner', status: '1'}).then(({response: {data}}) => setUserList(data))
  }, [])

  const dataSource = useMemo(() => {
    // 所有的数据
    if (!isPublic) return communitySection[community?.code] ?? []
    // 公区
    return communitySection[community?.code]?.filter(value => value.isPublic) ?? []
  }, [community?.code, communitySection, isPublic])

  const renderSection = useMemo(() => {
    return renderSectionList(
      dataSource.filter(item => item.houseNo?.includes(houseNo)),
      {
        renderTitle: item =>
          community?.communityType === '1' ? renderTitle(item.buildingNo, '栋') : renderTitle(item.floor, '层'),
        groupBy: item => (community?.communityType === '1' ? item.buildingNo : item.floor),
      }
    )
  }, [community?.communityType, dataSource, houseNo])

  // 左边是否选中
  const isChecked = useCallback(
    (item: CustomerPropertyInfoVO) => {
      return selectable.some(multi => isEqual(multi, item))
    },
    [selectable]
  )

  // 移到了右边的
  const isDisabled = useCallback(
    (item: CustomerPropertyInfoVO) => {
      return selected.some(ele => isEqual(ele, item))
    },
    [selected]
  )

  // 是否有向右边移动的项
  const isRight = useMemo(() => {
    return !selectable.every(item => selected.some(multi => isEqual(multi, item)))
  }, [selectable, selected])

  //选择的服务日期
  const [appointDateList, setAppointDateList] = useState<Array<string>>([])

  return (
    <Page
      breadcrumb={
        <Breadcrumb
          right={
            <Button
              type={'primary'}
              onClick={async () => {
                await form.validateFields()
                if (selected.length === 0) return message.warn('请选择服务房源！')
                setIsConfirm(true)
              }}
            >
              提交
            </Button>
          }
        />
      }
    >
      <BackButton />
      <div className={commonStyles.filterSection}>
        <div className={styles.transfer}>
          <div className={styles.transfer_left}>
            <Row align={'middle'} justify={'space-between'} wrap={false} style={{height: '75px'}}>
              <Space size={20}>
                <span className={styles.transfer_title}>可选房源</span>
                <Button
                  type={'link'}
                  style={{padding: 0}}
                  onClick={() => {
                    setSelectable(prev => {
                      const selected = dataSource?.filter(
                        value => !isDisabled(value) && prev.every(item => !isEqual(item, value))
                      )
                      return [...prev, ...selected]
                    })
                  }}
                >
                  全选
                </Button>
                <Button
                  type={'link'}
                  style={{padding: 0}}
                  onClick={() => {
                    setSelectable(prevState =>
                      prevState.filter(item => !dataSource.some(value => isEqual(item, value)))
                    )
                  }}
                >
                  全不选
                </Button>
                <Checkbox value={isPublic} onChange={() => setIsPublic(prevState => !prevState)}>
                  公区
                </Checkbox>
              </Space>
              <Select
                showSearch
                placeholder={'请输入房东名称'}
                style={{width: '200px'}}
                onSearch={queryCustomerList}
                onChange={(value: string, option: any) => {
                  queryCommunityAggregate(value)
                  setCustomer({customerId: value, lessorName: option?.children})
                  setSelectable([])
                  setSelected([])
                  setRightSelectable([])
                }}
                allowClear
                showArrow={false}
                filterOption={false}
                defaultActiveFirstOption={false}
              >
                {customerList.map(item => (
                  <Option key={item.userId} value={item.userId}>
                    {`${item.certName}（${item.mobile}）`}
                  </Option>
                ))}
              </Select>
            </Row>
            <Row>
              <Col span={10} style={{height: '469px', overflow: 'scroll', borderRight: '1px solid #D9D9D9'}}>
                <List
                  dataSource={communityList}
                  renderItem={item => (
                    <List.Item
                      onClick={() => {
                        setCommunity(item)
                        if (!communitySection[item.code])
                          queryPropertyList({
                            communityCode: item.code,
                            lessorId: customer.customerId,
                            rentType: item.communityType === '2' ? '3' : null,
                          })
                      }}
                      style={
                        item.code === community?.code
                          ? {backgroundColor: '#DDF8F6', color: '#00B7AE', justifyContent: 'center'}
                          : {justifyContent: 'center'}
                      }
                    >
                      {item.name}
                    </List.Item>
                  )}
                  rowKey={item => item.code}
                  split={false}
                  header={
                    <div style={{paddingRight: '15px'}}>
                      <Input
                        allowClear
                        placeholder={'请输入小区名称/公寓名称'}
                        onChange={({target: {value}}) =>
                          setCommunityList(originCommunityList.filter(item => item.name?.includes(value)))
                        }
                      />
                    </div>
                  }
                />
              </Col>
              <Col span={14} style={{height: '469px', overflow: 'scroll'}}>
                <div style={{margin: '12px 0 12px 15px'}}>
                  <Input allowClear placeholder={'请输入门牌号'} onChange={({target: {value}}) => setHouseNo(value)} />
                </div>
                {renderSection?.map((item, index) => (
                  <List
                    key={index}
                    dataSource={item.data}
                    rowKey={item => item.propertyCode + `${item.isPublic}`}
                    header={
                      <div style={{backgroundColor: '#F5F5F5', padding: '12px 15px', margin: '-12px 0'}}>
                        {item.title}
                      </div>
                    }
                    renderItem={ele => (
                      <List.Item
                        actions={[
                          <Checkbox
                            key={'1'}
                            checked={isDisabled(ele) || isChecked(ele)}
                            disabled={isDisabled(ele)}
                            onChange={() => {
                              setSelectable(prevState =>
                                prevState.some(item => isEqual(item, ele))
                                  ? prevState.filter(item => !isEqual(item, ele))
                                  : [...prevState, ele]
                              )
                            }}
                          />,
                        ]}
                        style={{paddingLeft: '15px', paddingRight: '15px'}}
                      >
                        {itemTitle(ele)}
                      </List.Item>
                    )}
                    split={false}
                  />
                ))}
              </Col>
            </Row>
          </div>
          <div className={styles.transfer_middle}>
            <Space direction={'vertical'} size={11}>
              <div
                className={isRight ? styles.transfer_arrowActive : styles.transfer_arrowNormal}
                onClick={() => {
                  if (isRight) {
                    setSelected(prevState => [...prevState, ...selectable])
                    setSelectable([])
                  }
                }}
              >
                <RightOutlined style={{color: 'white'}} />
              </div>
              <div
                className={rightSelectable.length ? styles.transfer_arrowActive : styles.transfer_arrowNormal}
                onClick={() => {
                  if (rightSelectable.length) {
                    setSelected(prevState =>
                      prevState.filter(item => !rightSelectable.some(value => isEqual(item, value)))
                    )
                    setRightSelectable([])
                  }
                }}
              >
                <LeftOutlined style={{color: 'white'}} />
              </div>
            </Space>
          </div>
          <div className={styles.transfer_right}>
            <Row align={'middle'} wrap={false} style={{height: '75px'}}>
              <Space size={20}>
                <span className={styles.transfer_title}>{`已选房源（${selected.length}间）`}</span>
                <Button type={'link'} style={{padding: 0}} onClick={() => setRightSelectable(selected)}>
                  全选
                </Button>
                <Button type={'link'} style={{padding: 0}} onClick={() => setRightSelectable([])}>
                  全不选
                </Button>
              </Space>
            </Row>
            <Table
              rowKey={record => record.propertyCode + `${record.isPublic}`}
              columns={[
                {title: '房东名称', width: '30%', render: () => customer.lessorName ?? ''},
                {
                  title: '门牌号',
                  key: 'title',
                  width: '60%',
                  dataIndex: 'title',
                  render: (title, record) => (record.isPublic ? title + '- 公区' : title),
                },
                {
                  title: '',
                  align: 'right',
                  width: '10%',
                  render: (_, record) => {
                    const checked = rightSelectable.some(item => isEqual(item, record))
                    return (
                      <Checkbox
                        checked={checked}
                        onChange={() => {
                          setRightSelectable(prevState =>
                            checked ? prevState.filter(item => !isEqual(item, record)) : [...prevState, record]
                          )
                        }}
                      />
                    )
                  },
                },
              ]}
              dataSource={selected}
              pagination={false}
              scroll={{y: '414px'}}
            />
          </div>
        </div>

        <Form
          form={form}
          labelAlign={'right'}
          initialValues={{appointTimeCustomized: false, contactMobile: '4001681217'}}
        >
          <Row>
            <Form.Item name={'appointTimeCustomized'}>
              <Radio.Group
                onChange={() => {
                  const formValues = form.getFieldsValue(true)
                  const allKeys = Object.keys(formValues)?.filter(item => item !== 'appointTimeCustomized')
                  form.resetFields(allKeys)
                }}
              >
                <Radio value={false}>标准</Radio>
                <Radio value={true}>自定义</Radio>
              </Radio.Group>
            </Form.Item>
          </Row>

          <Row>
            <Col span={7}>
              <Form.Item
                name={'_category'}
                getValueFromEvent={(_, server) => {
                  form.setFieldsValue({
                    categoryFir: server[0]?.id,
                    categoryFirName: server[0]?.name,
                    categorySec: server[1]?.id,
                    categorySecName: server[1]?.name,
                    categoryThi: server[2]?.id,
                    categoryThiName: server[2]?.name,
                  })
                  return _
                }}
                label={'服务项目'}
                rules={[{required: true, message: '请选择服务项目'}]}
              >
                <Cascader
                  allowClear
                  placeholder={'请选择'}
                  options={serviceTree}
                  fieldNames={{label: 'name', value: 'id', children: 'stewardServiceList'}}
                  onChange={value => {
                    if (value && cleanServiceArr.findIndex(item => item.id === value[2]) !== -1) {
                      form.setFieldsValue({serverId: null})
                    }
                  }}
                />
              </Form.Item>
            </Col>

            <Col span={7} offset={1}>
              <Form.Item noStyle shouldUpdate>
                {form1 => {
                  const categoryThi = form1.getFieldValue('categoryThi')
                  const disable = cleanServiceArr.findIndex(item => item.id === categoryThi) !== -1
                  return (
                    <Form.Item
                      name={'serverId'}
                      label={'服务人员'}
                      rules={[{required: !disable, message: '请选择服务人员'}]}
                    >
                      <Select
                        placeholder={'请选择'}
                        allowClear
                        disabled={disable}
                        showSearch
                        filterOption={(inputValue, option: any) => {
                          return option.children?.indexOf(inputValue) > -1
                        }}
                      >
                        {userList.map(item => (
                          <Option key={item.userId} value={item.userId}>
                            {`${item.name}（${item.mobile}）`}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  )
                }}
              </Form.Item>
            </Col>

            <Form.Item noStyle shouldUpdate>
              {form1 => {
                const timeDisabled =
                  cleanServiceArr.findIndex(item => item.id === form1.getFieldValue('categoryThi')) !== -1
                return form1.getFieldValue('appointTimeCustomized') ? (
                  <>
                    <Col span={7} offset={1}>
                      <Form.Item
                        name={'time'}
                        label={'时间'}
                        rules={[{required: !timeDisabled, message: '请选择时间'}]}
                      >
                        <DatePicker
                          picker={'time'}
                          format={'HH:mm'}
                          allowClear
                          style={{width: '100%'}}
                          placeholder={'请选择时间'}
                          disabled={timeDisabled}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={7}>
                      <Form.Item name={'date'} label={'日期'}>
                        <DatePicker
                          allowClear
                          onChange={value => {
                            if (value && !appointDateList.includes(moment(value).format('YYYY-MM-DD'))) {
                              setAppointDateList(state => [...state, moment(value).format('YYYY-MM-DD')])
                            }
                          }}
                          dateRender={current => {
                            const style = {} as {border: string; borderRadius: string}
                            if (appointDateList.includes(moment(current).format('YYYY-MM-DD'))) {
                              style.border = '1px solid #1890ff'
                              style.borderRadius = '50%'
                            }

                            return (
                              <div className='ant-picker-cell-inner' style={style}>
                                {current.date()}
                              </div>
                            )
                          }}
                          format={'YYYY-MM-DD'}
                          style={{width: '100%'}}
                          open={open}
                          onClick={() => setOpen(true)}
                          onBlur={() => setOpen(false)}
                          disabledDate={current => {
                            return current && current < moment().add(1, 'day').startOf('day')
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={24} style={{marginBottom: 20}}>
                      <Row gutter={[20, 20]}>
                        {appointDateList.map((item, index) => (
                          <Col key={index}>
                            <div className={styles.tagItem}>
                              <Tag style={{padding: '5px 10px'}}>{item}</Tag>
                              <CloseCircleFilled
                                className={styles.sortItemIcon}
                                onClick={() =>
                                  setAppointDateList(state => state.filter((_, _index) => _index !== index))
                                }
                              />
                            </div>
                          </Col>
                        ))}
                      </Row>
                    </Col>
                  </>
                ) : (
                  <>
                    <Col span={7} offset={1}>
                      <Form.Item
                        name={'periodBeginTime'}
                        label={'首次服务时间'}
                        rules={[{required: true, message: '请选择首次服务时间'}]}
                      >
                        <DatePicker
                          showTime={{format: 'YYYY-MM-DD HH:mm'}}
                          allowClear
                          format={'YYYY-MM-DD HH:mm'}
                          style={{width: '100%'}}
                          disabledDate={current => {
                            return current && current < moment().add(1, 'day').startOf('day')
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={7}>
                      <Form.Item
                        name={'_periodFreq'}
                        getValueFromEvent={value => {
                          form.setFieldsValue({periodFreq: value[1]})
                          return value
                        }}
                        label={'频次'}
                        rules={[{required: true, message: '请选择频次'}]}
                      >
                        <Cascader
                          allowClear
                          placeholder={'请选择'}
                          options={cleanFrequencyArr}
                          fieldNames={{value: 'id'}}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={7} offset={1}>
                      <Form.Item noStyle shouldUpdate>
                        {form1 => (
                          <Form.Item
                            name={'periodTimes'}
                            label={'周期'}
                            rules={[{required: true, message: '请选择周期'}]}
                          >
                            <Select allowClear placeholder={'请选择'} disabled={!form1.getFieldValue('periodFreq')}>
                              {serviceMap[form1.getFieldValue('periodFreq')]?.map(item => (
                                <Option key={item.id} value={item.id}>
                                  {item.label}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        )}
                      </Form.Item>
                    </Col>
                  </>
                )
              }}
            </Form.Item>
            <Form.Item noStyle shouldUpdate>
              {f => {
                const appointTimeCustomized = f.getFieldValue('appointTimeCustomized')
                return (
                  <Col span={7} offset={appointTimeCustomized ? 0 : 1}>
                    <Form.Item
                      label={'服务联系手机号'}
                      name={'contactMobile'}
                      rules={[
                        {required: true, message: '请输入服务联系手机号'},
                        {
                          validator: (_, value, callback) => {
                            if (value?.length === 11) {
                              if (!/^1[0-9]{10}$/.test(value)) return callback('手机号格式不正确')
                            } else {
                              if (!/^400[0-9]{7}/.test(value)) return callback('号码格式不正确')
                            }
                            return Promise.resolve()
                          },
                        },
                      ]}
                    >
                      <Input placeholder={'请输入'} />
                    </Form.Item>
                  </Col>
                )
              }}
            </Form.Item>

            <Col span={24}>
              <Form.Item name={'orderDesc'} label={'服务备注'} wrapperCol={{span: 10}}>
                <Input.TextArea placeholder={'请输入备注信息'} allowClear maxLength={200} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>

      <Modal open={isConfirm} centered title={'提示'} onCancel={() => setIsConfirm(false)} footer={null}>
        <span>是否确认提交该申请？</span>
        <Row justify={'end'}>
          <Space>
            <Button onClick={() => setIsConfirm(false)}>取消</Button>
            <Button
              type={'primary'}
              onClick={async () => {
                const {time, ...formValues} = form.getFieldsValue(true)
                const propertyList = selected.map(item => ({
                  propertyCode: item.propertyCode,
                  publicTag: item.isPublic ? 1 : 0,
                }))
                if (form.getFieldValue('appointTimeCustomized')) {
                  if (!appointDateList.length) {
                    return message.warn('请选择日期')
                  }
                  const appointTimeList = appointDateList.map(item => item + ' ' + moment(time).format('HH:mm:00'))
                  await stewardModel.stewardOrderBatchInsert({
                    ...formValues,
                    propertyList,
                    customerId: customer.customerId,
                    appointTimeList,
                  })
                } else {
                  const periodBeginTime = moment(form.getFieldValue('periodBeginTime')).format('YYYY-MM-DD HH:mm:ss')
                  await stewardModel.stewardOrderBatchInsert({
                    ...formValues,
                    propertyList,
                    periodBeginTime,
                    customerId: customer.customerId,
                  })
                }
                setIsConfirm(false)
                message.success('申请成功！')
                history.goBack()
              }}
              loading={stewardModel.stewardOrderBatchInsert.useLoading()}
            >
              确定
            </Button>
          </Space>
        </Row>
      </Modal>
    </Page>
  )
}
