import React, {useCallback} from 'react'
import {DatePicker, Form, message, Modal, Select} from 'antd'
import {debounce} from '@/utils/util'
import {sysUserModel} from '@/store/models/SysUser'
import {cleanModel} from '@/store/models/Clean'
import moment from 'moment'
import locale from 'antd/es/date-picker/locale/zh_CN'

export function useCreateOfflineQa() {
  const [form] = Form.useForm()

  const searchServerList = useCallback(
    debounce((name: string) => {
      sysUserModel
        .sysUserList({name, status: '1', pageNum: 1, pageSize: 10, pageable: false, roleId: 'cleaning_onsite_QC'})
        .then(({response: {data}}) => form.setFieldsValue({_userList: data}))
    }, 150),
    []
  )

  const searchCountOfflineUnfinished = useCallback(
    (serverId: string) => {
      cleanModel.cleanOrderCountOfflineUnfinished({qaOfflineServerId: serverId}).then(({response: {data}}) => {
        form.setFieldsValue({_unfinishedCount: data})
      })
    },
    [form]
  )

  return useCallback(
    ({orderId, onSuccess}: {orderId: string; onSuccess?: () => void}) => {
      searchServerList('')

      Modal.confirm({
        title: '现场质检安排',
        icon: null,
        closable: true,
        centered: true,
        okText: '提交',
        onOk: async () => {
          await form.validateFields()
          form.submit()
        },
        onCancel: () => form.resetFields(),
        content: (
          <Form
            form={form}
            onFinish={async () => {
              const formValues = form.getFieldsValue(true)
              await cleanModel.cleanOrderCreateOfflineQa({
                orderId,
                ...formValues,
                qaOfflineAssignDate: moment(formValues.qaOfflineAssignDate).format('YYYY-MM-DD 23:59:59'),
              })
              message.success('安排成功！')
              onSuccess?.()
            }}
            style={{marginTop: '20px'}}
          >
            <Form.Item noStyle shouldUpdate={(prevValues, nextValues) => prevValues._userList !== nextValues._userList}>
              {form1 => {
                const userList = form1.getFieldValue('_userList')
                return (
                  <Form.Item
                    label={'现场质检员'}
                    name={'qaOfflineServerId'}
                    rules={[{required: true, message: '请选择'}]}
                  >
                    <Select
                      placeholder={'请选择'}
                      allowClear
                      onSearch={searchServerList}
                      showSearch
                      showArrow={false}
                      filterOption={false}
                      defaultActiveFirstOption={false}
                      onChange={(value: string, option) => {
                        const children = ((option as unknown) as {key: string; value: string; children: string})
                          ?.children
                        form1.setFieldsValue({_serverName: children?.split('-')?.[0]})
                        if (value) {
                          searchCountOfflineUnfinished(value)
                        } else {
                          form1.setFieldsValue({_unfinishedCount: null})
                        }
                      }}
                    >
                      {userList?.map(item => (
                        <Select.Option key={item.userId} value={item.userId}>
                          {`${item.name}-${item.mobile}`}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                )
              }}
            </Form.Item>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, nextValues) => prevValues._unfinishedCount !== nextValues._unfinishedCount}
            >
              {form1 => {
                const unfinishedCount = form1.getFieldValue('_unfinishedCount')
                const serverName = form1.getFieldValue('_serverName')
                return (
                  ![null, undefined].includes(unfinishedCount) && (
                    <Form.Item label={' '} colon={false}>
                      <span style={{color: '#00B7AE'}}>
                        {serverName}剩余{unfinishedCount}单未完成
                      </span>
                    </Form.Item>
                  )
                )
              }}
            </Form.Item>
            <Form.Item
              label={'期望完成时间'}
              name={'qaOfflineAssignDate'}
              rules={[{required: true, message: '请选择期望完成时间'}]}
            >
              <DatePicker
                placeholder={'请选择'}
                disabledDate={date => moment().subtract('1', 'day').isAfter(date)}
                locale={locale}
              />
            </Form.Item>
          </Form>
        ),
      })
    },
    [form, searchCountOfflineUnfinished, searchServerList]
  )
}
