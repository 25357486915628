import React, {CSSProperties, useEffect, useRef, useState} from 'react'
import {Page} from '@/components/Page'
import {Button, Form, Input, message, Space} from 'antd'

import {ak} from '@/common/config'
import BMap from 'react-bmapgl/dist/Map/Map'
import MapApiLoaderHOC from 'react-bmapgl/dist/Map/MapApiLoaderHOC'
import DrawingManager from 'react-bmapgl/dist/Library/DrawingManager'
import {useAppHistory, useAppLocation} from '@/common/hooks/useRouter'
import Polygon from 'react-bmapgl/dist/Overlay/Polygon'
import {stewardModel} from '@/store/models/Steward'

function CleanServerAreaAdd() {
  const history = useAppHistory()
  const location = useAppLocation<'/clean-server-area/add'>()
  const areaId = location.state?.areaId

  const [form] = Form.useForm()
  const mapRef = useRef(null)

  useEffect(() => {
    if (areaId) {
      stewardModel.stewardCleanAreaInfo({areaId}).then(({response: {data}}) => {
        setEnableEditing(true)
        setPointList(data.pointList)
        form.setFieldsValue({areaId: data.areaId, areaName: data.areaName})
        mapRef.current?.map?.setViewport?.(data.pointList.map(value => new BMapGL.Point(value.lng, value.lat)))
      })
    }
  }, [areaId, form])

  const [pointList, setPointList] = useState([])
  const [enableEditing, setEnableEditing] = useState(false)
  const ref = useRef<Polygon>(null)

  const containerRef = useRef(null)

  return (
    <Page>
      <div style={styles.container}>
        <div style={{marginBottom: 21}}>在地图上选点进行服务区域绘制</div>
        <div ref={containerRef} style={{flex: 1, position: 'relative'}}>
          <BMap ref={mapRef} style={{height: '100%'}} enableScrollWheelZoom center={areaId ? '' : '上海市'} zoom={13}>
            {!pointList?.length && (
              <DrawingManager
                map={null}
                drawingToolOptions={{drawingModes: ['polygon'], customContainer: containerRef.current}}
                polygonOptions={{strokeColor: '#00B7AE'}}
                onOverlaycomplete={(e, info: any) => {
                  const path: PointModel[] = info.overlay.getPath()
                  info.overlay.map.removeOverlay(info.overlay)
                  if (path.length < 3) {
                    return message.warn('请设置三个点以上的区域！')
                  }
                  setPointList(path)
                }}
              />
            )}
            {!!pointList?.length && (
              <Polygon
                ref={ref}
                map={null}
                strokeColor={'#00B7AE'}
                enableEditing={enableEditing}
                path={pointList.map(value => new BMapGL.Point(value.lng, value.lat))}
              />
            )}
          </BMap>
        </div>
        <div style={{marginTop: 20}}>
          <Form
            form={form}
            initialValues={{areaId}}
            onFinish={async () => {
              const values = form.getFieldsValue(true)
              const path: any[] = ref.current?.overlay?.getPath?.()
              if (!path?.length) {
                return message.error('服务区不能为空')
              }
              const params = {...values, pointList: path}
              params.areaId
                ? await stewardModel.stewardCleanAreaUpdate(params)
                : await stewardModel.stewardCleanAreaInsert(params)
              message.success('操作成功')
              history.goBack()
            }}
          >
            <Form.Item
              label={'服务区域名称'}
              name={'areaName'}
              rules={[{required: true, message: '服务区域名称不能为空'}]}
            >
              <Input placeholder={'请输入'} style={{width: 210}} />
            </Form.Item>

            <Space>
              <Button type={'primary'} htmlType={'submit'}>
                提交
              </Button>
              <Button onClick={() => history.goBack()}>取消</Button>
            </Space>
          </Form>
        </div>
      </div>
    </Page>
  )
}

export default MapApiLoaderHOC({ak})(CleanServerAreaAdd)

const styles: {[key: string]: CSSProperties} = {
  container: {
    height: 'calc(100vh - 54px - 48px - 20px)',
    display: 'flex',
    flexDirection: 'column',
    padding: 30,
    backgroundColor: '#fff',
  },
}
