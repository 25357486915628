import React, {useCallback, useEffect, useState} from 'react'
import {
  Button,
  Col,
  DatePicker,
  Descriptions,
  Divider,
  Drawer,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Row,
  Select,
  Space,
  Switch,
  Table,
  Typography,
} from 'antd'
import DividerLine from '@/pages/property/component/DividerLine'
import {storageModel} from '@/store/models/Storage'
import {useCodeArraySync, useCodeSync} from '@/store/models/Common'
import {CopyOutlined} from '@ant-design/icons'
import styles from '@/pages/oa/PurchaseManagement/index.module.less'
import {Album, UploadButton} from '@/components/Album'
import {baseUrl} from '@/common/config'
import {stewardModel} from '@/store/models/Steward'
import {Permission} from '@/components/Permission'
import {getImg} from '@/utils/util'
import moment from 'moment/moment'
import {userModel} from '@/store/models/User'
import currency from 'currency.js'

interface PurchaseDetailProps {
  purchaseId: string
  onCancel: () => void
  personalRight: boolean
  updateList: () => void
}

export default function PurchaseDetail({purchaseId, onCancel, personalRight, updateList}: PurchaseDetailProps) {
  const userInfo = userModel.useData(data => data.userInfo)
  const [purchaseDetail, setPurchaseDetail] = useState({} as PurchaseVO)
  const [purchaseItems, setPurchaseItems] = useState<PurchaseItemVO[]>([])
  const [isDiff, setIsDiff] = useState(false)
  const detailLoading = storageModel.purchaseDetail.useLoading()
  const itemDetailLoading = storageModel.stewardAppPurchaseItemDetail.useLoading()
  const updateLoading = storageModel.purchaseUpdateOrder.useLoading()

  const queryPurchaseDetail = useCallback(() => {
    if (purchaseId) {
      storageModel.purchaseDetail({purchaseId}).then(({response: {data}}) => {
        setPurchaseDetail(data)
      })
    }
  }, [purchaseId])
  useEffect(() => {
    queryPurchaseDetail()
  }, [queryPurchaseDetail])

  // 采购
  const [showEdit, setShowEdit] = useState(false)
  const [operForm] = Form.useForm()
  const purchasePlatformCode = useCodeSync('purchase.platform')
  const purchasePlatformArray = useCodeArraySync('purchase.platform')

  // 采购详情
  const [purchaseItem, setPurchaseItem] = useState({} as PurchaseItemVO)

  // 退货
  const [returnForm] = Form.useForm()
  const [returnOpen, setReturnOpen] = useState<boolean>(false)

  // 采购状态码表
  const purchaseStatusCode = useCodeSync('purchase.status')

  // 撤回 & 批量撤回
  const [cancelForm] = Form.useForm()
  const cancelReasonArr = useCodeArraySync('purchase.item.cancel.reason')
  const cancelReason = useCodeSync('purchase.item.cancel.reason')
  function onPurchaseCancel(ids: string[]) {
    Modal.confirm({
      icon: null,
      title: '撤回',
      centered: true,
      width: '500px',
      content: (
        <Form form={cancelForm} labelCol={{span: 5}}>
          <Form.Item
            label={'撤回原因'}
            name={'cancelReason'}
            wrapperCol={{span: 12}}
            rules={[{required: true, message: '请选择撤回原因'}]}
          >
            <Select placeholder={'请选择'} allowClear>
              {cancelReasonArr.map(item => (
                <Select.Option value={item.id} key={item.id}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label={'附件'} shouldUpdate>
            {() => (
              <Album
                accept={'.jpg, .jpeg, .png'}
                action={baseUrl + '/steward/app/purchase/item/upload-img'}
                limit={1}
                onDone={fileList => {
                  cancelForm.setFieldsValue({cancelImg: fileList?.[0]?.imgUrl})
                }}
              >
                {!cancelForm.getFieldValue('cancelImg') ? <UploadButton /> : null}
              </Album>
            )}
          </Form.Item>
          <Form.Item
            label={'备注'}
            wrapperCol={{span: 18}}
            name={'cancelDesc'}
            rules={[
              () => ({
                validator: (_, value, callback) => {
                  const cancelReason = cancelForm.getFieldValue('cancelReason')
                  if (cancelReason === '5' && !value) {
                    callback('请填写备注')
                    return
                  }
                  callback()
                },
              }),
            ]}
          >
            <Input.TextArea placeholder='请输入备注' maxLength={200} allowClear rows={3} />
          </Form.Item>
        </Form>
      ),
      onOk: async () => {
        await cancelForm.validateFields()
        const {cancelDesc, cancelImg, cancelReason} = cancelForm.getFieldsValue(true)
        if (['4', '5'].includes(cancelReason) && !cancelImg) {
          message.warning('请上传附件')
          return Promise.reject()
        }

        for (const id of ids) {
          await stewardModel.stewardAppPurchaseItemCancel({cancelDesc, cancelImg, cancelReason, purchaseItemId: id})
        }
        message.success('撤回成功！')
        setPurchaseItems([])
        queryPurchaseDetail()
        updateList()
      },
      afterClose: () => cancelForm.resetFields(),
    })
  }

  // 收货 & 批量收货
  function onConfirmReceipt(args: {purchaseItemId: string; numActual: number}[]) {
    Modal.confirm({
      icon: null,
      title: '提示',
      content: '是否确认收货？',
      centered: true,
      onOk: async () => {
        for (let i = 0; i < args.length; i++) {
          const item = args[i]
          await storageModel.purchaseConfirmReceipt({
            purchaseItemId: item.purchaseItemId,
            operatorType: 1,
            numActual: item.numActual,
          } as PurchaseItemConfirmDTO)
        }
        message.success('确认收货成功！')
        setPurchaseItems([])
        queryPurchaseDetail()
        updateList()
      },
    })
  }

  return (
    <>
      <Drawer open={!!purchaseId} title={'采购详情'} width={1000} onClose={onCancel}>
        <DividerLine title={'基本信息'} bottom={15} />
        <Descriptions column={2}>
          <Descriptions.Item label={'采购ID'}>{purchaseDetail.purchaseId}</Descriptions.Item>
          {!personalRight && (
            <Descriptions.Item
              label={'申请人'}
            >{`${purchaseDetail.applicantName}（${purchaseDetail.applicantMobile}）`}</Descriptions.Item>
          )}
          <Descriptions.Item label={'采购状态'}>{purchaseStatusCode[purchaseDetail.purchaseStatus]}</Descriptions.Item>
          <Descriptions.Item label={'申请时间'}>{purchaseDetail.applyTime}</Descriptions.Item>
          <Descriptions.Item label={'采购地址'}>{purchaseDetail.warehouseName}</Descriptions.Item>
          <Descriptions.Item label={'关联服务单'}>{purchaseDetail.stewardOrderId ?? '/'}</Descriptions.Item>
        </Descriptions>
        <Descriptions column={1}>
          <Descriptions.Item label={'收货信息'}>
            <Space size={5}>
              <span>{`${purchaseDetail.consigneeName} ${purchaseDetail.consigneeMobile} ${purchaseDetail.warehouseName}`}</span>
              <CopyOutlined
                title={'点击复制'}
                onClick={() => {
                  navigator.clipboard.writeText(
                    `${purchaseDetail.consigneeName} ${purchaseDetail.consigneeMobile} ${purchaseDetail.warehouseName}`
                  )
                  message.success('已复制')
                }}
              />
            </Space>
          </Descriptions.Item>
        </Descriptions>
        <Divider dashed />
        <Row justify={'space-between'}>
          <Col>
            <DividerLine
              title={'采购物品'}
              bottom={20}
              right={
                !purchaseDetail?.itemList?.every(item => item.itemStatus === '01') && (
                  <Space style={{marginLeft: '20px'}}>
                    <Button
                      type={'link'}
                      style={{padding: 0}}
                      onClick={() => {
                        if (purchaseItems.length === 0) return message.warning('请选择撤回的物品')
                        if (purchaseItems.some(item => !['02', '03'].includes(item.itemStatus)))
                          return message.warning('请选择待采购/待收货的物品')
                        onPurchaseCancel(purchaseItems.map(item => item.purchaseItemId))
                      }}
                    >
                      批量撤回
                    </Button>
                    <Button
                      type={'link'}
                      style={{padding: 0}}
                      onClick={() => {
                        if (purchaseItems.length === 0) return message.warning('请选择收货的物品')
                        if (purchaseItems.some(item => item.itemStatus !== '03'))
                          return message.warning('请选择待收货的物品')
                        onConfirmReceipt(
                          purchaseItems.map(item => ({
                            purchaseItemId: item.purchaseItemId,
                            numActual: item.num - (item.returnNum ?? 0),
                          }))
                        )
                      }}
                    >
                      批量收货
                    </Button>
                  </Space>
                )
              }
            />
          </Col>
          <Col>
            {!personalRight && (
              <div className={styles.diff}>
                <span style={{marginRight: '8px'}}>采收数量有差异</span>
                <Switch checked={isDiff} onChange={checked => setIsDiff(checked)} />
              </div>
            )}
          </Col>
        </Row>
        <Table
          rowKey={'purchaseItemId'}
          rowSelection={{
            selectedRowKeys: purchaseItems.map(item => item.purchaseItemId),
            onChange: (_, selectedRows) => setPurchaseItems(selectedRows),
          }}
          loading={detailLoading}
          columns={[
            {
              title: 'SKU',
              dataIndex: 'sku',
              render: (sku, record) => (
                <Button
                  type={'text'}
                  onClick={async () => {
                    const {
                      response: {data},
                    } = await storageModel.stewardWarehouseMaterialDetail({materialId: record.materialId})

                    Modal.confirm({
                      title: '物品信息详情',
                      icon: null,
                      width: 500,
                      centered: true,
                      content: (
                        <>
                          <Row gutter={[0, 10]}>
                            <GoodsInfoItem label={'物品类型：'} value={data.categoryName} />
                            <GoodsInfoItem label={'物品名称：'} value={data.name} />
                            <GoodsInfoItem label={'SKU：'} value={data.sku} />
                            <GoodsInfoItem label={'单位：'} value={data.unit} />
                            <GoodsInfoItem label={'规格：'} value={data.specification} />
                            <GoodsInfoItem label={'品牌：'} value={data.brand} />
                            <GoodsInfoItem label={'参考价：'} value={data.price} />
                            <GoodsInfoItem
                              label={'常用购物链接：'}
                              value={
                                <a href={data.purchaseLink} target={'_blank'} rel={'noreferrer'}>
                                  {data.purchaseLink}
                                </a>
                              }
                            />
                            <Col span={24} style={{display: 'flex', flexDirection: 'row', marginBottom: '30px'}}>
                              <Typography.Text>附件：</Typography.Text>
                              {!!data.attach && (
                                <Album fileList={[{url: getImg(data.attach), uid: data.attach}]} disabled>
                                  {null}
                                </Album>
                              )}
                            </Col>
                            <GoodsInfoItem span={24} label={'备注：'} value={data.remark} />
                          </Row>
                        </>
                      ),
                    })
                  }}
                >
                  <Typography.Text underline>{sku}</Typography.Text>
                </Button>
              ),
            },
            {title: '名称', dataIndex: 'name'},
            {title: '订单号', dataIndex: 'purchaseOrderNo', render: text => text ?? '/'},
            {title: '状态', dataIndex: 'itemStatus', render: itemStatus => purchaseStatusCode[itemStatus]},
            {title: '采购数量', dataIndex: 'num'},
            {title: '实收数量', dataIndex: 'numActual', render: value => value ?? '/'},
            {title: '退货数量', dataIndex: 'returnNum', render: value => value ?? '/'},
            {title: '移动单价（元）', dataIndex: 'priceCost', render: value => value ?? '/'},
            {title: '采购渠道', dataIndex: 'online', render: online => (online === 1 ? '线上' : ' 线下')},
            {title: '规格', dataIndex: 'specification', render: specification => specification ?? '/'},
            {title: '品牌', dataIndex: 'brand', render: brand => brand ?? '/'},
            {title: '单位', dataIndex: 'unit'},
            {
              title: '操作',
              fixed: 'right',
              render: (_, record) => (
                <Space direction={'vertical'} size={0}>
                  {['02', '03'].includes(record.itemStatus) && (
                    <Permission code={record.online === 1 ? '002027002' : '002056002'}>
                      <Button
                        type={'link'}
                        onClick={() => {
                          operForm.setFieldsValue({...record, purchaserName: record.purchaserName || userInfo.name})
                          setShowEdit(true)
                        }}
                      >
                        {{'02': '采购', '03': '编辑采购'}[record.itemStatus]}
                      </Button>
                    </Permission>
                  )}
                  {record.itemStatus === '02' && (
                    <Button
                      type={'link'}
                      onClick={() => {
                        onPurchaseCancel([record.purchaseItemId])
                      }}
                    >
                      撤回
                    </Button>
                  )}
                  {record.itemStatus === '03' && (
                    <Button
                      type={'link'}
                      onClick={() => {
                        onConfirmReceipt([
                          {purchaseItemId: record.purchaseItemId, numActual: record.num - (record.returnNum ?? 0)},
                        ])
                      }}
                    >
                      确认收货
                    </Button>
                  )}
                  {['04'].includes(record.itemStatus) && record.returnAllowedNum > 0 && (
                    <Button
                      type={'link'}
                      onClick={() => {
                        setReturnOpen(true)
                        returnForm.setFieldsValue({...record, returnNum: undefined})
                      }}
                    >
                      退货
                    </Button>
                  )}
                  {['03', '04', '98'].includes(record.itemStatus) && (
                    <Button
                      type={'link'}
                      loading={itemDetailLoading}
                      onClick={async () => {
                        const {
                          response: {data},
                        } = await storageModel.stewardAppPurchaseItemDetail({purchaseItemId: record.purchaseItemId})
                        setPurchaseItem(data)
                      }}
                    >
                      查看
                    </Button>
                  )}
                </Space>
              ),
            },
          ]}
          scroll={{x: 'max-content'}}
          dataSource={purchaseDetail.itemList?.filter(item => {
            if (isDiff) {
              return item.itemStatus === '04' && item.numActual !== item.num
            } else return true
          })}
          pagination={false}
        />
      </Drawer>

      {/* 采购 */}
      <Modal
        title={'采购'}
        open={showEdit}
        centered
        footer={null}
        width={480}
        onCancel={() => {
          setShowEdit(false)
        }}
        destroyOnClose
        afterClose={() => {
          operForm.resetFields()
        }}
      >
        <Form
          form={operForm}
          onFinish={async () => {
            const {online, platformId, platformName, purchaseId, ...fieldsValue} = operForm.getFieldsValue([
              'num',
              'purchaseTime',
              'platformId',
              'online',
              'platformName',
              'priceReference',
              'purchaseAmt',
              'purchaseItemId',
              'purchaseOrderNo',
              'purchaseWaybill',
              'purchaseWaybillAmt',
              'purchaseId',
              'purchaseRemark',
            ])

            await new Promise<void>((resolve, reject) => {
              Modal.confirm({
                title: '确认采购',
                icon: null,
                closable: true,
                content: (
                  <Form>
                    <Form.Item label={'采购数量'}>{fieldsValue.num}个</Form.Item>
                    <Form.Item label={'订单号'}>{fieldsValue.purchaseOrderNo}</Form.Item>
                    <Form.Item label={'订单金额'}>{fieldsValue.purchaseAmt}元</Form.Item>
                  </Form>
                ),
                onOk: () => resolve(),
                afterClose: () => reject(),
              })
            })

            await storageModel.purchaseUpdateOrder({
              ...fieldsValue,
              platformId: online === 1 ? platformId : '99',
              platformName: online === 1 ? purchasePlatformCode[platformId] : platformName,
            })
            setShowEdit(false)
            message.success('提交成功！')
            queryPurchaseDetail()
          }}
          labelCol={{span: 6}}
          wrapperCol={{span: 14}}
        >
          <Form.Item label={'SKU'} name={'sku'}>
            <Input disabled />
          </Form.Item>
          <Form.Item label={'名称'} name={'name'}>
            <Input disabled />
          </Form.Item>
          <Form.Item label={'采购人'} name={'purchaserName'}>
            <Input disabled />
          </Form.Item>
          <Form.Item
            name={'purchaseTime'}
            getValueProps={value => ({value: value ? moment(value) : undefined})}
            getValueFromEvent={(_, dateSting) => dateSting}
            label={'采购时间'}
            rules={[{required: true, message: '请选择采购时间'}]}
          >
            <DatePicker showTime style={{width: '100%'}} />
          </Form.Item>
          {operForm.getFieldValue('online') === 1 ? (
            <Form.Item name={'platformId'} label={'采购平台'} rules={[{required: true, message: '请选择采购平台'}]}>
              <Select
                placeholder={'请选择平台'}
                options={purchasePlatformArray}
                fieldNames={{value: 'id'}}
                allowClear
              />
            </Form.Item>
          ) : (
            <Form.Item name={'platformName'} label={'供应商'}>
              <Input placeholder={'请输入供应商'} allowClear />
            </Form.Item>
          )}
          <Form.Item name={'num'} label={'采购数量'} rules={[{required: true, message: '请输入采购数量'}]}>
            <InputNumber min={1} disabled={!['02', '03'].includes(operForm.getFieldValue('itemStatus'))} />
          </Form.Item>
          <Form.Item name={'purchaseOrderNo'} label={'订单号'} rules={[{required: true, message: '请输入订单号'}]}>
            <Input placeholder={'请输入订单号'} allowClear />
          </Form.Item>
          <Form.Item
            name={'purchaseAmt'}
            label={'订单金额'}
            rules={[
              {required: true, message: '请输入订单金额'},
              () => ({
                async validator(_, value) {
                  const num = operForm.getFieldValue('num')
                  const materialId = operForm.getFieldValue('materialId')

                  const res = await storageModel.stewardWarehouseMaterialDetail({materialId})

                  const priceReference = res.response.data?.price

                  if (currency(value).divide(num).subtract(priceReference).value > 0) {
                    return Promise.reject(new Error('采购单价不能大于参考价'))
                  }

                  return Promise.resolve()
                },
              }),
            ]}
          >
            <InputNumber min={0} step={0.01} placeholder={'请输入订单金额'} addonAfter={'元'} />
          </Form.Item>
          <Form.Item name={'purchaseWaybill'} label={'运单号'}>
            <Input placeholder={'请输入运单号'} allowClear />
          </Form.Item>
          <Form.Item name={'purchaseWaybillAmt'} label={'运费'}>
            <InputNumber min={0} step={0.01} placeholder={'请输入运费'} addonAfter={'元'} />
          </Form.Item>
          <Form.Item name={'purchaseRemark'} label={'采购备注'}>
            <Input.TextArea placeholder={'请输入备注'} rows={3} maxLength={200} />
          </Form.Item>
          <Row justify={'end'} style={{marginTop: '40px'}}>
            <Space>
              <Button
                htmlType={'reset'}
                onClick={() => {
                  setShowEdit(false)
                  operForm.resetFields()
                }}
              >
                取消
              </Button>
              {operForm.getFieldValue('itemStatus') !== '04' && (
                <Button type={'primary'} htmlType={'submit'} loading={updateLoading}>
                  提交
                </Button>
              )}
            </Space>
          </Row>
        </Form>
      </Modal>

      {/* 采购详情 */}
      <Modal
        open={!!purchaseItem.purchaseItemId}
        title={'采购详情'}
        centered
        footer={null}
        destroyOnClose
        onCancel={() => setPurchaseItem({} as PurchaseItemVO)}
      >
        <DividerLine title='采购信息' bottom={20} />
        <Descriptions column={2}>
          <Descriptions.Item label={'SKU '}>{purchaseItem.sku}</Descriptions.Item>
          <Descriptions.Item label={'名称'}>{purchaseItem.name}</Descriptions.Item>
          <Descriptions.Item label={'采购人'}>{purchaseItem.purchaserName}</Descriptions.Item>
          <Descriptions.Item label={'采购时间'}>{purchaseItem.purchaseTime}</Descriptions.Item>
          <Descriptions.Item label={purchaseItem.online === 1 ? '采购平台' : '供应商'}>
            {purchaseItem.platformName}
          </Descriptions.Item>
          <Descriptions.Item label={'采购数量'}>{purchaseItem.num}</Descriptions.Item>
          <Descriptions.Item label={'订单号'}>{purchaseItem.purchaseOrderNo}</Descriptions.Item>
          <Descriptions.Item label={'订单金额'}>{purchaseItem.purchaseAmt}</Descriptions.Item>
          <Descriptions.Item label={'运单号'}>{purchaseItem.purchaseWaybill ?? '/'}</Descriptions.Item>
          <Descriptions.Item label={'运费'}>{purchaseItem.purchaseWaybillAmt}</Descriptions.Item>
          <Descriptions.Item label={'采购备注'}>{purchaseItem.purchaseRemark ?? '/'}</Descriptions.Item>
          {purchaseItem.itemStatus !== '98' && (
            <>
              <Descriptions.Item
                label={
                  purchaseItem.divisionTag === 1
                    ? '第一批实收数量'
                    : purchaseItem.purchaseItemIdOrigin
                    ? '第二批实收数量'
                    : '实收数量'
                }
              >
                {purchaseItem.numActual}
              </Descriptions.Item>
              <Descriptions.Item label={'收货时间'}>{purchaseItem.receiptTime}</Descriptions.Item>
              {!!purchaseItem.warehouseIdActual && purchaseItem.warehouseId !== purchaseItem.warehouseIdActual && (
                <Descriptions.Item label={'实收地址'}>{purchaseItem.warehouseNameActual}</Descriptions.Item>
              )}
              <Descriptions.Item label={'备注'}>{purchaseItem.remark ?? '/'}</Descriptions.Item>
              <Descriptions.Item label={'照片'}>
                {purchaseItem.imgList?.length ? (
                  <Album fileList={purchaseItem.imgList.map(item => ({uid: item, url: item}))} disabled>
                    {null}
                  </Album>
                ) : (
                  '/'
                )}
              </Descriptions.Item>
            </>
          )}
        </Descriptions>
        {purchaseItem.itemStatus === '98' && (
          <>
            <DividerLine title='撤销信息' bottom={20} />
            <Descriptions column={2}>
              <Descriptions.Item label={'撤回人'}>{purchaseItem.cancelName}</Descriptions.Item>
              <Descriptions.Item label={'操作时间'}>{purchaseItem.cancelTime}</Descriptions.Item>
              <Descriptions.Item label={'撤回原因'}>{cancelReason[purchaseItem.cancelReason]}</Descriptions.Item>
              <Descriptions.Item label={'备注'}>{purchaseItem.cancelDesc ?? '无'}</Descriptions.Item>
              <Descriptions.Item label={'图片'}>
                {purchaseItem.cancelImg ? (
                  <Album disabled fileList={[{url: getImg(purchaseItem.cancelImg), uid: purchaseItem.cancelImg}]}>
                    {null}
                  </Album>
                ) : (
                  '/'
                )}
              </Descriptions.Item>
            </Descriptions>
          </>
        )}
        {!!purchaseItem.returnInfoList?.length && (
          <>
            <DividerLine title='退货信息' bottom={20} />
            {purchaseItem.returnInfoList.map(info => (
              <Descriptions column={2} key={info.returnId}>
                <Descriptions.Item label={'退货人'}>{info.insertBy}</Descriptions.Item>
                <Descriptions.Item label={'退货时间'}>{info.returnTime}</Descriptions.Item>
                <Descriptions.Item label={'退货数量'}>{info.returnNum}</Descriptions.Item>
                <Descriptions.Item label={'退货金额（元）'}>{info.returnAmt}</Descriptions.Item>
                <Descriptions.Item label={'运费（元）'}>{info.freight}</Descriptions.Item>
                <Descriptions.Item label={'运单号'}>{info.waybillNumber}</Descriptions.Item>
                <Descriptions.Item label={'图片'}>
                  {info.fileUrl ? (
                    <Album disabled fileList={info.fileUrl.split(',').map(img => ({url: getImg(img), uid: img}))}>
                      {null}
                    </Album>
                  ) : (
                    '/'
                  )}
                </Descriptions.Item>
              </Descriptions>
            ))}
          </>
        )}
      </Modal>

      {/* 退货 */}
      <Modal
        title={'退货'}
        centered
        destroyOnClose
        width={500}
        open={returnOpen}
        onCancel={() => setReturnOpen(false)}
        afterClose={() => returnForm.resetFields()}
        onOk={() => returnForm.submit()}
      >
        <Form
          form={returnForm}
          onFinish={async () => {
            const {itemStatus, price, returnAmt, fileList, ...formValues} = returnForm.getFieldsValue([
              'freight',
              'purchaseId',
              'purchaseItemId',
              'returnAmt',
              'returnId',
              'returnNum',
              'returnTime',
              'waybillNumber',
              'itemStatus',
              'fileList',
              'price',
            ])
            if (returnAmt !== price) {
              Modal.confirm({
                title: '提示',
                icon: null,
                centered: true,
                content: '当前退货单价与采购单价不一致，是否确认退货。',
                onOk: async () => {
                  await storageModel.purchaseItemReturn({
                    ...formValues,
                    returnType: itemStatus === '04' ? 1 : 0,
                    returnAmt,
                    fileUrl: fileList?.join(','),
                  })
                  setReturnOpen(false)
                  queryPurchaseDetail()
                },
              })
            } else {
              await storageModel.purchaseItemReturn({
                ...formValues,
                returnType: itemStatus === '04' ? 1 : 0,
                returnAmt,
                fileUrl: fileList?.join(','),
              })
              setReturnOpen(false)
              queryPurchaseDetail()
            }
          }}
          labelCol={{flex: '120px'}}
          wrapperCol={{flex: '300px'}}
        >
          <Form.Item label={'SKU'}>{returnForm.getFieldValue('sku')}</Form.Item>
          <Form.Item label={'名称'}>{returnForm.getFieldValue('name')}</Form.Item>
          <Form.Item label={'可退货数量'}>{returnForm.getFieldValue('returnAllowedNum')}</Form.Item>
          <Form.Item
            label={'退货时间'}
            name={'returnTime'}
            getValueProps={value => ({value: value ? moment(value) : undefined})}
            getValueFromEvent={(_, dateSting) => dateSting}
            rules={[{required: true, message: '请选择退货时间'}]}
          >
            <DatePicker showTime style={{width: '100%'}} />
          </Form.Item>
          <Form.Item
            label={'退货数量'}
            name={'returnNum'}
            rules={[
              {required: true, message: '请选择退货数量'},
              ({getFieldValue}) => ({
                validator(_, value) {
                  const returnAllowedNum = getFieldValue('returnAllowedNum')
                  if (value && value > returnAllowedNum) {
                    return Promise.reject(new Error('退货数量大于当前可退货数量，不可退!'))
                  }
                  return Promise.resolve()
                },
              }),
            ]}
          >
            <InputNumber
              min={1}
              step={1}
              placeholder={'请输入'}
              onChange={value => {
                if (value > 0) {
                  const {returnNum, purchaseAmt, num} = returnForm.getFieldsValue(['returnNum', 'purchaseAmt', 'num'])
                  const price = (purchaseAmt / num) * returnNum
                  returnForm.setFieldsValue({price: +price.toFixed(2)})
                }
              }}
              style={{width: '100%'}}
            />
          </Form.Item>
          <Form.Item label={'退货金额'} name={'returnAmt'} rules={[{required: true, message: '请选择退货金额'}]}>
            <InputNumber min={0} step={0.01} placeholder={'请输入'} style={{width: '100%'}} />
          </Form.Item>
          <Form.Item noStyle shouldUpdate={(prev, next) => prev.returnNum !== next.returnNum}>
            {f => {
              const {returnNum, price} = f.getFieldsValue(['returnNum', 'price'])
              if (returnNum > 0) {
                return (
                  <Form.Item label={' '} colon={false}>
                    <span>{`预计退款：${price}元`}</span>
                  </Form.Item>
                )
              }
            }}
          </Form.Item>
          <Form.Item label={'运费'} name={'freight'}>
            <InputNumber min={0} step={0.01} placeholder={'请输入'} style={{width: '100%'}} />
          </Form.Item>
          <Form.Item label={'运单号'} name={'waybillNumber'}>
            <Input placeholder={'请输入'} />
          </Form.Item>
          <Form.Item label={'附件'} shouldUpdate>
            {() => (
              <Album
                accept={'.jpg, .jpeg, .png'}
                action={baseUrl + '/steward/app/purchase/item/upload-img'}
                limit={3}
                onDone={fileList => {
                  returnForm.setFieldsValue({fileList: fileList?.map(item => item.imgUrl)})
                }}
              >
                <UploadButton />
              </Album>
            )}
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

const GoodsInfoItem = React.memo(function (args: {
  label: string
  value: React.ReactNode | string | number
  span?: number
}) {
  const {label, value, span = 12} = args
  return (
    <Col span={span} style={{marginBottom: '30px'}}>
      <Typography.Text>{label}</Typography.Text>
      {React.isValidElement(value) ? value : <Typography.Text>{value || '/'}</Typography.Text>}
    </Col>
  )
})
