import React, {useCallback, useEffect, useState} from 'react'
import {useAppHistory, useAppLocation} from '@/common/hooks/useRouter'
import {cleanModel} from '@/store/models/Clean'
import {Page} from '@/components/Page'
import {
  Button,
  Col,
  Collapse,
  Descriptions,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  PageHeader,
  Popover,
  Radio,
  Row,
  Select,
  Space,
  Steps,
  Table,
  Tabs,
  Tag,
  Upload,
} from 'antd'
import DividerLine from '@/pages/property/component/DividerLine'
import {useCodeSync} from '@/store/models/Common'
import {useCleanOrderCancel} from '@/pages/cleanManagement/hooks/useCleanOrderCancel'
import {useCleanOrderAssign} from '@/pages/cleanManagement/hooks/useCleanOrderAssign'
import {STEWARD_SERVICE_THI_4, STEWARD_SERVICE_THI_5, STEWARD_SERVICE_THI_6} from '@/utils/clean'
import {useCreateOfflineQa} from '@/pages/cleanManagement/hooks/useCreateOfflineQa'
import {Album, UploadButton} from '@/components/Album'
import {baseUrl, serverUrl} from '@/common/config'
import {debounce, getImg} from '@/utils/util'
import {sysUserModel} from '@/store/models/SysUser'
import {stewardModel} from '@/store/models/Steward'
import {DownOutlined, UpOutlined} from '@ant-design/icons'
import {DetailSectionImage} from '@/pages/steward/StewardDetail'
import QRCodeSVG from 'qrcode.react'
import {useCleanOrderCharge} from '@/pages/cleanManagement/hooks/useCleanOrderCharge'
import {useCleanOnlineQualified, useCleanPreview} from '@/pages/cleanManagement/hooks/useCleanOrderPreview'
// import {useCreateOnlineQa} from '@/pages/cleanManagement/hooks/useCreateOnlineQa'
import {onAlbumUploadEvent, onAlbumUploadProps} from '@/pages/service/utils/albumUpload'
import {useCleanOrderRework} from '@/pages/cleanManagement/hooks/useCleanOrderRework'
import moment from 'moment'
import * as listener from '@/common/hooks/listener'

const saveTypeCode1 = [1, 2]
const saveTypeCode2 = {'1': '保洁前', '2': '保洁后'}
const orderMakerTypeCode = {lessee: '租客', lessor: '房东'}

// 根据菜单状态显示的服务单详情。
const entryCode = {
  1: '待派单',
  2: '已派单',
  3: '已完成',
  4: '待确认',
  5: '已确认',
  6: '现场质检',
  7: '已取消',
  8: '保洁质检',
}

export default function CleanServiceDetail() {
  const history = useAppHistory()
  const location = useAppLocation<'/clean-detail'>()
  const orderId = location.state?.orderId
  const entry = location.state?.entry

  const cleanOrderStatusCode = useCodeSync('clean.order.status')

  const cleanOrderCancel = useCleanOrderCancel()
  const cleanOrderAssignDispatch = useCleanOrderAssign()
  const cleanOrderAssignTransfer = useCleanOrderAssign('transfer')
  const createOfflineQa = useCreateOfflineQa()
  const cleanOrderCharge = useCleanOrderCharge()
  const cleanPreview = useCleanPreview()
  const cleanOnlineQualified = useCleanOnlineQualified()
  // const createOnlineQa = useCreateOnlineQa()
  const cleanOrderRework = useCleanOrderRework()

  const [collapseActiveKeys, setCollapseActiveKeys] = useState<string[]>([])

  // 上传表单
  const [form] = Form.useForm()
  // 上传步骤
  const [step, setStep] = useState(0)
  // 是否显示上传表单
  const [visible, setVisible] = useState(false)
  // 是否是周期保洁
  const [isPeriodClean, setIsPeriodClean] = useState(false)

  const [typeForm] = Form.useForm()

  // 订单详情
  const [orderDetail, setOrderDetail] = useState({} as CleanOrderVO)

  const queryCleanOrder = useCallback(async () => {
    if (orderId) {
      const {
        response: {data},
      } = await cleanModel.cleanOrderGet({orderId})
      setOrderDetail(data)
      form.setFieldsValue({_config: data?.payloadList})
      setIsPeriodClean(
        [STEWARD_SERVICE_THI_4, STEWARD_SERVICE_THI_5, STEWARD_SERVICE_THI_6].includes(data?.categoryThi)
      )
    }
  }, [form, orderId])

  useEffect(() => {
    queryCleanOrder()
  }, [queryCleanOrder])

  useEffect(() => {
    if (orderDetail.orderId) {
      const timeNodes = [
        {key: '3', timeKey: 'checkTime', timeValue: orderDetail.checkTime},
        ...(orderDetail.assignList || []).map((item, index) => ({
          key: `5-${index}`,
          timeKey: 'assignTime',
          timeValue: item.assignTime,
        })),
        {key: '6', timeKey: 'finishTime', timeValue: orderDetail.finishTime},
        {key: '7', timeKey: 'qaOnlineTime', timeValue: orderDetail.qaOnlineTime},
        {key: '8', timeKey: 'amtCheckTime', timeValue: orderDetail.amtCheckTime},
        {key: '9', timeKey: 'qaOfflineAssignTime', timeValue: orderDetail.qaOfflineAssignTime},
        {key: '10', timeKey: 'qaOfflineFinishTime', timeValue: orderDetail.qaOfflineFinishTime},
        {key: '11', timeKey: 'remarkTime', timeValue: orderDetail.remarkTime},
        {key: '12', timeKey: 'cancelTime', timeValue: orderDetail.cancelTime},
      ]
      const sortNodes = timeNodes
        .filter(item => !!item.timeValue)
        .sort((value1, value2) => new Date(value2.timeValue).getTime() - new Date(value1.timeValue).getTime())
      if (sortNodes.length) {
        setCollapseActiveKeys([sortNodes[0].key])
      } else setCollapseActiveKeys(['2'])
      // console.log('sortNodes', sortNodes)
    }
  }, [orderDetail])

  const [houseType, setHouseType] = useState('')

  const queryCleanHouseType = useCallback(
    async (confirm?: boolean) => {
      if (orderDetail.propertyCode && orderDetail.categoryThi) {
        const {
          response: {data},
        } = await cleanModel.cleanOrderGetCleanHouseType({
          propertyCode: orderDetail.propertyCode,
          serviceId: orderDetail.categoryThi,
        })
        if (data) {
          if (confirm) {
            typeForm.setFieldsValue({
              bathroom: data.bathroom,
              bedroom: data.bedroom,
              kitchen: data.kitchen,
              parlor: data.parlor,
              propertyCode: data.propertyCode,
              propertyType: data.propertyType,
              orderId,
            })
          } else
            setHouseType(
              [
                data.propertyType && `${data.propertyType === '1' ? '平层' : '复式'}`,
                data.bedroom && `${data.bedroom}室`,
                data.parlor && `${data.parlor}厅`,
                data.kitchen && `${data.kitchen}厨`,
                data.bathroom && `${data.bathroom}卫`,
              ]
                .filter(item => item)
                .join('')
            )
        }
      }
    },
    [orderDetail.categoryThi, orderDetail.propertyCode, orderId, typeForm]
  )

  useEffect(() => {
    queryCleanHouseType()
  }, [queryCleanHouseType])

  // 查用户
  const searchServerList = debounce((name: string) => {
    sysUserModel
      .sysUserList({name, status: '1', pageNum: 1, pageSize: 10, pageable: false, roleId: 'cleaner_manager,cleaner'})
      .then(({response: {data}}) => form.setFieldsValue({_userList: data}))
  }, 150)

  // 计算垃圾清运提成
  const calculateGarbageCommission = debounce(value => {
    cleanModel.cleanOrderCalculateGarbageCommission({garbagePkgNum: value}).then(({response: {data}}) => {
      form.setFieldsValue({_garbageCommission: data})
    })
  }, 150)

  const [chargeList, setChargeList] = useState<StewardServiceChargeItemVO[]>([])

  const queryServiceChargeList = useCallback(() => {
    if (orderId) {
      stewardModel
        .stewardServiceChargeList({categoryId: '1', chargeType: '2', orderId, categoryThi: ''})
        .then(({response: {data}}) => setChargeList(data))
    }
  }, [orderId])

  const calculateServiceCommission = useCallback(
    value => {
      if (value) {
        const item = chargeList.find(item => item.id === value)
        const payItemList = [{chargeAmt: item?.chargeAmt, chargeId: item?.chargeId, itemNum: 1}]
        cleanModel
          .cleanOrderCalculateServiceCommission({cityCode: orderDetail.cityCode, payItemList})
          .then(({response: {data}}) => form.setFieldsValue({_serviceCommission: data, payItemList}))
      }
    },
    [chargeList, form, orderDetail.cityCode]
  )

  const renderOperation = [
    {
      when: ['1', '2'].includes(entry),
      title: '取消服务',
      onClick: () =>
        cleanOrderCancel({
          orderId,
          onSuccess: () => {
            listener.emit('useCleanOrderList')
            history.goBack()
          },
        }),
    },
    {
      when: ['2'].includes(entry),
      title: '定位更新',
      onClick: async () => {
        await stewardModel.stewardAdminOrderUpdateLocation({orderId})
        message.success('服务单信息已更新')
        queryCleanOrder()
      },
    },
    {
      when: ['2'].includes(entry),
      title: '转单',
      onClick: () =>
        cleanOrderAssignTransfer({
          orderIdList: [orderId],
          onSuccess: () => {
            history.goBack()
            listener.emit('useCleanOrderList')
          },
        }),
    },
    {
      when: ['2'].includes(entry),
      title: '保洁完成',
      onClick: async () => {
        // 1.有没有上传过照片，如果上传过，走原来的流程；如果没上传过，确认流程弹框
        await queryCleanOrder()
        const payloadList = form.getFieldValue('_config')
        if (
          payloadList.some(item => item.mediaList?.some(ele => ele.imageList?.length || ele.videoList?.length)) ||
          payloadList.some(item =>
            item.subList?.some(value => value.mediaList?.some(ele => ele.imageList?.length || ele.videoList?.length))
          )
        ) {
          queryServiceChargeList()
          searchServerList('')
          setVisible(true)
        } else {
          await queryCleanHouseType(true)
          Modal.confirm({
            title: '保洁区域',
            icon: null,
            closable: true,
            centered: true,
            onOk: async () => {
              const formValues = typeForm.getFieldsValue(true)
              await cleanModel.cleanOrderSetCleanHouseType(formValues)
              await queryCleanOrder()
              queryServiceChargeList()
              searchServerList('')
              setVisible(true)
            },
            content: (
              <Form form={typeForm} labelCol={{span: 6}} labelAlign={'right'} style={{marginTop: '24px'}}>
                <Form.Item noStyle shouldUpdate>
                  {form1 => {
                    const {bathroom, bedroom, kitchen, parlor} = form1.getFieldsValue(true)
                    return (
                      <>
                        <Form.Item
                          name={'propertyType'}
                          label={'房型结构'}
                          rules={[{required: true, message: '请选择房型结构'}]}
                        >
                          <Radio.Group>
                            <Radio value={'1'}>平层</Radio>
                            <Radio value={'2'}>复式</Radio>
                          </Radio.Group>
                        </Form.Item>
                        {![null, undefined].includes(bedroom) && (
                          <Form.Item label={'室'} name={'bedroom'} getValueFromEvent={value => value || 0}>
                            <InputNumber min={0} style={{width: '100%'}} />
                          </Form.Item>
                        )}
                        {![null, undefined].includes(parlor) && (
                          <Form.Item label={'厅'} name={'parlor'} getValueFromEvent={value => value || 0}>
                            <InputNumber min={0} style={{width: '100%'}} />
                          </Form.Item>
                        )}
                        {![null, undefined].includes(kitchen) && (
                          <Form.Item label={'厨'} name={'kitchen'} getValueFromEvent={value => value || 0}>
                            <InputNumber min={0} style={{width: '100%'}} />
                          </Form.Item>
                        )}
                        {![null, undefined].includes(bathroom) && (
                          <Form.Item label={'卫'} name={'bathroom'} getValueFromEvent={value => value || 0}>
                            <InputNumber min={0} style={{width: '100%'}} />
                          </Form.Item>
                        )}
                      </>
                    )
                  }}
                </Form.Item>
              </Form>
            ),
          })
        }
      },
    },
    {
      when: ['3'].includes(entry),
      title: '分享',
      onClick: null,
    },
    {
      when: ['3'].includes(entry),
      title: '返工',
      onClick: () => {
        if (orderDetail.orderIdRework)
          return message.warning(`当前保洁单已经创建过返工单，单号：${orderDetail.orderIdRework}`)
        cleanOrderRework({...orderDetail, onSuccess: () => queryCleanOrder()})
      },
    },
    {
      when: ['2', '3'].includes(entry),
      title: '现场质检派单',
      onClick: () => {
        if (orderDetail.qaOfflineAssignTime) return message.warning('当前保洁单已派单现场质检')
        createOfflineQa({orderId})
      },
    },
    {
      when: ['1'].includes(entry),
      title: '派单',
      onClick: () =>
        cleanOrderAssignDispatch({
          orderIdList: [orderId],
          customerId: orderDetail.customerId,
          onSuccess: () => {
            history.goBack()
            listener.emit('useCleanOrderList')
          },
        }),
    },
    {
      when: ['4', '5'].includes(entry),
      title: '扣款记录',
      onClick: async () => {
        const {
          response: {data, total},
        } = await cleanModel.cleanOrderWalletRecord({
          pageable: false,
          pageNum: 1,
          pageSize: 1,
          propertyCode: orderDetail.propertyCode,
        } as CleanOrderWalletRecordListDTO)
        Modal.info({
          title: '扣款记录',
          width: 800,
          content: (
            <Table
              dataSource={data}
              rowKey={'orderId'}
              columns={[
                {title: '扣款时间', dataIndex: 'amtCheckTime'},
                {title: '扣款金额', dataIndex: 'amtCheck', render: value => value?.toFixed(2)},
                {title: '扣款人', dataIndex: 'amtCheckOperator'},
                {title: '服务单号', dataIndex: 'orderId'},
                {
                  title: '服务收费提成',
                  dataIndex: 'commissionService',
                  render: value => value?.toFixed(2),
                },
              ]}
              pagination={{total, position: ['bottomCenter'], defaultPageSize: 10, defaultCurrent: 1}}
            />
          ),
          cancelText: '关闭',
          icon: null,
          centered: true,
        })
      },
      // cleanOrderCharge({
      //   detail: orderDetail,
      //   onSuccess: () => {
      //     history.goBack()
      //     listener.emit('useCleanOrderList')
      //   },
      // }),
    },
    {
      when: ['4'].includes(entry),
      title: '确认扣款',
      onClick: () =>
        cleanOrderCharge({
          detail: orderDetail,
          onSuccess: () => {
            history.goBack()
            listener.emit('useCleanOrderList')
          },
        }),
    },
    {
      when: ['8'].includes(entry),
      title: '线上质检',
      onClick: () => {
        message.warning('请前往待办完成线上质检!')
        // createOnlineQa({
        //   payloadList: orderDetail.payloadList,
        //   orderId,
        //   onSuccess: () => {
        //     history.goBack()
        //     listener.emit('useCleanOrderList')
        //   },
        // })
      },
    },
  ]

  const saveLoading = cleanModel.cleanOrderSaveFile.useLoading()
  const finishLoading = cleanModel.cleanOrderFinish.useLoading()

  if (!orderDetail.orderId) return null

  return (
    <Page
      header={
        <PageHeader
          title={entryCode[entry]}
          ghost={false}
          onBack={() => history.goBack()}
          extra={
            <Space>
              {renderOperation
                .filter(item => item.when)
                .map(item =>
                  item.title === '分享' ? (
                    <Popover
                      content={
                        <div style={{textAlign: 'center'}}>
                          <QRCodeSVG value={`${serverUrl}/web/clean.html?orderId=${orderId}`} />
                          <div>仅限使用企业微信扫一扫</div>
                        </div>
                      }
                      key={item.title}
                    >
                      <Button>{item.title}</Button>
                    </Popover>
                  ) : (
                    <Button key={item.title} onClick={item.onClick}>
                      {item.title}
                    </Button>
                  )
                )}
            </Space>
          }
        />
      }
    >
      <div style={{padding: '30px 15px', backgroundColor: 'white'}}>
        <Collapse
          expandIcon={() => null}
          ghost
          activeKey={collapseActiveKeys}
          onChange={key => setCollapseActiveKeys(typeof key === 'string' ? [key] : key)}
        >
          <Collapse.Panel
            header={
              <DividerLine
                title={'服务单信息'}
                right={
                  <Space>
                    <span style={{fontSize: '14px', fontWeight: 'normal'}}>
                      {!!orderDetail.insertTime && `（${orderDetail.insertTime}）`}
                    </span>
                    {collapseActiveKeys.includes('1') ? (
                      <UpOutlined style={{color: '#00B7AE'}} />
                    ) : (
                      <DownOutlined style={{color: '#00B7AE'}} />
                    )}
                  </Space>
                }
              />
            }
            key='1'
            showArrow={false}
          >
            <Descriptions title={null} column={2}>
              <Descriptions.Item label={'订单编号'}>{orderDetail.orderId}</Descriptions.Item>
              <Descriptions.Item label={'下单时间'}>{orderDetail.insertTime}</Descriptions.Item>
              <Descriptions.Item label={'下单人'}>
                {orderDetail.orderMakerName}（{orderMakerTypeCode[orderDetail.orderMakerType]}）（
                {orderDetail.orderMakerMobile}）
              </Descriptions.Item>
              <Descriptions.Item label={'订单状态'}>{cleanOrderStatusCode[orderDetail.orderStatus]}</Descriptions.Item>
              <Descriptions.Item label={'服务联系手机号'}>{orderDetail.contactMobile}</Descriptions.Item>
              {orderDetail.orderType === '2' && (
                <Descriptions.Item label={'返工单原单'}>{orderDetail.orderIdOrigin}</Descriptions.Item>
              )}
            </Descriptions>
          </Collapse.Panel>

          {/** 订单信息 */}
          <Collapse.Panel
            header={
              <DividerLine
                title={'订单信息'}
                right={
                  <Space>
                    <span style={{fontSize: '14px', fontWeight: 'normal'}}>
                      {!!orderDetail.insertTime && `（${orderDetail.insertTime}）`}
                    </span>
                    {collapseActiveKeys.includes('2') ? (
                      <UpOutlined style={{color: '#00B7AE'}} />
                    ) : (
                      <DownOutlined style={{color: '#00B7AE'}} />
                    )}
                  </Space>
                }
              />
            }
            key='2'
            showArrow={false}
          >
            <Descriptions title={null} column={2}>
              <Descriptions.Item label={'房源地址'}>{orderDetail.propertyAddr}</Descriptions.Item>
              <Descriptions.Item label={'房东'}>{orderDetail.customerName}</Descriptions.Item>
              <Descriptions.Item label={'详细地址'}>{orderDetail.communityAddress}</Descriptions.Item>
              <Descriptions.Item label={'需求描述'}>{orderDetail.orderDesc}</Descriptions.Item>
              <Descriptions.Item label={'所属团队'}>{orderDetail.teamName}</Descriptions.Item>
              <Descriptions.Item label={'服务项目'}>
                <Space>
                  <span>
                    {[orderDetail.categoryFirName, orderDetail.categorySecName, orderDetail.categoryThiName]
                      .filter(item => item)
                      .join('-')}
                  </span>
                  {!!orderDetail.orderIdOrigin && <Tag color={'blue'}>返</Tag>}
                </Space>
              </Descriptions.Item>
              <Descriptions.Item label={'合同类型'}>{orderDetail.saleContractType}</Descriptions.Item>
              <Descriptions.Item label={'物品清理'}>{orderDetail.orderTags}</Descriptions.Item>
              <Descriptions.Item label={'图片'}>
                <DetailSectionImage
                  imgList={orderDetail.mediaList}
                  onClickVideo={item =>
                    Modal.confirm({
                      title: '视频预览',
                      icon: null,
                      closable: true,
                      centered: true,
                      content: <video controls src={getImg(item.imgUrl)} width='100%' />,
                    })
                  }
                />
              </Descriptions.Item>
            </Descriptions>
          </Collapse.Panel>

          {/* 确认信息:周期保洁单不用确认 */}
          {orderDetail.categoryThi !== STEWARD_SERVICE_THI_6 && (
            <Collapse.Panel
              header={
                <DividerLine
                  title={'确认信息'}
                  right={
                    <Space>
                      <span style={{fontSize: '14px', fontWeight: 'normal'}}>
                        {!!orderDetail.checkTime && `（${orderDetail.checkTime}）`}
                      </span>
                      {collapseActiveKeys.includes('3') ? (
                        <UpOutlined style={{color: '#00B7AE'}} />
                      ) : (
                        <DownOutlined style={{color: '#00B7AE'}} />
                      )}
                    </Space>
                  }
                />
              }
              key='3'
              showArrow={false}
            >
              <Descriptions title={null} column={2}>
                <Descriptions.Item label={'户型'}>{orderDetail.checkHouseType}</Descriptions.Item>
                <Descriptions.Item label={'客户特殊需求'}>{orderDetail.checkSpecialNotes}</Descriptions.Item>
                <Descriptions.Item label={'确认人'}>{orderDetail.checkOperator}</Descriptions.Item>
                <Descriptions.Item label={'确认时间'}>{orderDetail.checkTime}</Descriptions.Item>
              </Descriptions>
            </Collapse.Panel>
          )}

          {/*派单信息*/}
          {orderDetail.assignList?.map((item, index) => (
            <Collapse.Panel
              header={
                <DividerLine
                  title={'派单信息'}
                  right={
                    <Space>
                      <span style={{fontSize: '14px', fontWeight: 'normal'}}>
                        {!!item.assignTime && `（${item.assignTime}）`}
                      </span>
                      {collapseActiveKeys.includes(`5-${index}`) ? (
                        <UpOutlined style={{color: '#00B7AE'}} />
                      ) : (
                        <DownOutlined style={{color: '#00B7AE'}} />
                      )}
                    </Space>
                  }
                />
              }
              key={`5-${index}`}
              showArrow={false}
            >
              <Descriptions title={null} column={2}>
                <Descriptions.Item label={'转单人'}>{item.assignOperator}</Descriptions.Item>
                <Descriptions.Item label={'转单时间'}>{item.assignTime}</Descriptions.Item>
                <Descriptions.Item label={'保洁员'}>{item.serverName}</Descriptions.Item>
                <Descriptions.Item label={'约定上门时间'}>
                  {[
                    moment(item.appointTimeBegin)?.format('YYYY-MM-DD HH:mm'),
                    moment(item.appointTimeEnd)?.format('YYYY-MM-DD HH:mm')?.slice(-5),
                  ]
                    .filter(value => value)
                    .join('-')}
                </Descriptions.Item>
                {!!item.assignNotes && <Descriptions.Item label={'转单原因'}>{item.assignNotes}</Descriptions.Item>}
              </Descriptions>
            </Collapse.Panel>
          ))}

          {/* 保洁信息 */}
          {!!orderDetail.finishTime && entry !== '1' && (
            <Collapse.Panel
              header={
                <DividerLine
                  title={'保洁信息'}
                  right={
                    <Space>
                      <span style={{fontSize: '14px', fontWeight: 'normal'}}>{`（${orderDetail.finishTime}）`}</span>
                      {collapseActiveKeys.includes('6') ? (
                        <UpOutlined style={{color: '#00B7AE'}} />
                      ) : (
                        <DownOutlined style={{color: '#00B7AE'}} />
                      )}
                    </Space>
                  }
                />
              }
              key='6'
              showArrow={false}
            >
              <Descriptions title={null} column={2}>
                <Descriptions.Item label={'保洁开始时间'}>{orderDetail.startTime}</Descriptions.Item>
                <Descriptions.Item label={'保洁完成时间'}>{orderDetail.finishTime}</Descriptions.Item>
                <Descriptions.Item label={'保洁员'}>{orderDetail.serverName}</Descriptions.Item>
                {entry === '3' && (
                  <Descriptions.Item label={'所在组织'}>{orderDetail.serviceOrgName}</Descriptions.Item>
                )}
                <Descriptions.Item label={'操作人'}>{orderDetail.finishOperator}</Descriptions.Item>
                <Descriptions.Item
                  label={'保洁前后照片/视频'}
                  style={{alignItems: 'baseline'}}
                  contentStyle={{alignItems: 'baseline'}}
                >
                  <Button
                    type={'link'}
                    style={{padding: 0, height: 'auto'}}
                    onClick={() => cleanPreview({payloadList: orderDetail.payloadList})}
                  >
                    <span style={{textDecoration: 'underline'}}>查看详情</span>
                  </Button>
                </Descriptions.Item>
                <Descriptions.Item label={'垃圾清运（包）'}>{orderDetail.garbagePkgNum}</Descriptions.Item>
                <Descriptions.Item label={'收费服务'}>{orderDetail.payItemList?.[0]?.chargeName}</Descriptions.Item>
                <Descriptions.Item label={'保洁区域'}>{houseType}</Descriptions.Item>
                <Descriptions.Item label={'保洁备注'}>{orderDetail.finishNotes}</Descriptions.Item>
              </Descriptions>
            </Collapse.Panel>
          )}

          {/* 线上质检 */}
          {!!orderDetail.qaOnlineTime && (
            <Collapse.Panel
              header={
                <DividerLine
                  title={'线上质检'}
                  right={
                    <Space>
                      <span style={{fontSize: '14px', fontWeight: 'normal'}}>{`（${orderDetail.qaOnlineTime}）`}</span>
                      {collapseActiveKeys.includes('7') ? (
                        <UpOutlined style={{color: '#00B7AE'}} />
                      ) : (
                        <DownOutlined style={{color: '#00B7AE'}} />
                      )}
                    </Space>
                  }
                />
              }
              key={`7`}
              showArrow={false}
            >
              <Descriptions title={null} column={2}>
                <Descriptions.Item label={'线上质检员'}>{orderDetail.qaOnlineOperator}</Descriptions.Item>
                <Descriptions.Item label={'线上质检完成时间'}>{orderDetail.qaOnlineTime}</Descriptions.Item>
                <Descriptions.Item label={'线上质检结果'}>
                  <Button
                    type={'link'}
                    style={{padding: 0, height: 'auto'}}
                    onClick={() => cleanOnlineQualified({payloadList: orderDetail.payloadList, isOnline: true})}
                  >
                    <span style={{textDecoration: 'underline'}}>查看质检报告</span>
                  </Button>
                </Descriptions.Item>
                <Descriptions.Item label={'线上质检合格率'}>
                  {![null, undefined].includes(orderDetail.qaOnlineRate) && +orderDetail.qaOnlineRate * 100 + '%'}
                </Descriptions.Item>
              </Descriptions>
            </Collapse.Panel>
          )}

          {/* 现场质检安排 */}
          {!!orderDetail.qaOfflineAssignTime && entry !== '1' && (
            <Collapse.Panel
              header={
                <DividerLine
                  title={'现场质检安排'}
                  right={
                    <Space>
                      <span style={{fontSize: '14px', fontWeight: 'normal'}}>
                        {`（${orderDetail.qaOfflineAssignTime}）`}
                      </span>
                      {collapseActiveKeys.includes('9') ? (
                        <UpOutlined style={{color: '#00B7AE'}} />
                      ) : (
                        <DownOutlined style={{color: '#00B7AE'}} />
                      )}
                    </Space>
                  }
                />
              }
              key={`9`}
              showArrow={false}
            >
              <Descriptions title={null} column={2}>
                <Descriptions.Item label={'现场质检安排人'}>{orderDetail.qaOfflineAssignOperator}</Descriptions.Item>
                <Descriptions.Item label={'现场质检安排时间'}>{orderDetail.qaOfflineAssignTime}</Descriptions.Item>
                <Descriptions.Item label={'现场质检员'}>{orderDetail.qaOfflineServerName}</Descriptions.Item>
                <Descriptions.Item label={'期望完成日期'}>{orderDetail.qaOfflineAssignDate}</Descriptions.Item>
              </Descriptions>
            </Collapse.Panel>
          )}

          {/* 现场质检完成 */}
          {!!orderDetail.qaOfflineFinishTime && entry !== '1' && (
            <Collapse.Panel
              header={
                <DividerLine
                  title={'现场质检完成'}
                  right={
                    <Space>
                      <span style={{fontSize: '14px', fontWeight: 'normal'}}>
                        {`（${orderDetail.qaOfflineFinishTime}）`}
                      </span>
                      {collapseActiveKeys.includes('10') ? (
                        <UpOutlined style={{color: '#00B7AE'}} />
                      ) : (
                        <DownOutlined style={{color: '#00B7AE'}} />
                      )}
                    </Space>
                  }
                />
              }
              key={`10`}
              showArrow={false}
            >
              <Descriptions title={null} column={2}>
                <Descriptions.Item label={'现场质检员'}>{orderDetail.qaOfflineServerName}</Descriptions.Item>
                <Descriptions.Item label={'现场质检完成时间'}>{orderDetail.qaOfflineFinishTime}</Descriptions.Item>
                <Descriptions.Item label={'现场质检报告'}>
                  <Button
                    type={'link'}
                    style={{padding: 0, height: 'auto'}}
                    onClick={() => cleanOnlineQualified({payloadList: orderDetail.payloadList})}
                  >
                    <span style={{textDecoration: 'underline'}}>查看详情</span>
                  </Button>
                </Descriptions.Item>
              </Descriptions>
            </Collapse.Panel>
          )}

          {/* 扣款确认 */}
          {!!orderDetail.amtCheckTime && entry !== '3' && (
            <Collapse.Panel
              header={
                <DividerLine
                  title={'扣款确认'}
                  right={
                    <Space>
                      <span style={{fontSize: '14px', fontWeight: 'normal'}}>{`（${orderDetail.amtCheckTime}）`}</span>
                      {collapseActiveKeys.includes('8') ? (
                        <UpOutlined style={{color: '#00B7AE'}} />
                      ) : (
                        <DownOutlined style={{color: '#00B7AE'}} />
                      )}
                    </Space>
                  }
                />
              }
              key={`8`}
              showArrow={false}
            >
              <Descriptions title={null} column={2}>
                <Descriptions.Item label={'服务费提成'}>{orderDetail.commissionService}</Descriptions.Item>
                <Descriptions.Item label={'垃圾清运提成'}>{orderDetail.commissionGarbage}</Descriptions.Item>
                <Descriptions.Item label={'扣款金额'}>{orderDetail.amtCheck}</Descriptions.Item>
                <Descriptions.Item label={'确认时间'}>{orderDetail.amtCheckTime}</Descriptions.Item>
                <Descriptions.Item label={'操作人'}>{orderDetail.amtCheckOperator}</Descriptions.Item>
                <Descriptions.Item label={'扣款备注'}>{orderDetail.amtCheckNotes}</Descriptions.Item>
              </Descriptions>
            </Collapse.Panel>
          )}

          {/* 评价信息 */}
          {!!orderDetail.remarkTime && (
            <Collapse.Panel
              header={
                <DividerLine
                  title={'评价信息'}
                  right={
                    <Space>
                      <span style={{fontSize: '14px', fontWeight: 'normal'}}>{`（${orderDetail.remarkTime}）`}</span>
                      {collapseActiveKeys.includes('11') ? (
                        <UpOutlined style={{color: '#00B7AE'}} />
                      ) : (
                        <DownOutlined style={{color: '#00B7AE'}} />
                      )}
                    </Space>
                  }
                />
              }
              key={`11`}
              showArrow={false}
            >
              <Descriptions title={null} column={2}>
                <Descriptions.Item label={'评价星级'}>{orderDetail.remarkScore}</Descriptions.Item>
                <Descriptions.Item label={'评价时间'}>{orderDetail.remarkTime}</Descriptions.Item>
                <Descriptions.Item label={'评价描述'}>{orderDetail.remarkResult}</Descriptions.Item>
              </Descriptions>
            </Collapse.Panel>
          )}

          {/* 取消信息 */}
          {!!orderDetail.cancelTime && (
            <Collapse.Panel
              header={
                <DividerLine
                  title={'取消信息'}
                  right={
                    <Space>
                      <span style={{fontSize: '14px', fontWeight: 'normal'}}>{`（${orderDetail.cancelTime}）`}</span>
                      {collapseActiveKeys.includes('12') ? (
                        <UpOutlined style={{color: '#00B7AE'}} />
                      ) : (
                        <DownOutlined style={{color: '#00B7AE'}} />
                      )}
                    </Space>
                  }
                />
              }
              key={`12`}
              showArrow={false}
            >
              <Descriptions title={null} column={2}>
                <Descriptions.Item label={'取消时间'}>{orderDetail.cancelTime}</Descriptions.Item>
                <Descriptions.Item label={'取消人'}>{orderDetail.cancelOperator}</Descriptions.Item>
                <Descriptions.Item label={'取消原因'}>{orderDetail.cancelReason}</Descriptions.Item>
                <Descriptions.Item label={'具体描述'}>{orderDetail.cancelNotes}</Descriptions.Item>
              </Descriptions>
            </Collapse.Panel>
          )}
        </Collapse>
      </div>

      <Modal
        title={'保洁完成'}
        visible={visible}
        centered
        footer={
          <Space>
            <Button
              onClick={() => {
                if (step === 0) setVisible(false)
                else setStep(prevState => prevState - 1)
              }}
            >
              {step === 0 ? '取消' : '上一步'}
            </Button>
            <Button type={'primary'} onClick={() => form.submit()} loading={saveLoading || finishLoading}>
              {step === 2 ? '提交' : '下一步'}
            </Button>
          </Space>
        }
        width={'60%'}
        onCancel={() => setVisible(false)}
        bodyStyle={{height: '600px', overflow: 'scroll'}}
        afterClose={() => form.resetFields()}
      >
        <Steps current={step} labelPlacement='vertical' onChange={current => setStep(current)}>
          {[{title: '保洁处理前照片/视频'}, {title: '保洁处理后照片/视频'}, {title: '填写处理结果'}].map(item => (
            <Steps.Step key={item.title} title={<span style={{fontWeight: 500}}>{item.title}</span>} disabled />
          ))}
        </Steps>

        <Form
          form={form}
          layout={step === 2 ? 'horizontal' : 'vertical'}
          initialValues={{garbagePkgNum: 0}}
          onFinish={() => {
            const formValues = form.getFieldsValue(true)
            const _config = formValues._config
            if (isPeriodClean) {
              if (
                _config.some(item =>
                  item.mediaList?.some(
                    ele =>
                      ele.imageList?.some(img => !img.imgUrl && !img.imgId) ||
                      ele.videoList?.some(video => !video.imgUrl && !video.imgId)
                  )
                )
              )
                return message.error('图片/视频正在上传中!')
            } else {
              if (
                _config.some(item =>
                  item.subList?.some(value =>
                    value.mediaList?.some(
                      ele =>
                        ele.imageList?.some(img => !img.imgUrl && !img.imgId) ||
                        ele.videoList?.some(video => !video.imgUrl && !video.imgId)
                    )
                  )
                )
              )
                return message.error('图片/视频正在上传中!')
            }
            if (step !== 2) {
              cleanModel
                .cleanOrderSaveFile({orderId, attachList: formValues._config, saveType: saveTypeCode1[step]})
                .then(() => setStep(prevState => prevState + 1))
            } else {
              cleanModel.cleanOrderFinish({orderId, ...formValues}).then(() => {
                setVisible(null)
                message.success('提交成功！')
                history.goBack()
                listener.emit('useCleanOrderList')
              })
            }
          }}
        >
          {[0, 1].includes(step) ? (
            <Form.Item noStyle shouldUpdate>
              {form1 => {
                const _config = form1.getFieldValue('_config')
                // 如果是周期保洁，根据 upload 判断是否需要上传图片
                // 如果是非周期保洁，根据 subUpload 判断是否需要上传图片
                // console.log('_config', _config)
                if (isPeriodClean) {
                  // 筛选出要渲染的项目
                  const renderList = _config?.filter(item => {
                    const mediaList = item.mediaList || []
                    // 判断 mediaList 中是否有需要渲染的项
                    const isMediaUpload = mediaList.some(value => {
                      return step === 0
                        ? value.saveType === 1 && (value.imageTag !== 0 || value.videoTag !== 0)
                        : value.saveType === 2 && (value.imageTag !== 0 || value.videoTag !== 0)
                    })
                    return item.upload && isMediaUpload
                  })
                  // console.log('renderList', renderList)

                  return (
                    <Tabs key={step}>
                      {renderList?.map(item => {
                        // 找到对应的 index
                        const index = _config.findIndex(value => value.name === item.name)
                        const {mediaList} = item
                        const loc = mediaList?.findIndex(item => item.saveType === saveTypeCode1[step])
                        const {imageList, imageMax, imageTag, videoList, videoMax, videoTag, saveType} =
                          mediaList[loc] ?? {}

                        return (
                          <Tabs.TabPane tab={item.name} key={index}>
                            <Row key={index}>
                              {/* imageTag == 0 不展示 */}
                              {imageTag !== 0 && (
                                <Col span={11}>
                                  <Form.Item
                                    label={
                                      <span style={{fontWeight: 500, fontSize: '15px'}}>{`${
                                        saveTypeCode2[saveType]
                                      }照片（${imageList?.length ?? 0}/${imageMax ?? 0}）`}</span>
                                    }
                                    name={['_config', index, 'mediaList', loc, 'imageList']}
                                    fieldKey={['_config', index, 'mediaList', loc, 'imageList']}
                                    rules={[
                                      {
                                        required: imageTag === 1,
                                        type: 'array',
                                        message: `请上传${saveTypeCode2[saveType]}照片`,
                                      },
                                    ]}
                                    getValueFromEvent={onAlbumUploadEvent}
                                    getValueProps={onAlbumUploadProps}
                                  >
                                    <Album
                                      limit={imageMax}
                                      multiple={true}
                                      action={baseUrl + '/clean-order/upload-file'}
                                      accept={'.jpg, .jpeg, .png'}
                                      beforeUpload={file => {
                                        if (file.size > 1024 * 1024 * 10) {
                                          message.error('不能上传超过10M的照片')
                                          return Upload.LIST_IGNORE
                                        }
                                        if (imageMax === 0) {
                                          message.error(`图片不能超过${imageMax}张`)
                                          return Upload.LIST_IGNORE
                                        }
                                      }}
                                    >
                                      <UploadButton title={'上传照片'} />
                                    </Album>
                                  </Form.Item>
                                </Col>
                              )}
                              {videoTag !== 0 && (
                                <Col span={11}>
                                  <Form.Item
                                    label={
                                      <span style={{fontWeight: 500, fontSize: '15px'}}>{`${
                                        saveTypeCode2[saveType]
                                      }视频（${videoList?.length ?? 0}/${videoMax ?? 0}）`}</span>
                                    }
                                    name={['_config', index, 'mediaList', loc, 'videoList']}
                                    fieldKey={['_config', index, 'mediaList', loc, 'videoList']}
                                    rules={[
                                      {
                                        required: videoTag === 1,
                                        type: 'array',
                                        message: `请上传${saveTypeCode2[saveType]}视频`,
                                      },
                                    ]}
                                    getValueFromEvent={onAlbumUploadEvent}
                                    getValueProps={onAlbumUploadProps}
                                  >
                                    <Album
                                      limit={videoMax}
                                      multiple={true}
                                      action={baseUrl + '/clean-order/upload-file'}
                                      accept={'.mp4, .mkv, .avi'}
                                      beforeUpload={file => {
                                        if (file.size > 1024 * 1024 * 30) {
                                          message.error('不能上传超过30M的视频')
                                          return Upload.LIST_IGNORE
                                        }
                                        if (videoMax === 0) {
                                          message.error(`视频不能超过${videoMax}个`)
                                          return Upload.LIST_IGNORE
                                        }
                                      }}
                                    >
                                      <UploadButton title={'上传视频'} />
                                    </Album>
                                  </Form.Item>
                                </Col>
                              )}
                            </Row>
                          </Tabs.TabPane>
                        )
                      })}
                    </Tabs>
                  )
                } else {
                  const renderList = _config?.filter(item => item.subUpload)
                  return (
                    <Tabs key={step}>
                      {renderList?.map((item, index) => {
                        return (
                          <Tabs.TabPane tab={item.name} key={index}>
                            <Form.List name={['_config', index, 'subList']}>
                              {fields =>
                                fields.map((field, idx) => {
                                  const subItem = form1.getFieldValue(['_config', index, 'subList', idx])
                                  const {upload, name, mediaList} = subItem ?? {}
                                  // upload 为 true，该项需要渲染
                                  if (upload) {
                                    // 找出 mediaList 中保洁前、保洁后对应的索引
                                    const loc = mediaList.findIndex(item => item.saveType === saveTypeCode1[step])
                                    const {imageList, imageMax, imageTag, videoList, videoMax, videoTag, saveType} =
                                      mediaList[loc] ?? {}
                                    return (
                                      <Row key={idx}>
                                        <Col span={3} style={{marginTop: '32px'}}>
                                          {name}
                                        </Col>
                                        {/* imageTag == 0 不展示 */}
                                        {imageTag !== 0 && (
                                          <Col span={10}>
                                            <Form.Item
                                              label={
                                                <span style={{fontWeight: 500, fontSize: '15px'}}>{`${
                                                  saveTypeCode2[saveType]
                                                }照片（${imageList?.length ?? 0}/${imageMax ?? 0}）`}</span>
                                              }
                                              name={[idx, 'mediaList', loc, 'imageList']}
                                              fieldKey={[idx, 'mediaList', loc, 'imageList']}
                                              rules={[
                                                {
                                                  required: imageTag === 1,
                                                  type: 'array',
                                                  message: `请上传${saveTypeCode2[saveType]}照片`,
                                                },
                                              ]}
                                              getValueFromEvent={onAlbumUploadEvent}
                                              getValueProps={onAlbumUploadProps}
                                            >
                                              <Album
                                                limit={imageMax}
                                                multiple={true}
                                                action={baseUrl + '/clean-order/upload-file'}
                                                accept={'.jpg, .jpeg, .png'}
                                                beforeUpload={file => {
                                                  if (file.size > 1024 * 1024 * 10) {
                                                    message.error('不能上传超过10M的照片')
                                                    return Upload.LIST_IGNORE
                                                  }
                                                  if (imageMax === 0) {
                                                    message.error(`图片不能超过${imageMax}张`)
                                                    return Upload.LIST_IGNORE
                                                  }
                                                }}
                                              >
                                                <UploadButton title={'上传照片'} />
                                              </Album>
                                            </Form.Item>
                                          </Col>
                                        )}
                                        {videoTag !== 0 && (
                                          <Col span={11}>
                                            <Form.Item
                                              label={
                                                <span style={{fontWeight: 500, fontSize: '15px'}}>{`${
                                                  saveTypeCode2[saveType]
                                                }视频（${videoList?.length ?? 0}/${videoMax ?? 0}）`}</span>
                                              }
                                              name={[idx, 'mediaList', loc, 'videoList']}
                                              fieldKey={[idx, 'mediaList', loc, 'videoList']}
                                              rules={[
                                                {
                                                  required: videoTag === 1,
                                                  type: 'array',
                                                  message: `请上传${saveTypeCode2[saveType]}视频`,
                                                },
                                              ]}
                                              getValueFromEvent={onAlbumUploadEvent}
                                              getValueProps={onAlbumUploadProps}
                                            >
                                              <Album
                                                limit={videoMax}
                                                multiple={true}
                                                action={baseUrl + '/clean-order/upload-file'}
                                                accept={'.mp4, .mkv, .avi'}
                                                beforeUpload={file => {
                                                  if (file.size > 1024 * 1024 * 30) {
                                                    message.error('不能上传超过30M的视频')
                                                    return Upload.LIST_IGNORE
                                                  }
                                                  if (videoMax === 0) {
                                                    message.error(`视频不能超过${videoMax}个`)
                                                    return Upload.LIST_IGNORE
                                                  }
                                                }}
                                              >
                                                <UploadButton title={'上传视频'} />
                                              </Album>
                                            </Form.Item>
                                          </Col>
                                        )}
                                      </Row>
                                    )
                                  } else return null
                                })
                              }
                            </Form.List>
                          </Tabs.TabPane>
                        )
                      })}
                    </Tabs>
                  )
                }
              }}
            </Form.Item>
          ) : (
            <div style={{width: '50%'}}>
              <Form.Item label={'服务项目'}>
                {[orderDetail.categoryFirName, orderDetail.categorySecName, orderDetail.categoryThiName]
                  .filter(item => item)
                  .join('-')}
              </Form.Item>
              <Form.Item label={'垃圾清运（包）'} name={'garbagePkgNum'}>
                <InputNumber min={0} placeholder={'请输入'} onChange={calculateGarbageCommission} />
              </Form.Item>
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, nextValues) =>
                  prevValues._garbageCommission !== nextValues._garbageCommission
                }
              >
                {form1 => (
                  <Form.Item label={'垃圾清运提成'}>{form1.getFieldValue('_garbageCommission') ?? 0}元</Form.Item>
                )}
              </Form.Item>
              <Form.Item label={'收费服务'} name={'_chargeId'} rules={[{required: true, message: '请选择收费服务'}]}>
                <Select placeholder={'请选择'} onChange={calculateServiceCommission}>
                  {chargeList.map(item => (
                    <Select.Option key={item.id} value={item.id}>
                      {item.chargeName}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, nextValues) =>
                  prevValues._serviceCommission !== nextValues._serviceCommission
                }
              >
                {form1 => (
                  <Form.Item label={'收费服务提成'}>{form1.getFieldValue('_serviceCommission') ?? 0}元</Form.Item>
                )}
              </Form.Item>
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, nextValues) => prevValues._userList !== nextValues._userList}
              >
                {form1 => {
                  const userList = form1.getFieldValue('_userList')
                  return (
                    <Form.Item label={'保洁员'} name={'serverId'} rules={[{required: true, message: '请选择'}]}>
                      <Select
                        placeholder={'请选择'}
                        allowClear
                        onSearch={searchServerList}
                        showSearch
                        showArrow={false}
                        filterOption={false}
                        defaultActiveFirstOption={false}
                      >
                        {userList?.map(item => (
                          <Select.Option key={item.userId} value={item.userId}>
                            {`${item.name}-${item.mobile}`}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  )
                }}
              </Form.Item>
              <Form.Item label={'保洁备注'} name={'finishNotes'}>
                <Input.TextArea placeholder={'请输入'} maxLength={200} />
              </Form.Item>
            </div>
          )}
        </Form>
      </Modal>
    </Page>
  )
}
