import React, {useRef, useState} from 'react'
import {Page} from '@/components/Page'
import {Button, message, Space, Tabs} from 'antd'
import StoreAgentSteward from '@/pages/concentrateProject/StoreAgentSteward'
import {Breadcrumb} from '@/components/Breadcrumb'
import {useAppHistory, useAppLocation} from '@/common/hooks/useRouter'
import StoreApartmentManage from '@/pages/concentrateProject/StoreApartmentManage'
import {StoreInfo} from '../StoreInfo'
import {ebassModel} from '@/store/models/Ebaas'
import BackButton from '@/components/BackButton'

export default function StoreManagementInfo() {
  const location = useAppLocation<'/store-manage/info'>()
  const storeNo = location.state?.storeNo
  const history = useAppHistory()
  const [edit, setEdit] = useState(true)

  const storeRef = useRef(null)
  const condoRef = useRef(null)

  const [activeKey, setActiveKey] = useState('1')

  // const [form] = Form.useForm()
  // const [projectList, setProjectList] = useState<CondoProjectVO[]>([])
  // const [showAdd, setShowAdd] = useState(false)

  const syncLoading = ebassModel.ebaasStoreSync.useLoading()
  // const importLoading = ebassModel.ebaasApartmentImport.useLoading()
  const condoLoading = ebassModel.ebaasApartmentCondoProjectList.useLoading()

  return (
    <Page
      // header={<PageHeader onBack={() => history.goBack()} ghost={false} title={'返回'} />}
      breadcrumb={
        <Breadcrumb
          right={
            <Space>
              {activeKey === '1' && (
                <Space>
                  <Button
                    type={'primary'}
                    onClick={async () => {
                      await ebassModel.ebaasStoreSync({storeNo})
                      message.success('更新成功！')
                    }}
                    loading={syncLoading}
                  >
                    更新门店状态
                  </Button>
                  {!edit && (
                    <Button
                      type={'primary'}
                      onClick={() => {
                        storeRef.current?.onSubmit()?.then(() => {
                          setEdit(true)
                        })
                      }}
                    >
                      保存
                    </Button>
                  )}
                  <Button
                    type={edit ? 'primary' : 'default'}
                    onClick={() => {
                      setEdit(state => !state)
                    }}
                  >
                    {edit ? '编辑' : '取消编辑'}
                  </Button>
                </Space>
              )}
              {activeKey === '2' && (
                <Button type={'primary'} onClick={() => history.push('/store-manage/add-agent')}>
                  新增人员
                </Button>
              )}
              {activeKey === '3' && (
                <Button
                  type={'primary'}
                  loading={condoLoading}
                  onClick={async () => {
                    if (storeRef.current?.isToAli() !== 1) return message.warning('门店未同步至天猫，不能添加公寓')
                    history.push('/store-manage/apartment-detail', {storeNo})
                    // const {
                    //   response: {data},
                    // } = await ebassModel.ebaasApartmentCondoProjectList({pageable: false} as CondoProjectListDTO)
                    // setProjectList(data)
                    // setShowAdd(true)
                  }}
                >
                  门店新增公寓
                </Button>
              )}
            </Space>
          }
        />
      }
    >
      <BackButton />
      <Tabs onChange={key => setActiveKey(key)} tabBarStyle={{backgroundColor: '#fff', paddingLeft: '34px'}}>
        <Tabs.TabPane tab={'门店信息'} key={'1'}>
          <StoreInfo storeNo={storeNo} edit={edit} ref={storeRef} />
        </Tabs.TabPane>
        <Tabs.TabPane tab={'经纪管家'} key={'2'}>
          <StoreAgentSteward storeNo={storeNo} />
        </Tabs.TabPane>
        <Tabs.TabPane tab={'公寓管理'} key={'3'}>
          <StoreApartmentManage storeNo={storeNo} ref={condoRef} />

          {/*<Modal*/}
          {/*  visible={showAdd}*/}
          {/*  centered*/}
          {/*  title={'门店关联公寓'}*/}
          {/*  footer={null}*/}
          {/*  onCancel={() => setShowAdd(false)}*/}
          {/*  afterClose={() => form.resetFields()}*/}
          {/*>*/}
          {/*  <Form*/}
          {/*    form={form}*/}
          {/*    onFinish={async () => {*/}
          {/*      const condoId = form.getFieldValue('condoId')*/}
          {/*      await ebassModel.ebaasApartmentImport({condoId, storeNo})*/}
          {/*      message.success('新增成功！')*/}
          {/*      condoRef.current?.refresh()*/}
          {/*      setShowAdd(false)*/}
          {/*    }}*/}
          {/*    onReset={() => setShowAdd(false)}*/}
          {/*  >*/}
          {/*    <div style={{width: '75%'}}>*/}
          {/*      <Form.Item label={'关联公寓'} name={'condoId'} rules={[{required: true, message: '请选择关联公寓'}]}>*/}
          {/*        <Select placeholder={'请选择'}>*/}
          {/*          {projectList.map(item => (*/}
          {/*            <Select.Option key={item.condoId} value={item.condoId}>*/}
          {/*              {item.condoName}*/}
          {/*            </Select.Option>*/}
          {/*          ))}*/}
          {/*        </Select>*/}
          {/*      </Form.Item>*/}
          {/*    </div>*/}
          {/*    <Row justify={'end'}>*/}
          {/*      <Space>*/}
          {/*        <Button htmlType={'reset'}>取消</Button>*/}
          {/*        <Button htmlType={'submit'} type={'primary'} loading={importLoading}>*/}
          {/*          保存*/}
          {/*        </Button>*/}
          {/*      </Space>*/}
          {/*    </Row>*/}
          {/*  </Form>*/}
          {/*</Modal>*/}
        </Tabs.TabPane>
      </Tabs>
    </Page>
  )
}
