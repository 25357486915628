import {Page} from '@/components/Page'
import {
  DatePicker,
  Descriptions,
  Layout,
  Menu,
  PageHeader,
  Form,
  Row,
  Space,
  Button,
  Table,
  Drawer,
  Radio,
  Tabs,
  Select,
  Input,
  Modal,
} from 'antd'
import React, {useCallback, useEffect, useState} from 'react'
import commonStyles from '@/assets/styles/common.module.less'
import DividerLine from '@/pages/property/component/DividerLine'
import {useAppHistory, useAppLocation} from '@/common/hooks/useRouter'
import moment from 'moment'
import {condoModel} from '@/store/models/Condo'
import {useCodeSync} from '@/store/models/Common'
import {downloadBlob} from '@/utils/util'
import {toFixed} from '@/utils/amount'

const months = [0, 1, 2, 3, 4, 5].map(item => moment().subtract(item, 'months').format('YYYY-MM'))
const typeArr = [
  {id: '1', label: '收入'},
  {id: '2', label: '支出'},
]
const feeTypeArr = [
  {id: '2a', label: '其他费用'},
  {id: '2b', label: '营运费用'},
]

export default function ProjectDetail() {
  const history = useAppHistory()
  const location = useAppLocation<'concentrate-pm/detail'>()
  const {condoProjectId} = location.state
  const [condoProjectFinanceList, setCondoProjectFinanceList] = useState<CondoProjectFinanceVO[]>([])
  const [condoProjectInfo, setCondoProjectInfo] = useState({} as CondoProjectVO)
  const repairSource = useCodeSync('condo.project.repairSource')
  const [form] = Form.useForm()
  const [drawerVisible, setDrawerVisible] = useState(false)
  const [visible, setVisible] = useState(false)
  const [info, setInfo] = useState({} as CondoProjectFinanceVO)
  const [chargeType, setChargeType] = useState(1)
  const [current, setCurrent] = useState('1')
  const [billTypeArr, setBillTypeArr] = useState<any[]>([])
  const [addForm] = Form.useForm()
  const [editDisable, setEditDisable] = useState(false)
  // const [person, setPerson] = useState<string>('')

  const queryList = useCallback(
    async (month?: string) => {
      if (month) {
        condoModel.condoProjectFinanceInfo({condoProjectId, month}).then(({response: {data}}) => {
          setCondoProjectFinanceList([data])
        })
      } else {
        const res = await Promise.all(
          months.map(item => condoModel.condoProjectFinanceInfo({condoProjectId, month: item}))
        )
        const arr: CondoProjectFinanceVO[] = res.map(item => item.response.data)
        setCondoProjectFinanceList(arr)
      }
    },
    [condoProjectId]
  )

  useEffect(() => {
    queryList()
  }, [queryList])

  useEffect(() => {
    condoModel.condoProjectInfo({condoProjectId}).then(({response: {data}}) => {
      setCondoProjectInfo(data)
      // sysUserModel.sysUserList({orgId: data.orgId}).then(({response: {data: data1}}) => {
      //   setPerson(data1.map(item => item.name).join(','))
      // })
    })
  }, [condoProjectId])

  const queryCondoProjectFinanceType = useCallback(async (type?: string, feeType?: string) => {
    const {
      response: {data},
    } = await condoModel.condoProjectFinanceType()
    let arr: any[] = []
    if (type === '1') {
      arr = data.filter(item => item.type === type)
    }
    if (type === '2' && feeType) {
      arr = data.filter(item => item.type === type && item.feeType === feeType)
    }

    setBillTypeArr(arr)
  }, [])

  return (
    <Page
      header={
        <PageHeader title={'基本信息'} ghost={false} onBack={() => history.goBack()}>
          <Descriptions column={4}>
            <Descriptions.Item label={'项目名称'}>{condoProjectInfo.projectName}</Descriptions.Item>
            <Descriptions.Item label={'创建时间'}>{condoProjectInfo.insertTime}</Descriptions.Item>
            <Descriptions.Item label={'企业名称'}>{condoProjectInfo.companyName}</Descriptions.Item>
            <Descriptions.Item label={'统一社会信用代码'}>{condoProjectInfo.socialCreditCode}</Descriptions.Item>
            <Descriptions.Item label={'法人'}>{condoProjectInfo.legalPerson}</Descriptions.Item>
            <Descriptions.Item label={'项目电话'}>{condoProjectInfo.projectMobile}</Descriptions.Item>
            <Descriptions.Item label={'项目地址'}>{condoProjectInfo.address}</Descriptions.Item>
            <Descriptions.Item label={'项目编号'}>{condoProjectInfo.projectCode}</Descriptions.Item>
            <Descriptions.Item label={'客户名称'}>{condoProjectInfo.lessorName}</Descriptions.Item>
            <Descriptions.Item label={'公寓名称'}>{condoProjectInfo.condoName}</Descriptions.Item>
            <Descriptions.Item label={'维修服务平台'}>{repairSource[condoProjectInfo.repairSource]}</Descriptions.Item>
            <Descriptions.Item label={'计费系数'}>{condoProjectInfo.feeRatio}</Descriptions.Item>
            <Descriptions.Item label={'店长'}>{condoProjectInfo.sysUserName}</Descriptions.Item>
            {/*
            <Descriptions.Item label={'人员'}>{person}</Descriptions.Item>
            <Descriptions.Item label={'实际交接房源数'}>{condoProjectInfo.propertyNum}</Descriptions.Item>
            */}
          </Descriptions>
        </PageHeader>
      }
    >
      <Layout>
        <Layout.Sider style={{marginRight: 20}} theme={'light'}>
          <Menu activeKey='1' defaultSelectedKeys={['1']} mode='inline'>
            <Menu.Item key={'1'}>财务报表</Menu.Item>
          </Menu>
        </Layout.Sider>
        <Layout>
          <Layout.Content>
            <div className={commonStyles.filterSection} style={{marginBottom: 0}}>
              <Form
                form={form}
                onFinish={() => {
                  const {month} = form.getFieldsValue(true)
                  if (month) {
                    queryList(moment(month).format('YYYY-MM'))
                  }
                }}
                onReset={() => {
                  queryList()
                }}
              >
                <Form.Item label={'月份'} name={'month'}>
                  <DatePicker
                    picker={'month'}
                    disabledDate={current => {
                      return !(current && moment().subtract(5, 'months') < current) || moment() < current
                    }}
                    placeholder='请选择'
                  />
                </Form.Item>
                <Form.Item>
                  <Row justify={'end'}>
                    <Space>
                      <Button type={'primary'} htmlType={'submit'}>
                        查询
                      </Button>
                      <Button htmlType={'reset'}>重置</Button>
                    </Space>
                  </Row>
                </Form.Item>
              </Form>
              <Table
                rowKey={'month'}
                dataSource={condoProjectFinanceList}
                columns={[
                  {title: '月份', dataIndex: 'month', key: 'month', render: text => moment(text).format('YYYY-MM')},
                  {title: '支出（元）', dataIndex: 'expend', key: 'expend', render: value => toFixed(value)},
                  {title: '收入（元）', dataIndex: 'income', key: 'income', render: value => toFixed(value)},
                  {
                    title: '操作',
                    render: (_, render) => {
                      return (
                        <Button
                          type={'link'}
                          onClick={() => {
                            setDrawerVisible(true)
                            setInfo(render)
                          }}
                        >
                          查看详情
                        </Button>
                      )
                    },
                  },
                ]}
                pagination={false}
              />
            </div>
          </Layout.Content>
        </Layout>

        {/* 财报详情 */}
        <Drawer
          visible={drawerVisible}
          width={700}
          title={'财报详情'}
          bodyStyle={{padding: 0}}
          onClose={() => setDrawerVisible(false)}
        >
          <Row
            justify={'space-between'}
            align={'middle'}
            style={{borderBottom: '1px solid #E8E8E8', padding: '10px 20px'}}
          >
            <span>操作</span>
            <Button
              type={'link'}
              onClick={() => {
                downloadBlob({url: '/condo/project/finance/excel', body: {condoProjectId, month: info.month}})
              }}
            >
              导出
            </Button>
          </Row>
          <div style={{padding: '0px 20px'}}>
            <DividerLine title='基本信息' style={{marginBottom: '10px', marginTop: '30px'}} />
            <Descriptions column={2}>
              <Descriptions.Item label={'项目名称'}>{condoProjectInfo.projectName}</Descriptions.Item>
              <Descriptions.Item label={'创建时间'}>{condoProjectInfo.insertTime}</Descriptions.Item>
              <Descriptions.Item label={'项目地址'}>{condoProjectInfo.address}</Descriptions.Item>
              <Descriptions.Item label={'客户名称'}>{condoProjectInfo.lessorName}</Descriptions.Item>
              <Descriptions.Item label={'公寓名称'}>{condoProjectInfo.condoName}</Descriptions.Item>
              <Descriptions.Item label={'计费系数'}>{condoProjectInfo.feeRatio}</Descriptions.Item>
              <Descriptions.Item label={'月份'}>{moment(info.month).format('YYYY-MM')}</Descriptions.Item>
              <Descriptions.Item label={'支出'}>{toFixed(info.expend)}元</Descriptions.Item>
              <Descriptions.Item label={'收入'}>{toFixed(info.income)}元</Descriptions.Item>
              <Descriptions.Item label={'项目编号'}>{condoProjectInfo.projectCode}</Descriptions.Item>
            </Descriptions>
          </div>
          <div style={{padding: '20px 20px'}}>
            <DividerLine
              title={'详细信息'}
              right={
                <div
                  style={{
                    marginLeft: 20,
                  }}
                >
                  <Space>
                    <Radio.Group value={chargeType} onChange={({target}) => setChargeType(target.value)}>
                      <Radio value={1}>支出</Radio>
                      <Radio value={2}>收入</Radio>
                    </Radio.Group>
                  </Space>
                </div>
              }
            />
          </div>
          {chargeType === 1 && (
            <Tabs
              style={{paddingLeft: 15}}
              activeKey={current}
              onChange={key => {
                setCurrent(key)
              }}
              tabBarExtraContent={<span style={{marginRight: 30}}>运营费用 = 账单金额之和 * 计费系数</span>}
            >
              <Tabs.TabPane tab={`运营费用（${toFixed(info.expendWork)}）`} key={'1'} />
              <Tabs.TabPane tab={`其他费用（${toFixed(info.expendOther)}）`} key={'2'} />
            </Tabs>
          )}
          <div style={{padding: '0 30px'}}>
            <Table
              rowKey={'billTypeDesc'}
              columns={[
                {title: '账单项目', dataIndex: 'billTypeDesc', key: 'billTypeDesc'},
                {title: '金额（元）', dataIndex: 'amount', key: 'amount', render: text => toFixed(text) || '-'},
                {
                  title: '操作',
                  render: (_, render) => {
                    return (
                      <Button
                        type={'link'}
                        onClick={() => {
                          setVisible(true)
                          addForm.setFieldsValue({
                            type: render.type,
                            amount: render.amount,
                            billType: render.billType,
                            feeType: render.feeType,
                          })
                          setEditDisable(true)
                          queryCondoProjectFinanceType(render.type, render.feeType)
                        }}
                      >
                        编辑
                      </Button>
                    )
                  },
                },
              ]}
              dataSource={
                chargeType === 1 ? (current === '1' ? info.expendWorkList : info.expendOtherList) : info.incomeList
              }
              pagination={{
                total:
                  chargeType === 1
                    ? current === '1'
                      ? info.expendWorkList?.length
                      : info.expendOtherList?.length
                    : info.incomeList?.length,
                defaultCurrent: 1,
                defaultPageSize: 10,
                position: ['bottomCenter'],
              }}
            />
          </div>
        </Drawer>

        {/* 新增 */}
        <Modal
          visible={visible}
          title={'编辑'}
          centered
          width={680}
          cancelText={'取消'}
          onCancel={() => {
            setVisible(false)
          }}
          afterClose={() => {
            setEditDisable(false)
            addForm.resetFields()
            setBillTypeArr([])
          }}
          okText={'保存'}
          onOk={async () => {
            await addForm.validateFields()
            const {billType, amount} = addForm.getFieldsValue(true)
            await condoModel.condoProjectFinanceUpsert({
              billType,
              amount: Number(amount),
              month: moment(info.month).format('YYYY-MM'),
              condoProjectId,
            })
            const {
              response: {data},
            } = await condoModel.condoProjectFinanceInfo({condoProjectId, month: info.month})
            setInfo(data)
            addForm.resetFields()
            setVisible(false)
          }}
        >
          <Form form={addForm} labelCol={{span: '4'}} wrapperCol={{span: '16'}}>
            <Form.Item label={'收支类型'} name={'type'} rules={[{required: true, message: '请输入收支类型'}]}>
              <Select
                placeholder={'请选择'}
                disabled={editDisable}
                onChange={(value: string) => {
                  queryCondoProjectFinanceType(value)
                  addForm.setFieldsValue({billType: undefined, feeType: undefined})
                }}
                allowClear
              >
                {typeArr.map(item => (
                  <Select.Option value={item.id} key={item.id}>
                    {item.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            {addForm.getFieldValue('type') === '2' && (
              <Form.Item label={'费用类型'} name={'feeType'} rules={[{required: true, message: '请输入费用类型'}]}>
                <Select
                  disabled={editDisable}
                  placeholder={'请选择'}
                  onChange={(value: string) => {
                    queryCondoProjectFinanceType(addForm.getFieldValue('type'), value)
                    addForm.setFieldsValue({billType: undefined})
                  }}
                  allowClear
                >
                  {feeTypeArr.map(item => (
                    <Select.Option value={item.id} key={item.id}>
                      {item.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            )}

            <Form.Item label={'账单项目'} name={'billType'} rules={[{required: true, message: '请输入账单项目'}]}>
              <Select disabled={editDisable} allowClear placeholder={'请选择'}>
                {billTypeArr.map(item => (
                  <Select.Option key={item.billType} value={item.amount}>
                    {item.billTypeDesc}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label={'金额'} name={'amount'} rules={[{required: true, message: '请输入金额'}]}>
              <Input placeholder={'请输入（元）'} allowClear />
            </Form.Item>
          </Form>
        </Modal>
      </Layout>
    </Page>
  )
}
