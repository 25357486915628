import {$api} from '@/store/service'
import {Model} from '@redux-model/web'

interface AdminModel {}

class Admin extends Model<AdminModel> {
  protected initReducer(): AdminModel {
    return {}
  }

  // 新增客户
  adminCondoCustomerInsert = $api.action((args: CondoCustomerInsertDTO) =>
    this.post<ApiResponse<string>>('/admin/condo/customer/insert').body(args)
  )

  // 编辑客户
  adminCondoCustomerUpdate = $api.action((args: CondoCustomerUpdateDTO) =>
    this.post<ApiResponse<Record<string, any>>>('/admin/condo/customer/update').body(args)
  )

  // 客户列表
  adminCondoCustomerList = $api.action((args: CondoCustomerQueryDTO) =>
    this.post<ApiResponse<CondoCustomerVO[]>>('/admin/condo/customer/list').body(args)
  )

  // 客户详情
  adminCondoCustomerGet = $api.action((args: CondoCustomerGetDTO) =>
    this.post<ApiResponse<CondoCustomerVO>>('/admin/condo/customer/get').body(args)
  )

  // 客户信息变更历史
  adminCondoCustomerListEditRecord = $api.action((args: CondoCustomerEditRecordQueryDTO) =>
    this.post<ApiResponse<CondoCustomerEditRecordVO[]>>('/admin/condo/customer/list-edit-record').body(args)
  )

  // 转移到公共池
  adminCondoCustomerTransferToPool = $api.action((args: CondoCustomerTransferToPoolDTO) =>
    this.post<ApiResponse<Record<string, any>>>('/admin/condo/customer/transfer-to-pool').body(args)
  )

  // 转移到个人
  adminCondoCustomerTransferToStaff = $api.action((args: CondoCustomerTransferToStaffDTO) =>
    this.post<ApiResponse<Record<string, any>>>('/admin/condo/customer/transfer-to-staff').body(args)
  )

  // 带看详情  |  object:{visitId:带看id}
  adminCondoCustomerVisitGet = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<CondoCustomerVisitVO>>('/admin/condo/customer/visit/get').body(args)
  )

  // 带看新增
  adminCondoCustomerVisitInsert = $api.action((args: CondoCustomerVisitInsertDTO) =>
    this.post<ApiResponse<string>>('/admin/condo/customer/visit/insert').body(args)
  )

  // 带看列表
  adminCondoCustomerVisitList = $api.action((args: CondoCustomerVisitQueryDTO) =>
    this.post<ApiResponse<CondoCustomerVisitVO[]>>('/admin/condo/customer/visit/list').body(args)
  )

  // 带看删除  |  object:{visitId:带看id}
  adminCondoCustomerVisitDelete = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<string>>('/admin/condo/customer/visit/delete').body(args)
  )

  // 带看编辑
  adminCondoCustomerVisitEdit = $api.action((args: CondoCustomerVisitEditWebDTO) =>
    this.post<ApiResponse<string>>('/admin/condo/customer/visit/edit').body(args)
  )

  // 客户列表(全部)
  adminCondoCustomerListAll = $api.action((args: CondoCustomerQueryDTO) =>
    this.post<ApiResponse<CondoCustomerVO[]>>('/admin/condo/customer/list-all').body(args)
  )
}

export const adminModel = new Admin()
