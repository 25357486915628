import React, {useEffect, useState} from 'react'
import {condoModel} from '@/store/models/Condo'
import {sysModel} from '@/store/models/Sys'
import {delay} from '@/utils/util'
import {Button, Col, DatePicker, Form, Modal, Row, Select, Space} from 'antd'
import moment from 'moment'

function _CondoTradeReport({reportId}, ref) {
  const [form] = Form.useForm()
  const [list, setList] = useState<CondoProjectVO[]>([])

  useEffect(() => {
    condoModel.condoProjectList({pageable: false} as CondoProjectListDTO).then(({response: {data}}) => {
      setList(data)
    })
  }, [])

  return (
    <Form
      labelCol={{flex: '80px'}}
      form={form}
      onFinish={async () => {
        const {month, condoList} = form.getFieldsValue(true)
        await sysModel.sysReportExportExcel3({month, condoList, reportId})
        Modal.success({
          title: '提示',
          content: '表格正在导出，稍后请前往“下载中心”进行下载',
          icon: null,
          centered: true,
          okText: '关闭',
        })
        await delay(1000)
        form.resetFields()
      }}
    >
      <Row>
        <Col>
          <Form.Item label={'统计月份'} name={'_month'} rules={[{required: true, message: '请选择统计月份'}]}>
            <DatePicker
              picker={'month'}
              format={'YYYY-MM'}
              onChange={(_, dateString) => {
                form.setFieldsValue({month: dateString})
              }}
              disabledDate={currentDate => {
                return currentDate > moment().subtract(1, 'month')
              }}
            />
          </Form.Item>
        </Col>
        <Col offset={1} span={10}>
          <Form.Item label={'所选项目'} name={'_condoList'} rules={[{required: true, message: '请选择所选项目'}]}>
            <Select
              placeholder={'请选择'}
              mode={'multiple'}
              showSearch
              filterOption={(value, option) => {
                return option.children.indexOf(value as any) > -1
              }}
              onChange={(_, option) => {
                form.setFieldsValue({condoList: option.map(item => ({key: item.value, value: item.children}))})
              }}
              maxTagCount={1}
              allowClear
            >
              {list.map(item => (
                <Select.Option value={item.condoProjectId} key={item.condoProjectId}>
                  {item.projectName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Form.Item label={' '} style={{marginTop: 20}} colon={false}>
        <Space>
          <Button type={'primary'} htmlType={'submit'}>
            导出
          </Button>
          <Button htmlType={'reset'}>重置</Button>
        </Space>
      </Form.Item>
    </Form>
  )
}

const CondoTradeReport = React.forwardRef(_CondoTradeReport)

export default CondoTradeReport
