import {useCallback, useEffect, useState} from 'react'
import {cleanModel} from '@/store/models/Clean'
import {FormInstance} from 'antd'
import {useListener} from '@/common/hooks/listener'

export function useCleanOrderList(form: FormInstance) {
  const [dataTotal, setDataTotal] = useState(0)
  const [dataList, setDataList] = useState<CleanOrderVO[]>([])

  const queryCleanOrderList = useCallback(() => {
    const formValue = form.getFieldsValue(true)
    cleanModel.cleanOrderList(formValue).then(({response: {data, total}}) => {
      setDataList(data)
      setDataTotal(total)
    })
  }, [form])

  useEffect(() => {
    queryCleanOrderList()
  }, [queryCleanOrderList])

  useListener('useCleanOrderList', () => {
    queryCleanOrderList()
  })

  const orderListLoading = cleanModel.cleanOrderList.useLoading()

  return {dataList, dataTotal, queryCleanOrderList, orderListLoading}
}
