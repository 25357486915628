import React, {useCallback, useEffect, useState} from 'react'
import {Table, Form, Typography, Button, DatePicker, Select, Row, Col, Input, Space} from 'antd'
import {propertyModel} from '@/store/models/Property'
import moment from 'moment'
import {useCodeArraySync, useCodeSync} from '@/store/models/Common'
import commonStyles from '@/assets/styles/common.module.less'
import {hasPermission} from '@/components/Permission'
import {useAppHistory, useAppLocation} from '@/common/hooks/useRouter'
import {getPaginationProps} from '@/common/biz/getPaginationProps'
import {useFocusEffect} from '@/common/hooks/useFocusEffect'
import {Page} from '@/components/Page'

const {Text} = Typography
// const {RangePicker} = DatePicker
const {Option} = Select

export default function BillList() {
  const history = useAppHistory()
  const location = useAppLocation<'/bill'>()

  // 账单状态
  const billStatus = useCodeSync('bill.status')
  const billStatusOptions = useCodeArraySync('bill.status', {defaultItem: true})

  // 列表
  const [dataList, setDataList] = useState<ContractBillAdminListQueryResultVO[]>([])
  // 数据条数
  const [total, setTotal] = useState(0)

  const loading = propertyModel.queryBillList.useLoading()

  const [form] = Form.useForm<ContractBillAdminListQueryDTO>()

  const search = useCallback(() => {
    const values = form.getFieldsValue(true)
    propertyModel.queryBillList(values).then(({response: {data, total}}) => {
      setDataList(data)
      setTotal(total)
    })
  }, [form])

  const propertyCode = location.state?.propertyCode
  const lessor = location.state?.lessor
  const lessee = location.state?.lessee
  // 因为保存了状态，其他页面传 propertyCode 或者 lessor 也会更新到当前页面
  useEffect(() => {
    if (propertyCode) {
      form.setFieldsValue({propertyCode})
      location.state.propertyCode = undefined
    }
    if (lessor) {
      form.setFieldsValue({lessor})
      location.state.lessor = undefined
    }
    if (lessee) {
      form.setFieldsValue({lessee})
      location.state.lessee = undefined
    }
  }, [form, lessee, lessor, location.state, propertyCode])

  useFocusEffect(() => {
    form.submit()
  }, 'PUSH|POP')

  useEffect(() => {
    form.submit()
  }, [form])

  return (
    <Page>
      <div className={commonStyles.filterSection}>
        <Form form={form} initialValues={{pageNum: 1, pageSize: 10}} onReset={search} onFinish={search}>
          {/**名称*/}
          <Row>
            <Col span={7}>
              <Form.Item name='lessor' label={'房东名称'}>
                <Input placeholder={'房东姓名/房东手机'} allowClear />
              </Form.Item>
            </Col>
            <Col span={7} offset={1}>
              <Form.Item name='lessee' label={'租客名称'}>
                <Input placeholder={'租客姓名/租客手机'} allowClear />
              </Form.Item>
            </Col>
            <Col span={7} offset={1}>
              <Form.Item name='propertyCode' label={'房源code'}>
                <Input placeholder={'房源code'} allowClear />
              </Form.Item>
            </Col>
          </Row>

          {/**添加时间*/}
          <Row>
            <Col span={7}>
              {/*todo: 有问题！时间应该是有时分秒的，此处只显示年月日*/}
              <Form.Item name='_time' label={'添加时间'}>
                <DatePicker.RangePicker
                  format={'YYYY-MM-DD'}
                  onChange={(_, formatString) => {
                    form.setFieldsValue({
                      insertTimeBegin: formatString?.[0] ? formatString?.[0] + ' 00:00:00' : formatString?.[0],
                      insertTimeEnd: formatString?.[1] ? formatString?.[1] + ' 23:59:59' : formatString?.[1],
                    })
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={7} offset={1}>
              <Form.Item name='title' label={'房源地址'}>
                <Input placeholder={'小区/公寓'} allowClear />
              </Form.Item>
            </Col>
            <Col span={7} offset={1}>
              <Form.Item name='rentType' label={'房源类别'}>
                <Select placeholder='全部/整租/合租/公寓房' allowClear>
                  <Option value=''>全部</Option>
                  <Option value='1'>整租</Option>
                  <Option value='2'>合租</Option>
                  <Option value='3'>公寓房</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={7}>
              <Form.Item name={'billStatus'} label={'账单状态'}>
                <Select placeholder={'请选择'} allowClear>
                  {billStatusOptions.map(item => (
                    <Option key={item.id} value={item.id}>
                      {item.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row justify={'end'}>
            <Space>
              <Button type={'primary'} htmlType={'submit'} onClick={() => form.setFieldsValue({pageNum: 1})}>
                查询
              </Button>
              <Button
                htmlType={'reset'}
                // onClick={() => {
                //   setParams({pageNum: 1, pageSize: 10})
                //   form.resetFields()
                // }}
              >
                重置
              </Button>
            </Space>
          </Row>
        </Form>
      </div>

      <div className={commonStyles.tableSection}>
        <Table
          rowKey={'billId'}
          dataSource={dataList}
          columns={[
            {title: '账单ID', dataIndex: 'billId', key: 'billId'},
            {title: '房东名称', dataIndex: 'certName', key: 'certName'},
            {title: '账单类型', dataIndex: 'billTypeName', key: 'billTypeName'},
            {
              title: '账单周期',
              dataIndex: 'billBegin',
              key: 'billBegin',
              render: (value, record) => {
                return (
                  <Text>
                    {record.billBegin && record.billEnd
                      ? `${moment(record.billBegin).format('YYYY-MM-DD')}至${moment(record.billEnd).format(
                          'YYYY-MM-DD'
                        )}`
                      : '无账期'}
                  </Text>
                )
              },
            },
            {title: '账单金额', dataIndex: 'billAmtTotal', key: 'billAmtTotal'},
            {title: '对应房源', dataIndex: 'propertyTitle', key: 'propertyTitle'},
            {title: '租客手机号', dataIndex: 'lesseeMobile', key: 'lesseeMobile'},
            {title: '租客名称', dataIndex: 'lesseeCertName', key: 'lesseeCertName'},
            {
              title: '状态',
              dataIndex: 'billStatus',
              key: 'billStatus',
              render: value => <div>{billStatus[value]}</div>,
            },
            {
              title: '操作',
              dataIndex: 'billId',
              key: 'billId',
              fixed: 'right',
              width: '100px',
              render: (value, record) =>
                hasPermission('001004002') && (
                  <Button
                    type={'link'}
                    size={'small'}
                    onClick={() =>
                      history.push({
                        pathname: '/bill/detail',
                        state: {billId: record.billId, propertyTitle: record.propertyTitle},
                      })
                    }
                    style={{padding: 0}}
                  >
                    查看
                  </Button>
                ),
            },
          ]}
          scroll={{x: 'max-content'}}
          loading={loading}
          pagination={getPaginationProps(form, total)}
        />
      </div>
    </Page>
  )
}
