import React, {useCallback, useEffect, useState} from 'react'
import commonStyles from '@/assets/styles/common.module.less'
import {Breadcrumb} from '@/components/Breadcrumb'
import {Button, Col, DatePicker, Form, Input, Modal, Row, Select, Space, Table} from 'antd'
import {projectModel} from '@/store/models/Project'
import {useCodeArraySync, useCodeSync} from '@/store/models/Common'
import {Page} from '@/components/Page'
import {useAppHistory} from '@/common/hooks/useRouter'
import {usePermission} from '@/components/Permission'
import {getPaginationProps} from '@/common/biz/getPaginationProps'
import {useFocusEffect} from '@/common/hooks/useFocusEffect'

const {Option} = Select

const visibleDesc = {
  del: '您是否确认删除该工程？',
  enable: '您是否确认开始启用该工程？',
  disable: '您是否确认暂停该工程？',
  invalid: '您是否确认作废该工程？',
}

export default function EngineeringManagement() {
  const history = useAppHistory()
  const [auth] = usePermission()

  const projectWorkStatusArr = useCodeArraySync('project.workStatus')
  const projectWorkStatusCode = useCodeSync('project.workStatus')

  const [form] = Form.useForm<ProjectWorkListDTO>()
  const [total, setTotal] = useState(0)
  const [workList, setWorkList] = useState<ProjectWorkVO[]>([])

  const listLoading = projectModel.projectAdminWorkList.useLoading()
  const statusLoading = projectModel.projectAdminWorkStatus.useLoading()
  const deleteLoading = projectModel.projectAdminWorkDelete.useLoading()

  const [operateVisible, setOperateVisible] = useState<{
    status: 'del' | 'enable' | 'disable' | 'invalid'
    workCode: string
  }>({status: null, workCode: null})

  // 工程列表
  const queryWorkList = useCallback(() => {
    const values = form.getFieldsValue(true)
    projectModel.projectAdminWorkList(values).then(({response: {data, total}}) => {
      setTotal(total)
      setWorkList(data)
    })
  }, [form])

  useFocusEffect(() => {
    form.submit()
  })

  useEffect(() => {
    form.submit()
  }, [form])

  const renderOperate = (args: Pick<ProjectWorkVO, 'workCode' | 'workStatus' | 'workName'>) => {
    const {workCode, workStatus, workName} = args
    return [
      {
        when: ['1', '3'].includes(workStatus) && auth('023077002'),
        title: '启用',
        onClick: () => setOperateVisible({status: 'enable', workCode}),
      },
      {
        when: workStatus === '2' && auth('023077002'),
        title: '暂停',
        onClick: () => setOperateVisible({status: 'disable', workCode}),
      },
      {
        when: workStatus === '3' && auth('020077002'),
        title: '作废',
        onClick: () => setOperateVisible({status: 'invalid', workCode}),
      },
      {
        when: workStatus === '1' && auth('004077002'),
        title: '删除',
        onClick: () => setOperateVisible({status: 'del', workCode}),
      },
      {
        when: ['1', '2', '3'].includes(workStatus) && auth('002077002'),
        title: '编辑',
        onClick: () => {
          history.push('/engineering/detail', {workCode, workStatus, operate: 'edit', workName})
        },
      },
      {
        when: auth('001077002'),
        title: '查看',
        onClick: () => {
          history.push('/engineering/detail', {workCode, workStatus, operate: 'check', workName})
        },
      },
    ].filter(item => item.when)
  }

  return (
    <Page
      breadcrumb={
        <Breadcrumb
          right={
            auth('003077002') && (
              <Button type={'primary'} onClick={() => history.push({pathname: '/engineering/add'})}>
                新增工程
              </Button>
            )
          }
        />
      }
    >
      <Form
        form={form}
        labelCol={{flex: '75px'}}
        labelAlign={'left'}
        initialValues={{pageNum: 1, pageSize: 10}}
        onReset={queryWorkList}
        onFinish={queryWorkList}
      >
        <div className={commonStyles.filterSection}>
          <Row gutter={30}>
            <Col span={7}>
              <Form.Item name={'workName'} label={'工程名称'}>
                <Input placeholder={'请输入'} allowClear />
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item name={'workCode'} label={'工程编号'}>
                <Input placeholder={'请输入'} allowClear />
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item name={'insertName'} label={'创建人'}>
                <Input placeholder={'请输入'} allowClear />
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item name={'workStatus'} label={'状态'}>
                <Select placeholder={'请选择'} allowClear>
                  {projectWorkStatusArr.map(item => (
                    <Option key={item.id} value={item.id}>
                      {item.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item name={'workDate'} label={'工期'}>
                <DatePicker.RangePicker
                  onChange={(_, format) => {
                    form.setFields([
                      {name: 'workBeginDate', value: format?.[0]},
                      {name: 'workEndDate', value: format?.[1]},
                    ])
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row justify={'end'}>
            <Space>
              <Button type={'primary'} htmlType={'submit'} onClick={() => form.setFieldsValue({pageNum: 1})}>
                查询
              </Button>
              <Button htmlType={'reset'}>重置</Button>
            </Space>
          </Row>
        </div>

        <div className={commonStyles.tableSection}>
          <Table
            rowKey={'workCode'}
            loading={listLoading}
            columns={[
              {title: '编号', dataIndex: 'workCode', key: 'workCode'},
              {title: '工程名称', dataIndex: 'workName', key: 'workName'},
              {title: '地点', dataIndex: 'address', key: 'address'},
              {
                title: '工期',
                key: 'workDate',
                render: values => {
                  const {workBeginDate, workEndDate} = values ?? {}
                  return [workBeginDate, workEndDate].filter(item => item).join('至')
                },
              },
              {title: '创建人', dataIndex: 'insertName', key: 'insertName'},
              {
                title: '状态',
                dataIndex: 'workStatus',
                key: 'workStatus',
                render: values => projectWorkStatusCode[values],
              },
              {
                title: '操作',
                fixed: 'right',
                render: values => {
                  return (
                    <Space>
                      {renderOperate(values).map((item, index) => (
                        <Button key={index} type={'link'} style={{padding: 0}} onClick={item.onClick}>
                          {item.title}
                        </Button>
                      ))}
                    </Space>
                  )
                },
              },
            ]}
            scroll={{x: 'max-content'}}
            dataSource={workList}
            pagination={getPaginationProps(form, total)}
          />
        </div>
      </Form>

      {/** 启用、暂停、作废、删除 */}
      <Modal
        title={'提示'}
        centered
        footer={null}
        visible={['del', 'enable', 'disable', 'invalid'].includes(operateVisible.status)}
        onCancel={() => setOperateVisible({status: null, workCode: null})}
      >
        {visibleDesc[operateVisible.status]}
        <Row justify={'end'} style={{marginTop: '24px'}}>
          <Space>
            <Button onClick={() => setOperateVisible({status: null, workCode: null})}>取消</Button>
            <Button
              type={'primary'}
              onClick={async () => {
                const {status, workCode} = operateVisible
                if (status === 'del') {
                  await projectModel.projectAdminWorkDelete({workCode})
                } else {
                  const code = {enable: '2', disable: '3', invalid: '5'}
                  await projectModel.projectAdminWorkStatus({workStatus: code[status], workCode})
                }
                setOperateVisible({status: null, workCode: null})
                form.submit()
              }}
              loading={statusLoading || deleteLoading}
            >
              确定
            </Button>
          </Space>
        </Row>
      </Modal>
    </Page>
  )
}
