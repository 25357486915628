import {Model} from '@redux-model/web'
import {$api} from '@/store/service'

interface SaleModel {}

class Sale extends Model<SaleModel> {
  protected initReducer(): SaleModel {
    return {}
  }

  // 查询客户列表
  saleCustomerList = $api.action((args: SaleCustomerListDTO) =>
    this.post<ApiResponse<SaleCustomerVO[]>>('/sale/customer/list').body(args)
  )

  // 查询客户详情  |  object:{customerId:客户id}
  saleCustomerInfo = $api.action((args: {customerId: string}) =>
    this.post<ApiResponse<SaleCustomerVO>>('/sale/customer/info').body(args)
  )

  // 查询客户历史记录  |  object:{customerId:客户id}
  saleCustomerHistory = $api.action((args: {customerId: string}) =>
    this.post<ApiResponse<SaleCustomerHistoryVO[]>>('/sale/customer/history').body(args)
  )

  // 新增/修改客户
  saleCustomerUpsert = $api.action((args: SaleCustomerDTO) => this.post('/sale/customer/upsert').body(args))

  // 查询客户合同列表
  saleCustomerContractList = $api.action((args: Partial<SaleCustomerContractListDTO>) =>
    this.post<ApiResponse<SaleCustomerContractVO[]>>('/sale/customer/contract/list').body({
      pageNum: 1,
      pageSize: 10,
      pageable: true,
      ...args,
    })
  )

  // 查询客户合同详情  |  object:{contractId:客户合同id}
  saleCustomerContractInfo = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<SaleCustomerContractVO>>('/sale/customer/contract/info').body(args)
  )

  // 根据房源code查询客户合同详情  |  object:{propertyCode:房源code}
  saleCustomerContractInfoByPropertyCode = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<SaleCustomerContractVO>>('/sale/customer/contract/info-by-property-code').body(args)
  )

  // 终止客户合同
  saleCustomerContractTerminate = $api.action((args: {contractId: string}) =>
    this.post('/sale/customer/contract/terminate').body(args)
  )

  // 查询客户签约申请列表
  saleCustomerSignList = $api.action((args: Partial<SaleCustomerSignListDTO>) =>
    this.post<ApiResponse<SaleCustomerSignVO[]>>('/sale/customer/sign/list').body({
      pageNum: 1,
      pageSize: 10,
      pageable: true,
      ...args,
    })
  )

  // 作废签约
  saleCustomerSignDestroy = $api.action((args: {signId: string}) => this.post('/sale/customer/sign/destroy').body(args))

  // 查询客户签约申请详情
  saleCustomerSignInfo = $api.action((args: {signId: string}) =>
    this.post<ApiResponse<SaleCustomerSignVO>>('/sale/customer/sign/info').body(args)
  )

  // 销售管理-列表
  saleUserList = $api.action((args: SaleUserListDTO) =>
    this.post<ApiResponse<SaleUserVO[]>>('/sale/user/list').body(args)
  )

  // 销售管理-禁用销售  |  object:{userId:销售id}
  saleUserDisable = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<Record<string, any>>>('/sale/user/disable').body(args)
  )

  // 查询客户合同详情-绑定房源列表  |  object:{contractId:客户合同id}
  saleCustomerContractPropertyStatistics = $api.action((args: {contractId: string}) =>
    this.post<ApiResponse<SaleCustomerContractPropertyVO[]>>('/sale/customer/contract/property-statistics').body(args)
  )

  // 新增客户跟进记录
  saleCustomerFollowInsert = $api.action((args: SaleCustomerFollowDTO) =>
    this.post<ApiResponse<Record<string, any>>>('/sale/customer/follow/insert').body(args)
  )

  // 查询销售/团队跟进列表
  saleCustomerFollowList = $api.action((args: SaleFollowListDTO) =>
    this.post<ApiResponse<SaleCustomerFollowVO[]>>('/sale/customer/follow/list').body(args)
  )

  // 将客户转移至其他人
  saleAppCustomerMoveTo = $api.action((args: MoveSaleCustomerDTO) =>
    this.post<ApiResponse>('/sale-app/customer/move-to').body(args)
  )

  // 自己与公共之间转移客户
  saleCustomerMove = $api.action((args: MoveSaleCustomerDTO) =>
    this.post<ApiResponse>('/sale/customer/move').body(args)
  )

  // 确认签约
  saleCustomerSignConfirm = $api.action((args: SaleCustomerSignAuditDTO) =>
    this.post<ApiResponse<Record<string, any>>>('/sale/customer/sign/confirm').body(args)
  )

  // 确认客户合同  |  object:{contractId:客户合同id}
  saleCustomerContractConfirm = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse>('/sale/customer/contract/confirm').body(args)
  )
  // 查询客户拜访列表
  saleCustomerVisitList = $api.action((args: SaleCustomerVisitListDTO) =>
    this.post<ApiResponse<SaleCustomerVisitVO[]>>('/sale/customer/visit/list').body(args)
  )
  // 销售管理-销售绩效-列表
  saleSalaryPerformanceList = $api.action((args: SaleSalaryPerformanceListDTO) =>
    this.post<ApiResponse<SaleSalaryPerformanceVO[]>>('/sale/salary/performance/list').body(args)
  )

  // 销售管理-销售绩效-新增/修改
  saleSalaryPerformanceUpsert = $api.action((args: SaleSalaryPerformanceDTO) =>
    this.post<ApiResponse<Record<string, any>>>('/sale/salary/performance/upsert').body(args)
  )

  // 销售管理-销售绩效-删除  |  object:{salePerformanceId:绩效id}
  saleSalaryPerformanceDelete = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<Record<string, any>>>('/sale/salary/performance/delete').body(args)
  )

  // 作废签约(合同)  |  object:{contractId:客户合同id}
  saleCustomerContractDestroy = $api.action((args: {contractId: string}) =>
    this.post<ApiResponse>('/sale/customer/contract/destroy').body(args)
  )

  // 客户合同历史  |  object:{signId:客户合同关联的signId}
  saleCustomerContractHistory = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<SaleCustomerContractHistoryVO[]>>('/sale/customer/contract/history').body(args)
  )

  // 客户合同历史  |  object:{signId:客户合同关联的signId}
  saleCustomerSignHistory = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<SaleCustomerContractHistoryVO[]>>('/sale/customer/sign/history').body(args)
  )

  // 附件列表查询
  saleCustomerContractListAttach = $api.action((args: SaleCustomerContractAttachQueryDTO) =>
    this.post<ApiResponse<SaleCustomerContractAttachVO[]>>('/sale/customer/contract/list-attach').body(args)
  )

  // 修改客户合同保洁类型
  saleCustomerContractUpdateCleanService = $api.action((args: SaleCustomerContractCleanDTO) =>
    this.post<ApiResponse>('/sale/customer/contract/update-clean-service').body(args)
  )

  // 查询房源列表
  saleCustomerContractPropertyList = $api.action((args: SaleCustomerContractPropertyListDTO) =>
    this.post<ApiResponse<SaleCustomerContractPropertyListVO[]>>('/sale/customer/contract/property/list').body(args)
  )

  // 查询默认合同  |  object:{customerId:客户id}
  saleCustomerContractPropertyDefaultInfo = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<SaleCustomerContractPropertyVO>>('/sale/customer/contract/property/default/info').body(args)
  )

  // 修改默认合同  |  object:{customerId:客户id, contractId:合同id}
  saleCustomerContractPropertyDefaultUpdate = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<Record<string, any>>>('/sale/customer/contract/property/default/update').body(args)
  )

  // 房源绑定
  saleCustomerContractPropertyBind = $api.action((args: SaleCustomerContractPropertyBindDTO) =>
    this.post<ApiResponse<Record<string, any>>>('/sale/customer/contract/property/bind').body(args)
  )

  // 查询房源绑定合同列表
  saleCustomerContractPropertyBindList = $api.action((args: SaleCustomerContractPropertyBindListDTO) =>
    this.post<ApiResponse<SaleCustomerContractPropertyVO[]>>('/sale/customer/contract/property/bind/list')
      .body(args)
      .metas(args.propertyCode)
  )

  // 查询房源合同绑定历史列表
  saleCustomerContractPropertyHistoryList = $api.action((args: SaleCustomerContractPropertyHistoryListDTO) =>
    this.post<ApiResponse<SaleCustomerContractPropertyHistoryVO[]>>(
      '/sale/customer/contract/property/history/list'
    ).body(args)
  )

  // 签约未充值处理
  saleCustomerSignConfirmCharge = $api.action((args: SaleCustomerSignAuditDTO) =>
    this.post<ApiResponse<Record<string, any>>>('/sale/customer/sign/confirm-charge').body(args)
  )

  // 批量解绑合同  |  ['合同id']
  saleCustomerContractRescindBatch = $api.action((args: string[]) =>
    this.post<ApiResponse<Record<string, any>>>('/sale/customer/contract/rescind/batch').body(args)
  )
}

export const saleModel = new Sale()
