import React, {useCallback, useEffect, useImperativeHandle, useRef, useState} from 'react'
import commonStyles from '@/assets/styles/common.module.less'
import {
  Button,
  Col,
  DatePicker,
  Descriptions,
  Drawer,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Pagination,
  Popconfirm,
  Row,
  Select,
  Space,
  Table,
} from 'antd'
import {customerModel} from '@/store/models/Customer'
import {teamModel} from '@/store/models/Team'
import {useCodeArraySync} from '@/store/models/Common'
import DividerLine from '@/pages/property/component/DividerLine'
import {Album, UploadButton} from '@/components/Album'
import {baseUrl} from '@/common/config'
import {getImg} from '@/utils/util'

interface Nyg8AccountProps {
  userId: string
  certName: string
  teamId?: string
}

const pointsScareArr = [
  {id: '0', label: '0%'},
  {id: '0.1', label: '10%'},
  {id: '0.2', label: '20%'},
  {id: '0.3', label: '30%'},
  {id: '0.4', label: '40%'},
  {id: '0.5', label: '50%'},
  {id: '0.6', label: '60%'},
  {id: '0.7', label: '70%'},
  {id: '0.8', label: '80%'},
  {id: '0.9', label: '90%'},
  {id: '1', label: '100%'},
]

function Nyg8Account({userId, certName, teamId}: Nyg8AccountProps, ref) {
  const [form] = Form.useForm()
  const [teamList, setTeamList] = useState([] as TeamTreeNodeVO[])
  // 来源
  const source = useCodeArraySync('team.points.source')
  // 类型
  const types = useCodeArraySync('team.points.type')
  const [list, setList] = useState<TeamPointsRecordVO[]>([])
  const [total, setTotal] = useState(0)
  const loading = teamModel.teamPointsRecordList.useLoading()
  const [addVisible, setAddVisible] = useState(false)
  const [addForm] = Form.useForm()
  const [minusVisible, setMinusVisible] = useState(false)
  const [visible, setVisible] = useState(false)
  const [info, setInfo] = useState({} as TeamPointsRecordVO)
  const currentTeamId = useRef(teamId)
  const [pointInfo, setPointInfo] = useState({} as any)

  //组织列表
  const searchTeamTree = useCallback(() => {
    customerModel.customerTeamGetTeamTree({ownerId: userId}).then(({response: {data}}) => {
      setTeamList(data)
      const info = data.find(item => item.teamId === teamId)
      setPointInfo(state => ({...state, teamName: info.name}))
    })
  }, [teamId, userId])

  // 查询充值列表
  const search = useCallback(() => {
    const formValues = form.getFieldsValue(true)
    teamModel
      .teamPointsRecordList({...formValues, recordTeamId: currentTeamId.current})
      .then(({response: {data, total}}) => {
        setList(data)
        setTotal(total)
      })
  }, [form])

  // 查询果币数量
  const queryPoint = useCallback(() => {
    teamModel.teamPointsGetByTeamId({teamId: currentTeamId.current}).then(({response: {data}}) => {
      setPointInfo(state => ({...state, points: data.points, pointsScale: data.pointsScale}))
    })
  }, [])

  useEffect(() => {
    searchTeamTree()
    search()
    queryPoint()
  }, [queryPoint, search, searchTeamTree])

  // 操作
  useImperativeHandle(ref, () => {
    addForm.setFieldsValue({pointsScale: pointInfo.pointsScale})
    return {
      recharge: () => {
        setAddVisible(true)
      },
      minus: () => {
        setMinusVisible(true)
      },
      set: () => {
        setVisible(true)
      },
    }
  })

  // 查询详情
  const queryInfo = useCallback(async (recordId: string) => {
    const {
      response: {data},
    } = await teamModel.teamPointsRecordGet({recordId})
    setInfo(data)
  }, [])

  return (
    <div>
      <Form form={form} initialValues={{pageNum: 1, pageSize: 10}} onFinish={search} onReset={search}>
        <div className={commonStyles.filterSection}>
          <Row>
            <Col>
              <Form.Item label={'客户姓名'}>
                <span>{certName}</span>
              </Form.Item>
            </Col>
            <Col offset={1}>
              <Form.Item label={'组织'}>
                <Select
                  style={{width: '200px'}}
                  placeholder={'请选择'}
                  onSelect={(value, item) => {
                    currentTeamId.current = value
                    queryPoint()
                    search()
                    setPointInfo(state => ({...state, teamName: item?.children}))
                  }}
                  value={currentTeamId.current}
                >
                  {teamList.map(item => (
                    <Select.Option key={item.teamId} value={item.teamId}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col offset={1}>
              <Form.Item label={'果币数量'} shouldUpdate>
                {() => <span>{pointInfo.points}</span>}
              </Form.Item>
            </Col>
            <Col offset={1}>
              <Form.Item label={'抵扣比例'} shouldUpdate>
                {() => <span>{pointInfo.pointsScale}</span>}
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={4}>
              <Form.Item label={'来源'} name={'recordSource'}>
                <Select placeholder={'请选择'} allowClear>
                  {source.map(item => (
                    <Select.Option value={item.id} key={item.id}>
                      {item.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col offset={1} span={4}>
              <Form.Item label={'类型'} name={'recordType'}>
                <Select placeholder={'请选择'} allowClear>
                  {types.map(item => (
                    <Select.Option value={item.id} key={item.id}>
                      {item.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col offset={1} span={14}>
              <Form.Item label={'时间'} name={'time'}>
                <DatePicker.RangePicker
                  showTime
                  onChange={(_, dateStrings) => {
                    const [insertTimeBegin, insertTimeEnd] = dateStrings ?? []
                    form.setFieldsValue({insertTimeBegin, insertTimeEnd})
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row justify={'end'}>
            <Space>
              <Button htmlType={'reset'}>重置</Button>
              <Button
                type={'primary'}
                htmlType={'submit'}
                onClick={() => form.setFieldsValue({pageNum: 1, pageSize: 10})}
              >
                查询
              </Button>
            </Space>
          </Row>
        </div>
        <div className={commonStyles.filterSection}>
          <Table
            style={{marginBottom: 10}}
            loading={loading}
            rowKey={'recordId'}
            dataSource={list}
            columns={[
              {title: '来源', dataIndex: 'recordSourceName'},
              {title: '类型', dataIndex: 'recordTypeName'},
              {title: '数量', dataIndex: 'pointsRecord'},
              {title: '使用后数量', dataIndex: 'pointsNew'},
              {title: '创建人', dataIndex: 'operatorName'},
              {title: '时间', dataIndex: 'insertTime'},
              {title: '状态', dataIndex: 'valid', render: text => (text === 1 ? '有效' : '无效')},
              {
                title: '操作',
                render: record => (
                  <Button
                    type='link'
                    onClick={() => {
                      queryInfo(record.recordId)
                    }}
                  >
                    查看详情
                  </Button>
                ),
              },
            ]}
            pagination={false}
          />
          <Form.Item
            noStyle
            shouldUpdate={(prev, next) => prev.pageNum !== next.pageNum || prev.pageSize !== next.pageSize}
          >
            {f => (
              <Pagination
                total={total}
                current={f.getFieldValue('pageNum')}
                pageSize={f.getFieldValue('pageSize')}
                showSizeChanger
                style={{textAlign: 'center'}}
                onChange={(pageNum, pageSize) => {
                  f.setFieldsValue({pageNum, pageSize})
                  search()
                }}
              />
            )}
          </Form.Item>
        </div>
      </Form>

      {/* 果币详情 */}
      <Drawer
        title={'果币详情'}
        visible={!!info.recordId}
        onClose={() => {
          setInfo({} as TeamPointsRecordVO)
        }}
        width={700}
      >
        <DividerLine title='基本信息' style={{marginBottom: 10}} />
        <Descriptions column={2}>
          <Descriptions.Item label={'客户姓名'}>{certName}</Descriptions.Item>
          <Descriptions.Item label={'组织'}>{pointInfo.teamName}</Descriptions.Item>
          <Descriptions.Item label={'当前果币数量'}>{pointInfo.points}</Descriptions.Item>
          {info.bizId && <Descriptions.Item label={'服务单号'}>{info.bizId}</Descriptions.Item>}
          <Descriptions.Item label={'类型'}>{info.recordTypeName}</Descriptions.Item>
          <Descriptions.Item label={'来源'}>{info.recordSourceName}</Descriptions.Item>
          {info.recordType === '1' ? (
            <>
              <Descriptions.Item label={'充值数量'}>{info.pointsRecord}</Descriptions.Item>
              <Descriptions.Item label={'充值后数量'}>{info.pointsNew}</Descriptions.Item>
            </>
          ) : (
            <>
              <Descriptions.Item label={'划扣数量'}>{info.pointsRecord}</Descriptions.Item>
              <Descriptions.Item label={'划扣后数量'}>{info.pointsNew}</Descriptions.Item>
            </>
          )}
        </Descriptions>
        <Descriptions column={1}>
          <Descriptions.Item label={'图片'}>
            {info.imgUrlList?.length ? (
              <Album disabled fileList={info.imgUrlList.map(item => ({uid: item, url: getImg(item)}))}>
                {null}
              </Album>
            ) : (
              '无'
            )}
          </Descriptions.Item>
        </Descriptions>
        <Descriptions column={1}>
          <Descriptions.Item label={'备注'}>{info.recordNotes ?? '无'}</Descriptions.Item>
        </Descriptions>
        <Descriptions column={2}>
          <Descriptions.Item label={'创建人'}>{info.operatorName}</Descriptions.Item>
          {info.valid === 0 && <Descriptions.Item label={'撤销人'}>{info.cancelName}</Descriptions.Item>}
          <Descriptions.Item label={'创建时间'}>{info.insertTime}</Descriptions.Item>
          {info.valid === 0 && <Descriptions.Item label={'撤销时间'}>{info.cancelTime}</Descriptions.Item>}
          <Descriptions.Item label={'状态'}>{info.valid === 1 ? '有效' : '无效'}</Descriptions.Item>
          {info.valid === 0 && <Descriptions.Item label={'撤销后数量'}>{info.cancelPointsNew}</Descriptions.Item>}
        </Descriptions>
        {info.valid === 1 && !info.bizId && (
          <Space style={{marginTop: 30}}>
            <Button onClick={() => setInfo({} as TeamPointsRecordVO)}>取消</Button>
            <Popconfirm
              placement={'top'}
              okText={'确定'}
              cancelText={'取消'}
              title={`确认撤销这笔${info.recordType === '1' ? '充值' : '划扣'}吗？`}
              onConfirm={async () => {
                await teamModel.teamPointsRecordCancel({recordId: info.recordId})
                message.success('撤销成功！')
                queryInfo(info.recordId)
                search()
                queryPoint()
              }}
            >
              <Button type='primary'>撤销{`${info.recordType === '1' ? '充值' : '划扣'}`}</Button>
            </Popconfirm>
          </Space>
        )}
      </Drawer>

      {/* 果币充值 */}
      <Modal
        title={'果币充值'}
        centered
        visible={addVisible}
        onCancel={() => {
          setAddVisible(false)
        }}
        afterClose={() => {
          addForm.resetFields()
        }}
        destroyOnClose
        footer={null}
      >
        <Form
          form={addForm}
          onFinish={async () => {
            const {points, imgUrlList, notes} = addForm.getFieldsValue(true)
            await teamModel.teamPointsAdd({teamId: currentTeamId.current, points, imgUrlList, notes})
            search()
            queryPoint()
            message.success('充值成功！')
            setAddVisible(false)
          }}
          onReset={() => {
            setAddVisible(false)
          }}
        >
          <Form.Item label={'客户姓名'}>
            <span>{certName}</span>
          </Form.Item>
          <Form.Item label={'组织'} shouldUpdate>
            {() => <span>{pointInfo.teamName}</span>}
          </Form.Item>
          <Form.Item label={'充值数量'} name={'points'} rules={[{required: true, message: '请输入充值数量'}]}>
            <InputNumber placeholder='请输入' min={1} />
          </Form.Item>
          <Form.Item label={'图片'} shouldUpdate>
            {() => {
              const imgUrlList = addForm.getFieldValue('imgUrlList') ?? []
              return (
                <Album
                  action={baseUrl + '/team/points/upload-file'}
                  accept={'.jpg, .jpeg, .png'}
                  onDone={fileList => {
                    addForm.setFieldsValue({imgUrlList: fileList.map(item => item.imgUrl)})
                  }}
                  limit={1}
                >
                  {imgUrlList.length ? null : <UploadButton />}
                </Album>
              )
            }}
          </Form.Item>
          <Form.Item label={'备注'} name={'notes'} wrapperCol={{span: 15}}>
            <Input.TextArea placeholder='请输入备注' allowClear maxLength={200} rows={3} />
          </Form.Item>
          <Form.Item label={' '} colon={false}>
            <Row justify='end'>
              <Space>
                <Button htmlType='reset'>取消</Button>
                <Button type='primary' htmlType='submit'>
                  确认充值
                </Button>
              </Space>
            </Row>
          </Form.Item>
        </Form>
      </Modal>

      {/* 果币划扣 */}
      <Modal
        title={'果币划扣'}
        centered
        visible={minusVisible}
        onCancel={() => {
          setMinusVisible(false)
        }}
        afterClose={() => {
          addForm.resetFields()
        }}
        destroyOnClose
        footer={null}
      >
        <Form
          form={addForm}
          onFinish={async () => {
            const {points, imgUrlList, notes} = addForm.getFieldsValue(true)
            await teamModel.teamPointsMinus({
              teamId: currentTeamId.current,
              points,
              imgUrlList: imgUrlList ?? [],
              notes,
            } as TeamPointsMinusDTO)
            search()
            queryPoint()
            message.success('划扣成功！')
            setMinusVisible(false)
          }}
          onReset={() => {
            setMinusVisible(false)
          }}
        >
          <Form.Item label={'客户姓名'}>
            <span>{certName}</span>
          </Form.Item>
          <Form.Item label={'组织'} shouldUpdate>
            {() => <span>{pointInfo.teamName}</span>}
          </Form.Item>
          <Form.Item
            label={'划扣数量'}
            name={'points'}
            rules={[
              {
                required: true,
                message: '请输入划扣数量',
              },
              () => ({
                validator: (_, value, callback) => {
                  const points = pointInfo.points
                  if (value && value >= points) {
                    return Promise.reject(new Error('划扣数量不可多余现有数量'))
                  }
                  callback()
                },
              }),
            ]}
          >
            <InputNumber placeholder='请输入' min={1} />
          </Form.Item>
          <Form.Item label={'图片'} shouldUpdate>
            {() => {
              const imgUrlList = addForm.getFieldValue('imgUrlList') ?? []
              return (
                <Album
                  action={baseUrl + '/team/points/upload-file'}
                  accept={'.jpg, .jpeg, .png'}
                  onDone={fileList => {
                    addForm.setFieldsValue({imgUrlList: fileList.map(item => item.imgUrl)})
                  }}
                >
                  {imgUrlList.length ? null : <UploadButton />}
                </Album>
              )
            }}
          </Form.Item>
          <Form.Item label={'备注'} name={'notes'} wrapperCol={{span: 15}}>
            <Input.TextArea placeholder='请输入备注' allowClear maxLength={200} rows={3} />
          </Form.Item>
          <Form.Item label={' '} colon={false}>
            <Row justify='end'>
              <Space>
                <Button htmlType='reset'>取消</Button>
                <Button type='primary' htmlType='submit'>
                  确认划扣
                </Button>
              </Space>
            </Row>
          </Form.Item>
        </Form>
      </Modal>

      {/* 设置折扣比例 */}
      <Modal
        title={'设置折扣比例'}
        centered
        visible={visible}
        onCancel={() => {
          setVisible(false)
        }}
        afterClose={() => {
          addForm.resetFields()
        }}
        destroyOnClose
        footer={null}
      >
        <Form
          form={addForm}
          onFinish={async () => {
            const {pointsScale} = addForm.getFieldsValue(true)
            await teamModel.teamPointsSetScale({teamId: currentTeamId.current, pointsScale})
            message.success('设置成功！')
            queryPoint()
            setVisible(false)
          }}
          onReset={() => {
            setVisible(false)
          }}
        >
          <Form.Item label={'客户姓名'}>
            <span>{certName}</span>
          </Form.Item>
          <Form.Item label={'组织'} shouldUpdate>
            {() => <span>{pointInfo.teamName}</span>}
          </Form.Item>
          <Form.Item
            label={'折扣比例'}
            shouldUpdate
            name={'pointsScale'}
            rules={[{required: true, message: '请输入折扣比例'}]}
          >
            <Select placeholder={'请选择'} style={{width: 180}} allowClear>
              {pointsScareArr.map(item => (
                <Select.Option value={item.id} key={item.id}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label={' '} colon={false}>
            <Row justify='end'>
              <Space>
                <Button htmlType='reset'>取消</Button>
                <Button type='primary' htmlType='submit'>
                  确认
                </Button>
              </Space>
            </Row>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  )
}

export default React.forwardRef(Nyg8Account)
