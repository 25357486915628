import React, {useEffect, useState} from 'react'
import {Button, Checkbox, Form, Input, message, Space, Switch, Typography, Upload, Row, Col, Transfer} from 'antd'
import {useLocation, useHistory} from 'react-router-dom'
import {PlusCircleFilled} from '@ant-design/icons'
import {stewardModel} from '@/store/models/Steward'
import {baseUrl} from '@/common/config'
import {getImg} from '@/utils/util'
// import styles from './index.module.less'
import commonStyles from '@/assets/styles/common.module.less'
import {SortableService} from '@/pages/steward/SortableService'
import {createHeaders} from '@/store/service'
import {Page} from '@/components/Page'
import BackButton from '@/components/BackButton'
import {commonModel} from '@/store/models/Common'
import {storageModel} from '@/store/models/Storage'

const rules = {
  name: [{required: true, message: '服务名称不能为空'}],
  desc: [{required: true, message: '服务内容不能为空'}],
  name2: [{required: true, message: '自费项目不能为空'}],
  priceDefault: [{required: true, message: '资费金额不能为空'}],
  // materialIdList: [{required: true, message: '耗材列表不能为空'}],
  // priceMin: [{required: true, message: '资费金额最小值不能为空'}],
  // priceMax: [{required: true, message: '资费金额最大值不能为空'}],
  imgUrlThumb: [{required: true, message: '缩略图不能为空'}],
  imgUrlContent: [{required: true, message: '大图不能为空'}],
  // permLessee: [{required: true, message: '服务名称不能为空'}],
  // permLessor: [{required: true, message: '服务名称不能为空'}],
}

// const cityCodes = [
//   {id: '310100', label: '上海'},
//   {id: '440100', label: '广州'},
// ]

export default function StewardServiceAdd() {
  const location = useLocation<StewardServiceVO>()
  const history = useHistory()
  const [form] = Form.useForm()

  // 临时 tags，取消的时候用的
  const [_tags, _setTags] = useState<string[]>([])
  const [tags, setTags] = useState<string[]>([])
  const [sortMode, setSortMode] = useState(false)
  const [editMode, setEditMode] = useState(false)
  const [emergency, setEmergency] = useState(0)
  const [tagsEmergency, setTagsEmergency] = useState<string[]>([])

  const [chargeList, setChargeList] = useState<StewardServiceChargeVO[]>([])
  const [chargeKeys, setChargeKeys] = useState<string[]>([])
  const [warrantyTag, setWarrantyTag] = useState(0)

  const [skuKeys, setSkuKeys] = useState<string[]>([])
  const [materialList, setMaterialList] = useState<StewardMaterialListVO[]>([])

  const [cityCodes, setCityCodes] = useState<CityDataProps[]>([])
  const [targetCitys, setTargetCitys] = useState<string[]>([])

  useEffect(() => {
    if (location.state?.id) {
      stewardModel.stewardAdminServiceDetail({serviceId: location.state?.id}).then(({response: {data}}) => {
        setEmergency(data.emergency)
        const {serviceFeeList, ...rest} = data
        if (data.tags?.length) setTags(data.tags.split(','))
        if (data.tagsEmergency?.length) setTagsEmergency(data.tagsEmergency.split(','))
        form.setFieldsValue({
          ...rest,
          cityCode: data.cityCode ? data.cityCode.split(',') : [],
          ...(serviceFeeList.length
            ? {feeList: serviceFeeList.map(item => ({desc: item?.desc, name: item?.name}))}
            : {}),
          chargeIdList: data.serviceChargeIdList,
          materialIdList: data.materialList.map(item => item.materialId),
        })
        setSkuKeys(data.materialList.map(item => item.materialId))
        setChargeKeys(data.serviceChargeIdList)
        setWarrantyTag(data.warrantyTag)
        setTargetCitys(data.cityCode ? data.cityCode.split(',') : [])
      })
    }
  }, [form, location.state?.id])

  useEffect(() => {
    stewardModel
      .stewardAdminServiceChargeList({pageable: false, enable: 1, categoryId: '1'} as StewardServiceChargeQueryDTO)
      .then(({response: {data}}) => setChargeList(data))

    commonModel.queryCityCode({type: '2', parentCode: ''}).then(({response: {data}}) => setCityCodes(data))

    storageModel
      .stewardWarehouseMaterialList({pageable: false, pageNum: 1, pageSize: 1} as StewardMaterialListDTO)
      .then(({response: {data}}) => {
        setMaterialList(data)
      })
  }, [])

  async function submit() {
    if (!tags.length) return message.error('故障现象不能为空！')
    const {cityCode, ...value}: any = form.getFieldsValue(true)
    await stewardModel.stewardServiceUpset(
      {
        ...value,
        level: 3,
        pid: location.state.pid,
        tags: tags.join(','),
        cityCode: cityCode.join(','),
        emergency,
        tagsEmergency: tagsEmergency.join(','),
      },
      2
    )
    message.success('提交成功')
    history.goBack()
  }

  return (
    <Page>
      <BackButton />
      <div className={commonStyles.filterSection}>
        <Form
          form={form}
          initialValues={{permLessee: '0', permLessor: '0', feeList: [], cityCode: []}}
          labelCol={{span: 3}}
          onFinish={submit}
        >
          <Row>
            <Col span={12}>
              <Form.Item labelCol={{span: 6}} label={'服务名称'} name={'name'} rules={rules.name}>
                <Input placeholder={'请输入服务名称'} allowClear />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item labelCol={{span: 4}} label={'设为紧急'} colon={false}>
                <Switch
                  size={'small'}
                  checked={emergency === 1}
                  onChange={value => (value ? setEmergency(1) : setEmergency(0))}
                />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item labelCol={{span: 3}} label={'服务内容'} name={'desc'} rules={rules.desc}>
            <Input.TextArea autoSize={{minRows: 3, maxRows: 8}} allowClear style={{width: 360}} />
          </Form.Item>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, nextValues) => prevValues.warrantyTag !== nextValues.warrantyTag}
          >
            {form1 => {
              const isWarranty = form1.getFieldValue('warrantyTag')
              return (
                <>
                  <Form.Item label={'保修'}>
                    <span
                      onClick={() => {
                        if (warrantyTag === 1) {
                          return message.warn('请联系管理员！')
                        } else form1.setFieldsValue({warrantyTag: isWarranty === 1 ? 0 : 1})
                      }}
                    >
                      <Switch checked={isWarranty} />
                    </span>
                  </Form.Item>
                  {isWarranty === 1 && (
                    <>
                      <Form.Item
                        label={'保修说明'}
                        name={'warrantyDesc'}
                        rules={[{required: true, message: '请输入保修说明'}]}
                      >
                        <Input.TextArea
                          placeholder={'请输入'}
                          maxLength={200}
                          autoSize={{minRows: 3, maxRows: 8}}
                          style={{width: 360}}
                          allowClear
                        />
                      </Form.Item>
                      <Form.Item
                        label={'免责条款'}
                        name={'warrantyExceptions'}
                        rules={[{required: true, message: '请输入免责条款'}]}
                      >
                        <Input.TextArea
                          placeholder={'请输入'}
                          maxLength={200}
                          autoSize={{minRows: 3, maxRows: 8}}
                          style={{width: 360}}
                          allowClear
                        />
                      </Form.Item>
                    </>
                  )}
                </>
              )
            }}
          </Form.Item>
          <Form.Item label={'故障现象'} required style={{transform: 'translateX(-30px)'}}>
            <Space align={'start'} size={10}>
              <PlusCircleFilled
                style={{color: '#00b7ae', fontSize: 20, marginTop: 5}}
                onClick={() => {
                  if (tags.length < 15) {
                    setEditMode(true)
                  } else {
                    message.info('最多只能存在15个故障现象')
                  }
                }}
              />
              <SortableService
                value={tags}
                onChange={setTags}
                sortMode={sortMode}
                emergency={tagsEmergency}
                onChangeEmergency={setTagsEmergency}
              >
                <Space>
                  {editMode && (
                    <Input
                      autoFocus
                      maxLength={6}
                      style={{textAlign: 'center'}}
                      placeholder={'请输入'}
                      onBlur={event => {
                        if (event.target.value) setTags(prevState => [...prevState, event.target.value])
                        setEditMode(false)
                      }}
                      allowClear
                    />
                  )}
                  {tags.length > 1 &&
                    (!sortMode ? (
                      <Button
                        type={'primary'}
                        onClick={() => {
                          setSortMode(true)
                          _setTags(tags)
                        }}
                      >
                        排序
                      </Button>
                    ) : (
                      <>
                        <Button type={'primary'} onClick={() => setSortMode(false)}>
                          保存
                        </Button>
                        <Button
                          onClick={() => {
                            setSortMode(false)
                            setTags(_tags)
                          }}
                        >
                          取消
                        </Button>
                      </>
                    ))}
                </Space>
              </SortableService>
            </Space>
          </Form.Item>
          <Form.Item label={'缩略图上传'} required shouldUpdate style={{marginBottom: 0}}>
            {() => (
              <Form.Item name={'imgUrlThumb'} rules={rules.imgUrlThumb}>
                <UploadImg
                  imageUrl={form.getFieldValue('imgUrlThumb')}
                  onDone={imgUrl => form.setFieldsValue({imgUrlThumb: imgUrl})}
                />
              </Form.Item>
            )}
          </Form.Item>
          <Form.Item label={'大图上传'} required shouldUpdate style={{marginBottom: 0}}>
            {() => (
              <Form.Item name={'imgUrlContent'} rules={rules.imgUrlContent}>
                <UploadImg
                  imageUrl={form.getFieldValue('imgUrlContent')}
                  onDone={imgUrl => form.setFieldsValue({imgUrlContent: imgUrl})}
                />
              </Form.Item>
            )}
          </Form.Item>
          <Form.Item label={'服务对象'} required shouldUpdate>
            {() => (
              <>
                <Checkbox
                  checked={form.getFieldValue('permLessee') === '1'}
                  onChange={e => form.setFieldsValue({permLessee: e.target.checked ? '1' : '0'})}
                >
                  租客
                </Checkbox>
                <Checkbox
                  checked={form.getFieldValue('permLessor') === '1'}
                  onChange={e => form.setFieldsValue({permLessor: e.target.checked ? '1' : '0'})}
                >
                  房东
                </Checkbox>
              </>
            )}
          </Form.Item>
          <Form.Item label={'服务地区'} name={'cityCode'}>
            <Transfer
              rowKey={item => item.code}
              dataSource={cityCodes}
              render={item => item.name}
              targetKeys={targetCitys}
              onChange={targetKeys => setTargetCitys(targetKeys)}
              showSearch
              filterOption={(inputValue, item) =>
                item.name.indexOf(inputValue as any) > -1 || item.name.indexOf(inputValue as any) > -1
              }
              listStyle={{width: '30%', height: 300}}
            />
          </Form.Item>

          <Form.Item name={'materialIdList'} label={'可用耗材'}>
            <Transfer
              rowKey={item => item.materialId}
              dataSource={materialList}
              render={item => `${item.sku}/${item.name}`}
              targetKeys={skuKeys}
              onChange={targetKeys => setSkuKeys(targetKeys)}
              showSearch
              filterOption={(inputValue, item) =>
                item.name.indexOf(inputValue as any) > -1 || item.sku.indexOf(inputValue as any) > -1
              }
              listStyle={{width: '30%', height: 300}}
            />
          </Form.Item>

          <Form.Item name={'chargeIdList'} label={'服务收费'} rules={[{required: true, message: '请选择服务收费项目'}]}>
            <Transfer
              rowKey={item => item.chargeId}
              dataSource={chargeList}
              render={item => item.chargeName}
              targetKeys={chargeKeys}
              onChange={targetKeys => setChargeKeys(targetKeys)}
              showSearch
              filterOption={(inputValue, item) => item.chargeName.indexOf(inputValue as any) > -1}
              listStyle={{width: '30%', height: 300}}
            />
          </Form.Item>

          <Form.Item label={' '} colon={false}>
            <Space>
              <Button type={'primary'} htmlType={'submit'} loading={stewardModel.stewardServiceUpset.useLoadings(2)}>
                保存并提交
              </Button>
              <Button onClick={() => history.goBack()}>取消</Button>
            </Space>
          </Form.Item>
        </Form>
      </div>
    </Page>
  )
}

function UploadImg(props: {imageUrl: string; onDone?: (imgUrl: string) => any}) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loading, setLoading] = useState(false)

  return (
    <>
      <Upload
        name='file'
        accept={'image/*'}
        listType='picture-card'
        showUploadList={false}
        headers={createHeaders()}
        action={baseUrl + '/steward/admin/service/upload-img'}
        onChange={info => {
          if (info.file.status === 'uploading') {
            setLoading(true)
          } else if (info.file.status === 'done') {
            setLoading(false)
            props.onDone && props.onDone(info.file.response.data)
          }
        }}
      >
        {props.imageUrl ? (
          <img src={getImg(props.imageUrl)} alt='avatar' style={{width: '100%'}} />
        ) : (
          <Space direction={'vertical'}>
            {/* {loading ? <LoadingOutlined /> : <PlusCircleOutlined />} */}
            <Typography.Text>上传图片</Typography.Text>
          </Space>
        )}
      </Upload>
      <Typography.Text>请上传 .jpg 或 .png 格式图片，最多1张</Typography.Text>
    </>
  )
}
