import {Model} from '@redux-model/web'
import {$api} from '@/store/service'

interface ClientModel {}

class Client extends Model<ClientModel> {
  protected initReducer(): ClientModel {
    return {}
  }

  // 客户列表
  // customerList = $api.action((args: Partial<LessorCustomerListDTO>) =>
  //   this.post<ApiResponse<LessorCustomerListVO[]>>('/customer/list').body(args)
  // )

  // 团队-余额-充值-记录列表
  // teamBalanceChargeList = $api.action((args: Partial<TeamWalletChargeQueryDTO>) =>
  //   this.post<ApiResponse<TeamBalanceChargeListVO[]>>('/team/balance/charge/list').body({
  //     pageNum: 1,
  //     pageSize: 10,
  //     pageable: true,
  //     ...args,
  //   })
  // )

  // 团队-余额-充值
  // teamBalanceChargeMake = $api.action((args: Partial<TeamBalanceChargeDTO>) =>
  //   this.post('/team/balance/charge/make').body(args)
  // )

  // 团队-余额-充值-记录详情  |  object:{chargeId:充值记录id}
  // teamBalanceChargeDetail = $api.action((chargeId: string) =>
  //   this.post<ApiResponse<TeamBalanceChargeListVO>>('/team/balance/charge/detail').body({chargeId})
  // )

  // 团队-余额-充值作废  |  object:{chargeId:充值记录id}
  // teamBalanceChargeCancel = $api.action((chargeId: string) => this.post('/team/balance/charge/cancel').body({chargeId}))

  // 查询录入过的小区
  // customerCommunityList = $api.action((args: {userId: string}) =>
  //   this.post<ApiResponse<CommunityVO[]>>('/customer/community-list').body(args)
  // )

  // 团队-余额-扣款-记录列表
  // teamBalanceRecordList = $api.action((args: Partial<TeamWalletRecordQueryDTO>) =>
  //   this.post<ApiResponse<TeamWalletRecordVO[]>>('/team/balance/record/list').body({
  //     pageNum: 1,
  //     pageSize: 10,
  //     pageable: true,
  //     ...args,
  //   })
  // )

  // 团队-余额-扣款-新增
  // teamBalanceRecordInsert = $api.action((args: Partial<TeamBalanceRecordInsertDTO>) =>
  //   this.post('/team/balance/record/insert').body(args)
  // )

  //团队-余额-扣款-记录详情  |  object:{recordId:记录id}
  // teamBalanceRecordDetail = $api.action((recordId: string) =>
  //   this.post<ApiResponse<TeamWalletRecordVO>>('/team/balance/record/detail').body({recordId})
  // )

  // 团队-余额-扣款-作废  |  object:{recordId:扣款记录id}
  // teamBalanceRecordCancel = $api.action((recordId: string) => this.post('/team/balance/record/cancel').body({recordId}))

  // 客户-编辑
  // customerUpsert = $api.action((args: LessorInsertDTO) => this.post<ApiResponse<string>>('/customer/upsert').body(args))

  // // 对公转账审核列表
  // customerChargePendingList = $api.action((args: Partial<CustomerChargePendingListDTO>) =>
  //   this.post<ApiResponse<CustomerChargePendingVO[]>>('/customer/charge/pending/list').body(args)
  // )

  // 对公转账审核
  // customerChargePending = $api.action((args: {applyId: string; chargeStatus: 'success' | 'fail'; reason: string}) =>
  //   this.post('/customer/charge/pending').body(args)
  // )

  // 客户详情
  // customerInfo = $api.action((args: {userId: string}) =>
  //   this.post<ApiResponse<LessorCustomerVO>>('/customer/info').body(args)
  // )

  // 客户-详情-查看合同  |  userId 客户id
  // customerContractInfo = $api.action((args: {userId: string}) =>
  //   this.post<ApiResponse<SaleCustomerContractVO>>('/customer/contract-info').body(args)
  // )

  // 客户详情
  weworkSyncAddrBook = $api.action(() => this.post<ApiResponse>('/wework/sync-addr-book'))

  // 统计列表
  // customerReportList = $api.action((args: LessorCustomerReportQueryDTO) =>
  //   this.post<ApiResponse<LessorCustomerReportVO[]>>('/customer/report/list').body(args)
  // )

  // 团队-余额-列表
  // teamBalanceList = $api.action((args: Partial<TeamBalanceListQueryDTO>) =>
  //   this.post<ApiResponse<TeamBalanceVO[]>>('/team/balance/list').body({
  //     pageSize: 10,
  //     pageNum: 1,
  //     pageable: true,
  //     ...args,
  //   })
  // )

  // 确认账单状态（确认中-代收款）
  // userLessorBillPreConfirm = $api.action((args: LessorBillPreConfirmDTO) =>
  //   this.post('/user/lessor/bill/pre-confirm').body(args)
  // )

  // 客户-详情-合同配置tree  |  userId 客户id
  // customerContractPropertyConfig = $api.action((args: {userId: string}) =>
  //   this.post<ApiResponse<SaleCustomerPropertyNodeVO[]>>('/customer/contract-property-config').body(args)
  // )

  // 客户-详情-保存合同配置tree  |  userId 客户id
  // customerContractPropertyConfigSave = $api.action((args: LessorContractConfigDTO) =>
  //   this.post<ApiResponse<Record<string, any>>>('/customer/contract-property-config/save').body(args)
  // )

  // 客户-详情-房源推广-更新  |  object:{userId:客户Id, propertyPromote:推广（0：否、1：是）}
  // customerPropertyPromoteUpdate = $api.action((args: {userId: string; propertyPromote: '0' | '1'}) =>
  //   this.post<ApiResponse<Record<string, any>>>('/customer/property-promote/update').body(args)
  // )

  // 更新合租房源对应的公区标题
  // customerPropertyRecordList = $api.action((args: PropertyRecordListDTO) =>
  //   this.post<ApiResponse<PropertyRecordVO[]>>('/customer/property/record/list').body({
  //     pageNum: 1,
  //     pageSize: 10,
  //     pageable: true,
  //     ...args,
  //   })
  // )

  // 更新客户的公寓banner  |  object:{userId:客户id,imgUrl:图片url}
  // customerCondoBannerUpdate = $api.action((args: {userId: string; imgUrl: string}) =>
  //   this.post<ApiResponse<Record<string, any>>>('/customer/condo-banner/update').body(args)
  // )
}

export const clientModel = new Client()
