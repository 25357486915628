import {Model} from '@redux-model/web'
import {$api} from '@/store/service'

export interface FileUploadModel {}

class FileUpload extends Model<FileUploadModel> {
  deleteFile = $api.action(params => this.post('/file/delete').body(params))

  initReducer() {
    return {}
  }
}

export const fileUploadModel = new FileUpload()
