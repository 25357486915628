import React, {useCallback, useEffect, useState} from 'react'
import {Page} from '@/components/Page'
import {Breadcrumb} from '@/components/Breadcrumb'
import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Select,
  DatePicker,
  Space,
  Table,
  Modal,
  Radio,
  message,
  Descriptions,
} from 'antd'
import commonStyles from '@/assets/styles/common.module.less'
import {sysModel} from '@/store/models/Sys'
import {getPaginationProps} from '@/common/biz/getPaginationProps'
import {customerModel} from '@/store/models/Customer'
import {debounce} from '@/utils/util'
import {usePermission} from '@/components/Permission'

export default function CustomerWhiteList() {
  const [list, setList] = useState<SysWhitelistVO[]>([])
  const [total, setTotal] = useState<number>(0)
  const [form] = Form.useForm()
  const [visible, setVisible] = useState(false)
  const [addForm] = Form.useForm()
  const [customerList, setCustomerList] = useState<LessorCustomerListVO[]>([])
  const [auth] = usePermission()

  const search = useCallback(() => {
    const formValues = form.getFieldsValue(true)
    sysModel.sysWhitelistList(formValues).then(({response: {data, total}}) => {
      setList(data)
      setTotal(total)
    })
  }, [form])

  const searchCustomerList = useCallback(
    debounce((certName: string) => {
      customerModel
        .customerList({pageNum: 1, pageSize: 10, pageable: true, status: '1', certName} as LessorCustomerListDTO)
        .then(({response: {data}}) => {
          setCustomerList(data)
        })
    }),
    []
  )
  useEffect(() => {
    form.submit()

    searchCustomerList('')
  }, [form, searchCustomerList])

  return (
    <Page
      breadcrumb={
        <Breadcrumb
          right={
            auth('003136002') && (
              <Button type={'primary'} onClick={() => setVisible(true)}>
                添加
              </Button>
            )
          }
        />
      }
    >
      <div className={commonStyles.filterSection}>
        <Form form={form} onFinish={search} onReset={search} initialValues={{pageNum: 1, pageSize: 10, pageable: true}}>
          <Row gutter={[20, 0]}>
            <Col span={8}>
              <Form.Item label={'客户名称'} name={'certName'}>
                <Input placeholder={'请输入'} allowClear />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={'客户手机号'} name={'mobile'}>
                <Input placeholder={'请输入'} allowClear />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={'状态'} name={'valid'}>
                <Select placeholder={'请选择'} allowClear>
                  <Select.Option value={1}>有效</Select.Option>
                  <Select.Option value={0}>无效</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={'新增时间'} name={'_time'}>
                <DatePicker.RangePicker
                  showTime
                  onChange={(_, dateStrings) => {
                    const [insertTimeBegin, insertTimeEnd] = dateStrings ?? []
                    form.setFieldsValue({insertTimeBegin, insertTimeEnd})
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row justify={'end'}>
            <Space>
              <Button type={'primary'} htmlType={'submit'} onClick={() => form.setFieldsValue({pageNum: 1})}>
                查询
              </Button>
              <Button htmlType={'reset'}>重置</Button>
            </Space>
          </Row>
        </Form>
      </div>
      <div className={commonStyles.filterSection}>
        <Table
          dataSource={list}
          rowKey={'userId'}
          pagination={getPaginationProps(form, total)}
          columns={[
            {title: '客户名称', dataIndex: 'certName'},
            {title: '原因', dataIndex: 'reason'},
            {title: '客户手机号', dataIndex: 'mobile'},
            {title: '状态', dataIndex: 'valid', render: value => (value === 1 ? '有效' : '无效')},
            {title: '操作人', dataIndex: 'operatorName'},
            {title: '新增时间', dataIndex: 'insertTime'},
            {
              title: '操作',
              render: (_, record) => {
                return (
                  <Space>
                    {auth('001136002') && (
                      <Button
                        type={'link'}
                        style={{paddingLeft: 0, paddingRight: 0}}
                        onClick={() => {
                          Modal.info({
                            title: '查看',
                            icon: null,
                            centered: true,
                            // autoFocusButton: 'cancel',
                            okText: '关闭',
                            content: (
                              <Descriptions column={1}>
                                <Descriptions.Item label={'客户名称'}>{record.certName}</Descriptions.Item>
                                <Descriptions.Item label={'客户手机号'}>{record.mobile}</Descriptions.Item>
                                <Descriptions.Item label={'白名单原因'}>{record.reason}</Descriptions.Item>
                                <Descriptions.Item label={'状态'}>
                                  {record.valid === 1 ? '有效' : '无效'}
                                </Descriptions.Item>
                              </Descriptions>
                            ),
                          })
                        }}
                      >
                        查看
                      </Button>
                    )}
                    {auth('002136002') && (
                      <Button
                        type={'link'}
                        style={{paddingLeft: 0, paddingRight: 0}}
                        onClick={() => {
                          setVisible(true)
                          addForm.setFieldsValue({
                            userId: record.userId,
                            mobile: record.mobile,
                            certName: record.certName,
                            reason: record.reason,
                            valid: record.valid,
                            disabled: true,
                          })
                        }}
                      >
                        编辑
                      </Button>
                    )}
                    <Button
                      type={'link'}
                      style={{paddingLeft: 0, paddingRight: 0}}
                      onClick={() => {
                        Modal.info({
                          title: '日志',
                          icon: null,
                          centered: true,
                          width: 800,
                          okText: '关闭',
                          content: (
                            <Table
                              dataSource={record.logList.reverse()}
                              rowKey={'operateTime'}
                              columns={[
                                {title: '操作人', dataIndex: 'operatorName', width: 100},
                                {title: '操作时间', dataIndex: 'operateTime', width: 120},
                                {title: '操作类型', dataIndex: 'operationType', width: 100},
                                {title: '内容', dataIndex: 'content'},
                              ]}
                              pagination={{total: record.logList.length, position: ['bottomCenter']}}
                            />
                          ),
                        })
                      }}
                    >
                      日志
                    </Button>
                  </Space>
                )
              },
            },
          ]}
        />
      </div>

      {/* 新增和编辑  */}
      <Modal
        visible={visible}
        destroyOnClose
        afterClose={() => addForm.resetFields()}
        title={addForm.getFieldValue('disabled') ? '编辑' : '新增'}
        centered
        onCancel={() => setVisible(false)}
        footer={null}
      >
        <Form
          form={addForm}
          labelCol={{span: 5}}
          labelAlign={'left'}
          wrapperCol={{span: 15}}
          onFinish={async () => {
            const values = addForm.getFieldsValue(true)
            if (addForm.getFieldValue('disabled')) {
              await sysModel.sysWhitelistUpdate({userId: values.userId, reason: values.reason, valid: values.valid})
              message.success('编辑成功！')
            } else {
              await sysModel.sysWhitelistInsert(values)
              message.success('新增成功！')
            }
            search()
            setVisible(false)
          }}
          onReset={() => {
            setVisible(false)
          }}
        >
          <Form.Item label={'客户名称'} name={'userId'} rules={[{required: true, message: '请输入客户名称'}]}>
            <Select
              placeholder={'请选择'}
              allowClear
              showSearch
              disabled={addForm.getFieldValue('disabled')}
              filterOption={false}
              onSearch={searchCustomerList}
              onSelect={value => {
                const {mobile, certName} = customerList.find(item => item.userId === value)
                addForm.setFieldsValue({mobile, certName})
              }}
            >
              {customerList.map(item => (
                <Select.Option value={item.userId} key={item.userId}>
                  {`${item.certName}（${item.mobile}）`}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label={'客户手机号'} name={'mobile'}>
            <Input placeholder={'请输入'} disabled />
          </Form.Item>
          <Form.Item label={'白名单原因'} name={'reason'} rules={[{required: true, message: '请输入白名单原因'}]}>
            <Input placeholder={'请输入'} />
          </Form.Item>
          <Form.Item label={'状态'} name={'valid'} rules={[{required: true, message: '请选择状态'}]}>
            <Radio.Group>
              <Radio value={1}>有效</Radio>
              <Radio value={0}>无效</Radio>
            </Radio.Group>
          </Form.Item>
          <Row justify={'end'}>
            <Space>
              <Button htmlType={'reset'}>取消</Button>
              <Button type={'primary'} htmlType={'submit'}>
                确定
              </Button>
            </Space>
          </Row>
        </Form>
      </Modal>
    </Page>
  )
}
