import React, {useImperativeHandle, useRef, useState} from 'react'
import {Button, Form, Input, Modal, Row, Space, Typography} from 'antd'
import {getImg} from '@/utils/util'
import {lessorModel} from '@/store/models/Lessor'
import LookImage from '@/pages/client/components/LookImage'

const {Text} = Typography
function AuditBill(props, ref) {
  const [visible, setVisible] = useState(false)
  const [approveForm] = Form.useForm()
  const imgRef = useRef(null)

  useImperativeHandle(ref, () => ({
    onDisplay: () => {
      setVisible(true)
    },
    onSetInfo: (detail: LessorBillVO) => {
      const {imgUrl, billId, billPayAmt, billPayWayName, billDetailRemark, billName, billPayTime} = detail
      approveForm.setFieldsValue({
        imgUrl,
        billId,
        billPayAmt,
        billPayWayName,
        billDetailRemark,
        billName,
        billPayTime,
      })
    },
  }))

  return (
    <Modal
      visible={visible}
      title={
        <div style={{textAlign: 'center'}}>
          <Text>账单审核</Text>
        </div>
      }
      centered
      footer={null}
      onCancel={() => setVisible(false)}
    >
      <Form form={approveForm}>
        <Form.Item name={'billName'} label={'付款方式'}>
          <Input disabled style={{border: 'none', color: '#000', backgroundColor: '#fff'}} />
        </Form.Item>
        <Form.Item name={'billPayAmt'} label={'收款金额'}>
          <Input disabled style={{border: 'none', color: '#000', backgroundColor: '#fff'}} />
        </Form.Item>
        <Form.Item name={'billPayWayName'} label={'收款方式'}>
          <Input disabled style={{border: 'none', color: '#000', backgroundColor: '#fff'}} />
        </Form.Item>
        <Form.Item name={'billPayTime'} label={'收款日期'}>
          <Input disabled style={{border: 'none', color: '#000', backgroundColor: '#fff'}} />
        </Form.Item>
        <Form.Item label={'照片凭证'}>
          {approveForm.getFieldValue('imgUrl') ? (
            <img
              src={getImg(approveForm.getFieldValue('imgUrl'))}
              style={{width: '102px', height: '102px'}}
              onClick={() => {
                imgRef.current.onDisplay(approveForm.getFieldValue('imgUrl'))
              }}
            />
          ) : (
            <Text>无</Text>
          )}
        </Form.Item>
        <Form.Item name={'billDetailRemark'} label={'备注'}>
          {approveForm.getFieldValue('billDetailRemark') ? (
            <Input disabled style={{border: 'none', color: '#000', backgroundColor: '#fff'}} />
          ) : (
            <Text>无</Text>
          )}
        </Form.Item>
        <Row justify={'end'}>
          <Space>
            <Button onClick={() => setVisible(false)}>取消</Button>
            <Button
              type={'primary'}
              onClick={async () => {
                await lessorModel.userLessorBillApprove({billId: approveForm.getFieldValue('billId')})
                props.onSuccess()
                setVisible(false)
              }}
            >
              审核通过
            </Button>
          </Space>
        </Row>
      </Form>

      {/* 查看图片 */}
      <LookImage ref={imgRef} />
    </Modal>
  )
}

export default React.forwardRef(AuditBill)
