import React, {useCallback, useEffect, useImperativeHandle, useRef, useState} from 'react'
import {
  Button,
  Col,
  DatePicker,
  Descriptions,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Row,
  Select,
  Space,
  Table,
  Upload,
  Radio,
  Typography,
  Divider,
  Cascader,
} from 'antd'
import {createHeaders} from '@/store/service'
import {baseUrl} from '@/common/config'
import {UploadOutlined, CloseOutlined} from '@ant-design/icons'
import {Album, UploadButton} from '@/components/Album'
import {stewardModel} from '@/store/models/Steward'
import moment from 'moment'
import {getPaginationProps} from '@/common/biz/getPaginationProps'
import {useCodeArraySync, useCodeSync} from '@/store/models/Common'
import DividerLine from '@/pages/property/component/DividerLine'
import {onAlbumUploadEvent, onAlbumUploadProps} from '@/pages/service/utils/albumUpload'
import {getImg} from '@/utils/util'
import {usePermission} from '@/components/Permission'
import {storageModel} from '@/store/models/Storage'

interface SiteContractDetailProps {
  warehouseId: string
}

interface GroupBillListVO extends StewardWarehouseBillDetailVO {
  list: StewardWarehouseBillDetailVO[]
}

function add(num1 = 0, num2 = 0, toFixed = 2) {
  return +((num1 * 100 + num2 * 100) / 100).toFixed(toFixed)
}

function groupBill(
  list: StewardWarehouseBillDetailVO[],
  {groupKey}: {groupKey: (item: StewardWarehouseBillDetailVO) => string}
) {
  const res = list.reduce(
    (prev, item) => {
      const key = groupKey(item)
      if (!prev.keys.includes(key)) {
        prev.keys.push(key)
        prev.data[key] = {
          ...item,
          list: [],
          billAmt: 0,
        }
      }
      prev.data[key].billAmt = add(prev.data[key].billAmt, item.billAmt)
      prev.data[key].list.push(item)
      return prev
    },
    {keys: [] as string[], data: {} as {[key: string]: GroupBillListVO}}
  )

  return res.keys.map(item => res.data[item])
}

export interface PeriodListProps extends StewardWarehouseBillDetailVO {
  children?: PeriodListProps[]
  label: string
  value: string
}

// 组合账期
function groupPeriod(
  list: StewardWarehouseBillDetailVO[],
  {getKey}: {getKey: (item: StewardWarehouseBillDetailVO) => string}
) {
  const res = list.reduce(
    (prev, item) => {
      const key = getKey(item)
      if (!prev.keys.includes(key)) {
        prev.keys.push(key)
        prev.data[key] = {
          ...item,
          label: key,
          value: key,
          children: [],
        }
      }
      const title = `${item.billBegin}至${item.billEnd}`
      prev.data[key].children.push({...item, label: title, value: title})
      return prev
    },
    {keys: [] as string[], data: {} as {[key: string]: PeriodListProps}}
  )

  return res.keys.map(item => res.data[item])
}

function _SiteContractDetail({warehouseId}: SiteContractDetailProps, ref) {
  const [auth] = usePermission()
  const [addOpen, setAddOpen] = useState(false)
  const [confirmOpen, setConfirmOpen] = useState(false)
  const [billOpen, setBillOpen] = useState(false)
  const [billList, setBillList] = useState<GroupBillListVO[]>([])
  const [addForm] = Form.useForm()
  const [form] = Form.useForm()
  const [cancelForm] = Form.useForm()
  const [billCancelForm] = Form.useForm()
  const [collectionBillForm] = Form.useForm()
  const [updateBillForm] = Form.useForm()
  const [addBillForm] = Form.useForm()
  const [list, setList] = useState<StewardWarehouseContractDetailVO[]>([])
  const [total, setTotal] = useState<number>(0)
  const contractStatusArr = useCodeArraySync('steward.warehouse.pact.status')
  const payWayArr = useCodeArraySync('bill.payWay')
  const contractStatus = useCodeSync('steward.warehouse.pact.status')
  const billStatus = useCodeSync('steward.warehouse.bill.status')
  const approvalStatus = useCodeSync('wework.approvalStatus')
  const loading = stewardModel.stewardWarehouseContractList.useLoading()
  const [contractBillList, setContractBillList] = useState<GroupBillListVO[]>([])
  const [noPeriodBill, setNoPeriodBill] = useState<StewardWarehouseBillDetailVO[]>([])
  const contractIdCurrent = useRef<string>(null)
  const contractStatusCurrent = useRef<string>(null)
  const [periodArr, setPeriodArr] = useState<PeriodListProps[]>([])

  useImperativeHandle(ref, () => ({
    hidden: async () => {
      const {
        response: {data},
      } = await storageModel.stewardWarehouseInfo(warehouseId)
      const {rent, deposit, payType, pledgeType, payAdvanceDays} = data
      addForm.setFieldsValue({rent, deposit, payType, pledgeType, payAdvanceDays})
      setAddOpen(true)
    },
  }))

  const search = useCallback(() => {
    const values = form.getFieldsValue(true)
    stewardModel.stewardWarehouseContractList({...values}).then(({response: {data, total}}) => {
      setList(data)
      setTotal(total)
    })
  }, [form])

  const searchBillList = useCallback(async (contractId: string) => {
    const {
      response: {data},
    } = await stewardModel.stewardWarehouseContractBillList({contractId})
    setContractBillList(
      groupBill(
        data.filter(item => item.periodNum !== 0),
        {groupKey: item => `${item.periodNum}`}
      )
    )

    setNoPeriodBill(data.filter(item => item.periodNum === 0))

    setPeriodArr(
      groupPeriod(
        data.filter(item => item.periodNum !== 0 && item.billCategory !== 'b.b' && item.billStatus === '1'),
        {getKey: item => `第${item.periodNum}期`}
      )
    )
  }, [])

  useEffect(() => {
    form.submit()
  }, [form])

  const billOperation = (billId: string, record: StewardWarehouseBillDetailVO) => {
    return (
      <Space>
        <Button
          type={'link'}
          style={{paddingLeft: 0, paddingRight: 0}}
          onClick={async () => {
            const {
              response: {data},
            } = await stewardModel.stewardWarehouseContractBillDetail({billId})
            Modal.info({
              title: '账单信息',
              icon: null,
              centered: true,
              width: 800,
              okText: '关闭',
              content: (
                <>
                  <DividerLine title={'基本信息'} top={10} bottom={20} />
                  <Descriptions column={2}>
                    <Descriptions.Item label={'账单类型'}>{data.billCategoryName}</Descriptions.Item>
                    <Descriptions.Item label={'账单应付金额'}>{data.billAmt}</Descriptions.Item>
                    <Descriptions.Item label={'应付日期'}>{data.billDate}</Descriptions.Item>
                    <Descriptions.Item label={'账单周期'}>{`${data.billBegin}至${data.billEnd}`}</Descriptions.Item>
                    <Descriptions.Item label={'账单备注'}>{data.billRemark ?? '/'}</Descriptions.Item>
                    {data.billStatus === '2' && (
                      <Descriptions.Item label={`实际支付（${data.billPayWay}）`}>{data.billAmt}</Descriptions.Item>
                    )}
                  </Descriptions>
                  {data.billStatus === '2' && (
                    <>
                      <DividerLine title={'付款信息'} top={20} bottom={20} />
                      <Descriptions column={2}>
                        <Descriptions.Item label={'付款方式'}>{data.billPayWay}</Descriptions.Item>
                        <Descriptions.Item label={'付款日期'}>{data.billPayDate}</Descriptions.Item>
                        <Descriptions.Item label={'付款备注'}>{data.billPayRemark ?? '/'}</Descriptions.Item>
                        <Descriptions.Item label={'操作人'}>{data.billPayInsertBy}</Descriptions.Item>
                        <Descriptions.Item label={'操作时间'}>{data.billPayInsertTime}</Descriptions.Item>
                        <Descriptions.Item label={'照片凭证'}>
                          <Album fileList={data.billPayUrlList.map(item => ({url: getImg(item), uid: item}))} disabled>
                            {null}
                          </Album>
                        </Descriptions.Item>
                      </Descriptions>
                    </>
                  )}
                  {data.billStatus === '3' && (
                    <>
                      <DividerLine title={'作废信息'} top={20} bottom={20} />
                      <Descriptions column={2}>
                        <Descriptions.Item label={'操作人'}>{data.cancelBy}</Descriptions.Item>
                        <Descriptions.Item label={'操作时间'}>{data.cancelTime}</Descriptions.Item>
                        <Descriptions.Item label={'作废原因'}>{data.cancelRemark}</Descriptions.Item>
                      </Descriptions>
                    </>
                  )}
                </>
              ),
            })
          }}
        >
          查看
        </Button>
        {record.billStatus === '1' && (
          <>
            {auth('008180007') && (
              <Button
                type={'link'}
                style={{paddingLeft: 0, paddingRight: 0}}
                onClick={async () => {
                  const {
                    response: {data},
                  } = await stewardModel.stewardWarehouseContractBillDetail({billId})
                  collectionBillForm.setFieldsValue({
                    billAmt: data.billAmt,
                    billId,
                    billPayWayCode: data.billPayWayCode,
                  })

                  const {
                    response: {data: data1},
                  } = await storageModel.stewardWarehouseInfo(warehouseId)
                  collectionBillForm.setFieldsValue({
                    billPayWayCode: data1.payWayCode,
                  })

                  Modal.confirm({
                    title: '账单付款',
                    icon: null,
                    centered: true,
                    width: 600,
                    afterClose: () => {
                      collectionBillForm.resetFields()
                    },
                    onOk: async () => {
                      await collectionBillForm.validateFields()
                      const {billPayUrlList, ...values} = collectionBillForm.getFieldsValue(true)
                      await stewardModel.stewardWarehouseContractBillCollection({
                        ...values,
                        billPayUrlList: billPayUrlList.map(item => item.imgUrl),
                      } as StewardWarehouseBillCollectionDTO)
                      await searchBillList(contractIdCurrent.current)
                      message.success('付款成功！')
                    },
                    content: (
                      <Form form={collectionBillForm} style={{marginTop: 20}} labelCol={{flex: '120px'}}>
                        <Form.Item
                          label={'付款方式'}
                          name={'billPayWayCode'}
                          rules={[{required: true, message: '请选择付款方式'}]}
                        >
                          <Select
                            options={payWayArr}
                            fieldNames={{value: 'id'}}
                            placeholder={'请选择'}
                            allowClear
                            disabled
                          />
                        </Form.Item>
                        <Form.Item label={'付款金额'} name={'billAmt'} required>
                          <InputNumber disabled />
                        </Form.Item>
                        <Form.Item
                          label={'付款日期'}
                          name={'billPayDate'}
                          getValueFromEvent={(_, value) => value}
                          getValueProps={value => ({value: value && moment(value)})}
                          rules={[{required: true, message: '请选择付款日期'}]}
                        >
                          <DatePicker placeholder={'请选择'} />
                        </Form.Item>
                        <Form.Item
                          label={'照片凭证'}
                          name={'billPayUrlList'}
                          getValueFromEvent={onAlbumUploadEvent}
                          getValueProps={onAlbumUploadProps}
                          rules={[{required: true, message: '请上传照片凭证'}]}
                        >
                          <Album
                            limit={3}
                            multiple
                            action={baseUrl + '/steward/steward-warehouse-contract/upload'}
                            accept={'.jpg, .jpeg, .png'}
                          >
                            <UploadButton title={'上传照片'} />
                          </Album>
                        </Form.Item>
                        <Form.Item label={'付款备注'} name={'billPayRemark'}>
                          <Input.TextArea placeholder={'请输入'} maxLength={200} showCount allowClear rows={3} />
                        </Form.Item>
                      </Form>
                    ),
                  })
                }}
              >
                付款
              </Button>
            )}
            {auth('002180008') && (
              <Button
                type={'link'}
                style={{paddingLeft: 0, paddingRight: 0}}
                onClick={async () => {
                  const {
                    response: {data},
                  } = await stewardModel.stewardWarehouseContractBillDetail({billId})
                  updateBillForm.setFieldsValue({billAmt: data.billAmt, billId})
                  Modal.confirm({
                    title: '账单修改',
                    icon: null,
                    centered: true,
                    width: 600,
                    afterClose: () => {
                      updateBillForm.resetFields()
                    },
                    onOk: async () => {
                      await updateBillForm.validateFields()
                      const values = updateBillForm.getFieldsValue(true)
                      await stewardModel.stewardWarehouseContractBillUpdate(values as StewardWarehouseBillUpdateDTO)
                      await searchBillList(contractIdCurrent.current)
                      message.success('修改成功！')
                    },
                    content: (
                      <Form form={updateBillForm} style={{marginTop: 20}} labelCol={{flex: '120px'}}>
                        <Form.Item label={'账单类型'}>{data.billCategoryName}</Form.Item>
                        <Form.Item label={'账单原始金额'}>{data.billAmt}</Form.Item>
                        <Form.Item
                          label={'账单应付金额'}
                          name={'billAmt'}
                          rules={[{required: true, message: '请输入应付金额'}]}
                        >
                          <InputNumber placeholder={'请输入'} step={0.01} min={0} />
                        </Form.Item>
                        <Form.Item label={'应付款日'}>{data.billDate}</Form.Item>
                        <Form.Item label={'账单周期'}>{`${data.billBegin}至${data.billEnd}`}</Form.Item>
                        <Form.Item label={'账单备注'} name={'billRemark'}>
                          <Input.TextArea placeholder={'请输入'} maxLength={200} showCount allowClear rows={3} />
                        </Form.Item>
                      </Form>
                    ),
                  })
                }}
              >
                修改
              </Button>
            )}
            {auth('004180006') && (
              <Button
                type={'link'}
                style={{paddingLeft: 0, paddingRight: 0}}
                onClick={() => {
                  Modal.confirm({
                    title: '作废账单',
                    icon: null,
                    centered: true,
                    width: 500,
                    closable: true,
                    afterClose: () => {
                      billCancelForm.resetFields()
                    },
                    onOk: async () => {
                      await billCancelForm.validateFields()
                      const cancelRemark = billCancelForm.getFieldValue('cancelRemark')
                      await stewardModel.stewardWarehouseContractBillCancel({
                        cancelRemark,
                        billId,
                      })
                      await searchBillList(contractIdCurrent.current)
                      message.success('作废成功！')
                    },
                    content: (
                      <Form form={billCancelForm}>
                        <Form.Item
                          name={'cancelRemark'}
                          label={'作废原因'}
                          rules={[{required: true, message: '请输入作废原因'}]}
                        >
                          <Input.TextArea placeholder={'请输入'} maxLength={200} rows={3} showCount />
                        </Form.Item>
                      </Form>
                    ),
                  })
                }}
              >
                作废
              </Button>
            )}
            {auth('002180009') && record.approvalStatus === '3' && (
              <Button
                type={'link'}
                style={{paddingLeft: 0, paddingRight: 0}}
                onClick={() => {
                  Modal.confirm({
                    title: '提示',
                    icon: null,
                    centered: true,
                    width: 500,
                    closable: true,
                    content: '确定发起审批？',
                    onOk: async () => {
                      await stewardModel.stewardWarehouseContractBillApprovalRelaunch({billId})
                      await searchBillList(contractIdCurrent.current)
                      message.success('提交成功！')
                    },
                  })
                }}
              >
                发起审批
              </Button>
            )}
          </>
        )}
      </Space>
    )
  }

  return (
    <>
      <Form
        form={form}
        onFinish={search}
        onReset={search}
        initialValues={{pageNum: 1, pageSize: 10, pageable: true, warehouseId}}
      >
        <Row gutter={[20, 0]}>
          <Col span={12}>
            <Form.Item label={'创建人'} name={'insertBy'}>
              <Input placeholder={'请输入'} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label={'状态'} name={'contractStatus'}>
              <Select options={contractStatusArr} fieldNames={{value: 'id'}} placeholder={'请选择'} />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item label={'创建时间'} name={'insertTime'}>
              <DatePicker.RangePicker
                onChange={(_, dateStrings) => {
                  const [insertTimeBegin, insertTimeEnd] = dateStrings ?? []
                  form.setFieldsValue({
                    insertTimeBegin: insertTimeBegin && insertTimeBegin + ' 00:00:00',
                    insertTimeEnd: insertTimeEnd && insertTimeEnd + ' 23:59:59',
                  })
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row justify={'end'}>
          <Space>
            <Button type={'primary'} htmlType={'submit'} onClick={() => form.setFieldsValue({pageNum: 1})}>
              查询
            </Button>
            <Button htmlType={'reset'}>重置</Button>
          </Space>
        </Row>
      </Form>
      <Table
        style={{marginTop: 20}}
        rowKey={'contractId'}
        loading={loading}
        dataSource={list}
        columns={[
          {title: '创建时间', dataIndex: 'insertTime'},
          {title: '创建人', dataIndex: 'insertBy'},
          {
            title: '租期',
            dataIndex: 'contractMonth',
            render: (value, record) => `${record.contractBegin}至${record.contractEnd}（${value}个月）`,
          },
          {title: '状态', dataIndex: 'contractStatus', render: value => contractStatus[value]},
          {
            title: '操作',
            dataIndex: 'contractId',
            render: (contractId, record) => {
              return (
                <Space>
                  {auth('001180003') && (
                    <Button
                      type={'link'}
                      style={{paddingLeft: 0, paddingRight: 0}}
                      onClick={async () => {
                        const {
                          response: {data},
                        } = await stewardModel.stewardWarehouseContractDetail({contractId})
                        Modal.info({
                          title: '租约信息',
                          icon: null,
                          centered: true,
                          width: 700,
                          okText: '关闭',
                          content: (
                            <>
                              <DividerLine title={'基本信息'} bottom={20} top={10} />
                              <Descriptions column={2}>
                                <Descriptions.Item label={'创建时间'}>{data.insertTime}</Descriptions.Item>
                                <Descriptions.Item label={'创建人'}>{data.insertBy}</Descriptions.Item>
                                <Descriptions.Item
                                  label={'租期'}
                                >{`${data.contractBegin}至${data.contractEnd}（${data.contractMonth}个月）`}</Descriptions.Item>
                                <Descriptions.Item label={'状态'}>
                                  {contractStatus[data.contractStatus]}
                                </Descriptions.Item>
                                {data.contractPdfUrl && (
                                  <Descriptions.Item label={'合同附件'}>
                                    <a
                                      href={getImg(data.contractPdfUrl)}
                                      style={{textDecoration: 'underline'}}
                                      target={'_blank'}
                                      rel='noreferrer'
                                    >
                                      合同
                                    </a>
                                  </Descriptions.Item>
                                )}
                                {!!data.contractUrlList?.length && (
                                  <Descriptions.Item label={'合同照片'}>
                                    <Album
                                      fileList={data.contractUrlList.map(item => ({url: getImg(item), uid: item}))}
                                      disabled
                                    >
                                      {null}
                                    </Album>
                                  </Descriptions.Item>
                                )}
                                <Descriptions.Item label={'租金'}>{data.rent}</Descriptions.Item>
                                <Descriptions.Item label={'押金'}>{data.deposit}</Descriptions.Item>
                                <Descriptions.Item
                                  label={'付款方式'}
                                >{`付${data.payType}押${data.pledgeType}`}</Descriptions.Item>
                                <Descriptions.Item
                                  label={'付款类型'}
                                >{`每期提前${data.payAdvanceDays}天`}</Descriptions.Item>
                              </Descriptions>
                              {data.contractStatus === '4' && (
                                <>
                                  <DividerLine title={'作废信息'} top={20} bottom={20} />
                                  <Descriptions column={2}>
                                    <Descriptions.Item label={'操作人'}>{data.cancelBy}</Descriptions.Item>
                                    <Descriptions.Item label={'操作时间'}>{data.cancelTime}</Descriptions.Item>
                                    <Descriptions.Item label={'作废原因'}>{data.cancelRemark}</Descriptions.Item>
                                  </Descriptions>
                                </>
                              )}
                            </>
                          ),
                        })
                      }}
                    >
                      查看
                    </Button>
                  )}
                  {['1', '2'].includes(record.contractStatus) && auth('004180004') && (
                    <Button
                      type={'link'}
                      style={{paddingLeft: 0, paddingRight: 0}}
                      onClick={() => {
                        Modal.confirm({
                          title: '作废合同',
                          icon: null,
                          centered: true,
                          width: 480,
                          content: (
                            <Form form={cancelForm}>
                              <Form.Item
                                label={'作废原因'}
                                rules={[{required: true, message: '请输入作废原因'}]}
                                name={'cancelRemark'}
                              >
                                <Input.TextArea placeholder={'请输入'} rows={3} maxLength={200} showCount />
                              </Form.Item>
                            </Form>
                          ),
                          afterClose: () => {
                            cancelForm.resetFields()
                          },
                          onOk: async () => {
                            await cancelForm.validateFields()
                            const cancelRemark = cancelForm.getFieldValue('cancelRemark')
                            await stewardModel.stewardWarehouseContractCancel({cancelRemark, contractId})
                            form.submit()
                          },
                        })
                      }}
                    >
                      作废
                    </Button>
                  )}
                  {auth('001180005') && (
                    <Button
                      type={'link'}
                      style={{paddingLeft: 0, paddingRight: 0}}
                      onClick={async () => {
                        contractIdCurrent.current = contractId
                        contractStatusCurrent.current = record.contractStatus
                        await searchBillList(contractId)
                        setBillOpen(true)
                      }}
                    >
                      账单信息
                    </Button>
                  )}
                </Space>
              )
            },
          },
        ]}
        pagination={getPaginationProps(form, total)}
      />

      {/* 新增合同 */}
      <Modal
        open={addOpen}
        title={'新增合同'}
        width={600}
        centered
        destroyOnClose
        afterClose={() => {
          addForm.resetFields()
        }}
        onOk={() => addForm.submit()}
        onCancel={() => setAddOpen(false)}
      >
        <Form
          form={addForm}
          initialValues={{warehouseId}}
          onFinish={async () => {
            const {contractPdfUrl, contractUrlList, ...values} = addForm.getFieldsValue(true)
            if (![contractPdfUrl, contractUrlList?.length].some(Boolean))
              return message.warning('合同附件/合同图片必填一个')
            const {
              response: {data},
            } = await stewardModel.stewardWarehouseContractInsertPreview({
              ...values,
              contractPdfUrl,
              contractUrlList: contractUrlList?.map(item => item.imgUrl) || [],
            })
            setBillList(
              groupBill(
                data.billList.filter(item => item.periodNum !== 0),
                {groupKey: item => `${item.periodNum}`}
              )
            )
            setConfirmOpen(true)
          }}
          labelCol={{flex: '120px'}}
        >
          <Form.Item label={'合同附件'}>
            <Upload
              name='file'
              maxCount={1}
              headers={createHeaders()}
              action={baseUrl + '/steward/steward-warehouse-contract/upload'}
              accept={'.pdf, .doc, .docx'}
              onChange={info => {
                if (info.file?.status === 'done') {
                  addForm.setFieldsValue({contractPdfUrl: info.file?.response?.data?.imgUrl})
                }
                if (info.file?.status === 'removed') {
                  addForm.setFieldsValue({contractPdfUrl: undefined})
                }
              }}
            >
              <Button icon={<UploadOutlined />}>选择上传文件</Button>
            </Upload>
          </Form.Item>
          <Form.Item
            label={'合同图片'}
            name={'contractUrlList'}
            getValueFromEvent={onAlbumUploadEvent}
            getValueProps={onAlbumUploadProps}
          >
            <Album
              limit={10}
              multiple
              action={baseUrl + '/steward/steward-warehouse-contract/upload'}
              accept={'.jpg, .jpeg, .png'}
            >
              <UploadButton title={'上传照片'} />
            </Album>
          </Form.Item>
          <Form.Item shouldUpdate label={'租金'} required style={{marginBottom: 0}}>
            {() => {
              return (
                <Space>
                  <Form.Item name={'rent'} rules={[{required: true, message: '请输入'}]}>
                    <InputNumber min={0} placeholder={'请输入'} style={{width: '180px'}} />
                  </Form.Item>
                  <Form.Item name={'deposit'} label={'押金'} rules={[{required: true, message: '请输入'}]}>
                    <InputNumber min={0} placeholder={'请输入'} style={{width: '180px'}} />
                  </Form.Item>
                </Space>
              )
            }}
          </Form.Item>
          <Form.Item label={'付款方式'} shouldUpdate required style={{marginBottom: 0}}>
            {() => {
              return (
                <Space>
                  <Form.Item>
                    <span>付</span>
                  </Form.Item>
                  <Form.Item name={'payType'} rules={[{required: true, message: '请选择'}]}>
                    <Select
                      options={Array.from({length: 12})
                        .fill(1)
                        .map((_, index) => ({label: index + 1, value: index + 1}))}
                      placeholder={'请选择'}
                      style={{width: '90px'}}
                    />
                  </Form.Item>
                  <Form.Item>
                    <span>押</span>
                  </Form.Item>
                  <Form.Item name={'pledgeType'} rules={[{required: true, message: '请选择'}]}>
                    <Select
                      options={Array.from({length: 12})
                        .fill(1)
                        .map((_, index) => ({label: index + 1, value: index + 1}))}
                      placeholder={'请选择'}
                      style={{width: '90px'}}
                    />
                  </Form.Item>
                </Space>
              )
            }}
          </Form.Item>
          <Form.Item label={'付款类型'} shouldUpdate required>
            {() => {
              return (
                <Space>
                  <Form.Item>
                    <span>每期提前</span>
                  </Form.Item>
                  <Form.Item name={'payAdvanceDays'} rules={[{required: true, message: '请选择'}]}>
                    <Select
                      options={Array.from({length: 30})
                        .fill(1)
                        .map((_, index) => ({label: index + 1, value: index + 1}))}
                      placeholder={'请选择'}
                      style={{width: '90px'}}
                    />
                  </Form.Item>
                  <Form.Item>
                    <span>天</span>
                  </Form.Item>
                </Space>
              )
            }}
          </Form.Item>
          <Form.Item
            label={'租约周期'}
            wrapperCol={{span: 10}}
            name={'contractMonth'}
            rules={[{required: true, message: '请选择'}]}
          >
            <Select
              options={Array.from({length: 24})
                .fill(1)
                .map((_, index) => ({label: `${index + 1}个月`, value: index + 1}))}
              onChange={() => {
                addForm.setFieldsValue({contractBegin: undefined, contractEnd: undefined})
              }}
              placeholder={'请选择'}
            />
          </Form.Item>
          <Form.Item label={' '} colon={false} shouldUpdate>
            {f => {
              const contractMonth = f.getFieldValue('contractMonth')
              return (
                <Space>
                  <Form.Item
                    name={'contractBegin'}
                    getValueFromEvent={value => (value ? moment(value).format('YYYY-MM-DD') : undefined)}
                    getValueProps={value => ({value: value ? moment(value) : undefined})}
                    rules={[{required: true, message: '请选择'}]}
                  >
                    <DatePicker
                      placeholder={'开始日期'}
                      onChange={value => {
                        if (contractMonth) {
                          f.setFieldsValue({
                            contractEnd: moment(value)
                              .add(contractMonth, 'month')
                              .subtract(1, 'day')
                              .format('YYYY-MM-DD'),
                          })
                        }
                      }}
                    />
                  </Form.Item>
                  <Form.Item
                    name={'contractEnd'}
                    rules={[{required: true, message: '请选择'}]}
                    getValueFromEvent={value => (value ? moment(value).format('YYYY-MM-DD') : undefined)}
                    getValueProps={value => ({value: value ? moment(value) : undefined})}
                  >
                    <DatePicker placeholder={'结束日期'} disabled />
                  </Form.Item>
                </Space>
              )
            }}
          </Form.Item>
          <Form.Item label={'备注'} name={'remark'} wrapperCol={{span: 15}}>
            <Input.TextArea placeholder={'请输入备注'} rows={3} maxLength={200} showCount />
          </Form.Item>
        </Form>
      </Modal>

      {/* 确认账单 */}
      <Modal
        title={'确认账单'}
        open={confirmOpen}
        centered
        width={600}
        destroyOnClose
        onCancel={() => setConfirmOpen(false)}
        onOk={async () => {
          const {contractUrlList, ...values} = addForm.getFieldsValue(true)
          await stewardModel.stewardWarehouseContractBillBatchInsert({
            ...values,
            contractUrlList: contractUrlList?.map(item => item.imgUrl) || [],
          })
          setConfirmOpen(false)
          setAddOpen(false)
          form.submit()
          message.success('新增合同成功！')
        }}
      >
        <Table
          dataSource={billList}
          rowKey={'periodNum'}
          pagination={false}
          columns={[
            {
              title: '周期',
              render: record => (
                <Space direction={'vertical'} size={0}>
                  <h4>{`${moment(record.periodBegin).format('YYYY-MM-DD')}-${moment(record.periodEnd).format(
                    'YYYY-MM-DD'
                  )}(${record.periodNum}期)`}</h4>
                  <span>{`应付日期：${moment(record.billDate).format('YYYY-MM-DD')}`}</span>
                </Space>
              ),
            },
            {title: '金额', dataIndex: 'billAmt', render: value => <h4>{value}</h4>},
          ]}
          expandable={{
            expandRowByClick: true,
            expandedRowRender: bill => {
              const {list} = bill
              return (
                <Table
                  dataSource={list}
                  pagination={false}
                  rowKey={'billId'}
                  columns={[
                    {
                      title: '周期',
                      render: record => (
                        <span>{`${record.billCategoryName}（${moment(record.billBegin).format('YYYY-MM-DD')}-${moment(
                          record.billEnd
                        ).format('YYYY-MM-DD')}）`}</span>
                      ),
                    },
                    {title: '金额', dataIndex: 'billAmt', render: value => <span>{value}</span>},
                  ]}
                  showHeader={false}
                />
              )
            },
          }}
          showHeader={false}
        />
      </Modal>

      {/* 账单信息 */}
      <Modal
        title={
          <Space>
            <span>账单信息</span>
            {['1', '2'].includes(contractStatusCurrent.current) && auth('003180009') && (
              <Button
                type={'link'}
                onClick={() => {
                  Modal.confirm({
                    title: '添加账单',
                    icon: null,
                    centered: true,
                    closable: true,
                    maskClosable: true,
                    width: 600,
                    afterClose: () => {
                      // reject()
                      addBillForm.resetFields()
                    },
                    onOk: async () => {
                      await addBillForm.validateFields()
                      const {billFlag, billFeeList, billDate, billRemark, billIdList} = addBillForm.getFieldsValue(true)
                      await stewardModel.stewardWarehouseContractBillInsert({
                        billFlag,
                        billDate: billFlag === 1 ? billDate : '',
                        billFeeList,
                        billRemark,
                        contractId: contractIdCurrent.current,
                        billIdList: billFlag === 2 ? billIdList : [],
                      } as StewardWarehouseBillInsertDTO)
                      await searchBillList(contractIdCurrent.current)
                      message.success('添加成功！')
                    },
                    content: (
                      <Form form={addBillForm} initialValues={{billFlag: 1, billFeeList: [{}]}}>
                        <Form.Item label={'账单类型'} name={'billFlag'} required>
                          <Radio.Group>
                            <Radio value={1}>单个账单</Radio>
                            <Radio value={2}>周期账单</Radio>
                          </Radio.Group>
                        </Form.Item>
                        <Form.List name={'billFeeList'}>
                          {(fields, {add, remove}) => {
                            return (
                              <>
                                <div
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    height: '50px',
                                    backgroundColor: '#f7f7fa',
                                    alignItems: 'center',
                                  }}
                                >
                                  <div style={{flex: 1, paddingLeft: 12}}>
                                    <Typography.Text>费用类型</Typography.Text>
                                  </div>
                                  <div style={{flex: 1, paddingLeft: 12}}>
                                    <Typography.Text>金额（元）</Typography.Text>
                                  </div>
                                </div>
                                {fields.map(({key, name, ...restFields}, index) => {
                                  return (
                                    <div
                                      key={key}
                                      style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        height: '60px',
                                      }}
                                    >
                                      <div style={{flex: 1}}>
                                        <Form.Item
                                          name={[name, 'feeType']}
                                          {...restFields}
                                          style={{marginBottom: 0}}
                                          rules={[{required: true, message: '请输入费用类型'}]}
                                        >
                                          <Input placeholder={'请输入'} allowClear />
                                        </Form.Item>
                                      </div>
                                      <div style={{flex: 1}}>
                                        <Form.Item
                                          name={[name, 'feeAmt']}
                                          {...restFields}
                                          style={{marginBottom: 0}}
                                          rules={[{required: true, message: '请输入金额'}]}
                                        >
                                          <InputNumber
                                            style={{width: '100%'}}
                                            placeholder={'请输入'}
                                            min={0}
                                            step={0.01}
                                          />
                                        </Form.Item>
                                      </div>
                                      <div style={{paddingLeft: '12px', width: '30px'}}>
                                        {index > 0 && (
                                          <Form.Item style={{marginBottom: 0}}>
                                            <CloseOutlined onClick={() => remove(name)} />
                                          </Form.Item>
                                        )}
                                      </div>
                                    </div>
                                  )
                                })}
                                <Divider style={{marginTop: '0px'}} />
                                <Row justify={'space-between'}>
                                  <Form.Item label={'总额'} shouldUpdate>
                                    {f => {
                                      const itemList = f.getFieldValue('billFeeList')
                                      const total = itemList.reduce((result, item) => {
                                        if (item?.feeAmt) {
                                          result += item.feeAmt
                                        }
                                        return result
                                      }, 0)
                                      return <span>{total}</span>
                                    }}
                                  </Form.Item>
                                  <Form.Item>
                                    <Button type={'link'} onClick={() => add({})}>
                                      添加收费项
                                    </Button>
                                  </Form.Item>
                                </Row>
                              </>
                            )
                          }}
                        </Form.List>
                        <Form.Item noStyle shouldUpdate={(prev, next) => prev.billFlag !== next.billFlag}>
                          {f => {
                            const billFlag = f.getFieldValue('billFlag')
                            return billFlag === 1 ? (
                              <Form.Item
                                name={'billDate'}
                                label={'应收款日'}
                                getValueFromEvent={(_, format) => format}
                                getValueProps={value => ({value: value ? moment(value) : value})}
                                rules={[{required: true, message: '请选择日期'}]}
                              >
                                <DatePicker placeholder={'应收款日'} />
                              </Form.Item>
                            ) : (
                              <Form.Item
                                rules={[{required: true, message: '请选择账期'}]}
                                name={'_billIdList'}
                                label={'账期选择'}
                              >
                                <Cascader
                                  options={periodArr}
                                  showCheckedStrategy={Cascader.SHOW_CHILD}
                                  onChange={(_, selectOptions: any) => {
                                    const arr = selectOptions.map(item => item[1]).map(item => item.billId)
                                    addBillForm.setFieldsValue({billIdList: arr})
                                  }}
                                  multiple
                                  placeholder={'请选择'}
                                />
                              </Form.Item>
                            )
                          }}
                        </Form.Item>
                        <Form.Item name={'billRemark'} label={'账单备注'}>
                          <Input.TextArea placeholder={'请输入'} rows={3} maxLength={50} showCount />
                        </Form.Item>
                      </Form>
                    ),
                  })
                }}
              >
                添加账单
              </Button>
            )}
          </Space>
        }
        open={billOpen}
        centered
        width={800}
        footer={null}
        destroyOnClose
        onCancel={() => setBillOpen(false)}
      >
        <Table
          rowKey={'periodNum'}
          dataSource={contractBillList}
          columns={[
            {
              title: '周期',
              render: record => (
                <span>{`${moment(record.periodBegin).format('YYYY-MM-DD')}-${moment(record.periodEnd).format(
                  'YYYY-MM-DD'
                )}(${record.periodNum}期)`}</span>
              ),
            },
          ]}
          pagination={false}
          expandable={{
            expandRowByClick: true,
            expandedRowRender: bill => {
              const {list} = bill
              return (
                <Table
                  rowKey={'billId'}
                  dataSource={list}
                  columns={[
                    {title: '账单类型', dataIndex: 'billCategoryName'},
                    {title: '应付金额', dataIndex: 'billAmt'},
                    {title: '应付日期', dataIndex: 'billDate'},
                    {title: '付款状态', dataIndex: 'billStatus', render: value => billStatus[value]},
                    {title: '审批状态', dataIndex: 'approvalStatus', render: value => approvalStatus[value]},
                    {title: '备注', dataIndex: 'billRemark'},
                    {
                      title: '操作',
                      dataIndex: 'billId',
                      render: billOperation,
                    },
                  ]}
                  pagination={false}
                />
              )
            },
          }}
          showHeader={false}
        />

        {!!noPeriodBill.length && (
          <Table
            style={{marginTop: 20}}
            dataSource={noPeriodBill}
            rowKey={'billId'}
            columns={[
              {title: '账单类型', dataIndex: 'billCategoryName'},
              {title: '应付金额', dataIndex: 'billAmt'},
              {title: '应付日期', dataIndex: 'billDate'},
              {title: '付款状态', dataIndex: 'billStatus', render: value => billStatus[value]},
              {title: '审批状态', dataIndex: 'approvalStatus', render: value => approvalStatus[value]},
              {title: '备注', dataIndex: 'billRemark'},
              {
                title: '操作',
                dataIndex: 'billId',
                render: billOperation,
              },
            ]}
            pagination={false}
          />
        )}
      </Modal>
    </>
  )
}

export const SiteContractDetail = React.forwardRef(_SiteContractDetail)
