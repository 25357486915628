import React, {DependencyList, EffectCallback, useCallback, useEffect} from 'react'
import {userModel} from '@/store/models/User'

type CodeType = string | string[]

export function hasPermission(code: CodeType, permission?: Record<string, boolean>) {
  permission = permission ?? userModel.data.permission
  // permission = {}
  return Array.isArray(code) ? code.some(value => permission[value]) : permission[code]
}

export function Permission({
  code,
  fallback = null,
  children = null,
}: {
  code?: CodeType
  children?: any
  fallback?: React.ReactNode
}) {
  const permission = userModel.useData(data => data.permission)
  if (hasPermission(code, permission)) return children

  return fallback
}

export function usePermission() {
  const permission = userModel.useData(data => data.permission)
  const auth = useCallback((code: CodeType) => hasPermission(code as any, permission), [permission])
  return [auth]
}

/**
 * 功能和 useEffect 相同，另外需要传入权限 code
 * @example
 ```ts
 usePermissionEffect(
   '001001001',
   () => console.log('aaaaaaaaaaaa'),
   []
 )
 ```
 */
export function usePermissionEffect(code: CodeType, effect: EffectCallback, deps?: DependencyList) {
  useEffect(() => {
    if (hasPermission(code)) {
      return effect()
    }
  }, deps)
}
