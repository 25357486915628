import React, {useCallback, useEffect, useImperativeHandle, useRef, useState} from 'react'
import commonStyle from '@/assets/styles/common.module.less'
import {
  Button,
  Col,
  DatePicker,
  Descriptions,
  Divider,
  Drawer,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Pagination,
  Row,
  Select,
  Space,
  Table,
} from 'antd'
import {customerModel} from '@/store/models/Customer'
import {useCodeArraySync, useCodeSync} from '@/store/models/Common'
import {teamModel} from '@/store/models/Team'
import {Permission, usePermission} from '@/components/Permission'
import {useAppHistory} from '@/common/hooks/useRouter'
import {SwapRightOutlined} from '@ant-design/icons'
import moment from 'moment'
import ConfirmVerify from '../components/ConfirmVerify'
import {toFixed} from '@/utils/amount'
import {userModel} from '@/store/models/User'
import {FORMAT_DATE_END, FORMAT_DATE_START} from '@/common/date'

interface ChargeAccountProps {
  userId: string
  certName: string
  teamId?: string
}
function ChargeAccount({userId, certName, teamId}: ChargeAccountProps, ref) {
  const history = useAppHistory()
  const [auth] = usePermission()
  const [teamList, setTeamList] = useState([] as TeamTreeNodeVO[])
  const [teamInfo, setTeamInfo] = useState({} as TeamTreeNodeVO)
  const [recordIndex, setRecordIndex] = useState('1')
  const [deductType, setDeductType] = useState('2')
  const selectTeamId = useRef(null)

  //组织列表
  const searchTeamTree = useCallback(
    id => {
      customerModel.customerTeamGetTeamTree({ownerId: userId}).then(({response: {data}}) => {
        setTeamList(data)
        const info = data.find(item => item.teamId === id)
        setTeamInfo(info ?? ({} as TeamTreeNodeVO))
      })
    },
    [userId]
  )

  useEffect(() => {
    searchTeamTree(teamId)
  }, [searchTeamTree, teamId])

  //充值记录
  const [balanceChargeList, setBalanceChargeList] = useState([] as TeamBalanceChargeListVO[])
  const chargeSource = useCodeArraySync('team.chargeSource')
  const [rechargeForm] = Form.useForm()
  const rechargeLoading = teamModel.teamBalanceChargeList.useLoading()
  const [balanceChargeTotal, setBalanceChargeTotal] = useState(0)
  const rechargeSearch = useCallback(async () => {
    const {insertTimeBegin, insertTimeEnd, ...value} = rechargeForm.getFieldsValue(true)
    const {
      response: {data, total},
    } = await teamModel.teamBalanceChargeList({
      ...value,
      insertTimeBegin: insertTimeBegin && moment(insertTimeBegin).format('YYYY-MM-DD'),
      insertTimeEnd: insertTimeEnd && moment(insertTimeEnd).format('YYYY-MM-DD'),
      teamId: selectTeamId.current || teamId,
    })
    setBalanceChargeList(data)
    setBalanceChargeTotal(total)
  }, [rechargeForm, teamId])
  useEffect(() => {
    rechargeSearch()
  }, [rechargeSearch])

  //扣款记录 ==> 服务扣费
  const teamChargeCategory = useCodeArraySync('team.chargeCategory')
  const [withholdForm] = Form.useForm()
  const [balanceRecordList, setBalanceRecordList] = useState([] as TeamWalletRecordVO[])
  const balanceRecordLoading = teamModel.teamBalanceRecordList.useLoading()
  const [balanceRecordTotal, setBalanceRecordTotal] = useState(0)
  const withholdSearch1 = useCallback(async () => {
    const {insertTimeBegin, insertTimeEnd, ...value} = withholdForm.getFieldsValue(true)
    const {
      response: {data, total},
    } = await teamModel.teamBalanceRecordList({
      ...value,
      insertTimeBegin: insertTimeBegin && moment(insertTimeBegin).format('YYYY-MM-DD'),
      insertTimeEnd: insertTimeEnd && moment(insertTimeEnd).format('YYYY-MM-DD'),
      deductType: '2',
      teamPid: selectTeamId.current || teamId,
    })
    setBalanceRecordList(data)
    setBalanceRecordTotal(total)
  }, [teamId, withholdForm])

  //扣款记录 ==> 账单扣款
  const billType = useCodeArraySync('lessor.billType').filter(item => item.id !== '3')
  const [withholdForm2] = Form.useForm()
  const [balanceRecordList2, setBalanceRecordList2] = useState([] as TeamWalletRecordVO[])
  const [balanceRecordTotal2, setBalanceRecordTotal2] = useState(0)
  const billTypeCode = useCodeSync('lessor.billType')
  const withholdSearch2 = useCallback(async () => {
    const {insertTimeBegin, insertTimeEnd, ...value} = withholdForm2.getFieldsValue(true)
    const {
      response: {data, total},
    } = await teamModel.teamBalanceRecordList({
      ...value,
      insertTimeBegin: insertTimeBegin && moment(insertTimeBegin).format('YYYY-MM-DD'),
      insertTimeEnd: insertTimeEnd && moment(insertTimeEnd).format('YYYY-MM-DD'),
      deductType: '1',
      teamPid: selectTeamId.current || teamId,
    })
    setBalanceRecordList2(data)
    setBalanceRecordTotal2(total)
  }, [teamId, withholdForm2])

  // 退款记录
  const [balanceWithdrawList, setBalanceWithdrawList] = useState<TeamWalletWithdrawVO[]>([])
  const [balanceWithdrawTotal, setBalanceWithdrawTotal] = useState(0)
  const balanceWithdrawLoading = teamModel.teamBalanceWithdrawList.useLoading()
  const [balanceWithdrawForm] = Form.useForm()
  const [withdrawVisible, setWithdrawVisible] = useState(false)
  const [addWithdrawForm] = Form.useForm()
  const [verifyVisible, setVerifyVisible] = useState(false)
  const [withdrawInfo, setWithdrawInfo] = useState({} as TeamWalletWithdrawVO)
  const [merchantBalance, setMerchantBalance] = useState({} as any)
  const balanceWithdrawSearch = useCallback(() => {
    const {insertTimeMin, insertTimeMax, ...value} = balanceWithdrawForm.getFieldsValue(true)
    teamModel
      .teamBalanceWithdrawList({
        ...value,
        insertTimeMin: insertTimeMin && moment(insertTimeMin).format(FORMAT_DATE_START),
        insertTimeMax: insertTimeMax && moment(insertTimeMax).format(FORMAT_DATE_END),
        teamPid: selectTeamId.current || teamId,
        customerId: userId,
      })
      .then(({response: {data, total}}) => {
        setBalanceWithdrawList(data)
        setBalanceWithdrawTotal(total)
      })
  }, [balanceWithdrawForm, teamId, userId])

  //选择组织
  function handleSelect(value) {
    const selectTeamInfo = teamList.find(item => item.teamId === value)
    selectTeamId.current = value
    rechargeSearch()
    withholdSearch1()
    withholdSearch2()
    setTeamInfo(selectTeamInfo)
  }

  //退款、充值、扣款操作
  useImperativeHandle(ref, () => ({
    recharge: () => {
      history.push({
        pathname: '/client/recharge',
        state: {
          type: '1',
          certName,
          balance: teamInfo.balance,
          teamName: teamInfo.name,
          teamId: teamInfo.teamId,
        },
      })
    },
    withhold: () => {
      history.push({
        pathname: '/client/recharge',
        state: {
          type: '2',
          certName,
          balance: teamInfo.balance,
          teamName: teamInfo.name,
          teamId: teamInfo.teamId,
        },
      })
    },
    withdraw: async () => {
      const {
        response: {data},
      } = await userModel.allinpayQueryMerchantBalance({})
      setMerchantBalance(data)
      setWithdrawVisible(true)
    },
  }))

  return (
    <>
      <div className={commonStyle.filterSection}>
        <Row>
          <Col style={{display: 'flex', alignItems: 'center'}}>
            <span>客户姓名: {certName}</span>
          </Col>
          <Col offset={2}>
            <Space>
              <span>组织</span>
              <Select
                defaultValue={teamId}
                style={{minWidth: '200px'}}
                placeholder={'请选择'}
                onSelect={value => handleSelect(value)}
              >
                {teamList.map(item => (
                  <Select.Option key={item.teamId} value={item.teamId}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </Space>
          </Col>

          <Col offset={2} style={{display: 'flex', alignItems: 'center'}}>
            <span>当前余额：{teamInfo?.balance}元</span>
          </Col>
        </Row>

        <Divider dashed />

        <Row>
          <Col style={{display: 'flex', alignItems: 'center'}}>
            <span>所属类目：</span>
          </Col>
          <Col>
            <Space>
              <Button
                size={'small'}
                type={recordIndex === '1' ? 'primary' : 'text'}
                onClick={() => setRecordIndex('1')}
              >
                充值记录
              </Button>
              <Button
                size={'small'}
                type={recordIndex === '2' ? 'primary' : 'text'}
                onClick={() => {
                  withholdSearch1()
                  setRecordIndex('2')
                }}
              >
                扣款记录
              </Button>
              <Button
                size={'small'}
                type={recordIndex === '3' ? 'primary' : 'text'}
                onClick={() => {
                  setRecordIndex('3')
                  balanceWithdrawSearch()
                }}
              >
                退款记录
              </Button>
            </Space>
          </Col>
          {recordIndex === '2' && (
            <>
              <Col style={{display: 'flex', alignItems: 'center'}} offset={1}>
                <span>扣款类型：</span>
              </Col>
              <Col>
                <Space>
                  <Button
                    size={'small'}
                    type={deductType === '2' ? 'primary' : 'text'}
                    onClick={() => {
                      setDeductType('2')
                      withholdSearch1()
                    }}
                  >
                    服务扣费
                  </Button>
                  <Button
                    size={'small'}
                    type={deductType === '1' ? 'primary' : 'text'}
                    onClick={() => {
                      setDeductType('1')
                      withholdSearch2()
                    }}
                  >
                    账单扣款
                  </Button>
                </Space>
              </Col>
            </>
          )}
        </Row>

        <Divider dashed />

        {/* 充值记录 */}
        {recordIndex === '1' && (
          <Form
            form={rechargeForm}
            onFinish={rechargeSearch}
            onReset={rechargeSearch}
            initialValues={{pageNum: 1, pageSize: 10}}
          >
            <Row>
              <Col span={5}>
                <Form.Item label={'充值渠道'} name={'chargeType'}>
                  <Select placeholder={'请选择'} allowClear>
                    {chargeSource.map(value => (
                      <Select.Option key={value.id} value={value.id}>
                        {value.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col offset={1}>
                <Form.Item label={'充值金额'} style={{marginBottom: 0}}>
                  <Space align={'start'}>
                    <Form.Item name={'chargeAmtMin'}>
                      <Input placeholder={'请输入'} allowClear style={{maxWidth: '87px'}} />
                    </Form.Item>
                    <SwapRightOutlined style={{color: '#D9D9D9'}} />
                    <Form.Item name={'chargeAmtMax'}>
                      <Input placeholder={'请输入'} allowClear style={{maxWidth: '87px'}} />
                    </Form.Item>
                  </Space>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item label={'充值日期'} style={{marginBottom: 0}}>
                  <Space align={'start'}>
                    <Form.Item name={'insertTimeBegin'}>
                      <DatePicker placeholder={'开始日期'} />
                    </Form.Item>
                    <SwapRightOutlined style={{color: '#D9D9D9'}} />
                    <Form.Item name={'insertTimeEnd'}>
                      <DatePicker placeholder={'结束日期'} />
                    </Form.Item>
                  </Space>
                </Form.Item>
              </Col>
            </Row>

            <Row justify={'end'}>
              <Col>
                <Space>
                  <Button
                    type={'primary'}
                    htmlType={'submit'}
                    onClick={() => {
                      rechargeForm.setFieldsValue({pageNum: 1})
                    }}
                  >
                    查询
                  </Button>
                  <Button htmlType={'reset'}>重置</Button>
                </Space>
              </Col>
            </Row>

            <Table
              loading={rechargeLoading}
              rowKey={'chargeId'}
              style={{marginTop: 30, marginBottom: 20}}
              columns={[
                {title: '流水号', key: 'chargeId', dataIndex: 'chargeId'},
                {title: '进账时间', key: 'chargeTime', dataIndex: 'chargeTime'},
                {
                  title: '充值金额',
                  key: 'chargeAmt',
                  dataIndex: 'chargeAmt',
                  render: text => `${text}元`,
                },
                {title: '充值渠道', key: 'chargeTypeName', dataIndex: 'chargeTypeName'},
                {title: '充值账户', key: 'chargeAccount', dataIndex: 'chargeAccount'},
                {
                  title: '充值后金额',
                  key: 'walletBalance',
                  dataIndex: 'walletBalance',
                  render: text => `${text}元`,
                },
                {title: '创建人', key: 'creatorName', dataIndex: 'creatorName'},
                {title: '创建时间', key: 'insertTime', dataIndex: 'insertTime'},
                {
                  title: '状态',
                  key: 'status',
                  dataIndex: 'status',
                  render: text => (text === '1' ? '有效' : '无效'),
                },
                {
                  title: '操作',
                  fixed: 'right',
                  width: '100px',
                  render: record =>
                    auth('001011002') && (
                      <Button
                        type={'link'}
                        size={'small'}
                        style={{padding: 0}}
                        onClick={() =>
                          history.push({
                            pathname: '/client/recharge/detail',
                            state: {
                              type: '1',
                              chargeId: record.chargeId,
                              certName,
                              teamName: teamInfo.name,
                              userId,
                              balance: teamInfo.balance,
                            },
                          })
                        }
                      >
                        查看详情
                      </Button>
                    ),
                },
              ]}
              scroll={{x: 'max-content'}}
              dataSource={balanceChargeList}
              pagination={false}
            />
            <Form.Item
              noStyle
              shouldUpdate={(prev, next) => prev.pageSize !== next.pageSize || prev.pageNum !== next.pageNum}
            >
              {() => (
                <Pagination
                  total={balanceChargeTotal}
                  current={rechargeForm.getFieldValue('pageNum')}
                  pageSize={rechargeForm.getFieldValue('pageSize')}
                  style={{textAlign: 'center'}}
                  showSizeChanger
                  onChange={(pageNum, pageSize) => {
                    rechargeForm.setFieldsValue({pageNum, pageSize})
                    rechargeSearch()
                  }}
                />
              )}
            </Form.Item>
          </Form>
        )}

        {/* 扣款记录 > 服务扣费 */}
        {recordIndex === '2' && deductType === '2' && (
          <Form
            form={withholdForm}
            onFinish={withholdSearch1}
            onReset={withholdSearch1}
            initialValues={{pageNum: 1, pageSize: 10}}
          >
            <Row>
              <Col span={6}>
                <Form.Item label={'服务单号'} style={{marginBottom: 0}} name={'stewardOrderId'}>
                  <Input allowClear placeholder={'请输入服务单号'} />
                </Form.Item>
              </Col>
              <Col offset={1} span={5}>
                <Form.Item label={'费用类型'} style={{marginBottom: 0}} name={'recordCategory'}>
                  <Select placeholder={'请选择'} allowClear>
                    {teamChargeCategory.map(value => (
                      <Select.Option key={value.id} value={value.id}>
                        {value.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col offset={1} span={11}>
                <Form.Item label={'扣款金额'} style={{marginBottom: 0}}>
                  <Space align='start'>
                    <Form.Item name={'recordAmtMin'}>
                      <Input allowClear placeholder={'请输入'} style={{maxWidth: '87px'}} />
                    </Form.Item>
                    <SwapRightOutlined style={{color: '#D9D9D9'}} />
                    <Form.Item name={'recordAmtMax'}>
                      <Input allowClear placeholder={'请输入'} style={{maxWidth: '87px'}} />
                    </Form.Item>
                  </Space>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item label={'扣款日期'} style={{marginBottom: 0}}>
                  <Space align={'start'}>
                    <Form.Item name={'insertTimeBegin'}>
                      <DatePicker placeholder={'开始日期'} />
                    </Form.Item>
                    <SwapRightOutlined style={{color: '#D9D9D9'}} />
                    <Form.Item name={'insertTimeEnd'}>
                      <DatePicker placeholder={'结束日期'} />
                    </Form.Item>
                  </Space>
                </Form.Item>
              </Col>
            </Row>
            <Row justify={'end'}>
              <Col>
                <Space>
                  <Button
                    type={'primary'}
                    htmlType={'submit'}
                    onClick={() => withholdForm.setFieldsValue({pageNum: 1})}
                  >
                    查询
                  </Button>
                  <Button htmlType={'reset'}>重置</Button>
                </Space>
              </Col>
            </Row>
            <Table
              loading={balanceRecordLoading}
              style={{marginTop: 30, marginBottom: 20}}
              rowKey={'recordId'}
              columns={[
                {title: '流水号', key: 'recordId', dataIndex: 'recordId'},
                {title: '扣款来源', key: 'recordPayTypeName', dataIndex: 'recordPayTypeName'},
                {title: '费用类型', key: 'recordCategoryName', dataIndex: 'recordCategoryName'},
                {title: '服务单号', key: 'stewardOrderId', dataIndex: 'stewardOrderId'},
                {
                  title: '扣款金额',
                  key: 'recordAmt',
                  dataIndex: 'recordAmt',
                  render: text => `${text}元`,
                },
                {
                  title: '扣款后金额',
                  key: 'walletBalance',
                  dataIndex: 'walletBalance',
                  render: text => `${text}元`,
                },
                {title: '创建人', key: 'creatorName', dataIndex: 'creatorName'},
                {title: '创建时间', key: 'insertTime', dataIndex: 'insertTime'},
                {
                  title: '状态',
                  key: 'status',
                  dataIndex: 'status',
                  render: text => (text === '1' ? '有效' : '无效'),
                },
                {
                  title: '操作',
                  fixed: 'right',
                  width: '100px',
                  render: record =>
                    auth('001013002') && (
                      <Button
                        type={'link'}
                        size={'small'}
                        onClick={() =>
                          history.push({
                            pathname: '/client/recharge/detail',
                            state: {
                              type: '2',
                              certName,
                              teamName: teamInfo.name,
                              balance: teamInfo.balance,
                              userId,
                              recordId: record.recordId,
                            },
                          })
                        }
                      >
                        查看详情
                      </Button>
                    ),
                },
              ]}
              scroll={{x: 'max-content'}}
              dataSource={balanceRecordList}
              pagination={false}
            />

            <Form.Item
              noStyle
              shouldUpdate={(prev, next) => prev.pageSize !== next.pageSize || prev.pageNum !== next.pageNum}
            >
              {() => (
                <Pagination
                  total={balanceRecordTotal}
                  current={withholdForm.getFieldValue('pageNum')}
                  pageSize={withholdForm.getFieldValue('pageSize')}
                  style={{textAlign: 'center'}}
                  showSizeChanger
                  onChange={(pageNum, pageSize) => {
                    withholdForm.setFieldsValue({pageNum, pageSize})
                    withholdSearch1()
                  }}
                />
              )}
            </Form.Item>
          </Form>
        )}

        {/* 扣款记录 > 账单扣款 */}
        {recordIndex === '2' && deductType === '1' && (
          <Form
            form={withholdForm2}
            onFinish={withholdSearch2}
            onReset={withholdSearch2}
            initialValues={{pageNum: 1, pageSize: 10}}
          >
            <Row>
              <Col span={6}>
                <Form.Item label={'账单类型'} name={'billType'}>
                  <Select placeholder={'请选择'} allowClear>
                    {billType.map(value => (
                      <Select.Option key={value.id} value={value.id}>
                        {value.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col offset={1}>
                <Form.Item label={'扣款金额'} style={{marginBottom: 0}}>
                  <Space align={'start'}>
                    <Form.Item name={'insertTimeBegin'}>
                      <DatePicker placeholder={'开始日期'} />
                    </Form.Item>
                    <SwapRightOutlined style={{color: '#D9D9D9'}} />
                    <Form.Item name={'insertTimeEnd'}>
                      <DatePicker placeholder={'结束日期'} />
                    </Form.Item>
                  </Space>
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item label={'扣款日期'} style={{marginBottom: 0}}>
                  <Space align='start'>
                    <Form.Item name={'insertTimeBegin'}>
                      <DatePicker placeholder={'开始日期'} />
                    </Form.Item>
                    <SwapRightOutlined style={{color: '#D9D9D9'}} />
                    <Form.Item name={'insertTimeEnd'}>
                      <DatePicker placeholder={'结束日期'} />
                    </Form.Item>
                  </Space>
                </Form.Item>
              </Col>
            </Row>

            <Row justify={'end'}>
              <Col>
                <Space>
                  <Button
                    type={'primary'}
                    htmlType={'submit'}
                    onClick={() => withholdForm2.setFieldsValue({pageNum: 1})}
                  >
                    查询
                  </Button>
                  <Button htmlType={'reset'}>重置</Button>
                </Space>
              </Col>
            </Row>

            <Table
              loading={balanceRecordLoading}
              rowKey={'recordId'}
              style={{marginTop: 30, marginBottom: 20}}
              columns={[
                {title: '账单名称', key: 'billName', dataIndex: 'billName'},
                {title: '账单类型', key: 'billType', dataIndex: 'billType', render: text => billTypeCode[text]},
                {
                  title: '扣款金额',
                  key: 'recordAmt',
                  dataIndex: 'recordAmt',
                  render: text => `${text}元`,
                },
                {title: '扣款时间', key: 'insertTime', dataIndex: 'insertTime'},
                {
                  title: '操作',
                  fixed: 'right',
                  width: '100px',
                  render: record =>
                    auth('001013002') && (
                      <Button
                        type={'link'}
                        size={'small'}
                        onClick={() =>
                          history.push({
                            pathname: '/client/recharge/detail',
                            state: {
                              type: '2',
                              certName,
                              teamName: teamInfo.name,
                              balance: teamInfo.balance,
                              userId,
                              recordId: record.recordId,
                            },
                          })
                        }
                      >
                        查看详情
                      </Button>
                    ),
                },
              ]}
              scroll={{x: 'max-content'}}
              dataSource={balanceRecordList2}
              pagination={false}
            />

            <Form.Item
              noStyle
              shouldUpdate={(prev, next) => prev.pageSize !== next.pageSize || prev.pageNum !== next.pageNum}
            >
              {() => (
                <Pagination
                  total={balanceRecordTotal2}
                  current={withholdForm2.getFieldValue('pageNum')}
                  pageSize={withholdForm2.getFieldValue('pageSize')}
                  style={{textAlign: 'center'}}
                  showSizeChanger
                  onChange={(pageNum, pageSize) => {
                    withholdForm2.setFieldsValue({pageNum, pageSize})
                    withholdSearch2()
                  }}
                />
              )}
            </Form.Item>
          </Form>
        )}

        {/* 退款记录 */}
        {recordIndex === '3' && (
          <Form
            form={balanceWithdrawForm}
            onFinish={balanceWithdrawSearch}
            onReset={balanceWithdrawSearch}
            initialValues={{pageNum: 1, pageSize: 10}}
          >
            <Row>
              <Col>
                <Form.Item label={'退款金额'} style={{marginBottom: 0}}>
                  <Space align='start'>
                    <Form.Item name={'withdrawAmtMin'}>
                      <Input placeholder={'请输入'} allowClear style={{maxWidth: '87px'}} />
                    </Form.Item>
                    <SwapRightOutlined style={{color: '#D9D9D9'}} />
                    <Form.Item name={'withdrawAmtMax'}>
                      <Input placeholder={'请输入'} allowClear style={{maxWidth: '87px'}} />
                    </Form.Item>
                  </Space>
                </Form.Item>
              </Col>
              <Col offset={1}>
                <Form.Item label={'退款日期'} style={{marginBottom: 0}}>
                  <Space align={'start'}>
                    <Form.Item name={'insertTimeMin'}>
                      <DatePicker placeholder={'开始日期'} />
                    </Form.Item>
                    <SwapRightOutlined style={{color: '#D9D9D9'}} />
                    <Form.Item name={'insertTimeMax'}>
                      <DatePicker placeholder={'结束日期'} />
                    </Form.Item>
                  </Space>
                </Form.Item>
              </Col>
            </Row>
            <Row justify={'end'}>
              <Space>
                <Button
                  type={'primary'}
                  htmlType={'submit'}
                  onClick={() => {
                    balanceWithdrawForm.setFieldsValue({pageNum: 1})
                  }}
                >
                  查询
                </Button>
                <Button htmlType={'reset'}>重置</Button>
              </Space>
            </Row>

            <Table
              rowKey={'withdrawId'}
              style={{marginTop: 30, marginBottom: 20}}
              loading={balanceWithdrawLoading}
              columns={[
                {title: '流水号', dataIndex: 'withdrawId', key: 'withdrawId'},
                {title: '退款金额', dataIndex: 'withdrawAmt', key: 'withdrawAmt', render: text => `${text}元`},
                {title: '退款后金额', dataIndex: 'walletBalance', key: 'walletBalance', render: text => `${text}元`},
                {title: '创建人', dataIndex: 'operatorName', key: 'operatorName'},
                {title: '创建时间', dataIndex: 'insertTime', key: 'insertTime'},
                {title: '状态', dataIndex: 'valid', key: 'valid', render: text => (text === 1 ? '有效' : '无效')},
                {
                  title: '操作',
                  render: record => (
                    <Permission code={'001086001'}>
                      <Button type={'link'} onClick={() => setWithdrawInfo(record)}>
                        查看详情
                      </Button>
                    </Permission>
                  ),
                },
              ]}
              dataSource={balanceWithdrawList}
              pagination={false}
            />

            <Form.Item
              noStyle
              shouldUpdate={(prev, next) => prev.pageNum !== next.pageNum || prev.pageSize !== next.pageSize}
            >
              {() => (
                <Pagination
                  total={balanceWithdrawTotal}
                  current={balanceWithdrawForm.getFieldValue('pageNum')}
                  pageSize={balanceWithdrawForm.getFieldValue('pageSize')}
                  style={{textAlign: 'center'}}
                  showSizeChanger
                  onChange={(pageNum, pageSize) => {
                    balanceWithdrawForm.setFieldsValue({pageNum, pageSize})
                    balanceWithdrawSearch()
                  }}
                />
              )}
            </Form.Item>
          </Form>
        )}
      </div>

      {/* 提现详情 */}
      <Drawer
        width={700}
        title={'退款详情'}
        visible={!!withdrawInfo.withdrawId}
        footer={null}
        destroyOnClose
        onClose={() => {
          setWithdrawInfo({} as TeamWalletWithdrawVO)
        }}
      >
        <Descriptions title={'基本信息'} column={2}>
          <Descriptions.Item label={'客户姓名'}>{certName}</Descriptions.Item>
          <Descriptions.Item label={'组织'}>{teamInfo.name}</Descriptions.Item>
          <Descriptions.Item label={'当前余额'}>{toFixed(teamInfo.balance)}元</Descriptions.Item>
          <Descriptions.Item label={'退款金额'}>{toFixed(withdrawInfo.withdrawAmt)}元</Descriptions.Item>
          <Descriptions.Item label={'退款后金额'}>{toFixed(withdrawInfo.walletBalance)}元</Descriptions.Item>
          <Descriptions.Item label={'创建人'}>{withdrawInfo.operatorName}</Descriptions.Item>
          <Descriptions.Item label={'创建时间'}>{withdrawInfo.insertTime}</Descriptions.Item>
          <Descriptions.Item label={'状态'}>{withdrawInfo.valid === 1 ? '有效' : '无效'}</Descriptions.Item>
          <Descriptions.Item label={'备注信息'}>{withdrawInfo.remark}</Descriptions.Item>
        </Descriptions>
      </Drawer>

      {/* 退款 */}
      <Modal
        visible={withdrawVisible}
        onCancel={() => {
          setWithdrawVisible(false)
          addWithdrawForm.resetFields()
        }}
        onOk={async () => {
          await addWithdrawForm.validateFields()
          setVerifyVisible(true)
        }}
        destroyOnClose
        title={'退款'}
        centered
      >
        <Form form={addWithdrawForm} labelCol={{span: 7}} wrapperCol={{span: 15}}>
          <Form.Item label={'客户姓名'}>
            <span>{certName}</span>
          </Form.Item>
          <Form.Item label={'组织'}>
            <span>{teamInfo.name}</span>
          </Form.Item>
          <Form.Item label={'当前余额'}>
            <span>{teamInfo.balance}元</span>
          </Form.Item>
          <Form.Item label={'平台账户余额'}>
            <span>{`${merchantBalance.allAmount ? Number(merchantBalance.allAmount) / 100 : '0'}元（冻结金额：${
              merchantBalance.freezeAmount ? Number(merchantBalance.freezeAmount) / 100 : '0'
            }元）`}</span>
          </Form.Item>
          <Form.Item
            label={'退款金额'}
            name={'withdrawAmt'}
            rules={[
              {required: true, message: '请输入退款金额'},
              () => ({
                validator(_, value) {
                  if (Number(value) > teamInfo.balance) return Promise.reject(new Error('退款金额不可大于账户余额'))
                  if (Number(value) > merchantBalance.allAmount / 100)
                    return Promise.reject(new Error('支付账户余额不足，无法进行支付'))
                  return Promise.resolve()
                },
              }),
            ]}
          >
            <InputNumber placeholder='请输入退款金额' style={{width: '100%'}} />
          </Form.Item>
          <Form.Item label={'备注信息'} name={'remark'} rules={[{required: true, message: '请输入备注'}]}>
            <Input.TextArea placeholder='请输入' allowClear />
          </Form.Item>
        </Form>
      </Modal>

      {/* 短信验证 */}
      <ConfirmVerify
        visible={verifyVisible}
        onOk={async code => {
          if (code.length < 6) return message.warning('请输入六位验证码')
          setVerifyVisible(false)
          await Modal.confirm({
            centered: true,
            title: '提示',
            icon: null,
            content: '是否确认完成该笔退款？确认后将无法撤销！',
            onOk: async () => {
              const value = await addWithdrawForm.getFieldsValue(true)
              await teamModel.teamBalanceWithdrawInsert({
                ...value,
                verifyCode: code,
                teamPid: selectTeamId.current || teamId,
              })
              searchTeamTree(selectTeamId.current || teamId)
              setWithdrawVisible(false)
              addWithdrawForm.resetFields()
              message.success('退款成功！')
            },
          })
        }}
        onCancel={() => {
          setVerifyVisible(false)
        }}
        send={async () => {
          await teamModel.teamBalanceWithdrawSendMsg({})
        }}
      />
    </>
  )
}

export default React.forwardRef(ChargeAccount)
