import React from 'react'
import commonStyle from '@/assets/styles/common.module.less'
import {Breadcrumb} from '@/components/Breadcrumb'
import {Button, Form, Input, message} from 'antd'
import LockOutlined from '@ant-design/icons/lib/icons/LockOutlined'
import {userModel} from '@/store/models/User'

export default function UpdatePassword() {
  const [form] = Form.useForm()

  return (
    <div className={commonStyle.section} style={{height: '100%', display: 'flex', flexDirection: 'column'}}>
      <Breadcrumb path={['首页', '修改密码']} />
      <div
        style={{
          display: 'flex',
          flex: 1,
          background: '#fff',
          marginTop: 20,
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
          <h1 style={{fontSize: '33px'}}>修改密码</h1>
          <Form
            style={{marginTop: '30px'}}
            form={form}
            onFinish={async () => {
              const {originPwd, newPwd} = form.getFieldsValue(true)
              await userModel.sysUserUpdatePassword({originPwd, newPwd})
              message.success('密码修改成功，请重新登录')
              await userModel.logout()
            }}
          >
            <Form.Item name={'originPwd'} rules={[{required: true, message: '请输入旧密码'}]}>
              <Input
                placeholder='旧密码'
                type={'password'}
                style={{minWidth: '368px'}}
                allowClear
                prefix={<LockOutlined style={{color: '#00B7AE'}} />}
              />
            </Form.Item>
            <Form.Item
              name={'newPwd'}
              rules={[
                {required: false},
                () => ({
                  validator(_, value: string) {
                    if (!value) {
                      return Promise.reject(new Error('请输入新密码'))
                    }
                    if (value.length < 8 || value.length > 16) {
                      return Promise.reject(new Error('密码必须是8-16位英文字母、数字、字符（可以是纯数字）'))
                    }
                    return Promise.resolve()
                  },
                }),
              ]}
            >
              <Input
                placeholder='新密码'
                type={'password'}
                style={{minWidth: '368px'}}
                allowClear
                prefix={<LockOutlined style={{color: '#00B7AE'}} />}
              />
            </Form.Item>
            <Form.Item
              name={'confirmPwd'}
              rules={[
                {required: true, message: '请确认新密码'},
                ({getFieldValue}) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('newPwd') === value) {
                      return Promise.resolve()
                    }

                    return Promise.reject(new Error('确认密码不一致，请重新填写'))
                  },
                }),
              ]}
            >
              <Input
                placeholder='确认新密码'
                type={'password'}
                style={{minWidth: '368px'}}
                allowClear
                prefix={<LockOutlined style={{color: '#00B7AE'}} />}
              />
            </Form.Item>
            <Button style={{minWidth: '368px'}} size={'large'} type={'primary'} htmlType={'submit'}>
              确认
            </Button>
          </Form>
        </div>
      </div>
    </div>
  )
}
