import React, {CSSProperties, useEffect, useState} from 'react'
import commonStyles from '@/assets/styles/common.module.less'
import {Page} from '@/components/Page'
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  FormListFieldData,
  FormListOperation,
  Input,
  InputNumber,
  message,
  Popover,
  Row,
  Select,
  Space,
  Table,
} from 'antd'
import moment from 'moment'
import {ExclamationCircleOutlined, MinusCircleFilled, PlusCircleFilled} from '@ant-design/icons'
import {FormListProps} from 'antd/lib/form/FormList'
import {FormItemProps} from 'antd/lib/form/FormItem'
import {stewardModel} from '@/store/models/Steward'
import {useAppHistory, useAppLocation} from '@/common/hooks/useRouter'
import {sysModel} from '@/store/models/Sys'

const basicLayout = {
  labelCol: {flex: '190px'},
  wrapperCol: {flex: '650px'},
}
const lhLayout = {
  labelCol: {flex: '190px'},
}

export default function LonghuPerformanceNew() {
  const location = useAppLocation<'/performance-longhu/new'>()

  const history = useAppHistory()

  const planId = location.state?.planId
  const action = location.state?.action

  const viewMode = action === 'view'
  const editMode = action === 'edit'
  const copyMode = action === 'copy'

  const [basicForm] = Form.useForm()
  const [lhForm] = Form.useForm()

  const [planDetail, setPlanDetail] = useState({} as StewardSalaryPlanVO)
  const [_cityCodes, setCityCodes] = useState<Option[]>([])
  const cityCodes = editMode
    ? _cityCodes.map(value => ({...value, disabled: planDetail?.cityCodeList?.includes(value.id)}))
    : _cityCodes

  useEffect(() => {
    sysModel.sysConfigGet({k: 'CONFIG_SYS_CITY'}).then(({response: {data}}) => {
      data?.city && setCityCodes(data.city)
    })
  }, [])

  const loading = stewardModel.stewardSalaryPlanUpsertLh.useLoading()

  useEffect(() => {
    if (planId) {
      stewardModel.stewardSalaryPlanDetail({planId}).then(({response: {data}}) => {
        if (data) {
          setPlanDetail(data)
          const {lhConfig, planId, ...rest} = data
          if (copyMode) {
            basicForm.setFieldsValue(rest)
          } else {
            basicForm.setFieldsValue({...rest, planId})
          }

          lhForm.setFieldsValue(lhConfig)
        }
      })
    }
  }, [basicForm, copyMode, lhForm, planId])

  return (
    <Page>
      <div className={commonStyles.filterSection}>
        <Form.Provider
        // onFormFinish={(name, info) => {
        //   console.log('onFormFinish: name, info', name, info)
        // }}
        >
          <Form {...basicLayout} form={basicForm} name={'basicForm'} disabled={viewMode}>
            <Form.Item label={'名称'} name={'planName'} rules={[{required: true}]}>
              <Input
                allowClear
                // undefined 的写法是为了能够继承 Form 组件的 disabled 属性，从而不必重复判断 viewMode 是否存在
                disabled={editMode ? true : undefined}
              />
            </Form.Item>
            <Form.Item
              label={'适用城市'}
              required
              wrapperCol={{flex: `${650 + 128}px`}}
              shouldUpdate={(prevValues, nextValues) => prevValues.cityCodeList !== nextValues.cityCodeList}
            >
              {f => {
                const cityCodeList: string[] = f.getFieldValue('cityCodeList')
                const cityCodeIds = cityCodes.map(value => value.id)
                const checked = cityCodeList?.sort().join(',') === cityCodeIds.sort().join(',')
                return (
                  <Row gutter={[12, 0]} wrap={false} align={'middle'}>
                    <Col flex={1}>
                      <Form.Item noStyle name={'cityCodeList'} rules={[{required: true, message: '请选择适用城市'}]}>
                        <Select
                          mode={'multiple'}
                          placeholder={'请选择'}
                          allowClear={!editMode}
                          options={cityCodes}
                          fieldNames={{label: 'label', value: 'id'}}
                          showSearch
                          filterOption={(inputValue, option) => option.label?.includes(inputValue)}
                        />
                      </Form.Item>
                    </Col>
                    <Col style={{whiteSpace: 'nowrap'}}>
                      <Checkbox
                        checked={!!cityCodeList?.length && checked}
                        onChange={e => {
                          if (e.target.checked) {
                            f.setFieldValue('cityCodeList', cityCodeIds)
                          } else {
                            if (editMode) {
                              f.setFieldValue('cityCodeList', planDetail?.cityCodeList)
                            } else {
                              f.setFieldValue('cityCodeList', [])
                            }
                          }
                        }}
                      >
                        选择所有城市
                      </Checkbox>
                    </Col>
                  </Row>
                )
              }}
            </Form.Item>
            <Form.Item
              label={'适用日期'}
              name={'applyBeginDate'}
              rules={[{required: true, message: '请选择适用日期'}]}
              getValueFromEvent={(_, formatString) => {
                basicForm.setFieldValue('applyEndDate', formatString?.[1])
                return formatString?.[0]
              }}
              getValueProps={value => ({
                value: value ? [moment(value), moment(basicForm.getFieldValue('applyEndDate'))] : value,
              })}
            >
              <DatePicker.RangePicker
                picker={'month'}
                allowClear={!editMode}
                {...(editMode
                  ? {
                      disabled: [true, false],
                      disabledDate: date => date.isSameOrBefore(basicForm.getFieldValue('applyEndDate'), 'month'),
                    }
                  : {})}
              />
            </Form.Item>
          </Form>

          <Form
            disabled={viewMode || editMode}
            {...lhLayout}
            name={'lhForm'}
            form={lhForm}
            onFinish={async () => {
              await basicForm.validateFields()
              const basicValues = basicForm.getFieldsValue(true)
              const lhValues = lhForm.getFieldsValue(true)
              const values = {...basicValues, lhConfig: lhValues}
              await stewardModel.stewardSalaryPlanUpsertLh(values)
              message.success('操作成功')
              history.goBack()
            }}
            initialValues={{
              latenessRateList: [{}],
              advanceCommunicationRateList: [{}],
              firstPassRateList: [{}],
              secondPassRateList: [{}],
              positiveFeedbackRateList: [{}],
              qualifiedRecordingRateList: [{}],
              scoreLevelList: [{}],
            }}
          >
            <Form.Item label={'评分标准权重（%）'} required>
              <Table
                dataSource={[{}]}
                pagination={false}
                columns={[
                  {
                    title: '迟到率',
                    render: () => (
                      <Form.Item noStyle name={'latenessRate'} rules={[{required: true, message: '迟到率不能为空'}]}>
                        <InputNumber min={0} style={{width: '100%'}} placeholder={'请输入数字'} />
                      </Form.Item>
                    ),
                  },
                  {
                    title: '提前沟通率',
                    render: () => (
                      <Form.Item
                        noStyle
                        name={'advanceCommunicationRate'}
                        rules={[{required: true, message: '提前沟通率能为空'}]}
                      >
                        <InputNumber min={0} style={{width: '100%'}} placeholder={'请输入数字'} />
                      </Form.Item>
                    ),
                  },
                  {
                    title: '一次性解决率',
                    render: () => (
                      <Form.Item
                        noStyle
                        name={'firstPassRate'}
                        rules={[{required: true, message: '一次性解决率不能为空'}]}
                      >
                        <InputNumber min={0} style={{width: '100%'}} placeholder={'请输入数字'} />
                      </Form.Item>
                    ),
                  },
                  {
                    title: '二次通过率',
                    render: () => (
                      <Form.Item
                        noStyle
                        name={'secondPassRate'}
                        rules={[{required: true, message: '二次通过率不能为空'}]}
                      >
                        <InputNumber min={0} style={{width: '100%'}} placeholder={'请输入数字'} />
                      </Form.Item>
                    ),
                  },
                  {
                    title: '好评率',
                    render: () => (
                      <Form.Item
                        noStyle
                        name={'positiveFeedbackRate'}
                        rules={[{required: true, message: '好评率不能为空'}]}
                      >
                        <InputNumber min={0} style={{width: '100%'}} placeholder={'请输入数字'} />
                      </Form.Item>
                    ),
                  },
                  {
                    title: '录音合格率',
                    render: () => (
                      <Form.Item
                        noStyle
                        name={'qualifiedRecordingRate'}
                        rules={[{required: true, message: '录音合格率不能为空'}]}
                      >
                        <InputNumber min={0} style={{width: '100%'}} placeholder={'请输入数字'} />
                      </Form.Item>
                    ),
                  },
                  {
                    title: '执行力',
                    render: () => (
                      <Form.Item noStyle name={'executionPower'} rules={[{required: true, message: '执行力不能为空'}]}>
                        <InputNumber min={0} style={{width: '100%'}} placeholder={'请输入数字'} />
                      </Form.Item>
                    ),
                  },
                ]}
              />
            </Form.Item>
            {/*<Form.Item label={'迟到率考核区间'}>
              <Form.List name={'latenessRateList'}>
                {(fields, operation) => {
                  return fields.map(field => {
                    return (
                      <Row
                        key={field.key}
                        gutter={12}
                        style={{alignItems: 'center', flexWrap: 'nowrap', whiteSpace: 'nowrap'}}
                      >
                        <Col>迟到率≥</Col>
                        <Col flex={1}>
                          <Form.Item noStyle name={[field.name, 'begin']} rules={[{required: true, message: '开始值不能为空'}]}>
                            <InputNumber min={0} style={{width: '100%'}} placeholder={'请输入数字'} />
                          </Form.Item>
                        </Col>
                        <Col>＜</Col>
                        <Col flex={1}>
                          <Form.Item noStyle name={[field.name, 'end']} rules={[{required: true, message: '结束值不能为空'}]}>
                            <InputNumber min={0} style={{width: '100%'}} placeholder={'请输入数字'} />
                          </Form.Item>
                        </Col>
                        <Col>，分数</Col>
                        <Col flex={1}>
                          <Form.Item noStyle name={[field.name, 'value']} rules={[{required: true, message: '分数不能为空'}]}>
                            <InputNumber min={0} style={{width: '100%'}} placeholder={'请输入数字'} />
                          </Form.Item>
                        </Col>
                      </Row>
                    )
                  })
                }}
              </Form.List>
            </Form.Item>*/}

            <FormList
              required
              label={
                <Space>
                  <span>迟到率考核区间</span>
                  <Popover
                    arrowPointAtCenter
                    placement={'topLeft'}
                    content={<pre>{`到达时间是当月，到达时间-预约上门时间>30分钟➗到达时间是当月；`}</pre>}
                  >
                    <ExclamationCircleOutlined />
                  </Popover>
                </Space>
              }
              name={'latenessRateList'}
              // onAdd={async (operation, field) => {
              //   field && (await lhForm.validateFields([['latenessRateList', field.name, 'begin']]))
              //   operation.add({})
              // }}
              renderItem={field => (
                <Row
                  key={field.key}
                  gutter={12}
                  style={{alignItems: 'center', flexWrap: 'nowrap', whiteSpace: 'nowrap'}}
                >
                  <Col>迟到率 ≥</Col>
                  <Col flex={1}>
                    <Form.Item
                      noStyle
                      name={[field.name, 'begin']}
                      rules={[{required: true, message: '开始值不能为空'}]}
                    >
                      <InputNumber min={-1} style={{width: '100%'}} placeholder={'请输入数字'} />
                    </Form.Item>
                  </Col>
                  <Col>% ＜</Col>
                  <Col flex={1}>
                    <Form.Item noStyle name={[field.name, 'end']} rules={[{required: true, message: '结束值不能为空'}]}>
                      <InputNumber min={0} style={{width: '100%'}} placeholder={'请输入数字'} />
                    </Form.Item>
                  </Col>
                  <Col>%，分数</Col>
                  <Col flex={1}>
                    <Form.Item noStyle name={[field.name, 'value']} rules={[{required: true, message: '分数不能为空'}]}>
                      <InputNumber min={0} style={{width: '100%'}} placeholder={'请输入数字'} />
                    </Form.Item>
                  </Col>
                </Row>
              )}
            />
            <FormList
              required
              label={
                <Space>
                  <span>提前沟通率考核区间</span>
                  <Popover
                    arrowPointAtCenter
                    placement={'topLeft'}
                    content={
                      <pre>{`当月下单时间9-21点，接单时间-下单时间>30分钟数据➗当月下单9-21点并且接单数据；`}</pre>
                    }
                  >
                    <ExclamationCircleOutlined />
                  </Popover>
                </Space>
              }
              name={'advanceCommunicationRateList'}
              renderItem={field => (
                <Row
                  key={field.key}
                  gutter={12}
                  style={{alignItems: 'center', flexWrap: 'nowrap', whiteSpace: 'nowrap'}}
                >
                  <Col>提前沟通率 ≥</Col>
                  <Col flex={1}>
                    <Form.Item
                      noStyle
                      name={[field.name, 'begin']}
                      rules={[{required: true, message: '开始值不能为空'}]}
                    >
                      <InputNumber min={-1} style={{width: '100%'}} placeholder={'请输入数字'} />
                    </Form.Item>
                  </Col>
                  <Col>% ＜</Col>
                  <Col flex={1}>
                    <Form.Item noStyle name={[field.name, 'end']} rules={[{required: true, message: '结束值不能为空'}]}>
                      <InputNumber min={0} style={{width: '100%'}} placeholder={'请输入数字'} />
                    </Form.Item>
                  </Col>
                  <Col>%，分数</Col>
                  <Col flex={1}>
                    <Form.Item noStyle name={[field.name, 'value']} rules={[{required: true, message: '分数不能为空'}]}>
                      <InputNumber min={0} style={{width: '100%'}} placeholder={'请输入数字'} />
                    </Form.Item>
                  </Col>
                </Row>
              )}
            />
            <FormList
              required
              label={
                <Space>
                  <span>一次性解决率考核区间</span>
                  <Popover
                    arrowPointAtCenter
                    placement={'topLeft'}
                    content={<pre>{`无二次上门完成服务订单数➗当月完成总订单量。`}</pre>}
                  >
                    <ExclamationCircleOutlined />
                  </Popover>
                </Space>
              }
              name={'firstPassRateList'}
              renderItem={field => (
                <Row
                  key={field.key}
                  gutter={12}
                  style={{alignItems: 'center', flexWrap: 'nowrap', whiteSpace: 'nowrap'}}
                >
                  <Col>一次性解决率 ≥</Col>
                  <Col flex={1}>
                    <Form.Item
                      noStyle
                      name={[field.name, 'begin']}
                      rules={[{required: true, message: '开始值不能为空'}]}
                    >
                      <InputNumber min={-1} style={{width: '100%'}} placeholder={'请输入数字'} />
                    </Form.Item>
                  </Col>
                  <Col>% ＜</Col>
                  <Col flex={1}>
                    <Form.Item noStyle name={[field.name, 'end']} rules={[{required: true, message: '结束值不能为空'}]}>
                      <InputNumber min={0} style={{width: '100%'}} placeholder={'请输入数字'} />
                    </Form.Item>
                  </Col>
                  <Col>%，分数</Col>
                  <Col flex={1}>
                    <Form.Item noStyle name={[field.name, 'value']} rules={[{required: true, message: '分数不能为空'}]}>
                      <InputNumber min={0} style={{width: '100%'}} placeholder={'请输入数字'} />
                    </Form.Item>
                  </Col>
                </Row>
              )}
            />
            <FormList
              required
              label={
                <Space>
                  <span>二次通过率考核区间</span>
                  <Popover
                    arrowPointAtCenter
                    placement={'topLeft'}
                    content={<pre>{`二次上门完成订单数(含取消)➗多次上门（上门数>1）当月完成总订单量。`}</pre>}
                  >
                    <ExclamationCircleOutlined />
                  </Popover>
                </Space>
              }
              name={'secondPassRateList'}
              renderItem={field => (
                <Row
                  key={field.key}
                  gutter={12}
                  style={{alignItems: 'center', flexWrap: 'nowrap', whiteSpace: 'nowrap'}}
                >
                  <Col>二次性解决率 ≥</Col>
                  <Col flex={1}>
                    <Form.Item
                      noStyle
                      name={[field.name, 'begin']}
                      rules={[{required: true, message: '开始值不能为空'}]}
                    >
                      <InputNumber min={-1} style={{width: '100%'}} placeholder={'请输入数字'} />
                    </Form.Item>
                  </Col>
                  <Col>% ＜</Col>
                  <Col flex={1}>
                    <Form.Item noStyle name={[field.name, 'end']} rules={[{required: true, message: '结束值不能为空'}]}>
                      <InputNumber min={0} style={{width: '100%'}} placeholder={'请输入数字'} />
                    </Form.Item>
                  </Col>
                  <Col>%，分数</Col>
                  <Col flex={1}>
                    <Form.Item noStyle name={[field.name, 'value']} rules={[{required: true, message: '分数不能为空'}]}>
                      <InputNumber min={0} style={{width: '100%'}} placeholder={'请输入数字'} />
                    </Form.Item>
                  </Col>
                </Row>
              )}
            />
            <FormList
              required
              label={
                <Space>
                  <span>好评率考核区间</span>
                  <Popover
                    arrowPointAtCenter
                    placement={'topLeft'}
                    content={<pre>{`当月完成好评总量（必须>=4星）➗当月完成参与评价订单数量。`}</pre>}
                  >
                    <ExclamationCircleOutlined />
                  </Popover>
                </Space>
              }
              name={'positiveFeedbackRateList'}
              renderItem={field => (
                <Row
                  key={field.key}
                  gutter={12}
                  style={{alignItems: 'center', flexWrap: 'nowrap', whiteSpace: 'nowrap'}}
                >
                  <Col>好评率 ≥</Col>
                  <Col flex={1}>
                    <Form.Item
                      noStyle
                      name={[field.name, 'begin']}
                      rules={[{required: true, message: '开始值不能为空'}]}
                    >
                      <InputNumber min={-1} style={{width: '100%'}} placeholder={'请输入数字'} />
                    </Form.Item>
                  </Col>
                  <Col>% ＜</Col>
                  <Col flex={1}>
                    <Form.Item noStyle name={[field.name, 'end']} rules={[{required: true, message: '结束值不能为空'}]}>
                      <InputNumber min={0} style={{width: '100%'}} placeholder={'请输入数字'} />
                    </Form.Item>
                  </Col>
                  <Col>%，分数</Col>
                  <Col flex={1}>
                    <Form.Item noStyle name={[field.name, 'value']} rules={[{required: true, message: '分数不能为空'}]}>
                      <InputNumber min={0} style={{width: '100%'}} placeholder={'请输入数字'} />
                    </Form.Item>
                  </Col>
                </Row>
              )}
            />
            <FormList
              required
              label={
                <Space>
                  <span>录音合格率考核区间</span>
                  <Popover
                    arrowPointAtCenter
                    placement={'topLeft'}
                    content={<pre>{`客服抽查在当月监控录音合格量➗当月抽查产生监控录音总量。`}</pre>}
                  >
                    <ExclamationCircleOutlined />
                  </Popover>
                </Space>
              }
              name={'qualifiedRecordingRateList'}
              renderItem={field => (
                <Row
                  key={field.key}
                  gutter={12}
                  style={{alignItems: 'center', flexWrap: 'nowrap', whiteSpace: 'nowrap'}}
                >
                  <Col>录音合格率 ≥</Col>
                  <Col flex={1}>
                    <Form.Item
                      noStyle
                      name={[field.name, 'begin']}
                      rules={[{required: true, message: '开始值不能为空'}]}
                    >
                      <InputNumber min={-1} style={{width: '100%'}} placeholder={'请输入数字'} />
                    </Form.Item>
                  </Col>
                  <Col>% ＜</Col>
                  <Col flex={1}>
                    <Form.Item noStyle name={[field.name, 'end']} rules={[{required: true, message: '结束值不能为空'}]}>
                      <InputNumber min={0} style={{width: '100%'}} placeholder={'请输入数字'} />
                    </Form.Item>
                  </Col>
                  <Col>%，分数</Col>
                  <Col flex={1}>
                    <Form.Item noStyle name={[field.name, 'value']} rules={[{required: true, message: '分数不能为空'}]}>
                      <InputNumber min={0} style={{width: '100%'}} placeholder={'请输入数字'} />
                    </Form.Item>
                  </Col>
                </Row>
              )}
            />

            <Form.Item label={'执行力'} required>
              <Table
                dataSource={[{}]}
                pagination={false}
                rowKey={(_, index) => index}
                columns={['A', 'B', 'C', 'D', 'E'].map((value, index) => ({
                  title: value,
                  render: () => (
                    <Form.Item
                      noStyle
                      name={['executionPowerList', index, 'value']}
                      rules={[{required: true, message: `${value}不能为空`}]}
                    >
                      <InputNumber
                        min={0}
                        placeholder={'请输入数字'}
                        style={{width: '100%'}}
                        onChange={() => lhForm.setFieldValue(['executionPowerList', index, 'key'], value)}
                      />
                    </Form.Item>
                  ),
                }))}
                // columns={[
                //   {
                //     title: 'A',
                //     render: () => (
                //       <Form.Item noStyle name={['executionPowerList', 0, 'value']}>
                //         <InputNumber min={0}
                //           style={{width: '100%'}}
                //           onChange={() => lhForm.setFieldValue(['executionPowerList', 0, 'key'], 'A')}
                //         />
                //       </Form.Item>
                //     ),
                //   },
                // ]}
              />
            </Form.Item>

            <FormList
              required
              label={'评分档次'}
              name={'scoreLevelList'}
              renderItem={field => (
                <Row
                  key={field.key}
                  gutter={12}
                  style={{alignItems: 'center', flexWrap: 'nowrap', whiteSpace: 'nowrap'}}
                >
                  <Col>绩效评分 ≥</Col>
                  <Col flex={1}>
                    <Form.Item
                      noStyle
                      name={[field.name, 'begin']}
                      rules={[{required: true, message: '开始值不能为空'}]}
                    >
                      <InputNumber min={0} style={{width: '100%'}} placeholder={'请输入数字'} />
                    </Form.Item>
                  </Col>
                  <Col>分 ＜</Col>
                  <Col flex={1}>
                    <Form.Item noStyle name={[field.name, 'end']} rules={[{required: true, message: '结束值不能为空'}]}>
                      <InputNumber min={0} style={{width: '100%'}} placeholder={'请输入数字'} />
                    </Form.Item>
                  </Col>
                  <Col>系数</Col>
                  <Col flex={1}>
                    <Form.Item noStyle name={[field.name, 'value']} rules={[{required: true, message: '分数不能为空'}]}>
                      <InputNumber min={0} style={{width: '100%'}} placeholder={'请输入数字'} />
                    </Form.Item>
                  </Col>
                </Row>
              )}
            />
          </Form>

          {/*独立开来，form 的disabled就影响不到按钮的状态*/}
          {!viewMode && (
            <Form
              {...basicLayout}
              name={'submitForm'}
              onFinish={() => lhForm.submit()}
              onReset={() => {
                basicForm.resetFields()
                lhForm.resetFields()
              }}
            >
              <Form.Item label={' '} colon={false}>
                <Space>
                  <Button htmlType={'submit'} type={'primary'} loading={loading}>
                    提交
                  </Button>
                  {/*编辑模式下不可重置，因为 disabled 的字段太多了，难以控制*/}
                  {!editMode && <Button htmlType={'reset'}>重置</Button>}
                </Space>
              </Form.Item>
            </Form>
          )}
        </Form.Provider>
      </div>
    </Page>
  )
}

const FormList: React.FC<
  {
    renderItem: (field: FormListFieldData) => React.ReactNode
    onAdd?: (operation: FormListOperation, field?: FormListFieldData) => void
    onRemove?: (operation: FormListOperation, field: FormListFieldData) => void
  } & Pick<FormListProps, 'name' | 'rules'> &
    Pick<FormItemProps, 'label' | 'required'>
> = props => {
  return (
    <Form.Item label={props.label} required={props.required}>
      <Form.List name={props.name} rules={props.rules ?? (props.required ? defaultRules : undefined)}>
        {(fields, operation, meta) => {
          return (
            <>
              {fields.length ? (
                fields.map((field, index, array) => {
                  return (
                    <Row key={field.key} gutter={12} style={RowStyle}>
                      <Col flex={1}>{props.renderItem(field)}</Col>
                      <Col flex={'50px'}>
                        {index + 1 === array.length && (
                          <Space>
                            <Button
                              type={'text'}
                              style={{padding: 0}}
                              onClick={() => props.onRemove?.(operation, field)}
                            >
                              <MinusCircleFilled />
                            </Button>
                            <Button type={'text'} style={{padding: 0}} onClick={() => props.onAdd?.(operation, field)}>
                              <PlusCircleFilled />
                            </Button>
                          </Space>
                        )}
                      </Col>
                    </Row>
                  )
                })
              ) : (
                <Row gutter={12} style={RowStyle}>
                  <Col flex={'50px'}>
                    <Button type={'text'} style={{padding: 0}} onClick={() => props.onAdd?.(operation, null)}>
                      <PlusCircleFilled />
                    </Button>
                  </Col>
                </Row>
              )}
              <Form.ErrorList errors={meta.errors} />
            </>
          )
        }}
      </Form.List>
    </Form.Item>
  )
}

FormList.defaultProps = {
  onAdd: operation => operation.add({}),
  onRemove: (operation, field) => operation.remove(field.name),
}
const RowStyle: CSSProperties = {
  minHeight: 32,
  alignItems: 'center',
  flexWrap: 'nowrap',
  whiteSpace: 'nowrap',
  marginBottom: 12,
}

const defaultRules = [
  {
    validator: async (_, value) => {
      if (!value?.length) {
        return Promise.reject(new Error('该项不能为空'))
      }
    },
  },
]
