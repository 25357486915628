import React, {useCallback, useEffect, useState} from 'react'
import {Page} from '@/components/Page'
import {Breadcrumb} from '@/components/Breadcrumb'
import commonStyles from '@/assets/styles/common.module.less'
import {Button, Cascader, Col, Form, Input, message, Modal, Row, Space, Table} from 'antd'
import {getPaginationProps} from '@/common/biz/getPaginationProps'
import {zzajModel} from '@/store/models/Zzaj'
import {stewardModel} from '@/store/models/Steward'
import {CloseCircleTwoTone, PlusCircleTwoTone} from '@ant-design/icons'
import {usePermission} from '@/components/Permission'

export default function AnJuQualityProject() {
  const [form] = Form.useForm()
  const [total, setTotal] = useState(0)
  const [list, setList] = useState<ZzajQaItemListVO[]>([])
  const loading = zzajModel.zzajQaItemList.useLoading()
  const [auth] = usePermission()

  const [addOpen, setAddOpen] = useState(false)
  const [addForm] = Form.useForm()

  const search = useCallback(() => {
    const values = form.getFieldsValue(true)
    zzajModel.zzajQaItemList(values).then(({response: {data, total}}) => {
      setList(data)
      setTotal(total)
    })
  }, [form])

  useEffect(() => {
    search()
  }, [search])

  const [serviceTree, setServiceTree] = useState<StewardServiceVO[]>([])
  useEffect(() => {
    stewardModel.stewardServiceTree({}).then(({response: {data}}) => setServiceTree(data))
  }, [])

  return (
    <Page
      breadcrumb={
        <Breadcrumb
          right={
            auth('003177012') && (
              <Button type={'primary'} onClick={() => setAddOpen(true)}>
                新增
              </Button>
            )
          }
        />
      }
    >
      <div className={commonStyles.filterSection}>
        <Form form={form} onFinish={search} onReset={search} initialValues={{pageNum: 1, pageSize: 10, pageable: true}}>
          <Row justify={'space-between'}>
            <Col span={7}>
              <Form.Item label={'质保项目名称'} name={'itemName'}>
                <Input placeholder={'请输入'} allowClear />
              </Form.Item>
            </Col>
            <Space>
              <Button htmlType={'submit'} type={'primary'} onClick={() => form.setFieldsValue({pageNum: 1})}>
                查询
              </Button>
              <Button htmlType={'reset'}>重置</Button>
            </Space>
          </Row>
        </Form>
      </div>
      <div className={commonStyles.filterSection}>
        <Table
          rowKey={'itemId'}
          dataSource={list}
          loading={loading}
          columns={[
            {title: '质保项目名称', dataIndex: 'itemName'},
            {
              title: '关联服务项目',
              dataIndex: 'itemSvcDetailVOList',
              render: value => value.map(item => item.categoryThiName).join(','),
            },
            {
              title: '操作',
              render: (_, record) => {
                return (
                  <Space>
                    {auth('002177013') && (
                      <Button
                        type={'link'}
                        style={{paddingLeft: 0, paddingRight: 0}}
                        onClick={() => {
                          const {itemId, itemName, itemSvcDetailVOList} = record
                          addForm.setFieldsValue({
                            itemId,
                            itemName,
                            serviceTypeList: itemSvcDetailVOList.map(
                              ({
                                categoryFir,
                                categoryFirName,
                                categorySec,
                                categorySecName,
                                categoryThi,
                                categoryThiName,
                              }) => ({
                                _id: [categoryFir, categorySec, categoryThi],
                                categoryFir,
                                categoryFirName,
                                categorySec,
                                categorySecName,
                                categoryThi,
                                categoryThiName,
                              })
                            ),
                          })
                          setAddOpen(true)
                        }}
                      >
                        编辑
                      </Button>
                    )}
                    {auth('004177017') && (
                      <Button
                        type={'link'}
                        style={{paddingLeft: 0, paddingRight: 0}}
                        onClick={() => {
                          Modal.confirm({
                            title: '提示',
                            icon: null,
                            centered: true,
                            content: '确认删除？',
                            onOk: async () => {
                              await zzajModel.zzajQaItemDelete({itemId: record.itemId})
                              message.success('删除成功！')
                              search()
                            },
                          })
                        }}
                      >
                        删除
                      </Button>
                    )}
                  </Space>
                )
              },
            },
          ]}
          pagination={getPaginationProps(form, total)}
        />
      </div>

      <Modal
        open={addOpen}
        title={addForm.getFieldValue('itemId') ? '编辑' : '新增'}
        centered
        onCancel={() => setAddOpen(false)}
        destroyOnClose
        afterClose={() => addForm.resetFields()}
        width={500}
        onOk={async () => {
          await addForm.validateFields()
          const {serviceTypeList, ...values} = addForm.getFieldsValue(true)
          const newListLength = new Set(serviceTypeList.map(item => item.categoryThi)).size
          if (serviceTypeList.length > newListLength) return message.warning('关联服务项目有重复！')
          await zzajModel.zzajQaItemUpset({...values, serviceTypeList})
          setAddOpen(false)
          search()
        }}
      >
        <Form form={addForm} wrapperCol={{span: 15}} labelCol={{flex: '120px'}} initialValues={{serviceTypeList: [{}]}}>
          <Form.Item label={'质保项目名称'} name={'itemName'} rules={[{required: true, message: '请输入质保项目名称'}]}>
            <Input placeholder={'请输入'} allowClear />
          </Form.Item>
          <Form.Item label={'关联服务项目'} required>
            <Form.List name={'serviceTypeList'}>
              {(fields, {add, remove}) => (
                <>
                  {fields.map(({key, name, ...filed}, index) => (
                    <div key={key}>
                      <Space>
                        <Form.Item
                          name={[name, '_id']}
                          {...filed}
                          rules={[{required: true, message: '请选择关联服务项目'}]}
                        >
                          <Cascader
                            options={serviceTree}
                            fieldNames={{label: 'name', value: 'id', children: 'stewardServiceList'}}
                            onChange={(value, option) => {
                              if (value?.length) {
                                const [categoryFir, categorySec, categoryThi] = value
                                const [categoryFirName, categorySecName, categoryThiName] = option.map(
                                  item => item.name
                                )
                                addForm.setFields([
                                  {name: ['serviceTypeList', name, 'categoryFir'], value: categoryFir},
                                  {name: ['serviceTypeList', name, 'categoryFirName'], value: categoryFirName},
                                  {name: ['serviceTypeList', name, 'categorySec'], value: categorySec},
                                  {name: ['serviceTypeList', name, 'categorySecName'], value: categorySecName},
                                  {name: ['serviceTypeList', name, 'categoryThi'], value: categoryThi},
                                  {name: ['serviceTypeList', name, 'categoryThiName'], value: categoryThiName},
                                ])
                              } else
                                addForm.resetFields([
                                  ['serviceTypeList', name, 'categoryFir'],
                                  ['serviceTypeList', name, 'categoryFirName'],
                                  ['serviceTypeList', name, 'categorySec'],
                                  ['serviceTypeList', name, 'categorySecName'],
                                  ['serviceTypeList', name, 'categoryThi'],
                                  ['serviceTypeList', name, 'categoryThiName'],
                                ])
                            }}
                            placeholder={'请选择'}
                            style={{width: 240}}
                          />
                        </Form.Item>
                        {index === 0 && (
                          <Form.Item>
                            <PlusCircleTwoTone twoToneColor='#00B7AE' onClick={() => add()} />
                          </Form.Item>
                        )}
                        {fields.length > 1 && (
                          <Form.Item>
                            <CloseCircleTwoTone
                              twoToneColor='#A8A7AA'
                              onClick={() => {
                                remove(name)
                              }}
                            />
                          </Form.Item>
                        )}
                      </Space>
                    </div>
                  ))}
                </>
              )}
            </Form.List>
          </Form.Item>
        </Form>
      </Modal>
    </Page>
  )
}
