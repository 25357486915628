import React, {useCallback, useEffect, useRef, useState} from 'react'
import commonStyle from '@/assets/styles/common.module.less'
import {
  Input,
  Form,
  Row,
  Col,
  Select,
  Space,
  Button,
  InputNumber,
  message,
  TreeSelect,
  Radio,
  Checkbox,
  Modal,
  PageHeader,
} from 'antd'
import {commonModel, useCodeArraySync} from '@/store/models/Common'
import {debounce} from '@/utils/util'
import {sysUserModel} from '@/store/models/SysUser'
import {useAppHistory, useAppLocation} from '@/common/hooks/useRouter'
import {condoModel} from '@/store/models/Condo'
import {customerModel} from '@/store/models/Customer'
import {sysModel} from '@/store/models/Sys'
import {userModel} from '@/store/models/User'
import DividerLine from '@/pages/property/component/DividerLine'
import {UpOutlined, DownOutlined} from '@ant-design/icons'
import Location from '@/assets/icons/location.svg'
import {Mapview} from '@/components/Map'
import MapLocation from '@/assets/icons/map_location.svg'
import {Page} from '@/components/Page'

const {Option} = Select
const topFloorArr = Array(99)
  .fill(1)
  .map((_, index) => index + 1)
export default function AddProject() {
  const history = useAppHistory()
  const location = useAppLocation<'/concentrate-pm/add'>()
  const condoProjectId = location.state?.condoProjectId
  const [form] = Form.useForm()
  const [moreVisible, setMoreVisible] = useState(false)

  const orgId = userModel.useData(data => data.userInfo?.orgId)
  const mapRef = useRef(null)
  const [mapVisible, setMapVisible] = useState(false)
  const [selectForm] = Form.useForm()

  // 地址相关
  const [province, setProvince] = useState<CityDataProps[]>()
  const [city, setCity] = useState<CityDataProps[]>()
  const [district, setDistrict] = useState<CityDataProps[]>()
  const [bizCircle, setBizCircle] = useState<CityDataProps[]>()
  //客户列表
  const [clientList, setClientList] = useState<LessorCustomerListVO[]>([])

  //店长列表
  const [storeManager, setStoreManager] = useState<SysUserVO[]>([])

  //维修服务平台
  const serviceTypeArr = useCodeArraySync('condo.project.repairSource')
  const [visible, setVisible] = useState(false)
  const loading = condoModel.condoProjectUpsert.useLoading()
  const [projectNameList, setProjectNameList] = useState<SysOrgTreeNodeVO[]>([])
  //服务公司
  const [companyList, setCompanyList] = useState<CondoProjectCompanyVO[]>([])
  //基础配置
  const projectEquipment = useCodeArraySync('condo.project.equipment')
  // 查地址
  const queryCityCode = useCallback(function (params: {type: '1' | '2' | '3' | '4'; parentCode: string}) {
    commonModel.queryCityCode(params).then(({response: {data}}) => {
      switch (params.type) {
        case '1':
          setProvince(data)
          break
        case '2':
          setCity(data)
          break
        case '3':
          setDistrict(data)
          break
        case '4':
          setBizCircle(data)
          break
      }
    })
  }, [])

  useEffect(() => {
    sysModel.sysOrgTree({fetchUser: false, orgIdLike: '0006'} as SysOrgTreeQueryDTO).then(({response: {data}}) => {
      // 【1001664】项目名称选后台组织管理里的所有叶子组织，单选
      const leafData = (function checkSelectable(value: SysOrgTreeNodeVO[]) {
        value.forEach((item: any) => {
          item.selectable = !item.children?.length
          item.disabled = !!item.children?.length
          if (item.children?.length) {
            checkSelectable(item.children)
          }
        })
        return value
      })(data as SysOrgTreeNodeVO[])
      setProjectNameList(leafData)
    })
  }, [orgId])

  useEffect(() => {
    condoModel
      .condoProjectCompanyList({pageable: false, status: '1'} as CondoProjectCompanyListDTO)
      .then(({response: {data}}) => {
        setCompanyList(data)
      })
  }, [])

  const searchClientList = useCallback(
    debounce(async value => {
      const {
        response: {data},
      } = await customerModel.customerList({
        certName: value,
        pageSize: 10,
        pageable: false,
        status: '1',
      } as LessorCustomerListDTO)
      setClientList(data)
    }),
    []
  )

  useEffect(() => {
    sysUserModel.sysUserList({roleId: 'condo_manager'}).then(({response: {data}}) => {
      setStoreManager(data)
    })
  }, [form])

  const [teamTree, setTeamTree] = useState<TeamTreeNodeVO[]>([])

  const searchTeamTree = useCallback(async value => {
    const {
      response: {data},
    } = await customerModel.customerTeamGetTeamTree({ownerId: value})

    setTeamTree(data)
  }, [])

  useEffect(() => {
    queryCityCode({type: '1', parentCode: ''})
  }, [queryCityCode])

  async function submit() {
    const {feeRatio, condoEquipmentList, ...formValues} = form.getFieldsValue(true)
    await condoModel.condoProjectUpsert({
      ...formValues,
      feeRatio: Number(feeRatio),
      condoEquipmentList: condoEquipmentList ?? [],
    })
    if (condoProjectId) {
      message.success('项目编辑成功！')
    } else {
      message.success('创建项目成功！')
    }
    history.goBack()
  }

  function finish() {
    if (!condoProjectId) return setVisible(true)
    submit()
  }

  useEffect(() => {
    if (condoProjectId) {
      condoModel.condoProjectInfo({condoProjectId}).then(({response: {data}}) => {
        const {condoEquipmentList} = data
        form.setFieldsValue(data)
        queryCityCode({type: '2', parentCode: data.provinceCode})
        queryCityCode({type: '3', parentCode: data.cityCode})
        queryCityCode({type: '4', parentCode: data.districtCode})
        searchClientList(data.lessorName)
        searchTeamTree(data.lessorId)
        setMoreVisible(!!condoEquipmentList?.length)
      })
    }
  }, [condoProjectId, form, queryCityCode, searchClientList, searchTeamTree, selectForm])

  async function enterSelectAddress() {
    const address = selectForm.getFieldValue('address')
    if (address) {
      const point = await mapRef.current?.geocoder(address)
      mapRef.current?.panTo(point.lng, point.lat)
      selectForm.setFieldsValue({lng: point.lng, lat: point.lat})
    }
  }

  function onSelectFinish() {
    const {lat, lng, address} = selectForm.getFieldsValue(true)
    form.setFieldsValue({lat, lng, address})
  }

  return (
    <Page header={<PageHeader ghost={false} title={'创建项目'} onBack={() => history.goBack()} />}>
      <div className={commonStyle.filterSection} style={{margin: '20px 20px'}}>
        <Form form={form} labelCol={{span: 3}} wrapperCol={{span: 10}} onFinish={finish}>
          <DividerLine title='客户信息' style={{paddingBottom: '30px'}} />
          <Form.Item label={'客户姓名'} name={'lessorId'} rules={[{required: true, message: '请选择客户姓名'}]}>
            <Select
              placeholder={'请选择'}
              showSearch
              allowClear
              onSearch={searchClientList}
              onChange={(value, option: any) => {
                searchTeamTree(value)
                form.setFieldsValue({lessorName: option?.children?.[0], teamPid: undefined, teamId: undefined})
              }}
              disabled={!!condoProjectId}
              filterOption={false}
            >
              {clientList.map(item => (
                <Option value={item.userId} key={item.userId}>
                  {item.certName}（{item.mobile}）
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label={'组织选择'} name={'teamPid'} rules={[{required: true, message: '请选择组织'}]}>
            <Select
              placeholder={'请选择'}
              onChange={value => {
                const currentTree = teamTree.find(item => item.teamPid === value)
                if (currentTree) {
                  const childrenTree = currentTree.children[0]
                  form.setFieldsValue({teamId: childrenTree.teamId})
                } else {
                  form.setFieldsValue({teamId: undefined})
                }
              }}
              allowClear
            >
              {teamTree.map(item => (
                <Select.Option key={item.teamPid} value={item.teamPid}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label={'计费系数'} name={'feeRatio'} rules={[{required: true, message: '请输入计费系数'}]}>
            <InputNumber min={0 as number} step={0.1} style={{width: '100%'}} placeholder={'请输入'} stringMode />
          </Form.Item>
          <Form.Item
            label={'维修服务平台'}
            name={'repairSource'}
            rules={[{required: true, message: '请选择维修服务平台'}]}
          >
            <Select placeholder={'请选择'} allowClear>
              {serviceTypeArr.map(item => (
                <Option value={item.id} key={item.id}>
                  {item.label}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <DividerLine title='项目信息' style={{padding: '30px 0'}} />
          <Form.Item label={'项目编号'} name={'projectCode'} rules={[{required: true, message: '请输入项目编号'}]}>
            <Input placeholder={'请输入'} disabled={!!condoProjectId} allowClear />
          </Form.Item>
          <Form.Item label={'关联组织'} name={'orgId'} rules={[{required: true, message: '请输入项目名称'}]}>
            <TreeSelect
              placeholder={'请选择（项目名称）'}
              treeData={projectNameList}
              treeDefaultExpandAll
              onChange={(value, labelList) => {
                form.setFieldsValue({projectName: labelList?.[0], condoName: labelList?.[0]})
              }}
              allowClear
            />
          </Form.Item>
          <Form.Item label={'财务公司'} name={'socialCreditCode'} rules={[{required: true, message: '请选择财务公司'}]}>
            <Select placeholder={'请选择'} allowClear>
              {companyList.map(item => (
                <Select.Option key={item.uniCredit} value={item.uniCredit}>
                  {item.companyName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          {/*<Form.Item label={'合同模板'}>*/}
          {/*  <Select placeholder={'请选择'}>*/}
          {/*    <Select.Option value={'1'}>1</Select.Option>*/}
          {/*    <Select.Option value={'2'}>2</Select.Option>*/}
          {/*  </Select>*/}
          {/*</Form.Item>*/}

          <Form.Item
            label={'公寓联系电话'}
            name={'projectMobile'}
            rules={[
              {required: true, message: '请输入公寓联系电话'},
              {len: 11, message: '请输入11位手机号'},
            ]}
          >
            <Input placeholder={'请输入'} allowClear />
          </Form.Item>
          <Form.Item label={'店长'} name={'sysUserId'}>
            <Select showSearch optionFilterProp={'children'} allowClear>
              {storeManager.map(value => (
                <Select.Option key={value.userId} value={value.userId}>
                  {value.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item label={'所在地区'} style={{marginBottom: 0}} required={true}>
            <Row gutter={10}>
              <Col flex={1}>
                <Form.Item name={'provinceCode'} labelCol={{span: 3}} rules={[{required: true, message: '请选择省'}]}>
                  <Select
                    placeholder={'请选择省'}
                    onChange={(value: string, option: any) => {
                      queryCityCode({type: '2', parentCode: value})
                      form.setFieldsValue({
                        province: option.children,
                        city: undefined,
                        cityCode: undefined,
                        district: undefined,
                        districtCode: undefined,
                        bizCircle: undefined,
                        bizCircleCode: undefined,
                      })
                    }}
                    allowClear
                  >
                    {province?.map(item => (
                      <Option key={item.code} value={item.code}>
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col flex={1}>
                <Form.Item name={'cityCode'} rules={[{required: true, message: '请选择市'}]}>
                  <Select
                    placeholder={'请选择市'}
                    onChange={(value: string, option: any) => {
                      queryCityCode({type: '3', parentCode: value})
                      form.setFieldsValue({
                        city: option.children,
                        district: undefined,
                        districtCode: undefined,
                        bizCircle: undefined,
                        bizCircleCode: undefined,
                      })
                    }}
                    allowClear
                  >
                    {city?.map(item => (
                      <Option key={item.code} value={item.code}>
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col flex={1}>
                <Form.Item name={'districtCode'} rules={[{required: true, message: '请选择区'}]}>
                  <Select
                    placeholder={'请选择区'}
                    onChange={(value: string, option: any) => {
                      queryCityCode({type: '4', parentCode: value})
                      form.setFieldsValue({
                        district: option.children,
                        bizCircle: undefined,
                        bizCircleCode: undefined,
                      })
                    }}
                    allowClear
                  >
                    {district?.map(item => (
                      <Option key={item.code} value={item.code}>
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col flex={1}>
                <Form.Item name={'bizCircleCode'} rules={[{required: true, message: '请选择商圈'}]}>
                  <Select
                    placeholder={'请选择商圈'}
                    allowClear
                    onChange={(value: string, option: any) => form.setFieldsValue({bizCircle: option.children})}
                  >
                    {bizCircle?.map(item => (
                      <Option key={item.code} value={item.code}>
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Form.Item>
          <Form.Item label={'详细地址'} name={'address'} rules={[{required: true, message: '请输入详细地址'}]}>
            <Input
              placeholder='地图标点选择'
              suffix={<img src={Location} alt={'icon'} style={{height: 20, width: 15}} />}
              onClick={() => {
                const {lng, lat, address} = form.getFieldsValue(true)
                if (!!lat && !!lng) {
                  setTimeout(() => mapRef.current?.panTo(lng, lat), 1000)
                }
                selectForm.setFieldsValue({lng, lat, address})
                setMapVisible(true)
              }}
              allowClear
            />
          </Form.Item>
          <Form.Item label={'楼层总高'} name={'topFloor'} rules={[{required: true, message: '请选择楼层总高'}]}>
            <Select placeholder={'请选择'} allowClear>
              {topFloorArr.map(item => (
                <Select.Option key={item} value={item}>
                  {item}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label={'电梯房'} name={'lift'} rules={[{required: true, message: '请选择是否为电梯房'}]}>
            <Radio.Group>
              <Radio value={'1'}>是</Radio>
              <Radio value={'0'}>否</Radio>
            </Radio.Group>
          </Form.Item>
          {moreVisible && (
            <Form.Item label={'基础设施'} wrapperCol={{span: 12}} name={'condoEquipmentList'}>
              <Checkbox.Group>
                <Row>
                  {projectEquipment.map(item => (
                    <Col span={6} key={item.id}>
                      <Checkbox value={item.id}>{item.label}</Checkbox>
                    </Col>
                  ))}
                </Row>
              </Checkbox.Group>
            </Form.Item>
          )}

          <Form.Item label={' '} colon={false}>
            <Space>
              <span
                style={{color: '#00B7AE'}}
                onClick={() => {
                  setMoreVisible(!moreVisible)
                }}
              >
                {moreVisible ? '收起' : '展开更多配置'}
              </span>
              {moreVisible ? <UpOutlined style={{color: '#00B7AE'}} /> : <DownOutlined style={{color: '#00B7AE'}} />}
            </Space>
          </Form.Item>

          <Form.Item label={' '} colon={false}>
            <Space>
              <Button loading={loading} type={'primary'} htmlType={'submit'}>
                提交
              </Button>
              <Button onClick={() => history.goBack()}>取消</Button>
            </Space>
          </Form.Item>
        </Form>
      </div>

      {/* 确认弹框 */}
      <Modal
        visible={visible}
        title={'提示'}
        centered
        cancelText={'取消'}
        onCancel={() => setVisible(false)}
        okText={'确认'}
        onOk={submit}
        confirmLoading={loading}
      >
        <span>是否确认创建该项目？</span>
      </Modal>

      {/* 地图选点 */}
      <Modal
        visible={mapVisible}
        onCancel={() => setMapVisible(false)}
        centered
        title={'地图选点'}
        closable={false}
        width={650}
        okText={'确认'}
        onOk={() => {
          selectForm.submit()
          setMapVisible(false)
        }}
        cancelText={'取消'}
      >
        <Form form={selectForm} onFinish={onSelectFinish}>
          <Form.Item label='详细地址' name={'address'} rules={[{required: true, message: '请输入详细地址!'}]}>
            <Input
              placeholder={'输入地址'}
              allowClear
              onPressEnter={event => {
                event.preventDefault()
                enterSelectAddress()
              }}
            />
          </Form.Item>
          <Form.Item>
            <div style={{position: 'relative'}}>
              <Mapview
                onMapChange={async event => {
                  const point = event.point
                  const {
                    response: {data},
                  } = await commonModel.commonAppHomeMapGeocoder(point)
                  selectForm.setFieldsValue({address: data.address, lat: data.lat, lng: data.lng})
                }}
                style={{height: 330}}
                ref={mapRef}
              />
              <img src={MapLocation} alt={'icon'} className={'edit-site-location'} />
            </div>
          </Form.Item>
        </Form>
      </Modal>
    </Page>
  )
}
