import React from 'react'
import {Page} from '@/components/Page'
import commonStyle from '@/assets/styles/common.module.less'
import {Button, Form, Input, InputNumber, message, Select, Space} from 'antd'
import {useCodeArraySync} from '@/store/models/Common'
import {saleModel} from '@/store/models/Sale'
import {useAppHistory} from '@/common/hooks/useRouter'
import {userModel} from '@/store/models/User'
import AreaCascader from '@/components/AreaCascader'

export default function AddCustomer() {
  const userInfo = userModel.useData(data => data.userInfo)
  const history = useAppHistory()
  const [form] = Form.useForm()
  const visitStatusArr = useCodeArraySync('sale.customer.visit.status')
  const gender = useCodeArraySync('gender')
  const typeCodeArr = useCodeArraySync('sale.customer.type')
  const statusCodeArr = useCodeArraySync('sale.customer.status')?.filter(item => item.id !== '4')
  const sourceCodeArr = useCodeArraySync('sale.customer.source')

  //保存
  async function submit() {
    const {addressValue, ...formValues} = form.getFieldsValue(true)
    await saleModel.saleCustomerUpsert({
      ...formValues,
      holderId: userInfo.userId,
      ...addressValue,
    })
    message.success('保存成功')
    history.goBack()
  }

  return (
    <Page>
      <div className={commonStyle.filterSection}>
        <Form form={form} onFinish={submit} labelCol={{span: 5}} wrapperCol={{span: 10}}>
          <Form.Item label={'姓名'} name={'name'} rules={[{required: true, message: '请输入姓名'}]}>
            <Input placeholder='请输入姓名' allowClear />
          </Form.Item>
          <Form.Item label={'手机号码'} name={'mobile'} rules={[{required: true, message: '请输入手机号码'}]}>
            <Input placeholder='请输入手机号码' allowClear />
          </Form.Item>
          <Form.Item label={'身份证号'} name={'certNo'}>
            <Input placeholder='请输入身份证号码' allowClear />
          </Form.Item>
          <Form.Item label={'年龄'} name={'age'}>
            <InputNumber placeholder='请输入年龄' style={{width: '100%'}} />
          </Form.Item>
          <Form.Item label={'房间数量'} name={'roomNum'}>
            <Input placeholder='请输入房间数量' allowClear />
          </Form.Item>
          <Form.Item label={'邀约状态'} name={'visitStatus'} rules={[{required: true, message: '请选择邀约状态'}]}>
            <Select placeholder={'请选择邀约状态'} allowClear>
              {visitStatusArr.map(item => (
                <Select.Option key={item.id} value={item.id}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label={'性别'} name={'sex'} rules={[{required: true, message: '请选择性别'}]}>
            <Select placeholder={'请选择'} allowClear>
              {gender.map(item => (
                <Select.Option key={item.id} value={item.id}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label={'类型'} name={'type'} rules={[{required: true, message: '请选择类型'}]}>
            <Select placeholder={'请选择'} allowClear>
              {typeCodeArr.map(item => (
                <Select.Option key={item.id} value={item.id}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label={'意向度'} name={'customerStatus'} rules={[{required: true, message: '请选择意向度'}]}>
            <Select placeholder={'请选择意向度'} allowClear>
              {statusCodeArr.map(item => (
                <Select.Option key={item.id} value={item.id}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label={'客户来源'} name={'customerSource'}>
            <Select placeholder={'请选择'} allowClear>
              {sourceCodeArr.map(item => (
                <Select.Option key={item.id} value={item.id}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label={'区域'} name={'addressValue'} rules={[{required: true, validator: addressValid}]}>
            <AreaCascader />
          </Form.Item>
          <Form.Item label={'地址'} name={'address'}>
            <Input placeholder='请输入地址' allowClear />
          </Form.Item>
          <Form.Item label={'备注信息'} name={'remark'}>
            <Input.TextArea placeholder='请输入备注信息' allowClear />
          </Form.Item>

          <Form.Item label={' '} colon={false}>
            <Space>
              <Button type={'primary'} htmlType={'submit'}>
                保存
              </Button>
              <Button
                onClick={() => {
                  history.goBack()
                }}
              >
                返回
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </div>
    </Page>
  )
}

export function addressValid(rule: any, value: any, callback: Function) {
  if (!value) {
    callback('区域不能为空')
    return
  }

  if (!value?.provinceCode) {
    return callback('省不能为空')
  }

  if (!value?.cityCode) {
    callback('市不能为空')
    return
  }

  if (!value?.districtCode) {
    callback('区不能为空')
    return
  }

  callback()
}
