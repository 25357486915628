import React, {useEffect, useState} from 'react'
import {Breadcrumb} from '@/components/Breadcrumb'
import commonStyles from '@/assets/styles/common.module.less'
import {Button, DatePicker, Form, Input, message, Select, Space} from 'antd'
import {Album, UploadButton} from '@/components/Album'
import {sysRoleModel} from '@/store/models/SysRole'
import moment from 'moment'
import {baseUrl} from '@/common/config'
import {useAppHistory} from '@/common/hooks/useRouter'
import {sysModel} from '@/store/models/Sys'
import * as listener from '@/common/hooks/listener'

function range(start, end) {
  const result = []
  for (let i = start; i < end; i++) {
    result.push(i)
  }
  return result
}

export default function AddNotice() {
  const history = useAppHistory()
  const [roleList, setRoleList] = useState<SysRoleVO[]>([])
  const [form] = Form.useForm()
  const [hours, setHours] = useState([])

  useEffect(() => {
    sysRoleModel.sysRoleList({status: '1', pageable: false} as SysListDTO).then(({response: {data}}) => {
      setRoleList(data)
    })
  }, [])

  return (
    <div>
      <div style={{background: '#fff', marginBottom: 20}}>
        <Breadcrumb />
        <div style={{padding: '0 0 20px 16px'}}>
          <span style={{fontSize: 20, fontWeight: 'bold'}}>添加公告</span>
        </div>
      </div>
      <div className={commonStyles.section}>
        <div className={commonStyles.filterSection}>
          <Form
            form={form}
            wrapperCol={{span: 10}}
            labelCol={{span: 4}}
            onFinish={async () => {
              const {_date, _time, attachList, ...formValues} = form.getFieldsValue(true)
              const sendTime = moment(_date).format('YYYY-MM-DD') + moment(_time).format(' HH:00:00')
              if (moment(sendTime) < moment()) return message.warning('发送时间需在当前时间之后！')
              await sysModel.sysNoticeSave({sendTime, attachList: attachList ?? [], ...formValues})
              listener.emit('ContentOperateManage:noticeId')
              history.goBack()
            }}
          >
            <Form.Item label={'标题'} name={'title'} rules={[{required: true, message: '请输入标题'}]}>
              <Input placeholder='请输入' allowClear maxLength={20} />
            </Form.Item>
            <Form.Item label={'接收方'} name={'roleId'} rules={[{required: true, message: '请选择接受方'}]}>
              <Select
                placeholder={'请选择'}
                showSearch
                allowClear
                filterOption={(input, option) => option?.children.indexOf(input as any) > -1}
              >
                {roleList.map(item => (
                  <Select.Option value={item.roleId} key={item.roleId}>
                    {item.roleName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label={'发送时间'} required style={{marginBottom: 0}}>
              <Space>
                <Form.Item name={'_date'} rules={[{required: true, message: '请选择发送日期'}]}>
                  <DatePicker
                    disabledDate={currentDate =>
                      (currentDate && currentDate <= moment().startOf('day')) || currentDate >= moment().add(2, 'day')
                    }
                    onChange={(_, dateStrings) => {
                      if (dateStrings === moment().format('YYYY-MM-DD')) {
                        const hour = +moment().add(1, 'hour').format('HH')
                        setHours(state => range(0, 24).splice(0, hour))
                        return
                      }
                      setHours([])
                    }}
                  />
                </Form.Item>
                <Form.Item name={'_time'} rules={[{required: true, message: '请选择发送时间'}]}>
                  <DatePicker
                    picker={'time'}
                    showNow={false}
                    disabledHours={() => hours}
                    disabledMinutes={() => range(0, 60)}
                    disabledSeconds={() => range(0, 60)}
                  />
                </Form.Item>
              </Space>
            </Form.Item>
            <Form.Item label={'内容'} name={'content'} rules={[{required: true, message: '请输入内容'}]}>
              <Input.TextArea showCount placeholder='请输入' rows={3} allowClear maxLength={500} />
            </Form.Item>
            <Form.Item label={'附件'} shouldUpdate>
              {() => {
                const imgList = form.getFieldValue('attachList')
                return (
                  <Album
                    accept={'.jpg, .jpeg, .png'}
                    action={baseUrl + '/sys-notice/upload-file'}
                    limit={3}
                    onDone={fileList => {
                      form.setFieldsValue({attachList: fileList.map(item => item.imgUrl)})
                    }}
                  >
                    {imgList?.length > 2 ? null : <UploadButton />}
                  </Album>
                )
              }}
            </Form.Item>
            <Form.Item label={' '} colon={false}>
              <Space>
                <Button type={'primary'} htmlType={'submit'}>
                  提交
                </Button>
                <Button
                  onClick={() => {
                    history.goBack()
                  }}
                >
                  取消
                </Button>
              </Space>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  )
}
