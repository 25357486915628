import commonStyles from '@/assets/styles/common.module.less'
import {
  Button,
  Col,
  Form,
  FormInstance,
  Input,
  message,
  Modal,
  Row,
  Select,
  Space,
  Table,
  TreeSelect,
  Image,
  Descriptions,
  Upload,
} from 'antd'
import React, {PropsWithChildren, useCallback, useEffect, useState} from 'react'
import {stewardModel} from '@/store/models/Steward'
import {sysModel} from '@/store/models/Sys'
import {Ellipsis} from '@/components/Ellipsis'
import {usePermission} from '@/components/Permission'
import {getPaginationProps} from '@/common/biz/getPaginationProps'
import {useFocusEffect} from '@/common/hooks/useFocusEffect'
import {getImg} from '@/utils/util'
import {baseUrl} from '@/common/config'
import {Album, extraFileListString, UploadButton} from '@/components/Album'

const statuOptions = [
  {value: '1', label: '正常'},
  {value: '0', label: '禁用'},
]

const licence = [
  {value: true, label: '已上传'},
  {value: false, label: '未上传'},
]

export default function StewardLicence(props: {form: FormInstance}) {
  // const [params, setParams] = useState({} as StewardUserListDTO)
  const [total, setTotal] = useState(0)
  const [list, setList] = useState([] as StewardUserVO[])
  // const [hasMin, setHasMin] = useState(false)
  // const [hasMax, setHasMax] = useState(false)
  // const [form] = Form.useForm()
  const form = props.form

  const [auth] = usePermission()

  const loading = stewardModel.stewardUserList.useLoading()
  const [openList, setOpenList] = useState<StewardOpenAppVO[]>([])
  const [tree, setTree] = useState<SysOrgV2VO[]>([])
  // const orgId = userModel.useData(data => data.userInfo?.orgId)

  const confirmLoading = stewardModel.stewardUserUpdateLicence.useLoading()
  const [userForm] = Form.useForm()
  const [user, setUser] = useState({} as StewardUserVO)

  const queryList = useCallback(() => {
    const values = form.getFieldsValue(true)
    stewardModel.stewardUserList(values).then(({response: {data, total}}) => {
      setList(data)
      setTotal(total)
    })
  }, [form])

  useFocusEffect(() => {
    form.submit()
  })

  useEffect(() => {
    form.submit()
  }, [form])

  useEffect(() => {
    stewardModel
      .stewardOpenAppList({
        pageable: false,
        pageNum: 1,
        pageSize: 1,
        appStatus: 1,
      } as StewardAppOpenListDTO)
      .then(({response: {data}}) => {
        setOpenList(data)
      })

    // 全部运营组织
    sysModel.sysOrgV2Tree({allChildren: true, orgId: 'ROOT'} as SysOrgV2ListDTO).then(({response: {data}}) => {
      setTree(data)
    })
  }, [])

  return (
    <>
      <div className={commonStyles.filterSection}>
        <Form
          form={form}
          labelCol={{flex: '96px'}}
          initialValues={{pageNum: 1, pageSize: 10, pageable: true, status: '1'}}
          onFinish={queryList}
          onReset={queryList}
        >
          <Row gutter={20}>
            <Col span={8}>
              <Form.Item label={'管家名称'} name={'userName'}>
                <Input placeholder={'管家名称或手机号'} allowClear />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={'状态'} name={'status'}>
                <Select options={statuOptions} placeholder={'请选择'} allowClear />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label={'服务标签'}
                name={'appIdList'}
                getValueFromEvent={event => (event ? [event] : event)}
                getValueProps={value => ({value: value?.[0]})}
              >
                <Select
                  options={openList}
                  fieldNames={{label: 'appName', value: 'appId'}}
                  allowClear
                  placeholder={'请选择'}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={'运营组织'} name={'orgId'}>
                <TreeSelect
                  placeholder={'请选择'}
                  allowClear
                  treeData={tree}
                  fieldNames={{value: 'orgId', label: 'orgName', children: 'children'}}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={'电工证'} name={'electricianLicence'}>
                <Select options={licence} placeholder={'请选择'} allowClear />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={'焊工证'} name={'welderLicence'}>
                <Select options={licence} placeholder={'请选择'} allowClear />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={'登高证'} name={'heightWorkLicence'}>
                <Select options={licence} placeholder={'请选择'} allowClear />
              </Form.Item>
            </Col>
          </Row>
          <Row justify={'end'}>
            <Space>
              <Button type={'primary'} htmlType={'submit'} onClick={() => form.setFieldsValue({pageNum: 1})}>
                查询
              </Button>
              <Button htmlType={'reset'}>重置</Button>
            </Space>
          </Row>
        </Form>
      </div>
      <div className={commonStyles.tableSection}>
        <Table
          rowKey={'userId'}
          loading={loading}
          dataSource={list}
          scroll={{x: 'max-content'}}
          columns={[
            {title: '管家', dataIndex: 'name', render: (value, record) => `${value}（${record.mobile}）`},
            {
              title: '状态',
              dataIndex: 'status',
              render: value => statuOptions.find(item => item.value === value)?.label,
            },
            {title: '服务标签', dataIndex: 'appNameJoint'},
            {
              title: '运营组织',
              dataIndex: 'orgName',
              render: value => <Ellipsis value={value} />,
            },
            {
              title: '电工证',
              dataIndex: 'electricianLicenceList',
              render: value => (value?.length ? <PreviewText urls={value}>查看</PreviewText> : '/'),
            },
            {
              title: '焊工证',
              dataIndex: 'welderLicenceList',
              render: value => (value?.length ? <PreviewText urls={value}>查看</PreviewText> : '/'),
            },
            {
              title: '登高证',
              dataIndex: 'heightWorkLicenceList',
              render: value => (value?.length ? <PreviewText urls={value}>查看</PreviewText> : '/'),
            },
            {title: '最后修改人', dataIndex: 'licenceUser'},
            {title: '最后修改时间', dataIndex: 'licenceTime'},
            {
              title: '操作',
              render: (_, record) =>
                auth('002002003') ? (
                  <Button
                    type={'link'}
                    style={{padding: 0}}
                    onClick={() => {
                      userForm.setFieldsValue({
                        electricianLicenceList: record.electricianLicenceList?.map(value => ({
                          uid: value,
                          url: getImg(value),
                        })),
                        welderLicenceList: record.welderLicenceList?.map(value => ({uid: value, url: getImg(value)})),
                        heightWorkLicenceList: record.heightWorkLicenceList?.map(value => ({
                          uid: value,
                          url: getImg(value),
                        })),
                      })
                      setUser(record)
                    }}
                  >
                    编辑
                  </Button>
                ) : null,
            },
          ]}
          pagination={getPaginationProps(form, total)}
        />
      </div>

      <Modal
        open={!!user?.userId}
        width={700}
        destroyOnClose
        title={'编辑'}
        onCancel={() => setUser({} as StewardUserVO)}
        afterClose={() => userForm.resetFields()}
        confirmLoading={confirmLoading}
        onOk={async () => {
          // await userForm.validateFields()
          const values = userForm.getFieldsValue(true)

          await stewardModel.stewardUserUpdateLicence({
            stewardId: user.userId,
            electricianLicenceList: extraFileListString(values.electricianLicenceList),
            welderLicenceList: extraFileListString(values.welderLicenceList),
            heightWorkLicenceList: extraFileListString(values.heightWorkLicenceList),
          })

          setUser({} as StewardUserVO)
          form.submit()
        }}
      >
        <Form layout={'vertical'} form={userForm}>
          <Descriptions column={2}>
            <Descriptions.Item label={'管家'}>{user.name}</Descriptions.Item>
            <Descriptions.Item label={'账号'}>
              {statuOptions.find(item => item.value === user.status)?.label}
            </Descriptions.Item>
            <Descriptions.Item label={'运营组织'}>{user.orgName}</Descriptions.Item>
            <Descriptions.Item label={'服务标签'}>{user.appNameJoint}</Descriptions.Item>
          </Descriptions>

          <Form.Item
            label={'电工证'}
            name={'electricianLicenceList'}
            getValueFromEvent={value => value?.fileList}
            getValueProps={fileList => ({fileList})}
          >
            <Album
              limit={3}
              multiple={true}
              action={baseUrl + '/steward/user/upload-file'}
              accept={'.jpg, .jpeg, .png'}
              beforeUpload={file => {
                if (file.size > 1024 * 1024 * 10) {
                  message.error('不能上传超过10M的照片')
                  return Upload.LIST_IGNORE
                }
              }}
            >
              <UploadButton title={'上传照片'} />
            </Album>
          </Form.Item>
          <Form.Item
            label={'焊工证'}
            name={'welderLicenceList'}
            getValueFromEvent={value => value?.fileList}
            getValueProps={fileList => ({fileList})}
          >
            <Album
              limit={3}
              multiple={true}
              action={baseUrl + '/steward/user/upload-file'}
              accept={'.jpg, .jpeg, .png'}
              beforeUpload={file => {
                if (file.size > 1024 * 1024 * 10) {
                  message.error('不能上传超过10M的照片')
                  return Upload.LIST_IGNORE
                }
              }}
            >
              <UploadButton title={'上传照片'} />
            </Album>
          </Form.Item>
          <Form.Item
            label={'登高证'}
            name={'heightWorkLicenceList'}
            getValueFromEvent={value => value?.fileList}
            getValueProps={fileList => ({fileList})}
          >
            <Album
              limit={3}
              multiple={true}
              action={baseUrl + '/steward/user/upload-file'}
              accept={'.jpg, .jpeg, .png'}
              beforeUpload={file => {
                if (file.size > 1024 * 1024 * 10) {
                  message.error('不能上传超过10M的照片')
                  return Upload.LIST_IGNORE
                }
              }}
            >
              <UploadButton title={'上传照片'} />
            </Album>
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

const PreviewText: React.FC<PropsWithChildren<{urls: string[]}>> = props => {
  const [visible, setVisible] = useState(false)
  return (
    <>
      <Button type={'link'} style={{padding: 0}} onClick={() => setVisible(true)}>
        {props.children}
      </Button>
      <div style={{display: 'none'}}>
        <Image.PreviewGroup preview={{visible, onVisibleChange: value => setVisible(value)}}>
          {visible && props.urls?.map((value, index) => <Image key={index} src={getImg(value)} />)}
        </Image.PreviewGroup>
      </div>
    </>
  )
}
