import React, {useCallback, useEffect, useState} from 'react'
import commonStyle from '@/assets/styles/common.module.less'
import {Breadcrumb} from '@/components/Breadcrumb'
import {Form, Row, Col, Input, Button, DatePicker, Space, Table, Modal, Typography} from 'antd'
import {saleModel} from '@/store/models/Sale'
import {Permission} from '@/components/Permission'
import {Page} from '@/components/Page'
import {OrgRoleId} from '@/common/constants'
import {getPaginationProps} from '@/common/biz/getPaginationProps'
import {useAppHistory} from '@/common/hooks/useRouter'

export default function SaleList() {
  const history = useAppHistory()
  const [form] = Form.useForm()
  // const [addForm] = Form.useForm()
  // const [addVisible, setAddVisible] = useState(false)
  const [total, setTotal] = useState(0)
  const [show, setShow] = useState({visible: false, userId: ''})
  const [saleList, setSaleList] = useState<SaleUserVO[]>([])
  const loading = saleModel.saleUserList.useLoading()

  // const orgId = userModel.useData(data => data.userInfo?.orgId)
  // const [treeList, setTreeList] = useState<SysOrgTreeNodeVO[]>([])

  const search = useCallback(async () => {
    const value = form.getFieldsValue(true)
    const {
      response: {data, total},
    } = await saleModel.saleUserList(value)
    setSaleList(data)
    setTotal(total)
  }, [form])

  useEffect(() => {
    form.submit()
  }, [form])

  // useEffect(() => {
  //   sysModel.sysOrgTree({fetchUser: false, orgIdLike: orgId} as SysOrgTreeQueryDTO).then(({response: {data}}) => {
  //     setTreeList(data)
  //   })
  // }, [orgId])

  // 新增销售
  // async function handleFormFinish() {
  //   const formValues = addForm.getFieldsValue()
  //   const addParams: SysUserDTO = {...formValues, roleIdList: [OrgRoleId.cus_manager], userId: ''}
  //   await sysUserModel.upsertSysUser(addParams)
  //   setAddVisible(false)
  //   search()
  // }

  return (
    <Page
      breadcrumb={
        <Breadcrumb
          right={
            <Permission code={'003059002'}>
              <Button
                type={'primary'}
                onClick={() => history.push('/sys-user/add', {roleIdList: [OrgRoleId.cus_manager]})}
              >
                新增销售
              </Button>
            </Permission>
          }
        />
      }
    >
      <Form form={form} onReset={search} onFinish={search} initialValues={{pageNum: 1, pageSize: 10, pageable: true}}>
        <div className={commonStyle.filterSection}>
          <Row gutter={70}>
            <Col span={7}>
              <Form.Item label={'销售'} name={'keyword'}>
                <Input placeholder={'请输入销售名称或手机号'} allowClear />
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item label={'虚拟号码'} name={'privateNum'}>
                <Input placeholder={'请输入虚拟号码'} allowClear />
              </Form.Item>
            </Col>
            <Col span={10}>
              <Form.Item label={'录入时间'} name={'_time'}>
                <DatePicker.RangePicker
                  style={{width: '100%'}}
                  showTime
                  allowClear
                  format={'YYYY-MM-DD HH:mm:ss'}
                  onChange={(_, formatString) => {
                    form.setFieldsValue({insertTimeBegin: formatString?.[0], insertTimeEnd: formatString?.[1]})
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row justify={'end'}>
            <Space>
              <Button type={'primary'} htmlType={'submit'} onClick={() => form.setFieldsValue({pageNum: 1})}>
                查询
              </Button>
              <Button htmlType={'reset'}>重置</Button>
            </Space>
          </Row>
        </div>
        <div className={commonStyle.tableSection}>
          <Table
            rowKey={'userId'}
            loading={loading}
            dataSource={saleList}
            columns={[
              {title: '姓名', dataIndex: 'name', key: 'name'},
              {title: '手机号', dataIndex: 'mobile', key: 'mobile'},
              {
                title: '所属组织',
                dataIndex: 'orgName',
                key: 'orgName',
                render: text => <Typography.Text>{text || '/'}</Typography.Text>,
              },
              {title: '录入时间', dataIndex: 'insertTime', key: 'insertTime'},
              {
                title: '虚拟号码',
                dataIndex: 'privateNum',
                key: 'privateNum',
                render: text => <Typography.Text>{text || '/'}</Typography.Text>,
              },
              {
                title: '操作',
                dataIndex: 'userId',
                align: 'center',
                key: 'userId',
                render: text => {
                  return (
                    <Permission code={'021059002'}>
                      <Space>
                        <Button
                          type={'link'}
                          onClick={() => {
                            setShow({visible: true, userId: text})
                          }}
                        >
                          禁用
                        </Button>
                      </Space>
                    </Permission>
                  )
                },
              },
            ]}
            pagination={getPaginationProps(form, total)}
          />
        </div>
      </Form>

      {/*提示*/}
      <Modal
        visible={show.visible}
        title={'提示'}
        centered
        footer={null}
        onCancel={() => {
          setShow(state => ({...state, visible: false}))
        }}
      >
        <p>是否确认禁用该账号？</p>
        <Row justify={'end'}>
          <Space>
            <Button
              onClick={() => {
                setShow(state => ({...state, visible: false}))
              }}
            >
              取消
            </Button>
            <Button
              type={'primary'}
              onClick={async () => {
                await saleModel.saleUserDisable({userId: show.userId})
                setShow({visible: false, userId: ''})
                search()
              }}
            >
              确认
            </Button>
          </Space>
        </Row>
      </Modal>

      {/*新增*/}
      {/*<Modal*/}
      {/*  width={450}*/}
      {/*  visible={addVisible}*/}
      {/*  title={*/}
      {/*    <div style={{textAlign: 'center'}}>*/}
      {/*      <Typography.Text style={{color: '#000000'}}>新增销售</Typography.Text>*/}
      {/*    </div>*/}
      {/*  }*/}
      {/*  footer={null}*/}
      {/*  centered*/}
      {/*  onCancel={() => {*/}
      {/*    setAddVisible(false)*/}
      {/*    addForm.resetFields()*/}
      {/*  }}*/}
      {/*>*/}
      {/*  <Form form={addForm} onFinish={handleFormFinish}>*/}
      {/*    /!*<Form.Item*!/*/}
      {/*    /!*  label={'登录账号'}*!/*/}
      {/*    /!*  name={'userName'}*!/*/}
      {/*    /!*  rules={[{required: true, message: '请输入登录账号'}]}*!/*/}
      {/*    /!*  wrapperCol={{span: 15}}*!/*/}
      {/*    /!*  labelCol={{span: 5}}*!/*/}
      {/*    /!*>*!/*/}
      {/*    /!*  <Input placeholder={'请输入'} autoComplete={'off'} allowClear />*!/*/}
      {/*    /!*</Form.Item>*!/*/}
      {/*    /!*<Form.Item*!/*/}
      {/*    /!*  label={'密码'}*!/*/}
      {/*    /!*  name={'password'}*!/*/}
      {/*    /!*  rules={[{required: true, message: '请输入密码'}]}*!/*/}
      {/*    /!*  wrapperCol={{span: 15}}*!/*/}
      {/*    /!*  labelCol={{span: 5}}*!/*/}
      {/*    /!*>*!/*/}
      {/*    /!*  <Input type={'password'} placeholder={'请输入'} autoComplete={'new-password'} allowClear />*!/*/}
      {/*    /!*</Form.Item>*!/*/}
      {/*    <Form.Item*/}
      {/*      label={'姓名'}*/}
      {/*      name={'name'}*/}
      {/*      rules={[{required: true, message: '请输入姓名'}]}*/}
      {/*      wrapperCol={{span: 15}}*/}
      {/*      labelCol={{span: 5}}*/}
      {/*    >*/}
      {/*      <Input*/}
      {/*        placeholder={'请输入姓名'}*/}
      {/*        onBlur={({target: {value}}) => {*/}
      {/*          if (value) {*/}
      {/*            sysUserModel.sysUserGetNamePinyin({name: value}).then(({response: {data}}) => {*/}
      {/*              addForm.setFieldsValue({userName: data})*/}
      {/*            })*/}
      {/*          }*/}
      {/*        }}*/}
      {/*        allowClear*/}
      {/*      />*/}
      {/*    </Form.Item>*/}
      {/*    <Form.Item label={'登录账号'} labelCol={{span: 5}} name={'userName'} wrapperCol={{span: 15}}>*/}
      {/*      <Input placeholder={'请输入登录账号'} disabled allowClear />*/}
      {/*    </Form.Item>*/}
      {/*    <Form.Item*/}
      {/*      label={'手机号'}*/}
      {/*      name={'mobile'}*/}
      {/*      rules={[{required: true, message: '请输入手机号'}]}*/}
      {/*      wrapperCol={{span: 15}}*/}
      {/*      labelCol={{span: 5}}*/}
      {/*    >*/}
      {/*      <Input placeholder={'请输入'} allowClear />*/}
      {/*    </Form.Item>*/}
      {/*    <Form.Item*/}
      {/*      label={'身份证号码'}*/}
      {/*      wrapperCol={{span: 15}}*/}
      {/*      labelCol={{span: 5}}*/}
      {/*      name={'certNumber'}*/}
      {/*      rules={[{...rules.certNo[0], required: false}]}*/}
      {/*    >*/}
      {/*      <Input placeholder={'请输入身份证号码'} allowClear />*/}
      {/*    </Form.Item>*/}
      {/*    <Form.Item label={'员工照'} name={'avatar'} wrapperCol={{span: 15}} labelCol={{span: 5}}>*/}
      {/*      <UploadAvatar />*/}
      {/*    </Form.Item>*/}
      {/*    <Form.Item label={'用户组'} wrapperCol={{span: 15}} labelCol={{span: 5}}>*/}
      {/*      <Input defaultValue={'客户经理'} disabled />*/}
      {/*    </Form.Item>*/}
      {/*    <Form.Item*/}
      {/*      label={'组织选择'}*/}
      {/*      name={'orgId'}*/}
      {/*      rules={[{required: true, message: '请选择组织'}]}*/}
      {/*      wrapperCol={{span: 15}}*/}
      {/*      labelCol={{span: 5}}*/}
      {/*    >*/}
      {/*      <TreeSelect treeDefaultExpandAll placeholder={'请选择'} treeData={treeList} />*/}
      {/*    </Form.Item>*/}
      {/*    <Form.Item label={'备注'} name={'remark'} wrapperCol={{span: 15}} labelCol={{span: 5}}>*/}
      {/*      <Input.TextArea placeholder={'请输入备注'} allowClear />*/}
      {/*    </Form.Item>*/}
      {/*    <Row justify={'end'}>*/}
      {/*      <Space>*/}
      {/*        <Button*/}
      {/*          htmlType={'button'}*/}
      {/*          onClick={() => {*/}
      {/*            setAddVisible(false)*/}
      {/*            addForm.resetFields()*/}
      {/*          }}*/}
      {/*        >*/}
      {/*          取消*/}
      {/*        </Button>*/}
      {/*        <Button type={'primary'} htmlType={'submit'} loading={loading}>*/}
      {/*          保存*/}
      {/*        </Button>*/}
      {/*      </Space>*/}
      {/*    </Row>*/}
      {/*  </Form>*/}
      {/*</Modal>*/}
    </Page>
  )
}
