import React, {useCallback, useEffect, useState} from 'react'
import {Page} from '@/components/Page'
import {Button, Checkbox, Col, message, Row, Space, Tag, Typography} from 'antd'
import {useAppHistory, useAppLocation} from '@/common/hooks/useRouter'
import {Breadcrumb} from '@/components/Breadcrumb'
import {useCodeArraySync, useCodeSync} from '@/store/models/Common'
import {stewardModel} from '@/store/models/Steward'

const answerCode = ['A', 'B', 'C', 'D']

export default function StewardExamGeneration() {
  const history = useAppHistory()
  const location = useAppLocation<'/steward-exam-management/generation'>()
  const paperId = location.state?.paperId

  const stewardQuestionTypeArray = useCodeArraySync('steward.question.type')
  const stewardQuestionTypeCode = useCodeSync('steward.question.type')

  const [questionType, setQuestionType] = useState('1')
  const [questionList, setQuestionList] = useState<{[key: string]: StewardQuestionVO[]}>({})

  const [currentQuestion, setCurrentQuestion] = useState({} as StewardQuestionVO)
  const [checkedQuestion, setCheckedQuestion] = useState<StewardQuestionVO[]>([])

  const [single, setSingle] = useState(0)
  const [judge, setJudge] = useState(0)
  const [multiple, setMultiple] = useState(0)
  const [shortAnswer, setShortAnswer] = useState(0)
  const [configInfo, setConfigInfo] = useState({} as StewardQuestionPaperConfigVO)

  // 题库
  const queryQuestionList = useCallback(() => {
    if (!questionList[questionType]?.length) {
      stewardModel
        .stewardAdminQuestionList({pageNum: 1, pageSize: 100, pageable: false, questionType} as StewardQuestionQueryDTO)
        .then(({response: {data}}) => {
          setQuestionList(prevState => ({...prevState, [questionType]: data}))
        })
    }
  }, [questionList, questionType])

  useEffect(() => {
    queryQuestionList()
  }, [queryQuestionList])

  useEffect(() => {
    stewardModel.stewardAdminQuestionPaperConfigInfo({paperType: '2'}).then(({response: {data}}) => {
      setConfigInfo(data)
    })
  }, [])

  function calcScore() {
    return (
      configInfo.singleScore * single +
      configInfo.tofScore * judge +
      configInfo.multipleScore * multiple +
      configInfo.shortAnswerScore * shortAnswer
    )
  }

  async function onSubmit() {
    if (calcScore() !== 100) return message.warning('分数总和必须为100!')
    const questionObj: {[key: string]: StewardQuestionPaperContextDTO[]} = checkedQuestion.reduce((acc, item) => {
      const type = item.questionType
      const obj = {
        answer: null,
        answerList: item.answerList,
        answerRight: item.answerRight,
        correct: null,
        goal: null,
        questionId: item.questionId,
        questionTitle: item.questionTitle,
        questionType: item.questionType,
        rank: null,
        score: null,
      }
      if (acc[type]) {
        acc[type] = [...acc[type], obj]
      } else {
        acc[type] = [obj]
      }
      return acc
    }, {})

    const obj = {
      '1': configInfo.singleScore,
      '2': configInfo.tofScore,
      '3': configInfo.multipleScore,
      '4': configInfo.shortAnswerScore,
    }

    const resList = Object.values(questionObj)
      .flat()
      .map((item, index) => {
        return {...item, rank: index + 1, score: obj[item.questionType]}
      })

    await stewardModel.stewardAdminQuestionPaperAudit({paperId, score: 100, paper: resList})
    message.success('操作成功！')
    history.goBack()
  }

  return (
    <Page
      breadcrumb={
        <Breadcrumb
          right={
            <Space>
              <Button onClick={() => history.goBack()}>取消</Button>
              <Button type={'primary'} onClick={onSubmit}>
                提交
              </Button>
            </Space>
          }
        />
      }
    >
      <div style={{backgroundColor: '#fff', height: '100vh', padding: '0 25px'}}>
        <Row gutter={50}>
          <Col span={16}>
            <div style={{margin: '30px 0 20px'}}>
              <Space size={15}>
                <span>题型：</span>
                {stewardQuestionTypeArray.map(item => (
                  <Button
                    key={item.id}
                    type={questionType === item.id ? 'primary' : 'text'}
                    size={'small'}
                    style={{padding: '0 20px'}}
                    onClick={() => {
                      setQuestionType(item.id)
                    }}
                  >
                    {item.label}
                  </Button>
                ))}
              </Space>
            </div>
            <div style={{display: 'flex', flexWrap: 'wrap'}}>
              {questionList[questionType]?.map(item => (
                <div key={item.questionId} style={{position: 'relative', margin: '20px 10px 0 0'}}>
                  <Button
                    type={item.questionId === currentQuestion.questionId ? 'primary' : 'ghost'}
                    shape='circle'
                    onClick={() => setCurrentQuestion(item)}
                  >
                    {item.rank}
                  </Button>
                  <Checkbox
                    value={item.questionId}
                    style={{position: 'absolute', right: -5, top: -8}}
                    checked={checkedQuestion.some(value => value.questionId === item.questionId)}
                    onClick={() => {
                      if (checkedQuestion.some(value => value.questionId === item.questionId)) {
                        if (questionType === '1') setSingle(prevState => prevState - 1)
                        if (questionType === '2') setJudge(prevState => prevState - 1)
                        if (questionType === '3') setMultiple(prevState => prevState - 1)
                        if (questionType === '4') setShortAnswer(prevState => prevState - 1)
                        setCheckedQuestion(prevState => prevState.filter(value => value.questionId !== item.questionId))
                      } else {
                        if (questionType === '1') setSingle(prevState => prevState + 1)
                        if (questionType === '2') setJudge(prevState => prevState + 1)
                        if (questionType === '3') setMultiple(prevState => prevState + 1)
                        if (questionType === '4') setShortAnswer(prevState => prevState + 1)
                        setCheckedQuestion(prevState => [...prevState, item])
                      }
                    }}
                  />
                </div>
              ))}
            </div>
            {!!currentQuestion.questionId && (
              <div style={{marginTop: '40px'}}>
                <Typography.Paragraph>
                  {currentQuestion.questionTitle}
                  <Tag color={'blue'}>{stewardQuestionTypeCode[currentQuestion.questionType]}</Tag>
                </Typography.Paragraph>
                {currentQuestion.questionType !== '4' ? (
                  <Space direction={'vertical'} style={{width: '75%'}}>
                    {currentQuestion.answerList?.map((item, index) => (
                      <Typography.Paragraph key={item}>{`${answerCode[index]} ${item}`}</Typography.Paragraph>
                    ))}
                    <Typography.Paragraph>
                      <Typography.Text style={{color: '#000'}}>参考答案：</Typography.Text>
                      {currentQuestion.answerRight}
                    </Typography.Paragraph>
                  </Space>
                ) : (
                  <Typography.Paragraph>
                    <Typography.Text style={{color: '#000'}}>参考答案：</Typography.Text>
                    {currentQuestion.answerRight}
                  </Typography.Paragraph>
                )}
              </div>
            )}
          </Col>
          <Col span={8}>
            <div style={{marginTop: '80px'}}>
              <Space direction={'vertical'} size={10}>
                <Typography.Paragraph>
                  单选：<Typography.Text>{`已选${single}题，共${configInfo.singleScore * single}分`}</Typography.Text>
                </Typography.Paragraph>
                <Typography.Paragraph>
                  判断：<Typography.Text>{`已选${judge}题，共${configInfo.tofScore * judge}分`}</Typography.Text>
                </Typography.Paragraph>
                <Typography.Paragraph>
                  多选：
                  <Typography.Text>{`已选${multiple}题，共${configInfo.multipleScore * multiple}分`}</Typography.Text>
                </Typography.Paragraph>
                <Typography.Paragraph>
                  简答：
                  <Typography.Text>{`已选${shortAnswer}题，共${
                    configInfo.shortAnswerScore * shortAnswer
                  }分`}</Typography.Text>
                </Typography.Paragraph>
                <Typography.Paragraph>
                  总分：
                  <Typography.Text>{calcScore()}分</Typography.Text>
                </Typography.Paragraph>
              </Space>
            </div>
          </Col>
        </Row>
      </div>
    </Page>
  )
}
