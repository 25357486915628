import React, {useState} from 'react'
import {Button, Col, DatePicker, Form, Input, InputNumber, message, Row, Select, Space, Typography, Upload} from 'antd'
import {PlusOutlined} from '@ant-design/icons'
import {useHistory, useLocation} from 'react-router-dom'
import {baseUrl} from '@/common/config'
import moment from 'moment'
import {Breadcrumb} from '@/components/Breadcrumb'
import commonStyles from '@/assets/styles/common.module.less'
import {useCodeArraySync, useCodeSync} from '@/store/models/Common'
import {teamModel} from '@/store/models/Team'
import {createHeaders} from '@/store/service'
import {Page} from '@/components/Page'
import BackButton from '@/components/BackButton'

const {Text} = Typography
const {Option} = Select
const {TextArea} = Input
export default function Recharge() {
  const history = useHistory()
  const location = useLocation<any>()
  const {type, certName, balance, teamName, teamId} = location.state
  const [rechargeForm] = Form.useForm()
  const [recordForm] = Form.useForm()
  const [imgUrl, setImgUrl] = useState('')
  const teamChargeCategory = useCodeArraySync('team.chargeCategory')
  const recordCategoryObj = useCodeSync('team.chargeCategory')
  const chargeSource = useCodeArraySync('team.chargeSource')

  //充值
  async function recharge() {
    if (!imgUrl) {
      message.warning('请上传截图凭证！')
      return
    }
    const {
      chargeType,
      chargeTime,
      chargeAmt,
      chargeAccount,
      remark,
    } = rechargeForm.getFieldsValue() as TeamBalanceChargeDTO
    await teamModel.teamBalanceChargeMake({
      chargeType,
      chargeTime: moment(chargeTime).format('YYYY-MM-DD HH:mm:ss'),
      chargeAmt,
      chargeAccount,
      chargeTypeName: chargeType === '1' ? '对公账户' : '支付宝',
      remark,
      chargeCertUrl: imgUrl,
      teamId,
    })
    message.success('充值成功!')
    await history.goBack()
  }

  //扣费
  async function record() {
    if (!imgUrl) {
      message.warning('请上传截图凭证！')
      return
    }
    const {recordPayType, recordAmt, recordCategory, stewardOrderId, remark} = recordForm.getFieldsValue(
      true
    ) as Partial<TeamBalanceRecordInsertDTO>
    await teamModel.teamBalanceRecordInsert({
      recordAmt,
      recordPayType,
      recordPayTypeName: recordPayType === '1' ? '系统划扣' : '手动扣费',
      recordCategory,
      recordCategoryName: recordCategoryObj[recordCategory],
      stewardOrderId,
      remark,
      recordImgUrl: imgUrl,
      teamPid: teamId,
    } as TeamBalanceRecordInsertDTO)
    message.success('扣款成功!')
    await history.goBack()
  }

  return (
    <Page breadcrumb={<Breadcrumb path={['客户管理', '客户列表', '服务费账户', type === '1' ? '充值' : '扣款']} />}>
      <BackButton />
      <Row style={{padding: '20px', backgroundColor: '#fff'}}>
        <Col>
          <Text>客户姓名：</Text>
          <Text style={{color: '#212832'}}>{certName}</Text>
        </Col>
        <Col offset={1}>
          <Text>组织：</Text>
          <Text style={{color: '#212832'}}>{teamName}</Text>
        </Col>
        <Col offset={1}>
          <Text>当前余额：</Text>
          <Text style={{color: '#212832'}}>{balance}元</Text>
        </Col>
      </Row>
      <div style={{backgroundColor: 'rgba(0, 0, 0, 0.06)', height: '1px'}} />

      {/* 充值 */}
      <div className={commonStyles.tableSection}>
        {type === '1' ? (
          <Form
            style={{marginTop: '20px'}}
            form={rechargeForm}
            onFinish={recharge}
            wrapperCol={{xs: {span: 24}, sm: {span: 12}}}
          >
            <Form.Item label={'充值渠道'} name={'chargeType'} rules={[{required: true, message: '请选择充值渠道'}]}>
              <Select placeholder={'请选择'} allowClear>
                {chargeSource.map(value => (
                  <Option key={value.id} value={value.id}>
                    {value.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              hasFeedback
              label={'充值时间'}
              name={'chargeTime'}
              rules={[{required: true, message: '请选择充值时间'}]}
            >
              <DatePicker placeholder={'充值时间'} style={{width: '100%'}} showTime format={'YYYY-MM-DD HH:mm:ss'} />
            </Form.Item>
            <Form.Item label={'充值金额'} name={'chargeAmt'} rules={[{required: true, message: '请输入充值金额'}]}>
              <InputNumber placeholder={'请输入充值金额'} style={{width: '100%'}} />
            </Form.Item>
            <Form.Item label={'充值账户'} name={'chargeAccount'} rules={[{required: true, message: '请输入充值账户'}]}>
              <Input placeholder={'请输入充值账户'} allowClear />
            </Form.Item>
            <Form.Item label={'充值截图'} required name={'imgList'}>
              <Upload
                data={{teamId}}
                accept={'image/*'}
                listType={'picture-card'}
                name={'file'}
                showUploadList={true}
                headers={createHeaders()}
                action={baseUrl + '/team/balance/upload-file'}
                onChange={info => {
                  if (info.file.status === 'done') {
                    const {
                      response: {data},
                    } = info.file
                    setImgUrl(data.imgUrl)
                  }
                  if (info.file.status === 'removed') {
                    setImgUrl('')
                  }
                }}
              >
                {imgUrl ? null : (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <PlusOutlined />
                    <div style={{marginTop: '10px'}}>上传凭证</div>
                  </div>
                )}
              </Upload>
            </Form.Item>
            <Form.Item label={'备注信息'} rules={[{required: true, message: '请输入备注信息'}]} name={'remark'}>
              <TextArea placeholder={'请输入备注信息'} allowClear />
            </Form.Item>
            <Form.Item>
              <div style={{marginLeft: '70px', marginTop: '10px'}}>
                <Space>
                  <Button onClick={() => history.goBack()}>返回</Button>
                  <Button type={'primary'} htmlType={'submit'}>
                    保存
                  </Button>
                </Space>
              </div>
            </Form.Item>
          </Form>
        ) : (
          // 扣款
          <Form
            style={{marginTop: '20px'}}
            form={recordForm}
            onFinish={record}
            wrapperCol={{xs: {span: 24}, sm: {span: 12}}}
          >
            <Form.Item label={'扣款来源'} name={'recordPayType'} rules={[{required: true, message: '请选择扣款来源'}]}>
              <Select placeholder={'请选择'} allowClear>
                <Option value={'1'}>系统划扣</Option>
                <Option value={'2'}>手动扣费</Option>
              </Select>
            </Form.Item>
            <Form.Item
              hasFeedback
              label={'费用类型'}
              name={'recordCategory'}
              rules={[{required: true, message: '请选择费用类型'}]}
            >
              <Select placeholder={'请选择'}>
                {teamChargeCategory.map(value => (
                  <Option key={value.id} value={value.id}>
                    {value.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label={'服务单号'} name={'stewardOrderId'} rules={[{required: true, message: '请输入服务单号'}]}>
              <Input placeholder={'请输入服务单号'} allowClear />
            </Form.Item>
            <Form.Item label={'扣款金额'} name={'recordAmt'} rules={[{required: true, message: '请输入扣款金额'}]}>
              <InputNumber placeholder={'请输入扣款金额'} style={{width: '100%'}} />
            </Form.Item>
            <Form.Item label={'扣费截图'} required name={'imgList'}>
              <Upload
                data={{teamId}}
                accept={'image/*'}
                listType={'picture-card'}
                name={'file'}
                showUploadList={true}
                headers={createHeaders()}
                action={baseUrl + '/team/balance/upload-file'}
                onChange={info => {
                  if (info.file.status === 'done') {
                    const {
                      response: {data},
                    } = info.file
                    setImgUrl(data.imgUrl)
                  }
                  if (info.file.status === 'removed') {
                    setImgUrl('')
                  }
                }}
              >
                {imgUrl ? null : (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <PlusOutlined />
                    <div style={{marginTop: '10px'}}>上传凭证</div>
                  </div>
                )}
              </Upload>
            </Form.Item>
            <Form.Item label={'备注信息'} rules={[{required: true, message: '请输入备注信息'}]} name={'remark'}>
              <TextArea placeholder={'请输入备注信息'} allowClear />
            </Form.Item>
            <div style={{marginLeft: '70px', marginTop: '10px'}}>
              <Space>
                <Button onClick={() => history.goBack()}>返回</Button>
                <Button type={'primary'} htmlType={'submit'}>
                  保存
                </Button>
              </Space>
            </div>
          </Form>
        )}
      </div>
    </Page>
  )
}
