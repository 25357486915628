import React, {useCallback, useEffect, useState} from 'react'
import {Button, Form, Select, TreeSelect} from 'antd'
import commonStyles from '@/assets/styles/common.module.less'
import styles from './index.module.less'
import Checked from '@/assets/icons/checked.svg'
import PropertyCondo from '@/assets/icons/property_condo.svg'
import PropertyScatter from '@/assets/icons/property_scatter.svg'
import {useAppHistory} from '@/common/hooks/useRouter'
import {Page} from '@/components/Page'
import {customerModel} from '@/store/models/Customer'
import type {DataNode} from 'antd/es/tree'

type ChartType = 'scatter' | 'condo'

export default function PropertyAdd() {
  const history = useAppHistory()
  const [form] = Form.useForm()

  const [chartType, setChartType] = useState<ChartType>('condo')

  function goNext() {
    const values = form.getFieldsValue(true)

    history.push({
      pathname: chartType === 'condo' ? `/property/add/condo` : `/property/add/scatter`,
      state: {teamId: values.teamId},
    })
  }

  const [customerList, setCustomerList] = useState<{label: string; value: string}[]>([])
  const [teamList, setTeamList] = useState<DataNode[]>([])

  const search = useCallback((args: LessorCustomerListDTO) => {
    customerModel
      .customerList({pageable: false, ...args})
      .then(({response: {data}}) =>
        setCustomerList(data.map(value => ({label: `${value.certName}-${value.mobile}`, value: value.userId})))
      )
  }, [])
  useEffect(() => {
    search({} as LessorCustomerListDTO)
  }, [search])

  return (
    <Page>
      <Form form={form} onFinish={goNext}>
        <div className={`${commonStyles.flexFullSection} ${styles.content}`}>
          <div style={{width: 500}}>
            <Form.Item label={'客户选择'} name={'userId'} rules={[{required: true}]}>
              <Select
                options={customerList}
                showSearch
                placeholder={'请输入客户姓名'}
                onSearch={value => search({certName: value} as LessorCustomerListDTO)}
                filterOption={(input, option) =>
                  ((option?.label ?? '') as string).toLowerCase().includes(input.toLowerCase())
                }
                onChange={async (value, option) => {
                  if (value) {
                    const {
                      response: {data},
                    } = await customerModel.customerTeamGetTeamTree({ownerId: value})
                    const treeData = (function mapData(value) {
                      return value.map(
                        item =>
                          ({
                            title: item.name,
                            value: item.teamId,
                            key: item.teamId,
                            selectable: item.level > 1,
                            children: item.children ? mapData(item.children) : item.children,
                          } as DataNode)
                      )
                    })(data)
                    setTeamList(treeData)
                  }
                }}
              />
            </Form.Item>
            <Form.Item label={'团队选择'} name={'teamId'} rules={[{required: true}]}>
              <TreeSelect treeData={teamList} fieldNames={{label: 'title'}} />
            </Form.Item>
          </div>
          <div className={styles.cardContainer}>
            <div onClick={() => setChartType('condo')} className={styles.card}>
              {chartType === 'condo' && <img src={Checked} alt={'icon'} className={styles.cardActive} />}
              <img src={PropertyCondo} alt={'icon'} style={{marginBottom: 27}} />
              <p className={styles.desc}>添加集中式公寓房源</p>
            </div>
            <div onClick={() => setChartType('scatter')} className={styles.card}>
              {chartType === 'scatter' && <img src={Checked} alt={'icon'} className={styles.cardActive} />}
              <img src={PropertyScatter} alt={'icon'} style={{marginBottom: 27}} />
              <p className={styles.desc}>添加分散式房源</p>
            </div>
          </div>
          <Button type={'primary'} htmlType={'submit'}>
            下一步
          </Button>
        </div>
      </Form>
    </Page>
  )
}
