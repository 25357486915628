import React, {useEffect, useState} from 'react'
import {sysModel} from '@/store/models/Sys'
import {Modal, Space, Typography} from 'antd'

export default function PublishNotification() {
  const [isOpen, setIsOpen] = useState(false)
  const [publishList, setPublishList] = useState<SysAppPublishVO[]>([])

  useEffect(() => {
    sysModel.sysAppPublishUser({publishType: '2'}).then(({response: {data}}) => {
      if (data && data.length) {
        setPublishList(data)
        setIsOpen(true)
      }
    })
  }, [])

  return (
    <Modal
      title={'系统上线通知'}
      open={isOpen}
      centered
      closable={false}
      cancelText={<span />}
      cancelButtonProps={{disabled: true, type: 'link'}}
      onOk={async () => {
        setIsOpen(false)
        await sysModel.sysAppPublishUserInsert({publishId: publishList[0].id})
      }}
    >
      <div style={{maxHeight: '200px', overflow: 'scroll'}}>
        {publishList.map(item => (
          <Typography key={item.id}>
            <Space size={30}>
              <Typography.Title level={5}>{item.publishTime}</Typography.Title>
              <Typography.Title level={5}>{item.publishIndex}</Typography.Title>
            </Space>
            <Typography.Paragraph style={{whiteSpace: 'pre-line'}}>{item.publishRemark}</Typography.Paragraph>
          </Typography>
        ))}
      </div>
    </Modal>
  )
}
