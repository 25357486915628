import React from 'react'
import {Page} from '@/components/Page'
import {Button, Form, message, Space, Table, Tag} from 'antd'
import commonStyles from '@/assets/styles/common.module.less'
import {getPaginationProps} from '@/common/biz/getPaginationProps'
import {useCleanOrderList} from '@/pages/cleanManagement/hooks/useCleanOrderList'
import {cleanServiceArr} from '@/utils/clean'
import CleanFilterComponent from '@/pages/cleanManagement/components/CleanFilterComponent'
import {useCodeArraySync, useCodeSync} from '@/store/models/Common'
import {useAppHistory} from '@/common/hooks/useRouter'
import {Breadcrumb} from '@/components/Breadcrumb'
import moment from 'moment'
import {downloadBlob} from '@/utils/util'
import {usePermission} from '@/components/Permission'

const qaStatusArr = [
  {id: 0, label: '未完成'},
  {id: 1, label: '已完成'},
]
const qaStatusCode = ['未完成', '已完成']

const qaResultArr = [
  {id: 0, label: '未通过'},
  {id: 1, label: '已通过'},
]
const qaResultCode = ['未通过', '已通过']

const remarkStatusArr = [
  {id: 0, label: '未评价'},
  {id: 1, label: '已评价'},
]

export default function CleanServiceFinish() {
  const history = useAppHistory()
  const cleanOrderTypeArr = useCodeArraySync('clean.order.type')

  const [auth] = usePermission()

  const [form] = Form.useForm()
  const {dataList, dataTotal, queryCleanOrderList, orderListLoading} = useCleanOrderList(form)
  const cleanOrderTimeStatusCode = useCodeSync('clean.order.timeoutStatus')
  const cleanOrderTimeoutStatusArr = useCodeArraySync('clean.order.timeoutStatus', {defaultItem: true})

  return (
    <Page
      breadcrumb={
        <Breadcrumb
          right={
            auth('007139001') && (
              <Button
                type={'primary'}
                onClick={() => {
                  const finishTimeBegin = form.getFieldValue('finishTimeBegin')
                  const finishTimeEnd = form.getFieldValue('finishTimeEnd')
                  if (!finishTimeBegin || !finishTimeEnd) return message.error('请选择保洁完成时间')
                  if (moment(finishTimeEnd).diff(moment(finishTimeBegin), 'day') >= 31)
                    return message.error('保洁完成时间范围最大31天')
                  downloadBlob({url: '/clean-order/list-export', body: form.getFieldsValue(true)})
                }}
              >
                导出
              </Button>
            )
          }
        />
      }
    >
      <div className={commonStyles.filterSection}>
        <CleanFilterComponent
          form={{
            form,
            labelCol: {span: '80px'},
            labelAlign: 'left',
            initialValues: {pageNum: 1, pageSize: 10, pageable: true, orderStatus: '4', sortType: 10},
            onFinish: queryCleanOrderList,
            onReset: queryCleanOrderList,
          }}
          filter={[
            {type: 'input', name: 'orderId', label: '服务单ID', placeholder: '请输入'},
            {type: 'input', name: 'propertyAddr', label: '房源', placeholder: '请输入'},
            {type: 'select', name: 'categoryThi', label: '服务项目', placeholder: '请选择', options: cleanServiceArr},
            {type: 'input', name: 'orderMakerName', label: '下单人', placeholder: '请输入'},
            {type: 'input', name: 'contactMobile', label: '服务联系手机号', placeholder: '请输入'},
            {
              type: 'range',
              name: 'insertTime',
              label: '下单时间',
              onChange: (_, dateString) => {
                form.setFieldsValue({
                  insertTimeBegin: dateString[0] ? dateString[0] + ' 00:00:00' : '',
                  insertTimeEnd: dateString[1] ? dateString[1] + ' 23:59:59' : '',
                })
              },
            },
            {
              type: 'range',
              name: 'finishTime',
              label: '保洁完成时间',
              onChange: (_, dateString) => {
                form.setFieldsValue({
                  finishTimeBegin: dateString[0] ? dateString[0] + ' 00:00:00' : '',
                  finishTimeEnd: dateString[1] ? dateString[1] + ' 23:59:59' : '',
                })
              },
            },
            {type: 'input', name: 'finishOperator', label: '操作人', placeholder: '请输入'},
            {type: 'select', name: 'orderType', label: '工单属性', placeholder: '请选择', options: cleanOrderTypeArr},
            {type: 'input', name: 'serverName', label: '保洁员', placeholder: '请输入'},
            {
              type: 'select',
              name: 'qaOnlineStatus',
              label: '线上质检状态',
              placeholder: '请选择',
              options: qaStatusArr,
            },
            {
              type: 'select',
              name: 'qaOnlineResult',
              label: '线上质检结果',
              placeholder: '请选择',
              options: qaResultArr,
            },
            {
              type: 'select',
              name: 'remarkTag',
              label: '评价情况',
              placeholder: '请选择',
              options: remarkStatusArr,
            },
            {
              type: 'select',
              name: 'qaOfflineStatus',
              label: '现场质检状态',
              placeholder: '请选择',
              options: qaStatusArr,
            },
            {
              type: 'select',
              name: 'qaOfflineResult',
              label: '现场质检结果',
              placeholder: '请选择',
              options: qaResultArr,
            },
            {
              type: 'select',
              name: 'timeoutStatus',
              label: '是否超时',
              placeholder: '请选择',
              options: cleanOrderTimeoutStatusArr,
            },
            {
              type: 'input',
              name: 'serviceOrgName',
              label: '所在组织',
              placeholder: '请输入',
            },
            {type: 'input', name: 'customerName', label: '房东', placeholder: '请输入'},
          ]}
        />
      </div>

      <div className={commonStyles.tableSection}>
        <Table
          rowKey={'orderId'}
          loading={orderListLoading}
          dataSource={dataList}
          columns={[
            {title: '服务单ID', dataIndex: 'orderId'},
            {
              title: '服务项目',
              dataIndex: 'categoryFirName',
              render: (value, record) => {
                return (
                  <Space>
                    <span>
                      {[value, record.categorySecName, record.categoryThiName].filter(item => item).join('-')}
                    </span>
                    {!!record.orderIdOrigin && <Tag color={'blue'}>返</Tag>}
                  </Space>
                )
              },
            },
            {title: '下单人', dataIndex: 'orderMakerName'},
            {title: '服务联系手机号', dataIndex: 'contactMobile'},
            {
              title: '下单时间',
              dataIndex: 'insertTime',
              sorter: {
                compare: (a, b) => new Date(a.insertTime).getTime() - new Date(b.insertTime).getTime(),
                multiple: 1,
              },
            },
            {
              title: '保洁完成时间',
              dataIndex: 'finishTime',
              sorter: {
                compare: (a, b) => new Date(a.finishTime).getTime() - new Date(b.finishTime).getTime(),
                multiple: 2,
              },
            },
            {
              title: '是否超时',
              dataIndex: 'timeoutStatus',
              render: value => cleanOrderTimeStatusCode[value],
            },
            {title: '保洁员', dataIndex: 'serverName'},
            {title: '所在组织', dataIndex: 'serviceOrgName'},
            {
              title: '线上质检状态/结果',
              dataIndex: 'qaOnlineStatus',
              render: (value, record) =>
                [qaStatusCode[value], qaResultCode[record.qaOnlineResult]].filter(item => item).join('/'),
            },
            {
              title: '现场质检状态/结果',
              dataIndex: 'qaOfflineStatus',
              render: (value, record) =>
                [qaStatusCode[value], qaResultCode[record.qaOfflineResult]].filter(item => item).join('/'),
            },
            {title: '操作人', dataIndex: 'finishOperator'},
            {title: '房源', dataIndex: 'propertyAddr'},
            {title: '房东', dataIndex: 'customerName'},
            {
              title: '操作',
              fixed: 'right',
              render: record => (
                <Space>
                  <Button
                    type={'link'}
                    style={{padding: 0}}
                    onClick={() => history.push('/clean-detail', {orderId: record.orderId, entry: '3'})}
                  >
                    查看
                  </Button>
                </Space>
              ),
            },
          ]}
          scroll={{x: 'max-content'}}
          pagination={getPaginationProps(form, dataTotal)}
        />
      </div>
    </Page>
  )
}
