import {Breadcrumb} from '@/components/Breadcrumb'
import {Page} from '@/components/Page'
import {Button, message, Space, Tabs} from 'antd'
import React, {useState, useMemo, useRef} from 'react'
import commonStyles from '@/assets/styles/common.module.less'
import {useAppHistory, useAppLocation} from '@/common/hooks/useRouter'
import {condoModel} from '@/store/models/Condo'
import UploadExcel from '@/components/UploadExcel'
import {usePermission} from '@/components/Permission'
import BackButton from '@/components/BackButton'
import IntellectSetComponent from '@/pages/concentrateProject/ProjectSet/component/IntellectSetComponent'
import OperationSetComponent from '@/pages/concentrateProject/ProjectSet/component/OperationSetComponent'
import PropertyTemplateComponent from '@/pages/concentrateProject/ProjectSet/component/PropertyTemplateComponent'
import PayConfigComponent from '@/pages/concentrateProject/ProjectSet/component/PayConfigComponent'
import PropertyManagementComponent from '@/pages/concentrateProject/ProjectSet/component/PropertyManagementComponent'

export default function ProjectSet() {
  const history = useAppHistory()
  const location = useAppLocation<'/concentrate-pm/set'>()
  const [auth] = usePermission()
  const condoProjectId = location.state?.condoProjectId
  const defaultActiveKey = useMemo(() => {
    const permissionObj = {
      '006073002': '1',
      '001095001': '2',
      '001096001': '3',
      '001097001': '4',
    }
    const permission = Object.keys(permissionObj).find(item => auth(item))
    return permissionObj[permission]
  }, [auth])
  const [tabKey, setTabKey] = useState(defaultActiveKey)
  const payRef = useRef(null)
  const propertyRef = useRef(null)
  const configPropertyLoading = condoModel.condoProjectPropertyEnable.useLoading()
  const uploadExcelRef = useRef(null)
  const payConfigLoading = condoModel.condoProjectContractConfigSave.useLoading()

  return (
    <Page
      breadcrumb={
        <Breadcrumb
          right={
            <>
              {tabKey === '2' && auth('003095002') && (
                <Button
                  type={'primary'}
                  onClick={() => history.push({pathname: '/concentrate-pm/template', state: {condoProjectId}})}
                >
                  添加户型模板
                </Button>
              )}
              {tabKey === '3' && (
                <Space>
                  {auth('007096001') && (
                    <Button type={'primary'} onClick={() => propertyRef.current?.exportProperty()}>
                      房源导出
                    </Button>
                  )}
                  {auth('011096002') && <Button onClick={() => uploadExcelRef.current.uploadExcel()}>房价导入</Button>}
                  {auth('003096002') && (
                    <Button
                      type={'primary'}
                      onClick={() => history.push({pathname: '/concentrate-pm/add-room', state: {condoProjectId}})}
                    >
                      添加房间
                    </Button>
                  )}

                  {auth('023096002') && (
                    <Button
                      type={'primary'}
                      loading={configPropertyLoading}
                      onClick={() => propertyRef.current?.upProperty()}
                    >
                      上架
                    </Button>
                  )}
                  {auth('023096002') && (
                    <Button loading={configPropertyLoading} onClick={() => propertyRef.current?.downProperty()}>
                      下架
                    </Button>
                  )}
                </Space>
              )}
              {tabKey === '4' && (
                <Button
                  type={'primary'}
                  loading={payConfigLoading}
                  onClick={() => {
                    payRef.current?.submit()
                  }}
                >
                  保存
                </Button>
              )}
            </>
          }
        />
      }
    >
      <BackButton />
      <Tabs className={commonStyles.tabs} onChange={key => setTabKey(key)} defaultActiveKey={defaultActiveKey}>
        <Tabs.TabPane tab={'运营设置'} key={'1'} disabled={!auth('006073002')}>
          <OperationSetComponent condoProjectId={condoProjectId} />
        </Tabs.TabPane>
        <Tabs.TabPane tab={'户型模板'} key={'2'} forceRender disabled={!auth('001095001')}>
          <PropertyTemplateComponent condoProjectId={condoProjectId} />
        </Tabs.TabPane>
        <Tabs.TabPane tab={'房源管理'} key={'3'} forceRender disabled={!auth('001096001')}>
          <PropertyManagementComponent condoProjectId={condoProjectId} ref={propertyRef} />
        </Tabs.TabPane>
        <Tabs.TabPane tab={'营业科目'} key={'4'} disabled={!auth('001097001')}>
          <PayConfigComponent condoProjectId={condoProjectId} ref={payRef} />
        </Tabs.TabPane>
        <Tabs.TabPane tab={'智能设备'} key={'5'}>
          <IntellectSetComponent condoProjectId={condoProjectId} />
        </Tabs.TabPane>
      </Tabs>

      {/* 房价导入 */}
      <UploadExcel
        uri={'/condo/project/property/upload-excel'}
        onSuccess={data => {
          const {success, withoutPrice, repeated} = data
          message.success(
            `${success}间房源价格已更新 ${withoutPrice ? `${withoutPrice}间房源价格未填写 ` : ''}${
              repeated ? `${repeated}间房源编号重复` : ''
            }`
          )
          propertyRef.current?.loadProperty()
        }}
        title={'房价导入'}
        ref={uploadExcelRef}
      />
    </Page>
  )
}
