import React, {useCallback, useEffect, useState} from 'react'
import {Breadcrumb} from '@/components/Breadcrumb'
import {Button, Col, Divider, Form, Input, message, Modal, Row, Select, Space, Table, Typography} from 'antd'
import commonStyle from '@/assets/styles/common.module.less'
import {sysUserModel} from '@/store/models/SysUser'
import {usePermission} from '@/components/Permission'
import {Page} from '@/components/Page'
import {getPaginationProps} from '@/common/biz/getPaginationProps'

const {Option} = Select

export default function VirtualNumberManage() {
  const [addShow, setAddShow] = useState({visible: false, title: '新增号码'})
  const [list, setList] = useState([] as PrivateNumberBindAxVO[])
  const [total, setTotal] = useState(0)
  const [form] = Form.useForm<PrivateNumberBindAxListDTO>()
  const [addForm] = Form.useForm()
  const [privateNum, setPrivateNum] = useState('')
  const [deleteVisible, setDeleteVisible] = useState(false)
  const [options, setOptions] = useState([])
  const [auth] = usePermission()

  const queryList = useCallback(() => {
    const values = form.getFieldsValue(true)
    sysUserModel.privateNumberAxList(values).then(({response: {data, total}}) => {
      setList(data)
      setTotal(total)
    })
  }, [form])

  useEffect(() => {
    form.submit()
  }, [form])

  const queryUserList = useCallback((name?: string) => {
    sysUserModel.querySysUserList({pageable: true, pageSize: 20, name} as SysListDTO).then(({response: {data}}) => {
      setOptions(
        data
          .filter(item => item.status === '1')
          .map(item => (
            <Option value={item.userId} key={item.userId}>
              {`${item.name}（${item.mobile}）`}
            </Option>
          ))
      )
    })
  }, [])

  return (
    <Page
      breadcrumb={
        <Breadcrumb
          right={
            auth('003053002') && (
              <Button
                type={'primary'}
                onClick={() => {
                  setAddShow({visible: true, title: '新增号码'})
                  queryUserList()
                }}
              >
                新增号码
              </Button>
            )
          }
        />
      }
    >
      <div className={commonStyle.filterSection}>
        <Form form={form} initialValues={{pageNum: 1, pageSize: 10}} onFinish={queryList} onReset={queryList}>
          <Row>
            <Col span={6}>
              <Form.Item label={'虚拟号码'} name={'privateNum'}>
                <Input placeholder={'请输入虚拟号码'} allowClear />
              </Form.Item>
            </Col>
            <Col span={6} offset={1}>
              <Form.Item label={'用户名称'} name={'sysUserName'}>
                <Input placeholder={'请输入用户名称'} allowClear />
              </Form.Item>
            </Col>
            <Col span={6} offset={1}>
              <Form.Item label={'分配'} name={'allocated'}>
                <Select placeholder={'请选择'} allowClear>
                  <Option value={'0'}>未分配</Option>
                  <Option value={'1'}>已分配</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row justify={'end'}>
            <Space>
              <Button type={'primary'} htmlType={'submit'} onClick={() => form.setFieldsValue({pageNum: 1})}>
                查询
              </Button>
              <Button htmlType={'reset'}>重置</Button>
            </Space>
          </Row>
        </Form>
      </div>
      <div className={commonStyle.tableSection}>
        <Table
          rowKey={'sysUserId'}
          loading={false}
          dataSource={list}
          columns={[
            {title: '虚拟号码', dataIndex: 'privateNum', key: 'privateNum'},
            {title: '虚拟号绑定手机号', dataIndex: 'originNum', key: 'originNum'},
            {
              title: '用户名称',
              dataIndex: 'sysUserName',
              key: 'sysUserName',
              render: (text, record) => (
                <Typography.Text>{text ? text + '（' + record.sysUserMobile + '）' : '/'}</Typography.Text>
              ),
            },
            {
              title: '操作',
              dataIndex: 'operation',
              key: 'operation',
              render: (text, record) => {
                const {privateNum, calleeNumDisplay, sysUserId} = record
                return (
                  <Space>
                    {auth('002053002') && (
                      <Button
                        style={{margin: 0, padding: 0}}
                        type={'link'}
                        onClick={() => {
                          setAddShow({title: '编辑', visible: true})
                          addForm.setFieldsValue({privateNum, calleeNumDisplay, sysUserId})
                          queryUserList(record.sysUserName)
                        }}
                      >
                        编辑
                      </Button>
                    )}

                    {auth('004053002') && (
                      <>
                        <Divider style={{margin: 0}} type={'vertical'} />
                        <Button
                          style={{margin: 0, padding: 0}}
                          type={'link'}
                          onClick={() => {
                            setPrivateNum(privateNum)
                            setDeleteVisible(true)
                          }}
                        >
                          删除
                        </Button>
                      </>
                    )}
                  </Space>
                )
              },
            },
          ]}
          pagination={getPaginationProps(form, total)}
        />
      </div>

      {/*  新增号码 */}
      <Modal
        visible={addShow.visible}
        title={addShow.title}
        onCancel={() => setAddShow(state => ({...state, visible: false}))}
        footer={null}
        centered
        afterClose={() => addForm.resetFields()}
      >
        <Form
          form={addForm}
          onFinish={async () => {
            const {privateNum, sysUserId, calleeNumDisplay} = addForm.getFieldsValue(true)
            await sysUserModel.privateNumberBindAx({privateNum, sysUserId, calleeNumDisplay})
            message.success(`${addShow.title}成功`)
            form.submit()
            setAddShow(state => ({...state, visible: false}))
          }}
          labelCol={{span: 8}}
          wrapperCol={{span: 12}}
        >
          <Form.Item label={'虚拟号码'} name={'privateNum'} rules={[{required: true, message: '请输入虚拟号码'}]}>
            <Input placeholder={'请输入虚拟号码'} allowClear disabled={addShow.title === '编辑'} />
          </Form.Item>
          <Form.Item label={'分配'} name={'sysUserId'}>
            <Select
              showSearch
              placeholder={'请查询要分配的管家'}
              allowClear
              onSearch={value => {
                if (value) {
                  queryUserList(value)
                }
              }}
              filterOption={false}
            >
              {options}
            </Select>
          </Form.Item>
          <Form.Item
            label={'系统用户被叫主显'}
            name={'calleeNumDisplay'}
            rules={[{required: true, message: '请选择主显方式'}]}
          >
            <Select placeholder={'请选择'} allowClear>
              <Option value={'0'}>显示隐私号码</Option>
              <Option value={'1'}>显示真实主叫号码</Option>
            </Select>
          </Form.Item>
          <Row justify={'end'}>
            <Space>
              <Button
                onClick={() => {
                  setAddShow(state => ({...state, visible: false}))
                }}
              >
                取消
              </Button>
              <Button type={'primary'} htmlType={'submit'}>
                确认
              </Button>
            </Space>
          </Row>
        </Form>
      </Modal>

      {/* 删除号码 */}
      <Modal
        visible={deleteVisible}
        title={'提示'}
        footer={null}
        centered
        onCancel={() => {
          setDeleteVisible(false)
        }}
      >
        <div>
          <Typography.Text>是否确认删除此条数据？</Typography.Text>
        </div>
        <Row justify={'end'}>
          <Space>
            <Button onClick={() => setDeleteVisible(false)}>取消</Button>
            <Button
              type={'primary'}
              onClick={async () => {
                await sysUserModel.privateNumberDeleteAx({privateNum})
                form.submit()
                message.success('删除成功')
                setDeleteVisible(false)
              }}
            >
              确认
            </Button>
          </Space>
        </Row>
      </Modal>
    </Page>
  )
}
