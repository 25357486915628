import React, {useCallback, useEffect, useRef, useState} from 'react'
import {Page} from '@/components/Page'
import {
  Button,
  DatePicker,
  Descriptions,
  Form,
  Image,
  Input,
  InputNumber,
  message,
  Modal,
  PageHeader,
  Space,
  Table,
  Upload,
} from 'antd'
import {useAppHistory, useAppLocation} from '@/common/hooks/useRouter'
import {omsModel} from '@/store/models/Oms'
import commonStyles from '@/assets/styles/common.module.less'
import DividerLine from '@/pages/property/component/DividerLine'
import {createHeaders} from '@/store/service'
import {baseUrl} from '@/common/config'
import {UploadOutlined} from '@ant-design/icons'
import locale from 'antd/es/date-picker/locale/zh_CN'
import moment from 'moment'
import {getPaginationProps} from '@/common/biz/getPaginationProps'
import {useListener} from '@/common/hooks/listener'
import {useFocusEffect} from '@/common/hooks/useFocusEffect'
import {usePermission} from '@/components/Permission'

const statusCode = {'1': '未生效', '2': '合作中', '3': '合作结束'}
const companyTypeCode = {
  '1': '国有企业',
  '2': '私营企业',
  '3': '事业单位或社会团体',
  '4': '中外合资',
  '5': '外商独资',
  '6': '其它',
}

export default function CompanyDetail() {
  const history = useAppHistory()
  const location = useAppLocation<'/company-detail'>()
  const companyId = location.state?.companyId
  const modelRef = useRef(null)
  const [auth] = usePermission()

  const [companyDetail, setCompanyDetail] = useState({} as RpcOmsCompanyVO)

  useFocusEffect(() => {
    queryCompanyContractList()
  })

  // 合同相关
  const [form] = Form.useForm()

  const [contractTotal, setContractTotal] = useState(0)
  const [contractList, setContractList] = useState<RpcOmsCompanyContractVO[]>([])

  const queryCompanyContractList = useCallback(() => {
    if (companyId) {
      omsModel.omsCompanyContractListByCompanyId({companyId}).then(({response: {data, total}}) => {
        setContractList(data)
        setContractTotal(total)
      })

      omsModel.omsCompanyGet({companyId}).then(({response: {data}}) => setCompanyDetail(data))
    }
  }, [companyId])

  useEffect(() => {
    queryCompanyContractList()
  }, [queryCompanyContractList])

  // 新增合同
  const [insertVisible, setInsertVisible] = useState(false)

  useListener('ContractInsert: contractModulePermissionList', contractModulePermissionList => {
    if (contractModulePermissionList.length) {
      form.setFieldsValue({
        contractModulePermissionList,
        permissionDec: `${
          contractModulePermissionList.filter(item => ['OPT01', 'OPT02', 'OPT03'].includes(item)).length
        }个端口，${contractModulePermissionList.filter(item => item.length === 8).length}个模块`,
      })
      setInsertVisible(true)
    } else {
      form.setFieldsValue({contractModulePermissionList: [], permissionDec: undefined})
    }
  })

  return (
    <Page
      header={
        <PageHeader
          title={'公司管理'}
          ghost={false}
          onBack={() => history.goBack()}
          extra={
            auth('002164002') && (
              <Button type={'primary'} onClick={() => history.push('/company-insert', {companyId})}>
                编辑
              </Button>
            )
          }
        />
      }
    >
      <div className={commonStyles.tableSection}>
        <Descriptions title={<DividerLine title={'公司信息'} />} column={3}>
          <Descriptions.Item label={'公司名称'}>{companyDetail.companyName}</Descriptions.Item>
          <Descriptions.Item label={'详细地址'}>{companyDetail.companyAddress}</Descriptions.Item>
          <Descriptions.Item label={'统一社会信用代码'}>{companyDetail.companyCertNo}</Descriptions.Item>
          <Descriptions.Item label={'类型'}>{companyTypeCode[companyDetail.companyType]}</Descriptions.Item>
          <Descriptions.Item label={'公司法人'}>{companyDetail.legalPersonName}</Descriptions.Item>
          <Descriptions.Item label={'法人身份证号码'}>{companyDetail.legalPersonCertNo}</Descriptions.Item>
          <Descriptions.Item label={'法人身份证照片'} span={2}>
            <Space size={10}>
              {!!companyDetail.legalPersonCertFront && (
                <Image src={companyDetail.legalPersonCertFront} width={80} height={80} />
              )}
              {!!companyDetail.legalPersonCertBack && (
                <Image src={companyDetail.legalPersonCertBack} width={80} height={80} />
              )}
              {!!companyDetail.legalPersonCertHold && (
                <Image src={companyDetail.legalPersonCertHold} width={80} height={80} />
              )}
            </Space>
          </Descriptions.Item>
          <Descriptions.Item label={'营业执照'}>
            <Image src={companyDetail.companyBizLicense} width={80} height={80} />
          </Descriptions.Item>
          <Descriptions.Item label={'可创建房间数量'}>
            {companyDetail.limitOfProperty === -1 ? '不限' : `限定${companyDetail.limitOfProperty}间`}
          </Descriptions.Item>
          <Descriptions.Item label={'可创建团队数量'}>
            {companyDetail.limitOfMember === -1 ? '不限' : `限定${companyDetail.limitOfMember}人`}
          </Descriptions.Item>
          <Descriptions.Item label={'公司介绍'} span={3}>
            {companyDetail.companyDesc}
          </Descriptions.Item>
        </Descriptions>

        <Descriptions title={<DividerLine title={'对公账号'} />} column={3} style={{marginTop: '24px'}}>
          <Descriptions.Item label={'银行账号'}>{companyDetail.bankAccountNo}</Descriptions.Item>
          <Descriptions.Item label={'户名'}>{companyDetail.bankAccountName}</Descriptions.Item>
          <Descriptions.Item label={'银行名称'}>{companyDetail.bankName}</Descriptions.Item>
          <Descriptions.Item label={'开户支行'}>{companyDetail.bankBranchName}</Descriptions.Item>
        </Descriptions>
        <Descriptions title={<DividerLine title={'支付信息'} />} column={3} style={{marginTop: '24px'}}>
          <Descriptions.Item label={'平台手续费'}>
            {companyDetail.payCommissions ? `${(companyDetail.payCommissions * 100).toFixed(2)}%` : '/'}
          </Descriptions.Item>
        </Descriptions>

        {!!companyDetail.payCommissionsAllinList?.length && (
          <Descriptions title={<DividerLine title={'通联'} />} column={3} style={{marginTop: '24px'}}>
            {companyDetail.payCommissionsAllinList?.map(value => (
              <Descriptions.Item
                label={{'01': '支付宝', '02': '微信', '03': '银行卡'}[value.payType]}
                key={value.payType}
              >{`${(value.payCommissions * 100).toFixed(2)}%`}</Descriptions.Item>
            ))}
          </Descriptions>
        )}

        <Descriptions title={<DividerLine title={'管理员账号'} />} column={3} style={{marginTop: '24px'}}>
          {companyDetail.adminList?.map(item => (
            <React.Fragment key={item.userId}>
              <Descriptions.Item label={'姓名'}>{item.certName}</Descriptions.Item>
              <Descriptions.Item label={'账号'}>{item.loginAccount}</Descriptions.Item>
              <Descriptions.Item label={'手机号'}>{item.mobile}</Descriptions.Item>
            </React.Fragment>
          ))}
        </Descriptions>

        <Descriptions title={<DividerLine title={'接入方'} />} column={3} style={{marginTop: '24px'}}>
          <Descriptions.Item label={'应用ID'}>{companyDetail.stewardAppId}</Descriptions.Item>
          <Descriptions.Item label={'应用密钥'}>{companyDetail.stewardAppSecret}</Descriptions.Item>
        </Descriptions>

        <div style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '24px 0'}}>
          <DividerLine title={'合同约定'} />
          {auth('003165002') && (
            <Button
              type={'primary'}
              onClick={() => {
                setInsertVisible(true)
              }}
            >
              新增合同
            </Button>
          )}
        </div>
        <Table
          rowKey={'contractId'}
          dataSource={contractList}
          columns={[
            {
              title: '系统使用开始时间',
              dataIndex: 'contractBegin',
              sorter: {compare: (a, b) => new Date(a.contractBegin).getTime() - new Date(b.contractBegin).getTime()},
            },
            {
              title: '系统使用结束时间',
              dataIndex: 'contractEnd',
              sorter: {compare: (a, b) => new Date(a.contractEnd).getTime() - new Date(b.contractEnd).getTime()},
            },
            {title: '系统使用费', dataIndex: 'contractFee', sorter: {compare: (a, b) => a.contractFee - b.contractFee}},
            {title: '模块', dataIndex: 'contractModuleDesc'},
            {title: '状态', dataIndex: 'contractStatus', render: value => statusCode[value]},
            {title: '创建人', dataIndex: 'insertBy'},
            {
              title: '创建时间',
              dataIndex: 'insertTime',
              sorter: {compare: (a, b) => new Date(a.insertTime).getTime() - new Date(b.insertTime).getTime()},
            },
            {
              title: '操作',
              dataIndex: 'contractId',
              render: value =>
                auth('001165002') && (
                  <Button
                    type={'link'}
                    style={{padding: 0}}
                    onClick={async () => {
                      const {
                        response: {data},
                      } = await omsModel.omsCompanyContractDetail({contractId: value})
                      modelRef.current = Modal.confirm({
                        title: '详情',
                        icon: null,
                        centered: true,
                        closable: true,
                        content: (
                          <Descriptions column={1}>
                            <Descriptions.Item label={'系统使用开始时间'}>{data.contractBegin}</Descriptions.Item>
                            <Descriptions.Item label={'系统使用结束时间'}>{data.contractEnd}</Descriptions.Item>
                            <Descriptions.Item label={'系统使用模块'}>
                              <Space>
                                <span>{data.contractModuleDesc}</span>
                                {auth('005164002') && (
                                  <span
                                    style={{color: '#00B7AE', cursor: 'pointer'}}
                                    onClick={() => {
                                      modelRef.current?.destroy()
                                      history.push('/company-permission', {
                                        contractId: value,
                                        permissionIdList: data.contractModulePermissionList,
                                      })
                                    }}
                                  >
                                    修改
                                  </span>
                                )}
                              </Space>
                            </Descriptions.Item>
                            <Descriptions.Item label={'网址'}>{companyDetail.companyDomain}</Descriptions.Item>
                            <Descriptions.Item label={'登陆LOGO'}>
                              {companyDetail.companyLogo && (
                                <Image src={companyDetail.companyLogo} width={80} height={80} />
                              )}
                            </Descriptions.Item>
                            <Descriptions.Item label={'右上角LOGO'}>
                              {companyDetail.companyLogoMini && (
                                <Image src={companyDetail.companyLogoMini} width={80} height={80} />
                              )}
                            </Descriptions.Item>
                            <Descriptions.Item label={'线下合同扫描件'}>
                              <a
                                href={data.contractScannedCopy}
                                style={{textDecoration: 'underline'}}
                                target={'_blank'}
                                rel='noreferrer'
                              >
                                合同
                              </a>
                            </Descriptions.Item>
                          </Descriptions>
                        ),
                      })
                    }}
                  >
                    详情
                  </Button>
                ),
            },
          ]}
          pagination={getPaginationProps(form, contractTotal)}
        />
      </div>

      <Modal
        visible={insertVisible}
        title={'新增合同'}
        centered={true}
        onCancel={() => {
          setInsertVisible(false)
          form.resetFields()
        }}
        onOk={async () => {
          await form.validateFields()
          const formValues = form.getFieldsValue(true)
          await omsModel.omsCompanyContractInsert({
            ...formValues,
            contractBegin: moment(formValues.contractBegin).format('YYYY-MM-DD HH:mm:ss'),
            contractEnd: moment(formValues.contractEnd).format('YYYY-MM-DD HH:mm:ss'),
            companyId,
          })
          message.success('操作成功')
          queryCompanyContractList()
          setInsertVisible(false)
          form.resetFields()
        }}
      >
        <Form form={form}>
          <Form.Item
            label={'系统使用开始时间'}
            name={'contractBegin'}
            rules={[{required: true, message: '请填写系统使用开始时间'}]}
          >
            <DatePicker
              placeholder={'请填写系统使用开始时间'}
              style={{width: '100%'}}
              locale={locale}
              disabledDate={date => date.isBefore(moment().add(-1, 'day') as any)}
              showToday={false}
            />
          </Form.Item>
          <Form.Item noStyle shouldUpdate>
            {form1 => {
              const contractBegin = form1.getFieldValue('contractBegin')
              return (
                <Form.Item
                  label={'系统使用结束时间'}
                  name={'contractEnd'}
                  rules={[{required: true, message: '请填写系统使用结束时间'}]}
                >
                  <DatePicker
                    placeholder={'请填写系统使用结束时间'}
                    style={{width: '100%'}}
                    locale={locale}
                    disabledDate={date =>
                      date.isBefore(contractBegin ? (moment(contractBegin).add(1, 'day') as any) : (moment() as any))
                    }
                    showToday={false}
                  />
                </Form.Item>
              )
            }}
          </Form.Item>
          <Form.Item noStyle shouldUpdate={(prev, next) => prev.permissionDec !== next.permissionDec}>
            {form1 => {
              const permissionIdList = form1.getFieldValue('contractModulePermissionList') ?? []
              return (
                <Form.Item
                  label={'系统使用模块'}
                  name={'permissionDec'}
                  rules={[{required: true, message: '请选择系统使用模块'}]}
                >
                  <Input
                    placeholder={'请选择系统使用模块'}
                    onClick={() => {
                      setInsertVisible(false)
                      history.push('/company-permission', {permissionIdList, type: 'insert-contract'})
                    }}
                  />
                </Form.Item>
              )
            }}
          </Form.Item>
          <Form.Item
            label={'线下合同扫描件'}
            name={'_contractScannedCopy'}
            rules={[{required: true, message: '请上传线下合同扫描件'}]}
          >
            <Upload
              name='file'
              maxCount={1}
              headers={createHeaders()}
              action={baseUrl + '/oms/company/file-upload'}
              accept={'.pdf, .doc, .docx'}
              onChange={info => {
                if (info.file?.status === 'done') {
                  form.setFieldsValue({contractScannedCopy: info.file?.response?.data?.imgUrl})
                }
                if (info.file?.status === 'removed') {
                  form.setFieldsValue({contractScannedCopy: null})
                  form.resetFields(['_contractScannedCopy'])
                }
              }}
            >
              <Button icon={<UploadOutlined />}>选择上传文件</Button>
            </Upload>
          </Form.Item>
          <Form.Item label={'系统使用费'} name={'contractFee'} rules={[{required: true, message: '请填写系统使用费'}]}>
            <InputNumber placeholder={'请填写系统使用费'} style={{width: '100%'}} />
          </Form.Item>
        </Form>
      </Modal>
    </Page>
  )
}
