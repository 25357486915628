import React, {useCallback, useEffect, useState} from 'react'
import {
  Button,
  Checkbox,
  Col,
  Descriptions,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  PageHeader,
  Radio,
  Row,
  Space,
  Switch,
  Table,
  Transfer,
} from 'antd'
import {Page} from '@/components/Page'
import {stewardModel} from '@/store/models/Steward'
import {useAppHistory, useAppLocation} from '@/common/hooks/useRouter'
import DividerLine from '@/pages/property/component/DividerLine'
import commonStyles from '@/assets/styles/common.module.less'
import {sysModel} from '@/store/models/Sys'

export type ServiceChargeDetailVO = {
  cityCode: string
  cityName: string

  chargeAmt1: number
  chargeDetailId1: string
  chargeId1: string
  commission1: number
  commissionType1: number
  deduction1: number

  chargeAmt2: number
  chargeDetailId2: string
  chargeId2: string
  commission2: number
  commissionType2: number
  deduction2: number
}

export default function ServiceChargeDetail() {
  const history = useAppHistory()
  const location = useAppLocation<'/service-charges/detail'>()
  const chargeId = location.state?.chargeId

  const [basicForm] = Form.useForm()
  const [cityForm] = Form.useForm()

  // 服务收费详情
  const [chargedetail, setChargedetail] = useState({} as StewardServiceChargeVO)
  // 服务地区列表
  const [regionList, setRegionList] = useState<ServiceChargeDetailVO[]>([])
  // 配置的城市列表
  const [cityList, setCityList] = useState<{key: string; title: string}[]>([])

  // 编辑服务
  const [updateService, setUpdateService] = useState<{
    isOpen: boolean
    opt: 'insert' | 'update' | 'batch'
    updateValue: ServiceChargeDetailVO
    cityCodeList?: string[] // 批量的时候必传
  }>({
    opt: null,
    isOpen: false,
    updateValue: null,
    cityCodeList: [],
  })

  const getServiceChargeDetail = useCallback(async () => {
    // 获取配置的城市列表
    const {
      response: {data: data1},
    } = await sysModel.sysConfigGet({k: 'CONFIG_SYS_CITY'})

    const configCityList: {label: string; id: string}[] = data1?.city || []

    // 服务收费详情
    const {
      response: {data: data2},
    } = await stewardModel.stewardAdminServiceChargeDetail({chargeId})

    setChargedetail(data2)

    // 过滤出未配置的城市
    setCityList(
      configCityList
        .filter(item => !data2.detailList.some(value => value.cityCode === item.id))
        .map(item => ({key: item.id, title: item.label}))
    )

    // 根据城市code分组
    const combineObj = data2.detailList.reduce<{
      [key: string]: {charge1: StewardServiceChargeDetailVO; charge2: StewardServiceChargeDetailVO}
    }>((acc, item) => {
      if (acc[item.cityCode]) {
        if (item.chargeType === '1') {
          acc[item.cityCode] = {...acc[item.cityCode], charge1: item}
        } else {
          acc[item.cityCode] = {...acc[item.cityCode], charge2: item}
        }
      } else {
        if (item.chargeType === '1') {
          acc[item.cityCode] = {charge1: item, charge2: null}
        } else {
          acc[item.cityCode] = {charge1: null, charge2: item}
        }
      }
      return acc
    }, {})

    const combineList: {charge1: StewardServiceChargeDetailVO; charge2: StewardServiceChargeDetailVO}[] = Object.values(
      combineObj
    )

    const resList = combineList.map(item => {
      const charge1 = item.charge1
      const charge2 = item.charge2
      return {
        cityCode: charge1.cityCode,
        cityName: configCityList.find(item => item.id === charge1.cityCode)?.label,
        chargeAmt1: charge1.chargeAmt,
        chargeDetailId1: charge1.chargeDetailId,
        chargeId1: charge1.chargeId,
        commission1: charge1.commission,
        commissionType1: charge1.commissionType,
        deduction1: charge1.deduction,

        chargeAmt2: charge2.chargeAmt,
        chargeDetailId2: charge2.chargeDetailId,
        chargeId2: charge2.chargeId,
        commission2: charge2.commission,
        commissionType2: charge2.commissionType,
        deduction2: charge2.deduction,
      }
    })

    setRegionList(resList)
  }, [chargeId])

  useEffect(() => {
    getServiceChargeDetail()
  }, [getServiceChargeDetail])

  // 编辑基本信息
  function serviceChargeUpdateBasic() {
    basicForm.setFieldsValue({
      categoryId: chargedetail.categoryId,
      categoryName: chargedetail.categoryName,
      chargeId: chargedetail.chargeId,
      chargeName: chargedetail.chargeName,
      chargeType: chargedetail.chargeType,
      chargeUnit: chargedetail.chargeUnit,
      detailList: chargedetail.detailList,
      mustMaterial: chargedetail.mustMaterial,
      remark: chargedetail.remark,
    })

    Modal.confirm({
      title: '编辑',
      icon: null,
      centered: true,
      content: (
        <Form form={basicForm}>
          <Form.Item
            name={'mustMaterial'}
            label={'必须使用耗材'}
            valuePropName={'checked'}
            rules={[{required: true}]}
            getValueProps={value => ({checked: value === 1})}
            getValueFromEvent={event => (event ? 1 : 0)}
          >
            <Switch />
          </Form.Item>
          <Form.Item name={'chargeUnit'} label={'单位'} rules={[{required: true, message: '请输入单位'}]}>
            <Input placeholder={'请输入'} />
          </Form.Item>
          <Form.Item name={'remark'} label={'备注'}>
            <Input.TextArea allowClear placeholder={'请输入'} />
          </Form.Item>
        </Form>
      ),
      afterClose: () => basicForm.resetFields(),
      onOk: async () => {
        await basicForm.validateFields()
        const formValues = basicForm.getFieldsValue(true)
        await stewardModel.stewardAdminServiceChargeUpdate(formValues)
        message.success('编辑成功！')
        getServiceChargeDetail()
      },
    })
  }

  // 删除服务信息
  function serviceChargeDeleteService(cityCode: string) {
    const detailList = chargedetail.detailList?.filter(item => item.cityCode !== cityCode)
    Modal.confirm({
      title: '提示',
      icon: null,
      centered: true,
      content: '确定删除？',
      onOk: async () => {
        await stewardModel.stewardAdminServiceChargeUpdate({
          categoryId: chargedetail.categoryId,
          categoryName: chargedetail.categoryName,
          chargeId: chargedetail.chargeId,
          chargeName: chargedetail.chargeName,
          chargeType: chargedetail.chargeType,
          chargeUnit: chargedetail.chargeUnit,
          detailList,
          mustMaterial: chargedetail.mustMaterial,
          remark: chargedetail.remark,
        })
        message.success('删除成功！')
        getServiceChargeDetail()
      },
    })
  }

  // 新增服务地区服务
  function serviceChargeAddService() {
    Modal.confirm({
      title: '新增',
      icon: null,
      centered: true,
      width: '50%',
      content: (
        <Form form={cityForm}>
          <Form.Item noStyle shouldUpdate>
            {form1 => {
              const targetKeys = form1.getFieldValue('targetKeys') ?? []
              const targetSelectedKeys = form1.getFieldValue('targetSelectedKeys') ?? []
              return (
                <Form.Item
                  name={'targetKeys'}
                  label={'服务地区'}
                  rules={[{required: true, type: 'array', message: '请选择服务地区'}]}
                >
                  <Transfer
                    showSearch
                    dataSource={cityList}
                    render={item => item.title}
                    targetKeys={targetKeys}
                    titles={[
                      null,
                      <Button
                        key={'批量编辑'}
                        type={'link'}
                        style={{padding: 0}}
                        onClick={() => {
                          if (targetSelectedKeys.length === 0) return message.warning('请选择批量编辑的项')
                          setUpdateService({
                            isOpen: true,
                            opt: 'batch',
                            updateValue: {} as ServiceChargeDetailVO,
                            cityCodeList: targetSelectedKeys,
                          })
                        }}
                      >
                        批量编辑
                      </Button>,
                    ]}
                    locale={{itemUnit: '项', itemsUnit: '项', searchPlaceholder: '请输入搜索内容'}}
                    onChange={targetKeys => {
                      form1.setFieldsValue({targetKeys})
                    }}
                    onSelectChange={(_, targetSelectedKeys) =>
                      form1.setFieldValue('targetSelectedKeys', targetSelectedKeys)
                    }
                  >
                    {({filteredItems, direction, selectedKeys, onItemSelect}) => {
                      const columns =
                        direction === 'left'
                          ? [{title: '城市', dataIndex: 'title'}]
                          : [
                              {title: '城市', dataIndex: 'title'},
                              {
                                title: '状态',
                                dataIndex: 'key',
                                render: value => {
                                  const charge = form1.getFieldValue(value)
                                  return charge?.cityCode ? '已编辑' : '未编辑'
                                },
                              },
                              {
                                title: '操作',
                                dataIndex: 'key',
                                render: value => (
                                  <Button
                                    type={'link'}
                                    style={{padding: 0}}
                                    onClick={() => {
                                      const charges =
                                        form1.getFieldValue(value) ?? ({cityCode: value} as ServiceChargeDetailVO)

                                      setUpdateService({isOpen: true, opt: 'insert', updateValue: charges})
                                    }}
                                  >
                                    编辑
                                  </Button>
                                ),
                              },
                            ]
                      return (
                        <Table
                          rowKey={'key'}
                          size={'small'}
                          columns={columns}
                          dataSource={filteredItems}
                          rowSelection={{
                            hideSelectAll: true,
                            selectedRowKeys: selectedKeys,
                            onSelect: ({key}, selected) => onItemSelect(key, selected),
                          }}
                          pagination={{pageSize: 5}}
                        />
                      )
                    }}
                  </Transfer>
                </Form.Item>
              )
            }}
          </Form.Item>
        </Form>
      ),
      afterClose: () => cityForm.resetFields(),
      onOk: async () => {
        await cityForm.validateFields()
        const formValues = cityForm.getFieldsValue(true)
        const targetKeys = formValues.targetKeys
        for (let i = 0; i < targetKeys.length; i++) {
          if (!formValues[targetKeys[i]]?.cityCode) {
            message.error('请填写未编辑的项！')
            return Promise.reject('')
          }
        }

        const detailList = chargedetail.detailList ?? []
        for (let i = 0; i < targetKeys.length; i++) {
          const newItem = chargeDetailToList(formValues[targetKeys[i]]) ?? []
          detailList.push(...newItem)
        }

        await stewardModel.stewardAdminServiceChargeUpdate({
          categoryId: chargedetail.categoryId,
          categoryName: chargedetail.categoryName,
          chargeId: chargedetail.chargeId,
          chargeName: chargedetail.chargeName,
          chargeType: chargedetail.chargeType,
          chargeUnit: chargedetail.chargeUnit,
          detailList,
          mustMaterial: chargedetail.mustMaterial,
          remark: chargedetail.remark,
        })

        message.success('操作成功！')

        getServiceChargeDetail()
      },
    })
  }

  return (
    <Page header={<PageHeader title={'返回'} ghost={false} onBack={() => history.goBack()} />}>
      <div className={commonStyles.tableSection}>
        <Descriptions
          title={
            <DividerLine
              title={'基本信息'}
              right={
                <Button type={'link'} onClick={serviceChargeUpdateBasic}>
                  编辑
                </Button>
              }
            />
          }
          column={4}
        >
          <Descriptions.Item label={'服务编号'}>{chargedetail.chargeId}</Descriptions.Item>
          <Descriptions.Item label={'服务名称'}>{chargedetail.chargeName}</Descriptions.Item>
          <Descriptions.Item label={'服务类型'}>
            {(chargedetail.chargeType === 'steward' && '管家') || (chargedetail.chargeType === 'clean' && '保洁')}
          </Descriptions.Item>
          <Descriptions.Item label={'关联服务类型'}>{chargedetail.serviceList?.join('、')}</Descriptions.Item>
          <Descriptions.Item label={'收费类型'}>{chargedetail.categoryName}</Descriptions.Item>
          <Descriptions.Item label={'必须使用耗材'}>
            {(chargedetail.mustMaterial === 0 && '否') || (chargedetail.mustMaterial === 1 && '是')}
          </Descriptions.Item>
          <Descriptions.Item label={'单位'}>{chargedetail.chargeUnit}</Descriptions.Item>
          <Descriptions.Item label={'备注'}>{chargedetail.remark}</Descriptions.Item>
        </Descriptions>

        <DividerLine
          title={'服务地区'}
          top={15}
          bottom={20}
          right={
            <Button type={'link'} onClick={() => serviceChargeAddService()}>
              新增
            </Button>
          }
        />
        <Table
          rowKey={'cityCode'}
          dataSource={regionList}
          columns={[
            {title: '城市', dataIndex: 'cityName'},
            {title: '全包收费价格', dataIndex: 'chargeAmt1'},
            {
              title: '全包收费提成',
              dataIndex: 'commissionType1',
              render: (value, record) =>
                value === 1
                  ? `固定金额${record.commission1}元`
                  : `按比例${record.commission1}%${record.deduction1 === 0 ? '不抵扣耗材核算价' : '抵扣耗材核算价'}`,
            },
            {title: '按次计费价格', dataIndex: 'chargeAmt2'},
            {
              title: '按次计费提成',
              dataIndex: 'commissionType2',
              render: (value, record) =>
                value === 1
                  ? `固定金额${record.commission2}元`
                  : `按比例${record.commission2}%${record.deduction2 === 0 ? '不抵扣耗材核算价' : '抵扣耗材核算价'}`,
            },
            {
              title: '操作',
              dataIndex: 'cityCode',
              render: (value, record) => {
                return (
                  <Space>
                    <Button
                      type={'link'}
                      style={{padding: 0}}
                      onClick={() => setUpdateService({isOpen: true, opt: 'update', updateValue: record})}
                    >
                      编辑
                    </Button>
                    <Button type={'link'} style={{padding: 0}} onClick={() => serviceChargeDeleteService(value)}>
                      删除
                    </Button>
                  </Space>
                )
              },
            },
          ]}
          pagination={false}
        />
      </div>

      <ServiceChargeUpdateService
        isOpen={updateService.isOpen}
        updateValue={updateService.updateValue}
        onOk={async values => {
          const cityCode = values.cityCode
          const newItem = chargeDetailToList(values)

          if (updateService.opt === 'update') {
            const detailList = chargedetail.detailList?.filter(item => item.cityCode !== cityCode)

            await stewardModel.stewardAdminServiceChargeUpdate({
              categoryId: chargedetail.categoryId,
              categoryName: chargedetail.categoryName,
              chargeId: chargedetail.chargeId,
              chargeName: chargedetail.chargeName,
              chargeType: chargedetail.chargeType,
              chargeUnit: chargedetail.chargeUnit,
              detailList: detailList.concat(newItem),
              mustMaterial: chargedetail.mustMaterial,
              remark: chargedetail.remark,
            })
            message.success('编辑成功！')
            getServiceChargeDetail()
          }

          if (updateService.opt === 'insert') {
            cityForm.setFieldsValue({[cityCode]: values})
          }

          if (updateService.opt === 'batch') {
            updateService.cityCodeList?.forEach(item => {
              cityForm.setFieldsValue({[item]: {...values, cityCode: item}})
            })
          }

          setUpdateService({isOpen: false, opt: null, updateValue: null, cityCodeList: []})
        }}
        onCancel={() => setUpdateService({isOpen: false, opt: null, updateValue: null, cityCodeList: []})}
      />
    </Page>
  )
}

export function chargeDetailToList(args: ServiceChargeDetailVO): StewardServiceChargeDetailVO[] {
  return [
    {
      chargeAmt: args.chargeAmt1,
      chargeDetailId: args.chargeDetailId1,
      chargeId: args.chargeId1,
      chargeType: '1',
      cityCode: args.cityCode,
      commission: args.commission1,
      commissionType: args.commissionType1,
      deduction: args.deduction1 ?? 1,
    },
    {
      chargeAmt: args.chargeAmt2,
      chargeDetailId: args.chargeDetailId2,
      chargeId: args.chargeId2,
      chargeType: '2',
      cityCode: args.cityCode,
      commission: args.commission2,
      commissionType: args.commissionType2,
      deduction: args.deduction2 ?? 1,
    },
  ]
}

type ServiceChargeUpdateServiceProps = {
  isOpen: boolean
  updateValue: ServiceChargeDetailVO
  onOk: (values: ServiceChargeDetailVO) => void
  onCancel: () => void
}

export function ServiceChargeUpdateService({isOpen, updateValue, onOk, onCancel}: ServiceChargeUpdateServiceProps) {
  const [form] = Form.useForm<ServiceChargeDetailVO>()

  useEffect(() => {
    if (updateValue) {
      form.setFieldsValue(updateValue)
    }
  }, [form, updateValue])

  return (
    <Modal
      open={isOpen}
      title={'编辑'}
      centered
      width={'50%'}
      afterClose={() => form.resetFields()}
      onOk={async () => {
        await form.validateFields()
        onOk(form.getFieldsValue(true))
      }}
      onCancel={onCancel}
      zIndex={2000}
    >
      <Form form={form} labelCol={{flex: '110px'}} labelAlign={'right'}>
        {/** 全包收费价格 */}
        <Form.Item name={'chargeAmt1'} label={'全包收费价格'} rules={[{required: true, message: '请输入全包收费价格'}]}>
          <InputNumber placeholder={'请输入'} addonAfter={'元'} />
        </Form.Item>
        <Row>
          <Col span={11}>
            <Form.Item name={'commissionType1'} label={'提成'}>
              <Radio.Group>
                <Radio value={1}>固定金额</Radio>
                <Radio value={2}>按比例</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, nextValues) => prevValues.commissionType1 !== nextValues.commissionType1}
            >
              {form1 => {
                const commissionType1 = form1.getFieldValue('commissionType1')
                return (
                  <Form.Item name={'commission1'}>
                    <InputNumber
                      placeholder={'请输入'}
                      addonAfter={(commissionType1 === 1 && '元') || (commissionType1 === 2 && '%')}
                    />
                  </Form.Item>
                )
              }}
            </Form.Item>
          </Col>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, nextValues) => prevValues.commissionType1 !== nextValues.commissionType1}
          >
            {form1 => {
              const commissionType1 = form1.getFieldValue('commissionType1')
              return commissionType1 === 2 ? (
                <Col span={6} offset={1}>
                  <Form.Item
                    name={'deduction1'}
                    valuePropName='checked'
                    getValueProps={value => ({checked: value === 0})}
                    getValueFromEvent={({target: {checked}}) => (checked ? 0 : 1)}
                  >
                    <Checkbox value={1}>不抵扣耗材核算价</Checkbox>
                  </Form.Item>
                </Col>
              ) : null
            }}
          </Form.Item>
        </Row>

        {/** 按次计费价格 */}
        <Form.Item name={'chargeAmt2'} label={'按次计费价格'} rules={[{required: true, message: '请输入按次计费价格'}]}>
          <InputNumber placeholder={'请输入'} addonAfter={'元'} />
        </Form.Item>
        <Row>
          <Col span={11}>
            <Form.Item name={'commissionType2'} label={'提成'}>
              <Radio.Group>
                <Radio value={1}>固定金额</Radio>
                <Radio value={2}>按比例</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, nextValues) => prevValues.commissionType2 !== nextValues.commissionType2}
            >
              {form1 => {
                const commissionType2 = form1.getFieldValue('commissionType2')
                return (
                  <Form.Item name={'commission2'}>
                    <InputNumber
                      placeholder={'请输入'}
                      addonAfter={(commissionType2 === 1 && '元') || (commissionType2 === 2 && '%')}
                    />
                  </Form.Item>
                )
              }}
            </Form.Item>
          </Col>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, nextValues) => prevValues.commissionType2 !== nextValues.commissionType2}
          >
            {form1 => {
              const commissionType2 = form1.getFieldValue('commissionType2')
              return commissionType2 === 2 ? (
                <Col span={6} offset={1}>
                  <Form.Item
                    name={'deduction2'}
                    valuePropName='checked'
                    getValueProps={value => ({checked: value === 0})}
                    getValueFromEvent={({target: {checked}}) => (checked ? 0 : 1)}
                  >
                    <Checkbox value={1}>不抵扣耗材核算价</Checkbox>
                  </Form.Item>
                </Col>
              ) : null
            }}
          </Form.Item>
        </Row>
      </Form>
    </Modal>
  )
}
