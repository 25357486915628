import React, {useEffect, useState} from 'react'
import {Page} from '@/components/Page'
import {Descriptions, PageHeader} from 'antd'
import {useAppHistory, useAppLocation} from '@/common/hooks/useRouter'
import {stewardModel} from '@/store/models/Steward'
import commonStyle from '@/assets/styles/common.module.less'

export default function OpenAppDetail() {
  const history = useAppHistory()
  const location = useAppLocation<'/open-app-list/detail'>()
  const appId = location.state?.appId

  const [appDetail, setAppDetail] = useState({} as StewardOpenAppVO)

  useEffect(() => {
    if (appId) {
      stewardModel.stewardOpenAppDetail({appId}).then(({response: {data}}) => setAppDetail(data))
    }
  }, [appId])

  return (
    <Page header={<PageHeader title={'返回'} ghost={false} onBack={() => history.goBack()} />}>
      <div className={commonStyle.filterSection}>
        <Descriptions title={''} column={1}>
          <Descriptions.Item label={'接入方名称'}>{appDetail.appName}</Descriptions.Item>
          <Descriptions.Item label={'负责人'}>{appDetail.registerUname}</Descriptions.Item>
          <Descriptions.Item label={'手机号'}>{appDetail.registerMobile}</Descriptions.Item>
          <Descriptions.Item label={'应用ID'}>{appDetail.appId}</Descriptions.Item>
          <Descriptions.Item label={'应用密钥'}>{appDetail.appSecret}</Descriptions.Item>
          <Descriptions.Item label={'有效期'}>
            {[appDetail.validBeginDate, appDetail.validEndDate].filter(Boolean).join('至')}
          </Descriptions.Item>
          <Descriptions.Item label={'备注'}>{appDetail.remark}</Descriptions.Item>
          <Descriptions.Item label={'创建人'}>{appDetail.insertBy}</Descriptions.Item>
          <Descriptions.Item label={'创建时间'}>{appDetail.insertTime}</Descriptions.Item>
        </Descriptions>
      </div>
    </Page>
  )
}
