import React, {useCallback, useEffect, useState} from 'react'
import {Page} from '@/components/Page'
import {Breadcrumb} from '@/components/Breadcrumb'
import {Button, Col, Form, Input, Row, Space, Table} from 'antd'
import commonStyles from '@/assets/styles/common.module.less'
import {getPaginationProps} from '@/common/biz/getPaginationProps'
import {stewardModel} from '@/store/models/Steward'
import {useAppHistory} from '@/common/hooks/useRouter'

export default function ClientServiceTemplate() {
  const history = useAppHistory()
  const [form] = Form.useForm()
  const [list, setList] = useState<StewardServiceTemplateVO[]>([])
  const [total, setTotal] = useState(0)
  const loading = stewardModel.stewardServiceTemplateList.useLoading()
  const search = useCallback(() => {
    const values = form.getFieldsValue(true)
    stewardModel.stewardServiceTemplateList(values).then(({response: {data, total}}) => {
      setList(data)
      setTotal(total)
    })
  }, [form])

  useEffect(() => {
    form.submit()
  }, [form])

  return (
    <Page
      breadcrumb={
        <Breadcrumb
          right={
            <Button type={'primary'} onClick={() => history.push({pathname: '/client-template/add'})}>
              新增模板
            </Button>
          }
        />
      }
    >
      <div className={commonStyles.filterSection}>
        <Form form={form} onFinish={search} onReset={search} initialValues={{pageable: true, pageNum: 1, pageSize: 10}}>
          <Row>
            <Col span={7}>
              <Form.Item label={'编号'} name={'templateId'}>
                <Input placeholder={'请输入'} allowClear />
              </Form.Item>
            </Col>
            <Col span={7} offset={1}>
              <Form.Item label={'名称'} name={'templateName'}>
                <Input placeholder={'请输入'} allowClear />
              </Form.Item>
            </Col>
          </Row>
          <Row justify={'end'}>
            <Space>
              <Button type={'primary'} htmlType={'submit'} onClick={() => form.setFieldsValue({pageNum: 1})}>
                查询
              </Button>
              <Button htmlType={'reset'}>重置</Button>
            </Space>
          </Row>
        </Form>
      </div>
      <div className={commonStyles.tableSection}>
        <Table
          rowKey={'templateId'}
          dataSource={list}
          loading={loading}
          columns={[
            {title: '编号', dataIndex: 'templateId'},
            {title: '名称', dataIndex: 'templateName'},
            {
              title: '操作',
              dataIndex: 'templateId',
              render: value => {
                return (
                  <Button
                    type={'link'}
                    onClick={() => history.push({pathname: '/client-template/add', state: {templateId: value}})}
                  >
                    编辑
                  </Button>
                )
              },
            },
          ]}
          pagination={getPaginationProps(form, total)}
        />
      </div>
    </Page>
  )
}
