import React, {useCallback, useEffect, useState} from 'react'
import {Button, Col, Descriptions, Drawer, Form, Input, Modal, Row, Select, Space, Switch, Table} from 'antd'
import {Page} from '@/components/Page'
import commonStyles from '@/assets/styles/common.module.less'
import {Breadcrumb} from '@/components/Breadcrumb'
import {useAppHistory} from '@/common/hooks/useRouter'
import DividerLine from '@/pages/property/component/DividerLine'
import {condoModel} from '@/store/models/Condo'
import {Album} from '@/components/Album'
import {getImg} from '@/utils/util'
import {usePermission} from '@/components/Permission'
import {getPaginationProps} from '@/common/biz/getPaginationProps'
import {useFocusEffect} from '@/common/hooks/useFocusEffect'

export default function FinanceCompany() {
  const history = useAppHistory()
  const [form] = Form.useForm()
  const [companyList, setCompanyList] = useState<CondoProjectCompanyVO[]>([])
  const [total, setTotal] = useState(0)
  const loading = condoModel.condoProjectCompanyList.useLoading()
  const [info, setInfo] = useState({} as CondoProjectCompanyVO)
  const [auth] = usePermission()

  const search = useCallback(() => {
    const formValues = form.getFieldsValue(true)

    condoModel.condoProjectCompanyList(formValues).then(({response: {data, total}}) => {
      setCompanyList(data)
      setTotal(total)
    })
  }, [form])

  useFocusEffect(() => {
    form.submit()
  })

  useEffect(() => {
    form.submit()
  }, [form])

  return (
    <Page
      breadcrumb={
        <Breadcrumb
          right={
            auth('003100002') && (
              <Button type={'primary'} onClick={() => history.push({pathname: '/finance-company/add'})}>
                新增财务公司
              </Button>
            )
          }
        />
      }
    >
      <Form form={form} onFinish={search} onReset={search} initialValues={{pageNum: 1, pageSize: 10}}>
        <div className={commonStyles.filterSection}>
          <Row>
            <Col span={5}>
              <Form.Item label={'企业名称'} name={'companyName'}>
                <Input placeholder='请输入企业名称' allowClear />
              </Form.Item>
            </Col>
            <Col span={5} offset={1}>
              <Form.Item label={'法人姓名'} name={'legalName'}>
                <Input placeholder='请输入企业名称' allowClear />
              </Form.Item>
            </Col>
            <Col offset={1} span={5}>
              <Form.Item label={'状态'} name={'status'}>
                <Select placeholder={'请选择'} allowClear>
                  <Select.Option value={'1'}>正常</Select.Option>
                  <Select.Option value={'0'}>禁用</Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row justify={'end'}>
            <Space>
              <Button type={'primary'} htmlType={'submit'} onClick={() => form.setFieldsValue({pageNum: 1})}>
                查询
              </Button>
              <Button htmlType={'reset'}>重置</Button>
            </Space>
          </Row>
        </div>
        <div className={commonStyles.filterSection}>
          <Table
            rowKey={'uniCredit'}
            loading={loading}
            style={{marginBottom: 10}}
            dataSource={companyList}
            columns={[
              {title: '企业名称', dataIndex: 'companyName', key: 'companyName'},
              {title: '法人姓名', dataIndex: 'legalName', key: 'legalName'},
              {title: '联系电话', dataIndex: 'legalPhone', key: 'legalPhone'},
              {title: '状态', dataIndex: 'status', key: 'status', render: text => (text === '1' ? '正常' : '禁用')},
              {
                title: '操作',
                render: record => {
                  return (
                    <Space>
                      {auth('023100002') && (
                        <Switch
                          checked={record.status === '1'}
                          onChange={value => {
                            Modal.confirm({
                              title: '提示',
                              centered: true,
                              icon: null,
                              content: `是否确认${value ? '启用' : '禁用'}该公司？`,
                              onOk: async () => {
                                await condoModel.condoProjectCompanyUpdateStatus({
                                  uniCredit: record.uniCredit,
                                  status: value ? '1' : '0',
                                })
                                search()
                              },
                            })
                          }}
                        />
                      )}

                      {record.status === '0' && auth('002100002') && (
                        <Button
                          type='link'
                          style={{padding: 0}}
                          onClick={() => {
                            history.push({pathname: '/finance-company/add', state: {uniCredit: record.uniCredit}})
                          }}
                        >
                          编辑
                        </Button>
                      )}
                      {auth('001100002') && (
                        <Button
                          type='link'
                          style={{padding: 0}}
                          onClick={async () => {
                            const {
                              response: {data},
                            } = await condoModel.condoProjectCompanyInfo({uniCredit: record.uniCredit})
                            setInfo(data)
                          }}
                        >
                          查看
                        </Button>
                      )}

                      {record.status === '2' && auth('004100002') && (
                        <Button
                          type='link'
                          style={{padding: 0}}
                          onClick={() => {
                            Modal.confirm({
                              title: '提示',
                              centered: true,
                              icon: null,
                              content: `是否确认删除该公司？`,
                              onOk: async () => {
                                await condoModel.condoProjectCompanyDelete({
                                  uniCredit: record.uniCredit,
                                })
                                search()
                              },
                            })
                          }}
                        >
                          删除
                        </Button>
                      )}
                    </Space>
                  )
                },
              },
            ]}
            pagination={getPaginationProps(form, total)}
          />
        </div>
      </Form>

      {/* 查看详情 */}
      <Drawer
        title={'财务公司详情'}
        visible={!!info.uniCredit}
        width={700}
        onClose={() => {
          setInfo({} as CondoProjectCompanyVO)
        }}
      >
        <DividerLine title='公司信息' style={{paddingBottom: '10px'}} />
        <Descriptions column={2}>
          <Descriptions.Item label={'企业名称'}>
            <span>{info.companyName}</span>
          </Descriptions.Item>
          <Descriptions.Item label={'统一社会信用代码'}>
            <span>{info.uniCredit}</span>
          </Descriptions.Item>
          <Descriptions.Item label={'法人姓名'}>
            <span>{info.legalName}</span>
          </Descriptions.Item>
          <Descriptions.Item label={'法人身份证号码'}>
            <span>{info.legalIds ?? '无'}</span>
          </Descriptions.Item>
          <Descriptions.Item label={'联系电话'}>
            <span>{info.legalPhone ?? '无'}</span>
          </Descriptions.Item>
        </Descriptions>
        <Descriptions column={1}>
          <Descriptions.Item label={'营业执照'}>
            {info.pictureUrl1 ? (
              <Album disabled fileList={[{url: getImg(info.pictureUrl1), uid: info.pictureUrl1}]}>
                {null}
              </Album>
            ) : (
              '无'
            )}
          </Descriptions.Item>
          <Descriptions.Item label={'法人身份证照片'}>
            {info.pictureUrl8 || info.pictureUrl9 ? (
              <Album
                disabled
                fileList={[
                  {url: getImg(info.pictureUrl8), uid: info.pictureUrl8},
                  {url: getImg(info.pictureUrl9), uid: info.pictureUrl9},
                ].filter(item => item.uid)}
              >
                {null}
              </Album>
            ) : (
              '无'
            )}
          </Descriptions.Item>
        </Descriptions>
        <DividerLine title='对公账户信息' style={{padding: '10px 0'}} />
        <Descriptions column={2}>
          <Descriptions.Item label={'公司账号'}>
            <span>{info.accountNo ?? '无'}</span>
          </Descriptions.Item>
          <Descriptions.Item label={'开户银行名称'}>
            <span>{info.parentBankName ?? '无'}</span>
          </Descriptions.Item>
          <Descriptions.Item label={'银行支行名称'}>
            <span>{info.bankName ?? '无'}</span>
          </Descriptions.Item>
          <Descriptions.Item label={'支行行号'}>
            <span>{info.unionBank ?? '无'}</span>
          </Descriptions.Item>
        </Descriptions>
      </Drawer>
    </Page>
  )
}
