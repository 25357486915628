import {Model} from '@redux-model/web'
import {$api} from '@/store/service'

export interface HomeModel {}

class Home extends Model<HomeModel> {
  protected initReducer(): HomeModel {
    return {}
  }

  // 后台管理首页-管家总监-待办事项&工单概览(9项数据)
  homeStewardStatistic1 = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<Record<string, any>>>('/home/steward/statistic-1').body(args)
  )

  // 后台管理首页-管家总监-数据统计2(48h未完成...)  |  object:{beginTime:开始时间,endTime:结束时间}
  homeStewardStatistic2 = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<Record<string, any>>>('/home/steward/statistic-2').body(args)
  )

  // 后台管理首页-管家总监-数据统计3(完成单量...)  |  object:{beginTime:开始时间,endTime:结束时间}
  homeStewardStatistic3 = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<Record<string, any>>>('/home/steward/statistic-3').body(args)
  )

  // 后台管理首页-管家总监-数据统计4(差评单量...)  |  object:{beginTime:开始时间,endTime:结束时间}
  homeStewardStatistic4 = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<Record<string, any>>>('/home/steward/statistic-4').body(args)
  )

  // 后台管理首页-管家总监-数据统计5(取消单量...)  |  object:{beginTime:开始时间,endTime:结束时间}
  homeStewardStatistic5 = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<Record<string, any>>>('/home/steward/statistic-5').body(args)
  )

  // 后台管理首页-管家-持单分布(num1:待沟通,num2:已沟通)
  homeStewardStatistic6 = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<Record<string, any>[]>>('/home/steward/statistic-6').body(args)
  )

  // 后台管理首页-管家-完成单量(num1:数量)
  homeStewardStatistic7 = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<Record<string, any>[]>>('/home/steward/statistic-7').body(args)
  )

  // 后台管理首页-管家-评价情况(num1:差评<=3,num2:房东好评>=4,num3:租客好评>=4)
  homeStewardStatistic8 = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<Record<string, any>[]>>('/home/steward/statistic-8').body(args)
  )
}

export const homeModel = new Home()
