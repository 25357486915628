import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Modal,
  Radio,
  Row,
  Select,
  Space,
  Table,
  Typography,
  Upload,
  Timeline,
  message,
  Descriptions,
} from 'antd'
import {commonModel, useCodeArraySync, useCodeSync} from '@/store/models/Common'
import moment from 'moment'
import {saleModel} from '@/store/models/Sale'
import styles from './index.module.less'
import {PlusCircleOutlined, UploadOutlined} from '@ant-design/icons'
import {baseUrl} from '@/common/config'
import {getImg} from '@/utils/util'
import {useAppHistory} from '@/common/hooks/useRouter'
import {createHeaders} from '@/store/service'
import DividerLine from '@/pages/property/component/DividerLine'
import {customerModel} from '@/store/models/Customer'

const {RangePicker} = DatePicker
const {Option} = Select

const cleanFreqTime = {
  '1': [
    {id: 4, label: '4周'},
    {id: 8, label: '8周'},
    {id: 12, label: '12周'},
    {id: 24, label: '24周'},
    {id: 48, label: '48周'},
  ],
  '2': [
    {id: 2, label: '4周'},
    {id: 4, label: '8周'},
    {id: 6, label: '12周'},
    {id: 12, label: '24周'},
    {id: 24, label: '48周'},
  ],
  '3': [
    {id: 2, label: '两个月'},
    {id: 4, label: '四个月'},
    {id: 6, label: '六个月'},
    {id: 12, label: '十二个月'},
  ],
}

const months = Array.from({length: 12}).map((_, i) => ({label: `${i + 1}`, value: i + 1}))

const getUUID = () => `${Math.random()}`

interface SaleClientContractInputProps {
  contractId?: string
  signId?: string
  editClean?: boolean // 签约客户管理-合同列表-合同详情：保洁服务可编辑
}

export default function SaleClientContractInput(props: SaleClientContractInputProps) {
  const history = useAppHistory()
  // 用来查询客户合同详情
  const contractId = props.contractId
  // 签约id
  const signId = props.signId
  const editClean = props.editClean
  // const audit = props.audit
  const servicePayWay = useCodeArraySync('sale.contract.pay.way')
  const cleanService = useCodeArraySync('cleanService')
  const cleanFreq = useCodeArraySync('cleanFreq')
  const cleanOtherFee = useCodeArraySync('cleanOtherFee')
  const materialSourceArr = useCodeArraySync('material.source')
  const stewardFeeType = useCodeArraySync('steward.fee.type')
  const saleContractVersion = useCodeArraySync('sale.contract.version')
  const cleanFeeTypeCode = useCodeSync('clean.fee.type')
  // 历史记录
  const [historyList, setHistoryList] = useState<SaleCustomerContractHistoryVO[]>([])
  const [basicForm] = Form.useForm()
  const [stewardForm] = Form.useForm()
  const [cleanForm] = Form.useForm()
  // 新增管家服务表单
  // 服务表单列表
  // 新增管家服务Modal
  const [stewardServiceForm] = Form.useForm()
  const [stewardServiceList, setStewardServiceList] = useState<(SaleCustomerSignStewardServiceVO & {_id: string})[]>([])
  const [stewardServiceFormVisible, setStewardServiceFormVisible] = useState(false)

  // 新增保洁服务表单
  // 保洁服务列表
  // 新增保洁服务Modal
  const [cleanServiceForm] = Form.useForm()
  const [cleanServiceList, setCleanServiceList] = useState<(SaleCustomerSignCleanServiceVO & {_id: string})[]>([])
  const [cleanServiceFormVisible, setCleanServiceFormVisible] = useState(false)

  const cleanNoPeriodServiceList = useMemo(
    () => cleanServiceList.filter(value => !value.serviceTypeList?.includes('6')),
    [cleanServiceList]
  )
  const cleanPeriodServiceList = useMemo(() => cleanServiceList.filter(value => value.serviceTypeList?.includes('6')), [
    cleanServiceList,
  ])
  const [contractType, setContractType] = useState([])
  // 系统收费
  const systemFeeArray = useCodeArraySync('system.fee')
  // 管家服务收费类型
  const stewardPriceTypeArray = useCodeArraySync('steward.price.type')
  // 甲方违约金
  const firstPartyBreakArray = useCodeArraySync('first.party.break')
  //省份列表
  const [provinceList, setProvinceList] = useState([])
  // 城市列表
  const [cityList, setCityList] = useState([])
  // 区域列表
  const [districtList, setDistrictList] = useState([])
  // 商圈列表
  const [bizCircleList, setBizCircleList] = useState([])
  // 查区域和商圈
  const queryBizCircle = useCallback(function (args: {type: '1' | '2' | '3' | '4'; parentCode: string}) {
    const {type, parentCode} = args
    commonModel.queryCityCode({type, parentCode}).then(({response: {data}}) => {
      switch (type) {
        case '1': {
          setProvinceList(data)
          break
        }
        case '2': {
          setCityList(data)
          break
        }
        case '3': {
          setDistrictList(data)
          break
        }
        case '4': {
          setBizCircleList(data)
          break
        }
      }
    })
  }, [])

  useEffect(() => {
    queryBizCircle({
      type: '1',
      parentCode: '',
    })
  }, [contractId, queryBizCircle, signId])

  // 付款日期
  const dateList = useMemo(() => {
    const arr = []
    for (let i = 1; i <= 31; i++) arr.push(`${i}`)
    return arr
  }, [])

  // 查询客户合同历史
  const queryHistoryList = useCallback((signId: string) => {
    if (signId) {
      saleModel.saleCustomerContractHistory({signId}).then(({response: {data}}) => {
        setHistoryList(data)
      })
    }
  }, [])

  // 查询维修超量扣款的描述
  const [emdDesc, setEmdDesc] = useState('')
  const queryEmdDesc = useCallback((userId: string) => {
    customerModel.customerGetEmdDesc({userId}).then(({response: {data}}) => {
      setEmdDesc(data)
    })
  }, [])

  // 查询合同详情
  const searchContractDetail = useCallback(() => {
    saleModel.saleCustomerContractInfo({contractId}).then(({response: {data}}) => {
      const {
        contractBeginDate,
        contractEndDate,
        contractClean,
        contractSteward,
        districtCode,
        cityCode,
        provinceCode,
      } = data
      const contractDate = [moment(contractBeginDate), moment(contractEndDate)]
      const contractType = [contractClean === '1' && 'B', contractSteward === '1' && 'A'].filter(item => item)
      basicForm.setFieldsValue({
        ...data,
        contractDate,
        contractType,
      })
      stewardForm.setFieldsValue({
        ...data.stewardVO,
        stewardPrice2: data.stewardPrice2,
        stewardPrice3: data.stewardPrice3,
        stewardPriceType2: data.stewardPriceType2,
        stewardPriceType3: data.stewardPriceType3,
        firstPayDate: data?.stewardVO?.firstPayDate
          ? moment(data.stewardVO.firstPayDate)
          : data?.stewardVO?.firstPayDate,
        materialAddress: (data as any)?.materialAddress,
      })
      cleanForm.setFieldsValue(data.cleanVO)

      if (data.stewardVO?.stewardServiceList?.length) {
        setStewardServiceList(data.stewardVO.stewardServiceList.map(value => ({...value, _id: getUUID()})))
      }
      if (data.cleanVO?.cleanServiceList?.length) {
        setCleanServiceList(
          data.cleanVO.cleanServiceList.map(value => ({
            ...value,
            _id: getUUID(),
            cleanStartDate: value.cleanStartDate ? (moment(value.cleanStartDate) as any) : value.cleanStartDate,
          }))
        )
      }
      setContractType(contractType)
      queryBizCircle({type: '2', parentCode: provinceCode})
      queryBizCircle({type: '3', parentCode: cityCode})
      queryBizCircle({type: '4', parentCode: districtCode})
      queryHistoryList(data.signId)
      queryEmdDesc(data.customerId)
    })
  }, [basicForm, cleanForm, contractId, queryBizCircle, queryEmdDesc, queryHistoryList, stewardForm])

  // 客户合同详情
  useEffect(
    function () {
      if (contractId) {
        searchContractDetail()
      }
    },
    [contractId, searchContractDetail]
  )

  // 签约详情
  useEffect(
    function () {
      if (signId) {
        saleModel.saleCustomerSignInfo({signId}).then(({response: {data}}) => {
          const contractType = [data.signClean === '1' && 'B', data.signSteward === '1' && 'A'].filter(item => item)
          basicForm.setFieldsValue({
            ...data,
            ...(data.contractBeginDate && data.contractEndDate
              ? {contractDate: [moment(data.contractBeginDate), moment(data.contractEndDate)]}
              : {}),
            contractType,
            contractClean: data.signClean,
            contractSteward: data.signSteward,
            remark: [
              data.address && `房源信息：${data.address}`,
              data.remark && `其他服务：${data.remark}`,
              data.auditRemark && `备注：${data.auditRemark}`,
            ]
              .filter(value => value)
              .join('\n\n'),
          })
          if (data.signSteward === '1') {
            // todo: data.materialInclusive
            stewardForm.setFieldsValue({
              ...data,
              firstPayDate: data?.firstPayDate ? moment(data.firstPayDate) : data?.firstPayDate,
            })
            if (data?.stewardServiceList?.length) {
              setStewardServiceList(data.stewardServiceList.map(value => ({...value, _id: getUUID()})))
            }
          }
          if (data.signClean === '1') {
            cleanForm.setFieldsValue(data)

            if (data?.cleanServiceList?.length) {
              setCleanServiceList(
                data.cleanServiceList.map(value => ({
                  ...value,
                  _id: getUUID(),
                  cleanStartDate: value.cleanStartDate ? (moment(value.cleanStartDate) as any) : value.cleanStartDate,
                }))
              )
            }
          }

          setContractType(contractType)
          queryBizCircle({type: '2', parentCode: data.provinceCode})
          queryBizCircle({type: '3', parentCode: data.cityCode})
          queryBizCircle({type: '4', parentCode: data.districtCode})
          queryEmdDesc(data.customerId)
        })

        queryHistoryList(signId)
      }
    },
    [basicForm, cleanForm, queryBizCircle, queryHistoryList, signId, stewardForm]
  )

  const renderCleanTable = useCallback(
    (dataSource: typeof cleanServiceList, period = false) => {
      return dataSource.length ? (
        <Table
          dataSource={dataSource}
          rowKey={'_id'}
          pagination={false}
          style={{marginBottom: 12, pointerEvents: editClean ? 'auto' : 'none'}}
          columns={[
            {
              title: '服务项目',
              dataIndex: 'serviceTypeList',
              render: (text: string[]) =>
                text
                  .map(value => cleanService.find(item => item.id === value)?.label)
                  ?.filter(item => item)
                  ?.join('、'),
            },
            {
              title: '收费方式',
              dataIndex: 'servicePayWay',
              render: text => servicePayWay.find(value => value.id === text)?.label,
            },
            {
              title: '费用价格',
              dataIndex: 'servicePrice',
              render: (text, record) => `${text}${getPayWayUnit(record.servicePayWay)}`,
            },
            ...(period
              ? [
                  {
                    title: '保洁频次',
                    dataIndex: 'cleanFreq',
                    render: text => cleanFreq.find(value => value.id === text)?.label,
                  },
                  {
                    title: '保洁周期',
                    dataIndex: 'cleanPeriod',
                    render: (text, record) => cleanFreqTime[record.cleanFreq]?.find(value => value.id === text)?.label,
                  },
                  {
                    title: '开始服务日期',
                    dataIndex: 'cleanStartDate',
                    render: text => (text ? moment(text).format('YYYY-MM-DD') : ''),
                  },
                ]
              : []),
            {
              title: '操作',
              dataIndex: '_id',
              render: (text, record) => (
                <Space>
                  <Button
                    type={'link'}
                    onClick={() => {
                      cleanServiceForm.setFieldsValue({...record})
                      setCleanServiceFormVisible(true)
                    }}
                  >
                    编辑
                  </Button>
                  <Button
                    type={'link'}
                    onClick={() => setCleanServiceList(prevState => prevState.filter(value => value._id !== text))}
                  >
                    删除
                  </Button>
                </Space>
              ),
            },
          ]}
        />
      ) : null
    },
    [cleanFreq, cleanService, cleanServiceForm, cleanServiceList, servicePayWay, editClean]
  )

  // 修改保洁服务
  const updateClean = async () => {
    await saleModel.saleCustomerContractUpdateCleanService({
      contractId,
      cleanServiceList: cleanServiceList as ContractCleanServiceDTO[],
    } as SaleCustomerContractCleanDTO)
    message.success('保存成功')
    searchContractDetail()
  }

  return (
    <div className={styles.container}>
      <div className={styles.left}>
        <Form
          form={basicForm}
          labelCol={{flex: '120px'}}
          wrapperCol={{span: 9}}
          initialValues={{provinceCode: '310000'}}
          style={{pointerEvents: 'none'}}
        >
          <Form.Item noStyle shouldUpdate>
            {form1 =>
              form1.getFieldValue('signStatus') === '3' && (
                <>
                  <DividerLine title={'驳回信息'} />
                  {form1.getFieldValue('auditSteward') === '0' && (
                    <>
                      <Form.Item name={'auditStewardName'} label={'驳回人'}>
                        <span>{form1.getFieldValue('auditStewardName')}</span>
                      </Form.Item>
                      <Form.Item name={'auditStewardRemark'} label={'驳回意见'}>
                        <span>{form1.getFieldValue('auditStewardRemark')}</span>
                      </Form.Item>
                    </>
                  )}
                  {form1.getFieldValue('auditClean') === '0' && (
                    <>
                      <Form.Item name={'auditCleanName'} label={'驳回人'}>
                        <span>{form1.getFieldValue('auditCleanName')}</span>
                      </Form.Item>
                      <Form.Item name={'auditCleanRemark'} label={'驳回意见'}>
                        <span>{form1.getFieldValue('auditCleanRemark')}</span>
                      </Form.Item>
                    </>
                  )}
                </>
              )
            }
          </Form.Item>
          <Form.Item noStyle shouldUpdate>
            {f =>
              f.getFieldValue('signStatus') === '7' && (
                <>
                  <DividerLine title={'未充值终止'} bottom={20} />
                  <Descriptions column={1}>
                    <Descriptions.Item label={'操作人'}>{f.getFieldValue('operatorName')}</Descriptions.Item>
                    <Descriptions.Item label={'操作时间'}>{f.getFieldValue('operationTime')}</Descriptions.Item>
                  </Descriptions>
                </>
              )
            }
          </Form.Item>

          {/*客户信息*/}
          <DividerLine top={10} title={'客户信息'} bottom={20} />
          <Form.Item name={'name'} label={'客户姓名'} rules={[{required: true, message: '请输入客户姓名'}]}>
            <Input maxLength={20} placeholder={'请输入'} />
          </Form.Item>
          <Form.Item name={'mobile'} label={'联系电话'} rules={[{required: true, message: '请输入联系电话'}]}>
            <Input maxLength={11} placeholder={'请输入'} />
          </Form.Item>
          <Form.Item label={'区域'} wrapperCol={{}}>
            <Row gutter={12}>
              <Col flex={1}>
                <Form.Item name={'provinceCode'} noStyle required>
                  <Select
                    onChange={(value: string) => {
                      queryBizCircle({type: '2', parentCode: value})
                      basicForm.setFieldsValue({cityCode: undefined, districtCode: undefined, bizCircleCode: undefined})
                    }}
                    allowClear
                    placeholder={'请选择'}
                  >
                    {provinceList.map(item => (
                      <Option key={item.code} value={item.code}>
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col flex={1}>
                <Form.Item name={'cityCode'} noStyle required>
                  <Select
                    placeholder={'请选择'}
                    onChange={(value: string) => {
                      queryBizCircle({type: '3', parentCode: value})
                      basicForm.setFieldsValue({districtCode: undefined, bizCircleCode: undefined})
                    }}
                    allowClear
                  >
                    {cityList.map(item => (
                      <Option key={item.code} value={item.code}>
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col flex={1}>
                <Form.Item name={'districtCode'} noStyle rules={[{required: true, message: '请选择区域'}]}>
                  <Select
                    placeholder={'请选择'}
                    onChange={(value: string) => {
                      queryBizCircle({type: '4', parentCode: value})
                      basicForm.setFieldsValue({bizCircleCode: undefined})
                    }}
                    allowClear
                  >
                    {districtList.map(item => (
                      <Option key={item.code} value={item.code}>
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col flex={1}>
                <Form.Item name={'bizCircleCode'} noStyle rules={[{required: true, message: '请选择商圈'}]}>
                  <Select placeholder={'请选择'} allowClear>
                    {bizCircleList.map(item => (
                      <Option key={item.code} value={item.code}>
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Form.Item>
          <Form.Item name={'address'} label={'地址'}>
            <Input maxLength={50} allowClear placeholder={'请输入'} />
          </Form.Item>
          <Form.Item label={'BD'} shouldUpdate>
            {form1 => form1.getFieldValue('holderName')}
          </Form.Item>
          {emdDesc && (
            <Form.Item label={'维修超量扣款配置'} wrapperCol={{span: 18}}>
              <span>{emdDesc}</span>
            </Form.Item>
          )}

          {/**/}
          <DividerLine title={'合同信息'} bottom={20} />
          {contractId && (
            <Form.Item name={'contractCode'} label={'合同编号'} rules={[{required: true, message: '请输入合同编号'}]}>
              <Input maxLength={50} allowClear placeholder={'请输入'} />
            </Form.Item>
          )}

          <Form.Item name={'firstPartyTitle'} label={'甲方抬头'}>
            <Input maxLength={50} allowClear placeholder={'请输入'} />
          </Form.Item>
          <Form.Item name={'systemFee'} label={'系统收费'} rules={[{required: true, message: '请选择系统收费'}]}>
            <Select placeholder={'请选择'} allowClear>
              {systemFeeArray.map(item => (
                <Option key={item.id} value={item.id}>
                  {item.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item name={'contractDate'} label={'合同周期'} rules={[{required: true, message: '请选择合同时间'}]}>
            <RangePicker style={{width: '100%'}} />
          </Form.Item>
          <Form.Item label={'签约类型'} shouldUpdate={(prev, next) => prev.previewId !== next.previewId}>
            {f => (f.getFieldValue('previewId') ? '续约' : '新签')}
          </Form.Item>

          {/*选哪个下方出哪个*/}
          <Form.Item name={'contractType'} label={'合同类型'} rules={[{required: true, message: '请选择合同类型'}]}>
            <Checkbox.Group onChange={checkedValue => setContractType(checkedValue)}>
              <Checkbox value='A'>管家</Checkbox>
              <Checkbox value='B'>保洁</Checkbox>
            </Checkbox.Group>
          </Form.Item>
          <Form.Item label={'服务费预充'} required>
            <Row gutter={12} align={'middle'}>
              <Col flex={1}>
                <Form.Item name={'stewardPrepaidFee'} noStyle rules={[{required: true, message: '请输入服务费预充'}]}>
                  <InputNumber min={0} max={999999} maxLength={6} placeholder={'请输入'} style={{width: '100%'}} />
                </Form.Item>
              </Col>
              <Col>元</Col>
            </Row>
          </Form.Item>

          <Form.Item label={'最少服务月数'} required>
            <Row gutter={12} align={'middle'}>
              <Col flex={1}>
                <Form.Item name={'leastMonth'} noStyle>
                  <Select options={months} allowClear />
                </Form.Item>
              </Col>
              <Col>月</Col>
            </Row>
          </Form.Item>
          <Form.Item label={'签订地区'} name={'signArea'} required>
            <Input allowClear />
          </Form.Item>
          <Form.Item label={'甲方抬头'} name={'firstPartyTitle'}>
            <Input allowClear />
          </Form.Item>
          <Form.Item label={'通讯地址'} name={'mailAddress'}>
            <Input allowClear />
          </Form.Item>
          <Form.Item label={'邮编'} name={'zipcode'}>
            <Input allowClear />
          </Form.Item>
          <Form.Item label={'微信号'} name={'wechatCode'}>
            <Input allowClear />
          </Form.Item>
          <Form.Item label={'合同版本'} name={'contractVersion'} required>
            <Select allowClear>
              {saleContractVersion.map(value => (
                <Select.Option key={value.id} value={value.id}>
                  {value.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label={'附件'}
            name={'attachList'}
            valuePropName={'fileList'}
            style={{pointerEvents: 'auto'}}
            getValueFromEvent={e => {
              return e?.fileList?.map(value => ({
                ...value,
                attachFileName: value.attachFileName ?? value.response?.data?.filename,
                attachUrl: value.attachUrl ?? value.response?.data?.imgUrl,
                url: value.url ?? (value.response?.data?.imgUrl ? getImg(value.response?.data?.imgUrl) : undefined),
              }))
            }}
            getValueProps={value => ({
              fileList: value?.map(item =>
                item.attachUrl
                  ? {
                      uid: item.attachUrl,
                      status: 'done',
                      ...item,
                      name: item.attachFileName,
                      url: getImg(item.attachUrl),
                    }
                  : item
              ),
            })}
          >
            <Upload
              name='file'
              disabled
              multiple
              headers={createHeaders()}
              action={baseUrl + '/sale/customer/contract/file/upload'}
            >
              <Button icon={<UploadOutlined />} disabled>
                上传
              </Button>
            </Upload>
          </Form.Item>
          <Form.Item name={'remark'} label={'备注信息'}>
            <Input.TextArea
              allowClear
              autoSize={{minRows: 4, maxRows: 10}}
              maxLength={200}
              placeholder={'请输入备注信息'}
            />
          </Form.Item>
        </Form>

        <Form
          form={stewardForm}
          name={'stewardForm'}
          labelCol={{flex: '120px'}}
          wrapperCol={{span: 9}}
          initialValues={{autoBaseBill: '0'}}
          style={{
            width: '80%',
            pointerEvents: 'none',
            display: contractType.includes('A') ? '' : 'none',
          }}
        >
          {/**/}
          <DividerLine title={'管家服务'} bottom={20} />
          <Form.Item label={'管家服务'} name={'stewardFeeType'} rules={[{required: true, message: '请选择管家服务'}]}>
            <Select placeholder={'请选择'} style={{width: '100%'}} allowClear>
              {stewardFeeType.map(item => (
                <Option key={item.id} value={item.id}>
                  {item.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, nextValues) => prevValues.stewardFeeType !== nextValues.stewardFeeType}
          >
            {form1 =>
              // 按此计费只显示管家服务
              form1.getFieldValue('stewardFeeType') !== '2' && (
                <>
                  <Form.Item
                    label={'首月付款日期'}
                    name={'firstPayDate'}
                    rules={[{required: true, message: '请选择首月付款日期'}]}
                  >
                    <DatePicker style={{width: '100%'}} />
                  </Form.Item>
                  <Form.Item label={'付款日期'} required>
                    <Row gutter={12} align={'middle'}>
                      <Col>每月</Col>
                      <Col flex={1}>
                        <Form.Item name={'payDateNum'} noStyle rules={[{required: true, message: '请选择付款日期'}]}>
                          <Select placeholder={'请选择'} allowClear style={{width: '100%'}}>
                            {dateList.map(item => (
                              <Option key={item} value={item}>
                                {item}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col>日</Col>
                    </Row>
                  </Form.Item>
                  <Form.Item label={'房源核算日'} required>
                    <Row gutter={12} align={'middle'}>
                      <Col>每月</Col>
                      <Col flex={1}>
                        <Form.Item
                          name={'propertyCountDateNum'}
                          noStyle
                          rules={[{required: true, message: '请选择房源核算日'}]}
                        >
                          <Select placeholder={'请选择'} allowClear style={{width: '100%'}}>
                            {dateList.map(item => (
                              <Option key={item} value={item}>
                                {item}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col>日</Col>
                    </Row>
                  </Form.Item>
                  <Form.Item label={'基础服务账单'} shouldUpdate>
                    {() => (
                      <Checkbox
                        checked={stewardForm.getFieldValue('autoBaseBill') === '1'}
                        onChange={e => {
                          stewardForm.setFieldsValue({autoBaseBill: e.target.checked ? '1' : '0'})
                        }}
                      >
                        自动生成
                      </Checkbox>
                    )}
                  </Form.Item>
                  <Form.Item
                    name={'propertyNumInit'}
                    label={'初始房源'}
                    rules={[{required: true, message: '请输入初始房源'}]}
                  >
                    <InputNumber
                      min={0}
                      max={99999}
                      maxLength={5}
                      placeholder={'请输入（间）'}
                      style={{width: '100%'}}
                    />
                  </Form.Item>
                  <Form.Item
                    name={'propertyNumMin'}
                    label={'保底房源'}
                    rules={[{required: true, message: '请输入保底房源'}]}
                  >
                    <InputNumber
                      min={0}
                      max={99999}
                      maxLength={5}
                      placeholder={'请输入（间）'}
                      style={{width: '100%'}}
                    />
                  </Form.Item>
                  <Form.Item label={'首期款项'} required>
                    <Space>
                      <Form.Item name={'firstFee'} noStyle rules={[{required: true, message: '请输入首期款项'}]}>
                        <InputNumber
                          min={0}
                          max={999999}
                          maxLength={6}
                          placeholder={'请输入'}
                          style={{width: '100%'}}
                        />
                      </Form.Item>
                      <div style={{display: 'flex', height: '30px', alignItems: 'center'}}>
                        <span>元</span>
                      </div>
                    </Space>
                  </Form.Item>
                  <Form.Item label={'甲方违约金'} required>
                    <Space>
                      <Form.Item
                        name={'firstPartyBreak'}
                        noStyle
                        rules={[{required: true, message: '请选择甲方违约金'}]}
                      >
                        <Select placeholder={'请选择'} allowClear>
                          {firstPartyBreakArray.map(item => (
                            <Option key={item.id} value={item.id}>
                              {item.label}
                            </Option>
                          ))}
                        </Select>
                      </Form.Item>
                      <Form.Item noStyle shouldUpdate>
                        {() =>
                          stewardForm.getFieldValue('firstPartyBreak') ? (
                            <Space>
                              <Form.Item
                                noStyle
                                name={'firstPartyBreakFee'}
                                rules={[{required: true, message: '请输入'}]}
                              >
                                <InputNumber
                                  min={0}
                                  max={9999}
                                  maxLength={4}
                                  placeholder={'请输入'}
                                  style={{width: '100%'}}
                                />
                              </Form.Item>
                              <div style={{display: 'flex', height: '30px', alignItems: 'center'}}>
                                {stewardForm.getFieldValue('firstPartyBreak') === '1' ? (
                                  <span>元/间</span>
                                ) : (
                                  <span>元</span>
                                )}
                              </div>
                            </Space>
                          ) : null
                        }
                      </Form.Item>
                    </Space>
                  </Form.Item>
                  <Form.Item label={'管家服务价'} required style={{marginBottom: 0}}>
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                      <Space>
                        <div style={{width: 80, marginTop: -23}}>
                          <Typography.Text>集中式房源</Typography.Text>
                        </div>
                        <Form.Item name={'stewardPriceType3'} rules={[{required: true, message: '请选择管家服务价'}]}>
                          <Select placeholder={'请选择'} allowClear style={{minWidth: 112}}>
                            {stewardPriceTypeArray.map(item => (
                              <Option key={item.id} value={item.id}>
                                {item.label}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                        <Form.Item shouldUpdate>
                          {() =>
                            stewardForm.getFieldValue('stewardPriceType3') === '1' ? (
                              <Space>
                                <Form.Item noStyle name={'stewardPrice3'} rules={[{required: true, message: '请输入'}]}>
                                  <InputNumber
                                    min={0}
                                    max={99999}
                                    maxLength={5}
                                    placeholder={'请输入'}
                                    style={{minWidth: 150}}
                                  />
                                </Form.Item>
                                <div style={{width: '100px'}}>
                                  <span>元/间/月</span>
                                </div>
                              </Space>
                            ) : stewardForm.getFieldValue('stewardPriceType3') === '2' ? (
                              <Space>
                                <Form.Item noStyle name={'stewardPrice3'} rules={[{required: true, message: '请输入'}]}>
                                  <InputNumber
                                    min={0}
                                    max={100}
                                    maxLength={3}
                                    placeholder={'请输入'}
                                    style={{minWidth: 150}}
                                  />
                                </Form.Item>
                                <div style={{width: '100px'}}>
                                  <span>% 月租金/间/月</span>
                                </div>
                              </Space>
                            ) : null
                          }
                        </Form.Item>
                      </Space>
                      <Space>
                        <div style={{width: 80, marginTop: -23}}>
                          <Typography.Text>分散式整租</Typography.Text>
                        </div>
                        <Form.Item name={'stewardPriceType'} rules={[{required: true, message: '请选择管家服务价'}]}>
                          <Select placeholder={'请选择'} allowClear style={{minWidth: 112}}>
                            {stewardPriceTypeArray.map(item => (
                              <Option key={item.id} value={item.id}>
                                {item.label}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                        <Form.Item shouldUpdate>
                          {() =>
                            stewardForm.getFieldValue('stewardPriceType') === '1' ? (
                              <Space>
                                <Form.Item noStyle name={'stewardPrice'} rules={[{required: true, message: '请输入'}]}>
                                  <InputNumber
                                    min={0}
                                    max={99999}
                                    maxLength={5}
                                    placeholder={'请输入'}
                                    style={{minWidth: 150}}
                                  />
                                </Form.Item>
                                <div style={{width: '100px'}}>
                                  <span>元/间/月</span>
                                </div>
                              </Space>
                            ) : stewardForm.getFieldValue('stewardPriceType') === '2' ? (
                              <Space>
                                <Form.Item noStyle name={'stewardPrice'} rules={[{required: true, message: '请输入'}]}>
                                  <InputNumber
                                    min={0}
                                    max={100}
                                    maxLength={3}
                                    placeholder={'请输入'}
                                    style={{minWidth: 150}}
                                  />
                                </Form.Item>
                                <div style={{width: '100px'}}>
                                  <span>% 月租金/间/月</span>
                                </div>
                              </Space>
                            ) : null
                          }
                        </Form.Item>
                      </Space>
                      <Space>
                        <div style={{width: 80, marginTop: -23}}>
                          <Typography.Text>分散式合租</Typography.Text>
                        </div>
                        <Form.Item name={'stewardPriceType2'} rules={[{required: true, message: '请选择管家服务价'}]}>
                          <Select placeholder={'请选择'} allowClear style={{minWidth: 112}}>
                            {stewardPriceTypeArray.map(item => (
                              <Option key={item.id} value={item.id}>
                                {item.label}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                        <Form.Item shouldUpdate>
                          {() =>
                            stewardForm.getFieldValue('stewardPriceType2') === '1' ? (
                              <Space>
                                <Form.Item noStyle name={'stewardPrice2'} rules={[{required: true, message: '请输入'}]}>
                                  <InputNumber
                                    min={0}
                                    max={99999}
                                    maxLength={5}
                                    placeholder={'请输入'}
                                    style={{minWidth: 150}}
                                  />
                                </Form.Item>
                                <div style={{width: '100px'}}>
                                  <span>元/间/月</span>
                                </div>
                              </Space>
                            ) : stewardForm.getFieldValue('stewardPriceType2') === '2' ? (
                              <Space>
                                <Form.Item noStyle name={'stewardPrice2'} rules={[{required: true, message: '请输入'}]}>
                                  <InputNumber
                                    min={0}
                                    max={100}
                                    maxLength={3}
                                    placeholder={'请输入'}
                                    style={{minWidth: 150}}
                                  />
                                </Form.Item>
                                <div style={{width: '100px'}}>
                                  <span>% 月租金/间/月</span>
                                </div>
                              </Space>
                            ) : null
                          }
                        </Form.Item>
                      </Space>
                    </div>
                  </Form.Item>
                  <Form.Item
                    label={'维修耗材'}
                    name={'materialInclusive'}
                    rules={[{required: true, message: '请选择维修耗材'}]}
                  >
                    <Select placeholder={'请选择'} allowClear>
                      {materialSourceArr.map(item => (
                        <Option key={item.id} value={item.id}>
                          {item.label}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item noStyle shouldUpdate>
                    {() =>
                      stewardForm.getFieldValue('materialInclusive') === '1' && (
                        <Form.Item label={'耗材上限'} required>
                          <Space>
                            <div style={{display: 'flex', height: '30px', alignItems: 'center'}}>
                              <span>单件</span>
                            </div>
                            <Form.Item
                              noStyle
                              name={'materialUnitPrice'}
                              rules={[{required: true, message: '请输入耗材上限'}]}
                            >
                              <InputNumber
                                min={0}
                                max={999}
                                maxLength={3}
                                placeholder={'请输入'}
                                style={{width: '100%'}}
                              />
                            </Form.Item>
                            <div style={{display: 'flex', height: '30px', alignItems: 'center'}}>
                              <span>元</span>
                            </div>
                          </Space>
                        </Form.Item>
                      )
                    }
                  </Form.Item>
                  <Form.Item noStyle shouldUpdate>
                    {form1 =>
                      form1.getFieldValue('materialInclusive') === '2' && (
                        <Form.Item
                          label={'耗材领取地址'}
                          name={'materialAddress'}
                          rules={[{required: true, message: '请输入耗材领取地址'}]}
                        >
                          <Input placeholder={'请输入耗材领取地址'} allowClear />
                        </Form.Item>
                      )
                    }
                  </Form.Item>
                  <Form.Item label={'管家服务类型'}>
                    <AddButton onClick={() => setStewardServiceFormVisible(true)} />
                  </Form.Item>
                  {!!stewardServiceList.length && (
                    <Table
                      dataSource={stewardServiceList}
                      rowKey={'_id'}
                      pagination={false}
                      style={{marginBottom: 12}}
                      columns={[
                        {title: '服务项目', dataIndex: 'serviceDesc', key: 'serviceDesc'},
                        {
                          title: '收费方式',
                          dataIndex: 'servicePayWay',
                          key: 'servicePayWay',
                          render: text => servicePayWay.find(value => value.id === text)?.label,
                        },
                        {title: '费用价格', dataIndex: 'servicePrice', key: 'servicePrice'},
                        {
                          title: '操作',
                          dataIndex: '_id',
                          key: '_id',
                          render: (text, record) => (
                            <Space>
                              <Button
                                type={'link'}
                                onClick={() => {
                                  stewardServiceForm.setFieldsValue({...record})
                                  setStewardServiceFormVisible(true)
                                }}
                              >
                                编辑
                              </Button>
                              <Button
                                type={'link'}
                                onClick={() =>
                                  setStewardServiceList(prevState => prevState.filter(value => value._id !== text))
                                }
                              >
                                删除
                              </Button>
                            </Space>
                          ),
                        },
                      ]}
                    />
                  )}
                </>
              )
            }
          </Form.Item>
        </Form>

        <Form
          form={cleanForm}
          labelCol={{flex: '120px'}}
          wrapperCol={{span: 9}}
          style={{
            width: '80%',
            // pointerEvents: viewOnly ? 'none' : 'auto',
            display: contractType.includes('B') ? '' : 'none',
          }}
        >
          <DividerLine title={'保洁服务'} bottom={20} />
          <Form.Item label={'保洁服务'}>
            <span>{cleanFeeTypeCode[basicForm.getFieldValue('cleanFeeType')]}</span>
          </Form.Item>
          {editClean && (
            <Form.Item label={'保洁服务类型'}>
              <Space size={20}>
                <AddButton onClick={() => setCleanServiceFormVisible(true)} />
                <Button
                  type='primary'
                  // loading={subLoading}
                  onClick={updateClean}
                >
                  保存
                </Button>
              </Space>
            </Form.Item>
          )}

          {renderCleanTable(cleanNoPeriodServiceList)}
          {renderCleanTable(cleanPeriodServiceList, true)}
          <Form.Item
            label={'其他保洁收费'}
            name={'cleanOtherFee'}
            rules={[{required: true, message: '请选择其他保洁收费'}]}
            style={{pointerEvents: 'none'}}
          >
            <Radio.Group>
              {cleanOtherFee.map(value => (
                <Radio key={value.id} value={value.id}>
                  {value.label}
                </Radio>
              ))}
            </Radio.Group>
          </Form.Item>
          <Form.Item style={{pointerEvents: 'none'}} label={'保洁说明'} name={'cleanIntro'}>
            <Input.TextArea placeholder={'请输入'} allowClear />
          </Form.Item>
        </Form>
        <Form
          labelCol={{flex: '120px'}}
          wrapperCol={{span: 9}}
          style={{
            width: '80%',
          }}
        >
          <Form.Item label={' '} colon={false}>
            <Space>
              <Button style={{pointerEvents: 'auto'}} onClick={() => history.goBack()}>
                返回
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </div>

      {contractId || signId ? (
        <div className={styles.right}>
          <DividerLine title={'合同记录'} bottom={20} />
          {
            <Timeline>
              {historyList.map((item, index) => {
                return (
                  <Timeline.Item key={index} dot={<Dot active={index === 0} />}>
                    <div>
                      <Typography.Text style={{color: '#222832', fontWeight: 600}}>
                        {item.label}
                        {item.subLabel}
                      </Typography.Text>
                    </div>
                    <div>
                      <Typography.Text
                        style={{marginTop: '10px', color: '#909399'}}
                      >{`操作人：${item.operatorName}`}</Typography.Text>
                    </div>
                    <div>
                      <Typography.Text style={{marginTop: '10px', color: '#909399'}}>{item.insertTime}</Typography.Text>
                    </div>
                  </Timeline.Item>
                )
              })}
            </Timeline>
          }
        </div>
      ) : null}

      <Modal
        title={stewardServiceForm.getFieldValue('_id') ? '编辑管家服务' : '新增管家服务'}
        visible={stewardServiceFormVisible}
        width={680}
        destroyOnClose
        onCancel={() => setStewardServiceFormVisible(false)}
        afterClose={() => stewardServiceForm.resetFields()}
        onOk={() => stewardServiceForm.submit()}
      >
        <Form
          form={stewardServiceForm}
          labelCol={{flex: '100px'}}
          wrapperCol={{span: 17}}
          onFinish={() => {
            const data: typeof stewardServiceList[number] = stewardServiceForm.getFieldsValue(true)
            if (data._id) {
              setStewardServiceList(prevState => prevState.map(value => (value._id === data._id ? data : value)))
            } else {
              setStewardServiceList(prevState => [...prevState, {_id: getUUID(), ...data}])
            }
            setStewardServiceFormVisible(false)
          }}
        >
          <Form.Item label={'服务项目'} name={'serviceDesc'} rules={[{required: true, message: '请输入服务项目'}]}>
            <Input allowClear />
          </Form.Item>
          <Form.Item label={'收费方式'} name={'servicePayWay'} rules={[{required: true, message: '请选择收费方式'}]}>
            <Select allowClear>
              {servicePayWay.map(value => (
                <Select.Option key={value.id} value={value.id}>
                  {value.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label={'费用价格'} shouldUpdate required>
            {form1 => (
              <Row gutter={20} align={'middle'}>
                <Col flex={'auto'}>
                  <Form.Item noStyle name={'servicePrice'} rules={[{required: true, message: '请输入费用价格'}]}>
                    <InputNumber min={0} style={{width: '100%'}} />
                  </Form.Item>
                </Col>
                <Col flex={'80px'}>{getPayWayUnit(form1.getFieldValue('servicePayWay'))}</Col>
              </Row>
            )}
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title={cleanServiceForm.getFieldValue('_id') ? '编辑保洁服务' : '新增保洁服务'}
        visible={cleanServiceFormVisible}
        width={680}
        destroyOnClose
        onCancel={() => setCleanServiceFormVisible(false)}
        afterClose={() => cleanServiceForm.resetFields()}
        onOk={() => cleanServiceForm.submit()}
      >
        <Form
          form={cleanServiceForm}
          initialValues={{serviceTypeList: []}}
          labelCol={{flex: '100px'}}
          wrapperCol={{span: 17}}
          onFinish={() => {
            const data: typeof cleanServiceList[number] = cleanServiceForm.getFieldsValue(true)
            if (data._id) {
              setCleanServiceList(prevState => prevState.map(value => (value._id === data._id ? data : value)))
            } else {
              setCleanServiceList(prevState => [...prevState, {_id: getUUID(), ...data}])
            }
            setCleanServiceFormVisible(false)
          }}
        >
          <Form.Item label={'服务项目'} shouldUpdate>
            {() => {
              const serviceTypeList: string[] = cleanServiceForm.getFieldValue('serviceTypeList')
              return (
                <Form.Item noStyle name={'serviceTypeList'}>
                  <Select mode='multiple' allowClear showArrow showSearch={false}>
                    {cleanService.map(value => (
                      <Select.Option
                        key={value.id}
                        value={value.id}
                        disabled={
                          // 已选过的服务不能再选
                          cleanServiceList?.some(item => item.serviceTypeList?.includes(value.id)) ||
                          // 选周期服务不能选非周期服务
                          // 或选非周期服务则不能选周期服务
                          (!!serviceTypeList?.length && value.id === '6' && !serviceTypeList?.includes('6')) ||
                          (value.id !== '6' && serviceTypeList?.includes('6'))
                        }
                      >
                        {value.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              )
            }}
          </Form.Item>
          <Form.Item label={'收费方式'} name={'servicePayWay'} rules={[{required: true, message: '请选择收费方式'}]}>
            <Select allowClear>
              {servicePayWay.map(value => (
                <Select.Option key={value.id} value={value.id}>
                  {value.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label={'费用价格'} shouldUpdate>
            {form1 => (
              <Row gutter={20} align={'middle'}>
                <Col flex={'auto'}>
                  <Form.Item noStyle name={'servicePrice'}>
                    <InputNumber min={0} style={{width: '100%'}} />
                  </Form.Item>
                </Col>
                <Col flex={'80px'}>{getPayWayUnit(form1.getFieldValue('servicePayWay'))}</Col>
              </Row>
            )}
          </Form.Item>
          <Form.Item noStyle shouldUpdate>
            {form1 =>
              form1.getFieldValue('serviceTypeList')?.includes('6') ? (
                <>
                  <Form.Item label={'保洁频次'} name={'cleanFreq'}>
                    <Select allowClear onChange={() => form1.setFieldsValue({cleanPeriod: ''})}>
                      {cleanFreq.map(value => (
                        <Select.Option key={value.id} value={value.id}>
                          {value.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item label={'保洁周期'} name={'cleanPeriod'}>
                    <Select allowClear>
                      {cleanFreqTime[form1.getFieldValue('cleanFreq')]?.map(value => (
                        <Select.Option key={value.id} value={value.id}>
                          {value.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                  <Form.Item label={'开始服务日期'} name={'cleanStartDate'}>
                    <DatePicker />
                  </Form.Item>
                </>
              ) : null
            }
          </Form.Item>
        </Form>
      </Modal>
    </div>
  )
}

const AddButton: React.FC<{onClick: () => void}> = props => (
  <div {...props}>
    <Space style={{color: '#00B7AE', cursor: 'pointer'}}>
      <PlusCircleOutlined />
      <span>添加</span>
    </Space>
  </div>
)

const Dot = React.memo<{active?: boolean}>(props => (
  <div
    style={{
      display: 'inline-block',
      width: 16,
      height: 16,
      borderRadius: 16 / 2,
      backgroundColor: props.active ? '#00B7AE' : '#D8D8D8',
    }}
  />
))

const getPayWayUnit = (value: string) => (value === '2' ? '元/月' : value === '3' ? '元/次' : '')
