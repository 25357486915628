import React, {useEffect} from 'react'
import {Button, Form, Input, PageHeader, Space} from 'antd'
import commonStyles from '@/assets/styles/common.module.less'
import DividerLine from '@/pages/property/component/DividerLine'
import {Album, UploadButton} from '@/components/Album'
import {baseUrl} from '@/common/config'
import {getImg} from '@/utils/util'
import {useAppHistory, useAppLocation} from '@/common/hooks/useRouter'
import {condoModel} from '@/store/models/Condo'
import {Page} from '@/components/Page'

export default function AddFinanceCompany() {
  const location = useAppLocation<'/finance-company/add'>()
  const history = useAppHistory()
  const uniCredit = location.state?.uniCredit

  const [form] = Form.useForm()

  useEffect(() => {
    if (uniCredit) {
      condoModel.condoProjectCompanyInfo({uniCredit}).then(({response: {data}}) => {
        form.setFieldsValue({...data})
      })
    }
  }, [form, uniCredit])

  return (
    <Page header={<PageHeader ghost={false} title={'新增财务公司'} onBack={() => history.goBack()} />}>
      <div className={commonStyles.filterSection}>
        <Form
          form={form}
          labelCol={{span: 3}}
          wrapperCol={{span: 7}}
          onFinish={async () => {
            const formValues = form.getFieldsValue(true)
            if (uniCredit) {
              await condoModel.condoProjectCompanyUpdate(formValues)
            } else {
              await condoModel.condoProjectCompanyInsert(formValues)
            }
            history.goBack()
          }}
        >
          <DividerLine title='公司信息' style={{paddingBottom: 30}} />
          <Form.Item name={'companyName'} label={'企业名称'} rules={[{required: true, message: '请输入企业名称'}]}>
            <Input placeholder='请输入' maxLength={30} allowClear disabled={!!uniCredit} />
          </Form.Item>
          <Form.Item
            name={'uniCredit'}
            label={'统一社会信用代码'}
            rules={[
              {
                required: true,
                message: '请输入统一社会信用代码',
                validator(_, value, callback) {
                  if (value && value.length === 18) return callback()
                  return callback('请输入18位的统一社会信用代码')
                },
              },
            ]}
          >
            <Input placeholder='请输入' disabled={!!uniCredit} allowClear />
          </Form.Item>
          <Form.Item name={'legalName'} label={'法人姓名'} rules={[{required: true, message: '请输入法人姓名'}]}>
            <Input placeholder='请输入' maxLength={10} allowClear />
          </Form.Item>
          <Form.Item name={'legalIds'} label={'法人身份证号码'}>
            <Input placeholder='请输入' allowClear />
          </Form.Item>
          <Form.Item
            name={'legalPhone'}
            label={'联系电话'}
            rules={[
              {
                validator(_, value) {
                  if (!value || /^1[0-9]{10}$/.test(value)) return Promise.resolve()
                  return Promise.reject(new Error('请输入11位的手机号码'))
                },
              },
            ]}
          >
            <Input placeholder='请输入' maxLength={11} allowClear />
          </Form.Item>
          <Form.Item label={'营业执照'} shouldUpdate>
            {f => {
              const pictureUrl1 = f.getFieldValue('pictureUrl1')
              return (
                <Album
                  limit={1}
                  fileList={pictureUrl1 ? [{url: getImg(pictureUrl1), uid: pictureUrl1}] : []}
                  action={baseUrl + '/condo/project/company/upload'}
                  onChange={info => {
                    if (info.file.status === 'done') {
                      const {
                        response: {data},
                      } = info.file
                      form.setFieldsValue({pictureUrl1: data.imgUrl})
                    }
                    if (info.file.status === 'removed') {
                      form.setFieldsValue({pictureUrl1: ''})
                    }
                  }}
                >
                  {pictureUrl1 ? null : <UploadButton />}
                </Album>
              )
            }}
          </Form.Item>
          <Form.Item label={'法人身份证照片'}>
            <div style={{display: 'flex', flexDirection: 'row'}}>
              {[
                {title: '正面', keys: 'pictureUrl8'},
                {title: '反面', keys: 'pictureUrl9'},
              ].map((item, index) => {
                const {title, keys} = item
                return (
                  <Form.Item key={index} shouldUpdate>
                    {f => {
                      const imgUrl = f.getFieldValue(keys)
                      return (
                        <Album
                          limit={1}
                          fileList={imgUrl ? [{url: getImg(imgUrl), uid: imgUrl}] : []}
                          action={baseUrl + '/condo/project/company/upload'}
                          onChange={info => {
                            if (info.file.status === 'done') {
                              const {
                                response: {data},
                              } = info.file
                              form.setFieldsValue({[keys]: data.imgUrl})
                            }
                            if (info.file.status === 'removed') {
                              form.setFieldsValue({[keys]: ''})
                            }
                          }}
                        >
                          {imgUrl ? null : <UploadButton title={title} />}
                        </Album>
                      )
                    }}
                  </Form.Item>
                )
              })}
            </div>
          </Form.Item>
          <DividerLine title='对公账户信息' style={{paddingBottom: 30}} />
          <Form.Item
            name={'accountNo'}
            label={'账户账号'}
            rules={[
              {
                validator: (rule: any, value: any, callback: Function) => {
                  if (value && value.length > 20) {
                    callback('仅限输入20位数字')
                    return
                  }
                  callback()
                },
              },
            ]}
          >
            <Input placeholder='请输入' allowClear />
          </Form.Item>
          <Form.Item
            label={'开户银行名称'}
            name={'parentBankName'}
            rules={[
              {
                validator: (rule: any, value: any, callback: Function) => {
                  if (value && value.length > 20) {
                    callback('仅限输入20位文字信息')
                    return
                  }
                  callback()
                },
              },
            ]}
          >
            <Input placeholder='请输入' allowClear />
          </Form.Item>
          <Form.Item
            label={'开户银行支行名称'}
            name={'bankName'}
            rules={[
              {
                validator: (rule: any, value: any, callback: Function) => {
                  if (value && value.length > 20) {
                    callback('仅限输入20位文字信息')
                    return
                  }
                  callback()
                },
              },
            ]}
          >
            <Input placeholder='请输入' allowClear />
          </Form.Item>
          <Form.Item
            label={'开户银行支行行号'}
            name={'unionBank'}
            rules={[
              {
                validator: (rule: any, value: any, callback: Function) => {
                  if (value && value?.length > 12) {
                    callback('仅限输入12位数字')
                    return
                  }
                  callback()
                },
              },
            ]}
          >
            <Input placeholder='请输入' allowClear />
          </Form.Item>
          <Form.Item label={' '} colon={false}>
            <Space>
              <Button type={'primary'} htmlType={'submit'}>
                保存
              </Button>
              <Button onClick={() => history.goBack()}>取消</Button>
            </Space>
          </Form.Item>
        </Form>
      </div>
    </Page>
  )
}
