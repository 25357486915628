import React, {useCallback, useEffect, useState} from 'react'
import commonStyles from '@/assets/styles/common.module.less'
import {
  Col,
  Form,
  Input,
  Row,
  DatePicker,
  Space,
  Button,
  Table,
  Typography,
  Modal,
  Select,
  message,
  InputNumber,
  TreeSelect,
  Tooltip,
  FormInstance,
} from 'antd'
import {stewardModel} from '@/store/models/Steward'
import {Permission} from '@/components/Permission'
import {getPaginationProps} from '@/common/biz/getPaginationProps'
import {useFocusEffect} from '@/common/hooks/useFocusEffect'
import {storageModel} from '@/store/models/Storage'
import {useAppHistory} from '@/common/hooks/useRouter'
import {commonModel, useCodeArraySync, useCodeSync} from '@/store/models/Common'
import {sysModel} from '@/store/models/Sys'
import {useDebounce} from '@/common/hooks/useDebounce'
import moment from 'moment'
import {Ellipsis} from '@/components/Ellipsis'
import currency from 'currency.js'

const {Option} = Select

export default function StewardUserList(props: {form: FormInstance}) {
  const history = useAppHistory()
  const [total, setTotal] = useState(0)
  const [list, setList] = useState([] as StewardUserVO[])
  const form = props.form

  const loading = stewardModel.stewardUserList.useLoading()
  const [stewardList, setStewardList] = useState<StewardUserVO[]>([])
  const [addMaster, setAddMaster] = useState({visible: false, userId: ''})
  const [masterForm] = Form.useForm()
  const [warehouseList, setWareHouseList] = useState<StewardWarehouseListItemVO[]>([])
  const [openList, setOpenList] = useState<StewardOpenAppVO[]>([])
  const positionLevelArr = useCodeArraySync('sys.user.steward.position.level')
  const positionLevel = useCodeSync('sys.user.steward.position.level')
  const [updateOpen, setUpdateOpen] = useState(false)
  const [updateForm] = Form.useForm()
  const [tree, setTree] = useState<SysOrgV2VO[]>([])

  const queryMasterList = useCallback(() => {
    stewardModel.stewardUserList({pageNum: 1, pageSize: 20, pageable: false}).then(({response: {data}}) => {
      setStewardList(data)
    })
  }, [])

  const queryList = useCallback(() => {
    const values = form.getFieldsValue(true)
    stewardModel.stewardUserList(values).then(({response: {data, total}}) => {
      setList(data)
      setTotal(total)
    })
  }, [form])

  useFocusEffect(() => {
    form.submit()
  })

  useEffect(() => {
    form.submit()
  }, [form])

  // 查询站点
  const searchWarehouseList = useDebounce(
    useCallback((name: string) => {
      storageModel
        .stewardWarehouseList({pageNum: 1, pageable: false, pageSize: 1, name} as StewardWarehouseListDTO)
        .then(({response: {data}}) => {
          setWareHouseList(data)
        })
    }, []),
    800
  )

  useEffect(() => {
    stewardModel
      .stewardOpenAppList({
        pageable: false,
        pageNum: 1,
        pageSize: 1,
        appStatus: 1,
      } as StewardAppOpenListDTO)
      .then(({response: {data}}) => {
        setOpenList(data)
      })

    // 全部运营组织
    sysModel.sysOrgV2Tree({allChildren: true, orgId: 'ROOT'} as SysOrgV2ListDTO).then(({response: {data}}) => {
      setTree(data)
    })
  }, [])

  const [cityCodes, setCityCodes] = useState<CityDataProps[]>([])
  useEffect(() => {
    commonModel.queryCityCode({type: '2', parentCode: ''}).then(({response: {data}}) => setCityCodes(data))
  }, [])

  function isNumber(value: any) {
    return typeof +value === 'number' && !isNaN(+value)
  }

  function updateAllowance() {
    const {
      probationSalary,
      probationBaseSalary,
      probationPerformance,
      salary,
      baseSalary,
      performance,
    } = updateForm.getFieldsValue()
    if (isNumber(probationSalary) && isNumber(probationBaseSalary) && isNumber(probationPerformance)) {
      updateForm.setFieldValue(
        'probationAllowance',
        currency(probationSalary).subtract(probationBaseSalary).subtract(probationPerformance).value
      )
    }
    if (isNumber(salary) && isNumber(baseSalary) && isNumber(performance)) {
      updateForm.setFieldValue('allowance', currency(salary).subtract(baseSalary).subtract(performance).value)
    }
  }

  return (
    <>
      <div className={commonStyles.filterSection}>
        <Form
          form={form}
          labelCol={{flex: '96px'}}
          initialValues={{pageNum: 1, pageSize: 10, pageable: true, status: '1'}}
          onFinish={queryList}
          onReset={queryList}
        >
          <Row gutter={20}>
            <Col span={8}>
              <Form.Item label={'管家名称'} name={'userName'}>
                <Input placeholder={'管家名称或手机号'} allowClear />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={'虚拟号码'} name={'privateNum'}>
                <Input placeholder={'请输入虚拟号码'} allowClear />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={'师傅'} name={'teacherName'}>
                <Input placeholder='请输入' allowClear />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={'所属站点'} name={'warehouseIdList'}>
                <Select
                  options={warehouseList}
                  fieldNames={{label: 'name', value: 'warehouseId'}}
                  placeholder={'请输入查询选择'}
                  allowClear
                  showSearch
                  onSearch={searchWarehouseList}
                  mode={'multiple'}
                  filterOption={false}
                  maxTagCount={2}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={'录入时间'} name={'_time'}>
                <DatePicker.RangePicker
                  showTime
                  onChange={(_, formatString) => {
                    form.setFieldsValue({
                      insertTimeBegin: formatString?.[0],
                      insertTimeEnd: formatString?.[1],
                    })
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={'岗位等级'} name={'positionLevel'}>
                <Select options={positionLevelArr} fieldNames={{value: 'id'}} placeholder={'请选择'} allowClear />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={'服务标签'} name={'appIdList'}>
                <Select
                  options={openList}
                  fieldNames={{label: 'appName', value: 'appId'}}
                  mode={'multiple'}
                  allowClear
                  placeholder={'请选择'}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={'运营组织'} name={'orgId'}>
                <TreeSelect
                  placeholder={'请选择'}
                  allowClear
                  treeData={tree}
                  fieldNames={{value: 'orgId', label: 'orgName', children: 'children'}}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={'虚拟号码'} name={'privateNumFlag'}>
                <Select
                  options={[
                    {value: '1', label: '已配置'},
                    {value: '0', label: '未配置'},
                  ]}
                  placeholder={'请选择'}
                  allowClear
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={'状态'} name={'status'} rules={[{required: true}]}>
                <Select
                  options={[
                    {value: '1', label: '正常'},
                    {value: '0', label: '禁用'},
                  ]}
                  placeholder={'请选择'}
                  allowClear
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label={'入职日期'}
                name={'entryTimeBegin'}
                getValueFromEvent={(_, dateStrings) => {
                  form.setFieldsValue({entryTimeEnd: dateStrings?.[1]})
                  return dateStrings?.[0]
                }}
                getValueProps={value => ({
                  value: value ? [moment(value), moment(form.getFieldValue('entryTimeEnd'))] : value,
                })}
              >
                <DatePicker.RangePicker />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label={'转正日期'}
                name={'regularDateBegin'}
                getValueFromEvent={(_, dateStrings) => {
                  form.setFieldsValue({regularDateEnd: dateStrings?.[1]})
                  return dateStrings?.[0]
                }}
                getValueProps={value => ({
                  value: value ? [moment(value), moment(form.getFieldValue('regularDateEnd'))] : value,
                })}
              >
                <DatePicker.RangePicker />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label={'离职日期'}
                name={'dimissionDateBegin'}
                getValueFromEvent={(_, dateStrings) => {
                  form.setFieldsValue({dimissionDateEnd: dateStrings?.[1]})
                  return dateStrings?.[0]
                }}
                getValueProps={value => ({
                  value: value ? [moment(value), moment(form.getFieldValue('dimissionDateEnd'))] : value,
                })}
              >
                <DatePicker.RangePicker />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={'站点所在城市'} name={'warehouseCityCodeList'}>
                <Select
                  mode={'multiple'}
                  placeholder={'请选择'}
                  allowClear
                  options={cityCodes}
                  fieldNames={{label: 'name', value: 'code'}}
                  showSearch
                  filterOption={(inputValue, option) => option.name.includes(inputValue)}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row justify={'end'}>
            <Space>
              <Button type={'primary'} htmlType={'submit'} onClick={() => form.setFieldsValue({pageNum: 1})}>
                查询
              </Button>
              <Button htmlType={'reset'}>重置</Button>
            </Space>
          </Row>
        </Form>
      </div>
      <div className={commonStyles.tableSection}>
        <Table
          rowKey={'userId'}
          loading={loading}
          dataSource={list}
          scroll={{x: 'max-content'}}
          columns={[
            {title: '姓名', dataIndex: 'name'},
            {
              title: '运营组织',
              dataIndex: 'orgName',
              render: value => <Ellipsis value={value} />,
            },
            {
              title: '用户组',
              dataIndex: 'userRoleNameJoint',
              render: value => {
                const roleName = value?.split?.(',')
                return roleName?.length > 1 ? (
                  <Tooltip title={value}>
                    <span>{roleName[0] + '...'}</span>
                  </Tooltip>
                ) : (
                  <span>{value}</span>
                )
              },
            },
            {title: '岗位等级', dataIndex: 'positionLevel', render: value => positionLevel[value]},
            {title: '服务标签', dataIndex: 'appNameJoint'},
            {
              title: '所属站点',
              dataIndex: 'warehouse',
              render: warehouse =>
                warehouse?.length ? (
                  warehouse.length === 1 ? (
                    warehouse[0].value
                  ) : (
                    <Tooltip title={warehouse.map(item => item.value).join(',')}>{`${warehouse[0].value}...`}</Tooltip>
                  )
                ) : (
                  '/'
                ),
            },
            {title: '手机号', dataIndex: 'mobile'},
            {title: '分配房源（间）', dataIndex: 'propertyNum'},
            {title: '入职日期', dataIndex: 'entryTime'},
            {title: '转正日期', dataIndex: 'regularDate'},
            {title: '离职日期', dataIndex: 'dimissionDate'},
            {title: '录入时间', dataIndex: 'insertTime'},
            {
              title: '虚拟号码',
              dataIndex: 'privateNum',
              render: text => <Typography.Text>{text || '/'}</Typography.Text>,
            },
            {
              title: '师傅',
              dataIndex: 'teacherName',
              render: text => <Typography.Text>{text || '/'}</Typography.Text>,
            },
            {
              title: '状态',
              dataIndex: 'status',
              render: value => (value === '1' ? '正常' : '禁用'),
            },
            {
              title: '操作',
              dataIndex: 'userId',
              fixed: 'right',
              render: text => {
                return (
                  <Permission code={'001002002'}>
                    <Space>
                      <Button
                        style={{padding: 0}}
                        type={'link'}
                        onClick={() => {
                          history.push({pathname: '/steward-list/detail', state: {userId: text}})
                        }}
                      >
                        查看
                      </Button>
                      <Button
                        style={{padding: 0}}
                        type={'link'}
                        onClick={() => {
                          if (!stewardList.length) {
                            queryMasterList()
                          }
                          setAddMaster({visible: true, userId: text})
                        }}
                      >
                        分配师傅
                      </Button>
                      <Button
                        type={'link'}
                        style={{padding: 0}}
                        onClick={async () => {
                          const {
                            response: {
                              data: {
                                userId,
                                name,
                                orgName,
                                userRoleNameJoint,
                                beikeId,
                                positionLevel,
                                appIdJoint,
                                salaryAndPerformance,
                              },
                            },
                          } = await stewardModel.stewardUserInfo({userId: text})
                          updateForm.setFieldsValue({
                            stewardId: userId,
                            name,
                            orgName,
                            userRoleNameJoint,
                            beikeId,
                            positionLevel,
                            serviceAppList: appIdJoint,
                            ...salaryAndPerformance,
                          })
                          setUpdateOpen(true)
                        }}
                      >
                        编辑
                      </Button>
                    </Space>
                  </Permission>
                )
              },
            },
          ]}
          pagination={getPaginationProps(form, total)}
        />
      </div>

      <Modal
        title={'分配师傅'}
        open={addMaster.visible}
        centered
        footer={null}
        onCancel={() => {
          setAddMaster({visible: false, userId: ''})
        }}
        afterClose={() => {
          masterForm.resetFields()
        }}
      >
        <Form form={masterForm}>
          <Form.Item label={'师傅'} name={'teacherId'}>
            <Select showSearch filterOption optionFilterProp={'children'} placeholder={'请选择'} allowClear>
              {stewardList.map(item => (
                <Option key={item.userId} value={item.userId}>
                  {`${item.name}（${item.mobile}）`}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Row justify={'end'}>
            <Space>
              <Button onClick={() => setAddMaster({visible: false, userId: ''})}>取消</Button>
              <Button
                type={'primary'}
                onClick={async () => {
                  const {teacherId} = masterForm.getFieldsValue(true)
                  await stewardModel.stewardUserSetTeacher({teacherId, stewardId: addMaster.userId})
                  form.submit()
                  message.success('分配成功')
                  setAddMaster({visible: false, userId: ''})
                }}
              >
                确定
              </Button>
            </Space>
          </Row>
        </Form>
      </Modal>

      <Modal
        open={updateOpen}
        title={'编辑'}
        footer={null}
        centered
        width={700}
        onCancel={() => setUpdateOpen(false)}
        afterClose={() => updateForm.resetFields()}
        destroyOnClose
      >
        <Form
          form={updateForm}
          labelCol={{flex: '120px'}}
          wrapperCol={{span: 15}}
          // onValuesChange={async changedValues => {
          //   const keys = Object.keys(changedValues)
          //   if (
          //     [
          //       'salary',
          //       'performanceRule',
          //       'performanceRuleBase',
          //       'probationPerformanceRule',
          //       'probationPerformanceRuleBase',
          //     ].some(key => keys.includes(key))
          //   ) {
          //     //
          //     await delay(1)
          //     const values: ApprovalSalaryAndPerformanceDTO = updateForm.getFieldsValue(true)
          //
          //     // 计算底薪和绩效
          //     if (!isNaN(values.salary) && values.performanceRule && !isNaN(values.performanceRuleBase)) {
          //       switch (values.performanceRule) {
          //         case '1': {
          //           values.performance = currency(values.salary).multiply(values.performanceRuleBase).divide(100).value
          //           values.baseSalary = currency(values.salary).subtract(values.performance).value
          //           break
          //         }
          //         case '2': {
          //           values.performance = values.performanceRuleBase
          //           values.baseSalary = currency(values.salary).subtract(values.performance).value
          //           break
          //         }
          //       }
          //     }
          //
          //     // 计算试用期底薪和绩效
          //     if (
          //       !isNaN(values.baseSalary) &&
          //       !isNaN(values.performance) &&
          //       (values.probationPerformanceRule === '1' ||
          //         (values.probationPerformanceRule && !isNaN(values.probationPerformanceRuleBase)))
          //     ) {
          //       switch (values.probationPerformanceRule) {
          //         case '1': {
          //           values.probationPerformance = values.performance
          //           values.probationBaseSalary = values.baseSalary
          //           values.probationSalary = currency(values.probationPerformance).add(values.probationBaseSalary).value
          //           break
          //         }
          //         case '2': {
          //           values.probationPerformance = currency(values.performance)
          //             .multiply(values.probationPerformanceRuleBase)
          //             .divide(100).value
          //           values.probationBaseSalary = values.baseSalary
          //           values.probationSalary = currency(values.probationPerformance).add(values.probationBaseSalary).value
          //           break
          //         }
          //         case '3': {
          //           values.probationPerformance = currency(values.performance).subtract(
          //             values.probationPerformanceRuleBase
          //           ).value
          //           values.probationBaseSalary = values.baseSalary
          //           values.probationSalary = currency(values.probationPerformance).add(values.probationBaseSalary).value
          //           break
          //         }
          //       }
          //     }
          //
          //     updateForm.setFieldsValue(values)
          //   }
          // }}
          onFinish={async () => {
            const {
              stewardId,
              beikeId,
              positionLevel,
              serviceAppList,
              salary,
              baseSalary,
              performance,
              allowance,
              // performanceRule,
              // performanceRuleBase,
              probationSalary,
              probationBaseSalary,
              probationPerformance,
              probationAllowance,
              probationMealSubsidy,
              // probationPerformanceRule,
              // probationPerformanceRuleBase,
              mealSubsidy,
            } = updateForm.getFieldsValue(true)
            await stewardModel.stewardUserUpdate({
              stewardId,
              positionLevel,
              serviceAppList: [serviceAppList],
              beikeId,
              salaryAndPerformance: {
                salary,
                baseSalary,
                performance,
                allowance,
                // performanceRule,
                // performanceRuleBase,
                probationSalary,
                probationBaseSalary,
                probationPerformance,
                probationAllowance,
                // probationPerformanceRule,
                // probationPerformanceRuleBase,
                mealSubsidy,
                probationMealSubsidy,
              },
            })
            message.success('编辑成功')
            form.submit()
            setUpdateOpen(false)
          }}
        >
          <Form.Item label={'姓名'}>
            <span>{updateForm.getFieldValue('name')}</span>
          </Form.Item>
          <Form.Item label={'运营组织'}>
            <span>{updateForm.getFieldValue('orgName')}</span>
          </Form.Item>
          <Form.Item label={'用户组'}>
            <span>{updateForm.getFieldValue('userRoleNameJoint')}</span>
          </Form.Item>
          <Form.Item label={'服务标签'} name={'serviceAppList'} rules={[{required: true, message: '请选择服务标签'}]}>
            <Select
              options={openList}
              fieldNames={{label: 'appName', value: 'appId'}}
              placeholder={'请选择'}
              allowClear
            />
          </Form.Item>
          <Form.Item label={'岗位等级'} name={'positionLevel'} rules={[{required: true, message: '请选择岗位等级'}]}>
            <Select options={positionLevelArr} fieldNames={{value: 'id'}} placeholder={'请选择'} allowClear />
          </Form.Item>
          {/*<Form.Item label={'综合薪资'} name={'salary'} rules={[{required: true, message: '请输入综合薪资'}]}>
            <InputNumber addonAfter={'元'} placeholder={'请输入'} min={0} step={0.01} />
          </Form.Item>
          <Form.Item label={'绩效计算'} name={'performanceRule'} rules={[{required: true, message: '请选择绩效计算'}]}>
            <Radio.Group
              onChange={() => {
                updateForm.setFieldsValue({performanceRuleBase: undefined})
              }}
            >
              <Radio value={'1'}>按薪资百分比</Radio>
              <Radio value={'2'}>按薪资固定金额</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, nextValues) =>
              prevValues.performanceRule !== nextValues.performanceRule ||
              prevValues.salary !== nextValues.salary ||
              prevValues.performance !== nextValues.performance
            }
          >
            {f => {
              const {performanceRule, salary, baseSalary, performance} = f.getFieldsValue(true)
              return (
                <>
                  {performanceRule === '1' && (
                    <Form.Item
                      label={'规则'}
                      rules={[{required: true, message: '请输入'}]}
                      name={'performanceRuleBase'}
                    >
                      <InputNumber min={0} max={100} addonAfter={'%'} placeholder={'请输入'} />
                    </Form.Item>
                  )}
                  {performanceRule === '2' && (
                    <Form.Item
                      label={'规则'}
                      rules={[
                        {required: true, message: '请输入'},
                        ...(!isNaN(salary)
                          ? [{type: 'number', max: salary, message: '绩效薪资不能超过综合薪资！'} as Rule]
                          : []),
                      ]}
                      name={'performanceRuleBase'}
                    >
                      <InputNumber min={0} step={0.01} addonAfter={'元'} placeholder={'请输入'} />
                    </Form.Item>
                  )}
                  <Form.Item label={' '} colon={false}>
                    <span>{`薪资：${salary ?? 0}元，底薪：${baseSalary ?? 0}元，绩效：${performance ?? 0}元`}</span>
                  </Form.Item>
                </>
              )
            }}
          </Form.Item>
          <Form.Item
            label={'试用期是否打折'}
            name={'probationPerformanceRule'}
            rules={[{required: true, message: '请选择'}]}
          >
            <Select
              options={[
                {label: '不打折', value: '1'},
                {label: '按绩效百分比打折', value: '2'},
                {label: '按绩效固定金额打折', value: '3'},
              ]}
              onChange={() => {
                updateForm.setFieldsValue({probationPerformanceRuleBase: undefined})
              }}
              placeholder={'请选择'}
              allowClear
            />
          </Form.Item>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, nextValues) =>
              prevValues.probationPerformanceRule !== nextValues.probationPerformanceRule ||
              prevValues.performance !== nextValues.performance
            }
          >
            {f => {
              const {
                probationPerformanceRule,
                probationSalary,
                probationBaseSalary,
                probationPerformance,
                performance,
              } = f.getFieldsValue(true)
              console.log('performance', performance)
              return (
                <>
                  {probationPerformanceRule === '2' && (
                    <>
                      <Form.Item
                        label={' '}
                        rules={[{required: true, message: '请输入'}]}
                        colon={false}
                        name={'probationPerformanceRuleBase'}
                      >
                        <InputNumber min={0} max={100} addonAfter={'%'} placeholder={'请输入'} />
                      </Form.Item>
                    </>
                  )}
                  {probationPerformanceRule === '3' && (
                    <>
                      <Form.Item
                        label={' '}
                        rules={[
                          {required: true, message: '请输入'},
                          ...(!isNaN(performance)
                            ? [
                                {
                                  type: 'number',
                                  max: performance,
                                  message: '试用期打折薪资不能超过基础绩效工资！',
                                } as Rule,
                              ]
                            : []),
                        ]}
                        colon={false}
                        name={'probationPerformanceRuleBase'}
                      >
                        <InputNumber min={0} step={0.01} addonAfter={'元'} placeholder={'请输入'} />
                      </Form.Item>
                    </>
                  )}
                  <Form.Item label={' '} colon={false}>
                    <span>{`薪资：${probationSalary ?? 0}元，底薪：${probationBaseSalary ?? 0}元，绩效：${
                      probationPerformance ?? 0
                    }元`}</span>
                  </Form.Item>
                </>
              )
            }}
          </Form.Item>*/}

          <Form.Item label={'试用期薪资'} name={'probationSalary'} rules={[{required: true}]}>
            <InputNumber style={{width: '100%'}} placeholder={'请输入'} onChange={updateAllowance} />
          </Form.Item>
          <Form.Item label={'基础工资'} name={'probationBaseSalary'} rules={[{required: true}]} initialValue={3000}>
            <InputNumber style={{width: '100%'}} placeholder={'请输入'} onChange={updateAllowance} />
          </Form.Item>
          <Form.Item label={'绩效工资'} name={'probationPerformance'} rules={[{required: true}]}>
            <InputNumber style={{width: '100%'}} placeholder={'请输入'} onChange={updateAllowance} />
          </Form.Item>
          <Form.Item
            label={'岗位津贴'}
            name={'probationAllowance'}
            rules={[
              {required: true},
              {type: 'number', min: 0, message: '试用期岗位津贴小于0或试用期薪资计算错误，请重新输入正确薪资'},
            ]}
          >
            <InputNumber style={{width: '100%'}} placeholder={'岗位津贴'} disabled />
          </Form.Item>
          <Form.Item label={'转正薪资'} name={'salary'} rules={[{required: true}]}>
            <InputNumber style={{width: '100%'}} placeholder={'请输入'} onChange={updateAllowance} />
          </Form.Item>
          <Form.Item label={'基础工资'} name={'baseSalary'} rules={[{required: true}]} initialValue={3000}>
            <InputNumber style={{width: '100%'}} placeholder={'请输入'} onChange={updateAllowance} />
          </Form.Item>
          <Form.Item label={'绩效工资'} name={'performance'} rules={[{required: true}]}>
            <InputNumber style={{width: '100%'}} placeholder={'请输入'} onChange={updateAllowance} />
          </Form.Item>
          <Form.Item
            label={'岗位津贴'}
            name={'allowance'}
            rules={[
              {required: true},
              {type: 'number', min: 0, message: '岗位津贴小于0或薪资计算错误，请重新输入正确薪资'},
            ]}
          >
            <InputNumber style={{width: '100%'}} placeholder={'岗位津贴'} disabled />
          </Form.Item>

          <Form.Item label={'餐补'} style={{marginBottom: 0}}>
            <Space>
              <Form.Item
                label={'试用期'}
                name={'probationMealSubsidy'}
                rules={[{required: true, message: '请输入试用期餐补'}]}
                colon={false}
              >
                <InputNumber min={0} step={0.01} addonAfter={'元/天'} />
              </Form.Item>
              <Form.Item
                label={'转正后'}
                name={'mealSubsidy'}
                rules={[{required: true, message: '请输入转正后餐补'}]}
                colon={false}
              >
                <InputNumber min={0} step={0.01} addonAfter={'元/天'} />
              </Form.Item>
            </Space>
          </Form.Item>
          <Form.Item label={'贝壳用户ID'} name={'beikeId'}>
            <Input placeholder={'请输入'} allowClear />
          </Form.Item>
          <Row justify={'end'}>
            <Space>
              <Button onClick={() => setUpdateOpen(false)}>取消</Button>
              <Button htmlType={'submit'} type={'primary'}>
                保存
              </Button>
            </Space>
          </Row>
        </Form>
      </Modal>
    </>
  )
}
