import React, {useCallback, useEffect, useState} from 'react'
import styles from '@/pages/client/ClientInfo/index.module.less'
import {Button, Col, message, Row, Select, Space, Table, Tree} from 'antd'
import {CaretDownOutlined} from '@ant-design/icons'
import {customerModel} from '@/store/models/Customer'
import {saleModel} from '@/store/models/Sale'
import {propertyModel} from '@/store/models/Property'
import {DataNode} from 'rc-tree/lib/interface'

const {Option} = Select

type TreePropertyInfoVO = PropertyInfoVO & {selected: boolean}

export default function ClientContractConfiged({userId}: {userId: string}) {
  const [configList, setConfigList] = useState<SaleCustomerPropertyNodeVO[]>([])
  const [contractList, setContractList] = useState<SaleCustomerContractVO[]>([])
  const [propertyTotal, setPropertyTotal] = useState(0)
  const [propertyList, setPropertyList] = useState<TreePropertyInfoVO[]>([])
  const [currentTreeKeys, setCurrentTreeKeys] = useState('')
  const propertyRef = React.useRef<TreePropertyInfoVO[]>([])

  // 客户-详情-合同配置tree
  const queryContractPropertyConfig = useCallback(() => {
    customerModel.customerContractPropertyConfig({userId}).then(({response: {data}}) => setConfigList(data))
  }, [userId])

  useEffect(() => {
    queryContractPropertyConfig()
  }, [queryContractPropertyConfig])

  useEffect(() => {
    saleModel
      .saleCustomerContractList({customerId: userId, pageable: false, contractStatus: '1'})
      .then(({response: {data}}) => {
        // console.log('ContractListContractList', data)
        setContractList(data)
      })
  }, [userId])

  const queryPropertyList = useCallback(
    (params: PropertyAdminListQueryDTO, didConfigList: {propertyCode: string; contractId: string}[]) => {
      propertyModel.queryPropertyList(params).then(({response: {data, total}}) => {
        // console.log('PropertyList', data)
        /**
         * 两件事：
         * 1.saleContractId，根据tree来配置这个字段
         * 2.selected: false
         * */
        setPropertyList(
          data.map(item => {
            const contractId = didConfigList.find(value => value.propertyCode === item.propertyCode)?.contractId
            return {...item, selected: false, saleContractId: contractId ? contractId : ''}
          })
        )
        setPropertyTotal(total)
      })
    },
    []
  )

  return (
    <div className={styles.config}>
      <div className={styles.config_tree}>
        <Tree
          blockNode
          treeData={configList}
          titleRender={_node => {
            const node = _node as DataNode & SaleCustomerPropertyNodeVO
            node.isLeaf = !!node.communityCode
            if (currentTreeKeys === node.key) {
              let newChildren = node.children ? ([...node.children] as SaleCustomerPropertyNodeVO[]) : []
              // console.log('childrenchildrenchildren', newChildren)
              // console.log('propertyRef.current', propertyRef.current)
              // 合并 children 和 propertyRef.current
              let newItems = []
              propertyRef.current.forEach(item => {
                let tag = true // tag 用来标识item是「新增配置项」还是「修改项」
                // 对修改项进行修改
                newChildren = newChildren.map(value => {
                  if (value.propertyCode === item.propertyCode) {
                    tag = false
                    return {...value, contractId: item.saleContractId}
                  } else return value
                })
                // 此item是新增项
                if (tag) newItems.push(item)
              })
              newItems = newItems.map(item => ({
                children: [],
                communityCode: item.communityCode,
                contractId: item.saleContractId,
                key: `${currentTreeKeys}_${item.propertyCode}`,
                propertyCode: item.propertyCode,
                rentType: item.rentType,
                teamId: item.teamId,
                teamPid: item.teamPid,
                title: item.title,
              }))
              // console.log('newItemsnewItemsnewItems', newItems)
              // 合并
              node.children = [...newChildren, ...newItems]
              // 合并完要把propertyRef置空
              propertyRef.current = []
            }
            return (
              <Row align={'middle'}>
                <Col flex={1}>
                  <span>{node.title}</span>
                </Col>
                <Col flex={'80px'}>
                  <Select
                    defaultValue={node.contractId ?? (node.teamId ? '' : null)}
                    style={{width: '100%'}}
                    bordered={false}
                    suffixIcon={<CaretDownOutlined style={{color: 'rgb(38, 38, 38)'}} />}
                    placeholder={'请选择'}
                    onSelect={(value: string) => {
                      node.contractId = value
                      setConfigList(prev => [...prev])
                    }}
                  >
                    {!!node.teamId && (
                      <Option key={''} value={''}>
                        默认（跟随上级）
                      </Option>
                    )}
                    {contractList.map(item => (
                      <Option key={item.contractId} value={item.contractId}>
                        {item.contractCode}
                      </Option>
                    ))}
                  </Select>
                </Col>
              </Row>
            )
          }}
          onSelect={(selectedKeys, info) => {
            // console.log('selectedKeys', selectedKeys)
            // console.log('info', info)
            const node = (info.node as unknown) as DataNode & SaleCustomerPropertyNodeVO
            const {communityCode, rentType, teamId, children = []} = node
            const key = selectedKeys[0] as string
            // console.log('------------node', node)
            // console.log('------------key', key)
            if (communityCode) {
              setCurrentTreeKeys(key)
              queryPropertyList(
                {teamId, rentType, communityCode, pageable: false} as PropertyAdminListQueryDTO,
                (children as SaleCustomerPropertyNodeVO[]).map(item => ({
                  propertyCode: item.propertyCode,
                  contractId: item.contractId,
                }))
              )
            }
          }}
          switcherIcon={
            <div style={{paddingTop: '4px'}}>
              <CaretDownOutlined />
            </div>
          }
        />
      </div>

      <Table
        rowKey={'propertyCode'}
        rowSelection={{
          selectedRowKeys: propertyList.filter(item => item.selected).map(item => item.propertyCode),
          onChange: (selectedRowKeys, selectedRows) =>
            setPropertyList(prevState =>
              prevState.map(item =>
                selectedRowKeys.includes(item.propertyCode) ? {...item, selected: true} : {...item, selected: false}
              )
            ),
        }}
        columns={[
          {title: '房源标题', dataIndex: 'title'},
          {
            title: '配置合同',
            width: '25%',
            render: (_, render) => {
              const {saleContractId, teamId, propertyCode} = render
              return (
                <Select
                  // disabled={!!configRows.length}
                  value={saleContractId ?? (teamId ? '' : null)}
                  style={{width: '100%'}}
                  bordered={false}
                  suffixIcon={<CaretDownOutlined style={{color: 'rgb(38, 38, 38)'}} />}
                  placeholder={'请选择'}
                  onSelect={(value: string) => {
                    setPropertyList(prevState =>
                      prevState.map(item => {
                        if (item.propertyCode === propertyCode) {
                          const newItem = {...item, saleContractId: value}
                          propertyRef.current = [newItem]
                          return newItem
                        } else return item
                      })
                    )
                    setConfigList(prev => [...prev])
                  }}
                >
                  <Option key={''} value={''}>
                    默认（跟随上级）
                  </Option>
                  {contractList.map(item => (
                    <Option key={item.contractId} value={item.contractId}>
                      {item.contractCode}
                    </Option>
                  ))}
                </Select>
              )
            },
          },
        ]}
        dataSource={propertyList}
        pagination={{position: ['bottomCenter']}}
        title={() => (
          <Row justify={'space-between'} style={{margin: '0 -16px'}}>
            <div>
              共计<span style={{color: '#00B7AE'}}>{propertyTotal}</span>套房源
            </div>
            <Space>
              {propertyList.some(item => item.selected) && (
                <Select
                  style={{width: '200px'}}
                  placeholder={'批量选择'}
                  onSelect={(value: string) => {
                    setPropertyList(prevState =>
                      prevState.map(item => {
                        if (item.selected) {
                          const newItem = {...item, saleContractId: value}
                          propertyRef.current = [...propertyRef.current, newItem]
                          return newItem
                        } else return item
                      })
                    )
                    setConfigList(prev => [...prev])
                  }}
                >
                  <Option key={''} value={''}>
                    默认（跟随上级）
                  </Option>
                  {contractList.map(item => (
                    <Option key={item.contractId} value={item.contractId}>
                      {item.contractCode}
                    </Option>
                  ))}
                </Select>
              )}
              <Button
                type={'primary'}
                onClick={async () => {
                  // console.log('-------------configList', configList)
                  if (configList.some(item => !item.contractId)) return message.error('请配置合同！')
                  await customerModel.customerContractPropertyConfigSave({config: configList, userId})
                  queryContractPropertyConfig()
                  setPropertyList(prevState => prevState.map(item => ({...item, selected: false})))
                  message.success('配置成功！')
                }}
              >
                保存
              </Button>
              {/*<Button*/}
              {/*  onClick={() => {*/}
              {/*    setPropertyTotal(0)*/}
              {/*    setPropertyList([])*/}
              {/*    queryContractPropertyConfig()*/}
              {/*  }}*/}
              {/*>*/}
              {/*  全部重置*/}
              {/*</Button>*/}
            </Space>
          </Row>
        )}
        style={{marginTop: '16px'}}
      />
    </div>
  )
}
