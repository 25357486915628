import {Button, message, PageHeader, Table, Tooltip} from 'antd'
import React, {useEffect, useState} from 'react'
import commonStyles from '@/assets/styles/common.module.less'
import {sysModel} from '@/store/models/Sys'
import {downloadFile} from '@/utils/util'
import {userModel} from '@/store/models/User'
import {Page} from '@/components/Page'

export default function DownLoadCenter() {
  const userId = userModel.data.userInfo?.userId
  const roleList = userModel.data.userInfo?.roleIdList
  const [params, setParams] = useState({
    pageNum: 1,
    pageSize: 10,
    exportUid: roleList.includes('admin') ? '' : userId,
  } as SysReportExportQueryDTO)
  const [list, setList] = useState<SysReportExportVO[]>([])
  const [total, setTotal] = useState(0)
  const loading = sysModel.sysReportExportList.useLoading()

  useEffect(() => {
    sysModel.sysReportExportList(params).then(({response: {data, total}}) => {
      setList(data)
      setTotal(total)
    })
  }, [params])

  return (
    <Page header={<PageHeader ghost={false} title={'下载中心'} />}>
      <div className={commonStyles.filterSection}>
        <Table
          rowKey={'exportId'}
          loading={loading}
          dataSource={list}
          columns={[
            {title: '报表名称', dataIndex: 'reportName'},
            {
              title: '导出条件',
              dataIndex: 'param',
              render: value => {
                const list = value?.split('&')
                return list?.length > 2 ? (
                  <Tooltip title={value}>
                    <span>{list[0] + '...'}</span>
                  </Tooltip>
                ) : (
                  value
                )
              },
            },
            {title: '导出时间', dataIndex: 'exportDoneTime'},
            {title: '导出人员', dataIndex: 'exportUname'},
            {title: '状态', dataIndex: 'exportStatus', render: text => (text === '0' ? '生成中' : '已完成')},
            {
              title: '操作',
              render: record => {
                return record.exportStatus === '1' ? (
                  <Button
                    type='link'
                    onClick={() => {
                      if (!record.exportSize) return message.warning('报表无内容！')
                      const url = record.exportUrl
                      const filename = record.reportId
                      downloadFile(url, filename)
                    }}
                  >
                    下载
                  </Button>
                ) : (
                  '/'
                )
              },
            },
          ]}
          pagination={{
            total,
            current: params.pageNum,
            pageSize: params.pageSize,
            position: ['bottomCenter'],
            showSizeChanger: true,
            onChange: (pageNum, pageSize) => {
              setParams(state => ({...state, pageNum, pageSize}))
            },
          }}
        />
      </div>
    </Page>
  )
}
