import React, {useCallback, useEffect, useState} from 'react'
import {Page} from '@/components/Page'
import {Breadcrumb} from '@/components/Breadcrumb'
import {Button, Col, DatePicker, Form, message, Modal, Row, Select, Space, Table, TreeSelect} from 'antd'
import commonStyles from '@/assets/styles/common.module.less'
import {stewardModel} from '@/store/models/Steward'
import {objectToArray} from '@/store/models/Common'
import BackButton from '@/components/BackButton'
import {getPaginationProps} from '@/common/biz/getPaginationProps'
import {sysModel} from '@/store/models/Sys'
import {debounce} from '@/utils/util'

// restDayOfWeek 范围：1 - 7，其中 1 是星期日
const weekMap = {
  1: '星期日',
  2: '星期一',
  3: '星期二',
  4: '星期三',
  5: '星期四',
  6: '星期五',
  7: '星期六',
}
const weekArray = objectToArray(weekMap, {keyParseToInt: true})

export default function ScheduleConfig() {
  const [form] = Form.useForm()
  const [listTotal, setListTotal] = useState(0)
  const [dataSource, setDataSource] = useState<StewardTransferConfigVO[]>([])

  const [editForm] = Form.useForm()
  const [selectedList, setSelectedList] = useState<StewardTransferConfigVO[]>([])

  const [modalForm] = Form.useForm()
  const [visible, setVisible] = useState(false)

  const queryConfigList = useCallback(async () => {
    const formValues = form.getFieldsValue(true)
    const {
      response: {data, total},
    } = await stewardModel.stewardAdminTransferConfigListV2(formValues)
    setListTotal(total)
    setDataSource(data)
  }, [form])

  useEffect(() => {
    form.submit()
  }, [form])

  // 组织树
  const [tree, setTree] = useState<SysOrgTreeNodeVO[]>([])

  useEffect(() => {
    sysModel.sysOrgTree({fetchUser: false, orgIdLike: '00'} as SysOrgTreeQueryDTO).then(({response: {data}}) => {
      setTree(data)
    })
  }, [])

  // 管家列表
  const [stewardList, setStewardList] = useState<StewardUserVO[]>([])

  const queryStewardList = debounce(
    useCallback(async (userName: string) => {
      const {
        response: {data},
      } = await stewardModel.stewardUserList({pageNum: 1, pageSize: 20, pageable: true, userName})
      setStewardList(data)
    }, [])
  )

  // 编辑
  function handleEdit({editList}: {editList: {stewardId: string; stewardName: string}[]}) {
    Modal.confirm({
      title: '编辑',
      icon: null,
      centered: true,
      content: (
        <Form form={editForm}>
          <Form.Item name={'restDayOfWeek'} label={'休息日'} rules={[{required: true, message: '请选择休息日'}]}>
            <Select placeholder={'请选择'} options={weekArray} fieldNames={{value: 'id'}} />
          </Form.Item>
        </Form>
      ),
      onOk: async () => {
        await editForm.validateFields()
        const restDayOfWeek = editForm.getFieldValue('restDayOfWeek')
        const reqParams = editList.map(item => ({restDayOfWeek, ...item}))
        await stewardModel.stewardAdminTransferConfigSave(reqParams)
        message.success('编辑成功！')
        setSelectedList([])
        form.submit()
      },
      afterClose: () => editForm.resetFields(),
    })
  }

  const createTransfer = stewardModel.stewardAdminTransferConfigCreateTransfer.useLoading()
  const listLoading = stewardModel.stewardAdminTransferConfigListV2.useLoading()

  return (
    <Page
      breadcrumb={
        <Breadcrumb
          right={
            <Space>
              <Button
                loading={createTransfer}
                type={'primary'}
                onClick={() => setVisible(true)}
                disabled={!dataSource.length}
              >
                生成排班
              </Button>
              <Button
                type={'primary'}
                onClick={() => {
                  if (selectedList.length === 0) return message.warn('请选择要编辑的项！')
                  handleEdit({
                    editList: selectedList.map(item => ({stewardId: item.stewardId, stewardName: item.stewardName})),
                  })
                }}
              >
                批量编辑
              </Button>
            </Space>
          }
        />
      }
    >
      <BackButton />
      <div className={commonStyles.filterSection}>
        <Form
          form={form}
          initialValues={{pageNum: 1, pageSize: 10, pageable: true}}
          onFinish={queryConfigList}
          onReset={queryConfigList}
        >
          <Row gutter={20}>
            <Col span={8}>
              <Form.Item label={'管家'} name={'stewardIdList'}>
                <Select
                  placeholder={'请输入管家名称'}
                  options={stewardList}
                  fieldNames={{value: 'userId', label: 'name'}}
                  showSearch={true}
                  mode={'multiple'}
                  filterOption={false}
                  onSearch={queryStewardList}
                  allowClear
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={'休息日'} name={'restDayOfWeekList'}>
                <Select
                  placeholder={'请选择'}
                  options={weekArray}
                  fieldNames={{value: 'id'}}
                  mode={'multiple'}
                  allowClear
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={'所在组织'} name={'orgIdList'}>
                <TreeSelect placeholder={'请选择'} treeData={tree} multiple allowClear />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={'配置状态'} name={'configStatus'}>
                <Select
                  placeholder={'请选择'}
                  options={[
                    {label: '未配置', value: 0},
                    {label: '已配置', value: 1},
                  ]}
                  allowClear
                />
              </Form.Item>
            </Col>
          </Row>
          <Row justify={'end'}>
            <Space>
              <Button type={'primary'} htmlType={'submit'} onClick={() => form.setFieldsValue({pageNum: 1})}>
                查询
              </Button>
              <Button htmlType={'reset'}>重置</Button>
            </Space>
          </Row>
        </Form>
      </div>

      <div className={commonStyles.tableSection}>
        <Table
          rowKey={'stewardId'}
          dataSource={dataSource}
          loading={listLoading}
          rowSelection={{
            selectedRowKeys: selectedList.map(item => item.stewardId),
            onChange: (_, selectedRows) => setSelectedList(selectedRows),
          }}
          columns={[
            {title: '管家', dataIndex: 'stewardName'},
            {title: '入职日期', dataIndex: 'entryTime'},
            {title: '离职日期', dataIndex: 'dimissionDate'},
            {title: '休息日', dataIndex: 'restDayOfWeek', render: value => weekMap[value]},
            {title: '所在组织', dataIndex: 'orgName'},
            {
              title: '配置状态',
              dataIndex: 'configStatus',
              render: value => (value === 0 && '未配置') || (value === 1 && '已配置'),
            },
            {
              title: '操作',
              dataIndex: 'stewardId',
              render: (value, record) => {
                return (
                  <Space>
                    <Button
                      type={'link'}
                      style={{padding: 0}}
                      onClick={() => {
                        handleEdit({editList: [{stewardId: value, stewardName: record.stewardName}]})
                      }}
                    >
                      编辑
                    </Button>
                    <Button
                      type={'link'}
                      style={{padding: 0}}
                      onClick={() => {
                        Modal.confirm({
                          title: '删除',
                          icon: null,
                          centered: true,
                          content: '确定删除？',
                          onOk: async () => {
                            await stewardModel.stewardAdminTransferConfigSave([
                              {stewardId: value, stewardName: record.stewardName, restDayOfWeek: null},
                            ])
                            message.success('删除成功！')
                            form.submit()
                          },
                        })
                      }}
                    >
                      删除
                    </Button>
                  </Space>
                )
              },
            },
          ]}
          pagination={getPaginationProps(form, listTotal)}
        />
      </div>

      <Modal
        visible={visible}
        title={'生成排班'}
        centered={true}
        afterClose={() => modalForm.resetFields()}
        onOk={() => modalForm.submit()}
        onCancel={() => setVisible(false)}
        confirmLoading={stewardModel.stewardAdminTransferConfigCreateTransfer.useLoading()}
      >
        <Form
          form={modalForm}
          onFinish={async () => {
            const data = modalForm.getFieldsValue(true)
            await stewardModel.stewardAdminTransferConfigCreateTransfer({
              ...data,
              stewardIdList: selectedList.map(item => item.stewardId),
            })
            message.success('操作成功')
            setVisible(false)
            setSelectedList([])
            form.submit()
          }}
        >
          <Form.Item label={'排班周期'} name={'_date'} rules={[{required: true}]}>
            <DatePicker.RangePicker
              picker={'date'}
              format={'YYYY-MM-DD'}
              onChange={(_, formatString) => {
                modalForm.setFieldsValue({beginDate: formatString?.[0], endDate: formatString?.[1]})
              }}
            />
          </Form.Item>
        </Form>
      </Modal>
    </Page>
  )
}
