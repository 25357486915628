import React, {useState, useCallback, useEffect, useMemo} from 'react'
import {Form, Input, DatePicker, Row, Col, Button, Table, Space, Typography, Badge} from 'antd'
import './index.less'
import {useHistory} from 'react-router-dom'
import {storageModel} from '@/store/models/Storage'
import moment from 'moment'
import {Breadcrumb} from '@/components/Breadcrumb'
import commonStyles from '@/assets/styles/common.module.less'
import {FORMAT_DATE_END, FORMAT_DATE_START} from '@/common/date'

interface InitProps {
  pageNum: number
  pageSize: number
}

const {Text} = Typography
const {RangePicker} = DatePicker
export default function AllotApplyList() {
  const histroy = useHistory()
  const [form] = Form.useForm()
  const [total, setTotal] = useState(0)
  const [pageNum, setPageNum] = useState(1)
  const [pageSize] = useState(10)
  const [tableList, setTableList] = useState<StewardWarehouseTransferListVO[]>([])

  const queryAllotList = useCallback(
    (args: InitProps) => {
      const {time, ...rest} = form?.getFieldsValue() as {
        time?: string[]
        userName?: string
        warehouseOutName?: string
        warehouseInName?: string
      }
      if (time) {
        const startTime = moment(time[0]).format(FORMAT_DATE_START)
        const endTime = moment(time[1]).format(FORMAT_DATE_END)
        storageModel
          .stewardWarehouseTransferList({...args, ...rest, startTime, endTime})
          .then(({response: {data, total}}) => {
            setTableList(data)
            setTotal(total)
          })
      } else {
        storageModel.stewardWarehouseTransferList({...args, ...rest}).then(({response: {data, total}}) => {
          setTableList(data)
          setTotal(total)
        })
      }
    },
    [form]
  )

  useEffect(() => {
    queryAllotList({pageNum, pageSize})
  }, [pageNum, pageSize, queryAllotList])
  //分页选择
  function onPageChange(page) {
    setPageNum(page)
  }
  const loading = storageModel.stewardWarehouseTransferList.useLoading()
  const statusType = useMemo(
    () => ({
      '1': '待审批',
      '2': '已通过',
      '3': '已拒绝',
      '4': '已取消',
    }),
    []
  )
  const statusStyle = useMemo(() => ({1: '#FFB240', 2: '#409EFF', 3: '#FF5454', 4: '#9EA0A7'}), [])
  return (
    <div className={commonStyles.section}>
      <Breadcrumb path={['仓库管理', '调拨申请']} />

      <div className={commonStyles.filterSection}>
        <Form form={form}>
          <Row>
            <Col span={7}>
              <Form.Item label={'出仓仓库'} name={'warehouseOutName'}>
                <Input placeholder={'请输入仓库名称'} allowClear />
              </Form.Item>
            </Col>
            <Col span={7} offset={1}>
              <Form.Item label={'入仓名称'} name={'warehouseInName'}>
                <Input placeholder={'请输入仓库名称'} allowClear />
              </Form.Item>
            </Col>
            <Col span={7} offset={1}>
              <Form.Item label={'申请时间'} name={'time'}>
                <RangePicker />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={7}>
              <Form.Item label={'操作人'} name={'userName'}>
                <Input placeholder={'请输入操作人姓名'} allowClear />
              </Form.Item>
            </Col>
          </Row>
          <Row justify={'end'}>
            <Button
              type={'primary'}
              onClick={() => {
                setPageNum(1)
                queryAllotList({pageNum, pageSize})
              }}
            >
              查询
            </Button>
          </Row>
        </Form>
      </div>

      <div className={commonStyles.tableSection}>
        <Table
          rowKey={'transferId'}
          loading={loading}
          columns={[
            {title: '入仓仓库', dataIndex: 'warehouseInName', key: 'warehouseInName', align: 'center'},
            {
              title: '耗材',
              dataIndex: 'approverId',
              key: 'approverId',
              render: (text, record, index) => {
                const arr = record?.materialVOList.map(item => item?.name)
                return arr.join(',')
              },
              align: 'center',
            },
            {title: '出仓仓库', dataIndex: 'warehouseOutName', key: 'warehouseOutName', align: 'center'},
            {title: '申请时间', dataIndex: 'insertTime', key: 'insertTime', align: 'center'},
            {title: '操作人', dataIndex: 'userName', key: 'userName', align: 'center'},
            {
              title: '状态',
              dataIndex: 'pendingStatus',
              key: 'pendingStatus',
              render: text => {
                return (
                  <Space>
                    <Badge color={statusStyle[text]} />
                    <Text>{statusType[text]}</Text>
                  </Space>
                )
              },
              align: 'center',
            },
            {
              title: '操作',
              dataIndex: 'userId',
              key: 'userId',
              render: (text, record, index) => {
                return (
                  <Button
                    onClick={() => {
                      histroy.push({
                        pathname: '/allot/detail',
                        state: {
                          transferId: record?.transferId,
                          warehouseIn: record?.warehouseIn,
                          status: record?.pendingStatus,
                        },
                      })
                    }}
                  >
                    查看
                  </Button>
                )
              },
              align: 'center',
            },
          ]}
          dataSource={tableList}
          pagination={{
            total,
            current: pageNum,
            pageSize,
            position: ['bottomCenter'],
            hideOnSinglePage: false,
            showSizeChanger: false,
            onChange: onPageChange,
          }}
        />
      </div>
    </div>
  )
}
