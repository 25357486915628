let _store
export function setStore(store) {
  _store = store
}
export function getStore() {
  return _store
}

let _models
export function setModels(models) {
  _models = models
}
export function getModels() {
  return _models
}
