import React, {useCallback, useEffect, useState} from 'react'
import {Page} from '@/components/Page'
import {
  Button,
  Calendar,
  Col,
  Descriptions,
  Form,
  Input,
  message,
  Modal,
  PageHeader,
  Row,
  Select,
  Space,
  Table,
  Tooltip,
} from 'antd'
import {useAppHistory, useAppLocation} from '@/common/hooks/useRouter'
import {Breadcrumb} from '@/components/Breadcrumb'
import DividerLine from '@/pages/property/component/DividerLine'
import commonStyle from '@/assets/styles/common.module.less'
import {stewardModel} from '@/store/models/Steward'
import {useCodeSync} from '@/store/models/Common'
import moment from 'moment'
import {APP_ID} from '@/common/constants'
import {emit} from '@/common/hooks/listener'
import {usePermission} from '@/components/Permission'
import {FORMAT_DATE} from '@/common/date'
import currency from 'currency.js'

const middayMap = {
  AM: '上午',
  PM: '下午',
}

export default function BeikeStewardPerformanceDetail() {
  const history = useAppHistory()
  const location = useAppLocation<'/performance-beike/steward-performance'>()
  const bkId = location.state?.bkId

  const positionLevelCode = useCodeSync('sys.user.steward.position.level')

  const [auth] = usePermission()

  const [form] = Form.useForm()

  const [modal, contextHolder] = Modal.useModal()

  const [bkDetail, setBkDetail] = useState({} as StewardSalaryBkVO)

  const querySalaryBkDetail = useCallback(async () => {
    if (bkId) {
      const {
        response: {data},
      } = await stewardModel.stewardSalaryBkDetail({bkId})

      setBkDetail(data)
    }
  }, [bkId])

  useEffect(() => {
    querySalaryBkDetail()
  }, [querySalaryBkDetail])

  // 绩效类型列表
  const [typeList, setTypeList] = useState<StewardSalaryTypeVO[]>([])

  const querySalaryTypeList = useCallback(async () => {
    const {
      response: {data},
    } = await stewardModel.stewardSalaryTypeList({
      pageNum: 1,
      pageSize: 20,
      pageable: false,
      enable: 1,
      appId: APP_ID.BEIKE,
    } as StewardSalaryTypeQueryDTO)

    setTypeList(data)
  }, [])

  useEffect(() => {
    querySalaryTypeList()
  }, [querySalaryTypeList])

  return (
    <Page
      header={<PageHeader title={'返回'} ghost={false} onBack={() => history.goBack()} />}
      breadcrumb={
        <Breadcrumb
          right={
            <Space>
              {bkDetail.status === 0 && auth('018185002') && (
                <Button
                  type={'primary'}
                  onClick={() => {
                    Modal.confirm({
                      title: '提示',
                      icon: null,
                      centered: true,
                      closable: true,
                      content: '是否确认最终绩效方案！终审确认后，将不可更改。请慎重操作！',
                      onOk: async () => {
                        await stewardModel.stewardSalaryBkBatchUpdate({
                          idList: [bkId],
                          confirmOrCancel: 1,
                        } as StewardSalaryBatchUpdateDTO)
                        message.success('绩效确认操作成功！')
                        querySalaryBkDetail()
                        // 刷新列表页
                        emit('BeikeStewardPerformanceDetail:Beike')
                      },
                    })
                  }}
                >
                  绩效确认
                </Button>
              )}
              {bkDetail.status === 1 && auth('025185002') && (
                <Button
                  onClick={() => {
                    Modal.confirm({
                      title: '提示',
                      icon: null,
                      centered: true,
                      closable: true,
                      content: (
                        <Form form={form}>
                          <Form.Item name={'cancelRemark'} label={'撤回原因'}>
                            <Input.TextArea placeholder={'请输入'} maxLength={200} showCount />
                          </Form.Item>
                        </Form>
                      ),
                      afterClose: () => form.resetFields(),
                      onOk: async () => {
                        const cancelRemark = form.getFieldValue('cancelRemark')
                        await stewardModel.stewardSalaryBkBatchUpdate({
                          idList: [bkId],
                          cancelRemark,
                          confirmOrCancel: 0,
                        })
                        message.success('绩效撤回操作成功！')
                        querySalaryBkDetail()
                        // 刷新列表页
                        emit('BeikeStewardPerformanceDetail:Beike')
                      },
                    })
                  }}
                >
                  绩效撤回
                </Button>
              )}
            </Space>
          }
          style={{background: '#fff'}}
        />
      }
    >
      {contextHolder}
      <div className={commonStyle.filterSection}>
        <DividerLine title={'管家基本信息'} bottom={15} />
        <Descriptions title={''} column={4}>
          <Descriptions.Item label={'姓名'}>{bkDetail.stewardName}</Descriptions.Item>
          <Descriptions.Item label={'手机号'}>{bkDetail.stewardMobile}</Descriptions.Item>
          <Descriptions.Item label={'所在站点'}>
            {bkDetail.sites?.split(',')?.length ? (
              bkDetail.sites.split(',').length === 1 ? (
                bkDetail.sites
              ) : (
                <Tooltip title={bkDetail.sites}>{bkDetail.sites.split(',')[0] + '...'}</Tooltip>
              )
            ) : (
              '/'
            )}
          </Descriptions.Item>
          <Descriptions.Item label={'组织'}>{bkDetail.stewardOrgName}</Descriptions.Item>
          <Descriptions.Item label={'用户组'}>{bkDetail.stewardRoleName}</Descriptions.Item>
          <Descriptions.Item label={'岗位等级'}>{positionLevelCode[bkDetail.stewardPositionLevel]}</Descriptions.Item>
          <Descriptions.Item label={'入职时间'}>
            {!!bkDetail.entryTime && moment(bkDetail.entryTime).format(FORMAT_DATE)}
          </Descriptions.Item>
          <Descriptions.Item label={'转正时间'}>
            {!!bkDetail.regularDate && moment(bkDetail.regularDate).format(FORMAT_DATE)}
          </Descriptions.Item>
          <Descriptions.Item label={'工作月份'}>{bkDetail.salaryDate}</Descriptions.Item>
          <Descriptions.Item label={'管家薪资'}>
            <Button
              type={'link'}
              style={{padding: 0, height: 'auto'}}
              onClick={() => {
                Modal.info({
                  title: '管家薪资',
                  icon: null,
                  width: 560,
                  content: (
                    <div>
                      <p>{`试用期工资：${bkDetail.stewardProbationTotalSalary ?? ' '}元（基础${
                        bkDetail.stewardProbationBasicSalary ?? ' '
                      }元，绩效${bkDetail.stewardProbationPerformance ?? ' '}元，津贴${
                        bkDetail.stewardProbationAllowance ?? ' '
                      }元）`}</p>
                      <p>{`转正工资：${bkDetail.stewardTotalSalary ?? ' '}元（基础${
                        bkDetail.stewardBasicSalary ?? ' '
                      }元，绩效${bkDetail.stewardPerformance ?? ' '}元，津贴${
                        bkDetail.stewardAllowance ?? ' '
                      }元）`}</p>
                    </div>
                  ),
                })
              }}
            >
              查看
            </Button>
          </Descriptions.Item>
          {/*<Descriptions.Item label={'当月底薪'}>{bkDetail.baseSalary}</Descriptions.Item>*/}
          {/*<Descriptions.Item label={'当月绩效'}>{bkDetail.performance}</Descriptions.Item>*/}
          <Descriptions.Item label={'当月方案'}>{bkDetail.planName}</Descriptions.Item>
          <Descriptions.Item label={'离职日期'}>
            {!!bkDetail.dimissionDate && moment(bkDetail.dimissionDate).format(FORMAT_DATE)}
          </Descriptions.Item>
        </Descriptions>
        <DividerLine
          title={'绩效统计'}
          right={
            <Row justify={'space-between'} style={{flex: 1}}>
              <Col>
                {auth('003185002') && (
                  <Button
                    type={'link'}
                    onClick={() => {
                      if (bkDetail.status === 1) {
                        Modal.confirm({
                          title: '额外奖惩',
                          icon: null,
                          centered: true,
                          closable: true,
                          content: '当前绩效已确认，已确认绩效不能操作额外奖惩。',
                          okCancel: false,
                        })
                      } else {
                        Modal.confirm({
                          title: '新增额外奖惩',
                          icon: null,
                          centered: true,
                          closable: true,
                          content: (
                            <Form form={form}>
                              <Form.Item
                                name={'typeId'}
                                label={'额外奖惩方案'}
                                rules={[{required: true, message: '请选择额外奖惩方案'}]}
                              >
                                <Select
                                  placeholder={'请选择'}
                                  options={typeList}
                                  fieldNames={{label: 'typeName', value: 'typeId'}}
                                  showSearch
                                  filterOption={(inputValue, option) => {
                                    return option?.typeName?.indexOf(inputValue) > -1
                                  }}
                                />
                              </Form.Item>
                            </Form>
                          ),
                          afterClose: () => form.resetFields(),
                          onOk: async () => {
                            await form.validateFields()
                            const typeId = form.getFieldValue('typeId')
                            await stewardModel.stewardSalaryBkTypeUse({idList: [bkId], addOrDelete: 1, typeId})
                            message.success('新增额外奖惩操作成功！')
                            querySalaryBkDetail()
                            // 刷新列表页
                            emit('BeikeStewardPerformanceDetail:Beike')
                          },
                        })
                      }
                    }}
                  >
                    新增额外奖惩
                  </Button>
                )}
              </Col>
              <Col>
                <Button
                  type={'link'}
                  onClick={() => {
                    modal.confirm({
                      title: `${bkDetail.stewardName}${bkDetail.salaryDate}月的出勤记录`,
                      icon: null,
                      centered: true,
                      closable: true,
                      okCancel: false,
                      width: 1200,
                      maskClosable: true,
                      content: (
                        <>
                          <div>
                            出勤天数：
                            {bkDetail.mealSubsidyDetail?.filter(value => value.vacationTypeId === -3)?.length / 2}
                            天，金额合计：
                            {bkDetail.extraMealSubsidy}元
                          </div>
                          <Calendar
                            headerRender={() => null}
                            validRange={
                              [
                                moment(bkDetail.salaryDate).startOf('month'),
                                moment(bkDetail.salaryDate).endOf('month'),
                              ] as any
                            }
                            defaultValue={moment(bkDetail.salaryDate) as any}
                            dateFullCellRender={date => {
                              const items = bkDetail.mealSubsidyDetail.filter(value =>
                                date.isSame(value.beginDate, 'date')
                              )

                              return (
                                <div
                                  style={{
                                    height: 90,
                                    margin: 2,
                                    paddingRight: 10,
                                    textAlign: 'left',
                                    ...([-1, -2].includes(items[0]?.vacationTypeId) ? {backgroundColor: '#ccc'} : {}),
                                  }}
                                >
                                  <div style={{textAlign: 'right'}}>{date.date()}</div>
                                  {items.map((value, index) => (
                                    <div key={index} style={{fontSize: 12, whiteSpace: 'nowrap'}}>{`${
                                      middayMap[value.beginMidday]
                                    }：${value.vacationTypeName}`}</div>
                                  ))}
                                </div>
                              )
                            }}
                          />
                        </>
                      ),
                    })
                  }}
                >
                  出勤记录
                </Button>
              </Col>
            </Row>
          }
          top={25}
          bottom={15}
        />
        <Table
          rowKey={'name'}
          dataSource={bkDetail.performanceStatistics}
          loading={false}
          columns={[
            {title: '绩效指标名称', dataIndex: 'name'},
            {title: '指标', dataIndex: 'key'},
            {title: '绩效金额', dataIndex: 'value'},
          ]}
          pagination={false}
        />
        <DividerLine title={'确认信息'} top={25} bottom={15} />
        <Descriptions title={''} column={2}>
          <Descriptions.Item label={'综合薪资'}>{`${
            currency(bkDetail.baseSalary).add(bkDetail.performance).add(bkDetail.allowance).value
          }元（底薪${bkDetail.baseSalary}元，绩效${bkDetail.performance}元，津贴${
            bkDetail.allowance
          }元）`}</Descriptions.Item>
          <Descriptions.Item label={'评定绩效金额'}>{bkDetail.salaryTotal}</Descriptions.Item>
          <Descriptions.Item label={'餐补'}>{bkDetail.extraMealSubsidy}元</Descriptions.Item>
          <Descriptions.Item label={'薪资合计'}>{bkDetail.allSalary}</Descriptions.Item>

          {bkDetail.status === 1 && (
            <>
              <Descriptions.Item label={'确认人'}>{bkDetail.confirmName}</Descriptions.Item>
              <Descriptions.Item label={'确认时间'}>{bkDetail.confirmTime}</Descriptions.Item>
            </>
          )}
        </Descriptions>
      </div>
    </Page>
  )
}
