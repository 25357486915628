import React, {useCallback, useEffect, useState} from 'react'
import {Page} from '@/components/Page'
import {Breadcrumb} from '@/components/Breadcrumb'
import {Button, Col, DatePicker, Form, Input, InputNumber, message, Modal, Row, Select, Space, Table, Tabs} from 'antd'
import commonStyles from '@/assets/styles/common.module.less'
import {useAppHistory, useAppLocation} from '@/common/hooks/useRouter'
import {ebassModel} from '@/store/models/Ebaas'
import {getPaginationProps} from '@/common/biz/getPaginationProps'
import {arrayToObject} from '@/store/models/Common'
import ebaas from '@/store/models/Common/ebaasCodes'
import BackButton from '@/components/BackButton'
import {useFocusEffect} from '@/common/hooks/useFocusEffect'

const balconyNumCode = arrayToObject(ebaas.balconyNum)
const windowTypesCode = arrayToObject(ebaas.windowTypes)
const isOpenKitchenCode = arrayToObject(ebaas.isOpenKitchen)
const syncStatusCode = arrayToObject(ebaas.syncStatus)

export default function StorePropertyInfo() {
  const location = useAppLocation<'/store-manage/property-info'>()
  const condoId = location.state?.condoId
  const history = useAppHistory()

  const [activeKey, setActiveKey] = useState('1')

  const [editForm] = Form.useForm()
  const [isBatch, setIsBatch] = useState(false)
  const [propertyCodes, setPropertyCodes] = useState<string[]>([])

  // 房源列表
  const [roomTotal, setRoomTotal] = useState(0)
  const [roomForm] = Form.useForm()
  const [roomList, setRoomList] = useState<EbaasRoomVO[]>([])

  const queryRoomList = useCallback(() => {
    if (condoId) {
      const formValues = roomForm.getFieldsValue(true)
      ebassModel.ebaasRoomList({condoId, ...formValues}).then(({response: {data, total}}) => {
        setRoomList(data)
        setRoomTotal(total)
      })
    }
  }, [condoId, roomForm])

  useEffect(() => {
    queryRoomList()
    setPropertyCodes([])
  }, [queryRoomList])

  useFocusEffect(() => {
    queryRoomList()
  })

  // 房型模板
  const [layoutTotal, setLayoutTotal] = useState(0)
  const [layoutForm] = Form.useForm()
  const [layoutList, setLayoutList] = useState<EbaasLayoutVO[]>([])

  const queryLayoutList = useCallback(() => {
    if (condoId) {
      const {pageNum = 1, pageSize = 10, pageable = true, ...rest} = layoutForm.getFieldsValue(true)
      ebassModel.ebaasLayoutList({condoId, pageNum, pageSize, pageable, ...rest}).then(({response: {data, total}}) => {
        setLayoutList(data)
        setLayoutTotal(total)
      })
    }
  }, [condoId, layoutForm])

  useEffect(() => {
    queryLayoutList()
  }, [queryLayoutList])

  useFocusEffect(() => {
    queryLayoutList()
  })

  const onEditFinish = useCallback(async () => {
    const formValues = editForm.getFieldsValue(true)
    await ebassModel.ebaasRoomUpdateBatch({propertyCodeList: propertyCodes, ...formValues})
    message.success('操作成功')
    setIsBatch(false)
    setPropertyCodes([])
    editForm.resetFields()
    queryRoomList()
  }, [editForm, propertyCodes, queryRoomList])

  const roomLoading = ebassModel.ebaasRoomList.useLoading()
  const layoutLoading = ebassModel.ebaasLayoutList.useLoading()
  const batchLoading = ebassModel.ebaasRoomUpdateBatch.useLoading()

  return (
    <Page
      breadcrumb={
        <Breadcrumb
          right={
            <Space>
              {activeKey === '1' && (
                <>
                  <Button type={'primary'} onClick={() => history.push('/store-manage/property-detail', {condoId})}>
                    新增房源
                  </Button>
                  <Button
                    type={'primary'}
                    onClick={() => {
                      if (propertyCodes.length === 0) return message.warn('请选择房源')
                      setIsBatch(true)
                    }}
                  >
                    批量编辑
                  </Button>
                </>
              )}
              {activeKey === '2' && (
                <Button type={'primary'} onClick={() => history.push('/store-manage/property-template', {condoId})}>
                  新建模板
                </Button>
              )}
            </Space>
          }
        />
      }
    >
      <BackButton />
      <Tabs onChange={key => setActiveKey(key)} tabBarStyle={{backgroundColor: '#fff', paddingLeft: '34px'}}>
        <Tabs.TabPane tab={'房源列表'} key={'1'}>
          <Form
            form={roomForm}
            initialValues={{pageNum: 1, pageSize: 10, pageable: true}}
            onFinish={queryRoomList}
            onReset={queryRoomList}
          >
            <div className={commonStyles.filterSection}>
              <Row>
                <Col span={7}>
                  <Form.Item label={'房间号'} name={'name'}>
                    <Input placeholder={'请输入'} allowClear />
                  </Form.Item>
                </Col>
                <Col span={7} offset={1}>
                  <Form.Item label={'楼层'} name={'currentFloorNum'}>
                    <InputNumber placeholder={'请输入'} style={{width: '100%'}} />
                  </Form.Item>
                </Col>
              </Row>
              <Row justify={'end'}>
                <Space>
                  <Button htmlType={'reset'}>重置</Button>
                  <Button htmlType={'submit'} type={'primary'} onClick={() => roomForm.setFieldsValue({pageNum: 1})}>
                    查询
                  </Button>
                </Space>
              </Row>
            </div>

            <div className={commonStyles.tableSection}>
              <Table
                rowKey={'propertyCode'}
                dataSource={roomList}
                rowSelection={{
                  fixed: true,
                  selectedRowKeys: propertyCodes,
                  onChange: selectedRowKeys => {
                    setPropertyCodes(selectedRowKeys as string[])
                  },
                }}
                loading={roomLoading}
                columns={[
                  {title: '房源标题', dataIndex: 'name'},
                  {title: '房型模板', dataIndex: 'templateName'},
                  {title: '楼层', dataIndex: 'currentFloorNum'},
                  {title: '租金', dataIndex: 'price'},
                  {title: '天猫好房推广', dataIndex: 'syncStatus', render: value => syncStatusCode[value]},
                  {title: '天猫更新时间', dataIndex: 'latestSyncTime'},
                  {
                    title: '操作',
                    render: (_, value) => {
                      return (
                        <Space>
                          <Button
                            type={'link'}
                            style={{padding: 0}}
                            onClick={() =>
                              history.push('/store-manage/property-detail', {propertyCode: value.propertyCode, condoId})
                            }
                          >
                            编辑
                          </Button>
                        </Space>
                      )
                    },
                  },
                ]}
                pagination={getPaginationProps(roomForm, roomTotal)}
              />
            </div>
          </Form>

          <Modal
            title={'批量编辑'}
            centered
            visible={isBatch}
            footer={null}
            onCancel={() => {
              setIsBatch(false)
              editForm.resetFields()
            }}
          >
            <Form form={editForm} labelCol={{flex: '100px'}} onFinish={onEditFinish} onReset={() => setIsBatch(false)}>
              <div style={{width: '90%'}}>
                <Form.Item
                  label={'租金'}
                  name={'price'}
                  rules={[
                    {required: true, message: '请输入租金'},
                    {type: 'number', min: 100, max: 100000, message: '租金范围在100-100000之间'},
                  ]}
                >
                  <InputNumber placeholder={'请输入'} style={{width: '100%'}} />
                </Form.Item>
                <Form.Item label={'房型模板'} name={'templateId'} rules={[{required: true, message: '请选择房型模板'}]}>
                  <Select placeholder={'请选择'} allowClear>
                    {layoutList.map(item => (
                      <Select.Option key={item.templateId} value={item.templateId}>
                        {item.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  label={'面积'}
                  name={'area'}
                  rules={[
                    {required: true, message: '请输入面积'},
                    {type: 'number', min: 6, max: 300, message: '面积范围在6-300之间'},
                  ]}
                >
                  <InputNumber placeholder={'请输入'} style={{width: '100%'}} />
                </Form.Item>
                <Form.Item
                  label={'所在楼层'}
                  name={'currentFloorNum'}
                  rules={[
                    {required: true, message: '请输入所在楼层'},
                    {type: 'number', min: -2, message: '楼层不能小于-2'},
                  ]}
                >
                  <InputNumber placeholder={'请输入'} style={{width: '100%'}} />
                </Form.Item>
                <Form.Item label={'朝向'} name={'towards'} rules={[{required: true, message: '请选择朝向'}]}>
                  <Select placeholder={'请选择'} allowClear>
                    {ebaas.towards.map(item => (
                      <Select.Option key={`${item.id}`} value={`${item.id}`}>
                        {item.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item
                  label={'可入住时间'}
                  name={'_checkInTime'}
                  rules={[{required: true, message: '请选择可入住时间'}]}
                >
                  <DatePicker
                    style={{width: '100%'}}
                    format={'YYYY-MM-DD'}
                    onChange={(_, formatString) => editForm.setFieldsValue({checkInTime: formatString})}
                  />
                </Form.Item>
                <Form.Item label={'出租状态'} name={'openStatus'} rules={[{required: true, message: '请选择出租状态'}]}>
                  <Select placeholder={'请选择'} allowClear>
                    {ebaas.roomOpenStatus.map(item => (
                      <Select.Option key={item.id} value={item.id}>
                        {item.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </div>
              <Row justify={'end'}>
                <Space>
                  <Button htmlType={'reset'}>取消</Button>
                  <Button type={'primary'} htmlType={'submit'} loading={batchLoading}>
                    保存
                  </Button>
                </Space>
              </Row>
            </Form>
          </Modal>
        </Tabs.TabPane>

        <Tabs.TabPane tab={'房型模板'} key={'2'}>
          <Form
            form={layoutForm}
            initialValues={{pageNum: 1, pageSize: 10, pageable: true}}
            onFinish={queryLayoutList}
            onReset={queryLayoutList}
          >
            <div className={commonStyles.filterSection}>
              <Row>
                <Col span={7}>
                  <Form.Item label={'模板名称'} name={'name'}>
                    <Input placeholder={'请输入'} allowClear />
                  </Form.Item>
                </Col>
              </Row>
              <Row justify={'end'}>
                <Space>
                  <Button htmlType={'reset'}>重置</Button>
                  <Button htmlType={'submit'} type={'primary'} onClick={() => layoutForm.setFieldsValue({pageNum: 1})}>
                    查询
                  </Button>
                </Space>
              </Row>
            </div>

            <div className={commonStyles.tableSection}>
              <Table
                rowKey={'templateId'}
                dataSource={layoutList}
                loading={layoutLoading}
                columns={[
                  {title: '公寓', dataIndex: 'condoName'},
                  {title: '模板名称', dataIndex: 'name'},
                  {
                    title: '户型',
                    dataIndex: 'roomNum',
                    render: (_, value) =>
                      `${value.roomNum ?? '0'}室${value.hallNum ?? '0'}厅${value.kitchenNum ?? '0'}厨${
                        value.bathNum ?? '0'
                      }卫`,
                  },
                  {title: '阳台', dataIndex: 'balconyNum', render: value => balconyNumCode[value]},
                  {title: '窗户', dataIndex: 'windowTypes', render: value => windowTypesCode[value]},
                  {title: '开放厨房', dataIndex: 'isOpenKitchen', render: value => isOpenKitchenCode[value]},
                  {title: '天猫更新时间', dataIndex: 'latestSyncTime'},
                  {
                    title: '操作',
                    render: (_, value) => {
                      return (
                        <Space>
                          <Button
                            type={'link'}
                            style={{padding: 0}}
                            onClick={() =>
                              history.push('/store-manage/property-template', {templateId: value.templateId})
                            }
                          >
                            编辑
                          </Button>
                        </Space>
                      )
                    },
                  },
                ]}
                pagination={getPaginationProps(layoutForm, layoutTotal)}
              />
            </div>
          </Form>
        </Tabs.TabPane>
      </Tabs>
    </Page>
  )
}
