import {Model} from '@redux-model/web'
import {$api} from '@/store/service'

export interface LhSettlementModel {}

class LhSettlement extends Model<LhSettlementModel> {
  initReducer() {
    return {}
  }

  // 龙湖冠寓-结算-列表
  lhSettlementList = $api.action((args: LhSettlementListDTO) =>
    this.post<ApiResponse<LhSettlementVO[]>>('/lh/settlement/list').body(args)
  )

  // 龙湖冠寓-结算-列表统计
  lhSettlementCalc = $api.action((args: LhSettlementListDTO) =>
    this.post<ApiResponse<LhSettlementCalcVO>>('/lh/settlement/calc').body(args)
  )

  // 龙湖冠寓-结算-详情  |  object:{settlementId:结算id}
  lhSettlementInfo = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<LhSettlementVO>>('/lh/settlement/info').body(args)
  )

  // 龙湖冠寓-结算-结算跟进
  lhSettlementFollow = $api.action((args: LhSettlementFollowDTO) =>
    this.post<ApiResponse<Record<string, any>>>('/lh/settlement/follow').body(args)
  )

  // 龙湖冠寓-结算-用章审批
  lhSettlementSealApproval = $api.action((args: LhSettlementSealApprovalDTO) =>
    this.post<ApiResponse<Record<string, any>>>('/lh/settlement/seal/approval').body(args)
  )

  // 龙湖冠寓-结算-开票审批
  lhSettlementInvoiceApproval = $api.action((args: LhSettlementInvoiceApprovalDTO) =>
    this.post<ApiResponse<Record<string, any>>>('/lh/settlement/invoice/approval').body(args)
  )
}

export const lhSettlementModel = new LhSettlement()
