import React, {useCallback, useEffect, useState} from 'react'
import {Page} from '@/components/Page'
import commonStyles from '@/assets/styles/common.module.less'
import {Button, Col, DatePicker, Form, Input, message, Row, Space, Table, Tooltip, TreeSelect} from 'antd'
import {stewardModel} from '@/store/models/Steward'
import {Breadcrumb} from '@/components/Breadcrumb'
import {Permission} from '@/components/Permission'
import {downloadBlob} from '@/utils/util'
import {getPaginationProps} from '@/common/biz/getPaginationProps'
import {ExclamationCircleOutlined} from '@ant-design/icons'
import {sysModel} from '@/store/models/Sys'
import {userModel} from '@/store/models/User'

export default function StewardDailyLog() {
  const [form] = Form.useForm()
  const orgId = userModel.data.userInfo?.orgId

  const [dataSource, setDataSource] = useState<StewardDailyLogVO[]>([])
  const [total, setTotal] = useState(0)
  const loading = stewardModel.stewardStewardDailyLogList.useLoading()
  const [treeList, setTreeList] = useState<SysOrgTreeNodeVO[]>([])
  const search = useCallback(async () => {
    const value = form.getFieldsValue(true)
    const {
      response: {data, total},
    } = await stewardModel.stewardStewardDailyLogList(value)
    setDataSource(data)
    setTotal(total)
  }, [form])

  useEffect(() => {
    sysModel.sysOrgTree({fetchUser: false, orgIdLike: orgId} as SysOrgTreeQueryDTO).then(({response: {data}}) => {
      setTreeList(data)
    })

    form.submit()
  }, [form, orgId])

  return (
    <Page
      breadcrumb={
        <Breadcrumb
          right={
            <Permission code={'007093001'}>
              <Button
                onClick={() => {
                  const value = form.getFieldsValue(true)
                  const {logDateBegin, logDateEnd} = value
                  if (!logDateBegin || !logDateEnd) return message.error('请选择日期')

                  downloadBlob({url: '/steward/steward-daily-log/list-excel', body: value})
                }}
              >
                导出
              </Button>
            </Permission>
          }
        />
      }
    >
      <Form form={form} initialValues={{pageNum: 1, pageSize: 10, pageable: true}} onFinish={search} onReset={search}>
        <div className={commonStyles.filterSection}>
          <Row gutter={30}>
            <Col span={8}>
              <Form.Item label={'姓名'} name={'uname'}>
                <Input placeholder={'请输入管家名称'} allowClear />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={'手机号'} name={'mobile'}>
                <Input placeholder={'请输入手机号'} allowClear />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={'日期'} name={'_logDateBegin'}>
                <DatePicker.RangePicker
                  style={{width: '100%'}}
                  onChange={(_, formatString) => {
                    form.setFieldsValue({logDateBegin: formatString?.[0], logDateEnd: formatString?.[1]})
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={'组织'} name={'orgIdLike'}>
                <TreeSelect treeDefaultExpandAll placeholder={'请选择'} treeData={treeList} />
              </Form.Item>
            </Col>
          </Row>

          <Row justify={'end'}>
            <Space>
              <Button type={'primary'} htmlType={'submit'} onClick={() => form.setFieldsValue({pageNum: 1})}>
                查询
              </Button>
              <Button htmlType={'reset'}>重置</Button>
            </Space>
          </Row>
        </div>
        <div className={commonStyles.tableSection}>
          <Table
            loading={loading}
            dataSource={dataSource}
            scroll={{x: 'max-content'}}
            columns={[
              {title: '日期', dataIndex: 'logDate'},
              {title: '管家', dataIndex: 'uname'},
              {title: '手机号', dataIndex: 'mobile'},
              {title: '组织', dataIndex: 'orgName'},
              {title: '首次进入App时间', render: (_, record) => record.time1 ?? '/'},
              {title: '首次点击接单时间', render: (_, record) => record.time2 ?? '/'},
              {title: '首次成功接单时间', render: (_, record) => record.time3 ?? '/'},
              {title: '订单完成数', dataIndex: 'orderFinishNum', render: value => value ?? '/'},
              {title: '首单完成时间', dataIndex: 'firstOrderFinishTime', render: value => value ?? '/'},
              {title: '最后一单完成时间', dataIndex: 'lastOrderFinishTime', render: value => value ?? '/'},
              {
                title: (
                  <Space>
                    <span>待沟通单量</span>
                    <Tooltip title={'截止当天09:00'}>
                      <ExclamationCircleOutlined style={{color: '#06B6AF'}} />
                    </Tooltip>
                  </Space>
                ),
                dataIndex: 'waitCommunicateNum',
                render: value => value ?? '/',
              },
              {
                title: (
                  <Space>
                    <span>已沟通单量</span>
                    <Tooltip title={'截止当天09:00'}>
                      <ExclamationCircleOutlined style={{color: '#06B6AF'}} />
                    </Tooltip>
                  </Space>
                ),
                dataIndex: 'communicateNum',
                render: value => value ?? '/',
              },
              {
                title: (
                  <Space>
                    <span>超48h已沟通单量</span>
                    <Tooltip title={'截止当天09:00'}>
                      <ExclamationCircleOutlined style={{color: '#06B6AF'}} />
                    </Tooltip>
                  </Space>
                ),
                dataIndex: 'overNum',
                render: value => value ?? '/',
              },
            ]}
            rowKey={'logId'}
            pagination={getPaginationProps(form, total)}
          />
        </div>
      </Form>
    </Page>
  )
}
