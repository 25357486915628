import React, {useCallback, useEffect, useState} from 'react'
import {Page} from '@/components/Page'
import {Breadcrumb} from '@/components/Breadcrumb'
import commonStyles from '@/assets/styles/common.module.less'
import {Button, Col, Form, Input, message, Modal, Radio, Row, Select, Space, Table, Typography} from 'antd'
import {getPaginationProps} from '@/common/biz/getPaginationProps'
import {zzajModel} from '@/store/models/Zzaj'
import {sysUserModel} from '@/store/models/SysUser'
import {mobileValid} from '@/utils/util'
import {usePermission} from '@/components/Permission'

export default function AnJuQualityUnit() {
  const [form] = Form.useForm()
  const [list, setList] = useState<ZzajQaCompanyListVO[]>([])
  const [total, setTotal] = useState(0)
  const loading = zzajModel.zzajQaCompanyList.useLoading()
  const [addOpen, setAddOpen] = useState(false)
  const [addForm] = Form.useForm()
  const [userList, setUserList] = useState<SysUserVO[]>([])
  const [auth] = usePermission()

  const search = useCallback(() => {
    const values = form.getFieldsValue(true)
    zzajModel.zzajQaCompanyList(values).then(({response: {data, total}}) => {
      setList(data)
      setTotal(total)
    })
  }, [form])

  useEffect(() => {
    search()
  }, [search])

  const searchUserList = useCallback(async () => {
    const {
      response: {data},
    } = await sysUserModel.querySysUserList({
      roleId: 'ZZAJ_ZBRY',
      pageNum: 1,
      pageSize: 1,
      pageable: false,
      status: '1',
    })
    setUserList(data)
  }, [])

  return (
    <Page
      breadcrumb={
        <Breadcrumb
          right={
            auth('003177009') && (
              <Button
                type={'primary'}
                onClick={async () => {
                  await searchUserList()
                  addForm.setFieldsValue({addFlag: true})
                  setAddOpen(true)
                }}
              >
                新增
              </Button>
            )
          }
        />
      }
    >
      <div className={commonStyles.filterSection}>
        <Form form={form} onFinish={search} onReset={search} initialValues={{pageNum: 1, pageSize: 10, pageable: true}}>
          <Row>
            <Col span={7}>
              <Form.Item label={'编号'} name={'companyId'}>
                <Input placeholder={'请输入'} allowClear />
              </Form.Item>
            </Col>
            <Col span={7} offset={1}>
              <Form.Item label={'质保单位名称'} name={'companyName'}>
                <Input placeholder={'请输入'} allowClear />
              </Form.Item>
            </Col>
            <Col span={7} offset={1}>
              <Form.Item label={'负责人姓名'} name={'managerName'}>
                <Input placeholder={'请输入'} allowClear />
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item label={'负责人手机号'} name={'managerMobile'}>
                <Input placeholder={'请输入'} allowClear />
              </Form.Item>
            </Col>
            <Col span={7} offset={1}>
              <Form.Item label={'状态'} name={'status'}>
                <Select
                  options={[
                    {value: 1, label: '有效'},
                    {value: 0, label: '无效'},
                  ]}
                  placeholder={'请选择'}
                />
              </Form.Item>
            </Col>
            <Col span={7} offset={1}>
              <Form.Item label={'相关人员'} name={'companyUserName'}>
                <Input placeholder={'请输入'} allowClear />
              </Form.Item>
            </Col>
          </Row>
          <Row justify={'end'}>
            <Space>
              <Button htmlType={'submit'} type={'primary'} onClick={() => form.setFieldsValue({pageNum: 1})}>
                查询
              </Button>
              <Button htmlType={'reset'}>重置</Button>
            </Space>
          </Row>
        </Form>
      </div>
      <div className={commonStyles.filterSection}>
        <Table
          rowKey={'companyId'}
          dataSource={list}
          loading={loading}
          columns={[
            {title: '编号', dataIndex: 'companyId'},
            {title: '质保单位名称', dataIndex: 'companyName'},
            {title: '负责人姓名', dataIndex: 'managerName'},
            {title: '负责人手机号', dataIndex: 'managerMobile'},
            {title: '相关人员', dataIndex: 'companyUserList', render: value => value.map(item => item.value).join(',')},
            {title: '状态', dataIndex: 'status', render: value => (value === 1 ? '有效' : '无效')},
            {
              title: '操作',
              render: (_, record) => {
                return (
                  <Space>
                    {auth('002177010') && (
                      <Button
                        type={'link'}
                        style={{paddingLeft: 0, paddingRight: 0}}
                        onClick={async () => {
                          await searchUserList()
                          addForm.setFieldsValue({
                            ...record,
                            addFlag: false,
                            companyUserList: record.companyUserList.map(item => item.key),
                          })
                          setAddOpen(true)
                        }}
                      >
                        编辑
                      </Button>
                    )}
                    {auth('004177016') && (
                      <Button
                        type={'link'}
                        style={{paddingLeft: 0, paddingRight: 0}}
                        onClick={() => {
                          Modal.confirm({
                            title: '提示',
                            content: '是否确认删除？',
                            icon: null,
                            centered: true,
                            onOk: async () => {
                              await zzajModel.zzajQaCompanyDelete({companyId: record.companyId})
                              message.success('删除成功！')
                              search()
                            },
                          })
                        }}
                      >
                        删除
                      </Button>
                    )}
                  </Space>
                )
              },
            },
          ]}
          pagination={getPaginationProps(form, total)}
        />
      </div>

      <Modal
        open={addOpen}
        title={addForm.getFieldValue('addFlag') ? '新增' : '编辑'}
        centered
        width={500}
        afterClose={() => addForm.resetFields()}
        onCancel={() => setAddOpen(false)}
        onOk={async () => {
          await addForm.validateFields()
          const {companyUserList, ...values} = addForm.getFieldsValue(true)
          await zzajModel.zzajQaCompanyUpset({
            ...values,
            companyUserList: userList
              .filter(item => companyUserList.includes(item.userId))
              .map(item => ({key: item.userId, value: item.name})),
          })
          search()
          setAddOpen(false)
        }}
      >
        <Form form={addForm} wrapperCol={{span: 15}} labelCol={{flex: '120px'}} initialValues={{status: 1}}>
          <Form.Item label={'编号'} name={'companyId'} rules={[{required: true, message: '请输入编号'}]}>
            {addForm.getFieldValue('addFlag') ? (
              <Input placeholder={'请输入'} allowClear />
            ) : (
              <Typography.Text>{addForm.getFieldValue('companyId')}</Typography.Text>
            )}
          </Form.Item>
          <Form.Item
            label={'质保单位名称'}
            name={'companyName'}
            rules={[{required: true, message: '请输入质保单位名称'}]}
          >
            <Input placeholder={'请输入'} allowClear />
          </Form.Item>
          <Form.Item label={'负责人姓名'} name={'managerName'} rules={[{required: true, message: '请输入负责人姓名'}]}>
            <Input placeholder={'请输入'} allowClear />
          </Form.Item>
          <Form.Item label={'负责人手机号'} name={'managerMobile'} rules={[{validator: mobileValid}]} required={true}>
            <Input placeholder={'请输入'} allowClear />
          </Form.Item>
          <Form.Item label={'相关人员'} name={'companyUserList'} rules={[{required: true, message: '请选择相关人员'}]}>
            <Select
              placeholder={'请选择'}
              options={userList}
              fieldNames={{label: 'name', value: 'userId'}}
              mode={'multiple'}
              allowClear
            />
          </Form.Item>
          <Form.Item label={'状态'} name={'status'} rules={[{required: true, message: '请选择状态'}]}>
            <Radio.Group>
              <Radio value={1}>有效</Radio>
              <Radio value={0}>无效</Radio>
            </Radio.Group>
          </Form.Item>
        </Form>
      </Modal>
    </Page>
  )
}
