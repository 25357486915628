import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {Page} from '@/components/Page'
import {Breadcrumb} from '@/components/Breadcrumb'
import commonStyles from '@/assets/styles/common.module.less'
import {
  Button,
  Cascader,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Pagination,
  Row,
  Select,
  Space,
  Switch,
  Table,
  Tabs,
  Drawer,
  Descriptions,
  message,
  Image as AntImage,
} from 'antd'
import {sysModel} from '@/store/models/Sys'
import {objectToArray, useCodeArraySync, useCodeSync} from '@/store/models/Common'
import {useAppHistory} from '@/common/hooks/useRouter'
import {getImg} from '@/utils/util'
import DividerLine from '@/pages/property/component/DividerLine'
import {sysRoleModel} from '@/store/models/SysRole'
import {useListener} from '@/common/hooks/listener'
import {Album, UploadButton} from '@/components/Album'
import {usePermission} from '@/components/Permission'
import {getPaginationProps} from '@/common/biz/getPaginationProps'
import {stewardModel} from '@/store/models/Steward'
import moment from 'moment'
import {Model} from '@redux-model/web'
import {baseUrl} from '@/common/config'
import {SortableContext, useSortable, verticalListSortingStrategy, arrayMove} from '@dnd-kit/sortable'
import {CSS} from '@dnd-kit/utilities'
import {DndContext, DragEndEvent} from '@dnd-kit/core'
import {MenuOutlined} from '@ant-design/icons'

const stewardStatus = {
  '1': '正常',
  '0': '禁用',
}
const stewardStatusArr = objectToArray(stewardStatus)

export default function ContentOperateManage() {
  const history = useAppHistory()
  const [form] = Form.useForm()
  const [list, setList] = useState<SysBannerVO[]>([])
  const [total, setTotal] = useState(0)
  const loading = sysModel.sysBannerList.useLoading()
  const platform = useCodeSync('sys.platform')

  const [auth] = usePermission()

  const defaultActiveKey: string = useMemo(() => {
    const permissionObj = {
      '001105001': '1',
      '001108001': '2',
      '001126001': '3',
      '006170001': '4',
    }
    const permission = Object.keys(permissionObj).find(item => auth(item))
    return permissionObj[permission]
  }, [auth])
  const [activeKey, setActiveKey] = useState(defaultActiveKey)

  const search = useCallback(() => {
    const formValues = form.getFieldsValue(true)
    sysModel.sysBannerList(formValues).then(({response: {data, total}}) => {
      setList(data)
      setTotal(total)
    })
  }, [form])

  useListener('ContentOperateManage:bannerId', () => {
    search()
  })

  const [noticeForm] = Form.useForm()
  const [noticeList, setNoticeList] = useState<SysNoticeVO[]>([])
  const [noticeTotal, setNoticeTotal] = useState(0)
  const noticeLoading = sysModel.sysNoticeList.useLoading()
  const noticeStatusArr = useCodeArraySync('sys.notice.status')
  const noticeStatus = useCodeSync('sys.notice.status')
  const [roleList, setRoleList] = useState<SysRoleVO[]>([])
  const [noticeInfo, setNoticeInfo] = useState({} as SysNoticeVO)

  const noticeSearch = useCallback(() => {
    const formValues = noticeForm.getFieldsValue(true)
    sysModel.sysNoticeList(formValues).then(({response: {data, total}}) => {
      setNoticeList(data)
      setNoticeTotal(total)
    })
  }, [noticeForm])

  useListener('ContentOperateManage:noticeId', () => {
    noticeSearch()
  })

  const [stewardForm] = Form.useForm()
  const [stewardModalForm] = Form.useForm()
  const [stewardModalVisible, setStewardModalVisible] = useState(false)
  const [stewardTotal, setStewardTotal] = useState(0)
  const [stewardList, setStewardList] = useState<StewardHonorVO[]>([])
  const [stewardUserList, setStewardUserList] = useState<StewardUserVO[]>([])

  const isStewardModalLoading = Model.isLoading(
    stewardModel.stewardHonorUpdate.useLoading(),
    stewardModel.stewardHonorInsert.useLoading()
  )
  const searchStewardLoading = stewardModel.stewardHonorList.useLoading()

  const searchSteward = useCallback(() => {
    const values = stewardForm.getFieldsValue(true)
    stewardModel.stewardHonorList(values).then(({response: {data, total}}) => {
      setStewardList(data)
      setStewardTotal(total)
    })
  }, [stewardForm])

  useEffect(() => {
    search()
    noticeSearch()

    sysRoleModel.sysRoleList({status: '1', pageable: false} as SysListDTO).then(({response: {data}}) => {
      setRoleList(data)
    })
  }, [noticeSearch, search])

  const stewardUserListLength = stewardUserList.length
  useEffect(() => {
    switch (activeKey) {
      case '3': {
        if (!stewardUserListLength) {
          stewardModel.stewardUserList({pageable: false}).then(({response: {data}}) => setStewardUserList(data))
        }
        break
      }
    }
  }, [activeKey, stewardUserListLength])

  useEffect(() => {
    switch (activeKey) {
      case '3':
        return searchSteward()
    }
  }, [activeKey, searchSteward])

  const [partnerForm] = Form.useForm()
  const [partnerList, setPartnerList] = useState<SysPartnerListVO[]>([])
  const [partnerTotal, setPartnerTotal] = useState<number>(0)
  const searchPartnerLoading = sysModel.sysPartnerList.useLoading()
  const [addPartnerForm] = Form.useForm()
  const [addPartnerVisible, setAddPartnerVisible] = useState<boolean>(false)
  const addPartnerLoading = sysModel.sysPartnerUpsert.useLoading()
  const searchPartnerList = useCallback(() => {
    const formValues = partnerForm.getFieldsValue(true)
    sysModel.sysPartnerList(formValues).then(({response: {data}}) => {
      setPartnerList(data)
      setPartnerTotal(data.length)
    })
  }, [partnerForm])

  useEffect(() => {
    switch (activeKey) {
      case '4':
        return searchPartnerList()
    }
  }, [activeKey, searchPartnerList])

  async function onDragEnd({active, over}: DragEndEvent) {
    if (active.id !== over?.id) {
      const activeIndex = partnerList.findIndex(item => item.partnerId === active.id)
      const overIndex = partnerList.findIndex(item => item.partnerId === over?.id)
      const list = arrayMove(partnerList, activeIndex, overIndex)
      setPartnerList(list)
      await sysModel.sysPartnerUpdateRank({
        partnerRankList: list.map((item, index) => ({partnerId: item.partnerId, rank: index})),
      })
      searchPartnerList()
    }
  }

  return (
    <Page
      breadcrumb={
        <Breadcrumb
          right={
            <>
              {activeKey === '1' && (
                <Button type={'primary'} onClick={() => history.push({pathname: '/content/add-banner'})}>
                  新增banner
                </Button>
              )}
              {activeKey === '2' && (
                <Button type={'primary'} onClick={() => history.push({pathname: '/content/add-notice'})}>
                  添加公告
                </Button>
              )}
              {activeKey === '3' && (
                <Button type={'primary'} onClick={() => setStewardModalVisible(true)}>
                  添加优秀管家
                </Button>
              )}
              {activeKey === '4' && (
                <Button
                  type={'primary'}
                  onClick={() => {
                    setAddPartnerVisible(true)
                    addPartnerForm.setFieldsValue({rank: partnerTotal})
                  }}
                >
                  新增
                </Button>
              )}
            </>
          }
        />
      }
    >
      <Tabs className={commonStyles.tabs} defaultActiveKey={defaultActiveKey} onChange={key => setActiveKey(key)}>
        <Tabs.TabPane tab={'banner管理'} key={'1'} disabled={!auth('001105001')}>
          <Form form={form} onFinish={search} onReset={search} initialValues={{pageNum: 1, pageSize: 10}}>
            <div className={commonStyles.filterSection}>
              <Row>
                <Col span={7}>
                  <Form.Item label={'编号'} name={'bannerId'}>
                    <Input placeholder='请输入' allowClear />
                  </Form.Item>
                </Col>
                <Col span={7} offset={1}>
                  <Form.Item label={'应用名称'} name={'_platforms'}>
                    <Cascader
                      expandTrigger={'hover'}
                      options={[
                        {
                          label: 'App',
                          value: 'app',
                          children: [
                            {label: '牛油果管家App', value: '02'},
                            {label: '牛油果房东App', value: '03'},
                          ],
                        },
                        {
                          label: '小程序',
                          value: '小程序',
                          children: [{label: '牛油果管家小程序', value: '05'}],
                        },
                      ]}
                      displayRender={label => label[label.length - 1]}
                      onChange={value => {
                        form.setFieldsValue({platforms: value[value.length - 1]})
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col span={7} offset={1}>
                  <Form.Item label={'状态'} name={'bannerStatus'}>
                    <Select placeholder={'请选择'} allowClear>
                      <Select.Option value={1}>正常</Select.Option>
                      <Select.Option value={0}>禁用</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item label={'生效周期'} name={'_time'}>
                    <DatePicker.RangePicker
                      onChange={(_, dateStrings) => {
                        const [limitTimeBegin, limitTimeEnd] = dateStrings ?? []
                        form.setFieldsValue({limitTimeBegin, limitTimeEnd})
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row justify={'end'}>
                <Space>
                  <Button
                    htmlType={'submit'}
                    onClick={() => form.setFieldsValue({pageNum: 1, pageSize: 10})}
                    type={'primary'}
                  >
                    查询
                  </Button>
                  <Button htmlType={'reset'}>重置</Button>
                </Space>
              </Row>
            </div>
            <div className={commonStyles.filterSection}>
              <Table
                rowKey={'bannerId'}
                loading={loading}
                dataSource={list}
                pagination={false}
                columns={[
                  {title: '编号', dataIndex: 'bannerId'},
                  {title: '应用名称', dataIndex: 'platforms', render: text => platform[text]},
                  {title: 'banner名称', dataIndex: 'name'},
                  {title: '展示位置', dataIndex: 'location'},
                  {title: '权重', dataIndex: 'weight'},
                  {
                    title: '图片',
                    dataIndex: 'url',
                    render: text => <AntImage src={getImg(text)} width={76} height={34} />,
                  },
                  {title: '生效周期', render: record => `${record.limitTimeBegin}至${record.limitTimeEnd}`},
                  {title: '状态', dataIndex: 'bannerStatus', render: text => (text === 1 ? '正常' : '禁用')},
                  {
                    title: '操作',
                    render: record => {
                      return (
                        <Space>
                          <Switch
                            checked={record.bannerStatus === 1}
                            onChange={value => {
                              Modal.confirm({
                                title: '提示',
                                content: `是否确认${value ? '启用' : '禁用'}？`,
                                centered: true,
                                icon: null,
                                onOk: async () => {
                                  await sysModel.sysBannerSetStatus({
                                    bannerId: record.bannerId,
                                    bannerStatus: value ? 1 : 0,
                                  })
                                  search()
                                },
                              })
                            }}
                          />
                          <Button
                            style={{paddingLeft: 0, paddingRight: 0}}
                            type={'link'}
                            onClick={() =>
                              history.push({pathname: '/content/add-banner', state: {bannerId: record.bannerId}})
                            }
                          >
                            编辑
                          </Button>
                          <Button
                            style={{paddingLeft: 0, paddingRight: 0}}
                            type={'link'}
                            onClick={() => {
                              Modal.confirm({
                                title: '提示',
                                icon: null,
                                content: '是否确认删除？',
                                centered: true,
                                onOk: async () => {
                                  await sysModel.sysBannerDelete({bannerId: record.bannerId})
                                  search()
                                },
                              })
                            }}
                          >
                            删除
                          </Button>
                        </Space>
                      )
                    },
                  },
                ]}
              />

              <Form.Item
                noStyle
                shouldUpdate={(prev, next) => prev.pageNum !== next.pageNum || prev.pageSize !== next.pageSize}
              >
                {() => (
                  <Pagination
                    total={total}
                    current={form.getFieldValue('pageNum')}
                    pageSize={form.getFieldValue('pageSize')}
                    style={{textAlign: 'center', marginTop: 10}}
                    showSizeChanger
                    onChange={(pageNum, pageSize) => {
                      form.setFieldsValue({pageNum, pageSize})
                      search()
                    }}
                  />
                )}
              </Form.Item>
            </div>
          </Form>
        </Tabs.TabPane>
        <Tabs.TabPane tab={'公告管理'} key={'2'} disabled={!auth('001108001')}>
          <Form
            form={noticeForm}
            onFinish={noticeSearch}
            onReset={noticeSearch}
            initialValues={{pageNum: 1, pageSize: 10}}
          >
            <div className={commonStyles.filterSection}>
              <Row>
                <Col span={7}>
                  <Form.Item label={'编号'} name={'noticeId'}>
                    <Input placeholder='请输入' allowClear />
                  </Form.Item>
                </Col>
                <Col span={7} offset={1}>
                  <Form.Item label={'接收方'} name={'roleId'}>
                    <Select
                      placeholder={'请选择'}
                      showSearch
                      allowClear
                      filterOption={(input, option) => option?.children.indexOf(input as any) > -1}
                    >
                      {roleList.map(item => (
                        <Select.Option value={item.roleId} key={item.roleId}>
                          {item.roleName}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={7} offset={1}>
                  <Form.Item label={'创建人'} name={'createName'}>
                    <Input placeholder='请输入' allowClear />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item label={'发送时间'} name={'_time'}>
                    <DatePicker.RangePicker
                      onChange={(_, dateStrings) => {
                        if (dateStrings.every(item => item)) {
                          const [sendTimeBegin, sendTimeEnd] = dateStrings
                          noticeForm.setFieldsValue({
                            sendTimeBegin: sendTimeBegin + ' 00:00:00',
                            sendTimeEnd: sendTimeEnd + ' 23:59:59',
                          })
                        } else {
                          noticeForm.setFieldsValue({sendTimeBegin: undefined, sendTimeEnd: undefined})
                        }
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col span={7} offset={1}>
                  <Form.Item label={'状态'} name={'noticeStatus'}>
                    <Select placeholder='请选择' allowClear>
                      {noticeStatusArr.map(item => (
                        <Select.Option value={item.id} key={item.id}>
                          {item.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row justify='end'>
                <Space>
                  <Button type={'primary'} htmlType={'submit'} onClick={() => noticeForm.setFieldsValue({pageNum: 1})}>
                    查询
                  </Button>
                  <Button htmlType={'reset'}>重置</Button>
                </Space>
              </Row>
            </div>
            <div className={commonStyles.filterSection}>
              <Table
                rowKey={'noticeId'}
                loading={noticeLoading}
                dataSource={noticeList}
                columns={[
                  {title: '编号', dataIndex: 'noticeId'},
                  {title: '标题', dataIndex: 'title'},
                  {title: '接收方', dataIndex: 'roleName'},
                  {title: '创建时间', dataIndex: 'insertTime'},
                  {title: '发送时间', dataIndex: 'sendTime'},
                  {title: '状态', dataIndex: 'noticeStatus', render: text => noticeStatus[text]},
                  {title: '创建人', dataIndex: 'createName'},
                  {
                    title: '操作',
                    dataIndex: '',
                    render: record => {
                      return (
                        <Space>
                          <Button
                            type={'link'}
                            style={{paddingLeft: 0, paddingRight: 0}}
                            onClick={async () => {
                              const {
                                response: {data},
                              } = await sysModel.sysNoticeInfo({noticeId: record.noticeId})
                              setNoticeInfo(data)
                            }}
                          >
                            查看
                          </Button>
                          {record.noticeStatus === '1' && (
                            <Button
                              type={'link'}
                              style={{paddingLeft: 0, paddingRight: 0}}
                              onClick={() => {
                                Modal.confirm({
                                  title: '提示',
                                  content: '是否确认撤回？',
                                  centered: true,
                                  icon: null,
                                  onOk: async () => {
                                    await sysModel.sysNoticeCancel({noticeId: record.noticeId})
                                    noticeSearch()
                                  },
                                })
                              }}
                            >
                              撤回
                            </Button>
                          )}
                        </Space>
                      )
                    },
                  },
                ]}
                pagination={false}
              />
              <Form.Item noStyle shouldUpdate>
                {() => (
                  <Pagination
                    total={noticeTotal}
                    current={noticeForm.getFieldValue('pageNum')}
                    pageSize={noticeForm.getFieldValue('pageSize')}
                    style={{textAlign: 'center', marginTop: 10}}
                    showSizeChanger
                    onChange={(pageNum, pageSize) => {
                      noticeForm.setFieldsValue({pageNum, pageSize})
                      noticeSearch()
                    }}
                  />
                )}
              </Form.Item>
            </div>
          </Form>
        </Tabs.TabPane>

        <Tabs.TabPane forceRender tab={'优秀管家'} key={'3'} disabled={!auth('001126001')}>
          <div className={commonStyles.filterSection}>
            <Form
              form={stewardForm}
              initialValues={{pageNum: 1, pageSize: 10, pageable: true}}
              onFinish={searchSteward}
              onReset={searchSteward}
            >
              <Row gutter={20}>
                <Col span={8}>
                  <Form.Item label={'管家'} name={'stewardNames'}>
                    <Input placeholder={'请输入'} allowClear />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label={'年月'}
                    name={'month'}
                    getValueFromEvent={(_, dateString) => dateString}
                    getValueProps={value => ({value: value ? moment(value) : value})}
                  >
                    <DatePicker placeholder={'请选择'} picker={'month'} allowClear style={{width: '100%'}} />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label={'状态'} name={'enable'}>
                    <Select placeholder={'请选择'} allowClear>
                      {stewardStatusArr.map(value => (
                        <Select.Option key={value.id} value={value.id}>
                          {value.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label={'生效时间'} name={'_begin'}>
                    <DatePicker.RangePicker
                      allowClear
                      style={{width: '100%'}}
                      onChange={(_, formatString) =>
                        stewardForm.setFieldsValue({
                          begin: formatString?.[0],
                          end: formatString?.[1],
                        })
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label={'创建人'} name={'insertUserName'}>
                    <Input placeholder={'请输入'} allowClear />
                  </Form.Item>
                </Col>
              </Row>
              <Row justify={'end'}>
                <Space>
                  <Button
                    loading={searchStewardLoading}
                    type={'primary'}
                    htmlType={'submit'}
                    onClick={() => stewardForm.setFieldsValue({pageNum: 1})}
                  >
                    查询
                  </Button>
                  <Button htmlType={'reset'}>重置</Button>
                </Space>
              </Row>
            </Form>
          </div>
          <div className={commonStyles.filterSection}>
            <Table
              dataSource={stewardList}
              rowKey={'honorId'}
              columns={[
                {title: '编号', dataIndex: 'honorId'},
                {title: '管家', dataIndex: 'stewardNames'},
                {title: '年月', dataIndex: 'month'},
                {title: '生效日期', render: (_, record) => [record.begin, record.end].join('至')},
                {title: '创建人', dataIndex: 'insertUserName'},
                {title: '创建时间', dataIndex: 'insertTime'},
                {title: '状态', dataIndex: 'enable', render: text => stewardStatus[text]},
                {
                  title: '操作',
                  render: (_, record) => {
                    const isEnable = record.enable === '1'
                    return (
                      <Space>
                        <Button
                          type={'link'}
                          onClick={() => {
                            stewardModalForm.setFieldsValue({
                              _begin: [moment(record.begin), moment(record.end)],
                              begin: record.begin,
                              desc: record.desc,
                              end: record.end,
                              honorId: record.honorId,
                              month: record.month,
                              stewardIdList: record.stewardIdList,
                            })
                            setStewardModalVisible(true)
                          }}
                        >
                          编辑
                        </Button>
                        <Switch
                          checked={isEnable}
                          onClick={() => {
                            Modal.confirm({
                              title: '提示',
                              icon: null,
                              content: `是否确认${isEnable ? '禁用' : '启用'}`,
                              onOk: async () => {
                                await stewardModel.stewardHonorEnable({
                                  honorId: record.honorId,
                                  enable: isEnable ? '0' : '1',
                                })
                                searchSteward()
                              },
                            })
                          }}
                        />
                      </Space>
                    )
                  },
                },
              ]}
              pagination={getPaginationProps(stewardForm, stewardTotal)}
            />
          </div>
        </Tabs.TabPane>

        <Tabs.TabPane forceRender tab={'官网合作伙伴'} key={'4'} disabled={!auth('006170001')}>
          <div className={commonStyles.filterSection}>
            <Form
              form={partnerForm}
              initialValues={{pageNum: 1, pageSize: 10, pageable: false}}
              onFinish={searchPartnerList}
              onReset={searchPartnerList}
            >
              <Row gutter={20}>
                <Col span={8}>
                  <Form.Item label={'合作伙伴名称'} name={'partnerName'}>
                    <Input placeholder={'请输入'} allowClear />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label={'状态'} name={'partnerEnable'}>
                    <Select placeholder={'请选择'}>
                      <Select.Option value={1}>启用</Select.Option>
                      <Select.Option value={0}>禁用</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row justify={'end'}>
                <Space>
                  <Button type={'primary'} htmlType={'submit'} onClick={() => partnerForm.setFieldsValue({pageNum: 1})}>
                    查询
                  </Button>
                  <Button htmlType={'reset'}>重置</Button>
                </Space>
              </Row>
            </Form>
          </div>
          <div className={commonStyles.filterSection}>
            <DndContext onDragEnd={onDragEnd}>
              <SortableContext items={partnerList.map(item => item.partnerId)} strategy={verticalListSortingStrategy}>
                <Table
                  dataSource={partnerList}
                  components={{
                    body: {
                      row: TableRow,
                    },
                  }}
                  loading={searchPartnerLoading}
                  rowKey={'partnerId'}
                  columns={[
                    {dataIndex: 'sort', key: 'sort'},
                    {title: '合作伙伴名称', dataIndex: 'partnerName'},
                    {
                      title: '图片',
                      dataIndex: 'partnerLogoUrl',
                      render: text => <AntImage src={getImg(text)} width={76} height={34} />,
                    },
                    {title: '状态', dataIndex: 'partnerEnable', render: value => (value === 1 ? '启用' : '禁用')},
                    {
                      title: '操作',
                      render: (_, record) => {
                        return (
                          <Space>
                            <Button
                              type={'link'}
                              style={{paddingLeft: 0, paddingRight: 0}}
                              onClick={() => {
                                Modal.confirm({
                                  title: '提示',
                                  centered: true,
                                  icon: null,
                                  content: `是否确认${record.partnerEnable === 1 ? '禁用' : '启用'}?`,
                                  onOk: async () => {
                                    await sysModel.sysPartnerEnable({
                                      partnerId: record.partnerId,
                                      partnerEnable: record.partnerEnable === 1 ? 0 : 1,
                                    } as SysPartnerUpsertDTO)
                                    searchPartnerList()
                                  },
                                })
                              }}
                            >
                              {record.partnerEnable === 1 ? '禁用' : '启用'}
                            </Button>
                            <Button
                              type={'link'}
                              style={{paddingLeft: 0, paddingRight: 0}}
                              onClick={() => {
                                setAddPartnerVisible(true)
                                addPartnerForm.setFieldsValue({
                                  partnerId: record.partnerId,
                                  partnerName: record.partnerName,
                                  partnerLogoUrl: record.partnerLogoUrl,
                                })
                              }}
                            >
                              编辑
                            </Button>
                          </Space>
                        )
                      },
                    },
                  ]}
                  pagination={getPaginationProps(partnerForm, partnerTotal)}
                />
              </SortableContext>
            </DndContext>
          </div>
        </Tabs.TabPane>
      </Tabs>

      {/* 公告详情 */}
      <Drawer
        visible={!!noticeInfo.noticeId}
        width={700}
        title={'公告详情'}
        onClose={() => setNoticeInfo({} as SysNoticeVO)}
      >
        <DividerLine style={{marginBottom: 10}} title={'基本信息'} />
        <Descriptions column={2}>
          <Descriptions.Item label={<span style={{color: 'rgba(0, 0, 0, 0.65)'}}>编号</span>}>
            {noticeInfo.noticeId}
          </Descriptions.Item>
          <Descriptions.Item label={<span style={{color: 'rgba(0, 0, 0, 0.65)'}}>标题</span>}>
            {noticeInfo.title}
          </Descriptions.Item>
          <Descriptions.Item label={<span style={{color: 'rgba(0, 0, 0, 0.65)'}}>接收方</span>}>
            {noticeInfo.roleName}
          </Descriptions.Item>
          <Descriptions.Item label={<span style={{color: 'rgba(0, 0, 0, 0.65)'}}>创建时间</span>}>
            {noticeInfo.insertTime}
          </Descriptions.Item>
          <Descriptions.Item label={<span style={{color: 'rgba(0, 0, 0, 0.65)'}}>创建人</span>}>
            {noticeInfo.createName}
          </Descriptions.Item>
          <Descriptions.Item label={<span style={{color: 'rgba(0, 0, 0, 0.65)'}}>发送时间</span>}>
            {noticeInfo.sendTime}
          </Descriptions.Item>
          <Descriptions.Item label={<span style={{color: 'rgba(0, 0, 0, 0.65)'}}>状态</span>}>
            {noticeStatus[noticeInfo.noticeStatus]}
          </Descriptions.Item>
          {noticeInfo.noticeStatus === '3' && (
            <>
              <Descriptions.Item label={<span style={{color: 'rgba(0, 0, 0, 0.65)'}}>撤回人</span>}>
                {noticeInfo.cancelName}
              </Descriptions.Item>
              <Descriptions.Item label={<span style={{color: 'rgba(0, 0, 0, 0.65)'}}>撤回时间</span>}>
                {noticeInfo.cancelTime}
              </Descriptions.Item>
            </>
          )}
        </Descriptions>
        <Descriptions column={1}>
          <Descriptions.Item label={<span style={{color: 'rgba(0, 0, 0, 0.65)'}}>内容</span>}>
            {noticeInfo.content}
          </Descriptions.Item>
        </Descriptions>
        <Descriptions column={1}>
          <Descriptions.Item label={<span style={{color: 'rgba(0, 0, 0, 0.65)'}}>附件</span>}>
            {noticeInfo.attachList?.length ? (
              <Album disabled fileList={noticeInfo.attachList.map(item => ({url: getImg(item), uid: item}))}>
                {null}
              </Album>
            ) : (
              '无'
            )}
          </Descriptions.Item>
        </Descriptions>
      </Drawer>

      {/* 新增partner */}
      <Modal
        title={'合作'}
        visible={addPartnerVisible}
        onCancel={() => setAddPartnerVisible(false)}
        onOk={() => addPartnerForm.submit()}
        afterClose={() => addPartnerForm.resetFields()}
        confirmLoading={addPartnerLoading}
        centered
        width={500}
        zIndex={9999999}
      >
        <Form
          form={addPartnerForm}
          onFinish={async () => {
            const formValues = addPartnerForm.getFieldsValue(true)
            if (!formValues.partnerLogoUrl) return message.warning('请上传照片')
            await sysModel.sysPartnerUpsert(formValues)
            setAddPartnerVisible(false)
            searchPartnerList()
          }}
          labelCol={{flex: '120px'}}
        >
          <Form.Item
            name={'partnerName'}
            label={'合作伙伴名称'}
            rules={[{required: true, message: '请输入合作者名称'}]}
          >
            <Input placeholder={'请输入'} />
          </Form.Item>
          <Form.Item label={'图片'} shouldUpdate={true} required>
            {f => {
              const partnerLogoUrl = f.getFieldValue('partnerLogoUrl')
              return (
                <Space direction={'vertical'}>
                  <Album
                    limit={1}
                    fileList={partnerLogoUrl ? [{url: getImg(partnerLogoUrl), uid: partnerLogoUrl}] : []}
                    accept={'.jpg, .png, .jpeg'}
                    action={baseUrl + '/sys-partner/upload'}
                    onDone={fileList => {
                      f.setFieldsValue({partnerLogoUrl: fileList?.[0]?.imgUrl})
                    }}
                    beforeUpload={(file, fileList) => {
                      return new Promise((resolve, reject) => {
                        const img = new Image()
                        const _URL = URL || webkitURL
                        img.onload = function () {
                          const valid = img.width === 334 && img.height === 120
                          if (!valid) {
                            message.warning('图片尺寸：334*120px！')
                            return
                          } else {
                            resolve()
                          }
                        }
                        img.src = _URL.createObjectURL(file)
                      })
                    }}
                  >
                    {partnerLogoUrl ? null : <UploadButton />}
                  </Album>
                  <span style={{color: 'rgba(0, 0, 0, 0.65)'}}>仅支持上传图片，尺寸：334*120px</span>
                </Space>
              )
            }}
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title={'添加优秀管家'}
        visible={stewardModalVisible}
        bodyStyle={{paddingRight: 57}}
        onCancel={() => setStewardModalVisible(false)}
        onOk={() => stewardModalForm.submit()}
        afterClose={() => stewardModalForm.resetFields()}
        confirmLoading={isStewardModalLoading}
      >
        <Form
          form={stewardModalForm}
          labelCol={{flex: '70px'}}
          onFinish={async () => {
            const values = stewardModalForm.getFieldsValue(true)
            values.honorId
              ? await stewardModel.stewardHonorUpdate(values)
              : await stewardModel.stewardHonorInsert(values)
            message.success('操作成功')
            setStewardModalVisible(false)
            searchSteward()
          }}
        >
          <Form.Item
            label={'管家'}
            shouldUpdate={(prevValues, nextValues) => prevValues.stewardIdList !== nextValues.stewardIdList}
            required
          >
            {f => {
              const stewardIdList = f.getFieldValue('stewardIdList')
              return (
                <Form.Item noStyle name={'stewardIdList'} rules={[{required: true, message: '管家不能为空'}]}>
                  <Select mode={'multiple'} allowClear showArrow placeholder={'请选择'}>
                    {stewardUserList.map(value => (
                      <Select.Option
                        key={value.userId}
                        value={value.userId}
                        disabled={stewardIdList?.length >= 3 && !stewardIdList?.includes(value.userId)}
                      >
                        {`${value.name}（${value.mobile}）`}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              )
            }}
          </Form.Item>
          <Form.Item
            label={'年月'}
            name={'month'}
            getValueFromEvent={(_, dateString) => dateString}
            getValueProps={value => ({value: value ? moment(value) : value})}
            rules={[{required: true, message: '年月不能为空'}]}
          >
            <DatePicker picker={'month'} allowClear style={{width: '100%'}} />
          </Form.Item>
          <Form.Item label={'生效时间'} name={'_begin'} rules={[{required: true, message: '生效时间不能为空'}]}>
            <DatePicker.RangePicker
              allowClear
              style={{width: '100%'}}
              onChange={(_, formatString) =>
                stewardModalForm.setFieldsValue({
                  begin: formatString?.[0],
                  end: formatString?.[1],
                })
              }
            />
          </Form.Item>
          <Form.Item
            label={'描述'}
            name={'desc'}
            rules={[
              {required: true, message: '描述不能为空'},
              {max: 50, message: '不能超过50个字'},
            ]}
          >
            <Input.TextArea rows={4} placeholder={'请输入'} maxLength={50} showCount />
          </Form.Item>
        </Form>
      </Modal>
    </Page>
  )
}

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  'data-row-key': string
}

const TableRow: React.FC<RowProps> = ({children, ...props}) => {
  const {attributes, listeners, setNodeRef, transform, transition, isDragging, setActivatorNodeRef} = useSortable({
    id: props['data-row-key'],
  })

  const style: React.CSSProperties = {
    ...props.style,
    transform: CSS.Transform.toString(transform && {...transform, scaleY: 1})?.replace(
      /translate3d\(([^,]+),/,
      'translate3d(0,'
    ),
    transition,
    ...(isDragging ? {position: 'relative', zIndex: 9999} : {}),
  }

  return (
    <tr {...props} ref={setNodeRef} style={style} {...attributes}>
      {React.Children.map(children, child => {
        if ((child as React.ReactElement).key === 'sort') {
          return React.cloneElement(child as React.ReactElement, {
            children: (
              <MenuOutlined ref={setActivatorNodeRef} style={{touchAction: 'none', cursor: 'move'}} {...listeners} />
            ),
          })
        }
        return child
      })}
    </tr>
  )
}
