import {useCallback} from 'react'
import type {FormInstance, TableProps} from 'antd'
// import {useUpdateEffect} from 'ahooks'

// const SORT_FIELD = 'sort'

/** 驼峰转下划线 */
const toUnderline = (name: string) => name.replace(/([A-Z])/g, '_$1').toLowerCase()

/**
 * 排序 hook
 *
 * @example
 * const [form] = Form.useForm()
 * const sorterProps = useSorter(form)
 *
 * <Table
 *   {...sorterProps}
 *   columns={[
 *     {title: '详细地址', dataIndex: 'address'},
 *     {title: '业主名称', dataIndex: 'ownerName', sorter: true}, // 需要排序的列加上 "sorter: true"
 *     {title: '甲方', dataIndex: 'jiaFang', sorter: true},
 *   ]}
 * />
 *
 */
export default function useSorter(form: FormInstance) {
  // const [tableKey, setTableKey] = useState(0)
  // const sort = form.getFieldValue(SORT_FIELD)
  // useUpdateEffect(() => {
  //   if (sort === undefined) {
  //     setTableKey(prevState => prevState + 1)
  //   }
  // }, [sort])

  const onChange = useCallback<TableProps<any>['onChange']>(
    (pagination, filters, sorter, extra) => {
      if (extra.action === 'sort') {
        const sort = (Array.isArray(sorter) ? sorter : [sorter])
          .filter(value => value.order)
          .map(value => {
            if (typeof value.field === 'string') {
              return `${toUnderline(value.field)} ${value.order.replace(/end$/, '')}`
            }
            throw new Error(`排序字段 ${value.field} 必须为字符串`)
          })
          .join(',')

        form.setFieldsValue({sort})
        form.submit()
      }
    },
    [form]
  )

  return {
    onChange,
    // key: tableKey, // reset 时重置 Table 组件
  }
}
