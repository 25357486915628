import {useState} from 'react'
import {Routers, routers} from '@/pages/layout/AppLayout'
import {useLocation} from 'react-router'

// 寻找与 link 匹配的路由配置
function findLink(routers: Routers[], link: string): Routers {
  for (const value of routers) {
    if (value.link === link) {
      return value
    }
    if (value.sub?.length) {
      const next = findLink(value.sub, link)
      if (next) return next
    }
  }
}

// 寻找上一级路由
// function findUpper(router: Routers | Routers[], link: string) {
//   if (Array.isArray(router)) {
//     return router.map(value => findUpper(value, link)).filter(value => value)[0]
//   }
//   if (router.sub?.some(value => value.link === link)) {
//     return router
//   }
//   if (router.sub?.length) {
//     return findUpper(router.sub, link)
//   }
// }

// todo: 更新渲染逻辑
function splitLink(pathname: string) {
  const ps = (function routePath(nextRoutes: Routers[], paths: Routers[] = []): Routers[] {
    for (const value of nextRoutes) {
      if (value.link === pathname) {
        if (pathname.includes('/')) {
          // 如果以 / 分割，寻找上级页面
          const links = pathname.split('/')
          const endPaths: Routers[] = []
          while (links.length) {
            const path = links.join('/')
            const link = findLink(routers, path)
            if (!link) break
            endPaths.unshift(link)
            links.pop()
          }
          // 不必过滤，因为只有三层
          return [...paths, ...endPaths]
          // return [...paths, ...endPaths.filter(value => value && paths.every(item => item.link !== value.link))]
        }
        return [...paths, value]
      } else if (value.sub?.length) {
        const next = routePath(value.sub, [...paths, value])
        if (next) return next
      }
    }
  })(routers)

  return ps.map(value => ({
    path: value.link,
    breadcrumbName: value.title,
  }))
}

export function useBreadcrumb() {
  const location = useLocation()

  const pathname = location.pathname
  const [routes] = useState(() => splitLink(pathname))

  return routes
}
