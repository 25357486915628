import React, {useCallback, useEffect, useState} from 'react'
import {Page} from '@/components/Page'
import {Breadcrumb} from '@/components/Breadcrumb'
import {Button, Col, Form, Modal, Row, Select, Space, Table} from 'antd'
import commonStyles from '@/assets/styles/common.module.less'
import {getPaginationProps} from '@/common/biz/getPaginationProps'
import {commonModel} from '@/store/models/Common'
import {useAppHistory} from '@/common/hooks/useRouter'
import {stewardModel} from '@/store/models/Steward'
import {usePermission} from '@/components/Permission'
import {useFocusEffect} from '@/common/hooks/useFocusEffect'

export default function PerformanceRuleSetList() {
  const [auth] = usePermission()
  const history = useAppHistory()
  const [form] = Form.useForm()
  const [cityList, setCityList] = useState<CityDataProps[]>([])
  const [list, setList] = useState<StewardSalaryRuleListVO[]>([])
  const [total, setTotal] = useState<number>(0)
  const [openAppList, setOpenAppList] = useState<StewardOpenAppVO[]>([])
  const loading = stewardModel.stewardSalaryRuleList.useLoading()

  useEffect(() => {
    commonModel
      .queryCityCode({type: '2', parentCode: null})
      .then(({response: {data}}) => setCityList(data.map(item => ({...item, key: item.code}))))

    stewardModel
      .stewardOpenAppList({
        pageable: false,
        pageNum: 1,
        pageSize: 1,
        appStatus: 1,
      } as StewardAppOpenListDTO)
      .then(({response: {data}}) => {
        setOpenAppList(data)
      })
  }, [])

  const search = useCallback(() => {
    const formValues = form.getFieldsValue(true)
    stewardModel.stewardSalaryRuleList(formValues).then(({response: {data, total}}) => {
      setList(data)
      setTotal(total)
    })
  }, [form])

  useEffect(() => {
    search()
  }, [search])

  useFocusEffect(() => {
    search()
  })

  return (
    <Page
      breadcrumb={
        <Breadcrumb
          right={
            <Space>
              {auth('002203001') && (
                <Button
                  onClick={() => history.push({pathname: '/performance-rule-set-list/set', state: {ruleId: '1'}})}
                >
                  默认配置
                </Button>
              )}
              {auth('003203001') && (
                <Button onClick={() => history.push({pathname: '/performance-rule-set-list/set'})}>新增配置</Button>
              )}
            </Space>
          }
        />
      }
    >
      <div className={commonStyles.filterSection}>
        <Form form={form} onFinish={search} onReset={search} initialValues={{pageNum: 1, pageSize: 10, pageable: true}}>
          <Row>
            <Col span={7}>
              <Form.Item label={'适用城市'} name={'cityCodeList'}>
                <Select
                  options={cityList}
                  fieldNames={{label: 'name', value: 'code'}}
                  placeholder={'请选择'}
                  showSearch
                  mode={'multiple'}
                  filterOption={(inputValue, option: any) => option.name?.indexOf(inputValue) > -1}
                  allowClear
                />
              </Form.Item>
            </Col>
            <Col span={7} offset={1}>
              <Form.Item label={'适用渠道'} name={'appId'}>
                <Select
                  options={openAppList}
                  fieldNames={{value: 'appId', label: 'appName'}}
                  placeholder={'请选择'}
                  allowClear
                />
              </Form.Item>
            </Col>
          </Row>
          <Row justify={'end'}>
            <Space>
              <Button type={'primary'} htmlType={'submit'} onClick={() => form.setFieldsValue({pageNum: 1})}>
                查询
              </Button>
              <Button htmlType={'reset'}>重置</Button>
            </Space>
          </Row>
        </Form>
      </div>
      <div className={commonStyles.tableSection}>
        <Table
          rowKey={'ruleId'}
          loading={loading}
          columns={[
            {title: '编号', dataIndex: 'ruleId'},
            {title: '适用城市', dataIndex: 'cityNameJoint'},
            {title: '适用渠道', dataIndex: 'appNameJoint'},
            {title: '创建人', dataIndex: 'insertByName'},
            {title: '创建时间', dataIndex: 'insertTime'},
            {
              title: '操作',
              dataIndex: 'ruleId',
              render: value => {
                return (
                  <Space>
                    {auth('001203002') && (
                      <Button
                        style={{padding: 0}}
                        type={'link'}
                        onClick={() =>
                          history.push({
                            pathname: '/performance-rule-set-list/set',
                            state: {ruleId: value, viewOnly: true},
                          })
                        }
                      >
                        查看
                      </Button>
                    )}
                    {auth('002203002') && (
                      <Button
                        style={{padding: 0}}
                        type={'link'}
                        onClick={() =>
                          history.push({pathname: '/performance-rule-set-list/set', state: {ruleId: value}})
                        }
                      >
                        编辑
                      </Button>
                    )}
                    {auth('004203001') && (
                      <Button
                        style={{padding: 0}}
                        type={'link'}
                        onClick={() => {
                          Modal.confirm({
                            title: '提示',
                            icon: null,
                            centered: true,
                            content: '是否确定删除？',
                            onOk: async () => {
                              await stewardModel.stewardSalaryRuleDelete({ruleId: value})
                              search()
                            },
                          })
                        }}
                      >
                        删除
                      </Button>
                    )}
                  </Space>
                )
              },
            },
          ]}
          dataSource={list}
          pagination={getPaginationProps(form, total)}
        />
      </div>
    </Page>
  )
}
