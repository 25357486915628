import React, {useState} from 'react'
import {Button, Form, Input, Modal, Row, Space, Typography} from 'antd'
import {stewardModel} from '@/store/models/Steward'

export default function GuanYuBindMobile({
  orderId,
  visible,
  onCancel,
}: {
  orderId: string
  visible: boolean
  onCancel: () => void
}) {
  const [mobileForm] = Form.useForm()
  const [mobileNumble, setMobileNumble] = useState('')

  return (
    <Modal
      title={'绑定号码'}
      open={visible}
      centered
      width={500}
      footer={null}
      onCancel={() => onCancel?.()}
      destroyOnClose
      afterClose={() => {
        mobileForm.resetFields()
        setMobileNumble('')
      }}
    >
      <Form
        form={mobileForm}
        onFinish={async () => {
          await mobileForm.validateFields()
          const mobileInput = mobileForm.getFieldValue('mobileInput')
          const {
            response: {data},
          } = await stewardModel.guanyuGetRepairUserVirNoItnput({orderId, mobileInput})
          setMobileNumble(data)
        }}
      >
        {mobileNumble !== '' ? (
          <Typography.Text>{`请使用${mobileForm.getFieldValue('mobileInput')}拨打${
            mobileNumble ?? ''
          }`}</Typography.Text>
        ) : (
          <Form.Item label={'号码'} name={'mobileInput'} rules={[{required: true, message: '请输入号码'}]}>
            <Input placeholder={'请输入'} />
          </Form.Item>
        )}
        <Row justify={'end'}>
          <Space>
            {mobileNumble ? (
              <Button type={'primary'} onClick={() => onCancel?.()}>
                确定
              </Button>
            ) : (
              <>
                <Button onClick={() => onCancel?.()}>取消</Button>
                <Button type={'primary'} htmlType={'submit'}>
                  确定
                </Button>
              </>
            )}
          </Space>
        </Row>
      </Form>
    </Modal>
  )
}
