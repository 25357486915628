import {getImg, isFile, isUploaded} from '@/utils/util'
import {message} from 'antd'

export function onAlbumUploadEvent(event) {
  return event.fileList?.reduce((prev, item) => {
    if (isFile(item) && item.status === 'done') {
      if (item.response?.code === 200) {
        prev.push(item.response?.data)
      } else {
        // 未上传成功丢掉该项目
        message.error(item.response?.msg)
      }
      return prev
    }
    // 已上传的文件取 uid
    if (isUploaded(item)) return [...prev, item]
    // file 对象原封不动
    return [...prev, item]
  }, [])
}

export function onAlbumUploadProps(value) {
  return {
    fileList: value?.map(item =>
      !!item.imgId && !!item.imgUrl ? {uid: item.imgId, url: getImg(item.imgUrl), ...item} : item
    ),
  }
}

export function onAlbumUploadSingleEvent(event) {
  const fileList = event.fileList ?? []
  const [item = {}] = fileList
  if (isFile(item) && item.status === 'done') {
    if (item.response?.code === 200) {
      return item.response?.data?.imgUrl
    } else {
      // 未上传成功丢掉该项目
      message.error(item.response?.msg)
      return null
    }
  }
  return null
}

export function onAlbumUploadSingleProps(value) {
  // console.log('value--------', value)
  return {
    fileList: typeof value === 'string' ? [{uid: value, url: value}] : typeof value === 'object' ? value : [],
  }
}
