import React, {useMemo, useState} from 'react'
import {Page} from '@/components/Page'
import {Tabs} from 'antd'
import {usePermission} from '@/components/Permission'
import PropertyMap from '@/pages/steward/MapService/components/PropertyMap'
import MapSearchOrderList from '@/pages/steward/MapService/components/MapSearchOrderList'
import MapServiceTrack from '@/pages/steward/MapService/components/MapServiceTrack'
import styles from './index.module.less'

export default function MapService() {
  const [auth] = usePermission()

  const defaultActiveKey = useMemo(() => {
    const permission = {
      '001124001': '1',
      '001131001': '2',
      '001051002': '3',
    }
    const key = permission[Object.keys(permission).find(item => auth(item))]
    return permission[key]
  }, [auth])

  const [key, setKey] = useState(defaultActiveKey)

  return (
    <Page style={{height: 'calc(100vh - 102px)'}}>
      <Tabs
        activeKey={key}
        className={styles.tabs}
        onChange={index => setKey(index)}
        items={[
          auth('001124001') && {
            key: '1',
            label: '地图找单',
            children: <MapSearchOrderList />,
          },
          auth('001131001') && {
            key: '2',
            label: '服务轨迹',
            children: <MapServiceTrack />,
            forceRender: true,
          },
          auth('001051002') && {
            key: '3',
            label: '房源地图',
            children: <PropertyMap />,
          },
        ]}
      />
    </Page>
  )
}
