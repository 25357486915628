import {Page} from '@/components/Page'
import {Descriptions, PageHeader, Space, Timeline} from 'antd'
import React, {useEffect, useState} from 'react'
import commonStyles from '@/assets/styles/common.module.less'
import DividerLine from '@/pages/property/component/DividerLine'
import {adminModel} from '@/store/models/Admin'
import {useAppHistory, useAppLocation} from '@/common/hooks/useRouter'
import {useCodeSync} from '@/store/models/Common'

export default function CRMLookDetail() {
  const history = useAppHistory()
  const location = useAppLocation<'/crm-look/detail'>()
  const visitId = location.state?.visitId
  const [detail, setDetail] = useState({} as CondoCustomerVisitVO)
  const visitStatus = useCodeSync('condo.cms.visitStatus')
  const gender = useCodeSync('gender')
  const visitResult = useCodeSync('condo.cms.visitResult')
  const recordSourceCode = useCodeSync('condo.cms.recordSource')

  useEffect(() => {
    adminModel.adminCondoCustomerVisitGet({visitId}).then(({response: {data}}) => {
      setDetail(data)
    })
  }, [visitId])

  return (
    <Page header={<PageHeader title={'带看单详情'} ghost={false} onBack={() => history.goBack()} />}>
      <div className={commonStyles.rowSection}>
        <div className={commonStyles.filterSection} style={{flex: 1}}>
          <DividerLine title='基本信息' bottom={15} />
          <Descriptions column={2}>
            <Descriptions.Item label={'编号'}>{detail.visitId}</Descriptions.Item>
            <Descriptions.Item label={'带看单来源'}>{recordSourceCode[detail.recordSource]}</Descriptions.Item>
            <Descriptions.Item label={'客户ID'}>{detail.customerId}</Descriptions.Item>
            <Descriptions.Item label={'性别'}>{gender[detail.customerGender]}</Descriptions.Item>
            <Descriptions.Item label={'姓名'}>{detail.customerName}</Descriptions.Item>
            <Descriptions.Item label={'接单人'}>{detail.ownerName}</Descriptions.Item>
            <Descriptions.Item label={'手机号'}>{detail.customerMobile ?? '/'}</Descriptions.Item>
            <Descriptions.Item label={'创建人'}>{detail.creatorName}</Descriptions.Item>
            <Descriptions.Item label={'创建时间'}>{detail.insertTime}</Descriptions.Item>
            <Descriptions.Item label={'预约公寓'}>{detail.appointCondoName}</Descriptions.Item>
            <Descriptions.Item label={'预约时间'}>{detail.appointTime}</Descriptions.Item>
            <Descriptions.Item label={'预约房间'}>{detail.appointRoom ?? '/'}</Descriptions.Item>
            <Descriptions.Item label={'接收状态'}>
              {detail.acceptStatus === '0' ? '未接收' : '已接收'}
            </Descriptions.Item>
            <Descriptions.Item label={'带看单状态'}>{visitStatus[detail.visitStatus]}</Descriptions.Item>
            <Descriptions.Item label={'带看备注'}>{detail.createRemark ?? '/'}</Descriptions.Item>
            {detail.visitResult && (
              <>
                <Descriptions.Item label={'结果'}>{visitResult[detail.visitResult]}</Descriptions.Item>
              </>
            )}
            {detail.visitResult === '4' && (
              <>
                <Descriptions.Item label={'预定公寓'}>{detail.finalCondoName}</Descriptions.Item>
                <Descriptions.Item label={'预定房间'}>{detail.finalRoom}</Descriptions.Item>
                <Descriptions.Item label={'预定金'}>{detail.finalReservePrice}</Descriptions.Item>
              </>
            )}

            {detail.visitResult === '5' && (
              <>
                <Descriptions.Item label={'签约公寓'}>{detail.finalCondoName}</Descriptions.Item>
                <Descriptions.Item label={'签约房间'}>{detail.finalRoom}</Descriptions.Item>
                <Descriptions.Item label={'租约编号'}>{detail.finalContractId}</Descriptions.Item>
              </>
            )}

            {['2', '3'].includes(detail.visitResult) && (
              <Descriptions.Item label={'原因'}>{detail.visitResultReason}</Descriptions.Item>
            )}
            {detail.visitStatus !== '1' && (
              <Descriptions.Item label={detail.visitStatus === '2' ? '关闭原因' : '结果备注'}>
                {detail.remark}
              </Descriptions.Item>
            )}
            {/* {detail.visitStatus === '3' && (
              <Descriptions.Item label={'带看人'}>{detail.finalTakerName}</Descriptions.Item>
            )} */}
          </Descriptions>
        </div>
        <div className={commonStyles.filterSection} style={{width: 300, marginLeft: 20}}>
          <DividerLine title='带看追踪' bottom={15} />
          <Timeline>
            {detail.recordList?.map((item, index) => (
              <Timeline.Item
                key={index}
                dot={
                  <div
                    style={{width: 16, height: 16, background: index === 0 ? '#10BBB3' : '#D8D8D8', borderRadius: 8}}
                  />
                }
              >
                <Space direction={'vertical'}>
                  <span style={{fontWeight: 'bold'}}>{visitStatus[item.visitStatus]}</span>
                  {!!item.visitResult && <span style={{color: '#909399'}}>结果：{visitResult[item.visitResult]}</span>}
                  {/* {item.visitStatus === '3' && <span style={{color: '#909399'}}>带看人：{item.takerName}</span>} */}
                  {['2', '3'].includes(item.visitResult) && (
                    <span style={{color: '#909399'}}>原因：{item.visitResultReason}</span>
                  )}
                  {item.remark && (
                    <span style={{color: '#909399'}}>
                      {item.visitStatus === '2' ? '关闭原因：' : '结果备注：'}
                      {item.remark}
                    </span>
                  )}
                  {item.visitStatus === '1' && (
                    <>
                      <span style={{color: '#909399'}}>预约时间：{item.appointTime}</span>
                      <span style={{color: '#909399'}}>预约公寓：{item.condoName}</span>
                      <span style={{color: '#909399'}}>预约房间：{item.room ?? '/'}</span>
                    </>
                  )}
                  {item.visitResult === '4' && (
                    <>
                      <span style={{color: '#909399'}}>预定公寓：{item.condoName}</span>
                      <span style={{color: '#909399'}}>预定房间：{item.room}</span>
                      <span style={{color: '#909399'}}>预定金：{item.reservePrice}</span>
                    </>
                  )}
                  {item.visitResult === '5' && (
                    <>
                      <span style={{color: '#909399'}}>签约公寓：{item.condoName}</span>
                      <span style={{color: '#909399'}}>签约房间：{item.room}</span>
                      <span style={{color: '#909399'}}>租约编号：{item.contractId}</span>
                    </>
                  )}
                  <span style={{color: '#909399'}}>操作人：{item.creatorName}</span>
                  <span style={{color: '#909399'}}>{item.updateTime}</span>
                </Space>
              </Timeline.Item>
            ))}
          </Timeline>
        </div>
      </div>
    </Page>
  )
}
