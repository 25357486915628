import React, {useState} from 'react'
import {Button, Space, Tabs} from 'antd'
import {Page} from '@/components/Page'
import {Breadcrumb} from '@/components/Breadcrumb'
import commonStyles from '@/assets/styles/common.module.less'
import {usePermission} from '@/components/Permission'
import StewardMyApproval from '@/pages/steward/StewardApprovalList/components/StewardMyApproval'
import StewardAllApproval from '@/pages/steward/StewardApprovalList/components/StewardAllApproval'
import StewardCopyApproval from '@/pages/steward/StewardApprovalList/components/StewardCopyApproval'
import StewardApprovalDetail from '@/pages/steward/StewardApprovalList/components/StewardApprovalDetail'
import StewardTakeLeave from '@/pages/steward/StewardApprovalList/components/StewardTakeLeave'

type RefProps = {refresh: () => void; export?: () => any}

export default function StewardApprovalList() {
  const [auth] = usePermission()
  const [key, setKey] = useState<string>('1')

  const myRef = React.useRef<RefProps>(null)
  const allRef = React.useRef<RefProps>(null)
  const copyRef = React.useRef<RefProps>(null)
  const vacationRef = React.useRef<{export: () => void}>(null)

  const [approvalOrderId, setApprovalOrderId] = useState<string>('')

  return (
    <Page
      breadcrumb={
        <Breadcrumb
          right={
            <Space>
              {key === '2' && auth('007197001') && (
                <Button
                  onClick={() => {
                    allRef.current?.export()
                  }}
                >
                  导出
                </Button>
              )}

              {key === '4' && auth('007021002') && (
                <Button
                  onClick={() => {
                    vacationRef.current?.export()
                  }}
                >
                  导出
                </Button>
              )}
            </Space>
          }
        />
      }
    >
      <Tabs className={commonStyles.tabs} onChange={value => setKey(value)}>
        <Tabs.TabPane tab={'我的审批'} key={'1'}>
          <StewardMyApproval ref={myRef} onCheck={value => setApprovalOrderId(value)} />
        </Tabs.TabPane>
        {auth('001197002') && (
          <Tabs.TabPane tab={'全部审批'} key={'2'}>
            <StewardAllApproval ref={allRef} onCheck={value => setApprovalOrderId(value)} />
          </Tabs.TabPane>
        )}
        <Tabs.TabPane tab={'抄送我的'} key={'3'}>
          <StewardCopyApproval ref={copyRef} onCheck={value => setApprovalOrderId(value)} />
        </Tabs.TabPane>
        {auth('001021001') && (
          <Tabs.TabPane tab={'管家请假记录'} key={'4'}>
            <StewardTakeLeave ref={vacationRef} />
          </Tabs.TabPane>
        )}
      </Tabs>

      {/* 审批详情  */}
      <StewardApprovalDetail
        approvalOrderId={approvalOrderId}
        onClose={() => {
          setApprovalOrderId('')
        }}
        approval={true}
        onSuccess={() => {
          switch (key) {
            case '1':
              myRef.current?.refresh()
              break
            case '2':
              allRef.current?.refresh()
              break
            case '3':
              copyRef.current?.refresh()
              break
          }
        }}
      />
    </Page>
  )
}
