import React, {useEffect, useState} from 'react'
import {sysModel} from '@/store/models/Sys'
import {Button, message, Transfer} from 'antd'
import {commonModel} from '@/store/models/Common'

export default function SysDistrictConfig() {
  const [targetKeys, setTargetKeys] = useState<string[]>([])
  const [cityList, setCityList] = useState<CityDataProps[]>([])

  useEffect(() => {
    commonModel
      .queryCityCode({type: '2', parentCode: null})
      .then(({response: {data}}) => setCityList(data.map(item => ({...item, key: item.code}))))
  }, [])

  useEffect(() => {
    sysModel.sysConfigGet({k: 'CONFIG_SYS_CITY'}).then(({response: {data}}) => {
      setTargetKeys(data?.city?.map(item => item.id) || [])
    })
  }, [])

  const saveLoading = sysModel.sysConfigSave.useLoading()

  return (
    <div style={{backgroundColor: '#fff', display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
      <Transfer
        dataSource={cityList}
        render={item => item.name}
        titles={['可配置城市', '已配置城市']}
        showSearch
        filterOption={(inputValue, option) => {
          return option?.name?.indexOf(inputValue) !== -1
        }}
        targetKeys={targetKeys}
        onChange={targetKeys1 => setTargetKeys(targetKeys1)}
        style={{width: '50%', marginTop: '100px'}}
        listStyle={{width: '100%', height: '600px'}}
      />
      <div style={{margin: '40px'}}>
        <Button
          type={'primary'}
          loading={saveLoading}
          onClick={async () => {
            await sysModel.sysConfigSave({
              k: 'CONFIG_SYS_CITY',
              v: {
                city: cityList
                  .filter(item => targetKeys.includes(item.code))
                  .map(item => ({label: item.name, id: item.code})),
              },
            })
            message.success('保存成功！')
          }}
        >
          保存
        </Button>
      </div>
    </div>
  )
}
