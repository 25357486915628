import React, {useEffect, useRef, useState} from 'react'
import {Button, DatePicker, Form, Input, message, Modal, PageHeader, Select, Space, Upload} from 'antd'
import {ContainerOutlined} from '@ant-design/icons'
import {Mapview} from '@/components/Map'
import MapLocation from '@/assets/icons/map_location.svg'
import {getImg} from '@/utils/util'
import {userModel} from '@/store/models/User'
import {Album, UploadButton} from '@/components/Album'
import {baseUrl} from '@/common/config'
import {sysUserModel} from '@/store/models/SysUser'
import {projectModel} from '@/store/models/Project'
import moment from 'moment'
import {Page} from '@/components/Page'
import {useAppHistory} from '@/common/hooks/useRouter'

const {Dragger} = Upload
const {Option} = Select
const {RangePicker} = DatePicker

type EngineeringAddProps = {
  operate: 'add' | 'edit' | 'check'
  workCode?: string
}

// 校验规则
function certNoValid(rule: any, value: any, callback: Function) {
  const certNoReg = /^[1-9]\d{5}(18|19|20|(3\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/
  if (value && !certNoReg.test(value)) return callback('证件号格式不正确')
  callback()
}

function mobileValid(rule: any, value: any, callback: Function) {
  const mobileReg = /^1[0-9]{10}$/
  if (value && !mobileReg.test(value)) return callback('手机号格式不正确')
  callback()
}

export default function EngineeringAdd(props: EngineeringAddProps) {
  const {operate = 'add', workCode} = props
  const history = useAppHistory()
  const token = userModel.useData(data => data.token)
  const readonly = operate === 'check'

  const [form] = Form.useForm()
  const [mapForm] = Form.useForm()

  const mapRef = useRef(null)
  const [mapVisible, setMapVisible] = useState(false)

  const insertLoading = projectModel.projectAdminWorkUpsert.useLoading()

  const [userList, setUserList] = useState<SysUserVO[]>([])
  useEffect(() => {
    // 查工程部的人
    sysUserModel.querySysUserList({status: '1', orgId: '0005'}).then(({response: {data}}) => setUserList(data))
  }, [])

  useEffect(() => {
    if (workCode && ['check', 'edit'].includes(operate)) {
      projectModel.projectAdminWorkInfo({workCode}).then(({response: {data}}) => {
        form.setFieldsValue({
          address: data.address,
          clientCertName: data.clientCertName,
          clientMobile: data.clientMobile,
          clientName: data.clientName,
          lat: data.lat,
          lng: data.lng,
          memberList: data.memberList,
          remark: data.remark,
          workBeginDate: data.workBeginDate,
          workCode: data.workCode,
          workEndDate: data.workEndDate,
          workFileIdList: data.workFileIdList?.map(item => item.workFileId),
          workImgIdList: data.workImgIdList?.map(item => item.workFileId),
          workName: data.workName,
          /** 以下为自定义字段 */
          workDate: [moment(data.workBeginDate), moment(data.workEndDate)],
          workFileList: data.workFileIdList?.map(item => ({
            name: item.fileName,
            attachFileName: item.fileName,
            attachUrl: item.url,
            imgId: item.workFileId,
            url: getImg(item.url),
          })),
          _workImgList: data.workImgIdList?.map(item => ({
            url: getImg(item.url),
            uid: item.workFileId,
          })),
          _address: `${data.lng},${data.lat}`,
        })
      })
    }
  }, [form, operate, workCode])

  // add 是页面，其他操作作为组件
  return (
    <Page
      {...(operate === 'add'
        ? {header: <PageHeader title={'新增工程'} ghost={false} onBack={() => history.goBack()} />}
        : {breadcrumb: null})}
    >
      <div
        style={
          operate === 'add'
            ? {backgroundColor: '#fff', padding: '20px 0'}
            : {backgroundColor: '#fff', padding: '20px 0', margin: '0 -20px'}
        }
      >
        <Form
          form={form}
          labelCol={{flex: '80px'}}
          style={{width: '50%', margin: '0 auto'}}
          onFinish={async () => {
            const formValues = form.getFieldsValue(true)
            // console.log('formValuesformValues', formValues)
            const {workImgIdList = [], workFileList = []} = formValues
            await projectModel.projectAdminWorkUpsert({
              ...formValues,
              workImgIdList,
              workFileIdList: workFileList.map(item => item.imgId),
            })
            message.success('保存成功！')
            history.goBack()
          }}
          onReset={() => history.goBack()}
        >
          <Form.Item name={'workName'} label={'名称'} rules={[{required: true}]}>
            <Input
              placeholder={'请输入工程名称'}
              maxLength={20}
              disabled={operate === 'edit'}
              readOnly={readonly}
              allowClear
            />
          </Form.Item>
          <Form.Item name={'_address'} label={'地点'} rules={[{required: true, message: '请选择地点'}]}>
            <Input
              readOnly
              placeholder={'请选择地点'}
              onClick={
                readonly
                  ? null
                  : () => {
                      const {lng, lat, address} = form.getFieldsValue(['lat', 'lng', 'address'])
                      if (!!lng && !!lat) {
                        setTimeout(() => mapRef.current?.panTo(lng, lat), 1000)
                        mapForm.setFieldsValue({lng, lat, _address: address})
                      }
                      setMapVisible(true)
                    }
              }
            />
          </Form.Item>
          <Form.Item name={'address'} label={'详细地址'} rules={[{required: true}]}>
            <Input placeholder={'请输入详细地址'} maxLength={50} allowClear readOnly={readonly} />
          </Form.Item>
          <Form.Item name={'workDate'} label={'工期'} rules={[{required: true}]}>
            <RangePicker
              onChange={(_, format) => {
                form.setFields([
                  {name: 'workBeginDate', value: format?.[0]},
                  {name: 'workEndDate', value: format?.[1]},
                ])
              }}
              style={{pointerEvents: readonly ? 'none' : 'auto'}}
            />
          </Form.Item>
          <Form.Item name={'clientName'} label={'甲方名称'}>
            <Input placeholder={'请输入甲方名称'} maxLength={20} readOnly={readonly} allowClear />
          </Form.Item>
          <Form.Item name={'clientCertName'} label={'身份证号码'} rules={[{validator: certNoValid}]}>
            <Input placeholder={'请输入身份证号码'} maxLength={18} allowClear readOnly={readonly} />
          </Form.Item>
          <Form.Item name={'clientMobile'} label={'电话'} rules={[{validator: mobileValid}]}>
            <Input placeholder={'请输入电话'} maxLength={11} allowClear readOnly={readonly} />
          </Form.Item>
          <Form.Item name={'remark'} label={'备注信息'}>
            <Input.TextArea placeholder={'请输入备注信息'} allowClear maxLength={200} readOnly={readonly} />
          </Form.Item>
          <Form.Item label={'施工图片'} shouldUpdate>
            {from1 => {
              const _workImgList = from1.getFieldValue('_workImgList') ?? []
              // console.log('_workImgList', _workImgList)
              return (
                <Album
                  multiple
                  fileList={_workImgList}
                  accept={'.jpg, .jpeg, .png'}
                  action={baseUrl + '/project/admin/file/upload'}
                  onDone={fileList => {
                    // console.log('fileListfileList', fileList)
                    const resList = fileList.map(item => {
                      if (item.status === 'done') {
                        return {
                          url: getImg(item.response?.data?.imgUrl),
                          uid: item.response?.data?.imgId,
                        }
                      } else return item
                    })
                    from1.setFieldsValue({
                      _workImgList: resList,
                      workImgIdList: resList.map(item => item.uid),
                    })
                  }}
                  disabled={readonly}
                >
                  <UploadButton />
                </Album>
              )
            }}
          </Form.Item>
          <Form.Item
            label={'文件'}
            name={'workFileList'}
            valuePropName={'fileList'}
            style={{pointerEvents: 'auto'}}
            getValueFromEvent={e => {
              return e?.fileList?.map(value => ({
                ...value,
                attachFileName: value.attachFileName ?? value.response?.data?.filename,
                attachUrl: value.attachUrl ?? value.response?.data?.imgUrl,
                imgId: value.imgId ?? value.response?.data?.imgId,
                url: value.url ?? (value.response?.data?.imgUrl ? getImg(value.response?.data?.imgUrl) : undefined),
              }))
            }}
          >
            <Dragger
              name='file'
              multiple
              headers={{'nyg8-auth': token}}
              action={baseUrl + '/project/admin/file/upload'}
              style={{pointerEvents: readonly ? 'none' : 'auto'}}
            >
              <ContainerOutlined style={{color: '#00B7AE', fontSize: '36px'}} />
              <p>点击或将文件拖拽到这里上传</p>
              <p>支持扩展名：.rar .zip .doc .docx .pdf .jpg...</p>
            </Dragger>
          </Form.Item>
          <Form.Item name={'memberList'} label={'人员配置'} rules={[{required: true, message: '请选择人员'}]}>
            <Select
              placeholder={'请选择'}
              mode={'multiple'}
              style={{pointerEvents: readonly ? 'none' : 'auto'}}
              allowClear
            >
              {userList.map(item => (
                <Option key={item.userId} value={item.userId}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label={' '} colon={false}>
            <Space>
              {operate !== 'check' && (
                <Button type={'primary'} htmlType={'submit'} loading={insertLoading}>
                  保存
                </Button>
              )}
              <Button htmlType={'reset'}>返回</Button>
            </Space>
          </Form.Item>
        </Form>
      </div>

      <Modal
        centered
        title={'选择地点'}
        visible={mapVisible}
        destroyOnClose
        onOk={() => mapForm.submit()}
        onCancel={() => setMapVisible(false)}
      >
        <Form
          form={mapForm}
          onFinish={() => {
            const {lat, lng, address} = mapForm.getFieldsValue(true)
            form.setFieldsValue({lng, lat, address, _address: `${lng},${lat}`})
            setMapVisible(false)
          }}
          // onReset={() => setMapVisible(false)}
        >
          <Form.Item name={'_address'} label={'地图选点'} rules={[{required: true, message: '选点地址不能为空'}]}>
            <Input
              allowClear
              placeholder={'输入搜索地址，回车搜索'}
              onPressEnter={event => {
                event.preventDefault()
                // console.log('eventevent', event)
                const _address = mapForm.getFieldValue('_address')
                if (_address) mapRef.current?.panToByPath(_address)
              }}
            />
          </Form.Item>
          <Form.Item>
            <div style={{position: 'relative'}}>
              <Mapview
                style={{height: '294px'}}
                ref={mapRef}
                onMapChange={event => {
                  // console.log('event', event)
                  mapForm.setFieldsValue({lng: event.point.lng, lat: event.point.lat, address: event.address})
                }}
              />
              <img src={MapLocation} alt={'icon'} className={'edit-site-location'} />
            </div>
          </Form.Item>
        </Form>
      </Modal>
    </Page>
  )
}
