import React, {useCallback, useState} from 'react'
import {Page} from '@/components/Page'
import {Breadcrumb} from '@/components/Breadcrumb'
import {Button, DatePicker, Form, message, Modal, Popover, Row, Space, Table, Tabs, Upload} from 'antd'
import commonStyles from '@/assets/styles/common.module.less'
import {uploadExcelData} from '@/components/UploadExcel'
import {financeModel} from '@/store/models/Finance'
import moment from 'moment'
import {baseUrl} from '@/common/config'
import {downloadBlob} from '@/utils/util'
import locale from 'antd/es/date-picker/locale/zh_CN'
import {useAppHistory} from '@/common/hooks/useRouter'

const {TabPane} = Tabs

const importTitleCode = {'1': '导入站点数据', '2': '导入管家人工', '3': '导入保洁人工'}
const importUrlCode = {
  '1': '/finance-report/import/template/warehouse',
  '2': '/finance-report/import/template/steward',
  '3': '/finance-report/import/template/clean',
}
const importTemplateCode = {'1': '站点数据模板.xlc', '2': '管家人工模板.xlc', '3': '保洁人工模板.xlc'}
const uploadUrlCode = {
  '1': '/finance-report/import/warehouse',
  '2': '/finance-report/import/steward',
  '3': '/finance-report/import/clean',
}

function TableHeader({code, title}: {code: string; title: string}) {
  return (
    <Space direction={'vertical'} align={'center'} size={0}>
      <span style={{color: '#ff4d4f'}}>{code}</span>
      <span>{title}</span>
    </Space>
  )
}

export default function OperationReport() {
  const history = useAppHistory()
  const [tabIndex, setTabIndex] = useState('1')

  // 客户维度
  const [form1] = Form.useForm()
  const [userList, setUserList] = useState<FinancialReportVO[]>([])
  const [userTotal, setUserTotal] = useState({} as FinancialReportVO)
  const [userPageSize, setUserPageSize] = useState(10)
  const queryFinanceReportUser = useCallback(async () => {
    const formValues = form1.getFieldsValue(true)
    if (!formValues.monthBegin || !formValues.monthEnd) return message.warn('请选择开始月份和结束月份')
    const {
      response: {data: data1},
    } = await financeModel.financeReportUserTotal({...formValues})
    if (data1) {
      setUserTotal(data1)
    } else setUserTotal({} as FinancialReportVO)

    const {
      response: {data: data2},
    } = await financeModel.financeReportUser({...formValues})
    setUserList(data1 ? data2.concat([{...data1, userName: '总计'}]) : data2)
  }, [form1])

  // 站点维度
  const [form2] = Form.useForm()
  const [warehouseList, setWarehouseList] = useState<FinancialReportVO[]>([])
  const [warehouseTotal, setWarehouseTotal] = useState({} as FinancialReportVO)
  const [warehousePageSize, setWarehousePageSize] = useState(10)
  const queryFinanceReportWarehouse = useCallback(async () => {
    const formValues = form2.getFieldsValue(true)
    if (!formValues.monthBegin || !formValues.monthEnd) return message.warn('请选择开始月份和结束月份')
    const {
      response: {data: data1},
    } = await financeModel.financeReportWarehouseTotal({...formValues})
    if (data1) {
      setWarehouseTotal(data1)
    } else setWarehouseTotal({} as FinancialReportVO)

    const {
      response: {data: data2},
    } = await financeModel.financeReportWarehouse({...formValues})
    setWarehouseList(data1 ? data2.concat([{...data1, warehouseName: '总计'}]) : data2)
  }, [form2])

  const [form3] = Form.useForm()
  const [open, setOpen] = useState(false)
  const [mode, setMode] = useState<'1' | '2' | '3'>(null)
  const [upLoading, setUpLoading] = useState(false)

  const columns = [
    {
      title: <TableHeader code={'D=A+B+C'} title={'收入小计'} />,
      dataIndex: 'inSubtotal',
      sorter: {
        compare: (a, b) => a.inSubtotal - b.inSubtotal,
      },
    },
    {
      title: <TableHeader code={'E'} title={'材料成本'} />,
      dataIndex: 'materialCost',
      sorter: {
        compare: (a, b) => a.materialCost - b.materialCost,
      },
    },
    {
      title: <TableHeader code={'F'} title={'维修工资成本'} />,
      dataIndex: 'stewardCost',
      sorter: {
        compare: (a, b) => a.stewardCost - b.stewardCost,
      },
    },
    {
      title: <TableHeader code={'G'} title={'保洁工资成本'} />,
      dataIndex: 'cleanCost',
      sorter: {
        compare: (a, b) => a.cleanCost - b.cleanCost,
      },
    },
    {
      title: <TableHeader code={'H'} title={'站点租金'} />,
      dataIndex: 'warehouseCost',
      sorter: {
        compare: (a, b) => a.warehouseCost - b.warehouseCost,
      },
    },
    {
      title: <TableHeader code={'I=E+F+G+H'} title={'成本小计'} />,
      dataIndex: 'outSubtotal',
      sorter: {
        compare: (a, b) => a.outSubtotal - b.outSubtotal,
      },
    },
    {
      title: <TableHeader code={'J=D-I'} title={'毛利润'} />,
      dataIndex: 'grossProfit',
      sorter: {
        compare: (a, b) => a.grossProfit - b.grossProfit,
      },
    },
    {
      title: <TableHeader code={'K'} title={'其他工资分摊'} />,
      dataIndex: 'otherSalary',
      sorter: {
        compare: (a, b) => a.otherSalary - b.otherSalary,
      },
    },
    {
      title: <TableHeader code={'L'} title={'费用'} />,
      dataIndex: 'otherFee',
      sorter: {
        compare: (a, b) => a.otherFee - b.otherFee,
      },
    },
    {
      title: <TableHeader code={'M=K+L'} title={'间接成本小计'} />,
      dataIndex: 'otherSubtotal',
      sorter: {
        compare: (a, b) => a.otherSubtotal - b.otherSubtotal,
      },
    },
    {
      title: <TableHeader code={'N=J-M'} title={'净利润'} />,
      dataIndex: 'netProfit',
      sorter: {
        compare: (a, b) => a.netProfit - b.netProfit,
      },
    },
    {
      title: <TableHeader code={'O'} title={'总单数'} />,
      dataIndex: 'orderNum',
      sorter: {
        compare: (a, b) => a.orderNum - b.orderNum,
      },
    },
    {
      title: <TableHeader code={'P'} title={'维修单数'} />,
      dataIndex: 'stewardNum',
      sorter: {
        compare: (a, b) => a.stewardNum - b.stewardNum,
      },
    },
    {
      title: <TableHeader code={'Q'} title={'保洁单数'} />,
      dataIndex: 'cleanNum',
      sorter: {
        compare: (a, b) => a.cleanNum - b.cleanNum,
      },
    },
    {
      title: <TableHeader code={'R=E/P'} title={'材料单均成本'} />,
      dataIndex: 'materialCostAvg',
      sorter: {
        compare: (a, b) => a.materialCostAvg - b.materialCostAvg,
      },
    },
    {
      title: <TableHeader code={'S=F/P'} title={'维修单均成本'} />,
      dataIndex: 'stewardCostAvg',
      sorter: {
        compare: (a, b) => a.stewardCostAvg - b.stewardCostAvg,
      },
    },
    {
      title: <TableHeader code={'T=G/Q'} title={'保洁单均成本'} />,
      dataIndex: 'cleanCostAvg',
      sorter: {
        compare: (a, b) => a.cleanCostAvg - b.cleanCostAvg,
      },
    },
  ]

  const handleImport = (mode: '1' | '2' | '3') => {
    setOpen(false)
    setMode(mode)
  }

  return (
    <Page
      breadcrumb={
        <Breadcrumb
          right={
            <Popover
              visible={open}
              // trigger={'click'}
              content={
                <Space direction={'vertical'}>
                  <Button onClick={() => handleImport('1')}>导入站点数据</Button>
                  <Button onClick={() => handleImport('2')}>导入管家人工</Button>
                  <Button onClick={() => handleImport('3')}>导入保洁人工</Button>
                </Space>
              }
              placement={'bottomRight'}
            >
              <Space>
                <Button
                  type={'primary'}
                  onClick={() => {
                    if (tabIndex === '1') {
                      const formValues = form2.getFieldsValue(true)
                      if (!formValues.monthBegin || !formValues.monthEnd)
                        return message.warn('请选择开始月份和结束月份')
                      downloadBlob({url: '/finance-report/warehouse-excel', body: formValues})
                    } else {
                      const formValues = form1.getFieldsValue(true)
                      if (!formValues.monthBegin || !formValues.monthEnd)
                        return message.warn('请选择开始月份和结束月份')
                      downloadBlob({url: '/finance-report/user-excel', body: formValues})
                    }
                  }}
                >
                  导出
                </Button>
                <Button type={'primary'} onClick={() => setOpen(prevState => !prevState)}>
                  导入数据
                </Button>
              </Space>
            </Popover>
          }
        />
      }
    >
      <Tabs
        defaultValue={'1'}
        onChange={value => setTabIndex(value)}
        tabBarStyle={{backgroundColor: '#fff', paddingLeft: '20px'}}
      >
        <TabPane tab={'站点维度'} key={'1'}>
          <div className={commonStyles.filterSection}>
            <Form
              form={form2}
              initialValues={{pageNum: 1, pageSize: 10, pageable: false}}
              onFinish={queryFinanceReportWarehouse}
            >
              <Form.Item name={'month'}>
                <DatePicker.RangePicker
                  picker={'month'}
                  format={'YYYYMM'}
                  onChange={(_, dateStrings) => {
                    const [monthBegin, monthEnd] = dateStrings ?? []
                    form2.setFieldsValue({monthBegin, monthEnd})
                  }}
                />
              </Form.Item>
              <Row justify={'end'}>
                <Space>
                  <Button type={'primary'} htmlType={'submit'}>
                    查询
                  </Button>
                  <Button htmlType={'reset'}>重置</Button>
                </Space>
              </Row>
            </Form>
          </div>

          <div className={commonStyles.tableSection}>
            <Space style={{marginBottom: '10px'}}>
              <span>收入总计：{warehouseTotal.inSubtotal ?? 0}</span>
              <span>成本总计：{warehouseTotal.outSubtotal ?? 0}</span>
              <span>
                资金余缺：
                {Number.isNaN(warehouseTotal.inSubtotal - warehouseTotal.outSubtotal)
                  ? 0
                  : (warehouseTotal.inSubtotal - warehouseTotal.outSubtotal).toFixed(2)}
              </span>
            </Space>
            <Table
              scroll={{x: 'max-content'}}
              rowKey={record => `${record.warehouseId}-${record.month}-${record.warehouseName}`}
              dataSource={warehouseList}
              columns={[
                {title: '管家站点', dataIndex: 'warehouseName'},
                {title: '年月', dataIndex: 'month'},
                {
                  title: <TableHeader code={'A'} title={'维修包月'} />,
                  dataIndex: 'stewardPeriod',
                  sorter: {
                    compare: (a, b) => a.stewardPeriod - b.stewardPeriod,
                  },
                },
                {
                  title: <TableHeader code={'B'} title={'保洁包月'} />,
                  dataIndex: 'cleanPeriod',
                  sorter: {
                    compare: (a, b) => a.cleanPeriod - b.cleanPeriod,
                  },
                },
                {
                  title: <TableHeader code={'C'} title={'按次收入'} />,
                  dataIndex: 'serveSingle',
                  sorter: {
                    compare: (a, b) => a.serveSingle - b.serveSingle,
                  },
                },
                ...columns,
              ]}
              pagination={{
                pageSize: warehousePageSize,
                position: ['bottomCenter'],
                showSizeChanger: true,
                onChange: (_, pageSize) => setWarehousePageSize(pageSize),
              }}
            />
          </div>
        </TabPane>
        <TabPane tab={'客户维度'} key={'2'}>
          <div className={commonStyles.filterSection}>
            <Form
              form={form1}
              initialValues={{pageNum: 1, pageSize: 10, pageable: false}}
              onFinish={queryFinanceReportUser}
            >
              <Form.Item name={'month'}>
                <DatePicker.RangePicker
                  picker={'month'}
                  format={'YYYYMM'}
                  onChange={(_, dateStrings) => {
                    const [monthBegin, monthEnd] = dateStrings ?? []
                    form1.setFieldsValue({monthBegin, monthEnd})
                  }}
                />
              </Form.Item>
              <Row justify={'end'}>
                <Space>
                  <Button type={'primary'} htmlType={'submit'}>
                    查询
                  </Button>
                  <Button htmlType={'reset'}>重置</Button>
                </Space>
              </Row>
            </Form>
          </div>

          <div className={commonStyles.tableSection}>
            <Space style={{marginBottom: '10px'}}>
              <span>收入总计：{userTotal.inSubtotal ?? 0}</span>
              <span>成本总计：{userTotal.outSubtotal ?? 0}</span>
              <span>
                资金余缺：
                {Number.isNaN(userTotal.inSubtotal - userTotal.outSubtotal)
                  ? 0
                  : (userTotal.inSubtotal - userTotal.outSubtotal).toFixed(2)}
              </span>
            </Space>
            <Table
              scroll={{x: 'max-content'}}
              rowKey={record => `${record.userId}-${record.month}`}
              dataSource={userList}
              columns={[
                {title: '客户名称', dataIndex: 'userName'},
                {title: '年月', dataIndex: 'month'},
                {
                  title: <TableHeader code={'A'} title={'维修包月'} />,
                  dataIndex: 'stewardPeriod',
                  sorter: {compare: (a, b) => a.stewardPeriod - b.stewardPeriod},
                  render: value => (
                    <Button type={'link'} onClick={() => history.push('/client-bill')}>
                      {value}
                    </Button>
                  ),
                },
                {
                  title: <TableHeader code={'B'} title={'保洁包月'} />,
                  dataIndex: 'cleanPeriod',
                  sorter: {compare: (a, b) => a.cleanPeriod - b.cleanPeriod},
                  render: value => (
                    <Button type={'link'} onClick={() => history.push('/client-bill')}>
                      {value}
                    </Button>
                  ),
                },
                {
                  title: <TableHeader code={'C'} title={'按次收入'} />,
                  dataIndex: 'serveSingle',
                  sorter: {compare: (a, b) => a.serveSingle - b.serveSingle},
                  render: value => (
                    <Button type={'link'} onClick={() => history.push('/order-list')}>
                      {value}
                    </Button>
                  ),
                },
                ...columns,
              ]}
              pagination={{
                pageSize: userPageSize,
                position: ['bottomCenter'],
                showSizeChanger: true,
                onChange: (_, pageSize) => setUserPageSize(pageSize),
              }}
            />
          </div>
        </TabPane>
      </Tabs>
      <Modal
        visible={!!mode}
        title={importTitleCode[mode]}
        centered={true}
        footer={null}
        onOk={() => setMode(null)}
        onCancel={() => setMode(null)}
        afterClose={() => form3.resetFields()}
      >
        <Form form={form3} labelAlign={'right'} labelCol={{span: 8}} style={{marginTop: '20px'}}>
          <Form.Item label={importTitleCode[mode]}>
            <Button
              type={'link'}
              style={{padding: 0}}
              onClick={() => downloadBlob({url: baseUrl + importUrlCode[mode]})}
            >
              {importTemplateCode[mode]}
            </Button>
          </Form.Item>
          <Form.Item name={'month'} label={'年月'} rules={[{required: true, message: '请选择'}]}>
            <DatePicker
              picker={'month'}
              format={'YYYY-MM'}
              locale={locale}
              disabledDate={current => moment(current).isAfter(moment().set('date', 1).subtract(1, 'day'))}
            />
          </Form.Item>
          <Form.Item noStyle shouldUpdate>
            {form => {
              const month = form.getFieldValue('month')
              return month ? (
                <Form.Item label={importTitleCode[mode]} rules={[{required: true, message: '请选择文件'}]}>
                  <Upload
                    data={{month: moment(month).format('YYYYMM')}}
                    accept={'.xlsx, .xls'}
                    multiple={false}
                    showUploadList={false}
                    customRequest={options =>
                      uploadExcelData({
                        info: options,
                        uri: uploadUrlCode[mode],
                        onLoading: loading => setUpLoading(loading),
                        onSuccess: () => setMode(null),
                      })
                    }
                  >
                    <Button loading={upLoading}>选择文件</Button>
                  </Upload>
                </Form.Item>
              ) : null
            }}
          </Form.Item>
        </Form>
      </Modal>
    </Page>
  )
}
