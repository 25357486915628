import React, {useCallback, useEffect, useMemo, useState} from 'react'
import HeaderList, {RenderItemProps} from '@/pages/home/components/HeaderList'
import styles from './index.module.less'
import {DatePicker} from 'antd'
import moment from 'moment/moment'
import {homeModel} from '@/store/models/Home'
import {FORMAT_DATE_END, FORMAT_DATE_START} from '@/common/date'

const beginFormat = time => moment(time).format(FORMAT_DATE_START)
const endFormat = time => moment(time).format(FORMAT_DATE_END)
// 初始化日期
const initialDates = [moment().subtract(1, 'month').add(1, 'day'), moment()]

export default function Statistics() {
  //数据统计
  const [statistic1, setStatistic1] = useState({} as any)
  const queryStatistic1 = useCallback(([beginTime, endTime]) => {
    homeModel
      .homeStewardStatistic2({
        beginTime: beginFormat(beginTime),
        endTime: endFormat(endTime),
      })
      .then(({response: {data}}) => {
        setStatistic1(data)
      })
  }, [])

  const [statistic2, setStatistic2] = useState({} as any)
  const queryStatistic2 = useCallback(([beginTime, endTime]) => {
    homeModel
      .homeStewardStatistic3({beginTime: beginFormat(beginTime), endTime: endFormat(endTime)})
      .then(({response: {data}}) => {
        setStatistic2(data)
      })
  }, [])

  const [statistic3, setStatistic3] = useState({} as any)
  const queryStatistic3 = useCallback(([beginTime, endTime]) => {
    homeModel
      .homeStewardStatistic4({beginTime: beginFormat(beginTime), endTime: endFormat(endTime)})
      .then(({response: {data}}) => {
        setStatistic3(data)
      })
  }, [])

  const [statistic4, setStatistic4] = useState({} as any)
  const queryStatistic4 = useCallback(([beginTime, endTime]) => {
    homeModel
      .homeStewardStatistic5({beginTime: beginFormat(beginTime), endTime: endFormat(endTime)})
      .then(({response: {data}}) => {
        setStatistic4(data)
      })
  }, [])

  useEffect(() => {
    queryStatistic1(initialDates)
    queryStatistic2(initialDates)
    queryStatistic3(initialDates)
    queryStatistic4(initialDates)
  }, [queryStatistic1, queryStatistic2, queryStatistic3, queryStatistic4])

  const statistic = useMemo(
    () => [
      {
        data: [
          {
            label: '48h未完成',
            num: statistic1.num10,
            tip: '系统内服务单的下单时间在当前时间范围内的48h未完成的服务单数量',
          },
          {
            label: '120h未完成',
            num: statistic1.num11,
            tip: '系统内服务单的下单时间在当前时间范围内的120h未完成的服务单数量',
          },
          {
            label: '上门超时',
            num: statistic1.num13,
            tip: '系统内服务单的下单时间在当前时间范围内的上门超时的服务单数量',
          },
          {
            label: '沟通超时',
            num: statistic1.num12,
            tip: '系统内服务单的下单时间在当前时间范围内的沟通超时的服务单数量',
          },
          {
            label: '保洁返工单',
            num: statistic1.num14,
            tip: '系统内服务单的下单时间在当前时间范围内的保洁返工单数量',
          },
          {
            label: '管家返修单',
            num: statistic1.num15,
            tip: '系统内服务单的下单时间在当前时间范围内的管家返修单数量',
            show: false,
          },
        ],
        onChange: dates => queryStatistic1(dates),
      },
      {
        data: [
          {
            label: '完成单量',
            num: statistic2.num16,
            tip: '系统内服务单的完成时间在当前时间范围内的系统内所有的已完成服务单数量',
          },
          {
            label: '房东参评率',
            num: [undefined, '', null].includes(statistic2.num17) ? '-' : statistic2.num17?.toFixed(2) + '%',
            tip: '系统内服务单的完成时间在当前时间范围内的且是房东已评价的百分比',
          },
          {
            label: '租客参评率',
            num: [undefined, '', null].includes(statistic2.num18) ? '-' : statistic2.num18?.toFixed(2) + '%',
            tip: '系统内服务单的完成时间在当前时间范围内的且是租客已评价的百分比',
            show: false,
          },
        ],
        onChange: dates => queryStatistic2(dates),
      },
      {
        data: [
          {
            label: '差评单量',
            num: statistic3.num19,
            tip: '系统内服务单的评价时间在当前时间范围内的且是差评的服务单数量',
            show: false,
          },
        ],
        onChange: dates => queryStatistic3(dates),
      },
      {
        data: [
          {
            label: '取消单量',
            num: statistic4.num20,
            tip: '系统内服务单的取消时间在当前时间范围内的服务单数量',
            show: false,
          },
        ],
        onChange: dates => queryStatistic4(dates),
      },
    ],
    [statistic1, statistic2, statistic3, statistic4, queryStatistic1, queryStatistic2, queryStatistic3, queryStatistic4]
  )

  return (
    <div style={{paddingBottom: 40, paddingTop: 10}}>
      {statistic.map(({data, onChange}, index) => (
        <StatisticsView items={data} key={index} onChange={onChange} />
      ))}
    </div>
  )
}

interface StatisticsProps {
  items: RenderItemProps[]
  onChange?: (args: string[]) => void
}

const StatisticsView: React.FC<StatisticsProps> = ({items, onChange}) => {
  return (
    <div className={styles.staticsStyle}>
      <div>
        <DatePicker.RangePicker
          defaultValue={[moment().subtract(1, 'month').add(1, 'day') as any, moment() as any]}
          onChange={(_, dateStrings) => {
            if (dateStrings && dateStrings.every(item => item)) {
              onChange?.(dateStrings)
            }
          }}
          disabledDate={currentDate => {
            return currentDate && (currentDate < moment().subtract(1, 'year') || currentDate > moment())
          }}
        />
      </div>
      <div className={styles.row}>
        <HeaderList data={items} width={'16%'} />
      </div>
    </div>
  )
}
