import React, {useCallback, useEffect, useState} from 'react'
import {Button, Cascader, Form, Input, message, Radio, Row, Select, Space, Typography, Upload} from 'antd'
import {lessorModel} from '@/store/models/Lessor'
import {lesseeModel} from '@/store/models/Lessee'
import {stewardModel} from '@/store/models/Steward'
import {propertyModel} from '@/store/models/Property'
import {baseUrl} from '@/common/config'
import {useLocation} from 'react-router'
import commonStyles from '@/assets/styles/common.module.less'
import {createHeaders} from '@/store/service'
import {Page} from '@/components/Page'
import moment from 'moment'

const {Option} = Select

export default function StewardOrderAdd() {
  const location = useLocation<any>()

  const [form] = Form.useForm()

  const [userId, setUserId] = useState('')
  // 房东用户列表 & 租客用户列表
  const [userList, setUserList] = useState([])
  // 服务列表
  const [serList, setSerList] = useState([])
  // 房源列表
  const [proList, setProList] = useState([])

  // 房东用户列表
  const queryLessorList = useCallback(function (keyword: string) {
    lessorModel.queryLessorList({pageNum: 1, pageSize: 10, pageable: false, keyword}).then(({response: {data}}) => {
      // console.log('lessor data =>', data)
      setUserList(
        data.map(item => ({value: [item.certName, item.mobile].filter(Boolean).join(' '), userId: item.userId}))
      )
    })
  }, [])

  // 租客用户列表
  const queryLesseeList = useCallback(function (keyword: string) {
    lesseeModel.queryLesseeList({pageNum: 1, pageSize: 10, pageable: false, keyword}).then(({response: {data}}) => {
      // console.log('lessee data =>', data)
      setUserList(
        data.map(item => ({value: [item.certName, item.mobile].filter(Boolean).join(' '), userId: item.userId}))
      )
    })
  }, [])

  // 服务单项目
  const queryServiceList = useCallback(function (pid?: string, index = 0, pri?: string) {
    stewardModel.stewardServiceList({pid}, index).then(({response: {data}}) => {
      // console.log('steward list =>', data)
      if (index === 0) {
        setSerList(data.map(item => ({label: item.name, value: item.id, isLeaf: false})))
      } else if (index === 1) {
        setSerList(prevState =>
          prevState.map(item => {
            if (item.value === pid) {
              return {
                ...item,
                children: data.map(_item => ({label: _item.name, value: _item.id, isLeaf: false})),
              }
            } else return item
          })
        )
      } else {
        setSerList(prevState =>
          prevState.map(item => {
            if (item.value === pri) {
              return {
                ...item,
                children: item.children?.map(_item => {
                  if (_item.value === pid) {
                    return {
                      ..._item,
                      children: data.map(itm => ({label: itm.name, value: itm.id})),
                    }
                  } else return _item
                }),
              }
            } else return item
          })
        )
      }
    })
  }, [])

  // 房源列表
  const queryPropertyList = useCallback(function ({
    title,
    lessorId,
    lesseeId,
  }: {
    title: string
    lessorId?: string
    lesseeId?: string
  }) {
    if (!(lessorId || lesseeId)) {
      message.warning('请先选择房东或租客姓名！')
      return
    }
    propertyModel
      .queryPropertyList({pageNum: 1, pageSize: 10, pageable: true, title, lessorId, lesseeId})
      .then(({response: {data}}) => {
        setProList(data.map(item => ({value: `(${item.propertyCode}) ${item.title}`, propertyCode: item.propertyCode})))
      })
  },
  [])

  useEffect(() => {
    queryServiceList()
    form.setFieldsValue({type: '1'})
  }, [form, queryServiceList])

  useEffect(
    function () {
      if (location.state?.userId) {
        setUserId(location.state?.userId)
        location.state.userId = undefined
      }
    },
    [location.state]
  )

  // 租客绑定详情
  useEffect(
    function () {
      if (userId) {
        lesseeModel.userLesseePropertyDetail({userId}).then(({response: {data}}) => {
          // console.log('data =>', data)
          if (data.categoryFir) {
            queryServiceList(data.categoryFir, 1)
            setTimeout(() => queryServiceList(data.categorySec, 2, data.categoryFir), 500)
          }

          if (data.propertyTitle) {
            queryPropertyList({title: data.propertyTitle})
          }

          form.setFieldsValue({
            userId: data.userId,
            mobile: data.mobile,
            ser: data.categoryFir ? [data.categoryFir, data.categorySec, data.categoryThi] : [],
            propertyCode: data.propertyCode,
            orderDesc: data.orderDesc,
          })
        })
      }
    },
    [userId, queryServiceList, queryPropertyList, form]
  )

  const [openAppList, setOpenAppList] = useState<StewardOpenAppVO[]>([])
  useEffect(() => {
    stewardModel
      .stewardOpenAppList({
        pageable: false,
        pageNum: 1,
        pageSize: 1,
        appStatus: 1,
        nowDate: moment().format('YYYY-MM-DD'),
      } as StewardAppOpenListDTO)
      .then(({response: {data}}) => {
        setOpenAppList(data)
      })
  }, [])

  const subLoading = stewardModel.stewardAdminOrderMake.useLoading()

  return (
    <Page>
      <div className={commonStyles.tableSection}>
        <Form
          form={form}
          labelCol={{flex: '120px'}}
          wrapperCol={{span: 12}}
          onFinish={async () => {
            const values = form.getFieldsValue(true)
            const [categoryFir, categorySec, categoryThi] = values.ser
            const cateFir: any = serList.filter(item => item.value === categoryFir).shift()
            const cateSec: any = cateFir.children.filter(item => item.value === categorySec).shift()
            const cateThi: any = cateSec.children.filter(item => item.value === categoryThi).shift()
            const params = {
              categoryFir,
              categoryFirName: cateFir.label,
              categorySec,
              categorySecName: cateSec.label,
              categoryThi,
              categoryThiName: cateThi.label,
              imgIdList: values.imgList?.map(item => item.imgId),
              orderDesc: values.orderDesc,
              propertyCode: values.propertyCode,
              userId: values.userId,
              appId: values.appId,
              contactMobile: values.contactMobile,
            }
            await stewardModel.stewardAdminOrderMake(params)
            message.success('下单成功！')
            setUserId('')
            form.resetFields()
            form.setFieldsValue({type: '1'})
          }}
        >
          {!userId && (
            <Form.Item label={'下单人类型'} shouldUpdate>
              {form1 => (
                <Radio.Group
                  value={form1.getFieldValue('type')}
                  onChange={({target: {value: type}}) => {
                    form1.setFieldsValue({type, userId: undefined, propertyCode: undefined})
                    setUserList([])
                    setProList([])
                  }}
                >
                  <Radio value={'1'}>房东</Radio>
                  <Radio value={'0'}>租客</Radio>
                </Radio.Group>
              )}
            </Form.Item>
          )}
          <Form.Item label={'渠道来源'} name={'appId'}>
            <Select placeholder={'渠道来源'} allowClear>
              {openAppList
                .filter(item => item.appId !== 'GUANYU')
                .map(item => (
                  <Select.Option value={item.appId} key={item.appId}>
                    {item.appName}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
          {userId ? (
            <Form.Item name={'mobile'} label={'手机号'} rules={[{required: true, message: '请选择下单人'}]}>
              <Input disabled />
            </Form.Item>
          ) : (
            <Form.Item noStyle shouldUpdate>
              {form1 => {
                const type = form1.getFieldValue('type')
                return (
                  <Form.Item
                    name={'userId'}
                    label={`${(type === '1' && '房东姓名') || (type === '0' && '租客姓名')}`}
                    rules={[{required: true, message: '请选择下单人'}]}
                  >
                    <Select
                      style={{width: '100%'}}
                      placeholder={'请输入手机号/姓名搜索下单人'}
                      showSearch
                      filterOption={false}
                      onSearch={value => {
                        if (form.getFieldValue('type') === '1') {
                          queryLessorList(value)
                        } else queryLesseeList(value)
                      }}
                      onChange={() => {
                        form1.setFieldsValue({propertyCode: undefined})
                        setProList([])
                      }}
                      allowClear
                    >
                      {userList.map((item, index) => (
                        <Option key={item.userId + index} value={item.userId}>
                          {item.value}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                )
              }}
            </Form.Item>
          )}
          <Form.Item noStyle shouldUpdate>
            {form1 => {
              const type = form1.getFieldValue('type')
              const userId = form1.getFieldValue('userId')
              return (
                <Form.Item
                  name={'propertyCode'}
                  label={'房源地址/编号'}
                  rules={[{required: true, message: '请选择房源'}]}
                >
                  <Select
                    style={{width: '100%'}}
                    placeholder={'请输入要搜索的房源'}
                    showSearch
                    filterOption={false}
                    onSearch={value => {
                      if (value.length > 2) {
                        queryPropertyList({
                          title: value,
                          lesseeId: type === '0' ? userId : undefined,
                          lessorId: type === '1' ? userId : undefined,
                        })
                      }
                    }}
                    allowClear
                  >
                    {proList.map((item, index) => (
                      <Option key={item.propertyCode + index} value={item.propertyCode}>
                        {item.value}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              )
            }}
          </Form.Item>
          <Form.Item label={'服务项目'} name={'ser'} rules={[{required: true, message: '请选择服务项目'}]}>
            <Cascader
              options={serList}
              placeholder={'请选择服务项目'}
              loadData={selectedOptions => {
                if (selectedOptions?.length === 1) {
                  queryServiceList(`${selectedOptions[0]?.value}`, 1)
                }
                if (selectedOptions?.length === 2) {
                  queryServiceList(`${selectedOptions[1]?.value}`, 2, `${selectedOptions[0]?.value}`)
                }
              }}
            />
          </Form.Item>
          <Form.Item
            label={'服务联系手机号'}
            name={'contactMobile'}
            rules={[
              {required: true, message: '请输入服务联系手机号'},
              {pattern: /^1[0-9]{10}$/, message: '手机号码不正确'},
            ]}
          >
            <Input placeholder={'请输入'} />
          </Form.Item>
          <Form.Item name={'orderDesc'} label={'备注信息'} rules={[{required: true, message: '请输入备注信息'}]}>
            <Input.TextArea rows={3} maxLength={200} showCount allowClear placeholder={'请输入备注信息'} />
          </Form.Item>
          <Form.Item label={'照片上传'} required shouldUpdate style={{marginBottom: 0}}>
            {() => (
              <Form.Item name={'imgList'} rules={[{required: true, message: '请选择图片'}]}>
                <>
                  <Upload
                    data={{userId: form.getFieldValue('userId')}}
                    name='file'
                    accept={'image/*'}
                    listType='picture-card'
                    showUploadList={true}
                    headers={createHeaders()}
                    action={baseUrl + '/steward/admin/order/upload-file'}
                    disabled={!form.getFieldValue('userId')}
                    onChange={info => {
                      if (info.file.status === 'done') {
                        const {
                          response: {data},
                        } = info.file
                        // console.log('info file =>', info.file)
                        const imgList = form.getFieldValue('imgList') ?? []
                        form.setFieldsValue({imgList: [...imgList.map(item => item), data]})
                      }
                      if (info.file.status === 'removed') {
                        const {
                          response: {data},
                        } = info.file
                        // console.log('info file =>', info.file)
                        const imgList = form.getFieldValue('imgList')
                        form.setFieldsValue({imgList: imgList.filter(item => item.imgId !== data.imgId)})
                      }
                    }}
                  >
                    {form.getFieldValue('imgList')?.length > 8 ? null : (
                      <Space direction={'vertical'}>
                        <Typography.Text>上传图片</Typography.Text>
                      </Space>
                    )}
                  </Upload>
                  <Typography.Text>请上传 .jpg 或 .png 格式图片，不少于1张， 不多于9张</Typography.Text>
                </>
              </Form.Item>
            )}
          </Form.Item>
          <Row justify={'center'}>
            <Button type={'primary'} htmlType={'submit'} loading={subLoading}>
              创建服务单
            </Button>
          </Row>
        </Form>
      </div>
    </Page>
  )
}
