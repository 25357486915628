import React, {CSSProperties} from 'react'
import {MapContext} from '@/components/Map/mapContext'
import {Script} from '@/utils/script'
import {ak} from '@/common/config'
import mapctrls from '@/assets/mapctrls.gif'

export interface MapviewProps {
  onClick?: (point: Point) => void
  onMapChange?: (event: GeocoderResult) => any
  style?: CSSProperties
  children?: React.ReactNode
}

export class Mapview extends React.Component<MapviewProps, {ready: boolean}> {
  constructor(props) {
    super(props)
    this.state = {
      ready: false,
    }
  }

  public mapRef: any
  private localSearch: any
  private timer: any

  private moveendAndGetLocation = rs => {
    clearTimeout(this.timer)
    this.timer = setTimeout(() => {
      const data = {zoom: this.mapRef?.getZoom(), ...rs}
      this.searchZoom = data.zoom
      this.props.onMapChange?.(data)
    }, 80)
  }

  private get BMapGL() {
    return window.BMapGL
  }

  private get BMapGLLib() {
    return window.BMapGLLib
  }

  private cache = []

  // 移动地图
  public panTo = (lng: number, lat: number, zoom?: number) => {
    if (!this.state.ready) {
      this.cache.push(() => this.panTo(lng, lat))
      return
    }
    if (zoom) this.mapRef.flyTo(new this.BMapGL.Point(lng, lat), zoom)
    else this.mapRef.panTo(new this.BMapGL.Point(lng, lat))
  }

  // 移动到指定位置
  private searchZoom = 14
  public panToByPath = (path: string, zoom?: number) => {
    if (!this.state.ready) {
      this.cache.push(() => this.panToByPath(path, zoom))
      return
    }
    if (zoom) this.searchZoom = zoom
    this.localSearch?.search(path)
  }

  // TODO：逆变坐标为地址
  public geocoder = (path: string) =>
    new Promise((resolve, reject) => {
      // LocalSearch 不用输入城市，Geocoder 的 getPoint() 需要城市
      new this.BMapGL.LocalSearch(this.mapRef, {
        onSearchComplete: result => {
          if (result._pois?.length) {
            resolve(result._pois[0].point)
          } else {
            reject('没有搜索结果')
          }
        },
      }).search(path)
    })

  // 设置缩放登记
  public setZoom = (zoom: number) => {
    if (!this.state.ready) {
      this.cache.push(() => this.setZoom(zoom))
      return
    }
    this.mapRef?.setZoom(zoom)
  }

  componentDidMount() {
    Promise.all([
      Script.loadScript(`//api.map.baidu.com/api?type=webgl&v=1.0&ak=${ak}&callback=initMap`, () => this.BMapGL?.Map), // callback 必须存在
      Script.loadScript(`//mapopen.cdn.bcebos.com/github/BMapGLLib/DistanceTool/src/DistanceTool.min.js`),
    ])
      .then(() => Script.loadScript(`//mapopen.cdn.bcebos.com/github/BMapGLLib/RichMarker/src/RichMarker.min.js`)) // BMapGL 必须存在
      .then(() => {
        const initialize = this.BMapGLLib.RichMarker.prototype.initialize
        this.BMapGLLib.RichMarker.prototype.initialize = function (map) {
          const div = initialize.call(this, map)
          div.style.background = 'unset'
          return div
        }
      })
      .then(() => {
        const BMapGL = this.BMapGL
        const map = (this.mapRef = new BMapGL.Map('allmap', {enableMapClick: false})) // 创建Map实例
        const point = new BMapGL.Point(121.486107, 31.218955) // 创建点坐标
        const scaleCtrl = new BMapGL.ScaleControl() // 添加比例尺控件
        map.addControl(scaleCtrl)
        const zoomCtrl = new BMapGL.ZoomControl() // 添加缩放控件
        map.addControl(zoomCtrl)

        // todo: 导航封装成组件
        // const navigationControl = new BMapGL.NavigationControl({
        //   anchor: BMapGL.BMAP_ANCHOR_TOP_LEFT, // 靠左上角位置
        //   type: BMapGL.BMAP_NAVIGATION_CONTROL_SMALL, // LARGE类型
        //   // enableGeolocation: true, // 启用显示定位
        // })
        // map.addControl(navigationControl)

        const geoc = new BMapGL.Geocoder()
        map.addEventListener('moveend', () => geoc.getLocation(map.getCenter(), this.moveendAndGetLocation))
        map.addEventListener('zoomend', () => geoc.getLocation(map.getCenter(), this.moveendAndGetLocation))

        if (typeof this.props.onClick === 'function') {
          map.addEventListener('click', e => this.props.onClick(e.latlng))
        }

        this.localSearch = new BMapGL.LocalSearch(map, {
          // renderOptions: {map: map},
          onSearchComplete: res => {
            if (res._pois && res._pois.length) {
              map.flyTo(res._pois[0].point, this.searchZoom)
            }
          },
        })

        map.addEventListener('load', () => {
          this.setState({ready: true}, () => {
            while (this.cache.length) {
              this.cache.shift()()
            }
          })
        })

        map.centerAndZoom(point, this.searchZoom)
        map.enableScrollWheelZoom() // 启用滚轮放大缩小
      })
  }

  private dis = null

  public get distanceTool() {
    if (!this.dis) {
      const Size = this.BMapGL.Size
      this.dis = new this.BMapGLLib.DistanceTool(this.mapRef, {
        closeIcon: new this.BMapGL.Icon(mapctrls, new Size(12, 12), {
          imageSize: new Size(82, 174),
          imageOffset: new Size(0, 14), // 设置icon偏移
        }),
      })
    }

    return {
      open: () => this.dis.open(),
      close: () => this.dis.close(),
    }
  }

  render() {
    const {ready} = this.state

    return (
      <MapContext.Provider value={this.mapRef}>
        <div style={{display: 'flex', flex: 1, width: '100%', height: '100%', ...this.props.style}} id={'allmap'}>
          {ready ? this.props.children : null}
        </div>
      </MapContext.Provider>
    )
  }
}

export const getBMapGL = () => window.BMapGL
export const getBMapGLLib = () => window.BMapGLLib
