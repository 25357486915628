import React, {useCallback, useEffect, useState} from 'react'
import {Page} from '@/components/Page'
import {Button, Col, Form, Input, message, Modal, Row, Select, Space, Table, Tooltip} from 'antd'
import commonStyles from '@/assets/styles/common.module.less'
import {stewardModel} from '@/store/models/Steward'
import {Breadcrumb} from '@/components/Breadcrumb'
import {sysModel} from '@/store/models/Sys'
import {getPaginationProps} from '@/common/biz/getPaginationProps'
import {rules} from '@/utils/validate'
import {usePermission} from '@/components/Permission'

const enableCode = {0: '无效', 1: '有效'}
const reverseEnableCode = {1: '无效', 0: '有效'}

export default function ContactList() {
  const [auth] = usePermission()

  const [form] = Form.useForm<StewardAddressBookListDTO>()
  const [uForm] = Form.useForm<StewardAddressBookUpsertDTO>()

  const [bookList, setBookList] = useState<StewardAddressBookVO[]>([])
  const [bookTotal, setBookTotal] = useState(0)

  const queryBookList = useCallback(() => {
    const formValues = form.getFieldsValue(true)
    stewardModel.stewardAddressBookList(formValues).then(({response: {data, total}}) => {
      setBookList(data)
      setBookTotal(total)
    })
  }, [form])

  useEffect(() => {
    queryBookList()
  }, [queryBookList])

  const isCityBookExist = useCallback(async (cityCode: string) => {
    const {
      response: {data},
    } = await stewardModel.stewardAddressBookList({pageNum: 1, pageSize: 10, pageable: false, enable: '1', cityCode})
    return !!data.length
  }, [])

  const [cityList, setCityList] = useState<{id: string; label: string}[]>([])

  useEffect(() => {
    sysModel.sysConfigGet({k: 'CONFIG_SYS_CITY'}).then(({response: {data}}) => {
      data?.city && setCityList(data.city)
    })
  }, [])

  const bookLoading = stewardModel.stewardAddressBookList.useLoading()

  function onBookUpsert(isUpdate: boolean) {
    Modal.confirm({
      title: isUpdate ? '编辑' : '新增',
      icon: null,
      centered: true,
      closable: true,
      width: '60%',
      content: (
        <Form form={uForm} labelCol={{flex: '80px'}} labelAlign={'right'} initialValues={{contentList: [{}]}}>
          <Row>
            <Col span={8}>
              <Form.Item label={'适用城市'} name={'cityCode'} rules={[{required: true, message: '请选择适用城市'}]}>
                <Select
                  showSearch
                  placeholder={'请选择适用城市'}
                  options={cityList.map(item => ({label: item.label, value: item.id}))}
                  filterOption={(inputValue, option) => {
                    return option?.label?.indexOf(inputValue) > -1
                  }}
                  onSelect={(_, option) => {
                    uForm.setFieldsValue({cityName: option.label})
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item label={'通讯录'}>
            <Row gutter={20}>
              <Col span={2}>序号</Col>
              <Col span={5}>姓名</Col>
              <Col span={5}>联系电话</Col>
              <Col span={5}>岗位</Col>
              <Col span={5}>岗位职能</Col>
              <Col span={2}>操作</Col>
            </Row>
          </Form.Item>
          <Form.List name={'contentList'}>
            {(fields, {add, remove}) => {
              return (
                <>
                  {fields.map((field, index) => {
                    return (
                      <Row gutter={20} key={field.key} style={{marginLeft: '70px'}}>
                        <Col span={2}>{index + 1}</Col>
                        <Col span={5}>
                          <Form.Item
                            name={[field.name, 'name']}
                            label={''}
                            colon={false}
                            rules={[{required: true, message: '请输入姓名'}]}
                          >
                            <Input placeholder={'请输入姓名'} />
                          </Form.Item>
                        </Col>
                        <Col span={5}>
                          <Form.Item name={[field.name, 'mobile']} label={''} colon={false} rules={rules.mobile}>
                            <Input placeholder={'请输入联系电话'} />
                          </Form.Item>
                        </Col>
                        <Col span={5}>
                          <Form.Item
                            name={[field.name, 'post']}
                            label={''}
                            colon={false}
                            rules={[{required: true, message: '请输入岗位'}]}
                          >
                            <Input placeholder={'请输入岗位'} />
                          </Form.Item>
                        </Col>
                        <Col span={5}>
                          <Form.Item
                            name={[field.name, 'postFunction']}
                            label={''}
                            colon={false}
                            rules={[{required: true, message: '请输入岗位职能'}]}
                          >
                            <Input placeholder={'请输入岗位职能'} maxLength={500} showCount />
                          </Form.Item>
                        </Col>
                        <Col span={2}>
                          <Button
                            disabled={fields.length === 1 && index === 0}
                            type={'link'}
                            danger
                            style={{padding: 0}}
                            onClick={() => remove(index)}
                          >
                            删除
                          </Button>
                        </Col>
                      </Row>
                    )
                  })}
                  <Button type={'primary'} style={{marginLeft: '80px'}} onClick={() => add()}>
                    添加通信录
                  </Button>
                </>
              )
            }}
          </Form.List>
        </Form>
      ),
      onOk: async () => {
        await uForm.validateFields()

        async function onUpsert() {
          const formValues = uForm.getFieldsValue(true)
          await stewardModel.stewardAddressBookUpsert(formValues)
          queryBookList()
        }

        if (!isUpdate) {
          const isExist = await isCityBookExist(uForm.getFieldValue('cityCode'))
          if (isExist) {
            message.error(`当前"${uForm.getFieldValue('cityName')}"存在有效通讯录，不能重复设置！`)
          } else {
            await onUpsert()
          }
        } else {
          await onUpsert()
        }
      },
      afterClose: () => uForm.resetFields(),
    })
  }

  return (
    <Page
      breadcrumb={
        <Breadcrumb
          right={
            auth('003198002') && (
              <Button type={'primary'} onClick={() => onBookUpsert(false)}>
                新增
              </Button>
            )
          }
        />
      }
    >
      <div className={commonStyles.filterSection}>
        <Form
          form={form}
          onFinish={queryBookList}
          onReset={queryBookList}
          initialValues={{pageNum: 1, pageSize: 10, pageable: true}}
        >
          <Row>
            <Col span={8}>
              <Form.Item label={'城市'} name={'cityCode'}>
                <Select
                  showSearch
                  placeholder={'请选择'}
                  options={cityList.map(item => ({label: item.label, value: item.id}))}
                  filterOption={(inputValue, option) => {
                    return option?.label?.indexOf(inputValue) > -1
                  }}
                  allowClear
                />
              </Form.Item>
            </Col>
          </Row>
          <Row justify={'end'}>
            <Space>
              <Button
                type={'primary'}
                htmlType={'submit'}
                onClick={() => {
                  form.setFieldsValue({pageNum: 1})
                }}
              >
                查询
              </Button>
              <Button htmlType={'reset'}>重置</Button>
            </Space>
          </Row>
        </Form>
      </div>

      <div className={commonStyles.tableSection}>
        <Table
          loading={bookLoading}
          rowKey={'id'}
          columns={[
            {title: '城市', dataIndex: 'cityName'},
            {
              title: '通讯录',
              dataIndex: 'contentList',
              render: contentList => {
                const content = contentList.map(
                  (item, index) =>
                    `${index + 1}、姓名：${item.name}，联系电话：${item.mobile}，岗位：${item.post}，岗位职能：${
                      item.postFunction
                    }`
                )

                return (
                  <Tooltip
                    title={content.map((item, index) => (
                      <li key={index}>{item}</li>
                    ))}
                  >
                    {content.join(';').slice(0, 20) + '...'}
                  </Tooltip>
                )
              },
            },
            {title: '状态', dataIndex: 'enable', render: enable => enableCode[enable]},
            {title: '修改人', dataIndex: 'updateBy'},
            {title: '修改时间', dataIndex: 'updateTime'},
            {
              title: '操作',
              dataIndex: 'id',
              render: (value, record) => {
                const enable = record.enable
                return (
                  <Space>
                    {auth('003198003') && (
                      <Button
                        type={'link'}
                        style={{padding: 0}}
                        disabled={record.enable === 0}
                        onClick={() => {
                          uForm.setFieldsValue({
                            id: record.id,
                            cityCode: record.cityCode,
                            cityName: record.cityName,
                            contentList: record.contentList,
                          })
                          onBookUpsert(true)
                        }}
                      >
                        编辑
                      </Button>
                    )}

                    {auth('023198004') && (
                      <Button
                        type={'link'}
                        style={{padding: 0}}
                        onClick={async () => {
                          function onBookEnable({
                            title,
                            content,
                            reqParams,
                          }: {
                            title: string
                            content: string
                            reqParams: {id: string; enable: 0 | 1}
                          }) {
                            Modal.confirm({
                              title: title,
                              icon: null,
                              centered: true,
                              closable: true,
                              content: content,
                              onOk: async () => {
                                await stewardModel.stewardAddressBookEnable(reqParams)
                                message.success('操作成功！')
                                queryBookList()
                              },
                            })
                          }

                          if (enable === 0) {
                            const isExist = await isCityBookExist(record.cityCode)
                            if (isExist) {
                              Modal.confirm({
                                title: reverseEnableCode[enable],
                                icon: null,
                                centered: true,
                                closable: true,
                                content: `当前"${record.cityName}"存在有效通讯录，不能重复设置！`,
                                okCancel: false,
                              })
                            } else {
                              onBookEnable({
                                title: reverseEnableCode[enable],
                                content: `确定要启用＂${record.cityName}＂的通讯录？`,
                                reqParams: {id: value, enable: 1},
                              })
                            }
                          } else {
                            onBookEnable({
                              title: reverseEnableCode[enable],
                              content: `确定将＂${record.cityName}＂通讯录设置为无效，无效后管家将无法查看。`,
                              reqParams: {id: value, enable: 0},
                            })
                          }
                        }}
                      >
                        {reverseEnableCode[enable]}
                      </Button>
                    )}
                  </Space>
                )
              },
            },
          ]}
          dataSource={bookList}
          pagination={getPaginationProps(form, bookTotal)}
        />
      </div>
    </Page>
  )
}
