import React, {useCallback, useEffect, useImperativeHandle, useRef, useState} from 'react'
import {
  Button,
  Col,
  DatePicker,
  Descriptions,
  Drawer,
  Form,
  Image,
  Input,
  InputNumber,
  Modal,
  Row,
  Select,
  Space,
  Table,
  Tooltip,
  message,
} from 'antd'
import {stewardModel} from '@/store/models/Steward'
import {getImg} from '@/utils/util'
import commonStyles from '@/assets/styles/common.module.less'
import {getPaginationProps} from '@/common/biz/getPaginationProps'
import {FORMAT_DATETIME, FORMAT_DATE_END, FORMAT_DATE_START} from '@/common/date'
import moment from 'moment'
import {useHistory} from 'react-router'
import ServiceBillInfo from '@/pages/client/components/ServiceBillInfo'
import {onAlbumUploadEvent, onAlbumUploadProps} from '@/pages/service/utils/albumUpload'
import {Album, UploadButton} from '@/components/Album'
import {baseUrl} from '@/common/config'
import DividerLine from '@/pages/property/component/DividerLine'
import {useCodeArraySync} from '@/store/models/Common'
import {usePermission} from '@/components/Permission'

interface PropsType {
  userId: string
  customerName: string
  customerMobile: string
}

function Refund(props: PropsType, ref: React.Ref<unknown>) {
  const {userId, customerName, customerMobile} = props
  // 新增
  const [isAdd, setIsAdd] = useState<boolean>(false)
  const [addForm] = Form.useForm()
  // const [isPicLoading, setIsPicLoading] = useState<boolean>(false)

  // 作废
  const [isCancel, setIsCancel] = useState<boolean>(false)
  const [cancelForm] = Form.useForm()
  // 确认退款
  const [isConfirm, setIsConfirm] = useState<boolean>(false)
  const [confirmForm] = Form.useForm()
  //   查看
  const [isLook, setIsLook] = useState<boolean>(false)
  const [detail, setDetail] = useState<StewardRefundVO>({} as StewardRefundVO)
  const [auth] = usePermission()

  /** 列表相关 */
  const [form] = Form.useForm()
  const [total, setTotal] = useState(0)
  const [list, setList] = useState<StewardRefundVO[]>([])
  const listLoading = stewardModel.stewardAdminRefundList.useLoading()
  const confirmLoading = stewardModel.stewardAdminRefundApproval.useLoading()

  const history = useHistory()
  const serviceInfoRef = useRef(null)
  // approval.order.status  审批
  const approvalArr = useCodeArraySync('wework.approvalStatus')

  // steward.refund.payment.status 付款
  const paymentArr = useCodeArraySync('steward.refund.payment.status')
  // sys.pay.source 付款方式
  const returnWayArr = useCodeArraySync('sys.pay.source')

  const queryList = useCallback(() => {
    stewardModel
      .stewardAdminRefundList({...form.getFieldsValue(true), customerId: userId})
      .then(({response: {data, total}}) => {
        setTotal(total)
        setList(data)
      })
  }, [form, userId])

  useImperativeHandle(ref, () => ({
    onAdd: () => {
      setIsAdd(true)
    },
  }))

  useEffect(() => {
    queryList()
  }, [queryList])

  return (
    <>
      <div className={commonStyles.filterSection}>
        <Form
          form={form}
          labelCol={{flex: '120px'}}
          initialValues={{pageNum: 1, pageSize: 10, pageable: true}}
          onFinish={queryList}
          onReset={queryList}
        >
          <Row gutter={30}>
            <Col span={8}>
              <Form.Item name={'refundId'} label={'退款申请单id'}>
                <Input placeholder={'请输入'} allowClear />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name={'approvalStatus'} label={'状态'}>
                <Select
                  placeholder={'请选择'}
                  allowClear
                  options={approvalArr}
                  fieldNames={{label: 'label', value: 'id'}}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name={'paymentStatus'} label={'付款状态'}>
                <Select
                  placeholder={'请选择'}
                  allowClear
                  options={paymentArr}
                  fieldNames={{label: 'label', value: 'id'}}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name={'applicant'} label={'申请人'}>
                <Input placeholder={'请输入'} allowClear />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name={'applicationTimeBegin'}
                label={'申请时间'}
                getValueFromEvent={value => {
                  form.setFieldsValue({applicationTimeEnd: value?.[1]?.format(FORMAT_DATE_END)})
                  return value?.[0]?.format(FORMAT_DATE_START)
                }}
                getValueProps={value => ({
                  value: value ? [moment(value), moment(form.getFieldValue('applicationTimeEnd'))] : value,
                })}
              >
                <DatePicker.RangePicker allowClear />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name={'approvalTimeBegin'}
                label={'审批时间'}
                getValueFromEvent={value => {
                  form.setFieldsValue({approvalTimeEnd: value?.[1]?.format(FORMAT_DATE_END)})
                  return value?.[0]?.format(FORMAT_DATE_START)
                }}
                getValueProps={value => ({
                  value: value ? [moment(value), moment(form.getFieldValue('approvalTimeEnd'))] : value,
                })}
              >
                <DatePicker.RangePicker allowClear />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name={'relatedServiceOrder'} label={'单号'}>
                <Input placeholder={'关联服务单/关联账单'} allowClear />
              </Form.Item>
            </Col>
          </Row>
          <Row justify={'end'}>
            <Space>
              <Button type={'primary'} htmlType={'submit'} onClick={() => form.setFieldsValue({pageNum: 1})}>
                查询
              </Button>
              <Button htmlType={'reset'}>重置</Button>
            </Space>
          </Row>
        </Form>

        <Table
          rowKey={'refundId'}
          style={{marginTop: 20}}
          loading={listLoading}
          dataSource={list}
          columns={[
            {title: '退款申请单id', dataIndex: 'refundId'},
            {title: '客户名称', dataIndex: 'customerName'},
            {title: '退款金额(元)', dataIndex: 'refundAmt'},
            {
              title: '原因',
              dataIndex: 'refundRemark',
              render: value => (
                <Tooltip title={value.length < 30 ? null : value}>
                  {value.length < 30 ? value : value.substring(0, 31) + '......'}
                </Tooltip>
              ),
            },
            {
              title: '关联服务单/关联账单',
              dataIndex: 'relatedServiceOrder',
              render: (value, {refundId}) => (
                <div
                  style={{cursor: 'pointer', color: '#00B7AE', textDecoration: 'underline'}}
                  onClick={() => {
                    if (value.includes('ZB')) {
                      history.push('/steward/detail', {orderId: value})
                    } else {
                      serviceInfoRef.current?.onDisplay()
                      serviceInfoRef.current?.onSetInfo({
                        billId: value,
                      } as LessorBillVO)
                    }
                  }}
                >
                  {value}
                </div>
              ),
            },
            {title: '申请人', dataIndex: 'applicant'},
            {title: '申请时间', dataIndex: 'applicationTime'},
            {
              title: '状态',
              dataIndex: 'approvalStatus',
              render: value => approvalArr.find(item => item.id === value)?.label,
            },
            {title: '审批时间/撤销时间', dataIndex: 'approvalTime', render: value => value ?? '/'},
            {
              title: '付款状态',
              dataIndex: 'paymentStatus',
              render: (value, {approvalStatus}) =>
                approvalStatus === '2' ? paymentArr.find(item => item.id === value)?.label : '/',
            },
            {
              title: '操作',
              dataIndex: 'refundId',
              fixed: 'right',
              render: (refundId, {refundAmt, paymentStatus, approvalStatus}) => {
                return (
                  <Space>
                    {auth('001195002') && (
                      <Button
                        type='link'
                        style={{padding: 0}}
                        onClick={async () => {
                          const res = await stewardModel.stewardAdminRefundDetail({refundId})
                          setDetail(res.response.data)
                          setIsLook(true)
                        }}
                      >
                        查看
                      </Button>
                    )}
                    {auth('018195001') && approvalStatus === '2' && paymentStatus === '1' && (
                      <Button
                        type='link'
                        style={{padding: 0}}
                        onClick={() => {
                          setIsConfirm(true)
                          confirmForm.setFieldsValue({
                            refundId,
                            refundAmt,
                          })
                        }}
                      >
                        确认退款
                      </Button>
                    )}
                    {auth('028195001') && approvalStatus === '2' && paymentStatus === '1' && (
                      <Button
                        type='link'
                        style={{padding: 0}}
                        onClick={() => {
                          cancelForm.setFieldsValue({
                            refundId,
                          })
                          setIsCancel(true)
                        }}
                      >
                        作废
                      </Button>
                    )}
                  </Space>
                )
              },
            },
          ]}
          scroll={{x: 'max-content'}}
          pagination={getPaginationProps(form, total)}
        />
      </div>

      {/* 关联账单点击后弹框这展示该账单详情 */}
      <ServiceBillInfo ref={serviceInfoRef} onSuccess={() => form.submit()} />

      {/* 新增退款 */}
      <Modal
        title='新增退款'
        centered
        destroyOnClose
        keyboard
        maskClosable
        open={isAdd}
        onOk={async () => {
          const {refundImgList, ...rest} = addForm.getFieldsValue(true)
          await addForm.validateFields()
          if (refundImgList?.[0]?.status) return message.warn('请等待附件上传完毕后再提交！')
          // stewardAdminRefundApproval  stewardAdminRefundInsert
          await stewardModel.stewardAdminRefundApproval({
            ...rest,
            refundImgList: refundImgList.map(item => item.imgUrl),
          })
          message.success('新增成功！')
          setIsAdd(false)
          queryList()
        }}
        onCancel={() => setIsAdd(false)}
        afterClose={() => addForm.resetFields()}
        confirmLoading={confirmLoading}
      >
        <Form
          form={addForm}
          colon
          labelCol={{span: 8}}
          initialValues={{customerName, customerId: userId, customerMobile}}
        >
          <Form.Item label='客户名称' name='customerName'>
            <Input disabled />
          </Form.Item>
          <Form.Item label='退款金额' name='refundAmt' rules={[{required: true}]}>
            <InputNumber placeholder='请输入' style={{width: '100%'}} min={0.1} />
          </Form.Item>
          <Form.Item name={'refundRemark'} label='退款原因' rules={[{required: true}]}>
            <Input.TextArea rows={4} placeholder={'请输入'} maxLength={100} showCount style={{resize: 'none'}} />
          </Form.Item>
          <Form.Item label='关联服务单/关联账单' name='relatedServiceOrder' rules={[{required: true}]}>
            <Input placeholder='请输入' />
          </Form.Item>
          <Form.Item
            label={'附件'}
            name={'refundImgList'}
            getValueFromEvent={onAlbumUploadEvent}
            getValueProps={onAlbumUploadProps}
            rules={[{required: true}]}
          >
            <Album
              data={{userId}}
              limit={5}
              action={baseUrl + '/steward/admin/order/upload-file'}
              accept={'.jpg, .jpeg, .png'}
            >
              <UploadButton title={'上传照片'} />
            </Album>
          </Form.Item>
        </Form>
      </Modal>

      {/* 查看详情 */}
      <Drawer
        open={isLook}
        title={'退款详情'}
        onClose={() => {
          setIsLook(false)
        }}
        destroyOnClose
        width={700}
      >
        <DividerLine title={'申请信息'} bottom={15} top={10} />
        <Descriptions column={2} colon>
          <Descriptions.Item label={'退款申请单id'}>{detail.refundId}</Descriptions.Item>
          <Descriptions.Item label={'申请人'}>{detail.applicant}</Descriptions.Item>
          <Descriptions.Item label={'申请时间'}>{detail.applicationTime}</Descriptions.Item>
          <Descriptions.Item label={'申请状态'}>
            {approvalArr.find(item => item.id === detail.approvalStatus)?.label}
          </Descriptions.Item>
          <Descriptions.Item label={'审批时间'}>{detail.approvalTime ?? '/'}</Descriptions.Item>
          <Descriptions.Item label={''}>{''}</Descriptions.Item>
          <Descriptions.Item label={'客户名称'}>{detail.customerName}</Descriptions.Item>
          <Descriptions.Item label={'退款金额（元）'}>{detail.refundAmt}</Descriptions.Item>
          <Descriptions.Item label={'退款原因'}>{detail.refundRemark}</Descriptions.Item>
          <Descriptions.Item label={'关联服务单/关联账单'}>{detail.relatedServiceOrder}</Descriptions.Item>
          <Descriptions.Item label={'附件'}>
            <div style={{display: 'flex', flexWrap: 'wrap'}}>
              {detail.refundImgList?.map((item, index) => (
                <div style={{padding: 7, border: '1px solid #d9d9d9', borderRadius: 2, marginRight: 4}} key={index}>
                  <Image src={getImg(item)} width={87} height={87} />
                </div>
              ))}
            </div>
          </Descriptions.Item>
        </Descriptions>

        {detail.paymentStatus === '2' && (
          <>
            <DividerLine title={'退款信息'} bottom={15} top={10} />
            <Descriptions column={2} colon>
              <Descriptions.Item label={'退款渠道'}>
                {returnWayArr.find(item => item.id === detail.refundSourceId)?.label}
              </Descriptions.Item>
              <Descriptions.Item label={'实际退款时间'}>{detail.refundOccurrenceTime}</Descriptions.Item>
              <Descriptions.Item label={'附件'}>
                <div style={{display: 'flex', flexWrap: 'wrap'}}>
                  {detail.confirmImgList?.map((item, index) => (
                    <div style={{padding: 7, border: '1px solid #d9d9d9', borderRadius: 2, marginRight: 4}} key={index}>
                      <Image src={getImg(item)} width={87} height={87} />
                    </div>
                  ))}
                </div>
              </Descriptions.Item>
              <Descriptions.Item label={'退款备注'}>{detail.confirmRemark}</Descriptions.Item>
            </Descriptions>
          </>
        )}

        {detail.paymentStatus === '3' && (
          <>
            <DividerLine title={'作废信息'} bottom={15} top={10} />
            <Descriptions column={2} colon>
              <Descriptions.Item label={'作废人'}>{detail.cancelBy}</Descriptions.Item>
              <Descriptions.Item label={'作废时间'}>{detail.cancelTime}</Descriptions.Item>
              <Descriptions.Item label={'作废原因'}>{detail.cancelRemark}</Descriptions.Item>
            </Descriptions>
          </>
        )}
      </Drawer>

      {/* 作废 */}
      <Modal
        title='作废'
        centered
        destroyOnClose
        keyboard
        maskClosable
        open={isCancel}
        onOk={async () => {
          await cancelForm.validateFields()
          await stewardModel.stewardAdminRefundCancel(cancelForm.getFieldsValue(true))
          message.success('操作成功！')
          setIsCancel(false)
          queryList()
        }}
        onCancel={() => setIsCancel(false)}
        afterClose={() => cancelForm.resetFields()}
      >
        <Form form={cancelForm} colon labelCol={{span: 4}}>
          <Form.Item name={'cancelRemark'} label='作废原因' rules={[{required: true}]}>
            <Input.TextArea rows={4} placeholder={'请输入'} maxLength={100} showCount style={{resize: 'none'}} />
          </Form.Item>
        </Form>
      </Modal>

      {/* 确认退款 */}
      <Modal
        title='确认退款'
        centered
        destroyOnClose
        keyboard
        maskClosable
        open={isConfirm}
        onOk={async () => {
          const {confirmImgList, ...rest} = confirmForm.getFieldsValue(true)
          await confirmForm.validateFields()
          await stewardModel.stewardAdminRefundConfirm({
            ...rest,
            confirmImgList: confirmImgList.map(item => item.imgUrl),
          })
          message.success('操作成功！')
          setIsConfirm(false)
          queryList()
        }}
        onCancel={() => setIsConfirm(false)}
        afterClose={() => confirmForm.resetFields()}
      >
        <Form form={confirmForm} colon labelCol={{span: 6}}>
          <Form.Item name={'refundSourceId'} label='退款渠道' rules={[{required: true}]}>
            <Select
              options={returnWayArr}
              allowClear
              placeholder='请选择'
              fieldNames={{label: 'label', value: 'id'}}
              onChange={(_, option) => {
                const {label} = option as Option
                confirmForm.setFieldValue('refundSource', label)
              }}
            />
          </Form.Item>
          <Form.Item
            name={'refundOccurrenceTime'}
            label='实际退款时间'
            rules={[{required: true}]}
            getValueFromEvent={value => {
              return value?.format(FORMAT_DATETIME)
            }}
            getValueProps={value => ({
              value: value ? moment(value) : value,
            })}
          >
            <DatePicker showTime />
          </Form.Item>

          <Form.Item
            label={'附件'}
            name={'confirmImgList'}
            getValueFromEvent={onAlbumUploadEvent}
            getValueProps={onAlbumUploadProps}
            rules={[{required: true}]}
          >
            <Album
              data={{userId}}
              limit={5}
              action={baseUrl + '/steward/admin/order/upload-file'}
              accept={'.jpg, .jpeg, .png'}
            >
              <UploadButton title={'上传照片'} />
            </Album>
          </Form.Item>
          <Form.Item name={'confirmRemark'} label='退款备注'>
            <Input.TextArea rows={4} placeholder={'请输入'} maxLength={100} showCount style={{resize: 'none'}} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export default React.forwardRef(Refund)
