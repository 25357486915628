import React, {useCallback, useEffect, useState} from 'react'
import {Page} from '@/components/Page'
import {Button, Form, Input, message, PageHeader, Select, Space} from 'antd'
import {Album, UploadButton} from '@/components/Album'
import commonStyles from '@/assets/styles/common.module.less'
import {ebassModel} from '@/store/models/Ebaas'
import {certNoValid, mobileValid} from '@/utils/util'
import {useAppHistory, useAppLocation} from '@/common/hooks/useRouter'
import {baseUrl} from '@/common/config'
import {onAlbumUploadSingleEvent, onAlbumUploadSingleProps} from '@/pages/service/utils/albumUpload'

export default function StoreAddAgentSteward() {
  const history = useAppHistory()
  const location = useAppLocation<'/store-manage/add-agent'>()
  const userId = location.state?.userId

  const [form] = Form.useForm()

  const [disable, setDisable] = useState(false)

  // 选择人员
  // const [userList, setUserList] = useState<SysUserVO[]>([])
  // const onSearch = useCallback(value => {
  //   sysUserModel
  //     .sysUserList({name: value, status: '1', pageable: false})
  //     .then(({response: {data}}) => setUserList(data))
  // }, [])
  // useEffect(() => {
  //   if (!userId) {
  //     // 新增的时候查一下
  //     onSearch('')
  //   }
  // }, [onSearch, userId])

  // 所属门店
  const [storeList, setStoreList] = useState<EbaasStoreVO[]>([])
  useEffect(() => {
    ebassModel.ebaasStoreList({} as EbaasStoreQueryDTO).then(({response: {data}}) => setStoreList(data))
  }, [])

  // 编辑的时候查看详情
  useEffect(() => {
    /* eslint-disable @typescript-eslint/no-extra-semi */
    ;(async () => {
      if (userId) {
        const {
          response: {data},
        } = await ebassModel.ebaasBrokerGet({userId})
        // await onSearch(data.name)
        form.setFieldsValue({
          avatarFpath: data.avatarFpath,
          brokerType: data.brokerType,
          idCardBack: data.idCardBack,
          idCardFront: data.idCardFront,
          idNum: data.idNum,
          inServiceStatus: data.inServiceStatus,
          mobile: data.mobile,
          storeNo: data.storeNo,
          userId: data.userId,
          userName: data.name,
        })
        setDisable(data.verificationStatus === 1)
      }
    })()
  }, [form, userId])

  const onFinish = useCallback(async () => {
    const formValues = form.getFieldsValue(true)
    // console.log('formValues', formValues)
    if (userId) {
      await ebassModel.ebaasBrokerUpdate(formValues)
    } else {
      await ebassModel.ebaasBrokerInsert(formValues)
    }
    message.success('操作成功！')
    history.goBack()
  }, [form, history, userId])

  const onReset = useCallback(() => {
    history.goBack()
  }, [history])

  // const userLoading = sysUserModel.sysUserList.useLoading()
  const insertLoading = ebassModel.ebaasBrokerInsert.useLoading()
  const updateLoading = ebassModel.ebaasBrokerUpdate.useLoading()

  return (
    <Page header={<PageHeader onBack={() => history.goBack()} ghost={false} title={userId ? '编辑' : '新增人员'} />}>
      <div className={commonStyles.content} style={{backgroundColor: '#fff', paddingTop: 20}}>
        <Form
          form={form}
          labelAlign={'right'}
          labelCol={{flex: '100px', offset: 2}}
          style={{width: '50%'}}
          onFinish={onFinish}
          onReset={onReset}
        >
          {/*<Form.Item label={'选择人员'} name={'userId'} rules={[{required: true, message: '请选择人员'}]}>*/}
          {/*  <Select*/}
          {/*    placeholder={'请选择'}*/}
          {/*    allowClear*/}
          {/*    showSearch*/}
          {/*    loading={userLoading}*/}
          {/*    onSearch={onSearch}*/}
          {/*    filterOption={false}*/}
          {/*    disabled={!!userId}*/}
          {/*    onSelect={(_, option) => {*/}
          {/*      const {children} = option ?? {}*/}
          {/*      if (children) {*/}
          {/*        const [name] = children?.split('-') ?? []*/}
          {/*        form.setFieldsValue({name})*/}
          {/*      }*/}
          {/*    }}*/}
          {/*  >*/}
          {/*    {userList.map(item => (*/}
          {/*      <Select.Option key={item.userId} value={item.userId}>{`${item.name}-${item.mobile}`}</Select.Option>*/}
          {/*    ))}*/}
          {/*  </Select>*/}
          {/*</Form.Item>*/}
          <Form.Item noStyle shouldUpdate>
            {form1 => {
              const userId = form1.getFieldValue('userId')
              return userId ? (
                <Form.Item label={'人员ID'} name={'userId'}>
                  {userId}
                </Form.Item>
              ) : null
            }}
          </Form.Item>
          <Form.Item label={'人员姓名'} name={'userName'} rules={[{required: true, message: '请输入人员姓名'}]}>
            <Input placeholder={'请输入'} allowClear />
          </Form.Item>
          <Form.Item label={'所属门店'} name={'storeNo'} rules={[{required: true, message: '请选择所属门店'}]}>
            <Select placeholder={'请选择'} allowClear disabled={!!userId}>
              {storeList.map(item => (
                <Select.Option key={item.storeNo} value={item.storeNo}>
                  {item.storeName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label={'手机号码'} name={'mobile'} rules={[{validator: mobileValid}]} required>
            <Input placeholder={'请输入'} allowClear />
          </Form.Item>
          <Form.Item label={'经纪人类型'} name={'brokerType'} rules={[{required: true, message: '请选择经纪人类型'}]}>
            <Select placeholder={'请选择'} allowClear disabled={disable}>
              <Select.Option value={'5'}>公寓管家</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item label={'身份证号码'} name={'idNum'} rules={[{validator: certNoValid}]} required>
            <Input placeholder={'请输入'} allowClear disabled={disable} />
          </Form.Item>
          <Form.Item label={'身份证照片'} required shouldUpdate>
            {form1 => {
              const {idCardFront, idCardBack} = form1.getFieldsValue(true)
              // console.log('idCardFront, idCardBack', idCardFront, idCardBack)
              return (
                <Space>
                  <Form.Item
                    name={'idCardFront'}
                    noStyle
                    rules={[{required: true, message: '身份证正面照不能为空'}]}
                    getValueFromEvent={onAlbumUploadSingleEvent}
                    getValueProps={onAlbumUploadSingleProps}
                  >
                    <Album
                      limit={1}
                      action={baseUrl + '/ebaas/common/upload'}
                      accept={'.jpg, .jpeg, .png'}
                      multiple={false}
                      disabled={disable}
                    >
                      {idCardFront ? null : <UploadButton title={'正面'} />}
                    </Album>
                  </Form.Item>
                  <Form.Item
                    name={'idCardBack'}
                    noStyle
                    getValueFromEvent={onAlbumUploadSingleEvent}
                    getValueProps={onAlbumUploadSingleProps}
                  >
                    <Album
                      limit={1}
                      action={baseUrl + '/ebaas/common/upload'}
                      accept={'.jpg, .jpeg, .png'}
                      multiple={false}
                      disabled={disable}
                    >
                      {idCardBack?.length ? null : <UploadButton title={'反面'} />}
                    </Album>
                  </Form.Item>
                </Space>
              )
            }}
          </Form.Item>
          <Form.Item label={'头像照片'} required shouldUpdate>
            {form1 => {
              const avatarFpath = form1.getFieldValue('avatarFpath')
              // console.log('avatarFpath', avatarFpath)
              return (
                <Form.Item
                  name={'avatarFpath'}
                  rules={[{required: true, message: '头像照片不能为空'}]}
                  getValueFromEvent={onAlbumUploadSingleEvent}
                  getValueProps={onAlbumUploadSingleProps}
                  noStyle
                >
                  <Album
                    limit={1}
                    action={baseUrl + '/ebaas/common/upload'}
                    accept={'.jpg, .jpeg, .png'}
                    multiple={false}
                  >
                    {avatarFpath ? null : <UploadButton />}
                  </Album>
                </Form.Item>
              )
            }}
          </Form.Item>
          <Form.Item label={' '} colon={false}>
            <Space>
              <Button type={'primary'} htmlType={'submit'} loading={insertLoading || updateLoading}>
                保存
              </Button>
              <Button htmlType={'reset'}>取消</Button>
            </Space>
          </Form.Item>
        </Form>
      </div>
    </Page>
  )
}
