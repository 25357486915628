import React, {CSSProperties} from 'react'
import {MapContext} from '@/components/Map/mapContext'
import {renderToString} from 'react-dom/server'

export interface LabelProps {
  point: {lng: number; lat: number}
  offset?: {width: number; height: number}
  style?: CSSProperties
  options?: Record<string, any>
  onClick?: (event: Event) => void
}

export class Label extends React.Component<LabelProps> {
  static contextType = MapContext
  context: React.ContextType<typeof MapContext>

  private instance = null

  componentDidMount() {
    this.init()
  }
  componentWillUnmount() {
    this.destroy()
  }

  private onClick = (event: Event) => this.props?.onClick(event)

  private init = () => {
    const {options, point, offset, children, style} = this.props

    const labelOptions = {
      ...options,
      position: point,
      ...(offset ? {offset} : {}),
    }
    this.instance = new window.BMapGL.Label(renderToString(<div>{children}</div>), labelOptions) // 创建信息窗口对象
    this.instance.setStyle({border: 'none', backgroundColor: 'transparent', ...style})
    if (this.props.onClick) {
      this.instance.addEventListener('click', this.onClick)
    }

    this.context.addOverlay(this.instance)
  }
  private destroy = () => {
    if (this.props.onClick) {
      this.instance.removeEventListener('click', this.onClick)
    }
    this.context.removeOverlay(this.instance)
    this.instance = null
  }

  render() {
    return null
  }
}
