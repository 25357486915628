import {Model} from '@redux-model/web'
import {$api} from '@/store/service'

export interface UserModel {
  token: string
  userInfo: SysUserVO
  permission: Record<string, boolean>
}

class User extends Model<UserModel> {
  clearUserInfo = this.action(state => {
    state.token = ''
    state.userInfo = {} as SysUserVO
    state.permission = {}
  })

  login = $api.action(params =>
    this.post<ApiResponse>('/sys-user/login')
      .body(params)
      .onSuccess((state, {response}) => {
        // console.log('登录结果:', response)
        state.token = response.data
      })
  )
  queryUserInfo = $api.action(() =>
    this.post<ApiResponse<SysUserVO>>('/sys-user/info')
      .body({})
      .onSuccess((state, {response}) => {
        state.userInfo = response.data

        const permission = response.data?.permissionIdList.reduce((prev, item) => ({...prev, [item]: true}), {})
        if (permission) {
          state.permission = permission
        }
      })
  )

  logout = $api.action(() =>
    this.post<ApiResponse>('/sys-user/logout')
      .body({})
      .onSuccess((state, {response}) => {
        state.token = ''
        state.userInfo = {} as SysUserVO
        state.permission = {}
      })
      .onFail(state => {
        state.token = ''
        state.userInfo = {} as SysUserVO
        state.permission = {}
      })
  )

  // 修改密码(登录后直接修改)  |  object:{originPwd:原密码,newPwd:新密码}
  sysUserUpdatePassword = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<string>>('/sys-user/update-password').body(args)
  )

  // 用户协议记录  |  object:{userId:协议id}
  userAgreementList = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<UserAgreementVO[]>>('/user/agreement/list').body(args)
  )

  // 申请记录列表
  userLessorCompanyApplyList = $api.action((args: LessorCompanyApplyQueryDTO) =>
    this.post<ApiResponse<LessorCompanyApplyVO[]>>('/user/lessor/company/apply/list').body(args)
  )

  // 申请记录审核
  userLessorCompanyApplyAudit = $api.action((args: LessorCompanyApplyAuditDTO) =>
    this.post<ApiResponse<Record<string, any>>>('/user/lessor/company/apply/audit').body(args)
  )

  // 平台账户集余额查询
  allinpayQueryMerchantBalance = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<Record<string, any>>>('/allinpay/query-merchant-balance').body(args)
  )

  // 服务账单-开票
  userLessorBillInvoiceMake = $api.action((args: UserLessorBillInvoiceMakeDTO) =>
    this.post<ApiResponse<Record<string, any>>>('/user/lessor/bill/invoice/make').body(args)
  )

  // 服务账单-开票列表
  userLessorBillInvoiceList = $api.action((args: UserLessorBillInvoiceListDTO) =>
    this.post<ApiResponse<UserLessorBillInvoiceVO[]>>('/user/lessor/bill/invoice/list').body(args)
  )

  // 服务账单-开票详情  |  object:{invoiceId:发票Id}
  userLessorBillInvoiceInfo = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<UserLessorBillInvoiceVO>>('/user/lessor/bill/invoice/info').body(args)
  )

  // 服务账单-开票列表-统计
  userLessorBillInvoiceStatistics = $api.action((args: UserLessorBillInvoiceListDTO) =>
    this.post<ApiResponse<number>>('/user/lessor/bill/invoice/statistics').body(args)
  )

  initReducer() {
    return {
      token: '',
      userInfo: {} as SysUserVO,
      permission: {},
    }
  }
}

export const userModel = new User()
