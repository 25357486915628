import React, {useCallback, useEffect, useState} from 'react'
import {Page} from '@/components/Page'
import {Breadcrumb} from '@/components/Breadcrumb'
import {
  Button,
  Col,
  Form,
  Row,
  Input,
  Select,
  Space,
  Table,
  Switch,
  Modal,
  DatePicker,
  InputNumber,
  message,
  Descriptions,
  Typography,
} from 'antd'
import commonStyles from '@/assets/styles/common.module.less'
import {condoModel} from '@/store/models/Condo'
import {Album, UploadButton} from '@/components/Album'
import {baseUrl} from '@/common/config'
import moment from 'moment'
import {getImg, isUploaded, isFile} from '@/utils/util'
import {usePermission} from '@/components/Permission'
import {getPaginationProps} from '@/common/biz/getPaginationProps'
import {useCodeArraySync, useCodeSync} from '@/store/models/Common'

export default function PropertyDiscount() {
  const [auth] = usePermission()
  const [form] = Form.useForm()
  const [addForm] = Form.useForm()
  const [list, setList] = useState<CondoProjectDiscountVO[]>([])
  const [total, setTotal] = useState(0)
  const [addVisible, setAddVisible] = useState(false)
  const loading = condoModel.condoProjectDiscountList.useLoading()
  const [projectList, setProjectList] = useState<CondoProjectVO[]>([])
  const [info, setInfo] = useState({} as CondoProjectDiscountVO)
  const search = useCallback(() => {
    const formValues = form.getFieldsValue(true)
    condoModel.condoProjectDiscountList(formValues).then(({response: {data, total}}) => {
      setList(data)
      setTotal(total)
    })
  }, [form])

  const discountTypeArr = useCodeArraySync('condo.project.discountType')
  const discountType = useCodeSync('condo.project.discountType')

  useEffect(() => {
    condoModel.condoProjectList({pageable: false} as CondoProjectListDTO).then(({response: {data, total}}) => {
      setProjectList(data)
    })
  }, [])

  useEffect(() => {
    search()
  }, [search])

  const [discountForm] = Form.useForm()
  const [discountVisible, setDiscountVisible] = useState(false)
  const addLoading = condoModel.condoProjectDiscountAddLimitNum.useLoading()

  const [logVisible, setLogVisible] = useState(false)
  const [discountName, setDiscountName] = useState('')
  const [discountLogList, setDiscountLogList] = useState<CondoProjectDiscountLogVO[]>([])

  return (
    <Page
      breadcrumb={
        <Breadcrumb
          right={
            auth('003101002') && (
              <Button type={'primary'} onClick={() => setAddVisible(true)}>
                新增优惠活动
              </Button>
            )
          }
        />
      }
    >
      <Form form={form} initialValues={{pageNum: 1, pageSize: 10}} onFinish={search} onReset={search}>
        <div className={commonStyles.filterSection}>
          <Row>
            <Col span={7}>
              <Form.Item label={'关键字'} name={'discountName'}>
                <Input placeholder={'请输入活动名称'} allowClear />
              </Form.Item>
            </Col>
            <Col offset={1} span={7}>
              <Form.Item label={'状态'} name={'discountStatus'}>
                <Select placeholder={'请选择'} allowClear>
                  <Select.Option value={'1'}>正常</Select.Option>
                  <Select.Option value={'0'}>禁用</Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row justify={'end'}>
            <Space>
              <Button type={'primary'} htmlType={'submit'} onClick={() => form.setFieldsValue({pageNum: 1})}>
                查询
              </Button>
              <Button htmlType={'reset'}>重置</Button>
            </Space>
          </Row>
        </div>
        <div className={commonStyles.filterSection}>
          <Table
            rowKey={'discountId'}
            loading={loading}
            dataSource={list}
            scroll={{x: 'max-content'}}
            columns={[
              {title: '优惠名称', dataIndex: 'discountName'},
              {
                title: '活动时间',
                render: record =>
                  moment(record.beginDate).format('YYYY-MM-DD') + '至' + moment(record.endDate).format('YYYY-MM-DD'),
              },
              {
                title: '优惠',
                render: record =>
                  `${discountType[record.discountType]}` +
                  `${record.discountTypeValue}` +
                  `${['1', '4'].includes(record.discountType) ? '元' : ''}`,
              },
              {title: '优惠码数量', dataIndex: 'numLimit'},
              {title: '剩余优惠码数量', render: (_, record) => record.numLimit - record.numUsed},
              {title: '租期范围', render: (_, record) => `${record.periodMin}-${record.periodMax}`},
              {title: '状态', dataIndex: 'discountStatus', render: text => (text === '1' ? '正常' : '禁用')},
              {
                title: '操作',
                render: record => {
                  return (
                    <Space>
                      {auth('023101002') && (
                        <Switch
                          checked={record.discountStatus === '1'}
                          onChange={value => {
                            Modal.confirm({
                              title: '提示',
                              icon: null,
                              centered: true,
                              content: `是否确认${value ? '启用' : '禁用'}该优惠活动？`,
                              onOk: async () => {
                                await condoModel.condoProjectDiscountEnableOrDisable({
                                  discountId: record.discountId,
                                  enable: value,
                                })
                                search()
                              },
                            })
                          }}
                        />
                      )}

                      {record.discountStatus === '1'
                        ? auth('001101002') && (
                            <Button
                              style={{padding: '0'}}
                              type={'link'}
                              onClick={async () => {
                                const {
                                  response: {data},
                                } = await condoModel.condoProjectDiscountGet({discountId: record.discountId})
                                setInfo(data)
                              }}
                            >
                              查看
                            </Button>
                          )
                        : auth('002101002') && (
                            <Button
                              style={{padding: '0'}}
                              type={'link'}
                              onClick={async () => {
                                const {
                                  response: {data},
                                } = await condoModel.condoProjectDiscountGet({discountId: record.discountId})
                                setAddVisible(true)
                                addForm.setFieldsValue({
                                  ...data,
                                  _date: [moment(data.beginDate), moment(data.endDate)],
                                  discountCondoIdList: data.discountCondoList.map(item => item.key),
                                })
                              }}
                            >
                              编辑
                            </Button>
                          )}
                      <Button
                        style={{padding: '0'}}
                        type={'link'}
                        onClick={() => {
                          discountForm.setFieldsValue({
                            discountId: record.discountId,
                            numLimit: record.numLimit,
                            numUsed: record.numUsed,
                          })
                          setDiscountVisible(true)
                        }}
                      >
                        新增
                      </Button>
                      <Button
                        style={{padding: '0'}}
                        type={'link'}
                        onClick={async () => {
                          const {
                            response: {data},
                          } = await condoModel.condoProjectDiscountLogList({discountId: record.discountId})
                          setDiscountLogList(data)
                          setDiscountName(record.discountName)
                          setLogVisible(true)
                        }}
                      >
                        历史记录
                      </Button>
                    </Space>
                  )
                },
              },
            ]}
            pagination={getPaginationProps(form, total)}
          />
        </div>
      </Form>

      {/* 新增优惠活动 */}
      <Modal
        visible={addVisible}
        onOk={() => addForm.submit()}
        centered
        onCancel={() => {
          setAddVisible(false)
        }}
        afterClose={() => addForm.resetFields()}
        destroyOnClose
        title={'新增优惠活动'}
        okText={'保存'}
        width={600}
      >
        <Form
          form={addForm}
          labelCol={{span: 4}}
          wrapperCol={{span: 15}}
          onFinish={async () => {
            const {discountId, discountPicList, ...formValues} = addForm.getFieldsValue(true)
            if (discountId) {
              await condoModel.condoProjectDiscountEdit({
                ...formValues,
                discountId,
                discountPicList: discountPicList ?? [],
              })
              message.success('编辑优惠活动成功！')
            } else {
              await condoModel.condoProjectDiscountInsert({
                ...formValues,
                discountPicList: discountPicList ?? [],
              })
              message.success('新增优惠活动成功！')
            }

            setAddVisible(false)
            search()
          }}
        >
          <Form.Item label={'名称'} required shouldUpdate>
            {f =>
              f.getFieldValue('discountId') ? (
                f.getFieldValue('discountName')
              ) : (
                <Form.Item
                  name={'discountName'}
                  style={{marginBottom: 0}}
                  rules={[{required: true, message: '请输入活动名称'}]}
                >
                  <Input placeholder={'请输入活动名称'} allowClear maxLength={20} />
                </Form.Item>
              )
            }
          </Form.Item>

          <Form.Item label={'活动时间'} name={'_date'} rules={[{required: true, message: '请选择活动时间'}]}>
            <DatePicker.RangePicker
              format={'YYYY-MM-DD'}
              onChange={(_, dateStrings) => {
                const [beginDate, endDate] = dateStrings ?? []
                addForm.setFieldsValue({beginDate, endDate})
              }}
            />
          </Form.Item>
          <Form.Item label={'优惠选择'} required style={{marginBottom: 0}}>
            <Space>
              <Form.Item name={'discountType'} rules={[{required: true, message: '请选择优惠类型'}]}>
                <Select style={{width: 180}} placeholder={'请选择'} allowClear>
                  {discountTypeArr.map(item => (
                    <Select.Option key={item.id} value={item.id}>
                      {item.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item noStyle shouldUpdate>
                {f =>
                  ['1', '4'].includes(f.getFieldValue('discountType')) ? (
                    <Form.Item name={'discountTypeValue'} rules={[{required: true, message: '请输入优惠'}]}>
                      <InputNumber max={9999} min={1} style={{width: 180}} placeholder={'请输入'} />
                    </Form.Item>
                  ) : (
                    <Form.Item name={'discountTypeValue'} rules={[{required: true, message: '请输入优惠'}]}>
                      <Input min={0.01} max={0.99} allowClear style={{width: 180}} placeholder={'请输入'} />
                    </Form.Item>
                  )
                }
              </Form.Item>
            </Space>
          </Form.Item>
          <Form.Item
            label={'优惠码数量'}
            name={'numLimit'}
            rules={[
              {required: true, message: '请输入优惠码数量'},
              {type: 'number', min: 1, max: 999, message: '优惠码数量范围为1-999'},
            ]}
          >
            <InputNumber />
          </Form.Item>
          <Form.Item
            label={'租期范围'}
            required
            shouldUpdate={(prevValues, nextValues) =>
              prevValues.periodMin !== nextValues.periodMin || prevValues.periodMax !== nextValues.periodMax
            }
          >
            {form1 => {
              const {periodMin, periodMax} = form1.getFieldsValue(['periodMin', 'periodMax'])
              return (
                <Space align={'center'}>
                  <Form.Item
                    name={'periodMin'}
                    style={{marginBottom: 0}}
                    rules={[
                      {
                        validator: (_, value, callback) => {
                          if (!value) {
                            callback('请输入最短租期范围')
                            return
                          }
                          if (value < 1 || value > 24) {
                            callback('最短租期范围为1-24')
                            return
                          }
                          if (value > periodMax) {
                            callback('最短租期不可大于最长租期')
                            return
                          }
                          callback()
                        },
                      },
                    ]}
                  >
                    <InputNumber />
                  </Form.Item>
                  -
                  <Form.Item
                    name={'periodMax'}
                    style={{marginBottom: 0}}
                    rules={[
                      {
                        validator: (_, value, callback) => {
                          if (!value) {
                            callback('请输入最长租期范围')
                            return
                          }
                          if (value < 1 || value > 24) {
                            callback('最长租期范围为1-24')
                            return
                          }
                          if (value < periodMin) {
                            callback('最长租期不可小于最短租期')
                            return
                          }
                          callback()
                        },
                      },
                    ]}
                  >
                    <InputNumber />
                  </Form.Item>
                </Space>
              )
            }}
          </Form.Item>
          <Form.Item
            label={'房源范围'}
            name={'discountCondoIdList'}
            rules={[{required: true, message: '请选择房源范围'}]}
          >
            <Select mode={'multiple'} placeholder={'请选择'} allowClear>
              {projectList.map(item => (
                <Select.Option key={item.condoProjectId} value={item.condoProjectId}>
                  {item.projectName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item noStyle shouldUpdate>
            {f => (
              <Form.Item
                label={'活动照片'}
                wrapperCol={{}}
                name={'discountPicList'}
                getValueFromEvent={event => {
                  return event.fileList?.reduce((prev, item) => {
                    if (isFile(item) && item.status === 'done') {
                      if (item.response?.code === 200) {
                        prev.push(item.response?.data?.imgUrl)
                      } else {
                        // 未上传成功丢掉该项目
                        message.error(item.response?.msg)
                      }
                      return prev
                    }
                    // 已上传的文件取 uid
                    if (isUploaded(item)) return [...prev, item.uid]

                    // file 对象原封不动
                    return [...prev, item]
                  }, [])
                }}
                getValueProps={value => ({
                  fileList: value?.map(item => (typeof item === 'string' ? {uid: item, url: getImg(item)} : item)),
                })}
              >
                <Album
                  action={baseUrl + '/file/upload'}
                  data={{
                    category: ' condo_project_discount',
                  }}
                  limit={9}
                >
                  <UploadButton />
                </Album>
              </Form.Item>
            )}
          </Form.Item>
        </Form>
      </Modal>

      {/* 查看详情 */}
      <Modal
        title={'活动详情'}
        visible={!!info.discountId}
        centered
        footer={null}
        onCancel={() => setInfo({} as CondoProjectDiscountVO)}
      >
        <Descriptions column={1}>
          <Descriptions.Item label={'名称'}>{info.discountName}</Descriptions.Item>
          <Descriptions.Item label={'活动时间'}>
            {moment(info.beginDate).format('YYYY-MM-DD')}至{moment(info.endDate).format('YYYY-MM-DD')}
          </Descriptions.Item>
          <Descriptions.Item label={'优惠选择'}>{discountType[info.discountType]}</Descriptions.Item>
          <Descriptions.Item label={'房源范围'}>
            {info.discountCondoList?.map(item => item.value).join(',')}
          </Descriptions.Item>
          <Descriptions.Item label={'活动照片'}>
            <Album disabled={true} fileList={info.discountPicList?.map(item => ({url: getImg(item), uid: item}))}>
              {null}
            </Album>
          </Descriptions.Item>
        </Descriptions>
      </Modal>

      {/* 新增优化码数量 */}
      <Modal
        title={'新增优惠码数量'}
        visible={discountVisible}
        centered
        footer={null}
        onCancel={() => setDiscountVisible(false)}
        afterClose={() => discountForm.resetFields()}
      >
        <Form
          form={discountForm}
          labelAlign={'right'}
          labelCol={{span: 6}}
          onFinish={async () => {
            const formValues = discountForm.getFieldsValue(true)
            await condoModel.condoProjectDiscountAddLimitNum(formValues)
            message.success('操作成功！')
            setDiscountVisible(false)
            search()
          }}
          onReset={() => setDiscountVisible(false)}
        >
          <Form.Item label={'优惠码数量'} shouldUpdate>
            {form1 => form1.getFieldValue('numLimit')}
          </Form.Item>
          <Form.Item label={'剩余优惠码数量'} shouldUpdate>
            {form1 => {
              const {numLimit, numUsed} = form1.getFieldsValue(['numLimit', 'numUsed'])
              return numLimit - numUsed
            }}
          </Form.Item>
          <Form.Item label={'新增数量'} name={'num'} rules={[{required: true, message: '请输入'}]}>
            <InputNumber />
          </Form.Item>
          <Row justify={'end'}>
            <Space>
              <Button htmlType={'reset'}>取消</Button>
              <Button type={'primary'} htmlType={'submit'} loading={addLoading}>
                保存
              </Button>
            </Space>
          </Row>
        </Form>
      </Modal>

      {/* 历史记录 */}
      <Modal title={'历史记录'} visible={logVisible} centered footer={null} onCancel={() => setLogVisible(false)}>
        <Typography.Title level={5}>{discountName}</Typography.Title>
        <Table
          rowKey={'logId'}
          dataSource={discountLogList}
          columns={[
            {title: '类型', dataIndex: 'typeName'},
            {title: '操作人员', dataIndex: 'operatorName'},
            {title: '时间', dataIndex: 'insertTime'},
          ]}
          pagination={{position: ['bottomCenter'], pageSize: 5}}
        />
        <Row justify={'end'} style={{marginTop: '20px'}}>
          <Button onClick={() => setLogVisible(false)}>取消</Button>
        </Row>
      </Modal>
    </Page>
  )
}
