import {Model} from '@redux-model/web'
import {$api} from '@/store/service'

export interface SysRoleModel {}

class SysRole extends Model<SysRoleModel> {
  initReducer() {
    return {}
  }

  /**
   * 用户组管理列表
   * @deprecated: 使用 sysRoleModel.sysRoleList 代替
   */
  querySysRoleList = $api.action((params: SysListDTO) =>
    this.post<ApiResponse<SysRoleVO[]>>('/sys-role/list').body(params)
  )

  // 系统角色列表
  sysRoleList = $api.action((args: SysListDTO) => this.post<ApiResponse<SysRoleVO[]>>('/sys-role/list').body(args))

  //新增或更新系统角色
  upsertSysRole = $api.action((params: SysRoleDTO) => this.post('/sys-role/upsert').body(params))

  //删除系统角色
  querySysRoleDelete = $api.action((roleId: string) => this.post('/sys-role/delete').body({roleId}))

  //角色详情
  sysRoleDetail = $api.action((roleId: string) => this.post<ApiResponse<SysRoleVO>>('/sys-role/detail').body({roleId}))

  //权限列表
  // sysPermissionList = $api.action((params: Partial<SysListDTO>) =>
  //   this.post<ApiResponse<SysPermissionVO[]>>('/sys-permission/list').body({
  //     pageNum: 1,
  //     pageSize: 10,
  //     pageable: true,
  //     ...params,
  //   })
  // )

  //新增或更新权限管理
  // sysPermissionEdit = $api.action((params: Partial<SysPermissionDTO>) => this.post('/sys-permission/edit').body(params))

  //删除权限
  // sysPermissionDelete = $api.action((permissionId: string) => this.post('/sys-permission/delete').body({permissionId}))
}

export const sysRoleModel = new SysRole()
