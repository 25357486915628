import React, {HTMLAttributes} from 'react'
import {Tooltip} from 'antd'

export const Ellipsis: React.FC<
  {value: string; separator?: string} & Pick<HTMLAttributes<HTMLDivElement>, 'style' | 'className'>
> = props => {
  if (!props.value) return <>{props.value}</>

  const [text, ...rest] = props.value.split(props.separator)
  return (
    <Tooltip placement='top' title={props.value}>
      <div
        className={props.className}
        style={{maxWidth: 200, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', ...props.style}}
      >
        {text}
        {rest.length ? '...' : ''}
      </div>
    </Tooltip>
  )
}

Ellipsis.defaultProps = {
  separator: ',',
}
