import * as echarts from 'echarts/core'
import {BarChart, BarSeriesOption, LineChart, LineSeriesOption, PieChart} from 'echarts/charts'
import {
  TitleComponent,
  TitleComponentOption,
  TooltipComponent,
  TooltipComponentOption,
  LegendComponent,
  LegendComponentOption,
  GridComponent,
  GridComponentOption,
  DataZoomComponent,
  DataZoomComponentOption,
  DataZoomInsideComponent,
} from 'echarts/components'
import {CanvasRenderer} from 'echarts/renderers'
import {PieSeriesOption} from 'echarts'

echarts.use([
  BarChart,
  LineChart,
  PieChart,
  CanvasRenderer,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  DataZoomComponent,
  DataZoomInsideComponent,
])

export type ECOption = echarts.ComposeOption<
  | BarSeriesOption
  | TitleComponentOption
  | TooltipComponentOption
  | LegendComponentOption
  | GridComponentOption
  | DataZoomComponentOption
  | DataZoomComponentOption
  | LineSeriesOption
  | PieSeriesOption
>
