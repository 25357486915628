import React from 'react'
import styles from './index.module.less'
import {Divider, Space, Tooltip} from 'antd'
import {InfoCircleOutlined} from '@ant-design/icons'

export default function HeaderList({data, width}: {data: any; width?: string}) {
  return (
    <div className={styles.row}>
      {data.map((item, index) => (
        <RenderItem key={index} width={width} {...item} />
      ))}
    </div>
  )
}

export interface RenderItemProps {
  label: string
  num: number | string
  tip: string
  show?: boolean
  width?: string
  onClick?: () => void
}

const RenderItem: React.FC<RenderItemProps> = ({width, onClick, num, label, tip, show}) => {
  return (
    <div
      className={styles.renderView}
      style={{width}}
      onClick={() => {
        onClick?.()
      }}
    >
      <div className={styles.renderItem}>
        <div className={styles.item}>
          <Space size={5} direction={'vertical'} align={'center'}>
            <span className={styles.num}>{num}</span>
            <div style={{width: 12, height: 4, background: '#A0DBD9', borderRadius: 4}} />
            <span className={styles.describe}>{label}</span>
          </Space>
        </div>
        <div className={styles.tipStyle}>
          <Tooltip title={tip}>
            <InfoCircleOutlined style={{color: '#06B6AF'}} />
          </Tooltip>
        </div>
      </div>
      {show && <Divider type={'vertical'} style={{margin: 0, height: 21}} />}
    </div>
  )
}
