import {Model} from '@redux-model/web'
import {$api} from '@/store/service'

interface StewardModel {}

class Steward extends Model<StewardModel> {
  protected initReducer(): StewardModel {
    return {}
  }

  // 管家服务单-列表
  stewardAdminOrderList = $api.action((args: StewardOrderQueryDTO) =>
    this.post<ApiResponse<StewardOrderQueryVO[]>>('/steward/admin/order/list').body(args)
  )

  // 管家服务单-详情
  stewardOrderDetail = $api.action((params: {orderId: string}) =>
    this.post<ApiResponse<StewardOrderQueryVO>>('/steward/admin/order/detail').body(params)
  )

  // 管家服务-服务列表
  stewardServiceList = $api.action((params: {pid?: string}, index: number) =>
    this.post<ApiResponse<StewardServiceVO[]>>('/steward/admin/service/list').body(params).metas(index)
  )

  // // 管家服务-服务详情
  // stewardAdminServiceDetail = $api.action((id: string) =>
  //   this.post<ApiResponse<StewardServiceVO>>('/steward/admin/service/detail').body({id})
  // )

  // 管家服务-服务详情  |  object:{serviceId:服务id,customerId:客户id}
  stewardAdminServiceDetail = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<StewardServiceVO>>('/steward/admin/service/detail').body(args)
  )

  // 管家服务-新增或编辑
  stewardServiceUpset = $api.action((params: StewardServiceUpsetDTO, index: number) =>
    this.post<ApiResponse>('/steward/admin/service/upset').body(params).metas(index)
  )

  // 管家服务-删除
  stewardServiceDelete = $api.action((id: string) => this.post<ApiResponse>('/steward/admin/service/delete').body({id}))

  // 管家分配-列表
  stewardAdminPropertyManageList = $api.action((args: StewardPropertyManageQueryDTO) =>
    this.post<ApiResponse<StewardPropertyManageQueryVO[]>>('/steward/admin/property-manage/list').body(args)
  )

  // 管家分配-列表分配
  stewardAdminPropertyManageAllot = $api.action((args: StewardPropertyManageAllotDTO) =>
    this.post<ApiResponse<Record<string, any>>>('/steward/admin/property-manage/allot').body(args)
  )

  // 管家分配-列表分配(自动)
  stewardAdminPropertyManageAllotAuto = $api.action((args: StewardPropertyManageAllotDTO) =>
    this.post<ApiResponse<StewardPropertyManageQueryVO[]>>('/steward/admin/property-manage/allot-auto').body(args)
  )

  //派遣管家
  stewardServiceDispatch = $api.action((params: {orderId: string; stewardId: string; dispatchReason: string}) =>
    this.post('/steward/admin/service/dispatch').body(params)
  )

  //服务单列表excel导出所有结果路径
  stewardAdminDataExcelPath = $api.action((params: Partial<StewardOrderQueryDTO>) =>
    this.post<ApiResponse>('/steward/admin/data-excel-path').body({
      pageNum: 1,
      pageSize: 10,
      pageable: false,
      ...params,
    })
  )

  // 管家服务单-下单
  stewardAdminOrderMake = $api.action((args: Partial<StewardOrderInsertDTO>) =>
    this.post('/steward/admin/order/make').body(args)
  )

  // 管家服务-服务列表排序  |  object:{serviceIdList:顺序服务id}
  stewardAdminServiceListOrder = $api.action((args: {serviceIdList: string[]}) =>
    this.post('/steward/admin/service/list/order').body(args)
  )

  // 设置仓库库存警戒线  |  object:{库存id(warehouseMaterialId):警戒阈值}
  stewardWarehouseInventoryThreshold = $api.action((args: StewardWarehouseThresholdDTO) =>
    this.post<ApiResponse<StewardMaterialListVO[]>>('/steward/warehouse/inventory/threshold').body(args)
  )
  // 绩效管理-终审确认  |  object:{salaryId:绩效记录id}
  stewardSalaryCheck = $api.action((args: {salaryId: string}) => this.post('/steward/salary/check').body(args))

  // 绩效管理-创建管家的绩效记录(传[]则创建所有在岗的管家)
  stewardSalaryCreateV2 = $api.action((args: StewardSalaryCreateDTO) =>
    this.post<ApiResponse<Record<string, any>>>('/steward/salary/createV2').body(args)
  )

  // 绩效管理-详情  |  object:{salaryId:绩效记录id}
  stewardSalaryDetail = $api.action((args: {salaryId: string}) =>
    this.post<ApiResponse<StewardSalaryVO>>('/steward/salary/detail').body(args)
  )

  // 绩效管理-列表
  stewardSalaryList = $api.action((args: StewardSalaryQueryDTO) =>
    this.post<ApiResponse<StewardSalaryVO[]>>('/steward/salary/list').body(args)
  )

  // 绩效管理-服务单列表(不分页)
  stewardSalaryListOrder = $api.action((args: StewardSalaryListOrderDTO) =>
    this.post<ApiResponse<StewardOrderQueryVO[]>>('/steward/salary/list-order').body(args)
  )

  // 绩效管理-更新服务单绩效相关信息
  stewardSalaryUpdateOrder = $api.action((args: StewardSalaryUpdateOrderDTO) =>
    this.post('/steward/salary/update-order').body(args)
  )

  // 绩效管理-修改绩效统计规则
  stewardSalaryUpdateStatistic = $api.action((args: StewardSalaryStatisticUpdateDTO) =>
    this.post('/steward/salary/update-statistic').body(args)
  )

  //管家管理-列表
  stewardUserList = $api.action((args: Partial<StewardUserListDTO>) =>
    this.post<ApiResponse<StewardUserVO[]>>('/steward/user/list').body(args)
  )

  // 管家管理-管家详情-绩效考核
  stewardUserLatestSalary = $api.action((args: StewardUserDTO) =>
    this.post<ApiResponse<StewardSalaryVO[]>>('/steward/user/latest-salary').body(args)
  )

  // 管家管理-管家详情  |  object:{userId:管家id}
  stewardUserInfo = $api.action((args: {userId: string}) =>
    this.post<ApiResponse<StewardUserVO>>('/steward/user/info').body(args)
  )

  // 管家管理-管家详情-物品列表
  stewardUserMaterialList = $api.action((args: StewardUserDTO) =>
    this.post<ApiResponse<StewardMaterialListVO[]>>('/steward/user/material-list').body(args)
  )

  // 管家管理-管家详情-服务单列表
  stewardUserOrderList = $api.action((args: StewardUserDTO) =>
    this.post<ApiResponse<StewardOrderQueryVO[]>>('/steward/user/order-list').body(args)
  )

  // 管家管理-管家详情-房源列表
  stewardUserPropertyList = $api.action((args: StewardUserDTO) =>
    this.post<ApiResponse<PropertyInfoVO[]>>('/steward/user/property-list').body(args)
  )

  // 管家管理-编辑管家证件
  stewardUserUpdateLicence = $api.action((args: StewardUserLicenceUpdateDTO) =>
    this.post<ApiResponse<Record<string, any>>>('/steward/user/update-licence').body(args)
  )

  // 管家服务-自定义服务-服务树  |  object:{customerId:客户id}
  stewardAdminServiceCustomTree = $api.action((args: {customerId: string}) =>
    this.post<ApiResponse<StewardServiceVO[]>>('/steward/admin/service/custom-tree').body(args)
  )

  // 管家服务-自定义服务-保存
  stewardAdminServiceCustomSave = $api.action((args: StewardServiceCustomSaveDTO) =>
    this.post<ApiResponse<Record<string, any>>>('/steward/admin/service/custom-save').body(args)
  )

  // 管家服务-服务列表树
  stewardAdminServiceTree = $api.action((args: StewardServiceQueryDTO) =>
    this.post<ApiResponse<StewardServiceVO[]>>('/steward/admin/service/tree').body(args)
  )

  // 管家服务单-耗材使用列表  |  {orderId:服务单id}
  stewardAdminOrderListItem = $api.action((args: StewardOrderItemQueryDTO) =>
    this.post<ApiResponse<StewardOrderItemVO[]>>('/steward/admin/order/list-item').body(args)
  )

  // 绩效管理-设置违规线  |  object:{salaryId:绩效id,percent:百分比整数}
  stewardSalarySetViolationPercent = $api.action((args: {salaryId: string; percent: number}) =>
    this.post<ApiResponse>('/steward/salary/set-violation-percent').body(args)
  )

  // 绩效管理-计算违规线  |  object:{orderNumValid:有效单量,percent:百分比整数}
  stewardSalaryCalculateViolationLine = $api.action((args: {orderNumValid: number; percent: number}) =>
    this.post<ApiResponse<number>>('/steward/salary/calculate-violation-line').body(args)
  )
  // 管家服务单-提交结果
  stewardOrderHandleResult = $api.action((args: StewardOrderHandleResultDTO) =>
    this.post<ApiResponse>('/steward/order/handle-result').body(args).throttle(5000)
  )

  // 周期服务单-预备的服务单列表
  /**
   * @deprecated 2023-04-17号删除
   */
  stewardAdminOrderPeriodTodoList = $api.action((args: Partial<StewardOrderPeriodTodoQueryDTO>) =>
    this.post<ApiResponse<StewardOrderPeriodTodoVO[]>>('/steward/admin/order/period-todo/list').body({
      pageNum: 1,
      pageSize: 10,
      pageable: true,
      ...args,
    })
  )

  // 管家管理-禁用管家  |  object:{userId:管家id}
  stewardUserDisable = $api.action((args: {userId: string}) => this.post('/steward/user/disable').body(args))

  // 查询客户签约对接详情  |  object:{extraId:对接记录id}
  stewardAppSaleCustomerSignExtraInfo = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<SaleCustomerSignExtraVO>>('/steward-app/sale-customer/sign-extra/info').body(args)
  )

  // 查询客户签约对接列表
  stewardAppSaleCustomerSignExtraList = $api.action((args: SaleCustomerSignExtraListDTO) =>
    this.post<ApiResponse<SaleCustomerSignExtraVO[]>>('/steward-app/sale-customer/sign-extra/list').body(args)
  )

  // 客户签约对接更新
  stewardAppSaleCustomerSignExtraUpdate = $api.action((args: SaleCustomerSignExtraUpdateDTO) =>
    this.post<ApiResponse<Record<string, any>>>('/steward-app/sale-customer/sign-extra/update').body(args)
  )

  // 批量生成记录-列表
  stewardOrderBatchList = $api.action((args: StewardOrderBatchQueryDTO) =>
    this.post<ApiResponse<StewardOrderBatchVO[]>>('/steward/order/batch/list').body(args)
  )

  // 批量生成记录-详情 | batchId
  stewardOrderBatchDetail = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<StewardOrderBatchVO>>('/steward/order/batch/detail').body(args)
  )

  // 管家服务-服务列表树
  stewardServiceTree = $api.action((args: Partial<StewardServiceQueryDTO>) =>
    this.post<ApiResponse<StewardServiceVO[]>>('/steward/service/tree').body(args)
  )

  // 批量生成记录-新增
  stewardOrderBatchInsert = $api.action((args: StewardOrderBatchInsertDTO) =>
    this.post<ApiResponse<string>>('/steward/order/batch/insert').body(args)
  )
  // 管家服务单-返工下单  |  object:{orderId:服务单id,serverId:服务人员id}
  stewardAdminReworkOrder = $api.action((args: StewardOrderReworkDTO) =>
    this.post<ApiResponse<string>>('/steward/admin/rework-order').body(args)
  )

  // 管家服务单-验房服务-导出报告
  stewardAdminInspectExcel = $api.action((args: string[]) =>
    this.post<ApiResponse<string>>('/steward/admin/inspect-excel').body(args)
  )

  // 采购项-撤回(待审批和待采购)  |  object:{purchaseItemId:采购项id, cancelReason:撤回原因（purchase.item.cancel.reason）, cancelDesc:撤回备注, cancelImg:附件}
  stewardAppPurchaseItemCancel = $api.action((args: PurchaseItemCancelDTO) =>
    this.post<ApiResponse<Record<string, any>>>('/steward/app/purchase/item/cancel').body(args)
  )

  // 采购-撤回(待审批和待采购)  |  object:{purchaseId:采购id}
  stewardAppPurchaseCancel = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<Record<string, any>>>('/steward/app/purchase/cancel').body(args)
  )

  // 管家管理-设置师傅  |  object:{teacherId:师傅id,stewardId:管家id}
  stewardUserSetTeacher = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<Record<string, any>>>('/steward/user/set-teacher').body(args)
  )

  // 绩效管理-更新带教单标记  |  object:{salaryId:绩效id,orderId:服务单id}
  stewardSalaryChangeTeachTag = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<Record<string, any>>>('/steward/salary/change-teach-tag').body(args)
  )
  // 服务收费-删除  |  object:{chargeId:收费id}
  stewardAdminServiceChargeDelete = $api.action((args: {chargeId: string}) =>
    this.post<ApiResponse<string>>('/steward/admin/service-charge/delete').body(args)
  )

  // 服务收费-详情  |  object:{chargeId:服务收费id,customerId:客户id(可空)}
  stewardAdminServiceChargeDetail = $api.action((args: {chargeId: string; customerId?: string}) =>
    this.post<ApiResponse<StewardServiceChargeVO>>('/steward/admin/service-charge/detail').body(args)
  )

  // 服务收费-新增
  stewardAdminServiceChargeInsert = $api.action((args: StewardServiceChargeSaveDTO) =>
    this.post<ApiResponse<string>>('/steward/admin/service-charge/insert').body(args)
  )

  // 服务收费-列表
  stewardAdminServiceChargeList = $api.action((args: StewardServiceChargeQueryDTO) =>
    this.post<ApiResponse<StewardServiceChargeVO[]>>('/steward/admin/service-charge/list').body({
      pageNum: 1,
      pageSize: 10,
      pageable: true,
      ...args,
    })
  )

  // 服务收费-列表
  stewardServiceChargeList = $api.action((args: StewardServiceChargeItemQueryDTO) =>
    this.post<ApiResponse<StewardServiceChargeItemVO[]>>('/steward/service/charge-list').body(args)
  )

  // 服务收费-更新
  stewardAdminServiceChargeUpdate = $api.action((args: StewardServiceChargeSaveDTO) =>
    this.post<ApiResponse<string>>('/steward/admin/service-charge/update').body(args)
  )

  // 服务收费-改变状态  |  object:{chargeId:收费id,enable:1启用0禁用}
  stewardAdminServiceChargeUpdateEnable = $api.action((args: {chargeId: string; enable: 1 | 0}) =>
    this.post<ApiResponse<string>>('/steward/admin/service-charge/update-enable').body(args)
  )

  // 客户自定义服务收费-列表  |  object:{customerId:客户id,serviceId:服务id(可空)}
  stewardAdminServiceChargeCustomList = $api.action((args: {customerId: string; serviceId?: string}) =>
    this.post<ApiResponse<StewardServiceCustomChargeBindVO[]>>('/steward/admin/service-charge/custom-list').body(args)
  )

  // 客户自定义服务收费-保存  |  object:{customerId:客户id}
  stewardAdminServiceChargeCustomSave = $api.action((args: StewardServiceCustomChargeBindSaveDTO[]) =>
    this.post<ApiResponse<Record<string, any>>>('/steward/admin/service-charge/custom-save').body(args)
  )

  // 设置'垃圾清运提成'  |  object:{commissionVal:提成(元)}
  stewardAdminServiceChargeSetCleanFreightCommission = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<Record<string, any>>>('/steward/admin/service-charge/set-clean-freight-commission').body(args)
  )

  // 查询'垃圾清运提成'
  stewardAdminServiceChargeGetCleanFreightCommission = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<number>>('/steward/admin/service-charge/get-clean-freight-commission').body(args)
  )

  // 排班列表(复用APP接口)  |  object:{stewardId:管家ID,month:yyyy-MM}
  stewardAdminTransferListByMonth = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<StewardTransferVO[]>>('/steward/admin/transfer/list-by-month').body(args)
  )

  // 排班列表(根据星期筛选,会排除跨天的排班!)
  // stewardAdminTransferListByWeek = $api.action((args: StewardTransferWeekQueryDTO) =>
  //   this.post<ApiResponse<StewardTransferWeekVO[]>>('/steward/admin/transfer/list-by-week').body(args)
  // )

  // 排班调整
  stewardAdminTransferChange = $api.action((args: StewardTransferChangeDTO) =>
    this.post<ApiResponse<Record<string, any>>>('/steward/admin/transfer/change').body(args)
  )

  // 排班配置-列表
  stewardAdminTransferConfigList = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<StewardTransferConfigVO[]>>('/steward/admin/transfer/config/list').body(args)
  )

  // 排班配置-列表V2
  stewardAdminTransferConfigListV2 = $api.action((args: StewardTransferConfigQueryDTO) =>
    this.post<ApiResponse<StewardTransferConfigVO[]>>('/steward/admin/transfer/config/list-v2').body(args)
  )

  // 排班配置-保存
  stewardAdminTransferConfigSave = $api.action((args: StewardTransferConfigSaveDTO[]) =>
    this.post<ApiResponse<Record<string, any>>>('/steward/admin/transfer/config/save').body(args)
  )

  // 排班配置-根据配置生成转单  |  object:{stewardIdList:管家ID列表,beginDate:开始日期,endDate:结束日期}
  stewardAdminTransferConfigCreateTransfer = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<Record<string, any>>>('/steward/admin/transfer/config/create-transfer').body(args)
  )

  // 获取管家定位列表
  stewardLocationList = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<StewardLocationVO[]>>('/steward/location/list').body(args)
  )

  // 批量生成记录-详情-删除  |  object:{batchDetailId:详情id}
  stewardOrderBatchDeleteDetail = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<string>>('/steward/order/batch/delete-detail').body(args)
  )

  // 批量生成记录-详情-一键删除  |  object:{batchId:详情id}
  stewardOrderBatchDelete = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<string>>('/steward/order/batch/delete').body(args)
  )

  // 管家服务单-更新服务单的经纬度  |  object:{orderId:服务单id}
  stewardAdminOrderUpdateLocation = $api.action((args: {orderId: string}) =>
    this.post<ApiResponse<Record<string, any>>>('/steward/admin/order/update-location').body(args)
  )
  // 仓库费用列表
  stewardWarehouseFeeList = $api.action((args: StewardWarehouseFeeListDTO) =>
    this.post<ApiResponse<StewardWarehouseFeeVO[]>>('/steward/warehouse/fee/list').body(args)
  )

  // 仓库盘点记录  |  object:{warehouseId:仓库id}
  stewardWarehouseCheckRecord = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<StewardWarehouseCheckRecordVO[]>>('/steward/warehouse/check/record').body(args)
  )

  // 仓库费用新增/更新
  stewardWarehouseFeeUpsert = $api.action((args: StewardWarehouseFeeDTO) =>
    this.post<ApiResponse<Record<string, any>>>('/steward/warehouse/fee/upsert').body(args)
  )

  // 仓库费用删除  |  object:{warehouseFeeId:仓库费用id}
  stewardWarehouseFeeDelete = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<Record<string, any>>>('/steward/warehouse/fee/delete').body(args)
  )
  // 绩效管理-设置基础工资  |  object:{salaryId:绩效id,wageBase:基础工资}
  stewardSalaryUpdateWageBase = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<Record<string, any>>>('/steward/salary/update-wage-base').body(args)
  )

  // 管家每日操作记录时间列表
  stewardStewardDailyLogList = $api.action((args: StewardDailyLogQueryDTO) =>
    this.post<ApiResponse<StewardDailyLogVO[]>>('/steward/steward-daily-log/list').body(args)
  )
  // 服务收费-更新保修信息
  stewardAdminServiceChargeUpdateWarranty = $api.action((args: StewardServiceChargeUpdateWarrantyDTO) =>
    this.post<ApiResponse<string>>('/steward/admin/service-charge/update-warranty').body(args)
  )

  // 保修卡详情  |  object:{orderId:服务单id(保修卡id)}
  stewardAppOrderWarrantyDetail = $api.action((args: {orderId: string}) =>
    this.post<ApiResponse<StewardOrderWarrantyVO>>('/steward/app/order/warranty/detail').body(args)
  )

  // 收费服务单详情
  stewardOrderChargeDetail = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<StewardOrderChargeVO>>('/steward/order/charge/detail').body(args)
  )

  // 保洁扣款-详情  |  object:{recordId:记录id,orderId:服务单id}
  stewardOrderChargeCleanDetail = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<StewardOrderChargeCleanVO>>('/steward/order/charge-clean/detail').body(args)
  )

  // 列表接口
  stewardOrderApplicationList = $api.action((args: StewardOrderApplicationQueryDTO) =>
    this.post<ApiResponse<StewardOrderApplicationVO[]>>('/steward/order-application/list').body(args)
  )

  // 详情接口  |  object:{orderId:服务单id}
  stewardOrderApplicationGet = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<StewardOrderApplicationVO>>('/steward/order-application/get').body(args)
  )
  // 电联核查列表
  stewardOrderConnectionCheckList = $api.action((args: StewardOrderConnectionCheckQueryDTO) =>
    this.post<ApiResponse<StewardOrderConnectionCheckVO[]>>('/steward/order/connection-check/list').body(args)
  )

  // 电联核查详情  |  object:{connectionCheckId:申请id}
  stewardOrderConnectionCheckGet = $api.action((args: {connectionCheckId: string}) =>
    this.post<ApiResponse<StewardOrderConnectionCheckVO>>('/steward/order/connection-check/get').body(args)
  )

  // 处理电联核查
  stewardOrderConnectionCheckHandle = $api.action((args: StewardOrderConnectionCheckHandleDTO) =>
    this.post<ApiResponse<Record<string, any>>>('/steward/order/connection-check/handle').body(args)
  )

  // 周期服务单-支付订单列表
  /**
   * @deprecated 2023-04-17号删除
   */
  stewardAdminOrderPeriodList = $api.action((args: StewardOrderPeriodQueryDTO) =>
    this.post<ApiResponse<StewardOrderPeriodVO[]>>('/steward/admin/order/period/list').body(args)
  )

  // 题库列表查询
  stewardAdminQuestionList = $api.action((args: StewardQuestionQueryDTO) =>
    this.post<ApiResponse<StewardQuestionVO[]>>('/steward/admin/question/list').body(args)
  )

  // 题库题数统计
  stewardAdminQuestionCount = $api.action(() =>
    this.post<ApiResponse<StewardQuestionCountVO>>('/steward/admin/question/count')
  )

  // 批准考试
  stewardAdminQuestionPaperAudit = $api.action((args: StewardQuestionPaperSubmitDTO) =>
    this.post<ApiResponse<Record<string, any>>>('/steward/admin/question/paper/audit').body(args)
  )

  // 查询每日练习试卷
  stewardAdminQuestionPaperConfig = $api.action((args: StewardQuestionPaperConfigDTO) =>
    this.post<ApiResponse<Record<string, any>>>('/steward/admin/question/paper/config').body(args)
  )

  // 查询考试配置  |  object:{paperType:配置考试类型（1：每日练习、2：考试）}
  stewardAdminQuestionPaperConfigInfo = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<StewardQuestionPaperConfigVO>>('/steward/admin/question/paper/config/info').body(args)
  )

  // 查询每日练习试卷详情  |  object:{paperId:试卷id}
  stewardAdminQuestionPaperDailyInfo = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<StewardQuestionPaperDailyVO>>('/steward/admin/question/paper/daily/info').body(args)
  )

  // 查询每日练习试卷
  stewardAdminQuestionPaperDailyList = $api.action((args: StewardQuestionPaperDailyListDTO) =>
    this.post<ApiResponse<StewardQuestionPaperDailyVO[]>>('/steward/admin/question/paper/daily/list').body(args)
  )

  // 查询考试试卷详情  |  object:{paperId:试卷id}
  stewardAdminQuestionPaperInfo = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<StewardQuestionPaperVO>>('/steward/admin/question/paper/info').body(args)
  )

  // 查询考试试卷
  stewardAdminQuestionPaperList = $api.action((args: StewardQuestionPaperListDTO) =>
    this.post<ApiResponse<StewardQuestionPaperVO[]>>('/steward/admin/question/paper/list').body(args)
  )

  // 批卷
  stewardAdminQuestionPaperMark = $api.action((args: StewardQuestionPaperSubmitDTO) =>
    this.post<ApiResponse<Record<string, any>>>('/steward/admin/question/paper/mark').body(args)
  )

  // 绩效管理-获取提成百分比
  stewardSalaryGetCommissionPercent = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<number>>('/steward/salary/get-commission-percent').body(args)
  )

  // 绩效管理-设置提成百分比  |  object:{percent:提成百分比}
  stewardSalarySetCommissionPercent = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<Record<string, any>>>('/steward/salary/set-commission-percent').body(args)
  )
  // 绩效管理-终审撤回  |  object:{salaryId:绩效id,rollbackReason:原因}
  stewardSalaryRollback = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<Record<string, any>>>('/steward/salary/rollback').body(args)
  )

  // 绩效管理-终审撤回记录列表
  stewardSalaryCheckRollbackList = $api.action((args: PageModel) =>
    this.post<ApiResponse<StewardSalaryRollbackVO[]>>('/steward/salary/check-rollback-list').body(args)
  )

  // 根据小区汇聚未完成的服务单
  stewardAdminOrderCommunityMapList = $api.action((args: StewardOrderCommunityMapAdminQueryDTO) =>
    this.post<ApiResponse<StewardOrderCommunityMapVO[]>>('/steward/admin/order/community-map/list').body(args)
  )

  // 管家服务单-取消订单  |  object:{orderId:服务单号,cancelType:取消类型,cancelReason:取消原因}
  stewardAdminOrderCancel = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<Record<string, any>>>('/steward/admin/order/cancel').body(args)
  )

  // 岗位启用/禁用  |  object:{honorId: 优秀管家记录id, enable:启用状态（1：启用、0：禁用）
  stewardHonorEnable = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<Record<string, any>>>('/steward/honor/enable').body(args)
  )

  // 优秀管家-详情  |  object:{honorId:优秀管家记录id}
  // stewardHonorInfo = $api.action((args: Record<string, any>) =>
  //   this.post<ApiResponse<StewardHonorVO>>('/steward/honor/info').body(args)
  // )

  // 优秀管家-新增
  stewardHonorInsert = $api.action((args: StewardHonorDTO) =>
    this.post<ApiResponse<Record<string, any>>>('/steward/honor/insert').body(args)
  )

  // 优秀管家-列表
  stewardHonorList = $api.action((args: StewardHonorListDTO) =>
    this.post<ApiResponse<StewardHonorVO[]>>('/steward/honor/list').body(args)
  )

  // 优秀管家-编辑
  stewardHonorUpdate = $api.action((args: StewardHonorDTO) =>
    this.post<ApiResponse<Record<string, any>>>('/steward/honor/update').body(args)
  )

  // 后台管理首页-管家-下单量(格式:{'2022-08-08':'1'})
  homeStewardStatistic9 = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<Record<string, any>>>('/home/steward/statistic-9').body(args)
  )

  // 后台管理首页-管家-完成量(格式:{'2022-08-08':'1'})
  homeStewardStatistic10 = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<Record<string, any>>>('/home/steward/statistic-10').body(args)
  )

  // 新增保洁区域
  stewardCleanAreaInsert = $api.action((args: StewardCleanAreaDTO) =>
    this.post<ApiResponse<Record<string, any>>>('/steward/clean/area/insert').body(args)
  )

  // 修改保洁区域
  stewardCleanAreaUpdate = $api.action((args: StewardCleanAreaDTO) =>
    this.post<ApiResponse<Record<string, any>>>('/steward/clean/area/update').body(args)
  )

  // 保洁区域列表
  stewardCleanAreaList = $api.action(() => this.post<ApiResponse<StewardCleanAreaVO[]>>('/steward/clean/area/list'))

  // 删除保洁区域  |  object:{areaId:区域id}
  stewardCleanAreaDelete = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<Record<string, any>>>('/steward/clean/area/delete').body(args)
  )

  // 保洁区域列表详情  |  object:{areaId:保洁区域id}
  stewardCleanAreaInfo = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<StewardCleanAreaVO>>('/steward/clean/area/info').body(args)
  )

  // 当前报价单
  stewardCleanAreaPriceSheetInfo = $api.action(() =>
    this.post<ApiResponse<StewardCleanAreaPriceSheetVO>>('/steward/clean/area/price-sheet/info')
  )

  // 保存报价单
  stewardCleanAreaPriceSheetInsert = $api.action((args: string[]) =>
    this.post<ApiResponse<Record<string, any>>>('/steward/clean/area/price-sheet/insert').body(args)
  )

  // 报价单历史记录
  stewardCleanAreaPriceSheetList = $api.action(() =>
    this.post<ApiResponse<StewardCleanAreaPriceSheetVO[]>>('/steward/clean/area/price-sheet/list')
  )

  // 新增物流单号  |  object:{orderId:服务单号,logisticsId:物流单号}
  stewardAdminOrderLogisticsInsert = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<Record<string, any>>>('/steward/admin/order/logistics/insert').body(args)
  )

  // 列表物流单号  |  object:{orderId:服务单号}
  stewardAdminOrderLogisticsList = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<PairModel[]>>('/steward/admin/order/logistics/list').body(args)
  )

  // 删除物流单号  |  object:{recordId:记录ID}
  stewardAdminOrderLogisticsDelete = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<Record<string, any>>>('/steward/admin/order/logistics/delete').body(args)
  )
  // 编辑物流单号  |  object:{recordId:记录ID,logisticsId:物流单号}
  stewardAdminOrderLogisticsUpdate = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<Record<string, any>>>('/steward/admin/order/logistics/update').body(args)
  )
  // 服务单轨迹列表
  stewardAdminOrderTrackList = $api.action((args: StewardOrderTrackJobQueryDTO) =>
    this.post<ApiResponse<StewardOrderTrackJobVO[]>>('/steward/admin/order/track/list').body(args)
  )

  // 远程指导审核列表
  stewardOrderRemoteHelpList = $api.action((args: StewardOrderRemoteHelpAuditQueryDTO) =>
    this.post<ApiResponse<StewardOrderRemoteHelpAuditVO[]>>('/steward/order/remote-help/list').body(args)
  )

  // 远程指导审核处理
  stewardOrderRemoteHelpHandle = $api.action((args: StewardOrderRemoteHelpAuditHandleDTO) =>
    this.post<ApiResponse<Record<string, any>>>('/steward/order/remote-help/handle').body(args)
  )

  // 远程指导审核详情  |  object:{auditId:审核ID}
  stewardOrderRemoteHelpGet = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<StewardOrderRemoteHelpAuditVO>>('/steward/order/remote-help/get').body(args)
  )

  // 休息日新单列表
  // stewardAdminOrderRestList = $api.action((args: StewardOrderRestQueryDTO) =>
  //   this.post<ApiResponse<StewardOrderRestVO[]>>('/steward/admin/order/rest/list').body(args)
  // )

  // 休息日新单列表(毛莉华（13818678534）和任振映（18522356263）的排前面)
  stewardAdminOrderRestList2 = $api.action((args: StewardOrderRestQueryDTO) =>
    this.post<ApiResponse<StewardOrderRestVO[]>>('/steward/admin/order/rest/list-2').body(args)
  )

  // 根据预约时间筛选出'不休息'的管家  |  object:{timeAppoint:预约时间}
  stewardAdminOrderRestListStewardWorking = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<PairModel[]>>('/steward/admin/order/rest/list-steward-working').body(args)
  )

  // 休息日新单处理
  stewardAdminOrderRestHandle = $api.action((args: StewardOrderRestHandleDTO) =>
    this.post<ApiResponse<Record<string, any>>>('/steward/admin/order/rest/handle').body(args)
  )

  // 查询管家(已沟通,上门中,服务中)的服务单  |  object:{stewardId:管家ID,orderId:服务单ID}
  stewardAdminOrderRestListOrderProcessing = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<StewardOrderQueryVO[]>>('/steward/admin/order/rest/list-order-processing').body(args)
  )

  // 绩效类型列表
  stewardSalaryTypeList = $api.action((args: StewardSalaryTypeQueryDTO) =>
    this.post<ApiResponse<StewardSalaryTypeVO[]>>('/steward/salary/type/list').body(args)
  )

  // 绩效类型新增或编辑
  stewardSalaryTypeUpsert = $api.action((args: StewardSalaryTypeUpsertDTO) =>
    this.post<ApiResponse<string>>('/steward/salary/type/upsert').body(args)
  )

  // 绩效类型 启用停用  |  object:{typeId:类型ID,enable:1或0}
  stewardSalaryTypeEnable = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<string>>('/steward/salary/type/enable').body(args)
  )

  // 同步管家的绩效的数据(每天定时刷新)
  stewardSalarySync = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<Record<string, any>>>('/steward/salary/sync').body(args)
  )

  // 为'管家服务单'增加/删除绩效
  stewardSalaryEditOrderSalary = $api.action((args: StewardSalaryOrderUpsertDTO) =>
    this.post<ApiResponse<string>>('/steward/salary/edit-order-salary').body(args)
  )

  // 查询'管家服务单'的绩效  |  object:{orderId:服务单ID}
  stewardSalaryListOrderSalary = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<StewardSalaryOrderVO[]>>('/steward/salary/list-order-salary').body(args)
  )

  // 取消申请列表
  stewardOrderCancelAuditList = $api.action((args: StewardOrderCancelAuditQueryDTO) =>
    this.post<ApiResponse<StewardOrderCancelAuditVO[]>>('/steward/order/cancel-audit/list').body(args)
  )

  // 处理取消申请
  stewardOrderCancelAuditHandle = $api.action((args: StewardOrderCancelAuditHandleDTO) =>
    this.post<ApiResponse<Record<string, any>>>('/steward/order/cancel-audit/handle').body(args)
  )

  // 管家服务单-填写处理结果
  stewardAdminOrderHandleResult = $api.action((args: StewardOrderHandleResultFromAdminDTO) =>
    this.post<ApiResponse<Record<string, any>>>('/steward/admin/order/handle-result').body(args)
  )

  // 物品列表查询(通用)  |  1:系统物品列表 2:个人库存 3:组织库存 4:仓库库存
  stewardWarehouseAppListMaterialCommon = $api.action((args: StewardWarehouseInventoryDTO) =>
    this.post<ApiResponse<StewardMaterialListVO[]>>('/steward/warehouse/app/list-material-common').body(args)
  )

  // 查询管家列表(含未完成的服务单数量)  |  object:{fetchAll:是否是全部管家,stewardId:管家ID}
  stewardAdminOrderRestListSteward = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<StewardOrderServerVO[]>>('/steward/admin/order/rest/list-steward').body(args)
  )

  // 批量生成记录-生成服务单  |  object:{batchDetailId:详情id}
  stewardOrderBatchCreateOrder = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<string>>('/steward/order/batch/create-order').body(args)
  )

  // 服务单-家电信息审核
  stewardOrderAppliancesApprovalAudit = $api.action((args: StewardOrderAppliancesApprovalAuditDTO) =>
    this.post<ApiResponse<Record<string, any>>>('/steward/order/appliances/approval/audit').body(args)
  )

  // 服务单-家电信息审核详情  |  object{approvalId:家电审批单id}
  stewardOrderAppliancesApprovalDetail = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<StewardOrderAppliancesApprovalListVO>>('/steward/order/appliances/approval/detail').body(args)
  )

  // 服务单-家电信息审核列表
  stewardOrderAppliancesApprovalList = $api.action((args: StewardOrderAppliancesApprovalListDTO) =>
    this.post<ApiResponse<StewardOrderAppliancesApprovalListVO[]>>('/steward/order/appliances/approval/list').body(args)
  )

  // 服务单-家电信息审核-家电信息  |  object:{approvalId:审批单id}
  stewardOrderAppliancesRoomDetail = $api.action((args: {approvalId: string}) =>
    this.post<ApiResponse<StewardOrderAppliancesRoomListVO[]>>('/steward/order/appliances/room/detail')
      .body(args)
      .metas(args.approvalId)
  )

  // 销售绩效方案-列表
  stewardSalaryPlanList = $api.action((args: StewardSalaryPlanListDTO) =>
    this.post<ApiResponse<StewardSalaryPlanVO[]>>('/steward-salary-plan/list').body(args)
  )

  // 销售绩效方案-禁用启用  |  object:{planId:方案id,status:状态}
  stewardSalaryPlanEnable = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<Record<string, any>>>('/steward-salary-plan/enable').body(args)
  )

  // 销售绩效方案-禁用-测试(true表示绑定)  |  object:{planId:方案id}
  stewardSalaryPlanUnableTest = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<boolean>>('/steward-salary-plan/unable-test').body(args)
  )

  // 销售绩效方案-新增
  stewardSalaryPlanUpsert = $api.action((args: StewardSalaryPlanInsertDTO) =>
    this.post<ApiResponse<Record<string, any>>>('/steward-salary-plan/upsert').body(args)
  )

  // 销售绩效方案-详情  |  object:{planId:方案id}
  stewardSalaryPlanDetail = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<StewardSalaryPlanVO>>('/steward-salary-plan/detail').body(args)
  )

  // 贝壳管家绩效方案-新增修改
  stewardSalaryPlanUpsertBk = $api.action((args: StewardSalaryPlanBkInsertDTO) =>
    this.post<ApiResponse<Record<string, any>>>('/steward-salary-plan/upsert/bk').body(args)
  )

  // 贝壳绩效管理-列表
  stewardSalaryBkList = $api.action((args: StewardSalaryBkQueryDTO) =>
    this.post<ApiResponse<StewardSalaryBkVO[]>>('/steward/salary-bk/list').body(args)
  )

  // 贝壳绩效管理-详情
  stewardSalaryBkDetail = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<StewardSalaryBkVO>>('/steward/salary-bk/detail').body(args)
  )

  // 贝壳绩效管理-批量操作
  stewardSalaryBkBatchUpdate = $api.action((args: StewardSalaryBatchUpdateDTO) =>
    this.post<ApiResponse<Record<string, any>>>('/steward/salary-bk/batch/update').body(args)
  )

  // 贝壳绩效管理-额外奖惩-使用
  stewardSalaryBkTypeUse = $api.action((args: UseStewardSalaryTypeDTO) =>
    this.post<ApiResponse<Record<string, any>>>('/steward/salary-bk/type/use').body(args)
  )

  // 贝壳绩效管理-批量刷新  |  object:{idList:[贝壳id],month:yyyy-MM} 必传一个
  stewardSalaryBkBatchRefresh = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<Record<string, any>>>('/steward/salary-bk/batch/refresh').body(args)
  )

  // 绩效管理-详情  |  object:{salaryId:绩效记录id}
  stewardSalaryDetailV2 = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<StewardSalaryVO>>('/steward/salary/detailV2').body(args)
  )

  // 绩效管理-终审确认-查询服务单是否在申诉单列表  |  object:{salaryId:绩效id}
  stewardSalaryBeforeCheck = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<string[]>>('/steward/salary/before-check').body(args)
  )

  // 龙湖销售绩效方案-新增修改
  stewardSalaryPlanUpsertLh = $api.action((args: StewardSalaryPlanLhInsertDTO) =>
    this.post<ApiResponse<Record<string, any>>>('/steward-salary-plan/upsert/lh').body(args)
  )

  // 绩效管理-列表
  stewardSalaryLhList = $api.action((args: StewardSalaryLhQueryDTO) =>
    this.post<ApiResponse<StewardSalaryLhVO[]>>('/steward/salary/lh/list').body(args)
  )

  // 绩效管理-批量操作
  stewardSalaryLhUpdateBatch = $api.action((args: StewardSalaryBatchUpdateDTO) =>
    this.post<ApiResponse<Record<string, any>>>('/steward/salary/lh/update-batch').body(args)
  )

  // 绩效管理-创建管家的绩效记录
  stewardSalaryLhCreate = $api.action((args: StewardSalaryLhCreateDTO) =>
    this.post<ApiResponse<Record<string, any>>>('/steward/salary/lh/create').body(args)
  )

  // 绩效管理-创建管家的绩效记录-全部
  stewardSalaryLhCreateAll = $api.action(() =>
    this.post<ApiResponse<Record<string, any>>>('/steward/salary/lh/create-all')
  )

  // 贝壳绩效管理-额外奖惩-使用
  stewardSalaryLhTypeUse = $api.action((args: UseStewardSalaryTypeDTO) =>
    this.post<ApiResponse<Record<string, any>>>('/steward/salary/lh/type/use').body(args)
  )

  // 绩效管理-详情
  stewardSalaryLhDetail = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<StewardSalaryLhVO>>('/steward/salary/lh/detail').body(args)
  )

  // 绩效管理-详情-服务单列表
  stewardSalaryLhListOrder = $api.action((args: StewardSalaryLhOrderListDTO) =>
    this.post<ApiResponse<StewardSalaryLhOrderVO[]>>('/steward/salary/lh/list-order').body(args)
  )

  // 第三方接入详情  |  object:{appId:应用id}
  stewardOpenAppDetail = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<StewardOpenAppVO>>('/steward-open-app/detail').body(args)
  )

  // 启用禁用  |  object:{appId:应用id,appStatus:应用状态}
  stewardOpenAppEnable = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<Record<string, any>>>('/steward-open-app/enable').body(args)
  )

  // 管家新增接入方
  stewardOpenAppInsert = $api.action((args: StewardOpenAppUpsertDTO) =>
    this.post<ApiResponse<string>>('/steward-open-app/insert').body(args)
  )

  // 管家-应用-列表
  stewardOpenAppList = $api.action((args: StewardAppOpenListDTO) =>
    this.post<ApiResponse<StewardOpenAppVO[]>>('/steward-open-app/list').body(args)
  )

  // 管家修改接入方
  stewardOpenAppUpdate = $api.action((args: StewardOpenAppUpsertDTO) =>
    this.post<ApiResponse<string>>('/steward-open-app/update').body(args)
  )

  // 第三方接入服务单详情  |  object:{orderId:服务单ID}
  stewardOpenOrderDetail = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<StewardOpenOrderVO>>('/steward-open-order/detail').body(args)
  )

  // 第三方接入服务单列表
  stewardOpenOrderList = $api.action((args: StewardOpenOrderQueryDTO) =>
    this.post<ApiResponse<StewardOpenOrderVO[]>>('/steward-open-order/list').body(args)
  )

  // 根据预约时间筛选出'不休息'的管家  |  object:{timeAppoint:预约时间}
  stewardOpenOrderListStewardWorking = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<PairModel[]>>('/steward-open-order/list-steward-working').body(args)
  )

  // 接单
  stewardOpenOrderTakeOrder = $api.action((args: StewardOpenOrderTakeDTO) =>
    this.post<ApiResponse<Record<string, any>>>('/steward-open-order/take-order').body(args)
  )

  // 可选站点列表(根据服务单所属城市)  |  object:{orderId:服务单ID}
  stewardOpenOrderListWarehouse = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<StewardWarehouseListItemVO[]>>('/steward-open-order/list-warehouse').body(args)
  )

  // 根据站点查询管家列表  |  object:{warehouseId:站点ID}
  stewardOpenOrderListStewardByWarehouseId = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<SysUserVO[]>>('/steward-open-order/list-steward-by-warehouse-id').body(args)
  )

  // 查询管家(已沟通,上门中,服务中)的服务单  |  object:{stewardId:管家ID,orderId:服务单ID}
  stewardOpenOrderListOrderProcessing = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<StewardOrderQueryVO[]>>('/steward-open-order/list-order-processing').body(args)
  )

  // 第三方渠道维修单同步(默认是贝壳)  |  object:{appOrderId:第三方的服务单,appId:渠道ID}
  stewardOpenOrderSync = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<Record<string, any>>>('/steward-open-order/sync').body(args)
  )

  // 获取分享验房报告Content  |  object:{orderId:服务单ID}
  stewardAdminShortLinkInspect = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<string>>('/steward/admin/short-link/inspect').body(args)
  )

  // 服务数据看板-保洁-服务改约列表
  serviceDataCleanChangeList = $api.action((args: ServiceDataBoardStewardListDTO) =>
    this.post<ApiResponse<ServiceDataBoardListVO[]>>('/service/data/clean/change-list').body(args)
  )

  // 服务数据看板-保洁-服务列表
  serviceDataCleanList = $api.action((args: ServiceDataBoardStewardListDTO) =>
    this.post<ApiResponse<ServiceDataBoardListVO[]>>('/service/data/clean/list').body(args)
  )

  // 服务数据看板-服务数据统计
  serviceDataStatistics = $api.action(() =>
    this.post<ApiResponse<ServiceDataBoardStatisticsVO>>('/service/data/statistics')
  )

  // 服务数据看板-保洁-服务改约列表
  serviceDataStewardCancelList = $api.action((args: ServiceDataBoardStewardCancelListDTO) =>
    this.post<ApiResponse<ServiceDataBoardStewardCancelListVO[]>>('/service/data/steward/cancel-list').body(args)
  )

  // 服务数据看板-管家-服务改约列表
  serviceDataStewardChangeList = $api.action((args: ServiceDataBoardStewardListDTO) =>
    this.post<ApiResponse<ServiceDataBoardListVO[]>>('/service/data/steward/change-list').body(args)
  )

  // 服务数据看板-管家-服务列表
  serviceDataStewardList = $api.action((args: ServiceDataBoardStewardListDTO) =>
    this.post<ApiResponse<ServiceDataBoardListVO[]>>('/service/data/steward/list').body(args)
  )

  // 管家服务单-评价
  stewardAdminOrderRemakeInsert = $api.action((args: StewardOrderRemarkInsertDTO) =>
    this.post<ApiResponse<string>>('/steward/admin/order/remake/insert').body(args)
  )

  // 待办导出字段
  stewardOrderTodoKeyValue = $api.action((args: SysTodoDTO) =>
    this.post<ApiResponse<Record<string, any>>>('/steward/order/todo/key-value').body(args)
  )

  // 待办列表
  stewardOrderTodoList = $api.action((args: SysTodoDTO) =>
    this.post<ApiResponse<SysTodoVO[]>>('/steward/order/todo/list').body(args)
  )

  // 修改待办
  stewardOrderTodoUpdate = $api.action((args: SysTodoUpdateDTO) =>
    this.post<ApiResponse>('/steward/order/todo/update').body(args)
  )

  // 待办详情
  stewardOrderTodoDetails = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<SysTodoVO>>('/steward/order/todo/details').body(args).metas(args.todoId)
  )

  // 分配待办  |  object:[{id集合,分配客服}]
  stewardOrderTodoAssign = $api.action((args: SysTodoAssignDTO) =>
    this.post<ApiResponse>('/steward/order/todo/assign').body(args)
  )

  // 待办拨打  |  object:{first:id集合,second:次数}
  stewardOrderTodoCall = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<Record<string, any>>>('/steward/order/todo/call').body(args).metas(args.first)
  )

  // 删除待办(id集合删除)
  stewardOrderTodoDelete = $api.action((args: string[]) =>
    this.post<ApiResponse>('/steward/order/todo/delete').body(args)
  )

  // 处理待办
  stewardOrderTodoHandle = $api.action((args: SysTodoUpdateDTO) =>
    this.post<ApiResponse<Record<string, any>>>('/steward/order/todo/handle').body(args)
  )

  // 获取待办客服接口  |  object:[待办类型id]
  stewardOrderTodoCustomerService = $api.action((args: string[]) =>
    this.post<ApiResponse<SysUserVO[]>>('/steward/order/todo/customer-service').body(args)
  )

  // action
  stewardOrderTodoAction = $api.action((args: number[]) =>
    this.post<ApiResponse>('/steward/order/todo/action').body(args)
  )

  // 待办作废
  stewardOrderTodoCancel = $api.action((args: SysTodoCancelDTO) =>
    this.post<ApiResponse<Record<string, any>>>('/steward/order/todo/cancel').body(args)
  )

  // 站点合同列表
  stewardWarehouseContractList = $api.action((args: StewardWarehouseContractListDTO) =>
    this.post<ApiResponse<StewardWarehouseContractDetailVO[]>>('/steward-warehouse-contract/list').body(args)
  )

  // 新增站点合同-预览
  stewardWarehouseContractInsertPreview = $api.action((args: StewardWarehouseContractInsertDTO) =>
    this.post<ApiResponse<StewardWarehouseContractAllBillVO>>('/steward-warehouse-contract/insert/preview').body(args)
  )

  // 站点合同详情  |  object:{contractId:站点合同id}
  stewardWarehouseContractDetail = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<StewardWarehouseContractDetailVO>>('/steward-warehouse-contract/detail').body(args)
  )

  // 作废站点合同  |  object:{contractId:站点合同id,cancelRemark:作废原因}
  stewardWarehouseContractCancel = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<Record<string, any>>>('/steward-warehouse-contract/cancel').body(args)
  )

  // 账单修改
  stewardWarehouseContractBillUpdate = $api.action((args: StewardWarehouseBillUpdateDTO) =>
    this.post<ApiResponse<Record<string, any>>>('/steward-warehouse-contract/bill/update').body(args)
  )

  // 账单列表
  stewardWarehouseContractBillList = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<StewardWarehouseBillDetailVO[]>>('/steward-warehouse-contract/bill/list').body(args)
  )

  // 新增账单
  stewardWarehouseContractBillInsert = $api.action((args: StewardWarehouseBillInsertDTO) =>
    this.post<ApiResponse<Record<string, any>>>('/steward-warehouse-contract/bill/insert').body(args)
  )

  // 账单详情  |  object:{billId:账单id}
  stewardWarehouseContractBillDetail = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<StewardWarehouseBillDetailVO>>('/steward-warehouse-contract/bill/detail').body(args)
  )

  // 账单收款
  stewardWarehouseContractBillCollection = $api.action((args: StewardWarehouseBillCollectionDTO) =>
    this.post<ApiResponse<Record<string, any>>>('/steward-warehouse-contract/bill/collection').body(args)
  )

  // 作废账单  |  object:{billId:账单id,cancelRemark:作废原因}
  stewardWarehouseContractBillCancel = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<Record<string, any>>>('/steward-warehouse-contract/bill/cancel').body(args)
  )

  // 新增站点合同and账单
  stewardWarehouseContractBillBatchInsert = $api.action((args: StewardWarehouseContractInsertDTO) =>
    this.post<ApiResponse<string>>('/steward-warehouse-contract/bill/batch/insert').body(args)
  )
  // 管家服务收费模板-列表
  stewardServiceTemplateList = $api.action((args: StewardServiceTemplateListDTO) =>
    this.post<ApiResponse<StewardServiceTemplateVO[]>>('/steward-service-template/list').body(args)
  )

  // 管家服务收费模板-保存
  stewardServiceTemplateSave = $api.action((args: StewardServiceTemplateInsertDTO) =>
    this.post<ApiResponse<Record<string, any>>>('/steward-service-template/save').body(args)
  )

  // 管家服务收费模板-使用  |  {'templateId':'模板id(为空就只删除)','customerId':'客户id'}
  stewardServiceTemplateUse = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<Record<string, any>>>('/steward-service-template/use').body(args)
  )

  // 管家服务收费模板-列表
  stewardServiceTemplateGetTree = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<StewardServiceTemplateVO>>('/steward-service-template/get-tree').body(args)
  )

  // 新增审批单
  approvalOrderInsert = $api.action((args: ApprovalOrderInsertDTO) =>
    this.post<ApiResponse<string>>('/approval/order/insert').body(args)
  )

  // 管家服务-服务列表-添加  |  {lessorId:房东手机号,serviceIdList:[''](为空就是修改状态),status:状态(数字0|1)}
  stewardAdminLessee_templateSave = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<Record<string, any>>>('/steward/admin/lessee_template/save').body(args)
  )

  // 管家服务-服务列表-详情  |  {lessorId:房东手机号}
  stewardAdminLessee_templateGet = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<StewardServiceLesseeTemplateGetVO>>('/steward/admin/lessee_template/get').body(args)
  )

  // 贝壳单同步  |  object:{serviceOrderCode:贝壳服务单code}
  stewardOpenOrderBeikeOrderSync = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<Record<string, any>>>('/steward-open-order/beike-order/sync').body(args)
  )

  // 申诉记录列表
  stewardSalaryValidAppealList = $api.action((args: StewardAppOrderValidAppealListDTO) =>
    this.post<ApiResponse<StewardAppOrderValidAppealListVO[]>>('/steward/salary/valid-appeal/list').body(args)
  )

  // 绩效管理-更新服务单绩效-申诉记录  |  object:{orderId:绩效id, type:1无效单、2违规单}
  stewardSalaryAppealRecord = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<StewardOrderVaildAppealRecordVO[]>>('/steward/salary/appeal-record').body(args)
  )

  // 获取报事客户电话（虚拟号）  |  orderId:服务单号
  guanyuGetRepairUserVirNo = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<string>>('/guanyu/getRepairUserVirNo').body(args)
  )

  // 获取报事客户电话（虚拟号）,根据输入的手机号决定  |  object:{orderId:服务单号,mobileInput:手机号}
  guanyuGetRepairUserVirNoItnput = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<string>>('/guanyu/getRepairUserVirNoItnput').body(args)
  )

  // 服务收费-批量设置使用耗材  |  object:{mustMaterial:必须使用耗材,chargeIdList: [chargeId]}
  stewardAdminServiceChargeUpdateMustMaterial = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<string>>('/steward/admin/service-charge/update-must-material').body(args)
  )

  // 管家服务单-接单
  stewardOrderTake = $api.action((args: StewardOrderAssignServerDTO) =>
    this.post<ApiResponse<Record<string, any>>>('/steward/order/take').body(args)
  )
  // 获取保洁校验列表根据订单id
  stewardCountCleanCheckByOrderId = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<number>>('/steward/count-clean-check-by-order-id').body(args)
  )

  // 管家管理-编辑管家
  stewardUserUpdate = $api.action((args: StewardUserUpdateDTO) =>
    this.post<ApiResponse<Record<string, any>>>('/steward/user/update').body(args)
  )

  // 重新发起审批  |  object:{billId:账单id}
  stewardWarehouseContractBillApprovalRelaunch = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<Record<string, any>>>('/steward-warehouse-contract/bill/approval/relaunch').body(args)
  )

  // 管家服务收费模板-审核  |  {'templateId':'模板id(为空就只删除)','customerId':'客户id'}
  stewardServiceTemplateAudit = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<Record<string, any>>>('/steward-service-template/audit').body(args)
  )

  // 管家服务-自定义服务-审核  |  {'serviceDataStr':'','serviceChargeDataStr':''}
  stewardServiceAudit = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<Record<string, any>>>('/steward-service/audit').body(args)
  )

  // 贝壳绩效管理-详情  |  object:{month:yyyy-MM,cityCode:城市code,userId:管家id}
  stewardSalaryBkDetailMonthAndCityAndUserId = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<StewardSalaryBkVO>>('/steward/salary-bk/detail/monthAndCityAndUserId').body(args)
  )

  // 员工评定-列表
  stewardSalaryEvaluationList = $api.action((args: StewardSalaryEvaluationQueryDTO) =>
    this.post<ApiResponse<StewardSalaryEvaluationVO[]>>('/steward-salary-evaluation/list').body(args)
  )

  // 员工评定-确认
  stewardSalaryEvaluationConfirm = $api.action((args: string[]) =>
    this.post<ApiResponse<Record<string, any>>>('/steward-salary-evaluation/confirm').body(args)
  )

  // 员工评定-新增修改
  stewardSalaryEvaluationUpsert = $api.action((args: StewardSalaryEvaluationUpsertDTO) =>
    this.post<ApiResponse<Record<string, any>>>('/steward-salary-evaluation/upsert').body(args)
  )

  // 用户绑定绩效列表
  stewardSalaryPlanUserList = $api.action((args: StewardSalaryPlanUserListDTO) =>
    this.post<ApiResponse<StewardSalaryPlanUserListVO[]>>('/steward/salary-plan-user/list').body(args)
  )
  // 用户绑定绩效
  stewardSalaryPlanUserBatchInsert = $api.action((args: StewardSalaryPlanUserBatchInsertDTO) =>
    this.post<ApiResponse<Record<string, any>>>('/steward/salary-plan-user/batch/insert').body(args)
  )
  // 用户追加绑定绩效
  stewardSalaryPlanUserBatchAddInsert = $api.action((args: StewardSalaryPlanUserBatchInsertDTO) =>
    this.post<ApiResponse<Record<string, any>>>('/steward/salary-plan-user/batch/add/insert').body(args)
  )
  // 渠道报价记录
  approvalOrderQuotationList = $api.action((args: ApprovalOrderQuotationRecordListDTO) =>
    this.post<ApiResponse<ApprovalOrderQuotationRecordVO[]>>('/approval/order/quotation/list').body(args)
  )

  // 审批单详情  |  object:{approvalOrderId:审批单id}
  approvalOrderDetail = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<ApprovalOrderDetailVO>>('/approval/order/detail').body(args)
  )

  // 员工评定-详情  |  object:{id:id}
  stewardSalaryEvaluationDetail = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<StewardSalaryEvaluationVO>>('/steward-salary-evaluation/detail').body(args)
  )

  // 管家服务-服务报价列表
  stewardServiceOfferLadderTwoList = $api.action((args: StewardServiceOfferQueryDTO) =>
    this.post<ApiResponse<StewardServiceOfferVO[]>>('/steward/service/offer/ladder-two/list').body(args)
  )

  // 根据外部订单号ID查询催单记录  |  object: {appOrderId:订单号}
  guanyuListUrgeByAppOrderId = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<GuanyuUrgeVO[]>>('/guanyu/listUrgeByAppOrderId').body(args)
  )

  // 收费服务单列表
  stewardOrderChargeList = $api.action((args: StewardOrderChargeQueryDTO) =>
    this.post<ApiResponse<StewardOrderChargeVO[]>>('/steward/order/charge/list').body(args)
  )

  // 收费服务单详情
  stewardAppOrderChargeDetail = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<StewardOrderChargeVO>>('/steward/app/order/charge/detail').body(args)
  )

  // 退款列表查询
  stewardAdminRefundList = $api.action((args: StewardRefundQueryDTO) =>
    this.post<ApiResponse<StewardRefundVO[]>>('/steward/admin/refund/list').body(args)
  )
  // 退款详情
  stewardAdminRefundDetail = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<StewardRefundVO>>('/steward/admin/refund/detail').body(args)
  )
  // 退款作废  |  {refundId: 退款id, cancelRemark: 作废原因}
  stewardAdminRefundCancel = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<Record<string, any>>>('/steward/admin/refund/cancel').body(args)
  )
  // 退款新增审批
  stewardAdminRefundApproval = $api.action((args: StewardRefundDTO) =>
    this.post<ApiResponse<Record<string, any>>>('/steward/admin/refund/approval').body(args)
  )
  // 退款确认
  stewardAdminRefundConfirm = $api.action((args: StewardRefundDTO) =>
    this.post<ApiResponse<Record<string, any>>>('/steward/admin/refund/confirm').body(args)
  )
  // 退款列表统计
  stewardAdminRefundListRate = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<StewardRefundRateVO>>('/steward/admin/refund/list/rate').body(args)
  )

  // 查询备用金申请列表
  stewardImprestList = $api.action((args: StewardImprestListDTO) =>
    this.post<ApiResponse<StewardImprestListVO[]>>('/steward-imprest/list').body(args)
  )

  // 站点列表  |  object:{cityCode:城市编码,districtCode:区域编码}
  dashboardListStation = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<DashBoardStationVO[]>>('/dashboard/list-station').body(args)
  )

  // 备用金-统计列表
  stewardImprestStatistic = $api.action((args: StewardImprestListDTO) =>
    this.post<ApiResponse<StewardImprestStatisticVO>>('/steward-imprest/statistic').body(args)
  )

  // 备用金申请详情  |  object:{applyId:申请Id}
  stewardImprestApplyDetail = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<StewardImprestApplyDetailVO>>('/steward-imprest/apply/detail').body(args)
  )

  // 备用金核销详情  |  object:{verificationId:核销Id}
  stewardImprestVerificationDetail = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<StewardImprestVerificationDetailVO>>('/steward-imprest/verification/detail').body(args)
  )

  // 备用金核销审批
  stewardImprestVerificationApproval = $api.action((args: StewardImprestVerificationApprovalDTO) =>
    this.post<ApiResponse<string>>('/steward-imprest/verification/approval').body(args)
  )

  // 退款新增
  stewardAdminRefundInsert = $api.action((args: StewardRefundDTO) =>
    this.post<ApiResponse<Record<string, any>>>('/steward/admin/refund/insert').body(args)
  )

  // 管家通讯录-列表
  stewardAddressBookList = $api.action((args: StewardAddressBookListDTO) =>
    this.post<ApiResponse<StewardAddressBookVO[]>>('/steward/address-book/list').body(args)
  )

  // 管家通讯录-新增修改
  stewardAddressBookUpsert = $api.action((args: StewardAddressBookUpsertDTO) =>
    this.post<ApiResponse<Record<string, any>>>('/steward/address-book/upsert').body(args)
  )

  // 管家通讯录-启弃用  |  id:'',enable:'' 0-启用 1-弃用
  stewardAddressBookEnable = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<Record<string, any>>>('/steward/address-book/enable').body(args)
  )

  // 绩效规则-列表
  stewardSalaryRuleList = $api.action((args: StewardSalaryRuleListDTO) =>
    this.post<ApiResponse<StewardSalaryRuleListVO[]>>('/steward-salary-rule/list').body(args)
  )

  // 绩效规则-新增
  stewardSalaryRuleInsert = $api.action((args: StewardSalaryRuleUpsertDTO) =>
    this.post<ApiResponse<string>>('/steward-salary-rule/insert').body(args)
  )

  // 绩效规则-详情  |  object{ruleId:规则id}
  stewardSalaryRuleDetail = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<StewardSalaryRuleDetailVO>>('/steward-salary-rule/detail').body(args)
  )

  // 绩效规则-删除  |  object{ruleId:规则id}
  stewardSalaryRuleDelete = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<Record<string, any>>>('/steward-salary-rule/delete').body(args)
  )

  // 绩效规则-修改
  stewardSalaryRuleUpdate = $api.action((args: StewardSalaryRuleUpsertDTO) =>
    this.post<ApiResponse<Record<string, any>>>('/steward-salary-rule/update').body(args)
  )

  // 已核销记录再确认  |  object:{idList:id列表,checkNotes:备注}
  stewardImprestVerificationCheck = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<boolean>>('/steward-imprest/verification/check').body(args)
  )

  // 管家服务单-导出列表Excel-字段key-value
  stewardAdminOrderExportKeyValue = $api.action(() =>
    this.post<ApiResponse<Record<string, any>>>('/steward/admin/order/export/key-value')
  )

  // 签到记录列表
  stewardCheckInList = $api.action((args: StewardCheckInListDTO) =>
    this.post<ApiResponse<StewardCheckInVO[]>>('/steward/check-in/list').body(args)
  )

  // 统计
  stewardCheckInStatistic = $api.action(
    (args: StewardCheckInListDTO) =>
      this.post<ApiResponse<Record<string, any>>>('/steward/check-in/statistic').body(args)
    // {checked: number; delay: number; total: number}
  )

  adminStewardTransferVacationList = $api.action((args: StewardTransferVacationQueryDTO) =>
    this.post<ApiResponse<StewardTransferVacationVO[]>>('/admin/steward-transfer-vacation/list').body(args)
  )

  // 混合的排班列表(1-31天,签到)
  stewardAdminTransferListMixed = $api.action((args: StewardTransferMixedQueryDTO) =>
    this.post<ApiResponse<StewardTransferMixedVO[]>>('/steward/admin/transfer/list-mixed').body(args)
  )
}

export const stewardModel = new Steward()
