import React, {useEffect} from 'react'
import {useState} from 'react'
import {Select, SelectProps} from 'antd'
import {sysRoleModel} from '@/store/models/SysRole'

/**
 * Form.Item 子项
 * 所在用户组，可搜索
 */
export default function SysRoleSelect(props: SelectProps) {
  const [dataSource, setDataSource] = useState<SysRoleVO[]>([])

  useEffect(() => {
    sysRoleModel.sysRoleList({pageNum: 1, pageSize: 10, pageable: false} as SysListDTO).then(({response: {data}}) => {
      setDataSource(data)
    })
  }, [])

  return (
    <Select
      showSearch
      filterOption={(inputValue, option: any) => option.children?.toLowerCase()?.includes(inputValue?.toLowerCase())}
      {...props}
    >
      {dataSource.map(item => (
        <Select.Option key={item.roleId} value={item.roleId}>
          {item.roleName}
        </Select.Option>
      ))}
    </Select>
  )
}
