import React, {useEffect, useState} from 'react'
import {Page} from '@/components/Page'
import styles from '@/assets/styles/common.module.less'
import {Button, Descriptions, Space, Typography} from 'antd'
import {useAppHistory, useAppLocation} from '@/common/hooks/useRouter'
import {feedbackModel} from '@/store/models/FeedBack'
import {commonModel, useCodeSync} from '@/store/models/Common'
import DividerLine from '@/pages/property/component/DividerLine'

const title = {
  0: '投诉工单信息',
  1: '赔付工单信息',
  2: '处罚工单信息',
}
export default function ComplaintDetail() {
  const history = useAppHistory()
  const location = useAppLocation<'/complaint/detail'>()
  const orderId = location.state?.orderId
  const orderType = location.state?.orderType
  const [detail, setDetail] = useState({} as CsWorkOrderVO)
  const complaintType = useCodeSync('cs.work.order.complaint.type')
  const feedBackChannel = useCodeSync('cs.work.order.feedback.channel')
  const compensation = useCodeSync('cs.work.order.compensation.status')
  const [compensationStatusObj, setCompensationStatusObj] = useState({})
  const punishmentReason = useCodeSync('cs.work.order.punishment.reason')

  useEffect(() => {
    feedbackModel.csWorkOrderGet({id: orderId}).then(({response: {data}}) => {
      setDetail(data)
      if (data.compensationStatus) {
        const status = data.compensationStatus.split('-')[0]
        setCompensationStatusObj(commonModel.getCodeSync(status))
      }
    })
  }, [orderId])

  return (
    <Page>
      <div className={styles.filterSection}>
        <DividerLine title={title[orderType]} bottom={20} />
        {orderType === 0 && (
          <>
            <Descriptions column={2}>
              <Descriptions.Item label={'投诉类型'}>{complaintType[detail.complaintType]}</Descriptions.Item>
              <Descriptions.Item label={'客户类型'}>
                {detail.customerType === 'lessor' ? '房东' : '租客'}
              </Descriptions.Item>
              <Descriptions.Item label={'用户名称'}>{detail.userName}</Descriptions.Item>
              <Descriptions.Item label={'联系手机号'}>{detail.userMobile}</Descriptions.Item>
              {detail.serviceOrderId && (
                <Descriptions.Item label={'服务单号'}>
                  <Space>
                    {detail.serviceOrderId.split(',').map(item => (
                      <Typography.Text
                        key={item}
                        style={{color: '#06B6AF', cursor: 'pointer'}}
                        underline
                        onClick={() => history.push('/steward/detail', {orderId: item})}
                      >
                        {item}
                      </Typography.Text>
                    ))}
                  </Space>
                </Descriptions.Item>
              )}
              <Descriptions.Item label={'反馈渠道'}>{feedBackChannel[detail.feedbackChannel]}</Descriptions.Item>
              <Descriptions.Item label={'投诉时间'}>{detail.complaintTime}</Descriptions.Item>
              <Descriptions.Item label={'被投诉部门'}>{detail.complainedDept}</Descriptions.Item>
              <Descriptions.Item label={'被投诉人'}>{detail.complainedEmp}</Descriptions.Item>
              <Descriptions.Item label={'责任判定'}>
                {detail.responsibilityJudgment === '0' ? '无责' : '有责'}
              </Descriptions.Item>
              <Descriptions.Item label={'投诉情况描述'}>{detail.complaintDescription}</Descriptions.Item>
              <Descriptions.Item label={'操作人'}>{detail.insertBy}</Descriptions.Item>
              <Descriptions.Item label={'操作时间'}>{detail.insertTime}</Descriptions.Item>
              <Descriptions.Item label={'状态'}>{detail.cancelOrder !== 0 ? '无效' : '有效'}</Descriptions.Item>
            </Descriptions>
            <Space style={{marginBottom: 20}}>
              <Button type={'primary'} onClick={() => history.push('/complaint', {orderId: detail.orderId, key: '2'})}>
                查看赔付情况
              </Button>
              <Button type={'primary'} onClick={() => history.push('/complaint', {orderId: detail.orderId, key: '3'})}>
                查看处罚情况
              </Button>
            </Space>
          </>
        )}

        {orderType === 1 && (
          <>
            <Descriptions column={2}>
              <Descriptions.Item label={'客户类型'}>
                {detail.customerType === 'lessor' ? '房东' : '租客'}
              </Descriptions.Item>
              <Descriptions.Item label={'原单号'}>
                <Button
                  type={'link'}
                  style={{padding: 0, margin: 0, height: '22px'}}
                  onClick={() => history.push('/complaint/detail', {orderId: detail.parentOrderId, orderType: 0})}
                >
                  {detail.parentOrderId}
                </Button>
              </Descriptions.Item>
              {detail.serviceOrderId && (
                <Descriptions.Item label={'服务单号'}>{detail.serviceOrderId}</Descriptions.Item>
              )}
              <Descriptions.Item label={'投诉时间'}>{detail.complaintTime}</Descriptions.Item>
              <Descriptions.Item label={'被投诉部门'}>{detail.complainedDept}</Descriptions.Item>
              <Descriptions.Item label={'被投诉人'}>{detail.complainedEmp ?? '/'}</Descriptions.Item>
              <Descriptions.Item label={'责任判定'}>
                {detail.responsibilityJudgment === '0' ? '无责' : '有责'}
              </Descriptions.Item>
              <Descriptions.Item label={'投诉情况描述'}>{detail.complaintDescription}</Descriptions.Item>
              <Descriptions.Item label={'赔付类型'}>
                {compensation[detail.compensationStatus?.split('-')?.[0]]}
              </Descriptions.Item>
              <Descriptions.Item label={'赔付情况'}>
                {detail.compensationStatus === 'other'
                  ? detail.compensationOtherStatusRemark
                  : compensationStatusObj[detail.compensationStatus]}
              </Descriptions.Item>
              <Descriptions.Item label={'操作人'}>{detail.compensationBy}</Descriptions.Item>
              <Descriptions.Item label={'操作时间'}>{detail.compensationTime}</Descriptions.Item>
            </Descriptions>
          </>
        )}

        {orderType === 2 && (
          <>
            <Descriptions column={2}>
              <Descriptions.Item label={'原单号'}>
                <Button
                  type={'link'}
                  style={{padding: 0, margin: 0, height: '22px'}}
                  onClick={() => history.push('/complaint/detail', {orderId: detail.parentOrderId, orderType: 0})}
                >
                  {detail.parentOrderId}
                </Button>
              </Descriptions.Item>
              {detail.serviceOrderId && (
                <Descriptions.Item label={'服务单号'}>{detail.serviceOrderId}</Descriptions.Item>
              )}
              <Descriptions.Item label={'被投诉部门'}>{detail.complainedDept}</Descriptions.Item>
              <Descriptions.Item label={'被投诉人'}>{detail.complainedEmp ?? '/'}</Descriptions.Item>
              <Descriptions.Item label={'处罚理由'}>
                {detail.punishmentReason === 'other'
                  ? `${punishmentReason[detail.punishmentReason]}-${detail.punishmentOtherReason}`
                  : punishmentReason[detail.punishmentReason]}
              </Descriptions.Item>
              <Descriptions.Item label={'处罚金额'}>{detail.punishmentAmt}元</Descriptions.Item>
              <Descriptions.Item label={'操作人'}>{detail.punishmentBy}</Descriptions.Item>
              <Descriptions.Item label={'操作时间'}>{detail.punishmentTime}</Descriptions.Item>
            </Descriptions>
          </>
        )}

        {detail.processResult && orderType === 0 && detail.cancelOrder === 0 && (
          <>
            <Descriptions column={2}>
              <DividerLine title={'处理结果'} bottom={20} />
              <Descriptions.Item label={'处理结果流程描述'}>{detail.processDescription}</Descriptions.Item>
              <Descriptions.Item label={'客户满意度'}>
                {detail.processResult === '0' ? '不满意' : '满意'}
              </Descriptions.Item>
              <Descriptions.Item label={'操作人'}>{detail.processBy}</Descriptions.Item>
              <Descriptions.Item label={'操作时间'}>{detail.cancelOrder}</Descriptions.Item>
            </Descriptions>
          </>
        )}

        {detail.cancelOrder !== 0 && (
          <>
            <DividerLine title={'作废信息'} bottom={20} />
            <Descriptions column={2}>
              <Descriptions.Item label={'作废原因'}>{detail.cancelResult}</Descriptions.Item>
              <Descriptions.Item label={'作废人'}>{detail.cancelBy}</Descriptions.Item>
              <Descriptions.Item label={'作废时间'}>{detail.cancelTime}</Descriptions.Item>
            </Descriptions>
          </>
        )}
      </div>
    </Page>
  )
}
