import React from 'react'
import {Page} from '@/components/Page'
import commonStyles from '@/assets/styles/common.module.less'
import CleanFilterComponent from '@/pages/cleanManagement/components/CleanFilterComponent'
import {Button, Form, Space, Table, Tag} from 'antd'
import {getPaginationProps} from '@/common/biz/getPaginationProps'
import {useCleanOrderList} from '@/pages/cleanManagement/hooks/useCleanOrderList'
import {useCodeArraySync, useCodeSync} from '@/store/models/Common'
import moment from 'moment/moment'
import {useAppHistory} from '@/common/hooks/useRouter'
import {cleanServiceArr} from '@/utils/clean'

const entryCode = {
  '2': '1',
  '3': '2',
  '4': '3',
  '5': '7',
  '6': '3',
}
export default function CleanServiceAll() {
  const history = useAppHistory()
  const cleanOrderStatusCode = useCodeSync('clean.order.status')
  const cleanOrderTimeStatusCode = useCodeSync('clean.order.timeoutStatus')
  const cleanOrderStatusArr = useCodeArraySync('clean.order.status', {defaultItem: true})
  const cleanOrderTimeoutStatusArr = useCodeArraySync('clean.order.timeoutStatus', {defaultItem: true})

  const [form] = Form.useForm()
  const {dataList, dataTotal, queryCleanOrderList, orderListLoading} = useCleanOrderList(form)

  return (
    <Page>
      <div className={commonStyles.filterSection}>
        <CleanFilterComponent
          form={{
            form,
            labelCol: {span: '80px'},
            labelAlign: 'left',
            initialValues: {pageNum: 1, pageSize: 10, pageable: true, sortType: 2},
            onFinish: queryCleanOrderList,
            onReset: queryCleanOrderList,
          }}
          filter={[
            {type: 'input', name: 'orderId', label: '服务单号', placeholder: '请输入'},
            {
              type: 'select',
              name: 'orderStatus',
              label: '保洁单状态',
              placeholder: '请选择',
              options: cleanOrderStatusArr,
            },
            {type: 'input', name: 'orderMakerName', label: '下单人', placeholder: '请输入'},
            {type: 'input', name: 'contactMobile', label: '服务联系手机号', placeholder: '请输入'},
            {
              type: 'range',
              name: 'insertTime',
              label: '下单时间',
              onChange: (_, dateString) => {
                form.setFieldsValue({
                  insertTimeBegin: dateString[0] ? dateString[0] + ' 00:00:00' : '',
                  insertTimeEnd: dateString[1] ? dateString[1] + ' 23:59:59' : '',
                })
              },
            },
            {
              type: 'range',
              name: 'finishTime',
              label: '完成时间',
              onChange: (_, dateString) => {
                form.setFieldsValue({
                  finishTimeBegin: dateString[0] ? dateString[0] + ' 00:00:00' : '',
                  finishTimeEnd: dateString[1] ? dateString[1] + ' 23:59:59' : '',
                })
              },
            },
            {type: 'input', name: 'propertyAddr', label: '房源', placeholder: '请输入'},
            {
              type: 'range',
              name: 'appointDate',
              label: '约定上门时间',
              onChange: (_, dateString) => {
                form.setFieldsValue({
                  appointDateBegin: dateString[0] ? dateString[0] + ' 00:00:00' : '',
                  appointDateEnd: dateString[1] ? dateString[1] + ' 23:59:59' : '',
                })
              },
            },
            {type: 'input', name: 'serverName', label: '保洁员', placeholder: '请输入'},
            {type: 'input', name: 'customerName', label: '房东', placeholder: '请输入'},
            {
              type: 'select',
              name: 'timeoutStatus',
              label: '是否超时',
              placeholder: '请选择',
              options: cleanOrderTimeoutStatusArr,
            },
            {
              type: 'select',
              name: 'categoryThiList',
              mode: 'multiple',
              label: '保洁项',
              placeholder: '请选择',
              options: cleanServiceArr,
            },
          ]}
        />
      </div>

      <div className={commonStyles.tableSection}>
        <Table
          rowKey={'orderId'}
          loading={orderListLoading}
          dataSource={dataList}
          columns={[
            {
              title: '服务单号',
              dataIndex: 'orderId',
              render: (value, record) => (
                <Space direction={'vertical'}>
                  <span>{value}</span>
                  <Space>
                    <span>
                      {[record.categoryFirName, record.categorySecName, record.categoryThiName]
                        .filter(item => item)
                        .join('-')}
                    </span>
                    {record.orderType === '2' && <Tag color={'blue'}>返</Tag>}
                  </Space>
                </Space>
              ),
            },
            {title: '保洁单状态', dataIndex: 'orderStatus', render: value => cleanOrderStatusCode[value]},
            {
              title: '下单人',
              dataIndex: 'orderMakerName',
              render: (value, record) => (
                <Space direction={'vertical'}>
                  <span>{value}</span>
                  <span>{record.contactMobile}</span>
                </Space>
              ),
            },
            {
              title: '下单时间',
              dataIndex: 'insertTime',
              sorter: {
                compare: (a, b) => new Date(a.insertTime).getTime() - new Date(b.insertTime).getTime(),
              },
            },
            {
              title: '约定上门时间',
              dataIndex: 'appointTimeBegin',
              render: (value, record) =>
                !!value &&
                [
                  moment(value)?.format('YYYY-MM-DD HH:mm'),
                  moment(record.appointTimeEnd)?.format('YYYY-MM-DD HH:mm')?.slice(-5),
                ]
                  .filter(item => item)
                  .join('-'),
            },
            {
              title: '保洁完成时间',
              dataIndex: 'finishTime',
              sorter: {
                compare: (a, b) => new Date(a.finishTime).getTime() - new Date(b.finishTime).getTime(),
              },
            },
            {
              title: '是否超时',
              dataIndex: 'timeoutStatus',
              render: value => cleanOrderTimeStatusCode[value],
            },
            {title: '房东', dataIndex: 'customerName'},
            {title: '房源', dataIndex: 'propertyAddr'},
            {title: '保洁员', dataIndex: 'serverName'},
            {
              title: '操作',
              fixed: 'right',
              render: record => (
                <Button
                  type={'link'}
                  onClick={() => {
                    record.orderStatus === '1'
                      ? history.push('/service-clean-confirm/detail', {orderId: record.orderId})
                      : history.push('/clean-detail', {orderId: record.orderId, entry: entryCode[record.orderStatus]})
                  }}
                >
                  查看
                </Button>
              ),
            },
          ]}
          scroll={{x: 'max-content'}}
          pagination={getPaginationProps(form, dataTotal)}
        />
      </div>
    </Page>
  )
}
