import React, {useCallback, useEffect, useState} from 'react'
import {Page} from '@/components/Page'
import commonStyles from '@/assets/styles/common.module.less'
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Descriptions,
  Drawer,
  Form,
  Input,
  message,
  Modal,
  Row,
  Select,
  Space,
  Table,
  Tabs,
  Tag,
  Typography,
} from 'antd'
import {getPaginationProps} from '@/common/biz/getPaginationProps'
import {stewardModel} from '@/store/models/Steward'
import {FORMAT_DATETIME} from '@/common/date'
import moment from 'moment'
import DividerLine from '@/pages/property/component/DividerLine'
import {useAppHistory} from '@/common/hooks/useRouter'
import {commonModel, useCodeSync} from '@/store/models/Common'
import {Breadcrumb} from '@/components/Breadcrumb'
import {sysUserModel} from '@/store/models/SysUser'
import {sysModel} from '@/store/models/Sys'
import GuanYuBindMobile from '@/pages/steward/components/GuanYuBindMobile'
import {timeInterval} from '@/utils/util'

export default function RestDayOrder() {
  const history = useAppHistory()

  const orderStatus = useCodeSync('steward.orderStatus')

  const [form] = Form.useForm()

  const [dataSource, setDataSource] = useState<StewardOrderRestVO[]>([])
  const [total, setTotal] = useState(0)
  const [detail, setDetail] = useState({} as StewardOrderRestVO)

  const loading = stewardModel.stewardAdminOrderRestList2.useLoading()
  const search = useCallback(async () => {
    const value = form.getFieldsValue(true)
    const {
      response: {data, total},
    } = await stewardModel.stewardAdminOrderRestList2(value)
    setTotal(total)
    setDataSource(data)
  }, [form])

  // 页面加载时触发
  useEffect(() => {
    form.submit()
  }, [form])

  const [orderForm] = Form.useForm()

  const [visible, setVisible] = useState(false)
  const [workingList, setWorkingList] = useState<PairModel[]>([])
  const restHandleLoading = stewardModel.stewardAdminOrderRestHandle.useLoading()
  const queryWorkingList = useCallback(async (timeAppoint: string) => {
    const {
      response: {data},
    } = await stewardModel.stewardAdminOrderRestListStewardWorking({timeAppoint})
    setWorkingList(data)
  }, [])

  const [drawerVisible, setDrawerVisible] = useState(false)

  const [restUserList, setRestUserList] = useState<StewardOrderServerVO[]>([])
  const [restList, setRestList] = useState<StewardOrderQueryVO[]>([])
  const restListLoading = stewardModel.stewardAdminOrderRestListOrderProcessing.useLoading()
  const [allSteward, setAllStewardList] = useState<StewardOrderServerVO[]>([])
  // 查询管家
  const queryStewardList = async (stewardId: string) => {
    const {
      response: {data},
    } = await stewardModel.stewardAdminOrderRestListSteward({
      fetchAll: true,
      stewardId,
    })
    setAllStewardList(data)
  }

  // 默认查询同组织人员
  const queryDefaultStewardList = useCallback(async (stewardId: string) => {
    const {
      response: {data},
    } = await stewardModel.stewardAdminOrderRestListSteward({
      fetchAll: false,
      stewardId,
    })
    setRestUserList(data)
  }, [])

  // 查询管家的订单信息
  const queryOrderList = useCallback(async (stewardId: string, orderId: string) => {
    const {
      response: {data},
    } = await stewardModel.stewardAdminOrderRestListOrderProcessing({stewardId, orderId})
    setRestList(data)
  }, [])

  // 城市列表
  const [cityList, setCityList] = useState<CityDataProps[]>([])

  const [selectedRowKeys, setSelectedRowKeys] = useState([])

  const [guanYuMobile, setGuanYuMobile] = useState<string>('')

  useEffect(() => {
    commonModel.queryCityCode({type: '2', parentCode: ''}).then(({response: {data}}) => setCityList(data))
  }, [])

  const [stewardUserList, setStewardUserList] = useState<SysUserVO[]>([])
  const [configForm] = Form.useForm()
  useEffect(() => {
    sysUserModel
      .querySysUserList({pageable: false, pageNum: 1, pageSize: 1, status: '1', roleId: 'steward'} as SysListDTO)
      .then(({response: {data}}) => {
        setStewardUserList(data)
      })
  }, [])

  const [mobileVisible, setMobileVisible] = useState(false)

  return (
    <Page
      breadcrumb={
        <Breadcrumb
          right={
            <Space>
              <Button
                type={'primary'}
                onClick={async () => {
                  const {
                    response: {data},
                  } = await sysModel.sysConfigGet({k: 'CONFIG_NOT_TAKE_REST_DAY_ORDER_STEWARDS'})
                  configForm.setFieldsValue({stewardIdList: data?.stewardIdList})
                  Modal.confirm({
                    title: '派单规则配置',
                    icon: null,
                    centered: true,
                    width: 500,
                    onOk: async () => {
                      await configForm.validateFields()
                      const stewardIdList = configForm.getFieldValue('stewardIdList')
                      await sysModel.sysConfigSave({k: 'CONFIG_NOT_TAKE_REST_DAY_ORDER_STEWARDS', v: {stewardIdList}})
                    },
                    content: (
                      <Form form={configForm}>
                        <Form.Item label={'不接单管家'} name={'stewardIdList'}>
                          <Select
                            options={stewardUserList}
                            fieldNames={{label: 'name', value: 'userId'}}
                            showSearch
                            filterOption={(inputValue, option) => {
                              return option.name?.indexOf?.(inputValue) > -1
                            }}
                            placeholder={'请选择'}
                            mode={'multiple'}
                            maxTagCount={3}
                          />
                        </Form.Item>
                      </Form>
                    ),
                  })
                }}
              >
                派单规则配置
              </Button>
              <Button
                type={'primary'}
                onClick={() => {
                  if (!selectedRowKeys?.length) return message.warning('请选择服务单！')
                  orderForm.setFieldsValue({orderIdList: selectedRowKeys})
                  setVisible(true)
                }}
              >
                批量接单
              </Button>
            </Space>
          }
        />
      }
    >
      <div className={commonStyles.filterSection}>
        <Form
          initialValues={{pageNum: 1, pageSize: 10, pageable: false}}
          form={form}
          onFinish={search}
          onReset={search}
        >
          <Row gutter={30}>
            <Col span={8}>
              <Form.Item label={'服务单ID'} name={'orderId'}>
                <Input placeholder={'请输入'} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={'房源'} name={'propertyAddr'}>
                <Input placeholder={'请输入'} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={'下单人'} name={'orderMakerName'}>
                <Input placeholder={'请输入'} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={'服务联系手机号'} name={'contactMobile'}>
                <Input placeholder={'请输入'} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={'休息管家'} name={'serverName'}>
                <Input placeholder={'请输入'} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={'区域'} name={'cityCodeList'}>
                <Select
                  placeholder={'请选择'}
                  mode={'multiple'}
                  showSearch
                  filterOption={(inputValue, option) => option?.children?.includes(inputValue as any)}
                >
                  {cityList.map(item => (
                    <Select.Option value={item.code} key={item.code}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={'紧急单'} name={'emergency'}>
                <Select
                  options={[
                    {label: '紧急', value: 1},
                    {label: '非紧急', value: 0},
                  ]}
                  placeholder={'请选择'}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={'房东'} name={'customerName'}>
                <Input placeholder={'请输入'} allowClear />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={'渠道'} name={'appName'}>
                <Input placeholder={'请输入'} allowClear />
              </Form.Item>
            </Col>
          </Row>

          <Row justify={'end'}>
            <Space>
              <Button
                loading={loading}
                type={'primary'}
                htmlType={'submit'}
                onClick={() => form.setFieldsValue({pageNum: 1})}
              >
                查询
              </Button>
              <Button htmlType={'reset'}>重置</Button>
            </Space>
          </Row>
        </Form>
      </div>

      <div className={commonStyles.tableSection}>
        <Table
          dataSource={dataSource}
          rowKey={'orderId'}
          rowSelection={{
            selectedRowKeys,
            onChange: keys => {
              setSelectedRowKeys(keys)
            },
          }}
          scroll={{x: 'max-content'}}
          columns={[
            {
              title: '服务单ID',
              dataIndex: 'orderId',
              render: (orderId, record) => (
                <Space>
                  <Typography.Text
                    underline
                    onClick={() => history.push({pathname: '/steward/detail', state: {orderId}})}
                  >
                    {orderId}
                  </Typography.Text>
                  {record.emergency === 1 && <Tag color={'red'}>急</Tag>}
                </Space>
              ),
            },
            {title: '休息管家', dataIndex: 'serverName'},
            {title: '下单人', dataIndex: 'orderMakerName'},
            {title: '服务联系手机号', dataIndex: 'contactMobile'},
            {title: '服务项目', dataIndex: 'categoryName'},
            {
              title: '下单时间',
              dataIndex: 'timeMake',
              render: (value, record) => (
                <>
                  <div>{value}</div>
                  {record.appId === 'GUANYU' && !!record.guanyuExpireAt && (
                    <div style={{color: '#f00'}}>{timeInterval(record.guanyuExpireAt)}</div>
                  )}
                </>
              ),
            },
            {title: '房东', dataIndex: 'customerName'},
            {title: '渠道', dataIndex: 'appName', render: value => value ?? '/'},
            {title: '房源', dataIndex: 'propertyAddr'},
            {
              title: '操作',
              render: (_, record) => {
                return (
                  <Button
                    type={'link'}
                    onClick={async () => {
                      await queryStewardList(record.serverId)
                      await queryOrderList(record.serverId, record.orderId)
                      await queryDefaultStewardList(record.serverId)
                      setDrawerVisible(true)
                      setDetail(record)
                    }}
                  >
                    查看详情
                  </Button>
                )
              },
            },
          ]}
          pagination={getPaginationProps(form, total)}
        />
      </div>

      <Modal
        title={'接单'}
        open={visible}
        onCancel={() => setVisible(false)}
        onOk={() => orderForm.submit()}
        afterClose={() => {
          orderForm.resetFields()
          setSelectedRowKeys([])
        }}
        confirmLoading={restHandleLoading}
        destroyOnClose // 因为 Drawer 设置了 destroyOnClose，在 Drawer 关闭后会销毁，新 Drawer 的层级会超过旧 Modal 的层级，导致 Modal 被新 Drawer 覆盖
      >
        <Form
          form={orderForm}
          labelCol={{span: 6}}
          onFinish={async () => {
            const {orderIdList, ...values} = orderForm.getFieldsValue(true)
            for (const orderId of orderIdList) {
              await stewardModel.stewardAdminOrderRestHandle({...values, orderId})
            }
            message.success('操作成功')
            setVisible(false)
            setDrawerVisible(false)
            search()
          }}
        >
          <Form.Item
            label={'约定上门时间'}
            name={'timeAppoint'}
            rules={[{required: true, message: '约定上门时间不能为空'}]}
            getValueFromEvent={event => event?.format(FORMAT_DATETIME)}
            getValueProps={value => (value ? moment(value) : value)}
          >
            <DatePicker
              showTime
              allowClear
              placeholder={'请选择'}
              onChange={(_, formatString) => {
                // 如果已勾选"休息管家"，则不查询服务人员列表，也不重置服务人员id
                if (orderForm.getFieldValue('_serverId')) return

                if (formatString) {
                  queryWorkingList(formatString)
                }
                orderForm.setFieldsValue({serverIdFinal: null})
              }}
              disabledDate={date => moment().subtract(1, 'day').isAfter(date)}
            />
          </Form.Item>
          <Form.Item label={'服务人员'} required shouldUpdate={(prev, next) => prev._serverId !== next._serverId}>
            {f => (
              <>
                <Row gutter={12} style={{alignItems: 'flex-start'}}>
                  {!!detail?.serverId && (
                    <Col>
                      <Form.Item noStyle name={'_serverId'} valuePropName={'checked'}>
                        <Checkbox
                          onChange={e => {
                            if (e.target.checked) {
                              f.setFieldsValue({serverIdFinal: detail.serverId})
                              setWorkingList([{key: detail.serverId, value: detail.serverName}])
                            }
                          }}
                        >
                          休息管家
                        </Checkbox>
                      </Form.Item>
                    </Col>
                  )}
                  <Col flex={1}>
                    <Row>
                      <Form.Item noStyle name={'serverIdFinal'} rules={[{required: true, message: '服务人员不能为空'}]}>
                        <Select
                          allowClear
                          showSearch
                          optionFilterProp={'children'}
                          placeholder={'请选择'}
                          disabled={f.getFieldValue('_serverId')} // 勾选"休息管家"则禁止选择"服务人员"
                          onChange={async (_, option) => {
                            if (!Array.isArray(option)) {
                              if (option && option.value) {
                                const {
                                  response: {data},
                                } = await sysModel.sysOrgMemberOrgPath({userId: option.value})
                                orderForm.setFieldsValue({_orgPath: data})
                              } else {
                                orderForm.setFieldsValue({_orgPath: ''})
                              }
                            }
                          }}
                        >
                          {workingList.map(value => (
                            <Select.Option key={value.key} value={value.key}>
                              {value.value}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Row>
                    <Row>
                      <Form.Item noStyle shouldUpdate>
                        {form1 => {
                          const _orgPath = form1.getFieldValue('_orgPath')
                          return (
                            !!_orgPath && (
                              <Form.Item label={' '} colon={false} style={{margin: 0}}>
                                <span style={{color: '#f00'}}>{_orgPath}</span>
                              </Form.Item>
                            )
                          )
                        }}
                      </Form.Item>
                    </Row>
                  </Col>
                </Row>
              </>
            )}
          </Form.Item>
          <Form.Item label={'订单备注'} name={'handleRemark'}>
            <Input.TextArea placeholder={'请输入备注'} maxLength={200} rows={3} />
          </Form.Item>
        </Form>
      </Modal>

      <Drawer
        destroyOnClose
        title={'约定服务详情'}
        width={800}
        visible={drawerVisible}
        onClose={() => {
          setDrawerVisible(false)
          setRestList([])
          setRestUserList([])
          setDetail({} as StewardOrderRestVO)
          setGuanYuMobile('')
        }}
      >
        <DividerLine
          title='基本信息'
          style={{marginBottom: 10}}
          right={
            <Space style={{marginLeft: 10}}>
              <Button
                type={'link'}
                style={{padding: 0}}
                onClick={() => {
                  orderForm.setFieldsValue({orderIdList: [detail.orderId]})
                  setVisible(true)
                }}
              >
                接单
              </Button>
              {detail.appId === 'GUANYU' && detail.publicTag === 0 && (
                <Button
                  type={'link'}
                  style={{padding: 0}}
                  onClick={() => {
                    setMobileVisible(true)
                  }}
                >
                  绑定号码
                </Button>
              )}
              {detail.appId === 'GUANYU' && (
                <Button
                  type={'link'}
                  style={{padding: 0}}
                  onClick={async () => {
                    const {
                      response: {data},
                    } = await stewardModel.guanyuGetRepairUserVirNo({orderId: detail.orderId})
                    setGuanYuMobile(data)
                  }}
                >
                  获取龙湖冠寓隐私号
                </Button>
              )}
              {guanYuMobile && <span style={{color: '#00B7AE'}}>{guanYuMobile}</span>}
            </Space>
          }
        />
        <Descriptions column={2}>
          <Descriptions.Item label={'服务单ID'}>{detail.orderId}</Descriptions.Item>
          <Descriptions.Item label={'休息管家'}>{detail.serverName}</Descriptions.Item>
          <Descriptions.Item label={'服务项目'}>{detail.categoryName}</Descriptions.Item>
          <Descriptions.Item label={'服务地址'}>{detail.propertyAddr}</Descriptions.Item>
        </Descriptions>
        <DividerLine title={'同组织及以下管家约单信息'} style={{margin: '20px 0'}} />
        <Select
          placeholder={'请输入需要查询的管家'}
          style={{width: '300px', marginBottom: 20}}
          onChange={(value: string) => {
            if (value) {
              setRestUserList(allSteward.filter(item => item.id === value))
              queryOrderList(value, detail.orderId)
            } else {
              queryOrderList(detail.serverId, detail.orderId)
              queryDefaultStewardList(detail.serverId)
            }
          }}
          showSearch
          filterOption={(inputValue, option) => {
            return option?.children?.indexOf(inputValue as any) > -1
          }}
          allowClear
        >
          {allSteward.map(item => (
            <Select.Option value={item.id} key={item.id}>{`${item.name}（${item.orderNum ?? '0'}）`}</Select.Option>
          ))}
        </Select>
        <Tabs
          tabPosition={'left'}
          onChange={async stewardId => {
            await queryOrderList(stewardId, detail.orderId)
          }}
        >
          {restUserList.map(value => (
            <Tabs.TabPane tab={`${value.name}（${value.orderNum ?? '0'}）`} id={value.id} key={value.id}>
              <Table
                scroll={{x: 'max-content'}}
                dataSource={restList}
                rowKey={'orderId'}
                loading={restListLoading}
                columns={[
                  {
                    title: '最新约定上门时间',
                    dataIndex: 'timeAppoint',
                    sorter: (a, b) => moment(a.timeAppoint).valueOf() - moment(b.timeAppoint).valueOf(),
                  },
                  {title: '服务地址', dataIndex: 'propertyAddr'},
                  {
                    title: '距离',
                    dataIndex: 'distance',
                    render: text => `${Number(text / 1000).toFixed(2)}km`,
                    sorter: (a, b) => a.distance - b.distance,
                  },
                  {title: '服务单号', dataIndex: 'orderId'},
                  {
                    title: '服务项目',
                    render: (_, record) =>
                      `${record.categoryFirName}-${record.categorySecName}-${record.categoryThiName}`,
                  },
                  {title: '状态', dataIndex: 'orderStatus', render: value => orderStatus[value]},
                  {
                    title: '下单时间',
                    dataIndex: 'timeMake',
                    sorter: (a, b) => moment(a.timeMake).valueOf() - moment(b.timeMake).valueOf(),
                  },
                ]}
                pagination={{position: ['bottomCenter']}}
              />
            </Tabs.TabPane>
          ))}
        </Tabs>
      </Drawer>

      {/* 绑定号码 */}
      <GuanYuBindMobile visible={mobileVisible} onCancel={() => setMobileVisible(false)} orderId={detail.orderId} />
    </Page>
  )
}
