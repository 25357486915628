import React, {useEffect, useRef, useState} from 'react'
import {Button, Col, Drawer, Form, Input, Row, Select, Space, Table, Typography, Modal} from 'antd'
import styles from './index.module.less'
import {propertyModel} from '@/store/models/Property'
import {useCodeSync} from '@/store/models/Common'
import {getImg} from '@/utils/util'
import commonStyles from '@/assets/styles/common.module.less'
import {hasPermission} from '@/components/Permission'
import {Page} from '@/components/Page'

export default function PropertyInfoList() {
  const [visible, setVisible] = useState(false)
  const [img, setImg] = useState('')
  const [list, setList] = useState([] as PropertyInfoVO[])
  const [total, setTotal] = useState(0)
  const [detail, setDetail] = useState({} as PropertyExtraVO)
  //查询房源列表
  const [params, setParams] = useState({
    pageNum: 1,
    pageSize: 10,
    pageable: true,
    extraExist: 1,
  } as PropertyAdminListQueryDTO)
  const rentType = useCodeSync('property.rentType')
  const [form] = Form.useForm()
  const formRef = useRef(null)
  const [title, setTitle] = useState('')
  useEffect(() => {
    propertyModel.queryPropertyList(params).then(({response: {data, total}}) => {
      setList(data)
      setTotal(total)
    })
  }, [params])
  //查询
  function onFinish() {
    const {title, rentType} = form.getFieldsValue()
    setParams(state => ({...state, title, rentType, pageNum: 1}))
  }

  //重置
  function onReset() {
    formRef.current.resetFields()
    const {title, rentType} = form.getFieldsValue()
    setParams(state => ({...state, title, rentType}))
  }
  return (
    <Page>
      <div className={commonStyles.filterSection}>
        <Form ref={formRef} form={form} onFinish={onFinish}>
          <Row>
            <Col span={7}>
              <Form.Item label={'房源地址'} name={'title'}>
                <Input placeholder={'请输入房源地址'} allowClear />
              </Form.Item>
            </Col>
            <Col span={7} offset={1}>
              <Form.Item label={'房源类型'} name={'rentType'}>
                <Select placeholder={'请选择'} allowClear>
                  <Select.Option value={'1'}>整租</Select.Option>
                  <Select.Option value={'2'}>合租</Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row justify={'end'}>
            <Space>
              <Button type={'primary'} htmlType={'submit'}>
                查询
              </Button>
              <Button onClick={() => onReset()}>重置</Button>
            </Space>
          </Row>
        </Form>
      </div>

      <div className={commonStyles.tableSection}>
        <Table
          rowKey={'propertyCode'}
          loading={false}
          dataSource={list}
          columns={[
            {title: '房源地址', key: 'title', dataIndex: 'title', width: 450},
            {
              title: '房源类型',
              key: 'rentTypeExtra',
              dataIndex: 'rentTypeExtra',
              render: text => <Typography.Text>{rentType[text] || '无'}</Typography.Text>,
            },
            {
              title: '房源户型',
              key: 'bedroomExtra',
              dataIndex: 'bedroomExtra',
              render: (text, record, index) => (
                <Typography.Text>{`${record?.bedroomExtra || 0}室${record?.parlorExtra || 0}厅${
                  record?.kitchenExtra || 0
                }厨${record?.bathroomExtra || 0}卫`}</Typography.Text>
              ),
            },
            {
              title: '操作',
              key: 'operation',
              dataIndex: 'operation',
              render: (text, record, index) =>
                hasPermission('001038002') && (
                  <Button
                    type={'link'}
                    onClick={() => {
                      setTitle(record.title)
                      propertyModel
                        .propertyDetailExtra(record.propertyCode)
                        .then(({response: {data}}) => setDetail(data))
                        .then(() => setVisible(true))
                    }}
                    style={{padding: 0}}
                  >
                    查看
                  </Button>
                ),
            },
          ]}
          pagination={{
            total: total,
            defaultCurrent: 1,
            defaultPageSize: 10,
            current: params.pageNum,
            pageSize: params.pageSize,
            position: ['bottomCenter'],
            showSizeChanger: true,
            onChange: page => {
              setParams(state => ({...state, pageNum: page}))
            },
            onShowSizeChange: (current, size) => {
              setParams(state => ({...state, pageNum: current, pageSize: size}))
            },
          }}
        />
      </div>

      <Drawer visible={visible} title={'房源详情'} width={698} onClose={() => setVisible(false)}>
        <div>
          <div className={styles.typeTitle}>
            <div className={styles.divider} />
            <Typography.Text className={styles.text}>基本信息</Typography.Text>
          </div>
          <Row className={styles.row}>
            <Col span={16}>
              <Typography.Text>房源地址：</Typography.Text>
              <Typography.Text style={{color: '#000000'}}>{title}</Typography.Text>
            </Col>
            <Col span={8}>
              <Typography.Text>房源类型：</Typography.Text>
              <Typography.Text style={{color: '#000000'}}>{rentType[detail.rentTypeExtra] || '无'}</Typography.Text>
            </Col>
            <Col span={12} style={{marginTop: '10px'}}>
              <Typography.Text>房源户型：</Typography.Text>
              <Typography.Text style={{color: '#000000'}}>{`${detail.bedroomExtra || 0}室${detail.parlorExtra || 0}厅${
                detail.kitchenExtra || 0
              }厨${detail.bathroomExtra || 0}卫`}</Typography.Text>
            </Col>
          </Row>
          {detail.propertyExtraImgIdList?.length ? (
            <>
              <div className={styles.typeTitle}>
                <div className={styles.divider} />
                <Typography.Text className={styles.text}>房间真实照片</Typography.Text>
              </div>
              <div className={styles.imgList}>
                <Row gutter={[12, 0]}>
                  {detail.propertyExtraImgIdList.map(item => (
                    <Col key={item.imgId}>
                      <img
                        src={getImg(item.imgUrl)}
                        alt={''}
                        width={88}
                        height={88}
                        style={{marginBottom: '10px'}}
                        onClick={() => {
                          setImg(item.imgUrl)
                        }}
                      />
                    </Col>
                  ))}
                </Row>
              </div>
            </>
          ) : null}
          {detail.propertyExtraPublicImgIdList?.length && detail.rentTypeExtra === '2' ? (
            <>
              <div className={styles.typeTitle}>
                <div className={styles.divider} />
                <Typography.Text className={styles.text}>公共区域照片</Typography.Text>
              </div>
              <div className={styles.imgList}>
                <Row gutter={[12, 0]}>
                  {detail.propertyExtraPublicImgIdList.map(item => (
                    <Col key={item.imgId}>
                      <img
                        src={getImg(item.imgUrl)}
                        alt={''}
                        width={88}
                        height={88}
                        style={{marginBottom: '10px'}}
                        onClick={() => {
                          setImg(item.imgUrl)
                        }}
                      />
                    </Col>
                  ))}
                </Row>
              </div>
            </>
          ) : null}
        </div>
      </Drawer>
      <Modal visible={!!img} footer={null} onCancel={() => setImg('')}>
        <img src={getImg(img)} alt={''} width={'100%'} height={'100%'} />
      </Modal>
    </Page>
  )
}
