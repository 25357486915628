import {Page} from '@/components/Page'
import DividerLine from '@/pages/property/component/DividerLine'
import {useCodeSync} from '@/store/models/Common'
import {stewardModel} from '@/store/models/Steward'
import {getImg, printModule} from '@/utils/util'
import {
  Button,
  Col,
  Descriptions,
  Form,
  Image,
  Input,
  Modal,
  PageHeader,
  Row,
  Space,
  Tabs,
  Typography,
  message,
} from 'antd'
import TabPane from 'antd/lib/tabs/TabPane'
import moment from 'moment'
import React, {useCallback, useEffect, useState} from 'react'
import {useLocation} from 'react-router-dom'
import {PrinterOutlined} from '@ant-design/icons'
import {usePermission} from '@/components/Permission'
import {useAppHistory} from '@/common/hooks/useRouter'
import {Album} from '@/components/Album'

const label = {
  2: '审批时间',
  3: '审批时间',
  4: '撤销时间',
}

const obj = {
  0: '离职',
  1: '在职',
}

export default function ImprestApplyDetail() {
  const [auth] = usePermission()
  const [detailData, setDetailData] = useState({} as StewardImprestApplyDetailVO)
  const [passOpen, setPassOpen] = useState<boolean>(false)
  const [rejectOpen, setRejectOpen] = useState<boolean>(false)
  // 核销详情
  const [detail, setDetail] = useState({} as StewardImprestVerificationDetailVO)
  const [stewardUserInfo, setStewardUserInfo] = useState<StewardUserVO>({} as StewardUserVO)
  //   const [selectImg, setSelectImg] = useState<{imgUrl: string; visible: boolean}>({imgUrl: '', visible: false})
  // const history = useHistory()
  const location = useLocation<any>()
  const applyId = location.state?.applyId
  const verificationId = location.state?.verificationId
  const userId = location.state?.applyUserId
  const approvalStatusCode = useCodeSync('steward.imprest.approval.status')
  const useObj = useCodeSync('steward.imprest.use.type')
  const [cancelForm] = Form.useForm()
  const history = useAppHistory()
  // 管家详情
  useEffect(() => {
    stewardModel.stewardUserInfo({userId}).then(res => {
      setStewardUserInfo(res.response.data)
    })
  }, [userId])

  // 申请单信息
  useEffect(() => {
    if (!applyId) return
    stewardModel.stewardImprestApplyDetail({applyId}).then(res => {
      setDetailData(res.response.data)
    })
  }, [applyId])

  const [visible, setVisible] = useState(false)
  const statusObj = useCodeSync('steward.imprest.verification.approval.status')

  const queryDetail = useCallback(() => {
    if (!verificationId) return
    stewardModel.stewardImprestVerificationDetail({verificationId}).then(res => {
      setDetail(res.response.data)
    })
  }, [verificationId])

  useEffect(() => {
    queryDetail()
  }, [queryDetail, verificationId])

  return (
    <div id='print'>
      <Page
        header={
          <PageHeader
            onBack={() => history.goBack()}
            ghost={false}
            title={'备用金详情'}
            extra={
              <Space>
                <PrinterOutlined style={{fontSize: 24}} onClick={() => printModule('print')} />
                {auth('019190002') && detail.approvalStatus === '2' ? (
                  <Button
                    type='primary'
                    onClick={() => {
                      setPassOpen(true)
                    }}
                  >
                    核销通过
                  </Button>
                ) : null}
                {auth('020190002') && detail.approvalStatus === '2' ? (
                  <Button
                    type='primary'
                    onClick={() => {
                      setRejectOpen(true)
                    }}
                  >
                    核销驳回
                  </Button>
                ) : null}
              </Space>
            }
          />
        }
      >
        <div style={{background: '#fff', marginBottom: 20}}>
          <div style={{padding: '20px 25px'}}>
            <DividerLine title={'申请人基本信息'} />
            <Row gutter={[0, 5]} style={{marginTop: 5}}>
              <Col span={6}>
                <Typography.Text>姓名：</Typography.Text>
                <Typography.Text style={{color: '#000000'}}>{stewardUserInfo.name}</Typography.Text>
              </Col>
              <Col span={6}>
                <Typography.Text>手机号：</Typography.Text>
                <Typography.Text style={{color: '#000000'}}>{stewardUserInfo.mobile}</Typography.Text>
              </Col>
              <Col span={6}>
                <Typography.Text>用户组：</Typography.Text>
                <Typography.Text style={{color: '#000000'}}>{stewardUserInfo.userRoleNameJoint}</Typography.Text>
              </Col>
              {
                <Col span={6}>
                  <Typography.Text>组织：</Typography.Text>
                  <Typography.Text style={{color: '#000000'}}>{stewardUserInfo.orgName}</Typography.Text>
                </Col>
              }
              <Col span={6}>
                <Typography.Text>所属站点：</Typography.Text>
                <Typography.Text style={{color: '#000000'}}>
                  {stewardUserInfo.warehouse?.map(item => item.value)?.join('、')}
                </Typography.Text>
              </Col>
              <Col span={6}>
                <Typography.Text>状态：</Typography.Text>
                <Typography.Text style={{color: '#000000'}}>{obj[stewardUserInfo.status]}</Typography.Text>
              </Col>
              <Col span={6}>
                <Typography.Text>备用金余额：</Typography.Text>
                <Typography.Text style={{color: '#000000'}}>{stewardUserInfo.imprest}</Typography.Text>
              </Col>
              {stewardUserInfo.status === '0' && (
                <Col span={6}>
                  <Typography.Text>离职日期：</Typography.Text>
                  <Typography.Text style={{color: '#000000'}}>{stewardUserInfo.dimissionDate}</Typography.Text>
                </Col>
              )}
            </Row>
          </div>
        </div>
        <div style={{background: '#fff'}}>
          <div style={{padding: '5px 25px 20px'}}>
            <Tabs defaultValue={'1'}>
              <TabPane tab={'申请详情'} key={'1'}>
                <Descriptions title={<DividerLine title={'申请单信息'} />} column={2}>
                  <Descriptions.Item label={'申请记录id'}>{detailData.id}</Descriptions.Item>
                  <Descriptions.Item label={'申请人'}>{detailData.applyUserName}</Descriptions.Item>
                  <Descriptions.Item label={'申请时间'}>
                    {detailData.insertTime ? moment(detailData.insertTime).format('YYYY-MM-DD HH:mm:ss') : ''}
                  </Descriptions.Item>
                  <Descriptions.Item label={'申请状态'}>
                    {approvalStatusCode[detailData.approvalStatus]}
                  </Descriptions.Item>
                  {detailData.approvalStatus !== '1' && (
                    <Descriptions.Item label={label[detailData.approvalStatus]}>
                      {detailData.approvalTime ? moment(detailData.approvalTime).format('YYYY-MM-DD HH:mm:ss') : ''}
                    </Descriptions.Item>
                  )}
                </Descriptions>

                <Descriptions title={<DividerLine title={'申请单详情'} />} column={2} style={{marginTop: 25}}>
                  <Descriptions.Item label={'备用金申请前余额（元）'}>
                    {detailData.beforeApplyImprestAmt}
                  </Descriptions.Item>
                  <Descriptions.Item label={'申请备用金金额（元）'}>{detailData.applyImprestAmt}</Descriptions.Item>
                  <Descriptions.Item label={'备用金用途'}>{useObj[detailData.imprestUseType]}</Descriptions.Item>
                  <Descriptions.Item label={'申请事由'}>{detailData.applyReason}</Descriptions.Item>
                  <Descriptions.Item label={'付款方式'}>{detailData.payWay}</Descriptions.Item>
                  <Descriptions.Item label={'付款日期'}>
                    {detailData.expectPayDate ? moment(detailData.expectPayDate).format('YYYY-MM-DD') : ''}
                  </Descriptions.Item>
                  <Descriptions.Item label={'收款人'}>{detailData.payee}</Descriptions.Item>
                  {detailData.payWay === '银行卡' ? (
                    <Descriptions.Item label={'银行账号'}>{detailData.payeeAccountNumber}</Descriptions.Item>
                  ) : (
                    <Descriptions.Item label={'收款账户'}>{detailData.payeeAccountNumber}</Descriptions.Item>
                  )}
                  {detailData.payWay === '银行卡' ? (
                    <Descriptions.Item label={'开户行'}>{detailData.bankBranchName}</Descriptions.Item>
                  ) : null}
                  <Descriptions.Item label={'附件'}>
                    {!!detailData?.payUrlList?.length && (
                      <Album
                        disabled
                        fileList={detailData.payUrlList.map(value => ({
                          uid: value,
                          url: getImg(value),
                        }))}
                      >
                        {null}
                      </Album>
                    )}
                  </Descriptions.Item>
                </Descriptions>
              </TabPane>
              {detailData.approvalStatus === '2' && verificationId && detail.approvalStatus !== '1' && (
                <TabPane tab={'核销详情'} key={'2'}>
                  <Descriptions title={<DividerLine title={'核销单信息'} />} column={2}>
                    <Descriptions.Item label={'核销记录id'}>{detail.id}</Descriptions.Item>
                    <Descriptions.Item label={'申请人'}>{detail.verificationUserName}</Descriptions.Item>
                    <Descriptions.Item label={'申请时间'}>{detail.insertTime}</Descriptions.Item>
                    <Descriptions.Item label={'核销状态'}>{statusObj[detail.approvalStatus]}</Descriptions.Item>

                    {detail.approvalStatus !== '2' ? (
                      <>
                        <Descriptions.Item label={'核销人'}>{detail.approvalUserName}</Descriptions.Item>
                        <Descriptions.Item label={detail.approvalStatus === '3' ? '驳回时间' : '核销时间'}>
                          {detail.verificationTime}
                        </Descriptions.Item>
                        {detail.approvalStatus === '2' ? (
                          <Descriptions.Item label={'核销后备用金余额（元）'}>
                            {detail.verificationAfterAmt}
                          </Descriptions.Item>
                        ) : null}
                      </>
                    ) : null}
                    {detail.approvalStatus === '3' && (
                      <Descriptions.Item label={'驳回原因'}>{detail.rejectRemark}</Descriptions.Item>
                    )}
                  </Descriptions>
                  <Descriptions title={<DividerLine title={'核销单详情'} />} column={2} style={{marginTop: 25}}>
                    {/* 采购类 */}
                    {detail.imprestUseType === '1' && (
                      <Descriptions.Item label={'关联备用金申请单'}>{applyId}</Descriptions.Item>
                    )}
                    <Descriptions.Item label={'备用金用途'}>{useObj[detail.imprestUseType]}</Descriptions.Item>

                    {/* 供应商外包 */}
                    {detail.imprestUseType === '2' && (
                      <Descriptions.Item label={'关联服务单'}>{detail.stewardOrderId}</Descriptions.Item>
                    )}

                    {/* 采购类 显示 */}
                    {detail.imprestUseType === '1' ? (
                      <>
                        <Descriptions.Item label={'已线下采购，请先确认入仓物品'}>
                          <div
                            style={{cursor: 'pointer', textDecoration: 'underline', color: '#00B7AE'}}
                            onClick={() => setVisible(true)}
                          >
                            入仓详情
                          </div>
                        </Descriptions.Item>
                        <Descriptions.Item label={'采购日期'}>{detail.purchaseDate?.split(' ')?.[0]}</Descriptions.Item>
                      </>
                    ) : null}

                    <Descriptions.Item label={detail.imprestUseType === '1' ? '采购说明' : '用途说明'}>
                      {detail.useExplain}
                    </Descriptions.Item>
                    <Descriptions.Item label={'核销金额（元）'}>{detail.verificationAmt}</Descriptions.Item>
                    <Descriptions.Item label={'核销凭证'}>
                      {detail.verificationVoucherType === '1' ? '发票' : '其他'}
                    </Descriptions.Item>
                    {detail.verificationVoucherType !== '1' && (
                      <Descriptions.Item label={'附件'}>
                        {!!detail?.otherVoucherUrlList?.length && (
                          <Album
                            disabled
                            fileList={detail.otherVoucherUrlList.map(value => ({
                              uid: value,
                              url: getImg(value),
                            }))}
                          >
                            {null}
                          </Album>
                        )}
                      </Descriptions.Item>
                    )}
                  </Descriptions>

                  {detail.verificationVoucherList?.length && (
                    <DividerLine title={'发票信息'} style={{marginBottom: 20, marginTop: 20}}></DividerLine>
                  )}
                  {detail.verificationVoucherList?.length && (
                    <div style={{display: 'flex', flexWrap: 'wrap'}}>
                      {detail.verificationVoucherList?.map((item, index) => (
                        <Descriptions key={index} column={1} style={{width: '50%'}}>
                          <Descriptions.Item label={'发票金额（元）'}>{item.invoiceAmt}</Descriptions.Item>
                          <Descriptions.Item label={'发票号码'}>{item.invoiceNo}</Descriptions.Item>
                          <Descriptions.Item label={'发票'}>
                            <Album
                              disabled
                              fileList={item.invoiceUrl.split(',').map(value => ({uid: value, url: getImg(value)}))}
                            >
                              {null}
                            </Album>
                          </Descriptions.Item>
                        </Descriptions>
                      ))}
                    </div>
                  )}
                </TabPane>
              )}
              {detail.checkStatus === '1' && (
                <TabPane tab='确认详情' key={'3'}>
                  <Descriptions title={<DividerLine title={'核销确认详情'} />} column={2}>
                    <Descriptions.Item label={'确认人'}>{detail.checkUname}</Descriptions.Item>
                    <Descriptions.Item label={'核销确认时间'}>{detail.checkTime}</Descriptions.Item>
                    <Descriptions.Item label={'确认备注'}>{detail.checkNotes}</Descriptions.Item>
                  </Descriptions>
                </TabPane>
              )}
            </Tabs>
          </div>

          {/* 采购物品详情 */}
          <Modal
            centered
            destroyOnClose
            open={visible}
            title={'入仓详情'}
            footer={null}
            width={600}
            onCancel={() => setVisible(false)}
          >
            <div style={{maxHeight: 600, overflowY: 'scroll'}}>
              {detail.purchaseDetailList?.map((item, index) => (
                <div
                  key={item.warehouseId}
                  style={{marginBottom: detail.purchaseDetailList?.length !== index + 1 && 24}}
                >
                  <Descriptions column={1}>
                    <Descriptions.Item label={'入仓批次id'}>{item.batchId}</Descriptions.Item>
                    <Descriptions.Item label={'入仓操作人'}>{item.insertByName}</Descriptions.Item>
                    <Descriptions.Item label={'入仓时间'}>{item.insertTime}</Descriptions.Item>
                    <Descriptions.Item label={'站点名称'}>{item.warehouseName}</Descriptions.Item>
                    <Descriptions.Item label={'入仓原因'}>{item.entryReason}</Descriptions.Item>
                    <Descriptions.Item label={'入仓备注'}>{item.entryRemark}</Descriptions.Item>
                  </Descriptions>
                  {item.goodList.map(child => (
                    <div
                      style={{width: '100%', padding: 10, border: '1px solid #f3f2f2', marginTop: -1}}
                      key={child.materialId}
                    >
                      <div style={{color: '#000'}}>{child.name}</div>
                      <div style={{display: 'flex', paddingTop: 10}}>
                        <div style={{padding: 3, marginRight: 8, border: '1px solid #d9d9d9', borderRadius: 2}}>
                          <Image
                            preview={false}
                            src={getImg(child.attach)}
                            width={56}
                            height={50}
                            style={{
                              background: !child.attach && '#d9d9d9',
                              // backgroundImage: `url(${require('@/assets/holder.png')})`,
                              backgroundSize: '100%',
                              border: 'none',
                            }}
                          />
                        </div>
                        <div
                          style={{
                            fontSize: 12,
                            color: '#59595a',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-evenly',
                          }}
                        >
                          <div>
                            规格/品牌：
                            {(child.specification ? child.specification : '~') +
                              '/' +
                              (child.brand ? child.brand : '~')}
                          </div>
                          <div>数量/单位：{child.num + '/' + child.unit}</div>
                          <div>sku：{child.sku}</div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </Modal>

          {/* 核销通过 */}
          <Modal
            centered
            destroyOnClose
            open={passOpen}
            title={'核销通过'}
            onCancel={() => setPassOpen(false)}
            onOk={async () => {
              await stewardModel.stewardImprestVerificationApproval({
                verificationId,
                approvalStatus: '4',
              })
              queryDetail()
              message.success('操作成功！')
              setPassOpen(false)
            }}
          >
            <div>核销通过后备用金抵消、物品入库（如有），请确认？</div>
          </Modal>

          {/* 核销通过 */}
          <Modal
            centered
            destroyOnClose
            open={rejectOpen}
            title={'核销驳回'}
            afterClose={() => cancelForm.resetFields()}
            onCancel={() => setRejectOpen(false)}
            onOk={async () => {
              await cancelForm.validateFields()
              await stewardModel.stewardImprestVerificationApproval({
                ...cancelForm.getFieldsValue(),
                verificationId,
                approvalStatus: '3',
              })
              queryDetail()
              message.success('操作成功！')
              setRejectOpen(false)
            }}
          >
            <Form form={cancelForm}>
              <Form.Item name={'approvalRemark'} label='驳回原因' rules={[{required: true, message: '请输入'}]}>
                <Input.TextArea rows={4} placeholder={'请输入'} maxLength={500} showCount style={{resize: 'none'}} />
              </Form.Item>
            </Form>
          </Modal>
        </div>
      </Page>
    </div>
  )
}
