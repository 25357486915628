import React, {useEffect, useState} from 'react'
import {Button, Col, message, Popconfirm, Row, Skeleton, Space, Typography} from 'antd'
import {getImg} from '@/utils/util'
import {Breadcrumb} from '@/components/Breadcrumb'
import commonStyles from '@/assets/styles/common.module.less'
import {useAppHistory, useAppLocation} from '@/common/hooks/useRouter'
import {teamModel} from '@/store/models/Team'
import {Page} from '@/components/Page'
import BackButton from '@/components/BackButton'
import {Model} from '@redux-model/web'

const {Text} = Typography
export default function RechargeDetail() {
  const history = useAppHistory()
  const location = useAppLocation<any>()
  //充值记录详情
  const [rechargeDetail, setRechargeDetail] = useState({} as TeamBalanceChargeListVO)
  //扣款记录详情
  const [recordDetail, setRecordDetail] = useState({} as TeamWalletRecordVO)
  const {type, certName, teamName, chargeId, recordId, balance} = location.state
  const [status, setStatus] = useState(false)
  const loading = Model.isLoading(
    teamModel.teamBalanceChargeDetail.useLoading(),
    teamModel.teamBalanceRecordDetail.useLoading()
  )

  useEffect(() => {
    if (type === '1') {
      teamModel.teamBalanceChargeDetail(chargeId).then(({response: {data}}) => {
        setRechargeDetail(data)
        setStatus(data.status === '0')
      })
    } else {
      teamModel.teamBalanceRecordDetail({recordId}).then(({response: {data}}) => {
        setRecordDetail(data)
        setStatus(data.status === '0')
      })
    }
  }, [chargeId, recordId, type])

  //充值撤销
  function cancelRecharge() {
    if (type === '1') {
      teamModel.teamBalanceChargeCancel(chargeId).then(() => {
        teamModel.teamBalanceChargeDetail(chargeId).then(({response: {data}}) => {
          setRechargeDetail(data)
          setStatus(data.status === '0')
        })
      })
    } else {
      teamModel.teamBalanceRecordCancel(recordId).then(() => {
        teamModel.teamBalanceRecordDetail({recordId}).then(({response: {data}}) => {
          setRecordDetail(data)
          setStatus(data.status === '0')
        })
      })
    }
    message.success('撤销成功!')
  }

  const detailList = [
    {
      title: type === '1' ? '充值渠道：' : '扣款来源：',
      label: type === '1' ? rechargeDetail.chargeTypeName : recordDetail.recordPayTypeName,
    },
    {
      title: type === '1' ? '进账时间：' : '费用类型：',
      label: type === '1' ? rechargeDetail.chargeTime : recordDetail.recordCategoryName,
    },
    {
      title: type === '1' ? '充值金额：' : '服务单号：',
      label: type === '1' ? `${rechargeDetail.chargeAmt}元` : recordDetail.stewardOrderId,
    },
    {
      title: type === '1' ? '充值账户：' : '扣费金额：',
      label: type === '1' ? rechargeDetail.chargeAccount : `${recordDetail.recordAmt}`,
    },
    {
      title: type === '1' ? '充值后余额：' : '扣费后金额：',
      label: type === '1' ? `${rechargeDetail.walletBalance}元` : `${recordDetail.walletBalance}元`,
    },
    {
      title: type === '1' ? '充值截图：' : '扣费截图：',
      label: type === '1' ? rechargeDetail.chargeCertUrl : recordDetail.recordImgUrl,
      type: true,
    },
    {
      title: '备注信息：',
      label: type === '1' ? rechargeDetail.remark : recordDetail.remark,
    },
  ].filter(item => item.label)

  const information = [
    {
      title: '创建人：',
      label: type === '1' ? rechargeDetail.creatorName : recordDetail.creatorName,
    },
    {
      title: '撤销人：',
      label: status && type === '1' ? rechargeDetail.cancelName : recordDetail.cancelName,
    },
    {
      title: '创建时间：',
      label: type === '1' ? rechargeDetail.insertTime : recordDetail.insertTime,
    },
    {
      title: '撤销时间：',
      label: status && type === '1' ? rechargeDetail.cancelTime : recordDetail.cancelTime,
    },
    {
      title: '状态：',
      label: status ? '无效' : '有效',
    },
    {
      title: '撤销后余额：',
      label:
        status && type === '1'
          ? rechargeDetail.cancelWalletBalance
            ? `${rechargeDetail.cancelWalletBalance}元`
            : ''
          : recordDetail.cancelWalletBalance
          ? `${recordDetail.cancelWalletBalance}元`
          : '',
    },
  ].filter(item => item.label)
  return (
    <Page
      breadcrumb={
        <Breadcrumb path={['客户管理', '客户列表', '服务费账户', type === '1' ? '查看充值详情' : '查看扣款详情']} />
      }
    >
      <BackButton />
      {loading ? (
        <Skeleton active />
      ) : (
        <>
          <Row style={{padding: '20px', backgroundColor: '#fff'}}>
            <Col>
              <Text>客户姓名：</Text>
              <Text style={{color: '#212832'}}>{certName}</Text>
            </Col>
            <Col offset={1}>
              <Text>组织：</Text>
              <Text style={{color: '#212832'}}>{teamName}</Text>
            </Col>
            <Col offset={1}>
              <Text>当前余额：</Text>
              <Text style={{color: '#212832'}}>{balance}元</Text>
            </Col>
          </Row>
          <div style={{backgroundColor: 'rgba(0, 0, 0, 0.06)', height: '1px'}} />

          <div className={commonStyles.tableSection}>
            {detailList.map((item, index) => (
              <div key={`${item.label} + ${index}`}>
                {item.type ? (
                  <Row style={{marginBottom: '20px'}}>
                    <Text>{item.title}</Text>
                    <img src={getImg(item.label)} height={104} width={104} />
                  </Row>
                ) : (
                  <Row style={{marginBottom: 20}}>
                    <Col>
                      <Text>{item.title}</Text>
                      <Text>{item.label}</Text>
                    </Col>
                  </Row>
                )}
              </div>
            ))}
            <div style={{backgroundColor: 'rgba(0, 0, 0, 0.06)', height: '1px'}} />
            <div style={{width: '50%', marginTop: '20px'}}>
              <Row gutter={[0, 20]}>
                {information.map((item, index) => (
                  <Col span={status ? 12 : 24} key={`${item.label} + ${index}`}>
                    <Text>{item.title}</Text>
                    <Text>{item.label}</Text>
                  </Col>
                ))}
              </Row>
            </div>
            <div style={{textAlign: 'center', marginTop: '100px'}}>
              <Space>
                <Button onClick={() => history.goBack()}>返回</Button>
                {!status && !recordDetail.pointsRecord && (
                  <Popconfirm
                    title={`你确定撤销这笔${type === '1' ? '充值' : '扣款'}吗？`}
                    placement={'top'}
                    okText={'确定'}
                    cancelText={'取消'}
                    onConfirm={cancelRecharge}
                  >
                    <Button type={'primary'}>撤销</Button>
                  </Popconfirm>
                )}
              </Space>
            </div>
          </div>
        </>
      )}
    </Page>
  )
}
