import {Model} from '@redux-model/web'
import {$api} from '@/store/service'

export interface FinanceModel {}

class Finance extends Model<FinanceModel> {
  protected initReducer(): FinanceModel {
    return {}
  }

  // 运营报表-用户维度
  financeReportUser = $api.action((args: FinancialReportDTO) =>
    this.post<ApiResponse<FinancialReportVO[]>>('/finance-report/user').body(args)
  )

  // 运营报表-站点维度
  financeReportWarehouse = $api.action((args: FinancialReportDTO) =>
    this.post<ApiResponse<FinancialReportVO[]>>('/finance-report/warehouse').body(args)
  )

  // 运营报表-用户维度-总数
  financeReportUserTotal = $api.action((args: FinancialReportDTO) =>
    this.post<ApiResponse<FinancialReportVO>>('/finance-report/user/total').body(args)
  )

  // 运营报表-站点维度-总数
  financeReportWarehouseTotal = $api.action((args: FinancialReportDTO) =>
    this.post<ApiResponse<FinancialReportVO>>('/finance-report/warehouse/total').body(args)
  )
}

export const financeModel = new Finance()
