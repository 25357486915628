import React, {useCallback, useEffect, useImperativeHandle, useState} from 'react'
import commonStyles from '@/assets/styles/common.module.less'
import {Button, Col, DatePicker, Form, Input, Radio, Row, Select, Space, Table} from 'antd'
import moment from 'moment/moment'
import {sysModel} from '@/store/models/Sys'
import {userModel} from '@/store/models/User'
import {useCodeArraySync, useCodeSync} from '@/store/models/Common'
import {getPaginationProps} from '@/common/biz/getPaginationProps'
import {Model} from '@redux-model/web'
import {useApprovalConfigList} from '@/pages/steward/StewardApprovalList/components/StewardApproval'

const StewardMyApproval = React.forwardRef<{refresh: () => void}, {onCheck: (approvalOrderId: string) => void}>(
  ({onCheck}, ref) => {
    const approvalOrderStatusArr = useCodeArraySync('approval.order.status')?.filter(item => item.label !== '待审批')
    const approvalOrderStatus = useCodeSync('approval.order.status')

    const userId = userModel.useData(data => data.userInfo?.userId)

    const [form] = Form.useForm()
    const [approvalList, setApprovalList] = useState<ApprovalOrderDetailVO[]>([])
    const [approvalTotal, setApprovalTotal] = useState(0)

    const approvalSearch = useCallback(() => {
      sysModel
        .approvalOrderListMyApproval({...form.getFieldsValue(true), operatorId: userId})
        .then(({response: {data, total}}) => {
          setApprovalList(data)
          setApprovalTotal(total)
        })
    }, [form, userId])

    useEffect(() => {
      approvalSearch()
    }, [approvalSearch])

    useImperativeHandle(ref, () => {
      return {
        refresh: () => form.submit(),
      }
    })

    // 审批类型列表
    const approvalConfigList = useApprovalConfigList()

    const loading = Model.isLoading(sysModel.approvalOrderListMyApproval.useLoading())

    return (
      <>
        <div className={commonStyles.filterSection}>
          <Form
            form={form}
            onFinish={approvalSearch}
            onReset={approvalSearch}
            initialValues={{pageNum: 1, pageSize: 10, pageable: true, handleStatus: '0'}}
          >
            <Row>
              <Col span={24}>
                <Form.Item label={'处理状态'} name={'handleStatus'}>
                  <Radio.Group
                    onChange={({target: {value}}) => {
                      form.setFieldsValue({handleStatus: value})
                      approvalSearch()
                    }}
                  >
                    <Space>
                      <Radio.Button value={'0'}>待处理</Radio.Button>
                      <Radio.Button value={'1'}>已处理</Radio.Button>
                    </Space>
                  </Radio.Group>
                </Form.Item>
              </Col>
              <Col span={7}>
                <Form.Item label={'审批类型'} name={'configId'}>
                  <Select
                    placeholder={'请选择'}
                    options={approvalConfigList}
                    fieldNames={{label: 'configName', value: 'configId'}}
                    allowClear
                  />
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item label={'审批状态'} name={'approvalStatus'}>
                  <Select
                    options={approvalOrderStatusArr}
                    fieldNames={{value: 'id'}}
                    placeholder={'请选择'}
                    allowClear
                  />
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item label={'业务关联编号'} name={'approvalKey'}>
                  <Input placeholder={'请输入'} allowClear />
                </Form.Item>
              </Col>
              <Col span={7}>
                <Form.Item
                  label={'提交日期'}
                  name={'insertTimeBegin'}
                  getValueFromEvent={(_, formatString) => {
                    form.setFieldValue('insertTimeEnd', formatString?.[1] && formatString[1] + ' 23:59:59')
                    return formatString?.[0] && formatString?.[0] + ' 00:00:00'
                  }}
                  getValueProps={value => ({
                    value: value ? [moment(value), moment(form.getFieldValue('insertTimeEnd'))] : value,
                  })}
                >
                  <DatePicker.RangePicker />
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item label={'申请人'} name={'applyUserName'}>
                  <Input placeholder={'请输入'} allowClear />
                </Form.Item>
              </Col>
              <Col span={7} offset={1}>
                <Form.Item label={'审批内容匹配'} name={'approvalContent'}>
                  <Input placeholder={'请输入'} allowClear />
                </Form.Item>
              </Col>
            </Row>
            <Row justify={'end'}>
              <Space>
                <Button type={'primary'} htmlType={'submit'} onClick={() => form.setFieldsValue({pageNum: 1})}>
                  查询
                </Button>
                <Button htmlType={'reset'}>重置</Button>
              </Space>
            </Row>
          </Form>
        </div>

        <div className={commonStyles.tableSection}>
          <Table
            rowKey={'approvalOrderId'}
            loading={loading}
            columns={[
              {title: '审批ID', dataIndex: 'approvalOrderId'},
              {title: '审批类型', dataIndex: 'configName'},
              {title: '审批状态', dataIndex: 'approvalStatus', render: value => approvalOrderStatus[value]},
              {title: '业务关联编号', dataIndex: 'approvalKey', render: value => value ?? '/'},
              {title: '申请人', dataIndex: 'applyUserName'},
              {title: '提交时间', dataIndex: 'insertTime'},
              {
                title: '操作',
                dataIndex: 'approvalOrderId',
                render: value => (
                  <Button type={'link'} onClick={() => onCheck?.(value)}>
                    查看
                  </Button>
                ),
              },
            ]}
            dataSource={approvalList}
            pagination={getPaginationProps(form, approvalTotal)}
          />
        </div>
      </>
    )
  }
)

export default StewardMyApproval
