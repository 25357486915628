import React, {useState, useCallback, useEffect, useRef} from 'react'
import {
  Form,
  Row,
  Col,
  Input,
  Space,
  Button,
  Table,
  Drawer,
  Typography,
  Modal,
  InputNumber,
  message,
  Select,
  Tabs,
  DatePicker,
  Descriptions,
  Collapse,
} from 'antd'
import {storageModel} from '@/store/models/Storage'
import {Breadcrumb} from '@/components/Breadcrumb'
import commonStyles from '@/assets/styles/common.module.less'
import {PlusCircleTwoTone, CloseCircleTwoTone, SwapRightOutlined, UpOutlined, DownOutlined} from '@ant-design/icons'
import './index.less'
import EditSite, {EditSiteRef} from '@/pages/storage/EditSite'
import {sysModel} from '@/store/models/Sys'
import {stewardModel} from '@/store/models/Steward'
import {Permission, usePermission} from '@/components/Permission'
import {useAppHistory} from '@/common/hooks/useRouter'
import {downloadBlob} from '@/utils/util'
import {Page} from '@/components/Page'
import {useCodeArraySync, useCodeSync} from '@/store/models/Common'
import moment from 'moment'
import {debounce} from '@/utils/util'
import {getPaginationProps} from '@/common/biz/getPaginationProps'
import {useFocusEffect} from '@/common/hooks/useFocusEffect'
import {SiteContractDetail} from '@/pages/storage/SiteList/components/SiteContractDetail'

const {Option} = Select
interface StewardMaterialListVOExtension extends StewardMaterialListVO {
  actualNum?: number
}

export default function SiteList() {
  const history = useAppHistory()
  const [auth] = usePermission()
  const [activeKey, setActiveKey] = useState('1')
  const contractInfoRef = useRef(null)

  // 查询站点列表
  const [form] = Form.useForm()
  const [total, setTotal] = useState(0)
  const [dataSource, setDataSource] = useState<StewardWarehouseListItemVO[]>([])
  const loading = storageModel.stewardWarehouseList.useLoading()
  const querySiteList = useCallback(() => {
    const value = form.getFieldsValue(true)
    storageModel.stewardWarehouseList(value).then(({response: {data, total}}) => {
      setDataSource(data)
      setTotal(total)
    })
  }, [form])

  useFocusEffect(() => {
    form.submit()
  })

  useEffect(() => {
    form.submit()
  }, [form])

  // todo: 详情及编辑
  const editSiteRef = useRef<EditSiteRef>(null)
  const [siteInfo, setSiteInfo] = useState({} as StewardWarehouseVO)

  const [orgList, setOrgList] = useState<SysOrgVO[]>([])

  //todo: 设置警戒线
  const [thresholdVisible, setThresholdVisible] = useState(false)
  const [thresholdParams, setThresholdParams] = useState([] as ThresholdDTO[])
  const [num, setNum] = useState(0)

  // 查组织列表
  useEffect(function () {
    sysModel.sysOrgList({orgPid: ''}).then(({response: {data}}) => {
      setOrgList(data)
    })
  }, [])

  const queryWarehouseInfo = useCallback(function (wareId: string) {
    storageModel.stewardWarehouseInfo(wareId).then(({response: {data}}) => {
      setSiteInfo(data)
    })
  }, [])

  // 站点物品列表
  const [keyword, setKeyword] = useState('')
  const queryInventory = useCallback(function (wareId: string, keyword?: string) {
    storageModel
      .stewardWarehouseInventory({warehouseId: wareId, keyword, pageable: false})
      .then(({response: {data, total}}) => {
        setInventoryList(data)
      })
  }, [])

  // todo: 盘点相关
  const [iForm] = Form.useForm()
  const [showInventory, setShowInventory] = useState(false)
  const [inventoryList, setInventoryList] = useState<StewardMaterialListVOExtension[]>([])
  const inventoryLoading = storageModel.stewardWarehouseInventory.useLoading()
  const submitLoading = storageModel.stewardWarehouseAppCheck.useLoading()
  async function onSubmit() {
    if (inventoryList.some(item => [null, undefined].includes(item.actualNum))) {
      return message.warning('请输入实际剩余数！')
    }
    await storageModel.stewardWarehouseAppCheck({
      checkList: inventoryList.map(item => ({
        materialId: item.materialId,
        num: item.actualNum,
        warehouseMaterialId: item.warehouseMaterialId,
      })),
      warehouseId: siteInfo.warehouseId,
    })
    setShowInventory(false)
    queryInventory(siteInfo.warehouseId)
    message.success('提交成功')
  }

  // todo: 入仓相关
  const [showStorage, setShowStorage] = useState(false)
  const [materialList, setMaterialList] = useState<StewardMaterialListVO[]>([])
  const [storageForm] = Form.useForm()
  const materialLoading = storageModel.stewardWarehouseMaterialList.useLoading()

  // 查耗材列表
  const searchMaterial = useCallback(
    debounce(value => {
      if (value) {
        storageModel
          .stewardWarehouseMaterialList({pageable: false, pageNum: 1, pageSize: 100, name: value})
          .then(({response: {data}}) => {
            setMaterialList(data)
          })
      } else {
        setMaterialList([])
      }
    }),
    []
  )

  // 入仓
  async function onStorageSubmit() {
    const {materialList, batchReason, batchRemark} = storageForm.getFieldsValue(true)
    await storageModel.stewardWarehousePurchase({
      warehouseId: siteInfo.warehouseId,
      materialList,
      batchReason,
      batchRemark,
    })
    setShowStorage(false)
    queryInventory(siteInfo.warehouseId)
    message.success('提交成功')
  }

  // 站点费用 => 列表
  const [feeForm] = Form.useForm()
  const [feeList, setFeeList] = useState<StewardWarehouseFeeVO[]>([])
  const searchFee = useCallback(() => {
    const {warehouseId, ...value} = feeForm.getFieldsValue(true)
    stewardModel
      .stewardWarehouseFeeList({pageable: false, warehouseId: warehouseId || siteInfo.warehouseId, ...value})
      .then(({response: {data}}) => {
        setFeeList(data)
      })
  }, [feeForm, siteInfo.warehouseId])

  // 站点费用 =>>新增
  const warehouseFeeTypeArray = useCodeArraySync('warehouse.fee.type')
  const warehouseFeeType = useCodeSync('warehouse.fee.type')
  const [addFeeForm] = Form.useForm()
  const [addFeeVisible, setAddFeeVisible] = useState(false)
  const [feeInfo, setFeeInfo] = useState({} as StewardWarehouseFeeVO)
  const [warehouseFeeId, setWarehouseFeeId] = useState('')

  // 删除
  const [selectedRows, setSelectedRows] = useState<StewardMaterialListVOExtension[]>([])

  return (
    <Page
      breadcrumb={
        <Breadcrumb
          right={
            <Space>
              {auth('003006002') && (
                <Button type={'primary'} onClick={() => editSiteRef.current.show()}>
                  新增
                </Button>
              )}
            </Space>
          }
        />
      }
    >
      <Form form={form} onFinish={querySiteList} onReset={querySiteList} initialValues={{pageNum: 1, pageSize: 10}}>
        <div className={commonStyles.filterSection}>
          <Row>
            <Col span={7}>
              <Form.Item name={'name'} label={'站点名称'}>
                <Input allowClear placeholder={'请输入站点名称'} />
              </Form.Item>
            </Col>
            <Col span={7} offset={1}>
              <Form.Item name={'address'} label={'站点地址'}>
                <Input allowClear placeholder={'请输入站点地址'} />
              </Form.Item>
            </Col>
            <Col span={7} offset={1}>
              <Form.Item name={'managerName'} label={'管理人'}>
                <Input allowClear placeholder={'请输入管理人姓名'} />
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item name={'stewardName'} label={'站点管家'}>
                <Input allowClear placeholder={'请输入管家姓名'} />
              </Form.Item>
            </Col>
            <Col span={7} offset={1}>
              <Form.Item name={'status'} label={'状态'}>
                <Select
                  options={[
                    {label: '正常', value: '1'},
                    {label: '禁用', value: '0'},
                  ]}
                  allowClear
                  placeholder={'请选择'}
                />
              </Form.Item>
            </Col>
            <Col span={7} offset={1}>
              <Form.Item name={'orgId'} label={'人员管理组织'}>
                <Select
                  allowClear
                  showSearch
                  placeholder={'请选择'}
                  options={orgList.map(item => ({label: item.orgName, value: item.orgId, children: item.orgName}))}
                  filterOption={(inputValue, option: {children: string}) => {
                    return option.children?.includes(inputValue)
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row justify={'end'}>
            <Space>
              <Button type={'primary'} htmlType={'submit'} onClick={() => form.setFieldsValue({pageNum: 1})}>
                查询
              </Button>
              <Button htmlType={'reset'}>重置</Button>
            </Space>
          </Row>
        </div>

        <div className={commonStyles.tableSection}>
          <Table
            style={{marginBottom: 20}}
            rowKey={'warehouseId'}
            dataSource={dataSource}
            loading={loading}
            // onRow={record => {
            //   return {
            //     onClick: async () => {
            //       if (hasPermission('001006002')) {
            //         queryWarehouseInfo(record.warehouseId)
            //         queryInventory(record.warehouseId)
            //         feeForm.setFieldsValue({warehouseId: record.warehouseId})
            //         searchFee()
            //       }
            //     },
            //   }
            // }}
            scroll={{x: 'max-content'}}
            columns={[
              {title: '编号', dataIndex: 'warehouseId'},
              {title: '站点名称', dataIndex: 'name'},
              {
                title: '站点地址',
                dataIndex: 'address',
                render: (value, record) =>
                  [record.provinceName, record.cityName, record.districtName, record.bizCircleName, value]
                    .filter(Boolean)
                    .join('-'),
              },
              {title: '管理人', dataIndex: 'managerName'},
              {title: '管理人联系电话', dataIndex: 'managerMobile'},
              {
                title: '站点管家',
                dataIndex: 'stewardList',
                render: value => (value.length ? value.map(item => item.value).join(',') : '/'),
              },
              {title: '人员管理组织', dataIndex: 'orgName'},
              {title: '最新盘点时间', dataIndex: 'lastCheckTime', render: value => value},
              {title: '状态', dataIndex: 'status', render: value => (value === '1' ? '正常' : '禁用')},
              {
                title: '操作',
                fixed: 'right',
                render: (_, {warehouseId, status}) => {
                  return (
                    <Space>
                      {status === '1' && (
                        <>
                          {auth('001006002') && (
                            <Button
                              type={'link'}
                              style={{paddingLeft: 0, paddingRight: 0}}
                              onClick={async () => {
                                feeForm.setFieldsValue({warehouseId})
                                queryWarehouseInfo(warehouseId)
                                queryInventory(warehouseId)
                                searchFee()
                              }}
                            >
                              查看
                            </Button>
                          )}
                          {auth('002006002') && (
                            <Button
                              type={'link'}
                              style={{paddingLeft: 0, paddingRight: 0}}
                              onClick={() => editSiteRef.current.show(warehouseId)}
                            >
                              编辑
                            </Button>
                          )}
                        </>
                      )}
                      {auth('023006003') && (
                        <Button
                          type={'link'}
                          style={{paddingLeft: 0, paddingRight: 0}}
                          onClick={() => {
                            Modal.confirm({
                              title: '提示',
                              icon: null,
                              centered: true,
                              content: `是否确认${status === '1' ? '禁用' : '启用'}？`,
                              onOk: async () => {
                                await storageModel.stewardWarehouseEnable({
                                  warehouseId,
                                  status: status === '1' ? '0' : '1',
                                })
                                form.submit()
                              },
                            })
                          }}
                        >
                          {status === '0' ? '启用' : '禁用'}
                        </Button>
                      )}
                    </Space>
                  )
                },
              },
            ]}
            pagination={getPaginationProps(form, total)}
          />
        </div>
      </Form>

      {/* 站点详情 */}
      <Drawer
        title={siteInfo.name}
        width={900}
        placement='right'
        onClose={() => {
          setSiteInfo({} as StewardWarehouseVO)
          setActiveKey('1')
        }}
        destroyOnClose
        open={!!siteInfo.warehouseId}
        zIndex={900}
      >
        <div className='siteSection'>
          <Space className='siteHeader'>
            <Typography.Title level={3} style={{marginBottom: 0, fontSize: 14}}>
              基本信息
            </Typography.Title>
            {/*<Permission code={'002006002'}>*/}
            {/*  <Button*/}
            {/*    type={'link'}*/}
            {/*    onClick={() => {*/}
            {/*      editSiteRef.current.show(siteInfo.warehouseId)*/}
            {/*    }}*/}
            {/*  >*/}
            {/*    编辑*/}
            {/*  </Button>*/}
            {/*</Permission>*/}
          </Space>
          <Form>
            <Row>
              <Col span={12}>
                <Form.Item label={'站点地址'} name={'address'} className={'siteFormIitem'}>
                  <span>
                    {[
                      siteInfo.provinceName,
                      siteInfo.cityName,
                      siteInfo.districtName,
                      siteInfo.bizCircleName,
                      siteInfo.address,
                    ]
                      .filter(Boolean)
                      .join('-')}
                  </span>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label={'管理人'} className={'siteFormIitem'}>
                  <span>{siteInfo.managerName}</span>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label={'房源接管人'} className={'siteFormIitem'}>
                  <span>{siteInfo.stewardName || '无'}</span>
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label={'关联组织'} className={'siteFormIitem'} shouldUpdate>
                  {() => {
                    const orgIdList = siteInfo.orgIdList
                    const orgResult = orgList.filter(item => orgIdList?.includes(item.orgId))
                    return orgResult.map(item => item.orgName)?.join(',')
                  }}
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
        <Tabs
          tabBarExtraContent={
            <>
              {activeKey === '1' && (
                <Space>
                  <Permission code={'004026003'}>
                    <Button
                      type={'link'}
                      style={{padding: '0 0 0 14px'}}
                      onClick={() => {
                        if (selectedRows.length === 0) return message.warn('请选择要删除的物品！')
                        Modal.confirm({
                          title: '提示',
                          centered: true,
                          icon: null,
                          content: '是否确认删除？',
                          onOk: async () => {
                            await storageModel.stewardWarehouseMaterialDeleteBatch(
                              selectedRows.map(item => item.warehouseMaterialId)
                            )
                            message.success('删除成功！')
                            setSelectedRows([])
                            queryInventory(siteInfo.warehouseId)
                          },
                        })
                      }}
                    >
                      删除
                    </Button>
                  </Permission>
                  <Permission code={'014026001'}>
                    <Button type={'link'} style={{padding: '0 0 0 14px'}} onClick={() => setShowStorage(true)}>
                      入仓
                    </Button>
                  </Permission>
                  <Permission code={'015026001'}>
                    <Button
                      disabled={inventoryList?.length === 0}
                      type={'link'}
                      style={{padding: '0 0 0 9px'}}
                      onClick={() => setShowInventory(true)}
                    >
                      盘点
                    </Button>
                  </Permission>
                  <Permission code={'001146001'}>
                    <Button
                      type={'link'}
                      style={{padding: '0 0 0 9px'}}
                      onClick={async () => {
                        const {
                          response: {data},
                        } = await stewardModel.stewardWarehouseCheckRecord({warehouseId: siteInfo.warehouseId})
                        iForm.setFieldsValue({activeKey: data?.[0]?.list?.[0]?.batchId})
                        Modal.confirm({
                          title: '盘点记录',
                          centered: true,
                          icon: null,
                          width: '50%',
                          closable: true,
                          okCancel: false,
                          okType: 'link',
                          okText: ' ',
                          okButtonProps: {onClick: () => null, style: {cursor: 'default'}},
                          afterClose: () => iForm.resetFields(),
                          bodyStyle: {height: '600px', overflow: 'scroll'},
                          content: (
                            <Form form={iForm}>
                              <Form.Item noStyle shouldUpdate>
                                {form1 => {
                                  const activeKey = form1.getFieldValue('activeKey') ?? []
                                  return (
                                    <Collapse
                                      expandIcon={() => null}
                                      ghost
                                      activeKey={activeKey}
                                      onChange={key => {
                                        const k = typeof key === 'string' ? [key] : key
                                        form1.setFieldsValue({activeKey: k.filter(value => !activeKey.includes(value))})
                                      }}
                                    >
                                      {data?.map(item => (
                                        <Collapse.Panel
                                          key={item.batchId}
                                          header={
                                            <Space style={{marginLeft: '-24px'}}>
                                              <span style={{color: '#000'}}>{item.userName}</span>
                                              <span style={{color: 'rgba(0, 0, 0, 0.65)'}}>{item.insertTime}</span>
                                              {activeKey.includes(item.batchId) ? <UpOutlined /> : <DownOutlined />}
                                            </Space>
                                          }
                                        >
                                          <Table
                                            rowKey={'recordId'}
                                            dataSource={item.list}
                                            columns={[
                                              {title: 'SKU', dataIndex: 'materialSku'},
                                              {title: '物品名称', dataIndex: 'materialName'},
                                              {title: '规格', dataIndex: 'materialSpecification'},
                                              {title: '品牌', dataIndex: 'materialBrand'},
                                              {title: '系统剩余数量', dataIndex: 'numBefore'},
                                              {title: '实际剩余数量', dataIndex: 'numAfter'},
                                              {title: '盈亏数', dataIndex: 'numChange'},
                                            ]}
                                          />
                                        </Collapse.Panel>
                                      ))}
                                    </Collapse>
                                  )
                                }}
                              </Form.Item>
                            </Form>
                          ),
                        })
                      }}
                    >
                      盘点记录
                    </Button>
                  </Permission>
                  <Permission code={'002047002'}>
                    <Button
                      type={'link'}
                      style={{padding: '0 0 0 9px'}}
                      onClick={async () => {
                        setThresholdVisible(true)
                      }}
                    >
                      设置警戒线
                    </Button>
                  </Permission>
                  <Permission code={'015026001'}>
                    <Button
                      type={'link'}
                      style={{padding: '0 0 0 9px'}}
                      onClick={() => {
                        history.push({pathname: '/site/output', state: {warehouseId: siteInfo.warehouseId}})
                        setSiteInfo({} as StewardWarehouseVO)
                      }}
                    >
                      调拨
                    </Button>
                  </Permission>
                  <Button
                    type={'link'}
                    style={{padding: '0 0 0 9px'}}
                    onClick={() => {
                      downloadBlob({
                        url: '/steward/warehouse/inventory-excel',
                        body: {warehouseId: siteInfo.warehouseId, keyword, pageable: false},
                      })
                    }}
                  >
                    导出
                  </Button>
                </Space>
              )}
              {activeKey === '2' && (
                <Permission code={'003092002'}>
                  <Button
                    type='link'
                    onClick={() => {
                      setAddFeeVisible(true)
                    }}
                  >
                    新增费用
                  </Button>
                </Permission>
              )}
              {activeKey === '3' && (
                <Permission code={'003180002'}>
                  <Button type={'link'} onClick={() => contractInfoRef.current?.hidden()}>
                    新增合同
                  </Button>
                </Permission>
              )}
            </>
          }
          onChange={key => setActiveKey(key)}
          items={[
            {
              key: '1',
              label: '物品信息',
              children: (
                <>
                  <Row style={{marginBottom: 20}} justify={'space-between'}>
                    <Col>
                      <Input
                        value={keyword}
                        placeholder={'请输入sku/耗材名称/规格/品牌'}
                        onChange={e => setKeyword(e.target.value)}
                        style={{width: 270}}
                        allowClear
                      />
                    </Col>
                    <Col>
                      <Space>
                        <Button type={'primary'} onClick={() => queryInventory(siteInfo.warehouseId, keyword)}>
                          查询
                        </Button>
                        <Button
                          onClick={() => {
                            setKeyword('')
                            queryInventory(siteInfo.warehouseId)
                          }}
                        >
                          重置
                        </Button>
                      </Space>
                    </Col>
                  </Row>
                  <Table
                    rowKey={'sku'}
                    dataSource={inventoryList}
                    rowSelection={{
                      fixed: true,
                      selectedRowKeys: selectedRows.map(item => item.sku),
                      getCheckboxProps: record => ({disabled: record.num !== 0}),
                      onChange: (_, selectedRows) => setSelectedRows(selectedRows),
                    }}
                    columns={[
                      {title: 'SKU', dataIndex: 'sku', width: '25%'},
                      {title: '耗材名称', dataIndex: 'name', width: '25%'},
                      {
                        title: '规格/品牌',
                        width: '25%',
                        render: record =>
                          `${record.specification ?? '~'} ${(record.brand && `(${record.brand})`) ?? '/ ~'}`,
                      },
                      {
                        title: '数量/单位',
                        width: '25%',
                        render: record => `${record.num} ${record.unit && `(${record.unit})`}`,
                      },
                    ]}
                    pagination={false}
                  />
                </>
              ),
            },
            auth('001092001') && {
              key: '2',
              label: '运营费用',
              children: (
                <Form form={feeForm} onFinish={searchFee} onReset={searchFee}>
                  <Row>
                    <Col span={10}>
                      <Form.Item label={'费用名称'} name={'feeName'}>
                        <Input placeholder='请输入费用名称' allowClear />
                      </Form.Item>
                    </Col>
                    <Col span={10} offset={1}>
                      <Form.Item label={'费用名称'} name={'feeType'}>
                        <Select placeholder={'请选择'} allowClear>
                          {warehouseFeeTypeArray.map(item => (
                            <Select.Option value={item.id} key={item.id}>
                              {item.label}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col span={10}>
                      <Form.Item noStyle>
                        <Space align={'start'}>
                          <Form.Item label={'金额'} name={'feeBegin'}>
                            <Input placeholder='最小金额' allowClear />
                          </Form.Item>
                          <SwapRightOutlined />
                          <Form.Item name={'feeEnd'}>
                            <Input placeholder='最大金额' allowClear />
                          </Form.Item>
                        </Space>
                      </Form.Item>
                    </Col>
                    <Col offset={1} span={10}>
                      <Form.Item label={'日期'} name={'time'}>
                        <DatePicker.RangePicker
                          onChange={value => {
                            const [dateBegin, dateEnd] = value
                              ? value.map(item => moment(item).format('YYYY-MM-DD'))
                              : []
                            feeForm.setFieldsValue({dateBegin, dateEnd})
                          }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row justify={'end'}>
                    <Space>
                      <Button type={'primary'} htmlType={'submit'}>
                        查询
                      </Button>
                      <Button htmlType={'reset'}>重置</Button>
                    </Space>
                  </Row>
                  <Table
                    rowKey={'warehouseFeeId'}
                    style={{marginTop: 20}}
                    columns={[
                      {title: '费用名称', dataIndex: 'feeName', key: 'feeName'},
                      {title: '金额', dataIndex: 'fee', key: 'fee'},
                      {title: '类型', dataIndex: 'feeType', key: 'feeType', render: text => warehouseFeeType[text]},
                      {title: '日期', dataIndex: 'date', key: 'date'},
                      {
                        title: '操作',
                        render: record => {
                          return (
                            <Space>
                              <Permission code={'001092002'}>
                                <Button
                                  style={{paddingLeft: 0, paddingRight: 0}}
                                  type={'link'}
                                  onClick={() => setFeeInfo(record)}
                                >
                                  查看
                                </Button>
                              </Permission>
                              <Permission code={'004092002'}>
                                <Button
                                  style={{paddingLeft: 0, paddingRight: 0}}
                                  type={'link'}
                                  onClick={() => {
                                    setWarehouseFeeId(record.warehouseFeeId)
                                  }}
                                >
                                  删除
                                </Button>
                              </Permission>
                            </Space>
                          )
                        },
                      },
                    ]}
                    dataSource={feeList}
                    pagination={false}
                  />
                </Form>
              ),
            },
            auth('001180001') && {
              key: '3',
              label: '合同信息',
              children: <SiteContractDetail ref={contractInfoRef} warehouseId={siteInfo.warehouseId} />,
            },
          ]}
        />
      </Drawer>

      {/* 编辑 */}
      <EditSite ref={editSiteRef} onSuccess={() => querySiteList()} />

      {/* 盘点 */}
      <Modal
        open={showInventory}
        title={'盘点'}
        centered
        footer={null}
        width={'50%'}
        onCancel={() => setShowInventory(false)}
      >
        <Table
          rowKey={'materialId'}
          loading={inventoryLoading}
          columns={[
            {title: 'sku', dataIndex: 'sku', key: 'sku'},
            {title: '规格', dataIndex: 'specification', key: 'specification'},
            {title: '品牌', dataIndex: 'brand', key: 'brand'},
            {title: '耗材名称', dataIndex: 'name', key: 'name'},
            {title: '资费价格(元)', dataIndex: 'price', key: 'price'},
            {title: '单位', dataIndex: 'unit', key: 'unit'},
            {title: '系统剩余数量', dataIndex: 'num', key: 'num'},
            {
              title: '实际剩余数量',
              dataIndex: 'actualNum',
              key: 'actualNum',
              render: (text, record, index) => (
                <InputNumber
                  min={0}
                  value={text}
                  style={{minWidth: 150}}
                  placeholder={'请输入剩余数量'}
                  onChange={value => {
                    setInventoryList(prevState =>
                      prevState.map(item =>
                        item.materialId === record.materialId ? {...item, actualNum: +value} : item
                      )
                    )
                  }}
                />
              ),
            },
            {
              title: '盈亏数',
              render: record => {
                return ![undefined, null].includes(record.actualNum)
                  ? Number(record.actualNum) - Number(record.num)
                  : ''
              },
            },
          ]}
          dataSource={inventoryList}
          pagination={false}
        />
        <Row justify={'end'} style={{marginTop: '24px'}}>
          <Space>
            <Button onClick={() => setShowInventory(false)}>取消</Button>
            <Button type={'primary'} onClick={onSubmit} loading={submitLoading}>
              提交
            </Button>
          </Space>
        </Row>
      </Modal>

      {/* 入仓 */}
      <Modal
        open={showStorage}
        title={'入仓'}
        centered
        footer={null}
        width={'717px'}
        onCancel={() => setShowStorage(false)}
        afterClose={() => storageForm.resetFields()}
      >
        <Form
          form={storageForm}
          onFinish={onStorageSubmit}
          initialValues={{materialList: [{materialId: undefined, num: undefined}]}}
        >
          <Form.Item
            labelCol={{span: 4}}
            wrapperCol={{span: 15}}
            rules={[{required: true, message: '请选择入仓原因'}]}
            name={'batchReason'}
            label={'入仓原因'}
          >
            <Select placeholder={'请选择'} allowClear>
              <Option value={'线下采购'}>线下采购</Option>
              <Option value={'返厂维修'}>返厂维修</Option>
              <Option value={'盘点错误'}>盘点错误</Option>
            </Select>
          </Form.Item>
          <Form.Item labelCol={{span: 4}} wrapperCol={{span: 15}} name={'batchRemark'} label={'入仓备注'}>
            <Input.TextArea
              maxLength={200}
              showCount
              style={{minHeight: 95}}
              rows={3}
              placeholder={'请输入'}
              allowClear
            />
          </Form.Item>
          <Form.List name='materialList'>
            {(fields, {add, remove}) => {
              return (
                <div style={{display: 'flex', flex: 1, flexDirection: 'row'}}>
                  <Form.Item label={'耗材'} required>
                    <PlusCircleTwoTone twoToneColor='#00B7AE' onClick={() => add()} />
                  </Form.Item>
                  <div style={{flex: 1}}>
                    {fields.map((field, index) => (
                      <Row key={field.key}>
                        <Col offset={1}>
                          <div style={{marginTop: '5px'}}>{index + 1}</div>
                        </Col>
                        <Col offset={1}>
                          <Form.Item
                            {...field}
                            name={[field.name, 'materialId']}
                            rules={[{required: true, message: '名称/规格/品牌'}]}
                            style={{width: '270px'}}
                            shouldUpdate
                          >
                            <Select
                              placeholder='请输入名称/规格/品牌'
                              showSearch
                              onSearch={searchMaterial}
                              loading={materialLoading}
                              filterOption={false}
                              allowClear
                            >
                              {materialList?.map(item => (
                                <Option value={item.materialId} key={item.materialId}>
                                  {`${item.name}${item.brand ? `-${item.brand}` : ''}${
                                    item.unit ? `-${item.unit}` : ''
                                  }${item.specification ? `-${item.specification}` : ''}`}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col offset={1}>
                          <Form.Item
                            {...field}
                            name={[field.name, 'num']}
                            rules={[{required: true, message: '请输入入仓数量'}]}
                          >
                            <InputNumber style={{minWidth: 140}} placeholder='请输入入仓数量' min={1} />
                          </Form.Item>
                        </Col>
                        {!(fields.length === 1 && index === 0) && (
                          <Col>
                            <Form.Item style={{marginLeft: '12px'}}>
                              <CloseCircleTwoTone twoToneColor='#A8A7AA' onClick={() => remove(field.name)} />
                            </Form.Item>
                          </Col>
                        )}
                      </Row>
                    ))}
                  </div>
                </div>
              )
            }}
          </Form.List>

          <Row justify={'end'} style={{marginTop: '24px'}}>
            <Space>
              <Button onClick={() => setShowStorage(false)}>取消</Button>
              <Button type={'primary'} htmlType={'submit'}>
                提交
              </Button>
            </Space>
          </Row>
        </Form>
      </Modal>

      {/* 设置警戒线 */}
      <Modal
        open={thresholdVisible}
        title={
          <div style={{textAlign: 'center'}}>
            <Typography.Text style={{color: '#000000'}}>仓储警戒线设置</Typography.Text>
          </div>
        }
        width={720}
        footer={null}
        centered
        onCancel={() => setThresholdVisible(false)}
      >
        <Table
          rowKey={'sku'}
          dataSource={inventoryList}
          columns={[
            {
              title: 'SKU',
              dataIndex: 'sku',
              width: '20%',
            },
            {
              title: '耗材名称',
              dataIndex: 'name',
              width: '20%',
            },
            {
              title: '规格/品牌',
              width: '20%',
              render: record => `${record.specification ?? '~'} ${(record.brand && `(${record.brand})`) ?? '/ ~'}`,
            },
            {
              title: '数量/单位',
              width: '20%',
              render: record => `${record.num} ${record.unit && `(${record.unit})`}`,
            },
            {
              title: '设置警戒线',
              dataIndex: 'threshold',
              key: 'threshold',
              width: '20%',
              render: (text, record) => (
                <Input
                  placeholder={'请输入'}
                  defaultValue={text}
                  onBlur={() => {
                    setThresholdParams(state => [
                      ...state,
                      {warehouseMaterialId: record.warehouseMaterialId, threshold: num},
                    ])
                  }}
                  onChange={event => {
                    setNum(Number(event.target.value))
                    // setThresholdParams(state => [
                    //   ...state,
                    //   {warehouseMaterialId: record.warehouseMaterialId, threshold: num},
                    // ])
                  }}
                />
              ),
            },
          ]}
          pagination={false}
        />
        <Row justify={'end'} style={{marginTop: 30}}>
          <Space>
            <Button onClick={() => setThresholdVisible(false)}>取消</Button>
            <Button
              type={'primary'}
              onClick={async () => {
                await stewardModel.stewardWarehouseInventoryThreshold({thresholdDTOList: thresholdParams})
                setThresholdVisible(false)
                queryInventory(siteInfo.warehouseId)
                message.success('设置警戒线成功')
              }}
            >
              保存
            </Button>
          </Space>
        </Row>
      </Modal>

      {/* 新增费用 */}
      <Modal
        open={addFeeVisible}
        destroyOnClose
        onCancel={() => {
          setAddFeeVisible(false)
        }}
        onOk={async () => {
          const {date, month, ...value} = addFeeForm.getFieldsValue(true)
          await stewardModel.stewardWarehouseFeeUpsert({
            date: moment(date).format('YYYY-MM-DD'),
            month: moment(month).format('YYYY-MM'),
            warehouseId: siteInfo.warehouseId,
            ...value,
          })
          searchFee()
          setAddFeeVisible(false)
        }}
        afterClose={() => addFeeForm.resetFields()}
        title={'新增费用'}
        centered
        okText={'保存'}
      >
        <Form form={addFeeForm} labelCol={{span: 5}} wrapperCol={{span: 15}}>
          <Form.Item label={'费用名称'} name={'feeName'} rules={[{required: true, message: '请输入费用名称'}]}>
            <Input allowClear placeholder='请输入' />
          </Form.Item>
          <Form.Item label={'金额'} name={'fee'} rules={[{required: true, message: '请输入金额'}]}>
            <InputNumber placeholder='请输入' style={{width: '100%'}} />
          </Form.Item>
          <Form.Item label={'费用类型'} name={'feeType'} rules={[{required: true, message: '请输入费用类型'}]}>
            <Select allowClear placeholder={'请选择'}>
              {warehouseFeeTypeArray.map(item => (
                <Select.Option key={item.id} value={item.id}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label={'日期'} name={'date'} rules={[{required: true, message: '请选择日期'}]}>
            <DatePicker />
          </Form.Item>
          <Form.Item label={'所属月份'} name={'month'} rules={[{required: true, message: '请选择所属月份'}]}>
            <DatePicker picker={'month'} />
          </Form.Item>
        </Form>
      </Modal>

      {/* 费用详情 */}
      <Modal
        open={!!feeInfo.warehouseFeeId}
        title={'费用详情'}
        centered
        footer={null}
        onCancel={() => {
          setFeeInfo({} as StewardWarehouseFeeVO)
        }}
      >
        <Descriptions column={1}>
          <Descriptions.Item label={'费用名称'}>{feeInfo.feeName}</Descriptions.Item>
          <Descriptions.Item label={'金额'}>{feeInfo.fee}</Descriptions.Item>
          <Descriptions.Item label={'类型'}>{warehouseFeeType[feeInfo.feeType]}</Descriptions.Item>
          <Descriptions.Item label={'日期'}>{feeInfo.date}</Descriptions.Item>
          <Descriptions.Item label={'所属月份'}>{feeInfo.month}</Descriptions.Item>
        </Descriptions>
      </Modal>

      {/* 删除费用 */}
      <Modal
        title={'提示'}
        open={!!warehouseFeeId}
        centered
        onOk={async () => {
          await stewardModel.stewardWarehouseFeeDelete({warehouseFeeId})
          searchFee()
          setWarehouseFeeId('')
        }}
        onCancel={() => {
          setWarehouseFeeId('')
        }}
      >
        <span>是否确认删除此条费用？</span>
      </Modal>
    </Page>
  )
}
