import React from 'react'
import {Modal} from 'antd'
import {getImg} from '@/utils/util'
import {PlayCircleFilled} from '@ant-design/icons'

const CleanVideoPreview = React.memo(({videoList}: {videoList: {imgId: string; imgUrl: string}[]}) => {
  return (
    <>
      {videoList?.map(item => (
        <div
          key={item.imgId}
          style={{
            width: '88px',
            height: '88px',
            lineHeight: '88px',
            textAlign: 'center',
            margin: '0 10px 10px 0',
            backgroundColor: '#ccc',
            color: '#fff',
            cursor: 'pointer',
            position: 'relative',
          }}
          onClick={() =>
            Modal.confirm({
              title: '视频预览',
              icon: null,
              closable: true,
              centered: true,
              content: <video controls src={getImg(item.imgUrl)} width='100%' />,
            })
          }
        >
          <video controls={false} src={getImg(item.imgUrl)} width={'100%'} height={'100%'} />
          <PlayCircleFilled
            style={{
              position: 'absolute',
              left: '30px',
              top: '30px',
              fontSize: '28px',
              color: 'white',
            }}
          />
        </div>
      ))}
    </>
  )
})

export default CleanVideoPreview
