import React, {useCallback, useEffect, useState} from 'react'
import {Page} from '@/components/Page'
import {Breadcrumb} from '@/components/Breadcrumb'
import commonStyles from '@/assets/styles/common.module.less'
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Row,
  Select,
  Space,
  Table,
} from 'antd'
import {getPaginationProps} from '@/common/biz/getPaginationProps'
import {stewardModel} from '@/store/models/Steward'
import {useCodeArraySync, useCodeSync} from '@/store/models/Common'
import {StewardOrderVO} from '@/pages/steward/ServiceOrderList'
import {zzajModel} from '@/store/models/Zzaj'
import {usePermission} from '@/components/Permission'
import {downloadBlob} from '@/utils/util'
import {useAppHistory} from '@/common/hooks/useRouter'
import {sysUserModel} from '@/store/models/SysUser'
import {FORMAT_DATE_END, FORMAT_DATE_START} from '@/common/date'
import {sysModel} from '@/store/models/Sys'
import {InfoCircleFilled} from '@ant-design/icons'

export default function AnJuServiceList() {
  const history = useAppHistory()
  const [form] = Form.useForm()
  const [list, setList] = useState<StewardOrderVO[]>([])
  const [total, setTotal] = useState(0)
  const loading = stewardModel.stewardAdminOrderList.useLoading()
  const orderStatus = useCodeSync('steward.orderStatus')
  const orderStatusArr = useCodeArraySync('steward.orderStatus')
  const [ruleOpen, setRuleOpen] = useState(false)
  const [ruleForm] = Form.useForm()
  const [auth] = usePermission()

  const [selectedRows, setSelectedRows] = useState<StewardOrderQueryVO[]>([])
  const [show, contextHolder] = useDispatchModel()

  const search = useCallback(() => {
    const values = form.getFieldsValue(true)
    stewardModel.stewardAdminOrderList(values).then(({response: {data, total}}) => {
      setList(
        data.map(item => {
          return {
            ...item,
            orderMaker: item.orderMakerType === 'lessor' ? item.lessorName : item.lesseeName,
            orderMakerMobile: item.orderMakerType === 'lessor' ? item.lessorMobile : item.lesseeMobile,
          }
        })
      )
      setTotal(total)
    })
  }, [form])

  useEffect(() => {
    search()
  }, [search])

  return (
    <Page
      breadcrumb={
        <Breadcrumb
          right={
            <Space>
              {auth('007177018') && (
                <Button
                  type={'primary'}
                  onClick={() => {
                    const values = form.getFieldsValue(true)
                    downloadBlob({url: '/steward/admin/order/export', body: {...values, pageable: false}})
                  }}
                >
                  导出
                </Button>
              )}
              {auth('006177019') && (
                <Button
                  type={'primary'}
                  onClick={async () => {
                    if (selectedRows.length === 0) return message.error('请选择服务单')
                    if (selectedRows.some(item => ['5', '6', '7', '99'].includes(item.orderStatus)))
                      return message.error('仅待沟通、已沟通、上门中、服务中的服务单支持重新分配，请重新选择')
                    if (selectedRows.some(item => item.connectionCheckStatus === '1'))
                      return message.error('所选服务单中存在待处理的电联核实申请，请检查后重新分配')
                    if (selectedRows.some(item => !!item.cancelAuditId))
                      return message.error('不可重新分配待取消服务单')
                    await show(selectedRows)
                    setSelectedRows([])
                    search()
                    // message.success('重新分配成功！')
                  }}
                >
                  重新分配
                </Button>
              )}
              {auth('002177006') && (
                <Button
                  type={'primary'}
                  onClick={async () => {
                    const {
                      response: {data},
                    } = await zzajModel.zzajConfigGet({})
                    ruleForm.setFieldsValue(data)
                    setRuleOpen(true)
                  }}
                >
                  服务规则设置
                </Button>
              )}
            </Space>
          }
        />
      }
    >
      <div className={commonStyles.filterSection}>
        <Form
          form={form}
          initialValues={{pageNum: 1, pageSize: 10, pageable: true, appId: 'ZZAJ'}}
          onFinish={search}
          onReset={search}
        >
          <Row>
            <Col span={7}>
              <Form.Item label={'订单编号'} name={'orderId'}>
                <Input placeholder={'请输入'} allowClear />
              </Form.Item>
            </Col>
            <Col span={7} offset={1}>
              <Form.Item label={'下单人'} name={'orderMakerName'}>
                <Input placeholder={'请输入'} allowClear />
              </Form.Item>
            </Col>
            <Col span={7} offset={1}>
              <Form.Item label={'服务联系手机号'} name={'contactMobile'}>
                <Input placeholder={'请输入'} allowClear />
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item label={'服务项目'} name={'categorySearch'}>
                <Input placeholder={'请输入'} allowClear />
              </Form.Item>
            </Col>
            <Col span={7} offset={1}>
              <Form.Item label={'服务房源'} name={'propertyAddr'}>
                <Input placeholder={'请输入'} allowClear />
              </Form.Item>
            </Col>
            <Col span={7} offset={1}>
              <Form.Item label={'下单日期'} name={'_timeMake'}>
                <DatePicker.RangePicker
                  onChange={value => {
                    form.setFieldsValue({
                      timeMakeBegin: value?.[0]?.format?.(FORMAT_DATE_START),
                      timeMakeEnd: value?.[1]?.format?.(FORMAT_DATE_END),
                    })
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item label={'完成日期'} name={'_timeFinished'}>
                <DatePicker.RangePicker
                  onChange={value => {
                    form.setFieldsValue({
                      timeFinishedBegin: value?.[0]?.format?.(FORMAT_DATE_START),
                      timeFinishedEnd: value?.[1]?.format?.(FORMAT_DATE_END),
                    })
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={7} offset={1}>
              <Form.Item label={'当前服务人员'} name={'cleanerName'}>
                <Input placeholder={'请输入'} allowClear />
              </Form.Item>
            </Col>
            <Col span={7} offset={1}>
              <Form.Item label={'订单状态'} name={'orderStatusList'}>
                <Select
                  options={orderStatusArr.filter(item => !['96', '99'].includes(item.id))}
                  fieldNames={{value: 'id'}}
                  placeholder={'请选择'}
                  allowClear
                  mode={'multiple'}
                  maxTagCount={3}
                />
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item label={'服务核验'} name={'verifyStatus'}>
                <Select
                  options={[
                    {label: '无需核验', value: '1'},
                    {label: '待核验', value: '2'},
                    {label: '已核验', value: '3'},
                  ]}
                  placeholder={'请选择'}
                  allowClear
                />
              </Form.Item>
            </Col>
            <Col span={7} offset={1}>
              <Form.Item label={'支付状态'} name={'payStatusZzaj'}>
                <Select
                  options={[
                    {label: '待支付', value: 0},
                    {label: '已支付', value: 1},
                  ]}
                  placeholder={'请选择'}
                  allowClear
                />
              </Form.Item>
            </Col>
          </Row>
          <Row justify={'end'}>
            <Space>
              <Button htmlType={'submit'} type={'primary'} onClick={() => form.setFieldsValue({pageNum: 1})}>
                查询
              </Button>
              <Button htmlType={'reset'}>重置</Button>
            </Space>
          </Row>
        </Form>
      </div>
      <div className={commonStyles.filterSection}>
        <Table
          rowKey={'orderId'}
          loading={loading}
          dataSource={list}
          scroll={{x: 'max-content'}}
          rowSelection={{
            selectedRowKeys: selectedRows.map(item => item.orderId),
            onChange(_, selectedRows) {
              setSelectedRows(selectedRows)
            },
          }}
          columns={[
            {title: '订单编号', dataIndex: 'orderId'},
            {title: '订单状态', dataIndex: 'orderStatus', render: value => orderStatus[value]},
            {
              title: '支付状态',
              dataIndex: 'payStatusZzaj',
              render: value => {
                return [0, 1].includes(value) ? {0: '待支付', 1: '已支付'}[value] : '/'
              },
            },
            {
              title: '下单人',
              dataIndex: 'orderMakerName',
              render: (value, record) => (
                <Space direction={'vertical'} size={0}>
                  <span>{value ?? '/'}</span>
                  <span>{record.orderMakerMobile}</span>
                </Space>
              ),
            },
            {
              title: '服务项目',
              render: (_, {categoryFirName, categorySecName, categoryThiName}) => (
                <span>{`${categoryFirName}-${categorySecName}-${categoryThiName}`}</span>
              ),
            },
            {title: '下单时间', dataIndex: 'timeMake'},
            {title: '最新约定上门时间', dataIndex: 'timeAppoint', render: value => value ?? '/'},
            {title: '完成时间', dataIndex: 'timeFinished', render: value => value ?? '/'},
            {title: '服务房源', dataIndex: 'propertyAddr'},
            {title: '管家', dataIndex: 'stewardName'},
            {title: '当前服务人员', dataIndex: 'cleanerName', render: value => value ?? '/'},
            {
              title: '操作',
              fixed: 'right',
              render: (_, {orderId}) => {
                return (
                  auth('001177018') && (
                    <Button
                      type={'link'}
                      onClick={() =>
                        history.push({
                          pathname: '/steward/detail',
                          state: {orderId},
                        })
                      }
                    >
                      查看
                    </Button>
                  )
                )
              },
            },
          ]}
          pagination={getPaginationProps(form, total)}
        />
      </div>

      {/* 服务规则设置 */}
      <Modal
        open={ruleOpen}
        title={'服务规则设置'}
        onCancel={() => setRuleOpen(false)}
        afterClose={() => ruleForm.resetFields()}
        destroyOnClose
        centered
        width={750}
        onOk={async () => {
          await ruleForm.validateFields()
          await zzajModel.zzajConfigSave(ruleForm.getFieldsValue(true))
          message.success('设置成功！')
          setRuleOpen(false)
        }}
      >
        <Form labelCol={{flex: '100px'}} form={ruleForm}>
          <Form.Item label={'服务响应'} required shouldUpdate style={{marginBottom: 0}}>
            {() => {
              return (
                <Space>
                  <Form.Item
                    name={'serviceRespEnable'}
                    getValueFromEvent={({target: {checked}}) => checked}
                    getValueProps={value => ({checked: value})}
                  >
                    <Checkbox />
                  </Form.Item>
                  <Form.Item>非牛油果管家超过</Form.Item>
                  <Form.Item
                    name={'serviceResp1'}
                    getValueFromEvent={value => (value ? value * 3600 : 0)}
                    getValueProps={value => ({value: value ? value / 3600 : 0})}
                    rules={[{required: true, message: '请输入'}]}
                  >
                    <InputNumber />
                  </Form.Item>
                  <Form.Item>小时+</Form.Item>
                  <Form.Item
                    name={'serviceResp2'}
                    getValueFromEvent={value => (value ? value * 60 : 0)}
                    getValueProps={value => ({value: value ? value / 60 : 0})}
                    rules={[{required: true, message: '请输入'}]}
                  >
                    <InputNumber min={0} max={60} />
                  </Form.Item>
                  <Form.Item>分钟未接单自动转到牛油果管家</Form.Item>
                </Space>
              )
            }}
          </Form.Item>
          <Form.Item label={'服务实施'} shouldUpdate style={{marginBottom: 0}}>
            {() => {
              return (
                <Space>
                  <Form.Item
                    name={'serviceImplEnable'}
                    getValueFromEvent={({target: {checked}}) => checked}
                    getValueProps={value => ({checked: value})}
                  >
                    <Checkbox />
                  </Form.Item>
                  <Form.Item>非牛油果管家超过约定上门时间</Form.Item>
                  <Form.Item
                    name={'serviceImpl'}
                    getValueFromEvent={value => (value ? value * 60 : 0)}
                    getValueProps={value => ({value: value ? value / 60 : 0})}
                    rules={[{required: true, message: '请输入'}]}
                  >
                    <InputNumber min={0} />
                  </Form.Item>
                  <Form.Item>分钟之后未开始服务自动转到牛油果管家</Form.Item>
                </Space>
              )
            }}
          </Form.Item>
          <Form.Item label={'服务验收'} shouldUpdate required style={{marginBottom: 0}}>
            {() => {
              return (
                <Space>
                  <Form.Item
                    name={'serviceAcceptEnable'}
                    getValueFromEvent={({target: {checked}}) => checked}
                    getValueProps={value => ({checked: value})}
                  >
                    <Checkbox />
                  </Form.Item>
                  <Form.Item>服务单超时</Form.Item>
                  <Form.Item
                    name={'serviceAccept'}
                    getValueFromEvent={value => (value ? value * 3600 : 0)}
                    getValueProps={value => ({value: value ? value / 3600 : 0})}
                    rules={[{required: true, message: '请输入'}]}
                  >
                    <InputNumber />
                  </Form.Item>
                  <Form.Item>小时未验收自动转到已验收</Form.Item>
                </Space>
              )
            }}
          </Form.Item>
        </Form>
      </Modal>

      {contextHolder}
    </Page>
  )
}

// 重新分配
export const useDispatchModel = () => {
  const [model, contextHolder] = Modal.useModal()
  const stewardOrderReassignReasonCode = useCodeSync('steward.order.reassignReason')
  const stewardOrderReassignReasonArr = useCodeArraySync('steward.order.reassignReason')

  const [form] = Form.useForm()
  const show = useCallback(
    async (ids: StewardOrderQueryVO[]) => {
      form.resetFields()
      const {
        response: {data: userList},
      } = await sysUserModel.sysUserList({roleId: 'steward,cleaner', status: '1'})
      return new Promise<void>((resolve, reject) => {
        model.confirm({
          title: '重新分配',
          icon: null,
          centered: true,
          onOk: async () => {
            await form.validateFields()
            const formValues = form.getFieldsValue(true)
            for (const item of ids) {
              const {
                response: {data},
              } = await sysModel.approvalOrderBeforeCheckTransferOrder({
                orderId: item.orderId,
                stewardId: formValues.stewardId,
              })

              if (data) {
                stewardModel.approvalOrderInsert({
                  approvalConfigId: '2',
                  approvalTransferOrderDTO: {
                    appointTime: item.timeAppoint,
                    lat: item.propertyLat,
                    lng: item.propertyLng,
                    orderId: item.orderId,
                    originalStewardId: item.stewardId,
                    originalStewardName: item.stewardName,
                    takeOverStewardId: formValues.stewardId,
                    takeOverStewardName: formValues._stewardName,
                    transferReason:
                      formValues.dispatchReason === '99'
                        ? formValues.otherReason
                        : stewardOrderReassignReasonCode[formValues.dispatchReason],
                    transferType: '1',
                  },
                } as ApprovalOrderInsertDTO)
                message.success('提交审批成功！')
              } else {
                await stewardModel.stewardServiceDispatch({
                  orderId: item.orderId,
                  stewardId: formValues.stewardId,
                  dispatchReason:
                    formValues.dispatchReason === '99'
                      ? formValues.otherReason
                      : stewardOrderReassignReasonCode[formValues.dispatchReason],
                })
                message.success('重新分配成功！')
              }
            }
            resolve()
          },
          onCancel: () => {
            reject()
          },
          width: 500,
          content: (
            <Form form={form} labelCol={{span: 6}}>
              <Row>
                <Col span={20}>
                  <Form.Item>
                    <Space>
                      <InfoCircleFilled />
                      <span>服务单转给不同站点的管家需要审批</span>
                    </Space>
                  </Form.Item>
                  <Form.Item
                    name={'stewardId'}
                    label={'服务人员'}
                    rules={[{required: true, message: '请选择服务人员'}]}
                  >
                    <Select
                      placeholder={'请选择'}
                      showSearch
                      filterOption={(inputValue, option) => {
                        return option?.label?.indexOf(inputValue as any) > -1
                      }}
                      allowClear
                      options={userList.map(item => ({label: `${item.name}（${item.mobile}）`, value: item.userId}))}
                    />
                  </Form.Item>
                </Col>
                <Col span={20}>
                  <Form.Item
                    name={'dispatchReason'}
                    label={'分配原因'}
                    rules={[{required: true, message: '请选择分配原因'}]}
                  >
                    <Select
                      placeholder={'请选择'}
                      allowClear
                      fieldNames={{value: 'id'}}
                      options={stewardOrderReassignReasonArr}
                    />
                  </Form.Item>
                </Col>
                <Col span={20}>
                  <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, nextValues) => prevValues.dispatchReason !== nextValues.dispatchReason}
                  >
                    {form1 => {
                      const dispatchReason = form1.getFieldValue('dispatchReason')
                      return dispatchReason === '99' ? (
                        <Form.Item
                          name={'otherReason'}
                          label={' '}
                          colon={false}
                          required={false}
                          rules={[{required: true, message: '请输入分配原因'}]}
                        >
                          <Input.TextArea placeholder={'请输入分配原因'} allowClear maxLength={200} showCount />
                        </Form.Item>
                      ) : null
                    }}
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          ),
        })
      })
    },
    [form, model, stewardOrderReassignReasonArr, stewardOrderReassignReasonCode]
  )

  return [show, contextHolder] as const
}
