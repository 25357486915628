import React from 'react'
import './LoginPage.less'
import {Button, Form, Input} from 'antd'
import {userModel} from '@/store/models/User'
import {useHistory} from 'react-router'
import loginCover from '@/assets/login_cover.png'
import loginLogo from '@/assets/login_logo.svg'
import {Model} from '@redux-model/web'

export default function LoginPage() {
  const history = useHistory()

  const onFinish = async values => {
    await userModel.login(values)
    await userModel.queryUserInfo()
    history.replace('/')
  }

  const loading = Model.isLoading(userModel.login.useLoading(), userModel.queryUserInfo.useLoading())

  return (
    <section className={'container'}>
      <div className={'content'}>
        <img className={'logo'} alt={'logo'} src={loginLogo} />
        <img className={'cover'} alt={'cover'} src={loginCover} />
        <div className={'form'}>
          <p className={'text-top'}>欢迎使用</p>
          <p className={'text-bottom'}>牛油果管理平台</p>
          <Form className={'form'} onFinish={onFinish} layout={'vertical'}>
            <Form.Item label={'账号'} name={'username'} rules={[{required: true, message: '请输入账号'}]}>
              <Input className={'input'} placeholder={'请输入账号'} allowClear />
            </Form.Item>
            <Form.Item label={'密码'} name={'password'} rules={[{required: true, message: '请输入密码'}]}>
              <Input className={'input'} type={'password'} allowClear placeholder={'请输入密码'} />
            </Form.Item>
            <Form.Item>
              <Button className={'btn'} type={'primary'} htmlType={'submit'} loading={loading}>
                登录
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </section>
  )
}
