import React, {useLayoutEffect} from 'react'
import {Page} from '@/components/Page'
import {Button, Form, Space, Table, Tabs, Tag} from 'antd'
import commonStyles from '@/assets/styles/common.module.less'
import {getPaginationProps} from '@/common/biz/getPaginationProps'
import {useCodeArraySync, useCodeSync} from '@/store/models/Common'
import {useCleanOrderList} from '@/pages/cleanManagement/hooks/useCleanOrderList'
import {cleanServiceArr} from '@/utils/clean'
import CleanFilterComponent from '@/pages/cleanManagement/components/CleanFilterComponent'
import {useAppHistory} from '@/common/hooks/useRouter'

export default function CleanServiceDeductConfirm() {
  const history = useAppHistory()
  const cleanOrderTypeArr = useCodeArraySync('clean.order.type')
  const cleanOrderTimeStatusCode = useCodeSync('clean.order.timeoutStatus')
  const cleanOrderTimeoutStatusArr = useCodeArraySync('clean.order.timeoutStatus', {defaultItem: true})

  const [form1] = Form.useForm()
  const {
    dataList: dataList1,
    dataTotal: dataTotal1,
    queryCleanOrderList: queryCleanOrderList1,
    orderListLoading: orderListLoading1,
  } = useCleanOrderList(form1)

  const [form2] = Form.useForm()

  useLayoutEffect(() => {
    form2.setFieldsValue({
      pageNum: 1,
      pageSize: 10,
      pageable: true,
      orderStatus: '4',
      sortType: 14,
      amtCheckStatus: 1,
    })
  }, [form2])

  const {
    dataList: dataList2,
    dataTotal: dataTotal2,
    queryCleanOrderList: queryCleanOrderList2,
    orderListLoading: orderListLoading2,
  } = useCleanOrderList(form2)

  return (
    <Page>
      <Tabs className={commonStyles.tabs}>
        <Tabs.TabPane tab={'待处理'} key={'1'}>
          <div className={commonStyles.filterSection}>
            <CleanFilterComponent
              form={{
                form: form1,
                labelCol: {span: '80px'},
                labelAlign: 'left',
                initialValues: {
                  pageNum: 1,
                  pageSize: 10,
                  pageable: true,
                  orderStatus: '4',
                  sortType: 12,
                  amtCheckStatus: 0,
                },
                onFinish: queryCleanOrderList1,
                onReset: queryCleanOrderList1,
              }}
              filter={[
                {type: 'input', name: 'orderId', label: '服务单ID', placeholder: '请输入'},
                {type: 'input', name: 'propertyAddr', label: '房源', placeholder: '请输入'},
                {
                  type: 'select',
                  name: 'categoryThi',
                  label: '服务项目',
                  placeholder: '请选择',
                  options: cleanServiceArr,
                },
                {type: 'input', name: 'orderMakerName', label: '下单人', placeholder: '请输入'},
                {type: 'input', name: 'contactMobile', label: '服务联系手机号', placeholder: '请输入'},
                {
                  type: 'range',
                  name: 'qaOnlineTime',
                  label: '线上质检完成时间',
                  onChange: (_, dateString) => {
                    form1.setFieldsValue({
                      qaOnlineTimeBegin: dateString[0] ? dateString[0] + ' 00:00:00' : '',
                      qaOnlineTimeEnd: dateString[1] ? dateString[1] + ' 23:59:59' : '',
                    })
                  },
                },
                {type: 'input', name: 'customerName', label: '房东', placeholder: '请输入'},
                {
                  type: 'select',
                  name: 'orderType',
                  label: '工单属性',
                  placeholder: '请选择',
                  options: cleanOrderTypeArr,
                },
                {
                  type: 'select',
                  name: 'timeoutStatus',
                  label: '是否超时',
                  placeholder: '请选择',
                  options: cleanOrderTimeoutStatusArr,
                },
              ]}
            />
          </div>
          <div className={commonStyles.tableSection}>
            <Table
              rowKey={'orderId'}
              loading={orderListLoading1}
              dataSource={dataList1}
              columns={[
                {title: '服务单ID', dataIndex: 'orderId'},
                {
                  title: '服务项目',
                  dataIndex: 'categoryFirName',
                  render: (value, record) => {
                    return (
                      <Space>
                        <span>
                          {[value, record.categorySecName, record.categoryThiName].filter(item => item).join('-')}
                        </span>
                        {!!record.orderIdOrigin && <Tag color={'blue'}>返</Tag>}
                      </Space>
                    )
                  },
                },
                {
                  title: '是否超时',
                  dataIndex: 'timeoutStatus',
                  render: value => cleanOrderTimeStatusCode[value],
                },
                {title: '下单人', dataIndex: 'orderMakerName'},
                {title: '服务联系手机号', dataIndex: 'contactMobile'},
                {
                  title: '线上质检完成时间',
                  dataIndex: 'qaOnlineTime',
                  sorter: {
                    compare: (a, b) => new Date(a.qaOnlineTime).getTime() - new Date(b.qaOnlineTime).getTime(),
                    multiple: 1,
                  },
                },
                {title: '房东', dataIndex: 'customerName'},
                {title: '房源', dataIndex: 'propertyAddr'},
                {
                  title: '操作',
                  fixed: 'right',
                  render: record => (
                    <Space>
                      <Button
                        type={'link'}
                        style={{padding: 0}}
                        onClick={() => history.push('/clean-detail', {orderId: record.orderId, entry: '4'})}
                      >
                        查看
                      </Button>
                    </Space>
                  ),
                },
              ]}
              scroll={{x: 'max-content'}}
              pagination={getPaginationProps(form1, dataTotal1)}
            />
          </div>
        </Tabs.TabPane>
        <Tabs.TabPane tab={'已处理'} key={'2'}>
          <div className={commonStyles.filterSection}>
            <CleanFilterComponent
              form={{
                form: form2,
                labelCol: {span: '80px'},
                labelAlign: 'left',
                initialValues: {
                  pageNum: 1,
                  pageSize: 10,
                  pageable: true,
                  orderStatus: '4',
                  sortType: 14,
                  amtCheckStatus: 1,
                },
                onFinish: queryCleanOrderList2,
                onReset: queryCleanOrderList2,
              }}
              filter={[
                {type: 'input', name: 'orderId', label: '服务单ID', placeholder: '请输入'},
                {type: 'input', name: 'propertyAddr', label: '房源', placeholder: '请输入'},
                {
                  type: 'select',
                  name: 'categoryThi',
                  label: '服务项目',
                  placeholder: '请选择',
                  options: cleanServiceArr,
                },
                {type: 'input', name: 'orderMakerName', label: '下单人', placeholder: '请输入'},
                {type: 'input', name: 'contactMobile', label: '服务联系手机号', placeholder: '请输入'},
                {
                  type: 'range',
                  name: 'amtCheckTime',
                  label: '确认时间',
                  onChange: (_, dateString) => {
                    form2.setFieldsValue({
                      amtCheckTimeBegin: dateString[0] ? dateString[0] + ' 00:00:00' : '',
                      amtCheckTimeEnd: dateString[1] ? dateString[1] + ' 23:59:59' : '',
                    })
                  },
                },
                {type: 'input', name: 'customerName', label: '房东', placeholder: '请输入'},
                {
                  type: 'select',
                  name: 'orderType',
                  label: '工单属性',
                  placeholder: '请选择',
                  options: cleanOrderTypeArr,
                },
                {
                  type: 'select',
                  name: 'timeoutStatus',
                  label: '是否超时',
                  placeholder: '请选择',
                  options: cleanOrderTimeoutStatusArr,
                },
              ]}
            />
          </div>
          <div className={commonStyles.tableSection}>
            <Table
              rowKey={'orderId'}
              loading={orderListLoading2}
              dataSource={dataList2}
              columns={[
                {title: '服务单ID', dataIndex: 'orderId'},
                {
                  title: '服务项目',
                  dataIndex: 'categoryFirName',
                  render: (value, record) => {
                    return (
                      <Space>
                        <span>
                          {[value, record.categorySecName, record.categoryThiName].filter(item => item).join('-')}
                        </span>
                        {!!record.orderIdOrigin && <Tag color={'blue'}>返</Tag>}
                      </Space>
                    )
                  },
                },
                {
                  title: '是否超时',
                  dataIndex: 'timeoutStatus',
                  render: value => cleanOrderTimeStatusCode[value],
                },
                {title: '下单人', dataIndex: 'orderMakerName'},
                {title: '服务联系手机号', dataIndex: 'contactMobile'},
                {
                  title: '确认时间',
                  dataIndex: 'amtCheckTime',
                  sorter: {
                    compare: (a, b) => new Date(a.amtCheckTime).getTime() - new Date(b.amtCheckTime).getTime(),
                    multiple: 1,
                  },
                },
                {title: '房东', dataIndex: 'customerName'},
                {title: '房源', dataIndex: 'propertyAddr'},
                {
                  title: '操作',
                  fixed: 'right',
                  render: record => (
                    <Space>
                      <Button
                        type={'link'}
                        style={{padding: 0}}
                        onClick={() => history.push('/clean-detail', {orderId: record.orderId, entry: '5'})}
                      >
                        查看
                      </Button>
                    </Space>
                  ),
                },
              ]}
              scroll={{x: 'max-content'}}
              pagination={getPaginationProps(form2, dataTotal2)}
            />
          </div>
        </Tabs.TabPane>
      </Tabs>
    </Page>
  )
}
