import React, {useCallback, useEffect, useState} from 'react'
import {Button, Col, Modal, Row, Input, Switch, message, Typography, Space, Skeleton, PageHeader, Timeline} from 'antd'
import {getImg} from '@/utils/util'
import {feedbackModel} from '@/store/models/FeedBack'
import commonStyles from '@/assets/styles/common.module.less'
import styles from './index.module.less'
import DividerLine from '@/pages/property/component/DividerLine'
import {useCodeSync} from '@/store/models/Common'
import {useAppHistory, useAppLocation} from '@/common/hooks/useRouter'
import {Page} from '@/components/Page'

import '../../property/index.less'
import {primary} from '@/assets/styles/vars.less'

export default function FeedBackDetail() {
  const history = useAppHistory()
  const location = useAppLocation<'/feedback/detail'>()

  // 反馈id
  const feedbackId = location.state?.feedbackId

  // 反馈类型
  const feedbackTypeCode = useCodeSync('feedback')
  const feedbackModeCode = useCodeSync('feedback.mode')

  // 详情数据
  const [detailData, setDetailData] = useState({} as FeedBackVO)
  // 选中的图片
  const [selectImg, setSelectImg] = useState<{imgUrl: string; visible: boolean}>({imgUrl: '', visible: false})
  // 是否处理完成
  const [resolve, setResolve] = useState(false)
  // 处理结果
  const [respContent, setRespContent] = useState('')
  // 补充信息
  const [respExtra, setRespExtra] = useState('')

  const loading = feedbackModel.feedbackHandle.useLoading()
  const toLoading = feedbackModel.feedbackDetail.useLoading()
  const appendLoading = feedbackModel.feedbackAppend.useLoading()

  const isFinish = detailData.operationStatus === '1'

  const init = useCallback(async () => {
    const {
      response: {data},
    } = await feedbackModel.feedbackDetail({feedbackId})
    setDetailData(() => ({...data, feedbackImg: JSON.parse(data.feedbackImg as string)}))

    setRespContent(data.respContent)

    setResolve(() => data.operationStatus === '1')
  }, [feedbackId])

  useEffect(() => {
    init()
  }, [init])

  async function onSubmit() {
    if (!respContent) return message.error('处理信息输入框必填')
    if (!resolve) return message.error('请选择处理状态')
    await feedbackModel.feedbackHandle({feedbackId, respContent, operationStatus: resolve ? '1' : '0'})
    return history.goBack()
  }

  async function onSubmitExtra() {
    if (!respExtra) return message.warning('请输入补充信息')

    await feedbackModel.feedbackAppend({feedbackId, respExtra})
    message.success('提交成功')
    setRespExtra('')
    init()
  }

  return (
    <Page header={<PageHeader title={'反馈详情'} ghost={false} onBack={() => history.goBack()} />}>
      {toLoading ? (
        <Skeleton avatar paragraph={{rows: 4}} />
      ) : (
        <Row gutter={20} wrap={false}>
          <Col flex={1}>
            <div className={commonStyles.tableSection}>
              <DividerLine title={'基本信息'} />
              <Row gutter={[0, 5]} style={{marginTop: 10}}>
                <Col span={8}>
                  <Typography.Text>用户昵称：</Typography.Text>
                  <Typography.Text>{detailData.userName}</Typography.Text>
                </Col>
                <Col span={10}>
                  <Typography.Text>手机号：</Typography.Text>
                  <Typography.Text>
                    {detailData.userMobile}
                    <Button
                      type={'link'}
                      onClick={() => {
                        history.push('/order-list', {
                          orderMakerType: detailData.source,
                          lesseeMobile: detailData.source === 'lessee' ? detailData.userMobile : undefined,
                          lessorMobile: detailData.source === 'lessor' ? detailData.userMobile : undefined,
                        })
                      }}
                    >
                      <span style={{textDecoration: 'underline'}}>查看服务单</span>
                    </Button>
                  </Typography.Text>
                </Col>
                <Col span={8}>
                  <Typography.Text>反馈类型：</Typography.Text>
                  <Typography.Text>{feedbackTypeCode[detailData.feedbackType]}</Typography.Text>
                </Col>
                <Col span={10}>
                  <Typography.Text>性质类型：</Typography.Text>
                  <Typography.Text>{feedbackModeCode[detailData.feedbackMode]}</Typography.Text>
                </Col>
                <Col span={8}>
                  <Typography.Text>服务单号：</Typography.Text>
                  <Button
                    type={'text'}
                    style={{padding: 0}}
                    onClick={() => {
                      if (detailData.extra) {
                        history.push({pathname: '/steward/detail', state: {orderId: detailData.extra}})
                      }
                    }}
                  >
                    <Typography.Text underline={!!detailData.extra}>{detailData.extra || '无'}</Typography.Text>
                  </Button>
                </Col>
                <Col span={10}>
                  <Typography.Text>内容：</Typography.Text>
                  <Typography.Text>{detailData.feedbackContent || '无'}</Typography.Text>
                </Col>
                <Col span={10}>
                  <Typography.Text>创建时间：</Typography.Text>
                  <Typography.Text>{detailData.insertTime}</Typography.Text>
                </Col>
                <Col span={24} style={{display: 'flex', flexDirection: 'row', alignItems: 'flex-start'}}>
                  <Typography.Text>图片：</Typography.Text>
                  {detailData.feedbackImg?.length ? (
                    <div>
                      <Space>
                        {((detailData.feedbackImg as unknown) as string[])?.map(item => {
                          return (
                            <img
                              key={item}
                              src={getImg(item)}
                              width={88}
                              height={88}
                              alt={'图片'}
                              onClick={() => setSelectImg({imgUrl: item, visible: true})}
                            />
                          )
                        })}
                      </Space>
                    </div>
                  ) : (
                    <Typography.Text>无</Typography.Text>
                  )}
                </Col>
              </Row>
              <DividerLine title={'处理信息'} style={{marginTop: 20}} />
              {isFinish ? (
                <Row gutter={[0, 5]} style={{marginTop: 10}}>
                  <Col span={8}>
                    <Typography.Text>处理时间：</Typography.Text>
                    <Typography.Text>{detailData.operationTime}</Typography.Text>
                  </Col>
                  <Col span={10}>
                    <Typography.Text>处理人：</Typography.Text>
                    <Typography.Text>{detailData.operatorName}</Typography.Text>
                  </Col>
                  <Col span={8}>
                    <Typography.Text>处理结果：</Typography.Text>
                    <Typography.Text>{detailData.respContent}</Typography.Text>
                  </Col>
                </Row>
              ) : (
                <Input.TextArea
                  placeholder={'请输入处理结果'}
                  allowClear
                  style={{maxWidth: 420, maxHeight: 200, marginTop: 25}}
                  rows={4}
                  value={respContent}
                  onChange={e => setRespContent(e.target.value)}
                  maxLength={200}
                />
              )}
              {isFinish && (
                <>
                  <DividerLine title={'补充信息'} style={{marginTop: 20}} />
                  <Input.TextArea
                    placeholder={'请输入补充信息'}
                    allowClear
                    style={{maxWidth: 420, maxHeight: 200, marginTop: 25}}
                    rows={4}
                    value={respExtra}
                    onChange={e => setRespExtra(e.target.value)}
                    maxLength={200}
                  />
                </>
              )}
              <Row style={{marginTop: 20}}>
                <Col>
                  <Typography.Text>处理状态：是否处理完成</Typography.Text>
                </Col>
                <Col>
                  <Switch
                    style={{marginLeft: 5}}
                    checked={resolve}
                    size={'small'}
                    onChange={value => setResolve(value)}
                    disabled={detailData.operationStatus === '1'}
                  />
                </Col>
              </Row>
              <Row justify={'center'} style={{marginTop: 30}}>
                <Space>
                  {detailData.operationStatus === '0' && (
                    <Button type={'primary'} className='backBtn' loading={loading} onClick={onSubmit}>
                      提交
                    </Button>
                  )}
                  {isFinish && (
                    <Button type={'primary'} className='backBtn' loading={appendLoading} onClick={onSubmitExtra}>
                      提交
                    </Button>
                  )}
                  <Button className='backGrayBtn' onClick={() => history.goBack()}>
                    返回
                  </Button>
                </Space>
              </Row>
            </div>
          </Col>
          {isFinish && (
            <Col flex={'286px'}>
              <div className={commonStyles.tableSection}>
                <DividerLine title={'补充记录'} style={{marginBottom: 20}} />
                <Timeline>
                  {detailData.respExtra?.map((value, index) => (
                    <Timeline.Item key={index} dot={<Dot active={index === 0} />}>
                      <p className={styles.title}>{value.content}</p>
                      <p className={styles.subtitle}>操作人：{value.operatorName}</p>
                      <p className={styles.subtitle}>{value.time}</p>
                    </Timeline.Item>
                  ))}
                </Timeline>
              </div>
            </Col>
          )}
        </Row>
      )}

      {/** 预览图片 */}
      <Modal
        visible={selectImg.visible}
        title={'图片预览'}
        footer={null}
        onCancel={() => setSelectImg(state => ({...state, visible: false}))}
      >
        <img alt={'图片预览'} src={getImg(selectImg.imgUrl)} width='100%' />
      </Modal>
    </Page>
  )
}

const Dot = React.memo<{active?: boolean}>(props => (
  <div
    style={{
      display: 'inline-block',
      width: 16,
      height: 16,
      borderRadius: 16 / 2,
      backgroundColor: props.active ? primary : '#D8D8D8',
    }}
  />
))
