import React, {useCallback, useEffect, useState} from 'react'
import {Button, Col, Form, Input, message, Pagination, Row, Space, Table} from 'antd'
import {Page} from '@/components/Page'
import commonStyles from '@/assets/styles/common.module.less'
import {lessorModel} from '@/store/models/Lessor'
import {Breadcrumb} from '@/components/Breadcrumb'
import {downloadBlob} from '@/utils/util'
import {Permission} from '@/components/Permission'

export default function LesseeMember() {
  const [form] = Form.useForm()
  const [dataSource, setDataSource] = useState<LessorLesseeMemberVO[]>([])
  const [total, setTotal] = useState(0)

  const search = useCallback(async () => {
    const value: LessorLesseeMemberQueryDTO = form.getFieldsValue(true)
    const {
      response: {data, total},
    } = await lessorModel.lessorLesseeMemberList(value)
    setDataSource(data)
    setTotal(total)
  }, [form])

  useEffect(() => {
    search()
  }, [search])

  return (
    <Page
      breadcrumb={
        <Breadcrumb
          right={
            <Permission code={'007082001'}>
              <Button
                onClick={() => {
                  if (!dataSource.length) return message.warn('数据为空')

                  downloadBlob({url: '/lessor/lessee-member/excel', body: form.getFieldsValue(true)})
                }}
              >
                导出
              </Button>
            </Permission>
          }
        />
      }
    >
      <Form
        form={form}
        labelCol={{flex: '75px'}}
        onFinish={search}
        onReset={search}
        initialValues={{pageNum: 1, pageSize: 10}}
      >
        <div className={commonStyles.filterSection}>
          <Row gutter={50}>
            <Col span={8}>
              <Form.Item name={'lessorName'} label={'房东'}>
                <Input placeholder={'请输入房东姓名'} allowClear />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name={'lesseeName'} label={'租客'}>
                <Input placeholder={'请输入租客姓名'} allowClear />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name={'lesseeMobile'} label={'手机号'}>
                <Input placeholder={'请输入租客手机号'} allowClear />
              </Form.Item>
            </Col>
          </Row>
          <Row justify={'end'}>
            <Space>
              <Button type={'primary'} htmlType={'submit'}>
                查询
              </Button>
              <Button htmlType={'reset'}>重置</Button>
            </Space>
          </Row>
        </div>

        <div className={commonStyles.tableSection}>
          <Table
            loading={lessorModel.lessorLesseeMemberList.useLoading()}
            rowKey={'memberId'}
            columns={[
              {title: '房东', dataIndex: 'lessorName'},
              {title: '租客', dataIndex: 'lesseeName'},
              {title: '手机号', dataIndex: 'lesseeMobile'},
              {title: '添加时间/开始时间', render: (_, record) => record.insertTime ?? '/'},
              {title: '删除时间/结束时间', render: (_, record) => record.deletedTime ?? '/'},
            ]}
            dataSource={dataSource}
            pagination={false}
          />
          <Row justify={'center'} style={{marginTop: '24px'}}>
            <Form.Item
              noStyle
              shouldUpdate={(prev, next) => prev.pageSize !== next.pageSize || prev.pageNum !== next.pageNum}
            >
              {f => (
                <Pagination
                  current={f.getFieldValue('pageNum')}
                  pageSize={f.getFieldValue('pageSize')}
                  total={total}
                  showSizeChanger={true}
                  onChange={(page, pageSize) => {
                    f.setFieldsValue({pageNum: page, pageSize})
                    search()
                  }}
                />
              )}
            </Form.Item>
          </Row>
        </div>
      </Form>
    </Page>
  )
}
