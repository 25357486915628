import React, {useState, useRef, useEffect, useImperativeHandle} from 'react'
import {Form, Select, Input, message, Modal, Row, Button, Space, InputNumber, Tabs} from 'antd'
import AreaCascader from '@/components/AreaCascader'
import {Mapview} from '@/components/Map'
import MapLocation from '@/assets/icons/map_location.svg'
import {sysUserModel} from '@/store/models/SysUser'

import './index.less'
import {storageModel} from '@/store/models/Storage'
import {sysModel} from '@/store/models/Sys'
import DividerLine from '@/pages/property/component/DividerLine'
import {useCodeArraySync} from '@/store/models/Common'

const {Option} = Select

interface Point {
  lat: number
  lng: number
}

export interface EditSiteRef {
  show: (warehouseId?: string) => void
}

interface Props {
  onSuccess: () => void
}

const EditSite = React.forwardRef(({onSuccess}: Props, ref) => {
  const payWayArr = useCodeArraySync('bill.payWay')

  const [visible, setVisible] = useState(false)

  const [form] = Form.useForm()
  const mapRef = useRef(null)
  const formItemLayout = {
    labelCol: {span: 4},
    wrapperCol: {span: 20},
  }

  const [stewardList, setStewardList] = useState<SysUserSimpleVO[]>([])
  const [orgList, setOrgList] = useState<SysOrgVO[]>([])

  const [warehouseUserId, setWarehouseUserId] = useState('') //判断当前站点管理员对应字段 编辑需要
  const [defaultValue, setDefaultValue] = useState({})
  // 经纬度
  const [point, setPoint] = useState({} as Point)
  const loading = storageModel.stewardWarehouseUpsert.useLoading()
  const [permissionWarehouse, setPermissionWarehouse] = useState([] as SysUserSimpleVO[])

  // 管家列表
  useEffect(() => {
    if (visible) {
      sysUserModel.sysUserQuerySteward({}).then(({response: {data}}) => {
        setStewardList(data)
      })

      sysModel.sysOrgList({orgPid: ''}).then(({response: {data}}) => {
        setOrgList(data)
      })
    }
  }, [visible])

  useEffect(() => {
    sysUserModel.sysUserListUserByPermissionOfWarehouse().then(({response: {data}}) => {
      setPermissionWarehouse(data)
    })
  }, [])

  const warehouseIdRef = useRef('')

  useImperativeHandle<any, EditSiteRef>(ref, () => ({
    show: warehouseId => {
      warehouseIdRef.current = warehouseId
      setVisible(true)
      if (warehouseId) {
        storageModel.stewardWarehouseInfo(warehouseId).then(({response: {data}}) => {
          const {provinceCode, cityCode, districtCode, bizCircleCode, lng, lat, stewardList, ...values} = data
          setWarehouseUserId(warehouseUserId)
          setDefaultValue({provinceCode, cityCode, districtCode, bizCircleCode})
          form.setFieldsValue({
            addressValue: {provinceCode, cityCode, districtCode, bizCircleCode},
            stewardIdList: stewardList.map(item => item.key),
            // addressPoint: [values.cityName, values.districtName, values.bizCircleName, values.address]
            //   .filter(Boolean)
            //   .join(''),
            ...values,
          })
          mapRef.current.panTo(lng, lat)
        })
      }
    },
  }))

  function addressValid(rule: any, value: any, callback: Function) {
    if (!value) {
      callback('站点地址不能为空')
      return
    }

    if (!value?.provinceCode) {
      return callback('省不能为空')
    }

    if (!value?.cityCode) {
      callback('城市不能为空')
      return
    }

    if (!value?.districtCode) {
      callback('区域不能为空')
      return
    }

    // 需求改为选填
    // if (!value?.bizCircleCode) {
    //   callback('商圈不能为空')
    //   return
    // }

    callback()
  }

  function enterSelectAddress(address: string, e: any) {
    // const addressPoint = form.getFieldValue('addressPoint')
    if (address) {
      mapRef.current?.panToByPath(address)
    }
    e.preventDefault()
  }

  function onFinish() {
    const {lat, lng} = point
    const {addressPoint, addressValue, warehouseId, ...rest} = form.getFieldsValue(true)
    const {provinceCode, cityCode, districtCode, bizCircleCode} = addressValue
    storageModel
      .stewardWarehouseUpsert({
        ...rest,
        provinceCode,
        cityCode,
        districtCode,
        bizCircleCode,
        lat,
        lng,
        // 编辑
        warehouseId: warehouseIdRef.current ?? '',
      })
      .then(() => {
        message.success('站点新增/编辑成功')
        form.resetFields()
        setDefaultValue({})
        warehouseIdRef.current = ''
        setVisible(false)
        onSuccess()
      })
  }

  return (
    <Modal
      open={visible}
      title={warehouseIdRef.current ? '编辑站点' : '新增站点'}
      width={717}
      footer={null}
      onCancel={() => {
        form.resetFields()
        setDefaultValue({})
        warehouseIdRef.current = ''
        setVisible(false)
      }}
    >
      <Form {...formItemLayout} form={form} onFinish={onFinish}>
        <Tabs>
          <Tabs.TabPane tab={'基本信息'} key={'1'}>
            <Form.Item name={'name'} label={'站点名称'} rules={[{required: true, message: '站点名称不能为空'}]}>
              <Input allowClear placeholder={'请输入站点名称'} />
            </Form.Item>
            <Form.Item label={'站点地址'} required style={{marginBottom: 0}}>
              <Form.Item name={'addressValue'} rules={[{required: true, validator: addressValid}]}>
                <AreaCascader value={defaultValue} />
              </Form.Item>
              <Form.Item name={'address'} rules={[{required: true, message: '详细地址不能为空'}]}>
                <Input
                  allowClear
                  placeholder={'请输入详细地址，回车搜索'}
                  onPressEnter={(e: any) => {
                    const {addressValue} = form.getFieldsValue(true)
                    const {city, district, bizCircle} = addressValue || {}
                    const values = [city, district, bizCircle, e.target.value].filter(Boolean).join('')
                    enterSelectAddress(values, e)
                    // form.setFieldValue('addressPoint', values)
                  }}
                />
              </Form.Item>
            </Form.Item>
            {/*<Form.Item label={'地图选点'} name={'addressPoint'}>
              <Input
                allowClear
                placeholder={'输入搜索地址，回车搜索'}
                onPressEnter={(e: any) => enterSelectAddress(e.target.value, e)}
              />
            </Form.Item>*/}
            <Form.Item label={' '} colon={false}>
              <div style={{position: 'relative'}}>
                <Mapview
                  style={{height: '294px'}}
                  ref={mapRef}
                  onMapChange={event => {
                    setPoint({lat: event.point.lat, lng: event.point.lng})
                  }}
                />
                <img src={MapLocation} alt={'icon'} className={'edit-site-location'} />
              </div>
            </Form.Item>
            {/*<Form.Item shouldUpdate label={'租金'} required style={{marginBottom: 0}}>*/}
            {/*  {() => {*/}
            {/*    return (*/}
            {/*      <Space>*/}
            {/*        <Form.Item name={'rent'} rules={[{required: true, message: '请输入'}]}>*/}
            {/*          <InputNumber min={1} placeholder={'请输入'} style={{width: '180px'}} />*/}
            {/*        </Form.Item>*/}
            {/*        <Form.Item name={'deposit'} label={'押金'} rules={[{required: true, message: '请输入'}]}>*/}
            {/*          <InputNumber min={1} placeholder={'请输入'} style={{width: '180px'}} />*/}
            {/*        </Form.Item>*/}
            {/*      </Space>*/}
            {/*    )*/}
            {/*  }}*/}
            {/*</Form.Item>*/}
            {/*<Form.Item label={'付款方式'} shouldUpdate required style={{marginBottom: 0}}>*/}
            {/*  {() => {*/}
            {/*    return (*/}
            {/*      <Space>*/}
            {/*        <Form.Item>*/}
            {/*          <span>付</span>*/}
            {/*        </Form.Item>*/}
            {/*        <Form.Item name={'payType'} rules={[{required: true, message: '请选择'}]}>*/}
            {/*          <Select*/}
            {/*            options={Array.from({length: 12})*/}
            {/*              .fill(1)*/}
            {/*              .map((_, index) => ({label: index + 1, value: index + 1}))}*/}
            {/*            placeholder={'请选择'}*/}
            {/*            style={{width: '90px'}}*/}
            {/*          />*/}
            {/*        </Form.Item>*/}
            {/*        <Form.Item>*/}
            {/*          <span>押</span>*/}
            {/*        </Form.Item>*/}
            {/*        <Form.Item name={'pledgeType'} rules={[{required: true, message: '请选择'}]}>*/}
            {/*          <Select*/}
            {/*            options={Array.from({length: 12})*/}
            {/*              .fill(1)*/}
            {/*              .map((_, index) => ({label: index + 1, value: index + 1}))}*/}
            {/*            placeholder={'请选择'}*/}
            {/*            style={{width: '90px'}}*/}
            {/*          />*/}
            {/*        </Form.Item>*/}
            {/*        <Form.Item label={'付款类型'} required>*/}
            {/*          <span>每期提前</span>*/}
            {/*        </Form.Item>*/}
            {/*        <Form.Item label={'提前天数'} name={'payAdvanceDays'} rules={[{required: true, message: '请选择'}]}>*/}
            {/*          <Select*/}
            {/*            options={Array.from({length: 30})*/}
            {/*              .fill(1)*/}
            {/*              .map((_, index) => ({label: index + 1, value: index + 1}))}*/}
            {/*            placeholder={'请选择'}*/}
            {/*            style={{width: '90px'}}*/}
            {/*          />*/}
            {/*        </Form.Item>*/}
            {/*      </Space>*/}
            {/*    )*/}
            {/*  }}*/}
            {/*</Form.Item>*/}
            <Form.Item
              name={'orgIdList'}
              label={'仓库关联组织'}
              rules={[{required: true, message: '仓库关联组织不能为空'}]}
            >
              <Select placeholder={'请选择仓库关联组织'} mode={'multiple'} allowClear>
                {orgList.map(item => (
                  <Option key={item.orgId} value={item.orgId}>
                    {item.orgName}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name={'orgId'}
              label={'人员管理组织'}
              rules={[{required: true, message: '人员管理组织不能为空'}]}
            >
              <Select
                placeholder={'请选择人员管理组织'}
                allowClear
                showSearch
                filterOption={(inputValue, option: {children: string}) => {
                  return option.children?.includes(inputValue)
                }}
              >
                {orgList.map(item => (
                  <Option key={item.orgId} value={item.orgId}>
                    {item.orgName}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name={'managerId'}
              label={'站点管理人'}
              rules={[{required: true, message: '站点管理人不能为空'}]}
            >
              <Select
                placeholder={'请选择站点管理人'}
                allowClear
                showSearch
                filterOption={(inputValue, option: {children: string}) => {
                  return option.children?.includes(inputValue)
                }}
              >
                {permissionWarehouse.map(item => (
                  <Option key={item.userId} value={item.userId}>
                    {`${item.name}（${item.mobile}）`}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name={'stewardId'}
              label={'房源接管人'}
              rules={[{required: true, message: '房源接管人不能为空'}]}
            >
              <Select
                allowClear
                placeholder={'请选择'}
                showSearch
                filterOption={(inputValue, option: {children: string}) => {
                  return option.children?.includes(inputValue)
                }}
              >
                {stewardList.map(item => (
                  <Option key={item.userId} value={item.userId}>
                    {`${item.name}（${item.mobile}）`}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name={'stewardIdList'}
              label={'站点管家'}
              // rules={[{required: true, message: '站点管家不能为空'}]}
            >
              <Select
                allowClear
                placeholder={'请选择'}
                mode={'multiple'}
                showSearch
                filterOption={(inputValue, option: any) => {
                  return option.children?.indexOf(inputValue) > -1
                }}
              >
                {stewardList.map(item => (
                  <Option key={item.userId} value={item.userId}>
                    {`${item.name}（${item.mobile}）`}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Tabs.TabPane>
          <Tabs.TabPane tab={'合同&账单'} key={'2'}>
            <DividerLine title={'基本信息'} bottom={15} />
            <Form.Item shouldUpdate label={'租金'} required style={{marginBottom: 0}}>
              {() => {
                return (
                  <Space>
                    <Form.Item name={'rent'} rules={[{required: true, message: '请输入'}]}>
                      <InputNumber min={0} placeholder={'请输入'} style={{width: '180px'}} />
                    </Form.Item>
                    <Form.Item name={'deposit'} label={'押金'} rules={[{required: true, message: '请输入'}]}>
                      <InputNumber min={0} placeholder={'请输入'} style={{width: '180px'}} />
                    </Form.Item>
                  </Space>
                )
              }}
            </Form.Item>
            <Form.Item label={'付款方式'} shouldUpdate required style={{marginBottom: 0}}>
              {() => {
                return (
                  <Space>
                    <Form.Item>
                      <span>付</span>
                    </Form.Item>
                    <Form.Item name={'payType'} rules={[{required: true, message: '请选择'}]}>
                      <Select
                        options={Array.from({length: 12})
                          .fill(1)
                          .map((_, index) => ({label: index + 1, value: index + 1}))}
                        placeholder={'请选择'}
                        style={{width: '90px'}}
                      />
                    </Form.Item>
                    <Form.Item>
                      <span>押</span>
                    </Form.Item>
                    <Form.Item name={'pledgeType'} rules={[{required: true, message: '请选择'}]}>
                      <Select
                        options={Array.from({length: 12})
                          .fill(1)
                          .map((_, index) => ({label: index + 1, value: index + 1}))}
                        placeholder={'请选择'}
                        style={{width: '90px'}}
                      />
                    </Form.Item>
                  </Space>
                )
              }}
            </Form.Item>
            <Form.Item label={'付款类型'} shouldUpdate required>
              {() => {
                return (
                  <Space>
                    <Form.Item>
                      <span>每期提前</span>
                    </Form.Item>
                    <Form.Item name={'payAdvanceDays'} rules={[{required: true, message: '请选择'}]}>
                      <Select
                        options={Array.from({length: 30})
                          .fill(1)
                          .map((_, index) => ({label: index + 1, value: index + 1}))}
                        placeholder={'请选择'}
                        style={{width: '90px'}}
                      />
                    </Form.Item>
                    <Form.Item>
                      <span>天</span>
                    </Form.Item>
                  </Space>
                )
              }}
            </Form.Item>

            <DividerLine title={'发起付款审批配置'} bottom={15} />
            <Form.Item label={'付款事由'} name={'payReason'} rules={[{required: true, message: '请输入付款事由'}]}>
              <Input placeholder={'请输入'} />
            </Form.Item>
            <Form.Item label={'付款方式'} name={'payWayCode'} rules={[{required: true, message: '请选择付款方式'}]}>
              <Select
                options={payWayArr}
                fieldNames={{value: 'id'}}
                placeholder={'请选择'}
                allowClear
                onChange={(_, option) => {
                  if (!Array.isArray(option)) form.setFieldsValue({payWayName: option.label})
                }}
              />
            </Form.Item>
            <Form.Item label={'收款人全称'} name={'payeeName'} rules={[{required: true, message: '请输入收款人全称'}]}>
              <Input placeholder={'请输入'} />
            </Form.Item>
            <Form.Item
              label={'银行账号'}
              name={'bankAccountNumber'}
              rules={[{required: true, message: '请输入银行账号'}]}
            >
              <Input placeholder={'请输入'} />
            </Form.Item>
            <Form.Item label={'开户行'} name={'bankBranchName'} rules={[{required: true, message: '请输入开户行'}]}>
              <Input placeholder={'请输入'} />
            </Form.Item>
          </Tabs.TabPane>
        </Tabs>

        <Row justify={'end'}>
          <Space>
            <Button
              onClick={() => {
                form.resetFields()
                setDefaultValue({})
                warehouseIdRef.current = ''
                setVisible(false)
              }}
            >
              取消
            </Button>
            <Button type={'primary'} htmlType={'submit'} loading={loading}>
              保存
            </Button>
          </Space>
        </Row>
      </Form>
    </Modal>
  )
})

export default EditSite
