import React, {useCallback, useEffect, useState} from 'react'
import {Page} from '@/components/Page'
import {Button, Col, Descriptions, Drawer, Form, Input, message, Modal, Row, Select, Space, Table} from 'antd'
import commonStyles from '@/assets/styles/common.module.less'
import {stewardModel} from '@/store/models/Steward'
import {useCodeArraySync, useCodeSync} from '@/store/models/Common'
import {Album, UploadButton} from '@/components/Album'
import {baseUrl} from '@/common/config'
import {getImg} from '@/utils/util'
import {getPaginationProps} from '@/common/biz/getPaginationProps'
import {useAppLocation} from '@/common/hooks/useRouter'

const {Option} = Select

const CheckDetailTitle = React.memo(function (args: {title: string}) {
  const {title} = args
  return (
    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
      <div style={{width: '6px', height: '14px', marginRight: '5px', backgroundColor: '#00B7AE'}} />
      <span style={{color: 'black', fontSize: '14px', lineHeight: '14px', fontWeight: 600}}>{title}</span>
    </div>
  )
})

export default function StewardCallingCheckList() {
  const location = useAppLocation<'/steward-calling'>()
  const connectionCheckStatusArr = useCodeArraySync('steward.order.connectionCheck.status')
  const connectionCheckStatusCode = useCodeSync('steward.order.connectionCheck.status')

  const [form] = Form.useForm<StewardOrderConnectionCheckQueryDTO>()

  const [total, setTotal] = useState(0)
  const [checkList, setCheckList] = useState<StewardOrderConnectionCheckVO[]>([])
  const [checkDetail, setCheckDetail] = useState({} as StewardOrderConnectionCheckVO)

  const [rejectForm] = Form.useForm()
  const [checkReject, setCheckReject] = useState('')
  const [checkPass, setCheckPass] = useState('')

  const queryConnectionCheckList = useCallback(() => {
    const values = form.getFieldsValue(true)
    stewardModel.stewardOrderConnectionCheckList(values).then(({response: {data, total}}) => {
      setCheckList(data)
      setTotal(total)
    })
  }, [form])

  useEffect(() => {
    form.setFieldsValue({connectionCheckStatus: location.state?.connectionCheckStatus})
    form.submit()
  }, [form, location])

  const queryConnectionCheckGet = useCallback(async (connectionCheckId: string) => {
    const {
      response: {data},
    } = await stewardModel.stewardOrderConnectionCheckGet({connectionCheckId})
    setCheckDetail(data)
  }, [])

  const loading = stewardModel.stewardOrderConnectionCheckList.useLoading()
  const handleLoading = stewardModel.stewardOrderConnectionCheckHandle.useLoading()

  return (
    <Page>
      <Form
        form={form}
        initialValues={{pageNum: 1, pageSize: 10}}
        onFinish={queryConnectionCheckList}
        onReset={queryConnectionCheckList}
      >
        <div className={commonStyles.filterSection}>
          <Row>
            <Col span={7}>
              <Form.Item label={'申请ID'} name={'connectionCheckId'}>
                <Input placeholder={'请输入'} allowClear />
              </Form.Item>
            </Col>
            <Col span={7} offset={1}>
              <Form.Item label={'申请人'} name={'applicantName'}>
                <Input placeholder={'请输入'} allowClear />
              </Form.Item>
            </Col>
            <Col span={7} offset={1}>
              <Form.Item label={'订单编号'} name={'orderId'}>
                <Input placeholder={'请输入'} allowClear />
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item label={'下单人'} name={'orderMakerName'}>
                <Input placeholder={'请输入'} allowClear />
              </Form.Item>
            </Col>
            <Col span={7} offset={1}>
              <Form.Item label={'下单人手机号'} name={'orderMakerMobile'}>
                <Input placeholder={'请输入'} allowClear />
              </Form.Item>
            </Col>
            <Col span={7} offset={1}>
              <Form.Item label={'状态'} name={'connectionCheckStatus'}>
                <Select placeholder={'请选择'} allowClear>
                  {connectionCheckStatusArr.map(item => (
                    <Option key={item.id} value={item.id}>
                      {item.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item label={'审核人'} name={'auditorName'}>
                <Input placeholder={'请输入'} allowClear />
              </Form.Item>
            </Col>
          </Row>
          <Row justify={'end'}>
            <Space>
              <Button type={'primary'} htmlType={'submit'}>
                查询
              </Button>
              <Button htmlType={'reset'}>重置</Button>
            </Space>
          </Row>
        </div>

        <div className={commonStyles.tableSection}>
          <Table
            loading={loading}
            dataSource={checkList}
            scroll={{x: 'max-content'}}
            columns={[
              {title: '申请ID', dataIndex: 'connectionCheckId'},
              {title: '申请人', dataIndex: 'applicantName'},
              {title: '申请时间', dataIndex: 'insertTime'},
              {title: '订单编号', dataIndex: 'orderId'},
              {title: '预约上门时间', dataIndex: 'timeAppoint'},
              {title: '下单人', dataIndex: 'orderMakerName'},
              {title: '下单人手机号', dataIndex: 'orderMakerMobile'},
              {title: '状态', dataIndex: 'connectionCheckStatus', render: value => connectionCheckStatusCode[value]},
              {title: '审核人', dataIndex: 'auditorName', render: value => value ?? '/'},
              {title: '审核时间', dataIndex: 'auditTime', render: value => value ?? '/'},
              {
                title: '操作',
                dataIndex: 'connectionCheckId',
                render: value => (
                  <Button type={'link'} style={{padding: 0}} onClick={async () => await queryConnectionCheckGet(value)}>
                    查看
                  </Button>
                ),
              },
            ]}
            rowKey={'connectionCheckId'}
            pagination={getPaginationProps(form, total)}
          />
        </div>
      </Form>

      <Drawer
        visible={!!checkDetail.connectionCheckId}
        title={'申请详情'}
        width={800}
        onClose={() => setCheckDetail({} as StewardOrderConnectionCheckVO)}
      >
        <Descriptions
          column={2}
          title={
            <Space size={6}>
              <CheckDetailTitle title={'基本信息'} />
              {checkDetail.connectionCheckStatus === '1' && (
                <Button type={'link'} style={{padding: 0}} onClick={() => setCheckPass(checkDetail.connectionCheckId)}>
                  通过
                </Button>
              )}
              {checkDetail.connectionCheckStatus === '1' && (
                <Button
                  type={'link'}
                  style={{padding: 0}}
                  onClick={() => setCheckReject(checkDetail.connectionCheckId)}
                >
                  驳回
                </Button>
              )}
            </Space>
          }
        >
          <Descriptions.Item label={'申请ID'}>{checkDetail.connectionCheckId}</Descriptions.Item>
          <Descriptions.Item label={'申请时间'}>{checkDetail.insertTime}</Descriptions.Item>
          <Descriptions.Item label={'申请人'}>{checkDetail.applicantName}</Descriptions.Item>
          <Descriptions.Item label={'订单编号'}>{checkDetail.orderId}</Descriptions.Item>
          <Descriptions.Item label={'预约上门时间'}>{checkDetail.timeAppoint}</Descriptions.Item>
          <Descriptions.Item label={'下单人'}>{checkDetail.orderMakerName}</Descriptions.Item>
          <Descriptions.Item label={'下单时间'}>{checkDetail.timeMake}</Descriptions.Item>
          <Descriptions.Item label={'下单人手机号'}>{checkDetail.orderMakerMobile}</Descriptions.Item>
          <Descriptions.Item label={'审核人'}>{checkDetail.auditorName ?? '/'}</Descriptions.Item>
          <Descriptions.Item label={'审核时间'}>{checkDetail.auditTime ?? '/'}</Descriptions.Item>
          <Descriptions.Item label={'状态'}>
            {connectionCheckStatusCode[checkDetail.connectionCheckStatus]}
          </Descriptions.Item>
          <Descriptions.Item label={'备注'}>{checkDetail.applyNotes ?? '无'}</Descriptions.Item>
          {!!checkDetail.applyImgUrlList?.length && (
            <Descriptions.Item label={'附件'} span={2}>
              <Album disabled fileList={checkDetail.applyImgUrlList?.map(item => ({url: getImg(item), uid: item}))}>
                {null}
              </Album>
            </Descriptions.Item>
          )}
        </Descriptions>

        {checkDetail.connectionCheckStatus === '3' && (
          <Descriptions column={2} title={<CheckDetailTitle title={'驳回信息'} />} style={{marginTop: '30px'}}>
            <Descriptions.Item label={'原因'}>{checkDetail.auditNotes}</Descriptions.Item>
            <Descriptions.Item label={'附件'}>
              {checkDetail.auditAttachList?.length ? (
                <Album disabled fileList={checkDetail.auditAttachList?.map(item => ({url: getImg(item), uid: item}))}>
                  {null}
                </Album>
              ) : (
                '/'
              )}
            </Descriptions.Item>
          </Descriptions>
        )}
      </Drawer>

      <Modal visible={!!checkPass} title={'提示'} footer={null} onCancel={() => setCheckPass('')}>
        <span>是否确定通过？</span>
        <Row justify={'end'} style={{marginTop: '50px'}}>
          <Space>
            <Button onClick={() => setCheckPass('')}>取消</Button>
            <Button
              type={'primary'}
              loading={handleLoading}
              onClick={async () => {
                await stewardModel.stewardOrderConnectionCheckHandle({
                  connectionCheckId: checkPass,
                  auditAttachList: [],
                  approve: true,
                } as StewardOrderConnectionCheckHandleDTO)
                message.success('操作成功！')
                queryConnectionCheckGet(checkPass)
                form.submit()
                setCheckPass('')
              }}
            >
              确认
            </Button>
          </Space>
        </Row>
      </Modal>

      <Modal
        visible={!!checkReject}
        title={'驳回'}
        footer={null}
        onCancel={() => setCheckReject('')}
        afterClose={() => rejectForm.resetFields()}
      >
        <Form
          form={rejectForm}
          initialValues={{auditAttachList: []}}
          onFinish={async () => {
            const formValues = rejectForm.getFieldsValue(true)
            // console.log('formValues', formValues)
            await stewardModel.stewardOrderConnectionCheckHandle({
              connectionCheckId: checkReject,
              approve: false,
              ...formValues,
            } as StewardOrderConnectionCheckHandleDTO)
            message.success('操作成功！')
            queryConnectionCheckGet(checkReject)
            form.submit()
            setCheckReject('')
          }}
          onReset={() => setCheckReject('')}
        >
          <Form.Item label={'附件'} shouldUpdate>
            {() => (
              <Album
                accept={'.jpg, .jpeg, .png'}
                action={baseUrl + '/steward/order/connection-check/upload'}
                limit={9}
                onDone={fileList => {
                  rejectForm.setFieldsValue({auditAttachList: fileList?.map(item => item.imgUrl)})
                }}
              >
                {rejectForm.getFieldValue('auditAttachList')?.length <= 9 ? <UploadButton /> : null}
              </Album>
            )}
          </Form.Item>
          <Form.Item name={'auditNotes'} label={'原因'} rules={[{required: true, message: '请输入备注'}]}>
            <Input.TextArea placeholder={'请输入备注'} maxLength={200} />
          </Form.Item>
          <Row justify={'end'}>
            <Space>
              <Button htmlType={'reset'}>取消</Button>
              <Button type={'primary'} htmlType={'submit'} loading={handleLoading}>
                提交
              </Button>
            </Space>
          </Row>
        </Form>
      </Modal>
    </Page>
  )
}
