import {Model} from '@redux-model/web'
import {$api} from '@/store/service'

class Customer extends Model<any> {
  protected initReducer(): any {
    return null
  }

  // 组织架构-组织架构tree  |  object:{ownerId:客户id}
  customerTeamGetTeamTree = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<TeamTreeNodeVO[]>>('/customer/team/get-team-tree').body(args)
  )

  // 组织架构-根据组织查询成员列表  |  object:{ownerId:客户id,teamId:组织/团队id}
  customerTeamListMember = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<TeamUserVO[]>>('/customer/team/list-member').body(args)
  )

  // 查询用户余额  |  object:{userId:客户id}
  customerTradeWalletBalance = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<QueryBalanceVO>>('/customer/trade-wallet/balance').body(args)
  )

  // 钱包-流水列表(通联)
  customerTradeWalletList = $api.action((args: TradeListQueryDTO) =>
    this.post<ApiResponse<TradeVO[]>>('/customer/trade-wallet/list').body(args)
  )

  // 组织成员-删除成员  |  object:{ownerId:客户id,userId:成员用户编号}
  customerMemberDelete = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<Record<string, any>>>('/customer/member/delete').body(args)
  )

  // 组织成员-成员详情  |  object:{ownerId:客户id,userId:成员用户编号}
  customerMemberDetail = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<TeamMemberVO>>('/customer/member/detail').body(args).metas(args.userId)
  )

  // 组织成员-编辑成员
  customerMemberEdit = $api.action((args: TeamMemberUpdateDTO) =>
    this.post<ApiResponse<Record<string, any>>>('/customer/member/edit').body(args)
  )

  // 组织成员-添加成员
  customerMemberInsert = $api.action((args: TeamMemberInsertDTO) =>
    this.post<ApiResponse<Record<string, any>>>('/customer/member/insert').body(args)
  )

  // 岗位角色-岗位列表  |  object:{ownerId:客户id}
  customerRoleList = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<TeamRoleVO[]>>('/customer/role/list').body(args)
  )

  // 岗位角色-查询所有的权限列表树
  customerRoleListAllPermissionTree = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<TeamPermissionTreeNodeVO[]>>('/customer/role/list-all-permission-tree').body(args)
  )

  // 岗位角色-根据岗位查询成员列表  |  object:{ownerId:客户id,teamRole:岗位编号}
  customerRoleListMember = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<TeamUserVO[]>>('/customer/role/list-member').body(args)
  )

  // 岗位角色-新增/编辑岗位
  customerRoleSave = $api.action((args: TeamRoleSaveDTO) =>
    this.post<ApiResponse<string>>('/customer/role/save').body(args)
  )

  // 组织架构-删除组织/团队  |  object:{ownerId:客户id,teamId:组织/团队id}
  customerTeamDelete = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<Record<string, any>>>('/customer/team/delete').body(args)
  )

  // 组织架构-新增组织/团队  |  object:{ownerId:客户id,teamId:组织id(可空),name:名称}
  customerTeamInsert = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<Record<string, any>>>('/customer/team/insert').body(args)
  )

  // 组织架构-重命名组织/团队  |  object:{teamId:组织/团队id,name:名称}
  customerTeamRename = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<Record<string, any>>>('/customer/team/rename').body(args)
  )

  // 客户列表
  customerList = $api.action((args: LessorCustomerListDTO) =>
    this.post<ApiResponse<LessorCustomerListVO[]>>('/customer/list').body(args)
  )

  // 查询录入过的小区
  customerCommunityList = $api.action((args: {userId: string}) =>
    this.post<ApiResponse<CommunityVO[]>>('/customer/community-list').body(args)
  )

  // 客户-编辑
  customerUpsert = $api.action((args: LessorInsertDTO) => this.post<ApiResponse<string>>('/customer/upsert').body(args))

  // 客户详情
  customerInfo = $api.action((args: {userId: string}) =>
    this.post<ApiResponse<LessorCustomerVO>>('/customer/info').body(args)
  )

  // 客户-详情-查看合同  |  userId 客户id
  customerContractInfo = $api.action((args: {userId: string}) =>
    this.post<ApiResponse<SaleCustomerContractVO>>('/customer/contract-info').body(args)
  )

  // 统计列表
  customerReportList = $api.action((args: LessorCustomerReportQueryDTO) =>
    this.post<ApiResponse<LessorCustomerReportVO[]>>('/customer/report/list').body(args)
  )

  // 客户-详情-合同配置tree  |  userId 客户id
  customerContractPropertyConfig = $api.action((args: {userId: string}) =>
    this.post<ApiResponse<SaleCustomerPropertyNodeVO[]>>('/customer/contract-property-config').body(args)
  )

  // 客户-详情-保存合同配置tree  |  userId 客户id
  customerContractPropertyConfigSave = $api.action((args: LessorContractConfigDTO) =>
    this.post<ApiResponse<Record<string, any>>>('/customer/contract-property-config/save').body(args)
  )

  // 客户-详情-房源推广-更新  |  object:{userId:客户Id, propertyPromote:推广（0：否、1：是）}
  customerPropertyPromoteUpdate = $api.action((args: {userId: string; propertyPromote: '0' | '1'}) =>
    this.post<ApiResponse<Record<string, any>>>('/customer/property-promote/update').body(args)
  )

  // 更新合租房源对应的公区标题
  customerPropertyRecordList = $api.action((args: PropertyRecordListDTO) =>
    this.post<ApiResponse<PropertyRecordVO[]>>('/customer/property/record/list').body({
      pageNum: 1,
      pageSize: 10,
      pageable: true,
      ...args,
    })
  )

  // 更新客户的公寓banner  |  object:{userId:客户id,imgUrl:图片url}
  customerCondoBannerUpdate = $api.action((args: {userId: string; imgUrl: string}) =>
    this.post<ApiResponse<Record<string, any>>>('/customer/condo-banner/update').body(args)
  )

  // 启用/禁用客户的服务费客户  |  object:{userId:客户id,lesseeMember:是否启用}
  customerLesseeMemberChange = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<Record<string, any>>>('/customer/lessee-member/change').body(args)
  )

  // 更新客户是否包退组保洁标记  |  object:{userId:客户id,cleanCoverTag:是否包退租保洁[0,1]}
  customerUpdateCleanCoverTag = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<Record<string, any>>>('/customer/update-clean-cover-tag').body(args)
  )

  // 组织上的服务单相关配置(1公里,5分钟)
  customerTeamOrderConfig = $api.action((args: TeamOrderConfigDTO) =>
    this.post<ApiResponse<Record<string, any>>>('/customer/team/order-config').body(args)
  )

  // 启用/禁用客户的海报  |  object:{userId:客户id,enablePosters:是否启用海报}
  customerPostersEnable = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<Record<string, any>>>('/customer/posters/enable').body(args)
  )

  // 更新客户维修服务核验Tag  |  object:{userId:客户id,serviceVerifyTag:是否维修服务核验[0,1]}
  customerUpdateServiceVerifyTag = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<Record<string, any>>>('/customer/update-service-verify-tag').body(args)
  )

  // 更新客户免联服务  |  object:{userId:客户id,enableNotRelation:是否免联[0,1]}
  customerUpdateNotRelation = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<Record<string, any>>>('/customer/update-not-relation').body(args)
  )

  // 添加/修改客户活动备注  |  object:{userId:客户id,activityRemark:活动备注}
  customerUpsertActivityRemark = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<Record<string, any>>>('/customer/upsert/activity-remark').body(args)
  )
  // 更新维修超量扣款  |  object:{userId:用户ID,emdEnable:标记,emdLimitNum:订单数阈值,emdAmt:每单扣款金额}
  customerUpdateEmd = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<Record<string, any>>>('/customer/update-emd').body(args)
  )

  // 查询维修超量扣款的描述(可能为null)  |  object:{userId:用户ID}
  customerGetEmdDesc = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<string>>('/customer/get-emd-desc').body(args)
  )
}

export const customerModel = new Customer()
