import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {Button, Col, Divider, Form, Input, InputNumber, message, Modal, Row, Select, Space, Table} from 'antd'
import {storageModel} from '@/store/models/Storage'
import {useCodeSync, useCodeArraySync} from '@/store/models/Common'
import {baseUrl} from '@/common/config'
import {Album, UploadButton} from '@/components/Album'
import {getImg} from '@/utils/util'
import {Breadcrumb} from '@/components/Breadcrumb'
import commonStyles from '@/assets/styles/common.module.less'
import {hasPermission, Permission} from '@/components/Permission'
import {Page} from '@/components/Page'
import {getPaginationProps} from '@/common/biz/getPaginationProps'

const {Option} = Select

export default function GoodsManagement() {
  const [form] = Form.useForm()
  const [itemForm] = Form.useForm()

  const categoryList = useCodeArraySync('material.category')
  const categoryCode = useCodeSync('material.category')

  const [total, setTotal] = useState(0)
  const [tableList, setTableList] = useState<StewardMaterialListVO[]>([])

  const renderOperate = useMemo(
    () =>
      [
        {
          permission: '002026002',
          title: '编辑',
          onClick: record => {
            itemForm.setFieldsValue(record)
            setAddEdit({visible: true, title: '编辑物品'})
          },
        },
        // {
        //   permission: '004026002',
        //   title: '删除',
        //   onClick: record => setDele({visible: true, materialId: record.materialId}),
        // },
        {
          permission: '013027002',
          title: '复制链接',
          onClick: record => {
            navigator.clipboard.writeText(record.purchaseLink).then(function () {
              message.success('复制成功！')
            })
          },
        },
      ].filter(value => hasPermission(value.permission)),
    [itemForm]
  )

  const [addEdit, setAddEdit] = useState({visible: false} as {visible: boolean; title?: string})
  const [dele, setDele] = useState({visible: false, materialId: ''})

  const tableLoading = storageModel.stewardWarehouseMaterialList.useLoading()
  const upsertLoading = storageModel.stewardWarehouseMaterialUpsert.useLoading()

  // todo: networking
  const queryMaterialList = useCallback(() => {
    const values = form.getFieldsValue(true)
    storageModel.stewardWarehouseMaterialList(values).then(({response: {data, total}}) => {
      setTotal(total)
      setTableList(data)
    })
  }, [form])

  useEffect(() => {
    form.submit()
  }, [form])

  // 删除
  async function materialDelete() {
    await storageModel.stewardWarehouseMaterialDelete({materialId: dele.materialId})
    message.success('删除成功！')
    setDele({visible: false, materialId: ''})
    form.submit()
  }

  // 新增/编辑
  async function materialUpsert() {
    const formData = itemForm.getFieldsValue(true) as StewardMaterialUpsertDTO
    if (formData.priceCheck <= formData.price) {
      return message.error('核算价必须大于参考价！')
    }

    const paramsData = {...formData, categoryName: categoryCode[formData.category]}
    await storageModel.stewardWarehouseMaterialUpsert(paramsData)
    setAddEdit({visible: false})
    itemForm.resetFields()
    form.submit()
  }

  return (
    <Page
      breadcrumb={
        <Breadcrumb
          right={
            <Permission code={'003026002'}>
              <Button type={'primary'} onClick={() => setAddEdit({visible: true, title: '新增物品'})}>
                新增
              </Button>
            </Permission>
          }
        />
      }
    >
      <div className={commonStyles.filterSection}>
        <Form
          form={form}
          initialValues={{pageNum: 1, pageSize: 10}}
          onFinish={queryMaterialList}
          onReset={queryMaterialList}
        >
          <Row gutter={20}>
            <Col span={7}>
              <Form.Item name={'name'} label={'物品名称'}>
                <Input placeholder={'请输入'} allowClear />
              </Form.Item>
            </Col>
            <Col span={1} />
            <Col span={7}>
              <Form.Item name={'sku'} label={'SKU'}>
                <Input placeholder={'请输入'} allowClear />
              </Form.Item>
            </Col>
            <Col span={1} />
            <Col span={7}>
              <Form.Item name={'category'} label={'物品类型'}>
                <Select placeholder={'请选择'} allowClear>
                  {categoryList.map(item => (
                    <Option key={item.id} value={item.id}>
                      {item.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={1} />
          </Row>
          <Row justify={'end'}>
            <Space>
              <Button type={'primary'} htmlType={'submit'} onClick={() => form.setFieldsValue({pageNum: 1})}>
                查询
              </Button>
              <Button htmlType={'reset'}>重置</Button>
            </Space>
          </Row>
        </Form>
      </div>

      <div className={commonStyles.tableSection}>
        <Table
          rowKey={'materialId'}
          loading={tableLoading}
          columns={[
            {title: 'SKU', dataIndex: 'sku', key: 'sku'},
            {title: '物品名称', dataIndex: 'name', key: 'name'},
            {title: '物品类型', dataIndex: 'categoryName', key: 'categoryName'},
            {title: '单位', dataIndex: 'unit', key: 'unit'},
            {title: '规格', dataIndex: 'specification', key: 'specification'},
            {title: '品牌', dataIndex: 'brand', key: 'brand'},
            {title: '参考价', dataIndex: 'price', key: 'price', render: value => <span>{value}元</span>},
            {
              title: '操作',
              width: '220px',
              fixed: 'right',
              render: record => (
                <Space>
                  {renderOperate.map((item, index, array) => (
                    <div key={`${item.title} - ${index}`}>
                      <Button
                        type={'link'}
                        style={{padding: 0}}
                        onClick={() => item.onClick(record)}
                        disabled={!record.purchaseLink && item.title === '复制链接'}
                      >
                        {item.title}
                      </Button>
                      {array.length - 1 !== index && <Divider type={'vertical'} />}
                    </div>
                  ))}
                </Space>
              ),
            },
          ]}
          scroll={{x: 'max-content'}}
          dataSource={tableList}
          pagination={getPaginationProps(form, total)}
        />
      </div>

      <Modal
        centered
        visible={dele.visible}
        title={'提示'}
        onOk={() => materialDelete()}
        onCancel={() => setDele({visible: false, materialId: ''})}
      >
        <span>是否确认删除此条数据？</span>
      </Modal>

      <Modal
        centered
        visible={addEdit.visible}
        title={addEdit.title}
        width={'60%'}
        footer={null}
        onCancel={() => setAddEdit({visible: false})}
        afterClose={() => itemForm.resetFields()}
      >
        <Form
          form={itemForm}
          labelCol={{span: 8}}
          labelAlign={'left'}
          onFinish={() => materialUpsert()}
          initialValues={{online: 1}}
        >
          <Row gutter={80}>
            <Col span={12}>
              <Form.Item name={'category'} label={'物品类型'} rules={[{required: true, message: '请选择物品类型'}]}>
                <Select
                  placeholder={'请选择'}
                  onSelect={(value: string) => {
                    storageModel.stewardWarehouseAppMaterialSku({category: value}).then(({response: {data}}) => {
                      itemForm.setFieldsValue({sku: data.sku})
                    })
                  }}
                  allowClear
                  disabled={!!itemForm.getFieldValue('category')}
                >
                  {categoryList.map(item => (
                    <Option key={item.id} value={item.id}>
                      {item.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name={'name'} label={'物品名称'} rules={[{required: true, message: '请输入物品名称'}]}>
                <Input placeholder={'请输入'} allowClear />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name={'sku'} label={'SKU'} rules={[{required: true, message: '请输入SKU'}]}>
                <Input placeholder={'请输入'} disabled allowClear />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name={'unit'} label={'单位'} rules={[{required: true, message: '请输入单位'}]}>
                <Input placeholder={'请输入'} allowClear />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name={'specification'} label={'规格'}>
                <Input placeholder={'请输入（选填）'} allowClear />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name={'brand'} label={'品牌'}>
                <Input placeholder={'请输入（选填）'} allowClear />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name={'price'} label={'参考价'} rules={[{required: true, message: '请输入参考价'}]}>
                <InputNumber min={0} placeholder={'请输入'} style={{width: '100%'}} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name={'priceCheck'} label={'核算价'} rules={[{required: true, message: '请输入核算价'}]}>
                <InputNumber min={0} placeholder={'请输入'} style={{width: '100%'}} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name={'online'} label={'采购渠道'} rules={[{required: true, message: '请输入采购渠道'}]}>
                <Select
                  placeholder={'请选择'}
                  allowClear
                  options={[
                    {label: '线上', value: 1},
                    {label: '线下', value: 0},
                  ]}
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item label={'常用采购链接'}>
                <Row>
                  <Col flex={'auto'}>
                    <Form.Item noStyle name={'purchaseLink'}>
                      <Input placeholder={'请输入'} allowClear />
                    </Form.Item>
                  </Col>
                  <Col flex={'66px'}>
                    <Permission code={'013027002'}>
                      <Form.Item shouldUpdate>
                        {() => (
                          <Button
                            type={'link'}
                            disabled={!itemForm.getFieldValue('purchaseLink')}
                            onClick={() =>
                              navigator.clipboard.writeText(itemForm.getFieldValue('purchaseLink')).then(function () {
                                message.success('复制成功！')
                              })
                            }
                          >
                            复制
                          </Button>
                        )}
                      </Form.Item>
                    </Permission>
                  </Col>
                </Row>
              </Form.Item>
            </Col>
            <Col span={22}>
              <Form.Item label={'附件'} shouldUpdate labelCol={{span: 4}}>
                {from1 => {
                  const attach = from1.getFieldValue('attach')
                  return (
                    <Album
                      limit={1}
                      fileList={attach ? [{url: getImg(attach), uid: attach}] : []}
                      action={baseUrl + '/steward/warehouse/material/upload'}
                      onChange={info => {
                        if (info.file.status === 'done') {
                          const {
                            response: {data},
                          } = info.file
                          itemForm.setFieldsValue({attach: data.imgUrl})
                        }
                        if (info.file.status === 'removed') {
                          itemForm.setFieldsValue({attach: ''})
                        }
                      }}
                    >
                      {attach ? null : <UploadButton />}
                    </Album>
                  )
                }}
              </Form.Item>
            </Col>
            <Col span={22}>
              <Form.Item name={'remark'} label={'备注'} labelCol={{span: 4}}>
                <Input.TextArea placeholder={'请输入备注'} allowClear />
              </Form.Item>
            </Col>
          </Row>
          <Row justify={'end'} style={{marginTop: '40px'}}>
            <Space>
              <Button
                htmlType={'reset'}
                onClick={() => {
                  itemForm.resetFields()
                  setAddEdit({visible: false})
                }}
              >
                取消
              </Button>
              <Button type={'primary'} htmlType={'submit'} loading={upsertLoading}>
                保存
              </Button>
            </Space>
          </Row>
        </Form>
      </Modal>
    </Page>
  )
}
