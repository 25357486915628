import {baseUrl} from '@/common/config'
import {Album, UploadButton} from '@/components/Album'
import {useCodeArraySync, useCodeSync} from '@/store/models/Common'
import {customerModel} from '@/store/models/Customer'
import {teamModel} from '@/store/models/Team'
import {Button, Form, Input, InputNumber, message, Modal, Row, Select, Space, Switch} from 'antd'
import React, {useImperativeHandle, useState} from 'react'

interface CutServiceProps {
  onSuccess: () => void
}
function CutService({onSuccess}: CutServiceProps, ref) {
  const [visible, setVisible] = useState(false)
  const [form] = Form.useForm()
  const teamChargeCategory = useCodeArraySync('team.chargeCategory')
  const chargeCategory = useCodeSync('team.chargeCategory')

  useImperativeHandle(ref, () => ({
    add: async (orderInfo: StewardOrderQueryVO) => {
      const {customerId, customerName, teamPid, teamPname, orderId, feeTotal} = orderInfo
      const {
        response: {data},
      } = await customerModel.customerTeamGetTeamTree({ownerId: customerId})
      const {
        response: {
          data: {pointsRate},
        },
      } = await teamModel.teamPointsGetByTeamId({teamId: teamPid})
      const {balance, points} = data.find(item => item.teamId === teamPid) ?? {}
      form.setFieldsValue({
        customerName,
        teamPid,
        teamPname,
        balance,
        points,
        stewardOrderId: orderId,
        pointsRate,
        recordAmt: feeTotal ?? undefined,
        payAmt: feeTotal,
        pointsUse: false,
      })
      setVisible(true)
    },
  }))

  return (
    <Modal
      centered
      title={'服务单扣款'}
      visible={visible}
      footer={null}
      onCancel={() => {
        setVisible(false)
      }}
      width={560}
      afterClose={() => {
        form.resetFields()
      }}
    >
      <Form
        form={form}
        onFinish={async () => {
          const {
            teamPid,
            recordAmt,
            recordImgUrl,
            remark,
            pointsUse,
            pointsRecord,
            recordCategory,
            stewardOrderId,
          } = form.getFieldsValue(true)
          await teamModel.teamBalanceRecordInsert({
            teamPid,
            recordAmt,
            recordImgUrl,
            remark,
            pointsUse,
            pointsRecord,
            recordCategory,
            recordCategoryName: chargeCategory[recordCategory],
            stewardOrderId,
            recordPayType: '10',
            recordPayTypeName: '手动扣费',
          } as TeamBalanceRecordInsertDTO)
          onSuccess && onSuccess()
          message.success('添加扣款成功！')
          setVisible(false)
        }}
      >
        <Form.Item label={'客户名称'} shouldUpdate>
          {() => <span>{form.getFieldValue('customerName')}</span>}
        </Form.Item>
        <Form.Item label={'组织'} shouldUpdate>
          {() => <span>{form.getFieldValue('teamPname')}</span>}
        </Form.Item>
        <Form.Item label={'账户余额'} shouldUpdate>
          {() => <span>{form.getFieldValue('balance')}元</span>}
        </Form.Item>
        <Form.Item label={'果币数量'} shouldUpdate>
          {() => <span>{form.getFieldValue('points')}</span>}
        </Form.Item>
        <Form.Item
          label={'费用类型'}
          wrapperCol={{span: 15}}
          name={'recordCategory'}
          rules={[{required: true, message: '请选择费用类型'}]}
        >
          <Select placeholder={'请选择'} allowClear>
            {teamChargeCategory.map(item => (
              <Select.Option value={item.id} key={item.id}>
                {item.label}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label={'服务单号'} shouldUpdate>
          {() => <span>{form.getFieldValue('stewardOrderId')}</span>}
        </Form.Item>
        <Form.Item label={'扣款金额'} name={'recordAmt'} rules={[{required: true, message: '请输入扣款金额'}]}>
          <InputNumber placeholder={'请输入'} style={{width: 200}} />
        </Form.Item>
        <Form.Item label={'账户支付'} shouldUpdate>
          {() => {
            const recordAmt = form.getFieldValue('recordAmt')
            const pointsRecord = form.getFieldValue('pointsRecord') ?? 0
            const payAmt = recordAmt - pointsRecord / 10
            return <span>{payAmt}元</span>
          }}
        </Form.Item>
        <Form.Item
          label={'果币划扣'}
          valuePropName={'checked'}
          getValueProps={value => ({checked: value})}
          name={'pointsUse'}
        >
          <Switch onChange={check => form.setFieldsValue({pointsRecord: undefined})} />
        </Form.Item>
        <Form.Item shouldUpdate style={{marginBottom: 0}}>
          {() =>
            form.getFieldValue('pointsUse') ? (
              <>
                <Form.Item
                  label={'果币抵扣数量'}
                  name={'pointsRecord'}
                  rules={[
                    {required: true, message: '请输入果币抵扣数量'},
                    () => ({
                      validator: (_, value, callback) => {
                        const points = form.getFieldValue('points')
                        const recordAmt = form.getFieldValue('recordAmt')
                        if (value && value >= points) {
                          return Promise.reject(new Error('果币抵扣数量不能大于账户剩余数量'))
                        }
                        if (value && value / 10 > recordAmt) {
                          return Promise.reject(new Error('果币抵扣金额不能大于扣款金额'))
                        }
                        callback()
                      },
                    }),
                  ]}
                >
                  <InputNumber placeholder='请输入' style={{width: 200}} />
                </Form.Item>
                <Form.Item label={'果币抵扣金额'} shouldUpdate>
                  {() => (
                    <span>{form.getFieldValue('pointsRecord') ? form.getFieldValue('pointsRecord') / 10 : '0'}元</span>
                  )}
                </Form.Item>
              </>
            ) : null
          }
        </Form.Item>
        <Form.Item label={'扣款凭证'} shouldUpdate>
          {() => {
            const recordImgUrl = form.getFieldValue('recordImgUrl')
            return (
              <Album
                limit={1}
                accept={'.jpg, .jpeg, .png'}
                action={baseUrl + '/team/balance/upload-file'}
                onDone={fileList => {
                  const {imgUrl} = fileList?.[0] ?? {}
                  form.setFieldsValue({recordImgUrl: imgUrl})
                }}
              >
                {recordImgUrl ? null : <UploadButton />}
              </Album>
            )
          }}
        </Form.Item>
        <Form.Item label={'备注'} name={'remark'} wrapperCol={{span: 15}}>
          <Input.TextArea placeholder={'请输入备注'} allowClear rows={3} maxLength={200} />
        </Form.Item>
        <Row justify='end'>
          <Space>
            <Button onClick={() => setVisible(false)}>取消</Button>
            <Button type='primary' htmlType='submit'>
              确认
            </Button>
          </Space>
        </Row>
      </Form>
    </Modal>
  )
}

export default React.forwardRef(CutService)
