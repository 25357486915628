import React, {useState, useEffect} from 'react'
import {Select, Row, Col} from 'antd'
import {commonModel} from '@/store/models/Common'

import './index.less'

const {Option} = Select

interface Props {
  onChange?: (area: Partial<AreaProps>) => unknown
  type?: string[] //省市区街道显示控制
  disabled?: boolean
  value?: Partial<AreaProps>
}

interface OptionProps {
  children: string
  key: string
  parentcode: string
  value: string
}

interface AreaProps {
  province: string
  provinceCode: string
  city: string
  cityCode: string
  district: string
  districtCode: string
  bizCircle: string
  bizCircleCode: string
}

export default function AreaCascader({onChange, type, disabled, value}: Props) {
  const [province, setProvince] = useState<CityDataProps[]>()
  const [city, setCity] = useState<CityDataProps[]>()
  const [district, setDistrict] = useState<CityDataProps[]>()
  const [bizCircle, setBizCircle] = useState<CityDataProps[]>()
  const [area, setArea] = useState<Partial<AreaProps>>({})
  const loading = commonModel.queryCityCode.useLoading()

  useEffect(() => {
    commonModel.queryCityCode({type: '1', parentCode: '1'}).then(({response: {data}}) => {
      setProvince(data)
    })
  }, [])

  useEffect(() => {
    if (JSON.stringify(value) !== '{}') {
      setArea({
        ...value,
        // 转换字段名称
        city: value?.city ?? (value as any)?.cityName,
        district: value?.district ?? (value as any)?.districtName,
        province: value?.province ?? (value as any)?.provinceName,
        bizCircle: value?.bizCircle ?? (value as any)?.bizCircleName,
      })
      // if (value?.provinceCode)
      commonModel.queryCityCode({type: '2', parentCode: value?.provinceCode}).then(({response: {data}}) => {
        setCity(data)
      })

      if (value?.cityCode)
        commonModel.queryCityCode({type: '3', parentCode: value.cityCode}).then(({response: {data}}) => {
          setDistrict(data)
        })

      if (value?.districtCode)
        commonModel.queryCityCode({type: '4', parentCode: value.districtCode}).then(({response: {data}}) => {
          setBizCircle(data)
        })
    }
  }, [value])

  function handleSelect(type: 'province' | 'city' | 'district' | 'bizCircle', option: OptionProps | any) {
    const {value, children} = option ?? {}
    let newArea = {...area}
    const {province, provinceCode, city, cityCode, district, districtCode} = newArea
    switch (type) {
      case 'province':
        commonModel.queryCityCode({type: '2', parentCode: value}).then(({response: {data}}) => {
          setCity(data)
          setDistrict([])
          setBizCircle([])
          newArea = {
            province: children,
            provinceCode: value,
          }
          typeof onChange === 'function' && onChange(newArea)
          setArea(newArea)
        })
        break
      case 'city':
        commonModel.queryCityCode({type: '3', parentCode: value}).then(({response: {data}}) => {
          setDistrict(data)
          setBizCircle([])
          newArea = {
            province,
            provinceCode,
            city: children,
            cityCode: value,
          }
          typeof onChange === 'function' && onChange(newArea)
          setArea(newArea)
        })
        break
      case 'district':
        commonModel.queryCityCode({type: '4', parentCode: value}).then(({response: {data}}) => {
          setBizCircle(data)
          newArea = {
            province,
            provinceCode,
            city,
            cityCode,
            district: children,
            districtCode: value,
          }
          typeof onChange === 'function' && onChange(newArea)
          setArea({...newArea, district: children, districtCode: value})
        })
        break
      case 'bizCircle':
        newArea = {
          province,
          provinceCode,
          city,
          cityCode,
          district,
          districtCode,
          bizCircle: children,
          bizCircleCode: value,
        }
        typeof onChange === 'function' && onChange(newArea)
        setArea(newArea)
        break
      default:
        break
    }
  }

  return (
    <Row gutter={12} wrap={false}>
      {type.includes('province') && (
        <Col span={6}>
          <Select
            value={area?.provinceCode}
            onChange={(value: string, option: OptionProps | any) => handleSelect('province', option)}
            loading={loading}
            placeholder={'请选择省'}
            disabled={disabled}
            showSearch
            filterOption={(inputValue, option) => option?.children?.indexOf(inputValue) > -1}
            allowClear
          >
            {province?.map(item => (
              <Option key={item.code} value={item.code}>
                {item.name}
              </Option>
            ))}
          </Select>
        </Col>
      )}
      {type.includes('city') && (
        <Col span={6}>
          <Select
            value={area?.cityCode}
            onChange={(value: string, option: OptionProps | any) => handleSelect('city', option)}
            loading={loading}
            placeholder={'请选择市'}
            allowClear
            disabled={disabled}
            showSearch
            filterOption={(inputValue, option) => option?.children?.indexOf(inputValue) > -1}
          >
            {city?.map(item => (
              <Option key={item.code} value={item.code}>
                {item.name}
              </Option>
            ))}
          </Select>
        </Col>
      )}
      {type.includes('district') && (
        <Col span={6}>
          <Select
            value={area?.districtCode}
            onChange={(value: string, option: OptionProps | any) => handleSelect('district', option)}
            disabled={disabled}
            loading={loading}
            placeholder={'请选择区'}
            allowClear
            showSearch
            filterOption={(inputValue, option) => option?.children?.indexOf(inputValue) > -1}
          >
            {district?.map(item => (
              <Option key={item.code} value={item.code}>
                {item.name}
              </Option>
            ))}
          </Select>
        </Col>
      )}
      {type.includes('bizCircle') && (
        <Col span={6}>
          <Select
            value={area?.bizCircleCode}
            onChange={(value: string, option: OptionProps | any) => handleSelect('bizCircle', option)}
            disabled={disabled}
            loading={loading}
            placeholder={'请选择商圈'}
            allowClear
            showSearch
            filterOption={(inputValue, option) => option?.children?.indexOf(inputValue) > -1}
          >
            {bizCircle?.map(item => (
              <Option key={item.code} value={item.code}>
                {item.name}
              </Option>
            ))}
          </Select>
        </Col>
      )}
    </Row>
  )
}

AreaCascader.defaultProps = {
  type: ['province', 'city', 'district', 'bizCircle'],
}

export function addressValid(rule: any, value: any, callback: Function) {
  if (!value) {
    callback('地址不能为空')
    return
  }

  if (!value?.cityCode) {
    callback('城市不能为空')
    return
  }

  if (!value?.districtCode) {
    callback('区域不能为空')
    return
  }

  // if (!value?.bizCircleCode) {
  //   callback('商圈不能为空')
  //   return
  // }

  callback()
}
