export const isProd = process.env.REACT_APP_ENV === 'prod'

export const serverUrl = isProd ? 'https://service.nyg8.com' : 'https://service-uat.nyg8.com'

export const baseUrl = serverUrl + '/admin'
export const bizUrl = serverUrl + '/biz'

// 文件地址
export const fileUrl = isProd // process.env.NODE_ENV === 'production'
  ? 'https://oss.nyg8.com/'
  : 'https://oss-uat.nyg8.com/'
export const ossUrl = fileUrl

export const uploadImgUrl =
  process.env.NODE_ENV === 'production'
    ? 'https://service.nyg8.com/admin/file/upload'
    : 'http://localhost:5001/admin/file/upload'

export const serviceListTempUrl =
  process.env.NODE_ENV === 'production'
    ? 'https://oss.nyg8.com/steward/%E7%AE%A1%E5%AE%B6%E6%9C%8D%E5%8A%A1%E5%8D%95%E5%AF%BC%E5%85%A5.xlsx'
    : 'https://oss-uat.nyg8.com/steward/%E7%AE%A1%E5%AE%B6%E6%9C%8D%E5%8A%A1%E5%8D%95%E5%AF%BC%E5%85%A5.xlsx'

// 报告地址
export const inspectUrl = `https://static.avocadocondo.com/inspect${!isProd ? '-uat' : ''}/index.html#`

export const wss = isProd ? 'wss://service.nyg8.com/push/entry' : 'wss://service-uat.nyg8.com/push/entry'

export const ak = '2beA3i1trGn6Rib1XgNwOyHWbl8HlPt9'

export const dashboardUrl = isProd ? 'http://dashboard.nyg8.com' : 'http://dashboard-uat.nyg8.com'
