import React, {useEffect} from 'react'
import {Page} from '@/components/Page'
import commonStyle from '@/assets/styles/common.module.less'
import {Button, DatePicker, Form, Input, message, PageHeader, Row, Space, Switch} from 'antd'
import {stewardModel} from '@/store/models/Steward'
import {useAppHistory, useAppLocation} from '@/common/hooks/useRouter'
import moment from 'moment'
import {mobileValid} from '@/utils/util'

export default function AddOpenApp() {
  const history = useAppHistory()
  const location = useAppLocation<'/open-app-list/add'>()
  const appId = location.state?.appId
  const [form] = Form.useForm()

  useEffect(() => {
    if (appId) {
      stewardModel.stewardOpenAppDetail({appId}).then(({response: {data}}) => {
        form.setFieldsValue({
          appId,
          appName: data.appName,
          registerUname: data.registerUname,
          registerMobile: data.registerMobile,
          appSecret: data.appSecret,
          _time: [moment(data.validBeginDate), moment(data.validEndDate)],
          validBeginDate: data.validBeginDate,
          validEndDate: data.validEndDate,
          remark: data.remark,
          distanceCheck: data.distanceCheck,
          timeCheck: data.timeCheck,
          sceneCheck: data.sceneCheck,
        })
      })
    }
  }, [appId, form])

  return (
    <Page header={<PageHeader title={'返回'} ghost={false} onBack={() => history.goBack()} />}>
      <div className={commonStyle.filterSection}>
        <Form
          form={form}
          initialValues={{distanceCheck: 0, timeCheck: 0, sceneCheck: 0}}
          labelCol={{span: 8}}
          wrapperCol={{span: 10}}
          onFinish={async () => {
            const formValues = form.getFieldsValue(true)
            if (appId) {
              await stewardModel.stewardOpenAppUpdate({...formValues} as StewardOpenAppUpsertDTO)
            } else {
              await stewardModel.stewardOpenAppInsert({...formValues} as StewardOpenAppUpsertDTO)
            }
            message.success('提交成功！')
            history.goBack()
          }}
        >
          <Form.Item label={'接入方名称'} name={'appName'} rules={[{required: true, message: '请输入接入方名称'}]}>
            <Input placeholder={'请输入'} disabled={!!appId} />
          </Form.Item>
          <Form.Item label={'负责人'} name={'registerUname'} rules={[{required: true, message: '请输入负责人'}]}>
            <Input placeholder={'请输入'} />
          </Form.Item>
          <Form.Item label={'手机号'} name={'registerMobile'} rules={[{validator: mobileValid}]} required>
            <Input placeholder={'请输入'} />
          </Form.Item>
          {!!appId && (
            <>
              <Form.Item label={'应用ID'} name={'appId'} rules={[{required: true, message: '应用ID'}]}>
                <Input placeholder={'请输入'} maxLength={32} disabled={!!appId} />
              </Form.Item>
              <Form.Item label={'应用密钥'} name={'appSecret'} rules={[{required: true, message: '应用秘钥'}]}>
                <Input placeholder={'请输入'} maxLength={32} disabled={!!appId} />
              </Form.Item>
            </>
          )}
          <Form.Item label={'有效期'} name={'_time'} rules={[{required: true, message: '请选择有效期'}]}>
            <DatePicker.RangePicker
              onChange={(_, dateStrings) => {
                const [validBeginDate, validEndDate] = dateStrings ?? []
                form.setFieldsValue({validEndDate, validBeginDate})
              }}
            />
          </Form.Item>
          <Form.Item
            label={'开始服务1km校验'}
            name={'distanceCheck'}
            valuePropName={'checked'}
            getValueFromEvent={value => (value ? 1 : 0)}
            getValueProps={value => ({checked: value === 1})}
          >
            <Switch />
          </Form.Item>
          <Form.Item
            label={'完成服务5分钟限制'}
            name={'timeCheck'}
            valuePropName={'checked'}
            getValueFromEvent={value => (value ? 1 : 0)}
            getValueProps={value => ({checked: value === 1})}
          >
            <Switch />
          </Form.Item>
          <Form.Item
            label={'必须现场拍照'}
            name={'sceneCheck'}
            valuePropName={'checked'}
            getValueFromEvent={value => (value ? 1 : 0)}
            getValueProps={value => ({checked: value === 1})}
          >
            <Switch />
          </Form.Item>
          <Form.Item label={'备注'} name={'remark'}>
            <Input.TextArea maxLength={200} showCount rows={3} placeholder={'请输入'} />
          </Form.Item>
          <Row justify={'center'}>
            <Space>
              <Button type={'primary'} htmlType={'submit'}>
                提交
              </Button>
              <Button onClick={() => history.goBack()}>取消</Button>
            </Space>
          </Row>
        </Form>
      </div>
    </Page>
  )
}
