import React, {CSSProperties} from 'react'
import {Breadcrumb as AntBreadcrumb, Col, Row} from 'antd'
import {useBreadcrumb} from '@/common/hooks/useBreadcrumb'
import styles from './index.module.less'
import {Route} from 'antd/lib/breadcrumb/Breadcrumb'
import {Link} from 'react-router-dom'

export interface BreadcrumbProps {
  right?: React.ReactNode
  path?: string[]
  className?: string
  style?: CSSProperties
}

export const Breadcrumb: React.FC<BreadcrumbProps> = props => {
  const routes = useBreadcrumb()
  const renderRoutes = props.path ? props.path.map(value => ({path: null, breadcrumbName: value})) : routes

  return (
    <Row
      justify={'space-between'}
      align={'middle'}
      className={`${styles.breadcrumb} ${props.className}`}
      style={props.style}
    >
      <Col>
        <AntBreadcrumb routes={renderRoutes} itemRender={itemRender} />
      </Col>
      {!!props.right && <Col>{props.right}</Col>}
    </Row>
  )
}

Breadcrumb.defaultProps = {className: ''}

function getBreadcrumbName(route: Route, params: any) {
  if (!route.breadcrumbName) {
    return null
  }
  const paramsKeys = Object.keys(params).join('|')
  return route.breadcrumbName.replace(
    new RegExp(`:(${paramsKeys})`, 'g'),
    (replacement, key) => params[key] || replacement
  )
}

export function itemRender(route: Route, params: any, routes: Route[], paths: string[]) {
  const isLastItem = routes.indexOf(route) === routes.length - 1
  const name = getBreadcrumbName(route, params)
  return isLastItem || !route.path ? <span>{name}</span> : <Link to={route.path}>{name}</Link>
}
