import React, {useCallback, useEffect, useState} from 'react'
import {Page} from '@/components/Page'
import commonStyles from '@/assets/styles/common.module.less'
import {Button, Col, Form, Input, message, Modal, Row, Select, Space, Table, Tooltip} from 'antd'
import {Breadcrumb} from '@/components/Breadcrumb'
import {getPaginationProps} from '@/common/biz/getPaginationProps'
import {ebassModel} from '@/store/models/Ebaas'
import {useAppHistory} from '@/common/hooks/useRouter'
import {arrayToObject} from '@/store/models/Common'
import ebaas from '@/store/models/Common/ebaasCodes'

const syncStatus = arrayToObject(ebaas.syncStatus)
export default function PromotionManagement() {
  const history = useAppHistory()
  const [form] = Form.useForm()
  const [recordVisible, setRecordVisible] = useState(false)
  const [list, setList] = useState<EbaasRoomVO[]>([])
  const [apartmentList, setApartmentList] = useState<EbaasApartmentVO[]>([])
  const [recordList, setRecordList] = useState<EbaasRoomHistoryVO[]>([])
  const [total, setTotal] = useState(0)
  const [title, setTitle] = useState('')
  const [ids, setIds] = useState<EbaasRoomVO[]>([])
  const search = useCallback(() => {
    const values = form.getFieldsValue(true)
    ebassModel.ebaasRoomList(values).then(({response: {data, total}}) => {
      setList(data)
      setTotal(total)
    })
  }, [form])
  const loading = ebassModel.ebaasRoomSubmit.useLoadings()

  useEffect(() => {
    form.submit()

    ebassModel
      .ebaasApartmentList({pageNum: 1, pageSize: 10, pageable: false} as EbaasApartmentListDTO)
      .then(({response: {data}}) => {
        setApartmentList(data)
      })
  }, [form])

  return (
    <Page
      breadcrumb={
        <Breadcrumb
          right={
            <Space>
              <Button
                type={'primary'}
                onClick={async () => {
                  if (!ids.length) return message.warning('请选择要上架的房源！')
                  // if (ids.some(item => item.syncStatus === '1'))
                  //   return message.warning('所选房源有上架的房源，不可进行批量上架！')
                  await ebassModel.ebaasRoomSubmit({
                    propertyCode: ids.map(item => item.propertyCode).join(','),
                    syncStatus: '1',
                  })
                  setIds([])
                  search()
                }}
              >
                批量上架
              </Button>
              <Button
                onClick={async () => {
                  if (!ids.length) return message.warning('请选择要下架的房源！')
                  // if (ids.some(item => item.syncStatus === '0'))
                  //   return message.warning('所选房源有下架的房源，不可进行批量下架！')
                  await ebassModel.ebaasRoomSubmit({
                    propertyCode: ids.map(item => item.propertyCode).join(','),
                    syncStatus: '0',
                  })
                  setIds([])
                  search()
                }}
              >
                批量下架
              </Button>
            </Space>
          }
        />
      }
    >
      <div className={commonStyles.filterSection}>
        <Form
          form={form}
          initialValues={{pageNum: 1, pageSize: 10, pageable: true}}
          onFinish={search}
          onReset={search}
          labelAlign={'left'}
          labelCol={{flex: '100px'}}
        >
          <Row>
            <Col span={24}>
              <Form.Item label={'平台'} shouldUpdate>
                {() => (
                  <Space>
                    <Button type={'primary'}>天猫好房</Button>
                  </Space>
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={7}>
              <Form.Item name={'name'} label={'房间号'}>
                <Input placeholder='请输入' allowClear />
              </Form.Item>
            </Col>
            <Col span={7} offset={1}>
              <Form.Item label={'公寓选择'} name={'condoId'} labelCol={{}}>
                <Select allowClear placeholder={'请选择'}>
                  {apartmentList.map(item => (
                    <Select.Option value={item.condoId} key={item.condoId}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={7} offset={1}>
              <Form.Item name={'syncStatus'} label={'平台上架状态'}>
                <Select allowClear placeholder={'请选择'}>
                  <Select.Option value={'1'}>上架</Select.Option>
                  <Select.Option value={'0'}>下架</Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row justify={'end'}>
            <Space>
              <Button htmlType={'reset'}>重置</Button>
              <Button type={'primary'} htmlType={'submit'} onClick={() => form.setFieldsValue({pageNum: 1})}>
                查询
              </Button>
            </Space>
          </Row>
        </Form>
      </div>

      <div className={commonStyles.filterSection}>
        <Table
          rowKey={'propertyCode'}
          dataSource={list}
          rowSelection={{
            selectedRowKeys: ids.map(item => item.propertyCode),
            onChange(selectedRowKeys, selectedRows) {
              setIds(selectedRows)
            },
          }}
          scroll={{x: 'max-content'}}
          columns={[
            {title: '公寓', dataIndex: 'condoName'},
            {title: '房间号', dataIndex: 'name'},
            {title: '牛油果平台上架状态', dataIndex: 'syncStatus', render: text => syncStatus[text] || '/'},
            {title: '审核状态', dataIndex: 'checkStatus', render: text => text || '/'},
            {
              title: '拒绝原因',
              dataIndex: 'checkReason',
              render: text =>
                text ? (
                  <Tooltip title={text}>
                    <span>{text.slice(0, 5) + '...'}</span>
                  </Tooltip>
                ) : (
                  '/'
                ),
            },
            {title: '天猫更新时间', dataIndex: 'latestSyncTime', render: text => text || '/'},
            {title: '天猫上架状态', dataIndex: 'aliStatus', render: text => text || '/'},
            {
              title: '操作',
              fixed: 'right',
              width: 200,
              render: record => {
                return (
                  <Space>
                    <Button
                      style={{paddingLeft: 0, paddingRight: 0}}
                      onClick={async () => {
                        await ebassModel.ebaasRoomSubmit({propertyCode: record.propertyCode, syncStatus: '1'})
                        search()
                        message.success('房源上架成功！')
                      }}
                      loading={loading.pick(record.propertyCode)}
                      type={'link'}
                    >
                      上架
                    </Button>
                    <Button
                      style={{paddingLeft: 0, paddingRight: 0}}
                      type={'link'}
                      onClick={async () => {
                        await ebassModel.ebaasRoomSubmit({propertyCode: record.propertyCode, syncStatus: '0'})
                        search()
                        message.success('房源下架成功！')
                      }}
                      loading={loading.pick(record.propertyCode)}
                    >
                      下架
                    </Button>
                    <Button
                      style={{paddingLeft: 0, paddingRight: 0}}
                      type={'link'}
                      onClick={() => {
                        history.push({
                          pathname: '/promotion/property-info',
                          state: {
                            propertyCode: record.propertyCode,
                            templateId: record.templateId,
                            condoId: record.condoId,
                          },
                        })
                      }}
                    >
                      查看
                    </Button>
                    <Button
                      style={{paddingLeft: 0, paddingRight: 0}}
                      type={'link'}
                      onClick={async () => {
                        const {
                          response: {data},
                        } = await ebassModel.ebaasRoomHistory({propertyCode: record.propertyCode})
                        setTitle(record.name)
                        setRecordList(data)
                        setRecordVisible(true)
                      }}
                    >
                      历史记录
                    </Button>
                  </Space>
                )
              },
            },
          ]}
          pagination={getPaginationProps(form, total)}
        />
      </div>

      <Modal
        width={687}
        title={'历史记录'}
        visible={recordVisible}
        centered
        footer={null}
        onCancel={() => setRecordVisible(false)}
      >
        <span style={{fontSize: '16px', fontWeight: 'bold', marginBottom: '15px', display: 'block'}}>{title}</span>
        <Table
          rowKey={'historyId'}
          columns={[
            {title: '操作', dataIndex: 'syncStatus', render: text => syncStatus[text]},
            {title: '时间', dataIndex: 'updateTime'},
            {title: '操作人', dataIndex: 'updateName'},
          ]}
          dataSource={recordList}
          pagination={{position: ['bottomCenter']}}
        />
      </Modal>
    </Page>
  )
}
