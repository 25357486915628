import type {TablePaginationConfig} from 'antd/lib/table/interface'
import type {FormInstance} from 'antd'

/**
 * 回车键会触发 htmlType={'submit'} 按钮的 onClick 事件
 * form.submit() 是否会触发 htmlType={'submit'} 按钮的 onClick 事件？（不会）
 */
export function getPaginationProps(
  form: FormInstance,
  total: number,
  config = {} as TablePaginationConfig
): TablePaginationConfig {
  return {
    total: total,
    // 更新 dataSource 会帮我们更新 pageSize 和 current，重置也会更新 dataSource，所以 pageSize 和 current 能正常显示
    // 也解决另外一个问题，如果 table 也用表单的话，查询表单和 table 表单互不影响
    pageSize: form.getFieldValue('pageSize'),
    current: form.getFieldValue('pageNum'),
    position: ['bottomCenter'],
    hideOnSinglePage: false,
    showSizeChanger: true,
    onChange: (pageNum, pageSize) => {
      form.setFieldsValue({pageNum, pageSize})
      form.submit()
    },
    ...config,
  }
}
