import React, {useCallback, useState} from 'react'
import {customerModel} from '@/store/models/Customer'
import {sysModel} from '@/store/models/Sys'
import {debounce, delay} from '@/utils/util'
import {Button, Col, DatePicker, Form, Modal, Row, Select, Space} from 'antd'
import moment from 'moment'
import {userModel} from '@/store/models/User'

interface UpdateProps {
  reportId: string
}

// 服务单报表
function _StewardOrderReport({reportId}: UpdateProps, ref) {
  const [form] = Form.useForm()
  //客户列表
  const [clientList, setClientList] = useState<LessorCustomerListVO[]>([])

  const searchClientList = useCallback(
    debounce(async value => {
      if (value) {
        const {
          response: {data},
        } = await customerModel.customerList({
          certName: value,
          pageSize: 10,
          pageable: false,
          status: '1',
          managerOrgId: userModel.data.userInfo?.orgId,
        } as LessorCustomerListDTO)
        setClientList(data)
      } else {
        setClientList([])
      }
    }),
    []
  )

  return (
    <Form
      form={form}
      onFinish={async () => {
        const {insertTimeBegin, insertTimeEnd, ...formValues} = form.getFieldsValue(true)
        // 管家服务统计报表
        if (reportId === '1')
          await sysModel.sysReportExportExcel1({
            ...formValues,
            insertTimeBegin: insertTimeBegin + ' 00:00:00',
            insertTimeEnd: insertTimeEnd + ' 23:59:59',
            reportId,
          })

        // 管家服务单报表
        if (reportId === '2')
          await sysModel.sysReportExportExcel2({
            ...formValues,
            insertTimeBegin: insertTimeBegin + ' 00:00:00',
            insertTimeEnd: insertTimeEnd + ' 23:59:59',
            reportId,
          })
        Modal.success({
          title: '提示',
          content: '表格正在导出，稍后请前往“下载中心”进行下载',
          icon: null,
          centered: true,
          okText: '关闭',
        })
        await delay(1000)
        form.resetFields()
      }}
      labelCol={{flex: '80px'}}
    >
      <Row>
        <Col>
          <Form.Item label={'下单日期'} name={'_time'} rules={[{required: true, message: '请选择下单日期'}]}>
            <DatePicker.RangePicker
              disabledDate={current => {
                return current >= moment().endOf('day')
              }}
              onChange={(_, valueStrings) => {
                const [insertTimeBegin, insertTimeEnd] = valueStrings ?? []
                form.setFieldsValue({insertTimeBegin, insertTimeEnd})
              }}
            />
          </Form.Item>
        </Col>
        <Col offset={1} span={8}>
          <Form.Item
            label={'房东姓名'}
            name={'customerId'}
            rules={[{required: reportId === '2', message: '请选择房东姓名'}]}
          >
            <Select
              placeholder={'请选择'}
              showSearch
              onSearch={searchClientList}
              onSelect={(value, option) => {
                form.setFieldsValue({customerName: option.children?.[0]})
              }}
              filterOption={false}
              allowClear
            >
              {clientList.map(item => (
                <Select.Option value={item.userId} key={item.userId}>
                  {item.certName}（{item.mobile}）
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Form.Item label={' '} style={{marginTop: 20}} colon={false}>
        <Space>
          <Button type={'primary'} htmlType={'submit'}>
            导出
          </Button>
          <Button htmlType={'reset'}>重置</Button>
        </Space>
      </Form.Item>
    </Form>
  )
}

const StewardOrderReport = React.forwardRef(_StewardOrderReport)

export default StewardOrderReport
