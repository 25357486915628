import React, {useCallback, useEffect, useState} from 'react'
import {Page} from '@/components/Page'
import commonStyles from '@/assets/styles/common.module.less'
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Descriptions,
  Form,
  Input,
  message,
  Modal,
  Radio,
  Row,
  Select,
  Space,
  Table,
  Tooltip,
} from 'antd'
import {sysModel} from '@/store/models/Sys'
import {getPaginationProps} from '@/common/biz/getPaginationProps'
import {useCodeArraySync, useCodeSync} from '@/store/models/Common'
import {Breadcrumb} from '@/components/Breadcrumb'
import locale from 'antd/es/date-picker/locale/zh_CN'
import moment from 'moment'
import {usePermission} from '@/components/Permission'

const statusArr = [
  {label: '全部', value: ''},
  {label: '有效未发布', value: '1'},
  {label: '无效', value: '0'},
  {label: '有效已发布', value: '2'},
]

const statusCode = {'1': '有效未发布', '0': '无效', '2': '有效已发布'}

export default function PublishNotificationList() {
  const publishTypeArr = useCodeArraySync('sys.publish.type', {defaultItem: true})
  const publishTypeCode = useCodeSync('sys.publish.type')
  // console.log('publishTypeArr', publishTypeArr)

  const [auth] = usePermission()

  const [form] = Form.useForm<SysAppPublishListDTO>()
  const [aForm] = Form.useForm()

  const [publishList, setPublishList] = useState<SysAppPublishVO[]>([])
  const [publishTotal, setPublishTotal] = useState(0)

  const queryPublishList = useCallback(() => {
    const formValues = form.getFieldsValue(true)
    sysModel.sysAppPublishList(formValues).then(({response: {data, total}}) => {
      setPublishList(data)
      setPublishTotal(total)
    })
  }, [form])

  useEffect(() => {
    queryPublishList()
  }, [queryPublishList])

  const listLoading = sysModel.sysAppPublishList.useLoading()

  function onPublishUpsert() {
    Modal.confirm({
      title: '新增',
      icon: null,
      centered: true,
      closable: true,
      content: (
        <Form form={aForm}>
          <Form.Item name={'status'} label={'状态'} rules={[{required: true, message: '请选择状态'}]}>
            <Radio.Group>
              <Radio value={'1'}>有效</Radio>
              <Radio value={'0'}>无效</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            name={'publishType'}
            label={'类型'}
            rules={[{required: true, message: '请选择类型'}]}
            getValueFromEvent={value => {
              if (value.length) {
                return value.join(',')
              } else return value
            }}
          >
            <Checkbox.Group>
              {publishTypeArr
                .filter(item => !!item.id)
                .map(item => (
                  <Checkbox key={item.id} value={item.id}>
                    {item.label}
                  </Checkbox>
                ))}
            </Checkbox.Group>
          </Form.Item>
          <Form.Item name={'publishIndex'} label={'版本号'} rules={[{required: true, message: '请输入版本号'}]}>
            <Input placeholder={'请输入'} maxLength={12} />
          </Form.Item>
          <Form.Item
            name={'publishTime'}
            label={'发布时间'}
            rules={[{required: true, message: '请选择发布时间'}]}
            getValueProps={value => ({value: moment(value)})}
            getValueFromEvent={value => value?.format('YYYY-MM-DD HH:mm:00')}
          >
            <DatePicker
              locale={locale}
              format={'YYYY-MM-DD HH:mm'}
              showTime={{format: 'YYYY-MM-DD HH:mm'}}
              disabledDate={date => new Date(date.toDate()).getTime() < new Date().getTime()}
            />
          </Form.Item>
          <Form.Item name={'publishRemark'} label={'描述'} rules={[{required: true, message: '请输入描述'}]}>
            <Input.TextArea placeholder={'请输入'} maxLength={800} showCount />
          </Form.Item>
        </Form>
      ),
      onOk: async () => {
        await aForm.validateFields()
        const formValues = aForm.getFieldsValue(true)
        // console.log('formValues', formValues)
        await sysModel.sysAppPublishUpsert(formValues)
        message.success('新增成功！')
        form.submit()
      },
      afterClose: () => aForm.resetFields(),
    })
  }

  return (
    <Page
      breadcrumb={
        <Breadcrumb
          right={
            auth('003204003') && (
              <Button
                type={'primary'}
                onClick={() => {
                  onPublishUpsert()
                }}
              >
                新增通知
              </Button>
            )
          }
        />
      }
    >
      <div className={commonStyles.filterSection}>
        <Form
          form={form}
          initialValues={{pageNum: 1, pageSize: 10, pageable: true}}
          onFinish={queryPublishList}
          onReset={queryPublishList}
        >
          <Row gutter={20}>
            <Col span={8}>
              <Form.Item name={'publishIndex'} label={'版本'}>
                <Input placeholder={'请输入'} allowClear />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name={'status'} label={'状态'}>
                <Select placeholder={'请选择'} options={statusArr} allowClear />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name={'publishType'} label={'类型'}>
                <Select placeholder={'请选择'} allowClear>
                  {publishTypeArr.map(item => (
                    <Select.Option key={item.id} value={item.id}>
                      {item.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row justify={'end'}>
            <Space>
              <Button type={'primary'} htmlType={'submit'} onClick={() => form.setFieldsValue({pageNum: 1})}>
                查询
              </Button>
              <Button htmlType={'reset'}>重置</Button>
            </Space>
          </Row>
        </Form>
      </div>

      <div className={commonStyles.tableSection}>
        <Table
          rowKey={'id'}
          loading={listLoading}
          dataSource={publishList}
          columns={[
            {title: '版本号', dataIndex: 'publishIndex'},
            {
              title: '类型',
              dataIndex: 'publishType',
              render: value =>
                value
                  ? value
                      .split(',')
                      .map(item => publishTypeCode[item])
                      .join(',')
                  : '/',
            },
            {
              title: '描述',
              dataIndex: 'publishRemark',
              render: value => (
                <Tooltip title={value}>
                  <span>{value.length > 20 ? value.slice(0, 20) + '...' : value}</span>
                </Tooltip>
              ),
            },
            {title: '发布时间', dataIndex: 'publishTime'},
            {title: '状态', dataIndex: 'status', render: value => statusCode[value]},
            {title: '修改人', dataIndex: 'insertBy'},
            {title: '修改时间', dataIndex: 'updateTime'},
            {
              title: '操作',
              fixed: 'right',
              dataIndex: 'id',
              render: (value, record) => {
                return (
                  <Space>
                    <Button
                      type={'link'}
                      style={{padding: 0}}
                      onClick={() => {
                        Modal.confirm({
                          title: '查看',
                          icon: null,
                          centered: true,
                          closable: true,
                          width: '50%',
                          content: (
                            <Descriptions column={2} style={{maxHeight: '400px', overflow: 'scroll'}}>
                              <Descriptions.Item label={'状态'}>{statusCode[record.status]}</Descriptions.Item>
                              <Descriptions.Item label={'类型'}>
                                {record.publishType
                                  ? record.publishType
                                      .split(',')
                                      .map(item => publishTypeCode[item])
                                      .join(',')
                                  : '/'}
                              </Descriptions.Item>
                              <Descriptions.Item label={'版本号'}>{record.publishIndex}</Descriptions.Item>
                              <Descriptions.Item label={'发布时间'}>{record.publishTime}</Descriptions.Item>
                              <Descriptions.Item label={'描述'}>
                                <pre>{record.publishRemark}</pre>
                              </Descriptions.Item>
                            </Descriptions>
                          ),
                        })
                      }}
                    >
                      查看
                    </Button>
                    {auth('002204002') && record.status !== '2' && (
                      <Button
                        type={'link'}
                        style={{padding: 0}}
                        onClick={() => {
                          aForm.setFieldsValue(record)
                          onPublishUpsert()
                        }}
                      >
                        编辑
                      </Button>
                    )}
                  </Space>
                )
              },
            },
          ]}
          scroll={{x: 'max-content'}}
          pagination={getPaginationProps(form, publishTotal)}
        />
      </div>
    </Page>
  )
}
