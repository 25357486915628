import React, {useEffect} from 'react'
import {Page} from '@/components/Page'
import {Button, Cascader, DatePicker, Form, Input, message, Select, Space} from 'antd'
import commonStyles from '@/assets/styles/common.module.less'
import {Album, UploadButton} from '@/components/Album'
import {baseUrl} from '@/common/config'
import {sysModel} from '@/store/models/Sys'
import {getImg} from '@/utils/util'
import {useAppHistory, useAppLocation} from '@/common/hooks/useRouter'
import moment from 'moment'
import BackButton from '@/components/BackButton'
import * as listener from '@/common/hooks/listener'

export default function AddBanner() {
  const location = useAppLocation<'/content/add-banner'>()
  const bannerId = location.state?.bannerId
  const history = useAppHistory()
  const [form] = Form.useForm()

  useEffect(() => {
    if (bannerId) {
      sysModel.sysBannerInfo({bannerId}).then(({response: {data}}) => {
        const {platforms, limitTimeBegin, limitTimeEnd, ...formValues} = data
        form.setFieldsValue({
          _platforms: platforms === '05' ? ['小程序', platforms] : ['app', platforms],
          platforms,
          _time: [moment(limitTimeBegin), moment(limitTimeEnd)],
          ...formValues,
          limitTimeBegin,
          limitTimeEnd,
        })
      })
    }
  }, [bannerId, form])

  return (
    <Page>
      <BackButton />
      <div className={commonStyles.filterSection}>
        <Form
          form={form}
          labelCol={{span: 4}}
          wrapperCol={{span: 8}}
          initialValues={{location: '首页'}}
          onFinish={async () => {
            const {url, ...formValues} = form.getFieldsValue(true)
            if (!url) return message.success('请上传图片！')
            await sysModel.sysBannerSave({url, ...formValues})
            listener.emit('ContentOperateManage:bannerId')
            history.goBack()
          }}
        >
          <Form.Item label={'应用名称'} name={'_platforms'} rules={[{required: true, message: '请选择应用名称'}]}>
            <Cascader
              disabled={!!bannerId}
              expandTrigger={'hover'}
              options={[
                {
                  label: 'app',
                  value: 'app',
                  children: [
                    {label: '牛油果管家App', value: '02'},
                    {label: '牛油果房东App', value: '03'},
                  ],
                },
                {
                  label: '小程序',
                  value: '小程序',
                  children: [{label: '牛油果管家小程序', value: '05'}],
                },
              ]}
              displayRender={label => label[label.length - 1]}
              onChange={value => {
                form.setFieldsValue({platforms: value[value.length - 1]})
              }}
            />
          </Form.Item>
          <Form.Item label={'banner名称'} name={'name'} rules={[{required: true, message: '请输入banner名称'}]}>
            <Input placeholder='请输入' disabled={!!bannerId} allowClear />
          </Form.Item>
          <Form.Item label={'展示位置'} shouldUpdate>
            {() => <span>首页</span>}
          </Form.Item>
          <Form.Item label={'权重'} name={'weight'} rules={[{required: true, message: '请选择权重'}]}>
            <Select placeholder={'请选择'} allowClear>
              {Array(6)
                .fill(1)
                .map((_, index) => index + 1)
                .map(item => (
                  <Select.Option value={item} key={item}>
                    {item}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item label={'url'} name={'targetUrl'} rules={[{required: true, message: '请输入url'}]}>
            <Input placeholder='请输入' allowClear />
          </Form.Item>
          <Form.Item required label={'图片'} shouldUpdate>
            {() => {
              const url = form.getFieldValue('url')
              return (
                <Space direction={'vertical'}>
                  <Album
                    limit={1}
                    fileList={url ? [{url: getImg(url), uid: url}] : []}
                    accept={'.jpg, .png, .jpeg'}
                    action={baseUrl + '/sys-banner/upload-file'}
                    onDone={fileList => {
                      form.setFieldsValue({url: fileList?.[0]?.imgUrl})
                    }}
                    beforeUpload={(file, fileList) => {
                      return new Promise((resolve, reject) => {
                        const img = new Image()
                        const _URL = URL || webkitURL
                        img.onload = function () {
                          const valid = img.width === 702 && img.height === 240
                          if (!valid) {
                            message.warning('图片尺寸：702*240px！')
                            return
                          } else {
                            resolve()
                          }
                        }
                        img.src = _URL.createObjectURL(file)
                      })
                    }}
                  >
                    {url ? null : <UploadButton />}
                  </Album>
                  <span style={{color: 'rgba(0, 0, 0, 0.65)'}}>图片尺寸：702*240px</span>
                </Space>
              )
            }}
          </Form.Item>
          <Form.Item label={'生效周期'} rules={[{required: true, message: '请选择生效周期'}]} name={'_time'}>
            <DatePicker.RangePicker
              disabledDate={current => current && current < moment().startOf('day')}
              onChange={(_, dateStrings) => {
                form.setFieldsValue({
                  limitTimeBegin: dateStrings?.[0],
                  limitTimeEnd: dateStrings?.[1],
                })
              }}
            />
          </Form.Item>
          <Form.Item label={' '} colon={false}>
            <Space>
              <Button type={'primary'} htmlType={'submit'}>
                提交
              </Button>
              <Button onClick={() => history.goBack()}>取消</Button>
            </Space>
          </Form.Item>
        </Form>
      </div>
    </Page>
  )
}
