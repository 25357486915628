import {baseUrl} from '@/common/config'
import {$api, createHeaders} from '@/store/service'
import {Upload, UploadProps} from 'antd'
import React, {useCallback, useState} from 'react'

export interface UploadFileProps extends UploadProps {
  onSuccess?: (response: any) => void
  onError?: (msg: string) => void
  children: React.ReactNode | ((loading: boolean) => React.ReactNode)
}

export default function UploadFile(props: UploadFileProps) {
  const [loading, setLoading] = useState(false)

  const uploadChange = useCallback(
    async info => {
      const fd = new FormData()
      fd.append(props.name || 'file', info.file)
      const uri = await (typeof props.action === 'string'
        ? props.action.startsWith('http')
          ? props.action
          : baseUrl + props.action
        : props.action?.(info.file))
      setLoading(true)
      try {
        const {response} = await $api.postAsync<ApiResponse>({
          uri,
          body: fd,
          requestOptions: {
            headers: {
              ...createHeaders(),
              'Content-Type': 'multipart/form-data',
            },
          },
        })
        if (response.code === 200) {
          props.onSuccess?.(response)
        } else {
          props.onError?.(response.msg)
        }
      } catch (e) {
        props.onError?.((e as any)?.response?.data?.msg)
      } finally {
        setLoading(false)
      }
    },
    [props.name, props.action, props.onSuccess, props.onError]
  )

  return (
    <Upload customRequest={uploadChange} showUploadList={false} {...props}>
      {typeof props.children === 'function' ? props.children(loading) : props.children}
    </Upload>
  )
}
