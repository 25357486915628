import React, {useEffect, useState} from 'react'
import commonStyles from '@/assets/styles/common.module.less'
import {Form, PageHeader, Select} from 'antd'
import DividerLine from '@/pages/property/component/DividerLine'
import {sysModel} from '@/store/models/Sys'
import {userModel} from '@/store/models/User'
import StewardOrderReport from '../components/StewardOrderReport'
import {Page} from '@/components/Page'
import CondoTradeReport from '../components/CondoTradeReport'
import CondoProjectContractReport from '@/pages/report/components/CondoProjectContractReport'

export default function StatisticsReport() {
  const roleIdList = userModel.data.userInfo?.roleIdList
  const [reportList, setReportList] = useState<SysReportVO[]>([])
  const [reportId, setReportId] = useState('')

  useEffect(() => {
    sysModel.sysReportList({valid: 1, roleIdList} as SysReportQueryDTO).then(({response: {data}}) => {
      setReportList(data)
    })
  }, [roleIdList])

  return (
    <Page header={<PageHeader ghost={false} title={'统计报表'} />}>
      <div className={commonStyles.filterSection}>
        <Form>
          <Form.Item label={'报表选择'} wrapperCol={{span: 7}} labelCol={{flex: '80px'}} name={'reportId'}>
            <Select placeholder={'请选择'} onChange={(value: string) => setReportId(value)} allowClear>
              {reportList.map(item => (
                <Select.Option value={item.reportId} key={item.reportId}>
                  {item.reportName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
        <DividerLine title='查询条件' style={{marginBottom: 20}} />
        {['1', '2'].includes(reportId) && <StewardOrderReport reportId={reportId} />}
        {reportId === '3' && <CondoTradeReport reportId={reportId} />}
        {reportId === '4' && <CondoProjectContractReport reportId={reportId} />}
      </div>
    </Page>
  )
}
