import React, {useRef} from 'react'
import {Page} from '@/components/Page'
import {Breadcrumb} from '@/components/Breadcrumb'
import {Button} from 'antd'
import {usePermission} from '@/components/Permission'
import {InvoiceListComponent} from '@/pages/client/InvoiceList/components/InvoiceListComponent'

export default function InvoiceList() {
  const ref = useRef(null)
  const [auth] = usePermission()
  return (
    <Page
      breadcrumb={
        <Breadcrumb
          right={
            auth('007201001') && (
              <Button
                onClick={() => {
                  ref.current?.export?.()
                }}
                type={'primary'}
              >
                导出
              </Button>
            )
          }
        />
      }
    >
      <InvoiceListComponent ref={ref} />
    </Page>
  )
}
