import React, {useCallback, useEffect, useState} from 'react'
import commonStyles from '@/assets/styles/common.module.less'
import {Button, Col, DatePicker, Form, Input, Modal, Row, Select, Space, Table} from 'antd'
import {saleModel} from '@/store/models/Sale'
import moment from 'moment'
import {useCodeArraySync, useCodeSync} from '@/store/models/Common'
import {usePermission} from '@/components/Permission'
import {Page} from '@/components/Page'
import {useAppHistory} from '@/common/hooks/useRouter'
import {getPaginationProps} from '@/common/biz/getPaginationProps'
import {useFocusEffect} from '@/common/hooks/useFocusEffect'

// 耗材全包
const materialInclusiveCode = {'0': '否', '1': '是'}
const materialInclusiveArray = [
  {id: '1', label: '是'},
  {id: '0', label: '否'},
]
// 合同类型
const contractTypeArray = [
  {id: 'A', label: '全部'},
  {id: 'B', label: '保洁'},
  {id: 'C', label: '管家'},
]
// 付款日期
const dateList = (() => {
  const arr = []
  for (let i = 1; i <= 31; i++) arr.push(`${i}`)
  return arr
})()

export default function SaleClientContractList() {
  const history = useAppHistory()
  const [auth] = usePermission()

  // 状态
  const saleContractStatusArray = useCodeArraySync('sale.contract.status')
  const saleContractStatusCode = useCodeSync('sale.contract.status')
  // 管家收费类型
  const stewardFeeTypeArray = useCodeArraySync('steward.fee.type', {defaultItem: true})
  const stewardFeeTypeCode = useCodeSync('steward.fee.type')
  // 保洁收费类型
  const cleanFeeTypeArray = useCodeArraySync('clean.fee.type', {defaultItem: true})
  const cleanFeeTypeCode = useCodeSync('clean.fee.type')
  const contractType = useCodeSync('contract.type')

  const [form] = Form.useForm()

  const [total, setTotal] = useState(0)
  const [contractList, setContractList] = useState<SaleCustomerContractVO[]>([])

  const contractListLoading = saleModel.saleCustomerContractList.useLoading()

  const search = useCallback(() => {
    const params = form.getFieldsValue(true)
    saleModel.saleCustomerContractList(params).then(({response: {data, total}}) => {
      setContractList(data)
      setTotal(total)
    })
  }, [form])

  useFocusEffect(() => {
    form.submit()
  })

  useEffect(() => {
    form.submit()
  }, [form])

  return (
    <Page>
      <div className={commonStyles.filterSection}>
        <Form
          form={form}
          labelAlign={'left'}
          initialValues={{pageNum: 1, pageSize: 10, contractStatusList: ['0', '4']}} // 待签署、合同作废
          onFinish={search}
          onReset={search}
        >
          <Row>
            <Col span={7}>
              <Form.Item label={'关键字'} name={'keyword'}>
                <Input placeholder={'请输入客户名称或手机号'} allowClear />
              </Form.Item>
            </Col>
            <Col span={7} offset={1}>
              <Form.Item label={'BD'} name={'holderName'}>
                <Input placeholder={'请输入BD名称'} allowClear />
              </Form.Item>
            </Col>
            <Col span={7} offset={1}>
              <Form.Item label={'耗材全包'} name={'materialInclusive'}>
                <Select placeholder={'请选择'} style={{minWidth: 160}} allowClear>
                  {materialInclusiveArray.map(item => (
                    <Select.Option key={item.id} value={item.id}>
                      {item.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item label={'合同类型'} name={'_contractType'}>
                <Select
                  placeholder={'请选择'}
                  style={{minWidth: 160}}
                  allowClear
                  onChange={value => {
                    form.setFieldsValue({
                      contractClean: value === 'B' ? '1' : '',
                      contractSteward: value === 'C' ? '1' : '',
                    })
                  }}
                >
                  {contractTypeArray.map(item => (
                    <Select.Option key={item.id} value={item.id}>
                      {item.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={7} offset={1}>
              <Form.Item label={'合同周期'} name={'_contractDate'}>
                <DatePicker.RangePicker
                  format={'YYYY-MM-DD'}
                  allowClear
                  style={{width: '100%'}}
                  onChange={(_, formatString) => {
                    form.setFieldsValue({
                      contractBeginDate: formatString?.[0] ? formatString?.[0] + ' 00:00:00' : formatString?.[0],
                      contractEndDate: formatString?.[1] ? formatString?.[1] + ' 23:59:59' : formatString?.[1],
                    })
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={7} offset={1}>
              <Form.Item label={'付款日期'} name={'payDateNum'}>
                <Select placeholder={'请选择'} style={{minWidth: 160}} allowClear>
                  {dateList.map(item => (
                    <Select.Option key={item} value={item}>
                      {item}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item label={'状态'} name={'contractStatus'}>
                <Select placeholder={'请选择'} style={{minWidth: 160}} allowClear>
                  {saleContractStatusArray
                    .filter(item => ['0', '4'].includes(item.id))
                    .map(item => (
                      <Select.Option key={item.id} value={item.id}>
                        {item.label}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={7} offset={1}>
              <Form.Item label={'管家收费类型'} name={'stewardFeeType'}>
                <Select placeholder={'请选择'} style={{minWidth: 160}} allowClear>
                  {stewardFeeTypeArray.map(item => (
                    <Select.Option key={item.id} value={item.id}>
                      {item.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={7} offset={1}>
              <Form.Item label={'保洁收费类型'} name={'cleanFeeType'}>
                <Select placeholder={'请选择'} style={{minWidth: 160}} allowClear>
                  {cleanFeeTypeArray.map(item => (
                    <Select.Option key={item.id} value={item.id}>
                      {item.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row justify={'end'}>
            <Space>
              <Button type={'primary'} htmlType={'submit'} onClick={() => form.setFieldsValue({pageNum: 1})}>
                查询
              </Button>
              <Button htmlType={'reset'}>重置</Button>
              {/*<Button type={'primary'} htmlType={'submit'}>
                查询
              </Button>
              <Button
                htmlType={'reset'}
                onClick={() => {
                  form.resetFields()
                  setContractParams({pageNum: 1, pageSize: 10})
                }}
              >
                重置
              </Button>*/}
            </Space>
          </Row>
        </Form>
      </div>

      <div className={commonStyles.tableSection}>
        <Table
          rowKey={'contractId'}
          loading={contractListLoading}
          scroll={{x: 'max-content'}}
          dataSource={contractList}
          columns={[
            {title: '客户名称', dataIndex: 'name'},
            {title: '手机号', dataIndex: 'mobile'},
            {
              title: '合同周期',
              render: value => (
                <span>
                  {[
                    value.contractBeginDate && moment(value.contractBeginDate).format('YYYY-MM-DD'),
                    value.contractEndDate && moment(value.contractEndDate).format('YYYY-MM-DD'),
                  ]
                    .filter(item => item)
                    .join('至')}
                </span>
              ),
            },
            {title: 'BD', dataIndex: 'holderName'},
            {title: '签约类型', dataIndex: 'previewId', render: text => (text ? '续约' : '新签')},
            {
              title: '合同类型',
              render: value => (
                <span>
                  {[value.contractSteward === '1' && '管家', value.contractClean === '1' && '保洁']
                    .filter(item => item)
                    .join('、')}
                </span>
              ),
            },
            {
              title: '管家收费类型',
              dataIndex: 'stewardFeeType',
              render: value => stewardFeeTypeCode[value],
            },
            {
              title: '保洁收费类型',
              dataIndex: 'cleanFeeType',
              render: value => cleanFeeTypeCode[value],
            },
            {
              title: '房源核算日',
              dataIndex: 'propertyCountDateNum',
              render: value => value ?? '-',
            },
            {title: '付款日期', dataIndex: 'payDateNum', render: value => value ?? '/'},
            {
              title: '耗材全包',
              dataIndex: 'materialInclusive',
              render: value => (value ? <span>{materialInclusiveCode[value]}</span> : '/'),
            },
            {
              title: '合同签署类型',
              dataIndex: 'contractType',
              render: value => (value ? <span>{contractType[value]}</span> : '/'),
            },
            {
              title: '状态',
              dataIndex: 'contractStatus',
              render: value => <span>{saleContractStatusCode[value]}</span>,
            },
            {
              title: '操作',
              fixed: 'right',
              render: (_, value) => {
                return (
                  <Space>
                    {value.contractStatus === '0' && auth('004048002') && (
                      <Button
                        type={'link'}
                        style={{padding: 0}}
                        onClick={() =>
                          Modal.confirm({
                            title: '提示',
                            content: '您确定要终止签约吗？',
                            onOk: async () => {
                              await saleModel.saleCustomerContractDestroy({contractId: value.contractId})
                              form.submit()
                            },
                          })
                        }
                      >
                        终止签约
                      </Button>
                    )}
                    {auth('001048002') && (
                      <Button
                        type={'link'}
                        style={{padding: 0}}
                        onClick={() =>
                          history.push({
                            pathname: '/sale-client/contract_input',
                            state: {
                              contractId: value.contractId,
                              // 纸质合同的待签约状态则去签约
                              audit:
                                value.contractType === 'paper' && value.contractStatus === '0' ? 'contract' : undefined,
                            },
                          })
                        }
                      >
                        查看
                      </Button>
                    )}
                  </Space>
                )
              },
            },
          ]}
          pagination={getPaginationProps(form, total)}
        />
      </div>
    </Page>
  )
}
