import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {Page} from '@/components/Page'
import {Button, Col, Form, Input, message, Modal, Row, Select, Space, Table, Tabs} from 'antd'
import commonStyles from '@/assets/styles/common.module.less'
import {Breadcrumb} from '@/components/Breadcrumb'
import {SwapRightOutlined} from '@ant-design/icons'
import {adminModel} from '@/store/models/Admin'
import {userModel} from '@/store/models/User'
import {useCodeArraySync, useCodeSync} from '@/store/models/Common'
import {getPaginationProps} from '@/common/biz/getPaginationProps'
import {useAppHistory} from '@/common/hooks/useRouter'
import {usePermission} from '@/components/Permission'

export default function CRMClientList() {
  const userId = userModel.data.userInfo?.userId
  const history = useAppHistory()
  const gender = useCodeSync('gender')
  const customerChannelArr = useCodeArraySync('condo.cms.customerChannel')
  const customerChannel = useCodeSync('condo.cms.customerChannel')
  const [auth] = usePermission()
  const [activeKey, setActiveKey] = useState(() => {
    const permissionObj = {
      '001109001': '1',
      '001110001': '2',
      '001111001': '3',
      '001120001': '4',
    }
    const permission = Object.keys(permissionObj).find(item => auth(item))
    return permissionObj[permission]
  })
  const [selectRows, setSelectRows] = useState([])
  const [condoCustomerList, setCondoCustomerList] = useState<CondoCustomerVO[]>([])
  const [total, setTotal] = useState(0)
  const [form1] = Form.useForm()
  const [form2] = Form.useForm()
  const [form3] = Form.useForm()
  const [form4] = Form.useForm()
  const form = useMemo(
    () =>
      ({
        '1': form1,
        '2': form2,
        '3': form3,
        '4': form4,
      }[activeKey]),
    [activeKey, form1, form2, form3, form4]
  )

  const search = useCallback(() => {
    const values = form.getFieldsValue(true)

    if (activeKey === '4') {
      adminModel.adminCondoCustomerListAll(values).then(({response: {data, total}}) => {
        setCondoCustomerList(data)
        setTotal(total)
      })
    } else {
      adminModel
        .adminCondoCustomerList({
          ...values,
          ownerId: activeKey !== '3' ? userId : '',
          teamTag: activeKey === '2' ? true : false,
          poolTag: activeKey === '3' ? true : false,
        })
        .then(({response: {data, total}}) => {
          setCondoCustomerList(data)
          setTotal(total)
        })
    }
  }, [activeKey, form, userId])

  useEffect(() => {
    form.submit()
  }, [form])

  return (
    <Page
      breadcrumb={
        <Breadcrumb
          right={
            <Space>
              {activeKey !== '4' && (
                <Button
                  type={'primary'}
                  onClick={() => {
                    if (!selectRows.length) {
                      return message.warning('请选择要转出客户！')
                    }
                    Modal.confirm({
                      title: '提示',
                      content: `是否确认转出${activeKey === '3' ? '' : '到公共客户'}?`,
                      icon: null,
                      centered: true,
                      onOk: async () => {
                        if (activeKey === '3') {
                          for (let i = 0; i < selectRows.length; i++) {
                            const {customerId, customerChannel} = selectRows[i]
                            await adminModel.adminCondoCustomerTransferToStaff({
                              customerId,
                              customerChannel,
                            } as CondoCustomerTransferToStaffDTO)
                          }
                        } else {
                          for (let i = 0; i < selectRows.length; i++) {
                            const {customerId, customerChannel} = selectRows[i]
                            await adminModel.adminCondoCustomerTransferToPool({
                              customerId,
                              customerChannel,
                            } as CondoCustomerTransferToStaffDTO)
                          }
                        }
                        search()
                      },
                    })
                  }}
                >
                  转出
                </Button>
              )}

              {activeKey === '1' && (
                <Button type={'primary'} onClick={() => history.push({pathname: '/crm/add-client'})}>
                  新增客户
                </Button>
              )}
            </Space>
          }
        />
      }
    >
      <Tabs
        className={commonStyles.tabs}
        onChange={key => {
          setActiveKey(key)
          setSelectRows([])
        }}
        activeKey={activeKey}
      >
        <Tabs.TabPane tab={'我的客户'} key={'1'} disabled={!auth('001109001')} />
        <Tabs.TabPane tab={'团队客户'} key={'2'} disabled={!auth('001110001')} />
        <Tabs.TabPane tab={'公共客户'} key={'3'} disabled={!auth('001111001')} />
        <Tabs.TabPane tab={'全部客户'} key={'4'} disabled={!auth('001120001')} />
      </Tabs>
      <div className={commonStyles.filterSection}>
        <Form
          key={activeKey}
          form={form}
          onFinish={search}
          initialValues={{pageNum: 1, pageSize: 10}}
          onReset={search}
          labelCol={{flex: '70px'}}
          labelAlign={'left'}
        >
          <Row>
            <Col span={7}>
              <Form.Item label={'ID'} name={'customerId'}>
                <Input placeholder={'请输入'} allowClear />
              </Form.Item>
            </Col>
            <Col span={7} offset={1}>
              <Form.Item label={'姓名'} name={'customerName'}>
                <Input placeholder={'请输入'} allowClear />
              </Form.Item>
            </Col>
            <Col span={7} offset={1}>
              <Form.Item label={'手机号'} name={'customerMobile'}>
                <Input placeholder={'请输入'} allowClear />
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item label={'获客渠道'} name={'customerChannel'}>
                <Select allowClear placeholder={'请选择'}>
                  {customerChannelArr
                    .filter(item =>
                      activeKey === '4' ? true : ['5', '6', '7', '8', '9', '10', '13', '14', '99'].includes(item.id)
                    )
                    .map(item => (
                      <Select.Option key={item.id} value={item.id}>
                        {item.label}
                      </Select.Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={7} offset={1}>
              <Form.Item label={'预算金额'}>
                <Space>
                  <Form.Item style={{marginBottom: 0}} name={'budgetMin'}>
                    <Input placeholder='最低' />
                  </Form.Item>
                  <SwapRightOutlined style={{color: 'rgba(0, 0, 0, 0.25)'}} />
                  <Form.Item style={{marginBottom: 0}} name={'budgetMax'}>
                    <Input placeholder='最高' />
                  </Form.Item>
                </Space>
              </Form.Item>
            </Col>
            <Col span={7} offset={1}>
              <Form.Item label={'创建人'} name={'creatorName'}>
                <Input placeholder={'请输入'} allowClear />
              </Form.Item>
            </Col>
            {['2', '4'].includes(activeKey) && (
              <Col span={7}>
                <Form.Item label={'归属人'} name={'ownerName'}>
                  <Input placeholder={'请输入'} allowClear />
                </Form.Item>
              </Col>
            )}
          </Row>
          <Row justify={'end'}>
            <Space>
              <Button type={'primary'} htmlType={'submit'} onClick={() => form.setFieldsValue({pageNum: 1})}>
                查询
              </Button>
              <Button htmlType='reset'>重置</Button>
            </Space>
          </Row>
        </Form>
      </div>
      <div className={commonStyles.tableSection}>
        <Table
          scroll={{x: 'max-content'}}
          rowKey={'customerId'}
          rowSelection={{
            type: 'checkbox',
            selectedRowKeys: selectRows.map(item => item.customerId),
            onChange: (_, rows) => {
              setSelectRows(rows)
            },
          }}
          dataSource={condoCustomerList}
          columns={[
            {title: 'ID', dataIndex: 'customerId'},
            {title: '姓名', dataIndex: 'customerName'},
            {title: '性别', dataIndex: 'customerGender', render: text => gender[text]},
            {title: '手机号', dataIndex: 'customerMobile', render: text => text ?? '/'},
            {title: '获客渠道', dataIndex: 'customerChannel', render: text => customerChannel[text]},
            {title: '预算金额', render: record => `${record.budgetMin}-${record.budgetMax}元`},
            ...(activeKey !== '1' ? [{title: '归属人', dataIndex: 'ownerName', render: text => text ?? '/'}] : []),
            {title: '创建人', dataIndex: 'creatorName'},
            {title: '创建时间', dataIndex: 'insertTime'},
            {
              title: '操作',
              render: record => {
                return (
                  <Space>
                    {activeKey !== '4' && (
                      <Button
                        type='link'
                        style={{paddingLeft: 0, paddingRight: 0}}
                        onClick={() =>
                          history.push({pathname: '/crm/client-detail', state: {customerId: record.customerId}})
                        }
                      >
                        查看
                      </Button>
                    )}
                    {activeKey === '1' && (
                      <>
                        <Button
                          type='link'
                          style={{paddingLeft: 0, paddingRight: 0}}
                          onClick={() => {
                            history.push({pathname: '/crm/add-client', state: {customerId: record.customerId}})
                          }}
                        >
                          编辑
                        </Button>
                        <Button
                          type='link'
                          style={{paddingLeft: 0, paddingRight: 0}}
                          onClick={() => {
                            history.push({pathname: '/crm/add-look', state: {customerId: record.customerId}})
                          }}
                        >
                          新建带看单
                        </Button>
                      </>
                    )}
                  </Space>
                )
              },
            },
          ]}
          pagination={getPaginationProps(form, total)}
        />
      </div>
    </Page>
  )
}
