import React, {useState, useEffect} from 'react'
import {Upload} from 'antd'
import {getModels} from '@/utils/globalService'
import upload from '@/assets/icons/upload.svg'
import {$api} from '@/store/service'
import {getImg} from '@/utils/util'

interface Props {
  defaultValue?: string
  onChange?: (avatar: string) => unknown
}

export default function UploadAvatar({defaultValue, onChange}: Props) {
  const [avatar, setAvatar] = useState('')

  useEffect(() => {
    if (defaultValue) {
      setAvatar(defaultValue)
      typeof onChange === 'function' && onChange(defaultValue)
    }
  }, [defaultValue])

  //照片显示
  function uploadPhoto(photo) {
    return photo ? (
      <img src={getImg(photo)} width={86} alt={'头像'} height={86} />
    ) : (
      <div>
        <img src={upload} alt={'默认头像'} width={16} />
        <p style={{color: '#000000', fontSize: 14, marginTop: 8}}>上传照片</p>
      </div>
    )
  }

  //上传图片
  async function uploadChange(info) {
    const fd = new FormData()
    fd.append('category', 'avatar')
    fd.append('file', info.file)
    const res: any = await $api
      .postAsync({
        uri: '/file/upload',
        body: fd,
        requestOptions: {
          headers: {
            'Content-Type': 'multipart/form-data',
            'nyg8-auth': getModels()?.userModel.data?.token,
          },
        },
      })
      .catch(e => console.log(e))
    const {imgUrl} = res.response.data
    typeof onChange === 'function' && onChange(imgUrl)
    setAvatar(imgUrl)
  }

  return (
    <Upload
      accept={'image/*'}
      customRequest={uploadChange}
      name={'avatar'}
      listType={'picture-card'}
      showUploadList={false}
    >
      {uploadPhoto(avatar)}
    </Upload>
  )
}
