import {Model} from '@redux-model/web'
import {$api} from '@/store/service'

interface FeedBackModel {}

class FeedBack extends Model<FeedBackModel> {
  protected initReducer(): FeedBackModel {
    return {}
  }

  // 反馈列表
  feedbackList = $api.action((args: FeedBackAdminQueryDTO) =>
    this.post<ApiResponse<FeedBackVO[]>>('/feedback/list').body(args)
  )

  // 反馈详情
  feedbackDetail = $api.action((params: {feedbackId: string}) =>
    this.post<ApiResponse<FeedBackVO>>('/feedback/detail').body(params)
  )

  // 反馈处理
  feedbackHandle = $api.action((params: {feedbackId: string; respContent: string; operationStatus: string}) =>
    this.post('/feedback/handle').body(params)
  )

  // 问答-列表查询
  stewardAppSysHelpList = $api.action((args: SysHelpListQueryDTO) =>
    this.post<ApiResponse<SysHelpVO[]>>('/sys-help/list').body(args)
  )

  // 问答-问题类型list
  stewardAppSysHelpListCategory = $api.action(() => this.post<ApiResponse<string[]>>('/sys-help/list-category'))

  // 问答-新增/编辑
  sysHelpSave = $api.action((args: SysHelpSaveDTO) => this.post('/sys-help/save').body(args))

  // 问答-设置是否有效  |  object:{helpId:问答id,helpStatus:状态}
  sysHelpSetStatus = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<Record<string, any>>>('/sys-help/set-status').body(args)
  )

  // 反馈补充  |  feedbackId:反馈id,respExtra:补充信息
  feedbackAppend = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<Record<string, any>>>('/feedback/append').body(args)
  )

  // 客服工单-作废  |  {id:'xxxxx',cancelResult:'作废原因',cancelOrder:'作废类型 1 全部 2处罚 3赔付'}
  csWorkOrderCancel = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<Record<string, any>>>('/cs-work-order/cancel').body(args)
  )

  // 客服工单-赔付  |  {id:'xxxxx',compensationStatus:'xx-xx'}
  csWorkOrderCompensation = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<Record<string, any>>>('/cs-work-order/compensation').body(args)
  )

  // 客服工单-详情
  csWorkOrderGet = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<CsWorkOrderVO>>('/cs-work-order/get').body(args)
  )

  // 客服工单-新增
  csWorkOrderInsert = $api.action((args: CsWorkOrderInsertDTO) =>
    this.post<ApiResponse<Record<string, any>>>('/cs-work-order/insert').body(args)
  )

  // 客服工单-列表
  csWorkOrderList = $api.action((args: CsWorkOrderDTO) =>
    this.post<ApiResponse<CsWorkOrderVO[]>>('/cs-work-order/list').body(args)
  )

  // 客服工单-处罚  |  {id:'xxxxx',punishmentAmt:0.00,punishmentReason:'xx'}
  csWorkOrderPunishment = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<Record<string, any>>>('/cs-work-order/punishment').body(args)
  )

  // 客服工单-处理结果  |  {id:'xxxxx',processDescription:'xxx',processResult:''}
  csWorkOrderProcess = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<Record<string, any>>>('/cs-work-order/process').body(args)
  )
}

export const feedbackModel = new FeedBack()
