import React, {CSSProperties} from 'react'
import {Breadcrumb} from '@/components/Breadcrumb'
import styles from './index.module.less'

export interface PageProps {
  /**
   * 如果传入，则覆盖原来的面包屑
   */
  breadcrumb?: React.ReactNode
  header?: React.ReactNode
  style?: CSSProperties
}

export const Page: React.FC<PageProps> = props => {
  return (
    <>
      {props.breadcrumb !== undefined ? (
        props.breadcrumb
      ) : (
        <Breadcrumb {...(props.header ? {style: {backgroundColor: '#fff'}} : {})} />
      )}
      {!!props.header && <div className={styles.header}>{props.header}</div>}
      <div className={styles.page} style={props.style}>
        {props.children}
      </div>
    </>
  )
}
