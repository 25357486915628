import React, {useCallback, useEffect, useState} from 'react'
import {Breadcrumb} from '@/components/Breadcrumb'
import {Button, Col, Form, Input, Row, DatePicker, Space, Table, Typography} from 'antd'
import commonStyle from '@/assets/styles/common.module.less'
import {SwapRightOutlined} from '@ant-design/icons'
import {downloadBlob} from '@/utils/util'
import moment from 'moment'
import {customerModel} from '@/store/models/Customer'
import {Page} from '@/components/Page'

const {RangePicker} = DatePicker
export default function ClientStatement() {
  const [params, setParams] = useState({pageNum: 1, pageSize: 10} as LessorCustomerReportQueryDTO)
  const [form] = Form.useForm()
  const [reportList, setReportList] = useState([] as LessorCustomerReportVO[])
  const [total, setTotal] = useState(0)
  const queryList = useCallback((args: LessorCustomerReportQueryDTO) => {
    customerModel.customerReportList(args).then(({response: {data, total}}) => {
      setReportList(data)
      setTotal(total)
    })
  }, [])

  useEffect(() => {
    queryList(params)
  }, [params, queryList])

  return (
    <Page
      breadcrumb={
        <Breadcrumb
          right={<Button onClick={() => downloadBlob({url: '/customer/report/list-excel', body: params})}>导出</Button>}
        />
      }
    >
      <div className={commonStyle.filterSection}>
        <Form
          form={form}
          onFinish={async values => {
            const {time, paidNumMax, paidNumMin, unpaidNumMax, unpaidNumMin, ...rest} = values
            const [billDateBegin, billDateEnd] = time ? time.map(item => moment(item).format('YYYY-MM-DD')) : []
            setParams(state => ({
              ...state,
              billDateBegin,
              billDateEnd,
              paidNumMax: paidNumMax ? Number(paidNumMax) : null,
              paidNumMin: paidNumMin ? Number(paidNumMin) : null,
              unpaidNumMax: unpaidNumMax ? Number(unpaidNumMax) : null,
              unpaidNumMin: unpaidNumMin ? Number(unpaidNumMin) : null,
              pageNum: 1,
              ...rest,
            }))
          }}
        >
          <Row>
            <Col span={5}>
              <Form.Item label={'客户名称'} name={'certName'}>
                <Input placeholder={'请输入客户名称'} allowClear />
              </Form.Item>
            </Col>
            <Col offset={1} span={5}>
              <Form.Item label={'手机号'} name={'mobile'}>
                <Input placeholder={'请输入手机号'} allowClear />
              </Form.Item>
            </Col>
            <Col offset={1}>
              <Form.Item label={'已付款笔数'} name={'paidNumMin'}>
                <Input placeholder={'最小数量'} style={{maxWidth: 100}} allowClear />
              </Form.Item>
            </Col>
            <Col style={{display: 'flex', justifyContent: 'center', minWidth: 30}}>
              <Form.Item>
                <SwapRightOutlined style={{color: 'rgba(0, 0, 0, 0.25)'}} />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item name={'paidNumMax'}>
                <Input placeholder={'最大数量'} style={{maxWidth: 100}} allowClear />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Item label={'待付款笔数'} name={'unpaidNumMin'}>
                <Input placeholder={'最小数量'} style={{maxWidth: 100}} allowClear />
              </Form.Item>
            </Col>
            <Col style={{display: 'flex', justifyContent: 'center', minWidth: 30}}>
              <Form.Item>
                <SwapRightOutlined style={{color: 'rgba(0, 0, 0, 0.25)'}} />
              </Form.Item>
            </Col>
            <Col>
              <Form.Item name={'unpaidNumMax'}>
                <Input placeholder={'最大数量'} style={{maxWidth: 100}} allowClear />
              </Form.Item>
            </Col>
            <Col offset={1}>
              <Form.Item label={'应付款日'} name={'time'}>
                <RangePicker />
              </Form.Item>
            </Col>
          </Row>
          <Row justify={'end'}>
            <Space>
              <Button type={'primary'} htmlType={'submit'}>
                查询
              </Button>
              <Button
                onClick={() => {
                  form.resetFields()
                  setParams({pageNum: 1, pageSize: 10} as LessorCustomerReportQueryDTO)
                }}
              >
                重置
              </Button>
            </Space>
          </Row>
        </Form>
      </div>
      <div className={commonStyle.tableSection}>
        <Table
          rowKey={'mobile'}
          columns={[
            {title: '客户名称', dataIndex: 'certName', key: 'certName'},
            {title: '手机号', dataIndex: 'mobile', key: 'mobile'},
            {title: '房源总数', dataIndex: 'propertyNum', key: 'propertyNum'},
            {
              title: '管家账单（已付款笔数/总金额）',
              dataIndex: 'paidBillNum',
              key: 'paidBillNum',
              render: (text, record) => (
                <Space direction={'vertical'} size={0}>
                  <Typography.Text>{text}笔</Typography.Text>
                  <Typography.Text>{record.paidBillAmt}元</Typography.Text>
                </Space>
              ),
            },
            {
              title: '管家账单（待付款笔数/总金额）',
              dataIndex: 'unpaidBillNum',
              key: 'unpaidBillNum',
              render: (text, record) => (
                <Space direction={'vertical'} size={0}>
                  <Typography.Text>{text}笔</Typography.Text>
                  <Typography.Text>{record.unpaidBillAmt}元</Typography.Text>
                </Space>
              ),
            },
          ]}
          dataSource={reportList}
          pagination={{
            total,
            current: params.pageNum,
            pageSize: params.pageSize,
            position: ['bottomCenter'],
            showSizeChanger: true,
            onChange: (pageNum, pageSize) => {
              setParams(state => ({...state, pageNum, pageSize}))
            },
          }}
        />
      </div>
    </Page>
  )
}
