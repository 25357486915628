import {$api} from '@/store/service'
import {Model} from '@redux-model/web'

interface EbassModel {}
class Ebaas extends Model<EbassModel> {
  protected initReducer(): EbassModel {
    return {}
  }

  // 新增经纪人
  ebaasBrokerInsert = $api.action((args: EbaasBrokerInsertDTO) =>
    this.post<ApiResponse<string>>('/ebaas/broker/insert').body(args)
  )

  // 经纪人列表(不分页)
  ebaasBrokerList = $api.action((args: EbaasBrokerQueryDTO) =>
    this.post<ApiResponse<EbaasBrokerVO[]>>('/ebaas/broker/list').body(args)
  )

  // 经纪人详情  |  object:{userId:后台人员ID}
  ebaasBrokerGet = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<EbaasBrokerVO>>('/ebaas/broker/get').body(args)
  )

  // 编辑经纪人
  ebaasBrokerUpdate = $api.action((args: EbaasBrokerUpdateDTO) =>
    this.post<ApiResponse<string>>('/ebaas/broker/update').body(args)
  )

  // 改变经纪人在职离职状态  |  object:{userId:后台人员ID,inServiceStatus:在职状态(1-在职2-离职)}
  ebaasBrokerChangeInServiceStatus = $api.action((args: {userId: string; inServiceStatus: 1 | 2}) =>
    this.post<ApiResponse<EbaasBrokerVO>>('/ebaas/broker/change-in-service-status').body(args).metas(args.userId)
  )

  // 查询集中式项目列表
  ebaasApartmentCondoProjectList = $api.action((args: CondoProjectListDTO) =>
    this.post<ApiResponse<CondoProjectVO[]>>('/ebaas/apartment/condo-project-list').body(args)
  )

  // 导入公寓  |  object:{condoId:门店编号}
  ebaasApartmentImport = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<string>>('/ebaas/apartment/import').body(args)
  )

  // 公寓列表
  ebaasApartmentList = $api.action((args: EbaasApartmentListDTO) =>
    this.post<ApiResponse<EbaasApartmentVO[]>>('/ebaas/apartment/list').body(args)
  )

  // 公寓列表
  ebaasApartmentInfo = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<EbaasApartmentVO>>('/ebaas/apartment/info').body(args)
  )

  // 提交公寓信息至天猫好房  |  object:{condoId:门店编号}
  ebaasApartmentSubmit = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<Record<string, any>>>('/ebaas/apartment/submit').body(args).metas(args.condoId)
  )

  // 编辑公寓信息
  ebaasApartmentUpdate = $api.action((args: EbaasApartmentUpdateDTO) =>
    this.post<ApiResponse<string>>('/ebaas/apartment/update').body(args)
  )

  // 新增公寓信息
  ebaasApartmentInsert = $api.action((args: EbaasApartmentInsertDTO) =>
    this.post<ApiResponse<string>>('/ebaas/apartment/insert').body(args)
  )

  // 房源列表/推广列表
  ebaasRoomList = $api.action((args: EbaasRoomListDTO) =>
    this.post<ApiResponse<EbaasRoomVO[]>>('/ebaas/room/list').body(args)
  )

  // 房源详情  |  object:{propertyCode:房源code}
  ebaasRoomInfo = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<EbaasRoomVO>>('/ebaas/room/info').body(args)
  )

  // 编辑房源
  ebaasRoomUpdate = $api.action((args: EbaasRoomUpdateDTO) =>
    this.post<ApiResponse<string>>('/ebaas/room/update').body(args)
  )

  // 新增房源
  ebaasRoomInsert = $api.action((args: EbaasRoomInsertDTO) =>
    this.post<ApiResponse<string>>('/ebaas/room/insert').body(args)
  )

  // 批量编辑房源
  ebaasRoomUpdateBatch = $api.action((args: EbaasRoomBatchDTO) =>
    this.post<ApiResponse<string>>('/ebaas/room/update-batch').body(args)
  )

  // 房型列表
  ebaasLayoutList = $api.action((args: EbaasLayoutListDTO) =>
    this.post<ApiResponse<EbaasLayoutVO[]>>('/ebaas/layout/list').body(args)
  )

  // 房型详情  |  object:{templateId:模板Id}
  ebaasLayoutInfo = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<EbaasLayoutVO>>('/ebaas/layout/info').body(args)
  )

  // 新增/编辑房型
  ebaasLayoutUpsert = $api.action((args: EbaasLayoutDTO) =>
    this.post<ApiResponse<string>>('/ebaas/layout/upsert').body(args)
  )

  // 门店列表(不分页)
  ebaasStoreList = $api.action((args: EbaasStoreQueryDTO) =>
    this.post<ApiResponse<EbaasStoreVO[]>>('/ebaas/store/list').body(args)
  )

  // 新增门店
  ebaasStoreInsert = $api.action((args: EbaasStoreInsertDTO) =>
    this.post<ApiResponse<string>>('/ebaas/store/insert').body(args)
  )

  // 编辑门店
  ebaasStoreUpdate = $api.action((args: EbaasStoreUpdateDTO) =>
    this.post<ApiResponse<string>>('/ebaas/store/update').body(args)
  )

  // 门店详情  |  object:{storeNo:门店编号}
  ebaasStoreGet = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<EbaasStoreVO>>('/ebaas/store/get').body(args)
  )

  // 同步门店信息到天猫  |  object:{storeNo:门店编号}
  ebaasStoreSync = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<Record<string, any>>>('/ebaas/store/sync').body(args)
  )

  // 房源上/下架  |  object:{propertyCode:模板Id,syncStatus:平台上下架（1：上、0：下） }
  ebaasRoomSubmit = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<string>>('/ebaas/room/submit').body(args).metas(args.propertyCode)
  )

  // 房源历史  |  object:{propertyCode:房源code}
  ebaasRoomHistory = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<EbaasRoomHistoryVO[]>>('/ebaas/room/history').body(args)
  )
}

export const ebassModel = new Ebaas()
