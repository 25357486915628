import React, {Fragment, useCallback} from 'react'
import {Form, Input, InputNumber, message, Modal, Space, Switch, Tag} from 'antd'
import {cleanModel} from '@/store/models/Clean'
import {customerModel} from '@/store/models/Customer'
import {saleModel} from '@/store/models/Sale'
import {commonModel, useCodeSync} from '@/store/models/Common'

export function useCleanOrderCharge() {
  const [form] = Form.useForm()

  // const chargeWayCode = useCodeSync('steward.orderAuditChargeWay')
  const contractPayWayCode = useCodeSync('sale.contract.pay.way')
  const contractCleanTypeCode = useCodeSync('sale.contract.clean.type')

  const queryCustomerInfo = useCallback(
    userId => {
      customerModel.customerInfo({userId}).then(({response: {data}}) => {
        form.setFieldsValue({chargePayWay: data?.chargePayWay})
      })
    },
    [form]
  )

  const queryContractInfo = useCallback(
    propertyCode => {
      saleModel.saleCustomerContractInfoByPropertyCode({propertyCode}).then(({response: {data}}) => {
        form.setFieldsValue({cleanServiceList: data?.cleanServiceList})
      })
    },
    [form]
  )

  const queryCleanHouseType = useCallback(
    (propertyCode, serviceId) =>
      cleanModel.cleanOrderGetCleanHouseType({propertyCode, serviceId}).then(({response: {data}}) => {
        if (data) {
          form.setFieldsValue({
            cleanHouseType: [
              data.propertyType && `${data.propertyType === '1' ? '平层' : '复式'}`,
              data.bedroom && `${data.bedroom}室`,
              data.parlor && `${data.parlor}厅`,
              data.kitchen && `${data.kitchen}厨`,
              data.bathroom && `${data.bathroom}卫`,
            ]
              .filter(item => item)
              .join(''),
          })
        }
      }),
    [form]
  )

  const search = useCallback(
    (orderId: string) => {
      cleanModel.cleanOrderGetTeamBalance({orderId}).then(({response: {data}}) => {
        form.setFieldsValue({total: data})
      })
    },
    [form]
  )

  const queryActivityRemarkGet = useCallback(
    customerId => {
      commonModel.commonAppCustomerActivityRemarkGet({userId: customerId}).then(({response: {data}}) => {
        form.setFieldsValue({activityRemark: data})
      })
    },
    [form]
  )

  return useCallback(
    ({detail, onSuccess}: {detail: CleanOrderVO; onSuccess?: () => void}) => {
      queryCustomerInfo(detail.customerId)
      queryContractInfo(detail.propertyCode)
      queryCleanHouseType(detail.propertyCode, detail.categoryThi)
      search(detail.orderId)
      queryActivityRemarkGet(detail.customerId)
      return Modal.confirm({
        title: '确认扣款',
        icon: null,
        closable: true,
        centered: true,
        okText: '提交',
        width: '50%',
        onOk: async () => {
          await form.validateFields()
          form.submit()
        },
        afterClose: () => form.resetFields(),
        content: (
          <Form
            form={form}
            style={{marginTop: '20px'}}
            labelAlign={'right'}
            // labelCol={{span: 6}}
            initialValues={{
              _commission: true,
              commissionGarbage: detail?.commissionGarbage,
              commissionService: detail?.commissionService,
            }}
            onFinish={async () => {
              const formValue = form.getFieldsValue(true)
              await cleanModel.cleanOrderSubmitAmtCheck({...formValue, orderId: detail?.orderId})
              message.success('确认扣款成功！')
              onSuccess?.()
            }}
          >
            <Form.Item noStyle shouldUpdate>
              {form1 => (
                <Form.Item label={'保洁区域'} style={{marginBottom: '12px'}}>
                  {form1.getFieldValue('cleanHouseType')}
                </Form.Item>
              )}
            </Form.Item>
            <Form.Item label={'客户'} style={{marginBottom: '12px'}} shouldUpdate>
              {form1 => {
                const activityRemark = form1.getFieldValue('activityRemark')
                return (
                  <Space>
                    <span>{`${detail?.customerName}（服务费账户余额：${form.getFieldValue('total')}元）`}</span>
                    {!!activityRemark && (
                      <span
                        style={{color: '#FF5454', textDecorationLine: 'underline'}}
                        onClick={() =>
                          Modal.confirm({
                            title: '活动充值',
                            icon: null,
                            centered: true,
                            content: activityRemark,
                            okCancel: false,
                            okText: '关闭',
                          })
                        }
                      >
                        充值活动
                      </span>
                    )}
                  </Space>
                )
              }}
            </Form.Item>
            <Form.Item label={'服务房源'} style={{marginBottom: '12px'}}>
              {detail?.propertyAddr}
            </Form.Item>
            <Form.Item label={'服务项目'} style={{marginBottom: '12px'}}>
              <Space>
                <span>
                  {[detail?.categoryFirName, detail?.categorySecName, detail?.categoryThiName]
                    .filter(item => item)
                    ?.join('-')}
                </span>
                {!!detail.orderIdOrigin && <Tag color={'blue'}>返</Tag>}
              </Space>
            </Form.Item>
            <Form.List name={'cleanServiceList'}>
              {fields =>
                fields.map((field, index) => {
                  const serviceTypeList = form.getFieldValue(['cleanServiceList', index, 'serviceTypeList'])
                  const servicePayWay = form.getFieldValue(['cleanServiceList', index, 'servicePayWay'])
                  return serviceTypeList?.map((item, idx) => (
                    <Fragment key={idx}>
                      <Form.Item label={contractCleanTypeCode[item]} fieldKey={['idx']}>
                        {contractPayWayCode[servicePayWay]}
                      </Form.Item>
                    </Fragment>
                  ))
                })
              }
            </Form.List>
            <Form.Item label={'服务收费'} style={{marginBottom: '12px'}}>
              {detail?.payItemList?.[0]?.chargeName}（{detail?.payItemList?.[0]?.itemAmtTotal}元）
            </Form.Item>
            <Form.Item label={'是否计算提成'} style={{marginBottom: '12px'}} name={'_commission'}>
              <Switch
                defaultChecked
                onChange={checked => {
                  form.setFieldsValue({
                    commissionGarbage: checked ? detail?.commissionGarbage : 0,
                    commissionService: checked ? detail?.commissionService : 0,
                  })
                }}
              />
            </Form.Item>
            <Form.Item
              style={{marginBottom: '12px'}}
              shouldUpdate={(prevValues, nextValues) => prevValues._commission !== nextValues._commission}
            >
              {form1 => {
                const _commission = form1.getFieldValue('_commission')
                return (
                  <Space>
                    <Form.Item
                      label={'收费服务提成'}
                      style={{marginBottom: 0}}
                      name={'commissionService'}
                      rules={[{required: true, message: '请输入收费服务提成'}]}
                    >
                      <InputNumber placeholder={'请输入'} disabled={!_commission} />
                    </Form.Item>
                    元
                  </Space>
                )
              }}
            </Form.Item>
            <Form.Item label={'垃圾清运（包）'} style={{marginBottom: '12px'}}>
              {detail?.garbagePkgNum}包
            </Form.Item>
            <Form.Item
              style={{marginBottom: '12px'}}
              shouldUpdate={(prevValues, nextValues) => prevValues._commission !== nextValues._commission}
            >
              {form1 => {
                const _commission = form1.getFieldValue('_commission')
                // console.log('_commission', _commission)
                return (
                  <Space>
                    <Form.Item
                      label={'垃圾清运提成'}
                      style={{marginBottom: 0}}
                      name={'commissionGarbage'}
                      rules={[{required: true, message: '请输入垃圾清运提成'}]}
                    >
                      <InputNumber placeholder={'请输入'} disabled={!_commission} />
                    </Form.Item>
                    元
                  </Space>
                )
              }}
            </Form.Item>
            <div style={{marginBottom: '12px'}}>
              <span style={{color: '#00B7AE'}}>提示：垃圾清运提成超过10包按10包算</span>
            </div>
            <Form.Item style={{marginBottom: '12px'}}>
              <Space>
                <Form.Item
                  label={'扣款金额'}
                  style={{marginBottom: 0}}
                  name={'amtCheck'}
                  rules={[{required: true, message: '请输入扣款金额'}]}
                >
                  <InputNumber placeholder={'请输入'} />
                </Form.Item>
                元
              </Space>
            </Form.Item>
            <Form.Item
              label={'扣款备注'}
              name={'amtCheckNotes'}
              style={{marginBottom: '12px'}}
              rules={[{required: true, message: '请输入扣款备注'}]}
            >
              <Input.TextArea placeholder={'请输入'} maxLength={200} />
            </Form.Item>
          </Form>
        ),
      })
    },
    [
      contractCleanTypeCode,
      contractPayWayCode,
      form,
      queryActivityRemarkGet,
      queryCleanHouseType,
      queryContractInfo,
      queryCustomerInfo,
      search,
    ]
  )
}
