import React, {useCallback, useEffect, useState} from 'react'
import {Button, Col, DatePicker, Form, Input, Row, Select, Table, Space, message} from 'antd'
import {useHistory} from 'react-router'
import {feedbackModel} from '@/store/models/FeedBack'
import '../../property/index.less'
import commonStyles from '@/assets/styles/common.module.less'
import {Permission, usePermission} from '@/components/Permission'
import {useCodeArraySync, useCodeSync} from '@/store/models/Common'
import {Page} from '@/components/Page'
import {useFocusEffect} from '@/common/hooks/useFocusEffect'
import {getPaginationProps} from '@/common/biz/getPaginationProps'
import {useAppLocation} from '@/common/hooks/useRouter'
import {Breadcrumb} from '@/components/Breadcrumb'
import {downloadBlob} from '@/utils/util'

const operationStatusCode = {'0': '待处理', '1': '已处理'}

export default function FeedBackList() {
  const location = useAppLocation<'/feedback'>()
  const history = useHistory()
  const [auth] = usePermission()

  // 反馈类型
  const feedbackTypeCode = useCodeSync('feedback')
  const feedbackTypeArray = useCodeArraySync('feedback')
  const feedbackTypeMode = useCodeSync('feedback.mode')

  // 列表
  const [dataList, setDataList] = useState<FeedBackVO[]>([])
  // 数据条数
  const [total, setTotal] = useState(0)

  const loading = feedbackModel.feedbackList.useLoading()

  const [form] = Form.useForm<FeedBackAdminQueryDTO>()

  const search = useCallback(async () => {
    const values = form.getFieldsValue(true)
    const {
      response: {data, total},
    } = await feedbackModel.feedbackList(values)
    setDataList(data)
    setTotal(total)
  }, [form])

  useFocusEffect(() => {
    form.submit()
  })

  useEffect(() => {
    form.setFieldsValue({operationStatus: location.state?.operationStatus})
    form.submit()
  }, [form, location])

  return (
    <Page
      breadcrumb={
        <Breadcrumb
          right={
            auth('007029001') && (
              <Button
                type={'primary'}
                onClick={() => {
                  const {feedBackTimeBegin, feedBackTimeEnd} = form.getFieldsValue(true)
                  if (!feedBackTimeBegin) return message.warning('请选择反馈时间！')
                  downloadBlob({url: '/feedback/list-export', body: {feedBackTimeBegin, feedBackTimeEnd}})
                }}
              >
                导出
              </Button>
            )
          }
        />
      }
    >
      <Form
        form={form}
        autoComplete={'off'}
        initialValues={{pageNum: 1, pageSize: 10}}
        onReset={search}
        onFinish={search}
      >
        <div className={commonStyles.filterSection}>
          {/**名称*/}
          <Row gutter={70}>
            <Col span={8}>
              <Form.Item name='keyword' label={'关键字'}>
                <Input placeholder={'请输入用户名/手机号'} allowClear />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name='feedbackType' label={'反馈类型'}>
                <Select getPopupContainer={triggerNode => triggerNode.parentNode} allowClear placeholder='反馈类型'>
                  {feedbackTypeArray.map(item => (
                    <Select.Option value={item.id} key={item.id}>
                      {item.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name={'extra'} label={'服务单号'}>
                <Input placeholder={'请输入服务单号'} allowClear />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name={'feedbackMode'} label={'性质'}>
                <Select placeholder={'请选择'} allowClear>
                  <Select.Option value={'1'}>建议</Select.Option>
                  <Select.Option value={'2'}>投诉</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={'有无服务单号'} name={'extraStatus'}>
                <Select placeholder={'请选择'} allowClear>
                  <Select.Option value={1}>有</Select.Option>
                  <Select.Option value={0}>无</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name='_time' label={'反馈时间'}>
                <DatePicker.RangePicker
                  style={{width: '100%'}}
                  showTime
                  allowClear
                  format={'YYYY-MM-DD HH:mm:ss'}
                  onChange={(_, formatString) => {
                    form.setFieldsValue({feedBackTimeBegin: formatString?.[0], feedBackTimeEnd: formatString?.[1]})
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={'状态'} name={'operationStatus'}>
                <Select placeholder={'请选择'} allowClear>
                  <Select.Option value={'0'}>待处理</Select.Option>
                  <Select.Option value={'1'}>已处理</Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row justify={'end'}>
            <Space>
              <Button type={'primary'} htmlType={'submit'} onClick={() => form.setFieldsValue({pageNum: 1})}>
                查询
              </Button>
              <Button htmlType={'reset'}>重置</Button>
            </Space>
          </Row>
        </div>

        <div className={commonStyles.tableSection}>
          <Table
            rowKey={'feedbackId'}
            dataSource={dataList}
            columns={[
              {title: '编号', dataIndex: 'feedbackId'},
              {
                title: '用户名称',
                dataIndex: 'userName',
                key: 'userName',
              },
              {title: '手机号', dataIndex: 'userMobile', key: 'userMobile'},
              {title: '类型', render: (_, record) => feedbackTypeCode[record.feedbackType]},
              {title: '性质', render: (_, record) => feedbackTypeMode[record.feedbackMode]},
              {title: '创建时间', dataIndex: 'insertTime', key: 'insertTime'},
              {
                title: '内容',
                dataIndex: 'feedbackContent',
                key: 'feedbackContent',
                render: value => <div style={{wordWrap: 'break-word', wordBreak: 'break-word'}}>{value || '/'}</div>,
                width: '25%',
              },
              {title: '状态', render: (_, record) => operationStatusCode[record.operationStatus]},
              {
                title: '操作',
                dataIndex: 'operationStatus',
                key: 'operationStatus',
                render: (value, record) =>
                  value === '0' ? (
                    <Permission code={'002029002'}>
                      <Button
                        type={'link'}
                        size={'small'}
                        onClick={() => {
                          const location = {
                            pathname: '/feedback/detail',
                            state: {feedbackId: record.feedbackId},
                          }
                          history.push(location)
                        }}
                        style={{padding: 0}}
                      >
                        编辑
                      </Button>
                    </Permission>
                  ) : (
                    <Permission code={'001029002'}>
                      <Button
                        type={'link'}
                        size={'small'}
                        onClick={() => {
                          const location = {
                            pathname: '/feedback/detail',
                            state: {feedbackId: record.feedbackId},
                          }
                          history.push(location)
                        }}
                        style={{padding: 0}}
                      >
                        查看
                      </Button>
                    </Permission>
                  ),
              },
            ]}
            loading={loading}
            pagination={getPaginationProps(form, total)}
          />
        </div>
      </Form>
    </Page>
  )
}
