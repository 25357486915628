import React, {useCallback, useEffect, useState} from 'react'
import {Page} from '@/components/Page'
import commonStyles from '@/assets/styles/common.module.less'
import {Button, Col, Form, Image, Input, Row, Select, Space, Table} from 'antd'
import {Breadcrumb} from '@/components/Breadcrumb'
import {omsModel} from '@/store/models/Oms'
import {getPaginationProps} from '@/common/biz/getPaginationProps'
import {useAppHistory} from '@/common/hooks/useRouter'
import {useCacheManager} from '@/common/hooks/useCacheManager'
import {useFocusEffect} from '@/common/hooks/useFocusEffect'
import {useListener} from '@/common/hooks/listener'
import {usePermission} from '@/components/Permission'

const statusArr = [
  {id: 1, label: '未生效'},
  {id: 2, label: '合作中'},
  {id: 3, label: '合作结束'},
]
const statusCode = {1: '未生效', 2: '合作中', 3: '合作结束'}
const companyTypeCode = {
  '1': '国有企业',
  '2': '私营企业',
  '3': '事业单位或社会团体',
  '4': '中外合资',
  '5': '外商独资',
  '6': '其它',
}

export default function CompanyList() {
  const history = useAppHistory()
  const [auth] = usePermission()

  const [form] = Form.useForm()

  const [companyTotal, setCompanyTotal] = useState(0)
  const [companyList, setCompanyList] = useState<RpcOmsCompanyVO[]>([])
  const {remove} = useCacheManager()

  useFocusEffect(() => {
    remove('/company-insert')
  })

  const queryCompanyList = useCallback(() => {
    const formValues = form.getFieldsValue(true)
    omsModel.omsCompanyList(formValues).then(({response: {data, total}}) => {
      setCompanyTotal(total)
      setCompanyList(data)
    })
  }, [form])

  useEffect(() => {
    queryCompanyList()
  }, [queryCompanyList])

  useListener('CompanyInsert', () => {
    queryCompanyList()
  })

  const loading = omsModel.omsCompanyList.useLoading()

  return (
    <Page
      breadcrumb={
        <Breadcrumb
          right={
            auth('003164002') && (
              <Button type={'primary'} onClick={() => history.push('/company-insert')}>
                新增公司
              </Button>
            )
          }
        />
      }
    >
      <div className={commonStyles.filterSection}>
        <Form
          form={form}
          labelCol={{span: '80px'}}
          labelAlign={'left'}
          initialValues={{pageNum: 1, pageSize: 10, pageable: true, sort: 'insert_time desc'}}
          onFinish={queryCompanyList}
          onReset={queryCompanyList}
        >
          <Row gutter={20}>
            <Col span={8}>
              <Form.Item name={'companyName'} label={'公司名称'}>
                <Input placeholder={'请输入'} allowClear />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name={'companyAddress'} label={'详细地址'}>
                <Input placeholder={'请输入'} allowClear />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name={'legalPersonName'} label={'法人'}>
                <Input placeholder={'请输入'} allowClear />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name={'contractStatus'} label={'状态'}>
                <Select placeholder={'请选择'} allowClear>
                  {statusArr.map(item => (
                    <Select.Option value={item.id} key={item.id}>
                      {item.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row justify={'end'}>
            <Space>
              <Button type={'primary'} htmlType={'submit'} onClick={() => form.setFieldsValue({pageNum: 1})}>
                查询
              </Button>
              <Button htmlType={'reset'}>重置</Button>
            </Space>
          </Row>
        </Form>
      </div>

      <div className={commonStyles.tableSection}>
        <Table
          rowKey={'companyId'}
          loading={loading}
          dataSource={companyList}
          columns={[
            {
              title: 'LOGO',
              dataIndex: 'companyLogo',
              render: value => (value ? <Image src={value} width={30} height={30} preview={false} /> : null),
            },
            {title: '公司ID', dataIndex: 'companyId'},
            {title: '公司名称', dataIndex: 'companyName'},
            {title: '详细地址', dataIndex: 'companyAddress'},
            {title: '类型', dataIndex: 'companyType', render: value => companyTypeCode[value]},
            {
              title: '平台手续费',
              dataIndex: 'payCommissions',
              render: value => (value ? `${(value * 100).toFixed(2)}%` : '/'),
            },
            {
              title: '通联手续费',
              dataIndex: 'payCommissionsAllinList',
              render: value =>
                value
                  ?.map(
                    ele =>
                      `${{'01': '支付宝', '02': '微信', '03': '银行卡'}[ele.payType]}: ${(
                        ele.payCommissions * 100
                      ).toFixed(2)}%`
                  )
                  ?.join(','),
            },
            {
              title: '系统使用开始时间',
              dataIndex: 'contractBegin',
              sorter: {compare: (a, b) => new Date(a.contractBegin).getTime() - new Date(b.contractBegin).getTime()},
            },
            {
              title: '系统使用结束时间',
              dataIndex: 'contractEnd',
              sorter: {compare: (a, b) => new Date(a.contractEnd).getTime() - new Date(b.contractEnd).getTime()},
            },
            {
              title: '系统使用费',
              dataIndex: 'contractFee',
              sorter: {compare: (a, b) => a.contractFee - b.contractFee},
            },
            {title: '系统使用模块', dataIndex: 'contractModuleDesc'},
            {title: '状态', dataIndex: 'contractStatus', render: value => statusCode[value]},
            {title: '法人', dataIndex: 'legalPersonName'},
            {title: '创建人', dataIndex: 'insertBy'},
            {
              title: '创建时间',
              dataIndex: 'insertTime',
              sorter: {compare: (a, b) => new Date(a.insertTime).getTime() - new Date(b.insertTime).getTime()},
            },
            {
              title: '操作',
              fixed: 'right',
              dataIndex: 'companyId',
              render: value => {
                return (
                  auth('001164001') && (
                    <Button
                      type={'link'}
                      style={{padding: 0}}
                      onClick={() => history.push('/company-detail', {companyId: value})}
                    >
                      查看
                    </Button>
                  )
                )
              },
            },
          ]}
          scroll={{x: 'max-content'}}
          pagination={getPaginationProps(form, companyTotal)}
        />
      </div>
    </Page>
  )
}
