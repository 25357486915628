import React, {useCallback, useEffect, useState} from 'react'
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  message,
  Modal,
  Radio,
  Row,
  Select,
  Space,
  Table,
  Tooltip,
  TreeSelect,
} from 'antd'
import {Breadcrumb} from '@/components/Breadcrumb'
import commonStyles from '@/assets/styles/common.module.less'
import {stewardModel} from '@/store/models/Steward'
import {usePermission} from '@/components/Permission'
import {Page} from '@/components/Page'
import {getPaginationProps} from '@/common/biz/getPaginationProps'
import {MinusCircleFilled, PlusCircleFilled} from '@ant-design/icons'
import {sysModel} from '@/store/models/Sys'
import {useCodeArraySync, useCodeSync} from '@/store/models/Common'
import {userModel} from '@/store/models/User'
import moment from 'moment'
import {sysRoleModel} from '@/store/models/SysRole'
import {downloadBlob} from '@/utils/util'

const bindOption = [
  {label: '已绑定', value: '1'},
  {label: '未绑定', value: '0'},
]

const statusOption = [
  {label: '在职', value: '1'},
  {label: '离职', value: '0'},
]

const {RangePicker} = DatePicker

export default function PerformanceConfiguration() {
  const [auth] = usePermission()
  const [selectedRows, setSelectedRows] = useState([])
  // 查看
  const [isView, setIsView] = useState(false)
  const [isSame, setIsSame] = useState(false)
  // 导出
  const [exportStatus, setExportStatus] = useState(false)
  const [isBatch, setIsBatch] = useState(false)

  const [appList, setAppList] = useState<{label: string; value: string}[]>()
  const [cityList, setCityList] = useState<{label: string; value: string}[]>()
  const [detailData, setDetailData] = useState<StewardSalaryPlanUserListVO>()
  const [roleList, setRoleList] = useState<{label: string; value: string}[]>([])
  const [userIdList, setUserIdList] = useState<string[]>()
  const [msg, setMsg] = useState('')
  const [planList, setPlanList] = useState<{
    [key: string]: {value: string; label: string; applyBeginDate: string; applyEndDate: string}[]
  }>()

  /** 列表相关 */
  const [form] = Form.useForm<StewardServiceChargeQueryDTO>()
  const [total, setTotal] = useState(0)
  const [list, setList] = useState<StewardSalaryPlanUserListVO[]>([])
  const [tree, setTree] = useState<SysOrgTreeNodeVO[]>([])
  const [btnLoading, setBtnLoading] = useState<boolean>(false)
  const orgId = userModel.data.userInfo?.orgId

  const queryList = useCallback(() => {
    stewardModel.stewardSalaryPlanUserList(form.getFieldsValue(true)).then(({response: {data, total}}) => {
      setTotal(total)
      setList(data)
    })
  }, [form])

  useEffect(() => {
    form.submit()
  }, [form])

  const [dispositionForm] = Form.useForm()

  const listLoading = stewardModel.stewardSalaryPlanUserList.useLoading()

  const jobOption = useCodeArraySync('sys.user.steward.position.level')?.map(item => ({
    label: item.label,
    value: item.id,
  }))
  const positionLevelObj = useCodeSync('sys.user.steward.position.level')

  // 标签列表
  useEffect(() => {
    stewardModel
      .stewardOpenAppList({appStatus: 1, pageable: false, pageSize: 1, pageNum: 1} as StewardAppOpenListDTO)
      .then(({response}) => {
        setAppList(response?.data?.map(item => ({label: item.appName, value: item.appId})))
      })
  }, [])

  // 组织列表
  useEffect(() => {
    sysModel.sysOrgTree({fetchUser: false, orgIdLike: orgId} as SysOrgTreeQueryDTO).then(({response: {data}}) => {
      setTree(data)
    })
  }, [orgId])

  // 所在用户组
  useEffect(() => {
    sysRoleModel
      .sysRoleList({pageable: false} as SysListDTO)
      .then(({response: {data}}) => setRoleList(data?.map(item => ({label: item.roleName, value: item.roleId}))))
  }, [])

  // 城市
  useEffect(() => {
    sysModel.sysConfigGet({k: 'CONFIG_SYS_CITY'}).then(({response: {data}}) => {
      data?.city && setCityList(data.city.map(value => ({label: value.label, value: value.id})))
    })
  }, [])

  const filterOption = (input: string, option?: {label: string; value: string}) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())

  // 查询绩效列表
  const queryPlanList = (appId, cityCode, name, status) => {
    stewardModel
      .stewardSalaryPlanList({
        status,
        pageable: false,
        pageNum: 1,
        pageSize: 1,
        appId,
        cityCode,
      } as StewardSalaryPlanListDTO)
      .then(({response: {data}}) => {
        setPlanList({
          ...planList,
          [name + '']: data?.map(item => ({
            value: item.planId,
            label: item.planName,
            applyBeginDate: item.applyBeginDate,
            applyEndDate: item.applyEndDate,
          })),
        })
      })
  }

  // 校验提示
  const handleMsg = list => {
    if (!list.map(item => item?.appId).every(item => !!item !== false)) return
    let obj = {} as {appId: string}

    list?.forEach((item, index) => {
      list?.forEach((child, j) => {
        if (index !== j) {
          if (item.appId === child.appId && item.cityCode === child.cityCode && item.planId === child.planId) {
            obj = item
          }
          if (child?.appId === item?.appId && child?.cityCode === item?.cityCode) {
            if (
              (Date.parse(item.applyBeginDate) >= Date.parse(child.applyBeginDate) &&
                Date.parse(item.applyBeginDate) <= Date.parse(child.applyEndDate)) ||
              (Date.parse(item.applyEndDate) >= Date.parse(child.applyBeginDate) &&
                Date.parse(item.applyEndDate) <= Date.parse(child.applyEndDate)) ||
              (Date.parse(child.applyBeginDate) >= Date.parse(item.applyBeginDate) &&
                Date.parse(child.applyBeginDate) <= Date.parse(item.applyEndDate)) ||
              (Date.parse(child.applyEndDate) >= Date.parse(item.applyBeginDate) &&
                Date.parse(child.applyEndDate) <= Date.parse(item.applyEndDate))
            ) {
              obj = item
            }
          }
        }
      })
    })

    if (obj?.appId) {
      setMsg(`${appList.find(item => item.value === obj?.appId)?.label}绩效方案绑定适用时间重复，不能进行操作！`)
    } else {
      setMsg('')
      return 'pass'
    }
  }

  return (
    <Page
      breadcrumb={
        <Breadcrumb
          right={
            <Space>
              {auth('026188001') && (
                <Button
                  type={'primary'}
                  onClick={() => {
                    if (!selectedRows.length) return message.warn('请至少勾选一条数据')
                    setUserIdList(selectedRows.map(item => item.stewardId))
                    setIsBatch(true)
                    setIsView(true)
                  }}
                >
                  批量绩效方案配置
                </Button>
              )}
              <Button
                type={'primary'}
                onClick={() => {
                  setExportStatus(true)
                }}
              >
                导出
              </Button>
            </Space>
          }
        />
      }
    >
      <div className={commonStyles.filterSection}>
        <Form
          form={form}
          labelCol={{flex: '120px'}}
          initialValues={{pageNum: 1, pageSize: 10, pageable: true}}
          onFinish={queryList}
          onReset={queryList}
        >
          <Row gutter={30}>
            <Col span={7}>
              <Form.Item name={'stewardName'} label={'管家名称'}>
                <Input placeholder={'管家名称'} allowClear />
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item name={'orgId'} label={'组织'}>
                <TreeSelect placeholder={'请选择'} allowClear treeData={tree} />
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item name={'roleId'} label={'所在用户组'}>
                <Select placeholder={'不限'} allowClear options={roleList} showSearch filterOption={filterOption} />
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item name={'planName'} label={'绩效方案'}>
                <Input placeholder={'请输入绩效方案名称'} allowClear />
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item name={'positionLevel'} label={'岗位等级'}>
                <Select placeholder={'不限'} allowClear options={jobOption} />
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item name={'status'} label={'在职状态'}>
                <Select placeholder={'不限'} allowClear options={statusOption} />
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item name={'bind'} label={'绑定状态'}>
                <Select placeholder={'不限'} allowClear options={bindOption} />
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item name={'appId'} label={'服务标签'}>
                <Select placeholder={'不限'} allowClear options={appList} />
              </Form.Item>
            </Col>
          </Row>
          <Row justify={'end'}>
            <Space>
              <Button type={'primary'} htmlType={'submit'} onClick={() => form.setFieldsValue({pageNum: 1})}>
                查询
              </Button>
              <Button htmlType={'reset'}>重置</Button>
            </Space>
          </Row>
        </Form>
      </div>

      <div className={commonStyles.tableSection}>
        <Table
          rowKey={'stewardId'}
          rowSelection={{
            columnWidth: 50,
            selectedRowKeys: selectedRows.map(item => item.stewardId),
            onChange: (_, rows) => setSelectedRows(rows),
          }}
          loading={listLoading}
          dataSource={list}
          columns={[
            {title: '姓名', dataIndex: 'stewardName'},
            {title: '手机号', dataIndex: 'stewardMobile'},
            {title: '服务标签', dataIndex: 'appName'},
            {title: '组织', dataIndex: 'orgName'},
            {
              title: '所在用户组',
              dataIndex: 'roleName',
              render: value => (
                <Tooltip title={value.length < 30 ? null : value}>
                  {value.length < 30 ? value : value.substring(0, 31) + '......'}
                </Tooltip>
              ),
            },
            {title: '在职状态', dataIndex: 'status', render: value => (value === '1' ? '在职' : '离职')},
            {title: '岗位等级', dataIndex: 'positionLevel', render: value => positionLevelObj[value]},
            {title: '绩效方案', dataIndex: 'planName'},
            {title: '绑定状态', dataIndex: 'bind', render: value => (value === '1' ? '已绑定' : '未绑定')},
            {title: '绑定人', dataIndex: 'bindName'},
            {title: '绑定时间', dataIndex: 'bindTime'},
            {
              title: '操作',
              dataIndex: 'bind',
              render: (_, record) => {
                return (
                  <Space>
                    {auth('018188001') && (
                      <Button
                        type='link'
                        style={{padding: 0}}
                        onClick={async () => {
                          setDetailData(record)
                          setIsBatch(false)
                          setIsView(true)
                          setUserIdList([record.stewardId])
                          dispositionForm.setFieldsValue({
                            execute: 1,
                            list: record.planList.length
                              ? record.planList.map(item => ({
                                  planName: item.planName,
                                  appId: item?.appId,
                                  cityCode: item.cityCode,
                                  planId: item.planId,
                                  applyBeginDate: item.applyBeginDate,
                                  applyEndDate: item.applyEndDate,
                                }))
                              : [{appId: null}],
                          })
                        }}
                      >
                        绩效方案配置
                      </Button>
                    )}
                  </Space>
                )
              },
            },
          ]}
          pagination={getPaginationProps(form, total)}
        />
      </div>

      {/* 绩效方案配置 */}
      <Modal
        open={isView}
        title={isBatch ? '批量绩效方案配置' : '绩效方案配置'}
        centered
        width={1040}
        onCancel={() => setIsView(false)}
        key={JSON.stringify(detailData)}
        afterClose={() => {
          dispositionForm.resetFields()
          setMsg('')
          setIsSame(false)
        }}
        onOk={async () => {
          if (isSame) return
          await dispositionForm.validateFields()
          const {list, execute} = dispositionForm.getFieldsValue(true)

          const flag = handleMsg(list)
          if (flag === 'pass') {
            if (isBatch) {
              await stewardModel.stewardSalaryPlanUserBatchAddInsert({
                execute,
                planInfoList: list,
                userIdList,
              })
            } else {
              await stewardModel.stewardSalaryPlanUserBatchInsert({
                execute,
                planInfoList: list,
                userIdList,
              })
            }
            message.success('操作成功')
            setIsView(false)
            form.submit()
          }
        }}
      >
        <Form
          colon
          labelAlign='right'
          labelCol={{span: 3}}
          form={dispositionForm}
          initialValues={{execute: 1, list: [{appId: null}]}}
          key={JSON.stringify(detailData)}
        >
          {isBatch ? (
            <Form.Item label='执行操作' name={'execute'} rules={[{required: true, message: '请选择'}]}>
              <Radio.Group>
                <Radio value={0}>解绑</Radio>
                <Radio value={1}>绑定</Radio>
              </Radio.Group>
            </Form.Item>
          ) : (
            <>
              <Form.Item label='名称'>{detailData?.stewardName}</Form.Item>
              <Form.Item label='手机号'>{detailData?.stewardMobile}</Form.Item>
              <Form.Item label='组织'>{detailData?.orgName}</Form.Item>
              <Form.Item label='所在用户组'>{detailData?.roleName}</Form.Item>
              <Form.Item label='在职状态'>{detailData?.status === '1' ? '在职' : '离职'}</Form.Item>
              <Form.Item label='岗位等级'>{positionLevelObj[detailData?.positionLevel]}</Form.Item>
            </>
          )}
          <Form.List name={'list'}>
            {(fields, {add, remove}) => {
              return (
                <Form.Item label='绩效方案' required style={{marginBottom: 0}}>
                  {fields.length === 0 && <PlusCircleFilled onClick={() => add()} />}
                  {fields.map(({key, name, ...restField}) => {
                    return (
                      <Row align={'middle'} key={key}>
                        <Col style={{marginRight: 8}}>
                          <Form.Item> 标签</Form.Item>
                        </Col>
                        <Col style={{marginRight: 8, width: 120}}>
                          <Form.Item
                            name={[name, 'appId']}
                            {...restField}
                            rules={[{required: true, message: '请选择'}]}
                          >
                            <Select
                              placeholder={'请选择'}
                              allowClear
                              options={appList}
                              onChange={value => {
                                const execute = dispositionForm.getFieldValue('execute')

                                const list = dispositionForm.getFieldValue('list')
                                const newList = list?.map(item => item?.appId).filter(item => item)
                                if (value && Array.from(new Set(newList)).length > 1) {
                                  return setIsSame(true)
                                } else {
                                  setIsSame(false)
                                }
                                dispositionForm.resetFields([
                                  ['list', name, 'planId'],
                                  ['list', name, 'planName'],
                                  ['list', name, 'applyBeginDate'],
                                  ['list', name, 'applyEndDate'],
                                ])
                                const cityCode = dispositionForm.getFieldValue(['list', name, 'cityCode'])
                                if (!cityCode) return
                                queryPlanList(value, cityCode, name, execute === 0 ? '' : '1')
                              }}
                            />
                          </Form.Item>
                        </Col>
                        <Col style={{marginRight: 8}}>
                          <Form.Item>城市</Form.Item>
                        </Col>
                        <Col style={{marginRight: 8, width: 100}}>
                          <Form.Item
                            name={[name, 'cityCode']}
                            {...restField}
                            rules={[{required: true, message: '请选择'}]}
                          >
                            <Select
                              placeholder={'请选择'}
                              showSearch
                              filterOption={filterOption}
                              allowClear
                              options={cityList}
                              onChange={(value, formatString) => {
                                const execute = dispositionForm.getFieldValue('execute')

                                const {label} = formatString as {
                                  label: string
                                  value: string
                                }
                                dispositionForm.setFields([
                                  {
                                    name: ['list', name, 'cityName'],
                                    value: label,
                                  },
                                ])
                                dispositionForm.resetFields([
                                  ['list', name, 'planId'],
                                  ['list', name, 'planName'],
                                  ['list', name, 'applyBeginDate'],
                                  ['list', name, 'applyEndDate'],
                                ])
                                const appId = dispositionForm.getFieldValue(['list', name, 'appId'])
                                if (!appId) return
                                queryPlanList(appId, value, name, execute === 0 ? '' : '1')
                              }}
                            />
                          </Form.Item>
                        </Col>
                        <Col style={{marginRight: 8}}>
                          <Form.Item>绩效方案名称</Form.Item>
                        </Col>
                        <Col style={{marginRight: 8, width: 140}}>
                          <Form.Item
                            name={[name, 'planName']}
                            {...restField}
                            rules={[{required: true, message: '请选择'}]}
                          >
                            <Select
                              placeholder={'请选择'}
                              allowClear
                              showSearch
                              filterOption={filterOption}
                              options={planList?.[name]}
                              onClick={() => {
                                const execute = dispositionForm.getFieldValue('execute')

                                const appId = dispositionForm.getFieldValue(['list', name, 'appId'])
                                if (!appId) return
                                const cityCode = dispositionForm.getFieldValue(['list', name, 'cityCode'])
                                if (!cityCode) return
                                queryPlanList(appId, cityCode, name, execute === 0 ? '' : '1')
                              }}
                              onChange={(value, option) => {
                                const {applyBeginDate, applyEndDate, label} = option as {
                                  label: string
                                  value: string
                                  applyEndDate: string
                                  applyBeginDate: string
                                }
                                dispositionForm.setFields([
                                  {
                                    name: ['list', name, 'applyBeginDate'],
                                    value: moment(applyBeginDate),
                                  },
                                  {
                                    name: ['list', name, 'applyEndDate'],
                                    value: moment(applyEndDate),
                                  },
                                  {
                                    name: ['list', name, 'planId'],
                                    value: value,
                                  },
                                  {
                                    name: ['list', name, 'planName'],
                                    value: label,
                                  },
                                ])
                              }}
                            />
                          </Form.Item>
                        </Col>
                        <Col style={{marginRight: 8}}>
                          <Form.Item>适用时间</Form.Item>
                        </Col>
                        <Col style={{marginRight: 8, width: 200}}>
                          <Form.Item
                            name={[name, 'applyBeginDate']}
                            {...restField}
                            getValueFromEvent={(_, formatString) => {
                              dispositionForm.setFieldValue(['list', name, 'applyEndDate'], formatString?.[1])
                              return formatString?.[0]
                            }}
                            getValueProps={value => ({
                              value: value
                                ? [moment(value), moment(dispositionForm.getFieldValue(['list', name, 'applyEndDate']))]
                                : value,
                            })}
                          >
                            <RangePicker picker='month' disabled />
                          </Form.Item>
                        </Col>
                        <Col flex={'28px'}>
                          <Form.Item>
                            <Space>
                              <PlusCircleFilled onClick={() => add()} />
                              {fields.length > 1 && (
                                <MinusCircleFilled
                                  onClick={() => {
                                    remove(name)
                                    const list = dispositionForm.getFieldValue('list')
                                    const newList = list?.map(item => item?.appId).filter(item => item)
                                    if (Array.from(new Set(newList)).length > 1) {
                                      return setIsSame(true)
                                    } else {
                                      setIsSame(false)
                                    }
                                    handleMsg(list)
                                  }}
                                />
                              )}
                            </Space>
                          </Form.Item>
                        </Col>
                      </Row>
                    )
                  })}
                </Form.Item>
              )
            }}
          </Form.List>
          <Form.Item noStyle>
            <div style={{marginLeft: 50, color: 'red'}}>{msg && msg}</div>
            <div style={{marginLeft: 50, color: 'red'}}>{isSame && '不能添加不同标签绩效方案绑定！'}</div>
          </Form.Item>
        </Form>
      </Modal>

      {/* 批量导出*/}
      <Modal
        open={exportStatus}
        title={'批量导出'}
        confirmLoading={btnLoading}
        centered
        onOk={async () => {
          setBtnLoading(true)
          const formValues = form.getFieldsValue(true)
          try {
            await downloadBlob({
              url: '/steward/salary-plan-user/list-excel',
              body: {
                ...formValues,
                pageable: false,
              },
            })
          } finally {
            setBtnLoading(false)
          }
          message.success('操作成功')
          setExportStatus(false)
        }}
        onCancel={() => setExportStatus(false)}
      >
        确认导出当前查询结果集数据？
      </Modal>
    </Page>
  )
}
