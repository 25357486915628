import React, {useState, useEffect, useMemo, useCallback} from 'react'
import {Row, Col, Button, Collapse, Table, List} from 'antd'
import {useHistory} from 'react-router-dom'
import {lessorModel} from '@/store/models/Lessor'
import {commonModel} from '@/store/models/Common'
import {CaretRightOutlined} from '@ant-design/icons/lib'

import './index.less'
import {customerModel} from '@/store/models/Customer'
import {Page} from '@/components/Page'
import BackButton from '@/components/BackButton'

const {Panel} = Collapse
export default function LessorDetail(props: any) {
  const history = useHistory()
  const [detail, setDetail] = useState<LessorInfoVO>()
  const state = props.history.location?.state
  const gender = useMemo(() => commonModel.getCodeSync('gender'), [])
  const [teamId, setTeamId] = useState('')

  const [teamGroup, setTeamGroup] = useState<TeamTreeNodeVO[]>([])
  const [tableData, setTableData] = useState<any[]>([])
  //查询团队架构
  const queryTeamTrees = useCallback((userId: string) => {
    customerModel.customerTeamGetTeamTree({ownerId: userId}).then(({response: {data}}) => {
      setTeamGroup(data)
    })
  }, [])

  const loading = customerModel.customerTeamListMember.useLoading()

  const queryTeamMember = useCallback(
    (teamId: string) => {
      customerModel
        .customerTeamListMember({teamId, ownerId: detail?.userId})
        .then(({response: {data}}) => setTableData(data))
    },
    [detail?.userId]
  )

  useEffect(() => {
    if (state?.userId) {
      lessorModel.queryLessorInfo(state?.userId).then(({response: {data}}) => {
        setDetail(data)
      })
      queryTeamTrees(state?.userId)
    }
  }, [queryTeamTrees, state])

  useEffect(() => {
    if (teamId) {
      queryTeamMember(teamId)
    }
  }, [queryTeamMember, teamId])

  const payLineCountMoneyPercent = useMemo(() => {
    return detail?.lessorContractBillCountVO?.billPayLineMoneyCount && detail?.lessorContractBillCountVO?.billMoneyCount
      ? Number(
          (
            detail?.lessorContractBillCountVO?.billPayLineMoneyCount / detail?.lessorContractBillCountVO?.billMoneyCount
          ).toFixed(2)
        ) *
          100 +
          '%'
      : '0%'
  }, [detail])
  const payLineCountPercent = useMemo(() => {
    return detail?.lessorContractBillCountVO?.billPayLineCount && detail?.lessorContractBillCountVO?.billCount
      ? Number(
          (detail?.lessorContractBillCountVO?.billPayLineCount / detail?.lessorContractBillCountVO?.billCount).toFixed(
            2
          )
        ) *
          100 +
          '%'
      : '0%'
  }, [detail])

  return (
    <Page>
      <BackButton />
      <section className={'detail'}>
        <article className={'content'}>
          <header className={'header'}>基础信息</header>
          <Row className={'row border-top'}>
            <Col span={8} className={'col border-right'}>
              用户名称：{detail?.certName}
            </Col>
            <Col span={8} className={'col border-right'}>
              手机号：{detail?.mobile}
            </Col>
            <Col span={8} className={'col'}>
              性别：{detail?.gender ? gender[detail?.gender] : ''}
            </Col>
          </Row>
          <Row className={'row border-top-bottom'}>
            <Col span={12} className={'col border-right'}>
              地址：
              {`${detail?.province ?? ''}${detail?.city ?? ''}${detail?.district ?? ''}${detail?.bizCircle ?? ''}`}
            </Col>
            <Col span={12} className={'col'}>
              身份证号：{detail?.certNo}
            </Col>
          </Row>
          <Row className={'row border-bottom remark'}>
            <Col>备注：{detail?.remark}</Col>
          </Row>
          <header className={'header'}>BD信息</header>
          <Row className={'row border-top-bottom'}>
            <Col span={12} className={'col border-right'}>
              客户经理：{detail?.manageName}
            </Col>
            <Col span={12} className={'col'}>
              手机号：{detail?.manageMobile}
            </Col>
          </Row>
          <header className={'header'}>房源信息</header>
          <section className={'section border-top-bottom'}>
            <div className={'list'}>
              <div className={'item'}>
                <span>{detail?.propertyCountVO?.propertyNum}</span>
                <span>房源总数</span>
              </div>
              <div className={'item'}>
                <span>{detail?.propertyCountVO?.condoPropertyNum}</span>
                <span>集中式房源</span>
              </div>
              <div className={'item'}>
                <span>{detail?.propertyCountVO?.scatterPropertyNum}</span>
                <span>分散整租</span>
              </div>
              <div className={'item'}>
                <span>
                  {detail?.propertyCountVO?.scatterJoinPropertyNum}（{detail?.propertyCountVO?.scatterPropertySuiteNum}
                  ）
                </span>
                <span>分散合租（套数）</span>
              </div>
            </div>
            <Button
              type={'link'}
              onClick={() => {
                history.push({pathname: '/property', state: {lessor: detail.mobile}})
              }}
            >
              查看该房东为团队创建者的房源列表
            </Button>
          </section>
          <header className={'header'}>租约信息</header>
          <section className={'section border-top-bottom'}>
            <div className={'list'}>
              <div className={'item'}>
                <span>{detail?.lessorContractCountVO?.contractNum}</span>
                <span>租约总数</span>
                <span>（不含作废）</span>
              </div>
              <div className={'item'}>
                <span>{detail?.lessorContractCountVO?.executingContractNum}</span>
                <span>执行中租约数</span>
              </div>
              <div className={'item'}>
                <span>{detail?.lessorContractCountVO?.checkOutContractNum}</span>
                <span>已退房</span>
              </div>
              <div className={'item'}>
                <span>{detail?.lessorContractCountVO?.executingElectrContractNum}</span>
                <span>执行中电子合同（总数）</span>
              </div>
              <div className={'item'}>
                <span>{detail?.lessorContractCountVO?.executingPaperContractNum}</span>
                <span>执行中纸质合同（总数）</span>
                <span>（总数不含作废）</span>
              </div>
            </div>
            <Button
              type={'link'}
              onClick={() => {
                history.push({pathname: '/contract', state: {lessor: detail.mobile}})
              }}
            >
              查看房东租约列表
            </Button>
          </section>
          <header className={'header'}>账单信息</header>
          <section className={'section border-top'}>
            <div className={'list'}>
              <div className={'item'}>
                <span>
                  {detail?.lessorContractBillCountVO?.billMoneyCount} ({detail?.lessorContractBillCountVO?.billCount})
                </span>
                <span>账单总金额（数量）</span>
                <span>（不含作废）</span>
              </div>
              <div className={'item'}>
                <span>
                  {detail?.lessorContractBillCountVO?.billNoPayMoneyCount} (
                  {detail?.lessorContractBillCountVO?.billNoPayCount})
                </span>
                <span>未支付金额（数量）</span>
              </div>
              <div className={'item'}>
                <span>
                  {detail?.lessorContractBillCountVO?.billPayMoneyCount} (
                  {detail?.lessorContractBillCountVO?.billPayCount})
                </span>
                <span>已支付金额（数量）</span>
              </div>
              <div className={'item'}>
                <span>
                  {detail?.lessorContractBillCountVO?.billPayLineMoneyCount} (
                  {detail?.lessorContractBillCountVO?.billPayLineCount})
                </span>
                <span>线上支付金额（数量）</span>
              </div>
              <div className={'item'}>
                <span>
                  {payLineCountMoneyPercent} ({payLineCountPercent})
                </span>
                <span>线上支付金额比例（数量比例）</span>
              </div>
            </div>
            <Button
              type={'link'}
              onClick={() => {
                history.push({pathname: '/bill', state: {lessor: detail.mobile}})
              }}
            >
              查看房东账单信息
            </Button>
          </section>
          <header className={'header'}>组织架构</header>
          {teamGroup[0]?.teamId ? (
            <Row>
              <Col span={3} style={{borderRightColor: '#DCDFE6', borderRightWidth: '1px', borderRightStyle: 'solid'}}>
                <Collapse
                  expandIcon={({isActive}) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                  defaultActiveKey={teamGroup[0]?.teamId}
                  onChange={key => {
                    if (key) {
                      setTeamId(key as string)
                    }
                  }}
                  accordion
                  className={'collapseHeader'}
                  bordered={false}
                >
                  {teamGroup?.map(item => {
                    return (
                      <Panel
                        header={item?.name}
                        key={item?.teamId}
                        className={item.teamId === teamId ? 'activeCollapse' : ''}
                      >
                        <List
                          split={false}
                          dataSource={item?.children ?? []}
                          renderItem={group => (
                            <List.Item
                              onClick={() => setTeamId(group?.teamId)}
                              className={group?.teamId === teamId ? 'activeStyle' : ''}
                              style={{paddingLeft: 60, paddingRight: 16}}
                            >
                              {group.name}
                            </List.Item>
                          )}
                        />
                      </Panel>
                    )
                  })}
                </Collapse>
              </Col>
              <Col span={21}>
                <p style={{color: '#000000', fontSize: '14px', fontWeight: 500, padding: '18px 40px'}}>成员列表</p>
                <Table
                  rowKey={'userId'}
                  dataSource={tableData}
                  columns={[
                    {
                      dataIndex: 'certName',
                      key: 'certName',
                      // render: ({avatar, certName}) => {
                      //   return (
                      //     <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                      //       <img style={{width: '40px', height: '40px', borderRadius: '20px'}} src={getImg(avatar)} />
                      //       <span style={{marginLeft: '10px'}}>{certName}</span>
                      //     </div>
                      //   )
                      // },
                    },
                    {
                      dataIndex: 'mobile',
                      key: 'mobile',
                    },
                    {
                      dataIndex: 'teamName',
                      key: 'teamName',
                    },
                    {
                      dataIndex: 'roleName',
                      key: 'roleName',
                      // render: text => (
                      //   <Tag color={tagType[text]} style={{border: 'none'}}>
                      //     {teamRoleType[text]}
                      //   </Tag>
                      // ),
                    },
                  ]}
                  showHeader={false}
                  pagination={false}
                  loading={loading}
                />
              </Col>
            </Row>
          ) : (
            <div style={{height: 100, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
              <span style={{color: ' #00B7AE'}}>该房东尚未添加组织</span>
            </div>
          )}
        </article>

        <footer className={'footer'}>
          <Button type={'primary'} onClick={() => history.goBack()}>
            返回
          </Button>
        </footer>
      </section>
    </Page>
  )
}
