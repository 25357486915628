import {Select, SelectProps} from 'antd'
import React, {useEffect, useState} from 'react'
import {sysModel} from '@/store/models/Sys'

/**
 * Form.Item 子项
 * 选择城市，可搜索
 */
export default function CitySelect(props: SelectProps) {
  const [cityCodes, setCityCodes] = useState<{label: string; id: string}[]>([])

  useEffect(() => {
    sysModel.sysConfigGet({k: 'CONFIG_SYS_CITY'}).then(({response: {data}}) => {
      data?.city && setCityCodes(data.city)
    })
  }, [])

  return <SearchSelect fieldNames={{label: 'label', value: 'id'}} options={cityCodes} {...props} />
}

export function SearchSelect(props: SelectProps) {
  return (
    <Select
      showSearch
      filterOption={(inputValue, option) => {
        const labelProp = props.fieldNames?.label || 'label'
        return option[labelProp]?.toLowerCase()?.includes(inputValue?.toLowerCase())
      }}
      {...props}
    />
  )
}
