import React, {useCallback, useEffect, useState} from 'react'
import {Page} from '@/components/Page'
import {Button, Col, Form, Input, Row, Space, Table} from 'antd'
import commonStyles from '@/assets/styles/common.module.less'
import {condoModel} from '@/store/models/Condo'
import {useHistory} from 'react-router-dom'
import {usePermission} from '@/components/Permission'
import {useFocusEffect} from '@/common/hooks/useFocusEffect'
import {getPaginationProps} from '@/common/biz/getPaginationProps'

export default function ApartmentDataList() {
  const history = useHistory()
  const [auth] = usePermission()

  const [form] = Form.useForm<CondoInfoAdminQueryDTO>()
  const [condoList, setCondoList] = useState<CondoInfoAdminQueryResultVO[]>([])
  const [condoTotal, setCondoTotal] = useState(0)
  const queryCondoList = useCallback(() => {
    const values = form.getFieldsValue(true)
    condoModel.condoList(values).then(({response: {data, total}}) => {
      setCondoList(data)
      setCondoTotal(total)
    })
  }, [form])

  useFocusEffect(() => {
    form.submit()
  })

  useEffect(() => {
    form.submit()
  }, [form])

  const dataLoading = condoModel.condoList.useLoading()

  return (
    <Page>
      <Form form={form} initialValues={{pageNum: 1, pageSize: 10}} onFinish={queryCondoList} onReset={queryCondoList}>
        <div className={commonStyles.filterSection}>
          <Row>
            <Col span={7}>
              <Form.Item name={'customerName'} label={'房东'}>
                <Input placeholder={'请输入房东名称'} allowClear />
              </Form.Item>
            </Col>
            <Col span={7} offset={1}>
              <Form.Item name={'customerMobile'} label={'手机号'}>
                <Input placeholder={'请输入手机号'} allowClear />
              </Form.Item>
            </Col>
            <Col span={7} offset={1}>
              <Form.Item name={'condoName'} label={'公寓名称'}>
                <Input placeholder={'请输入'} allowClear />
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item name={'condoAddress'} label={'地址'}>
                <Input placeholder={'请输入地址'} allowClear />
              </Form.Item>
            </Col>
            <Col span={7} offset={1}>
              <Form.Item name={'condoId'} label={'公寓ID'}>
                <Input placeholder={'请输入'} allowClear />
              </Form.Item>
            </Col>
          </Row>
          <Row justify={'end'}>
            <Space>
              <Button type={'primary'} htmlType={'submit'} onClick={() => form.setFieldsValue({pageNum: 1})}>
                查询
              </Button>
              <Button htmlType={'reset'}>重置</Button>
            </Space>
          </Row>
        </div>

        <div className={commonStyles.tableSection}>
          <Table
            rowKey={'condoId'}
            loading={dataLoading}
            pagination={getPaginationProps(form, condoTotal)}
            dataSource={condoList}
            columns={[
              {title: '公寓ID', dataIndex: 'condoId', key: 'condoId'},
              {title: '房东', dataIndex: 'customerName', key: 'customerName'},
              {title: '手机号', dataIndex: 'customerMobile', key: 'customerMobile'},
              {title: '公寓名称', dataIndex: 'condoName', key: 'condoName'},
              {title: '地址', dataIndex: 'condoAddress', key: 'condoAddress'},
              {
                title: '操作',
                dataIndex: 'condoId',
                key: 'condoId',
                render: (condoId: string) =>
                  auth('002094002') && (
                    <Button
                      type={'link'}
                      style={{padding: 0}}
                      onClick={() => history.push('/apartment/detail', {condoId})}
                    >
                      编辑
                    </Button>
                  ),
              },
            ]}
          />
        </div>
      </Form>
    </Page>
  )
}
