import {Breadcrumb} from '@/components/Breadcrumb'
import {Page} from '@/components/Page'
import {Button, Col, DatePicker, Descriptions, Form, Input, Modal, PageHeader, Row, Space, Table, Tabs} from 'antd'
import React, {useCallback, useEffect, useState} from 'react'
import pageStyle from '@/components/Page/index.module.less'
import commonStyles from '@/assets/styles/common.module.less'
import DividerLine from '@/pages/property/component/DividerLine'
import {useAppHistory, useAppLocation} from '@/common/hooks/useRouter'
import {adminModel} from '@/store/models/Admin'
import {commonModel, useCodeSync} from '@/store/models/Common'
import {getPaginationProps} from '@/common/biz/getPaginationProps'

const renderRow = {
  customerGender: text => commonModel.getCodeSync('gender')[text],
  customerChannel: text => commonModel.getCodeSync('condo.cms.customerChannel')[text],
}
export default function CRMClientDetail() {
  const history = useAppHistory()
  const location = useAppLocation<'/crm/client-detail'>()
  const customerId = location.state?.customerId
  const [customerDetail, setCustomerDetail] = useState({} as CondoCustomerVO)
  const gender = useCodeSync('gender')
  const customerChannel = useCodeSync('condo.cms.customerChannel')
  const [form] = Form.useForm()
  const [historyList, setHistoryList] = useState<CondoCustomerEditRecordVO[]>([])
  const [total, setTotal] = useState(0)
  const search = useCallback(() => {
    const values = form.getFieldsValue(true)
    adminModel.adminCondoCustomerListEditRecord({...values, customerId}).then(({response: {data, total}}) => {
      setHistoryList(data)
      setTotal(total)
    })
  }, [customerId, form])

  useEffect(() => {
    search()
  }, [search])

  useEffect(() => {
    adminModel.adminCondoCustomerGet({customerId} as CondoCustomerGetDTO).then(({response: {data}}) => {
      setCustomerDetail(data)
    })
  }, [customerId])

  // 变更信息显示
  const changeInfo = data => {
    return (
      <Button
        type={'link'}
        style={{paddingLeft: 0, paddingRight: 0}}
        onClick={() => {
          Modal.info({
            title: '详细信息',
            content: (
              <Table
                rowKey={'fieldNameCN'}
                columns={[
                  {title: '变更字段', dataIndex: 'fieldNameCN'},
                  {title: '变更前', dataIndex: 'beforeVal', render: customRender},
                  {title: '变更后', dataIndex: 'afterVal', render: customRender},
                ]}
                dataSource={data}
                pagination={false}
              />
            ),
            icon: null,
            centered: true,
            width: 700,
            okText: '关闭',
          })
        }}
      >
        详细信息
      </Button>
    )
  }

  return (
    <Page
      breadcrumb={<Breadcrumb style={{background: '#fff'}} />}
      header={<PageHeader title={'客户详情'} className={pageStyle.header} />}
    >
      <Tabs className={commonStyles.tabs}>
        <Tabs.TabPane tab={'基本信息'} key={'1'}>
          <div className={commonStyles.filterSection}>
            <DividerLine title='基本信息' bottom={15} />
            <Descriptions column={2}>
              <Descriptions.Item label={'ID'}>{customerDetail.customerId}</Descriptions.Item>
              <Descriptions.Item label={'姓名'}>{customerDetail.customerName}</Descriptions.Item>
              <Descriptions.Item label={'性别'}>{gender[customerDetail.customerGender]}</Descriptions.Item>
              <Descriptions.Item label={'手机号'}>{customerDetail.customerMobile ?? '/'}</Descriptions.Item>
              <Descriptions.Item label={'微信号'}>{customerDetail.customerWechat ?? '/'}</Descriptions.Item>
              <Descriptions.Item label={'获取渠道'}>
                {customerChannel[customerDetail.customerChannel]}
              </Descriptions.Item>
              <Descriptions.Item
                label={'预算金额'}
              >{`${customerDetail.budgetMin}-${customerDetail.budgetMax}元`}</Descriptions.Item>
              <Descriptions.Item label={'备注'}>{customerDetail.remark ?? '/'}</Descriptions.Item>
              <Descriptions.Item label={'归属人'}>{customerDetail.ownerName}</Descriptions.Item>
              <Descriptions.Item label={'创建人'}>{customerDetail.creatorName}</Descriptions.Item>
              <Descriptions.Item label={'创建时间'}>{customerDetail.insertTime}</Descriptions.Item>
            </Descriptions>
            <Button style={{marginTop: 30}} onClick={() => history.goBack()}>
              返回
            </Button>
          </div>
        </Tabs.TabPane>
        <Tabs.TabPane tab={'变更历史'} key={'2'}>
          <div className={commonStyles.filterSection}>
            <Form form={form} onFinish={search} onReset={search} initialValues={{pageNum: 1, pageSize: 10}}>
              <Row>
                <Col>
                  <Form.Item label={'变更人'} name={'editorName'}>
                    <Input placeholder='请输入' allowClear />
                  </Form.Item>
                </Col>
                <Col offset={1}>
                  <Form.Item label={'变更时间'} name={'time'}>
                    <DatePicker.RangePicker
                      onChange={(_, dateStrings) => {
                        const [insertTimeBegin, insertTimeEnd] = dateStrings ?? []
                        form.setFieldsValue({
                          insertTimeBegin: insertTimeBegin ? insertTimeBegin + ' 00:00:00' : undefined,
                          insertTimeEnd: insertTimeEnd ? insertTimeEnd + ' 23:59:59' : undefined,
                        })
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row justify={'end'}>
                <Space>
                  <Button type={'primary'} htmlType={'submit'} onClick={() => form.setFieldsValue({pageNum: 1})}>
                    查询
                  </Button>
                  <Button htmlType={'reset'}>重置</Button>
                </Space>
              </Row>
            </Form>
          </div>
          <div className={commonStyles.tableSection}>
            <Table
              rowKey={'recordId'}
              dataSource={historyList}
              columns={[
                {title: '编号', dataIndex: 'recordId'},
                {title: '变更人', dataIndex: 'editorName'},
                {title: '变更时间', dataIndex: 'updateTime'},
                {
                  title: '变更字段',
                  dataIndex: 'compareJson',
                  render: text => {
                    const compare = JSON.parse(text)
                    const info = compare[0]
                    return compare?.length === 1 ? info.fieldNameCN : changeInfo(compare)
                  },
                },
                {
                  title: '变更前',
                  dataIndex: 'compareJson',
                  render: text => {
                    const compare = JSON.parse(text)
                    const info = compare[0]
                    return compare?.length === 1
                      ? renderRow[info.fieldName]?.(info.beforeVal) || info.beforeVal || '无'
                      : changeInfo(compare)
                  },
                },
                {
                  title: '变更后',
                  dataIndex: 'compareJson',
                  render: text => {
                    const compare = JSON.parse(text)
                    const info = compare[0]
                    return compare?.length === 1
                      ? renderRow[info.fieldName]?.(info.afterVal) || info.afterVal || '无'
                      : changeInfo(compare)
                  },
                },
              ]}
              pagination={getPaginationProps(form, total)}
            />
          </div>
        </Tabs.TabPane>
      </Tabs>
    </Page>
  )
}

function createRender<
  T extends {
    fieldNameCN: string
    fieldName: string
    beforeVal: string
    afterVal: string
  }
>(rows) {
  return (text, record: T) => {
    if (rows[record.fieldName]) {
      return rows[record.fieldName](text)
    }
    return text || '无'
  }
}

const customRender = createRender(renderRow)
