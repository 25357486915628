import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {getBMapGL, Mapview} from '@/components/Map'
import {sysModel} from '@/store/models/Sys'
import {OrgRoleId} from '@/common/constants'
import {Label, LabelProps} from '@/components/Map/Label'
import {Page} from '@/components/Page'
import {Marker} from '@/components/Map/Marker'
import {Absolute, Bubble} from '@/pages/steward/components/location_widget'
import styles from '@/pages/steward/StewardLocation/index.module.less'
import {AutoComplete, Input, Modal, Space} from 'antd'
import {saleModel} from '@/store/models/Sale'
import moment from 'moment'

const iconSize = 76

export default function SaleLocation() {
  const mapRef = useRef<Mapview>()
  const [locations, setLocations] = useState<StewardLocationVO[]>([])
  const [search, setSearch] = useState('')
  const searchLocations = useMemo(
    () => (search ? locations.filter(value => value.stewardName.includes(search)) : locations),
    [locations, search]
  )

  useEffect(() => {
    sysModel.sysUserLocationList({roleId: OrgRoleId.cus_manager}).then(({response: {data}}) => setLocations(data))
  }, [])

  const [detail, setDetail] = useState<
    Pick<StewardOrderQueryVO, 'stewardId'> & {address: string; point: LabelProps['point']} & {vo: SaleCustomerVisitVO}
  >(null)
  const stewardOrderDetail = useCallback(async (stewardId: string, point: LabelProps['point']) => {
    const BMapGL = getBMapGL()
    const myGeo = new BMapGL.Geocoder()
    const location = new BMapGL.Point(point.lng, point.lat)
    const result: {address: string} = await new Promise(resolve => myGeo.getLocation(location, resolve))

    const today = moment().format('YYYY-MM-DD')
    const {
      response: {data},
    } = await saleModel.saleCustomerVisitList({
      holderId: stewardId,
      pageNum: 1,
      pageSize: 1,
      pageable: true,
      visitDateBegin: today,
      visitDateEnd: today,
    } as SaleCustomerVisitListDTO)

    return {stewardId, point, address: result?.address, vo: data?.[0]}
  }, [])

  return (
    <Page style={{height: 'calc(100% - 80px)', position: 'relative'}}>
      <Mapview ref={mapRef}>
        {locations.map(value => {
          const active = value.stewardId === detail?.stewardId
          return (
            <Marker
              key={value.stewardId}
              lat={value.lat}
              lng={value.lng}
              options={{anchor: {width: -iconSize / 2, height: -iconSize / 2}}}
              dependencies={[active]}
              onClick={async event => {
                if (active) {
                  setDetail(null)
                } else {
                  const position = event.target.getPosition()
                  const data = await stewardOrderDetail(value.stewardId, position)
                  // set null 为了刷新 Label
                  setDetail(null)
                  setDetail(data)
                }
              }}
            >
              <div style={{position: 'relative'}}>
                <img src={require('@/assets/icons/sale.svg')} style={{width: iconSize, height: iconSize}} alt={''} />
                <Absolute>
                  <Bubble
                    style={{
                      borderRadius: 100,
                      minWidth: 80,
                      textAlign: 'center',
                      padding: '5px',
                      ...(active
                        ? {backgroundColor: '#00B7AE', color: 'white'}
                        : {backgroundColor: 'white', color: '#262626'}),
                    }}
                  >
                    <div>{value.stewardName}</div>
                  </Bubble>
                </Absolute>
              </div>
            </Marker>
          )
        })}
        {!!detail && (
          <Label
            point={detail.point}
            offset={{width: -iconSize / 2, height: -(125 - iconSize / 2)}}
            onClick={() => {
              const vo = detail.vo
              if (vo) {
                Modal.info({
                  icon: null,
                  content: (
                    <Space direction={'vertical'}>
                      <span>
                        拜访记录：{vo.name}-{vo.mobile}
                      </span>
                      <span>拜访位置：{vo.address}</span>
                      <span>拜访时间：{vo.visitTime}</span>
                    </Space>
                  ),
                  okText: '关闭',
                })
              }
            }}
          >
            <Bubble arrowPlace={iconSize / 2} style={{position: 'absolute', bottom: 0}}>
              {!!detail?.vo && (
                <Space style={{padding: '5px 8px'}}>
                  <div>
                    拜访记录：{detail.vo.name}-{detail.vo.mobile}
                  </div>
                  <a>详情</a>
                </Space>
              )}
              <div style={{padding: '5px 8px'}}>当前位置：{detail.address}</div>
              <div style={{padding: '5px 8px'}}>
                上报时间：{locations.find(value => value.stewardId === detail.stewardId)?.reportTime}
              </div>
            </Bubble>
          </Label>
        )}
      </Mapview>

      <div className={styles.search}>
        <AutoComplete
          dropdownMatchSelectWidth={252}
          className={styles.searchInput}
          options={searchLocations.map(value => ({label: value.stewardName, value: value.stewardName, data: value}))}
          onSearch={setSearch}
          onSelect={async (_, {data}) => {
            const detail = await stewardOrderDetail(data.stewardId, data)
            // set null 为了刷新 Label
            setDetail(null)
            setDetail(detail)
            mapRef.current?.panTo(data.lng, data.lat)
          }}
        >
          <Input.Search size='large' placeholder='请输入销售名称' enterButton bordered={false} />
        </AutoComplete>
      </div>
    </Page>
  )
}
