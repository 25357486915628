import React, {useEffect, useState} from 'react'
import {Button, Form, Input, Select, Transfer, TreeSelect} from 'antd'
import {sysRoleModel} from '@/store/models/SysRole'
import {sysUserModel} from '@/store/models/SysUser'
import UploadAvatar from '@/components/UploadAvatar'
import {Breadcrumb} from '@/components/Breadcrumb'
import commonStyles from '@/assets/styles/common.module.less'
import {sysModel} from '@/store/models/Sys'
import {userModel} from '@/store/models/User'
import {Page} from '@/components/Page'
import BackButton from '@/components/BackButton'
import {rules} from '@/utils/validate'
import {useAppHistory, useAppLocation} from '@/common/hooks/useRouter'
import {OrgRoleId} from '@/common/constants'

export default function SysUserAddPage() {
  const history = useAppHistory()
  const location = useAppLocation<'/sys-user/add'>()

  const userId = location.state?.userId
  const roleIdList = location.state?.roleIdList

  const orgId = userModel.useData(data => data.userInfo?.orgId)

  const [allRoleList, setAllRoleList] = useState<SysRoleVO[]>([])
  const [targetKeys, setTargetKeys] = useState(roleIdList ?? [])
  const [selectedKeys, setSelectedKeys] = useState([])
  const [defaultValue, setDefaultValue] = useState('')
  const [form] = Form.useForm()

  // 组织树
  const [treeList, setTreeList] = useState<SysOrgTreeNodeVO[]>([])

  useEffect(() => {
    sysModel.sysOrgTree({fetchUser: false, orgIdLike: orgId} as SysOrgTreeQueryDTO).then(({response: {data}}) => {
      setTreeList(data)
    })
  }, [orgId])

  // 组织树
  const [treeList2, setTreeList2] = useState<SysOrgV2VO[]>([])

  useEffect(() => {
    sysModel.sysOrgV2Tree({orgId: '', orgPid: '', orgName: '', allChildren: true}).then(({response: {data}}) => {
      setTreeList2(data)
    })
  }, [])

  useEffect(() => {
    Promise.all([
      sysRoleModel.sysRoleList({pageNum: 1, pageSize: 1, pageable: false} as SysListDTO),
      !!userId && sysUserModel.sysUserDetail({userId}),
    ]).then(([{response: roleList}, detail]) => {
      setAllRoleList(roleList.data)
      if (detail) {
        const data = detail.response.data
        setDefaultValue(data?.avatar)
        form.setFieldsValue({...data, orgIdV2List: data.orgV2IdList})
        if (data.roleIdList?.length) {
          setTargetKeys(data.roleIdList)
        }
      }
    })
  }, [form, userId])

  //新增或修改后台管理用户
  async function handleFormFinish() {
    const formValues = form.getFieldsValue(true)
    await sysUserModel.upsertSysUser(formValues)
    history.goBack()
  }

  const upsertLoading = sysUserModel.upsertSysUser.useLoading()

  return (
    <Page
      breadcrumb={
        <Breadcrumb
          path={
            roleIdList?.includes(OrgRoleId.steward)
              ? ['首页', '管家管理', '新增管家']
              : roleIdList?.includes(OrgRoleId.cus_manager)
              ? ['首页', '销售管理', '新增销售']
              : ['管理员管理', '系统用户', '新增系统用户']
          }
        />
      }
    >
      <BackButton />
      <div className={commonStyles.tableSection}>
        <Form
          layout={'horizontal'}
          labelCol={{span: 2}}
          wrapperCol={{span: 8}}
          onFinish={handleFormFinish}
          form={form}
          initialValues={{fullTime: 1, roleIdList, userId}}
        >
          <Form.Item label={'姓名'} name={'name'} rules={[{required: true}]}>
            <Input
              placeholder={'请输入姓名'}
              onBlur={({target: {value}}) => {
                if (value) {
                  sysUserModel.sysUserGetNamePinyin({name: value}).then(({response: {data}}) => {
                    form.setFieldsValue({userName: data})
                    form.validateFields(['userName'])
                  })
                }
              }}
              allowClear
            />
          </Form.Item>
          <Form.Item label={'登录账号'} name={'userName'}>
            <Input placeholder={'请输入登录账号'} disabled allowClear />
          </Form.Item>
          <Form.Item label={'手机号'} name={'mobile'} rules={rules.mobile}>
            <Input placeholder={'请输入手机号'} allowClear />
          </Form.Item>
          <Form.Item label={'身份证号码'} name={'certNumber'} rules={[{...rules.certNo[0], required: false}]}>
            <Input placeholder={'请输入身份证号码'} allowClear />
          </Form.Item>
          <Form.Item label={'工作性质'} name={'fullTime'} rules={[{required: true}]}>
            <Select allowClear>
              <Select.Option value={1}>全职</Select.Option>
              <Select.Option value={0}>兼职</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item label={'员工照'} name={'avatar'}>
            <UploadAvatar defaultValue={defaultValue} />
          </Form.Item>
          <Form.Item label={'用户组'} name='roleIdList' wrapperCol={{span: 24}}>
            <Transfer
              titles={['可分配用户组', '已分配用户组']}
              dataSource={allRoleList}
              targetKeys={targetKeys}
              selectedKeys={selectedKeys}
              onChange={(targetKeys, direction, moveKeys) => {
                setTargetKeys(targetKeys)
              }}
              disabled={!!roleIdList?.length}
              onSelectChange={(sourceSelectedKeys, targetSelectedKeys) => {
                setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys])
              }}
              render={item => item.roleName}
              rowKey={item => item.roleId}
            />
          </Form.Item>
          <Form.Item label={'组织选择'} name={'orgId'} rules={[{required: true, message: '请选择组织'}]}>
            <TreeSelect
              fieldNames={{label: 'label', value: 'key', children: 'children'}}
              allowClear
              treeDefaultExpandAll
              treeData={treeList}
              placeholder={'请选择'}
            />
          </Form.Item>
          <Form.Item label={'运营组织选择'} name={'orgIdV2List'} rules={[{required: true, message: '请选择运营组织'}]}>
            <TreeSelect
              multiple
              fieldNames={{label: 'orgName', value: 'orgId', children: 'children'}}
              allowClear
              treeDefaultExpandAll
              treeData={treeList2}
              placeholder={'请选择'}
              filterTreeNode={(inputValue: string, treeNode) => {
                if (!inputValue) return true
                return treeNode.orgName?.includes(inputValue)
              }}
              // onSelect={(id, info) => {
              //   form.setFieldsValue({orgIdV2List: info.orgPidList.concat(id)})
              // }}
            />
          </Form.Item>
          <Form.Item label={'备注信息'} name={'remark'}>
            <Input.TextArea rows={3} maxLength={200} showCount placeholder={'请输入'} allowClear />
          </Form.Item>
          <Form.Item wrapperCol={{offset: 4}}>
            <Button htmlType='submit' type='primary' loading={upsertLoading}>
              保存
            </Button>
            <Button style={{marginLeft: '30px'}} onClick={() => history.goBack()}>
              取消
            </Button>
          </Form.Item>
        </Form>
      </div>
    </Page>
  )
}
