import {dashboardUrl} from '@/common/config'
import {userModel} from '@/store/models/User'
import React, {useEffect} from 'react'

export default function DashboardPage() {
  const token = userModel.useData(data => data.token)

  useEffect(() => {
    window.open(`${dashboardUrl}?token=${token}#/`)
    // window.open(`http://localhost:5173?token=${token}#/`)
  }, [])

  return <></>
}
