import React, {useCallback, useEffect, useState} from 'react'
import {Button, Col, Form, message, Modal, Row, Select} from 'antd'
import {useHistory, useLocation} from 'react-router'
import {lesseeModel} from '@/store/models/Lessee'
import '../../property/index.less'
import {getImg} from '@/utils/util'
import {propertyModel} from '@/store/models/Property'
import commonStyles from '@/assets/styles/common.module.less'
import BackButton from '@/components/BackButton'
import {Page} from '@/components/Page'

const {Option} = Select

export default function TenantBindDetail() {
  const history = useHistory()
  const location = useLocation<any>()
  const userId = location.state?.userId

  const [detail, setDetail] = useState({} as LesseePropertyVO)
  // 选中的图片
  const [selectImg, setSelectImg] = useState<{imgUrl: string; visible: boolean}>({imgUrl: '', visible: false})

  const [form] = Form.useForm()
  const [proList, setProList] = useState([])

  const subLoading = lesseeModel.userLesseePropertyBind.useLoading()

  useEffect(
    function () {
      lesseeModel.userLesseePropertyDetail({userId}).then(({response: {data}}) => {
        // console.log('data =>', data)
        setDetail(data)
      })
    },
    [userId]
  )

  // 房源列表
  const queryPropertyList = useCallback(function (title: string) {
    // console.log('title', title)
    propertyModel.queryPropertyList({pageNum: 1, pageSize: 10, pageable: false, title}).then(({response: {data}}) => {
      // console.log('property list =>', data)
      setProList(data.map(item => ({value: item.title, propertyCode: item.propertyCode})))
    })
  }, [])

  return (
    <Page>
      <BackButton />
      <div className={`${commonStyles.tableSection} pageDetail`}>
        <div className='table'>
          {/** 基础信息 */}
          <Row className='table--rowHeader'>租客信息</Row>
          <Row className='table--rowNormal'>
            <Col span={8}>租客姓名：{detail.nickName}</Col>
            <div className='vertical' />
            <Col span={8}>联系方式：{detail.mobile}</Col>
            <div className='vertical' />
            <Col span={7}>
              服务项目：
              {[detail.categoryFirName, detail.categorySecName, detail.categoryThiName].filter(item => item).join('-')}
            </Col>
          </Row>
          <Row className='table--rowNormal'>房源地址：{detail.address}</Row>
          {/** 需求信息 */}
          <Row className='table--rowHeader'>需求信息</Row>
          <Row className='table--rowNormal'>需求描述：{detail.orderDesc}</Row>
          <Row className='table--rowImg'>
            房源照片：
            {detail.imgUrlList?.map((item, index) => {
              return (
                <div key={index} className='table--img'>
                  <img
                    src={getImg(item)}
                    width={88}
                    height={88}
                    onClick={() => setSelectImg({imgUrl: item, visible: true})}
                  />
                </div>
              )
            })}
          </Row>
        </div>
        <div style={{marginTop: '30px'}}>
          <Form
            form={form}
            onFinish={async () => {
              const propertyCode = form.getFieldValue('propertyCode')
              await lesseeModel.userLesseePropertyBind({userId, propertyCode})
              message.success('绑定成功！')
              history.goBack()
            }}
          >
            <Row gutter={20}>
              <Col span={8}>
                <Form.Item name={'propertyCode'} rules={[{required: true, message: '请选择房源'}]}>
                  <Select
                    style={{width: '100%'}}
                    placeholder={'请输入要搜索的房源'}
                    showSearch
                    filterOption={false}
                    onSearch={value => queryPropertyList(value)}
                    allowClear
                  >
                    {proList.map(item => (
                      <Option key={item.propertyCode} value={item.propertyCode}>
                        {item.value}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col>
                <Button type={'primary'} htmlType={'submit'} loading={subLoading}>
                  确认
                </Button>
              </Col>
              <Col>
                <Button type={'primary'} onClick={() => history.push('/steward_orderAdd', {userId})}>
                  创建服务单
                </Button>
              </Col>
            </Row>
          </Form>
        </div>
      </div>

      {/** 预览图片 */}
      <Modal
        visible={selectImg.visible}
        title={'图片预览'}
        footer={null}
        onCancel={() => setSelectImg(state => ({...state, visible: false}))}
      >
        <img src={getImg(selectImg.imgUrl)} width='100%' />
      </Modal>
    </Page>
  )
}
