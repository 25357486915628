import React, {useState, useEffect, useMemo} from 'react'
import {Form, Row, Col, Button, Space, Modal, Input, message} from 'antd'
import {ExclamationCircleFilled} from '@ant-design/icons'
import {commonModel} from '@/store/models/Common'
import {getImg} from '@/utils/util'

import './index.less'
import {usePermission} from '@/components/Permission'
import {teamModel} from '@/store/models/Team'
import {useAppHistory, useAppLocation} from '@/common/hooks/useRouter'
import {Page} from '@/components/Page'
import BackButton from '@/components/BackButton'

const {confirm} = Modal
const {TextArea} = Input

export default function AuthDetail() {
  const history = useAppHistory()
  const location = useAppLocation<'/auth/detail'>()

  const [auth] = usePermission()
  const recordId = location.state?.recordId

  const [form] = Form.useForm()
  const [detail, setDetail] = useState<TeamAuthRecordVO>()
  // 选中的图片
  const [selectImg, setSelectImg] = useState<{imgUrl: string; visible: boolean}>({imgUrl: '', visible: false})
  const teamAuthType = useMemo(() => commonModel.getCodeSync('team.auth.type'), [])
  const loading = teamModel.teamAuthRecordAudit.useLoading()

  useEffect(() => {
    if (recordId) {
      teamModel.teamAuthRecordDetail({recordId}).then(({response: {data}}) => {
        setDetail(data)
      })
    }
  }, [recordId])

  function passConfirm(pass: boolean) {
    confirm({
      centered: true,
      title: '企业认证审核',
      icon: <ExclamationCircleFilled />,
      content: !pass ? (
        <Form form={form}>
          <Form.Item label={'拒绝原因'} name={'remark'} rules={[{required: true, message: '拒绝原因不能为空'}]}>
            <TextArea rows={4} placeholder='请输入拒绝原因' allowClear />
          </Form.Item>
        </Form>
      ) : (
        <Form form={form}>
          <Form.Item label={'企业抬头'} name={'companyNameSign'}>
            <TextArea rows={4} placeholder='企业抬头' allowClear />
          </Form.Item>
        </Form>
      ),
      onOk() {
        const {companyNameSign, remark} = form.getFieldsValue(true)
        if (!pass && !remark) {
          message.warning('拒绝原因不能为空')
          return
        }

        teamModel.teamAuthRecordAudit(recordId, companyNameSign, remark, pass).then(() => {
          message.success('企业审核提交成功')
          history.goBack()
        })
      },
    })
  }

  return (
    <Page>
      <BackButton />
      <div className={'detail'}>
        <article className={'content'}>
          <header className={'header'}>主体信息</header>
          <Row className={'row border-top'}>
            <Col span={8} className={'col border-right'}>
              用户名称：{detail?.applicantName}
            </Col>
            <Col span={8} className={'col border-right'}>
              手机号：{detail?.applicantMobile}
            </Col>
            <Col span={8} className={'col'}>
              性别：{detail?.applicantSex}
            </Col>
          </Row>
          <Row className={'row border-top'}>
            <Col span={12} className={'col border-right'}>
              地址：{detail?.applicantAddr}
            </Col>
            <Col span={12} className={'col'}>
              身份证号：{detail?.applicantCert}
            </Col>
          </Row>
          <header className={'header'}>主体信息</header>
          <Row className={'row border-top'}>
            <Col span={8} className={'col border-right'}>
              商用类型：{teamAuthType[detail?.teamType]}
            </Col>
            <Col span={8} className={'col border-right'}>
              企业名称：{detail?.companyName}
            </Col>
            <Col span={8} className={'col'}>
              法人姓名：{detail?.legalPerson}
            </Col>
          </Row>
          <Row className={'row border-top'}>
            <Col className={'col'}>统一社会信用代码：{detail?.socialCreditCode}</Col>
          </Row>
          <Row className={'row border-top image'}>
            <Col span={3} className={'col'}>
              营业执照照片：
            </Col>
            <Col className={'col'}>
              <img
                src={getImg(detail?.bizLicense)}
                width={300}
                height={300}
                onClick={() => setSelectImg({imgUrl: detail?.bizLicense, visible: true})}
              />
            </Col>
          </Row>
          <Row className={'row border-top remark'}>
            <Col className={'col'}>拒绝原因：{detail?.operatorRemark}</Col>
          </Row>
        </article>

        <footer className={'footer'}>
          {detail?.authStatus === '2' ? (
            <Space>
              {auth('019007002') && (
                <Button type={'primary'} onClick={() => passConfirm(true)} loading={loading}>
                  通过
                </Button>
              )}
              {auth('020007002') && (
                <Button onClick={() => passConfirm(false)} loading={loading}>
                  拒绝
                </Button>
              )}
            </Space>
          ) : (
            <Button type={'primary'} onClick={() => history.goBack()}>
              返回
            </Button>
          )}
        </footer>
      </div>

      {/** 预览图片 */}
      <Modal
        visible={selectImg.visible}
        centered
        title={'图片预览'}
        footer={null}
        bodyStyle={{width: '100%', height: '100%'}}
        onCancel={() => setSelectImg(state => ({...state, visible: false}))}
      >
        <img src={getImg(selectImg.imgUrl)} width='100%' />
      </Modal>
    </Page>
  )
}
