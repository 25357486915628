/**
 * 由所选的节点的值，转换为所选的叶子节点的值
 */
export function getCascaderLeafValues(tree: RecursionOption[], selectsArray: string[][]) {
  function getLastNode(tree: RecursionOption[], selects: string[]) {
    let currentNode = null
    let currentTree = tree
    selects.forEach(select => {
      currentNode = currentTree?.find(item => item.value === select)
      if (currentNode?.children) {
        currentTree = currentNode.children
      }
    })
    return currentNode
  }

  function getLastNodeValues(node: RecursionOption) {
    let values = []
    if (node) {
      if (node?.children) {
        node.children.forEach(child => {
          values = values.concat(getLastNodeValues(child))
        })
      } else {
        values.push(node.value)
      }
    }

    return values
  }

  return selectsArray.map(selects => getLastNodeValues(getLastNode(tree, selects))).flat()
}

/**
 * 给一个完整的树，通过叶子节点的值，还原 Cascader 组件的值的结构
 */
export function getCascaderValueByLeafValues(tree: RecursionOption[], leafValues: string[] = []) {
  function leafValuesToTree(trees: RecursionOption[], values: string[]) {
    trees = trees
      .map(node => {
        const tempNode = {...node}

        if (node.children?.length) {
          tempNode.children = leafValuesToTree(node.children, values)

          if (!tempNode.children.length) {
            return null
          }

          // 除了最后一层，如果所有的 children 都相同，那么就把 children 置空
          if (
            node.children.every((value, index) => {
              const one = value
              const two = tempNode.children[index]
              return one.value === two?.value && one.children?.length === two?.children?.length
            })
          ) {
            tempNode.children = []
          }
          return tempNode
        } else {
          if (values.includes(tempNode.value)) {
            return tempNode
          } else {
            return null
          }
        }
      })
      .filter(Boolean)

    return trees
  }

  const cascaderTree = leafValuesToTree(tree, leafValues)

  return treeToCascaderValue(cascaderTree)
}

/**
 * 树形结构变成 Cascader 的值
 */
function treeToCascaderValue(tree: RecursionOption[]) {
  const paths = []
  function mapTree(tree, path = []) {
    tree.forEach(node => {
      const current = path.concat(node.value)
      if (node.children?.length) {
        mapTree(node.children, current)
      } else {
        paths.push(current)
      }
    })
  }

  mapTree(tree)

  return paths
}
