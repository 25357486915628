import React, {useCallback, useEffect, useRef, useState} from 'react'
import {
  Col,
  Modal,
  Row,
  Typography,
  Divider,
  Popover,
  Image,
  Button,
  Form,
  Input,
  Select,
  Space,
  Radio,
  message,
  Descriptions,
  PageHeader,
  Upload,
  Tabs,
  Drawer,
  InputNumber,
  Tag,
  Tooltip,
  Rate,
  Checkbox,
  Table,
} from 'antd'
import {getImg} from '@/utils/util'
import {stewardModel} from '@/store/models/Steward'
import {Marker} from '@/components/Map/Marker'
import {Mapview} from '@/components/Map'
import {
  EnvironmentFilled,
  ExclamationCircleFilled,
  PlayCircleFilled,
  PlusCircleTwoTone,
  CloseCircleTwoTone,
  RightOutlined,
} from '@ant-design/icons'
import {useCodeArraySync, useCodeSync} from '@/store/models/Common'
import {userModel} from '@/store/models/User'
import {baseUrl, inspectUrl, serverUrl} from '@/common/config'
import styles from './index.module.less'
import {Album, UploadButton} from '@/components/Album'
import {sysUserModel} from '@/store/models/SysUser'
import {usePermission} from '@/components/Permission'
import {useQuery} from '@/common/hooks/useQuery'
import {useAppHistory, useAppLocation} from '@/common/hooks/useRouter'
import {Page} from '@/components/Page'
import {Model} from '@redux-model/web'
import GuanYuBindMobile from '@/pages/steward/components/GuanYuBindMobile'
import {purchaseModel} from '@/store/models/Purchase'
// import DividerLine from '@/pages/property/component/DividerLine'

const {Option} = Select

const approvalStatusOptions = {
  1: '待审批',
  2: '审批中',
  3: '通过',
  4: '驳回',
  5: '已撤销',
}

const DetailSectionTitle = React.memo(function (props: {title: string; top?: number; ext?: React.ReactElement}) {
  const {title, top, ext} = props
  return (
    <div style={{marginTop: top}}>
      <Divider type={'vertical'} style={{borderColor: '#00B7AE', borderWidth: '6px', marginLeft: 0}} />
      <Typography.Text style={{fontWeight: 'bold', fontSize: '14px', color: '#000'}}>{title}</Typography.Text>
      {React.isValidElement(ext) && ext}
    </div>
  )
})

export const isImageUrl = (url: string) => [/.png$/i, /.jpe?g$/i].some(item => item.test(url))

export const isVideoUrl = (url: string) =>
  [
    /\.mpe?4$/i,
    /\.flv$/i,
    /\.wmv$/i,
    /\.avi$/i,
    /\.mov$/i,
    /\.mkv$/i,
    /\.m4v$/i,
    /\.vob$/i,
    /\.qsv$/i,
    /\.rmvb$/i,
    /\.rm$/i,
    /\.f4v$/i,
    /\.mv$/i,
  ].some(item => item.test(url))

// 特定的保洁
export function checkThiClean(value: string) {
  return [
    '80fc193dc36f40049e969238f04d4142',
    '6c1d632ef0d145489754fcd476b800b7',
    'dccb8ec3e9ce4b6d8c1b93eaf684b60a',
    'd9b33bc9c28f455495a3b0a85a8b2bb9',
    'ea48d913e54e465692e524be20b49a05',
    '892e01f3ff914d718e1f21a0ff246fc3',
  ].includes(value)
}

export const DetailSectionImage = React.memo(function (props: {
  title?: string
  imgList: StewardOrderImgVO[]
  top?: number
  onClickVideo?: (img: StewardOrderImgVO) => void
}) {
  const {title, imgList, top, onClickVideo} = props
  return (
    <div style={{marginTop: top}}>
      <Row>
        {!!title && (
          <Typography.Text style={{color: 'rgba(0, 0, 0, 0.65)', fontSize: '14px'}}>{title}：</Typography.Text>
        )}
        <Image.PreviewGroup>
          {imgList?.map(item =>
            isImageUrl(item.imgUrl) ? (
              <div key={item.imgId} style={{margin: '0 10px 10px 0'}}>
                <Image
                  width={88}
                  height={88}
                  alt={'图片'}
                  src={getImg(item.imgUrl, {w: 88 * 2})}
                  preview={{
                    src: getImg(item.imgUrl, {q: 75}),
                  }}
                />
              </div>
            ) : isVideoUrl(item.imgUrl) ? (
              <div key={item.imgId} className={styles.video} onClick={() => onClickVideo(item)}>
                <video controls={false} src={getImg(item.imgUrl)} width={'100%'} height={'100%'} />
                <PlayCircleFilled
                  style={{position: 'absolute', left: '30px', top: '30px', fontSize: '28px', color: 'white'}}
                />
              </div>
            ) : (
              <div>无</div>
            )
          )}
        </Image.PreviewGroup>
      </Row>
    </div>
  )
})

const DetailSectionStep = React.memo(function (props: {
  item: StewardOrderProgressVO
  isTop?: boolean
  isBottom?: boolean
  onClick?: (value: StewardOrderProgressVO) => void
}) {
  const {item, isTop, isBottom, onClick} = props
  return (
    <Row>
      <Col span={2}>
        <div
          style={{
            width: '16px',
            height: '16px',
            margin: `${isTop ? '28px' : '44px'} auto 0`,
            borderRadius: '8px',
            backgroundColor: isTop ? '#10BBB3' : '#D8D8D8',
          }}
        />
        {!isBottom && <div style={{width: '1px', height: '100%', margin: '0 auto', backgroundColor: '#DCDFE6'}} />}
      </Col>
      <Col span={21} offset={1} style={{paddingTop: isTop ? '24px' : '40px'}}>
        <div>
          <Typography.Text style={{color: isTop ? '#222832' : '#606266', fontWeight: 'bold'}}>
            {item.progressTitle}
          </Typography.Text>
          {item.lat && item.lng && (
            <EnvironmentFilled
              style={{color: isTop ? '#10BBB3' : '#B1B5BD', marginLeft: '10px'}}
              onClick={() => onClick(item)}
            />
          )}
          {['重新沟通', '重新分配', '已沟通', '二次上门'].includes(item.progressTitle) && (
            <Space>
              <Popover
                placement={'top'}
                trigger={'click'}
                content={
                  <>
                    {['已沟通', '重新沟通'].includes(item.progressTitle) && (
                      <>
                        {/*<Typography.Paragraph>*/}
                        {/*  预约类型：*/}
                        {/*  <Typography.Text style={{color: '#000'}}>*/}
                        {/*    {item.appointType === '1' ? '上门' : '联系'}*/}
                        {/*  </Typography.Text>*/}
                        {/*</Typography.Paragraph>*/}
                        <Typography.Paragraph>
                          约定上门时间：
                          <Typography.Text style={{color: '#000'}}>{item.timeAppoint}</Typography.Text>
                        </Typography.Paragraph>
                      </>
                    )}
                    {item.progressTitle !== '已沟通' && (
                      <Typography.Paragraph style={{maxWidth: '250px'}}>
                        {item.progressTitle === '二次上门' ? '二次上门原因：' : '分配原因：'}
                        <Typography.Text style={{color: '#000', wordWrap: 'break-word', wordBreak: 'break-all'}}>
                          {item.operatorReason}
                        </Typography.Text>
                      </Typography.Paragraph>
                    )}
                  </>
                }
              >
                <ExclamationCircleFilled style={{color: '#FFA636', marginLeft: '10px'}} />
              </Popover>
            </Space>
          )}
        </div>
        <div style={{marginTop: '5px'}}>
          <Typography.Text style={{color: '#909399'}}>{item?.progressDesc}</Typography.Text>
        </div>
        <div style={{marginTop: '5px'}}>
          <Typography.Text style={{color: '#909399'}}>操作人：{`${item?.operatorName}`}</Typography.Text>
        </div>
        <div style={{marginTop: '5px'}}>
          <Typography.Text style={{color: '#909399'}}>{item?.updateTime}</Typography.Text>
        </div>
      </Col>
    </Row>
  )
})

const orderMakerType = {lessee: '租客', lessor: '房东'}
const handleResultTypeCode = {1: '已完成服务', 2: '无需服务', 3: '无法完成服务'}
export default function StewardDetail() {
  const history = useAppHistory()
  const location = useAppLocation()
  const [auth] = usePermission()
  const query = useQuery()
  const orderId = location.state?.orderId || query.get('orderId')
  const userId = userModel.data.userInfo?.userId

  const purchaseStatus = useCodeSync('purchase.status')
  const orderStatusType = useCodeSync('steward.orderStatus')
  const materialSourceCode = useCodeSync('material.source')
  const handleResultTypeArr = useCodeArraySync('steward.handleResultType')
  const cancelTypeArr = useCodeArraySync('steward.cancelType2')
  const cancelTypeCode = useCodeSync('steward.cancelType2')
  const stewardFeeType = useCodeSync('steward.fee.type')
  const saleContractPayWay = useCodeSync('sale.contract.pay.way')
  const orderRemarkTagArr = useCodeArraySync('steward.orderRemarkTag')

  const submitLoading = stewardModel.stewardOrderHandleResult.useLoading()

  // 选中的图片
  const [selectImg, setSelectImg] = useState<{imgUrl: string; visible: boolean}>({imgUrl: '', visible: false})
  const [detail, setDetail] = useState({} as StewardOrderQueryVO)

  // 定位
  const mapRef = useRef(null)
  const [mapInfo, setMapInfo] = useState({show: false, lng: undefined, lat: undefined})

  const [form] = Form.useForm()
  const [isHandleInput, setIsHandleInput] = useState(false)

  //订单跟踪列表
  const [progressList, setProgressList] = useState<StewardOrderProgressVO[]>([])
  const [warrantyVisible, setWarrantyVisible] = useState(false)

  // 取消服务
  const [cancelVisible, setCancelVisible] = useState(false)
  const [cancelForm] = Form.useForm()

  // 管家详情
  const queryStewardOrderDetail = useCallback(
    function () {
      stewardModel.stewardOrderDetail({orderId}).then(({response: {data}}) => {
        setDetail(data)
        setProgressList(data?.progressList.reverse())
      })
    },
    [orderId]
  )
  useEffect(() => {
    queryStewardOrderDetail()
  }, [queryStewardOrderDetail])

  const [purchaseList, setPurchaseList] = useState<PurchaseVO[]>([])
  useEffect(() => {
    if (orderId) {
      purchaseModel.stewardAppPurchaseList({stewardOrderId: orderId, pageable: false}).then(({response: {data}}) => {
        setPurchaseList(data)
      })
    }
  }, [orderId])

  // 保洁人员列表
  const [cForm] = Form.useForm()
  const [isRework, setIsRework] = useState(false)
  const [cleanerList, setCleanerList] = useState<SysUserSimpleVO[]>([])

  useEffect(() => {
    sysUserModel.sysUserQueryCleaner().then(({response: {data}}) => setCleanerList(data))
  }, [])

  function mapPanToLoc(lng: number, lat: number) {
    setTimeout(function () {
      setTimeout(() => {
        mapRef.current?.panTo(lng, lat)
      }, 500)
      mapRef.current?.setZoom(18)
    }, 500)
  }

  const [isCleanCheck, setIsCleanCheck] = useState(false)

  useEffect(() => {
    if (detail.lessorMobile === '13818678534') {
      stewardModel.stewardCountCleanCheckByOrderId({orderId}).then(({response: {data}}) => setIsCleanCheck(data !== 0))
    }
  }, [detail.lessorMobile, orderId])

  function handleResult() {
    const params = new URLSearchParams()
    params.append('token', userModel.data.token)
    params.append('propertyCode', detail.propertyCode)
    params.append('env', 'admin')
    params.append('platform', 'steward')
    let inspectUri
    if (detail.templateType === 'DEFAULT') {
      inspectUri = `${inspectUrl}/inspect/report-result/${detail.orderId}?${params.toString()}`
    } else {
      inspectUri = `${inspectUrl}/inspect/detail/${detail.orderId}?${params.toString()}`
    }

    return detail.categoryThi === '92d9de26c34d4f7f91f61cfa52e6ded8' && detail.orderStatus === '5' ? (
      <Space>
        <a href={inspectUri} target={'_blank'} rel={'noopener noreferrer'} style={{textDecoration: 'underline'}}>
          查看验房报告
        </a>
        <Button
          type={'link'}
          size={'small'}
          style={{padding: 0}}
          onClick={async () => {
            const {
              response: {data},
            } = await stewardModel.stewardAdminShortLinkInspect({orderId: detail.orderId})
            await navigator.clipboard.writeText(data)
            message.success('已复制到粘贴板！')
          }}
        >
          分享验房报告
        </Button>
        {isCleanCheck && (
          <>
            <a
              href={`${serverUrl}/web/steward/check.html?orderId=${orderId}`}
              target={'_blank'}
              rel='noopener noreferrer'
              style={{textDecoration: 'underline'}}
            >
              查看保洁验收
            </a>
            <Button
              type={'link'}
              size={'small'}
              style={{padding: 0}}
              onClick={async () => {
                await navigator.clipboard.writeText(
                  `${serverUrl}/web/steward/check.html?orderId=${orderId}，${detail.propertyAddr} 的保洁验收已完成，点此链接查看验收报告`
                )
                message.success('已复制到粘贴板！')
              }}
            >
              分享保洁验收
            </Button>
          </>
        )}
      </Space>
    ) : (
      handleResultTypeCode[detail.handleResultType]
    )
  }

  const updateLocationLoading = stewardModel.stewardAdminOrderUpdateLocation.useLoading()
  const cancelLoading = stewardModel.stewardAdminOrderCancel.useLoading()

  const logisticsLoading = Model.isLoading(
    stewardModel.stewardAdminOrderLogisticsUpdate.useLoading(),
    stewardModel.stewardAdminOrderLogisticsInsert.useLoading()
  )
  const [logisticsVisible, setLogisticsVisible] = useState(false)
  const [logisticsForm] = Form.useForm<{recordId: string; logisticsId: string}>()

  const [logisticsList, setLogisticsList] = useState<PairModel[]>([])
  const queryLogisticsList = useCallback(() => {
    stewardModel.stewardAdminOrderLogisticsList({orderId}).then(({response: {data}}) => {
      setLogisticsList(data)
    })
  }, [orderId])

  useEffect(() => {
    queryLogisticsList()
  }, [queryLogisticsList])

  const repairTag = detail.repairTag
  const detailOrderId = detail.orderId
  const [warrantyDetail, setWarrantyDetail] = useState<StewardOrderWarrantyVO>(null)
  useEffect(() => {
    if (repairTag === 0 && detailOrderId) {
      stewardModel.stewardAppOrderWarrantyDetail({orderId: detailOrderId}).then(({response: {data}}) => {
        setWarrantyDetail(data)
      })
    }
  }, [detailOrderId, repairTag])

  // 维修单填写处理情况
  const [repairResultVisible, setRepairResultVisible] = useState(false)
  const [repairResultForm] = Form.useForm()
  const [stewardList, setStewardList] = useState<SysUserVO[]>([])
  const [materialList, setMaterialList] = useState<StewardMaterialListVO[]>([])
  const handleResultLoading = stewardModel.stewardAdminOrderHandleResult.useLoading()

  useEffect(() => {
    sysUserModel.querySysUserList({pageable: false, roleId: 'steward'}).then(({response: {data}}) => {
      setStewardList(data)
    })
  }, [])

  const [rForm] = Form.useForm()

  const [mobileVisible, setMobileVisible] = useState(false)

  const [recordOpen, setRecordOpen] = useState(false)
  const [recordDetailOpen, setRecordDetailOpen] = useState(false)

  const [approvalOrderQuotationList, setApprovalOrderQuotationList] = useState<ApprovalOrderQuotationRecordVO[]>()
  const [approvalOrderDetail, setApprovalOrderDetail] = useState<ApprovalOrderDetailVO>()

  const {applyUserName, feeExplain, pics, itemList, applyUserPhone} =
    approvalOrderDetail?.approvalStewardOrderQuotationDTO || {}

  const {
    beikeOrderId,
    repairAddress,
    repairScheme,
    goodsAge,
    monthFee,
    lessorFee,
    feeTotal,
    payTypeList,
    assetManagerName,
    pics: pics2,
    remark,
  } = approvalOrderDetail?.approvalStewardOrderQuotationBeikeDTO || {}

  return (
    <Page
      header={
        <PageHeader
          title={'服务单信息'}
          ghost={false}
          onBack={() => history.goBack()}
          extra={
            <Space>
              {(detail.appName === '龙湖冠寓' || detail.appName === '贝壳') && auth('001192001') && (
                <Button
                  onClick={async () => {
                    const res = await stewardModel.approvalOrderQuotationList({
                      approvalConfigId: detail.appName === '龙湖冠寓' ? '1000' : '1001',
                      stewardOrderId: detailOrderId,
                    })
                    setApprovalOrderQuotationList(res.response.data)
                    setRecordOpen(true)
                  }}
                >
                  渠道报价记录
                </Button>
              )}
              {detail.appId === 'GUANYU' && detail.publicTag === 0 && (
                <Button
                  onClick={() => {
                    setMobileVisible(true)
                  }}
                >
                  绑定号码
                </Button>
              )}
              {/*{detail.cleanTag === 0 && ['1', '2', '3', '4'].includes(detail.orderStatus) && auth('001145002') && (*/}
              {/*  <Button onClick={() => setRepairResultVisible(true)}>填写处理结果</Button>*/}
              {/*)}*/}
              {['1', '2', '3', '4'].includes(detail.orderStatus) && auth('003130002') && (
                <Button
                  onClick={() => {
                    // 编辑状态带上 recordId
                    setLogisticsVisible(true)
                  }}
                >
                  添加物流单号
                </Button>
              )}
              {/*1、是保洁单 2、服务中 3、有权限*/}
              {detail.categoryFir === 'a4cb004ece0e4d0bba8ff2f76f1352a2' &&
                detail.orderStatus === '4' &&
                auth('003089002') && (
                  <Button type={'primary'} onClick={() => setIsHandleInput(true)}>
                    填写处理情况
                  </Button>
                )}
              {/*0.有权限 1.保洁单才有返工 2.已完成的保洁单 3.tag为0*/}
              {auth('002041002') &&
                detail.categoryFir === 'a4cb004ece0e4d0bba8ff2f76f1352a2' &&
                detail.orderStatus === '5' &&
                detail.reworkTag === 0 && (
                  <Button type={'primary'} onClick={() => setIsRework(true)}>
                    返工
                  </Button>
                )}
              {/* 待沟通、已沟通、上门中、服务中 */}
              {['1', '2', '3', '4'].includes(detail.orderStatus) && (
                <>
                  {auth('004041002') && (
                    <Button
                      onClick={() => {
                        cancelForm.setFieldsValue({orderId: detail.orderId})
                        setCancelVisible(true)
                      }}
                    >
                      取消服务
                    </Button>
                  )}
                  <Button
                    type={'primary'}
                    onClick={async () => {
                      await stewardModel.stewardAdminOrderUpdateLocation({orderId})
                      message.success('服务单信息已更新')
                      queryStewardOrderDetail()
                    }}
                    loading={updateLocationLoading}
                  >
                    信息更新
                  </Button>
                </>
              )}
              {auth('005041002') && detail.orderStatus === '5' && detail.remarkTag === '0' && (
                <Button
                  onClick={() => {
                    Modal.confirm({
                      title: '评价',
                      icon: null,
                      centered: true,
                      closable: true,
                      okText: '提交',
                      onOk: async () => {
                        await rForm.validateFields()
                        const formValues = rForm.getFieldsValue(true)
                        await stewardModel.stewardAdminOrderRemakeInsert({orderId, ...formValues})
                        message.success('评价成功！')
                        queryStewardOrderDetail()
                      },
                      afterClose: () => rForm.resetFields(),
                      content: (
                        <Form form={rForm} labelAlign={'right'} labelCol={{span: 6}}>
                          <Form.Item
                            label={'服务评价'}
                            name={'remarkScore'}
                            rules={[{required: true, type: 'number', message: '请选择服务评价'}]}
                          >
                            <Rate />
                          </Form.Item>
                          <Form.Item label={' '} colon={false} name={'tagIdList'}>
                            <Checkbox.Group>
                              {orderRemarkTagArr.map(item => (
                                <Checkbox key={item.id} value={item.id}>
                                  {item.label}
                                </Checkbox>
                              ))}
                            </Checkbox.Group>
                          </Form.Item>
                          <Form.Item label={'具体描述'} name={'remarkResult'}>
                            <Input.TextArea placeholder={'请输入'} maxLength={200} showCount />
                          </Form.Item>
                        </Form>
                      ),
                    })
                  }}
                >
                  评价
                </Button>
              )}
              <Button
                onClick={async () => {
                  const {
                    response: {data},
                  } = await stewardModel.guanyuListUrgeByAppOrderId({appOrderId: detail.appOrderId})
                  if (data.length === 0) return message.warning('暂无数据')
                  Modal.info({
                    title: '催单记录',
                    icon: null,
                    centered: true,
                    width: 500,
                    content: (
                      <Table
                        rowKey={'recordId'}
                        pagination={false}
                        columns={[{title: '催单时间', dataIndex: 'urgeAtDate'}]}
                        dataSource={data}
                      />
                    ),
                    okText: '关闭',
                  })
                }}
              >
                催单记录
              </Button>
            </Space>
          }
        />
      }
    >
      <Row gutter={20}>
        <Col span={17} style={{minHeight: '100%'}}>
          <div style={{backgroundColor: '#fff', padding: '30px 25px', minHeight: '100%'}}>
            {/*服务单信息*/}
            <Descriptions
              title={
                <DetailSectionTitle
                  title={'服务单信息'}
                  ext={
                    // detail.repairTag === 0 && (
                    !!warrantyDetail && (
                      <Button
                        type={'link'}
                        onClick={async () => {
                          setWarrantyVisible(true)
                        }}
                      >
                        <span style={{textDecoration: 'underline', textDecorationStyle: 'solid'}}>保修信息</span>
                      </Button>
                    )
                  }
                />
              }
              column={2}
            >
              <Descriptions.Item label={'订单编号'}>
                <Space>
                  <Typography.Text>{detail.orderId}</Typography.Text>
                  {/*{detail.customerSource === '1' && <Tag color={'blue'}>贝壳</Tag>}*/}

                  {detail.cleanTag === 0 && (!!detail.exOrderList?.length || detail.originOrderId) && (
                    <Tooltip
                      color={'#fff'}
                      title={
                        <Space direction={'vertical'}>
                          {detail.originOrderId
                            ? [{key: detail.originOrderId, value: '验房服务'}].map(item => (
                                <Typography.Text
                                  onClick={() =>
                                    history.push('/steward/detail', {
                                      orderId: item.key,
                                    })
                                  }
                                  style={{color: '#10BBB3', cursor: 'pointer'}}
                                  key={item.key}
                                  underline
                                >{`${item.key}(${item.value})`}</Typography.Text>
                              ))
                            : detail.exOrderList.map(item => (
                                <Typography.Text
                                  onClick={() =>
                                    history.push('/steward/detail', {
                                      orderId: item.key,
                                    })
                                  }
                                  style={{color: '#10BBB3', cursor: 'pointer'}}
                                  key={item.key}
                                  underline
                                >{`${item.key}(${item.value})`}</Typography.Text>
                              ))}
                        </Space>
                      }
                    >
                      <Space size={5}>
                        <Typography.Text style={{color: '#10BBB3'}}>
                          {detail.originOrderId ? '原单' : '子单'}
                        </Typography.Text>
                        <RightOutlined style={{color: '#00B7AE', fontSize: 12}} />
                      </Space>
                    </Tooltip>
                  )}
                </Space>
              </Descriptions.Item>
              <Descriptions.Item label={'下单时间'}>{detail.timeMake}</Descriptions.Item>
              <Descriptions.Item label={'下单人'}>{`${detail.orderMakerName ?? ''}(${
                orderMakerType[detail.orderMakerType]
              })`}</Descriptions.Item>
              <Descriptions.Item label={'订单状态'}>{orderStatusType[detail.orderStatus]}</Descriptions.Item>
              {detail.orderStatus === '6' ? (
                <>
                  <Descriptions.Item label={'取消时间'}>{detail.cancelTime}</Descriptions.Item>
                  <Descriptions.Item label={'取消原因'}>{detail.cancelType}</Descriptions.Item>
                  <Descriptions.Item label={'取消描述'}>{detail.cancelReason}</Descriptions.Item>
                  <Descriptions.Item label={'取消人'}>{detail.cancelOperatorName}</Descriptions.Item>
                </>
              ) : (
                <>
                  <Descriptions.Item label={'完成时间'}>{detail.timeFinished}</Descriptions.Item>
                  <Descriptions.Item label={'评价星级'}>{`${detail.remarkScore ?? ''}`}</Descriptions.Item>
                  <Descriptions.Item label={'评价描述'}>{detail.remarkResult}</Descriptions.Item>
                  {detail.reworkTag === 1 && (
                    <Descriptions.Item label={'是否需要返工'}>
                      <span>
                        是（返工单编号：
                        <a
                          style={{color: '#00B7AE'}}
                          onClick={() =>
                            history.replace({
                              pathname: '/steward/detail',
                              state: {orderId: detail.reworkOrderId},
                            })
                          }
                        >
                          {detail.reworkOrderId}
                        </a>
                        ）
                      </span>
                    </Descriptions.Item>
                  )}
                  {detail.repairTag === 1 && (
                    <Descriptions.Item label={'返修单'}>
                      <span>
                        是（原服务单号：
                        <a
                          style={{color: '#00B7AE'}}
                          onClick={() =>
                            history.replace({
                              pathname: '/steward/detail',
                              state: {orderId: detail.originOrderId},
                            })
                          }
                        >
                          {detail.originOrderId}
                        </a>
                        ）
                      </span>
                    </Descriptions.Item>
                  )}
                </>
              )}
              <Descriptions.Item label={'服务联系手机号'}>{detail.contactMobile}</Descriptions.Item>
              {/*<Descriptions.Item label={'返修单'}>*/}
              {/*  <span>*/}
              {/*    是（原服务单号：*/}
              {/*    <a*/}
              {/*      style={{color: '#00B7AE'}}*/}
              {/*      onClick={() =>*/}
              {/*        history.replace({*/}
              {/*          pathname: '/steward/detail',*/}
              {/*          state: {orderId: detail.reworkOrderId},*/}
              {/*        })*/}
              {/*      }*/}
              {/*    >*/}
              {/*      {detail.reworkOrderId}*/}
              {/*    </a>*/}
              {/*    ）*/}
              {/*  </span>*/}
              {/*</Descriptions.Item>*/}

              {!!logisticsList.length && (
                <Descriptions.Item label={'相关物流单号'}>
                  <Space direction={'vertical'}>
                    {logisticsList.map(value => (
                      <Space key={value.key}>
                        <a href={`https://www.baidu.com/s?wd=${value.value}`} rel={'noreferrer'} target={'_blank'}>
                          {value.value}
                        </a>
                        {auth('002130002') && (
                          <Button
                            disabled={['5', '6'].includes(detail.orderStatus)}
                            type={'link'}
                            style={{padding: 0, height: 'auto'}}
                            onClick={() => {
                              logisticsForm.setFieldsValue({recordId: value.key, logisticsId: value.value})
                              setLogisticsVisible(true)
                            }}
                          >
                            编辑
                          </Button>
                        )}
                        {auth('004130002') && (
                          <Button
                            disabled={['5', '6'].includes(detail.orderStatus)}
                            type={'link'}
                            style={{padding: 0, height: 'auto'}}
                            onClick={() => {
                              Modal.confirm({
                                content: '是否确认删除',
                                onOk: async () => {
                                  await stewardModel.stewardAdminOrderLogisticsDelete({recordId: value.key})
                                  queryLogisticsList()
                                },
                              })
                            }}
                          >
                            删除
                          </Button>
                        )}
                      </Space>
                    ))}
                  </Space>
                </Descriptions.Item>
              )}
            </Descriptions>
            {/*订单详情*/}
            <Descriptions title={<DetailSectionTitle title={'订单详情'} />} column={2}>
              <Descriptions.Item label={'房源地址'}>{detail.propertyAddr}</Descriptions.Item>
              <Descriptions.Item label={'小区code/公寓ID'}>{detail.communityCode}</Descriptions.Item>
              <Descriptions.Item
                label={
                  ['80fc193dc36f40049e969238f04d4142', 'ea48d913e54e465692e524be20b49a05'].includes(detail.categoryThi)
                    ? '物品清理'
                    : '故障现象'
                }
              >
                {detail.tags}
              </Descriptions.Item>
              <Descriptions.Item label={'详细地址'}>{detail.communityAddr}</Descriptions.Item>
              <Descriptions.Item label={'需求描述'}>{detail.orderDesc}</Descriptions.Item>
              <Descriptions.Item label={'服务项目'}>
                <Space>
                  <span>
                    {[detail.categoryFirName, detail.categorySecName, detail.categoryThiName].filter(Boolean).join('-')}
                  </span>
                  {detail.stewardIdFirst === 'CUSTOMER_CENTER' && <Tag color={'blue'}>客服</Tag>}
                </Space>
              </Descriptions.Item>
              <Descriptions.Item label={'所属团队'}>{detail.teamName}</Descriptions.Item>
              <Descriptions.Item label={'房东'}>{detail.lessorName}</Descriptions.Item>
              {(detail.contractClean === '1' || detail.contractSteward === '1') && (
                <>
                  <Descriptions.Item label={'合同类型'}>
                    {[detail.contractSteward === '1' && '管家', detail.contractClean === '1' && '保洁']
                      .filter(item => item)
                      .join('、')}
                  </Descriptions.Item>
                  {detail.contractSteward === '1' && (
                    <Descriptions.Item label={'管家收费类型'}>
                      {stewardFeeType[detail.stewardFeeType]}
                    </Descriptions.Item>
                  )}
                  {detail.contractClean === '1' && (
                    <Descriptions.Item label={'保洁收费类型'}>
                      {saleContractPayWay[detail.cleanFeeType]}
                    </Descriptions.Item>
                  )}
                </>
              )}

              <Descriptions.Item label={'照片'}>
                <DetailSectionImage
                  title={''}
                  imgList={detail.imgListOfDetail}
                  onClickVideo={img => setSelectImg({imgUrl: img.imgUrl, visible: true})}
                />
              </Descriptions.Item>
              <Descriptions.Item label={'渠道来源'}>{detail.appName}</Descriptions.Item>
              <Descriptions.Item label={'服务核验码'}>{detail.verifyCode}</Descriptions.Item>
            </Descriptions>
            {/*管家处理*/}
            <Descriptions title={<DetailSectionTitle title={'管家处理'} />} column={2}>
              <Descriptions.Item label={'第一接单人'}>{detail.stewardNameFirst}</Descriptions.Item>
              <Descriptions.Item label={'当前服务人员'}>{detail.currentServer}</Descriptions.Item>
              <Descriptions.Item label={'管家姓名'}>{detail.stewardName}</Descriptions.Item>
              <Descriptions.Item label={'管家手机号'}>{detail.stewardMobile}</Descriptions.Item>
              <Descriptions.Item label={'出发时间'}>{detail.timeSetOff}</Descriptions.Item>
              <Descriptions.Item label={'到达时间'}>{detail.timeArrived}</Descriptions.Item>
              <Descriptions.Item label={'是否为自费单'}>{`${
                detail.feeNeedPay === '0' ? '否' : detail.feeNeedPay === '1' ? '是' : ''
              }`}</Descriptions.Item>
              <Descriptions.Item label={'处理结果'}>{handleResult()}</Descriptions.Item>
              <Descriptions.Item label={'外包处理'}>
                {(detail.outsourcingTag === 1 && '是') || (detail.outsourcingTag === 0 && '否')}
              </Descriptions.Item>
              <Descriptions.Item label={'自费耗材'}>
                {detail.itemListV2
                  ?.filter(item => item.stewardPay)
                  .map(
                    item =>
                      `${item.sku ?? '-'}/${item.name ?? '-'}/${item.specification ?? '-'}/${item.brand ?? '-'}x${
                        item.num
                      }(${item.chargeName})`
                  )
                  .join('、')}
              </Descriptions.Item>
              <Descriptions.Item label={'仓库耗材'}>{`${
                detail.itemListV2
                  ?.filter(item => !item.stewardPay)
                  .map(
                    item =>
                      `${item.sku ?? '-'}/${item.name ?? '-'}/${item.specification ?? '-'}/${item.brand ?? '-'}x${
                        item.num
                      }(${item.chargeName})`
                  )
                  .join('、') ?? ''
              }`}</Descriptions.Item>
              <Descriptions.Item label={'总费用'}>
                {![null, undefined].includes(detail.feeStaff) ? `${detail.feeStaff}元` : ''}
                {detail.payItemList?.length
                  ? `（${detail.payItemList
                      .map(item => `${item.chargeName}${item.itemNum}个${item.itemAmtTotal}元`)
                      .join('，')}）`
                  : ''}
              </Descriptions.Item>
              <Descriptions.Item label={'订单备注'}>{detail.serverNotes}</Descriptions.Item>
              <Descriptions.Item label={'结果备注'}>{detail.handleResult}</Descriptions.Item>
              <Descriptions.Item label={'维修耗材'}>{materialSourceCode[detail.materialSource]}</Descriptions.Item>
              {detail.materialSource === '2' && (
                <Descriptions.Item label={'耗材领取地址'}>{detail.materialAddress}</Descriptions.Item>
              )}
              {!!detail?.feeCarriage && (
                <Descriptions.Item label={'垃圾清运费'}>
                  {detail.feeCarriage ? `${detail.feeCarriage} 元` : ''}
                </Descriptions.Item>
              )}
              <Descriptions.Item label={'垫付金额'}>
                {detail.feePayFirst ? `${detail.feePayFirst}元` : ''}
              </Descriptions.Item>
              <Descriptions.Item label={'垫付凭证'}>
                {detail.imgListOfPayFirst?.length ? (
                  <DetailSectionImage
                    title={''}
                    imgList={detail.imgListOfPayFirst}
                    onClickVideo={img => setSelectImg({imgUrl: img.imgUrl, visible: true})}
                  />
                ) : (
                  '/'
                )}
              </Descriptions.Item>
              {/*<Descriptions.Item label={'处理内容'}>{detail.resultNameList?.join('、')}</Descriptions.Item>*/}
              <Descriptions.Item label={'处理前照片'}>
                <DetailSectionImage
                  title={''}
                  imgList={detail.imgListOfStewardFront}
                  onClickVideo={img => setSelectImg({imgUrl: img.imgUrl, visible: true})}
                />
              </Descriptions.Item>
              <Descriptions.Item label={'处理后照片'}>
                <DetailSectionImage
                  title={''}
                  imgList={detail.imgListOfStewardBack}
                  onClickVideo={img => setSelectImg({imgUrl: img.imgUrl, visible: true})}
                />
              </Descriptions.Item>
              <Descriptions.Item label={'海报'}>
                <DetailSectionImage
                  title={''}
                  imgList={
                    detail.extra?.posterImgUrl
                      ? [{imgId: detail.extra?.posterImgUrl, imgUrl: getImg(detail.extra?.posterImgUrl)}]
                      : null
                  }
                  onClickVideo={img => setSelectImg({imgUrl: img.imgUrl, visible: true})}
                />
              </Descriptions.Item>
              {!!detail?.imgListOfHigh?.length && (
                <Descriptions.Item label={'高空作业凭证'}>
                  <DetailSectionImage
                    title={''}
                    imgList={detail.imgListOfHigh}
                    onClickVideo={img => setSelectImg({imgUrl: img.imgUrl, visible: true})}
                  />
                </Descriptions.Item>
              )}
            </Descriptions>
            <Descriptions title={<DetailSectionTitle title={'采购信息'} />} column={2}>
              {purchaseList.map(value => (
                <React.Fragment key={value.purchaseId}>
                  <Descriptions.Item
                    label={'申请人'}
                  >{`${value.applicantName}(${value.applicantMobile})`}</Descriptions.Item>
                  <Descriptions.Item label={'申请时间'}>{value.applyTime}</Descriptions.Item>
                  <Descriptions.Item label={'采购 ID'}>
                    <Typography.Link
                      onClick={() => history.push({pathname: '/purchase', state: {purchaseId: value.purchaseId}})}
                    >
                      <Space>
                        {value.purchaseId}
                        <RightOutlined />
                      </Space>
                    </Typography.Link>
                  </Descriptions.Item>
                  <Descriptions.Item label={'采购物品'}>{value.purchaseTitle}</Descriptions.Item>
                  <Descriptions.Item label={'采购地址'}>{value.warehouseName}</Descriptions.Item>
                  <Descriptions.Item label={'采购状态'}>{purchaseStatus[value.purchaseStatus]}</Descriptions.Item>
                  <Descriptions.Item span={2}>
                    <Divider style={{margin: 0}} />
                  </Descriptions.Item>
                </React.Fragment>
              ))}
            </Descriptions>
            `
          </div>
        </Col>

        <Col span={7} style={{minHeight: '100%'}}>
          <div style={{backgroundColor: '#fff', padding: '30px', minHeight: '100%'}}>
            <Row>
              <Col>
                <DetailSectionTitle title={'订单追踪'} />
              </Col>
            </Row>
            {progressList?.map((item, index) => {
              return (
                <div key={item.progressId}>
                  <DetailSectionStep
                    item={item}
                    isTop={index === 0}
                    isBottom={index === progressList.length - 1}
                    onClick={value => {
                      setMapInfo({show: true, lng: value.lng, lat: value.lat})
                      mapPanToLoc(value.lng, value.lat)
                    }}
                  />
                </div>
              )
            })}
          </div>
        </Col>
      </Row>

      {/* 渠道报价记录 */}
      <Drawer open={recordOpen} title={'渠道报价记录'} width={800} onClose={() => setRecordOpen(false)}>
        <Table
          rowKey={'approvalOrderId'}
          columns={[
            {title: '申请人', dataIndex: 'applyUserName'},
            {title: '申请时间', dataIndex: 'insertTime'},
            {title: '内部审批状态', dataIndex: 'approvalStatus', render: value => approvalStatusOptions[value]},
            {title: '外部审批状态', dataIndex: 'appAuditStatus'},
            {
              title: '操作',
              fixed: 'right',
              render: record => (
                <div style={{display: 'flex', flexDirection: 'column'}}>
                  <Button
                    type={'link'}
                    style={{paddingLeft: 0}}
                    onClick={async () => {
                      const {
                        response: {data},
                      } = await stewardModel.approvalOrderDetail({approvalOrderId: record.approvalOrderId})
                      setApprovalOrderDetail(data)
                      setRecordDetailOpen(true)
                    }}
                  >
                    查看
                  </Button>
                </div>
              ),
            },
          ]}
          scroll={{x: 'max-content'}}
          dataSource={approvalOrderQuotationList}
          pagination={false}
        />
      </Drawer>

      {/* 报价详情 */}
      <Modal
        open={recordDetailOpen}
        centered={true}
        title={
          <div>
            报价详情&nbsp;&nbsp;&nbsp;
            {approvalOrderDetail?.approvalStatus === '3' ? (
              <span
                style={{fontSize: 14, fontWeight: 400, color: '#00B7AE', cursor: 'pointer'}}
                onClick={() => {
                  if (!navigator.clipboard) {
                    const ele = document.createElement('input')
                    if (detail.appName === '龙湖冠寓') {
                      ele.value = `${serverUrl}/web/quotation/longhu.html?approvalOrderId=${approvalOrderDetail?.approvalOrderId} 点此链接查看${detail.propertyAddr}报价详情`
                    } else {
                      ele.value = `${serverUrl}/web/quotation/beike.html?approvalOrderId=${approvalOrderDetail?.approvalOrderId} 点此链接查看${detail.propertyAddr}报价详情`
                    }
                    document.body.appendChild(ele)
                    ele.select()
                    document.execCommand('copy')
                    document.body.removeChild(ele)
                    if (document.execCommand('copy')) {
                      message.success('复制成功！')
                    } else {
                      message.warn('复制失败!')
                    }
                  } else {
                    if (detail.appName === '龙湖冠寓') {
                      navigator.clipboard
                        .writeText(
                          `${serverUrl}/web/quotation/longhu.html?approvalOrderId=${approvalOrderDetail?.approvalOrderId} 点此链接查看${detail.propertyAddr}报价详情`
                        )
                        .then(() => {
                          message.success('复制成功！')
                        })
                        .catch(() => {
                          message.warn('复制失败!')
                        })
                    } else {
                      navigator.clipboard
                        .writeText(
                          `${serverUrl}/web/quotation/beike.html?approvalOrderId=${approvalOrderDetail?.approvalOrderId} 点此链接查看${detail.propertyAddr}报价详情`
                        )
                        .then(() => {
                          message.success('复制成功！')
                        })
                        .catch(() => {
                          message.warn('复制失败!')
                        })
                    }
                  }
                }}
              >
                分享
              </span>
            ) : null}
          </div>
        }
        footer={null}
        onCancel={() => setRecordDetailOpen(false)}
        destroyOnClose
        width={800}
      >
        <div style={{display: 'flex'}}>
          <div style={{width: 70}}>申请详情：</div>
          {detail.appName === '龙湖冠寓' ? (
            <div style={{flex: 1}}>
              <Descriptions column={2} colon>
                <Descriptions.Item label={'订单编号'}>{detailOrderId}</Descriptions.Item>
                <Descriptions.Item label={'申请人'}>{applyUserName + '（' + applyUserPhone + '）'}</Descriptions.Item>
                <Descriptions.Item label={'申请时间'}>{approvalOrderDetail?.insertTime}</Descriptions.Item>
                <Descriptions.Item label={'总费用（元）'}>
                  {itemList?.reduce((sum, item) => (sum += item.feeItemNum * (item.feeItemPrice * 100)), 0) * 0.01}
                </Descriptions.Item>
                <Descriptions.Item label={'申请附件'} contentStyle={{flexWrap: 'wrap'}}>
                  {pics?.map(item => (
                    <div key={item} style={{marginRight: 8, padding: 8, border: '1px solid #d9d9d9', borderRadius: 2}}>
                      <Image src={getImg(item)} width={50} height={50} />
                    </div>
                  ))}
                </Descriptions.Item>
                <Descriptions.Item label={'申请备注'}>{feeExplain}</Descriptions.Item>
                <Descriptions.Item label={'房源地址'}>{detail.propertyAddr}</Descriptions.Item>
              </Descriptions>
              {itemList?.map((item, index) => (
                <Descriptions column={2} colon key={item.feeItemCode}>
                  <Descriptions.Item label={`服务名称${index + 1}`}>{item?.feeItemName}</Descriptions.Item>
                  <Descriptions.Item label={'服务收费（元）'}>{item?.feeItemPrice}</Descriptions.Item>
                  <Descriptions.Item label={'单位'}>{item?.feeItemUnit}</Descriptions.Item>
                  <Descriptions.Item label={'工程量'}>{item?.feeItemNum}</Descriptions.Item>
                  <Descriptions.Item label={'工程量图片'}>
                    <div style={{marginRight: 8, padding: 8, border: '1px solid #d9d9d9', borderRadius: 2}}>
                      <Image src={getImg(item?.feeItemPic)} width={50} height={50} />
                    </div>
                  </Descriptions.Item>
                </Descriptions>
              ))}
            </div>
          ) : (
            <div style={{flex: 1}}>
              <Descriptions column={1} colon>
                <Descriptions.Item label={'报价日期'}>
                  {approvalOrderDetail?.insertTime?.split(' ')?.[0]}
                </Descriptions.Item>
                <Descriptions.Item label={'订单编号'}>{detailOrderId}</Descriptions.Item>
                <Descriptions.Item label={'贝壳服务单号'}>{beikeOrderId}</Descriptions.Item>
                <Descriptions.Item label={'维修地址'}>{repairAddress}</Descriptions.Item>
                <Descriptions.Item label={'维修项目'}>{detail.categoryThiName}</Descriptions.Item>
                <Descriptions.Item label={'维修方案'}>{repairScheme}</Descriptions.Item>
                <Descriptions.Item label={'物品年限'}>{goodsAge}</Descriptions.Item>
                <Descriptions.Item label={'支付方式'}>
                  {payTypeList?.map(item => item.value)?.join(',')}
                </Descriptions.Item>
                {payTypeList?.some(item => item.key === '1') && (
                  <Descriptions.Item label={'月结费用（元）'}>{monthFee ?? 0}</Descriptions.Item>
                )}
                {payTypeList?.some(item => item.key === '2') && (
                  <Descriptions.Item label={'房东支付费用（元）'}>{lessorFee ?? 0}</Descriptions.Item>
                )}
                <Descriptions.Item label={'总费用（元）'}>{feeTotal}</Descriptions.Item>
                <Descriptions.Item label={'维修管家'}>{detail.stewardName}</Descriptions.Item>
                <Descriptions.Item label={'资管'}>{assetManagerName}</Descriptions.Item>
                <Descriptions.Item label={'申请备注'}>{remark}</Descriptions.Item>
                <Descriptions.Item label={'申请附件'} contentStyle={{flexWrap: 'wrap'}}>
                  {pics2?.map(item => (
                    <div key={item} style={{marginRight: 8, padding: 8, border: '1px solid #d9d9d9', borderRadius: 2}}>
                      <Image src={getImg(item)} width={50} height={50} />
                    </div>
                  ))}
                </Descriptions.Item>
              </Descriptions>
            </div>
          )}
        </div>
      </Modal>

      {/** 预览视频 */}
      <Modal
        centered
        destroyOnClose
        visible={selectImg.visible}
        title={'视频预览'}
        footer={null}
        onCancel={() => setSelectImg(state => ({...state, visible: false}))}
      >
        <video controls src={getImg(selectImg.imgUrl)} width='100%' />
      </Modal>

      <Modal
        visible={mapInfo.show}
        centered={true}
        title={'完成定位'}
        footer={null}
        onCancel={() => setMapInfo(prevState => ({...prevState, show: false}))}
        destroyOnClose
      >
        <div style={{width: '100%', height: 300}}>
          <Mapview ref={mapRef}>
            <Marker lat={mapInfo.lat} lng={mapInfo.lng} />
          </Mapview>
        </div>
      </Modal>

      {/** 填写处理情况 */}
      <Modal centered visible={isHandleInput} title={'处理结果'} footer={null} onCancel={() => setIsHandleInput(false)}>
        <Form
          form={form}
          labelAlign={'right'}
          labelCol={{span: 8}}
          onFinish={async () => {
            const formValues = form.getFieldsValue(true)
            const {feeNeedPay, imgIdListFront, imgIdListBack} = formValues
            if (!imgIdListFront || !imgIdListFront.length) return message.error('处理前照片或视频')
            if (!imgIdListBack || !imgIdListBack.length) return message.error('处理后照片或视频')

            const thiClean = checkThiClean(detail.categoryFir)
            if (thiClean) {
              if (imgIdListFront.every(value => !isVideoUrl(value.imgUrl))) {
                return message.error('处理前必须至少有一个视频')
              }
              if (imgIdListBack.every(value => !isVideoUrl(value.imgUrl))) {
                return message.error('处理后必须至少有一个视频')
              }
            }

            await stewardModel.stewardOrderHandleResult({
              orderId,
              ...formValues,
              categoryFir: detail.categoryFir,
              categoryFirName: detail.categoryFirName,
              categorySec: detail.categorySec,
              categorySecName: detail.categorySecName,
              categoryThi: detail.categoryThi,
              categoryThiName: detail.categoryThiName,
              feeNeedPay: feeNeedPay ?? '0',
              imgIdListFront: imgIdListFront.map(item => item.imgId),
              imgIdListBack: imgIdListBack.map(item => item.imgId),
            })
            form.resetFields()
            setIsHandleInput(false)
            queryStewardOrderDetail()
          }}
        >
          <Form.Item name={'feeStaff'} label={'总费用（元）'} rules={[{required: true, message: '请输入人工费用'}]}>
            <Input placeholder={'请输入'} allowClear />
          </Form.Item>
          <Form.Item
            name={'feeCarriage'}
            label={'垃圾清运费（元）'}
            rules={[{required: true, message: '请输入垃圾清运费'}]}
          >
            <Input placeholder={'请输入'} allowClear />
          </Form.Item>
          <Form.Item name={'feeNeedPay'} label={'是否为自费单'}>
            <Radio.Group defaultValue={'0'}>
              <Radio value={'0'}>否</Radio>
              <Radio value={'1'}>是</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item name={'handleResultType'} label={'处理结果'} rules={[{required: true, message: '请选择处理结果'}]}>
            <Select placeholder={'请选择'} allowClear>
              {handleResultTypeArr.map(item => (
                <Option key={item.id} value={item.id}>
                  {item.label}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label={'处理前照片或视频'} shouldUpdate required>
            {form1 => {
              const imgList = form1.getFieldValue('imgIdListFront') ?? []
              return (
                <Album
                  limit={50}
                  action={baseUrl + '/steward/upload-img'}
                  accept={'image/*,video/*'}
                  data={{imgCategory: '3'}}
                  beforeUpload={file => {
                    if (file.size > 1024 * 1024 * 30) {
                      message.error('不能上传超过 30m 的图片/视频')
                      return Upload.LIST_IGNORE
                    }
                  }}
                  onChange={info => {
                    if (info.file.status === 'done') {
                      const {
                        response: {data},
                      } = info.file
                      form1.setFieldsValue({imgIdListFront: [...imgList, data]})
                    }
                    if (info.file.status === 'removed') {
                      const {
                        response: {data},
                      } = info.file
                      form1.setFieldsValue({imgIdListFront: imgList.filter(item => item.imgId !== data.imgId)})
                    }
                  }}
                >
                  <UploadButton />
                </Album>
              )
            }}
          </Form.Item>
          <Form.Item label={'处理后照片或视频'} shouldUpdate required>
            {form1 => {
              const imgList = form1.getFieldValue('imgIdListBack') ?? []
              return (
                <Album
                  limit={50}
                  accept={'image/*,video/*'}
                  action={baseUrl + '/steward/upload-img'}
                  data={{imgCategory: '2'}}
                  beforeUpload={file => {
                    if (file.size > 1024 * 1024 * 30) {
                      message.error('不能上传超过 30m 的图片/视频')
                      return Upload.LIST_IGNORE
                    }
                  }}
                  onChange={info => {
                    if (info.file.status === 'done') {
                      const {
                        response: {data},
                      } = info.file
                      form1.setFieldsValue({imgIdListBack: [...imgList, data]})
                    }
                    if (info.file.status === 'removed') {
                      const {
                        response: {data},
                      } = info.file
                      form1.setFieldsValue({imgIdListBack: imgList.filter(item => item.imgId !== data.imgId)})
                    }
                  }}
                >
                  <UploadButton />
                </Album>
              )
            }}
          </Form.Item>
          <Form.Item name={'handleResult'} label={'结果备注'}>
            <Input.TextArea maxLength={200} placeholder={'请输入备注'} allowClear />
          </Form.Item>
          <Row justify={'end'}>
            <Space>
              <Button
                onClick={() => {
                  form.resetFields()
                  setIsHandleInput(false)
                }}
              >
                取消
              </Button>
              <Button type={'primary'} htmlType={'submit'} loading={submitLoading}>
                提交
              </Button>
            </Space>
          </Row>
        </Form>
      </Modal>

      {/*返工*/}
      <Modal visible={isRework} centered title={'返工'} footer={null} onCancel={() => setIsRework(false)}>
        <Form form={cForm} labelAlign={'left'} labelCol={{span: 6}}>
          <Row>
            <Col span={18}>
              <Form.Item name={'serverId'} label={'服务人员'} rules={[{required: true, message: '请选择服务人员'}]}>
                <Select placeholder={'请选择'} allowClear>
                  {cleanerList.map(item => (
                    <Option key={item.userId} value={item.userId}>
                      {item.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={18}>
              <Form.Item label={'图片'} shouldUpdate>
                {form1 => {
                  const imgList = form1.getFieldValue('imgIdList') ?? []
                  return (
                    <Album
                      limit={50}
                      action={baseUrl + '/steward/upload-img'}
                      data={{imgCategory: '1'}}
                      onChange={info => {
                        if (info.file.status === 'done') {
                          const {
                            response: {data},
                          } = info.file
                          form1.setFieldsValue({imgIdList: [...imgList, data]})
                        }
                        if (info.file.status === 'removed') {
                          const {
                            response: {data},
                          } = info.file
                          form1.setFieldsValue({imgIdList: imgList.filter(item => item.imgId !== data.imgId)})
                        }
                      }}
                    >
                      <UploadButton />
                    </Album>
                  )
                }}
              </Form.Item>
            </Col>
            <Col span={18}>
              <Form.Item name={'orderDesc'} label={'备注'}>
                <Input.TextArea placeholder={'请输入'} maxLength={100} allowClear />
              </Form.Item>
            </Col>
          </Row>
          <Row justify={'end'}>
            <Space>
              <Button onClick={() => setIsRework(false)}>取消</Button>
              <Button
                type={'primary'}
                onClick={async () => {
                  await cForm.validateFields()
                  const formValues = cForm.getFieldsValue(true)
                  await stewardModel.stewardAdminReworkOrder({
                    orderId,
                    ...formValues,
                    imgIdList: formValues.imgIdList?.map(item => item.imgId),
                  })
                  cForm.resetFields()
                  setIsRework(false)
                  message.success('返工单创建成功！')
                  queryStewardOrderDetail()
                }}
                loading={stewardModel.stewardAdminReworkOrder.useLoading()}
              >
                确定
              </Button>
            </Space>
          </Row>
        </Form>
      </Modal>

      {/* 保修 */}
      <Modal
        visible={warrantyVisible}
        title={'保修信息'}
        centered
        footer={null}
        onCancel={() => setWarrantyVisible(false)}
        bodyStyle={{paddingTop: 0}}
      >
        {!!warrantyDetail && (
          <Tabs>
            {warrantyDetail.itemList.map((item, index) => (
              <Tabs.TabPane id={item.itemId} tab={item.chargeName} key={index}>
                <Descriptions column={1}>
                  <Descriptions.Item
                    label={'保修服务'}
                  >{`${warrantyDetail.categoryFirName}-${warrantyDetail.categorySecName}-${warrantyDetail.categoryThiName}`}</Descriptions.Item>
                  <Descriptions.Item label={'保修期限'}>{`${item.warrantyTime}天`}</Descriptions.Item>
                  <Descriptions.Item label={'到期时间'}>{item.endDate}</Descriptions.Item>
                  <Descriptions.Item label={'保修地址'}>{warrantyDetail.propertyAddr}</Descriptions.Item>
                  <Descriptions.Item label={'保修范围'} contentStyle={{whiteSpace: 'pre-line'}}>
                    {item.warrantyRange}
                  </Descriptions.Item>
                  <Descriptions.Item label={'免责条款'} contentStyle={{whiteSpace: 'pre-line'}}>
                    {item.warrantyExceptions}
                  </Descriptions.Item>
                </Descriptions>
              </Tabs.TabPane>
            ))}
          </Tabs>
        )}
      </Modal>

      {/* 取消服务 */}
      <Modal
        title={'取消原因'}
        visible={cancelVisible}
        centered
        footer={null}
        onCancel={() => setCancelVisible(false)}
        afterClose={() => cancelForm.resetFields()}
      >
        <Form
          form={cancelForm}
          labelCol={{span: 6}}
          onFinish={async () => {
            const {orderId, cancelType, cancelReason} = cancelForm.getFieldsValue(true)
            await stewardModel.stewardAdminOrderCancel({orderId, cancelType: cancelTypeCode[cancelType], cancelReason})
            setCancelVisible(false)
            message.success('操作成功！')
            queryStewardOrderDetail()
          }}
          onReset={() => setCancelVisible(false)}
        >
          <Row>
            <Col span={20}>
              <Form.Item label={'取消原因'} name={'cancelType'} rules={[{required: true, message: '请选择取消原因'}]}>
                <Select placeholder={'请选择'}>
                  {cancelTypeArr.map(item => (
                    <Select.Option key={item.id} value={item.id}>
                      {item.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={20}>
              <Form.Item label={'具体描述'} name={'cancelReason'}>
                <Input.TextArea placeholder={'请输入'} maxLength={200} showCount />
              </Form.Item>
            </Col>
          </Row>
          <Row justify={'end'}>
            <Space>
              <Button htmlType={'reset'}>取消</Button>
              <Button type={'primary'} htmlType={'submit'} loading={cancelLoading}>
                提交
              </Button>
            </Space>
          </Row>
        </Form>
      </Modal>

      <Modal
        title={logisticsForm.getFieldValue('recordId') ? '编辑物流单号' : '添加物流单号'}
        visible={logisticsVisible}
        onCancel={() => setLogisticsVisible(false)}
        okText={'提交'}
        onOk={() => logisticsForm.submit()}
        afterClose={() => logisticsForm.resetFields()}
        confirmLoading={logisticsLoading}
      >
        <Form
          form={logisticsForm}
          onFinish={async () => {
            const values = logisticsForm.getFieldsValue(true)
            values.recordId
              ? await stewardModel.stewardAdminOrderLogisticsUpdate(values)
              : await stewardModel.stewardAdminOrderLogisticsInsert({orderId: detail.orderId, ...values})
            message.success('操作成功')
            setLogisticsVisible(false)
            queryLogisticsList()
          }}
        >
          <Form.Item label={'物流单号'} name={'logisticsId'} rules={[{required: true, message: '物流单号不能为空'}]}>
            <Input placeholder={'请输入'} style={{width: 210}} />
          </Form.Item>
        </Form>
      </Modal>

      {/* 填写处理结果 */}
      <Drawer
        visible={repairResultVisible}
        width={700}
        title={'填写处理结果'}
        onClose={() => {
          setRepairResultVisible(false)
          repairResultForm.resetFields()
        }}
        destroyOnClose
      >
        <Form
          form={repairResultForm}
          onFinish={async () => {
            const {imgIdListBack, imgIdListFront, itemList, ...values} = repairResultForm.getFieldsValue(true)
            if (!imgIdListBack?.length) return message.error('请上传处理后照片/视频！')
            await stewardModel.stewardAdminOrderHandleResult({
              orderId,
              imgIdListBack: imgIdListBack.map(item => item.imgId),
              imgIdListFront: imgIdListFront?.length ? imgIdListFront.map(item => item.imgId) : [],
              itemList: itemList?.length ? itemList : [],
              ...values,
            } as StewardOrderHandleResultFromAdminDTO)
            message.success('提交成功!')
            repairResultForm.resetFields()
            setRepairResultVisible(false)
            queryStewardOrderDetail()
          }}
        >
          <Form.Item label={'服务项目'} shouldUpdate>
            <span>{`${detail.categoryFirName}-${detail.categorySecName}-${detail.categoryThiName}`}</span>
          </Form.Item>
          <Form.Item label={'处理结果'} shouldUpdate>
            <span>已完成服务</span>
          </Form.Item>
          <Form.Item label={'处理前照片/视频'} />
          <Form.Item shouldUpdate>
            {() => {
              const imgIdListFront = repairResultForm.getFieldValue('imgIdListFront') ?? []
              return (
                <Space direction={'vertical'}>
                  <Album
                    limit={13}
                    accept={'video/*, image/*'}
                    action={baseUrl + '/steward/admin/order/upload-file'}
                    data={{imgCategory: '3', userId}}
                    iconRender={file => {
                      return file.url ? (
                        <div style={{display: 'flex', alignItems: 'center', height: '100%', justifyContent: 'center'}}>
                          <video src={file.url} />
                        </div>
                      ) : (
                        <span>文件上传中</span>
                      )
                    }}
                    fileList={imgIdListFront.map(item => ({...item, uid: item.imgId, url: getImg(item.imgUrl)}))}
                    beforeUpload={file => {
                      if (
                        imgIdListFront.filter(item => item.video === '0').length === 10 ||
                        imgIdListFront.filter(item => item.video === '1').length === 3
                      ) {
                        message.error('最多上传10张照片或3个视频')
                        return Upload.LIST_IGNORE
                      }
                      if (isImageUrl(file.name)) {
                        if (file.size > 1024 * 1024 * 10) {
                          message.error('不能上传超过10M的照片')
                          return Upload.LIST_IGNORE
                        }
                      }
                      if (isVideoUrl(file.name)) {
                        if (file.size > 1024 * 1024 * 30) {
                          message.error('不能上传超过30M的视频')
                          return Upload.LIST_IGNORE
                        }
                      }
                    }}
                    onDone={fileList => {
                      repairResultForm.setFieldsValue({
                        imgIdListFront: fileList.map(item => ({
                          imgId: item.imgId,
                          imgUrl: item.imgUrl,
                          video: isVideoUrl(item.imgUrl) ? '1' : '0',
                        })),
                      })
                    }}
                  >
                    <UploadButton />
                  </Album>
                  <span style={{color: 'rgba(0, 0, 0, 0.45)'}}>照片最多10张，单张最大10M，视频最多3个</span>
                </Space>
              )
            }}
          </Form.Item>
          <Form.Item label={'处理后照片/视频'} required />
          <Form.Item shouldUpdate>
            {() => {
              const imgIdListBack = repairResultForm.getFieldValue('imgIdListBack') ?? []
              return (
                <Space direction={'vertical'}>
                  <Album
                    limit={13}
                    accept={'video/*, image/*'}
                    action={baseUrl + '/steward/admin/order/upload-file'}
                    data={{imgCategory: '2', userId}}
                    iconRender={file => {
                      return file.url ? (
                        <div style={{display: 'flex', alignItems: 'center', height: '100%', justifyContent: 'center'}}>
                          <video src={file.url} />
                        </div>
                      ) : (
                        <span>文件上传中</span>
                      )
                    }}
                    fileList={imgIdListBack.map(item => ({...item, uid: item.imgId, url: getImg(item.imgUrl)}))}
                    beforeUpload={file => {
                      if (
                        imgIdListBack.filter(item => item.video === '0').length === 10 ||
                        imgIdListBack.filter(item => item.video === '1').length === 3
                      ) {
                        message.error('最多上传10张照片或3个视频')
                        return Upload.LIST_IGNORE
                      }
                      if (isImageUrl(file.name)) {
                        if (file.size > 1024 * 1024 * 10) {
                          message.error('不能上传超过10M的照片')
                          return Upload.LIST_IGNORE
                        }
                      }
                      if (isVideoUrl(file.name)) {
                        if (file.size > 1024 * 1024 * 30) {
                          message.error('不能上传超过30M的视频')
                          return Upload.LIST_IGNORE
                        }
                      }
                    }}
                    onDone={fileList => {
                      repairResultForm.setFieldsValue({
                        imgIdListBack: fileList.map(item => ({
                          imgId: item.imgId,
                          imgUrl: item.imgUrl,
                          video: isVideoUrl(item.imgUrl) ? '1' : '0',
                        })),
                      })
                    }}
                  >
                    <UploadButton />
                  </Album>
                  <span style={{color: 'rgba(0, 0, 0, 0.45)'}}>照片最多10张，单张最大10M，视频最多3个</span>
                </Space>
              )
            }}
          </Form.Item>
          <Form.Item label={'服务人员'} name={'serverId'} rules={[{required: true, message: '请选择服务人员'}]}>
            <Select
              placeholder={'请选择'}
              showSearch
              filterOption={(inputValue, option) => {
                return option?.children?.indexOf(inputValue as any) > -1
              }}
              style={{width: 300}}
            >
              {stewardList.map(item => (
                <Select.Option value={item.userId} key={item.userId}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label={'是否有效'} name={'salaryValid'} rules={[{required: true, message: '请选择是否有效'}]}>
            <Radio.Group>
              <Radio value={1}>有效</Radio>
              <Radio value={0}>无效</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item label={'耗材使用'} shouldUpdate>
            {() => (
              <Form.List name={'itemList'}>
                {(fields, {add, remove}) => (
                  <>
                    <Form.Item>
                      <Space>
                        <Select
                          placeholder={'请选择管家'}
                          showSearch
                          filterOption={(inputValue, option) => {
                            return option?.children?.indexOf(inputValue as any) > -1
                          }}
                          style={{width: 300}}
                          onSelect={async value => {
                            const {
                              response: {data},
                            } = await stewardModel.stewardWarehouseAppListMaterialCommon({
                              queryType: 3,
                              pageable: false,
                              stewardId: value,
                              zeroFilter: true,
                            } as StewardWarehouseInventoryDTO)
                            repairResultForm.setFieldsValue({
                              itemList: [
                                {materialId: undefined, name: undefined, stewardPay: false, itemNum: undefined},
                              ],
                            })
                            setMaterialList(data)
                          }}
                        >
                          {stewardList
                            .filter(item => item.status === '1')
                            .map(item => (
                              <Select.Option value={item.userId} key={item.userId}>
                                {item.name}
                              </Select.Option>
                            ))}
                        </Select>
                        <PlusCircleTwoTone
                          twoToneColor='#00B7AE'
                          onClick={() => {
                            add()
                          }}
                        />
                      </Space>
                    </Form.Item>
                    {fields.map((field, index) => (
                      <Space key={field.key}>
                        <Form.Item
                          {...field}
                          name={[field.name, 'materialId']}
                          fieldKey={[field.fieldKey, 'materialId']}
                          rules={[{required: true, message: '请选择耗材'}]}
                          label={' '}
                          colon={false}
                        >
                          <Select
                            placeholder={'请选择耗材'}
                            style={{width: 300}}
                            onSelect={value => {
                              const option = materialList.find(item => item.materialId === value)
                              repairResultForm.setFields([
                                {name: ['itemList', index, 'name'], value: option?.name},
                                {name: ['itemList', index, 'stewardPay'], value: false},
                                {name: ['itemList', index, 'num'], value: option?.num},
                              ])
                            }}
                            showSearch
                            filterOption={(inputValue, option) => {
                              return option?.children?.indexOf(inputValue as any) > -1
                            }}
                          >
                            {materialList.map(item => (
                              <Select.Option value={item.materialId} key={item.materialId}>
                                {`${item.name}${item.specification ? `-${item.specification}` : ''}（${item.num}${
                                  item.unit
                                }）`}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                        <Form.Item
                          {...field}
                          name={[field.name, 'itemNum']}
                          fieldKey={[field.fieldKey, 'itemNum']}
                          rules={[
                            {
                              validator(_, value, callback) {
                                const itemList = repairResultForm.getFieldValue('itemList')
                                const {num} = itemList?.find((_, _index) => _index === index) ?? {}
                                if (!value) {
                                  callback('请输入数量')
                                  return
                                }
                                if (value && value > num) {
                                  callback('输入数量不能大于携带量')
                                  return
                                }
                                callback()
                              },
                            },
                          ]}
                        >
                          <InputNumber style={{width: 150}} placeholder={'请输入数量'} />
                        </Form.Item>
                        <Form.Item>
                          <CloseCircleTwoTone
                            twoToneColor='#A8A7AA'
                            onClick={() => {
                              remove(field.name)
                            }}
                          />
                        </Form.Item>
                      </Space>
                    ))}
                  </>
                )}
              </Form.List>
            )}
          </Form.Item>
          <Form.Item label={'结果备注'} name={'handleResult'}>
            <Input.TextArea style={{width: 300}} rows={3} maxLength={200} placeholder={'请输入'} />
          </Form.Item>
          <Row justify={'end'}>
            <Space>
              <Button
                onClick={() => {
                  setRepairResultVisible(false)
                  repairResultForm.resetFields()
                }}
              >
                取消
              </Button>
              <Button type={'primary'} loading={handleResultLoading} htmlType={'submit'}>
                提交
              </Button>
            </Space>
          </Row>
        </Form>
      </Drawer>

      {/* 绑定号码 */}
      <GuanYuBindMobile visible={mobileVisible} onCancel={() => setMobileVisible(false)} orderId={orderId} />
    </Page>
  )
}
