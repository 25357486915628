import {Model} from '@redux-model/web'
import {$api} from '@/store/service'

interface StorageModel {}

class Storage extends Model<StorageModel> {
  protected initReducer(): StorageModel {
    return {}
  }

  // 耗材sku列表
  stewardWarehouseMaterialList = $api.action((args: Partial<StewardMaterialListDTO>) =>
    this.post<ApiResponse<StewardMaterialListVO[]>>('/steward/warehouse/material/list').body({
      pageable: true,
      ...args,
    })
  )

  // 物品-详情  |  object:{materialId:物品id}
  stewardWarehouseMaterialDetail = $api.action((args: {materialId: string}) =>
    this.post<ApiResponse<StewardMaterialListVO>>('/steward/warehouse/material/detail').body(args)
  )

  // 新增编辑耗材
  stewardWarehouseMaterialUpsert = $api.action((args: Partial<StewardMaterialUpsertDTO>) =>
    this.post<ApiResponse>('/steward/warehouse/material/upsert').body(args)
  )

  // 物品-删除
  stewardWarehouseMaterialDelete = $api.action((args: {materialId: string}) =>
    this.post('/steward/warehouse/material/delete').body(args)
  )

  // 站点列表
  stewardWarehouseList = $api.action((args: Partial<StewardWarehouseListDTO>) =>
    this.post<ApiResponse<StewardWarehouseListItemVO[]>>('/steward/warehouse/list').body({
      pageNum: 1,
      pageSize: 10,
      pageable: true,
      ...args,
    })
  )

  // 站点信息
  stewardWarehouseInfo = $api.action((warehouseId: string) =>
    this.post<ApiResponse<StewardWarehouseVO>>('/steward/warehouse/info').body({
      warehouseId,
    })
  )

  // 新增编辑站点
  stewardWarehouseUpsert = $api.action((args: Partial<StewardWarehouseUpsertDTO>) =>
    this.post<ApiResponse>('/steward/warehouse/upsert').body(args)
  )

  //调拨申请列表
  stewardWarehouseTransferList = $api.action((args: Partial<StewardWarehouseTransferListDTO>) =>
    this.post<ApiResponse<StewardWarehouseTransferListVO[]>>('/steward/warehouse/transfer/list').body({
      pageNum: 1,
      pageSize: 10,
      pageable: true,
      ...args,
    })
  )

  // 站点库存列表
  stewardWarehouseInventory = $api.action((args: Partial<StewardWarehouseInventoryDTO>) =>
    this.post<ApiResponse<StewardMaterialListVO[]>>('/steward/warehouse/inventory').body({
      numOrder: true,
      pageNum: 1,
      pageSize: 10,
      pageable: true,
      ...args,
    })
  )

  // 仓库报表
  stewardWarehouseReport = $api.action((args: Partial<StewardWarehouseReportDTO>) =>
    this.post<ApiResponse<StewardWarehouseReportVO[]>>('/steward/warehouse/report').body({
      pageNum: 1,
      pageSize: 10,
      pageable: true,
      ...args,
    })
  )

  // 站点入仓
  stewardWarehousePurchase = $api.action((args: Partial<StewardWarehousePurchaseDTO>) =>
    this.post<ApiResponse>('/steward/warehouse/purchase').body(args)
  )

  // 站点盘点
  stewardWarehouseCheck = $api.action((args: StewardWarehouseCheckDTO) =>
    this.post<ApiResponse>('/steward/warehouse/check').body(args)
  )

  //站点盘点
  stewardWarehouseAppCheck = $api.action((args: StewardWarehouseCheckDTO) =>
    this.post<ApiResponse>('/steward/warehouse/app/check').body(args)
  )

  // 站点调拨
  stewardWarehouseTransfer = $api.action((args: StewardWarehouseTransferDTO) =>
    this.post<ApiResponse>('/steward/warehouse/transfer').body(args)
  )

  //调拨申请详情
  stewardWarehouseTransferInfo = $api.action((transferId: string) =>
    this.post<ApiResponse<StewardWarehouseTransferPendingVO>>('/steward/warehouse/transfer/info').body({transferId})
  )

  //调拨通过
  stewardWarehouseTransferPending = $api.action((transferId: string, status: string) =>
    this.post('/steward/warehouse/transfer/pending').body({
      transferId,
      status,
    })
  )

  // 采购列表
  purchaseList = $api.action((args: Partial<PurchaseListQueryDTO>) =>
    this.post<ApiResponse<PurchaseVO[]>>('/purchase/list').body({
      pageable: true,
      ...args,
    })
  )

  // 采购申请
  purchaseInsert = $api.action((args: PurchaseInsertDTO) => this.post('/purchase/insert').body(args))

  // 采购详情
  purchaseDetail = $api.action((args: {purchaseId: string}) =>
    this.post<ApiResponse<PurchaseVO>>('/purchase/detail').body(args)
  )

  // 提交采购订单信息
  purchaseUpdateOrder = $api.action((args: PurchaseUpdateOrderDTO) =>
    this.post('/purchase/item/update-order').body(args)
  )

  // 更新运单号
  purchaseUpdateWaybill = $api.action((args: {purchaseId: string; purchaseWaybill: string}) =>
    this.post('/purchase/update-waybill').body(args)
  )

  // 确认收货
  purchaseConfirmReceipt = $api.action((args: PurchaseItemConfirmDTO) =>
    this.post('/purchase/item/confirm-receipt').body(args)
  )

  // 采购列表
  purchaseListExport = $api.action((args: Partial<PurchaseListQueryDTO>) =>
    this.post('/purchase/list-export').body({
      pageable: false,
      ...args,
    })
  )

  // 管家App-物品-查询最新sku  |  object:{category:物品类别}
  stewardWarehouseAppMaterialSku = $api.action((args: {category: string}) =>
    this.post<ApiResponse<{sku: string}>>('/steward/warehouse/app/material/sku').body(args)
  )

  // 采购地址-删除  |  {addressId:地址id}
  purchaseAddressDelete = $api.action((args: {addressId: string}) => this.post('/purchase/address/delete').body(args))

  // 采购地址-地址列表
  purchaseAddressList = $api.action((args: PurchaseAddressQueryDTO) =>
    this.post<ApiResponse<PurchaseAddressVO[]>>('/purchase/address/list').body(args)
  )

  // 采购地址-新增/编辑
  purchaseAddressSave = $api.action((args: PurchaseAddressSaveDTO) => this.post('/purchase/address/save').body(args))

  // 采购项-详情  |  object:{purchaseItemId:采购项id}
  stewardAppPurchaseItemDetail = $api.action((args: {purchaseItemId: string}) =>
    this.post<ApiResponse<PurchaseItemVO>>('/steward/app/purchase/item/detail').body(args)
  )

  // 删除模板
  purchaseTemplateDelete = $api.action((args: {templateId: string}) =>
    this.post<ApiResponse<string>>('/purchase/template/delete').body(args)
  )

  // 查询模板列表(不分页)
  purchaseTemplateList = $api.action(() => this.post<ApiResponse<PurchaseTemplateVO[]>>('/purchase/template/list'))

  // 新增/编辑模板
  purchaseTemplateSave = $api.action((args: PurchaseTemplateSaveDTO) =>
    this.post<ApiResponse<string>>('/purchase/template/save').body(args)
  )

  // 物品-删除  |  object:[物品id]
  stewardWarehouseMaterialDeleteBatch = $api.action((args: string[]) =>
    this.post<ApiResponse<Record<string, any>>>('/steward/warehouse/material/delete-batch').body(args)
  )

  // 仓库启用/禁用  |  object:{warehouseId:仓库id, status:状态}
  stewardWarehouseEnable = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<Record<string, any>>>('/steward/warehouse/enable').body(args)
  )

  // 采购项-退货
  purchaseItemReturn = $api.action((args: PurchaseItemReturnInsertDTO) =>
    this.post<ApiResponse<Record<string, any>>>('/purchase/item/return').body(args)
  )

  // 仓库盘点历史
  stewardWarehouseCheckHistory = $api.action((args: StewardWarehouseCheckHistoryListDTO) =>
    this.post<ApiResponse<StewardWarehouseCheckRecordVO[]>>('/steward/warehouse/check/history').body(args)
  )

  // 仓库盘点记录  |  object:{warehouseId:仓库id, historyId:记录id}
  stewardWarehouseCheckRecord = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<StewardWarehouseCheckRecordVO[]>>('/steward/warehouse/check/record')
      .body(args)
      .metas(args.historyId)
  )
}

export const storageModel = new Storage()
