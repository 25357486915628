import {Model} from '@redux-model/web'
import {$api} from '@/store/service'

export interface LessorModel {}

class Lessor extends Model<LessorModel> {
  initReducer() {
    return {}
  }

  // 房东列表
  queryLessorList = $api.action((params: Partial<LessorListQueryDTO>) =>
    this.post<ApiResponse<LessorListVO[]>>('/user/lessor/list').body({
      ...params,
      pageable: true,
    })
  )

  // 开启或禁用房东状态
  lessorChangeStatus = $api.action((status: string, userId: string) =>
    this.post<ApiResponse>('/user/lessor/change-status').body({
      status,
      userId,
    })
  )

  // 创建、编辑房东
  lessorUpsert = $api.action((args: LessorInsertDTO) => this.post<ApiResponse>('/user/lessor/upsert').body({...args}))

  // 房东详情
  queryLessorInfo = $api.action((userId: string) =>
    this.post<ApiResponse<LessorInfoVO>>('/user/lessor/query-info').body({userId})
  )

  // 房东账单列表
  userLessorBillList = $api.action((args: Partial<LessorBillListDTO>) =>
    this.post<ApiResponse<LessorBillVO[]>>('/user/lessor/bill/list').body({
      pageable: true,
      ...args,
    })
  )

  // 查询房东账单详情
  userLessorBillInfo = $api.action((args: {billId: string}) =>
    this.post<ApiResponse<LessorBillVO>>('/user/lessor/bill/info').body(args)
  )

  // 创建房东账单
  userLessorBillAdd = $api.action((args: Partial<LessorBillDTO>) => this.post('/user/lessor/bill/add').body(args))

  //账单的房源列表查询
  userLessorBillPropertyList = $api.action((args: Partial<LessorBillPropertyQueryDTO>) =>
    this.post<ApiResponse<LessorBillPropertyVO[]>>('/user/lessor/bill/property-list').body({
      pageNum: 1,
      pageSize: 10,
      pageable: true,
      ...args,
    })
  )

  //// 账单的房源对应的`区域信息`  |  object:{billId:账单Id}
  userLessorBillPropertyDistrictList = $api.action((billId: string) =>
    this.post<ApiResponse<PairModel[]>>('/user/lessor/bill/property-district-list').body({billId})
  )

  // 账单的房源对应的`团队信息`  |  object:{billId:账单Id}
  userLessorBillPropertyTeamList = $api.action((billId: string) =>
    this.post<ApiResponse<PairModel[]>>('/user/lessor/bill/property-team-list').body({billId})
  )

  // 修改房东账单/更新房东账单状态
  userLessorBillUpdate = $api.action((args: Partial<LessorBillDTO>) => this.post('/user/lessor/bill/update').body(args))

  // 房东账单列表-excel
  userLessorBillListExcel = $api.action((args: Partial<LessorBillListDTO>) =>
    this.post<ApiResponse>('/user/lessor/bill/list-excel').body({pageNum: 1, pageSize: 10, pageable: false, ...args})
  )

  // 账单的房源列表查询（导出excel）  |  object:{billId:账单Id}
  userLessorBillPropertyListExcel = $api.action((args: Partial<LessorBillPropertyQueryDTO>) =>
    this.post<ApiResponse>('/user/lessor/bill/property-list-excel').body({
      pageNum: 1,
      pageSize: 10,
      pageable: false,
      ...args,
    })
  )

  // 审核账单
  userLessorBillApprove = $api.action((args: {billId: string}) => this.post('/user/lessor/bill/approve').body(args))

  // 确认账单（平台审核中-已付款）
  userLessorBillConfirm = $api.action((args: LessorBillConfirmDTO) =>
    this.post<ApiResponse<Record<string, any>>>('/user/lessor/bill/confirm').body(args)
  )

  // 删除账单  |  object:{billId:账单Id}
  userLessorBillDelete = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<Record<string, any>>>('/user/lessor/bill/delete').body(args)
  )

  // 删除账单  |  object:[账单Id]
  userLessorBillDeleteBatch = $api.action((args: string[]) =>
    this.post<ApiResponse<Record<string, any>>>('/user/lessor/bill/delete-batch').body(args)
  )

  // 确认账单状态（确认中-代收款）
  userLessorBillPreConfirm = $api.action((args: LessorBillPreConfirmDTO) =>
    this.post('/user/lessor/bill/pre-confirm').body(args)
  )

  // 租客会员-查询  |  object:{memberId:会员id}
  lessorLesseeMemberList = $api.action((args: LessorLesseeMemberQueryDTO) =>
    this.post<ApiResponse<LessorLesseeMemberVO[]>>('/lessor/lessee-member/list').body(args)
  )

  // 房东账单Id列表和金额合计(不分页,给批量收款用)
  userLessorBillConfirmQuery = $api.action((args: LessorBillListDTO) =>
    this.post<ApiResponse<LessorBillConfirmQueryVO>>('/user/lessor/bill/confirm-query').body(args)
  )
}

export const lessorModel = new Lessor()
