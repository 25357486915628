import React, {useCallback, useEffect, useRef, useState} from 'react'
import {Page} from '@/components/Page'
import {TreeProps} from 'antd/es/tree'
import {MoreOutlined, TeamOutlined, UserOutlined} from '@ant-design/icons'
import {
  Button,
  Col,
  Dropdown,
  Form,
  Input,
  message,
  Modal,
  Popover,
  Row,
  Space,
  Table,
  Tag,
  Tree,
  Typography,
} from 'antd'
import commonStyles from '@/assets/styles/common.module.less'
import {sysModel} from '@/store/models/Sys'
import {usePermission} from '@/components/Permission'

type OrgTreeData = TreeProps['treeData'][number] & SysOrgV2VO

type OrgMemberData = (Omit<SysOrgV2VO, 'children'> & {children: OrgMemberData[]}) | SysOrgV2MemberVO

type OrgMemberTreeData = TreeProps['treeData'][number] & OrgMemberData

// 获取父组织名称
function getOrgPname(fullName: string): string {
  const orgFullName = fullName?.split('/') ?? []
  let orgPname = ''
  if (orgFullName.length > 1) {
    orgPname = orgFullName[orgFullName.length - 2]
  }
  return orgPname
}

// 拖拽时遍历tree，找当前节点
function dropTreeLoop(
  data: OrgTreeData[],
  key: React.Key,
  callback: (node: OrgTreeData, index: number, data: OrgTreeData[]) => void
) {
  for (let i = 0; i < data.length; i++) {
    const node = data[i]
    if (node.key === key) {
      // 找到节点
      return callback(node, i, data)
    }
    // 递归子节点
    if (node.children) {
      dropTreeLoop(node.children as OrgTreeData[], key, callback)
    }
  }
}

// 拖拽时遍历tree，找父节点
function dropEndTreeLoop(data: OrgTreeData[], key: React.Key, callback: (node: OrgTreeData) => void) {
  for (let i = 0; i < data.length; i++) {
    const item = data[i]
    if (item && item.children && item.children.length > 0) {
      if (item.children.some(child => child.key === key)) {
        return callback(item)
      } else {
        dropEndTreeLoop(item.children as OrgTreeData[], key, callback)
      }
    }
  }
}

// 初始化组合和成员组合树
function initOrgMemberTree(orgTree: SysOrgV2VO[], memberNonOrgList: SysOrgV2MemberVO[]): OrgMemberData[] {
  return [
    ...orgTree,
    {
      children: memberNonOrgList,
      orgFullName: '',
      orgId: '未分配',
      orgName: '未分配',
      orgPid: '',
      orgPidList: [],
    },
  ]
}

// 把成员插入到组织树里面
function treeLoopToInsertMember(
  renderTree: OrgMemberData[],
  orgId: string,
  memberList: SysOrgV2MemberVO[]
): OrgMemberData[] {
  if (!renderTree || renderTree.length === 0) {
    return []
  } else {
    return renderTree.map(item => {
      if ('orgId' in item) {
        if (item.orgId === orgId) {
          return {...item, children: item.children ? [...item.children, ...memberList] : memberList}
        } else {
          return {...item, children: treeLoopToInsertMember(item?.children, orgId, memberList)}
        }
      } else return item
    })
  }
}

// 重复成员过滤，不重复成员合并
function treeMemberMerge(memberList: SysOrgV2MemberVO[], member: SysOrgV2MemberVO): SysOrgV2MemberVO[] {
  return memberList.some(value => value.userId === member.userId)
    ? memberList.filter(value => value.userId !== member.userId)
    : [
        ...memberList,
        {...member},
        // {
        //   mobile: member.mobile,
        //   name: member.name,
        //   orgName: member.orgName,
        //   roleName: member.roleName,
        //   status: member.status,
        //   userId: member.userId,
        //   userName: member.userName,
        //   orgIdStr: member.orgIdStr,
        //   positionLevel: member.positionLevel,
        // },
      ]
}

export default function OperationOrgManage() {
  const [form1] = Form.useForm()
  const [form2] = Form.useForm<{
    orgId: string // 组织id
    memberObj: {[orgId: string]: SysOrgV2MemberVO[]} // 组织对应的成员列表
    queryList: SysOrgV2MemberVO[] // 搜索列表
    isQuery: boolean // 是否搜索
    orgMemberTree: OrgMemberTreeData[] // 组织和人员渲染树
    selectedMembers: SysOrgV2MemberVO[] // 选中的人员列表
  }>()

  const [auth] = usePermission()

  // 组织树选中项
  const [treeKey, setTreeKey] = useState<string[]>([])

  // 组织树数据
  const [orgTree, setOrgTree] = useState<SysOrgV2VO[]>([])

  // 组织树渲染数据
  const [orgTreeData, setOrgTreeData] = useState<OrgTreeData[]>([])

  const queryOrgTree = useCallback(() => {
    sysModel.sysOrgV2Tree({allChildren: true, orgName: '', orgPid: '', orgId: 'ROOT'}).then(({response: {data}}) => {
      setOrgTree(data)
    })
  }, [])

  useEffect(() => {
    queryOrgTree()
  }, [queryOrgTree])

  // 组织成员列表
  const [reqMemberParams, setReqMemberParams] = useState<SysOrgV2MemberListDTO>({
    pageNum: 1,
    pageSize: 10,
    pageable: true,
    // status: '1',
  })
  const [memberTotal, setMemberTotal] = useState<number>(0)
  const [memberList, setMemberList] = useState<SysOrgV2MemberVO[]>([])
  const [checkedMembers, setCheckedMembers] = useState<SysOrgV2MemberVO[]>([])

  // 分页查询组织成员数据
  const queryMemberList = useCallback((args: SysOrgV2MemberListDTO) => {
    sysModel.sysOrgV2MemberList(args).then(({response: {data, total}}) => {
      setMemberList(data)
      setMemberTotal(total)
    })
  }, [])

  useEffect(() => {
    if (treeKey.length) {
      queryMemberList({...reqMemberParams, orgIdList: treeKey})
    }
  }, [queryMemberList, reqMemberParams, treeKey])

  // 不分页查询组织成员数据
  const queryMemberList1 = useCallback(
    async (args: Pick<SysOrgV2MemberListDTO, 'keyword' | 'orgIdList' | 'allChildren'>) => {
      const {
        response: {data},
      } = await sysModel.sysOrgV2MemberList({...args, pageNum: 1, pageSize: 10, pageable: false, status: '1'})
      return data
    },
    []
  )

  // 查询未分配的成员数据
  const queryMemberNonOrgList = useCallback(async (args: Pick<SysOrgV2MemberListDTO, 'keyword' | 'orgIdList'>) => {
    const {
      response: {data},
    } = await sysModel.sysOrgV2MemberNonOrgList({
      ...args,
      allChildren: true,
      pageNum: 1,
      pageSize: 10,
      pageable: false,
      status: '1',
    })
    return data
  }, [])

  // 新增或编辑
  const treeUpsert = useCallback(
    (action: 'update' | 'insert', data: SysOrgV2VO) => {
      switch (action) {
        case 'insert': {
          form1.setFieldsValue({orgPid: data.orgId, orgPname: data.orgName})
          break
        }
        case 'update': {
          const orgPname = getOrgPname(data.orgFullName)
          form1.setFieldsValue({orgId: data.orgId, orgName: data.orgName, orgPname})
          break
        }
      }

      Modal.confirm({
        title: '组织信息',
        icon: null,
        closable: true,
        centered: true,
        content: (
          <Form form={form1}>
            <Form.Item name={'orgPname'} label={'上级组织'} required>
              <Input readOnly />
            </Form.Item>
            <Form.Item name={'orgName'} label={'组织名称'} rules={[{required: true, message: '请输入组织名称'}]}>
              <Input placeholder={'请输入组织名称'} showCount maxLength={50} />
            </Form.Item>
          </Form>
        ),
        onOk: async () => {
          await form1.validateFields()

          const {orgId, orgName, orgPid} = form1.getFieldsValue(true)

          switch (action) {
            case 'insert': {
              await sysModel.sysOrgV2Insert({orgName, orgPid})
              break
            }
            case 'update': {
              await sysModel.sysOrgV2UpdateName({orgId, orgName})
              break
            }
          }

          queryOrgTree()
        },
        afterClose: () => form1.resetFields(),
      })
    },
    [form1, queryOrgTree]
  )

  // 删除
  const treeDelete = useCallback(
    (data: SysOrgV2VO) => {
      Modal.confirm({
        title: '组织删除',
        icon: null,
        closable: true,
        centered: true,
        content: '确认删除当前组织及子组织、以及所有成员吗？',
        onOk: async () => {
          await sysModel.sysOrgV2Delete({orgId: data.orgId})
          queryOrgTree()
          queryMemberList(reqMemberParams)
        },
      })
    },
    [queryMemberList, queryOrgTree, reqMemberParams]
  )

  // 渲染项
  const orgTreeItem = useCallback(
    ({data}: {data: SysOrgV2VO}) => {
      return (
        <div style={{display: 'flex', justifyContent: 'space-between'}}>
          <Space>
            <TeamOutlined />
            <span>{data.orgName}</span>
          </Space>
          <Dropdown
            menu={{
              items: [
                ...(auth('003193001') ? [{key: '1', label: '新增', onClick: () => treeUpsert('insert', data)}] : []),
                ...(auth('002193001') ? [{key: '2', label: '编辑', onClick: () => treeUpsert('update', data)}] : []),
                ...(auth('004193001')
                  ? [{key: '3', label: '删除', onClick: () => treeDelete(data), disabled: data.orgId === 'ROOT'}]
                  : []),
              ],
            }}
          >
            <span onClick={event => event.stopPropagation()} style={{transform: 'rotate(90deg)'}}>
              <MoreOutlined />
            </span>
          </Dropdown>
        </div>
      )
    },
    [auth, treeDelete, treeUpsert]
  )

  useEffect(() => {
    function toTransform(dataList: SysOrgV2VO[] = []): OrgTreeData[] {
      if (!dataList || dataList.length === 0) {
        return []
      } else {
        return dataList.map(item => ({
          ...item,
          key: item.orgId,
          title: orgTreeItem({data: item}),
          children: toTransform(item.children),
        }))
      }
    }

    if (orgTree) {
      setOrgTreeData(toTransform(orgTree))
    }
  }, [orgTree, orgTreeItem])

  // 移动
  const startNodeRef = useRef(null)

  function onDropStart(info) {
    startNodeRef.current = info.node
  }

  function onDrop(info) {
    // console.log('drag info', info)
    const dragNode = info.dragNode // 被拖拽移动的节点
    // const dragNodesKeys = info.dragNodesKeys // 被拖拽移动的节点的key，包括子节点的key
    const node = info.node // 拖拽移动之后被迫更改的节点
    const dropToGap = info.dropToGap // 是否在被迫更改的节点的下一个位置
    const dropPosition = info.dropPosition // 被拖拽移动的节点在当前层所处的位置

    const dragKey = dragNode.key // 被拖拽移动的节点
    const dropKey = node.key // 拖拽移动之后被迫更改的节点
    const dropLayer = node.pos.split('-') // 层数 pos: 0-0（一层树）, 0-0-0（两层树）, 0-0-0-0（三层树）这种结构

    let dragParentKey = '' // 被拖拽移动的节点的父节点的key
    dropEndTreeLoop(orgTreeData, dragKey, resultNode => {
      dragParentKey = resultNode.orgId
    })

    const dropDiff = dropPosition - Number(dropLayer[dropLayer.length - 1]) // 计算出在被迫更改节点的上面还是下面

    // 不能移动最外层
    if (dropDiff === -1) return

    const data = [...orgTreeData]

    let dragObj: OrgTreeData

    dropTreeLoop(data, dragKey, (item, index, arr) => {
      arr.splice(index, 1)
      dragObj = item
    })

    if (!dropToGap) {
      // 在被迫更改的节点的上一个位置
      dropTreeLoop(data, dropKey, item => {
        item.children = item.children || []
        item.children.unshift(dragObj)
      })
    } else if ((node?.props?.children || []).length > 0 && node?.props?.expanded && dropDiff === 1) {
      dropTreeLoop(data, dropKey, item => {
        item.children = item.children || []
        item.children.unshift(dragObj)
      })
    } else {
      let idx: number
      let resList: OrgTreeData[] = []
      dropTreeLoop(data, dropKey, (_item, index, arr) => {
        resList = arr
        idx = index
      })
      if (dropDiff === -1) {
        resList.splice(idx, 0, dragObj)
      } else {
        resList.splice(idx + 1, 0, dragObj)
      }
    }

    // console.log('data', data)

    dropEndTreeLoop(data, dragKey, resultNode => {
      // 如果父orgId发生变化，说明层级发生了变化
      if (dragParentKey !== resultNode.orgId) {
        setOrgTree(data)

        Modal.confirm({
          title: '组织移动',
          icon: null,
          closable: true,
          centered: true,
          content: `确认移动当前组织及子组织、以及所有成员到新组织${resultNode.orgFullName}下吗`,
          onOk: async () => {
            await sysModel.sysOrgV2Move({orgId: dragKey, orgPid: node.key})
            queryOrgTree()
          },
        })
      }
    })
  }

  // function onDropEnd(info) {
  //   console.log('end info', info)
  //
  //   const startNode = startNodeRef.current
  //   const startPos = startNode?.pos || ''
  //   const startPosList = startPos.split('-') || []
  //
  //   const endNode = info.node
  //   const endPos = endNode?.pos || ''
  //   const endPosList = endPos.split('-') || []
  //
  //   if (startPosList.length !== endPosList.length) {
  //     const data = [...orgTreeData]
  //
  //     dropEndTreeLoop(data, endNode.key, node => {
  //       Modal.confirm({
  //         title: '组织移动',
  //         icon: null,
  //         closable: true,
  //         centered: true,
  //         content: `确认移动当前组织及子组织、以及所有成员到新组织${node.orgFullName}下吗`,
  //         onOk: async () => {
  //           await sysModel.sysOrgV2Move({orgId: endNode.key, orgPid: node.key})
  //           queryOrgTree()
  //         },
  //       })
  //     })
  //   }
  // }

  // 组织和成员组合的树
  const orgMemberTreeItem = useCallback((data: OrgMemberData, selected: SysOrgV2MemberVO[] = [], isQuery = false) => {
    if ('orgId' in data) {
      return (
        <Space>
          <TeamOutlined />
          <span>{data.orgName}</span>
        </Space>
      )
    }

    if ('userId' in data)
      return (
        <Space>
          <UserOutlined />
          <span
            style={
              selected.some(value => value.userId === data.userId)
                ? isQuery
                  ? {color: '#00B7AE'}
                  : {fontWeight: 'bold', color: 'rgba(0, 0, 0, 0.65)'}
                : {}
            }
          >
            {data.name}（{data.mobile}）
          </span>
        </Space>
      )

    return null
  }, [])

  const orgMemberTreeRender = useCallback(
    (memberTree: OrgMemberData[], selected: SysOrgV2MemberVO[] = [], isQuery = false) => {
      function toTransform(dataList: OrgMemberData[] = []): OrgMemberTreeData[] {
        if (!dataList || dataList.length === 0) {
          return []
        } else {
          return dataList.map(item => {
            if ('orgId' in item) {
              return {
                ...item,
                key: item.orgId,
                title: orgMemberTreeItem(item, selected, isQuery),
                children: toTransform(item.children),
              }
            } else {
              return {...item, key: item.userId, title: orgMemberTreeItem(item, selected, isQuery)}
            }
          })
        }
      }

      return toTransform(memberTree)
    },
    [orgMemberTreeItem]
  )

  return (
    <Page>
      <div className={commonStyles.tableSection}>
        <Row gutter={10} style={{flexWrap: 'nowrap'}}>
          <Col flex={'328px'}>
            <Tree.DirectoryTree
              showIcon={false}
              selectedKeys={treeKey}
              treeData={orgTreeData}
              draggable
              blockNode
              defaultExpandAll
              expandAction={false}
              onDrop={onDrop}
              onDragStart={onDropStart}
              // onDragEnd={onDropEnd}
              onSelect={selectedKeys => setTreeKey(selectedKeys as string[])}
            />
          </Col>
          <Col flex={'auto'}>
            <Row justify={'space-between'} style={{marginBottom: '20px'}}>
              <Col>
                <Space>
                  <Input.Search
                    placeholder={'请输入姓名/手机号'}
                    onSearch={value => setReqMemberParams(prevState => ({...prevState, keyword: value}))}
                    allowClear
                  />
                </Space>
              </Col>
              <Col>
                <Space>
                  {auth('004194001') && (
                    <Button
                      type={'primary'}
                      onClick={() => {
                        if (checkedMembers.length === 0) return message.warning('请选择待删除的成员！')
                        Modal.confirm({
                          title: '删除成员',
                          icon: null,
                          closable: true,
                          centered: true,
                          content: `确认删除当前组织下的${checkedMembers.map(item => item.name).join(',')}吗？`,
                          onOk: async () => {
                            await sysModel.sysOrgV2RemoveMember({
                              orgId: treeKey[0],
                              userIdList: checkedMembers.map(item => item.userId),
                            })
                            setCheckedMembers([])
                            message.success('删除成功！')
                            queryMemberList({...reqMemberParams, orgIdList: treeKey})
                          },
                        })
                      }}
                    >
                      删除成员
                    </Button>
                  )}

                  {auth('003194001') && (
                    <Button
                      disabled={!treeKey.length}
                      type={'primary'}
                      onClick={async () => {
                        const memberNonOrgList = await queryMemberNonOrgList({})
                        const memberList = await queryMemberList1({orgIdList: treeKey})

                        const initList = initOrgMemberTree(orgTree, memberNonOrgList)

                        form2.setFieldsValue({
                          orgMemberTree: orgMemberTreeRender(initList, memberList),
                          selectedMembers: memberList,
                        })

                        Modal.confirm({
                          title: '添加成员',
                          icon: null,
                          closable: true,
                          centered: true,
                          width: '639px',
                          content: (
                            <Form form={form2}>
                              <Row style={{flexWrap: 'nowrap'}}>
                                <Col flex={'228px'}>
                                  <div style={{padding: '0 14px 14px 0'}}>
                                    <Input.Search
                                      placeholder='请输入姓名/手机号'
                                      allowClear
                                      onSearch={async value => {
                                        if (value) {
                                          const queryMemberList = await queryMemberList1({
                                            keyword: value,
                                            allChildren: true,
                                            orgIdList: ['ROOT'],
                                          })
                                          const queryNonOrgMemberList = await queryMemberNonOrgList({keyword: value})
                                          form2.setFieldsValue({
                                            queryList: [...queryMemberList, ...queryNonOrgMemberList],
                                            isQuery: true,
                                          })
                                        } else {
                                          form2.setFieldsValue({queryList: [], isQuery: false})
                                        }
                                      }}
                                    />
                                  </div>
                                  <Form.Item
                                    noStyle
                                    shouldUpdate={(prevValues, nextValues) =>
                                      prevValues.orgMemberTree !== nextValues.orgMemberTree
                                    }
                                  >
                                    {() => {
                                      const orgMemberTree = form2.getFieldValue('orgMemberTree') ?? []
                                      const selectedMembers = form2.getFieldValue('selectedMembers') ?? []
                                      const queryList = form2.getFieldValue('queryList') ?? []
                                      const isQuery = form2.getFieldValue('isQuery')
                                      // console.log('orgMemberTree', orgMemberTree)
                                      return isQuery ? (
                                        <Tree
                                          showIcon={false}
                                          treeData={orgMemberTreeRender(queryList, selectedMembers, isQuery)}
                                          onClick={(_, node) => {
                                            const treeNode = (node as unknown) as OrgMemberTreeData
                                            if ('userId' in treeNode) {
                                              // 如果点击的是成员，则添加到已选成员列表
                                              const selectedMembers = form2.getFieldValue('selectedMembers') ?? []
                                              const newSelectedMembers = treeMemberMerge(selectedMembers, treeNode)
                                              form2.setFieldsValue({
                                                selectedMembers: newSelectedMembers,
                                                orgMemberTree: orgMemberTreeRender(
                                                  orgMemberTree,
                                                  newSelectedMembers,
                                                  isQuery
                                                ),
                                              })
                                            }
                                          }}
                                        />
                                      ) : (
                                        <Tree.DirectoryTree
                                          showIcon={false}
                                          onClick={async (_, node) => {
                                            const treeNode = (node as unknown) as OrgMemberTreeData

                                            if ('orgId' in treeNode) {
                                              // 如果点击的是组织，则查询该组织下的成员
                                              const orgId = treeNode.orgId
                                              const memberObj = form2.getFieldValue('memberObj') ?? {}
                                              if (!memberObj[orgId]) {
                                                const data = await queryMemberList1({orgIdList: [orgId]})

                                                if (data.length) {
                                                  const newOrgMemberTree = treeLoopToInsertMember(
                                                    orgMemberTree,
                                                    orgId,
                                                    data
                                                  )
                                                  const newRenderTree = orgMemberTreeRender(
                                                    newOrgMemberTree,
                                                    selectedMembers
                                                  )

                                                  form2.setFieldsValue({
                                                    orgMemberTree: newRenderTree,
                                                    memberObj: {...memberObj, [orgId]: data},
                                                  })
                                                }
                                              }

                                              form2.setFieldsValue({orgId})
                                            } else {
                                              // 如果点击的是成员，则添加到已选成员列表
                                              const selectedMembers = form2.getFieldValue('selectedMembers') ?? []
                                              const newSelectedMembers = treeMemberMerge(selectedMembers, treeNode)
                                              form2.setFieldsValue({
                                                selectedMembers: newSelectedMembers,
                                                orgMemberTree: orgMemberTreeRender(orgMemberTree, newSelectedMembers),
                                              })
                                            }
                                          }}
                                          treeData={orgMemberTree}
                                        />
                                      )
                                    }}
                                  </Form.Item>
                                </Col>
                                <Col flex={'auto'}>
                                  <Typography.Text>已选择成员</Typography.Text>
                                  <Form.Item
                                    noStyle
                                    shouldUpdate={(prevValues, nextValues) =>
                                      prevValues.selectedMembers !== nextValues.selectedMembers
                                    }
                                  >
                                    {() => {
                                      const selectedMembers = form2.getFieldValue('selectedMembers') ?? []

                                      return (
                                        <div>
                                          {selectedMembers.map(item => (
                                            <Tag
                                              key={item.userId}
                                              style={{marginTop: 8}}
                                              closable
                                              onClose={() => {
                                                form2.setFieldsValue({
                                                  selectedMembers: selectedMembers.filter(
                                                    value => value.userId !== item.userId
                                                  ),
                                                })
                                              }}
                                            >
                                              {item.name}
                                            </Tag>
                                          ))}
                                        </div>
                                      )
                                    }}
                                  </Form.Item>
                                </Col>
                              </Row>
                            </Form>
                          ),
                          onOk: async () => {
                            const selectedMembers = form2.getFieldValue('selectedMembers') ?? []
                            await sysModel.sysOrgV2AddMember({
                              orgId: treeKey[0],
                              userIdList: selectedMembers.map(value => value.userId),
                            })
                            queryMemberList({...reqMemberParams, orgIdList: treeKey})
                          },
                          afterClose: () => {
                            form2.resetFields()
                          },
                        })
                      }}
                    >
                      添加成员
                    </Button>
                  )}
                </Space>
              </Col>
            </Row>

            <Table
              rowKey={'userId'}
              rowSelection={{
                selectedRowKeys: checkedMembers.map(value => value.userId),
                onChange: (_, selectedRows) => setCheckedMembers(selectedRows),
              }}
              columns={[
                {title: 'ID', dataIndex: 'userId'},
                {title: '账号', dataIndex: 'userName'},
                {title: '姓名', dataIndex: 'name'},
                {title: '手机号', dataIndex: 'mobile'},
                {title: '状态', dataIndex: 'status', render: value => ['无效', '有效'][value]},
                {
                  title: '组织',
                  dataIndex: 'orgName',
                  render: value => (
                    <Popover content={value}>{value?.length > 10 ? value?.slice(0, 10) + '...' : value}</Popover>
                  ),
                },
                {
                  title: '用户组',
                  dataIndex: 'roleName',
                  render: value => (
                    <Popover content={value}>{value?.length > 10 ? value?.slice(0, 10) + '...' : value}</Popover>
                  ),
                },
              ]}
              dataSource={memberList}
              pagination={{
                position: ['bottomCenter'],
                showSizeChanger: true,
                total: memberTotal,
                current: reqMemberParams.pageNum,
                pageSize: reqMemberParams.pageSize,
                onChange: (page, pageSize) => {
                  setReqMemberParams(prevState => ({
                    ...prevState,
                    pageSize,
                    pageNum: prevState.pageSize === pageSize ? page : 1,
                  }))
                },
              }}
            />
          </Col>
        </Row>
      </div>
    </Page>
  )
}
