import React, {useCallback, useEffect, useState} from 'react'
import {Button, Col, Form, Input, InputNumber, message, Row, Select, Space, Typography} from 'antd'
import {PlusCircleTwoTone, CloseCircleTwoTone} from '@ant-design/icons'
import {storageModel} from '@/store/models/Storage'
import styles from '@/pages/storage/StorageInput/index.module.less'
import {useHistory} from 'react-router-dom'
import commonStyles from '@/assets/styles/common.module.less'
import {useAppLocation} from '@/common/hooks/useRouter'
import {Page} from '@/components/Page'
import BackButton from '@/components/BackButton'

const {Option} = Select

export default function StorageOutput() {
  const history = useHistory()
  const location = useAppLocation<'/site/output'>()
  const warehouseId = location.state?.warehouseId

  // 出仓站点
  const [wareHouse, setWareHouse] = useState({} as StewardWarehouseListItemVO)
  // 站点列表
  const [wareList, setWareList] = useState<StewardWarehouseListItemVO[]>([])
  // 耗材列表
  const [materialList, setMaterialList] = useState<StewardMaterialListVO[]>([])
  // 添加不重复的材料
  const addMaterialList = useCallback((list: StewardMaterialListVO[]) => {
    setMaterialList(list)
    // setMaterialList(prev => {
    //   const next = list.filter(value => prev.every(item => item.materialId !== value.materialId))
    //   return [...prev, ...next]
    // })
  }, [])

  const [form] = Form.useForm()

  const materialLoading = storageModel.stewardWarehouseInventory.useLoading()
  const submitLoading = storageModel.stewardWarehouseTransfer.useLoading()

  // 站点列表
  useEffect(
    function () {
      storageModel.stewardWarehouseList({pageable: false, pageNum: 1, pageSize: 100}).then(({response: {data}}) => {
        setWareList(data.filter(item => item.warehouseId !== warehouseId))
        setWareHouse(data.filter(item => item.warehouseId === warehouseId)[0])
      })
    },
    [warehouseId]
  )

  // 库存列表
  function onMaterialSearch(value) {
    storageModel
      .stewardWarehouseInventory({
        pageable: false,
        pageNum: 1,
        pageSize: 100,
        warehouseId,
        numOrder: true,
        keyword: value,
      })
      .then(({response: {data}}) => {
        addMaterialList(data)
      })
  }

  // 提交
  async function onSubmit() {
    const params = form.getFieldsValue(true) as StewardWarehouseTransferDTO
    // console.log('params ', params)
    await storageModel.stewardWarehouseTransfer(params)
    message.success('提交成功')
    history.goBack()
  }

  return (
    <Page>
      <BackButton />
      <div className={commonStyles.tableSection}>
        <Form
          form={form}
          labelCol={{span: 2}}
          colon={false}
          onFinish={onSubmit}
          initialValues={{
            materialList: [{materialId: undefined, num: undefined}],
            transferOut: warehouseId,
          }}
        >
          <Form.Item label={'出仓站点'} labelCol={{span: 5}} className={styles.itemName}>
            <Input disabled placeholder={wareHouse?.name} />
          </Form.Item>
          <Form.Item label={'入仓站点'} labelCol={{span: 5}} className={styles.itemName} required>
            <Row gutter={10}>
              <Col flex={1}>
                <Form.Item noStyle name={'transferIn'} rules={[{required: true, message: '请输入站点名称'}]}>
                  <Select placeholder={'请输入站点名称'} allowClear>
                    {wareList?.map(item => (
                      <Option value={item.warehouseId} key={item.warehouseId}>
                        {item.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col>
                <Button
                  type={'link'}
                  onClick={async () => {
                    const {
                      response: {data},
                    } = await storageModel.stewardWarehouseInventory({pageable: false, warehouseId, numOrder: true})
                    form.setFieldsValue({materialList: data.filter(value => value.num)})
                    addMaterialList(data)
                  }}
                >
                  全部导入
                </Button>
              </Col>
            </Row>
          </Form.Item>
          <Form.Item label={'耗材'} required={true} shouldUpdate>
            <Form.List name='materialList'>
              {(fields, {add, remove}) => {
                return (
                  <div>
                    {fields.map((field, index) => (
                      <Space key={field.key} style={{display: 'flex'}} direction={'vertical'}>
                        <Space align='start'>
                          <Form.Item
                            name={[field.name, 'materialId']}
                            rules={[{required: true, message: '请输入耗材sku/名称'}]}
                            className={styles.itemSurplus}
                            shouldUpdate
                          >
                            <Select
                              placeholder='请输入耗材sku/名称并选择'
                              showSearch
                              onSearch={onMaterialSearch}
                              loading={materialLoading}
                              filterOption={false}
                              allowClear
                            >
                              {materialList?.map(item => (
                                <Option value={item.materialId} key={item.materialId}>
                                  {[item.name, item.specification, item.brand].filter(Boolean).join('/')}
                                </Option>
                              ))}
                            </Select>
                          </Form.Item>
                          <Form.Item
                            name={[field.name, 'num']}
                            rules={[{required: true, message: '请输入入仓数量'}]}
                            className={styles.itemSurplus}
                          >
                            <InputNumber style={{minWidth: 200}} placeholder='请输入入仓数量' min={1} />
                          </Form.Item>

                          {fields.length > 1 ? (
                            <>
                              <CloseCircleTwoTone
                                className={styles.icon}
                                twoToneColor='#A8A7AA'
                                onClick={() => {
                                  remove(field.name)
                                }}
                              />
                              {fields.length - index === 1 && (
                                <PlusCircleTwoTone
                                  className={styles.icon}
                                  twoToneColor='#00B7AE'
                                  onClick={() => {
                                    add()
                                  }}
                                />
                              )}
                            </>
                          ) : (
                            <PlusCircleTwoTone
                              className={styles.icon}
                              twoToneColor='#00B7AE'
                              onClick={() => {
                                add()
                              }}
                            />
                          )}
                        </Space>
                        <Space align='start'>
                          <Form.Item shouldUpdate label={'该仓库目前储量：'}>
                            {() => {
                              return (
                                <Typography.Text style={{color: '#00B7AE'}}>
                                  {
                                    materialList.find(
                                      item =>
                                        item.materialId ===
                                        form.getFieldValue(['materialList', field.name, 'materialId'])
                                    )?.num
                                  }
                                </Typography.Text>
                              )
                            }}
                          </Form.Item>
                        </Space>
                      </Space>
                    ))}
                  </div>
                )
              }}
            </Form.List>
          </Form.Item>
          <div className={styles.submit}>
            <Button type={'primary'} htmlType={'submit'} loading={submitLoading}>
              提交
            </Button>
            <span />
            <span />
          </div>
        </Form>
      </div>
    </Page>
  )
}
