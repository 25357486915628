import {useAppHistory, useAppLocation} from '@/common/hooks/useRouter'
import {Breadcrumb} from '@/components/Breadcrumb'
import {Page} from '@/components/Page'
import {Descriptions, PageHeader} from 'antd'
import React, {useEffect, useState} from 'react'
import commonStyles from '@/assets/styles/common.module.less'
import DividerLine from '@/pages/property/component/DividerLine'
import {ebassModel} from '@/store/models/Ebaas'
import {Album} from '@/components/Album'
import {arrayToObject} from '@/store/models/Common'
import ebaas from '@/store/models/Common/ebaasCodes'

const balconyNum = arrayToObject(ebaas.balconyNum)
const windowTypes = arrayToObject(ebaas.windowTypes)
const timeLimitMin = arrayToObject(ebaas.timeLimitMin)
const timeLimitMax = arrayToObject(ebaas.timeLimitMax)
const facilities = ebaas.facilities

export default function PropertyInfo() {
  const location = useAppLocation<'/promotion/property-info'>()
  const propertyCode = location.state?.propertyCode
  const templateId = location.state?.templateId
  const condoId = location.state?.condoId
  const [propertyInfo, setPropertyInfo] = useState({} as EbaasRoomVO)
  const [templateInfo, setTemplateInfo] = useState({} as EbaasLayoutVO)
  const [condoInfo, setCondoInfo] = useState({} as EbaasApartmentVO)
  const history = useAppHistory()

  useEffect(() => {
    ebassModel.ebaasRoomInfo({propertyCode}).then(({response: {data}}) => {
      setPropertyInfo(data)
    })

    ebassModel.ebaasLayoutInfo({templateId}).then(({response: {data}}) => {
      setTemplateInfo(data)
    })

    ebassModel.ebaasApartmentInfo({condoId}).then(({response: {data}}) => {
      setCondoInfo(data)
    })
  }, [propertyCode, templateId, condoId])

  return (
    <Page
      breadcrumb={<Breadcrumb style={{background: '#fff'}} />}
      header={<PageHeader title={'房源信息'} ghost={false} onBack={() => history.goBack()} />}
    >
      <div className={commonStyles.tableSection}>
        <DividerLine title='基础信息' style={{marginBottom: 10}} />
        <Descriptions column={2}>
          <Descriptions.Item label={'房型ID'}>{propertyInfo.templateId}</Descriptions.Item>
          <Descriptions.Item label={'房间ID'}>{propertyInfo.propertyCode}</Descriptions.Item>
          <Descriptions.Item label={'租金金额'}>{propertyInfo.price}元</Descriptions.Item>
          <Descriptions.Item label={'房源公寓管家'}>{condoInfo.userName ?? '/'}</Descriptions.Item>
          <Descriptions.Item label={'房间名'}>{propertyInfo.name}</Descriptions.Item>
          <Descriptions.Item label={'面积'}>{propertyInfo.area}㎡</Descriptions.Item>
          <Descriptions.Item label={'所在楼层'}>{propertyInfo.currentFloorNum}楼</Descriptions.Item>
          <Descriptions.Item label={'可入住时间'}>{propertyInfo.checkInTime ?? '/'}</Descriptions.Item>
          <Descriptions.Item label={'出租状态'}>{propertyInfo.openStatus === 1 ? '出租' : '未出租'}</Descriptions.Item>
          <Descriptions.Item label={'最短租期'}>{timeLimitMin[condoInfo.timeLimitMin] ?? '/'}</Descriptions.Item>
          <Descriptions.Item label={'最长租期'}>{timeLimitMax[condoInfo.timeLimitMax] ?? '/'}</Descriptions.Item>
        </Descriptions>
        <DividerLine title='房型模板' style={{marginTop: '10px', marginBottom: '20px'}} />
        <Descriptions column={2}>
          <Descriptions.Item label={'房型ID'}>{templateInfo.templateId}</Descriptions.Item>
          <Descriptions.Item label={'公寓ID'}>{templateInfo.condoId}</Descriptions.Item>
          <Descriptions.Item label={'房型名称'}>{templateInfo.name}</Descriptions.Item>
          <Descriptions.Item label={'客厅数量'}>
            {templateInfo.hallNum ? `${templateInfo.hallNum}` : '0'}厅
          </Descriptions.Item>
          <Descriptions.Item label={'居室数量'}>
            {templateInfo.roomNum ? `${templateInfo.roomNum}` : '0'}间
          </Descriptions.Item>
          <Descriptions.Item label={'厨房数量'}>
            {templateInfo.kitchenNum ? `${templateInfo.kitchenNum}` : '0'}间
          </Descriptions.Item>
          <Descriptions.Item label={'卫生间数量'}>
            {templateInfo.bathNum ? `${templateInfo.bathNum}` : '0'}间
          </Descriptions.Item>
          <Descriptions.Item label={'是否有阳台'}>{balconyNum[templateInfo.balconyNum] ?? '/'}</Descriptions.Item>
          <Descriptions.Item label={'窗户类型'}>{windowTypes[templateInfo.windowTypes]}</Descriptions.Item>
          <Descriptions.Item label={'房屋描述'}>{templateInfo.desc ?? '/'}</Descriptions.Item>
          <Descriptions.Item label={'房屋设施'}>
            {templateInfo.facilities
              ? facilities
                  .filter(item => templateInfo.facilities.split(',').includes(String(item.id)))
                  .map(item => item.label)
                  .join(',')
              : '/'}
          </Descriptions.Item>
        </Descriptions>
        <Descriptions column={1}>
          <Descriptions.Item label={'房型图片'}>
            {templateInfo.layoutImages?.length ? (
              <Album disabled fileList={templateInfo.layoutImages.map(item => ({url: item, uid: item}))}>
                {null}
              </Album>
            ) : (
              '/'
            )}
          </Descriptions.Item>
        </Descriptions>
      </div>
    </Page>
  )
}
