import React, {useEffect, useMemo, useState} from 'react'
import {Breadcrumb, Button, Divider, InputNumber, message, Table, Typography} from 'antd'
import {storageModel} from '@/store/models/Storage'
import {useHistory, useLocation} from 'react-router-dom'

const {Title} = Typography

interface CustomInventory extends StewardMaterialListVO {
  realityNum?: number
  excess?: number
}

export default function StorageCheck() {
  const history = useHistory()
  const location = useLocation<any>()
  const warehouseId = useMemo(() => location.state?.warehouseId, [location.state])

  const [inventoryList, setInventoryList] = useState<CustomInventory[]>([])
  const listLoading = storageModel.stewardWarehouseInventory.useLoading()
  const submitLoading = storageModel.stewardWarehouseCheck.useLoading()

  const columns = useMemo(
    () => [
      {title: '耗材名称', dataIndex: 'name', key: 'name'},
      {title: '资费价格(元)', dataIndex: 'price', key: 'price'},
      {title: '单位', dataIndex: 'unit', key: 'unit'},
      {title: '系统剩余数量', dataIndex: 'num', key: 'num'},
      {
        title: '实际剩余数量',
        dataIndex: 'realityNum',
        key: 'realityNum',
        render: (text, record, index) => (
          <InputNumber
            min={0}
            style={{minWidth: 150}}
            placeholder={'请输入剩余数量'}
            onChange={value => {
              setInventoryList(prevState =>
                prevState.map(item =>
                  item.materialId === record.materialId
                    ? {...item, realityNum: +value, excess: +value - item.num}
                    : item
                )
              )
            }}
          />
        ),
      },
      {title: '盈亏数', dataIndex: 'excess', key: 'excess'},
    ],
    []
  )

  useEffect(
    function () {
      storageModel
        .stewardWarehouseInventory({
          // name: '',
          pageable: true,
          numOrder: true,
          pageNum: 1,
          pageSize: 100,
          warehouseId,
        })
        .then(({response: {data, total}}) => {
          // console.log('inventory data =>', data)
          setInventoryList(data)
        })
    },
    [warehouseId]
  )

  async function onSubmit() {
    const checkList = inventoryList.map(item => ({
      materialId: item.materialId,
      num: item.realityNum,
      warehouseMaterialId: item.warehouseMaterialId,
    }))
    const params: StewardWarehouseCheckDTO = {
      checkList,
      warehouseId,
    }
    await storageModel.stewardWarehouseCheck(params)
    message.success('提交成功')
    history.goBack()
  }

  return (
    <div style={{margin: '20px 24px'}}>
      <Breadcrumb separator='/' style={{margin: '20px 10px 44px'}}>
        <Breadcrumb.Item>仓储管理</Breadcrumb.Item>
        <Breadcrumb.Item>盘点</Breadcrumb.Item>
      </Breadcrumb>

      <Title level={4}>{inventoryList[0]?.warehouseName} 仓库盘点</Title>
      <Divider />
      <Table
        rowKey={'materialId'}
        loading={listLoading}
        columns={columns}
        dataSource={inventoryList}
        pagination={false}
      />

      <div style={{display: 'flex', justifyContent: 'center', marginTop: 25}}>
        <Button type={'primary'} onClick={onSubmit} loading={submitLoading}>
          提交
        </Button>
      </div>
    </div>
  )
}
