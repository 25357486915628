import {getImg} from '@/utils/util'
import {Modal} from 'antd'
import React from 'react'

interface ImageModalProps {
  visible: boolean
  imgUrl: string
  onCancel: () => void
}
export default function ImageModal({visible, imgUrl, onCancel}: ImageModalProps) {
  return (
    <Modal centered visible={visible} footer={null} onCancel={onCancel}>
      <img alt={'图片预览'} src={getImg(imgUrl)} width='100%' />
    </Modal>
  )
}
