import React, {useCallback, useEffect, useMemo, useState} from 'react'
import commonStyle from '@/assets/styles/common.module.less'
import {Breadcrumb} from '@/components/Breadcrumb'
import {Button, Form, Input, Row, Col, Select, DatePicker, Space, Table, Typography} from 'antd'
import {sysUserModel} from '@/store/models/SysUser'
import {downloadBlob, getImg} from '@/utils/util'
import {usePermission} from '@/components/Permission'
import {Page} from '@/components/Page'
import {getPaginationProps} from '@/common/biz/getPaginationProps'
import {customerModel} from '@/store/models/Customer'
import moment from 'moment/moment'
import {FORMAT_DATE_END, FORMAT_DATE_START} from '@/common/date'
import {useAppLocation} from '@/common/hooks/useRouter'
import {PRIVATE_CODE} from '@/common/constants'

const {Option} = Select
const {RangePicker} = DatePicker
export default function CallManage() {
  const location = useAppLocation<'/call-manage'>()
  const orderId = location.state?.orderId
  const [list, setList] = useState([] as PrivateNumberFeeVO[])
  const [total, setTotal] = useState(0)
  const [form] = Form.useForm()
  const loading = sysUserModel.privateNumberFee.useLoading()
  const [auth] = usePermission()

  useEffect(() => {
    if (orderId) {
      form.setFieldsValue({nyg8OrderId: orderId})
      location.state = {}
    }
  }, [form, location, orderId])

  const search = useCallback(() => {
    const {_time, ...values} = form.getFieldsValue(true)
    const [nyg8CallInTimeBegin, nyg8CallInTimeEnd] = _time
    sysUserModel
      .privateNumberFee({...values, nyg8CallInTimeBegin, nyg8CallInTimeEnd})
      .then(({response: {data, total}}) => {
        setList(data)
        setTotal(total)
      })
  }, [form])

  useEffect(() => {
    form.submit()
  }, [form])

  const [customerList, setCustomerList] = useState<LessorCustomerListVO[]>([])
  const customerOptions = useMemo(
    () =>
      customerList.map(value => ({
        label: (
          <Space>
            <span>{value.certName}</span>
            <span>-</span>
            <span>{value.mobile}</span>
          </Space>
        ),
        value: value.mobile,
        cert_name: value.certName, // key 不能用驼峰命名法，不然 react 会报错
      })),
    [customerList]
  )
  useEffect(() => {
    customerModel
      .customerList({pageable: false} as LessorCustomerListDTO)
      .then(({response: {data}}) => setCustomerList(data))
  }, [])

  const toMinute = (data: number) => {
    const minutes = Math.floor((data % (1000 * 60 * 60)) / (1000 * 60))
    const seconds = (data % (1000 * 60)) / 1000
    return `${minutes}分${seconds}秒`
  }
  return (
    <Page
      breadcrumb={
        <Breadcrumb
          right={
            auth('007054001') && (
              <Button
                onClick={async () => {
                  await form.validateFields()
                  const {_time, ...values} = form.getFieldsValue(true)
                  const [nyg8CallInTimeBegin, nyg8CallInTimeEnd] = _time
                  downloadBlob({
                    url: '/private-number/fee-excel',
                    body: {...values, nyg8CallInTimeBegin, nyg8CallInTimeEnd, pageable: false},
                  })
                }}
              >
                导出
              </Button>
            )
          }
        />
      }
    >
      <div className={commonStyle.filterSection}>
        <Form
          form={form}
          initialValues={{
            pageNum: 1,
            pageSize: 10,
            pageable: true,
            _time: [moment().subtract(31, 'day').format(FORMAT_DATE_START), moment().format(FORMAT_DATE_END)],
          }}
          onFinish={search}
          onReset={search}
        >
          <Row gutter={20}>
            <Col span={8}>
              <Form.Item label={'管家'} name={'nyg8UserName'}>
                <Input placeholder={'请输入用户名称'} allowClear />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={'虚拟号码'} name={'privateNum'}>
                <Input placeholder={'请输入虚拟号码'} allowClear addonBefore={'+86'} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={'是否有录音'} name={'recordFlag'}>
                <Select placeholder={'请选择'} allowClear>
                  <Option value={'0'}>未录音</Option>
                  <Option value={'1'}>已录音</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={'通话类型'} name={'direction'}>
                <Select placeholder={'请选择'} allowClear>
                  <Option value={'0'}>呼入</Option>
                  <Option value={'1'}>呼出</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label={'呼叫时间'}
                name={'_time'}
                getValueProps={value => ({value: value ? value.map(item => moment(item)) : []})}
                getValueFromEvent={(_, dateStrings) => {
                  const [nyg8CallInTimeBegin, nyg8CallInTimeEnd] = dateStrings
                  if (nyg8CallInTimeBegin && nyg8CallInTimeEnd) {
                    return [
                      moment(nyg8CallInTimeBegin).format(FORMAT_DATE_START),
                      moment(nyg8CallInTimeEnd).format(FORMAT_DATE_END),
                    ]
                  }
                  return undefined
                }}
                rules={[
                  {
                    validator: (rule, value, callback) => {
                      if (value) {
                        const [nyg8CallInTimeBegin, nyg8CallInTimeEnd] = value
                        const intervalDay = moment(nyg8CallInTimeEnd).diff(nyg8CallInTimeBegin, 'day')
                        if (intervalDay > 31) return callback('呼叫时间间隔不超过31天')
                      } else {
                        return callback('呼叫时间不能为空')
                      }
                      return callback()
                    },
                  },
                ]}
              >
                <RangePicker />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={'主叫号码'} name={'callerNum'}>
                <Input placeholder={'请输入'} allowClear addonBefore={'+86'} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={'被叫号码'} name={'fwdDstNum'}>
                <Input placeholder={'请输入'} allowClear addonBefore={'+86'} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={'客户名称'} name={'mobile'}>
                <Select
                  placeholder={'请选择'}
                  allowClear
                  showSearch
                  filterOption={(inputValue, option) => (option.cert_name as string)?.includes?.(inputValue as any)}
                  options={customerOptions}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={'服务单号'} name={'nyg8OrderId'}>
                <Input placeholder={'请输入'} allowClear />
              </Form.Item>
            </Col>
          </Row>
          <Row justify={'end'}>
            <Space>
              <Button type={'primary'} htmlType={'submit'} onClick={() => form.setFieldsValue({pageNum: 1})}>
                查询
              </Button>
              <Button htmlType={'reset'}>重置</Button>
            </Space>
          </Row>
        </Form>
      </div>
      <div className={commonStyle.tableSection}>
        <Table
          rowKey={'id'}
          loading={loading}
          dataSource={list}
          scroll={{x: 'max-content'}}
          columns={[
            {title: '管家', dataIndex: 'nyg8UserName'},
            {
              title: '通话类型',
              dataIndex: 'direction',
              render: text => <Typography.Text>{text === 0 ? '呼入' : '呼出'}</Typography.Text>,
            },
            {title: '服务单号', dataIndex: 'nyg8OrderId'},
            {title: '虚拟号码', dataIndex: 'bindNum'},
            {
              title: '呼叫时间',
              dataIndex: 'nyg8FwdAnswerTime',
              render: (text, record) => (
                <Space direction={'vertical'} size={5}>
                  <Typography.Text>{`呼叫发起时间：${record.nyg8CallInTime ?? '/'}`}</Typography.Text>
                  <Typography.Text>{`呼叫振铃时间：${record.nyg8FwdAlertingTime ?? '/'}`}</Typography.Text>
                  <Typography.Text>{`呼叫接通时间：${text ?? '/'}`}</Typography.Text>
                  <Typography.Text>{`呼叫挂断时间：${record.nyg8CallEndTime ?? '/'}`}</Typography.Text>
                </Space>
              ),
            },
            {
              title: '呼叫号码',
              dataIndex: 'callerNum',
              render: (text, record) => {
                return (
                  <Space size={5} direction={'vertical'}>
                    <Typography.Text>{`主叫号码：${text ?? '/'}${
                      record.nyg8CallerSource && `（${record.nyg8CallerSource}）`
                    }`}</Typography.Text>
                    <Typography.Text>{`被叫号码：${record.fwdDstNum ?? '/'}`}</Typography.Text>
                    <Typography.Text>{`呼入的显示号码：${record.fwdDisplayNum ?? '/'}`}</Typography.Text>
                  </Space>
                )
              },
            },
            {
              title: '通话时间',
              dataIndex: 'nyg8CallDuring',
              render: text => (text ? toMinute(text) : '/'),
            },
            {
              title: '是否录音',
              dataIndex: 'recordFlag',
              render: text => (text === 0 ? '未录音' : '已录音'),
            },
            {
              title: '挂机描述',
              dataIndex: 'ulFailReason',
              render: text => PRIVATE_CODE[text],
            },
            {
              title: '录音文件操作',
              dataIndex: 'nyg8VoiceUrl',
              fixed: 'right',
              render: text =>
                text
                  ? auth('001054002') && (
                      <a href={getImg(text)} download={'录音文件.wav'} target={'_blank'} rel={'noreferrer'}>
                        查看
                      </a>
                    )
                  : '/',
            },
          ]}
          pagination={getPaginationProps(form, total)}
        />
      </div>
    </Page>
  )
}
