import React from 'react'
import ReactDOM from 'react-dom'

import './index.less'
import './common/lib/echarts'

import {Provider} from 'react-redux'
import {PersistGate} from '@redux-model/web'
import {ConfigProvider} from 'antd'
import zhCN from 'antd/es/locale/zh_CN'
import moment from 'moment'

import App from './pages/App'
import * as serviceWorker from './serviceWorker'
import {store} from '@/store'

import 'moment/locale/zh-cn'
import './setup'
import AuthGate from '@/AuthGate'

moment.locale('zh-cn')

ReactDOM.render(
  <ConfigProvider locale={zhCN}>
    <Provider store={store}>
      <PersistGate>
        <AuthGate>
          <App />
        </AuthGate>
      </PersistGate>
    </Provider>
  </ConfigProvider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
