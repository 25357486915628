import React, {useCallback, useEffect} from 'react'
import {Button, Checkbox, Form, Input, InputNumber, message, PageHeader, Select, Space, Upload} from 'antd'
import commonStyles from '@/assets/styles/common.module.less'
import {Page} from '@/components/Page'
import {Album, UploadButton} from '@/components/Album'
import {ebassModel} from '@/store/models/Ebaas'
import {useAppHistory, useAppLocation} from '@/common/hooks/useRouter'
import ebaas from '@/store/models/Common/ebaasCodes'
import {baseUrl} from '@/common/config'
import {isFile, isUploaded} from '@/utils/util'

const facilitiesArr = ebaas.facilities.map(item => ({label: item.label, value: `${item.id}`}))

export default function StorePorpertyTemplate() {
  const location = useAppLocation<'/store-manage/property-template'>()
  const templateId = location.state?.templateId
  const condoId = location.state?.condoId
  const history = useAppHistory()
  const [form] = Form.useForm()

  useEffect(() => {
    if (templateId) {
      ebassModel.ebaasLayoutInfo({templateId}).then(({response: {data}}) => {
        form.setFieldsValue({
          balconyNum: data.balconyNum,
          bathNum: data.bathNum,
          condoId: data.condoId,
          condoName: data.condoName,
          desc: data.desc,
          facilities: data.facilities,
          hallNum: data.hallNum,
          isOpenKitchen: data.isOpenKitchen,
          kitchenNum: data.kitchenNum,
          layoutId: data.layoutId,
          layoutImages: data.layoutImages,
          name: data.name,
          roomNum: data.roomNum,
          templateId: data.templateId,
          windowTypes: data.windowTypes,
        })
      })
    }
    if (condoId) {
      form.setFieldsValue({condoId})
    }
  }, [condoId, form, templateId])

  const upsertLoading = ebassModel.ebaasLayoutUpsert.useLoading()

  const onFinish = useCallback(async () => {
    const formValues = form.getFieldsValue(true)
    const layoutImages = formValues.layoutImages
    if (layoutImages?.some(item => typeof item === 'object')) return message.warning('图片正在上传中!')
    await ebassModel.ebaasLayoutUpsert(formValues)
    message.success('操作成功！')
    history.goBack()
  }, [form, history])

  return (
    <Page
      header={
        <PageHeader
          onBack={() => history.goBack()}
          title={templateId ? '编辑房型模板' : '添加房型模板'}
          ghost={false}
        />
      }
    >
      <div className={commonStyles.content} style={{backgroundColor: '#fff', paddingTop: 20}}>
        <Form
          form={form}
          labelAlign={'right'}
          labelCol={{flex: '120px', offset: 2}}
          style={{width: '70%'}}
          onFinish={onFinish}
        >
          {!!templateId && (
            <>
              <Form.Item label={'公寓ID'} shouldUpdate>
                {form1 => <span>{form1.getFieldValue('condoId')}</span>}
              </Form.Item>
              <Form.Item label={'房型ID'} shouldUpdate>
                {form1 => <span>{form1.getFieldValue('layoutId')}</span>}
              </Form.Item>
            </>
          )}
          <Form.Item label={'房型名称'} name={'name'} rules={[{required: true, message: '请输入模板名称'}]}>
            <Input placeholder={'请输入'} maxLength={20} />
          </Form.Item>
          <Form.Item label={'户型'} required>
            <Space>
              <Space>
                <Form.Item
                  noStyle
                  name={'roomNum'}
                  rules={[
                    {required: true, message: '请输入室'},
                    {type: 'number', min: 0, max: 8, message: '室的范围在0-8之间'},
                  ]}
                >
                  <InputNumber placeholder={'请输入'} />
                </Form.Item>
                室
              </Space>
              <Space>
                <Form.Item
                  noStyle
                  name={'hallNum'}
                  rules={[
                    {required: true, message: '请输入厅'},
                    {type: 'number', min: 0, max: 5, message: '厅的范围在0-5之间'},
                  ]}
                >
                  <InputNumber placeholder={'请输入'} />
                </Form.Item>
                厅
              </Space>
              <Space>
                <Form.Item
                  noStyle
                  name={'bathNum'}
                  rules={[
                    {required: true, message: '请输入卫'},
                    {type: 'number', min: 0, max: 5, message: '卫的范围在0-5之间'},
                  ]}
                >
                  <InputNumber placeholder={'请输入'} />
                </Form.Item>
                卫
              </Space>
              <Space>
                <Form.Item
                  noStyle
                  name={'kitchenNum'}
                  rules={[
                    {required: true, message: '请输入厨'},
                    {type: 'number', min: 0, max: 5, message: '厨的范围在0-5之间'},
                  ]}
                >
                  <InputNumber placeholder={'请输入'} />
                </Form.Item>
                厨
              </Space>
            </Space>
          </Form.Item>

          <Form.Item label={'阳台'} name={'balconyNum'} rules={[{required: true, message: '请选择阳台'}]}>
            <Select placeholder={'请选择'} allowClear>
              {ebaas.balconyNum.map(item => (
                <Select.Option key={item.id} value={item.id}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label={'窗户类型'} name={'windowTypes'} rules={[{required: true, message: '请选择窗户类型'}]}>
            <Select placeholder={'请选择'} allowClear>
              {ebaas.windowTypes.map(item => (
                <Select.Option key={item.id} value={item.id}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label={'开放厨房'} name={'isOpenKitchen'}>
            <Select placeholder={'请选择'} allowClear>
              {ebaas.isOpenKitchen.map(item => (
                <Select.Option key={item.id} value={item.id}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label={'房屋描述'}
            name={'desc'}
            rules={[
              {required: true, message: '请输入房屋描述'},
              {min: 10, max: 500, message: '房屋描述字数范围在10-500之间'},
            ]}
          >
            <Input.TextArea placeholder={'请输入'} />
          </Form.Item>
          <Form.Item
            label={'房屋设施'}
            name={'facilities'}
            rules={[{required: true, message: '请选择房屋设施'}]}
            getValueFromEvent={event => event?.join(',')}
            getValueProps={value => ({value: value?.split(',')})}
          >
            <Checkbox.Group options={facilitiesArr} />
          </Form.Item>
          <Form.Item
            label={'房型图片'}
            required
            shouldUpdate={(prevValues, nextValues) => prevValues.layoutImages !== nextValues.layoutImages}
          >
            {form1 => {
              const layoutImages = form1.getFieldValue('layoutImages') ?? []
              return (
                <Form.Item
                  name={'layoutImages'}
                  rules={[
                    {required: true, message: '图片不能为空'},
                    {
                      validator: (_, value) => {
                        if (value?.length < 3) return Promise.reject(new Error('图片不能少于3张'))
                        return Promise.resolve()
                      },
                    },
                  ]}
                  getValueFromEvent={event => {
                    return event.fileList?.reduce((prev, item) => {
                      if (isFile(item) && item.status === 'done') {
                        if (item.response?.code === 200) {
                          prev.push(item.response?.data?.imgUrl)
                        } else {
                          // 未上传成功丢掉该项目
                          message.error(item.response?.msg)
                        }
                        return prev
                      }
                      // 已上传的文件取 uid
                      if (isUploaded(item)) return [...prev, item.uid]

                      // file 对象原封不动
                      return [...prev, item]
                    }, [])
                  }}
                  getValueProps={value => ({
                    fileList: value?.map(item => (typeof item === 'string' ? {uid: item, url: item} : item)),
                  })}
                  noStyle
                >
                  <Album
                    limit={30}
                    action={baseUrl + '/ebaas/common/upload'}
                    accept={'.jpg, .jpeg, .png'}
                    beforeUpload={file => {
                      if (file.size > 1024 * 1024 * 2) {
                        message.warn('文件大小不能超过2M')
                        return Upload.LIST_IGNORE
                      }
                      return true
                    }}
                  >
                    {layoutImages.length > 30 ? null : <UploadButton />}
                  </Album>
                </Form.Item>
              )
            }}
          </Form.Item>
          <Form.Item label={' '} colon={false}>
            <Space>
              <Button type={'primary'} htmlType={'submit'} loading={upsertLoading}>
                提交
              </Button>
              <Button onClick={() => history.goBack()}>取消</Button>
            </Space>
          </Form.Item>
        </Form>
      </div>
    </Page>
  )
}
