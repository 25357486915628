import React, {useCallback} from 'react'
import {Form, Input, message, Modal, Select} from 'antd'
import {cleanModel} from '@/store/models/Clean'
import {cleanServiceArr} from '@/utils/clean'
import {commonModel} from '@/store/models/Common'

export function useCleanOrderCheck() {
  const [form] = Form.useForm()

  const queryActivityRemarkGet = useCallback(
    customerId => {
      commonModel.commonAppCustomerActivityRemarkGet({userId: customerId}).then(({response: {data}}) => {
        form.setFieldsValue({activityRemark: data})
      })
    },
    [form]
  )

  return useCallback(
    ({
      orderId,
      serviceId,
      customerId,
      onSuccess,
    }: {
      orderId: string
      serviceId?: string
      customerId?: string
      onSuccess?: () => void
    }) => {
      if (customerId) queryActivityRemarkGet(customerId)

      form.setFieldsValue({serviceId})

      Modal.confirm({
        title: '确认',
        icon: null,
        closable: true,
        centered: true,
        okText: '提交',
        onOk: async () => {
          await form.validateFields()
          form.submit()
        },
        afterClose: () => form.resetFields(),
        content: (
          <Form
            form={form}
            onFinish={async () => {
              const formValues = form.getFieldsValue(true)
              await cleanModel.cleanOrderCheck({...formValues, orderId})
              message.success('确认成功！')
              onSuccess?.()
            }}
            style={{marginTop: '20px'}}
            labelAlign={'right'}
            labelCol={{span: 8}}
          >
            <Form.Item noStyle shouldUpdate>
              {form1 => {
                const activityRemark = form1.getFieldValue('activityRemark')
                if (activityRemark) {
                  return (
                    <Form.Item label={'活动充值'} name={'activityRemark'}>
                      <Input.TextArea readOnly bordered={false} style={{color: '#FF5454'}} maxLength={500} rows={5} />
                    </Form.Item>
                  )
                }
                return null
              }}
            </Form.Item>
            <Form.Item label={'服务项目'} name={'serviceId'} rules={[{required: true}]}>
              <Select placeholder={'请选择'}>
                {cleanServiceArr
                  .filter(item => item.label !== '周期保洁')
                  .map(item => (
                    <Select.Option value={item.id} key={item.id}>
                      {item.label}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
            <Form.Item label={'户型'} name={'checkHouseType'} rules={[{required: true, message: '请输入户型'}]}>
              <Input placeholder={'请输入'} allowClear />
            </Form.Item>
            <Form.Item
              label={'客户特殊需求'}
              name={'checkSpecialNotes'}
              rules={[{required: true, message: '请输入客户特殊需求'}]}
            >
              <Input.TextArea placeholder={'请输入'} allowClear maxLength={200} showCount />
            </Form.Item>
          </Form>
        ),
      })
    },
    [form, queryActivityRemarkGet]
  )
}
