import React, {useCallback, useEffect, useState} from 'react'
import {Page} from '@/components/Page'
import {
  Form,
  Tabs,
  Row,
  Col,
  Input,
  Select,
  Space,
  Button,
  Table,
  Drawer,
  Descriptions,
  Modal,
  Radio,
  message,
  DatePicker,
} from 'antd'
import commonStyles from '@/assets/styles/common.module.less'
import {useCodeArraySync, useCodeSync} from '@/store/models/Common'
import {getPaginationProps} from '@/common/biz/getPaginationProps'
import {stewardModel} from '@/store/models/Steward'
import DividerLine from '@/pages/property/component/DividerLine'
import {DetailSectionImage} from '@/pages/steward/StewardDetail'
import {getImg} from '@/utils/util'
import {useAppHistory} from '@/common/hooks/useRouter'

const handleResultTypeCode = {'1': '已完成服务', '2': '无需服务', '3': '无法完成服务'}

export default function ServiceRemoteApply() {
  const history = useAppHistory()
  const remoteHelpResultArr = useCodeArraySync('steward.order.remoteHelp.result')
  const remoteHelpResultCode = useCodeSync('steward.order.remoteHelp.result')

  const [form] = Form.useForm()
  const [total, setTotal] = useState(0)
  const [helpList, setHelpList] = useState<StewardOrderRemoteHelpAuditVO[]>([])

  const [activeKey, setActiveKey] = useState('1')

  const [visible, setVisible] = useState(false)
  const [auditDetail, setAuditDetail] = useState({} as StewardOrderRemoteHelpAuditVO)

  // 选中的图片
  const [selectImg, setSelectImg] = useState<{imgUrl: string; visible: boolean}>({imgUrl: '', visible: false})

  const [checkForm] = Form.useForm()

  const queryRemoteHelpList = useCallback(() => {
    const formValues = form.getFieldsValue(true)
    stewardModel
      .stewardOrderRemoteHelpList({...formValues, auditStatus: activeKey})
      .then(({response: {data, total}}) => {
        setTotal(total)
        setHelpList(data)
      })
  }, [activeKey, form])

  useEffect(() => {
    queryRemoteHelpList()
  }, [queryRemoteHelpList])

  const queryRemoteHelpGet = useCallback(async auditId => {
    const {
      response: {data},
    } = await stewardModel.stewardOrderRemoteHelpGet({auditId})
    setAuditDetail(data)
  }, [])

  const listLoading = stewardModel.stewardOrderRemoteHelpList.useLoading()

  return (
    <Page>
      <Tabs className={commonStyles.tabs} onChange={key => setActiveKey(key)}>
        <Tabs.TabPane tab={'待处理'} key={'1'} />
        <Tabs.TabPane tab={'已处理'} key={'2'} />
      </Tabs>
      <div className={commonStyles.filterSection}>
        <Form
          form={form}
          labelCol={{span: '80px'}}
          labelAlign={'left'}
          initialValues={{pageNum: 1, pageSize: 10, pageable: true}}
          onFinish={queryRemoteHelpList}
          onReset={queryRemoteHelpList}
        >
          <Row gutter={40}>
            <Col span={8}>
              <Form.Item label={'服务人员'} name={'serverName'}>
                <Input placeholder={'请输入'} allowClear />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={'下单人'} name={'orderMakerName'}>
                <Input placeholder={'请输入'} allowClear />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={'服务联系手机号'} name={'contactMobile'}>
                <Input placeholder={'请输入'} allowClear />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item label={'服务单ID'} name={'orderId'}>
                <Input placeholder={'请输入'} allowClear />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={'维修项目'} name={'categoryName'}>
                <Input placeholder={'请输入'} allowClear />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={'房源'} name={'propertyAddr'}>
                <Input placeholder={'请输入'} allowClear />
              </Form.Item>
            </Col>
            <Col span={8}>
              {activeKey === '1' ? (
                <Form.Item label={'申请时间'} name={'insertTime'}>
                  <DatePicker.RangePicker
                    allowClear
                    format={'YYYY-MM-DD'}
                    onChange={(_, dateString) => {
                      form.setFieldsValue({
                        insertTimeBegin: dateString[0] ? dateString[0] + ' 00:00:00' : '',
                        insertTimeEnd: dateString[1] ? dateString[1] + ' 23:59:59' : '',
                      })
                    }}
                  />
                </Form.Item>
              ) : (
                <Form.Item label={'审核结果'} name={'auditResultStatus'}>
                  <Select placeholder={'请选择'} allowClear>
                    {remoteHelpResultArr.map(item => (
                      <Select.Option key={item.id} value={item.id}>
                        {item.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              )}
            </Col>
          </Row>
          <Row justify={'end'}>
            <Space>
              <Button type={'primary'} htmlType={'submit'} onClick={() => form.setFieldsValue({pageNum: 1})}>
                查询
              </Button>
              <Button htmlType={'reset'}>重置</Button>
            </Space>
          </Row>
        </Form>
      </div>

      <div className={commonStyles.tableSection}>
        <Table
          rowKey={'auditId'}
          loading={listLoading}
          dataSource={helpList}
          columns={[
            {title: '申请编号', dataIndex: 'auditId'},
            {
              title: '服务单ID',
              dataIndex: 'orderId',
              render: value => (
                <Button
                  type={'link'}
                  style={{padding: 0}}
                  onClick={() => history.push('/steward/detail', {orderId: value})}
                >
                  <span style={{textDecoration: 'underline', color: 'rgba(0, 0, 0, 0.65)'}}>{value}</span>
                </Button>
              ),
            },
            {title: '下单人', dataIndex: 'orderMakerName'},
            {title: '服务联系手机号', dataIndex: 'contactMobile'},
            {title: '维修项目', dataIndex: 'categoryName'},
            {title: '服务人员', dataIndex: 'serverName'},
            ...(activeKey === '1'
              ? [{title: '申请时间', dataIndex: 'insertTime'}]
              : [
                  {title: '审核结果', dataIndex: 'auditResultStatus', render: value => remoteHelpResultCode[value]},
                  {title: '审核时间', dataIndex: 'auditTime'},
                ]),
            {title: '房源', dataIndex: 'propertyAddr'},
            {
              title: '操作',
              render: record => (
                <Space>
                  <Button
                    type={'link'}
                    style={{padding: 0}}
                    onClick={async () => {
                      await queryRemoteHelpGet(record.auditId)
                      setVisible(true)
                    }}
                  >
                    查看
                  </Button>
                </Space>
              ),
            },
          ]}
          scroll={{x: 'max-content'}}
          pagination={getPaginationProps(form, total)}
        />
      </div>

      <Drawer
        visible={visible}
        width={700}
        title={'服务详情'}
        onClose={() => setVisible(false)}
        // afterVisibleChange={visible => {
        //   console.log('visible', visible)
        // }}
      >
        <Descriptions
          title={
            <DividerLine
              title={'基本信息'}
              right={
                auditDetail.auditStatus === '1' && (
                  <Button
                    type={'link'}
                    onClick={() => {
                      Modal.confirm({
                        title: '审核',
                        icon: null,
                        centered: true,
                        closable: true,
                        content: (
                          <Form
                            form={checkForm}
                            onFinish={async () => {
                              const formValues = checkForm.getFieldsValue(true)
                              // console.log('formValues', formValues)
                              const auditId = auditDetail.auditId
                              await stewardModel.stewardOrderRemoteHelpHandle({...formValues, auditId})
                              message.success('处理成功！')
                              queryRemoteHelpGet(auditId)
                              form.setFieldsValue({pageNum: 1})
                              queryRemoteHelpList()
                            }}
                            initialValues={{auditResultStatus: '1'}}
                          >
                            <Form.Item label={'审核结果'} name={'auditResultStatus'}>
                              <Radio.Group>
                                <Radio value={'1'}>通过</Radio>
                                <Radio value={'2'}>驳回</Radio>
                              </Radio.Group>
                            </Form.Item>
                            <Form.Item
                              shouldUpdate={(prevValues, nextValues) =>
                                prevValues.auditResultStatus !== nextValues.auditResultStatus
                              }
                            >
                              {form1 => {
                                const auditResultStatus = form1.getFieldValue('auditResultStatus')
                                return (
                                  <Form.Item
                                    label={'审核备注'}
                                    name={'auditorRemark'}
                                    rules={[{required: auditResultStatus === '2', message: '请输入备注'}]}
                                  >
                                    <Input.TextArea allowClear maxLength={200} placeholder={'请输入备注'} />
                                  </Form.Item>
                                )
                              }}
                            </Form.Item>
                          </Form>
                        ),
                        onOk: async () => {
                          await checkForm.validateFields()
                          checkForm.submit()
                        },
                        afterClose: () => checkForm.resetFields(['auditResultStatus', 'auditorRemark']),
                      })
                    }}
                  >
                    审核
                  </Button>
                )
              }
            />
          }
          column={2}
        >
          <Descriptions.Item label={'服务单ID'}>{auditDetail.orderId}</Descriptions.Item>
          <Descriptions.Item label={'下单人'}>{auditDetail.orderMakerName}</Descriptions.Item>
          <Descriptions.Item label={'下单时间'}>{auditDetail.timeMake}</Descriptions.Item>
          <Descriptions.Item label={'服务联系手机号'}>{auditDetail.contactMobile}</Descriptions.Item>
          <Descriptions.Item label={'维修项目'}>{auditDetail.categoryName}</Descriptions.Item>
          <Descriptions.Item label={'服务人员'}>{auditDetail.serverName}</Descriptions.Item>
          <Descriptions.Item label={'申请时间'}>{auditDetail.insertTime}</Descriptions.Item>
          <Descriptions.Item label={'房源'}>{auditDetail.propertyAddr}</Descriptions.Item>
        </Descriptions>
        <Descriptions title={<DividerLine title={'处理信息'} />} column={1} style={{marginTop: '30px'}}>
          <Descriptions.Item label={'处理前照片/视频'}>
            <DetailSectionImage
              imgList={auditDetail.imgFrontList}
              onClickVideo={img => setSelectImg({imgUrl: img.imgUrl, visible: true})}
            />
          </Descriptions.Item>
          <Descriptions.Item label={'处理后照片/视频'}>
            <DetailSectionImage
              imgList={auditDetail.imgBackList}
              onClickVideo={img => setSelectImg({imgUrl: img.imgUrl, visible: true})}
            />
          </Descriptions.Item>
          <Descriptions.Item label={'处理结果'}>{handleResultTypeCode[auditDetail.handleResultType]}</Descriptions.Item>
          <Descriptions.Item label={'处理备注'}>{auditDetail.handleResult}</Descriptions.Item>
        </Descriptions>
        {auditDetail.auditStatus === '2' && (
          <Descriptions title={<DividerLine title={'审核信息'} />} column={2} style={{marginTop: '30px'}}>
            <Descriptions.Item label={'审核结果'}>
              {remoteHelpResultCode[auditDetail.auditResultStatus]}
            </Descriptions.Item>
            <Descriptions.Item label={'审核备注'}>{auditDetail.auditorRemark}</Descriptions.Item>
            <Descriptions.Item label={'审核人'}>{auditDetail.auditorName}</Descriptions.Item>
            <Descriptions.Item label={'审核时间'}>{auditDetail.auditTime}</Descriptions.Item>
          </Descriptions>
        )}

        <Modal
          centered
          destroyOnClose
          visible={selectImg.visible}
          title={'视频预览'}
          footer={null}
          onCancel={() => setSelectImg(state => ({...state, visible: false}))}
        >
          <video controls src={getImg(selectImg.imgUrl)} width='100%' />
        </Modal>
      </Drawer>
    </Page>
  )
}
