import React, {useCallback, useEffect, useState} from 'react'
import {Button, Col, List, Menu, message, Radio, Row, Space} from 'antd'
import {Page} from '@/components/Page'
import {msgModel} from '@/store/models/Msg'
import * as listener from '@/common/hooks/listener'

export default function MessageList() {
  const [categoryCode, setCategoryCode] = React.useState<number>()
  const [msgListCategory, setMsgListCategory] = React.useState<SysMsgVO[]>([])

  // 消息分类
  const queryMsgListCategory = useCallback(() => {
    msgModel.commonAppSysMsgListCategory().then(({response: {data}}) => {
      setMsgListCategory(data)
    })
  }, [])

  useEffect(() => {
    queryMsgListCategory()
  }, [queryMsgListCategory])

  useEffect(() => {
    if (msgListCategory.length && !categoryCode) setCategoryCode(msgListCategory[0].categoryCode)
  }, [categoryCode, msgListCategory])

  // 消息列表
  const [pageNum, setPageNum] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(10)
  const [readStatus, setReadStatus] = useState<0 | 1>(0)

  const [msgTotal, setMsgTotal] = useState<number>(0)
  const [msgList, setMsgList] = useState<SysMsgVO[]>([])

  const queryMsgList = useCallback((args: Omit<SysMsgListQueryDTO, 'pageable' | 'counter'>) => {
    msgModel.commonAppSysMsgListMsg({...args, pageable: true, counter: true}).then(({response: {data, total}}) => {
      setMsgList(data)
      setMsgTotal(total)
    })
  }, [])

  useEffect(() => {
    if (categoryCode) {
      queryMsgList({categoryCode, pageNum, pageSize, read: readStatus})
    }
  }, [categoryCode, pageNum, pageSize, queryMsgList, readStatus])

  // 标记已读
  const setMsgRead = useCallback(async ({categoryCode, msgId}: {categoryCode: number; msgId?: string}) => {
    await msgModel.commonAppSysMsgSetCategoryRead({categoryCode, msgId})
    listener.emit('MessageList:SetCategoryRead')
  }, [])

  return (
    <Page>
      <Row gutter={10}>
        <Col flex={'200px'}>
          <Menu
            items={msgListCategory.map(item => ({
              label: `${item.categoryName}（${item.unreadNum}）`,
              key: item.categoryCode,
              onClick: () => {
                setCategoryCode(item.categoryCode)
                setPageNum(1)
                setPageSize(10)
                setReadStatus(0)
              },
            }))}
            mode={'inline'}
            activeKey={`${categoryCode}`}
            selectedKeys={[`${categoryCode}`]}
          />
        </Col>
        <Col flex={1}>
          <Space>
            <Radio.Group
              value={readStatus}
              size={'middle'}
              buttonStyle='solid'
              onChange={({target: {value}}) => setReadStatus(value)}
            >
              <Radio.Button value={0}>未读</Radio.Button>
              <Radio.Button value={1}>已读</Radio.Button>
            </Radio.Group>

            <Button
              type={'link'}
              size={'large'}
              style={{marginLeft: 26}}
              onClick={async () => {
                await setMsgRead({categoryCode})
                message.success('全部已读成功！')
                queryMsgListCategory()
                queryMsgList({categoryCode, pageNum, pageSize, read: readStatus})
              }}
            >
              全部已读
            </Button>
          </Space>

          <List
            bordered
            dataSource={msgList}
            renderItem={item => (
              <List.Item
                actions={[
                  item.read === 0 ? (
                    <div style={{minWidth: 100}}>
                      <a
                        key='edit'
                        style={{textDecorationLine: 'underline'}}
                        onClick={async () => {
                          await setMsgRead({categoryCode: item.categoryCode, msgId: item.msgId})
                          message.success('标记已读成功！')
                          queryMsgListCategory()
                          queryMsgList({categoryCode, pageNum, pageSize, read: readStatus})
                        }}
                      >
                        标记已读
                      </a>
                    </div>
                  ) : (
                    <div style={{minWidth: 100}}>
                      <span>已读</span>
                    </div>
                  ),
                ]}
                extra={<span>{item.updateTime}</span>}
              >
                <List.Item.Meta title={item.title} description={item.content} />
              </List.Item>
            )}
            pagination={{
              onChange: (page, size) => {
                if (pageSize !== size) {
                  setPageNum(1)
                  setPageSize(size)
                } else setPageNum(page)
              },
              current: pageNum,
              total: msgTotal,
              pageSize: pageSize,
            }}
            style={{backgroundColor: '#fff', marginTop: 20}}
          />
        </Col>
      </Row>
    </Page>
  )
}
