import React, {useCallback, useEffect, useState} from 'react'
import {Row, Col, Button, Input, Table, Modal, Form, Popconfirm, Space, message} from 'antd'
import {Breadcrumb} from '@/components/Breadcrumb'
import commonStyles from '@/assets/styles/common.module.less'
import {usePermission} from '@/components/Permission'
import {sysPermissionModel} from '@/store/models/SysPermission'
import {Page} from '@/components/Page'
import UploadFile from '@/components/UploadFile'
import {getPaginationProps} from '@/common/biz/getPaginationProps'

export default function SysPermissionListPage() {
  const [auth] = usePermission()
  const [visible, setVisible] = useState(false)
  const [permissionList, setPermissionList] = useState<SysPermissionVO[]>([])
  const [total, setTotal] = useState(0)
  const [form] = Form.useForm()
  const [addForm] = Form.useForm()
  const [permissionIdOld, setPermissionIdOld] = useState('')

  const search = useCallback(() => {
    const params = form.getFieldsValue(true)
    sysPermissionModel.sysPermissionList(params).then(({response: {data, total}}) => {
      setPermissionList(data)
      setTotal(total)
    })
  }, [form])

  useEffect(() => {
    form.submit()
  }, [form])

  function submit() {
    const {permissionId, permissionName} = addForm.getFieldsValue()
    sysPermissionModel
      .sysPermissionEdit({
        permissionId: permissionIdOld ? permissionIdOld : permissionId,
        permissionName,
        permissionIdNew: permissionId,
      })
      .then(() => search())
    setVisible(false)
  }
  const loading = sysPermissionModel.sysPermissionList.useLoading()

  return (
    <Page
      breadcrumb={
        <Breadcrumb
          right={
            <Space>
              {auth('011035001') && (
                <UploadFile
                  action={'/sys-permission/import-excel'}
                  onSuccess={({data}) => {
                    message.success(`导入${data}条权限`)
                    search()
                  }}
                  onError={msg => {
                    message.error(msg)
                  }}
                >
                  {pending => (
                    <Button type={'primary'} loading={pending}>
                      权限导入
                    </Button>
                  )}
                </UploadFile>
              )}
              {auth('003035002') && (
                <Button
                  onClick={() => {
                    setVisible(true)
                    addForm.resetFields()
                  }}
                >
                  添加
                </Button>
              )}
            </Space>
          }
        />
      }
    >
      <div className={commonStyles.filterSection}>
        <Form form={form} initialValues={{pageNum: 1, pageSize: 10}} onReset={search} onFinish={search}>
          <Row>
            <Col>
              <Form.Item label={'ID/权限名称'} name={'name'}>
                <Input placeholder={'ID/权限名称'} style={{width: '220px'}} allowClear />
              </Form.Item>
            </Col>
          </Row>
          <Row justify={'end'}>
            <Space>
              <Button type={'primary'} htmlType={'submit'} onClick={() => form.setFieldsValue({pageNum: 1})}>
                查询
              </Button>
              <Button htmlType={'reset'}>重置</Button>
            </Space>
          </Row>
        </Form>
      </div>

      <div className={commonStyles.tableSection}>
        <Table
          rowKey={'permissionId'}
          columns={[
            {title: 'ID', dataIndex: 'permissionId'},
            {title: '名称', dataIndex: 'permissionName'},
            {
              title: '操作',
              dataIndex: 'opertion',
              render: (text, record) => {
                return (
                  <Space>
                    {auth('002035002') && (
                      <Button
                        type={'link'}
                        size={'small'}
                        style={{padding: 0}}
                        onClick={() => {
                          setVisible(true)
                          addForm.setFieldsValue({
                            permissionId: record.permissionId,
                            permissionName: record.permissionName,
                          })
                          setPermissionIdOld(record.permissionId)
                        }}
                      >
                        编辑
                      </Button>
                    )}
                    {auth('004035002') && (
                      <Popconfirm
                        title={'确认删除这条权限吗'}
                        onConfirm={() => {
                          sysPermissionModel.sysPermissionDelete(permissionIdOld).then(() => form.submit())
                        }}
                        okText={'确认'}
                        cancelText={'取消'}
                      >
                        <Button
                          type={'link'}
                          size={'small'}
                          onClick={() => {
                            const {permissionId} = record
                            setPermissionIdOld(permissionId)
                          }}
                          style={{padding: 0}}
                        >
                          删除
                        </Button>
                      </Popconfirm>
                    )}
                  </Space>
                )
              },
              width: '300px',
            },
          ]}
          dataSource={permissionList}
          loading={loading}
          pagination={getPaginationProps(form, total)}
        />
      </div>

      <Modal
        title={'添加'}
        visible={visible}
        width={420}
        centered
        footer={null}
        onCancel={() => {
          setVisible(false)
        }}
      >
        <Form form={addForm} style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}} onFinish={submit}>
          <Form.Item name={'permissionId'} rules={[{required: true, message: 'ID不能为空'}]}>
            <Input placeholder={'请输入ID'} style={{width: '187px', marginBottom: '14px'}} allowClear />
          </Form.Item>
          <Form.Item name={'permissionName'} rules={[{required: true, message: '名称不能为空'}]}>
            <Input placeholder={'请输入名称'} style={{width: '187px'}} allowClear />
          </Form.Item>
          <Form.Item style={{textAlign: 'right'}}>
            <Button type={'primary'} htmlType={'submit'}>
              确认
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </Page>
  )
}
