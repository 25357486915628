import React, {useEffect, useRef} from 'react'
import {Mapview} from '@/components/Map'
import {commonModel} from '@/store/models/Common'
import {Form, Input, Modal} from 'antd'
import MapImg from '@/assets/icons/map_location.svg'

interface MapLocationProps {
  onOk: (point: {lat: string; lng: string}, address?: string) => void
  onCancel: () => void
  visible: boolean
  point?: {lat: string; lng: string}
}

function _MapLocation({visible, onCancel, onOk, point}: MapLocationProps, ref) {
  const mapRef = useRef(null)
  const [form] = Form.useForm()

  async function enterSelectAddress() {
    const address = form.getFieldValue('address')
    if (address) {
      const point = await mapRef.current?.geocoder(address)
      mapRef.current?.panTo(point.lng, point.lat)
      form.setFieldsValue({lng: point.lng, lat: point.lat})
    }
  }

  useEffect(() => {
    const {lat, lng} = point
    if (lat && lng) {
      setTimeout(() => {
        mapRef.current?.panTo(lng, lat)
      }, 1000)
      form.setFieldsValue({lat, lng})
    }
  }, [form, point])

  return (
    <Modal
      visible={visible}
      title={'地图选点'}
      centered
      onCancel={onCancel}
      onOk={() => {
        const {lat, lng} = form.getFieldsValue(true)
        onOk({lat, lng})
      }}
    >
      <Form form={form}>
        <Form.Item name={'address'} label={'详细地址'} rules={[{required: true, message: '请输入详细地址!'}]}>
          <Input
            placeholder='请输入'
            allowClear
            onPressEnter={event => {
              event.preventDefault()
              enterSelectAddress()
            }}
          />
        </Form.Item>
        <Form.Item>
          <div style={{position: 'relative'}}>
            <Mapview
              onMapChange={async event => {
                const point = event.point
                const {
                  response: {data},
                } = await commonModel.commonAppHomeMapGeocoder(point)
                form.setFieldsValue({address: data?.address, lat: data?.lat, lng: data?.lng})
              }}
              style={{height: 330}}
              ref={mapRef}
            />
            <img src={MapImg} alt={'icon'} className={'edit-site-location'} />
          </div>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export const MapLocation = React.forwardRef(_MapLocation)
