import React, {useCallback, useEffect, useState} from 'react'
import {Page} from '@/components/Page'
import commonStyles from '@/assets/styles/common.module.less'
import {
  Affix,
  Button,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  List,
  message,
  Row,
  Select,
  Space,
  Steps,
  Tabs,
  Typography,
} from 'antd'
import styles from './index.module.less'
import {PlusOutlined} from '@ant-design/icons/lib'
import {useAppHistory, useAppLocation} from '@/common/hooks/useRouter'
import {condoModel} from '@/store/models/Condo'
import Delete from '@/assets/icons/delete.svg'
import * as listener from '@/common/hooks/listener'
import BackButton from '@/components/BackButton'

interface FloorList {
  // floor: string
  floor: number // 楼层号
  extNum: string // 房间数量
  prefixEnabled?: boolean // 是否添加前缀
  propertyList: {
    id: number
    houseNo: string
    rentStatus: string // '0' | '1'
  }[]
}

const rules = {
  floor: [{required: true, message: '楼层不能为空'}],
  houseNo: [
    {required: true, message: '请输入房号'},
    {max: 8, message: '房号不能超过8个字符'},
  ],
}

function maxHouseIndex(dtos: FloorList['propertyList'] = [], floor = 1, prefix = '') {
  const prefixReg = new RegExp(`^${prefix}${floor}`)
  const houseNos = dtos
    .map(value => value.houseNo?.replace?.(prefixReg, ''))
    .filter(value => value)
    .map(value => +value)
  return houseNos.length ? Math.max(...houseNos) : 1
}

const padStart = (value: number) => `${value}`.padStart(2, '0')

export default function AddRoom() {
  const history = useAppHistory()
  const location = useAppLocation<'/concentrate-pm/add-room'>()
  const condoProjectId = location.state.condoProjectId
  const [current, setCurrent] = useState(0)
  const [form] = Form.useForm()
  const [itemForm] = Form.useForm()
  const [projectInfo, setProjectInfo] = useState({} as CondoProjectVO)
  // 公寓楼层高
  const [floors, setFloors] = useState([])
  // 房间
  const [dataList, setDataList] = useState([])
  const [selectIds, setSelectIds] = useState([])
  const [templateList, setTemplateList] = useState<CondoProjectTemplateVO[]>([])

  // 模板列表
  useEffect(() => {
    condoModel
      .condoProjectTemplateList({pageable: false, condoProjectId, enable: '1'} as CondoProjectTemplateListDTO)
      .then(({response: {data}}) => {
        setTemplateList(data)
      })
  }, [condoProjectId])

  useEffect(() => {
    condoModel.condoProjectInfo({condoProjectId}).then(({response: {data}}) => {
      setFloors(Array.from({length: data.topFloor}).map((_, index) => index + 1))
      setProjectInfo(data)
    })
  }, [condoProjectId, form])

  // 改变楼层号楼层号
  function onFloorChange(fieldKey) {
    const floorList = form.getFieldValue('floorList')
    const newList = floorList?.map((item, index) =>
      index === fieldKey
        ? {
            ...item,
            propertyList: item.propertyList.map((_item, _index) => ({
              ..._item,
              houseNo: `${item.floor * 100 + _index + 1}`,
            })),
          }
        : item
    )
    form.setFieldsValue({floorList: newList})
  }

  function createProperty(index: number, floor = 1, prefix = '', rentStatus = '0'): FloorList['propertyList'][number] {
    return {
      houseNo: `${prefix}${floor}${padStart(index)}`,
      rentStatus,
      id: Math.random(),
    }
  }

  // 输入房间数
  function onExtNumChange(name: number) {
    const extNum = form.getFieldValue(['floorList', name, 'extNum'])
    const floor: number = form.getFieldValue(['floorList', name, 'floor'])
    const prefixEnabled: boolean = form.getFieldValue(['floorList', name, 'prefixEnabled'])
    const prefix = prefixEnabled ? form.getFieldValue('prefix') : ''
    const propertyList: FloorList['propertyList'] = form.getFieldValue(['floorList', name, 'propertyList'])
    if (extNum) {
      if (+extNum > propertyList.length) {
        const maxIndex = maxHouseIndex(propertyList, floor, prefix)
        let offset = 0
        const skip4: boolean = form.getFieldValue('skip4')
        const leftDTOS = Array.from({length: +extNum - propertyList.length}).map((_, index) => {
          if (skip4 && `${maxIndex + index + 1 + offset}`.endsWith('4')) {
            offset++
          }
          return createProperty(maxIndex + index + 1 + offset, floor, prefix)
        })
        form.setFields([{name: ['floorList', name, 'propertyList'], value: [...propertyList, ...leftDTOS]}])
      } else if (+extNum < propertyList.length) {
        form.setFields([{name: ['floorList', name, 'propertyList'], value: propertyList.filter((_, n) => n < +extNum)}])
      }
    } else {
      form.resetFields([['floorList', name, 'extNum']]) // 消除警告
      form.setFields([{name: ['floorList', name, 'extNum'], value: propertyList?.length}])
    }
  }

  // 增加/删除房间后，更新 extNum
  const setExtNum = useCallback(
    (name: number, length: number) => {
      form.setFields([{name: ['floorList', name, 'extNum'], value: length}])
    },
    [form]
  )

  // 下一步
  function next() {
    form.validateFields().then(async () => {
      const formValues = form.getFieldsValue(true)
      const {floorList} = formValues
      if (!floorList || floorList?.length === 0) return message.warning('请添加楼层！')

      // 检查楼层号是否重复
      const floors = floorList?.map(item => item.floor)
      const isRepeat = floors.length !== new Set(floors).size
      if (isRepeat) return message.warning('楼层号重复!')

      // 检查房间号是否重复
      const propertys = floorList?.map(item => item.propertyList)
      for (let i = 0; i < propertys.length; i++) {
        const list = propertys[i]
        const obj = {}
        for (let j = 0; j < list.length; j++) {
          const houseNo = list[j].houseNo
          if (obj[houseNo] === undefined) {
            obj[houseNo] = houseNo
          } else return message.warning(`房间号 ${houseNo} 重复！`)
        }
      }

      setDataList(() => floorList?.map(item => ({title: item.floor, data: item.propertyList})))
      setCurrent(1)
    })
  }

  function toggleId(id) {
    if (selectIds.includes(id)) {
      setSelectIds(prevState => prevState.filter(item => item !== id))
    } else {
      setSelectIds(prevState => [...prevState, id])
    }
  }

  // 添加
  async function onFinish() {
    const propertyList = dataList
      .map(item => {
        return item.data.map(_item => {
          return {
            rentPrice: _item.rentPrice,
            templateId: _item.templateId,
            rentStatus: _item.rentStatus,
            floor: item.title,
            houseNo: _item.houseNo,
          }
        })
      })
      .flat()
    if (!propertyList.every(item => item.templateId)) return message.warning('请配置房间信息！')

    await condoModel.condoProjectPropertyInsert({condoId: condoProjectId, propertyList})
    listener.emit('ProjectSet:property')
    message.success('添加成功！')
    history.goBack()
  }

  return (
    <Page>
      <BackButton />
      <div className={commonStyles.filterSection}>
        <Steps
          current={current}
          labelPlacement={'vertical'}
          style={{padding: '0 150px'}}
          onChange={current1 => {
            setCurrent(current1)
          }}
        >
          <Steps.Step title={'添加房间'} />
          <Steps.Step title={'配置房间'} disabled={current < 1} />
        </Steps>
      </div>
      <Tabs activeKey={`${current}`} defaultActiveKey={'0'} renderTabBar={() => null}>
        <Tabs.TabPane key={'0'}>
          <div className={commonStyles.flexFullSection}>
            <Form form={form} colon={false} labelCol={{span: 7}}>
              <Form.List name={'floorList'}>
                {(fields, {add, remove}) => {
                  return (
                    <>
                      {fields.map(field => (
                        <div className={styles.floorList} key={field.key}>
                          {/* 楼层号 */}
                          <div className={styles.floorHeader}>
                            <Form.Item
                              label={'楼层号'}
                              name={[field.name, 'floor']}
                              fieldKey={[field.fieldKey, 'floor']}
                              rules={rules.floor}
                              className={styles.floorFormItem1}
                            >
                              <Select placeholder={'请选择'} allowClear onChange={() => onFloorChange(field.name)}>
                                {floors.map(item => (
                                  <Select.Option key={item} value={item}>
                                    {item}
                                  </Select.Option>
                                ))}
                              </Select>
                            </Form.Item>
                            <Form.Item
                              name={[field.name, 'extNum']}
                              fieldKey={[field.fieldKey, 'extNum']}
                              label={'房间数量'}
                              rules={[
                                {required: true, message: '房间数不能为空'},
                                {
                                  validator: (_, value, callback) => {
                                    if (!/^\d+$/.test(value)) callback('请输入整数')
                                    else callback()
                                  },
                                  message: '请输入整数',
                                },
                              ]}
                              className={styles.floorFormItem2}
                            >
                              <InputNumber
                                min={1}
                                max={200}
                                onBlur={() => onExtNumChange(field.name)}
                                onPressEnter={() => onExtNumChange(field.name)}
                                placeholder={'请输入房间数量'}
                                style={{width: '100%'}}
                              />
                            </Form.Item>

                            <Button danger onClick={() => remove(field.name)} className={styles.floorDelete}>
                              删除
                            </Button>
                          </div>

                          {/* 房间列表 */}
                          <Form.List name={[field.name, 'propertyList']}>
                            {(innerFields, {add: innerAdd, remove: innerRemove}) => {
                              const propertyList: FloorList['propertyList'] = form.getFieldValue([
                                'floorList',
                                field.name,
                                'propertyList',
                              ])

                              return (
                                <div className={styles.roomList}>
                                  {innerFields.map(innerField => (
                                    <div key={innerField.key} className={styles.roomItem}>
                                      <img
                                        src={Delete}
                                        alt={'icon'}
                                        className={styles.roomDelete}
                                        onClick={() => {
                                          innerRemove(innerField.name)
                                          setExtNum(field.name, propertyList.length - 1)
                                        }}
                                      />
                                      <div className={styles.roomBorder}>
                                        <Form.Item
                                          name={[innerField.name, 'houseNo']}
                                          fieldKey={[innerField.fieldKey, 'houseNo']}
                                          style={{marginBottom: 0}}
                                          rules={rules.houseNo}
                                        >
                                          <Input placeholder='请输入房间号' allowClear style={{border: 'none'}} />
                                        </Form.Item>
                                        <Divider className={styles.roomDivider} />
                                        <Form.Item
                                          name={[innerField.name, 'rentStatus']}
                                          fieldKey={[innerField.fieldKey, 'rentStatus']}
                                          style={{marginBottom: 0}}
                                        >
                                          <Select placeholder={'请选择出租状态'} bordered={false}>
                                            <Select.Option value={'1'}>已租</Select.Option>
                                            <Select.Option value={'0'}>未租</Select.Option>
                                          </Select>
                                        </Form.Item>
                                      </div>
                                    </div>
                                  ))}
                                  <div>
                                    {+form.getFieldValue('floorList')?.[field.name]?.extNum < 199 && (
                                      <Button
                                        shape={'circle'}
                                        type={'primary'}
                                        ghost
                                        icon={<PlusOutlined />}
                                        onClick={() => {
                                          const floor = form.getFieldValue(['floorList', field.name, 'floor']) || 1
                                          const prefixEnabled: boolean = form.getFieldValue([
                                            'floorList',
                                            field.name,
                                            'prefixEnabled',
                                          ])
                                          const prefix = prefixEnabled ? form.getFieldValue('prefix') : ''
                                          const maxIndex = maxHouseIndex(propertyList, floor, prefix)
                                          const skip4 = form.getFieldValue('skip4')
                                          let nextIndex = maxIndex + 1
                                          if (skip4 && `${nextIndex}`.endsWith('4')) {
                                            nextIndex++
                                          }

                                          innerAdd(createProperty(nextIndex, floor, prefix))
                                          setExtNum(field.name, propertyList.length + 1)
                                        }}
                                      />
                                    )}
                                  </div>
                                </div>
                              )
                            }}
                          </Form.List>
                        </div>
                      ))}

                      <Form.Item noStyle shouldUpdate>
                        {() => (
                          <Button
                            type='primary'
                            ghost
                            icon={<PlusOutlined />}
                            onClick={() => {
                              const floorList = form.getFieldValue('floorList')
                              if (floorList?.length >= projectInfo.topFloor) {
                                return message.warn('楼层数不能超过公寓总高！')
                              }
                              add({
                                propertyList: [createProperty(1)],
                                extNum: '1',
                              })
                            }}
                          >
                            添加楼层
                          </Button>
                        )}
                      </Form.Item>
                    </>
                  )
                }}
              </Form.List>

              <Row justify={'center'}>
                <Button type={'primary'} style={{width: 120, height: 40}} onClick={next}>
                  下一步
                </Button>
              </Row>
            </Form>
          </div>
        </Tabs.TabPane>
        <Tabs.TabPane key={'1'}>
          <Row gutter={20} style={{margin: 0}}>
            <Col flex={'1'} style={{paddingLeft: 0, paddingRight: 0}}>
              <Form>
                <Form.Item noStyle shouldUpdate>
                  {() => (
                    <List
                      style={{backgroundColor: '#fff'}}
                      dataSource={dataList}
                      renderItem={(item: any) => (
                        <List.Item style={{paddingTop: 0}}>
                          <div className={styles.listSection}>
                            <Space className={styles.configListSection}>
                              <Typography.Text>{`${item?.title}层`}</Typography.Text>

                              <Button
                                onClick={() => {
                                  setSelectIds(prevState =>
                                    Array.from(new Set([...prevState, ...item?.data.map(_item => _item.id)]))
                                  )
                                  itemForm.setFieldsValue(item?.data[0])
                                }}
                                type={'ghost'}
                              >
                                全选
                              </Button>

                              <Button
                                onClick={() => {
                                  const ids = item?.data.map(_item => _item.id)
                                  setSelectIds(prevState => prevState.filter(id => !ids?.includes(id)))
                                }}
                                type={'ghost'}
                              >
                                取消全选
                              </Button>
                            </Space>
                            <div className={styles.configListContainer}>
                              {item?.data?.map((_item, n) => {
                                const isActive = selectIds.includes(_item.id) // _item.id === itemForm.getFieldValue('id')
                                return (
                                  <div
                                    key={`${n}-${_item.houseNo}`}
                                    className={isActive ? styles.configListItemS : styles.configListItemN}
                                    onClick={() => {
                                      toggleId(_item.id)
                                      if (!isActive) itemForm.setFieldsValue(_item)
                                    }}
                                  >
                                    <div className={isActive ? styles.listItemHeaderS : styles.listItemHeaderN}>
                                      <div
                                        className={_item.rentStatus === '0' ? styles.headerSquarG : styles.headerSquarB}
                                      />
                                      <div>{_item.houseNo}</div>
                                    </div>
                                    <div className={styles.listItemBody}>
                                      {_item.templateName ? (
                                        <>
                                          <div>{_item.templateName}</div>
                                          <div>{_item.rentPrice ? `${_item.rentPrice}元/月` : ''}</div>
                                        </>
                                      ) : (
                                        <div style={isActive ? {color: '#1CBEB6'} : {}}>未选择模板</div>
                                      )}
                                    </div>
                                  </div>
                                )
                              })}
                            </div>
                          </div>
                        </List.Item>
                      )}
                      header={
                        <div className={styles.configHeader}>
                          <div className={styles.headerTitle}>{form.getFieldValue('condoName')}</div>
                          <div className={styles.configHeader}>
                            <div className={styles.configHeader}>
                              <div className={styles.headerSquarG} />
                              <div className={styles.headerDesc}>未出租</div>
                            </div>
                            <div className={styles.configHeader}>
                              <div className={styles.headerSquarB} />
                              <div className={styles.headerDesc}>已出租</div>
                            </div>
                          </div>
                        </div>
                      }
                    />
                  )}
                </Form.Item>
              </Form>

              <Form form={form} onFinish={onFinish}>
                <div className={styles.confirmAdd}>
                  <Form.Item>
                    <Button type={'primary'} htmlType={'submit'}>
                      确认添加
                    </Button>
                  </Form.Item>
                </div>
              </Form>
            </Col>
            <Affix offsetTop={0}>
              <Col flex='280px' className={styles.side}>
                <Form
                  form={itemForm}
                  colon={false}
                  labelCol={{span: 6}}
                  onFinish={() => {
                    if (!selectIds.length) return message.warning('请选择要配置的房源！')
                    const value = itemForm.getFieldsValue(true)
                    setDataList(prevState => {
                      return prevState.map(item => {
                        return {
                          ...item,
                          data: item.data.map(_item => {
                            if (selectIds.includes(_item.id)) {
                              return {
                                ..._item,
                                rentPrice: value.rentPrice,
                                templateId: value.templateId,
                                rentStatus: value.rentStatus,
                                templateName: value.templateName,
                              }
                            } else {
                              return _item
                            }
                          }),
                        }
                      })
                    })
                    itemForm.resetFields()
                    setSelectIds([])
                  }}
                >
                  <Form.Item name={'id'} noStyle>
                    <div />
                  </Form.Item>
                  <Col>
                    <Form.Item
                      name={'houseNo'}
                      label={'房间号'}
                      // rules={rules.houseNo}
                    >
                      <Input allowClear disabled placeholder={'请输入房间号'} />
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item
                      name={'rentStatus'}
                      label={'出租状态'}
                      // rules={[{required: true, message: '请选择出租状态'}]}
                    >
                      <Select placeholder={'请选择'} allowClear>
                        <Select.Option value={'1'}>已租</Select.Option>
                        <Select.Option value={'0'}>未租</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item name={'templateId'} label={'模板'}>
                      <Select
                        placeholder={'请选择'}
                        onChange={value => {
                          const {rentPrice, templateName} = templateList?.find(item => item.templateId === value)
                          itemForm.setFieldsValue({rentPrice, templateName})
                        }}
                        allowClear
                      >
                        {templateList.map((item, i) => (
                          <Select.Option value={item.templateId} key={i}>
                            {item.templateName}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item name={'rentPrice'} label={'租金'}>
                      <InputNumber placeholder={'请输入租金'} style={{width: '100%'}} />
                    </Form.Item>
                  </Col>
                  <div className={styles.configSave}>
                    <Form.Item>
                      <Button type={'primary'} htmlType={'submit'}>
                        保存
                      </Button>
                    </Form.Item>
                  </div>
                </Form>
              </Col>
            </Affix>
          </Row>
        </Tabs.TabPane>
      </Tabs>
    </Page>
  )
}
