import React, {useCallback, useEffect, useState} from 'react'
import commonStyles from '@/assets/styles/common.module.less'
import {Button, Col, Form, Input, Modal, Row, Select, Space, Switch, Table} from 'antd'
import {condoModel} from '@/store/models/Condo'
import {getPaginationProps} from '@/common/biz/getPaginationProps'
import {useCodeSync} from '@/store/models/Common'
import {useAppHistory} from '@/common/hooks/useRouter'
import {usePermission} from '@/components/Permission'

interface PropertyTemplateComponentProps {
  condoProjectId: string
}

const PropertyTemplateComponent: React.FC<PropertyTemplateComponentProps> = ({condoProjectId}) => {
  const history = useAppHistory()
  const [auth] = usePermission()
  const [form] = Form.useForm()
  const [templateList, setTemplateList] = useState<CondoProjectTemplateVO[]>([])
  const [templateTotal, setTemplateTotal] = useState(0)
  // 朝向
  const orientation = useCodeSync('property.orientation')
  // 窗户
  const window = useCodeSync('property.window')
  const templateLoading = condoModel.condoProjectTemplateList.useLoading()

  const search = useCallback(() => {
    const formValues = form.getFieldsValue(true)
    condoModel.condoProjectTemplateList({...formValues, condoProjectId}).then(({response: {data, total}}) => {
      setTemplateList(data)
      setTemplateTotal(total)
    })
  }, [condoProjectId, form])

  useEffect(() => {
    if (condoProjectId && auth('001095001')) {
      form.submit()
    }
  }, [condoProjectId, form, auth])

  return (
    <Form form={form} onFinish={search} onReset={search} initialValues={{pageNum: 1, pageSize: 10}}>
      <div className={commonStyles.filterSection}>
        <Row>
          <Col span={5}>
            <Form.Item label={'关键字'} name={'templateName'}>
              <Input placeholder='请输入模板名称' allowClear />
            </Form.Item>
          </Col>
          <Col offset={1} span={5}>
            <Form.Item label={'状态'} name={'enable'}>
              <Select placeholder={'请选择'} allowClear>
                <Select.Option value={'1'}>正常</Select.Option>
                <Select.Option value={'0'}>禁用</Select.Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row justify={'end'}>
          <Space>
            <Button type='primary' htmlType={'submit'} onClick={() => form.setFieldsValue({pageNum: 1})}>
              查询
            </Button>
            <Button htmlType={'reset'}>重置</Button>
          </Space>
        </Row>
      </div>

      <div className={commonStyles.filterSection}>
        <Table
          style={{marginBottom: 10}}
          rowKey={'templateId'}
          loading={templateLoading}
          dataSource={templateList}
          columns={[
            {title: '模板名称', dataIndex: 'templateName'},
            {title: '房间面积', dataIndex: 'floorage', render: text => `${text}m²`},
            {
              title: '户型',
              render: record =>
                `${record.bedroom ? `${record.bedroom}室` : ''}${record.parlor ? `${record.parlor}厅` : ''}${
                  record.bathroom ? `${record.bathroom}卫` : ''
                }`,
            },
            {title: '朝向', dataIndex: 'orientation', render: text => orientation[text]},
            {title: '窗户', dataIndex: 'window', render: text => window[text]},
            {title: '状态', dataIndex: 'templateEnable', render: text => (text === '1' ? '正常' : '禁用')},
            {
              title: '操作',
              render: record => {
                return (
                  <Space>
                    {auth('023095002') && (
                      <Switch
                        checked={record.templateEnable === '1'}
                        onChange={checked => {
                          Modal.confirm({
                            title: '提示',
                            icon: null,
                            content: `是否确认${checked ? '启用' : '禁用'}该模板？`,
                            centered: true,
                            onOk: async () => {
                              await condoModel.condoProjectTemplateEnable({
                                templateId: record.templateId,
                                templateEnable: checked ? '1' : '0',
                              })
                              form.submit()
                            },
                          })
                        }}
                      />
                    )}

                    {record.templateEnable === '0' && auth('002095002') && (
                      <Button
                        type={'link'}
                        style={{padding: 0}}
                        onClick={() =>
                          history.push({
                            pathname: '/concentrate-pm/template',
                            state: {condoProjectId, templateId: record.templateId},
                          })
                        }
                      >
                        编辑
                      </Button>
                    )}
                    {auth('001095002') && (
                      <Button
                        type={'link'}
                        style={{padding: 0}}
                        onClick={() => {
                          history.push({
                            pathname: '/concentrate-pm/template',
                            state: {condoProjectId, templateId: record.templateId, readonly: true},
                          })
                        }}
                      >
                        查看
                      </Button>
                    )}
                  </Space>
                )
              },
            },
          ]}
          pagination={getPaginationProps(form, templateTotal)}
        />
      </div>
    </Form>
  )
}

export default PropertyTemplateComponent
