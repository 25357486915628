import {Model} from '@redux-model/web'
import {$api} from '@/store/service'

export interface WebsiteModel {}

class Website extends Model<WebsiteModel> {
  protected initReducer(): WebsiteModel {
    return null
  }

  // 意向合作列表
  websiteCooperationList = $api.action((args: WebsiteCooperationQueryDTO) =>
    this.post<ApiResponse<WebsiteCooperationVO[]>>('/website/cooperation/list').body(args)
  )

  websiteCooperationHandle = $api.action((args: WebsiteCooperationHandleDTO) =>
    this.post<ApiResponse<Record<string, any>>>('/website/cooperation/handle').body(args)
  )

  // 意向合作详情  |  object:{recordId:记录编号}
  websiteCooperationGet = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<WebsiteCooperationVO>>('/website/cooperation/get').body(args)
  )
}

export const websiteModel = new Website()
