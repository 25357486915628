import React, {useEffect} from 'react'
import commonStyles from '@/assets/styles/common.module.less'
import DividerLine from '@/pages/property/component/DividerLine'
import {Button, Form, Input, message, Space} from 'antd'
import {useAppHistory} from '@/common/hooks/useRouter'
import {condoModel} from '@/store/models/Condo'

interface IntellectSetComponentProps {
  condoProjectId: string
}

const IntellectSetComponent: React.FC<IntellectSetComponentProps> = ({condoProjectId}) => {
  const history = useAppHistory()
  const [form] = Form.useForm()
  const loading = condoModel.condoProjectDeviceConfig.useLoading()
  useEffect(() => {
    if (condoProjectId) {
      condoModel.condoProjectDeviceConfigInfo({condoProjectId}).then(({response: {data}}) => {
        form.setFieldsValue(data)
      })
    }
  }, [condoProjectId, form])

  return (
    <div className={commonStyles.filterSection}>
      <Form
        form={form}
        labelCol={{flex: '150px'}}
        onFinish={async () => {
          const values = form.getFieldsValue(true)
          await condoModel.condoProjectDeviceConfig({...values, condoProjectId})
          message.success('智能设备配置成功！')
          history.goBack()
        }}
      >
        <DividerLine title={'门锁'} bottom={15} />
        <Form.Item required style={{marginBottom: 0}} label={'临时密码有效时长'}>
          <Space>
            <Form.Item name={'lockPwdDuring'} rules={[{required: true, message: '请输入临时密码有效时长'}]}>
              <Input placeholder={'请输入1~120分钟'} />
            </Form.Item>
            <Form.Item>
              <span>分钟</span>
            </Form.Item>
          </Space>
        </Form.Item>
        <DividerLine title={'电表'} bottom={15} />
        <Form.Item style={{marginBottom: 0}} required label={'电表价格'}>
          <Space>
            <Form.Item name={'elePrice'} rules={[{required: true, message: '请输入电表价格'}]}>
              <Input placeholder={'请输入电表价格'} />
            </Form.Item>
            <Form.Item>
              <span>元/千瓦时</span>
            </Form.Item>
          </Space>
        </Form.Item>
        <Form.Item style={{marginBottom: 0}} required label={'电量突增预警'}>
          <Space>
            <Form.Item name={'eleIncrDuringLimit'} rules={[{required: true, message: '请输入电量突增预警时长'}]}>
              <Input placeholder={'请输入0~9天'} />
            </Form.Item>
            <Form.Item>
              <span>天内，超过</span>
            </Form.Item>
            <Form.Item name={'eleIncrDataLimit'} rules={[{required: true, message: '请输入电量突增预警度数'}]}>
              <Input placeholder={'请输入度数'} />
            </Form.Item>
            <Form.Item>
              <span>千瓦时</span>
            </Form.Item>
          </Space>
        </Form.Item>
        <Form.Item style={{marginBottom: 0}} required label={'空房用电预警阈值'}>
          <Space>
            <Form.Item name={'eleEmptyDuringLimit'} rules={[{required: true, message: '请输入空房用电预警时长'}]}>
              <Input placeholder={'请输入0~9天'} />
            </Form.Item>
            <Form.Item>
              <span>天内，超过</span>
            </Form.Item>
            <Form.Item name={'eleEmptyDataLimit'} rules={[{required: true, message: '请输入空房用电预警度数'}]}>
              <Input placeholder={'请输入度数'} />
            </Form.Item>
            <Form.Item>
              <span>千瓦时</span>
            </Form.Item>
          </Space>
        </Form.Item>
        <DividerLine title={'水表'} bottom={15} />
        <Form.Item style={{marginBottom: 0}} required label={'水费价格'}>
          <Space>
            <Form.Item name={'waterPrice'} rules={[{required: true, message: '请输入水费价格'}]}>
              <Input placeholder={'请输入水费价格'} />
            </Form.Item>
            <Form.Item>
              <span>元/吨</span>
            </Form.Item>
          </Space>
        </Form.Item>
        <Form.Item style={{marginBottom: 0}} required label={'水量突增预警'}>
          <Space>
            <Form.Item name={'waterIncrDuringLimit'} rules={[{required: true, message: '请输入水量突增预警时长'}]}>
              <Input placeholder={'请输入0~9天'} />
            </Form.Item>
            <Form.Item>
              <span>天内，超过</span>
            </Form.Item>
            <Form.Item name={'waterIncrDataLimit'} rules={[{required: true, message: '请输入水量突增预警吨数'}]}>
              <Input placeholder={'请输入吨数'} />
            </Form.Item>
            <Form.Item>
              <span>吨</span>
            </Form.Item>
          </Space>
        </Form.Item>
        <Form.Item style={{marginBottom: 0}} required label={'空房用水预警'}>
          <Space>
            <Form.Item name={'waterEmptyDuringLimit'} rules={[{required: true, message: '请输入空房用水预警天数'}]}>
              <Input placeholder={'请输入0~9天'} />
            </Form.Item>
            <Form.Item>
              <span>天内，超过</span>
            </Form.Item>
            <Form.Item name={'waterEmptyDataLimit'} rules={[{required: true, message: '请输入空房用水预警吨数'}]}>
              <Input placeholder={'请输入吨数'} />
            </Form.Item>
            <Form.Item>
              <span>吨</span>
            </Form.Item>
          </Space>
        </Form.Item>
        <DividerLine title={'水电额度'} bottom={15} />
        <Form.Item style={{marginBottom: 0}} required label={'剩余水电余额通知'}>
          <Space>
            <Form.Item>
              <span>少于</span>
            </Form.Item>
            <Form.Item name={'walletNoticeLimit'} rules={[{required: true, message: '请输入剩余水电余额通知'}]}>
              <Input placeholder={'请输入'} />
            </Form.Item>
            <Form.Item>
              <span>发送通知</span>
            </Form.Item>
          </Space>
        </Form.Item>
        <Form.Item label={' '} colon={false}>
          <Space>
            <Button type={'primary'} htmlType={'submit'} loading={loading}>
              提交
            </Button>
            <Button onClick={() => history.goBack()}>取消</Button>
          </Space>
        </Form.Item>
      </Form>
    </div>
  )
}

export default IntellectSetComponent
