import React, {useCallback, useEffect, useState} from 'react'
import {Page} from '@/components/Page'
import {Button, DatePicker, Form, Input, InputNumber, message, PageHeader, Select, Space} from 'antd'
import commonStyles from '@/assets/styles/common.module.less'
import {useAppHistory, useAppLocation} from '@/common/hooks/useRouter'
import {ebassModel} from '@/store/models/Ebaas'
import ebaas from '@/store/models/Common/ebaasCodes'
import moment from 'moment'
import {arrayToObject} from '@/store/models/Common'

const timeLimitMinCode = arrayToObject(ebaas.timeLimitMin)
const timeLimitMaxCode = arrayToObject(ebaas.timeLimitMax)

export default function StorePropertyDetail() {
  const location = useAppLocation<'/store-manage/property-detail'>()
  // 有 propertyCode 是编辑，没 propertyCode 是新增
  const propertyCode = 'propertyCode' in location.state ? location.state.propertyCode : null
  const condoId = location.state?.condoId
  const history = useAppHistory()

  const [form] = Form.useForm()
  const [layoutList, setLayoutList] = useState<EbaasLayoutVO[]>([])
  const [disable, setDisable] = useState(false)

  const updateLoading = ebassModel.ebaasRoomUpdate.useLoading()

  useEffect(() => {
    if (propertyCode && condoId) {
      ebassModel.ebaasRoomInfo({propertyCode}).then(({response: {data}}) => {
        form.setFieldsValue({
          propertyCode: data.propertyCode,
          name: data.name,
          price: data.price,
          templateId: data.templateId,
          area: data.area,
          currentFloorNum: data.currentFloorNum,
          towards: data.towards,
          checkInTime: data.checkInTime,
          _checkInTime: data.checkInTime ? moment(data.checkInTime) : null,
          openStatus: data.openStatus,
        })
        setDisable(!!data.roomId)
      })

      ebassModel.ebaasApartmentInfo({condoId}).then(({response: {data}}) =>
        form.setFieldsValue({
          timeLimitMax: data.timeLimitMax,
          timeLimitMin: data.timeLimitMin,
        })
      )
    }

    if (condoId) {
      ebassModel
        .ebaasLayoutList({condoId, pageNum: 1, pageSize: 10, pageable: false} as EbaasLayoutListDTO)
        .then(({response: {data}}) => setLayoutList(data))
    }
  }, [condoId, form, propertyCode])

  const onFinish = useCallback(async () => {
    const formValues = form.getFieldsValue(true)
    // console.log('formValues', formValues)
    if (propertyCode) {
      await ebassModel.ebaasRoomUpdate(formValues)
    } else {
      await ebassModel.ebaasRoomInsert({...formValues, condoId})
    }
    message.success('操作成功！')
    history.goBack()
  }, [condoId, form, history, propertyCode])

  return (
    <Page
      header={
        <PageHeader onBack={() => history.goBack()} title={propertyCode ? '房源信息' : '新增房间'} ghost={false} />
      }
    >
      <div className={commonStyles.content} style={{backgroundColor: '#fff', paddingTop: 20}}>
        <Form
          form={form}
          labelAlign={'right'}
          labelCol={{flex: '120px', offset: 2}}
          style={{width: '50%'}}
          onFinish={onFinish}
        >
          <Form.Item noStyle shouldUpdate>
            {form1 => {
              const propertyCode = form1.getFieldValue('propertyCode')
              return propertyCode ? (
                <Form.Item label={'房源ID'} name={'propertyCode'}>
                  {propertyCode}
                </Form.Item>
              ) : null
            }}
          </Form.Item>
          <Form.Item label={'房间号'} name={'name'} rules={[{required: true, message: '请输入房间号'}]}>
            <Input placeholder={'请输入'} maxLength={20} />
          </Form.Item>
          <Form.Item
            label={'租金'}
            name={'price'}
            rules={[
              {required: true, message: '请输入租金'},
              {type: 'number', min: 100, max: 100000, message: '租金范围在100-100000之间'},
            ]}
          >
            <InputNumber placeholder={'请输入'} style={{width: '100%'}} />
          </Form.Item>
          <Form.Item label={'房型模板'} name={'templateId'} rules={[{required: true, message: '请选择房型模板'}]}>
            <Select placeholder={'请选择'} allowClear disabled={disable}>
              {layoutList.map(item => (
                <Select.Option key={item.templateId} value={item.templateId}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label={'面积'}
            name={'area'}
            rules={[
              {required: true, message: '请输入面积'},
              {type: 'number', min: 6, max: 300, message: '面积范围在6-300之间'},
            ]}
          >
            <InputNumber placeholder={'请输入'} style={{width: '100%'}} />
          </Form.Item>
          <Form.Item
            label={'所在楼层'}
            name={'currentFloorNum'}
            rules={[
              {required: true, message: '请输入所在楼层'},
              {type: 'number', min: -2, message: '楼层不能小于-2'},
            ]}
          >
            <InputNumber placeholder={'请输入'} style={{width: '100%'}} />
          </Form.Item>
          <Form.Item label={'朝向'} name={'towards'} rules={[{required: true, message: '请选择朝向'}]}>
            <Select placeholder={'请选择'} allowClear>
              {ebaas.towards.map(item => (
                <Select.Option key={item.id} value={item.id}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label={'可入住时间'} name={'_checkInTime'} rules={[{required: true, message: '请选择可入住时间'}]}>
            <DatePicker
              style={{width: '100%'}}
              format={'YYYY-MM-DD'}
              onChange={(_, formatString) => form.setFieldsValue({checkInTime: formatString})}
            />
          </Form.Item>
          <Form.Item label={'出租状态'} name={'openStatus'} rules={[{required: true, message: '请选择出租状态'}]}>
            <Select placeholder={'请选择'} allowClear>
              {ebaas.roomOpenStatus.map(item => (
                <Select.Option key={item.id} value={item.id}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          {!!propertyCode && (
            <>
              <Form.Item
                label={'最短租期'}
                shouldUpdate={(prevValues, nextValues) => prevValues.timeLimitMin !== nextValues.timeLimitMin}
              >
                {form1 => <Input value={timeLimitMinCode[form1.getFieldValue('timeLimitMin')]} disabled />}
              </Form.Item>
              <Form.Item
                label={'最长租期'}
                shouldUpdate={(prevValues, nextValues) => prevValues.timeLimitMax !== nextValues.timeLimitMax}
              >
                {form1 => <Input value={timeLimitMaxCode[form1.getFieldValue('timeLimitMax')]} disabled />}
              </Form.Item>
            </>
          )}
          <Form.Item label={' '} colon={false}>
            <Space>
              <Button type={'primary'} htmlType={'submit'} loading={updateLoading}>
                保存
              </Button>
              <Button onClick={() => history.goBack()}>取消</Button>
            </Space>
          </Form.Item>
        </Form>
      </div>
    </Page>
  )
}
