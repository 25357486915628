import React, {useCallback, useEffect, useImperativeHandle, useMemo, useState} from 'react'
import styles from './index.module.less'
import {Checkbox, Col, message, Row} from 'antd'
import {condoModel} from '@/store/models/Condo'
import {commonModel, useCodeArraySync} from '@/store/models/Common'
import {useAppHistory} from '@/common/hooks/useRouter'
import {usePermission} from '@/components/Permission'

const _PayConfigComponent = ({condoProjectId}, ref) => {
  const history = useAppHistory()
  const [auth] = usePermission()
  // 支付渠道
  const payWayList = useCodeArraySync('bill.payWay')
  const [payConfigList, setPayConfigList] = useState<PayVO[]>([])
  const renderPayConfigList = useMemo(() => {
    return payWayList
      .map(item => ({payWay: item.id, payWayName: item.label, selected: false}))
      .map(item => {
        if (payConfigList.find(_item => _item.payWay === item.payWay)) return {...item, selected: true}
        return item
      })
  }, [payConfigList, payWayList])

  // 财务科目渠道
  const [billConfigList, setBillConfigList] = useState<BillVO[]>([])
  const [initialBillConfigList, setInitialConfigList] = useState<BillVO[]>([])

  useImperativeHandle(ref, () => ({
    submit: async () => {
      await condoModel.condoProjectContractConfigSave({condoProjectId, payConfigList, billConfigList})
      message.success('营业科目设置成功！')
      history.goBack()
    },
  }))

  useEffect(() => {
    const payParentList = commonModel.getCodeArraySync('b').filter(item => item.id !== 'b.l')
    let arr = []
    payParentList.forEach(item => {
      const payChildList = commonModel.getCodeArraySync(item.id).map(_item => ({
        billCategory: item.id,
        billCategoryName: item.label,
        billTypeCode: _item.id,
        billTypeName: _item.label,
        selected: false,
      }))
      arr = [...arr, ...payChildList]
    })
    setInitialConfigList(arr)
  }, [])

  const billConfigListReduce: {[key: string]: BillVO[]} = useMemo(() => {
    return initialBillConfigList.reduce((result, item) => {
      item.selected = billConfigList.map(_item => _item.billTypeCode).includes(item.billTypeCode)
      if (!result[item.billCategoryName]) result[item.billCategoryName] = []
      result[item.billCategoryName].push(item)
      return result
    }, {})
  }, [billConfigList, initialBillConfigList])
  // 营业科目设置
  const queryContractConfig = useCallback(() => {
    condoModel.condoProjectContractConfigListSelected({condoProjectId}).then(({response: {data}}) => {
      const {payConfigList, billConfigList} = data
      setPayConfigList(payConfigList)
      setBillConfigList(billConfigList)
    })
  }, [condoProjectId])

  useEffect(() => {
    if (condoProjectId && auth('001097001')) {
      queryContractConfig()
    }
  }, [condoProjectId, queryContractConfig, auth])

  return (
    <>
      <div className={styles.sectionHeader}>
        <div className={styles.header}>
          <span>支付渠道设置</span>
        </div>
        <div style={{padding: '20px 20px'}}>
          <Row gutter={[0, 10]}>
            {renderPayConfigList.map(item => (
              <Col key={item.payWay} span={6}>
                <Checkbox
                  checked={item.selected}
                  value={item.payWay}
                  onChange={e => {
                    const payWay = e.target.value
                    setPayConfigList(prevState => {
                      if (!prevState.find(item => item.payWay === payWay))
                        return [...prevState, {...item, selected: true}]
                      return prevState.filter(item => item.payWay !== payWay)
                    })
                  }}
                >
                  {item.payWayName}
                </Checkbox>
              </Col>
            ))}
          </Row>
        </div>
      </div>
      <div className={styles.sectionHeader} style={{marginTop: 10}}>
        <div className={styles.header}>
          <span>账单科目设置</span>
        </div>
        <div className={styles.sectionContent}>
          {Object.keys(billConfigListReduce).map(key => (
            <Row className={styles.box} key={key}>
              <Col flex='144px' className={styles.boxLeft}>
                {key}
              </Col>
              <Col flex='auto'>
                <Row>
                  {billConfigListReduce[key].map(item => (
                    <Col span={6} key={item.billTypeCode}>
                      <Checkbox
                        value={item.billTypeCode}
                        checked={item.selected}
                        onChange={e => {
                          const billTypeCode = e.target.value
                          setBillConfigList(prevState => {
                            if (!prevState.find(_item => _item.billTypeCode === billTypeCode))
                              return [...prevState, {...item, selected: true}]
                            return prevState.filter(_item => _item.billTypeCode !== billTypeCode)
                          })
                        }}
                      >
                        {item.billTypeName}
                      </Checkbox>
                    </Col>
                  ))}
                </Row>
              </Col>
            </Row>
          ))}
        </div>
      </div>
    </>
  )
}

const PayConfigComponent = React.forwardRef(_PayConfigComponent)
export default PayConfigComponent
