import React, {useCallback, useEffect, useImperativeHandle, useState} from 'react'
import {Button, Col, DatePicker, Descriptions, Drawer, Form, Input, Row, Select, Space, Table, Typography} from 'antd'
import commonStyles from '@/assets/styles/common.module.less'
import moment from 'moment'
import {FORMAT_DATE, FORMAT_DATE_END, FORMAT_DATE_START} from '@/common/date'
import {stewardModel} from '@/store/models/Steward'
import {objectToArray} from '@/store/models/Common'
import {getPaginationProps} from '@/common/biz/getPaginationProps'
import DividerLine from '@/pages/property/component/DividerLine'
import TimeLine from '@/pages/saleManagement/component/TimeLine'
import {downloadBlob} from '@/utils/util'

const vacationTypeCode = {
  1: '年假',
  2: '事假',
  3: '病假',
  4: '调休',
  5: '婚假',
  6: '产假',
  7: '陪产假',
  8: '其他',
  9: '丧假',
}

const vacationTypeList = objectToArray(vacationTypeCode)

const applyStatusCode = {
  '1': '审批中',
  '2': '已通过',
  '3': '已驳回',
  '4': '已撤销',
  '6': '通过后撤销',
  '7': '已删除',
  '10': '已支付',
}

const applyStatusList = objectToArray(applyStatusCode)

function calcVacation({
  vacationBeginDate,
  vacationEndDate,
  vacationBeginMidday,
  vacationEndMidday,
}: Pick<
  StewardTransferVacationVO,
  'vacationBeginDate' | 'vacationBeginMidday' | 'vacationEndDate' | 'vacationEndMidday'
>) {
  let diff = moment(vacationEndDate).diff(vacationBeginDate, 'day')
  // 1天：AM => PM，PM => AM
  // 0.5天：AM => AM，PM => PM
  if (
    (vacationBeginMidday === 'AM' && vacationEndMidday === 'PM') ||
    (vacationBeginMidday === 'PM' && vacationEndMidday === 'AM')
  ) {
    diff = diff + 1
  }

  if (
    (vacationBeginMidday === 'AM' && vacationEndMidday === 'AM') ||
    (vacationBeginMidday === 'PM' && vacationEndMidday === 'PM')
  ) {
    diff = diff + 0.5
  }

  return `${[vacationBeginDate, vacationEndDate].map(item => moment(item).format(FORMAT_DATE)).join('至')}（${diff}天）`
}

const StewardTakeLeave = React.forwardRef((props, ref) => {
  const [form] = Form.useForm()

  const [vacationList, setVacationList] = useState<StewardTransferVacationVO[]>([])
  const [vacationTotal, setVacationTotal] = useState(0)
  const [vacationDetail, setVacationDetail] = useState({} as StewardTransferVacationVO)

  const queryTransferVacationList = useCallback(() => {
    const reqParams = form.getFieldsValue(true)
    stewardModel.adminStewardTransferVacationList(reqParams).then(({response: {data, total}}) => {
      setVacationList(data)
      setVacationTotal(total)
    })
  }, [form])

  useEffect(() => {
    queryTransferVacationList()
  }, [queryTransferVacationList])

  const [drawerVisible, setDrawerVisible] = useState(false)

  useImperativeHandle(ref, () => {
    return {
      export: () => {
        const values = form.getFieldsValue(true)
        downloadBlob({url: '/admin/steward-transfer-vacation/export', body: {...values, pageable: false}})
      },
    }
  })

  const vacationLoading = stewardModel.adminStewardTransferVacationList.useLoading()

  return (
    <>
      <div className={commonStyles.filterSection}>
        <Form
          form={form}
          initialValues={{pageNum: 1, pageSize: 10, pageable: true}}
          onFinish={queryTransferVacationList}
          onReset={queryTransferVacationList}
        >
          <Row gutter={20}>
            <Col span={8}>
              <Form.Item label={'请假类型'} name={'vacationTypeId'}>
                <Select placeholder={'请选择'} allowClear>
                  {vacationTypeList.map(item => (
                    <Select.Option key={item.id} value={item.id}>
                      {item.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label={'请假起止'}
                name={'vacationBeginDate'}
                getValueProps={value => ({
                  value: value ? [moment(value), moment(form.getFieldValue('vacationEndDate'))] : value,
                })}
                getValueFromEvent={value => {
                  form.setFieldsValue({vacationEndDate: value?.[1]?.format(FORMAT_DATE_END)})
                  return value?.[0]?.format(FORMAT_DATE_START)
                }}
              >
                <DatePicker.RangePicker />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={'申请人'} name={'stewardName'}>
                <Input placeholder={'请输入'} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label={'申请日期'}
                name={'insertTimeBegin'}
                getValueProps={value => ({
                  value: value ? [moment(value), moment(form.getFieldValue('insertTimeEnd'))] : value,
                })}
                getValueFromEvent={value => {
                  form.setFieldsValue({insertTimeEnd: value?.[1]?.format(FORMAT_DATE_END)})
                  return value?.[0]?.format(FORMAT_DATE_START)
                }}
              >
                <DatePicker.RangePicker />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={'审批状态'} name={'applyStatus'}>
                <Select placeholder={'请选择'} allowClear>
                  {applyStatusList.map(item => (
                    <Select.Option key={item.id} value={item.id}>
                      {item.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row justify={'end'}>
            <Space>
              <Button type={'primary'} htmlType={'submit'} onClick={() => form.setFieldsValue({pageNum: 1})}>
                查询
              </Button>
              <Button htmlType={'reset'}>重置</Button>
            </Space>
          </Row>
        </Form>
      </div>

      <div className={commonStyles.tableSection}>
        <Table
          rowKey={'applyId'}
          loading={vacationLoading}
          columns={[
            {title: '申请ID', dataIndex: 'applyId'},
            {title: '请假类型', dataIndex: 'vacationTypeName'},
            {
              title: '请假起止',
              dataIndex: 'vacationBeginDate',
              render: (vacationBeginDate, record) =>
                calcVacation({
                  vacationBeginDate: vacationBeginDate,
                  vacationEndDate: record.vacationEndDate,
                  vacationEndMidday: record.vacationEndMidday,
                  vacationBeginMidday: record.vacationBeginMidday,
                }),
            },
            {title: '审批状态', dataIndex: 'applyStatus', render: applyStatus => applyStatusCode[applyStatus]},
            {
              title: '申请人',
              dataIndex: 'stewardName',
              render: (stewardName, record) => `${stewardName}(${record.stewardId})`,
            },
            {title: '申请时间', dataIndex: 'insertTime'},
            {
              title: '操作',
              dataIndex: 'applyId',
              render: (_, record) => {
                return (
                  <Button
                    type={'link'}
                    style={{padding: 0}}
                    onClick={() => {
                      setDrawerVisible(true)
                      setVacationDetail(record)
                    }}
                  >
                    查看
                  </Button>
                )
              },
            },
          ]}
          dataSource={vacationList}
          pagination={getPaginationProps(form, vacationTotal)}
        />
      </div>

      <Drawer
        visible={drawerVisible}
        title={'申请详情'}
        width={800}
        onClose={() => {
          setDrawerVisible(false)
        }}
        destroyOnClose
      >
        <DividerLine title={'基本信息'} bottom={15} />
        <Descriptions column={2}>
          <Descriptions.Item label='申请ID'>{vacationDetail.applyId}</Descriptions.Item>
          <Descriptions.Item label='请假类型'>{vacationDetail.vacationTypeName}</Descriptions.Item>
          <Descriptions.Item label='请假起止'>
            {calcVacation({
              vacationBeginDate: vacationDetail.vacationBeginDate,
              vacationBeginMidday: vacationDetail.vacationBeginMidday,
              vacationEndDate: vacationDetail.vacationEndDate,
              vacationEndMidday: vacationDetail.vacationEndMidday,
            })}
          </Descriptions.Item>
          <Descriptions.Item label='申请人'>{`${vacationDetail.stewardName}(${vacationDetail.stewardId})`}</Descriptions.Item>
          <Descriptions.Item label='申请时间'>{vacationDetail.insertTime}</Descriptions.Item>
          <Descriptions.Item label='请假事由'>{vacationDetail.vacationReason}</Descriptions.Item>
        </Descriptions>
        <DividerLine title={'审批信息'} top={15} bottom={15} />
        <TimeLine label={''} dot={<div style={{width: 16, height: 16, borderRadius: 8, backgroundColor: '#10BBB3'}} />}>
          <div>
            <Typography.Text style={{color: '#222832', fontWeight: 600}}>
              {applyStatusCode[vacationDetail.applyStatus]}
            </Typography.Text>
            <Typography.Paragraph>{vacationDetail.updateTime}</Typography.Paragraph>
          </div>
        </TimeLine>
      </Drawer>
    </>
  )
})

export default StewardTakeLeave
