import React, {useEffect, useMemo, useState} from 'react'
import {Button, Col, Row, Modal} from 'antd'
import {useHistory, useLocation} from 'react-router'
import {propertyModel} from '@/store/models/Property'
import {baseCodes} from '@/store/models/Common/baseCodes'
import {getImg} from '@/utils/util'
import '../index.less'
import {commonModel} from '@/store/models/Common'
import {Breadcrumb} from '@/components/Breadcrumb'
import commonStyles from '@/assets/styles/common.module.less'

export default function ContractDetail() {
  const history = useHistory()
  const location = useLocation<any>()

  // 租约id
  const contractId = useMemo(() => location.state?.contractId, [location.state])
  // 合约状态
  // const contractStatusCode = useMemo(() => baseCodes.contractStatus, [])
  const contractStatusCode = commonModel.getCodeSync('contract.status')
  // 合同类型
  const contractTypeCode = useMemo(() => baseCodes.contractType, [])
  // 收租日类型
  const rentCollectTypeCode = useMemo(() => baseCodes.rentCollectType, [])
  // 退房原因
  const checkOutReasonCode = useMemo(() => baseCodes.checkOutReason, [])
  // 证件类型
  const contractCertType = commonModel.getCodeSync('contract.certType')

  const contractDetail = {
    payType(params: Pick<ContractDetailVO, 'rentPayType' | 'rentPledgeType'>) {
      const {rentPayType, rentPledgeType} = params
      return [rentPayType && `付${rentPayType}`, rentPledgeType && `押${rentPledgeType}`].filter(item => item).join('')
    },
  }

  // 租约详情
  const [detailData, setDetailData] = useState({} as ContractVO)
  // 合约详情
  const [contract, setContract] = useState({} as ContractDetailVO)
  // 结账详情
  const [checkOut, setCheckOut] = useState({} as ContractCheckOutVO)

  // 选中的图片
  const [selectImg, setSelectImg] = useState<{imgUrl: string; visible: boolean}>({imgUrl: '', visible: false})

  useEffect(function () {
    propertyModel.queryContractDetail({contractId}).then(({response: {data}}) => {
      console.log('租约详情 data =>', data)
      setDetailData(data)

      const contractItem = data.contractDetailList?.shift()
      if (contractItem?.contractId) {
        setContract(() => {
          return {
            ...contractItem,
            rentExtra: JSON.parse(contractItem.rentExtra as string),
            furnitureExtra: JSON.parse(contractItem.furnitureExtra as string),
          }
        })
      }
      setCheckOut(data.checkOutDetail)
    })
  }, [])

  return (
    <div className='pageDetail'>
      <Breadcrumb path={['房态相关', '租约列表', '租约信息']} />

      <div style={{marginTop: '20px'}} />
      <div className={commonStyles.tableSection}>
        <div className='table'>
          <Row className='table--rowHeader'>
            <Col>基础信息：</Col>
            <Col>（租约ID：{detailData.contractId}）</Col>
          </Row>

          {detailData.lesseeType === 'person' ? (
            <>
              <Row className='table--rowNormal'>
                <Col span={8}>承租人：{detailData.lesseeCertName}</Col>
                <div className='vertical' />
                <Col span={8}>证件类型：{contractCertType[detailData.lesseeCertType]}</Col>
                <div className='vertical' />
                <Col span={7}>证件号码：{detailData.lesseeCertNo}</Col>
              </Row>
              <Row className='table--rowNormal'>
                <Col span={8}>手机号：{detailData.lesseeMobile}</Col>
                <div className='vertical' />
              </Row>
            </>
          ) : (
            <>
              <Row className='table--rowNormal'>
                <Col span={8}>公司名称：{detailData.lesseeOrganName}</Col>
                <div className='vertical' />
                <Col span={8}>公司营业执照：{detailData.lesseeOrganCode}</Col>
                <div className='vertical' />
                <Col span={7}>联系人：{detailData.lesseeCertName}</Col>
              </Row>
              <Row className='table--rowNormal'>
                <Col span={8}>联系人手机：{detailData.lesseeMobile}</Col>
                <div className='vertical' />
                <Col span={8}>证件类型：{contractCertType[detailData.lesseeCertType]}</Col>
                <div className='vertical' />
                <Col span={7}>证件号码：{detailData.lesseeCertNo}</Col>
              </Row>
            </>
          )}

          <Row className='table--rowNormal'>
            <Col span={8}>租约状态：{contractStatusCode[detailData.contractStatus]}</Col>
            <div className='vertical' />
            <Col span={8}>租约起始日期：{detailData.contractBegin}</Col>
            <div className='vertical' />
            <Col span={7}>租约终止日期：{detailData.contractEnd}</Col>
          </Row>
          <Row className='table--rowNormal'>房源地址：{detailData.propertyTitle}</Row>
          <Row className='table--rowNormal'>
            <Col span={8}>房屋租金：{contract?.rentPrice}</Col>
            <div className='vertical' />
            <Col span={8}>房屋押金：{contract?.rentPledge}</Col>
            <div className='vertical' />
            <Col span={7}>
              付款方式：
              {contractDetail.payType({rentPayType: contract?.rentPayType, rentPledgeType: contract?.rentPledgeType})}
            </Col>
          </Row>
          <Row className='table--rowNormal'>
            <Col span={8}>
              收租日：{`${rentCollectTypeCode[contract?.rentCollectType]}-${contract?.rentCollectDay}`}
            </Col>
            <div className='vertical' />
            <Col span={8}>合同类型：{contractTypeCode[contract?.contractType]}</Col>
            <div className='vertical' />
            <Col span={7}>签约时间：{contract?.insertTime}</Col>
          </Row>

          {/** 更多信息 */}
          <Row className='table--rowHeader'>更多信息</Row>
          <Row className='table--rowNormal'>
            租金包含费用（仅电子合同）：
            {(contract?.rentExtra as {id: string; num: number; label: string}[])?.map(item => item.label)?.join(',')}
          </Row>
          <Row className='table--rowNormal'>
            家具清单（仅电子合同）：
            {(contract?.furnitureExtra as {id: string; num: number; label: string}[])
              ?.map(item => item.label)
              ?.join(',')}
          </Row>
          <Row className='table--rowNormal'>附加条款（仅电子合同）：{contract?.remark}</Row>
          <Row className='table--rowNormal'>合同编号（仅纸质合同）：{contract?.contractId}</Row>
          <Row className='table--rowNormal'>备注：{contract?.remark}</Row>
          <Row className='table--rowImg'>
            合同照片：
            {contract?.imgList?.map(item => {
              return (
                <div key={item.imgId} className='table--img'>
                  <img
                    src={getImg(item.path)}
                    width={88}
                    height={88}
                    onClick={() => setSelectImg({imgUrl: item.path, visible: true})}
                  />
                </div>
              )
            })}
          </Row>

          {/** 退房结算信息 */}
          <Row className='table--rowHeader'>退房结算信息</Row>
          <Row className='table--rowNormal'>
            <Col span={8}>退房结算日期：{checkOut?.billEndDate}</Col>
            <div className='vertical' />
            <Col span={8}>实收总计：{checkOut?.totalAmt}</Col>
            <div className='vertical' />
            <Col span={7}>坏账总计：{checkOut?.badAmt}</Col>
          </Row>
          <Row className='table--rowNormal'>
            <Col span={8}>退款总额：{checkOut?.refundAmt}</Col>
            <div className='vertical' />
            <Col span={8}>退款途径：{checkOut?.rfdPayWayName}</Col>
            <div className='vertical' />
            <Col span={7}>退房原因：{checkOutReasonCode[detailData.checkOutReason]}</Col>
          </Row>
          <Row className='table--rowNormal'>退房备注：{detailData.checkOutRemark}</Row>
          <Row className='table--rowNormal'>退款备注：{checkOut?.rfdRemark}</Row>

          {/** 账单信息 */}
          <Row className='table--rowHeader'>账单信息</Row>
          <Row justify={'center'} className='table--rowNormal'>
            <Button
              type='link'
              onClick={() => {
                console.log('propertyCode =>', detailData.propertyCode)
                // const location = {
                //   pathname: '/bill',
                //   state: {propertyCode: detailData.propertyCode},
                // }
                history.push('/bill', {propertyCode: detailData.propertyCode})
              }}
            >
              查看该租约的账单列表
            </Button>
          </Row>
        </div>
        <div className='backView'>
          <Button type={'primary'} className='backBtn' onClick={() => history.goBack()}>
            返回
          </Button>
        </div>
      </div>

      {/** 预览图片 */}
      <Modal
        visible={selectImg.visible}
        title={'图片预览'}
        footer={null}
        onCancel={() => setSelectImg(state => ({...state, visible: false}))}
      >
        <img src={getImg(selectImg.imgUrl)} width='100%' />
      </Modal>
    </div>
  )
}
