import {Model} from '@redux-model/web'
import {$api} from '@/store/service'

export interface SysPermissionModel {}

class SysPermission extends Model<SysPermissionModel> {
  initReducer() {
    return {}
  }

  querySysPermissionList = $api.action(params => this.post('/sys-permission/list').body(params))

  //权限列表
  sysPermissionList = $api.action((params: Partial<SysListDTO>) =>
    this.post<ApiResponse<SysPermissionVO[]>>('/sys-permission/list').body({
      pageNum: 1,
      pageSize: 10,
      pageable: true,
      ...params,
    })
  )

  //新增或更新权限管理
  sysPermissionEdit = $api.action((params: Partial<SysPermissionDTO>) => this.post('/sys-permission/edit').body(params))

  //删除权限
  sysPermissionDelete = $api.action((permissionId: string) => this.post('/sys-permission/delete').body({permissionId}))
}

export const sysPermissionModel = new SysPermission()
