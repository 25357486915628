import React, {useImperativeHandle, useState} from 'react'
import {Button, Form, Input, message, Modal, Row, Select, Space} from 'antd'
import {storageModel} from '@/store/models/Storage'
import {useCodeArraySync} from '@/store/models/Common'
import AreaCascader from '@/components/AreaCascader'

const {Option} = Select
function _AddAddress(props, ref) {
  const [visible, setVisible] = useState(false)
  const [form] = Form.useForm()
  const addressTypeArr = useCodeArraySync('purchase.addressType').filter(item => item.id !== '0')

  useImperativeHandle(ref, () => ({
    edit: (info?: PurchaseAddressVO) => {
      setVisible(true)
      if (info) {
        form.setFieldsValue({
          area: {
            provinceCode: info.provinceCode,
            cityCode: info.cityCode,
            districtCode: info.districtCode,
            bizCircleCode: info.bizCircleCode,
            province: info.province,
            city: info.city,
            district: info.district,
            bizCircle: info.bizCircle,
          },
          consigneeName: info.consigneeName,
          consigneeMobile: info.consigneeMobile,
          address: info.address,
          addressType: info.addressType,
          addressId: info.addressId,
        })
      }
    },
  }))

  return (
    <Modal
      visible={visible}
      title={form.getFieldValue('addressId') ? '编辑地址' : '添加新地址'}
      footer={null}
      width={600}
      onCancel={() => setVisible(false)}
      afterClose={() => {
        form.resetFields()
      }}
      destroyOnClose
      centered
    >
      <Form
        form={form}
        onFinish={async () => {
          const {area, ...values} = form.getFieldsValue(true)
          await storageModel.purchaseAddressSave({...area, ...values})
          message.success('添加成功')
          props.onSuccess()
          setVisible(false)
        }}
        labelCol={{flex: '80px'}}
        labelAlign={'left'}
        initialValues={{
          area: {
            province: '上海市',
            provinceCode: '310000',
            city: '上海市',
            cityCode: '310100',
          },
        }}
        onReset={() => setVisible(false)}
        wrapperCol={{span: 15}}
      >
        <Form.Item label={'收货人'} name={'consigneeName'} rules={[{required: true, message: '请输入收货人姓名'}]}>
          <Input placeholder={'请输入'} allowClear />
        </Form.Item>
        <Form.Item
          label={'手机号'}
          name={'consigneeMobile'}
          rules={[
            {
              required: true,
              validator: (_, value) => {
                const telReg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
                if (!value) {
                  return Promise.reject(new Error('请输入收货人手机号'))
                } else if (!telReg.test(value)) {
                  return Promise.reject(new Error('请输入正确的手机号'))
                } else {
                  return Promise.resolve()
                }
              },
            },
          ]}
        >
          <Input placeholder={'请输入'} allowClear />
        </Form.Item>

        <Form.Item
          label={'所在地区'}
          wrapperCol={{}}
          required
          rules={[
            {
              // required: true,
              validator: (rule, value, callback) => {
                if (!value) return callback('所在地区不能为空')
                if (!value?.provinceCode) return callback('省份不能为空')
                if (!value?.cityCode) return callback('城市不能为空')
                if (!value?.districtCode) return callback('区不能为空')
                // if (!value?.bizCircleCode) return callback('商圈不能为空')
                callback()
              },
            },
          ]}
          name={'area'}
        >
          <AreaCascader type={['province', 'city', 'district']} />
        </Form.Item>
        <Form.Item label={'详细地址'} name={'address'} rules={[{required: true, message: '请输入详细地址'}]}>
          <Input placeholder={'道路、门牌号、小区、楼栋号、单元'} allowClear />
        </Form.Item>
        <Form.Item label={'类别'} name={'addressType'} rules={[{required: true, message: '请选择类别'}]}>
          <Select disabled={form.getFieldValue('addressType') === '4'} allowClear placeholder={'请选择'}>
            {addressTypeArr.map(item => (
              <Option value={item.id} key={item.id} disabled={item.id === '4'}>
                {item.label}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Row justify={'end'}>
          <Space>
            <Button htmlType={'reset'}>取消</Button>
            <Button type={'primary'} htmlType={'submit'}>
              提交
            </Button>
          </Space>
        </Row>
      </Form>
    </Modal>
  )
}

export const AddAddress = React.forwardRef(_AddAddress)
