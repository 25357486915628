import React, {useCallback, useEffect, useState} from 'react'
import {Page} from '@/components/Page'
import commonStyles from '@/assets/styles/common.module.less'
import {
  Badge,
  Button,
  Col,
  DatePicker,
  Form,
  FormInstance,
  Input,
  message,
  Modal,
  Row,
  Select,
  Space,
  Table,
  Tabs,
} from 'antd'
import {stewardModel} from '@/store/models/Steward'
import {useCodeArraySync, useCodeSync} from '@/store/models/Common'
import {getPaginationProps} from '@/common/biz/getPaginationProps'
import {DownOutlined, UpOutlined} from '@ant-design/icons'
import {userModel} from '@/store/models/User'
import {
  TodoDetail,
  useBeiKePreContact,
  useBillPending,
  useChangeAppointment,
  useConfirm,
  useLandlordSatisfaction,
  useQC,
  useTenantSatisfaction,
} from '@/pages/service/TodoComponent'
import {useCreateOnlineQa} from '@/pages/cleanManagement/hooks/useCreateOnlineQa'
import {cleanModel} from '@/store/models/Clean'
import {useCleanOnlineQualified} from '@/pages/cleanManagement/hooks/useCleanOrderPreview'
import moment from 'moment'
import {FORMAT_DATE_END, FORMAT_DATE_START} from '@/common/date'

export default function MineTodo() {
  const todoListArr = useCodeArraySync('role.todo.list')
  const todoListCode = useCodeSync('role.todo.list')
  // const todoStatusArr = useCodeArraySync('sys.todo.status')
  const todoStatusCode = useCodeSync('sys.todo.status')
  const callStatusArr = useCodeArraySync('todo.call.status')
  const callStatusCode = useCodeSync('todo.call.status')
  // console.log('todoListArr', todoListArr)
  // console.log('todoStatusCode', todoStatusCode)
  // console.log('todoListCode', todoListCode)

  const userInfo = userModel.useData(data => data.userInfo)

  const queryTodoList = useCallback((args: SysTodoDTO) => stewardModel.stewardOrderTodoList(args), [])

  // 更多筛选
  const [collapse, setCollapse] = useState(false)

  // 待处理
  const [form1] = Form.useForm()
  const [todoList1, setTodoList1] = useState<SysTodoVO[]>([])
  const [todoTotal1, setTodoTotal1] = useState(0)

  const queryTodo = useCallback(() => {
    const {assignTime, finishTime, insert, billCreateTime, timeFinished, ...formValues} = form1.getFieldsValue(true)
    const [assignTimeBegin, assignTimeEnd] = assignTime ?? []
    const [finishTimeBegin, finishTimeEnd] = finishTime ?? []
    const [billCreateTimeBegin, billCreateTimeEnd] = billCreateTime ?? []
    const [insertBegin, insertEnd] = insert ?? []
    const [timeFinishedBegin, timeFinishedEnd] = timeFinished ?? []
    if ([assignTime, finishTime, insert, billCreateTime, timeFinished].every(item => !item))
      return message.warning('分配时间、处理完成时间、创建时间、下单时间、订单完成时间其中必选一项')
    queryTodoList({
      serviceStaffId: userInfo.userId,
      todoStatus: '2',
      ...formValues,
      assignTimeBegin,
      assignTimeEnd,
      finishTimeBegin,
      finishTimeEnd,
      insertBegin,
      insertEnd,
      billCreateTimeBegin,
      billCreateTimeEnd,
      timeFinishedBegin,
      timeFinishedEnd,
    }).then(({response: {data, total}}) => {
      setTodoList1(data)
      setTodoTotal1(total)
    })
  }, [form1, queryTodoList, userInfo.userId])

  useEffect(() => {
    queryTodo()
  }, [queryTodo])

  // 已处理
  const [form2] = Form.useForm()
  const [todoList2, setTodoList2] = useState<SysTodoVO[]>([])
  const [todoTotal2, setTodoTotal2] = useState(0)

  const queryDo = useCallback(() => {
    const {assignTime, finishTime, insert, billCreateTime, timeFinished, ...formValues} = form2.getFieldsValue(true)
    const [assignTimeBegin, assignTimeEnd] = assignTime ?? []
    const [finishTimeBegin, finishTimeEnd] = finishTime ?? []
    const [billCreateTimeBegin, billCreateTimeEnd] = billCreateTime ?? []
    const [insertBegin, insertEnd] = insert ?? []
    const [timeFinishedBegin, timeFinishedEnd] = timeFinished ?? []
    if ([assignTime, finishTime, insert, billCreateTime, timeFinished].every(item => !item))
      return message.warning('分配时间、处理完成时间、创建时间、下单时间、订单完成时间其中必选一项')
    queryTodoList({
      serviceStaffId: userInfo.userId,
      todoStatus: '3',
      ...formValues,
      assignTimeBegin,
      assignTimeEnd,
      finishTimeBegin,
      finishTimeEnd,
      insertBegin,
      insertEnd,
      billCreateTimeBegin,
      billCreateTimeEnd,
      timeFinishedBegin,
      timeFinishedEnd,
    }).then(({response: {data, total}}) => {
      setTodoList2(data)
      setTodoTotal2(total)
    })
  }, [form2, queryTodoList, userInfo.userId])

  useEffect(() => {
    queryDo()
  }, [queryDo])

  const listLoading = stewardModel.stewardOrderTodoList.useLoading()

  // 待办详情
  const [detailVisible, setDetailVisible] = useState(false)
  const [todoDetails, setTodoDetails] = useState({} as SysTodoVO)
  const [qaOnlineRate, setQaOnlineRate] = useState(0)

  const queryTodoDetails = async todoId => {
    const {
      response: {data},
    } = await stewardModel.stewardOrderTodoDetails({todoId})
    setTodoDetails(data)
  }

  const toRenderForm = (form: FormInstance, onFinish: () => void) => {
    return (
      <Form
        form={form}
        initialValues={{
          pageNum: 1,
          pageSize: 10,
          pageable: true,
          insert: [moment().subtract(31, 'day').format(FORMAT_DATE_START), moment().format(FORMAT_DATE_END)],
        }}
        onFinish={onFinish}
        onReset={onFinish}
      >
        <Row gutter={20}>
          <Col span={8}>
            <Form.Item name={'todoType'} label={'待办类型'}>
              <Select
                placeholder={'请选择'}
                options={todoListArr.map(item => ({label: item.label, value: item.id}))}
                allowClear
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name={'assignTime'}
              label={'分配时间'}
              getValueProps={value => ({value: value ? value.map(item => moment(item)) : []})}
              getValueFromEvent={(_, dateStrings) => {
                const [assignTimeBegin, assignTimeEnd] = dateStrings
                if (assignTimeBegin && assignTimeEnd) {
                  return [
                    moment(assignTimeBegin).format(FORMAT_DATE_START),
                    moment(assignTimeEnd).format(FORMAT_DATE_END),
                  ]
                }
                return undefined
              }}
              rules={[
                {
                  validator: (rule, value, callback) => {
                    if (value) {
                      const [assignTimeBegin, assignTimeEnd] = value
                      const intervalDay = moment(assignTimeEnd).diff(assignTimeBegin, 'day')
                      if (intervalDay > 31) return callback('分配时间间隔不超过31天')
                    }
                    return callback()
                  },
                },
              ]}
            >
              <DatePicker.RangePicker style={{width: '100%'}} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name={'finishTime'}
              label={'处理完成时间'}
              getValueProps={value => ({value: value ? value.map(item => moment(item)) : []})}
              getValueFromEvent={(_, dateStrings) => {
                const [finishTimeBegin, finishTimeEnd] = dateStrings
                if (finishTimeBegin && finishTimeEnd) {
                  return [
                    moment(finishTimeBegin).format(FORMAT_DATE_START),
                    moment(finishTimeEnd).format(FORMAT_DATE_END),
                  ]
                }
                return undefined
              }}
              rules={[
                {
                  validator: (rule, value, callback) => {
                    if (value) {
                      const [finishTimeBegin, finishTimeEnd] = value
                      const intervalDay = moment(finishTimeEnd).diff(finishTimeBegin, 'day')
                      if (intervalDay > 31) return callback('处理完成时间间隔不超过31天')
                    }
                    return callback()
                  },
                },
              ]}
            >
              <DatePicker.RangePicker style={{width: '100%'}} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name={'callStatusList'} label={'拨打状态'}>
              <Select
                placeholder={'请选择'}
                options={callStatusArr.map(item => ({label: item.label, value: item.id}))}
                mode={'multiple'}
                allowClear
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name={'insert'}
              label={'创建时间'}
              getValueProps={value => ({value: value ? value.map(item => moment(item)) : []})}
              getValueFromEvent={(_, dateStrings) => {
                const [insertBegin, insertEnd] = dateStrings
                if (insertBegin && insertEnd) {
                  return [moment(insertBegin).format(FORMAT_DATE_START), moment(insertEnd).format(FORMAT_DATE_END)]
                }
                return undefined
              }}
              rules={[
                {
                  validator: (rule, value, callback) => {
                    if (value) {
                      const [insertBegin, insertEnd] = value
                      const intervalDay = moment(insertEnd).diff(insertBegin, 'day')
                      if (intervalDay > 31) return callback('创建时间间隔不超过31天')
                    }
                    return callback()
                  },
                },
              ]}
            >
              <DatePicker.RangePicker style={{width: '100%'}} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name={'orderId'} label={'订单编号'}>
              <Input placeholder={'请输入'} allowClear />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name={'categoryName'} label={'服务项目'}>
              <Input placeholder={'请输入'} allowClear />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name={'orderMakerType'} label={'下单人类型'}>
              <Select
                placeholder={'请选择'}
                options={[
                  {label: '租客', value: 'lessee'},
                  {label: '房东', value: 'lessor'},
                ]}
                allowClear
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name={'stewardName'} label={'管家'}>
              <Input placeholder={'请输入'} allowClear />
            </Form.Item>
          </Col>
          {collapse && (
            <>
              <Col span={8}>
                <Form.Item name={'propertyAddr'} label={'房源地址'}>
                  <Input placeholder={'请输入'} allowClear />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name={'rentConfirmationStatus'} label={'确认状态'}>
                  <Select
                    placeholder={'请选择'}
                    options={[
                      {label: '未确认', value: 1},
                      {label: '部分确认', value: 2},
                      {label: '已确认', value: 3},
                    ]}
                    mode={'multiple'}
                    allowClear
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name={'lessorName'} label={'客户姓名'}>
                  <Input placeholder={'请输入'} allowClear />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name={'lessorMobile'} label={'客户手机号'}>
                  <Input placeholder={'请输入'} allowClear />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name={'stewardFeeType'} label={'维修收费类型'}>
                  <Select
                    placeholder={'请选择'}
                    options={[
                      {label: '全包收费', value: '1'},
                      {label: '按次计费', value: '2'},
                    ]}
                    allowClear
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name={'billCreateTime'}
                  label={'下单时间'}
                  getValueProps={value => ({value: value ? value.map(item => moment(item)) : []})}
                  getValueFromEvent={(_, dateStrings) => {
                    const [billCreateTimeBegin, billCreateTimeEnd] = dateStrings
                    if (billCreateTimeBegin && billCreateTimeEnd) {
                      return [
                        moment(billCreateTimeBegin).format(FORMAT_DATE_START),
                        moment(billCreateTimeEnd).format(FORMAT_DATE_END),
                      ]
                    }
                    return undefined
                  }}
                  rules={[
                    {
                      validator: (rule, value, callback) => {
                        if (value) {
                          const [billCreateTimeBegin, billCreateTimeEnd] = value
                          const intervalDay = moment(billCreateTimeEnd).diff(billCreateTimeBegin, 'day')
                          if (intervalDay > 31) return callback('下单时间间隔不超过31天')
                        }
                        return callback()
                      },
                    },
                  ]}
                >
                  <DatePicker.RangePicker style={{width: '100%'}} />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name={'timeFinished'}
                  label={'订单完成时间'}
                  getValueProps={value => ({value: value ? value.map(item => moment(item)) : []})}
                  getValueFromEvent={(_, dateStrings) => {
                    const [timeFinishedBegin, timeFinishedEnd] = dateStrings
                    if (timeFinishedBegin && timeFinishedEnd) {
                      return [
                        moment(timeFinishedBegin).format(FORMAT_DATE_START),
                        moment(timeFinishedEnd).format(FORMAT_DATE_END),
                      ]
                    }
                    return undefined
                  }}
                  rules={[
                    {
                      validator: (rule, value, callback) => {
                        if (value) {
                          const [timeFinishedBegin, timeFinishedEnd] = value
                          const intervalDay = moment(timeFinishedEnd).diff(timeFinishedBegin, 'day')
                          if (intervalDay > 31) return callback('订单完成时间间隔不超过31天')
                        }
                        return callback()
                      },
                    },
                  ]}
                >
                  <DatePicker.RangePicker style={{width: '100%'}} />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name={'appName'} label={'渠道'}>
                  <Input placeholder={'请输入'} allowClear />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name={'appOrderId'} label={'渠道订单号'}>
                  <Input placeholder={'请输入'} allowClear />
                </Form.Item>
              </Col>
            </>
          )}
        </Row>
        <Row justify={'center'}>
          <Space size={10}>
            <Button type={'link'} style={{padding: 0}} onClick={() => setCollapse(prevState => !prevState)}>
              更多筛选条件
            </Button>
            {collapse ? <UpOutlined style={{color: '#00B7AE'}} /> : <DownOutlined style={{color: '#00B7AE'}} />}
          </Space>
        </Row>
        <Row justify={'end'}>
          <Space>
            <Button type={'primary'} htmlType={'submit'} onClick={() => form.setFieldsValue({pageNum: 1})}>
              查询
            </Button>
            <Button htmlType={'reset'} onClick={() => form.setFieldsValue({pageNum: 1})}>
              重置
            </Button>
          </Space>
        </Row>
      </Form>
    )
  }

  const tenantSatisfaction = useTenantSatisfaction()
  const landlordSatisfaction = useLandlordSatisfaction()
  const changeAppointment = useChangeAppointment()
  const qc = useQC()
  const billPending = useBillPending()
  const beikePreContact = useBeiKePreContact()
  const confirm = useConfirm()
  const createOnlineQa = useCreateOnlineQa()
  const cleanOnlineQualified = useCleanOnlineQualified()

  const tableColumns = [
    {title: '待办类型', dataIndex: 'todoType', render: value => todoListCode[value]},
    {title: '处理状态', dataIndex: 'todoStatus', render: value => todoStatusCode[value]},
    {title: '订单编号', dataIndex: 'orderId'},
    {title: '渠道订单号', dataIndex: 'appOrderId'},
    {title: '渠道', dataIndex: 'appName'},
    {title: '服务项目', dataIndex: 'categoryName'},
    {title: '客户姓名', dataIndex: 'lessorName'},
    {title: '客户手机号', dataIndex: 'lessorMobile'},
    {title: '管家', dataIndex: 'stewardName'},
    {title: '房源地址', dataIndex: 'propertyAddr'},
    {
      title: '下单时间',
      dataIndex: 'orderTime',
      sorter: {
        compare: (a, b) => new Date(a.orderTime).getTime() - new Date(b.orderTime).getTime(),
      },
    },
    {
      title: '订单完成时间',
      dataIndex: 'timeFinished',
      sorter: {
        compare: (a, b) => new Date(a.timeFinished).getTime() - new Date(b.timeFinished).getTime(),
      },
    },
    {
      title: '创建时间',
      dataIndex: 'insertTime',
      sorter: {
        compare: (a, b) => new Date(a.insertTime).getTime() - new Date(b.insertTime).getTime(),
      },
    },
    {title: '拨打状态', dataIndex: 'callStatus', render: value => callStatusCode[value]},
    {title: '拨打次数', dataIndex: 'callNum'},
    {
      title: '分配时间',
      dataIndex: 'assignTime',
      sorter: {
        compare: (a, b) => new Date(a.assignTime).getTime() - new Date(b.assignTime).getTime(),
      },
    },
    {
      title: '处理完成时间',
      dataIndex: 'finishTime',
      sorter: {
        compare: (a, b) => new Date(a.finishTime).getTime() - new Date(b.finishTime).getTime(),
      },
    },
  ]

  const todoCallLoading = stewardModel.stewardOrderTodoCall.useLoadings()
  const todoDetailsLoading = stewardModel.stewardOrderTodoDetails.useLoadings()

  const renderOperate = ({
    todoId,
    appId,
    todoType,
    todoStatus,
    callNum,
    orderId,
    relatedId,
    rentPrice,
    billBegin,
    billEnd,
  }: SysTodoVO) => [
    {
      when: todoStatus === '2' && todoType !== '09',
      title: '拨打+1',
      loading: todoCallLoading.pick(todoId),
      onClick: () => {
        Modal.confirm({
          title: '提示',
          centered: true,
          content: '确认拨打次数+1？',
          icon: null,
          closable: true,
          onOk: async () => {
            await stewardModel.stewardOrderTodoCall({first: todoId, second: callNum})
            message.success('操作成功！')
            queryTodo()
          },
        })
      },
    },
    {
      when: true,
      title: '查看',
      loading: todoDetailsLoading.pick(todoId),
      onClick: async () => {
        await queryTodoDetails(todoId)
        if (todoType === '09') {
          const {
            response: {data},
          } = await cleanModel.cleanOrderGet({orderId})
          setQaOnlineRate(data?.qaOnlineRate)
        }
        setDetailVisible(true)
      },
    },
    {
      when: todoType === '09' && todoStatus === '2',
      title: '开始质检',
      onClick: async () => {
        const {
          response: {data},
        } = await cleanModel.cleanOrderGet({orderId})
        // 保洁质检
        createOnlineQa({
          payloadList: data.payloadList,
          orderId,
          onSuccess: async () => {
            // 代办单状态流转
            await stewardModel.stewardOrderTodoHandle({todoId} as SysTodoUpdateDTO)
            queryTodo()
            queryDo()
          },
        })
      },
    },
    {
      when: todoType === '09' && todoStatus === '3',
      title: '查看质检报告',
      onClick: async () => {
        const {
          response: {data},
        } = await cleanModel.cleanOrderGet({orderId})
        cleanOnlineQualified({payloadList: data.payloadList, isOnline: true})
      },
    },
    {
      when: todoType === '08' && todoStatus === '2',
      title: '删除',
      loading: todoCallLoading.pick(todoId),
      onClick: () => {
        Modal.confirm({
          title: '提示',
          centered: true,
          content: '是否确认删除',
          icon: null,
          closable: true,
          onOk: async () => {
            await stewardModel.stewardOrderTodoDelete([todoId])
            message.success('操作成功！')
            queryTodo()
          },
        })
      },
    },
    {
      when: todoType === '12' && todoStatus === '2',
      title: '信息确认',
      onClick: () => {
        confirm({
          todoId,
          rentPrice,
          billBegin,
          billEnd,
          onSuccess: () => {
            queryTodo()
            queryDo()
          },
        })
      },
    },
    {
      when: todoStatus === '2' && !['09', '12'].includes(todoType),
      title: '填写处理结果',
      onClick: () => {
        function onSuccess() {
          queryTodo()
          queryDo()
        }

        if (['01', '13'].includes(todoType)) {
          tenantSatisfaction({todoId, appId, onSuccess})
        }
        if (['02', '03', '04'].includes(todoType)) {
          landlordSatisfaction({todoId, todoType, onSuccess})
        }
        if (['05', '14'].includes(todoType)) {
          changeAppointment({todoId, onSuccess})
        }
        if (todoType === '08') {
          qc({todoId, relatedId, onSuccess})
        }
        if (['10', '11'].includes(todoType)) {
          billPending({todoId, onSuccess})
        }

        if (['15'].includes(todoType)) {
          beikePreContact({todoId, onSuccess})
        }
      },
    },
  ]

  return (
    <Page>
      <Tabs className={commonStyles.tabs}>
        <Tabs.TabPane
          tab={
            <Badge count={todoTotal1} offset={[10, 0]}>
              待处理
            </Badge>
          }
          key={'1'}
          forceRender={true}
        >
          <div className={commonStyles.filterSection}>{toRenderForm(form1, queryTodo)}</div>

          <div className={commonStyles.tableSection}>
            <Table
              rowKey={'todoId'}
              dataSource={todoList1}
              loading={listLoading}
              scroll={{x: 'max-content'}}
              columns={[
                ...tableColumns,
                {
                  title: '操作',
                  fixed: 'right',
                  dataIndex: 'todoId',
                  render: (_, record) => {
                    return (
                      <Space>
                        {renderOperate(record)
                          .filter(item => item.when)
                          .map((item, index) => (
                            <Button
                              key={index}
                              type={'link'}
                              size={'small'}
                              style={{padding: 0}}
                              onClick={item.onClick}
                              loading={item.loading}
                            >
                              {item.title}
                            </Button>
                          ))}
                      </Space>
                    )
                  },
                },
              ]}
              pagination={getPaginationProps(form1, todoTotal1)}
            />
          </div>
        </Tabs.TabPane>
        <Tabs.TabPane
          tab={
            <Badge count={todoTotal2} offset={[10, 0]}>
              已处理
            </Badge>
          }
          key={'2'}
          forceRender={true}
        >
          <div className={commonStyles.filterSection}>{toRenderForm(form2, queryDo)}</div>

          <div className={commonStyles.tableSection}>
            <Table
              rowKey={'todoId'}
              dataSource={todoList2}
              loading={listLoading}
              scroll={{x: 'max-content'}}
              columns={[
                ...tableColumns,
                {
                  title: '操作',
                  fixed: 'right',
                  dataIndex: 'todoId',
                  render: (_, record) => {
                    return (
                      <Space>
                        {renderOperate(record)
                          .filter(item => item.when)
                          .map((item, index) => (
                            <Button
                              key={index}
                              type={'link'}
                              size={'small'}
                              style={{padding: 0}}
                              onClick={item.onClick}
                              loading={item.loading}
                            >
                              {item.title}
                            </Button>
                          ))}
                      </Space>
                    )
                  },
                },
              ]}
              pagination={getPaginationProps(form2, todoTotal2)}
            />
          </div>
        </Tabs.TabPane>
      </Tabs>

      <TodoDetail
        title={'待办详情'}
        visible={detailVisible}
        onClose={() => setDetailVisible(false)}
        todoDetails={todoDetails}
        qaOnlineRate={qaOnlineRate}
      />
    </Page>
  )
}
