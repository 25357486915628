import React, {useCallback, useEffect, useState} from 'react'
import {Page} from '@/components/Page'
import {Breadcrumb} from '@/components/Breadcrumb'
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  List,
  message,
  Modal,
  Radio,
  Row,
  Select,
  Space,
  Table,
  Tabs,
  Tag,
  Tooltip,
  Typography,
  Upload,
} from 'antd'
import commonStyles from '@/assets/styles/common.module.less'
import {getPaginationProps} from '@/common/biz/getPaginationProps'
import {stewardModel} from '@/store/models/Steward'
import {useCodeArraySync, useCodeSync} from '@/store/models/Common'
import {useAppHistory} from '@/common/hooks/useRouter'
import {InfoCircleOutlined} from '@ant-design/icons'
import {Rule} from 'rc-field-form/lib/interface'
import {usePermission} from '@/components/Permission'
import {uploadExcelData} from '@/components/UploadExcel'
import {useFocusEffect} from '@/common/hooks/useFocusEffect'

const answerCode = ['A', 'B', 'C', 'D']
const tagColorCode = {'1': 'cyan', '2': 'green', '3': 'orange', '4': 'blue'}

const paperStatusCode = {'0': '未作答', '1': '已作答'}

export default function StewardExamManagement() {
  const history = useAppHistory()
  const stewardQuestionTypeCode = useCodeSync('steward.question.type')
  const stewardQuestionTypeArray = useCodeArraySync('steward.question.type', {defaultItem: true})
  const stewardPaperStatusCode = useCodeSync('steward.paper.status')
  const stewardPaperStatusArray = useCodeArraySync('steward.paper.status')

  const [auth] = usePermission()
  const [activeKey, setActiveKey] = useState('1')

  // 题库
  const [questForm] = Form.useForm<StewardQuestionQueryDTO>()
  const [questTotal, setQuestTotal] = useState(0)
  const [questionList, setQuestionList] = useState<StewardQuestionVO[]>([])
  const [questionCount, setQuestionCount] = useState({} as StewardQuestionCountVO)

  // 每日练习
  const [exerForm] = Form.useForm()
  const [exerTotal, setExerTotal] = useState(0)
  const [exerList, setExerList] = useState<StewardQuestionPaperDailyVO[]>([])
  const [exerSetForm] = Form.useForm()
  const [isExer, setIsExer] = useState(false)

  // 考试
  const [examForm] = Form.useForm()
  const [examTotal, setExamTotal] = useState(0)
  const [examList, setExamList] = useState<StewardQuestionPaperVO[]>([])
  const [examSetForm] = Form.useForm()
  const [isExam, setIsExam] = useState(false)

  // MARK: Networking
  // 题库
  const queryQuestionList = useCallback(() => {
    const formValues = questForm.getFieldsValue(true)
    stewardModel.stewardAdminQuestionList(formValues).then(({response: {data, total}}) => {
      setQuestTotal(total)
      setQuestionList(data)
    })
  }, [questForm])

  useEffect(() => {
    queryQuestionList()
  }, [queryQuestionList])

  // 每日练习
  const queryQuestionPaperDailyList = useCallback(() => {
    const {pageNum = 1, pageSize = 10, pageable = true, ...rest} = exerForm.getFieldsValue(true)
    stewardModel
      .stewardAdminQuestionPaperDailyList({pageNum, pageSize, pageable, ...rest})
      .then(({response: {data, total}}) => {
        setExerList(data)
        setExerTotal(total)
      })
  }, [exerForm])

  useEffect(() => {
    queryQuestionPaperDailyList()
  }, [queryQuestionPaperDailyList])

  // 考试
  const queryQuestionPaperList = useCallback(() => {
    const {pageNum = 1, pageSize = 10, pageable = true, ...rest} = examForm.getFieldsValue(true)
    stewardModel
      .stewardAdminQuestionPaperList({pageNum, pageSize, pageable, ...rest})
      .then(({response: {data, total}}) => {
        setExamList(data)
        setExamTotal(total)
      })
  }, [examForm])

  useEffect(() => {
    queryQuestionPaperList()
  }, [queryQuestionPaperList])

  useFocusEffect(() => {
    queryQuestionPaperList()
  }, 'POP')

  const queryQuestionPaperConfigInfo = useCallback(async (paperType: '1' | '2') => {
    const {
      response: {data},
    } = await stewardModel.stewardAdminQuestionPaperConfigInfo({paperType})
    return data
  }, [])

  // 题库题数统计
  const queryQuestionCount = useCallback(() => {
    stewardModel.stewardAdminQuestionCount().then(({response: {data}}) => setQuestionCount(data))
  }, [])

  useEffect(() => {
    queryQuestionCount()
  }, [queryQuestionCount])

  // MARK: Action
  // 导入题库
  const [upLoading, setUpLoading] = useState(false)

  const calcScore: (args: {
    single: number
    singleScore: number
    multiple: number
    multipleScore: number
    tof: number
    tofScore: number
    shortAnswer: number
    shortAnswerScore: number
  }) => number = useCallback(args => {
    const {single, singleScore, multiple, multipleScore, tof, tofScore, shortAnswer, shortAnswerScore} = args
    return +single * +singleScore + +multiple * +multipleScore + +tof * +tofScore + +shortAnswer * +shortAnswerScore
  }, [])

  // MARK: Rules
  const createRule: (args: {
    rule_message_1: string
    rule_max_2: number
    rule_message_2: string
  }) => Rule[] = useCallback(args => {
    return [
      {required: true, message: args.rule_message_1},
      {
        type: 'number',
        min: 0,
        max: args.rule_max_2,
        message: args.rule_message_2,
      },
    ]
  }, [])

  const singleRule: Rule[] = createRule({
    rule_message_1: '请输入单选题数量',
    rule_max_2: questionCount.single,
    rule_message_2: `单选题数量范围0-${questionCount.single}`,
  })

  const multipleRule: Rule[] = createRule({
    rule_message_1: '请输入多选题数量',
    rule_max_2: questionCount.multiple,
    rule_message_2: `多选题数量范围0-${questionCount.multiple}`,
  })

  const tofRule: Rule[] = createRule({
    rule_message_1: '请输入判断题数量',
    rule_max_2: questionCount.tof,
    rule_message_2: `判断题数量范围0-${questionCount.tof}`,
  })

  const shortRule: Rule[] = createRule({
    rule_message_1: '请输入简答题数量',
    rule_max_2: questionCount.shortAnswer,
    rule_message_2: `简答题数量范围0-${questionCount.shortAnswer}`,
  })

  // MARK: Loading
  const dailyLoading = stewardModel.stewardAdminQuestionPaperDailyList.useLoading()
  const paperLoading = stewardModel.stewardAdminQuestionPaperList.useLoading()
  const configLoading = stewardModel.stewardAdminQuestionPaperConfig.useLoading()
  const infoLoading = stewardModel.stewardAdminQuestionPaperConfigInfo.useLoading()
  const auditLoading = stewardModel.stewardAdminQuestionPaperAudit.useLoading()

  return (
    <Page
      breadcrumb={
        <Breadcrumb
          right={
            <>
              {activeKey === '1' && (
                <Upload
                  accept={'.xlsx, .xls'}
                  multiple={false}
                  showUploadList={false}
                  customRequest={options =>
                    uploadExcelData({
                      info: options,
                      uri: '/steward/admin/question/import',
                      onLoading: loading => setUpLoading(loading),
                      onSuccess: () => {
                        queryQuestionList()
                        queryQuestionCount()
                      },
                    })
                  }
                >
                  <Button type={'primary'} loading={upLoading}>
                    导入题库
                  </Button>
                </Upload>
              )}
              {activeKey === '2' && (
                <Button
                  type={'primary'}
                  loading={infoLoading}
                  onClick={async () => {
                    const data = await queryQuestionPaperConfigInfo('1')
                    if (data) exerSetForm.setFieldsValue(data)
                    setIsExer(true)
                  }}
                >
                  每日练习设置
                </Button>
              )}
              {activeKey === '3' && (
                <Button
                  type={'primary'}
                  onClick={async () => {
                    const data = await queryQuestionPaperConfigInfo('2')
                    if (data) examSetForm.setFieldsValue(data)
                    setIsExam(true)
                  }}
                >
                  考试设置
                </Button>
              )}
            </>
          }
        />
      }
    >
      <Tabs tabBarStyle={{backgroundColor: '#fff', margin: 0, paddingLeft: '20px'}} onChange={key => setActiveKey(key)}>
        <Tabs.TabPane tab={'题库'} key={'1'}>
          <Form
            form={questForm}
            onFinish={queryQuestionList}
            onReset={queryQuestionList}
            initialValues={{pageNum: 1, pageSize: 10, pageable: true, questionType: null}}
          >
            <div className={commonStyles.filterSection} style={{marginTop: '20px'}}>
              <Row>
                <Form.Item
                  label={'题型'}
                  shouldUpdate={(prevValues, nextValues) => prevValues.questionType !== nextValues.questionType}
                >
                  {form1 => {
                    const questionType = form1.getFieldValue('questionType')
                    return (
                      <Space size={15}>
                        {stewardQuestionTypeArray.map(item => (
                          <Button
                            key={item.id}
                            type={questionType === item.id ? 'primary' : 'text'}
                            size={'small'}
                            style={{padding: '0 20px'}}
                            onClick={() => {
                              form1.setFieldsValue({pageNum: 1, questionType: item.id})
                              form1.submit()
                            }}
                          >
                            {item.label}
                          </Button>
                        ))}
                      </Space>
                    )
                  }}
                </Form.Item>
              </Row>
              <Row>
                <Form.Item label={'题干'} name={'questionTitle'}>
                  <Input placeholder={'请输入'} allowClear />
                </Form.Item>
              </Row>
              <Row justify={'end'}>
                <Space>
                  <Button htmlType={'reset'}>重置</Button>
                  <Button type={'primary'} htmlType={'submit'} onClick={() => questForm.setFieldsValue({pageNum: 1})}>
                    查询
                  </Button>
                </Space>
              </Row>
            </div>

            <div className={commonStyles.tableSection}>
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, nextValues) =>
                  prevValues.pageNum !== nextValues.pageNum || prevValues.pageSize !== nextValues.pageSize
                }
              >
                {() => (
                  <List
                    dataSource={questionList}
                    renderItem={item => (
                      <List.Item>
                        <Space direction={'vertical'} size={10} style={{width: '100%'}}>
                          <div style={{display: 'flex', justifyContent: 'space-between'}}>
                            <div style={{flexBasis: '75%'}}>
                              <Typography.Text style={{color: 'rgba(0, 0, 0, 0.85)'}}>
                                {item.questionTitle}
                              </Typography.Text>
                            </div>
                            <div
                              style={{
                                display: 'flex',
                                flexBasis: '25%',
                                alignSelf: 'start',
                                flexDirection: 'row-reverse',
                              }}
                            >
                              <Tag color={tagColorCode[item.questionType] ?? 'cyan'} style={{border: 'none'}}>
                                {stewardQuestionTypeCode[item.questionType]}
                              </Tag>
                            </div>
                          </div>
                          {item.questionType !== '4' ? (
                            item.answerList?.map((value, index) => (
                              <Tag.CheckableTag checked={item.answerRight.includes(answerCode[index])} key={value}>
                                {`${answerCode[index]} ${value}`}
                              </Tag.CheckableTag>
                            ))
                          ) : (
                            <Typography.Text>{`答案：${item.answerRight}`}</Typography.Text>
                          )}
                        </Space>
                      </List.Item>
                    )}
                    pagination={{
                      ...getPaginationProps(questForm, questTotal),
                      position: 'bottom',
                      style: {textAlign: 'center'},
                    }}
                  />
                )}
              </Form.Item>
            </div>
          </Form>
        </Tabs.TabPane>
        {auth('001114001') && (
          <Tabs.TabPane tab={'每日练习'} key={'2'}>
            <Form
              form={exerForm}
              onFinish={queryQuestionPaperDailyList}
              onReset={queryQuestionPaperDailyList}
              initialValues={{pageNum: 1, pageSize: 10, pageable: true}}
              labelAlign={'left'}
              labelCol={{flex: '70px'}}
            >
              <div className={commonStyles.filterSection} style={{marginTop: '20px'}}>
                <Row>
                  <Col span={7}>
                    <Form.Item label={'日期'} name={'createDate'}>
                      <DatePicker.RangePicker
                        allowClear
                        onChange={(_, formatString) => {
                          const [createDateBegin, createDateEnd] = formatString ?? []
                          exerForm.setFieldsValue({createDateBegin, createDateEnd})
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={7} offset={1}>
                    <Form.Item label={'管家'} name={'userName'}>
                      <Input placeholder={'请输入'} allowClear />
                    </Form.Item>
                  </Col>
                  <Col span={7} offset={1}>
                    <Form.Item label={'作答状态'} name={'paperStatus'}>
                      <Select placeholder={'请选择'} allowClear>
                        <Select.Option value={'0'}>未作答</Select.Option>
                        <Select.Option value={'1'}>已作答</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={7}>
                    <Form.Item label={'正确率'} name={'correctAll'}>
                      <Select placeholder={'请选择'} allowClear>
                        <Select.Option value={'1'}>100%</Select.Option>
                        <Select.Option value={'0'}>非100%</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Row justify={'end'}>
                  <Space>
                    <Button htmlType={'reset'}>重置</Button>
                    <Button type={'primary'} htmlType={'submit'} onClick={() => exerForm.setFieldsValue({pageNum: 1})}>
                      查询
                    </Button>
                  </Space>
                </Row>
              </div>

              <div className={commonStyles.tableSection}>
                <Table
                  rowKey={'paperId'}
                  dataSource={exerList}
                  loading={dailyLoading}
                  columns={[
                    {title: '日期', dataIndex: 'createDate'},
                    {title: '管家', dataIndex: 'userName'},
                    {title: '题数', dataIndex: 'totalNum'},
                    {title: '作答状态', dataIndex: 'paperStatus', render: value => paperStatusCode[value]},
                    {
                      title: (
                        <Tooltip title={'每日练习答对题数/（每日练习题数-每日练习里的简答题）'}>
                          <Space>
                            <span>正确率</span>
                            <InfoCircleOutlined style={{color: '#06B6AF'}} />
                          </Space>
                        </Tooltip>
                      ),
                      dataIndex: 'correctRate',
                      render: value => ([null, undefined].includes(value) ? '/' : `${value}%`),
                    },
                    {title: '提交时间', dataIndex: 'commitTime', render: value => value ?? '/'},
                    {
                      title: '操作',
                      render: (_, value) => (
                        <Button
                          type={'link'}
                          style={{padding: 0}}
                          onClick={() =>
                            history.push('/steward-exam-management/detail', {type: '1', paperId: value.paperId})
                          }
                        >
                          查看
                        </Button>
                      ),
                    },
                  ]}
                  pagination={getPaginationProps(exerForm, exerTotal)}
                />
              </div>
            </Form>

            <Modal
              title={'每日练习设置'}
              visible={isExer}
              centered
              footer={null}
              onCancel={() => setIsExer(false)}
              afterClose={() => exerSetForm.resetFields()}
            >
              <Form
                form={exerSetForm}
                onFinish={async () => {
                  const formValue = exerSetForm.getFieldsValue(true)
                  await stewardModel.stewardAdminQuestionPaperConfig(formValue)
                  message.success('操作成功！')
                  queryQuestionPaperDailyList()
                  setIsExer(false)
                }}
                onReset={() => setIsExer(false)}
                // 分值是后端接口复用所以前端给默认设置
                initialValues={{paperType: '1', singleScore: 0, multipleScore: 0, tofScore: 0, shortAnswerScore: 0}}
              >
                <Form.Item label={'推送时间'}>
                  <span>07:00</span>
                </Form.Item>
                <Form.Item label={'题型数量'} shouldUpdate>
                  {() => {
                    return (
                      <Space direction={'vertical'} size={20}>
                        <Space>
                          {`单选题一共${questionCount.single}题，抽取`}
                          <Form.Item name={'single'} style={{marginBottom: 0}} rules={singleRule}>
                            <InputNumber placeholder={'请输入'} />
                          </Form.Item>
                          题
                        </Space>
                        <Space>
                          {`多选题一共${questionCount.multiple}题，抽取`}
                          <Form.Item name={'multiple'} style={{marginBottom: 0}} rules={multipleRule}>
                            <InputNumber placeholder={'请输入'} />
                          </Form.Item>
                          题
                        </Space>
                        <Space>
                          {`判断题一共${questionCount.tof}题，抽取`}
                          <Form.Item name={'tof'} style={{marginBottom: 0}} rules={tofRule}>
                            <InputNumber placeholder={'请输入'} />
                          </Form.Item>
                          题
                        </Space>
                        <Space>
                          {`简答题一共${questionCount.shortAnswer}题，抽取`}
                          <Form.Item name={'shortAnswer'} style={{marginBottom: 0}} rules={shortRule}>
                            <InputNumber placeholder={'请输入'} />
                          </Form.Item>
                          题
                        </Space>
                      </Space>
                    )
                  }}
                </Form.Item>
                <Row justify={'end'}>
                  <Space>
                    <Button htmlType={'reset'}>取消</Button>
                    <Button type={'primary'} htmlType={'submit'} loading={configLoading}>
                      保存
                    </Button>
                  </Space>
                </Row>
              </Form>
            </Modal>
          </Tabs.TabPane>
        )}
        {auth('001115001') && (
          <Tabs.TabPane tab={'考试'} key={'3'}>
            <Form
              form={examForm}
              onFinish={queryQuestionPaperList}
              onReset={queryQuestionPaperList}
              initialValues={{pageNum: 1, pageSize: 10, pageable: true}}
              labelAlign={'left'}
              labelCol={{flex: '70px'}}
            >
              <div className={commonStyles.filterSection} style={{marginTop: '20px'}}>
                <Row>
                  <Col span={7}>
                    <Form.Item label={'管家'} name={'userName'}>
                      <Input placeholder={'请输入'} allowClear />
                    </Form.Item>
                  </Col>
                  <Col span={7} offset={1}>
                    <Form.Item label={'考试状态'} name={'paperStatus'}>
                      <Select placeholder={'请选择'} allowClear>
                        {stewardPaperStatusArray.map(item => (
                          <Select.Option key={item.id} value={item.id}>
                            {item.label}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={7} offset={1}>
                    <Form.Item label={'阅卷人'} name={'markUserName'}>
                      <Input placeholder={'请输入'} allowClear />
                    </Form.Item>
                  </Col>
                </Row>
                <Row justify={'end'}>
                  <Space>
                    <Button htmlType={'reset'}>重置</Button>
                    <Button type={'primary'} htmlType={'submit'} onClick={() => examForm.setFieldsValue({pageNum: 1})}>
                      查询
                    </Button>
                  </Space>
                </Row>
              </div>

              <div className={commonStyles.tableSection}>
                <Table
                  rowKey={'paperId'}
                  dataSource={examList}
                  loading={paperLoading}
                  columns={[
                    {title: '管家', dataIndex: 'userName'},
                    {title: '申请时间', dataIndex: 'pendingTime'},
                    {title: '考试状态', dataIndex: 'paperStatus', render: value => stewardPaperStatusCode[value]},
                    {title: '交卷时间', dataIndex: 'commitTime', render: value => value ?? '/'},
                    {title: '审批人', dataIndex: 'auditUserName', render: value => value ?? '/'},
                    {title: '阅卷人', dataIndex: 'markUserName', render: value => value ?? '/'},
                    {
                      title: '总分',
                      dataIndex: 'score',
                      render: (value, record) => (record.paperStatus === '4' ? value : '/'),
                    },
                    {
                      title: '操作',
                      render: (_, value) => (
                        <Space>
                          <Button
                            type={'link'}
                            style={{padding: 0}}
                            onClick={() =>
                              history.push('/steward-exam-management/detail', {type: '2', paperId: value.paperId})
                            }
                          >
                            查看
                          </Button>
                          {value.paperStatus === '1' && (
                            <Button
                              type={'link'}
                              style={{padding: 0}}
                              onClick={() => {
                                let tmp = '1'
                                Modal.confirm({
                                  title: '通过',
                                  content: (
                                    <Form.Item label={'试题生成方式'}>
                                      <Radio.Group
                                        defaultValue={'1'}
                                        onChange={({target: {value}}) => {
                                          tmp = value
                                        }}
                                      >
                                        <Radio value={'1'}>系统生成</Radio>
                                        <Radio value={'2'}>自定义</Radio>
                                      </Radio.Group>
                                    </Form.Item>
                                  ),
                                  icon: null,
                                  closable: true,
                                  centered: true,
                                  okText: '确认',
                                  okButtonProps: {loading: auditLoading},
                                  onOk: async () => {
                                    if (tmp === '2') {
                                      history.push('/steward-exam-management/generation', {paperId: value.paperId})
                                    } else {
                                      await stewardModel.stewardAdminQuestionPaperAudit({
                                        paperId: value.paperId,
                                        score: 100,
                                      } as StewardQuestionPaperSubmitDTO)
                                      message.success('操作成功！')
                                      queryQuestionPaperList()
                                    }
                                  },
                                })
                              }}
                            >
                              通过
                            </Button>
                          )}
                        </Space>
                      ),
                    },
                  ]}
                  pagination={getPaginationProps(examForm, examTotal)}
                />
              </div>
            </Form>

            <Modal
              title={'考试设置'}
              visible={isExam}
              centered
              footer={null}
              onCancel={() => setIsExam(false)}
              afterClose={() => examSetForm.resetFields()}
              width={'600px'}
            >
              <Form
                form={examSetForm}
                onFinish={async () => {
                  const {
                    single,
                    singleScore,
                    multiple,
                    multipleScore,
                    tof,
                    tofScore,
                    shortAnswer,
                    shortAnswerScore,
                  } = examSetForm.getFieldsValue(true)
                  const totalScore = calcScore({
                    single,
                    singleScore,
                    multiple,
                    multipleScore,
                    tof,
                    tofScore,
                    shortAnswer,
                    shortAnswerScore,
                  })
                  if (totalScore !== 100) return message.warning('分数总和必须为100!')
                  const formValues = examSetForm.getFieldsValue(true)
                  await stewardModel.stewardAdminQuestionPaperConfig(formValues)
                  message.success('操作成功！')
                  queryQuestionPaperList()
                  setIsExam(false)
                }}
                onReset={() => setIsExam(false)}
                initialValues={{paperType: '2'}}
              >
                <Form.Item label={'题型数量'} shouldUpdate>
                  {form1 => {
                    const {
                      single = 0,
                      singleScore = 0,
                      multiple = 0,
                      multipleScore = 0,
                      tof = 0,
                      tofScore = 0,
                      shortAnswer = 0,
                      shortAnswerScore = 0,
                    } = form1.getFieldsValue(true)
                    return (
                      <Space direction={'vertical'} size={20}>
                        <Space>
                          {`单选题一共${questionCount.single}题，抽取`}
                          <Form.Item name={'single'} style={{marginBottom: 0}} rules={singleRule}>
                            <InputNumber placeholder={'请输入'} />
                          </Form.Item>
                          题
                          <Form.Item
                            name={'singleScore'}
                            style={{marginBottom: 0}}
                            rules={[{required: true, message: '请输入单选题分值'}]}
                          >
                            <InputNumber placeholder={'请输入'} />
                          </Form.Item>
                          分/题
                          <span>{+single * +singleScore}分</span>
                        </Space>
                        <Space>
                          {`多选题一共${questionCount.multiple}题，抽取`}
                          <Form.Item name={'multiple'} style={{marginBottom: 0}} rules={multipleRule}>
                            <InputNumber placeholder={'请输入'} />
                          </Form.Item>
                          题
                          <Form.Item
                            name={'multipleScore'}
                            style={{marginBottom: 0}}
                            rules={[{required: true, message: '请输入多选题分值'}]}
                          >
                            <InputNumber placeholder={'请输入'} />
                          </Form.Item>
                          分/题
                          <span>{+multiple * +multipleScore}分</span>
                        </Space>
                        <Space>
                          {`判断题一共${questionCount.tof}题，抽取`}
                          <Form.Item name={'tof'} style={{marginBottom: 0}} rules={tofRule}>
                            <InputNumber placeholder={'请输入'} />
                          </Form.Item>
                          题
                          <Form.Item
                            name={'tofScore'}
                            style={{marginBottom: 0}}
                            rules={[{required: true, message: '请输入判断题分值'}]}
                          >
                            <InputNumber placeholder={'请输入'} />
                          </Form.Item>
                          分/题
                          <span>{+tof * +tofScore}分</span>
                        </Space>
                        <Space>
                          {`简答题一共${questionCount.shortAnswer}题，抽取`}
                          <Form.Item name={'shortAnswer'} style={{marginBottom: 0}} rules={shortRule}>
                            <InputNumber placeholder={'请输入'} />
                          </Form.Item>
                          题
                          <Form.Item
                            name={'shortAnswerScore'}
                            style={{marginBottom: 0}}
                            rules={[{required: true, message: '请输入简答题分值'}]}
                          >
                            <InputNumber placeholder={'请输入'} />
                          </Form.Item>
                          分/题
                          <span>{+shortAnswer * +shortAnswerScore}分</span>
                        </Space>
                      </Space>
                    )
                  }}
                </Form.Item>
                <Form.Item label={'目前总分'} shouldUpdate>
                  {form1 => {
                    const {
                      single = 0,
                      singleScore = 0,
                      multiple = 0,
                      multipleScore = 0,
                      tof = 0,
                      tofScore = 0,
                      shortAnswer = 0,
                      shortAnswerScore = 0,
                    } = form1.getFieldsValue(true)
                    return (
                      <span>
                        {calcScore({
                          single,
                          singleScore,
                          multiple,
                          multipleScore,
                          tof,
                          tofScore,
                          shortAnswer,
                          shortAnswerScore,
                        })}
                        分
                      </span>
                    )
                  }}
                </Form.Item>
                <Row justify={'end'}>
                  <Space>
                    <Button htmlType={'reset'}>取消</Button>
                    <Button type={'primary'} htmlType={'submit'}>
                      保存
                    </Button>
                  </Space>
                </Row>
              </Form>
            </Modal>
          </Tabs.TabPane>
        )}
      </Tabs>
    </Page>
  )
}
