import {Page} from '@/components/Page'
import {Button, Col, Descriptions, Input, message, Modal, PageHeader, Row, Space} from 'antd'
import React, {useState} from 'react'
import commonStyle from '@/assets/styles/common.module.less'
import DividerLine from '@/pages/property/component/DividerLine'
import {useAppHistory, useAppLocation} from '@/common/hooks/useRouter'
import {userModel} from '@/store/models/User'
import {getImg} from '@/utils/util'
import ImageModal from '@/components/ImageModal'

export default function CompanyAccountDetail() {
  const history = useAppHistory()
  const location = useAppLocation()
  const info: LessorCompanyApplyVO = location.state ?? {}
  const [visible, setVisible] = useState(false)
  const [auditRemark, setAuditRemark] = useState('')
  const [confirmVisible, setConfirmVisible] = useState(false)
  const [img, setImg] = useState('')
  const confirmLoading = userModel.userLessorCompanyApplyAudit.useLoading()
  const refuseLoading = userModel.userLessorCompanyApplyAudit.useLoading()

  return (
    <Page header={<PageHeader title={'企业账户详情'} ghost={false} onBack={() => history.goBack()} />}>
      <div className={commonStyle.filterSection}>
        <DividerLine title='公司信息' />
        <Descriptions column={2} style={{marginTop: 10, paddingRight: 200}}>
          <Descriptions.Item label={'公司名称'}>{info.companyName}</Descriptions.Item>
          <Descriptions.Item label={'统一社会信用代码'}>{info.uniCredit}</Descriptions.Item>
          <Descriptions.Item label={'法人姓名'}>{info.legalName}</Descriptions.Item>
          <Descriptions.Item label={'法人手机号'}>{info.legalPhone}</Descriptions.Item>
        </Descriptions>
        <Row style={{marginBottom: 15}}>
          <Col>
            <span>营业执照照片：</span>
          </Col>
          <Col>
            <div>
              <img src={getImg(info.pictureUrl1)} width={87} height={87} onClick={() => setImg(info.pictureUrl1)} />
            </div>
          </Col>
        </Row>
        <Row style={{marginBottom: 15}}>
          <Col>
            <span>法人身份证照片：</span>
          </Col>
          <Col>
            <Space>
              <div>
                <img src={getImg(info.pictureUrl8)} width={87} height={87} onClick={() => setImg(info.pictureUrl8)} />
              </div>
              <div>
                <img src={getImg(info.pictureUrl9)} width={87} height={87} onClick={() => setImg(info.pictureUrl9)} />
              </div>
            </Space>
          </Col>
        </Row>
        <DividerLine title='对公账户' />
        <Descriptions column={2} style={{marginTop: 10, paddingRight: 200}}>
          <Descriptions.Item label={'账户账号'}>{info.accountNo}</Descriptions.Item>
          <Descriptions.Item label={'开户银行名称'}>{info.parentBankName}</Descriptions.Item>
          <Descriptions.Item label={'开户银行支行名称'}>{info.bankName}</Descriptions.Item>
          <Descriptions.Item label={'开户银行支付行号'}>{info.unionBank}</Descriptions.Item>
        </Descriptions>
        {info.auditStatus === '1' && (
          <Row justify={'center'} style={{marginTop: 30}}>
            <Space>
              <Button
                type={'primary'}
                onClick={() => {
                  setConfirmVisible(true)
                }}
              >
                通过
              </Button>
              <Button onClick={() => setVisible(true)}>拒绝</Button>
            </Space>
          </Row>
        )}
      </div>

      {/* 确认通过 */}
      <Modal
        visible={confirmVisible}
        centered
        title={'提示'}
        onCancel={() => setConfirmVisible(false)}
        onOk={async () => {
          await await userModel.userLessorCompanyApplyAudit({
            applyId: info.applyId,
            auditStatus: '2',
          } as LessorCompanyApplyAuditDTO)
          message.success('审核通过成功！')
          history.goBack()
        }}
        confirmLoading={confirmLoading}
      >
        <span>是否确认通过审核?</span>
      </Modal>

      {/* 拒绝账号认证 */}
      <Modal
        visible={visible}
        title={'拒绝账号认证'}
        centered
        onCancel={() => setVisible(false)}
        onOk={async () => {
          if (!auditRemark) return message.error('请输入拒绝原因')
          await userModel.userLessorCompanyApplyAudit({applyId: info.applyId, auditRemark, auditStatus: '3'})
          message.success('拒绝审核成功！')
          history.goBack()
        }}
        confirmLoading={refuseLoading}
      >
        <Space align={'start'}>
          <span style={{color: '#606266'}}>拒绝原因:</span>
          <Input.TextArea
            style={{width: 350}}
            rows={4}
            showCount
            maxLength={100}
            allowClear
            onChange={({target}) => setAuditRemark(target.value)}
            placeholder='请输入拒绝原因，限100字以内'
          />
        </Space>
      </Modal>

      {/* 查看图片 */}
      <ImageModal visible={!!img} imgUrl={img} onCancel={() => setImg('')} />
    </Page>
  )
}
