import React, {useImperativeHandle, useState} from 'react'
import {Button, Modal, Row, Space, Typography} from 'antd'
import {lessorModel} from '@/store/models/Lessor'

const {Text} = Typography
function DeleteServiceBill(props, ref) {
  const [visible, setVisible] = useState(false)
  const [billId, setBillId] = useState('')
  const [billName, setBillName] = useState('')
  useImperativeHandle(ref, () => ({
    onDisplay: () => {
      setVisible(true)
    },
    onSetInfo: (billId, billName) => {
      setBillId(billId)
      setBillName(billName)
    },
  }))
  return (
    <Modal visible={visible} centered footer={null} onCancel={() => setVisible(false)}>
      <Space direction={'vertical'}>
        <Text style={{fontSize: 16, color: '#000000', fontWeight: 600}}>提示</Text>
        <Text>{`是否确认删除${billName}账单？`}</Text>
      </Space>

      <Row justify={'end'} style={{marginTop: 50}}>
        <Space>
          <Button onClick={() => setVisible(false)}>取消</Button>
          <Button
            type={'primary'}
            onClick={async () => {
              await lessorModel.userLessorBillDelete({billId: billId})
              setVisible(false)
              props.onSuccess()
            }}
          >
            确认
          </Button>
        </Space>
      </Row>
    </Modal>
  )
}

export default React.forwardRef(DeleteServiceBill)
