import React, {useCallback, useEffect, useState} from 'react'
import {Button, Col, Form, Input, message, Row, Select, Space, Switch, Table, Tooltip} from 'antd'
import commonStyles from '@/assets/styles/common.module.less'
import {useAppHistory} from '@/common/hooks/useRouter'
import {ebassModel} from '@/store/models/Ebaas'
import {arrayToObject} from '@/store/models/Common'
import ebaas from '@/store/models/Common/ebaasCodes'
import {useFocusEffect} from '@/common/hooks/useFocusEffect'
import {getPaginationProps} from '@/common/biz/getPaginationProps'

const inServiceStatusCode = arrayToObject(ebaas.inServiceStatus)
const verificationStatusCode = arrayToObject(ebaas.verificationStatus)
const isToAliCode = arrayToObject(ebaas.isToAli)
const brokerTypeCode = ['经纪人', '社区志愿者', '客服', '卖房管家', '委托客服', '公寓管家']

export default function StoreAgentSteward({storeNo}: {storeNo: string}) {
  const history = useAppHistory()
  const [form] = Form.useForm()

  const [brokerTotal, setBrokerTotal] = useState(0)
  const [dataList, setDataList] = useState<EbaasBrokerVO[]>([])

  const queryBrokerList = useCallback(() => {
    const formValues = form.getFieldsValue(true)
    ebassModel.ebaasBrokerList({...formValues, storeNo}).then(({response: {data, total}}) => {
      setDataList(data)
      setBrokerTotal(total)
    })
  }, [form, storeNo])

  useEffect(() => {
    queryBrokerList()
  }, [queryBrokerList])

  useFocusEffect(() => {
    queryBrokerList()
  })

  const onServiceStatusChange = useCallback(
    async (args: EbaasBrokerVO, checked: boolean) => {
      await ebassModel.ebaasBrokerChangeInServiceStatus({
        userId: args.userId,
        inServiceStatus: checked ? 1 : 2,
      })
      message.success('操作成功！')
      queryBrokerList()
    },
    [queryBrokerList]
  )

  const listLoading = ebassModel.ebaasBrokerList.useLoading()
  const statusLoading = ebassModel.ebaasBrokerChangeInServiceStatus.useLoadings()

  return (
    <Form
      form={form}
      onFinish={queryBrokerList}
      onReset={queryBrokerList}
      initialValues={{pageNum: 1, pageSize: 10, pageable: true}}
    >
      <div className={commonStyles.filterSection}>
        <Row>
          <Col span={7}>
            <Form.Item label={'人员名称'} name={'name'}>
              <Input placeholder={'请输入'} allowClear />
            </Form.Item>
          </Col>
          <Col span={7} offset={1}>
            <Form.Item label={'状态'} name={'inServiceStatus'}>
              <Select placeholder={'请选择'} allowClear>
                {ebaas.inServiceStatus.map(item => (
                  <Select.Option key={item.id} value={item.id}>
                    {item.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row justify={'end'}>
          <Space>
            <Button htmlType={'reset'}>重置</Button>
            <Button htmlType={'submit'} type={'primary'} onClick={() => form.setFieldsValue({pageNum: 1})}>
              查询
            </Button>
          </Space>
        </Row>
      </div>

      <div className={commonStyles.tableSection}>
        <Table
          rowKey={'brokerId'}
          dataSource={dataList}
          loading={listLoading}
          columns={[
            {title: '人员名称', dataIndex: 'name'},
            {title: '手机号', dataIndex: 'mobile'},
            {title: '角色', dataIndex: 'brokerType', render: value => brokerTypeCode[value]},
            {title: '状态', dataIndex: 'inServiceStatus', render: value => inServiceStatusCode[value]},
            {title: '天猫更新时间', dataIndex: 'latestSyncTime'},
            {title: '是否同步至阿里', dataIndex: 'isToAli', render: value => isToAliCode[value]},
            {title: '审核状态', dataIndex: 'verificationStatus', render: value => verificationStatusCode[value]},
            {
              title: '审核返回信息',
              dataIndex: 'verificationInfo',
              render: value => {
                if (value?.length > 15) {
                  return <Tooltip title={value}>{value.slice(0, 15) + '...'}</Tooltip>
                }
                return value || '/'
              },
            },
            {
              title: '操作',
              fixed: 'right',
              render: (_, record) => {
                const {inServiceStatus} = record
                return (
                  <Space>
                    <Button
                      type={'link'}
                      style={{padding: 0}}
                      onClick={() => history.push('/store-manage/add-agent', {userId: record.userId})}
                    >
                      编辑
                    </Button>
                    <Switch
                      checked={inServiceStatus === 1}
                      onChange={checked => onServiceStatusChange(record, checked)}
                      loading={statusLoading.pick(record.userId)}
                    />
                  </Space>
                )
              },
            },
          ]}
          scroll={{x: 'max-content'}}
          pagination={getPaginationProps(form, brokerTotal)}
        />
      </div>
    </Form>
  )
}
