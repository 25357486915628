import React, {useCallback, useEffect, useState} from 'react'
import commonStyles from '@/assets/styles/common.module.less'
import {Button, Col, DatePicker, Form, Input, Row, Select, Space, Table} from 'antd'
import {useAppHistory} from '@/common/hooks/useRouter'
import moment from 'moment'
import {customerModel} from '@/store/models/Customer'

const {Option} = Select
const {RangePicker} = DatePicker

export type PropertyAddDeletePorps = {
  customerId: string
}

export default function PropertyAddDelete(args: PropertyAddDeletePorps) {
  const {customerId} = args
  const history = useAppHistory()
  const [form] = Form.useForm()

  const [total, setTotal] = useState(0)
  const [dataList, setDataList] = useState<PropertyRecordVO[]>([])
  const [reqParams, setReqParams] = useState({
    pageNum: 1,
    pageSize: 10,
  } as PropertyRecordListDTO)
  const listLoading = customerModel.customerPropertyRecordList.useLoading()

  const queryPropertyRecordList = useCallback((args: PropertyRecordListDTO) => {
    customerModel.customerPropertyRecordList(args).then(({response: {data, total}}) => {
      setTotal(total)
      setDataList(data)
    })
  }, [])

  useEffect(() => {
    if (customerId) queryPropertyRecordList({customerId, ...reqParams})
  }, [customerId, queryPropertyRecordList, reqParams])

  return (
    <>
      <div style={{backgroundColor: 'white', padding: '24px', marginBottom: '20px'}}>
        <Form
          form={form}
          labelCol={{flex: '80px'}}
          onFinish={() => {
            const formValues = form.getFieldsValue(true)
            setReqParams(prevState => ({...prevState, pageNum: 1, ...formValues}))
          }}
          onReset={() => {
            form.resetFields()
            setReqParams({
              pageNum: 1,
              pageSize: 10,
            } as PropertyRecordListDTO)
          }}
        >
          <Row gutter={30}>
            <Col span={9}>
              <Form.Item name={'propertyCode'} label={'房源code'}>
                <Input placeholder={'请输入'} allowClear />
              </Form.Item>
            </Col>
            <Col span={9}>
              <Form.Item name={'propertyTitle'} label={'房源名称'}>
                <Input placeholder={'请输入'} allowClear />
              </Form.Item>
            </Col>
            <Col span={9}>
              <Form.Item name={'operateTime'} label={'操作时间'}>
                <RangePicker
                  style={{width: '100%'}}
                  allowClear
                  onChange={(value, format) => {
                    form.setFields([{name: 'operateTimeBegin', value: format?.[0]}])
                    form.setFields([{name: 'operateTimeEnd', value: format?.[1]}])
                  }}
                  showTime={{
                    defaultValue: [moment('00:00:00', 'HH:mm:ss') as any, moment('23:59:59', 'HH:mm:ss') as any],
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={9}>
              <Form.Item name={'operateType'} label={'操作类型'}>
                <Select placeholder={'请选择'} allowClear>
                  <Option value={'1'}>新增</Option>
                  <Option value={'3'}>删除</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={9}>
              <Form.Item name={'operatorName'} label={'操作人'}>
                <Input placeholder={'请输入'} allowClear />
              </Form.Item>
            </Col>
          </Row>
          <Row justify={'end'}>
            <Space>
              <Button type={'primary'} htmlType={'submit'}>
                查询
              </Button>
              <Button htmlType={'reset'}>重置</Button>
            </Space>
          </Row>
        </Form>
      </div>

      <div className={commonStyles.tableSection}>
        <Table
          rowKey={'id'}
          scroll={{x: 'max-content'}}
          loading={listLoading}
          dataSource={dataList}
          columns={[
            {title: 'ID', key: 'id', dataIndex: 'id'},
            {title: '房源code', key: 'propertyCode', dataIndex: 'propertyCode'},
            {title: '门牌号', key: 'propertyTitle', dataIndex: 'propertyTitle'},
            {title: '操作时间', key: 'operateTime', dataIndex: 'operateTime'},
            {title: '操作人', key: 'operatorName', dataIndex: 'operatorName'},
            {title: '操作类型', key: 'operateTypeDesc', dataIndex: 'operateTypeDesc'},
            {
              title: '操作',
              fixed: 'right',
              align: 'center',
              render: ({propertyCode, operateType}) => {
                if (operateType === '3') return '/'
                return (
                  <Button type={'link'} onClick={() => history.push('/property/detail', {propertyCode})}>
                    查看详情
                  </Button>
                )
              },
            },
          ]}
          pagination={{
            total,
            current: reqParams.pageNum,
            pageSize: reqParams.pageSize,
            position: ['bottomCenter'],
            showSizeChanger: true,
            onChange: (page: number, pageSize: number) =>
              setReqParams(prevState => ({
                ...prevState,
                pageSize,
                pageNum: prevState.pageSize === pageSize ? page : 1,
              })),
          }}
        />
      </div>
    </>
  )
}
