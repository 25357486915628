import React, {useCallback, useEffect, useState} from 'react'
import {Page} from '@/components/Page'
import commonStyles from '@/assets/styles/common.module.less'
import {
  Badge,
  Button,
  Col,
  DatePicker,
  Form,
  FormInstance,
  Input,
  message,
  Modal,
  Row,
  Select,
  Space,
  Table,
  Tabs,
} from 'antd'
import {stewardModel} from '@/store/models/Steward'
import {useCodeArraySync, useCodeSync} from '@/store/models/Common'
import {getPaginationProps} from '@/common/biz/getPaginationProps'
import {DownOutlined, UpOutlined} from '@ant-design/icons'
import {Breadcrumb} from '@/components/Breadcrumb'
// import locale from 'antd/es/date-picker/locale/zh_CN'
// import {downloadBlob} from '@/utils/util'
import {TodoDetail} from '@/pages/service/TodoComponent'
import {cleanModel} from '@/store/models/Clean'
import {useCleanOnlineQualified} from '@/pages/cleanManagement/hooks/useCleanOrderPreview'
import useSorter from '@/common/lib/useSorter'
import {downloadBlob} from '@/utils/util'
import moment from 'moment/moment'
import {FORMAT_DATE_END, FORMAT_DATE_START} from '@/common/date'

/** 驼峰转下划线 */
// const toUnderline = (name: string) => name.replace(/([A-Z])/g, '_$1').toLowerCase()

export default function TodoManagement() {
  const todoListArr = useCodeArraySync('role.todo.list')
  const todoListCode = useCodeSync('role.todo.list')
  // const todoStatusArr = useCodeArraySync('sys.todo.status')
  const todoStatusCode = useCodeSync('sys.todo.status')
  const callStatusArr = useCodeArraySync('todo.call.status')
  const callStatusCode = useCodeSync('todo.call.status')

  const queryTodoList = useCallback((args: SysTodoDTO) => stewardModel.stewardOrderTodoList(args), [])

  const [activeKey, setActiveKey] = useState('1')

  // 更多筛选
  const [collapse, setCollapse] = useState(false)

  // 待分配
  const [form1] = Form.useForm()
  const sorterProps1 = useSorter(form1)
  const [todoList1, setTodoList1] = useState<SysTodoVO[]>([])
  const [todoTotal1, setTodoTotal1] = useState(0)
  const [selectedRows1, setSelectedRows1] = useState<SysTodoVO[]>([])

  const queryToAllocation = useCallback(() => {
    const {assignTime, finishTime, insert, billCreateTime, timeFinished, ...formValues} = form1.getFieldsValue(true)
    const [assignTimeBegin, assignTimeEnd] = assignTime ?? []
    const [finishTimeBegin, finishTimeEnd] = finishTime ?? []
    const [billCreateTimeBegin, billCreateTimeEnd] = billCreateTime ?? []
    const [insertBegin, insertEnd] = insert ?? []
    const [timeFinishedBegin, timeFinishedEnd] = timeFinished ?? []
    if ([assignTime, finishTime, insert, billCreateTime, timeFinished].every(item => !item))
      return message.warning('分配时间、处理完成时间、创建时间、下单时间、订单完成时间其中必选一项')
    queryTodoList({
      ...formValues,
      todoStatus: '1',
      assignTimeBegin,
      assignTimeEnd,
      finishTimeBegin,
      finishTimeEnd,
      insertBegin,
      insertEnd,
      billCreateTimeBegin,
      billCreateTimeEnd,
      timeFinishedBegin,
      timeFinishedEnd,
    }).then(({response: {data, total}}) => {
      setTodoList1(data)
      setTodoTotal1(total)
    })
  }, [form1, queryTodoList])

  useEffect(() => {
    queryToAllocation()
  }, [queryToAllocation])

  // 待处理
  const [form2] = Form.useForm()
  const sorterProps2 = useSorter(form2)
  const [todoList2, setTodoList2] = useState<SysTodoVO[]>([])
  const [todoTotal2, setTodoTotal2] = useState(0)

  const queryTodo = useCallback(() => {
    const {assignTime, finishTime, insert, billCreateTime, timeFinished, ...formValues} = form2.getFieldsValue(true)
    const [assignTimeBegin, assignTimeEnd] = assignTime ?? []
    const [finishTimeBegin, finishTimeEnd] = finishTime ?? []
    const [billCreateTimeBegin, billCreateTimeEnd] = billCreateTime ?? []
    const [insertBegin, insertEnd] = insert ?? []
    const [timeFinishedBegin, timeFinishedEnd] = timeFinished ?? []
    if ([assignTime, finishTime, insert, billCreateTime, timeFinished].every(item => !item))
      return message.warning('分配时间、处理完成时间、创建时间、下单时间、订单完成时间其中必选一项')
    queryTodoList({
      ...formValues,
      todoStatus: '2',
      assignTimeBegin,
      assignTimeEnd,
      finishTimeBegin,
      finishTimeEnd,
      insertBegin,
      insertEnd,
      billCreateTimeBegin,
      billCreateTimeEnd,
      timeFinishedBegin,
      timeFinishedEnd,
    }).then(({response: {data, total}}) => {
      setTodoList2(data)
      setTodoTotal2(total)
    })
  }, [form2, queryTodoList])

  useEffect(() => {
    queryTodo()
  }, [queryTodo])

  // 已处理
  const [form3] = Form.useForm()
  const sorterProps3 = useSorter(form3)
  const [todoList3, setTodoList3] = useState<SysTodoVO[]>([])
  const [todoTotal3, setTodoTotal3] = useState(0)

  const queryDo = useCallback(() => {
    const {assignTime, finishTime, insert, billCreateTime, timeFinished, ...formValues} = form3.getFieldsValue(true)
    const [assignTimeBegin, assignTimeEnd] = assignTime ?? []
    const [finishTimeBegin, finishTimeEnd] = finishTime ?? []
    const [billCreateTimeBegin, billCreateTimeEnd] = billCreateTime ?? []
    const [insertBegin, insertEnd] = insert ?? []
    const [timeFinishedBegin, timeFinishedEnd] = timeFinished ?? []
    if ([assignTime, finishTime, insert, billCreateTime, timeFinished].every(item => !item))
      return message.warning('分配时间、处理完成时间、创建时间、下单时间、订单完成时间其中必选一项')
    queryTodoList({
      ...formValues,
      todoStatus: '3',
      assignTimeBegin,
      assignTimeEnd,
      finishTimeBegin,
      finishTimeEnd,
      insertBegin,
      insertEnd,
      billCreateTimeBegin,
      billCreateTimeEnd,
      timeFinishedBegin,
      timeFinishedEnd,
    }).then(({response: {data, total}}) => {
      setTodoList3(data)
      setTodoTotal3(total)
    })
  }, [form3, queryTodoList])

  useEffect(() => {
    queryDo()
  }, [queryDo])

  // 已作废
  const [form4] = Form.useForm()
  const sorterProps4 = useSorter(form4)
  const [todoList4, setTodoList4] = useState<SysTodoVO[]>([])
  const [todoTotal4, setTodoTotal4] = useState(0)

  const queryInvalid = useCallback(() => {
    const {assignTime, finishTime, insert, billCreateTime, timeFinished, ...formValues} = form4.getFieldsValue(true)
    const [assignTimeBegin, assignTimeEnd] = assignTime ?? []
    const [finishTimeBegin, finishTimeEnd] = finishTime ?? []
    const [billCreateTimeBegin, billCreateTimeEnd] = billCreateTime ?? []
    const [insertBegin, insertEnd] = insert ?? []
    const [timeFinishedBegin, timeFinishedEnd] = timeFinished ?? []
    if ([assignTime, finishTime, insert, billCreateTime, timeFinished].every(item => !item))
      return message.warning('分配时间、处理完成时间、创建时间、下单时间、订单完成时间其中必选一项')
    queryTodoList({
      ...formValues,
      todoStatus: '4',
      assignTimeBegin,
      assignTimeEnd,
      finishTimeBegin,
      finishTimeEnd,
      insertBegin,
      insertEnd,
      billCreateTimeBegin,
      billCreateTimeEnd,
      timeFinishedBegin,
      timeFinishedEnd,
    }).then(({response: {data, total}}) => {
      setTodoList4(data)
      setTodoTotal4(total)
    })
  }, [form4, queryTodoList])

  useEffect(() => {
    queryInvalid()
  }, [queryInvalid])

  const listLoading = stewardModel.stewardOrderTodoList.useLoading()

  // 作废
  const [cForm] = Form.useForm()

  // 分配
  const [dForm] = Form.useForm()
  const [assign, setAssign] = useState<{
    todo: 'assigns' | 'assign-list' | 'assign-detail' // 批量分配 | 分配（列表） | 分配（详情）
    isAssign: boolean
    todoIdList: string[]
  }>({
    todo: null,
    isAssign: false,
    todoIdList: [],
  })
  const [customerList, setCustomerList] = useState<SysUserVO[]>([])

  const queryTodoCustomer = useCallback(todoType => {
    stewardModel.stewardOrderTodoCustomerService([todoType]).then(({response: {data}}) => setCustomerList(data))
  }, [])

  // 待办详情
  const [detailVisible, setDetailVisible] = useState(false)
  const [todoDetails, setTodoDetails] = useState({} as SysTodoVO)
  const [qaOnlineRate, setQaOnlineRate] = useState(0)

  const queryTodoDetails = async todoId => {
    const {
      response: {data},
    } = await stewardModel.stewardOrderTodoDetails({todoId})
    setTodoDetails(data)
  }

  const cleanOnlineQualified = useCleanOnlineQualified()

  const toRenderForm = (form: FormInstance, onFinish: () => void) => {
    return (
      <Form
        form={form}
        initialValues={{
          pageNum: 1,
          pageSize: 10,
          pageable: true,
          insert: [moment().subtract(31, 'day').format(FORMAT_DATE_START), moment().format(FORMAT_DATE_END)],
        }}
        onFinish={onFinish}
        onReset={onFinish}
      >
        <Row gutter={20}>
          <Col span={8}>
            <Form.Item name={'todoType'} label={'待办类型'}>
              <Select
                placeholder={'请选择'}
                options={todoListArr.map(item => ({label: item.label, value: item.id}))}
                allowClear
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name={'serviceStaff'} label={'处理客服'}>
              <Input placeholder={'请输入'} allowClear />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name={'assignTime'}
              label={'分配时间'}
              getValueProps={value => ({value: value ? value.map(item => moment(item)) : []})}
              getValueFromEvent={(_, dateStrings) => {
                const [assignTimeBegin, assignTimeEnd] = dateStrings
                if (assignTimeBegin && assignTimeEnd) {
                  return [
                    moment(assignTimeBegin).format(FORMAT_DATE_START),
                    moment(assignTimeEnd).format(FORMAT_DATE_END),
                  ]
                }
                return undefined
              }}
              rules={[
                {
                  validator: (rule, value, callback) => {
                    if (value) {
                      const [assignTimeBegin, assignTimeEnd] = value
                      const intervalDay = moment(assignTimeEnd).diff(assignTimeBegin, 'day')
                      if (intervalDay > 31) return callback('分配时间间隔不超过31天')
                    }
                    return callback()
                  },
                },
              ]}
            >
              <DatePicker.RangePicker allowClear style={{width: '100%'}} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name={'finishTime'}
              label={'处理完成时间'}
              getValueProps={value => ({value: value ? value.map(item => moment(item)) : []})}
              getValueFromEvent={(_, dateStrings) => {
                const [finishTimeBegin, finishTimeEnd] = dateStrings
                if (finishTimeBegin && finishTimeEnd) {
                  return [
                    moment(finishTimeBegin).format(FORMAT_DATE_START),
                    moment(finishTimeEnd).format(FORMAT_DATE_END),
                  ]
                }
                return undefined
              }}
              rules={[
                {
                  validator: (rule, value, callback) => {
                    if (value) {
                      const [finishTimeBegin, finishTimeEnd] = value
                      const intervalDay = moment(finishTimeEnd).diff(finishTimeBegin, 'day')
                      if (intervalDay > 31) return callback('处理完成时间间隔不超过31天')
                    }
                    return callback()
                  },
                },
              ]}
            >
              <DatePicker.RangePicker allowClear style={{width: '100%'}} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name={'callStatusList'} label={'拨打状态'}>
              <Select
                placeholder={'请选择'}
                options={callStatusArr.map(item => ({label: item.label, value: item.id}))}
                mode={'multiple'}
                allowClear
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name={'insert'}
              label={'创建时间'}
              getValueProps={value => ({value: value ? value.map(item => moment(item)) : []})}
              getValueFromEvent={(_, dateStrings) => {
                const [insertBegin, insertEnd] = dateStrings
                if (insertBegin && insertEnd) {
                  return [moment(insertBegin).format(FORMAT_DATE_START), moment(insertEnd).format(FORMAT_DATE_END)]
                }
                return undefined
              }}
              rules={[
                {
                  validator: (rule, value, callback) => {
                    if (value) {
                      const [insertBegin, insertEnd] = value
                      const intervalDay = moment(insertEnd).diff(insertBegin, 'day')
                      if (intervalDay > 31) return callback('创建时间间隔不超过31天')
                    }
                    return callback()
                  },
                },
              ]}
            >
              <DatePicker.RangePicker style={{width: '100%'}} />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name={'orderId'} label={'订单编号'}>
              <Input placeholder={'请输入'} allowClear />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name={'categoryName'} label={'服务项目'}>
              <Input placeholder={'请输入'} allowClear />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name={'orderMakerType'} label={'下单人类型'}>
              <Select
                placeholder={'请选择'}
                options={[
                  {label: '租客', value: 'lessee'},
                  {label: '房东/门店', value: 'lessor'},
                ]}
                allowClear
              />
            </Form.Item>
          </Col>
          {collapse && (
            <>
              <Col span={8}>
                <Form.Item name={'stewardName'} label={'管家'}>
                  <Input placeholder={'请输入'} allowClear />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name={'propertyAddr'} label={'房源地址'}>
                  <Input placeholder={'请输入'} allowClear />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name={'rentConfirmationStatus'} label={'确认状态'}>
                  <Select
                    placeholder={'请选择'}
                    options={[
                      {label: '未确认', value: 1},
                      {label: '部分确认', value: 2},
                      {label: '已确认', value: 3},
                    ]}
                    mode={'multiple'}
                    allowClear
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name={'lessorName'} label={'客户姓名'}>
                  <Input placeholder={'请输入'} allowClear />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name={'lessorMobile'} label={'客户手机号'}>
                  <Input placeholder={'请输入'} allowClear />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name={'stewardFeeType'} label={'维修收费类型'}>
                  <Select
                    placeholder={'请选择'}
                    options={[
                      {label: '全包收费', value: '1'},
                      {label: '按次计费', value: '2'},
                    ]}
                    allowClear
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name={'billCreateTime'}
                  label={'下单时间'}
                  getValueProps={value => ({value: value ? value.map(item => moment(item)) : []})}
                  getValueFromEvent={(_, dateStrings) => {
                    const [billCreateTimeBegin, billCreateTimeEnd] = dateStrings
                    if (billCreateTimeBegin && billCreateTimeEnd) {
                      return [
                        moment(billCreateTimeBegin).format(FORMAT_DATE_START),
                        moment(billCreateTimeEnd).format(FORMAT_DATE_END),
                      ]
                    }
                    return undefined
                  }}
                  rules={[
                    {
                      validator: (rule, value, callback) => {
                        if (value) {
                          const [billCreateTimeBegin, billCreateTimeEnd] = value
                          const intervalDay = moment(billCreateTimeEnd).diff(billCreateTimeBegin, 'day')
                          if (intervalDay > 31) return callback('下单时间间隔不超过31天')
                        }
                        return callback()
                      },
                    },
                  ]}
                >
                  <DatePicker.RangePicker style={{width: '100%'}} />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name={'timeFinished'}
                  label={'订单完成时间'}
                  getValueProps={value => ({value: value ? value.map(item => moment(item)) : []})}
                  getValueFromEvent={(_, dateStrings) => {
                    const [timeFinishedBegin, timeFinishedEnd] = dateStrings
                    if (timeFinishedBegin && timeFinishedEnd) {
                      return [
                        moment(timeFinishedBegin).format(FORMAT_DATE_START),
                        moment(timeFinishedEnd).format(FORMAT_DATE_END),
                      ]
                    }
                    return undefined
                  }}
                  rules={[
                    {
                      validator: (rule, value, callback) => {
                        if (value) {
                          const [timeFinishedBegin, timeFinishedEnd] = value
                          const intervalDay = moment(timeFinishedEnd).diff(timeFinishedBegin, 'day')
                          if (intervalDay > 31) return callback('订单完成时间间隔不超过31天')
                        }
                        return callback()
                      },
                    },
                  ]}
                >
                  <DatePicker.RangePicker style={{width: '100%'}} />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name={'processTime'} label={'处理花费时间'}>
                  <Select
                    placeholder={'请选择'}
                    options={[
                      {label: '0-5分钟', value: 1},
                      {label: '5-10分钟', value: 2},
                      {label: '10-15分钟', value: 3},
                      {label: '15分钟以上', value: 4},
                    ]}
                    mode={'multiple'}
                    allowClear
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name={'appName'} label={'渠道'}>
                  <Input placeholder={'请输入'} allowClear />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name={'appOrderId'} label={'渠道订单号'}>
                  <Input placeholder={'请输入'} allowClear />
                </Form.Item>
              </Col>
            </>
          )}
        </Row>
        <Row justify={'center'}>
          <Space size={10}>
            <Button type={'link'} style={{padding: 0}} onClick={() => setCollapse(prevState => !prevState)}>
              更多筛选条件
            </Button>
            {collapse ? <UpOutlined style={{color: '#00B7AE'}} /> : <DownOutlined style={{color: '#00B7AE'}} />}
          </Space>
        </Row>
        <Row justify={'end'}>
          <Space>
            <Button type={'primary'} htmlType={'submit'} onClick={() => form.setFieldsValue({pageNum: 1})}>
              查询
            </Button>
            <Button htmlType={'reset'} onClick={() => form.setFieldsValue({pageNum: 1})}>
              重置
            </Button>
          </Space>
        </Row>
      </Form>
    )
  }

  const tableColumns = [
    {title: '待办类型', dataIndex: 'todoType', render: value => todoListCode[value]},
    {title: '处理状态', dataIndex: 'todoStatus', render: value => todoStatusCode[value]},
    {title: '订单编号', dataIndex: 'orderId'},
    {
      title: '下单人类型',
      dataIndex: 'orderMakerType',
      render: value => (value === 'lessee' ? '租客' : value === 'lessor' ? '房东/门店' : ''),
    },
    {title: '渠道订单号', dataIndex: 'appOrderId'},
    {title: '渠道', dataIndex: 'appName'},
    {title: '服务项目', dataIndex: 'categoryName'},
    {title: '客户姓名', dataIndex: 'lessorName'},
    {title: '客户手机号', dataIndex: 'lessorMobile'},
    {title: '管家', dataIndex: 'stewardName'},
    {title: '房源地址', dataIndex: 'propertyAddr'},
    {title: '下单时间', dataIndex: 'orderTime', sorter: true},
    {title: '订单完成时间', dataIndex: 'timeFinished', sorter: true},
    {title: '创建时间', dataIndex: 'insertTime', sorter: true},
    {title: '拨打状态', dataIndex: 'callStatus', render: value => callStatusCode[value]},
    {title: '拨打次数', dataIndex: 'callNum'},
    {title: '处理客服', dataIndex: 'serviceStaff'},
    {title: '分配时间', dataIndex: 'assignTime', sorter: true},
    {title: '处理完成时间', dataIndex: 'finishTime', sorter: true},
  ]

  const loadings = stewardModel.stewardOrderTodoDetails.useLoadings()

  const renderOperate = ({todoId, todoType, orderId, todoStatus}) => [
    {
      when: true,
      title: '查看',
      loading: loadings.pick(todoId),
      onClick: async () => {
        await queryTodoDetails(todoId)
        if (todoType === '09') {
          const {
            response: {data},
          } = await cleanModel.cleanOrderGet({orderId})
          setQaOnlineRate(data?.qaOnlineRate)
        }
        setDetailVisible(true)
      },
    },
    {
      when: ['1', '2'].includes(activeKey),
      title: '分配',
      onClick: () => {
        setAssign({todo: 'assign-list', isAssign: true, todoIdList: [todoId]})
        queryTodoCustomer(todoType)
      },
    },
    {
      when: todoType === '09' && todoStatus === '3',
      title: '查看质检报告',
      onClick: async () => {
        const {
          response: {data},
        } = await cleanModel.cleanOrderGet({orderId})
        cleanOnlineQualified({payloadList: data.payloadList, isOnline: true})
      },
    },
  ]

  return (
    <Page
      breadcrumb={
        <Breadcrumb
          right={
            <Space>
              {['1', '2'].includes(activeKey) && (
                <Button
                  onClick={() => {
                    if (selectedRows1.length === 0) return message.warning('请选择待分配事项')
                    Modal.confirm({
                      title: '作废',
                      centered: true,
                      icon: null,
                      content: (
                        <Form form={cForm}>
                          <Form.Item
                            label={'作废原因'}
                            name={'cancelRemake'}
                            rules={[{required: true, message: '请输入作废原因'}]}
                          >
                            <Input.TextArea maxLength={200} placeholder={'请输入'} />
                          </Form.Item>
                        </Form>
                      ),
                      onOk: async () => {
                        await cForm.validateFields()
                        const cancelRemake = cForm.getFieldValue('cancelRemake')
                        await stewardModel.stewardOrderTodoCancel({
                          cancelRemake,
                          todoIdList: selectedRows1.map(item => item.todoId),
                        })
                        message.success('作废成功！')
                        setSelectedRows1([])
                        queryToAllocation()
                        queryTodo()
                        queryInvalid()
                      },
                      afterClose: () => cForm.resetFields(),
                    })
                  }}
                >
                  批量作废
                </Button>
              )}

              {['1', '2'].includes(activeKey) && (
                <Button
                  onClick={() => {
                    if (selectedRows1.length === 0) return message.warning('请选择待分配事项')
                    const [first] = selectedRows1
                    if (selectedRows1.some(item => item.todoType !== first.todoType))
                      return message.warning('请选择相同类型的待办')
                    queryTodoCustomer(first.todoType)
                    setAssign({todo: 'assigns', isAssign: true, todoIdList: selectedRows1.map(item => item.todoId)})
                  }}
                >
                  批量分配
                </Button>
              )}
              {activeKey === '3' && (
                <Button
                  onClick={() => {
                    const {assignTime, finishTime, insert, billCreateTime, timeFinished} = form3.getFieldsValue(true)
                    const [assignTimeBegin, assignTimeEnd] = assignTime ?? []
                    const [finishTimeBegin, finishTimeEnd] = finishTime ?? []
                    const [billCreateTimeBegin, billCreateTimeEnd] = billCreateTime ?? []
                    const [insertBegin, insertEnd] = insert ?? []
                    const [timeFinishedBegin, timeFinishedEnd] = timeFinished ?? []
                    if ([assignTime, finishTime, insert, billCreateTime, timeFinished].every(item => !item))
                      return message.warning('分配时间、处理完成时间、创建时间、下单时间、订单完成时间其中必选一项')
                    downloadBlob({
                      url: '/steward/order/todo/export',
                      body: {
                        ...form3.getFieldsValue(true),
                        pageable: false,
                        assignTimeBegin,
                        assignTimeEnd,
                        finishTimeBegin,
                        finishTimeEnd,
                        insertBegin,
                        insertEnd,
                        billCreateTimeBegin,
                        billCreateTimeEnd,
                        timeFinishedBegin,
                        timeFinishedEnd,
                        todoStatus: '3',
                      },
                    })
                  }}
                >
                  导出
                </Button>
              )}
            </Space>
          }
        />
      }
    >
      <Tabs className={commonStyles.tabs} activeKey={activeKey} onChange={key => setActiveKey(key)}>
        <Tabs.TabPane
          tab={
            <Badge count={todoTotal1} offset={[10, 0]}>
              待分配
            </Badge>
          }
          key={'1'}
          forceRender={true}
        >
          <div className={commonStyles.filterSection}>{toRenderForm(form1, queryToAllocation)}</div>
          <div className={commonStyles.tableSection}>
            <Table
              {...sorterProps1}
              rowKey={'todoId'}
              dataSource={todoList1}
              loading={listLoading}
              scroll={{x: 'max-content'}}
              rowSelection={{
                fixed: true,
                selectedRowKeys: selectedRows1.map(item => item.todoId),
                onChange: (_, selectedRows) => setSelectedRows1(selectedRows),
              }}
              columns={[
                ...tableColumns,
                {
                  title: '操作',
                  fixed: 'right',
                  dataIndex: 'todoId',
                  render: (value, record) => {
                    return (
                      <Space>
                        {renderOperate({
                          todoId: value,
                          todoType: record.todoType,
                          orderId: record.orderId,
                          todoStatus: record.todoStatus,
                        })
                          .filter(item => item.when)
                          .map((item, index) => (
                            <Button
                              key={index}
                              type={'link'}
                              size={'small'}
                              style={{padding: 0}}
                              onClick={item.onClick}
                              loading={item.loading}
                            >
                              {item.title}
                            </Button>
                          ))}
                      </Space>
                    )
                  },
                },
              ]}
              pagination={getPaginationProps(form1, todoTotal1)}
            />
          </div>
        </Tabs.TabPane>
        <Tabs.TabPane
          tab={
            <Badge count={todoTotal2} offset={[10, 0]}>
              待处理
            </Badge>
          }
          key={'2'}
          forceRender={true}
        >
          <div className={commonStyles.filterSection}>{toRenderForm(form2, queryTodo)}</div>
          <div className={commonStyles.tableSection}>
            <Table
              {...sorterProps2}
              rowKey={'todoId'}
              dataSource={todoList2}
              loading={listLoading}
              scroll={{x: 'max-content'}}
              rowSelection={{
                fixed: true,
                selectedRowKeys: selectedRows1.map(item => item.todoId),
                onChange: (_, selectedRows) => setSelectedRows1(selectedRows),
              }}
              columns={[
                ...tableColumns,
                {
                  title: '操作',
                  fixed: 'right',
                  dataIndex: 'todoId',
                  render: (value, record) => {
                    return (
                      <Space>
                        {renderOperate({
                          todoId: value,
                          todoType: record.todoType,
                          orderId: record.orderId,
                          todoStatus: record.todoStatus,
                        })
                          .filter(item => item.when)
                          .map((item, index) => (
                            <Button
                              key={index}
                              type={'link'}
                              size={'small'}
                              style={{padding: 0}}
                              onClick={item.onClick}
                              loading={item.loading}
                            >
                              {item.title}
                            </Button>
                          ))}
                      </Space>
                    )
                  },
                },
              ]}
              pagination={getPaginationProps(form2, todoTotal2)}
            />
          </div>
        </Tabs.TabPane>
        <Tabs.TabPane
          tab={
            <Badge count={todoTotal3} offset={[10, 0]}>
              已处理
            </Badge>
          }
          key={'3'}
          forceRender={true}
        >
          <div className={commonStyles.filterSection}>{toRenderForm(form3, queryDo)}</div>
          <div className={commonStyles.tableSection}>
            <Table
              {...sorterProps3}
              rowKey={'todoId'}
              dataSource={todoList3}
              loading={listLoading}
              scroll={{x: 'max-content'}}
              columns={[
                ...tableColumns,
                {
                  title: '操作',
                  fixed: 'right',
                  dataIndex: 'todoId',
                  render: (value, record) => {
                    return (
                      <Space>
                        {renderOperate({
                          todoId: value,
                          todoType: record.todoType,
                          orderId: record.orderId,
                          todoStatus: record.todoStatus,
                        })
                          .filter(item => item.when)
                          .map((item, index) => (
                            <Button
                              key={index}
                              type={'link'}
                              size={'small'}
                              style={{padding: 0}}
                              onClick={item.onClick}
                              loading={item.loading}
                            >
                              {item.title}
                            </Button>
                          ))}
                      </Space>
                    )
                  },
                },
              ]}
              pagination={getPaginationProps(form3, todoTotal3)}
            />
          </div>
        </Tabs.TabPane>
        <Tabs.TabPane tab={'已作废'} key={'4'} forceRender={true}>
          <div className={commonStyles.filterSection}>{toRenderForm(form4, queryInvalid)}</div>
          <div className={commonStyles.tableSection}>
            <Table
              {...sorterProps4}
              rowKey={'todoId'}
              dataSource={todoList4}
              loading={listLoading}
              scroll={{x: 'max-content'}}
              columns={[
                ...tableColumns,
                {
                  title: '操作',
                  fixed: 'right',
                  dataIndex: 'todoId',
                  render: (value, record) => {
                    return (
                      <Space>
                        {renderOperate({
                          todoId: value,
                          todoType: record.todoType,
                          orderId: record.orderId,
                          todoStatus: record.todoStatus,
                        })
                          .filter(item => item.when)
                          .map((item, index) => (
                            <Button
                              key={index}
                              type={'link'}
                              size={'small'}
                              style={{padding: 0}}
                              onClick={item.onClick}
                              loading={item.loading}
                            >
                              {item.title}
                            </Button>
                          ))}
                      </Space>
                    )
                  },
                },
              ]}
              pagination={getPaginationProps(form4, todoTotal4)}
            />
          </div>
        </Tabs.TabPane>
      </Tabs>

      <Modal
        title={'分配'}
        open={assign.isAssign}
        centered
        afterClose={() => {
          dForm.resetFields()
          setCustomerList([])
        }}
        destroyOnClose
        footer={null}
        onCancel={() => setAssign({todo: null, isAssign: false, todoIdList: []})}
      >
        <Form
          form={dForm}
          onFinish={async () => {
            await dForm.validateFields()
            const formValues = dForm.getFieldsValue(true)
            await stewardModel.stewardOrderTodoAssign({...formValues, todoIdList: assign.todoIdList})
            message.success('分配成功！')
            if (assign.todo === 'assign-detail') {
              queryTodoDetails(assign.todoIdList[0]) // 刷新详情信息
            }
            if (activeKey === '1') {
              queryToAllocation() // 待分配 刷新待分配列表信息
            }
            queryTodo() // 刷新已分配信息
            setAssign({todo: null, isAssign: false, todoIdList: []})
            setSelectedRows1([])
          }}
          onReset={() => setAssign({todo: null, isAssign: false, todoIdList: []})}
        >
          <Form.Item name={'serviceStaffId'} label={'客服'} rules={[{required: true, message: '请选择'}]}>
            <Select
              allowClear
              placeholder={'请选择'}
              options={customerList.map(item => ({label: `${item.name}（${item.mobile}）`, value: item.userId}))}
              onChange={(value, option) => {
                if ('label' in option) {
                  dForm.setFieldsValue({serviceStaff: option?.label})
                }
              }}
              showSearch
              filterOption={(inputValue, option) => {
                return option?.label?.indexOf(inputValue) > -1
              }}
            />
          </Form.Item>
          <Form.Item name={'contactName'} label={'联系人姓名'}>
            <Input placeholder={'请输入'} />
          </Form.Item>
          <Form.Item name={'contactPhone'} label={'联系人手机号'}>
            <Input placeholder={'请输入'} />
          </Form.Item>
          <Row justify={'end'}>
            <Space>
              <Button htmlType={'reset'}>取消</Button>
              <Button htmlType={'submit'} type={'primary'}>
                确定
              </Button>
            </Space>
          </Row>
        </Form>
      </Modal>

      <TodoDetail
        title={
          <Space>
            <span>待办详情</span>
            {['1', '2'].includes(todoDetails.todoStatus) && (
              <Button
                type={'link'}
                onClick={() => {
                  setAssign({todo: 'assign-detail', isAssign: true, todoIdList: [todoDetails.todoId]})
                  queryTodoCustomer(todoDetails.todoType)
                }}
              >
                分配
              </Button>
            )}
          </Space>
        }
        visible={detailVisible}
        onClose={() => setDetailVisible(false)}
        todoDetails={todoDetails}
        qaOnlineRate={qaOnlineRate}
        onSuccess={async () => {
          queryDo()
          await queryTodoDetails(todoDetails.todoId)
        }}
      />
    </Page>
  )
}
