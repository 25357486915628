import React from 'react'
import {Descriptions} from 'antd'
import {useCodeSync} from '@/store/models/Common'

export default function ClientBasicInfo(props: {info: LessorInfoVO}) {
  const {info} = props
  const gender = useCodeSync('gender')

  return (
    <Descriptions style={{paddingRight: '30%'}}>
      <Descriptions.Item label={'客户名称'}>{info.certName}</Descriptions.Item>
      <Descriptions.Item label={'手机号码'}>{info.mobile}</Descriptions.Item>
      <Descriptions.Item label={'性别'}>{gender[info.gender]}</Descriptions.Item>
      <Descriptions.Item label={'地址'}>{`${info.city ?? ''}${info.district ?? ''}${
        info.bizCircle ?? ''
      }`}</Descriptions.Item>
      <Descriptions.Item label={'身份证号'}>{info.certNo}</Descriptions.Item>
      <Descriptions.Item label={'备注'}>{info.remark}</Descriptions.Item>
    </Descriptions>
  )
}
