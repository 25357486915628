import React, {useEffect, useState} from 'react'
import {Button, Checkbox, Col, Form, Input, InputNumber, message, Modal, Row, Space, Transfer, Tree} from 'antd'
import {Page} from '@/components/Page'
import {Breadcrumb} from '@/components/Breadcrumb'
import commonStyles from '@/assets/styles/common.module.less'
import {stewardModel} from '@/store/models/Steward'
import {DataNode} from 'antd/es/tree'
import {useAppHistory, useAppLocation} from '@/common/hooks/useRouter'

const flatData = item => {
  const res: StewardServiceTemplateSvcInsertDTO[] = []
  const flatIds = (data: any[]) => {
    data.forEach(a => {
      if (a.stewardServiceList) {
        flatIds(a.stewardServiceList)
      } else {
        res.push({serviceId: a.id, serviceName: a.name, priceList: []})
      }
    })
  }
  flatIds([item])

  return res
}

export default function ClientServiceTemplateAdd() {
  const history = useAppHistory()
  const location = useAppLocation<'/client-template/add'>()
  const templateId = location.state?.templateId
  const [form] = Form.useForm()
  const [editForm1] = Form.useForm()
  const [editForm2] = Form.useForm()
  const [open1, setOpen1] = useState<boolean>(false)
  const [open2, setOpen2] = useState<boolean>(false)
  const [serviceTree, setServiceTree] = useState<StewardServiceVO[]>([])
  const [chargeList, setChargeList] = useState<StewardServiceChargeVO[]>([])
  const [chargeKeys, setChargeKeys] = useState<string[]>([])
  const loading = stewardModel.stewardServiceTemplateSave.useLoading()

  useEffect(() => {
    stewardModel.stewardAdminServiceTree({} as StewardServiceQueryDTO).then(({response: {data}}) => {
      setServiceTree(data)
      if (templateId) {
        stewardModel.stewardServiceTemplateGetTree({templateId}).then(({response: {data}}) => {
          const {templateSvc, templateId, templateName} = data
          form.setFieldsValue({templateId, templateName, templateSvcInsertList: templateSvc})
        })
      }
    })

    stewardModel
      .stewardAdminServiceChargeList({pageable: false, enable: 1, categoryId: '1'} as StewardServiceChargeQueryDTO)
      .then(({response: {data}}) => setChargeList(data))
  }, [form, templateId])

  return (
    <Page breadcrumb={<Breadcrumb />}>
      <div className={commonStyles.filterSection}>
        <Form
          form={form}
          labelCol={{flex: '120px'}}
          wrapperCol={{span: 10}}
          onFinish={async () => {
            const {templateSvcInsertList, templateName, templateId} = form.getFieldsValue(true)
            if (!templateSvcInsertList.length) return message.warning('请选择服务项目！')
            if (!templateSvcInsertList.every(item => item.priceList.length)) {
              const serviceNames = []
              templateSvcInsertList.forEach(({priceList, serviceName}) => {
                if (!priceList.length) {
                  serviceNames.push(serviceName)
                }
              })
              return message.warning(`${serviceNames.join('、')}未选收费服务！`)
            }
            await stewardModel.stewardServiceTemplateSave({templateSvcInsertList, templateName, templateId})
            message.success('提交成功！')
            history.goBack()
          }}
          onReset={() => history.goBack()}
        >
          <Form.Item label={'模板名称'} name={'templateName'} rules={[{required: true, message: '请输入模板名称'}]}>
            <Input placeholder={'请输入'} allowClear />
          </Form.Item>
          <Form.Item label={'服务项目'} shouldUpdate required>
            {f => {
              const templateSvcInsertList: StewardServiceTemplateSvcInsertDTO[] =
                f.getFieldValue('templateSvcInsertList') ?? []
              return (
                <Tree
                  checkable
                  treeData={serviceTree as any}
                  onCheck={(keys, e) => {
                    const {node, checked} = e
                    const ids = flatData(node)
                    if (checked) {
                      form.setFieldsValue({templateSvcInsertList: [...templateSvcInsertList, ...ids]})
                    } else {
                      form.setFieldsValue({
                        templateSvcInsertList: templateSvcInsertList.filter(
                          item => !ids.map(id => id.serviceId).includes(item.serviceId)
                        ),
                      })
                    }
                  }}
                  checkedKeys={templateSvcInsertList.map(item => item.serviceId)}
                  titleRender={_node => {
                    const node = _node as DataNode & StewardServiceVO
                    const stewardServiceList = node.stewardServiceList
                    return (
                      <Row justify={'space-between'}>
                        <Col>
                          <span>{node.name}</span>
                        </Col>
                        {!stewardServiceList && (
                          <Col>
                            <Button
                              type={'link'}
                              style={{paddingTop: 0, paddingBottom: 0}}
                              onClick={e => {
                                if (templateSvcInsertList.map(item => item.serviceId).includes(node.id)) {
                                  const item = templateSvcInsertList.find(item => item.serviceId === node.id)
                                  setChargeKeys(item.priceList?.map(item => item.chargeId))
                                  editForm1.setFieldsValue(item)
                                  setOpen1(true)
                                } else {
                                  message.warning('请先勾选该服务项目，再进行编辑操作！')
                                }
                                e.stopPropagation()
                              }}
                            >
                              编辑
                            </Button>
                          </Col>
                        )}
                      </Row>
                    )
                  }}
                  blockNode
                  fieldNames={{title: 'name', key: 'id', children: 'stewardServiceList'}}
                />
              )
            }}
          </Form.Item>
          <Row justify={'center'}>
            <Space>
              <Button htmlType={'reset'}>取消</Button>
              <Button type={'primary'} htmlType={'submit'} loading={loading}>
                提交
              </Button>
            </Space>
          </Row>
        </Form>
      </div>

      <Modal
        open={open1}
        title={'编辑服务'}
        centered
        width={1000}
        destroyOnClose
        afterClose={() => {
          editForm1.resetFields()
          setChargeKeys([])
        }}
        onCancel={() => setOpen1(false)}
        onOk={() => editForm1.submit()}
      >
        <Form
          form={editForm1}
          onFinish={() => {
            const priceList = editForm1.getFieldValue('priceList')
            if (!priceList.length) return message.warning('请选择收费服务！')
            if (!priceList.every(item => item.allInCharge && item.everyCharge)) {
              const chargeNames = priceList
                .filter(item => !(item.allInCharge && item.everyCharge))
                .map(item => item.chargeName)
              return message.warning(`${chargeNames.join('、')}未填写服务收费价格！`)
            }
            const editFormValues = editForm1.getFieldsValue(true)
            const templateSvcInsertList = form.getFieldValue('templateSvcInsertList')
            form.setFieldsValue({
              templateSvcInsertList: templateSvcInsertList.map(item =>
                item.serviceId === editFormValues.serviceId ? editFormValues : item
              ),
            })
            setOpen1(false)
          }}
        >
          <Form.Item label={'服务项目'}>
            <span>{editForm1.getFieldValue('serviceName')}</span>
          </Form.Item>
          <Form.Item label={'收费服务'} shouldUpdate required colon={false}>
            {f => {
              const priceList = editForm1.getFieldValue('priceList') ?? []
              return (
                <Transfer
                  rowKey={item => item.chargeId}
                  dataSource={chargeList}
                  render={item => item.chargeName}
                  targetKeys={chargeKeys}
                  onChange={targetKeys => {
                    const newKeys = targetKeys.filter(item => !chargeKeys.includes(item))
                    const list = chargeList.filter(item => newKeys.includes(item.chargeId))
                    setChargeKeys(targetKeys)
                    editForm1.setFieldsValue({
                      priceList: [
                        ...priceList,
                        ...list.map(item => ({chargeId: item.chargeId, chargeName: item.chargeName})),
                      ],
                    })
                  }}
                  showSearch
                  filterOption={(inputValue, item) => item.chargeName.indexOf(inputValue as any) > -1}
                  listStyle={{height: 300, width: 0}}
                  className={commonStyles.transfer}
                >
                  {({direction, filteredItems, selectedKeys, onItemSelect}) => {
                    if (direction === 'right') {
                      return filteredItems.map(item => (
                        <div
                          key={item.chargeId}
                          style={{
                            minHeight: '32px',
                            padding: '0 12px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                          }}
                        >
                          <Checkbox
                            checked={selectedKeys.includes(item.chargeId)}
                            value={item.chargeId}
                            onChange={({target: {checked, value}}) => onItemSelect(value, checked)}
                          >
                            {item.chargeName}
                          </Checkbox>
                          <Button
                            type={'link'}
                            onClick={() => {
                              const value = priceList.find(price => price.chargeId === item.chargeId)
                              editForm2.setFieldsValue(value)
                              setOpen2(true)
                            }}
                          >
                            编辑
                          </Button>
                        </div>
                      ))
                    }
                  }}
                </Transfer>
              )
            }}
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        open={open2}
        centered
        title={'服务收费编辑'}
        width={500}
        destroyOnClose
        afterClose={() => editForm2.resetFields()}
        onCancel={() => setOpen2(false)}
        onOk={() => editForm2.submit()}
      >
        <Form
          form={editForm2}
          onFinish={() => {
            const values = editForm2.getFieldsValue(true)
            const priceList = editForm1.getFieldValue('priceList')
            editForm1.setFieldsValue({
              priceList: priceList.map(item => (item.chargeId === values.chargeId ? values : item)),
            })
            setOpen2(false)
          }}
        >
          <Form.Item label={'全包收费'} name={'allInCharge'} rules={[{required: true, message: '请输入全包收费'}]}>
            <InputNumber placeholder={'请输入'} min={0} step={1} addonAfter={'元'} />
          </Form.Item>
          <Form.Item label={'按次收费'} name={'everyCharge'} rules={[{required: true, message: '请输入全包收费'}]}>
            <InputNumber placeholder={'请输入'} min={0} step={1} addonAfter={'元'} />
          </Form.Item>
        </Form>
      </Modal>
    </Page>
  )
}
