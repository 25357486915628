import React, {useCallback, useEffect, useRef, useState} from 'react'
import {message, Modal, Upload} from 'antd'
import {UploadChangeParam} from 'antd/lib/upload'
import {UploadFile} from 'antd/lib/upload/interface'
import {PlusOutlined} from '@ant-design/icons'
import {UploadProps} from 'antd/es/upload'
import {baseUrl} from '@/common/config'
import {$api, createHeaders} from '@/store/service'
import {isVideoUrl} from '@/pages/steward/StewardDetail'
// import {getImg} from '@/utils/util'

function getBase64(file): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result as string)
    reader.onerror = error => reject(error)
  })
}

const isPdf = (url: string) => /\.pdf$/i.test(url)

export interface ImageResult {
  imgId: string
  imgUrl: string
}

type Optional<T, K extends keyof T> = Partial<Pick<T, K>> & Omit<T, K>

// type FileList = UploadProps['fileList'] | Pick<UploadFile, 'uid' | 'url'>[]
type FileList = Optional<UploadFile, 'name'>[]

export interface AlbumProps extends Omit<UploadProps, 'fileList' | 'defaultFileList'> {
  title?: string
  limit?: number // 图片数量限制
  fileList?: FileList
  defaultFileList?: FileList
  onDone?: (fileList: (UploadFile & ImageResult)[]) => any
  children?: React.ReactNode
}

function _Album(
  {title, onChange, limit, fileList, defaultFileList, onDone, children, beforeUpload, ...rest}: AlbumProps,
  ref
) {
  const changeRef = useRef(onChange)
  const doneRef = useRef(onDone)
  const beforeUploadRef = useRef(beforeUpload)
  changeRef.current = onChange
  doneRef.current = onDone
  beforeUploadRef.current = beforeUpload

  const action = (rest.action || baseUrl + '/property/img/upload') as string

  const [_fileList, _setFileList] = useState<any[]>([])
  const [previewImage, setPreviewImage] = useState({})
  const [previewVisible, setPreviewVisible] = useState(false)
  // const teamId = userModel.useData(data => data.currentTeam?.teamId)

  useEffect(
    function () {
      _setFileList(fileList)
    },
    [fileList]
  )

  const handlePreview = async (file: UploadFile) => {
    if (isPdf(file.url)) {
      window.open(file.url)
      return
    }

    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj)
    }

    setPreviewImage({...file, video: isVideoUrl(file.url) ? '1' : null})
    setPreviewVisible(true)
    // this.setState({
    //   previewImage: file.url || file.preview,
    //   previewVisible: true,
    //   previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
    // })
  }

  // const uploadButton = (
  //   <div>
  //     <PlusOutlined />
  //     <div className='ant-upload-text'>{title || '上传照片'}</div>
  //   </div>
  // )

  const canUpload = useCallback(
    function canUpload<T>(file: T, FileList: T[], hitMessage = false) {
      if (typeof beforeUploadRef.current === 'function') {
        const result = beforeUploadRef.current(file as any, FileList as any)
        if (result !== true) return result
      }
      if (limit && _fileList?.length + FileList.length > limit) {
        message.destroy()
        message.info(`图片不能超过${limit}张`)
        return false
      }
      return true
    },
    [_fileList?.length, limit]
  )

  const onUploadChange = useCallback(
    (info: UploadChangeParam) => {
      function isChange(FileList: UploadChangeParam['fileList']) {
        return !limit || FileList.length <= limit
      }

      if (isChange(info.fileList)) {
        changeRef.current?.(info)
        _setFileList(info.fileList)
      }
      if (
        typeof doneRef.current === 'function' &&
        info.fileList.filter(item => !item.url).every(item => item.status === 'done')
      ) {
        doneRef.current(info.fileList.map(item => (item.response ? {...item, ...item.response.data} : item)))
      }
    },
    [limit]
  )

  const pasteListener = useCallback(
    async (event: ClipboardEvent) => {
      const files = event.clipboardData?.files
      if (!files?.length) return

      let blobs: File[] = []
      for (let i = 0; i < files.length; i++) {
        const file = files.item(i)
        if (file.type.startsWith('image/')) {
          blobs.push(file)
        }
      }

      blobs = blobs.slice(0, 1) // 单次限制一张图片的上传
      for (const blob of blobs) {
        const result = canUpload(blob, [blob])
        if (result) {
          message.warning(`${blob.name ? `"${blob.name}" ` : ''}上传中...`)
          const fd = new FormData()
          fd.append(rest.name || 'file', blob)
          const {response} = await $api.postAsync({
            uri: action,
            body: fd,
            requestOptions: {headers: {...createHeaders(), 'Content-Type': 'multipart/form-data'}},
          })
          const info = {
            // file: {uid: data.imgUrl, name: data.filename || data.imgUrl, url: getImg(data.imgUrl)},
            file: ({
              ...blob,
              response,
              originFileObj: blob,
              lastModified: blob.lastModified,
              lastModifiedDate: (blob as any).lastModifiedDate,
              name: blob.name,
              size: blob.size,
              type: blob.type,
            } as unknown) as UploadFile,
            fileList: [],
          }
          info.fileList = [...(_fileList || []), info.file]
          onUploadChange(info)
        }
      }
    },
    [_fileList, action, canUpload, onUploadChange, rest.name]
  )

  const [pasteEnabled, setPasteEnabled] = useState(false)

  useEffect(() => {
    if (pasteEnabled) {
      document.addEventListener('paste', pasteListener)
    }
    return () => {
      document.removeEventListener('paste', pasteListener)
    }
  }, [pasteEnabled, pasteListener])

  return (
    <>
      <div onMouseEnter={() => setPasteEnabled(true)} onMouseLeave={() => setPasteEnabled(false)}>
        <Upload
          // action='https://www.mocky.io/v2/5cc8019d300000980a055e76'
          action={baseUrl + '/property/img/upload'}
          listType='picture-card'
          fileList={_fileList}
          onPreview={handlePreview}
          headers={createHeaders()}
          onChange={onUploadChange}
          beforeUpload={canUpload}
          {...rest}
        >
          {children === undefined ? <UploadButton title={title} /> : children}
        </Upload>
      </div>
      <Modal
        open={previewVisible}
        // title={previewTitle}
        footer={null}
        title={'预览'}
        destroyOnClose
        centered
        onCancel={() => setPreviewVisible(false)}
      >
        {/*@ts-ignore*/}
        {previewImage?.video === '1' ? (
          // @ts-ignore
          <video controls src={previewImage.url} width='100%' autoPlay />
        ) : (
          // @ts-ignore
          <img alt='预览' style={{width: '100%'}} src={previewImage.url || previewImage.preview} />
        )}
      </Modal>
    </>
  )
}

export function extraFileListString(fileList: FileList | string[] = []) {
  return fileList?.map<string>(value => {
    if (typeof value === 'object') {
      if ('response' in value) return (value.response as any).data?.imgUrl
      if ('url' in value && 'uid' in value) return value.uid
    }
    return value
  })
}

export const Album = React.forwardRef(_Album)

Album.defaultProps = {
  limit: 9,
  fileList: [],
  defaultFileList: [],
  accept: 'image/*', // 'png,jpeg,jpg',
  multiple: true,
  // showUploadList: {
  //   showRemoveIcon: false,
  // },
}

export const UploadButton = (props: {title?: string}) => (
  <div>
    <PlusOutlined />
    <div className='ant-upload-text'>{props.title || '上传照片'}</div>
  </div>
)
