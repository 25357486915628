const ebaas = {
  inServiceStatus: [
    {label: '未知', id: 0},
    {label: '在职', id: 1},
    {label: '离职', id: 2},
  ],
  openStatus: [
    {label: '未知', id: 99},
    {label: '筹建中', id: 1},
    {label: '营业中', id: 2},
    {label: '暂停营业', id: 3},
  ],

  parkingTypes: [
    {label: '有免费车位', id: 1},
    {label: '有可租车位', id: 2},
    {label: '固定车位(免费)', id: 3},
    {label: '无车位', id: 4},
  ],

  isElevator: [
    {label: '有', id: 1},
    {label: '无', id: 0},
  ],

  heatingTypes: [
    {label: '未知', id: 99},
    {label: '自采暖', id: 1},
    {label: '集中供暖', id: 2},
    {label: '无需供暖', id: 3},
  ],

  fitment: [
    {label: '毛坯', id: 1},
    {label: '简装', id: 2},
    {label: '精装', id: 3},
    {label: '豪华装修', id: 6},
  ],

  serviceFacilities: [
    {label: '电梯', id: 1},
    {label: '近地铁', id: 2},
    {label: '便利店', id: 3},
    {label: '停车场', id: 4},
    {label: '健身房', id: 5},
    {label: '社区活动', id: 6},
    {label: '安保监控', id: 7},
    {label: '书吧', id: 8},
    {label: '吧台', id: 9},
    {label: '休闲区', id: 10},
    {label: '桌球区', id: 11},
    {label: 'DIY餐吧', id: 12},
    {label: '洗衣房', id: 13},
    {label: '空中花园', id: 14},
    {label: '休息区', id: 15},
    {label: '影音区', id: 16},
    {label: '前台', id: 17},
    {label: '会客区', id: 18},
    {label: '舞蹈室', id: 19},
    {label: '24H安保', id: 20},
    {label: '代收快递', id: 21},
    {label: '保洁维修', id: 22},
    {label: 'ATM', id: 23},
    {label: '专属客服', id: 24},
  ],

  timeLimitMin: [
    {label: '1个月', id: 1},
    {label: '2个月', id: 2},
    {label: '3个月', id: 3},
    {label: '4个月', id: 4},
    {label: '5个月', id: 5},
    {label: '6个月', id: 6},
    {label: '7个月', id: 7},
    {label: '8个月', id: 8},
    {label: '9个月', id: 9},
    {label: '10个月', id: 10},
    {label: '11个月', id: 11},
    {label: '1年', id: 12},
    {label: '2年', id: 24},
    {label: '3年', id: 36},
    {label: '未知', id: 99},
  ],

  timeLimitMax: [
    {label: '1个月', id: 1},
    {label: '2个月', id: 2},
    {label: '3个月', id: 3},
    {label: '4个月', id: 4},
    {label: '5个月', id: 5},
    {label: '6个月', id: 6},
    {label: '7个月', id: 7},
    {label: '8个月', id: 8},
    {label: '9个月', id: 9},
    {label: '10个月', id: 10},
    {label: '11个月', id: 11},
    {label: '1年', id: 12},
    {label: '2年', id: 24},
    {label: '3年', id: 36},
    {label: '不限', id: 999},
    {label: '未知', id: 99},
  ],

  balconyNum: [
    {label: '无阳台', id: 0},
    {label: '有阳台', id: 1},
  ],

  windowTypes: [
    {label: '无窗', id: 1},
    {label: '内窗', id: 2},
    {label: '外窗', id: 3},
    {label: '其它', id: 4},
  ],

  isOpenKitchen: [
    {label: '无', id: 0},
    {label: '有', id: 1},
  ],

  facilities: [
    {label: '床', id: 1},
    {label: '衣柜', id: 2},
    {label: '智能门锁', id: 3},
    {label: '书桌', id: 4},
    {label: '暖气', id: 5},
    {label: '天然气', id: 6},
    {label: 'WIFI', id: 7},
    {label: '电视机', id: 8},
    {label: '冰箱', id: 9},
    {label: '洗衣机', id: 10},
    {label: '空调', id: 11},
    {label: '热水器', id: 12},
    {label: '微波炉', id: 13},
    {label: '抽油烟机', id: 14},
    {label: '燃气', id: 15},
    {label: '电磁炉', id: 16},
    {label: '沙发', id: 17},
    {label: '餐桌', id: 18},
    {label: '橱柜', id: 19},
  ],

  syncStatus: [
    {label: '下架', id: 0},
    {label: '上架', id: 1},
  ],

  towards: [
    {label: '东', id: 1},
    {label: '南', id: 2},
    {label: '西', id: 3},
    {label: '北', id: 4},
    {label: '东南', id: 5},
    {label: '东北', id: 6},
    {label: '西南', id: 7},
    {label: '西北', id: 8},
    {label: '南北', id: 9},
    {label: '东西', id: 10},
    {label: '东西北', id: 11},
    {label: '东西南', id: 12},
    {label: '南北东', id: 13},
    {label: '南北西', id: 14},
    {label: '东南西北', id: 15},
  ],

  roomOpenStatus: [
    {label: '待出租', id: 0},
    {label: '已出租', id: 1},
    {label: '不可租', id: 2},
    {label: '配置中', id: 3},
    {label: '已预订', id: 4},
  ],

  storeType: [
    {id: 1, label: '实体'},
    {id: 2, label: '虚拟'},
  ],

  storeStatus: [
    {id: 1, label: '正常'},
    {id: 2, label: '暂停'},
    {id: 3, label: '关闭'},
  ],

  toAliStatus: [
    {id: 0, label: '待同步'},
    {id: 1, label: '成功'},
    {id: 9, label: '同步失败'},
  ],

  isToAli: [
    {id: 0, label: '待上翻'},
    {id: 1, label: '上翻成功'},
    {id: 9, label: '上翻失败'},
  ],

  verificationStatus: [
    {id: 1, label: '通过'},
    {id: 0, label: '审核中'},
    {id: 9, label: '核验失败'},
  ],
}

export default ebaas
