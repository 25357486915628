import {Model} from '@redux-model/web'
import {$api} from '@/store/service'

interface MsgModel {}

class Msg extends Model<MsgModel> {
  protected initReducer(): MsgModel {
    return {}
  }

  // 消息分类-列表
  commonAppSysMsgListCategory = $api.action(() =>
    this.post<ApiResponse<SysMsgVO[]>>('/common-app/sys-msg/list-category')
  )

  // 消息-列表
  commonAppSysMsgListMsg = $api.action((args: SysMsgListQueryDTO) =>
    this.post<ApiResponse<SysMsgVO[]>>('/common-app/sys-msg/list-msg').body(args)
  )

  // 消息(分类)-已读  |  object:{categoryCode:消息分类code,msgId:消息id}
  commonAppSysMsgSetCategoryRead = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<Record<string, any>>>('/common-app/sys-msg/set-category-read').body(args)
  )
}

export const msgModel = new Msg()
