import React, {useCallback, useEffect, useState} from 'react'
import commonStyles from '@/assets/styles/common.module.less'
import {Button, Col, Form, Input, Row, Select, Space, Table, Tabs} from 'antd'
import {saleModel} from '@/store/models/Sale'
import {useCodeArraySync, useCodeSync} from '@/store/models/Common'
import {Page} from '@/components/Page'
import {useAppHistory} from '@/common/hooks/useRouter'
import {ColumnsType} from 'antd/lib/table/interface'
import {usePermission} from '@/components/Permission'
import {getPaginationProps} from '@/common/biz/getPaginationProps'
import {useFocusEffect} from '@/common/hooks/useFocusEffect'

const getStatus = (sign: string, audit: string) => {
  if (sign === '0') return '/'
  if (!audit) return '未审核'
  return '已审核'
}

export default function SaleClientSignList() {
  const history = useAppHistory()
  const saleCustomerSign = useCodeSync('sale.customer.sign')
  const saleCustomerSignArr = useCodeArraySync('sale.customer.sign')

  const [form] = Form.useForm()
  const [auth] = usePermission()

  const [activeKey, setActiveKey] = useState(() => {
    if (auth('001049001')) return '1'
    if (auth('001050001')) return '2'
    return ''
  })

  const [total, setTotal] = useState(0)
  const [dataSource, setDataSource] = useState<SaleCustomerSignVO[]>([])
  const search = useCallback(async () => {
    const value: SaleCustomerSignListDTO = form.getFieldsValue(true)
    const {
      response: {data, total},
    } = await saleModel.saleCustomerSignList(value)
    setDataSource(data)
    setTotal(total)
  }, [form])

  useFocusEffect(() => {
    form.submit()
  })

  // 如果 tabs 作为条件出现在页面中，这使用 search() 来触发查询（因为切换 tab 的时候可以更新 search，从而触发查询）
  useEffect(() => {
    search()
  }, [search])

  return (
    <Page>
      <Tabs
        className={commonStyles.tabs}
        activeKey={activeKey}
        onChange={key => {
          form.setFieldsValue({pageNum: 1, signStatus: key === '2' ? '2' : ''})
          form.submit()
          setActiveKey(key)
        }}
      >
        <Tabs.TabPane tab={'签约审批'} key={'1'} disabled={!auth('001049001')} />
        <Tabs.TabPane tab={'合同确认'} key={'2'} disabled={!auth('001050001')} />
      </Tabs>
      <Form initialValues={{pageNum: 1, pageSize: 10}} form={form} onFinish={search} onReset={search}>
        <div className={commonStyles.filterSection}>
          <Row gutter={80}>
            <Col span={8}>
              <Form.Item label={'关键字'} name={'keyword'}>
                <Input placeholder={'请输入客户名称或手机号'} allowClear />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={'BD'} name={'holderName'}>
                <Input placeholder={'请输入BD名称'} allowClear />
              </Form.Item>
            </Col>
            {activeKey === '1' && (
              <Col span={8}>
                <Form.Item label={'签约审批状态'} name={'signStatus'}>
                  <Select placeholder={'请选择'} allowClear>
                    {saleCustomerSignArr.map(item => (
                      <Select.Option value={item.id} key={item.id}>
                        {item.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            )}
          </Row>
          <Row justify={'end'}>
            <Space>
              <Button type={'primary'} htmlType={'submit'} onClick={() => form.setFieldsValue({pageNum: 1})}>
                查询
              </Button>
              <Button htmlType={'reset'}>重置</Button>
            </Space>
          </Row>
        </div>

        <div className={commonStyles.tableSection}>
          <Table
            loading={saleModel.saleCustomerSignList.useLoading()}
            rowKey={'signId'}
            dataSource={dataSource}
            columns={[
              {title: '客户名称', dataIndex: 'name'},
              {title: '手机号', dataIndex: 'mobile'},
              {title: 'BD', dataIndex: 'holderName'},
              {title: '签约类型', dataIndex: 'previewId', render: text => (text ? '续约' : '新签')},
              {title: '合同周期', render: (_, record) => `${record.contractBeginDate}-${record.contractEndDate}`},
              ...((activeKey === '1'
                ? [
                    {title: '管家总监审批', render: (_, record) => getStatus(record.signSteward, record.auditSteward)},
                    {title: '保洁主管审批', render: (_, record) => getStatus(record.signClean, record.auditClean)},
                    {
                      title: '签约审批状态',
                      render: (_, record) => saleCustomerSign[record.signStatus],
                    },
                  ]
                : [{title: '确认状态', render: (_, record) => saleCustomerSign[record.signStatus]}]) as ColumnsType<
                typeof dataSource[number]
              >),
              {
                title: '操作',
                render: (_, record: SaleCustomerSignVO) => {
                  return (
                    <Button
                      style={{padding: 0}}
                      type={'link'}
                      onClick={() => {
                        history.push({
                          pathname: '/sale-client/contract_input',
                          state: {
                            signId: record.signId,
                            // audit: {2: 'sign', 6: 'cancel'}[record.signStatus],
                            audit:
                              record.signStatus === '2' ? 'sign' : record.signStatus === '6' ? 'cancel' : undefined,
                          },
                        })
                      }}
                    >
                      查看
                    </Button>
                  )
                },
              },
            ]}
            pagination={getPaginationProps(form, total)}
          />
        </div>
      </Form>
    </Page>
  )
}
