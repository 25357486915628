/**
 * 第三级服务:退租保洁
 */
export const STEWARD_SERVICE_THI_1 = '80fc193dc36f40049e969238f04d4142'
/**
 * 第三级服务:公区保洁
 */
export const STEWARD_SERVICE_THI_2 = '6c1d632ef0d145489754fcd476b800b7'
/**
 * 第三级服务:入驻保洁
 */
export const STEWARD_SERVICE_THI_3 = 'dccb8ec3e9ce4b6d8c1b93eaf684b60a'
/**
 * 第三级服务:开荒保洁
 */
export const STEWARD_SERVICE_THI_4 = 'd9b33bc9c28f455495a3b0a85a8b2bb9'
/**
 * 第三级服务:深度保洁
 */
export const STEWARD_SERVICE_THI_5 = 'ea48d913e54e465692e524be20b49a05'
/**
 * 第三级服务:周期保洁
 */
export const STEWARD_SERVICE_THI_6 = '892e01f3ff914d718e1f21a0ff246fc3'

export const cleanServiceArr = [
  {id: STEWARD_SERVICE_THI_1, label: '退租保洁'},
  {id: STEWARD_SERVICE_THI_2, label: '公区保洁'},
  {id: STEWARD_SERVICE_THI_3, label: '入住保洁'},
  {id: STEWARD_SERVICE_THI_4, label: '开荒保洁'},
  {id: STEWARD_SERVICE_THI_5, label: '深度保洁'},
  {id: STEWARD_SERVICE_THI_6, label: '周期保洁'},
]

export const cleanServiceCode = {
  [STEWARD_SERVICE_THI_1]: '退租保洁',
  [STEWARD_SERVICE_THI_2]: '公区保洁',
  [STEWARD_SERVICE_THI_3]: '入住保洁',
  [STEWARD_SERVICE_THI_4]: '开荒保洁',
  [STEWARD_SERVICE_THI_5]: '深度保洁',
  [STEWARD_SERVICE_THI_6]: '周期保洁',
}
