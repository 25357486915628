import React from 'react'
import {useAppHistory, useAppLocation} from '@/common/hooks/useRouter'
import {omsModel} from '@/store/models/Oms'
import * as listener from '@/common/hooks/listener'
import {PermissionConfig} from '@/pages/company/components/PermissionConfig'

export default function CompanyPermission() {
  const history = useAppHistory()
  const location = useAppLocation<'/company-permission'>()
  const {type, contractId, permissionIdList} = location.state ?? {}

  return (
    <>
      <PermissionConfig
        permissionIdList={permissionIdList}
        confirm={async permissionIdList => {
          if (contractId) {
            await omsModel.omsCompanyContractUpdatePermission({contractId, permissionIdList})
          } else {
            if (type === 'insert-company')
              listener.emit('CompanyInsert: contractModulePermissionList', permissionIdList)
            if (type === 'insert-contract')
              listener.emit('ContractInsert: contractModulePermissionList', permissionIdList)
          }
          history.goBack()
        }}
      />
    </>
  )
}
