import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {
  Form,
  Table,
  Button,
  Drawer,
  Tabs,
  Typography,
  Row,
  Col,
  Skeleton,
  Space,
  Modal,
  DatePicker,
  Select,
  Input,
} from 'antd'
import {propertyModel} from '@/store/models/Property'
import '../index.less'
import {useCodeSync} from '@/store/models/Common'
import DividerLine from '../component/DividerLine'
import {getImg} from '@/utils/util'
import moment from 'moment'
import commonStyles from '@/assets/styles/common.module.less'
import {Model} from '@redux-model/web'
import empty from '@/assets/icons/empty.svg'
import {hasPermission} from '@/components/Permission'
import {useAppHistory, useAppLocation} from '@/common/hooks/useRouter'
import {useFocusEffect} from '@/common/hooks/useFocusEffect'
import {getPaginationProps} from '@/common/biz/getPaginationProps'
import {Page} from '@/components/Page'
import {FORMAT_DATE_END, FORMAT_DATE_START} from '@/common/date'

export default function ContractList() {
  const history = useAppHistory()
  const location = useAppLocation<'/contract'>()

  const [visible, setVisible] = useState(false)
  //租约详情
  const [contractDetail, setContractDetail] = useState({} as ContractVO)
  //退房结算详情
  const [checkoutDetail, setCheckoutDetail] = useState({} as ContractCheckOutVO)
  //入住人列表
  const [personList, setPersonList] = useState([] as ContractPersonCheckinVO[])
  //物业交割信息
  const [equipmentInfo, setEquipmentInfo] = useState({} as ContractEquipmentVO)
  //房间物品
  const equipmentList = useMemo(() => equipmentInfo?.equipmentList || [], [equipmentInfo])
  //公区物品
  const equipmentPublicList = useMemo(() => equipmentInfo?.equipmentPublicList || [], [equipmentInfo])
  //水电煤读数
  const meterList = useMemo(() => equipmentInfo?.meterList || [], [equipmentInfo])
  //查看图片
  const [img, setImg] = useState('')
  //扣除费用列表
  const cutList = useMemo(() => checkoutDetail?.checkoutDetailList?.filter(item => item.detailType === '1') || [], [
    checkoutDetail,
  ])
  //退款费用列表
  const refundList = useMemo(() => checkoutDetail?.checkoutDetailList?.filter(item => item.detailType === '2') || [], [
    checkoutDetail,
  ])

  //总押金
  const rentPledgeTotal = useMemo(
    () => contractDetail.contractDetailList?.map(item => item.rentPledge)?.reduce((prev, sum) => prev + sum, 0),
    [contractDetail]
  )
  // 合约状态
  const contractStatusCode = useCodeSync('contract.status')
  // 合同类型
  const contractTypeCode = useCodeSync('contractType')
  // 证件类型
  const contractCertType = useCodeSync('contract.certType')
  //收租日类型
  const rentCollectTypeCode = useCodeSync('rentCollectType')
  //性别
  const gender = useCodeSync('gender')
  //退房原因
  const checkOutReasonCode = useCodeSync('contract.checkOutReason')
  // 列表
  const [dataList, setDataList] = useState<ContractAdminListQueryResultVO[]>([])
  // 数据条数
  const [total, setTotal] = useState(0)

  //tabKey
  const [tabKey, setTabKey] = useState('1')
  const loading = propertyModel.queryContractList.useLoading()
  const detailLoading = Model.isLoading(
    propertyModel.queryContractDetail.useLoading(),
    propertyModel.contractCheckinPersonList.useLoading(),
    propertyModel.contractEquipmentInfo.useLoading()
  )

  const [form] = Form.useForm<ContractAdminListQueryDTO>()

  const search = useCallback(() => {
    const {_time, ...formValues} = form.getFieldsValue(true)
    const [insertTimeBegin, insertTimeEnd] = _time ?? []
    propertyModel
      .queryContractList({...formValues, insertTimeBegin, insertTimeEnd})
      .then(({response: {data, total}}) => {
        const list = data.map(item => {
          return {
            ...item,
            contractType: contractTypeCode[item.contractType],
            contractStatus: contractStatusCode[item.contractStatus],
          }
        })
        setDataList(list)

        setTotal(total)
      })
  }, [contractStatusCode, contractTypeCode, form])

  const propertyCode = location.state?.propertyCode
  const lessor = location.state?.lessor
  const lessee = location.state?.lessee
  useEffect(() => {
    if (propertyCode) {
      form.setFieldsValue({propertyCode})
      location.state.propertyCode = undefined
    }
    if (lessor) {
      form.setFieldsValue({lessor})
      location.state.lessor = undefined
    }
    if (lessee) {
      form.setFieldsValue({lessee})
      location.state.lessee = undefined
    }
  }, [form, lessee, lessor, location.state, propertyCode])

  useFocusEffect(() => {
    form.submit()
  }, 'PUSH|POP')

  useEffect(() => {
    form.submit()
  }, [form])

  //查看租约详情和入住人列表
  function queryDetail(id: string) {
    //租约详情
    propertyModel.queryContractDetail({contractId: id}).then(({response: {data}}) => {
      setContractDetail(data)
      setCheckoutDetail(data.checkOutDetail)
    })

    //入住人列表
    propertyModel.contractCheckinPersonList(id).then(({response: {data}}) => {
      setPersonList(data)
    })

    //物业交割信息
    propertyModel.contractEquipmentInfo(id).then(({response: {data}}) => {
      setEquipmentInfo(data)
    })
  }

  const payType = (rentPayType, rentPledgeType) => {
    return [rentPayType && `付${rentPayType}`, rentPledgeType ? `押${rentPledgeType}` : '押自定义']
      .filter(item => item)
      .join('')
  }

  return (
    <Page>
      <div className={commonStyles.filterSection}>
        <Form
          form={form}
          initialValues={{
            pageNum: 1,
            pageSize: 10,
            pageable: true,
            _time: [moment().subtract(31, 'day').format(FORMAT_DATE_START), moment().format(FORMAT_DATE_END)],
          }}
          onReset={search}
          onFinish={search}
        >
          {/**名称*/}
          <Row>
            <Col span={7}>
              <Form.Item name='lessor' label={'房东名称'}>
                <Input placeholder={'房东姓名/房东手机'} allowClear />
              </Form.Item>
            </Col>
            <Col span={7} offset={1}>
              <Form.Item name='lessee' label={'租客名称'}>
                <Input placeholder={'租客姓名/租客手机'} allowClear />
              </Form.Item>
            </Col>
            <Col span={7} offset={1}>
              <Form.Item name='propertyCode' label={'房源code'}>
                <Input placeholder={'房源code'} allowClear />
              </Form.Item>
            </Col>
          </Row>

          {/**添加时间*/}
          <Row>
            <Col span={7}>
              {/*todo: 有问题！时间应该是有时分秒的，此处只显示年月日*/}
              <Form.Item
                name='_time'
                label={'签约时间'}
                getValueProps={value => ({value: value ? value.map(item => moment(item)) : []})}
                getValueFromEvent={(_, dateStrings) => {
                  const [insertTimeBegin, insertTimeEnd] = dateStrings
                  if (insertTimeBegin && insertTimeEnd) {
                    return [
                      moment(insertTimeBegin).format(FORMAT_DATE_START),
                      moment(insertTimeEnd).format(FORMAT_DATE_END),
                    ]
                  }
                  return undefined
                }}
                rules={[
                  {
                    validator: (rule, value, callback) => {
                      if (value) {
                        const [insertTimeBegin, insertTimeEnd] = value
                        const intervalDay = moment(insertTimeEnd).diff(insertTimeBegin, 'day')
                        if (intervalDay > 31) return callback('签约时间间隔不超过31天')
                      } else {
                        return callback('签约时间不能为空')
                      }
                      return callback()
                    },
                  },
                ]}
              >
                <DatePicker.RangePicker />
              </Form.Item>
            </Col>
            <Col span={7} offset={1}>
              <Form.Item name='title' label={'房源地址'}>
                <Input placeholder={'小区/公寓'} allowClear />
              </Form.Item>
            </Col>
            <Col span={7} offset={1}>
              <Form.Item name='rentType' label={'房源类别'}>
                <Select placeholder='全部/整租/合租/公寓房' allowClear>
                  <Select.Option value=''>全部</Select.Option>
                  <Select.Option value='1'>整租</Select.Option>
                  <Select.Option value='2'>合租</Select.Option>
                  <Select.Option value='3'>公寓房</Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={7}>
              <Form.Item name={'contractStatus'} label={'租约状态'}>
                <Select placeholder={'租约状态'} allowClear>
                  {[
                    {key: '', value: '全部'},
                    {key: '1', value: '待签约'},
                    {key: '2', value: '已签约'},
                    {key: '3', value: '已到期'},
                    {key: '4', value: '已退房'},
                    {key: '5', value: '已作废'},
                  ].map(item => (
                    <Select.Option key={item.key} value={item.key}>
                      {item.value}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={7} offset={1}>
              <Form.Item name={'contractType'} label={'合同类型'}>
                <Select placeholder={'全部/纸质/电子'} allowClear>
                  {[
                    {key: '', value: '全部'},
                    {key: 'paper', value: '纸质'},
                    {key: 'elec', value: '电子'},
                  ].map(item => (
                    <Select.Option key={item.key} value={item.key}>
                      {item.value}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row justify={'end'}>
            <Space>
              <Button type={'primary'} htmlType={'submit'} onClick={() => form.setFieldsValue({pageNum: 1})}>
                查询
              </Button>
              <Button htmlType={'reset'}>重置</Button>
            </Space>
          </Row>
        </Form>
      </div>

      <div className={commonStyles.tableSection}>
        <Table
          rowKey={'contractId'}
          dataSource={dataList}
          columns={[
            {title: 'ID', dataIndex: 'contractId', key: 'contractId'},
            {title: '房东名称', dataIndex: 'certName', key: 'certName'},
            {title: '合同类型', dataIndex: 'contractType', key: 'contractType'},
            {title: '签约时间', dataIndex: 'insertTime', key: 'insertTime'},
            {title: '对应房源', dataIndex: 'propertyTitle', key: 'propertyTitle'},
            {title: '租客手机', dataIndex: 'lesseeMobile', key: 'lesseeMobile'},
            {title: '租客名称', dataIndex: 'lesseeCertName', key: 'lesseeCertName'},
            {title: '状态', dataIndex: 'contractStatus', key: 'contractStatus'},
            {
              title: '操作',
              dataIndex: 'contractId',
              key: 'contractId',
              width: '100px',
              fixed: 'right',
              render: (text, record, index) =>
                hasPermission('001015002') && (
                  <Button
                    type={'link'}
                    size={'small'}
                    onClick={() => {
                      setVisible(true)
                      setTabKey('1')
                      queryDetail(record.contractId)
                    }}
                    style={{padding: 0}}
                  >
                    查看
                  </Button>
                ),
            },
          ]}
          scroll={{x: 'max-content'}}
          loading={loading}
          pagination={getPaginationProps(form, total)}
        />
      </div>
      <Drawer
        open={visible}
        width={700}
        onClose={() => {
          setVisible(false)
        }}
      >
        <Typography.Text style={{fontSize: '16px', color: ' #000000', fontWeight: 'bold'}}>租约详情</Typography.Text>
        {!detailLoading ? (
          <Tabs
            defaultActiveKey={tabKey}
            tabBarExtraContent={
              <Button
                type={'primary'}
                onClick={() => {
                  setVisible(false)
                  history.push({
                    pathname: '/bill',
                    state: {propertyCode: contractDetail.propertyCode},
                  })
                }}
              >
                查看账单信息
              </Button>
            }
          >
            <Tabs.TabPane key={'1'} tab={'租约信息'}>
              <DividerLine title={'基本信息'} />
              <div style={{margin: '5px 0 10px'}}>
                <Row gutter={[0, 8]}>
                  <Col span={14}>
                    <Typography.Text>租约编号：</Typography.Text>
                    <Typography.Text style={{color: '#000000'}}>{contractDetail.contractId}</Typography.Text>
                  </Col>
                  <Col span={10}>
                    <Typography.Text>承租人姓名：</Typography.Text>
                    <Typography.Text style={{color: '#000000'}}>{contractDetail.lesseeCertName}</Typography.Text>
                  </Col>
                </Row>
                <Row gutter={[0, 8]}>
                  <Col span={14}>
                    <Typography.Text>租约状态：</Typography.Text>
                    <Typography.Text style={{color: '#000000'}}>
                      {contractStatusCode[contractDetail.contractStatus]}
                    </Typography.Text>
                  </Col>
                  <Col span={10}>
                    <Typography.Text>承租人手机号：</Typography.Text>
                    <Typography.Text style={{color: '#000000'}}>{contractDetail.lesseeMobile}</Typography.Text>
                  </Col>
                </Row>
                <Row gutter={[0, 8]}>
                  <Col span={14}>
                    <Typography.Text>签约单类型：</Typography.Text>
                    <Typography.Text style={{color: '#000000'}}>
                      {contractTypeCode[contractDetail.contractType]}
                    </Typography.Text>
                  </Col>
                  <Col span={10}>
                    <Typography.Text>证件类型：</Typography.Text>
                    <Typography.Text style={{color: '#000000'}}>
                      {contractCertType[contractDetail.lesseeCertType]}
                    </Typography.Text>
                  </Col>
                </Row>
                <Row gutter={[0, 8]}>
                  <Col span={14}>
                    <Typography.Text>房源标题：</Typography.Text>
                    <Typography.Text style={{color: '#000000'}}>{contractDetail.propertyTitle}</Typography.Text>
                  </Col>
                  <Col span={10}>
                    <Typography.Text>证件号码：</Typography.Text>
                    <Typography.Text style={{color: '#000000'}}>{contractDetail.lesseeCertNo}</Typography.Text>
                  </Col>
                </Row>
                <Row gutter={[0, 8]}>
                  <Col span={14}>
                    <Typography.Text>房东姓名：</Typography.Text>
                    <Typography.Text style={{color: '#000000'}}>{contractDetail.lessorName}</Typography.Text>
                  </Col>
                  <Col span={10}>
                    <Typography.Text>租约始末：</Typography.Text>
                    <Typography.Text
                      style={{color: '#000000'}}
                    >{`${contractDetail.contractBegin}至${contractDetail.contractEnd}`}</Typography.Text>
                  </Col>
                </Row>
                <Row gutter={[0, 8]}>
                  <Col span={14}>
                    <Typography.Text>押金总金额：</Typography.Text>
                    <Typography.Text style={{color: '#000000'}}>{`${
                      contractDetail.contractStatus === '6' ? contractDetail.rentPledge : rentPledgeTotal
                    }元`}</Typography.Text>
                  </Col>
                  <Col span={10}>
                    <Typography.Text>租期：</Typography.Text>
                    <Typography.Text style={{color: '#000000'}}>
                      {`${moment(contractDetail.contractBegin).from(contractDetail.contractEnd, true)}`}
                    </Typography.Text>
                  </Col>
                </Row>
              </div>
              {personList.length ? (
                <>
                  <DividerLine title={'入住人'} />
                  <Table
                    style={{margin: '20px 0'}}
                    rowKey={'personId'}
                    dataSource={personList}
                    columns={[
                      {
                        title: '姓名',
                        key: 'name',
                        dataIndex: 'name',
                        render: (value, record, index) => (
                          <Typography.Text>{`${value}${
                            record.personType === '1' ? '（承租人）' : '（入住人）'
                          }`}</Typography.Text>
                        ),
                        width: 150,
                      },
                      {
                        title: '性别',
                        key: 'gender',
                        dataIndex: 'gender',
                        width: 150,
                        render: value => <Typography.Text>{gender[value]}</Typography.Text>,
                      },
                      {title: '手机号', key: 'mobile', dataIndex: 'mobile', width: 150},
                      {title: '入住时间', key: 'checkinTime', dataIndex: 'checkinTime'},
                    ]}
                    pagination={false}
                  />
                </>
              ) : null}
              {contractDetail.contractDetailList?.length ? (
                <div>
                  <DividerLine title={'签约信息'} />
                  {contractDetail.contractDetailList?.map((item, index) => (
                    <div key={item.contractId + index}>
                      <div style={{marginTop: 14}}>
                        <div>
                          <Typography.Text style={{fontSize: '14px', color: '#000000'}}>
                            {item.contractDetailTitle}
                          </Typography.Text>
                          <Row gutter={[0, 8]} style={{marginTop: 8}}>
                            <Col span={14}>
                              <Typography.Text>租约起始日：</Typography.Text>
                              <Typography.Text style={{color: '#000000'}}>{item.contractBegin}</Typography.Text>
                            </Col>
                            <Col span={10}>
                              <Typography.Text>租约结束日：</Typography.Text>
                              <Typography.Text style={{color: '#000000'}}>{item.contractEnd}</Typography.Text>
                            </Col>
                          </Row>
                          <Row gutter={[0, 8]}>
                            <Col span={14}>
                              <Typography.Text>房屋租金：</Typography.Text>
                              <Typography.Text style={{color: '#000000'}}>{`${item.rentPrice}元`}</Typography.Text>
                            </Col>
                            <Col span={10}>
                              <Typography.Text>
                                {index === contractDetail.contractDetailList?.length - 1 ? '房屋押金：' : '增收押金：'}
                              </Typography.Text>
                              <Typography.Text style={{color: '#000000'}}>{`${item.rentPledge}元`}</Typography.Text>
                            </Col>
                          </Row>
                          <Row gutter={[0, 8]}>
                            <Col span={14}>
                              <Typography.Text>付款方式：</Typography.Text>
                              <Typography.Text style={{color: '#000000'}}>
                                {payType(item.rentPayType, item.rentPledgeType)}
                              </Typography.Text>
                            </Col>
                            <Col span={10}>
                              <Typography.Text>收租日：</Typography.Text>
                              <Typography.Text style={{color: '#000000'}}>{`${
                                rentCollectTypeCode[item.rentCollectType]
                              }${item.rentCollectDay}天`}</Typography.Text>
                            </Col>
                          </Row>
                          <Row gutter={[0, 8]}>
                            <Col span={14}>
                              <Typography.Text>合同类型：</Typography.Text>
                              <Typography.Text style={{color: '#000000'}}>
                                {contractTypeCode[item.contractType]}
                              </Typography.Text>
                            </Col>
                            <Col span={10}>
                              <Typography.Text>原合同编号：</Typography.Text>
                              <Typography.Text style={{color: '#000000'}}>
                                {item.originContractId || '无'}
                              </Typography.Text>
                            </Col>
                          </Row>
                          <Row gutter={[0, 8]}>
                            <Col span={14}>
                              <Typography.Text>签约时间：</Typography.Text>
                              <Typography.Text style={{color: '#000000'}}>{item.signTime}</Typography.Text>
                            </Col>
                            <Col span={10}>
                              <Typography.Text>备注：</Typography.Text>
                              <Typography.Text style={{color: '#000000'}}>
                                {item.contractRemark || '无'}
                              </Typography.Text>
                            </Col>
                          </Row>
                          <Row gutter={[0, 8]}>
                            <Col>
                              <Typography.Text>合同图片：</Typography.Text>
                            </Col>
                            <Col style={{display: 'flex', flexDirection: 'row', color: '#000000'}}>
                              {item.imgList?.length
                                ? item.imgList.map(item => (
                                    <div
                                      key={item.imgId}
                                      style={{
                                        width: '88px',
                                        height: '88px',
                                        border: '1px solid rgba(0, 0, 0, 0.15)',
                                        textAlign: 'center',
                                        lineHeight: '88px',
                                        marginRight: '5px',
                                      }}
                                      onClick={() => setImg(item.path)}
                                    >
                                      <img style={{width: '74px', height: '74px'}} src={getImg(item.path)} />
                                    </div>
                                  ))
                                : '无'}
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ) : null}
            </Tabs.TabPane>

            <Tabs.TabPane key={'2'} tab={'退房结算'}>
              {contractDetail.contractStatus === '4' ? (
                <>
                  <DividerLine title={'基本信息'} />
                  <div style={{marginTop: 5}}>
                    <Row gutter={[0, 8]}>
                      <Col span={14}>
                        <Typography.Text>扣除租客金额：</Typography.Text>
                        <Typography.Text style={{color: '#000000'}}>{`${
                          checkoutDetail?.cutAmt + checkoutDetail?.debtAmt
                        }元`}</Typography.Text>
                      </Col>
                      <Col span={10}>
                        <Typography.Text>实际退房时间：</Typography.Text>
                        <Typography.Text style={{color: '#000000'}}>{checkoutDetail?.billEndDate}</Typography.Text>
                      </Col>
                    </Row>
                    <Row gutter={[0, 8]}>
                      <Col span={14}>
                        <Typography.Text>应退租客金额：</Typography.Text>
                        <Typography.Text style={{color: '#000000'}}>{`${checkoutDetail?.refundAmt}元`}</Typography.Text>
                      </Col>
                      <Col span={10}>
                        <Typography.Text>退房原因：</Typography.Text>
                        <Typography.Text style={{color: '#000000'}}>
                          {checkOutReasonCode[contractDetail?.checkOutReason]}
                        </Typography.Text>
                      </Col>
                    </Row>
                    <Row gutter={[0, 8]}>
                      <Col span={14}>
                        <Typography.Text>总计：</Typography.Text>
                        <Typography.Text style={{color: '#000000'}}>
                          {checkoutDetail?.totalAmt
                            ? `${Math.abs(checkoutDetail?.totalAmt)}元${
                                checkoutDetail?.totalAmt < 0
                                  ? `（向租客收取${Math.abs(checkoutDetail?.totalAmt)}元）`
                                  : `（退还给租客${checkoutDetail?.totalAmt}元）`
                              }`
                            : `${checkoutDetail?.totalAmt}元`}
                        </Typography.Text>
                      </Col>
                      <Col span={10}>
                        <Typography.Text>坏账总计：</Typography.Text>
                        <Typography.Text style={{color: '#000000'}}>{`${checkoutDetail?.badAmt}元`}</Typography.Text>
                      </Col>
                    </Row>
                    <Row gutter={[0, 8]}>
                      <Col span={14}>
                        <Typography.Text>退款途径：</Typography.Text>
                        <Typography.Text style={{color: '#000000'}}>
                          {checkoutDetail?.rfdPayWayName || '无'}
                        </Typography.Text>
                      </Col>
                      <Col span={10}>
                        <Typography.Text>退房备注：</Typography.Text>
                        <Typography.Text style={{color: '#000000'}}>
                          {contractDetail.checkOutRemark || '无'}
                        </Typography.Text>
                      </Col>
                    </Row>
                    <Row gutter={[0, 8]}>
                      <Col span={14}>
                        <Typography.Text>退款备注：</Typography.Text>
                        <Typography.Text style={{color: '#000000'}}>
                          {checkoutDetail?.rfdRemark || '无'}
                        </Typography.Text>
                      </Col>
                    </Row>
                    {cutList.length ? (
                      <Table
                        style={{marginTop: 20}}
                        dataSource={cutList}
                        rowKey={'billId'}
                        columns={[
                          {title: '扣除费用类型', key: 'billTypeName', dataIndex: 'billTypeName'},
                          {
                            title: '费用周期',
                            key: 'billBegin',
                            dataIndex: 'billBegin',
                            render: (text, record, index) => (
                              <Typography.Text>{`${record.billBegin}至${record.billEnd}`}</Typography.Text>
                            ),
                          },
                          {
                            title: '金额（元）',
                            key: 'checkoutAmt',
                            dataIndex: 'checkoutAmt',
                            render: (value, record, index) => (
                              <Typography.Text style={{textDecoration: record.billFee === '1' ? 'line-through' : ''}}>
                                {value}
                              </Typography.Text>
                            ),
                          },
                        ]}
                        pagination={false}
                      />
                    ) : null}
                    {refundList.length ? (
                      <Table
                        style={{marginTop: 20}}
                        rowKey={'billId'}
                        dataSource={refundList}
                        columns={[
                          {title: '退款费用类型', key: 'billTypeName', dataIndex: 'billTypeName'},
                          {
                            title: '费用周期',
                            key: 'billBegin',
                            dataIndex: 'billBegin',
                            render: (text, record, index) => (
                              <Typography.Text>{`${record.billBegin}至${record.billEnd}`}</Typography.Text>
                            ),
                          },
                          {title: '金额（元）', key: 'checkoutAmt', dataIndex: 'checkoutAmt'},
                        ]}
                        pagination={false}
                      />
                    ) : null}
                  </div>
                </>
              ) : (
                <div>
                  <img
                    src={empty}
                    alt={''}
                    width={66}
                    height={66}
                    style={{position: 'absolute', marginTop: '200px', marginLeft: '-44px', left: '50%'}}
                  />
                </div>
              )}
            </Tabs.TabPane>

            <Tabs.TabPane key={'3'} tab={'物业交割'}>
              <DividerLine title={'房间物品'} />
              {equipmentList.length ? (
                <Table
                  style={{marginTop: '20px'}}
                  rowKey={'code'}
                  dataSource={equipmentList}
                  columns={[
                    {title: '物品', key: 'name', dataIndex: 'name'},
                    {title: '数量', key: 'amt', dataIndex: 'amt'},
                    {title: '单价', key: 'price', dataIndex: 'price'},
                    {title: '品牌', key: 'model', dataIndex: 'model'},
                    {
                      title: '附件',
                      key: 'attach',
                      dataIndex: 'attach',
                      align: 'center',
                      render: value =>
                        value ? (
                          <Button
                            type={'link'}
                            onClick={() => {
                              setImg(value)
                            }}
                          >
                            查看
                          </Button>
                        ) : (
                          '/'
                        ),
                    },
                    {title: '备注', key: 'remark', dataIndex: 'remark'},
                  ]}
                  pagination={false}
                />
              ) : null}
              <DividerLine title={'公区物品'} style={{marginTop: '20px'}} />
              {equipmentPublicList.length ? (
                <Table
                  rowKey={'code'}
                  style={{marginTop: '20px'}}
                  dataSource={equipmentPublicList}
                  columns={[
                    {title: '物品', key: 'name', dataIndex: 'name'},
                    {title: '数量', key: 'amt', dataIndex: 'amt'},
                    {
                      title: '单价',
                      key: 'price',
                      dataIndex: 'price',
                      render: value => <Typography.Text>{value ? value : '/'}</Typography.Text>,
                    },
                    {
                      title: '品牌',
                      key: 'model',
                      dataIndex: 'model',
                      render: value => <Typography.Text>{value ? value : '/'}</Typography.Text>,
                    },
                    {
                      title: '附件',
                      key: 'attach',
                      dataIndex: 'attach',
                      align: 'center',
                      render: value =>
                        value ? (
                          <Button
                            type={'link'}
                            onClick={() => {
                              setImg(value)
                            }}
                          >
                            查看
                          </Button>
                        ) : (
                          '/'
                        ),
                    },
                    {
                      title: '备注',
                      key: 'remark',
                      dataIndex: 'remark',
                      render: value => <Typography.Text>{value ? value : '/'}</Typography.Text>,
                    },
                  ]}
                  pagination={false}
                />
              ) : null}
              <DividerLine title={'水电煤读数'} style={{marginTop: '20px'}} />
              {meterList.length ? (
                <Table
                  style={{marginTop: '20px'}}
                  rowKey={'code'}
                  dataSource={meterList}
                  columns={[
                    {title: '仪表', key: 'name', dataIndex: 'name'},
                    {title: '位置', key: 'locationName', dataIndex: 'locationName'},
                    {
                      title: '底数',
                      key: 'value',
                      dataIndex: 'value',
                      render: value => <Typography.Text>{value ? value : '/'}</Typography.Text>,
                    },
                    {
                      title: '支付方式',
                      key: 'payWay',
                      dataIndex: 'payWay',
                      render: value => (
                        <Typography.Text>{value ? (value === '1' ? '后付费' : '预付费') : '无'}</Typography.Text>
                      ),
                    },
                    {
                      title: '附件',
                      key: 'attach',
                      dataIndex: 'attach',
                      render: value =>
                        value ? (
                          <Button
                            type={'link'}
                            onClick={() => {
                              setImg(value)
                            }}
                          >
                            查看
                          </Button>
                        ) : (
                          '/'
                        ),
                      align: 'center',
                    },
                    {
                      title: '备注',
                      key: 'remark',
                      dataIndex: 'remark',
                      render: value => <Typography.Text>{value ? value : '/'}</Typography.Text>,
                    },
                  ]}
                  pagination={false}
                />
              ) : null}
            </Tabs.TabPane>
          </Tabs>
        ) : (
          <Skeleton active />
        )}
      </Drawer>

      {/* 查看图片 */}
      <Modal open={!!img} footer={null} onCancel={() => setImg('')}>
        {!!img && <img src={getImg(img)} alt='' width={'100%'} height={'100%'} />}
      </Modal>
    </Page>
  )
}
