import {Model} from '@redux-model/web'
import {$api} from '@/store/service'

interface PropertyModel {}

class Property extends Model<PropertyModel> {
  initReducer() {
    return {}
  }

  // 查询房源列表
  queryPropertyList = $api.action((params: Partial<PropertyAdminListQueryDTO>) =>
    this.post<ApiResponse<PropertyInfoVO[]>>('/property/list').body({
      pageable: true,
      ...params,
    })
  )

  // 查询房源详情
  queryPropertyDetail = $api.action((params: {propertyCode: string}) =>
    this.post<ApiResponse<PropertyAdminInfoVO>>('/property/detail').body(params)
  )

  // 查询房源列表(根据房东id查询被分配的房源)  |  object:{lessorId:房东id}
  propertyListWithSteward = $api.action((args: Partial<PropertyAdminListWithStewardDTO>) =>
    this.post<ApiResponse<PropertyAdminListWithStewardVO[]>>('/property/list-with-steward').body({
      pageNum: 1,
      pageSize: 10,
      pageable: true,
      ...args,
    })
  )

  // 查询租约列表
  queryContractList = $api.action((params: Partial<ContractAdminListQueryDTO>) =>
    this.post<ApiResponse<ContractAdminListQueryResultVO[]>>('/contract/list').body({
      pageable: true,
      ...params,
    })
  )

  // 查询租约详情
  queryContractDetail = $api.action((params: {contractId: string}) =>
    this.post<ApiResponse<ContractVO>>('/contract/detail').body(params)
  )

  // 查询账单列表
  queryBillList = $api.action((params: Partial<ContractBillAdminListQueryDTO>) =>
    this.post<ApiResponse<ContractBillAdminListQueryResultVO[]>>('/bill/list').body({
      pageable: true,
      ...params,
    })
  )

  // 查询账单详情
  queryBillDetail = $api.action((params: {billId: string}) =>
    this.post<ApiResponse<ContractBillVO>>('/bill/detail').body(params)
  )

  //查询房源详情(额外补充信息)
  propertyDetailExtra = $api.action((propertyCode: string) =>
    this.post<ApiResponse<PropertyExtraVO>>('/property/detail-extra').body({propertyCode})
  )

  // 入住人列表  |  object:{contractId:租约id}
  contractCheckinPersonList = $api.action((contractId: string) =>
    this.post<ApiResponse<ContractPersonCheckinVO[]>>('/contract/checkin-person-list').body({contractId})
  )

  //租约-租客登记-查看租约物业交割
  contractEquipmentInfo = $api.action((contractId: string) =>
    this.post<ApiResponse<ContractEquipmentVO>>('/contract/equipment/info').body({contractId})
  )

  // 查询小区地图  |  object:{lng1:经度1,lat1:纬度1,lng2:经度2,lat2:纬度2}
  propertyCommunityMap = $api.action((args: {lng1: number; lat1: number; lng2: number; lat2: number}) =>
    this.post<ApiResponse<PropertyMapCommunityVO[]>>('/property/community-map').body(args)
  )

  // 查询小区地图提示  |  object:{keyword:关键字}
  propertyCommunityMapLocation = $api.action((args: {keyword: string}) =>
    this.post<ApiResponse<PropertyMapCommunityVO[]>>('/property/community-map/location').body(args)
  )

  // 查询商圈地图
  propertyBizCircleMap = $api.action(() =>
    this.post<ApiResponse<PropertyMapBizCircleVO[]>>('/property/biz-circle-map').body({})
  )

  // 更新小区地图  |  object:{communityCode:小区code, num:数量}
  // propertyCommunityMapUpdate = $api.action((args: {communityCode: string; num: number}) =>
  //   this.post<ApiResponse<PropertyCommunityMapVO>>('/property/community-map/update').body(args)
  // )

  // 根据条件汇聚小区  |  object:{lessorId:房东id}
  propertyCommunityAggregate = $api.action((args: {lessorId: string}) =>
    this.post<ApiResponse<CommunityVO[]>>('/property/community-aggregate').body(args)
  )

  // 根据条件汇聚小区(查询客户录入过的小区)  |  object:{lessorId:房东id, communityType:小区类别 1：分散式房源，2：集中式公寓}
  propertyCommunityAggregateWithCount = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<CommunityVO[]>>('/property/community-aggregate-with-count').body(args)
  )

  // 转移团队
  propertyChangeTeam = $api.action((args: PropertyAdminMoveTeamDTO) =>
    this.post<ApiResponse<number>>('/property/change-team').body(args)
  )

  // 新增公寓分步校验
  propertyCondoInsertStepCheck = $api.action((args: CondoPropertyInsertDTO) =>
    this.post<ApiResponse<Record<string, any>>>('/property/condo/insert/step-check').body(args)
  )

  // 新增公寓
  propertyCondoInsert = $api.action((args: CondoPropertyInsertDTO) =>
    this.post<ApiResponse<Record<string, any>>>('/property/condo/insert').body(args)
  )

  // 新增房源(整租/合租)
  propertyScatterInsert = $api.action((args: PropertyInfoDTO) =>
    this.post<ApiResponse<string[]>>('/property/scatter/insert').body(args)
  )
}

export const propertyModel = new Property()
