import React, {useState} from 'react'
import {Page} from '@/components/Page'
import {Button, Form, message, Space, Table, Tag} from 'antd'
import commonStyles from '@/assets/styles/common.module.less'
import {getPaginationProps} from '@/common/biz/getPaginationProps'
import {Breadcrumb} from '@/components/Breadcrumb'
import CleanFilterComponent from '@/pages/cleanManagement/components/CleanFilterComponent'
import {cleanServiceArr} from '@/utils/clean'
import {useCodeArraySync} from '@/store/models/Common'
import {useCleanOrderAssign} from '@/pages/cleanManagement/hooks/useCleanOrderAssign'
import {useCleanOrderList} from '@/pages/cleanManagement/hooks/useCleanOrderList'
import {useAppHistory} from '@/common/hooks/useRouter'

export default function CleanServiceWillDispatch() {
  const history = useAppHistory()

  const cleanOrderTypeArr = useCodeArraySync('clean.order.type')

  const [form] = Form.useForm()
  const {dataList, dataTotal, queryCleanOrderList, orderListLoading} = useCleanOrderList(form)

  const cleanOrderAssign = useCleanOrderAssign()

  const [orderIds, setOrderIds] = useState<string[]>([])

  return (
    <Page
      breadcrumb={
        <Breadcrumb
          right={
            <Button
              type={'primary'}
              onClick={() => {
                if (orderIds.length === 0) return message.warning('请选择服务单！')
                cleanOrderAssign({
                  orderIdList: orderIds,
                  onSuccess: () => {
                    queryCleanOrderList()
                    setOrderIds([])
                  },
                })
              }}
            >
              批量派单
            </Button>
          }
        />
      }
    >
      <div className={commonStyles.filterSection}>
        <CleanFilterComponent
          form={{
            form,
            labelCol: {span: '80px'},
            labelAlign: 'left',
            initialValues: {pageNum: 1, pageSize: 10, pageable: true, orderStatus: '2', sortType: 2},
            onFinish: queryCleanOrderList,
            onReset: queryCleanOrderList,
          }}
          filter={[
            {type: 'input', name: 'orderId', label: '服务单ID', placeholder: '请输入'},
            {type: 'input', name: 'propertyAddr', label: '房源', placeholder: '请输入'},
            {type: 'select', name: 'categoryThi', label: '服务项目', placeholder: '请选择', options: cleanServiceArr},
            {type: 'input', name: 'orderMakerName', label: '下单人', placeholder: '请输入'},
            {type: 'input', name: 'contactMobile', label: '服务联系手机号', placeholder: '请输入'},
            {
              type: 'range',
              name: 'insertTime',
              label: '下单时间',
              onChange: (_, dateString) => {
                form.setFieldsValue({
                  insertTimeBegin: dateString[0] ? dateString[0] + ' 00:00:00' : '',
                  insertTimeEnd: dateString[1] ? dateString[1] + ' 23:59:59' : '',
                })
              },
            },
            {
              type: 'range',
              name: 'checkTime',
              label: '确认时间',
              onChange: (_, dateString) => {
                form.setFieldsValue({
                  checkTimeBegin: dateString[0] ? dateString[0] + ' 00:00:00' : '',
                  checkTimeEnd: dateString[1] ? dateString[1] + ' 23:59:59' : '',
                })
              },
            },
            {type: 'input', name: 'checkOperator', label: '确认人', placeholder: '请输入'},
            {type: 'select', name: 'orderType', label: '工单属性', placeholder: '请选择', options: cleanOrderTypeArr},
            {type: 'input', name: 'customerName', label: '房东', placeholder: '请输入'},
          ]}
        />
      </div>

      <div className={commonStyles.tableSection}>
        <Table
          rowKey={'orderId'}
          loading={orderListLoading}
          dataSource={dataList}
          rowSelection={{
            fixed: true,
            selectedRowKeys: orderIds,
            onChange: selectedRowKeys => setOrderIds(selectedRowKeys as string[]),
          }}
          columns={[
            {title: '服务单ID', dataIndex: 'orderId'},
            {
              title: '服务项目',
              dataIndex: 'categoryFirName',
              render: (value, record) => {
                return (
                  <Space>
                    <span>
                      {[value, record.categorySecName, record.categoryThiName].filter(item => item).join('-')}
                    </span>
                    {!!record.orderIdOrigin && <Tag color={'blue'}>返</Tag>}
                  </Space>
                )
              },
            },
            {title: '下单人', dataIndex: 'orderMakerName'},
            {title: '服务联系手机号', dataIndex: 'contactMobile'},
            {
              title: '下单时间',
              dataIndex: 'insertTime',
              sorter: {
                compare: (a, b) => new Date(a.insertTime).getTime() - new Date(b.insertTime).getTime(),
                multiple: 1,
              },
            },
            {
              title: '确认时间',
              dataIndex: 'checkTime',
              sorter: {
                compare: (a, b) => new Date(a.checkTime).getTime() - new Date(b.checkTime).getTime(),
                multiple: 2,
              },
            },
            {title: '确认人', dataIndex: 'checkOperator'},
            {title: '房源', dataIndex: 'propertyAddr'},
            {title: '房东', dataIndex: 'customerName'},
            {
              title: '操作',
              fixed: 'right',
              render: record => (
                <Space>
                  <Button
                    type={'link'}
                    style={{padding: 0}}
                    onClick={() => history.push('/clean-detail', {orderId: record.orderId, entry: '1'})}
                  >
                    查看
                  </Button>
                  <Button
                    type={'link'}
                    style={{padding: 0}}
                    onClick={() =>
                      cleanOrderAssign({
                        orderIdList: [record.orderId],
                        customerId: record.customerId,
                        onSuccess: () => queryCleanOrderList(),
                      })
                    }
                  >
                    派单
                  </Button>
                </Space>
              ),
            },
          ]}
          scroll={{x: 'max-content'}}
          pagination={getPaginationProps(form, dataTotal)}
        />
      </div>
    </Page>
  )
}
