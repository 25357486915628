import React, {useCallback, useEffect, useRef, useState} from 'react'
import commonStyle from '@/assets/styles/common.module.less'
import style from './index.module.less'
import {Breadcrumb} from '@/components/Breadcrumb'
import {Button, Col, Form, Input, Row, Space, Tabs, Typography, DatePicker, Select, Table} from 'antd'
import {usePermission} from '@/components/Permission'
import {downloadBlob} from '@/utils/util'
import {useAppHistory} from '@/common/hooks/useRouter'
import {customerModel} from '@/store/models/Customer'
import {teamModel} from '@/store/models/Team'
import {useFocusEffect} from '@/common/hooks/useFocusEffect'
import {Page} from '@/components/Page'
import {getPaginationProps} from '@/common/biz/getPaginationProps'
import {ClientBillComponent} from '@/pages/client/components/ClientBillComponent'

const {TabPane} = Tabs
const {Option} = Select

export default function ClientBill() {
  const history = useAppHistory()
  const [auth] = usePermission()
  const clientBillRef = useRef(null)

  const timer = useRef<any>()

  useEffect(() => {
    return () => clearTimeout(timer.current)
  }, [])

  const [active, setActive] = useState(() => {
    const permissionMap = {
      '001057001': '1',
      '001058001': '2',
      '001011001': '3',
      '001013001': '4',
    }
    const permission = Object.keys(permissionMap).find(value => auth(value))
    return permissionMap[permission]
  })

  const [teamBalanceForm] = Form.useForm<TeamBalanceListQueryDTO>()
  const [teamBalanceList, setTeamBalanceList] = useState<TeamBalanceVO[]>([])
  const [teamBalanceTotal, setTeamBalanceTotal] = useState(0)
  const teamBalanceListLoading = teamModel.teamBalanceList.useLoading()

  //客户列表
  const [clientList, setClientList] = useState<LessorCustomerListVO[]>([])

  const queryClientList = useCallback((args?: Partial<LessorCustomerListDTO>) => {
    customerModel
      .customerList({pageNum: 1, pageSize: 10, ...args} as LessorCustomerListDTO)
      .then(({response: {data}}) => {
        setClientList(data.filter(item => item.status))
      })
  }, [])

  useEffect(() => {
    queryClientList()
  }, [queryClientList])

  // 充值记录DTO
  const [rechargeForm] = Form.useForm<TeamWalletChargeQueryDTO>()
  const [rechargeList, setRechargeList] = useState([] as TeamBalanceChargeListVO[])
  const [rechargeTotal, setRechargeTotal] = useState(0)

  const queryTeamBalanceChargeList = useCallback(() => {
    teamModel.teamBalanceChargeList(rechargeForm.getFieldsValue(true)).then(({response: {data, total}}) => {
      setRechargeList(data)
      setRechargeTotal(total)
    })
  }, [rechargeForm])

  // 扣款明细DTO
  const [withholdForm] = Form.useForm<TeamWalletRecordQueryDTO>()
  const [withholdList, setWithholdList] = useState([] as TeamWalletRecordVO[])
  const [withholdTotal, setWithholdTotal] = useState(0)

  const queryTeamBalanceRecordList = useCallback(() => {
    teamModel.teamBalanceRecordList(withholdForm.getFieldsValue(true)).then(({response: {data, total}}) => {
      setWithholdList(data)
      setWithholdTotal(total)
    })
  }, [withholdForm])

  const queryTeamBalanceList = useCallback(() => {
    teamModel.teamBalanceList(teamBalanceForm.getFieldsValue(true)).then(({response: {data, total}}) => {
      setTeamBalanceList(data)
      setTeamBalanceTotal(total)
    })
  }, [teamBalanceForm])

  useFocusEffect(() => {
    switch (active) {
      // case '1':
      //   return serviceBillForm.submit()
      case '2':
        return teamBalanceForm.submit()
      case '3':
        return rechargeForm.submit()
      case '4':
        return withholdForm.submit()
    }
  })

  useEffect(() => {
    switch (active) {
      // case '1':
      //   return serviceBillForm.submit()
      case '2':
        return teamBalanceForm.submit()
      case '3':
        return rechargeForm.submit()
      case '4':
        return withholdForm.submit()
    }
  }, [active, rechargeForm, teamBalanceForm, withholdForm])

  return (
    <Page
      breadcrumb={
        <Breadcrumb
          right={
            <>
              {active === '1' && (
                <Space>
                  {auth('004003002') && (
                    <Button
                      type='primary'
                      onClick={() => {
                        clientBillRef.current?.batchDelete?.()
                      }}
                    >
                      批量删除
                    </Button>
                  )}

                  {auth('008003003') && (
                    <Button
                      type='primary'
                      onClick={() => {
                        clientBillRef.current?.billConfirmAll?.()
                      }}
                    >
                      确认收款
                    </Button>
                  )}
                  {auth('007003001') && (
                    <Button
                      onClick={() => {
                        clientBillRef.current?.export?.()
                      }}
                    >
                      导出
                    </Button>
                  )}
                  {auth('028003002') && (
                    <Button
                      type='primary'
                      onClick={() => {
                        clientBillRef.current?.invoice?.()
                      }}
                    >
                      批量开票申请
                    </Button>
                  )}
                </Space>
              )}
              {active === '2' && auth('007058001') && (
                <Button
                  onClick={() => {
                    downloadBlob({
                      url: '/team/balance/list-excel',
                      body: teamBalanceForm.getFieldsValue(true),
                    })
                  }}
                >
                  导出
                </Button>
              )}
            </>
          }
        />
      }
    >
      <Tabs activeKey={active} onChange={key => setActive(key)} className={style.tabs}>
        {auth('001057001') && (
          <TabPane key={'1'} tab={'服务账单'}>
            <ClientBillComponent ref={clientBillRef} />
          </TabPane>
        )}
        {auth('001058001') && (
          <TabPane key={'2'} tab={'服务费账户'}>
            <div className={commonStyle.filterSection}>
              <Form
                form={teamBalanceForm}
                initialValues={{pageNum: 1, pageSize: 10}}
                onReset={queryTeamBalanceList}
                onFinish={queryTeamBalanceList}
              >
                <Row>
                  <Col span={6}>
                    <Form.Item name={'customerName'} label={'客户名称'}>
                      <Input placeholder={'请输入'} allowClear />
                    </Form.Item>
                  </Col>
                  <Col span={6} offset={1}>
                    <Form.Item name={'customerMobile'} label={'手机号码'}>
                      <Input placeholder={'请输入'} allowClear />
                    </Form.Item>
                  </Col>
                </Row>
                <Row justify={'end'}>
                  <Space>
                    <Button
                      type={'primary'}
                      htmlType={'submit'}
                      onClick={() => teamBalanceForm.setFieldsValue({pageNum: 1})}
                    >
                      查询
                    </Button>
                    <Button htmlType={'reset'}>重置</Button>
                  </Space>
                </Row>
              </Form>
            </div>
            <div className={commonStyle.tableSection}>
              <Table
                rowKey={'teamId'}
                loading={teamBalanceListLoading}
                dataSource={teamBalanceList}
                columns={[
                  {title: '客户名称', dataIndex: 'customerName', key: 'customerName'},
                  {title: '手机号码', dataIndex: 'customerMobile', key: 'customerMobile'},
                  {title: 'BD', dataIndex: 'managerName'},
                  {
                    title: '地区',
                    render: record => (
                      <Typography.Text>
                        {record.city}
                        {record.district}
                      </Typography.Text>
                    ),
                  },
                  {title: '组织', dataIndex: 'name', key: 'name'},
                  {title: '当前余额（元）', dataIndex: 'balance', key: 'balance'},
                  {
                    title: '操作',
                    render: record =>
                      auth('001009002') ? (
                        <Button
                          type={'link'}
                          onClick={() => {
                            history.push({
                              pathname: '/client/client-info',
                              state: {
                                userId: record.customerId,
                                key: '4',
                                certName: record.customerName,
                                teamId: record.teamId,
                              },
                            })
                          }}
                        >
                          查看详情
                        </Button>
                      ) : (
                        '/'
                      ),
                  },
                ]}
                pagination={getPaginationProps(teamBalanceForm, teamBalanceTotal)}
              />
            </div>
          </TabPane>
        )}
        {auth('001011001') && (
          <TabPane key={'3'} tab={'充值记录'}>
            <div className={commonStyle.filterSection}>
              <Form
                form={rechargeForm}
                initialValues={{pageNum: 1, pageSize: 10}}
                onFinish={queryTeamBalanceChargeList}
                onReset={queryTeamBalanceChargeList}
              >
                <Row>
                  <Col span={6}>
                    <Form.Item label={'客户名称'} name={'teamOwnerId'}>
                      <Select
                        showSearch
                        placeholder={'请选择'}
                        onSearch={(value: string) => {
                          if (timer.current) {
                            clearTimeout(timer.current)
                            timer.current = null
                          }

                          timer.current = setTimeout(() => {
                            if (value) {
                              queryClientList({certName: value})
                            }
                          }, 300)
                        }}
                        allowClear
                        filterOption={false}
                      >
                        {clientList.map(item => (
                          <Option key={item.userId} value={item.userId}>
                            {item.certName}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={6} offset={1}>
                    <Form.Item label={'手机号'} name={'teamOwnerMobile'}>
                      <Input placeholder='请输入手机号' allowClear />
                    </Form.Item>
                  </Col>
                  <Col offset={1}>
                    <Form.Item label={'操作时间'} name={'time'}>
                      <DatePicker.RangePicker
                        format={'YYYY-MM-DD'}
                        onChange={(_, formatString) => {
                          rechargeForm.setFieldsValue({
                            insertTimeBegin: formatString?.[0],
                            insertTimeEnd: formatString?.[1],
                          })
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row justify={'end'}>
                  <Space>
                    <Button type={'primary'} htmlType={'submit'}>
                      查询
                    </Button>
                    <Button htmlType={'reset'}>重置</Button>
                  </Space>
                </Row>
              </Form>
            </div>
            <div className={commonStyle.tableSection}>
              <Table
                rowKey={'chargeId'}
                columns={[
                  {title: '客户名称', dataIndex: 'teamOwnerName', key: 'teamOwnerName'},
                  {title: '手机号码', dataIndex: 'teamOwnerMobile', key: 'teamOwnerMobile'},
                  {title: '组织', dataIndex: 'teamName', key: 'teamName'},
                  {title: 'BD', dataIndex: 'managerName'},
                  {title: '充值（元）', dataIndex: 'chargeAmt', key: 'chargeAmt'},
                  {title: '账户余额（元）', dataIndex: 'walletBalance', key: 'walletBalance'},
                  {title: '创建人', dataIndex: 'creatorName', key: 'creatorName'},
                  {title: '创建时间', dataIndex: 'insertTime', key: 'insertTime'},
                  {
                    title: '操作',
                    dataIndex: 'chargeId',
                    key: 'chargeId',
                    render: (text, record) => {
                      return (
                        <Button
                          type={'link'}
                          onClick={() => {
                            history.push({
                              pathname: '/client/recharge/detail',
                              state: {
                                type: '1',
                                certName: record.teamOwnerName,
                                chargeId: text,
                                userId: record.teamOwnerId,
                                balance: record.walletBalance,
                                teamName: record.teamName,
                              },
                            })
                          }}
                        >
                          查看详情
                        </Button>
                      )
                    },
                  },
                ]}
                dataSource={rechargeList}
                pagination={getPaginationProps(rechargeForm, rechargeTotal)}
              />
            </div>
          </TabPane>
        )}
        {auth('001013001') && (
          <TabPane key={'4'} tab={'扣款明细'}>
            <div className={commonStyle.filterSection}>
              <Form
                form={withholdForm}
                initialValues={{pageNum: 1, pageSize: 10}}
                onFinish={queryTeamBalanceRecordList}
                onReset={queryTeamBalanceRecordList}
              >
                <Row>
                  <Col span={6}>
                    <Form.Item label={'客户名称'} name={'teamOwnerId'}>
                      <Select
                        showSearch
                        placeholder={'请选择'}
                        onSearch={(value: string) => {
                          if (timer.current) {
                            clearTimeout(timer.current)
                            timer.current = null
                          }

                          timer.current = setTimeout(() => {
                            if (value) {
                              queryClientList({certName: value})
                            }
                          }, 300)
                        }}
                        filterOption={false}
                        allowClear
                      >
                        {clientList.map(item => (
                          <Option key={item.userId} value={item.userId}>
                            {item.certName}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={6} offset={1}>
                    <Form.Item label={'手机号'} name={'teamOwnerMobile'}>
                      <Input placeholder='请输入手机号' allowClear />
                    </Form.Item>
                  </Col>
                  <Col offset={1}>
                    <Form.Item label={'操作时间'} name={'_time'}>
                      <DatePicker.RangePicker
                        format={'YYYY-MM-DD'}
                        onChange={(_, formatString) => {
                          withholdForm.setFieldsValue({
                            insertTimeBegin: formatString?.[0],
                            insertTimeEnd: formatString?.[1],
                          })
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row justify={'end'}>
                  <Space>
                    <Button
                      type={'primary'}
                      htmlType={'submit'}
                      onClick={() => withholdForm.setFieldsValue({pageNum: 1})}
                    >
                      查询
                    </Button>
                    <Button htmlType={'reset'}>重置</Button>
                  </Space>
                </Row>
              </Form>
            </div>
            <div className={commonStyle.tableSection}>
              <Table
                rowKey={'recordId'}
                columns={[
                  {title: '客户名称', dataIndex: 'teamOwnerName', key: 'teamOwnerName'},
                  {title: '手机号码', dataIndex: 'teamOwnerMobile', key: 'teamOwnerMobile'},
                  {title: '组织', dataIndex: 'teamName', key: 'teamName'},
                  {title: 'BD', dataIndex: 'managerName'},
                  {title: '扣款金额（元）', dataIndex: 'recordAmt', key: 'recordAmt'},
                  {title: '账户余额（元）', dataIndex: 'walletBalance', key: 'walletBalance'},
                  {title: '创建人', dataIndex: 'creatorName', key: 'creatorName'},
                  {title: '创建时间', dataIndex: 'insertTime', key: 'insertTime'},
                  {
                    title: '操作',
                    dataIndex: 'recordId',
                    key: 'recordId',
                    render: (recordId, record) => (
                      <Button
                        type={'link'}
                        onClick={() => {
                          history.push({
                            pathname: '/client/recharge/detail',
                            state: {
                              type: '2',
                              certName: record.teamOwnerName,
                              recordId,
                              userId: record.teamOwnerId,
                              balance: 0,
                              teamName: record.teamName,
                            },
                          })
                        }}
                      >
                        查看详情
                      </Button>
                    ),
                  },
                ]}
                dataSource={withholdList}
                pagination={getPaginationProps(withholdForm, withholdTotal)}
              />
            </div>
          </TabPane>
        )}
      </Tabs>
    </Page>
  )
}
