import React, {useCallback, useEffect, useRef, useState} from 'react'
import {Button, Modal, Row, Space, message, Form, Radio, Select, Col, Input, Table, Transfer, Tooltip} from 'antd'
import {stewardModel} from '@/store/models/Steward'
import EditService from '@/pages/client/components/EditService'
import commonStyles from '@/assets/styles/common.module.less'
import {usePermission} from '@/components/Permission'
import {getPaginationProps} from '@/common/biz/getPaginationProps'

function flatTree<T extends Pick<StewardServiceVO, 'stewardServiceList'>>(arr: T[]): T[] {
  const newArr = []
  arr?.forEach(item => {
    item.stewardServiceList.forEach(child => {
      child.stewardServiceList.forEach(el => {
        newArr.push(el)
      })
    })
  })
  return newArr
}

interface RecordType {
  key: string
  title: string
  description: string
  chosen: boolean
}

export default function ClientServiceInfo(props: {userId: string}) {
  const userId = props.userId
  const [auth] = usePermission()
  const [isEdit, setIsEdit] = useState<boolean>(false)

  // 总的数据
  const [options, setOptions] = useState<StewardServiceVO[]>([])

  const [chargeCustomList, setChargeCustomList] = useState<StewardServiceCustomChargeBindVO[]>([])

  // 穿梭框
  const [targetKeys, setTargetKeys] = useState<string[]>()

  const editRef = useRef(null)
  const [serviceId, setServiceId] = useState('')

  // 配置报价
  const [useOpen, setUseOpen] = useState<boolean>(false)
  const [useForm] = Form.useForm()
  const [templateList, setTemplateList] = useState<StewardServiceTemplateVO[]>([])

  useEffect(() => {
    stewardModel
      .stewardServiceTemplateList({pageable: false, pageSize: 1, pageNum: 1} as StewardServiceTemplateListDTO)
      .then(({response: {data}}) => {
        setTemplateList(data)
      })
  }, [])

  useEffect(() => {
    stewardModel
      .stewardAdminServiceTree({} as StewardServiceQueryDTO)
      .then(({response: {data}}) => setOptions(flatTree(data)))
  }, [])

  const [chargeIdList, setChargeIdList] = useState<string[]>([])

  // ChargeIdList
  useEffect(() => {
    if (!serviceId) return
    stewardModel
      .stewardAdminServiceDetail({serviceId})
      .then(({response: {data}}) => setChargeIdList(data.serviceChargeIdList))
  }, [serviceId])

  const queryChargeCustomList = useCallback(() => {
    stewardModel.stewardAdminServiceChargeCustomList({customerId: userId}).then(({response: {data}}) => {
      setChargeCustomList(data)
    })
  }, [userId])

  useEffect(() => {
    queryChargeCustomList()
  }, [queryChargeCustomList])

  const queryServiceCustomTree = useCallback(() => {
    if (options.length) {
      // 已选的
      stewardModel.stewardAdminServiceCustomTree({customerId: userId}).then(({response: {data}}) => {
        setTargetKeys(flatTree(data).map(value => value.id))
      })
    }
  }, [options.length, userId])

  useEffect(() => {
    queryServiceCustomTree()
  }, [queryServiceCustomTree])

  /** 列表相关 */
  const [form] = Form.useForm<StewardServiceOfferQueryDTO>()
  const [total, setTotal] = useState(0)
  const [list, setList] = useState<StewardServiceOfferVO[]>([])

  const queryList = useCallback(() => {
    stewardModel.stewardServiceOfferLadderTwoList(form.getFieldsValue(true)).then(({response: {data, total}}) => {
      setTotal(total)
      setList(data)
    })
  }, [form])

  useEffect(() => {
    form.submit()
  }, [form])

  const listLoading = stewardModel.stewardServiceOfferLadderTwoList.useLoading()
  const confirmLoading = stewardModel.stewardServiceTemplateAudit.useLoading()
  const sendLoading = stewardModel.stewardServiceAudit.useLoading()

  return (
    <div style={{background: '#fff', padding: '26px 25px'}}>
      {isEdit ? (
        <Row justify={'end'} style={{marginBottom: '20px'}}>
          <Space>
            {
              <Button
                onClick={() => {
                  setIsEdit(false)
                }}
              >
                取消
              </Button>
            }
            <Button
              loading={sendLoading}
              type={'primary'}
              onClick={async () => {
                const serviceCharge = chargeCustomList.filter(value => targetKeys?.includes(value.serviceId))

                if (targetKeys?.some(key => serviceCharge.every(item => item.serviceId !== key))) {
                  return message.warn('有已选的收费项的价格未编辑')
                }
                const serviceList = options.filter(value => targetKeys?.includes(value.id))

                await stewardModel.stewardServiceAudit({
                  serviceDataStr: JSON.stringify({
                    customerId: userId,
                    // serviceList: serviceList.map(item => ({...item, feeList: item.serviceFeeList})),
                    serviceList: serviceList,
                  }),
                  serviceChargeDataStr: JSON.stringify(serviceCharge),
                })
                message.success('已发起申请，请前往企业微信审批！')
                queryServiceCustomTree()
                setIsEdit(false)
                queryList()
              }}
            >
              保存
            </Button>
          </Space>
        </Row>
      ) : (
        <Row justify={'end'} style={{marginBottom: '20px'}}>
          <Space>
            {auth('002001003') && (
              <Button
                type={'primary'}
                onClick={() => {
                  setUseOpen(true)
                }}
              >
                配置报价
              </Button>
            )}
            <Button type={'primary'} onClick={() => setIsEdit(true)}>
              编辑服务类型
            </Button>
          </Space>
        </Row>
      )}
      {isEdit ? (
        <Transfer
          dataSource={options}
          filterOption={(inputValue, option) => option?.name?.toLowerCase().includes(inputValue.toLowerCase())}
          rowKey={record => record.id}
          showSearch
          titles={['可选服务类型', '已选服务类型']}
          listStyle={{
            width: 250,
            height: 400,
          }}
          targetKeys={targetKeys}
          onChange={newTargetKeys => setTargetKeys(newTargetKeys)}
          render={item =>
            targetKeys.includes(item.id) ? (
              <div style={{display: 'flex', alignItems: 'center'}}>
                <div style={{width: 100, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis'}}>
                  <Tooltip title={item.name}>{item.name}</Tooltip>
                </div>
                <span style={{color: 'red', margin: '0 15px'}}>
                  {chargeCustomList.some(value => value.serviceId === item.id) ? '已编辑' : '未编辑'}
                </span>
                <div
                  style={{color: '#00B7AE'}}
                  onClick={() => {
                    setServiceId(item.id)
                    editRef.current.onDisplay()
                    editRef.current.onSetForm(
                      item.id,
                      chargeIdList,
                      chargeCustomList.filter(i => i.serviceId === item.id)
                    )
                  }}
                >
                  编辑
                </div>
              </div>
            ) : (
              item.name
            )
          }
        />
      ) : (
        <div>
          <div className={commonStyles.filterSection}>
            <Form
              form={form}
              labelCol={{flex: '120px'}}
              initialValues={{pageNum: 1, pageSize: 10, pageable: true, userId}}
              onFinish={() => {
                queryList()
              }}
              onReset={() => {
                queryList()
              }}
            >
              <Row gutter={30}>
                <Col span={7}>
                  <Form.Item name={'chargeId'} label={'收费服务编号'}>
                    <Input placeholder={'请输入'} allowClear />
                  </Form.Item>
                </Col>
                <Col span={7}>
                  <Form.Item name={'chargeName'} label={'收费服务名称'}>
                    <Input placeholder={'请输入'} allowClear />
                  </Form.Item>
                </Col>
                <Col span={7}>
                  <Form.Item name={'serviceIdList'} label={'服务类型'}>
                    <Select
                      maxTagCount={2}
                      fieldNames={{label: 'name', value: 'id'}}
                      options={options as any[]}
                      placeholder={'请选择'}
                      mode='multiple'
                      showSearch
                      filterOption={(input, option: StewardServiceVO) =>
                        option?.name?.toLowerCase().includes(input.toLowerCase())
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row justify={'end'}>
                <Space>
                  <Button
                    type={'primary'}
                    htmlType={'submit'}
                    onClick={() => {
                      form.setFieldsValue({pageNum: 1})
                      queryChargeCustomList()
                    }}
                  >
                    查询
                  </Button>
                  <Button htmlType={'reset'}>重置</Button>
                </Space>
              </Row>
            </Form>
          </div>
          <div className={commonStyles.tableSection}>
            <Table
              rowKey={'bindId'}
              loading={listLoading}
              dataSource={list}
              columns={[
                {
                  title: '收费服务编号',
                  dataIndex: 'chargeId',
                },

                {title: '收费服务名称', dataIndex: 'chargeName'},
                {title: '全包价格（元）', dataIndex: 'allChargeAmt'},
                {title: '按次价格（元）', dataIndex: 'perChargeAmt'},
                {title: '服务类型', dataIndex: 'serviceName'},
              ]}
              pagination={getPaginationProps(form, total)}
            />
          </div>
        </div>
      )}

      <EditService
        ref={editRef}
        serviceId={serviceId}
        customerId={userId}
        chargeCustomList={chargeCustomList}
        onSuccess={({feeList, customChargeList: chargeList}) => {
          console.log('chargeList', feeList, chargeList)
          setOptions(prevState =>
            prevState.map(value => (value.id === serviceId ? {...value, serviceFeeList: feeList} : value))
          )
          // const tree = customTree
          // for (const [index, id] of select.entries()) {
          //   if (index === 2) {
          //     tree.forEach(item => {
          //       if (item.id === serviceId) {
          //         item.serviceFeeList = feeList
          //       }
          //     })
          //   } else {
          //     const item = tree.find(item => item.id === id)
          //     tree = item.stewardServiceList
          //   }
          // }
          setChargeCustomList(prevState => prevState.filter(item => item.serviceId !== serviceId).concat(chargeList))
        }}
      />

      <Modal
        open={useOpen}
        title={'配置报价'}
        centered
        width={500}
        confirmLoading={confirmLoading}
        destroyOnClose
        afterClose={() => useForm.resetFields()}
        onCancel={() => setUseOpen(false)}
        onOk={() => useForm.submit()}
      >
        <Form
          form={useForm}
          onFinish={async () => {
            const {templateId, config} = useForm.getFieldsValue(true)
            await stewardModel.stewardServiceTemplateAudit({
              templateId: config === '1' ? templateId : '',
              customerId: userId,
            })
            message.success('已发起申请，请前往企业微信审批!')
            setUseOpen(false)
          }}
          labelCol={{flex: '120px'}}
        >
          <Form.Item label={'配置方式'} name={'config'} rules={[{required: true, message: '请选择配置方式'}]}>
            <Radio.Group>
              <Radio value={'1'}>使用模板</Radio>
              <Radio value={'2'}>清除现有配置</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            label={' '}
            style={{marginBottom: 0}}
            shouldUpdate={(prev, next) => prev.config !== next.config}
            colon={false}
          >
            {f => {
              const config = f.getFieldValue('config')
              return config === '1' ? (
                <Space direction={'vertical'}>
                  <Form.Item name={'templateId'} rules={[{required: true, message: '请选择配置模板'}]}>
                    <Select
                      placeholder={'请选择'}
                      options={templateList}
                      fieldNames={{value: 'templateId', label: 'templateName'}}
                    />
                  </Form.Item>
                  <Form.Item>
                    <span style={{color: '#F35A5A'}}>使用模板将替换已单独配置的价格进行展示</span>
                  </Form.Item>
                </Space>
              ) : config === '2' ? (
                <Form.Item colon={false}>
                  <span style={{color: '#F35A5A'}}>清除后将展示通用服务价格</span>
                </Form.Item>
              ) : null
            }}
          </Form.Item>
        </Form>
      </Modal>
    </div>
  )
}
