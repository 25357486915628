import React from 'react'
import styles from './index.module.less'
import {Typography} from 'antd'

interface TimeLineProp {
  label?: string | React.ReactNode
  children?: any
  dot?: React.ReactNode
  line?: boolean
}

export default function TimeLine(props: TimeLineProp) {
  const {label, children, dot, line} = props
  return (
    <div className={styles.timeLine}>
      <div className={styles.date}>
        <Typography.Text>{label}</Typography.Text>
      </div>
      <div className={styles.divide}>
        <div>{dot}</div>
        {line ? <div className={styles.line} /> : null}
      </div>
      <div className={styles.content}>{children}</div>
    </div>
  )
}
