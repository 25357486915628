import React, {useCallback, useEffect, useState} from 'react'
import {Page} from '@/components/Page'
import {Button, Descriptions, Modal, PageHeader, Space} from 'antd'
import DividerLine from '@/pages/property/component/DividerLine'
import {useAppHistory, useAppLocation} from '@/common/hooks/useRouter'
import {cleanModel} from '@/store/models/Clean'
import {useCodeSync} from '@/store/models/Common'
import {useCleanOrderCheck} from '@/pages/cleanManagement/hooks/useCleanOrderCheck'
import {useCleanOrderCancel} from '@/pages/cleanManagement/hooks/useCleanOrderCancel'
import {DetailSectionImage} from '@/pages/steward/StewardDetail'
import {getImg} from '@/utils/util'
import * as listener from '@/common/hooks/listener'

const orderMakerTypeCode = {lessee: '租客', lessor: '房东'}

export default function ServiceCleanConfirmDetail() {
  const location = useAppLocation<'/service-clean-confirm/detail'>()
  const history = useAppHistory()
  const orderId = location.state?.orderId

  const cleanOrderStatusCode = useCodeSync('clean.order.status')

  const cleanOrderCheck = useCleanOrderCheck()
  const cleanOrderCancel = useCleanOrderCancel()

  const [orderDetail, setOrderDetail] = useState({} as CleanOrderVO)

  const queryCleanOrder = useCallback(() => {
    if (orderId) {
      cleanModel.cleanOrderGet({orderId}).then(({response: {data}}) => {
        setOrderDetail(data)
      })
    }
  }, [orderId])

  useEffect(() => {
    queryCleanOrder()
  }, [queryCleanOrder])

  return (
    <Page
      header={
        <PageHeader
          title={'保洁确认'}
          ghost={false}
          onBack={() => {
            history.goBack()
          }}
          extra={
            <Space>
              <Button
                onClick={() =>
                  cleanOrderCancel({
                    orderId,
                    onSuccess: () => {
                      history.goBack()
                      listener.emit('useCleanOrderList')
                    },
                  })
                }
              >
                取消服务
              </Button>
              <Button
                type={'primary'}
                onClick={() =>
                  cleanOrderCheck({
                    orderId,
                    serviceId: orderDetail.categoryThi,
                    customerId: orderDetail.customerId,
                    onSuccess: () => {
                      history.goBack()
                      listener.emit('useCleanOrderList')
                    },
                  })
                }
              >
                确认
              </Button>
            </Space>
          }
        />
      }
    >
      <Space direction={'vertical'} size={30} style={{padding: '30px 25px', backgroundColor: 'white'}}>
        <Descriptions
          title={
            <DividerLine
              title={'服务单信息'}
              right={
                <span style={{fontSize: '14px', fontWeight: 'normal'}}>
                  {!!orderDetail.insertTime && `（${orderDetail.insertTime}）`}
                </span>
              }
            />
          }
          column={2}
        >
          <Descriptions.Item label={'订单编号'}>{orderDetail.orderId}</Descriptions.Item>
          <Descriptions.Item label={'下单时间'}>{orderDetail.insertTime}</Descriptions.Item>
          <Descriptions.Item label={'下单人'}>
            {orderDetail.orderMakerName}（{orderMakerTypeCode[orderDetail.orderMakerType]}）（
            {orderDetail.orderMakerMobile}）
          </Descriptions.Item>
          <Descriptions.Item label={'服务联系手机号'}>{orderDetail.contactMobile}</Descriptions.Item>
          <Descriptions.Item label={'订单状态'}>{cleanOrderStatusCode[orderDetail.orderStatus]}</Descriptions.Item>
        </Descriptions>

        <Descriptions
          title={
            <DividerLine
              title={'订单信息'}
              right={
                <span style={{fontSize: '14px', fontWeight: 'normal'}}>
                  {!!orderDetail.insertTime && `（${orderDetail.insertTime}）`}
                </span>
              }
            />
          }
          column={2}
        >
          <Descriptions.Item label={'房源地址'}>{orderDetail.propertyAddr}</Descriptions.Item>
          <Descriptions.Item label={'房东'}>{orderDetail.customerName}</Descriptions.Item>
          <Descriptions.Item label={'详细地址'}>{orderDetail.communityAddress}</Descriptions.Item>
          <Descriptions.Item label={'需求描述'}>{orderDetail.orderDesc}</Descriptions.Item>
          <Descriptions.Item label={'所属团队'}>{orderDetail.teamName}</Descriptions.Item>
          <Descriptions.Item label={'服务项目'}>
            {[orderDetail.categoryFirName, orderDetail.categorySecName, orderDetail.categoryThiName]
              .filter(item => item)
              .join('-')}
          </Descriptions.Item>
          <Descriptions.Item label={'合同类型'}>{orderDetail.saleContractType}</Descriptions.Item>
          <Descriptions.Item label={'物品清理'}>{orderDetail.orderTags}</Descriptions.Item>
          <Descriptions.Item label={'图片'}>
            <DetailSectionImage
              imgList={orderDetail.mediaList}
              onClickVideo={item =>
                Modal.confirm({
                  title: '视频预览',
                  icon: null,
                  closable: true,
                  centered: true,
                  content: <video controls src={getImg(item.imgUrl)} width='100%' />,
                })
              }
            />
          </Descriptions.Item>
        </Descriptions>
      </Space>
    </Page>
  )
}
