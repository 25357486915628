import {Breadcrumb} from '@/components/Breadcrumb'
import {Button, Form, FormInstance, Input, message, Modal, Space, Tabs} from 'antd'
import {downloadBlob} from '@/utils/util'
import React, {useState} from 'react'
import {Page} from '@/components/Page'
import {usePermission} from '@/components/Permission'
import StewardUserList from '@/pages/stewardManage/List/StewardUserList'
import commonStyles from '@/assets/styles/common.module.less'
import StewardLicence from '@/pages/stewardManage/List/StewardLicence'

export default function List() {
  const [auth] = usePermission()
  const [btnLoading, setBtnLoading] = useState<boolean>(false)

  const [form1] = Form.useForm()
  const [form2] = Form.useForm()

  const [exportLoading, setExportLoading] = useState(false)
  const [activeKey, setActiveKey] = useState(() => {
    const permissionObj = {
      '001002001': '1',
      '001002003': '2',
    }
    const permission = Object.keys(permissionObj).find(item => auth(item))
    return permissionObj[permission]
  })

  return (
    <Page
      breadcrumb={
        <Breadcrumb
          right={
            <Space>
              {activeKey === '1' && auth('007002001') && (
                <Button
                  loading={btnLoading}
                  type={'primary'}
                  onClick={async () => {
                    setBtnLoading(true)
                    try {
                      await downloadBlob({url: '/steward/user/export', body: form1.getFieldsValue(true)})
                    } finally {
                      setBtnLoading(false)
                    }
                  }}
                >
                  导出
                </Button>
              )}
              {activeKey === '2' && auth('007002002') && (
                <Button
                  loading={exportLoading}
                  type={'primary'}
                  onClick={async () => {
                    let form: FormInstance = null
                    Modal.confirm({
                      title: '导出',
                      icon: null,
                      closable: true,
                      centered: true,
                      width: 500,
                      onOk: async () => {
                        await form.validateFields()
                        const values = form.getFieldsValue(true)
                        setExportLoading(true)
                        try {
                          await downloadBlob({
                            url: '/steward/user/export-licence',
                            body: {
                              ...form2.getFieldsValue(true),
                              ...values,
                            },
                          })
                          message.success('导出申请成功，预计1-7分钟会发送数据到邮箱！')
                        } finally {
                          setExportLoading(false)
                        }
                      },
                      content: (
                        <Form preserve={false}>
                          <Form.Item noStyle shouldUpdate>
                            {f => !!(form = f as any) && null}
                          </Form.Item>
                          <Form.Item
                            label={'导出邮箱'}
                            name={'targetEmail'}
                            rules={[
                              {required: true, message: '请输入邮箱'},
                              {type: 'email', message: '邮箱格式不正确'},
                            ]}
                          >
                            <Input placeholder={'请输入邮箱'} />
                          </Form.Item>
                        </Form>
                      ),
                    })
                  }}
                >
                  导出
                </Button>
              )}
            </Space>
          }
        />
      }
    >
      <Tabs
        activeKey={activeKey}
        onChange={setActiveKey}
        className={commonStyles.tabs}
        items={[
          ...(auth('001002001') ? [{label: '管家列表', key: '1', children: <StewardUserList form={form1} />}] : []),
          ...(auth('001002003') ? [{label: '证件管理', key: '2', children: <StewardLicence form={form2} />}] : []),
        ]}
      />
    </Page>
  )
}
