import React, {useEffect, useState, useRef, useCallback} from 'react'
import DividerLine from '@/pages/property/component/DividerLine'
import {
  Form,
  Row,
  Col,
  Input,
  Select,
  DatePicker,
  Space,
  Button,
  Table,
  Drawer,
  Modal,
  message,
  Descriptions,
} from 'antd'
import commonStyles from '@/assets/styles/common.module.less'
import {Album, UploadButton} from '@/components/Album'
import {baseUrl} from '@/common/config'
import {stewardModel} from '@/store/models/Steward'
import moment from 'moment'
import {useCodeArraySync, useCodeSync} from '@/store/models/Common'
import {getImg} from '@/utils/util'
import LookImage from '@/pages/client/components/LookImage'
import {usePermission} from '@/components/Permission'
import {Page} from '@/components/Page'
import {useAppLocation} from '@/common/hooks/useRouter'
import {useFocusEffect} from '@/common/hooks/useFocusEffect'
import {getPaginationProps} from '@/common/biz/getPaginationProps'

const {RangePicker} = DatePicker
const {Option} = Select

export default function ClientDocking() {
  const location = useAppLocation<'/client-docking'>()
  const [form] = Form.useForm()
  const [editForm] = Form.useForm()
  const [total, setTotal] = useState<number>(0)
  const [auth] = usePermission()
  const [list, setList] = useState<SaleCustomerSignExtraVO[]>([])
  const [drawerVisible, setDrawerVisible] = useState(false)
  const [editVisible, setEditVisible] = useState(false)
  const signExtraStatus = useCodeSync('sign.extra.status')
  const signExtraStatusArr = useCodeArraySync('sign.extra.status')
  const stewardFeeType = useCodeSync('steward.fee.type')
  const cleanFeeType = useCodeSync('clean.fee.type')
  const [info, setInfo] = useState<Partial<SaleCustomerSignExtraVO>>({})
  const imgRef = useRef(null)

  const search = useCallback(() => {
    const values = form.getFieldsValue(true)
    stewardModel.stewardAppSaleCustomerSignExtraList(values).then(({response: {data, total}}) => {
      setList(data)
      setTotal(total)
    })
  }, [form])

  useFocusEffect(() => {
    form.submit()
  })

  useEffect(() => {
    form.setFieldsValue({extraStatus: location.state?.extraStatus})
    form.submit()
  }, [form, location])

  return (
    <Page>
      {/* 列表 */}
      <div className={commonStyles.filterSection}>
        <Form initialValues={{pageNum: 1, pageSize: 10}} form={form} onFinish={search} onReset={search}>
          <Row gutter={20}>
            <Col span={7}>
              <Form.Item label={'客户名称'} name={'keyword'}>
                <Input placeholder={'请输入客户名称或手机号'} allowClear />
              </Form.Item>
            </Col>
            <Col span={7} offset={1}>
              <Form.Item label={'对接状态'} name={'extraStatus'}>
                <Select placeholder={'请选择'} allowClear>
                  {signExtraStatusArr.map(item => (
                    <Option value={item.id} key={item.id}>
                      {item.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={7} offset={1}>
              <Form.Item label={'签约日期'} name={'time'}>
                <RangePicker />
              </Form.Item>
            </Col>
          </Row>
          <Row justify={'end'}>
            <Space>
              <Button type={'primary'} htmlType={'submit'} onClick={() => form.setFieldsValue({pageNum: 1})}>
                查询
              </Button>
              <Button htmlType={'reset'}>重置</Button>
            </Space>
          </Row>
        </Form>
      </div>

      <div className={commonStyles.tableSection}>
        <Table
          rowKey={'extraId'}
          dataSource={list}
          columns={[
            {title: '编号', dataIndex: 'extraId'},
            {title: '客户名称', dataIndex: 'name'},
            {title: '手机号', dataIndex: 'mobile'},
            {
              title: '服务类型',
              render: render => {
                return [render.signSteward === '1' ? '管家' : '', render.signClean === '1' ? '保洁' : '']
                  .filter(item => item)
                  .join('、')
              },
            },
            {
              title: '管家收费类型',
              dataIndex: 'stewardFeeType',
              render: value => stewardFeeType[value] ?? '/',
            },
            {
              title: '保洁收费类型',
              dataIndex: 'cleanFeeType',
              render: value => cleanFeeType[value] ?? '/',
            },
            {
              title: '签约日期',
              dataIndex: 'signDate',
              render: value => moment(value).format('YYYY-MM-DD'),
            },
            {title: 'BD', dataIndex: 'holderName'},
            {
              title: '对接状态',
              dataIndex: 'extraStatus',
              render: value => signExtraStatus[value],
            },
            {
              title: '操作',
              align: 'center',
              render: (_, render) =>
                render.extraStatus === '1' ? (
                  auth('002060002') && (
                    <Button
                      type={'link'}
                      size={'small'}
                      onClick={async () => {
                        const {
                          response: {data},
                        } = await stewardModel.stewardAppSaleCustomerSignExtraInfo({extraId: render.extraId})
                        setInfo(data)
                        editForm.setFieldsValue({extraId: render.extraId, address: render.address})
                        setEditVisible(true)
                      }}
                    >
                      录入
                    </Button>
                  )
                ) : (
                  <Button
                    type={'link'}
                    size={'small'}
                    onClick={async () => {
                      const {
                        response: {data},
                      } = await stewardModel.stewardAppSaleCustomerSignExtraInfo({extraId: render.extraId})
                      setInfo(data)
                      setDrawerVisible(true)
                    }}
                  >
                    查看
                  </Button>
                ),
            },
          ]}
          pagination={getPaginationProps(form, total)}
        />
      </div>

      {/* 详情 */}
      <Drawer visible={drawerVisible} onClose={() => setDrawerVisible(false)} width={689} title={'对接详情'}>
        <DividerLine title={'基本信息'} bottom={15} />
        <Descriptions column={2}>
          <Descriptions.Item label={'客户名称'}>{info.name}</Descriptions.Item>
          <Descriptions.Item label={'联系电话'}>{info.mobile}</Descriptions.Item>
          <Descriptions.Item label={'服务类型'}>
            {[info.signSteward === '1' ? '管家' : '', info.signClean === '1' ? '保洁' : '']
              .filter(item => item)
              .join('、')}
          </Descriptions.Item>
          {info.signSteward === '1' && (
            <Descriptions.Item label={'管家收费类型'}>{stewardFeeType[info.stewardFeeType]}</Descriptions.Item>
          )}
          {info.signClean === '1' && (
            <Descriptions.Item label={'保洁收费类型'}>{cleanFeeType[info.cleanFeeType]}</Descriptions.Item>
          )}
          <Descriptions.Item label={'智齿通话ID'}>{info.zhiChiId}</Descriptions.Item>
          <Descriptions.Item label={'备注'}>{info.remark ?? '/'}</Descriptions.Item>
          <Descriptions.Item label={'操作人'}>{info.operatorName}</Descriptions.Item>
          <Descriptions.Item label={'操作时间'}>{info.operatorTime}</Descriptions.Item>
        </Descriptions>
        <Descriptions column={1}>
          <Descriptions.Item label={'照片凭证'}>
            {info.imgUrlList?.length ? (
              info.imgUrlList.map(item => (
                <img
                  key={item}
                  src={getImg(item)}
                  style={{width: 74, height: 74, marginRight: 10}}
                  onClick={() => {
                    imgRef.current.onDisplay(item)
                  }}
                  alt={'图片凭证'}
                />
              ))
            ) : (
              <span style={{color: '#000000'}}>无</span>
            )}
          </Descriptions.Item>
        </Descriptions>
      </Drawer>

      {/* 录入 */}
      <Modal
        visible={editVisible}
        onCancel={() => setEditVisible(false)}
        centered
        title={'对接录入'}
        width={480}
        footer={null}
        afterClose={() => editForm.resetFields()}
      >
        <Form
          form={editForm}
          labelCol={{span: 5}}
          wrapperCol={{span: 17}}
          onReset={() => {
            setEditVisible(false)
          }}
          onFinish={async () => {
            const {imgUrlList, remark, zhiChiId, extraId} = editForm.getFieldsValue(true)
            await stewardModel.stewardAppSaleCustomerSignExtraUpdate({
              imgUrlList: imgUrlList ?? [],
              remark,
              extraId,
              zhiChiId,
            } as SaleCustomerSignExtraUpdateDTO)
            search()
            message.success('录入成功')
            setEditVisible(false)
          }}
        >
          <Form.Item label={'客户名称'}>
            <span>{info.name}</span>
          </Form.Item>
          <Form.Item label={'联系电话'}>
            <span>{info.mobile}</span>
          </Form.Item>
          <Form.Item label={'服务类型'}>
            <span>
              {[info.signSteward === '1' ? '管家' : '', info.signClean === '1' ? '保洁' : '']
                .filter(item => item)
                .join('、')}
            </span>
          </Form.Item>
          {info.signSteward === '1' && (
            <Form.Item label={'管家收费类型'}>
              <span>{stewardFeeType[info.stewardFeeType]}</span>
            </Form.Item>
          )}
          {info.signClean === '1' && (
            <Form.Item label={'保洁收费类型'}>
              <span>{cleanFeeType[info.cleanFeeType]}</span>
            </Form.Item>
          )}
          <Form.Item label={'智齿通话ID'} name={'zhiChiId'} rules={[{required: true, message: '请输入智齿通话ID'}]}>
            <Input placeholder={'请输入'} allowClear />
          </Form.Item>
          <Form.Item label={'照片凭证'} shouldUpdate wrapperCol={{span: 19}}>
            {form1 => {
              const imgList = form1.getFieldValue('imgUrlList') ?? []
              return (
                <Album
                  limit={9}
                  action={baseUrl + '/steward/sale-customer/sign-extra/file/upload'}
                  onChange={info => {
                    if (info.file.status === 'done') {
                      const {
                        response: {data},
                      } = info.file
                      form1.setFieldsValue({imgUrlList: [...imgList, data.imgUrl]})
                    }
                    if (info.file.status === 'removed') {
                      const {
                        response: {data},
                      } = info.file
                      form1.setFieldsValue({imgUrlList: imgList.filter(item => item !== data.imgUrl)})
                    }
                  }}
                >
                  <UploadButton />
                </Album>
              )
            }}
          </Form.Item>
          <Form.Item label={'备注'} name={'remark'}>
            <Input.TextArea placeholder={'请输入备注'} maxLength={200} autoSize={{maxRows: 5, minRows: 3}} allowClear />
          </Form.Item>
          <Row justify={'end'}>
            <Space>
              <Button htmlType={'reset'}>取消</Button>
              <Button type={'primary'} htmlType={'submit'}>
                确认
              </Button>
            </Space>
          </Row>
        </Form>
      </Modal>

      {/* 查看图片 */}
      <LookImage ref={imgRef} />
    </Page>
  )
}
