import React, {useCallback, useEffect, useState} from 'react'
import {Page} from '@/components/Page'
import {Button, Drawer, Form, InputNumber, message, Modal, Select, Space, Table, Tabs} from 'antd'
import {sysModel} from '@/store/models/Sys'
import styles from '@/assets/styles/common.module.less'
import {usePermission} from '@/components/Permission'
import {sysUserModel} from '@/store/models/SysUser'
import {commonModel} from '@/store/models/Common'
import SysDistrictConfig from '@/pages/administrator/SysDistrictConfig'

export default function SysRuleConfig() {
  const [auth] = usePermission()
  const [form] = Form.useForm()
  const [activeKey, setActiveKey] = useState(() => {
    const permissionMap = {
      '001163001': '1',
      '001163002': '2',
      '001163003': '3',
    }
    const key = Object.keys(permissionMap).find(item => auth(item))
    return permissionMap[key]
  })

  useEffect(() => {
    sysModel.sysConfigGet({k: 'MSG_TIME_END'}).then(({response: {data}}) => {
      form.setFieldsValue({time: data?.time})
    })
  }, [form])

  const [configList, setConfigList] = useState<ApprovalListVO[]>([])
  const [visible, setVisible] = useState(false)
  const [editForm] = Form.useForm()
  const loading = sysModel.approvalConfigList.useLoading()
  const [configCodeList, setConfigCodeList] = useState<PairModel[]>([])
  const [userList, setUserList] = useState<SysUserVO[]>([])
  const [configCityList, setConfigCityList] = useState<ApprovalConfigCityListVO[]>([])
  const [drawerVisible, setDrawerVisible] = useState<boolean>(false)
  const [cityList, setCityList] = useState<CityDataProps[]>([])

  useEffect(() => {
    commonModel
      .queryCityCode({type: '2', parentCode: null})
      .then(({response: {data}}) => setCityList(data.map(item => ({...item, key: item.code}))))
  }, [])

  const searchConfigCityList = useCallback(async (configId: string) => {
    const res = await Promise.all([
      sysModel.approvalConfigCodeGet({approvalConfigId: configId}),
      sysModel.approvalConfigCityList({configId: configId}),
    ])
    const [codeList, list] = res.map(item => item.response.data)
    setConfigCodeList(codeList as PairModel[])
    setConfigCityList(list as ApprovalConfigCityListVO[])
    setDrawerVisible(true)
  }, [])

  const search = useCallback(() => {
    sysModel.approvalConfigList().then(({response: {data}}) => {
      setConfigList(data)
    })
  }, [])

  useEffect(() => {
    search()

    sysUserModel
      .querySysUserList({pageable: false, pageNum: 1, pageSize: 10, status: '1'} as SysListDTO)
      .then(({response: {data}}) => {
        setUserList(data)
      })
  }, [search])

  const saveLoading = sysModel.sysConfigSave.useLoading()

  return (
    <Page>
      <Tabs
        activeKey={activeKey}
        onChange={key => setActiveKey(key)}
        className={styles.tabs}
        tabBarExtraContent={
          <>
            {activeKey === '1' && (
              <Button type={'primary'} onClick={() => form.submit()} loading={saveLoading}>
                保存
              </Button>
            )}
          </>
        }
      >
        {auth('001163001') && (
          <Tabs.TabPane tab={'定时相关配置'} key={'1'}>
            <div className={styles.filterSection}>
              <Form
                form={form}
                onFinish={async () => {
                  const time = form.getFieldValue('time')
                  await sysModel.sysConfigSave({k: 'MSG_TIME_END', v: {time}})
                  message.success('保存成功！')
                }}
              >
                <Space>
                  <Form.Item label={'消息自动关闭时间'} name={'time'} style={{marginBottom: 0}}>
                    <InputNumber style={{width: '200px'}} />
                  </Form.Item>
                  分钟
                </Space>
              </Form>
            </div>
          </Tabs.TabPane>
        )}
        {auth('001163003') && (
          <Tabs.TabPane tab={'服务地区配置'} key={'3'}>
            <SysDistrictConfig />
          </Tabs.TabPane>
        )}
        {auth('001163002') && (
          <Tabs.TabPane tab={'审批配置'} key={'2'}>
            <div className={styles.filterSection}>
              <Table
                rowKey={'configId'}
                dataSource={configList}
                loading={loading}
                columns={[
                  {title: '审批类型', dataIndex: 'configName'},
                  {title: '状态', dataIndex: 'configStatus', render: value => (value === 0 ? '禁用' : '启用')},
                  {
                    title: '操作',
                    render: (_, {configStatus, configId, configName}) => {
                      return (
                        <Space>
                          {/*{configStatus === 0 && (*/}
                          {/*  <Button*/}
                          {/*    type={'link'}*/}
                          {/*    style={{paddingLeft: 0, paddingRight: 0}}*/}
                          {/*    onClick={async () => {*/}
                          {/*      const {*/}
                          {/*        response: {data},*/}
                          {/*      } = await sysModel.approvalConfigCityList({configId})*/}
                          {/*      if (!data.length) return message.warning('该审批类型没有配置审批人，请先编辑配置！')*/}
                          {/*      Modal.confirm({*/}
                          {/*        title: '提示',*/}
                          {/*        centered: true,*/}
                          {/*        icon: null,*/}
                          {/*        content: '是否确认启用该配置？',*/}
                          {/*        onOk: async () => {*/}
                          {/*          await sysModel.approvalConfigEnable({*/}
                          {/*            approvalConfigId: configId,*/}
                          {/*            approvalConfigStatus: 1,*/}
                          {/*          })*/}
                          {/*          message.success('启用成功！')*/}
                          {/*          search()*/}
                          {/*        },*/}
                          {/*      })*/}
                          {/*    }}*/}
                          {/*  >*/}
                          {/*    启用*/}
                          {/*  </Button>*/}
                          {/*)}*/}
                          {/*{configStatus === 1 && (*/}
                          {/*  <Button*/}
                          {/*    type={'link'}*/}
                          {/*    style={{paddingLeft: 0, paddingRight: 0}}*/}
                          {/*    onClick={() => {*/}
                          {/*      Modal.confirm({*/}
                          {/*        title: '提示',*/}
                          {/*        centered: true,*/}
                          {/*        icon: null,*/}
                          {/*        content: '是否确认禁用该配置？',*/}
                          {/*        onOk: async () => {*/}
                          {/*          await sysModel.approvalConfigEnable({*/}
                          {/*            approvalConfigId: configId,*/}
                          {/*            approvalConfigStatus: 0,*/}
                          {/*          })*/}
                          {/*          message.success('禁用成功！')*/}
                          {/*          search()*/}
                          {/*        },*/}
                          {/*      })*/}
                          {/*    }}*/}
                          {/*  >*/}
                          {/*    禁用*/}
                          {/*  </Button>*/}
                          {/*)}*/}
                          <Button
                            type={'link'}
                            style={{paddingLeft: 0, paddingRight: 0}}
                            disabled={configId === '9001'}
                            onClick={async () => {
                              editForm.setFieldsValue({
                                approvalConfigId: configId,
                                title: configName,
                              })
                              await searchConfigCityList(configId)
                            }}
                          >
                            编辑
                          </Button>
                        </Space>
                      )
                    },
                  },
                ]}
                pagination={false}
              />
            </div>
          </Tabs.TabPane>
        )}
      </Tabs>

      <Drawer
        visible={drawerVisible}
        title={
          <Space>
            <span>{editForm.getFieldValue('title')}</span>
            <Button
              type={'link'}
              onClick={() => {
                editForm.setFieldsValue({approvalContentList: [{approvalUserId: undefined, contentList: []}]})
                setVisible(true)
              }}
            >
              新增
            </Button>
          </Space>
        }
        width={800}
        onClose={() => {
          setDrawerVisible(false)
          editForm.resetFields()
        }}
        destroyOnClose
      >
        <Table
          rowKey={'id'}
          dataSource={configCityList}
          columns={[
            {title: '城市', dataIndex: 'cityName'},
            {title: '审批人', dataIndex: 'approvalNameList', render: value => value.join(',')},
            {title: '抄送人', dataIndex: 'ccUserList', render: value => value?.map(item => item.value)?.join(',')},
            {
              title: '操作',
              dataIndex: 'id',
              render: value => {
                return (
                  <Space>
                    <Button
                      type={'link'}
                      style={{paddingLeft: 0, paddingRight: 0}}
                      onClick={async () => {
                        const {
                          response: {data},
                        } = await sysModel.approvalConfigCityGet({id: value})
                        const {cityCode, configContentList, ccUserList} = data
                        editForm.setFieldsValue({
                          cityCode,
                          id: value,
                          approvalContentList: configContentList,
                          ccUserIdList: ccUserList?.map(item => item.key),
                        })
                        setVisible(true)
                      }}
                    >
                      编辑
                    </Button>
                    <Button
                      type={'link'}
                      style={{paddingLeft: 0, paddingRight: 0}}
                      onClick={() => {
                        const configId = editForm.getFieldValue('approvalConfigId')
                        Modal.confirm({
                          title: '提示',
                          icon: null,
                          centered: true,
                          content: `是否确认删除？`,
                          onOk: async () => {
                            await sysModel.approvalConfigCityDelete({id: value})
                            await searchConfigCityList(configId)
                          },
                        })
                      }}
                    >
                      删除
                    </Button>
                  </Space>
                )
              },
            },
          ]}
          pagination={false}
        />
      </Drawer>

      <Modal
        title={editForm.getFieldValue('title')}
        open={visible}
        centered
        onCancel={() => setVisible(false)}
        afterClose={() => {
          editForm.setFieldsValue({cityCode: undefined, id: undefined, approvalContentList: undefined})
        }}
        destroyOnClose
        width={600}
        okText={'提交'}
        onOk={() => {
          editForm.submit()
        }}
      >
        <Form
          form={editForm}
          onFinish={async () => {
            const {approvalContentList, approvalConfigId, cityCode, id, ccUserIdList} = editForm.getFieldsValue(true)
            const contentList = approvalContentList
              .map(item => item.contentList)
              .flat()
              .filter(item => item)
            if (contentList.length > configCodeList.length) return message.warning('审批内容不能重复！')
            if (contentList.length !== configCodeList.length) return message.warning('存在未配置到审批人的审批内容！')
            if (id) {
              await sysModel.approvalConfigEdit({
                approvalConfigId,
                contentList: approvalContentList,
                cityCode,
                id,
                ccUserIdList,
              } as ApprovalUpsetDTO)
            } else {
              await sysModel.approvalConfigCityInsert({
                approvalConfigId,
                contentList: approvalContentList,
                cityCode,
                id,
                ccUserIdList,
              } as ApprovalUpsetDTO)
            }
            message.success(`${id ? '编辑' : '编辑'}成功！`)
            searchConfigCityList(approvalConfigId)
            setVisible(false)
          }}
        >
          <Form.Item name={'cityCode'} label={'城市'} rules={[{required: true, message: '请选择'}]}>
            <Select
              options={cityList.map(item => ({label: item.name, value: item.code}))}
              placeholder={'请选择'}
              showSearch
              filterOption={(inputValue, option: any) => {
                return option.label?.indexOf(inputValue) > -1
              }}
              disabled={!!editForm.getFieldValue('id')}
              allowClear
            />
          </Form.Item>
          <Tabs
            items={[
              {
                key: '1',
                label: '审批人',
                children: (
                  <>
                    <Form.Item noStyle shouldUpdate>
                      {f => {
                        const approvalContentList = f.getFieldValue('approvalContentList') ?? []
                        const userIdList = approvalContentList.map(item => item.approvalUserId)
                        return (
                          <Form.List name={'approvalContentList'}>
                            {(fields, {add, remove}) => (
                              <>
                                {fields.map(({key, name, ...field}, index) => (
                                  <div key={key} style={{display: 'flex', flexDirection: 'column'}}>
                                    <div style={{display: 'flex', flexDirection: 'row'}}>
                                      <Form.Item
                                        name={[name, 'approvalUserId']}
                                        label={'审批人'}
                                        {...field}
                                        style={{width: '400px'}}
                                        rules={[{required: true, message: '请选择'}]}
                                      >
                                        <Select
                                          placeholder={'请选择'}
                                          showSearch
                                          allowClear
                                          filterOption={(inputValue, option) =>
                                            option?.children?.indexOf(inputValue as any) > -1
                                          }
                                        >
                                          {userList.map(item => (
                                            <Select.Option
                                              value={item.userId}
                                              disabled={userIdList.includes(item.userId)}
                                              key={item.userId}
                                            >
                                              {`${item.name}（${item.mobile}）`}
                                            </Select.Option>
                                          ))}
                                        </Select>
                                      </Form.Item>
                                      <Form.Item>
                                        <Space>
                                          <Button
                                            type={'link'}
                                            onClick={() => add({approvalUserId: undefined, contentList: []}, index)}
                                          >
                                            新增上级审批人
                                          </Button>
                                          <Button
                                            type={'link'}
                                            onClick={() => add({approvalUserId: undefined, contentList: []}, index + 1)}
                                          >
                                            新增下级审批人
                                          </Button>
                                        </Space>
                                      </Form.Item>
                                    </div>

                                    <div style={{display: 'flex', flexDirection: 'row'}}>
                                      <Form.Item
                                        style={{width: '400px'}}
                                        name={[name, 'contentList']}
                                        label={'审批内容'}
                                        {...field}
                                      >
                                        <Select placeholder={'请选择'} mode={'multiple'}>
                                          {configCodeList.map(item => (
                                            <Select.Option value={item.key} key={item.key}>
                                              {item.value}
                                            </Select.Option>
                                          ))}
                                        </Select>
                                      </Form.Item>
                                      {index > 0 && (
                                        <Form.Item>
                                          <Button type={'link'} onClick={() => remove(name)}>
                                            删除
                                          </Button>
                                        </Form.Item>
                                      )}
                                    </div>
                                  </div>
                                ))}
                              </>
                            )}
                          </Form.List>
                        )
                      }}
                    </Form.Item>
                  </>
                ),
              },
              {
                key: '2',
                label: '抄送人',
                children: (
                  <Form.Item label={'抄送人'} name={'ccUserIdList'}>
                    <Select
                      placeholder={'请选择'}
                      showSearch
                      allowClear
                      mode={'multiple'}
                      filterOption={(inputValue: any, option) => option?.children?.indexOf(inputValue) > -1}
                    >
                      {userList.map(item => (
                        <Select.Option value={item.userId} key={item.userId}>
                          {`${item.name}（${item.mobile}）`}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                ),
              },
            ]}
          />
        </Form>
      </Modal>
    </Page>
  )
}
