import React, {useEffect, useRef, useState} from 'react'
import {Button, PageHeader, Space, Table, Tabs, Upload} from 'antd'
import commonStyles from '@/assets/styles/common.module.less'
import SaleClientContractInput from '@/pages/saleManagement/SaleClientContractInput'
import ClientBasicInfo from '@/pages/client/components/ClientBasicInfo'
import styles from './index.module.less'
import {useLocation} from 'react-router'
import {lessorModel} from '@/store/models/Lessor'
import {saleModel} from '@/store/models/Sale'
import {useCodeSync} from '@/store/models/Common'
import {Page} from '@/components/Page'
import {usePermission} from '@/components/Permission'
import {ClientBillComponent} from '@/pages/client/components/ClientBillComponent'
import {uploadExcelData} from '@/components/UploadExcel'

const {TabPane} = Tabs

export default function ClientContractDetail() {
  const location = useLocation<{customerId: string; contractId: string}>()
  const rentTypeCode = useCodeSync('property.rentType')
  const [auth] = usePermission()
  const clientBillRef = useRef(null)

  const customerId = location.state?.customerId
  const contractId = location.state?.contractId

  const [activeKey, setActiveKey] = useState('1')
  const [lessorInfo, setLessorInfo] = useState({} as LessorInfoVO)

  const [total, setTotal] = useState(0)
  const [propertyList, setPropertyList] = useState<SaleCustomerContractPropertyVO[]>([])

  const [upLoading, setUpLoading] = useState(false)

  useEffect(() => {
    lessorModel.queryLessorInfo(customerId).then(({response: {data}}) => setLessorInfo(data))
  }, [customerId])

  useEffect(() => {
    saleModel.saleCustomerContractPropertyStatistics({contractId}).then(({response: {data, total}}) => {
      setTotal(total)
      setPropertyList(data)
    })
  }, [contractId])

  return (
    <Page
      header={
        <PageHeader title={'基本信息'} ghost={false}>
          <ClientBasicInfo info={lessorInfo} />
        </PageHeader>
      }
    >
      <Tabs
        activeKey={activeKey}
        onChange={key => setActiveKey(key)}
        tabBarExtraContent={
          activeKey === '3' && (
            <Space>
              {auth('011003001') && (
                <Upload
                  accept={'.xlsx, .xls'}
                  multiple={false}
                  showUploadList={false}
                  customRequest={options =>
                    uploadExcelData({
                      info: options,
                      uri: '/user/lessor/bill/confirm-excel',
                      onLoading: loading => setUpLoading(loading),
                      onSuccess: () => clientBillRef.current?.import?.(),
                    })
                  }
                >
                  <Button type={'primary'} loading={upLoading}>
                    导入已收款账单
                  </Button>
                </Upload>
              )}
              {auth('003003002') && (
                <Button
                  type={'primary'}
                  onClick={() => {
                    clientBillRef.current?.addBill?.()
                  }}
                >
                  添加服务账单
                </Button>
              )}
              {auth('007003001') && (
                <Button
                  onClick={() => {
                    clientBillRef.current?.export?.()
                  }}
                >
                  导出
                </Button>
              )}
            </Space>
          )
        }
        className={styles.tab}
      >
        <TabPane tab={'合同详情'} key={'1'}>
          {/* auth('002048002')：编辑保洁合同权限 */}
          <SaleClientContractInput contractId={contractId} editClean={auth('002048002')} />
        </TabPane>
        <TabPane tab={'绑定房源'} key={'2'}>
          <div className={commonStyles.tableSection}>
            <Table
              rowKey={'propertyCode'}
              loading={false}
              scroll={{x: 'max-content'}}
              columns={[
                {title: '组织-团队', render: record => `${record.teamPname}-${record.teamName}`},
                {title: '房源类型', dataIndex: 'rentType', key: 'rentType', render: rentType => rentTypeCode[rentType]},
                {title: '小区', dataIndex: 'communityName', key: 'communityName'},
                {title: '地址', dataIndex: 'address', key: 'address'},
                {title: '房源数量', dataIndex: 'propertyCount', key: 'propertyCount'},
              ]}
              dataSource={propertyList}
              pagination={{total, position: ['bottomCenter']}}
            />
          </div>
        </TabPane>
        <TabPane tab={'服务账单'} key={'3'}>
          <ClientBillComponent userId={customerId} contractId={contractId} ref={clientBillRef} />
        </TabPane>
      </Tabs>
    </Page>
  )
}
