import {objectCreate} from '@/utils/util'
import {isProd} from '@/common/config'
// import {IconNames} from 'ui'

export enum BankName {
  工商银行 = '工商银行',
  农业银行 = '农业银行',
  中国银行 = '中国银行',
  建设银行 = '建设银行',
  交通银行 = '交通银行',
  招商银行 = '招商银行',
  中信银行 = '中信银行',
  光大银行 = '光大银行',
  华夏银行 = '华夏银行',
  民生银行 = '民生银行',
  兴业银行 = '兴业银行',
  广发银行 = '广发银行',
  平安银行 = '平安银行',
  浦发银行 = '浦发银行',
  邮政储蓄 = '邮政储蓄',
  青岛银行 = '青岛银行',
  上海银行 = '上海银行',
}

export const baseCodes: Codes = {
  'handle.status': {
    '-1': '已取消',
    '0': '待确认',
    '1': '已确认',
  },
  // 保洁频次
  cleanFreq: {
    '1': '每周一次',
    '2': '两周一次',
    '3': '一月一次',
  },
  // 保洁服务类型
  cleanService: {
    '1': '退租保洁',
    '2': '公区保洁',
    '3': '入住保洁',
    '4': '开荒保洁',
    '5': '深度保洁',
    '6': '周期保洁',
  },
  // 其他保洁收费
  cleanOtherFee: {
    '0': '预充值',
    '1': '后付费',
  },
  picExist: {
    '0': '无照片',
    '1': '有照片',
  },
  rentType: {
    '1': '分散式房源整租',
    '2': '分散式房源合租',
    '3': '集中式公寓',
  },
  commonList: {
    a: '餐桌',
    b: '煤气灶',
    c: '抽烟烟机',
    d: '冰箱',
    e: '餐椅',
    f: '沙发',
    g: '洗衣机',
    h: '烘干机',
    i: '微波炉',
    j: '热水器',
  },
  roomList: {
    aa: '单人床',
    bb: '双人床',
    cc: '台灯',
    dd: '床垫',
    ee: '床头柜',
    ff: '矮柜',
    gg: '茶几',
    hh: '斗柜',
    ii: '吊橱',
    jj: '空调',
    kk: '电视',
    ll: '遥控器',
    mm: '沙发',
    nn: '电视柜',
    oo: '桌子',
    pp: '椅子',
    qq: '窗帘',
    rr: '落地台灯',
    ss: '吊灯',
    tt: '壁灯',
    uu: '沙发床',
    vv: '化妆台',
    ww: '化妆椅',
    xx: '鞋柜',
    yy: '机顶盒',
  },

  contractStatus: {
    '1': '待签约',
    '2': '已签约',
    '3': '已到期',
    '4': '已退房',
    '5': '已作废',
  },
  billStatus: {
    '1': '待收款',
    '2': '已收款',
    '3': '已作废',
    '4': '坏账',
    '5': '部分坏账',
  },
  checkOutReason: {
    '1': '到期退房',
    '2': '提前退房',
    '3': '特殊退房',
  },
  floor: objectCreate(35, {labelSuffix: '层'}),
  floorHead: objectCreate(35, {labelSuffix: '层'}),
  bedroom: objectCreate(26, {labelSuffix: '室'}),
  parlor: objectCreate(11, {labelSuffix: '厅', idOffset: -1}),
  bathroom: objectCreate(11, {labelSuffix: '卫', idOffset: -1}),
  rentPayType: objectCreate(24, {labelPrefix: '付'}),
  rentPledgeType: objectCreate(12, {labelPrefix: '押', idOffset: -1}),
  // 银行卡类型
  acctType: {
    1: '借记卡',
    2: '准贷记卡/贷记卡',
  },
  bankIcon: {
    [BankName.工商银行]: 'bank_gongshang',
    [BankName.农业银行]: 'bank_nongye',
    [BankName.中国银行]: 'bank_zhongguo',
    [BankName.建设银行]: 'bank_jianshe',
    [BankName.交通银行]: 'bank_jiaotong',
    [BankName.招商银行]: 'bank_zhaoshang',
    [BankName.中信银行]: 'bank_zhongxin',
    [BankName.光大银行]: 'bank_guangda',
    [BankName.华夏银行]: 'bank_huaxia',
    [BankName.民生银行]: 'bank_minsheng',
    [BankName.兴业银行]: 'bank_xingye',
    [BankName.广发银行]: 'bank_guangfa',
    [BankName.平安银行]: 'bank_pingan',
    [BankName.浦发银行]: 'bank_pufa',
    [BankName.邮政储蓄]: 'bank_youzheng',
    // 北京银行: ,
    [BankName.青岛银行]: 'bank_qingdao',
    [BankName.上海银行]: 'bank_shanghai',
  } /*as {[key: string]: IconNames}*/,

  // 显示条数
  pageSizeOptions: {
    10: '10条',
    20: '20条',
    50: '50条',
    100: '100条',
  },

  // 用户状态
  userStatus: {
    '0': '禁用',
    '1': '正常',
  },

  // 用户等级
  userLevel: {
    '1': '未激活',
    '2': '未实名用户',
    '3': '普通用户',
  },

  // 朝向
  orientation: {
    DK: '无窗暗间',
    E: '东',
    N: '北',
    NE: '东北',
    NS: '南北',
    NW: '西北',
    SE: '东南',
    SW: '西南',
    W: '西',
    WE: '东西',
  },

  // 电梯
  lift: {
    '0': '无',
    '1': '有',
  },

  // 出租状态
  rentStatus: {
    '0': '未出租',
    '1': '已出租',
  },

  // 合同类型
  contractType: {
    paper: '纸质',
    elec: '电子',
  },

  // 收租日类型
  rentCollectType: {
    '1': '每期提前',
    '2': '每期固定',
    '3': '每期提前一个月',
  },

  // 反馈类型
  feedbackType: {
    feature: '功能问题',
    other: '其他问题',
    power: '性能问题',
    product: '产品问题',
  },

  // 房屋类型
  propertyType: {
    condo: '公寓',
    other: '其他',
    townhouse: '新式里弄',
    villa: '别墅',
  },

  //服务单状态
  'steward.orderStatus': {
    '1': '待沟通',
    '2': '已沟通',
    '3': '上门中',
    '4': '服务中',
    '5': '已完成',
    '6': '已取消',
    '99': '待评价',
  },

  // 企业认证状态
  'team.status': {
    '0': '未认证',
    '1': '已认证',
    '2': '审核中',
    '3': '已拒绝',
  },

  // 小区类别
  'community.type': {
    '1': '住宅区',
    '2': '酒店,公寓',
  },

  // 仅在开发环境测试。
  ...(!isProd && {
    education: {
      '1': '博士',
      '2': '硕士学位',
      '3': '本科',
      '4': '大专',
      '5': '高中/中专',
      '6': '高中以下',
    },
    relation: {
      classmate: '同学',
      colleague: '同事',
      family: '家属',
      friend: '朋友',
      other: '其他',
    },
    'bill.status': {
      '1': '待收款',
      '2': '已收款',
      '3': '已作废',
      '4': '坏账',
      '5': '部分坏账',
      '6': '已拆分',
    },
    feedback: {
      feature: '功能问题',
      other: '其他问题',
      power: '性能问题',
      product: '产品问题',
    },
    constellation: {
      '1': '双鱼座',
      '2': '天蝎座',
      '3': '处女座',
      '4': '天秤座',
      '5': '巨蟹座',
      '6': '射手座',
      '7': '双子座',
      '8': '摩羯座',
      '9': '水瓶座',
      '10': '金牛座',
      '11': '白羊座',
      '12': '狮子座',
    },
    'equipment.type': {
      'pe.a': '整租房屋设施，公寓房屋设施',
      'pe.b': '合租房屋设施',
      'pe.c': '合租公共区域设施',
    },
    'team.auth.type': {
      '1': '职业房东',
      '2': '公寓机构',
      '3': '中介机构',
    },
    'pe.a': {
      '10': '电磁炉',
      '11': '床',
      '12': '书桌',
      '13': '衣柜',
      '14': '沙发',
      '15': '阳台',
      '01': 'WIFI',
      '02': '空调',
      '03': '热水器',
      '04': '洗衣机',
      '05': '冰箱',
      '06': '电视',
      '07': '微波炉',
      '08': '燃灶气',
      '09': '抽油烟机',
    },
    'pe.b': {
      '01': '空调',
      '02': '电视',
      '03': '床',
      '04': '书桌',
      '05': '衣柜',
      '06': '阳台',
      '07': '独卫',
    },
    'pe.c': {
      '10': '沙发',
      '01': 'WIFI',
      '02': '热水器',
      '03': '洗衣机',
      '04': '冰箱',
      '05': '电视',
      '06': '微波炉',
      '07': '燃气灶',
      '08': '抽油烟机',
      '09': '电磁炉',
    },
    'steward.cancelType': {
      '01': '问题已解决',
      '02': '点错了/误点了',
      '03': '描述错误需要重新填写',
      '04': '房源选择错误，要重新选择',
      '05': '照片不完整，需重新传照片',
    },
    'user.source': {
      lessee: '承租人',
      lessor: '出租人',
    },
    'rent.status': {
      '0': '未出租',
      '1': '已出租',
    },
    'team.auth.status': {
      '0': '未认证',
      '1': '已认证',
      '2': '审核中',
      '3': '审核失败',
    },
    'property.rentType': {
      '1': '整租',
      '2': '合租',
      '3': '公寓',
    },
    'contract.furniture.pub': {
      '10': '热水器',
      '01': '餐桌',
      '02': '煤气灶',
      '03': '抽烟烟机',
      '04': '冰箱',
      '05': '餐椅',
      '06': '沙发',
      '07': '洗衣机',
      '08': '烘干机',
      '09': '微波炉',
    },
    'steward.orderStatus': {
      '1': '待沟通',
      '2': '已沟通',
      '3': '上门中',
      '4': '服务中',
      '5': '已完成',
      '6': '已取消',
      '7': '待支付',
      '99': '待评价',
    },
    'contract.rentExtra': {
      '01': '水费',
      '02': '电费',
      '03': '煤气费',
      '04': '有线电视费',
      '05': '网络宽带费',
      '06': '物业管理费',
      '07': '室内设施维修费(人为使用不当除外)',
      '08': '保洁费',
      '09': '暖气费',
    },
    'contract.checkOutReason': {
      '1': '到期退房',
      '2': '提前退房',
      '3': '特殊退房',
    },
    'property.type': {
      condo: '公寓',
      other: '其他',
      townhouse: '新式里弄',
      villa: '别墅',
    },
    'contract.certType': {
      '99': '其他',
      '01': '身份证',
      '02': '护照',
      '03': '港澳通行证',
      '04': '军官证',
    },
    'property.orientation': {
      DK: '无窗暗间',
      E: '东',
      N: '北',
      NE: '东北',
      NS: '南北',
      NW: '西北',
      S: '南',
      SE: '东南',
      SW: '西南',
      W: '西',
      WE: '东西',
    },
    gender: {
      '1': '男',
      '2': '女',
    },
    certificate: {
      'id.card': '身份证',
    },
    'msg.type': {
      '2001': '租客_首次注册',
      '2002': '租客_接单',
      '2003': '租客_服务订单评价',
      '2004': '租客_合同待确认',
      '2005': '租客_即将失效提醒',
      '2006': '租客_账单提醒',
      '2007': '租客_周期账单提醒',
      '2008': '租客_逾期提醒',
    },
    'steward.handleResultType': {
      '1': '已完成服务',
      '2': '无需服务',
      '3': '无法完成服务',
    },
    'warehouse.reason': {
      '1': '服务使用',
      '2': '直接入仓',
      '3': '调拨入仓',
      '4': '调拨出仓',
      '5': '盘盈',
      '6': '盘亏',
    },
    'trade.folw': {
      in: '进账',
      out: '出账',
    },
    'b.a': {
      'b.a.a': '房屋租金',
      'b.a.b': '临时租金',
      'b.a.c': '房租手动添加',
      'b.a.d': '租金涨价',
      'b.a.e': '租金补差',
    },
    'contract.type': {
      elec: '电子合同',
      paper: '纸质合同',
    },
    'b.c': {
      'b.c.a': '水费',
      'b.c.b': '电费',
      'b.c.c': '煤气费',
      'b.c.d': '宽带费',
      'b.c.e': '供暖费',
      'b.c.f': '空调费',
      'b.c.g': '燃气费',
      'b.c.h': '热水费',
      'b.c.i': '冷水费',
      'b.c.j': '电卡充值',
    },
    'b.b': {
      'b.b.a': '房屋押金',
      'b.b.b': '其他押金',
      'b.b.c': '燃气卡押金',
      'b.b.d': '水卡押金',
      'b.b.e': '电卡押金',
      'b.b.f': '水电押金',
      'b.b.g': '家电押金',
      'b.b.h': '家具押金',
      'b.b.i': '钥匙押金',
      'b.b.j': '门卡押金',
    },
    'b.e': {
      'b.e.a': '家电维修',
      'b.e.b': '房屋维修',
      'b.e.c': '公共区域维修',
    },
    'rent.collect.type': {
      '1': '每期提前',
    },
    'b.d': {
      'b.d.a': '管理费',
      'b.d.b': '中介费',
      'b.d.c': '物业费',
      'b.d.d': '垃圾费',
      'b.d.e': '有线电视费',
      'b.d.f': '保洁费',
      'b.d.g': '卫生费',
      'b.d.h': '服务费',
      'b.d.i': '照明费',
      'b.d.j': '电梯费',
      'b.d.k': '排污费',
    },
    'b.g': {
      'b.g.a': '预收煤气费',
      'b.g.b': '预收燃气费',
      'b.g.c': '预收网费',
      'b.g.d': '预收供暖费',
      'b.g.e': '预收其他费用',
      'b.g.f': '预收电费',
      'b.g.g': '预收冷水费',
      'b.g.h': '预收热水费',
      'b.g.i': '定金',
    },
    'b.f': {
      'b.f.a': '门卡工本费',
      'b.f.b': '电卡宫本费',
      'b.f.c': '床上用品费',
      'b.f.d': '税收',
      'b.f.e': '转账手续费',
      'b.f.f': '车位费',
      'b.f.g': '公摊费',
      'b.f.h': '电瓶车充电费',
      'b.f.i': '其他杂费',
      'b.f.j': '损耗费',
    },
    'b.i': {
      'b.i.a': '赔偿金',
      'b.i.b': '违约金',
    },
    'b.h': {
      'b.h.a': 'pos刷卡手续费',
      'b.h.b': '利息',
      'b.h.c': '滞纳金',
      'b.h.d': '换房费',
      'b.h.f': '分期手续费',
      'b.h.g': '开票税费',
    },
    'permission.type': {
      btn: '按钮',
      menu: '菜单',
      menu_sub: '次级菜单',
    },
    'b.k': {
      'b.k.a': '退房退款',
      'b.k.b': '明细作废',
      'b.k.c': '额外退款',
      'b.k.d': '提现',
      'b.k.e': '其他',
      'b.k.f': '定金退款',
    },
    'b.j': {
      'b.j.a': '家具租赁费',
      'b.j.b': '电器租赁费',
    },
    b: {
      'b.a': '租金',
      'b.b': '押金',
      'b.c': '水电煤暖网',
      'b.d': '服务收费',
      'b.e': '维修费用',
      'b.f': '杂费',
      'b.g': '预收费',
      'b.h': '手续费',
      'b.i': '违约赔偿',
      'b.j': '租赁费用',
      'b.k': '其他',
    },
    'contract.status': {
      '1': '待签约',
      '2': '已签约',
      '3': '已到期',
      '4': '已退房',
      '5': '已作废',
      '6': '已预订',
      '7': '已撤销',
    },
    'household.register': {
      local: '本地户籍',
      official: '正式居住证',
      temporary: '临时居住证',
    },
    'contract.furniture.room': {
      '11': '单人床',
      '12': '双人床',
      '13': '台灯',
      '14': '床垫',
      '15': '床头柜',
      '16': '矮柜',
      '17': '茶几',
      '18': '斗柜',
      '19': '吊橱',
      '20': '空调',
      '21': '电视',
      '22': '遥控器',
      '23': '沙发',
      '24': '电视柜',
      '25': '桌子',
      '26': '椅子',
      '27': '窗帘',
      '28': '落地台灯',
      '29': '吊灯',
      '30': '壁灯',
      '31': '沙发床',
      '32': '化妆台',
      '33': '化妆椅',
      '34': '鞋柜',
      '35': '机顶盒',
    },
    'business.type': {
      'collect.soon': '三天内待收款',
      expire: '合同到期',
      'expire.soon': '合同快到期',
      overdue: '逾期',
      pending: '待录',
    },
    'bill.payWay': {
      '01': '现金',
      '02': '支付宝转账',
      '03': '微信转账',
      '04': '银行转账',
      '05': '刷卡',
      '06': '其他方式',
      '07': '牛油果平台',
    },
    category: {
      b: '账单类型',
      'business.type': '业务状态',
      certificate: '证件类型',
      constellation: '星座',
      'contract.certType': '租约-证件类型',
      'contract.type': '合同类型',
      education: '学历',
      'equipment.type': '房源设施',
      feedback: '反馈类别',
      gender: '性别',
      'household.register': '户籍',
      'msg.type': '消息类别',
      'permission.type': '权限类型',
      'property.orientation': '房源朝向',
      'property.type': '房源类型',
      relation: '关系',
      'rent.status': '出租状态',
      'steward.cancelType': '管家-服务单取消原因',
      'steward.orderStatus': '管家-服务单状态',
      'team.auth.status': '团队-企业认证状态',
      'team.auth.type': '团队-企业认证类型',
      'trade.folw': '流水方向',
      'user.source': '用户类型',
      'warehouse.reason': '变仓原因',
    },
  }),
}

// export default baseCodes

export const otherCharges = {
  周期费用: [
    {
      category: 'b.f',
      id: 'b.f.a',
      label: '门卡工本费',
      interval: true,
    },
    {
      category: 'b.d',
      id: 'b.d.a',
      label: '管理费',
      interval: true,
    },
    {
      category: 'b.d',
      id: 'b.d.c',
      label: '物业费',
      interval: true,
    },
    {
      category: 'b.d',
      id: 'b.d.d',
      label: '垃圾费',
      interval: true,
    },
    {
      category: 'b.c',
      id: 'b.c.a',
      label: '水费',
      interval: true,
    },
    {
      category: 'b.c',
      id: 'b.c.b',
      label: '电费',
      interval: true,
    },
    {
      category: 'b.c',
      id: 'b.c.d',
      label: '宽带费',
      interval: true,
    },
    {
      category: 'b.d',
      id: 'b.d.e',
      label: '有线电视费',
      interval: true,
    },
    {
      category: 'b.c',
      id: 'b.c.e',
      label: '供暖费',
      interval: true,
    },
    {
      category: 'b.i',
      id: 'b.i.a',
      label: '赔偿金',
      interval: true,
    },
    {
      category: 'b.i',
      id: 'b.i.b',
      label: '违约金',
      interval: true,
    },
    {
      category: 'b.c',
      id: 'b.c.g',
      label: '燃气费',
      interval: true,
    },
    {
      category: 'b.h',
      id: 'b.h.d',
      label: '换房费',
      interval: true,
    },
  ],
  其他押金: [
    {
      category: 'b.b',
      id: 'b.b.j',
      label: '门卡押金',
      interval: false,
    },
    {
      category: 'b.b',
      id: 'b.b.i',
      label: '钥匙押金',
      interval: false,
    },
    {
      category: 'b.b',
      id: 'b.b.h',
      label: '家具押金',
      interval: false,
    },
    {
      category: 'b.b',
      id: 'b.b.g',
      label: '家电押金',
      interval: false,
    },
  ],
  首期一次性费用: [
    {
      category: 'b.f',
      id: 'b.f.a',
      label: '门卡工本费',
      interval: false,
    },
    {
      category: 'b.d',
      id: 'b.d.a',
      label: '管理费',
      interval: false,
    },
    {
      category: 'b.d',
      id: 'b.d.c',
      label: '物业费',
      interval: false,
    },
    {
      category: 'b.d',
      id: 'b.d.d',
      label: '垃圾费',
      interval: false,
    },
    {
      category: 'b.c',
      id: 'b.c.a',
      label: '水费',
      interval: false,
    },
    {
      category: 'b.c',
      id: 'b.c.b',
      label: '电费',
      interval: false,
    },
    {
      category: 'b.c',
      id: 'b.c.d',
      label: '宽带费',
      interval: false,
    },
    {
      category: 'b.d',
      id: 'b.d.e',
      label: '有线电视费',
      interval: false,
    },
    {
      category: 'b.c',
      id: 'b.c.e',
      label: '供暖费',
      interval: false,
    },
    {
      category: 'b.i',
      id: 'b.i.a',
      label: '赔偿金',
      interval: false,
    },
    {
      category: 'b.i',
      id: 'b.i.b',
      label: '违约金',
      interval: false,
    },
    {
      category: 'b.c',
      id: 'b.c.g',
      label: '燃气费',
      interval: false,
    },
    {
      category: 'b.h',
      id: 'b.h.d',
      label: '换房费',
      interval: false,
    },
  ],
}
