import React, {useCallback, useEffect, useState} from 'react'
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Row,
  Select,
  Space,
  Table,
  Tabs,
} from 'antd'
import {Page} from '@/components/Page'
import {Breadcrumb} from '@/components/Breadcrumb'
import commonStyles from '@/assets/styles/common.module.less'
import {getPaginationProps} from '@/common/biz/getPaginationProps'
import {baseUrl} from '@/common/config'
import {Album, UploadButton} from '@/components/Album'
import locale from 'antd/es/date-picker/locale/zh_CN'
import DividerLine from '@/pages/property/component/DividerLine'
import {lhSettlementModel} from '@/store/models/LhSettlement'
import {commonModel, useCodeArraySync, useCodeSync} from '@/store/models/Common'
import {FORMAT_DATE, FORMAT_DATE_END, FORMAT_DATE_START} from '@/common/date'
import moment from 'moment'
import {uploadExcelData} from '@/components/UploadExcel'
import {useAppHistory} from '@/common/hooks/useRouter'
import {downloadBlob} from '@/utils/util'
import {userModel} from '@/store/models/User'
import {sysUserModel} from '@/store/models/SysUser'
import {usePermission} from '@/components/Permission'

// const XLSX_MIME = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
// const MP3_MIME = 'audio/mpeg'

// const XLSX_Validator = {
//   validator: (rule, value: any[]) => {
//     if (value) {
//       for (const item of value) {
//         if (item.status === 'done') {
//           if (item.response?.code === 1000) return Promise.reject(new Error(item.response?.msg))
//         }
//         if (item.type !== XLSX_MIME) return Promise.reject(new Error('请上传扩展名为.xlsx的文件'))
//       }
//     }
//     return Promise.resolve()
//   },
// }

// const ZIP_Validator = {
//   validator: (rule, value: any[]) => {
//     if (value) {
//       for (const item of value) {
//         if (item.status === 'uploading') {
//           return Promise.reject(new Error('文件上传中'))
//         }
//         if (item.status === 'done') {
//           if (item.response?.code === 1000) return Promise.reject(new Error(item.response?.msg))
//         }
//         if (!/\.zip$/i.test(item.name)) return Promise.reject(new Error('请上传扩展名为.zip的文件'))
//       }
//     }
//     return Promise.resolve()
//   },
// }

// const MP3_Validator = {
//   validator: (rule, value: any[]) => {
//     if (value) {
//       for (const item of value) {
//         if (item.status === 'uploading') {
//           return Promise.reject(new Error('文件上传中'))
//         }
//         if (item.status === 'done') {
//           if (item.response?.code === 1000) return Promise.reject(new Error(item.response?.msg))
//         }
//         if (item.type !== MP3_MIME) return Promise.reject(new Error('请上传扩展名为.mp3的文件'))
//       }
//     }
//     return Promise.resolve()
//   },
// }

const File_Validator = (mediaType: 'mp3' | 'xlsx' | 'zip') => {
  const RegCode = {mp3: /\.mp3$/i, xlsx: /\.xlsx$/i, zip: /\.zip$/i}
  const RegDesc = {mp3: '请上传扩展名为.mp3的文件', xlsx: '请上传扩展名为.xlsx的文件', zip: '请上传扩展名为.zip的文件'}

  const reg = RegCode[mediaType]
  const desc = RegDesc[mediaType]

  return {
    validator: (rule, value: any[]) => {
      if (value) {
        for (const item of value) {
          if (item.status === 'uploading') {
            return Promise.reject(new Error('文件上传中'))
          }
          if (item.status === 'done') {
            if (item.response?.code === 1000) return Promise.reject(new Error(item.response?.msg))
          }
          if (!reg.test(item.name)) return Promise.reject(new Error(desc))
        }
      }
      return Promise.resolve()
    },
  }
}

const followOptionCode = {
  '02': ['02', '03'], // 当前状态「电子档待发送」，可选「电子档待发送，电子档待接收，结算终止」
  '03': ['03', '04'],
  '04': ['04', '05', '08'],
  '05': ['05', '06'],
  '06': ['06', '07'],
  '07': ['07', '08'],
  '08': ['08', '09'],
  '09': ['09', '10'],
}

// 微信没有「待申请」状态，所以补充一个「待申请」状态
const defaultApprovalStatus = {id: '0', label: '待申请'}
const defaultApprovalStatusCode = {'0': '待申请'}

export default function SettlementRecord() {
  const history = useAppHistory()
  const approvalStatusList = useCodeArraySync('wework.approvalStatus', {defaultItem: defaultApprovalStatus})
  const approvalStatusCode = useCodeSync('wework.approvalStatus')
  const settlementList = useCodeArraySync('lh.settlement')
  const settlementCode = useCodeSync('lh.settlement')
  // console.log('approvalStatusCode', approvalStatusCode)

  const [auth] = usePermission()

  const userId = userModel.useData(data => data.userInfo?.userId)
  const userName = userModel.useData(data => data.userInfo?.name)

  const [tForm] = Form.useForm()

  const [form] = Form.useForm()
  const [activeKey, setActiveKey] = useState(() => (auth('001081001') ? '1' : '2'))

  const [lhSettlementTotal, setLhSettlementTotal] = useState<number>(0)
  const [lhSettlementList, setLhSettlementList] = useState<LhSettlementVO[]>([])
  const [selectedSettlementList, setSelectedSettlementList] = useState<LhSettlementVO[]>([])

  const [calc, setCalc] = useState<LhSettlementCalcVO>({taxExcludedAmount: 0, taxIncludedAmount: 0})

  const queryLhSettlementList = useCallback(() => {
    const formValues = form.getFieldsValue(true)
    const values = {...formValues, operatorId: activeKey === '1' ? undefined : userId}
    lhSettlementModel.lhSettlementList(values).then(({response: {data, total}}) => {
      setLhSettlementList(data)
      setLhSettlementTotal(total)
      setSelectedSettlementList([])
    })

    lhSettlementModel.lhSettlementCalc(values).then(({response: {data}}) => setCalc(data))
  }, [activeKey, form, userId])

  useEffect(() => {
    queryLhSettlementList()
  }, [queryLhSettlementList])

  const [cityList, setCityList] = useState<CityDataProps[]>([])

  useEffect(() => {
    commonModel
      .queryCityCode({type: '2', parentCode: null})
      .then(({response: {data}}) => setCityList(data.map(item => ({...item, key: item.code}))))
  }, [])

  const handleSearch = useCallback(
    (name: string) => {
      sysUserModel.sysUserList({pageNum: 1, pageSize: 20, pageable: true, name}).then(({response: {data}}) => {
        tForm.setFieldsValue({
          _userList: data.map(item => ({label: `${item.name}-${item.mobile}`, value: item.userId})),
        })
      })
    },
    [tForm]
  )

  const [exportLoading, setExportLoading] = useState(false)

  const listLoading = lhSettlementModel.lhSettlementList.useLoading()

  function invoiceFormRender() {
    return (
      <>
        <Form.Item name={'invoiceTitle'} label={'发票抬头'} rules={[{required: true, message: '请输入发票抬头'}]}>
          <Input placeholder={'请输入'} />
        </Form.Item>
        <Form.Item label={'附件'}>
          <Form.Item
            name={'_imageList1'}
            style={{marginBottom: 0}}
            valuePropName={'fileList'}
            getValueFromEvent={({fileList}) => fileList}
          >
            <Album
              data={{forApproval: true}}
              limit={3}
              action={baseUrl + '/lh/settlement/upload'}
              accept={'.jpg, .jpeg, .png'}
            >
              <UploadButton title={'选择图片'} />
            </Album>
          </Form.Item>
          <span>仅支持上传图片、最多3张，单张最大10M</span>
        </Form.Item>
        <Form.Item name={'invoiceRemark'} label={'备注'}>
          <Input.TextArea placeholder={'请输入'} maxLength={200} showCount />
        </Form.Item>
      </>
    )
  }

  function signetFormRender() {
    return (
      <>
        <Form.Item label={'所在部门'}>运营管理中台</Form.Item>
        <Form.Item name={'sealReason'} label={'用印事由'} rules={[{required: true, message: '请输入用印事由'}]}>
          <Input.TextArea placeholder={'请输入'} maxLength={200} showCount />
        </Form.Item>
        <Form.Item label={'公司名称'}>翊帮人</Form.Item>
        <Form.Item label={'印章类型'}>公章</Form.Item>
        <Form.Item name={'fileName'} label={'文件名称'} rules={[{required: true, message: '请输入文件名称'}]}>
          <Input placeholder={'请输入'} />
        </Form.Item>
        <Form.Item name={'fileCopies'} label={'文件份数'} rules={[{required: true, message: '请输入文件份数'}]}>
          <InputNumber placeholder={'请输入'} />
        </Form.Item>
        <Form.Item
          name={'sealDate'}
          label={'用印日期'}
          getValueFromEvent={(_, formatString) => formatString}
          getValueProps={value => ({value: value ? moment(value) : value})}
          rules={[{required: true, message: '请选择用印日期'}]}
        >
          <DatePicker locale={locale} />
        </Form.Item>
        <Form.Item
          label={'工作量确认单'}
          required
          shouldUpdate={(prevValues, nextValues) => prevValues._fileList !== nextValues._fileList}
        >
          {form1 => {
            const _fileList = form1.getFieldValue('_fileList') ?? []
            return (
              <>
                <Form.Item
                  name={'_fileList'}
                  style={{marginBottom: 0}}
                  valuePropName={'fileList'}
                  getValueFromEvent={({fileList}) => fileList}
                  rules={[{required: true, message: '请上传工作量确认单'}, File_Validator('zip')]}
                >
                  <Album
                    data={{forApproval: true}}
                    limit={1}
                    action={baseUrl + '/lh/settlement/upload'}
                    accept={'.zip'}
                  >
                    {_fileList.length === 0 && <UploadButton title={'选择文件'} />}
                  </Album>
                </Form.Item>
                <span>支持上传压缩文件、最多1个、最大5M</span>
              </>
            )
          }}
        </Form.Item>
        <Form.Item label={'附件'}>
          <Form.Item
            name={'_imageList2'}
            style={{marginBottom: 0}}
            valuePropName={'fileList'}
            getValueFromEvent={({fileList}) => fileList}
          >
            <Album
              data={{forApproval: true}}
              limit={3}
              action={baseUrl + '/lh/settlement/upload'}
              accept={'.jpg, .jpeg, .png'}
            >
              <UploadButton title={'选择图片'} />
            </Album>
          </Form.Item>
          <span>仅支持上传图片、最多3张，单张最大10M</span>
        </Form.Item>
        <Form.Item name={'sealRemark'} label={'备注'}>
          <Input.TextArea placeholder={'请输入'} maxLength={200} showCount />
        </Form.Item>
      </>
    )
  }

  // 用章申请
  function handleSealApproval(settlementIdList: string[]) {
    const instance = Modal.confirm({
      title: '用章申请',
      icon: null,
      centered: true,
      closable: true,
      width: '50%',
      content: (
        <Form form={tForm} labelCol={{flex: '95px'}} labelAlign={'right'}>
          <Form.Item label={'用章明细'}>
            <Button
              type={'link'}
              style={{padding: 0}}
              onClick={() => {
                downloadBlob({
                  url: '/lh/settlement/list-excel',
                  body: {
                    pageNum: 1,
                    pageSize: 1,
                    pageable: false,
                    settlementIdList,
                    excelMode: 3,
                  },
                })
              }}
            >
              用章明细.xlsx
            </Button>
          </Form.Item>
          {signetFormRender()}
        </Form>
      ),
      onOk: async () => {
        try {
          instance.update({okButtonProps: {loading: true}})

          await tForm.validateFields()
          const formValues = tForm.getFieldsValue(true)
          // console.log('formValues', formValues)
          await lhSettlementModel.lhSettlementSealApproval({
            ...formValues,
            company: '翊帮人',
            orgName: '运营管理中台',
            sealType: '公章',
            settlementIdList,
            remark: formValues.sealRemark,
            attachmentList: formValues._imageList2?.map(item => item.response?.data),
            workloadConfirmationList: formValues._fileList?.map(item => item.response?.data),
          })
          message.success('用章申请成功！')
          form.submit()
        } catch (e) {
          return Promise.reject(e)
        } finally {
          instance.update({okButtonProps: {loading: false}})
        }
      },
      afterClose: () => tForm.resetFields(),
    })
  }

  // 开票申请
  function handleInvoiceApproval(settlementIdList: string[]) {
    const instance = Modal.confirm({
      title: '开票申请',
      icon: null,
      centered: true,
      width: '50%',
      content: (
        <Form form={tForm} labelCol={{flex: '100px'}} labelAlign={'right'}>
          <Form.Item label={'开票明细'}>
            <Button
              type={'link'}
              style={{padding: 0}}
              onClick={() => {
                downloadBlob({
                  url: '/lh/settlement/list-excel',
                  body: {
                    pageNum: 1,
                    pageSize: 1,
                    pageable: false,
                    settlementIdList,
                    excelMode: 2,
                  },
                })
              }}
            >
              开票明细.xlsx
            </Button>
          </Form.Item>
          {invoiceFormRender()}
        </Form>
      ),
      onOk: async () => {
        try {
          instance.update({okButtonProps: {loading: true}})

          await tForm.validateFields()
          const {invoiceTitle, invoiceRemark, _imageList1} = tForm.getFieldsValue(true)
          await lhSettlementModel.lhSettlementInvoiceApproval({
            invoiceTitle,
            settlementIdList,
            remark: invoiceRemark,
            attachmentList: _imageList1?.map(item => item.response?.data),
          })
          message.success('开票申请成功！')
          form.submit()
        } catch (e) {
          return Promise.reject(e)
        } finally {
          instance.update({okButtonProps: {loading: false}})
        }
      },
      afterClose: () => tForm.resetFields(),
    })
  }

  return (
    <Page
      breadcrumb={
        <Breadcrumb
          right={
            <Space>
              {auth('011081002') && activeKey === '1' && (
                <Button
                  onClick={() => {
                    Modal.confirm({
                      title: '导入发票号',
                      icon: null,
                      centered: true,
                      width: '50%',
                      closable: true,
                      content: (
                        <Form form={tForm} labelCol={{flex: '100px'}} labelAlign={'right'}>
                          <Form.Item label={'下载导入模版'}>
                            <Button
                              type={'link'}
                              style={{padding: 0}}
                              onClick={() => {
                                downloadBlob({
                                  url: '/lh/settlement/list-excel',
                                  body: {pageNum: 1, pageSize: 1, pageable: false, settlementIdList: [], excelMode: 2},
                                })
                              }}
                            >
                              发票模板.xlsx
                            </Button>
                          </Form.Item>
                          <Form.Item noStyle shouldUpdate>
                            {form1 => {
                              const attachmentUrlList = form1.getFieldValue('attachmentUrlList') ?? []
                              return (
                                <Form.Item label={'附件'} required>
                                  <Form.Item
                                    name={'attachmentUrlList'}
                                    valuePropName={'fileList'}
                                    getValueFromEvent={({fileList}) => fileList}
                                    style={{marginBottom: 0}}
                                    rules={[{required: true, message: '请上传附件'}, File_Validator('xlsx')]}
                                  >
                                    <Album
                                      limit={1}
                                      action={baseUrl + '/lh/settlement/invoice/import'}
                                      accept={'.xlsx'}
                                      customRequest={options => {
                                        tForm.setFieldsValue({
                                          _fileOptions: options,
                                          attachmentUrlList: attachmentUrlList.map(value => ({
                                            ...value,
                                            status: 'success',
                                          })),
                                        })
                                      }}
                                    >
                                      {attachmentUrlList.length === 0 && <UploadButton title={'上传附件'} />}
                                    </Album>
                                  </Form.Item>
                                  <span>仅支持上传表格文件，最多1个，单张最大10M</span>
                                </Form.Item>
                              )
                            }}
                          </Form.Item>
                        </Form>
                      ),
                      onOk: async () => {
                        await tForm.validateFields()
                        const formValues = tForm.getFieldsValue(true)
                        uploadExcelData({
                          info: formValues._fileOptions,
                          uri: '/lh/settlement/invoice/import',
                          onSuccess: () => form.submit(),
                          onError: () => {
                            // 上传失败的话，清空重新上传
                            tForm.setFieldsValue({_settlementList: []})
                          },
                        })
                      },
                      afterClose: () => tForm.resetFields(),
                    })
                  }}
                >
                  导入发票号
                </Button>
              )}

              {(auth('021081003') || activeKey === '2') && (
                <Button
                  onClick={() => {
                    if (selectedSettlementList.length === 0) return message.warning('请选择结算单')

                    const instance = Modal.confirm({
                      title: '结算终止',
                      icon: null,
                      centered: true,
                      width: '50%',
                      content: (
                        <Form form={tForm} labelAlign={'right'}>
                          <Form.Item>
                            <span style={{color: 'red'}}>导入发票号的结算记录已生成账单，请及时处理</span>
                          </Form.Item>
                          <Form.Item label={'附件'}>
                            <Form.Item
                              name={'_imageList'}
                              style={{marginBottom: 0}}
                              valuePropName={'fileList'}
                              getValueFromEvent={({fileList}) => fileList}
                            >
                              <Album limit={3} action={baseUrl + '/lh/settlement/upload'} accept={'.jpg, .jpeg, .png'}>
                                <UploadButton title={'上传照片'} />
                              </Album>
                            </Form.Item>
                            <span>仅支持上传图片，最多3张，单张最大10M</span>
                          </Form.Item>
                          <Form.Item name={'remark'} label={'备注'} rules={[{required: true, message: '请输入备注'}]}>
                            <Input.TextArea placeholder={'请输入'} maxLength={200} showCount />
                          </Form.Item>
                        </Form>
                      ),
                      onOk: async () => {
                        try {
                          instance.update({okButtonProps: {loading: true}})

                          await tForm.validateFields()
                          const {remark, _imageList} = tForm.getFieldsValue(true)
                          await lhSettlementModel.lhSettlementFollow({
                            remark,
                            followStatus: '11',
                            pictureUrlList: _imageList?.map(item => item.response?.data?.imgUrl),
                            settlementIdList: selectedSettlementList.map(item => item.settlementId),
                          })
                          message.success('批量结算终止操作成功！')
                          form.submit()
                        } catch (e) {
                          return Promise.reject(e)
                        } finally {
                          instance.update({okButtonProps: {loading: false}})
                        }
                      },
                      afterClose: () => tForm.resetFields(),
                    })
                  }}
                >
                  批量结算终止
                </Button>
              )}

              {(auth('003081004') || activeKey === '2') && (
                <Button
                  onClick={() => {
                    if (selectedSettlementList.length === 0) return message.warning('请选择结算单')
                    if (selectedSettlementList.some(item => ['1', '2'].includes(item.sealStatus)))
                      return message.warning('请选择非审批中且非审批通过的结算单')
                    handleSealApproval(selectedSettlementList.map(item => item.settlementId))
                  }}
                >
                  批量用章
                </Button>
              )}

              {(auth('003081005') || activeKey === '2') && (
                <Button
                  onClick={() => {
                    if (selectedSettlementList.length === 0) return message.warning('请选择结算单')
                    if (selectedSettlementList.some(item => ['1', '2'].includes(item.invoiceStatus)))
                      return message.warning('请选择非审批中且非审批通过的结算单')
                    handleInvoiceApproval(selectedSettlementList.map(item => item.settlementId))
                  }}
                >
                  批量开票
                </Button>
              )}

              {(auth('003081006') || activeKey === '2') && (
                <Button
                  onClick={() => {
                    // if (selectedSettlementList.length === 0) return message.warning('请选择结算单')

                    const instance = Modal.confirm({
                      title: '新增结算',
                      icon: null,
                      centered: true,
                      closable: true,
                      width: '50%',
                      content: (
                        <div style={{height: '500px', overflowY: 'scroll'}}>
                          <Form form={tForm} labelCol={{flex: '100px'}} labelAlign={'right'}>
                            <Form.Item label={'导入模板'}>
                              <Button type={'link'}>
                                <a href={baseUrl + '/settlement_import.xlsx'} download>
                                  下载导入模板
                                </a>
                              </Button>
                            </Form.Item>
                            <Form.Item
                              noStyle
                              shouldUpdate={(prevValues, nextValues) =>
                                prevValues._settlementList !== nextValues._settlementList
                              }
                            >
                              {form1 => {
                                const _settlementList = form1.getFieldValue('_settlementList') ?? []
                                return (
                                  <Form.Item
                                    name={'_settlementList'}
                                    valuePropName={'fileList'}
                                    getValueFromEvent={({fileList}) => fileList}
                                    label={'结算信息'}
                                    rules={[{required: true, message: '请上传结算信息'}, File_Validator('xlsx')]}
                                  >
                                    <Album
                                      limit={1}
                                      action={baseUrl + '/lh/settlement/invoice/import'}
                                      accept={'.xlsx'}
                                      customRequest={options => {
                                        tForm.setFieldsValue({
                                          _fileOptions: options,
                                          _settlementList: _settlementList.map(value => ({
                                            ...value,
                                            status: 'success',
                                          })),
                                        })
                                      }}
                                    >
                                      {_settlementList.length === 0 && <UploadButton title={' '} />}
                                    </Album>
                                  </Form.Item>
                                )
                              }}
                            </Form.Item>
                            <Form.Item label={'提交审批'} name={'approvalType'}>
                              <Checkbox.Group>
                                <Checkbox value={'1'}>开票</Checkbox>
                                <Checkbox value={'2'}>用章</Checkbox>
                              </Checkbox.Group>
                            </Form.Item>
                            <Form.Item
                              noStyle
                              shouldUpdate={(prevValues, nextValues) =>
                                prevValues.approvalType !== nextValues.approvalType
                              }
                            >
                              {form1 => {
                                const approvalType = form1.getFieldValue('approvalType') ?? []
                                return (
                                  <>
                                    {approvalType.includes('1') && (
                                      <>
                                        <DividerLine title={'开票申请信息'} bottom={15} />
                                        {invoiceFormRender()}
                                      </>
                                    )}
                                    {approvalType.includes('2') && (
                                      <>
                                        <DividerLine title={'用章申请信息'} bottom={15} />
                                        {signetFormRender()}
                                      </>
                                    )}
                                  </>
                                )
                              }}
                            </Form.Item>
                          </Form>
                        </div>
                      ),
                      onOk: async () => {
                        try {
                          instance.update({okButtonProps: {loading: true}})

                          await tForm.validateFields()
                          const formValues = tForm.getFieldsValue(true)

                          uploadExcelData({
                            info: formValues._fileOptions,
                            uri: '/lh/settlement/import',
                            onSuccess: async value => {
                              // console.log('formValues', formValues)
                              if (formValues.approvalType?.includes('1')) {
                                await lhSettlementModel.lhSettlementInvoiceApproval({
                                  ...formValues,
                                  settlementIdList: value,
                                  remark: formValues.invoiceRemark,
                                  attachmentList: formValues._imageList1?.map(item => item.response?.data),
                                })
                              }
                              if (formValues.approvalType?.includes('2')) {
                                await lhSettlementModel.lhSettlementSealApproval({
                                  ...formValues,
                                  company: '翊帮人',
                                  orgName: '运营管理中台',
                                  sealType: '公章',
                                  settlementIdList: value,
                                  remark: formValues.sealRemark,
                                  attachmentList: formValues._imageList2?.map(item => item.response?.data),
                                  workloadConfirmationList: formValues._fileList?.map(item => item.response?.data),
                                })
                              }

                              form.submit()
                            },
                            onError: () => {
                              // 上传失败的话，清空重新上传
                              tForm.setFieldsValue({_settlementList: []})
                            },
                          })
                        } catch (e) {
                          return Promise.reject(e)
                        } finally {
                          instance.update({okButtonProps: {loading: false}})
                        }
                      },
                      afterClose: () => tForm.resetFields(),
                    })
                  }}
                >
                  新增结算
                </Button>
              )}

              {(auth('007081007') || activeKey === '2') && (
                <Button
                  loading={exportLoading}
                  onClick={() => {
                    setExportLoading(true)
                    const formValues = form.getFieldsValue(true)
                    try {
                      downloadBlob({
                        url: '/lh/settlement/list-excel',
                        body: {...formValues, excelMode: 1},
                      })
                    } finally {
                      setExportLoading(false)
                    }
                  }}
                >
                  导出
                </Button>
              )}
            </Space>
          }
        />
      }
    >
      <Tabs className={commonStyles.tabs} activeKey={activeKey} onChange={key => setActiveKey(key)}>
        {auth('001081001') && <Tabs.TabPane tab={'全部结算'} key={'1'} />}
        {auth('001081002') && <Tabs.TabPane tab={'我提交的'} key={'2'} />}
      </Tabs>

      <div className={commonStyles.filterSection}>
        <Form
          form={form}
          onFinish={queryLhSettlementList}
          onReset={queryLhSettlementList}
          initialValues={{
            pageNum: 1,
            pageSize: 10,
            pageable: true,
            settlementStatusList: settlementList
              .filter(value => !['10', '11'].includes(value.id))
              .map(value => value.id),
          }}
        >
          <Row gutter={20}>
            <Col span={8}>
              <Form.Item label={'结算编号'} name={'settlementId'}>
                <Input placeholder={'请输入'} allowClear />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item label={'批次号'} name={'batchId'}>
                <Input placeholder={'请输入'} allowClear />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item label={'区域'} name={'region'}>
                <Input placeholder={'请输入'} allowClear />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item label={'城市'} name={'city'}>
                <Select
                  options={cityList.map(item => ({label: item.name, value: item.name}))}
                  placeholder={'请选择'}
                  showSearch
                  filterOption={(inputValue, option: any) => {
                    return option.label?.indexOf(inputValue) > -1
                  }}
                  allowClear
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item label={'门店'} name={'condoName'}>
                <Input placeholder={'请输入'} allowClear />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item label={'费用类型'} name={'feeType'}>
                <Select
                  options={[
                    {label: '包干翻修费用', value: '包干翻修费用'},
                    {label: '包干运营费用', value: '包干运营费用'},
                  ]}
                  placeholder={'请选择'}
                  allowClear
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item label={'开票审批状态'} name={'invoiceStatus'}>
                <Select options={approvalStatusList} fieldNames={{value: 'id'}} placeholder={'请选择'} allowClear />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item label={'用章审批状态'} name={'sealStatus'}>
                <Select options={approvalStatusList} fieldNames={{value: 'id'}} placeholder={'请选择'} allowClear />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item label={'结算状态'} name={'settlementStatusList'}>
                <Select
                  mode={'multiple'}
                  options={settlementList}
                  fieldNames={{value: 'id'}}
                  placeholder={'请选择'}
                  allowClear
                  maxTagCount={2}
                  showArrow
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label={'应收款日'}
                name={'receivableDateBegin'}
                getValueProps={value => ({
                  value: value ? [moment(value), moment(form.getFieldValue('receivableDateEnd'))] : value,
                })}
                getValueFromEvent={value => {
                  form.setFieldsValue({receivableDateEnd: value?.[1]?.format(FORMAT_DATE_END)})
                  return value?.[0]?.format(FORMAT_DATE_START)
                }}
              >
                <DatePicker.RangePicker style={{width: '100%'}} />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label={'最新跟进日期'}
                name={'followDateBegin'}
                getValueProps={value => ({
                  value: value ? [moment(value), moment(form.getFieldValue('followDateEnd'))] : value,
                })}
                getValueFromEvent={value => {
                  form.setFieldsValue({followDateEnd: value?.[1]?.format(FORMAT_DATE_END)})
                  return value?.[0]?.format(FORMAT_DATE_START)
                }}
              >
                <DatePicker.RangePicker style={{width: '100%'}} />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item label={'创建人'} name={'operatorName'}>
                <Input placeholder={'请输入'} allowClear />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label={'创建日期'}
                name={'insertTimeBegin'}
                getValueProps={value => ({
                  value: value ? [moment(value), moment(form.getFieldValue('insertTimeEnd'))] : value,
                })}
                getValueFromEvent={value => {
                  form.setFieldsValue({insertTimeEnd: value?.[1]?.format(FORMAT_DATE_END)})
                  return value?.[0]?.format(FORMAT_DATE_START)
                }}
              >
                <DatePicker.RangePicker style={{width: '100%'}} />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item label={'发票号'} name={'invoiceNumber'}>
                <Input placeholder={'请输入'} allowClear />
              </Form.Item>
            </Col>
          </Row>

          <Row justify={'end'}>
            <Space>
              <Button type={'primary'} htmlType={'submit'} onClick={() => form.setFieldsValue({pageNum: 1})}>
                查询
              </Button>
              <Button htmlType={'reset'}>重置</Button>
            </Space>
          </Row>
        </Form>
      </div>

      <div className={commonStyles.tableSection}>
        <div style={{marginBottom: 15}}>
          <span style={{fontWeight: 'bold'}}>合计：</span>
          {`含税金额：${calc.taxIncludedAmount}元，不含税金额：${calc.taxExcludedAmount}元`}
        </div>
        <Table
          // title={() => <span>{`含税金额：${calc.taxIncludedAmount}元，不含税金额：${calc.taxExcludedAmount}元`}</span>}
          rowKey={'settlementId'}
          dataSource={lhSettlementList}
          loading={listLoading}
          scroll={{x: 'max-content'}}
          rowSelection={{
            fixed: true,
            selectedRowKeys: selectedSettlementList.map(item => item.settlementId),
            onChange: (_, selectedRows) => setSelectedSettlementList(selectedRows),
          }}
          columns={[
            {title: '区域', dataIndex: 'region'},
            {title: '城市', dataIndex: 'city'},
            {title: '门店', dataIndex: 'condoName'},
            {title: '期数', dataIndex: 'period'},
            {title: '费用类型', dataIndex: 'feeType'},
            {title: '含税金额', dataIndex: 'taxIncludedAmount'},
            {title: '不含税金额', dataIndex: 'taxExcludedAmount'},
            {
              title: '结算周期起',
              dataIndex: 'settlementPeriodStart',
              render: value => moment(value).format(FORMAT_DATE),
            },
            {title: '结算周期止', dataIndex: 'settlementPeriodEnd', render: value => moment(value).format(FORMAT_DATE)},
            {title: '应收款日', dataIndex: 'receivableDate', render: value => moment(value).format(FORMAT_DATE)},
            {title: '最后付款日', dataIndex: 'lastPaymentDate', render: value => moment(value).format(FORMAT_DATE)},
            {
              title: '开票审批状态',
              dataIndex: 'invoiceStatus',
              render: invoiceStatus => approvalStatusCode[invoiceStatus] ?? defaultApprovalStatusCode[invoiceStatus],
            },
            {
              title: '用章审批状态',
              dataIndex: 'sealStatus',
              render: sealStatus => approvalStatusCode[sealStatus] ?? defaultApprovalStatusCode[sealStatus],
            },
            {
              title: '结算状态',
              dataIndex: 'settlementStatus',
              render: settlementStatus => settlementCode[settlementStatus],
            },
            {title: '创建人', dataIndex: 'operatorName'},
            {title: '创建时间', dataIndex: 'insertTime'},
            {title: '跟进时间', dataIndex: 'followDate'},
            {title: '结算编号', dataIndex: 'settlementId'},
            {title: '批次号', dataIndex: 'batchId'},
            {title: '发票号', dataIndex: 'invoiceNumber'},
            {
              title: '操作',
              dataIndex: 'settlementId',
              fixed: 'right',
              render: (settlementId, record) => {
                return (
                  <Space>
                    {(auth('001081008') || activeKey === '2') && (
                      <Button
                        type={'link'}
                        style={{padding: 0}}
                        onClick={() => {
                          history.push('/settlement-detail', {settlementId})
                        }}
                      >
                        查看详情
                      </Button>
                    )}

                    {(auth('003081005') || activeKey === '2') && ['0', '3', '4'].includes(record.invoiceStatus) && (
                      <Button
                        type={'link'}
                        style={{padding: 0}}
                        onClick={() => {
                          handleInvoiceApproval([settlementId])
                        }}
                      >
                        开票申请
                      </Button>
                    )}

                    {(auth('003081004') || activeKey === '2') && ['0', '3', '4'].includes(record.sealStatus) && (
                      <Button
                        type={'link'}
                        style={{padding: 0}}
                        onClick={() => {
                          handleSealApproval([settlementId])
                        }}
                      >
                        用章申请
                      </Button>
                    )}

                    {(auth('003081009') || activeKey === '2') && !['01', '10', '11'].includes(record.settlementStatus) && (
                      <Button
                        type={'link'}
                        style={{padding: 0}}
                        onClick={() => {
                          const followOptions = followOptionCode[record.settlementStatus]
                          handleSearch(userName)
                          form.setFieldsValue({userId})

                          Modal.confirm({
                            title: '跟进',
                            icon: null,
                            centered: true,
                            closable: true,
                            width: '50%',
                            content: (
                              <Form form={tForm} labelAlign={'right'} labelCol={{flex: '95px'}}>
                                <Form.Item
                                  name={'followStatus'}
                                  label={'跟进状态'}
                                  rules={[{required: true, message: '请选择跟进状态'}]}
                                >
                                  <Select
                                    placeholder={'请选择'}
                                    options={settlementList.filter(value => followOptions.includes(value.id))}
                                    fieldNames={{value: 'id'}}
                                  />
                                </Form.Item>
                                <Form.Item
                                  noStyle
                                  shouldUpdate={(prevValues, nextValues) =>
                                    prevValues._userList !== nextValues._userList
                                  }
                                >
                                  {form1 => {
                                    const _userList = form1.getFieldValue('_userList') ?? []
                                    return (
                                      <Form.Item
                                        name={'userId'}
                                        label={'实际跟进人'}
                                        rules={[{required: true, message: '请选择实际跟进人'}]}
                                      >
                                        <Select
                                          placeholder={'请选择'}
                                          showSearch
                                          filterOption={false}
                                          onSearch={handleSearch}
                                          options={_userList}
                                          onChange={(_, option) => {
                                            if (!Array.isArray(option)) {
                                              tForm.setFieldsValue({
                                                userName:
                                                  typeof option.label === 'string'
                                                    ? option.label?.split('-')?.[0]
                                                    : undefined,
                                              })
                                            }
                                          }}
                                        />
                                      </Form.Item>
                                    )
                                  }}
                                </Form.Item>

                                <Form.Item label={'跟进凭证'} required>
                                  <span>图片和录音必填其中一项</span>
                                </Form.Item>
                                <Form.Item
                                  noStyle
                                  shouldUpdate={(prevValues, nextValues) =>
                                    prevValues._imageList !== nextValues._imageList ||
                                    prevValues._voiceList !== nextValues._voiceList
                                  }
                                >
                                  {form1 => {
                                    const _imageList = form1.getFieldValue('_imageList') ?? []
                                    const _voiceList = form1.getFieldValue('_voiceList') ?? []

                                    return (
                                      <>
                                        <Form.Item label={'图片'}>
                                          <Form.Item
                                            name={'_imageList'}
                                            style={{marginBottom: 0}}
                                            valuePropName={'fileList'}
                                            getValueFromEvent={({fileList}) => fileList}
                                            rules={[
                                              {
                                                required: _imageList.length === 0 && _voiceList.length === 0,
                                                message: '请上传图片',
                                              },
                                            ]}
                                          >
                                            <Album
                                              limit={3}
                                              action={baseUrl + '/lh/settlement/upload'}
                                              accept={'.jpg, .jpeg, .png'}
                                            >
                                              {_imageList.length < 3 && <UploadButton title={'上传照片'} />}
                                            </Album>
                                          </Form.Item>
                                          <span>仅支持上传图片，最多3张，单张最大10M</span>
                                        </Form.Item>

                                        <Form.Item label={'录音'}>
                                          <Form.Item
                                            name={'_voiceList'}
                                            valuePropName={'fileList'}
                                            getValueFromEvent={({fileList}) => fileList}
                                            style={{marginBottom: 0}}
                                            rules={[
                                              {
                                                required: _imageList.length === 0 && _voiceList.length === 0,
                                                message: '请上传录音',
                                              },
                                              File_Validator('mp3'),
                                            ]}
                                          >
                                            <Album limit={3} action={baseUrl + '/lh/settlement/upload'} accept={'.mp3'}>
                                              {_voiceList.length < 3 && <UploadButton title={'上传录音'} />}
                                            </Album>
                                          </Form.Item>
                                          <span>仅支持上传.mp3录音文件，最多3个，单个最大5M</span>
                                        </Form.Item>
                                      </>
                                    )
                                  }}
                                </Form.Item>
                                <Form.Item
                                  noStyle
                                  shouldUpdate={(prevValues, nextValues) =>
                                    prevValues.followStatus !== nextValues.followStatus
                                  }
                                >
                                  {form1 => {
                                    const followStatus = form1.getFieldValue('followStatus')
                                    return (
                                      <Form.Item label={'物流单号'}>
                                        <Form.Item
                                          name={'logisticsNumber'}
                                          rules={[
                                            {
                                              required: ['05', '06', '07'].includes(followStatus),
                                              message: '请输入物流单号',
                                            },
                                          ]}
                                        >
                                          <Input placeholder={'请输入'} />
                                        </Form.Item>
                                        <span>跟进状态转到【纸质档待接收】时必填</span>
                                      </Form.Item>
                                    )
                                  }}
                                </Form.Item>

                                <Form.Item name={'remark'} label={'备注'}>
                                  <Input.TextArea placeholder={'请输入'} maxLength={200} showCount />
                                </Form.Item>
                              </Form>
                            ),
                            onOk: async () => {
                              await tForm.validateFields()
                              const formValues = tForm.getFieldsValue(true)
                              // console.log('formValues', formValues)
                              await lhSettlementModel.lhSettlementFollow({
                                ...formValues,
                                settlementIdList: [settlementId],
                                recordingUrlList: formValues._voiceList?.map(item => item?.response?.data?.imgUrl),
                                pictureUrlList: formValues._imageList?.map(item => item?.response?.data?.imgUrl),
                              })
                              message.success('跟进成功！')
                              form.submit()
                            },
                            afterClose: () => tForm.resetFields(),
                          })
                        }}
                      >
                        跟进
                      </Button>
                    )}
                  </Space>
                )
              },
            },
          ]}
          pagination={getPaginationProps(form, lhSettlementTotal)}
        />
      </div>
    </Page>
  )
}
