import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {Mapview} from '@/components/Map'
import {Marker} from '@/components/Map/Marker'
import {AutoComplete, Divider, Space, Input, Modal, Form, InputNumber, message} from 'antd'
import styles from './index.module.less'
import ruler from '@/assets/icons/ruler.svg'
import {PlusOutlined} from '@ant-design/icons'
import {propertyModel} from '@/store/models/Property'
import {storageModel} from '@/store/models/Storage'
import {sysModel} from '@/store/models/Sys'

// 显示商圈或者小区
const targetZoom = 16

const PropertyMap = () => {
  const mapRef = useRef<Mapview>()
  const [form] = Form.useForm()
  const [addCommunityVisible, setAddCommunityVisible] = useState(false)
  const [communityList, setCommunityList] = useState<CommunityVO[]>([])

  const [zoom, setZoom] = useState(targetZoom)
  // 用户新增的小区列表
  const [customCommunityList, setCustomCommunityList] = useState<(PropertyMapCommunityVO & {offset?: boolean})[]>([])
  // 搜索框小区列表
  const [communitySearchList, setCommunitySearchList] = useState<PropertyMapCommunityVO[]>([])
  // 地图上的小区列表
  const [communityMapList, setCommunityMapList] = useState<PropertyMapCommunityVO[]>([])
  // 仓库列表
  const [warehouseList, setWarehouseList] = useState<StewardWarehouseListItemVO[]>([])
  // 商圈
  const [bizCircle, setBizCircle] = useState<PropertyMapBizCircleVO[]>([])

  useEffect(() => {
    Promise.all([
      storageModel.stewardWarehouseList({pageable: false, status: '1'}),
      propertyModel.propertyBizCircleMap(),
    ]).then(([{response: res1}, {response: res2}]) => {
      setWarehouseList(res1.data)
      setBizCircle(res2.data)
    })
  }, [])

  const options = useMemo(
    () =>
      communitySearchList.map(value => ({
        label: (
          <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <span>{value.name}</span>
            <span>{value.num}套</span>
          </div>
        ),
        value: value.name, // value.code,
        lat: value.lat,
        lng: value.lng,
      })),
    [communitySearchList]
  )

  const navToCommunity = useCallback((lng: number, lat: number) => {
    // 只+0.5，尽量少显示地理信息，提升流畅度
    mapRef.current?.panTo(lng, lat, targetZoom + 0.5)
  }, [])

  return (
    <>
      <div style={{height: '100%', position: 'relative'}}>
        <Mapview
          ref={mapRef}
          onMapChange={event => {
            setZoom(event.zoom)
            if (event.zoom > targetZoom) {
              const {ne, sw} = mapRef.current?.mapRef?.getBounds()
              propertyModel
                .propertyCommunityMap({lat1: ne.lat, lng1: ne.lng, lat2: sw.lat, lng2: sw.lng})
                .then(({response: {data}}) => setCommunityMapList(data))
            }
          }}
        >
          {zoom <= targetZoom
            ? // 商圈
              bizCircle.map(value => (
                <Marker
                  key={`${value.code}-${value.num}`}
                  lat={value.lat}
                  lng={value.lng}
                  onClick={() => navToCommunity(value.lng, value.lat)}
                >
                  <div className={styles.round}>
                    <span>{value.name}</span>
                    <span>{value.num}套</span>
                  </div>
                </Marker>
              ))
            : // 小区
              communityMapList.map(value => (
                <Marker
                  key={`${value.code}-${value.num}`}
                  lat={value.lat}
                  lng={value.lng}
                  onClick={() => {
                    navigator.clipboard.writeText(value.name)
                    message.success('小区名称已复制')
                  }}
                >
                  <div className={styles.rect}>
                    <span>{value.name}</span>
                    <Divider type={'vertical'} />
                    <span>{value.num}套</span>
                  </div>
                </Marker>
              ))}

          {warehouseList.map(value => (
            // 站点
            <Marker key={value.warehouseId} lat={value.lat} lng={value.lng}>
              <div className={`${styles.rect} ${styles.rectOrange}`}>
                <span>{value.name}</span>
              </div>
            </Marker>
          ))}

          {customCommunityList.map(value => (
            <Marker
              key={`${value.code}-${value.num}`}
              lat={value.lat}
              lng={value.lng}
              onClick={() => {
                Modal.confirm({
                  icon: null,
                  content: `确认删除：${value.name}`,
                  onOk: () => setCustomCommunityList(prevState => prevState.filter(item => item.code !== value.code)),
                })
              }}
            >
              <div className={`${styles.rect} ${styles.rectRed}`} style={value.offset ? {top: 36} : {}}>
                <span>{value.name}</span>
                <Divider type={'vertical'} />
                <span>{value.num}套</span>
              </div>
            </Marker>
          ))}
        </Mapview>

        <div className={styles.search}>
          <AutoComplete
            dropdownMatchSelectWidth={252}
            className={styles.searchInput}
            options={options}
            onSearch={value => {
              propertyModel
                .propertyCommunityMapLocation({keyword: value})
                .then(({response: {data}}) => setCommunitySearchList(data))
            }}
            onSelect={(value, option) => mapRef.current?.panTo(option.lng, option.lat, targetZoom + 3)}
            // filterOption={(inputValue, option) => option?.value.includes(inputValue)}
          >
            <Input.Search allowClear size='large' placeholder='请输入搜索小区的名称' enterButton />
          </AutoComplete>
        </div>
        <div className={styles.handle}>
          <Space size={3} style={{cursor: 'pointer'}}>
            <img src={ruler} width={15} height={15} alt={'测量距离'} />
            <span onClick={() => mapRef.current?.distanceTool.open()}>测量距离</span>
          </Space>
          <Divider type={'vertical'} />
          <Space size={3} style={{cursor: 'pointer'}}>
            <PlusOutlined />
            <span onClick={() => setAddCommunityVisible(true)}>新增小区</span>
          </Space>
        </div>
      </div>

      <Modal
        title={'新增小区'}
        open={addCommunityVisible}
        onCancel={() => setAddCommunityVisible(false)}
        onOk={() => form.submit()}
        afterClose={() => form.resetFields()}
        destroyOnClose
      >
        <Form
          form={form}
          labelCol={{span: 4}}
          onFinish={async () => {
            const vo = form.getFieldsValue(true) as CommunityVO & {num: number}
            setAddCommunityVisible(false)
            setCustomCommunityList(prevState => [
              ...prevState.filter(value => value.code !== vo.code),
              {...vo, offset: true},
            ])
            navToCommunity(vo.lng, vo.lat)
          }}
        >
          <Form.Item label={'小区名称'} name={'name'}>
            <AutoComplete
              autoFocus
              allowClear
              placeholder={'请输入'}
              options={communityList.map(value => ({value: value.name, data: value}))}
              onSelect={(value, option) => {
                const vo: CommunityVO = option.data
                form.setFieldsValue({...vo, communityCode: vo.code, type: {'1': '住宅区', '2': '酒店,公寓'}[vo.type]})
              }}
              onSearch={value => {
                if (value) {
                  sysModel
                    .communityList({name: value, pageable: false})
                    .then(({response: {data}}) => setCommunityList(data))
                } else {
                  setCommunityList([])
                }
              }}
            />
          </Form.Item>
          <Form.Item label={'区域'}>
            <Space>
              <Form.Item noStyle name={'districtName'}>
                <Input disabled />
              </Form.Item>
              <Form.Item noStyle name={'bizCircleName'}>
                <Input disabled />
              </Form.Item>
            </Space>
          </Form.Item>
          <Form.Item
            label={'房源数'}
            name={'num'}
            rules={[
              {required: true, message: '房源数不能为空'},
              {type: 'number', min: 1, message: '房源数量不正确'},
            ]}
          >
            <InputNumber min={1} placeholder={'请输入'} />
          </Form.Item>
          <Form.Item label={'小区地址'} name={'address'} rules={[{required: true, message: '地址不能为空'}]}>
            <Input disabled />
          </Form.Item>
          <Form.Item label={'纬度'} name={'lat'}>
            <Input disabled />
          </Form.Item>
          <Form.Item label={'经度'} name={'lng'}>
            <Input disabled />
          </Form.Item>
          <Form.Item label={'房源类型'} name={'type'}>
            <Input disabled />
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export default PropertyMap
