import React, {useEffect, useState} from 'react'
import {Breadcrumb, Button, Form, InputNumber, Select, Space} from 'antd'
import {PlusCircleTwoTone, CloseCircleTwoTone} from '@ant-design/icons'
import styles from './index.module.less'
import {useHistory} from 'react-router-dom'
import {storageModel} from '@/store/models/Storage'

const {Option} = Select

export default function StorageInput() {
  const history = useHistory()

  // 站点列表
  const [wareList, setWareList] = useState<StewardWarehouseListItemVO[]>([])
  // 耗材列表
  const [materialList, setMaterialList] = useState<StewardMaterialListVO[]>([])
  const [form] = Form.useForm()

  const materialLoading = storageModel.stewardWarehouseMaterialList.useLoading()
  const submitLoading = storageModel.stewardWarehousePurchase.useLoading()

  // 默认一项
  useEffect(function () {
    form.setFieldsValue({materialList: [{materialId: undefined, num: undefined}]})
  }, [])

  // 站点列表
  useEffect(function () {
    storageModel.stewardWarehouseList({pageable: false, pageNum: 1, pageSize: 100}).then(({response: {data}}) => {
      // console.log('站点 list =>', data)
      setWareList(data)
    })
  }, [])

  // 耗材列表
  function onMaterialSearch(value) {
    // console.log('value =>', value)
    storageModel
      .stewardWarehouseMaterialList({pageable: false, pageNum: 1, pageSize: 100, name: value})
      .then(({response: {data}}) => {
        // console.log('material data =>', data)
        setMaterialList(data)
      })
  }

  // 提交
  async function onSubmit() {
    const params = form.getFieldsValue(true) as StewardWarehousePurchaseDTO
    // console.log('params ', params)
    await storageModel.stewardWarehousePurchase(params)
    history.goBack()
  }

  return (
    <div style={{margin: '20px 24px'}}>
      <Breadcrumb separator='/' style={{margin: '20px 10px 44px'}}>
        <Breadcrumb.Item>仓储管理</Breadcrumb.Item>
        <Breadcrumb.Item>入仓</Breadcrumb.Item>
      </Breadcrumb>

      <Form form={form} labelCol={{span: 2}} colon={false} onFinish={onSubmit}>
        <Form.Item
          label={'站点名称'}
          name={'warehouseId'}
          labelCol={{span: 5}}
          rules={[{required: true, message: '请输入站点名称'}]}
          className={styles.itemName}
        >
          <Select placeholder={'请输入站点名称'} allowClear>
            {wareList?.map(item => (
              <Option value={item.warehouseId} key={item.warehouseId}>
                {item.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label={'耗材'} required={true}>
          <Form.List name='materialList'>
            {(fields, {add, remove}) => {
              return (
                <div>
                  {fields.map((field, index) => (
                    <Space key={field.key} style={{display: 'flex'}} direction={'vertical'}>
                      <Space align='start'>
                        <Form.Item
                          {...field}
                          name={[field.name, 'materialId']}
                          fieldKey={[field.fieldKey, 'materialId']}
                          rules={[{required: true, message: '请输入耗材sku/名称'}]}
                          className={styles.itemList}
                          shouldUpdate
                        >
                          <Select
                            placeholder='请输入耗材sku/名称并选择'
                            showSearch
                            onSearch={onMaterialSearch}
                            loading={materialLoading}
                            filterOption={false}
                            allowClear
                          >
                            {materialList?.map(item => (
                              <Option value={item.materialId} key={item.materialId}>
                                {item.name}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                        <Form.Item
                          {...field}
                          name={[field.name, 'num']}
                          fieldKey={[field.fieldKey, 'num']}
                          rules={[{required: true, message: '请输入入仓数量'}]}
                          className={styles.itemList}
                        >
                          <InputNumber style={{minWidth: 200}} placeholder='请输入入仓数量' min={1} />
                        </Form.Item>

                        {fields.length > 1 ? (
                          <>
                            <CloseCircleTwoTone
                              className={styles.icon}
                              twoToneColor='#A8A7AA'
                              onClick={() => {
                                remove(field.name)
                              }}
                            />
                            {fields.length - index === 1 && (
                              <PlusCircleTwoTone
                                className={styles.icon}
                                twoToneColor='#00B7AE'
                                onClick={() => {
                                  add()
                                }}
                              />
                            )}
                          </>
                        ) : (
                          <PlusCircleTwoTone
                            className={styles.icon}
                            twoToneColor='#00B7AE'
                            onClick={() => {
                              add()
                            }}
                          />
                        )}
                      </Space>
                    </Space>
                  ))}
                </div>
              )
            }}
          </Form.List>
        </Form.Item>
        <div className={styles.submit}>
          <Button type={'primary'} htmlType={'submit'} loading={submitLoading}>
            提交
          </Button>
          <span />
          <span />
        </div>
      </Form>
    </div>
  )
}
