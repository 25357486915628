import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {Page} from '@/components/Page'
import styles from './index.module.less'
import {Button, Divider, Progress, Radio, Space, Typography} from 'antd'
import {RightOutlined} from '@ant-design/icons'
import {stewardModel} from '@/store/models/Steward'
import * as echarts from 'echarts/core'
import {ECOption} from '@/common/lib/echarts'
import LookServiceList from '@/pages/service/ServiceDataBoard/component/LookServiceList'

const title = {
  0: '昨天',
  1: '今天',
  2: '上月',
  3: '本月',
}

export default function ServiceDataBoard() {
  const [drawerTitle, setDrawerTitle] = useState<string>('')

  // 维修统计
  const [stewardStatistics, setStewardStatistics] = useState<ServiceDataBoardStewardOrderStatistics[]>([])
  // 保洁统计

  // 维修
  const [cleanStatistics, setCleanStatistics] = useState<ServiceDataBoardCleanOrderStatistics[]>([])
  const stewardYesDayData = useMemo(
    () => stewardStatistics.find(item => item.dateFlag === '2') ?? ({} as ServiceDataBoardStewardOrderStatistics),
    [stewardStatistics]
  )
  const stewardTodayData = useMemo(
    () => stewardStatistics.find(item => item.dateFlag === '1') ?? ({} as ServiceDataBoardStewardOrderStatistics),
    [stewardStatistics]
  )
  const stewardYesMData = useMemo(
    () => stewardStatistics.find(item => item.dateFlag === '4') ?? ({} as ServiceDataBoardStewardOrderStatistics),
    [stewardStatistics]
  )
  const stewardTodayMData = useMemo(
    () => stewardStatistics.find(item => item.dateFlag === '3') ?? ({} as ServiceDataBoardStewardOrderStatistics),
    [stewardStatistics]
  )

  // 保洁
  const cleanYesDayData = useMemo(
    () => cleanStatistics.find(item => item.dateFlag === '2') ?? ({} as ServiceDataBoardCleanOrderStatistics),
    [cleanStatistics]
  )
  const cleanTodayData = useMemo(
    () => cleanStatistics.find(item => item.dateFlag === '1') ?? ({} as ServiceDataBoardCleanOrderStatistics),
    [cleanStatistics]
  )
  const cleanYesMData = useMemo(
    () => cleanStatistics.find(item => item.dateFlag === '4') ?? ({} as ServiceDataBoardCleanOrderStatistics),
    [cleanStatistics]
  )
  const cleanTodayMData = useMemo(
    () => cleanStatistics.find(item => item.dateFlag === '3') ?? ({} as ServiceDataBoardCleanOrderStatistics),
    [cleanStatistics]
  )

  const [serviceActive, setServiceActive] = useState('1')
  const [serviceUpdateActive, setServiceUpdateActive] = useState('1')
  const [serviceFinishActive, setServiceFinishActive] = useState('1')
  const [serviceTimeOutActive, setServiceTimeOutActive] = useState('1')
  // 服务时段维修数据
  const stewardData1: number[][] = useMemo(() => {
    return [
      [
        stewardYesDayData.sixToNineOrderCount,
        stewardYesDayData.nineToTwelveOrderCount,
        stewardYesDayData.twelveToFifteenOrderCount,
        stewardYesDayData.fifteenToEighteenOrderCount,
        stewardYesDayData.eighteenToTwentyOneOrderCount,
        stewardYesDayData.twentyOneToTwentyFourOrderCount,
      ],
      [
        stewardTodayData.sixToNineOrderCount,
        stewardTodayData.nineToTwelveOrderCount,
        stewardTodayData.twelveToFifteenOrderCount,
        stewardTodayData.fifteenToEighteenOrderCount,
        stewardTodayData.eighteenToTwentyOneOrderCount,
        stewardTodayData.twentyOneToTwentyFourOrderCount,
      ],
      [
        stewardYesMData.sixToNineOrderCount,
        stewardYesMData.nineToTwelveOrderCount,
        stewardYesMData.twelveToFifteenOrderCount,
        stewardYesMData.fifteenToEighteenOrderCount,
        stewardYesMData.eighteenToTwentyOneOrderCount,
        stewardYesMData.twentyOneToTwentyFourOrderCount,
      ],
      [
        stewardTodayMData.sixToNineOrderCount,
        stewardTodayMData.nineToTwelveOrderCount,
        stewardTodayMData.twelveToFifteenOrderCount,
        stewardTodayMData.fifteenToEighteenOrderCount,
        stewardTodayMData.eighteenToTwentyOneOrderCount,
        stewardTodayMData.twentyOneToTwentyFourOrderCount,
      ],
    ]
  }, [stewardTodayData, stewardYesDayData, stewardTodayMData, stewardYesMData])

  // 服务时段保洁数据
  const cleanData1: number[][] = useMemo(() => {
    return [
      [
        cleanYesDayData.sixToTwelveOrderCount,
        cleanYesDayData.twelveToEighteenOrderCount,
        cleanYesDayData.eighteenToTwentyOneOrderCount,
      ],
      [
        cleanTodayData.sixToTwelveOrderCount,
        cleanTodayData.twelveToEighteenOrderCount,
        cleanTodayData.eighteenToTwentyOneOrderCount,
      ],
      [
        cleanYesMData.sixToTwelveOrderCount,
        cleanYesMData.twelveToEighteenOrderCount,
        cleanYesMData.eighteenToTwentyOneOrderCount,
      ],
      [
        cleanTodayMData.sixToTwelveOrderCount,
        cleanTodayMData.twelveToEighteenOrderCount,
        cleanTodayMData.eighteenToTwentyOneOrderCount,
      ],
    ]
  }, [cleanYesDayData, cleanTodayData, cleanYesMData, cleanTodayMData])

  // 服务取消维修数据
  const stewardData2: number[][] = useMemo(() => {
    return [
      [stewardYesDayData.orderCount, stewardYesDayData.cancelOrderCount],
      [stewardTodayData.orderCount, stewardTodayData.cancelOrderCount],
      [stewardYesMData.orderCount, stewardYesMData.cancelOrderCount],
      [stewardTodayMData.orderCount, stewardTodayMData.cancelOrderCount],
    ]
  }, [stewardTodayData, stewardYesDayData, stewardTodayMData, stewardYesMData])

  useEffect(() => {
    stewardModel.serviceDataStatistics().then(({response: {data}}) => {
      setStewardStatistics(data.stewardOrderStatisticsList)
      setCleanStatistics(data.cleanOrderStatisticsList)
    })
  }, [])

  return (
    <Page>
      <div className={styles.index}>
        <div className={styles.left}>
          {/* 服务时段 */}
          <Section
            title={
              <Space size={20}>
                <Typography.Text style={{fontWeight: 600, fontSize: '14px'}}>服务时段</Typography.Text>
                <Radio.Group value={serviceActive} onChange={e => setServiceActive(e.target.value)}>
                  <Radio.Button value={'1'}>维修</Radio.Button>
                  <Radio.Button value={'2'}>保洁</Radio.Button>
                </Radio.Group>
              </Space>
            }
            extra={
              <Button
                type={'text'}
                style={{
                  display: 'flex',
                  flexDirection: 'row-reverse',
                  paddingBottom: 0,
                  alignItems: 'center',
                  color: '#9EA0A7',
                  fontSize: '12px',
                }}
                icon={<RightOutlined />}
                onClick={() => setDrawerTitle('服务时段')}
              >
                查看更多
              </Button>
            }
          >
            {serviceActive === '1' ? (
              <div style={{display: 'flex', flexDirection: 'row'}}>
                {stewardData1.map((data, index) => (
                  <PieData title={title[index]} key={index} data={data} type={serviceActive} />
                ))}
              </div>
            ) : (
              <div style={{display: 'flex', flexDirection: 'row'}}>
                {cleanData1.map((data, index) => (
                  <PieData title={title[index]} key={index} data={data} type={serviceActive} />
                ))}
              </div>
            )}
          </Section>

          {/* 服务取消 */}
          <Section
            title={
              <Space size={20}>
                <Typography.Text style={{fontWeight: 600, fontSize: '14px'}}>服务取消</Typography.Text>
                <Radio.Group defaultValue={'1'}>
                  <Radio.Button value={'1'}>维修</Radio.Button>
                </Radio.Group>
              </Space>
            }
            extra={
              <Button
                type={'text'}
                style={{
                  display: 'flex',
                  flexDirection: 'row-reverse',
                  paddingBottom: 0,
                  alignItems: 'center',
                  color: '#9EA0A7',
                  fontSize: '12px',
                }}
                icon={<RightOutlined />}
                onClick={() => setDrawerTitle('服务取消')}
              >
                查看更多
              </Button>
            }
          >
            <div style={{display: 'flex', flexDirection: 'row', paddingBottom: 20}}>
              {stewardData2.map((data, index) => (
                <CancelService key={index} title={title[index]} data={data} />
              ))}
            </div>
          </Section>
        </div>
        <div className={styles.right}>
          <Section
            title={
              <Space size={20}>
                <Typography.Text style={{fontWeight: 600, fontSize: '14px'}}>服务改约</Typography.Text>
                <Radio.Group value={serviceUpdateActive} onChange={e => setServiceUpdateActive(e.target.value)}>
                  <Radio.Button value={'1'}>维修</Radio.Button>
                  <Radio.Button value={'2'}>保洁</Radio.Button>
                </Radio.Group>
              </Space>
            }
            extra={
              <Button
                type={'text'}
                style={{
                  display: 'flex',
                  flexDirection: 'row-reverse',
                  paddingBottom: 0,
                  alignItems: 'center',
                  color: '#9EA0A7',
                  fontSize: '12px',
                }}
                icon={<RightOutlined />}
                onClick={() => setDrawerTitle('服务改约')}
              >
                查看更多
              </Button>
            }
          >
            {serviceUpdateActive === '1' ? (
              <div style={{display: 'flex', flexWrap: 'wrap'}}>
                <DataTitle
                  num={stewardStatistics.find(item => item.dateFlag === '2')?.changeOrderCount}
                  title={'昨天'}
                />
                <DataTitle
                  num={stewardStatistics.find(item => item.dateFlag === '1')?.changeOrderCount}
                  title={'今天'}
                />
                <DataTitle
                  num={stewardStatistics.find(item => item.dateFlag === '4')?.changeOrderCount}
                  title={'上月'}
                />
                <DataTitle
                  num={stewardStatistics.find(item => item.dateFlag === '3')?.changeOrderCount}
                  title={'本月'}
                />
              </div>
            ) : (
              <div style={{display: 'flex', flexWrap: 'wrap'}}>
                <DataTitle num={cleanStatistics.find(item => item.dateFlag === '2')?.changeOrderCount} title={'昨天'} />
                <DataTitle num={cleanStatistics.find(item => item.dateFlag === '1')?.changeOrderCount} title={'今天'} />
                <DataTitle num={cleanStatistics.find(item => item.dateFlag === '4')?.changeOrderCount} title={'上月'} />
                <DataTitle num={cleanStatistics.find(item => item.dateFlag === '3')?.changeOrderCount} title={'本月'} />
              </div>
            )}
          </Section>
          <Section
            title={
              <Space size={20}>
                <Typography.Text style={{fontWeight: 600, fontSize: '14px'}}>服务完成</Typography.Text>
                <Radio.Group value={serviceFinishActive} onChange={e => setServiceFinishActive(e.target.value)}>
                  <Radio.Button value={'1'}>维修</Radio.Button>
                  <Radio.Button value={'2'}>保洁</Radio.Button>
                </Radio.Group>
              </Space>
            }
            extra={
              <Button
                type={'text'}
                style={{
                  display: 'flex',
                  flexDirection: 'row-reverse',
                  paddingBottom: 0,
                  alignItems: 'center',
                  color: '#9EA0A7',
                  fontSize: '12px',
                }}
                icon={<RightOutlined />}
                onClick={() => setDrawerTitle('服务完成')}
              >
                查看更多
              </Button>
            }
          >
            {serviceFinishActive === '1' ? (
              <div style={{display: 'flex', flexWrap: 'wrap'}}>
                <DataTitle
                  num={stewardStatistics.find(item => item.dateFlag === '2')?.finishOrderCount}
                  title={'昨天'}
                />
                <DataTitle
                  num={stewardStatistics.find(item => item.dateFlag === '1')?.finishOrderCount}
                  title={'今天'}
                />
                <DataTitle
                  num={stewardStatistics.find(item => item.dateFlag === '4')?.finishOrderCount}
                  title={'上月'}
                />
                <DataTitle
                  num={stewardStatistics.find(item => item.dateFlag === '3')?.finishOrderCount}
                  title={'本月'}
                />
              </div>
            ) : (
              <div style={{display: 'flex', flexWrap: 'wrap'}}>
                <DataTitle num={cleanStatistics.find(item => item.dateFlag === '2')?.finishOrderCount} title={'昨天'} />
                <DataTitle num={cleanStatistics.find(item => item.dateFlag === '1')?.finishOrderCount} title={'今天'} />
                <DataTitle num={cleanStatistics.find(item => item.dateFlag === '4')?.finishOrderCount} title={'上月'} />
                <DataTitle num={cleanStatistics.find(item => item.dateFlag === '3')?.finishOrderCount} title={'本月'} />
              </div>
            )}
          </Section>
          <Section
            title={
              <Space size={20}>
                <Typography.Text style={{fontWeight: 600, fontSize: '14px'}}>服务超时</Typography.Text>
                <Radio.Group value={serviceTimeOutActive} onChange={e => setServiceTimeOutActive(e.target.value)}>
                  <Radio.Button value={'1'}>维修</Radio.Button>
                  <Radio.Button value={'2'}>保洁</Radio.Button>
                </Radio.Group>
              </Space>
            }
            extra={
              <Button
                type={'text'}
                style={{
                  display: 'flex',
                  flexDirection: 'row-reverse',
                  paddingBottom: 0,
                  alignItems: 'center',
                  color: '#9EA0A7',
                  fontSize: '12px',
                }}
                icon={<RightOutlined />}
                onClick={() => setDrawerTitle('服务超时')}
              >
                查看更多
              </Button>
            }
          >
            {serviceTimeOutActive === '1' ? (
              <div style={{display: 'flex', flexWrap: 'wrap'}}>
                <DataTitle
                  num={stewardStatistics.find(item => item.dateFlag === '2')?.timeoutOrderCount}
                  title={'昨天'}
                />
                <DataTitle
                  num={stewardStatistics.find(item => item.dateFlag === '1')?.timeoutOrderCount}
                  title={'今天'}
                />
                <DataTitle
                  num={stewardStatistics.find(item => item.dateFlag === '4')?.timeoutOrderCount}
                  title={'上月'}
                />
                <DataTitle
                  num={stewardStatistics.find(item => item.dateFlag === '3')?.timeoutOrderCount}
                  title={'本月'}
                />
              </div>
            ) : (
              <div style={{display: 'flex', flexWrap: 'wrap'}}>
                <DataTitle
                  num={cleanStatistics.find(item => item.dateFlag === '2')?.timeoutOrderCount}
                  title={'昨天'}
                />
                <DataTitle
                  num={cleanStatistics.find(item => item.dateFlag === '1')?.timeoutOrderCount}
                  title={'今天'}
                />
                <DataTitle
                  num={cleanStatistics.find(item => item.dateFlag === '4')?.timeoutOrderCount}
                  title={'上月'}
                />
                <DataTitle
                  num={cleanStatistics.find(item => item.dateFlag === '3')?.timeoutOrderCount}
                  title={'本月'}
                />
              </div>
            )}
          </Section>
        </div>
      </div>

      <LookServiceList
        title={drawerTitle}
        onClose={() => {
          setDrawerTitle('')
        }}
      />
    </Page>
  )
}

interface SectionProps {
  title: string | React.ReactNode
  className?: string
  extra?: string | React.ReactNode
}
const Section: React.FC<SectionProps> = ({className, title, extra, children}) => {
  return (
    <div className={`${styles.section} ${className}`}>
      <div className={styles.header}>
        {React.isValidElement(title) ? title : <Typography.Text className={styles.titleStyle}>{title}</Typography.Text>}
        {React.isValidElement(extra) ? extra : <Typography.Text className={styles.extraStyle}>{extra}</Typography.Text>}
      </div>
      <Divider style={{marginBottom: 0, marginTop: 0}} />
      <div className={styles.propsContentClass}>{children}</div>
    </div>
  )
}

interface DataTitleProps {
  title: string
  num: number
}

const DataTitle: React.FC<DataTitleProps> = ({num, title}) => {
  return (
    <div style={{width: '50%', display: 'flex', flexDirection: 'column', alignItems: 'center', paddingBottom: '20px'}}>
      <Typography.Text style={{color: '#212832', fontWeight: 600, fontSize: '20px'}}>{num}</Typography.Text>
      <div style={{backgroundColor: '#A0DBD9', width: '12px', height: '4px', borderRadius: '3.5px', margin: '7px 0'}} />
      <Typography.Text style={{color: '#9EA0A7', fontSize: '12px'}}>{title}</Typography.Text>
    </div>
  )
}

interface PieDataProps {
  title: string
  data: number[]
  type: string
}

const PieData: React.FC<PieDataProps> = ({title, data, type}) => {
  const chartDomRef = useRef(null)
  const chartRef = useRef<echarts.ECharts>(null)
  const stewardType = useMemo(
    () => ({
      0: '6点~9点',
      1: '9点~12点',
      2: '12点~15点',
      3: '15点~18点',
      4: '18点~21点',
      5: '21点~24点',
    }),
    []
  )

  const cleanType = useMemo(
    () => ({
      0: '6点~12点',
      1: '12点~18点',
      2: '18点~24点',
    }),
    []
  )

  const setOption = useCallback(() => {
    chartRef.current = echarts.init(chartDomRef.current)

    chartRef.current?.showLoading('default', {
      text: '加载中...',
      color: '#017BFF',
      textColor: '#2C3542',
    })
    const option: ECOption = {
      tooltip: {
        trigger: 'item',
      },
      series: [
        {
          type: 'pie',
          radius: '70%',
          data: data.some(item => item)
            ? data.map((item, index) => ({name: type === '1' ? stewardType[index] : cleanType[index], value: item}))
            : [],
          label: {show: false},
          color: ['#0FA363', '#FFB92E', '#2EB5FF', '#FF602D', '#FFDF1C', '#A248FF'],
        },
      ],
    }

    chartRef.current?.setOption(option, true)

    chartRef.current?.hideLoading()
  }, [cleanType, data, stewardType, type])

  useEffect(() => {
    setTimeout(() => {
      setOption()
    })
    function onResize() {
      chartRef.current?.resize?.()
    }

    window.addEventListener('resize', onResize)

    return () => window.removeEventListener('resize', onResize)
  }, [setOption])

  return (
    <div style={{display: 'flex', flex: 1, flexDirection: 'column', alignItems: 'center'}}>
      <Typography.Text>{title}</Typography.Text>
      <div ref={chartDomRef} style={{height: '155px', width: '155px'}} />
    </div>
  )
}

interface CancelService {
  title: string
  data: number[]
}

const CancelService: React.FC<CancelService> = ({title, data}) => {
  const total = data[0] ?? 0
  const cancelTotal = data[1] ?? 0
  const percent = useMemo(() => {
    return Math.floor((cancelTotal / total) * 100)
  }, [cancelTotal, total])

  const color = {
    昨天: '#27A16C',
    今天: '#4ABCFF',
    上月: '#FF7A4F',
    本月: '#5C86FF',
  }

  return (
    <div style={{display: 'flex', flex: 1, flexDirection: 'column', alignItems: 'center'}}>
      <Typography.Text>{title}</Typography.Text>
      <Progress
        type={'circle'}
        percent={percent}
        format={percent => `${percent}%`}
        strokeColor={color[title]}
        style={{margin: '10px 0'}}
      />
      <div style={{display: 'flex', flexDirection: 'row', width: '100%', alignItems: 'center'}}>
        <div style={{display: 'flex', flex: 1, flexDirection: 'column', alignItems: 'center'}}>
          <Typography.Text style={{color: '#9EA0A7', fontSize: 12}}>下单量</Typography.Text>
          <Typography.Text style={{fontWeight: 'bold', fontSize: 20}}>{total}</Typography.Text>
        </div>
        <div style={{background: '#E6E7EB', width: 1, height: 34}} />
        <div style={{display: 'flex', flex: 1, flexDirection: 'column', alignItems: 'center'}}>
          <Typography.Text style={{color: '#9EA0A7', fontSize: 12}}>取消量</Typography.Text>
          <Typography.Text style={{fontWeight: 'bold', fontSize: 20}}>{cancelTotal}</Typography.Text>
        </div>
      </div>
    </div>
  )
}
