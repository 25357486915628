import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {Button, Calendar, Col, DatePicker, Form, FormInstance, Row, Select, Space} from 'antd'
import moment from 'moment/moment'
import commonStyles from '@/assets/styles/common.module.less'
import {sysModel} from '@/store/models/Sys'
import {FORMAT_YEAR_MONTH} from '@/common/date'
import {stewardModel} from '@/store/models/Steward'
import {sysUserModel} from '@/store/models/SysUser'
import {getImg} from '@/utils/util'
import {PreviewButton} from '@/pages/stewardManage/Schedule/component/PreviewButton'

export default function CalendarView(props: {form: FormInstance}) {
  const form = props.form

  const [stewardList, setStewardList] = useState<SysUserVO[]>([])
  const stewardListWithMobile = useMemo(
    () => stewardList.map(value => ({...value, name: `${value.name}（${value.mobile}）`})),
    [stewardList]
  )

  useEffect(() => {
    sysUserModel
      .querySysUserList({roleId: 'steward', pageable: false})
      .then(({response: {data}}) => setStewardList(data))
  }, [])

  const [transferMonth, setTransferMonth] = useState<StewardTransferVO[]>([])

  const search = useCallback(async () => {
    await form.validateFields()

    const values = form.getFieldsValue(true)
    const {
      response: {data},
    } = await stewardModel.stewardAdminTransferListByMonth(values)
    setTransferMonth(data)
  }, [form])

  return (
    <>
      <Form
        form={form}
        labelCol={{flex: '80px'}}
        initialValues={{month: moment().format(FORMAT_YEAR_MONTH)}}
        onFinish={search}
        onReset={search}
      >
        <div className={commonStyles.filterSection}>
          <Row gutter={30}>
            <Col span={8}>
              <Form.Item label={'管家'} name={'stewardId'} rules={[{required: true}]}>
                <Select
                  placeholder={'请输入管家名称'}
                  options={stewardListWithMobile}
                  fieldNames={{label: 'name', value: 'userId'}}
                  showSearch
                  filterOption={(inputValue, option) => option.name?.indexOf(inputValue) > -1}
                  onChange={async (_, option) => {
                    if (!Array.isArray(option)) {
                      if (option && option.userId) {
                        const {
                          response: {data},
                        } = await sysModel.sysOrgMemberOrgPath({userId: option.userId})
                        form.setFieldsValue({_orgPath: data})
                      } else {
                        form.setFieldsValue({_orgPath: ''})
                      }
                    }
                  }}
                  allowClear
                />
              </Form.Item>
              <Form.Item noStyle shouldUpdate>
                {form1 => {
                  const _orgPath = form1.getFieldValue('_orgPath')
                  return (
                    !!_orgPath && (
                      <Form.Item label={' '} colon={false} style={{margin: '-24px 0 0'}}>
                        <span style={{color: '#f00'}}>{_orgPath}</span>
                      </Form.Item>
                    )
                  )
                }}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label={'月份'}
                name={'month'}
                rules={[{required: true}]}
                getValueFromEvent={event => (event ? event.format(FORMAT_YEAR_MONTH) : event)}
                getValueProps={value => ({value: value ? moment(value) : value})}
              >
                <DatePicker.MonthPicker />
              </Form.Item>
            </Col>
          </Row>
          <Row justify={'end'}>
            <Space>
              <Button type={'primary'} htmlType={'submit'}>
                查询
              </Button>
              <Button htmlType={'reset'}>重置</Button>
            </Space>
          </Row>
        </div>

        <div className={commonStyles.filterSection}>
          <Calendar
            value={form.getFieldValue('month') ? (moment(form.getFieldValue('month')) as any) : undefined}
            mode={'month'}
            headerRender={() => null}
            dateCellRender={date => {
              const items = transferMonth.filter(value => date.isSame(value.beginDate, 'date'))
              const checkIn = items[0]?.stewardCheckIn
              const numFinished = items[0]?.numFinished // 上午下午的 numFinished 一样，拿第一条

              return (
                <Row justify={'space-between'}>
                  <Col>
                    {items.map((value, index) => (
                      <div key={index} style={[-2, -1].includes(value.vacationTypeId) ? {color: '#f00'} : {}}>
                        {value.vacationTypeName}
                      </div>
                    ))}
                  </Col>
                  <Col>
                    <Space direction={'vertical'}>
                      {!!checkIn && <PreviewButton src={getImg(checkIn.checkPicUrl)} />}

                      {typeof numFinished === 'number' && <span style={{color: '#EA9F3C'}}>{numFinished}单</span>}
                    </Space>
                  </Col>
                </Row>
              )
            }}
          />
        </div>
      </Form>
    </>
  )
}
