import {Model} from '@redux-model/web'
import {$api} from '@/store/service'

export interface SysModel {}

class Sys extends Model<SysModel> {
  protected initReducer(): SysModel {
    return {}
  }

  // 新增(编辑)码表
  sysCodeUpset = $api.action((args: SysCodeDTO) => this.post('/sys/code/upset').body({status: '1', ...args}))

  // 码表列表(不分页)  |  category:不填查第一级,code:可空,name:可空
  sysCodeList = $api.action((args: SysCodeListDTO) => this.post<ApiResponse<SysCodeVO[]>>('/sys/code/list').body(args))

  // 移除组织成员
  sysOrgRemoveMember = $api.action((args: SysOrgUpdateMemberDTO) =>
    this.post<ApiResponse>('/sys/org/remove-member').body(args)
  )

  // 更新组织成员
  sysOrgUpdateMember = $api.action((args: SysOrgUpdateMemberDTO) =>
    this.post<ApiResponse>('/sys/org/update-member').body(args)
  )

  // 组织树  |  object:{fetchUser:true,orgIdLike:组织id,orgRoleId:组织对应的角色}
  sysOrgTree = $api.action((args: SysOrgTreeQueryDTO) =>
    this.post<ApiResponse<SysOrgTreeNodeVO[]>>('/sys/org/tree').body(args)
  )

  // 新增组织  |  objects:{orgPid:父级组织id,orgName:组织名称,orgRoleId:组织对应的角色}
  sysOrgInsert = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<string>>('/sys/org/insert').body(args)
  )

  // 更新组织信息  |  objects:{orgId:组织id,orgName:组织名称,orgRoleId:组织对应的角色}
  sysOrgUpdateName = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<Record<string, any>>>('/sys/org/update-name').body(args)
  )

  // 删除组织(如果组织下无人,则会递归删除所有子组织)  |  objects:{orgId:组织id}
  sysOrgDelete = $api.action((args: {orgId: string}) => this.post<ApiResponse>('/sys/org/delete').body(args))

  // 组织列表
  sysOrgList = $api.action((args: Partial<SysOrgListQueryDTO>) =>
    this.post<ApiResponse<SysOrgVO[]>>('/sys/org/list').body({
      pageNum: 1,
      pageSize: 10,
      pageable: false,
      ...args,
    })
  )

  // 系统用户列表
  // sysUserList = $api.action((args: Partial<SysListDTO>) =>
  //   this.post<ApiResponse<SysUserVO[]>>('/sys-user/list').body({
  //     pageNum: 1,
  //     pageSize: 10,
  //     pageable: false,
  //     ...args,
  //   })
  // )

  // 查询小区列表
  communityList = $api.action((params: Partial<CommunityListDTO>) =>
    this.post<ApiResponse<CommunityVO[]>>('/sys/community/list').body({
      ...params,
      pageable: true,
    })
  )

  // 新增小区
  communityAdd = $api.action((params: SysCommunityDTO) =>
    this.post<ApiResponse>('/sys/community/add').body({
      ...params,
    })
  )

  // 新增更新
  communityUpdate = $api.action((params: SysCommunityDTO) =>
    this.post<ApiResponse>('/sys/community/update').body({
      ...params,
    })
  )

  // 小区详情
  communityInfo = $api.action((code: string) =>
    this.post<ApiResponse<CommunityVO>>('/sys/community/info').body({communityCode: code})
  )

  // 删除小区数据
  communityDelete = $api.action((code: string) => this.post<ApiResponse>('/sys/community/delete').body({code}))

  // 获取后台人员定位列表  |  object:{roleId:角色id}
  sysUserLocationList = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<StewardLocationVO[]>>('/sys-user/location/list').body(args)
  )

  // 查询小区列表
  sysCommunityHistoryList = $api.action((args: SysCommunityHistoryListDTO) =>
    this.post<ApiResponse<CommunityHistoryVO[]>>('/sys/community/history/list').body(args)
  )

  // 核查小区(参数:code)
  sysCommunityCheck = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<Record<string, any>>>('/sys/community/check').body(args)
  )

  // banner-删除  |  object:{bannerId:bannerId}
  sysBannerDelete = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<Record<string, any>>>('/sys-banner/delete').body(args)
  )

  // banner-列表查询
  sysBannerList = $api.action((args: SysBannerListDTO) =>
    this.post<ApiResponse<SysBannerVO[]>>('/sys-banner/list').body(args)
  )

  // banner-新增/编辑
  sysBannerSave = $api.action((args: SysBannerDTO) =>
    this.post<ApiResponse<Record<string, any>>>('/sys-banner/save').body(args)
  )

  // banner-设置是否有效  |  object:{bannerId:bannerId,bannerStatus:状态}
  sysBannerSetStatus = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<Record<string, any>>>('/sys-banner/set-status').body(args)
  )

  // banner-详情  |  object:{bannerId:bannerId}
  sysBannerInfo = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<SysBannerVO>>('/sys-banner/info').body(args)
  )
  // 报表配置列表
  sysReportList = $api.action((args: SysReportQueryDTO) =>
    this.post<ApiResponse<SysReportVO[]>>('/sys-report/list').body(args)
  )

  // 更新报表的角色配置
  sysReportUpdateRole = $api.action((args: SysReportUpdateDTO) =>
    this.post<ApiResponse<Record<string, any>>>('/sys-report/update-role').body(args)
  )

  // 更新报表的是否有效
  sysReportUpdateValid = $api.action((args: SysReportUpdateDTO) =>
    this.post<ApiResponse<Record<string, any>>>('/sys-report/update-valid').body(args)
  )

  // 下载记录列表
  sysReportExportList = $api.action((args: SysReportExportQueryDTO) =>
    this.post<ApiResponse<SysReportExportVO[]>>('/sys-report/export/list').body(args)
  )

  // 报表1(服务单统计报表)
  sysReportExportExcel1 = $api.action((args: SysReportExportExcel1DTO) =>
    this.post<ApiResponse<Record<string, any>>>('/sys-report/export/excel-1').body(args)
  )

  // 报表2(管家服务单报表)
  sysReportExportExcel2 = $api.action((args: SysReportExportExcel2DTO) =>
    this.post<ApiResponse<Record<string, any>>>('/sys-report/export/excel-2').body(args)
  )

  // 报表3(财务部按月导出集中式项目收入情况)
  sysReportExportExcel3 = $api.action((args: SysReportExportExcel3DTO) =>
    this.post<ApiResponse<Record<string, any>>>('/sys-report/export/excel-3').body(args)
  )

  // 报表4(集中式项目新签和续签统计表)
  sysReportExportExcel4 = $api.action((args: SysReportExportExcel4DTO) =>
    this.post<ApiResponse<Record<string, any>>>('/sys-report/export/excel-4').body(args)
  )

  // 公告-列表查询
  sysNoticeList = $api.action((args: SysNoticeListDTO) =>
    this.post<ApiResponse<SysNoticeVO[]>>('/sys-notice/list').body(args)
  )

  // 公告-撤回  |  object:{noticeId:noticeId}
  sysNoticeCancel = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<Record<string, any>>>('/sys-notice/cancel').body(args)
  )

  // 公告-详情  |  object:{noticeId:noticeId}
  sysNoticeInfo = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<SysNoticeVO>>('/sys-notice/info').body(args)
  )

  // 公告-新增/编辑
  sysNoticeSave = $api.action((args: SysNoticeDTO) =>
    this.post<ApiResponse<Record<string, any>>>('/sys-notice/save').body(args)
  )

  // 白名单列表
  sysWhitelistList = $api.action((args: SysWhitelistQueryDTO) =>
    this.post<ApiResponse<SysWhitelistVO[]>>('/sys-whitelist/list').body(args)
  )

  // 白名单添加
  sysWhitelistInsert = $api.action((args: SysWhitelistInsertDTO) =>
    this.post<ApiResponse<Record<string, any>>>('/sys-whitelist/insert').body(args)
  )

  // 白名单编辑
  sysWhitelistUpdate = $api.action((args: SysWhitelistUpdateDTO) =>
    this.post<ApiResponse<Record<string, any>>>('/sys-whitelist/update').body(args)
  )

  // 消息自动关闭时间  |  objects:{k:键}
  sysConfigGet = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<Record<string, any>>>('/sys-config/get').body(args)
  )

  // 消息自动关闭时间  |  objects:{k:键,v:值(对象)}
  sysConfigSave = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<Record<string, any>>>('/sys-config/save').body(args)
  )

  // 合作伙伴启用/禁用
  sysPartnerEnable = $api.action((args: SysPartnerUpsertDTO) =>
    this.post<ApiResponse<string>>('/sys-partner/enable').body(args)
  )

  // 合作伙伴列表
  sysPartnerList = $api.action((args: SysPartnerListDTO) =>
    this.post<ApiResponse<SysPartnerListVO[]>>('/sys-partner/list').body(args)
  )

  // 修改合作伙伴顺序
  sysPartnerUpdateRank = $api.action((args: SysPartnerRankUpdateDTO) =>
    this.post<ApiResponse<Record<string, any>>>('/sys-partner/update-rank').body(args)
  )

  // 添加/编辑-合作伙伴
  sysPartnerUpsert = $api.action((args: SysPartnerUpsertDTO) =>
    this.post<ApiResponse<string>>('/sys-partner/upsert').body(args)
  )
  // 黑名单列表
  sysSysBlackListList = $api.action((args: SysBlackListDTO) =>
    this.post<ApiResponse<SysBlackListVO[]>>('/sys/sys-black-list/list').body(args)
  )

  // 新增黑名单
  sysSysBlackListInsert = $api.action((args: SysBlackListDTO) =>
    this.post<ApiResponse<Record<string, any>>>('/sys/sys-black-list/insert').body(args)
  )

  // 修改黑名单
  sysSysBlackListUpdate = $api.action((args: SysBlackListDTO) =>
    this.post<ApiResponse<Record<string, any>>>('/sys/sys-black-list/update').body(args)
  )

  // 禁用  |  object:{id:id,status:1:有效 0:无效}
  sysSysBlackListEnable = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<Record<string, any>>>('/sys/sys-black-list/enable').body(args)
  )

  // 查询用户所在组织路径  |  objects:{userId:用户id}
  sysOrgMemberOrgPath = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<string>>('/sys/org/member-org-path').body(args)
  )

  // 上线通知详情
  sysAppPublishDetail = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<SysAppPublishVO>>('/sys/app-publish/detail').body(args)
  )

  // 上线通知列表
  sysAppPublishList = $api.action((args: SysAppPublishListDTO) =>
    this.post<ApiResponse<SysAppPublishVO[]>>('/sys/app-publish/list').body(args)
  )

  // 上线通知新增
  sysAppPublishUpsert = $api.action((args: SysAppPublishInsertDTO) =>
    this.post<ApiResponse<Record<string, any>>>('/sys/app-publish/upsert').body(args)
  )

  // 用户上线通知
  sysAppPublishUser = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<SysAppPublishVO[]>>('/sys/app-publish/user').body(args)
  )

  // 用户上线通知
  sysAppPublishUserInsert = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<Record<string, any>>>('/sys/app-publish/user/insert').body(args)
  )

  // 审批配置列表
  approvalConfigList = $api.action(() => this.post<ApiResponse<ApprovalListVO[]>>('/approval/config/list'))

  // 启用/禁用-审批配置  |  object:{approvalConfigId:审批配置id,approvalConfigStatus:配置状态}
  approvalConfigEnable = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<Record<string, any>>>('/approval/config/enable').body(args)
  )

  // 审批配置-内容编辑
  approvalConfigEdit = $api.action((args: ApprovalUpsetDTO) =>
    this.post<ApiResponse<Record<string, any>>>('/approval/config/edit').body(args)
  )

  // 审批配置-二级码表查询  |  object:{approvalConfigId:审批配置id}
  approvalConfigCodeGet = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<PairModel[]>>('/approval/config/code/get').body(args)
  )

  // 审批地区配置  |  object:{id:审批地区配置id}
  approvalConfigCityGet = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<ApprovalConfigCityDetailVO>>('/approval/config/city/get').body(args)
  )

  // 新增审批配置
  approvalConfigCityInsert = $api.action((args: ApprovalUpsetDTO) =>
    this.post<ApiResponse<string>>('/approval/config/city/insert').body(args)
  )

  // 审批地区配置列表  |  object:{configId:审批配置id}
  approvalConfigCityList = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<ApprovalConfigCityListVO[]>>('/approval/config/city/list').body(args)
  )

  // 审批地区配置删除  |  object:{id:审批地区配置id}
  approvalConfigCityDelete = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<Record<string, any>>>('/approval/config/city/delete').body(args)
  )

  // 审批单-校验转移  |  object:{orderId:转移单id,stewardId:重新分配的服务管家id} true:走审批流程 false:不走审批流程
  approvalOrderBeforeCheckTransferOrder = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<boolean>>('/approval/order/before/check/transfer-order').body(args)
  )

  // 我审批的审批单列表
  approvalOrderListMyApproval = $api.action((args: ApprovalOrderListDTO) =>
    this.post<ApiResponse<ApprovalOrderDetailVO[]>>('/approval/order/list/my-approval').body(args)
  )

  // 审批单详情  |  object:{approvalOrderId:审批单id}
  approvalOrderDetail = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<ApprovalOrderDetailVO>>('/approval/order/detail').body(args)
  )

  // 审批单-审批人审批内容  |  object:{approvalOrderId:审批单id}
  approvalOrderApprovalUserContent = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<string[]>>('/approval/order/approval-user/content').body(args)
  )

  // 审批单操作
  approvalOrderStatusFlow = $api.action((args: ApprovalOrderOperationDTO) =>
    this.post<ApiResponse<Record<string, any>>>('/approval/order/status/flow').body(args)
  )

  // 销售绩效方案-列表
  stewardSalaryPlanList = $api.action((args: StewardSalaryPlanListDTO) =>
    this.post<ApiResponse<StewardSalaryPlanVO[]>>('/steward-salary-plan/list').body(args)
  )

  // 抄送我的审批单列表
  approvalOrderListMyCc = $api.action((args: ApprovalOrderListDTO) =>
    this.post<ApiResponse<ApprovalOrderDetailVO[]>>('/approval/order/list/my-cc').body(args)
  )
  // 添加成员
  sysOrgV2AddMember = $api.action((args: SysOrgV2MemberDTO) =>
    this.post<ApiResponse<Record<string, any>>>('/sys/org/v2/add-member').body(args)
  )

  // 删除组织  |  objects:{orgId:组织id}
  sysOrgV2Delete = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<Record<string, any>>>('/sys/org/v2/delete').body(args)
  )

  // 新增组织  |  objects:{orgPid:父级组织id,orgName:组织名称}
  sysOrgV2Insert = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<string>>('/sys/org/v2/insert').body(args)
  )

  // 组织列表
  sysOrgV2List = $api.action((args: SysOrgV2ListDTO) =>
    this.post<ApiResponse<SysOrgV2VO[]>>('/sys/org/v2/list').body(args)
  )

  // 组织成员列表
  sysOrgV2MemberList = $api.action((args: SysOrgV2MemberListDTO) =>
    this.post<ApiResponse<SysOrgV2MemberVO[]>>('/sys/org/v2/member/list').body(args)
  )

  // 未分配-成员列表
  sysOrgV2MemberNonOrgList = $api.action((args: SysOrgV2MemberListDTO) =>
    this.post<ApiResponse<SysOrgV2MemberVO[]>>('/sys/org/v2/member/non-org-list').body(args)
  )

  // 移动组织  |  objects:{orgId:组织id,orgPid:父级组织id}
  sysOrgV2Move = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<Record<string, any>>>('/sys/org/v2/move').body(args)
  )

  // 删除成员
  sysOrgV2RemoveMember = $api.action((args: SysOrgV2MemberDTO) =>
    this.post<ApiResponse<Record<string, any>>>('/sys/org/v2/remove-member').body(args)
  )

  // 组织树
  sysOrgV2Tree = $api.action((args: SysOrgV2ListDTO) =>
    this.post<ApiResponse<SysOrgV2VO[]>>('/sys/org/v2/tree').body(args)
  )

  // 编辑组织  |  objects:{orgId:组织id,orgName:组织名称}
  sysOrgV2UpdateName = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<Record<string, any>>>('/sys/org/v2/update-name').body(args)
  )
}

export const sysModel = new Sys()
