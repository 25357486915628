import {baseUrl, fileUrl, ossUrl} from '@/common/config'
import {message} from 'antd'
import {createHeaders} from '@/store/service'
import moment from 'moment/moment'

export const delay = (ms = 1000) => new Promise(resolve => setTimeout(resolve, ms))

interface OSSProcess {
  w?: number // 缩放宽度
  q?: number // 缩放质量 1 - 100
}

export function getImg(path = '', option = {} as OSSProcess) {
  if (!path || path.startsWith('file://')) {
    return path
  }

  let query = option.w ? `/resize,w_${option.w}` : ''
  query += option.q ? `/quality,q_${option.q}` : ''
  query = query ? `?x-oss-process=image${query}` : query

  return path.startsWith('http') ? `${path}${query}` : `${fileUrl}${path}${query}`
}

export function objectCreate(length, {labelSuffix = '', labelPrefix = '', renderValue = i => i, idOffset = 0} = {}) {
  return Array.from({length}).reduce<CodeItem>((result, _, i) => {
    result[`${i + 1 + idOffset}`] = `${labelPrefix ?? ''}${renderValue(i + 1 + idOffset)}${labelSuffix ?? ''}`
    return result
  }, {})
}

//
export function downloadFile(fileurl, fileName) {
  const a = document.createElement('a')
  a.download = fileName
  a.target = '_blank'
  // a.style.display = 'none'
  a.href = `${ossUrl}${fileurl}`
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
}

interface DownloadBlobOptions extends Omit<RequestInit, 'body'> {
  url: string
  body?: Record<string, any>
}

export async function downloadBlob({url, body, headers, ...options}: DownloadBlobOptions) {
  url = /^https?:\/\//.test(url) ? url : `${baseUrl}/${url.replace(/^\//, '')}`
  const fetchHeaders = {
    ...createHeaders(),
    'Content-Type': 'application/json',
    ...headers,
  }
  const fetchBody = body || {}

  const response = await fetch(url, {
    method: 'post',
    headers: fetchHeaders,
    body: JSON.stringify(fetchBody),
    ...options,
  })
  const contentType = response.headers.get('content-type')
  if (contentType === 'application/json') {
    const data = await response.json()
    message.warn(data.msg)
    return
  }

  let filename = response.headers.get('nyg8-filename')
  filename = filename ? decodeURIComponent(filename) : '导出.xlsx'
  const blob = await response.blob()
  const a = document.createElement('a')
  a.href = URL.createObjectURL(blob)
  a.download = filename
  a.click()
  URL.revokeObjectURL(a.href)
}

export function debounce<F extends (...args) => any>(fn: F, time = 350): (...args: Parameters<F>) => void {
  let timer
  return (...args) => {
    clearTimeout(timer)
    timer = setTimeout(() => fn(...args), time)
  }
}

// 判断文件对象
export const isFile = value => typeof value === 'object' && !!value.size
// 判断已上传对象
export const isUploaded = value => typeof value === 'object' && !!value.uid && !!value.url

export function mobileValid(rule: any, value: any, callback: Function) {
  const mobileReg = /^1[0-9]{10}$/
  if (!value) return callback('手机号不能为空')
  if (!mobileReg.test(value)) return callback('手机号格式不正确')
  callback()
}

// 校验规则
export function certNoValid(rule: any, value: any, callback: Function) {
  const certNoReg = /^[1-9]\d{5}(18|19|20|(3\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/
  if (!value) return callback('身份证号不能为空')
  if (!certNoReg.test(value)) return callback('身份证号格式不正确')
  callback()
}

export function timeInterval(time) {
  const tTime = moment(time).toDate()
  const tNow = moment().toDate()
  const diff = tTime.getTime() - tNow.getTime()

  const hours = Math.floor(diff / (60 * 60 * 1000))
  const rest = diff % (60 * 60 * 1000)
  const minutes = Math.floor(rest / (60 * 1000))

  return diff >= 0 ? `剩余${hours}h${minutes}m` : '已超时'
}

// 打印：将要打印的模块id传进来
export function printModule(myDiv) {
  const newStr = document.getElementById(myDiv).innerHTML
  // const oldStr = document.body.innerHTML

  const div = document.createElement('div')
  div.style.position = 'fixed'
  div.style.left = '0'
  div.style.top = '0'
  div.style.zIndex = '99999999'
  div.style.width = '100%'
  div.style.minHeight = '100vh'
  div.style.background = 'white'

  div.innerHTML = newStr

  document.getElementById('root').style.display = 'none'
  document.body.appendChild(div)
  window.print()
  document.body.removeChild(div)
  document.getElementById('root').style.removeProperty('display')
  return false
}
