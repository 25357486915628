import React, {useCallback, useEffect, useState} from 'react'
import MapApiLoaderHOC from 'react-bmapgl/dist/Map/MapApiLoaderHOC'
import {ak} from '@/common/config'
import commonStyle from '@/assets/styles/common.module.less'
import {Button, Col, Descriptions, Drawer, Form, message, Row, Select, Space, Table, Tooltip} from 'antd'
import {stewardModel} from '@/store/models/Steward'
import Map from 'react-bmapgl/dist/Map/Map'
import CustomOverlay from 'react-bmapgl/dist/Overlay/CustomOverlay'
import styles from '@/pages/steward/MapSearchOrder/index.module.less'
import orderActive from '@/assets/icons/order_active.svg'
import order from '@/assets/icons/order.svg'
import {debounce} from '@/utils/util'
import {sysModel} from '@/store/models/Sys'
import {condoModel} from '@/store/models/Condo'
import {commonModel, useCodeArraySync, useCodeSync} from '@/store/models/Common'
import DividerLine from '@/pages/property/component/DividerLine'
import {useAppHistory} from '@/common/hooks/useRouter'
import {useDispatchModel} from '@/pages/anju/AnJuServiceList'

function MapSearchOrderList() {
  const history = useAppHistory()
  const [form] = Form.useForm()
  const [list, setList] = useState<StewardOrderCommunityMapVO[]>([])
  const [stewardList, setStewardList] = useState<StewardUserVO[]>([])
  const [communityList, setCommunityList] = useState<CommunityVO[]>([])
  const [condoList, setCondoList] = useState<CondoInfoAdminQueryResultVO[]>([])
  const orderStatusArr = useCodeArraySync('steward.orderStatus').filter(item => ['1', '2', '3', '4'].includes(item.id))
  const orderStatus = useCodeSync('steward.orderStatus')
  const [center, setCenter] = useState<string>('上海')
  const [cityList, setCityList] = useState<CityDataProps[]>([])
  const [info, setInfo] = useState({} as StewardOrderCommunityMapVO)
  const [orderList, setOrderList] = useState<StewardOrderQueryVO[]>([])
  const [total, setTotal] = useState(0)

  // 重新分配
  const [selectedRows, setSelectedRows] = useState<StewardOrderQueryVO[]>([])
  const [show, contextHolder] = useDispatchModel()

  const search = useCallback(async () => {
    const values = form.getFieldsValue(true)
    const {
      response: {data},
    } = await stewardModel.stewardAdminOrderCommunityMapList(values)
    setList(data)
  }, [form])

  const searchCommunity = useCallback(
    debounce(async (name: string) => {
      const {
        response: {data},
      } = await sysModel.communityList({name, pageNum: 1, pageable: true, pageSize: 10})
      setCommunityList(data)
    }),
    []
  )

  const searchCondo = useCallback(
    debounce(async (condoName: string) => {
      const {
        response: {data},
      } = await condoModel.condoList({condoName, pageNum: 1, pageSize: 10, pageable: true} as CondoInfoAdminQueryDTO)
      setCondoList(data)
    }),
    []
  )

  // 服务单列表
  const searchOrderList = useCallback(
    ({communityCode, pageNum, pageSize}: {communityCode: string; pageNum: number; pageSize: number}) => {
      const {orderStatusList, stewardId, serverId} = form.getFieldsValue(true)
      stewardModel
        .stewardAdminOrderList({
          orderStatusList,
          stewardId,
          communityCode,
          cleanerId: serverId,
          pageNum,
          pageable: true,
          pageSize,
        } as StewardOrderQueryDTO)
        .then(({response: {data, total}}) => {
          setOrderList(data)
          setTotal(total)
        })
    },
    [form]
  )

  useEffect(() => {
    form.submit()

    stewardModel.stewardUserList({pageNum: 1, pageSize: 10, pageable: false}).then(({response: {data}}) => {
      setStewardList(data)
    })

    commonModel
      .queryCityCode({type: '2', parentCode: null})
      .then(({response: {data}}) => setCityList(data.map(item => ({...item, key: item.code}))))
  }, [])

  return (
    <div style={{height: '100%', width: '100%', display: 'flex', flexDirection: 'column', position: 'relative'}}>
      <div className={commonStyle.filterSection}>
        <Form
          form={form}
          onFinish={search}
          onReset={search}
          labelAlign={'left'}
          initialValues={{orderStatusList: ['1', '2', '3', '4'], cityCodeList: ['310100']}}
        >
          <Row>
            <Col span={7}>
              <Form.Item label={'小区'} name={'communityCode'}>
                <Select placeholder={'请输入'} allowClear showSearch filterOption={false} onSearch={searchCommunity}>
                  {communityList.map(item => (
                    <Select.Option key={item.code} value={item.code}>
                      {item.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={7} offset={1}>
              <Form.Item label={'公寓名称'} name={'condoId'}>
                <Select placeholder={'请输入'} allowClear showSearch filterOption={false} onSearch={searchCondo}>
                  {condoList.map(item => (
                    <Select.Option key={item.condoId} value={item.condoId}>
                      {item.condoName}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={7} offset={1}>
              <Form.Item label={'管家'} name={'stewardId'}>
                <Select
                  placeholder={'请输入'}
                  allowClear
                  showSearch
                  filterOption={(inputValue, option) => {
                    return option?.children?.indexOf(inputValue as any) > -1
                  }}
                >
                  {stewardList.map(item => (
                    <Select.Option key={item.userId} value={item.userId}>
                      {`${item.name}(${item.mobile})`}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item label={'当前服务人员'} name={'serverId'}>
                <Select
                  placeholder={'请输入'}
                  allowClear
                  showSearch
                  filterOption={(inputValue, option) => {
                    return option?.children?.indexOf(inputValue as any) > -1
                  }}
                >
                  {stewardList.map(item => (
                    <Select.Option key={item.userId} value={item.userId}>
                      {`${item.name}(${item.mobile})`}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col offset={1} span={10}>
              <Form.Item label={'服务单状态'} name={'orderStatusList'}>
                <Select placeholder={'请选择'} mode={'multiple'} maxTagCount={3}>
                  {orderStatusArr.map(item => (
                    <Select.Option value={item.id} key={item.id}>
                      {item.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item label={'城市'} name={'cityCodeList'}>
                <Select
                  options={cityList}
                  fieldNames={{label: 'name', value: 'code'}}
                  placeholder={'请选择'}
                  showSearch
                  mode={'multiple'}
                  filterOption={(inputValue, option: any) => option.name?.indexOf(inputValue) > -1}
                  allowClear
                />
              </Form.Item>
            </Col>
          </Row>
          <Row justify={'end'}>
            <Space>
              <Button
                type={'primary'}
                htmlType={'submit'}
                onClick={() => {
                  const cityCodeList = form.getFieldValue('cityCodeList') ?? []
                  setCenter(cityList.find(item => item.code === cityCodeList[0]).name ?? '上海')
                }}
              >
                查询
              </Button>
              <Button htmlType={'reset'} onClick={() => setCenter('上海')}>
                重置
              </Button>
            </Space>
          </Row>
        </Form>
      </div>
      <div style={{flex: 1}}>
        <Map style={{height: '100%'}} center={center} enableScrollWheelZoom zoom={12}>
          {list.map(item => (
            <React.Fragment key={item.communityCode}>
              <CustomOverlay position={new BMapGL.Point(item.lng, item.lat)} map={null}>
                <div
                  onClick={() => {
                    searchOrderList({communityCode: item.communityCode, pageNum: 1, pageSize: 10})
                    setInfo(item)
                  }}
                  className={styles.content}
                  style={{
                    backgroundColor: info.communityCode === item.communityCode ? '#00B7AE' : '#FFFFFF',
                    color: info.communityCode === item.communityCode ? '#FFFFFF' : '#000000',
                    boxShadow:
                      info.communityCode !== item.communityCode ? '0px 4px 8px 0px #DFE7EC' : '0px 4px 8px 0px #B9D4D3',
                  }}
                >
                  <img
                    src={info.communityCode === item.communityCode ? orderActive : order}
                    width={24}
                    height={24}
                    alt={'icon'}
                  />
                  <span style={{marginLeft: 7}}>{`${item.communityName}(${item.orderNum}单)`}</span>
                  {!!item.stewardNameList?.length && (
                    <Tooltip title={<span>{item.stewardNameList.join(',')}</span>}>
                      <span style={{color: '#00B7AE'}}>
                        {item.stewardNameList.length > 1 ? item.stewardNameList[0] + '...' : item.stewardNameList[0]}
                      </span>
                    </Tooltip>
                  )}
                  <div
                    style={{
                      position: 'absolute',
                      bottom: 0,
                      left: '50%',
                      transform: 'translate(-50%, 100%)',
                      border: `6px solid transparent`,
                      borderTopColor: info.communityCode === item.communityCode ? '#00B7AE' : '#FFFFFF',
                    }}
                  />
                </div>
              </CustomOverlay>
            </React.Fragment>
          ))}
        </Map>
      </div>

      {/* 找单详情 */}
      <Drawer
        open={!!info.communityCode}
        width={800}
        title={'找单详情'}
        destroyOnClose
        onClose={() => setInfo({} as StewardOrderCommunityMapVO)}
      >
        <DividerLine title='基本信息' bottom={20} />
        <Descriptions column={2}>
          <Descriptions.Item label={'小区/公寓名称'}>{info.communityName}</Descriptions.Item>
          <Descriptions.Item label={'服务单数量'}>{info.orderNum}</Descriptions.Item>
        </Descriptions>
        <DividerLine
          title='详细信息'
          top={10}
          bottom={20}
          right={
            <Button
              type={'link'}
              onClick={async () => {
                if (selectedRows.length === 0) return message.error('请选择服务单')
                if (selectedRows.some(item => item.connectionCheckStatus === '1'))
                  return message.error('所选服务单中存在待处理的电联核实申请，请检查后重新分配')
                if (selectedRows.some(item => !!item.cancelAuditId)) return message.error('不可重新分配待取消服务单')
                await show(selectedRows)
                setSelectedRows([])
                searchOrderList({communityCode: info.communityCode, pageNum: 1, pageSize: 10})
              }}
            >
              重新分配
            </Button>
          }
        />
        <Table
          rowKey={'orderId'}
          dataSource={orderList}
          rowSelection={{
            type: 'checkbox',
            selectedRowKeys: selectedRows.map(item => item.orderId),
            onChange(_, selectedRows) {
              setSelectedRows(selectedRows)
            },
          }}
          columns={[
            {title: 'ID', dataIndex: 'orderId'},
            {
              title: '服务类型',
              render: record => `${record.categoryFirName}-${record.categorySecName}-${record.categoryThiName}`,
            },
            {title: '状态', dataIndex: 'orderStatus', width: 80, render: text => orderStatus[text]},
            {title: '当前服务人员', dataIndex: 'cleanerName', width: 120, render: text => text ?? '/'},
            {title: '管家', width: 100, dataIndex: 'stewardName'},
            {
              title: '操作',
              render: record => {
                return (
                  <Button
                    type={'link'}
                    onClick={() => {
                      history.push({pathname: '/steward/detail', state: {orderId: record.orderId}})
                      setInfo({} as StewardOrderCommunityMapVO)
                    }}
                    style={{paddingLeft: 0, paddingRight: 0}}
                  >
                    查看
                  </Button>
                )
              },
            },
          ]}
          loading={stewardModel.stewardAdminOrderList.useLoading()}
          pagination={{
            total,
            position: ['bottomCenter'],
            onChange: (pageNum, pageSize) => {
              searchOrderList({communityCode: info.communityCode, pageNum, pageSize})
            },
            showSizeChanger: true,
          }}
        />
      </Drawer>

      {contextHolder}
    </div>
  )
}

export default MapApiLoaderHOC({ak})(MapSearchOrderList)
