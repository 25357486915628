import React, {useCallback, useEffect, useRef, useState} from 'react'
import commonStyles from '@/assets/styles/common.module.less'
import {Button, Col, DatePicker, Form, Input, message, Pagination, Row, Space, Table} from 'antd'
import CutService from '../components/CutService'
import {stewardModel} from '@/store/models/Steward'
import {useCodeSync} from '@/store/models/Common'
import {usePermission} from '@/components/Permission'
import {useAppHistory} from '@/common/hooks/useRouter'
import CutServiceDetail from '../components/CutServiceDetail'
import moment from 'moment'
import {FORMAT_DATETIME} from '@/common/date'

interface ClientServiceListProps {
  userId: string
}
function ClientServiceList({userId}: ClientServiceListProps, ref) {
  const history = useAppHistory()
  const [form] = Form.useForm()
  const [auth] = usePermission()
  const [list, setList] = useState<StewardOrderQueryVO[]>([])
  const orderStatusType = useCodeSync('steward.orderStatus')
  const addRef = useRef(null)
  const [cutVisible, setCutVisible] = useState(null)

  const [total, setTotal] = useState(0)
  const search = useCallback(() => {
    const formValues = form.getFieldsValue(true)
    stewardModel.stewardAdminOrderList({...formValues, customerId: userId}).then(({response: {data, total}}) => {
      setList(
        data.map(item => {
          return {
            ...item,
            orderMaker: item.orderMakerType === 'lessor' ? item.lessorName : item.lesseeName,
            orderMakerMobile: item.orderMakerType === 'lessor' ? item.lessorMobile : item.lesseeMobile,
            orderCategory: [item.categoryFirName, item.categorySecName, item.categoryThiName]
              .filter(item => item)
              .join('-'),
          }
        })
      )
      setTotal(total)
    })
  }, [form, userId])

  useEffect(() => {
    search()
  }, [search])

  return (
    <div>
      <Form
        form={form}
        onFinish={search}
        onReset={search}
        initialValues={{
          pageNum: 1,
          pageSize: 10,
          timeMakeBegin: moment().subtract(1, 'month').format(FORMAT_DATETIME),
          timeMakeEnd: moment().format(FORMAT_DATETIME),
        }}
      >
        <div className={commonStyles.filterSection}>
          <Row>
            <Col>
              <Form.Item label={'名称'} name={'propertyAddr'}>
                <Input placeholder='请输入房源标题' allowClear />
              </Form.Item>
            </Col>
            <Col offset={1}>
              <Form.Item
                label={'下单时间'}
                name={'timeMakeBegin'}
                getValueFromEvent={(_, formatString) => {
                  form.setFieldValue('timeMakeEnd', formatString?.[1])
                  return formatString?.[0]
                }}
                getValueProps={value => ({
                  value: value ? [moment(value), moment(form.getFieldValue('timeMakeEnd'))] : value,
                })}
              >
                <DatePicker.RangePicker showTime />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Item label={'服务单ID'} name={'orderId'}>
                <Input placeholder='请输入服务单ID' allowClear />
              </Form.Item>
            </Col>
          </Row>
          <Row justify='end'>
            <Space>
              <Button htmlType={'reset'}>重置</Button>
              <Button type='primary' htmlType='submit' onClick={() => form.setFieldsValue({pageNum: 1, pageSize: 10})}>
                查询
              </Button>
            </Space>
          </Row>
        </div>
        <div className={commonStyles.filterSection}>
          <Table
            style={{marginBottom: 10}}
            rowKey={'orderId'}
            loading={false}
            dataSource={list}
            scroll={{x: 'max-content'}}
            columns={[
              {title: 'ID', dataIndex: 'orderId'},
              {title: '下单人', dataIndex: 'orderMakerName'},
              {title: '类别', dataIndex: 'orderCategory'},
              {title: '下单人手机号', dataIndex: 'orderMakerMobile'},
              {title: '下单时间', dataIndex: 'timeMake'},
              ...(form.getFieldValue('orderStatus') === '5'
                ? [{title: '服务单完成时间', dataIndex: 'timeFinished'}]
                : []),
              {title: '房源', dataIndex: 'propertyAddr'},
              {title: '管家', dataIndex: 'stewardName'},
              {
                title: '状态',

                dataIndex: 'orderStatus',
                render: tags => orderStatusType[tags],
              },
              {
                title: '操作',
                dataIndex: 'orderId',
                fixed: 'right',
                render: (value, record) => (
                  <Space>
                    {auth('001041002') && (
                      <Button
                        type={'link'}
                        size={'small'}
                        onClick={() =>
                          history.push({
                            pathname: '/steward/detail',
                            state: {orderId: value},
                          })
                        }
                        style={{padding: 0}}
                      >
                        查看
                      </Button>
                    )}

                    {['96', '99'].includes(record.orderStatus) && (
                      <>
                        <Button
                          type={'link'}
                          style={{padding: 0}}
                          onClick={() => setCutVisible({orderId: record.orderId, teamPid: record.teamPid})}
                        >
                          查看扣款
                        </Button>
                        <Button
                          type={'link'}
                          style={{padding: 0}}
                          onClick={async () => {
                            const {orderId, cleanTag} = record
                            if (cleanTag === 0) {
                              const {
                                response: {data},
                              } = await stewardModel.stewardOrderChargeDetail({orderId})
                              if (data && ['1', '3'].includes(data.auditStatus)) {
                                return message.warning('请联系管家主管或者总监，存在待审核或审核中的记录！')
                              }
                            }
                            if (cleanTag === 1) {
                              const {
                                response: {data},
                              } = await stewardModel.stewardOrderChargeCleanDetail({orderId})
                              if (data && ['1', '3'].includes(data.recordStatus)) {
                                return message.warning('请联系保洁主管，存在待扣款或复核中的记录！')
                              }
                            }
                            addRef.current?.add(record)
                          }}
                        >
                          添加扣款
                        </Button>
                      </>
                    )}
                  </Space>
                ),
              },
            ]}
            pagination={false}
          />

          <Form.Item
            noStyle
            shouldUpdate={(prev, next) => prev.pageNum !== next.pageNum || prev.pageSize !== next.pageSize}
          >
            {f => (
              <Pagination
                total={total}
                current={f.getFieldValue('pageNum')}
                pageSize={f.getFieldValue('pageSize')}
                style={{textAlign: 'center'}}
                showSizeChanger
                onChange={(pageNum, pageSize) => {
                  f.setFieldsValue({pageNum, pageSize})
                  search()
                }}
              />
            )}
          </Form.Item>
        </div>
      </Form>

      {/* 服务单扣款详情 */}
      <CutServiceDetail
        orderId={cutVisible?.orderId}
        teamPid={cutVisible?.teamPid}
        onCancel={() => {
          setCutVisible(null)
        }}
      />

      <CutService
        onSuccess={() => {
          search()
        }}
        ref={addRef}
      />
    </div>
  )
}

export default React.forwardRef(ClientServiceList)
