import {useCountDown} from '@/common/hooks/countDown'
import {Button, Input, Modal} from 'antd'
import React, {useState} from 'react'

interface ConfirmVerifyProps {
  onOk: (code: string) => void
  send: () => void
  visible: boolean
  onCancel: () => void
}
function _ConfirmVerify({onOk, send, visible, onCancel}: ConfirmVerifyProps) {
  const {countDown, startCountDown, stopCountDown} = useCountDown()
  const [verifyCode, setVerifyCode] = useState('')

  return (
    <Modal
      visible={visible}
      title={'短信验证'}
      destroyOnClose
      afterClose={() => {
        stopCountDown()
      }}
      onOk={() => onOk(verifyCode)}
      onCancel={onCancel}
      centered
    >
      <Input
        placeholder='请输入六位短信验证码'
        maxLength={6}
        style={{width: '60%'}}
        onChange={e => {
          setVerifyCode(e.target.value)
        }}
        allowClear
        suffix={
          <Button
            type={'link'}
            onClick={async () => {
              if (countDown < 1) {
                send()
                startCountDown()
              }
            }}
          >
            {countDown > 0 ? `${countDown}s` : '获取验证码'}
          </Button>
        }
      />
    </Modal>
  )
}

export default React.forwardRef(_ConfirmVerify)
