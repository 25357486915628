import {Model} from '@redux-model/web'
import {$api} from '@/store/service'

export interface SysUserModel {}

class SysUser extends Model<SysUserModel> {
  initReducer() {
    return {}
  }

  //查询系统用户列表
  querySysUserList = $api.action((params: Partial<SysListDTO>) =>
    this.post<ApiResponse<SysUserVO[]>>('/sys-user/list').body({
      pageNum: 1,
      pageSize: 10,
      pageable: false,
      ...params,
    })
  )

  // 用户详情  |  userId
  sysUserDetail = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<SysUserVO>>('/sys-user/detail').body(args)
  )

  //新增或更改系统用户
  upsertSysUser = $api.action((params: SysUserDTO) => this.post<ApiResponse>('/sys-user/upsert').body(params))

  //改变用户状态
  changeUserStatus = $api.action(params => this.post<ApiResponse>('/sys-user/change-status').body(params))

  //重置密码
  resetPassword = $api.action(params => this.post<ApiResponse>('/sys-user/reset-password').body(params))

  // 管家列表(默认查询当前用户的组织往下)  |  object:{orgId:组织id,name:人员姓名,excludeSelf:是否排除自己bool}
  sysUserQuerySteward = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<SysUserSimpleVO[]>>('/sys-user/query-steward').body(args)
  )

  // 虚拟号码列表
  privateNumberAxList = $api.action((args: PrivateNumberBindAxListDTO) =>
    this.post<ApiResponse<PrivateNumberBindAxVO[]>>('/private-number/ax-list').body(args)
  )

  // 新增/编辑虚拟号码
  privateNumberBindAx = $api.action((args: PrivateNumberBindAxDTO) => this.post('/private-number/bind-ax').body(args))

  // 删除虚拟号码  |  object:{privateNum:隐私号码}
  privateNumberDeleteAx = $api.action((args: {privateNum: string}) => this.post('/private-number/delete-ax').body(args))

  // 呼叫管理列表
  privateNumberFee = $api.action((args: PrivateNumberFeeListDTO) =>
    this.post<ApiResponse<PrivateNumberFeeVO[]>>('/private-number/fee').body(args)
  )

  // 呼叫管理列表
  privateNumberFeeGet = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<PrivateNumberFeeVO>>('/private-number/fee/get').body(args)
  )

  // 保洁人员列表(当前用户组织往上的保洁人员(含自己))
  sysUserQueryCleaner = $api.action(() => this.post<ApiResponse<SysUserSimpleVO[]>>('/sys-user/query-cleaner'))

  // 系统用户列表
  sysUserList = $api.action((args: Partial<SysListDTO>) =>
    this.post<ApiResponse<SysUserVO[]>>('/sys-user/list').body({
      pageNum: 1,
      pageSize: 10,
      pageable: false,
      ...args,
    })
  )

  // 查询客户经理列表
  queryCusManager = $api.action(() => this.post<ApiResponse<SysUserVO[]>>('/sys-user/query-cus-manager'))

  // 查询系统用户列表(仓库管理权限)
  sysUserListUserByPermissionOfWarehouse = $api.action(() =>
    this.post<ApiResponse<SysUserSimpleVO[]>>('/sys-user/list-user-by-permission-of-warehouse')
  )

  // 获取某个后台人员某天的定位列表  |  object:{stewardId:管家id,reportDate:上报日期}
  sysUserLocationListRecord = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<StewardLocationVO[]>>('/sys-user/location/list-record').body(args)
  )

  // 通过用户名查询是否有此用户  |  object:{userName:用户名}
  sysUserCheckByUserName = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<boolean>>('/sys-user/check/by-user-name').body(args)
  )

  // 通过用户名查询是否有此用户  |  object:{name:姓名}
  sysUserGetNamePinyin = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<string>>('/sys-user/get/name/pinyin').body(args)
  )

  // 用户绑定绩效
  stewardSalaryPlanUserBatchInsert = $api.action((args: StewardSalaryPlanUserBatchInsertDTO) =>
    this.post<ApiResponse<Record<string, any>>>('/steward/salary-plan-user/batch/insert').body(args)
  )
}

export const sysUserModel = new SysUser()
