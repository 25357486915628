import React, {useCallback, useEffect, useImperativeHandle, useState} from 'react'
import {Button, Col, Form, Input, message, Row, Space, Table} from 'antd'
import commonStyles from '@/assets/styles/common.module.less'
import {useAppHistory} from '@/common/hooks/useRouter'
import {ebassModel} from '@/store/models/Ebaas'
import {arrayToObject} from '@/store/models/Common'
import ebaas from '@/store/models/Common/ebaasCodes'
import {useFocusEffect} from '@/common/hooks/useFocusEffect'
import {getPaginationProps} from '@/common/biz/getPaginationProps'

const openStatusCode = arrayToObject(ebaas.openStatus)

const StoreApartmentManage = React.forwardRef((props: {storeNo: string}, ref) => {
  const storeNo = props.storeNo
  const history = useAppHistory()
  const [form] = Form.useForm()

  const [apartmentTotal, setApartmentTotal] = useState(0)
  const [apartmentList, setApartmentList] = useState<EbaasApartmentVO[]>([])

  const queryApartmentList = useCallback(() => {
    const formValues = form.getFieldsValue(true)
    ebassModel.ebaasApartmentList({storeNo, ...formValues}).then(({response: {data, total}}) => {
      // console.log('data', data)
      setApartmentList(data)
      setApartmentTotal(total)
    })
  }, [form, storeNo])

  useEffect(() => {
    queryApartmentList()
  }, [queryApartmentList])

  useFocusEffect(() => {
    queryApartmentList()
  })

  const onSubmit = useCallback(
    async condoId => {
      await ebassModel.ebaasApartmentSubmit({condoId})
      message.success('提交成功！')
      queryApartmentList()
    },
    [queryApartmentList]
  )

  const submitLoading = ebassModel.ebaasApartmentSubmit.useLoadings()

  useImperativeHandle(ref, () => ({
    refresh: () => queryApartmentList(),
  }))

  return (
    <Form
      form={form}
      initialValues={{pageNum: 1, pageSize: 10, pageable: true}}
      onFinish={queryApartmentList}
      onReset={queryApartmentList}
    >
      <div className={commonStyles.filterSection}>
        <Row>
          <Col span={7}>
            <Form.Item label={'公寓名称'} name={'name'}>
              <Input placeholder={'请输入'} allowClear />
            </Form.Item>
          </Col>
        </Row>
        <Row justify={'end'}>
          <Space>
            <Button htmlType={'reset'}>重置</Button>
            <Button htmlType={'submit'} type={'primary'} onClick={() => form.setFieldsValue({pageNum: 1})}>
              查询
            </Button>
          </Space>
        </Row>
      </div>

      <div className={commonStyles.tableSection}>
        <Table
          rowKey={'condoId'}
          dataSource={apartmentList}
          columns={[
            {title: '公寓名称', dataIndex: 'name'},
            {title: '地址', dataIndex: 'address'},
            {title: '绑定管家', dataIndex: 'userName'},
            {title: '房源数量（间）', dataIndex: 'propertyNum'},
            {title: '营业状态', dataIndex: 'openStatus', render: value => openStatusCode[value]},
            {title: '天猫更新时间', dataIndex: 'latestSyncTime'},
            {
              title: '操作',
              render: (_, value) => {
                return (
                  <Space>
                    <Button
                      type={'link'}
                      style={{padding: 0}}
                      onClick={() => onSubmit(value.condoId)}
                      loading={submitLoading.pick(value.condoId)}
                    >
                      提交
                    </Button>
                    <Button
                      type={'link'}
                      style={{padding: 0}}
                      onClick={() => history.push('/store-manage/apartment-detail', {condoId: value.condoId, storeNo})}
                    >
                      编辑
                    </Button>
                    <Button
                      type={'link'}
                      style={{padding: 0}}
                      onClick={() => history.push('/store-manage/property-info', {condoId: value.condoId})}
                    >
                      房源设置
                    </Button>
                  </Space>
                )
              },
            },
          ]}
          pagination={getPaginationProps(form, apartmentTotal)}
        />
      </div>
    </Form>
  )
})

export default StoreApartmentManage
