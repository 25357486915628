import React, {useCallback, useEffect, useState} from 'react'
import {Page} from '@/components/Page'
import {Button, Form, message, Modal, Radio, Space, Table, Tabs, Typography, Upload} from 'antd'
import commonStyles from '@/assets/styles/common.module.less'
import {ContainerOutlined} from '@ant-design/icons'
import {Breadcrumb} from '@/components/Breadcrumb'
import {saleModel} from '@/store/models/Sale'
import {getPaginationProps} from '@/common/biz/getPaginationProps'
import {$api, createHeaders} from '@/store/service'
import {usePermission} from '@/components/Permission'

export default function FileManagement() {
  const [auth] = usePermission()
  const [form] = Form.useForm()
  const [list, setList] = useState<SaleCustomerContractAttachVO[]>([])
  const [total, setTotal] = useState(0)
  const [visible, setVisible] = useState(false)
  const [uploadLoading, setUploading] = useState(false)
  const queryList = useCallback(() => {
    const {serviceType, pageNum, pageSize, pageable} = form.getFieldsValue(true)
    saleModel
      .saleCustomerContractListAttach({serviceType, pageNum, pageSize, pageable} as SaleCustomerContractAttachQueryDTO)
      .then(({response: {data, total}}) => {
        setList(data)
        setTotal(total)
      })
  }, [form])

  useEffect(() => {
    queryList()
  }, [queryList])

  return (
    <Page
      breadcrumb={
        <Breadcrumb
          right={
            <Space>
              {visible && (
                <Button
                  type={'primary'}
                  loading={uploadLoading}
                  onClick={async () => {
                    await form.validateFields()
                    const {serviceType, serviceTypeSub, file} = form.getFieldsValue(true)
                    if (!serviceTypeSub) return message.warning('请选择收费类型！')
                    if (!file) return message.warning('请选择上传的文件！')
                    setUploading(true)
                    const fd = new FormData()
                    fd.append('file', file)
                    fd.append('serviceType', serviceType)
                    fd.append('serviceTypeSub', serviceTypeSub)
                    const res: any = await $api
                      .postAsync({
                        uri: '/sale/customer/contract/upload-attach',
                        body: fd,
                        requestOptions: {
                          headers: {
                            ...createHeaders(),
                            'Content-Type': 'multipart/form-data',
                          },
                        },
                      })
                      .catch(e => {
                        message.error(e.message)
                      })
                      .finally(() => {
                        setUploading(false)
                      })

                    if (res) {
                      const {
                        response: {code},
                      } = res
                      if (code === 200) {
                        message.success('保存成功！')
                      }
                    }
                    setVisible(false)
                    form.setFieldsValue({serviceTypeSub: undefined, file: undefined})
                    form.submit()
                  }}
                >
                  保存
                </Button>
              )}
              {auth('002122002') && (
                <Button
                  onClick={() => {
                    setVisible(!visible)
                    form.setFieldsValue({serviceTypeSub: undefined, file: undefined})
                  }}
                >
                  {visible ? '取消编辑' : '编辑'}
                </Button>
              )}
            </Space>
          }
        />
      }
    >
      <Form
        form={form}
        onFinish={queryList}
        initialValues={{pageNum: 1, pageSize: 10, pageable: true, serviceType: 'steward'}}
        labelCol={{flex: '150px'}}
      >
        <Form.Item shouldUpdate style={{marginBottom: 0}}>
          {() => (
            <Tabs
              className={commonStyles.tabs}
              onChange={value => {
                form.setFieldsValue({serviceType: value})
                form.submit()
              }}
            >
              <Tabs.TabPane key={'steward'} tab={'管家服务'} />
              <Tabs.TabPane key={'clean'} tab={'保洁服务'} />
            </Tabs>
          )}
        </Form.Item>

        <div className={commonStyles.filterSection}>
          <Form.Item label={'收费类型'} required shouldUpdate style={{marginBottom: 0}}>
            {f => {
              const serviceType = f.getFieldValue('serviceType')
              return serviceType === 'clean' ? (
                <Form.Item name={'serviceTypeSub'}>
                  <Radio.Group disabled={!visible}>
                    <Radio value={'1'}>按次计费</Radio>
                    <Radio value={'2'}>按月计费</Radio>
                  </Radio.Group>
                </Form.Item>
              ) : (
                <Form.Item name={'serviceTypeSub'}>
                  <Radio.Group disabled={!visible}>
                    <Radio value={'1'}>全包收费</Radio>
                    <Radio value={'2'}>按次计费</Radio>
                  </Radio.Group>
                </Form.Item>
              )
            }}
          </Form.Item>
          <Form.Item label={'牛油果服务合同附件'} style={{marginBottom: 0}} shouldUpdate wrapperCol={{flex: '384px'}}>
            {f => {
              const file = f.getFieldValue('file')
              return (
                <Upload.Dragger
                  name='file'
                  beforeUpload={file => {
                    f.setFieldsValue({file})
                    return false
                  }}
                  onRemove={() => {
                    f.setFieldsValue({file: undefined})
                  }}
                  accept={'.pdf'}
                  disabled={!visible}
                  fileList={file ? [file] : []}
                >
                  <ContainerOutlined style={{color: '#00B7AE', fontSize: '36px'}} />
                  <p style={{fontSize: 16, marginTop: 10, marginBottom: 5}}>点击或将文件拖拽到这里上传</p>
                  <p style={{color: 'rgba(0, 0, 0, 0.4500)'}}>支持扩展名：.pdf</p>
                </Upload.Dragger>
              )
            }}
          </Form.Item>
        </div>
        <div className={commonStyles.tableSection}>
          <Form.Item label={' '} colon={false}>
            <Table
              rowKey={'attachId'}
              dataSource={list}
              columns={[
                {title: '时间', dataIndex: 'insertTime'},
                {title: '操作人', dataIndex: 'operatorName'},
                {title: '文档', dataIndex: 'attachFilename'},
                {
                  title: '操作',
                  render: record => {
                    return (
                      <Space>
                        <Typography.Link href={record.attachPath} target={'_blank'}>
                          查看
                        </Typography.Link>
                        <Button
                          type='link'
                          onClick={() => {
                            Modal.confirm({
                              title: '提示',
                              content: '是否确认下载当前附件？',
                              centered: true,
                              icon: null,
                              onOk: () => {
                                const a = document.createElement('a')
                                a.download = record.attachFilename
                                a.href = `${record.attachPath}?response-content-type=application/octet-stream`
                                document.body.appendChild(a)
                                a.click()
                                document.body.removeChild(a)
                              },
                            })
                          }}
                        >
                          下载
                        </Button>
                      </Space>
                    )
                  },
                },
              ]}
              pagination={getPaginationProps(form, total)}
            />
          </Form.Item>
        </div>
      </Form>
    </Page>
  )
}
