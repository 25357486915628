import React, {useCallback, useEffect, useState} from 'react'
import {Button, Cascader, Col, Form, Input, message, Modal, Radio, Row, Select, Space, Table, Tabs} from 'antd'
import transferStyles from './index.module.less'
import {propertyModel} from '@/store/models/Property'
import {customerModel} from '@/store/models/Customer'
import {getPaginationProps} from '@/common/biz/getPaginationProps'
import {commonModel, useCodeSync} from '@/store/models/Common'
import {useAppHistory} from '@/common/hooks/useRouter'
import commonStyles from '@/assets/styles/common.module.less'

// const communityTypeCode = {'1': '分散式房源', '2': '集中式公寓'}
const rentTypeCode = {'1': '整租', '2': '合租', '3': '公寓房'}

export default function PropertyTransfer({userId}: {userId: string}) {
  const history = useAppHistory()
  const rentStatusCode = useCodeSync('rent.status')

  // 小区
  const [selectedCommunity, setSelectedCommunity] = useState<CommunityVO[]>([])
  const [communityType, setCommunityType] = useState('1')
  const [propertyCommunity, setpropertyCommunity] = useState<CommunityVO[]>([])
  const [listForm] = Form.useForm()

  const queryCommunityAggregateWithCount = useCallback(() => {
    propertyModel
      .propertyCommunityAggregateWithCount({...listForm.getFieldsValue(), lessorId: userId, communityType})
      .then(({response: {data}}) => {
        setpropertyCommunity(data)
      })
  }, [communityType, userId, listForm])

  useEffect(() => {
    queryCommunityAggregateWithCount()
  }, [queryCommunityAggregateWithCount])

  // 房源
  const [selectedProperty, setSelectedProperty] = useState<PropertyInfoVO[]>([])
  const [propertyList, setPropertyList] = useState<PropertyInfoVO[]>([])
  const [cityList, setCityList] = useState<{label: string; value: string}[]>()

  const [form] = Form.useForm()
  const [total, setTotal] = useState(0)

  const queryPropertyList = useCallback(() => {
    const {rentType = '2', ...rest} = form.getFieldsValue(true)
    propertyModel.queryPropertyList({lessorId: userId, rentType, ...rest}).then(({response: {data, total}}) => {
      setPropertyList(data)
      setTotal(total)
    })
  }, [form, userId])

  useEffect(() => {
    queryPropertyList()
  }, [queryPropertyList])

  useEffect(() => {
    form.setFieldsValue({rentType: '2'})
  }, [form])

  // 转移归属团队
  const [transferVisible, setTransferVisible] = useState(false)
  const [treeForm] = Form.useForm()
  const [teamTree, setTeamTree] = useState<TeamTreeNodeVO[]>([])

  useEffect(() => {
    customerModel.customerTeamGetTeamTree({ownerId: userId}).then(({response: {data}}) => setTeamTree(data))
  }, [userId])

  // 城市
  useEffect(() => {
    commonModel.queryCityCode({type: '2', parentCode: null}).then(({response: {data}}) => {
      setCityList(data?.map(item => ({label: item.name, value: item.code})))
    })
  }, [])

  return (
    <>
      <Tabs
        tabBarStyle={{backgroundColor: '#fff', paddingLeft: '28px'}}
        onChange={() => {
          setSelectedProperty([])
          setSelectedCommunity([])
        }}
      >
        <Tabs.TabPane tab={'小区'} key={'1'}>
          <div className={transferStyles.tabContainer}>
            <div className={transferStyles.header}>
              <Space align={'center'}>
                类型：
                <Radio.Group value={communityType} onChange={({target: {value}}) => setCommunityType(value)}>
                  <Radio value={'1'}>分散式房源</Radio>
                  <Radio value={'2'}>集中式房源</Radio>
                </Radio.Group>
              </Space>
              {communityType === '2' && (
                <Button
                  type={'primary'}
                  onClick={() => {
                    if (selectedCommunity.length === 0) return message.warn('请选择小区')
                    setTransferVisible(true)
                  }}
                >
                  转移归属团队
                </Button>
              )}
            </div>

            {/* 筛选 */}
            <div className={commonStyles.filterSection} style={{padding: 0}}>
              <Form
                form={listForm}
                labelCol={{flex: '120px'}}
                initialValues={{pageNum: 1, pageSize: 10, pageable: true}}
                onFinish={queryCommunityAggregateWithCount}
                onReset={queryCommunityAggregateWithCount}
              >
                <Row gutter={30} style={{justifyContent: 'space-between'}}>
                  {communityType === '1' ? (
                    <Col span={7}>
                      <Form.Item name={'communityName'} label={'小区名称'}>
                        <Input placeholder={'小区名称'} allowClear />
                      </Form.Item>
                    </Col>
                  ) : (
                    <Col span={14}>
                      <Row>
                        <Col span={12}>
                          <Form.Item name={'cityCode'} label={'城市'}>
                            <Select placeholder={'请选择'} allowClear options={cityList} />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item name={'communityName'} label={'公寓名称'}>
                            <Input placeholder={'公寓名称'} allowClear />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>
                  )}

                  <Col>
                    <Space>
                      <Button
                        type={'primary'}
                        htmlType={'submit'}
                        onClick={() => listForm.setFieldsValue({pageNum: 1})}
                      >
                        查询
                      </Button>
                      <Button htmlType={'reset'}>重置</Button>
                    </Space>
                  </Col>
                </Row>
              </Form>
            </div>

            <Table
              rowKey={'code'}
              columns={[
                ...(communityType === '2'
                  ? [
                      {title: '城市', dataIndex: 'cityName'},
                      {title: '公寓名称', dataIndex: 'name'},
                      {title: '房源数量', dataIndex: 'propertyCount'},
                      {title: '所属团队', dataIndex: 'teamName'},
                    ]
                  : [
                      {title: '小区名称', dataIndex: 'name'},
                      {title: '房源数量', dataIndex: 'propertyCount'},
                    ]),
              ]}
              dataSource={propertyCommunity}
              rowSelection={
                communityType === '2' && {
                  type: 'checkbox',
                  selectedRowKeys: selectedCommunity.map(item => item.code),
                  onChange: (_, rows) => setSelectedCommunity(rows),
                }
              }
              pagination={{position: ['bottomCenter']}}
            />
          </div>
        </Tabs.TabPane>
        <Tabs.TabPane tab={'房源'} key={'2'}>
          <div className={transferStyles.tabContainer}>
            <div className={transferStyles.header}>
              <Form form={form} initialValues={{rentType: '2', pageNum: 1, pageSize: 10}} onFinish={queryPropertyList}>
                <Space align={'center'}>
                  类型：
                  <Form.Item name={'rentType'} style={{marginBottom: 0}}>
                    <Radio.Group
                      onChange={({target: {value}}) => {
                        form.setFieldsValue({pageNum: 1})
                        form.submit()
                      }}
                    >
                      <Radio value={'2'}>分散式合租</Radio>
                      <Radio value={'1'}>分散式整租</Radio>
                      <Radio value={'3'}>集中式公寓</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Space>
              </Form>
              {['1', '2'].includes(form.getFieldValue('rentType')) && (
                <Button
                  type={'primary'}
                  onClick={() => {
                    if (selectedProperty.length === 0) return message.warn('请选择房源')
                    setTransferVisible(true)
                  }}
                >
                  转移归属团队
                </Button>
              )}
            </div>

            {form.getFieldsValue().rentType === '3' ? (
              <div className={commonStyles.filterSection} style={{padding: 0}}>
                <Form
                  form={form}
                  labelCol={{flex: '120px'}}
                  initialValues={{pageNum: 1, pageSize: 10, pageable: true}}
                  onFinish={queryPropertyList}
                  onReset={queryPropertyList}
                >
                  <Row gutter={30} style={{justifyContent: 'space-between'}}>
                    <Col span={18}>
                      <Row>
                        <Col span={8}>
                          <Form.Item name={'cityCode'} label={'城市'}>
                            <Select placeholder={'请选择'} allowClear options={cityList} />
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Form.Item name={'title'} label={'公寓名称'}>
                            <Input placeholder={'公寓名称'} allowClear />
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Form.Item name={'houseNo'} label={'房间号'}>
                            <Input placeholder={'房间号'} allowClear />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>

                    <Col>
                      <Space>
                        <Button
                          type={'primary'}
                          htmlType={'submit'}
                          onClick={() => listForm.setFieldsValue({pageNum: 1})}
                        >
                          查询
                        </Button>
                        <Button htmlType={'reset'}>重置</Button>
                      </Space>
                    </Col>
                  </Row>
                </Form>
              </div>
            ) : null}

            <Table
              rowKey={'propertyCode'}
              columns={[
                {title: 'ID', dataIndex: 'propertyCode'},
                {title: '城市', dataIndex: 'cityName'},
                {title: '公寓名称', dataIndex: 'title'},
                {title: '楼栋', dataIndex: 'buildingNo'},
                {title: '层数', dataIndex: 'floor'},
                {title: '房间号', dataIndex: 'houseNo'},
                {title: '户型', dataIndex: 'bedroom', render: (value, {parlor}) => value + '室' + parlor + '厅'},
                {title: '房源类别', dataIndex: 'rentType', render: value => rentTypeCode[value]},
                {title: '所属团队', dataIndex: 'teamName'},
                {title: '添加时间', dataIndex: 'insertTime'},
                {title: '状态', dataIndex: 'rentStatus', render: value => rentStatusCode[value]},
                {
                  title: '操作',
                  render: (_, value) => {
                    return (
                      <Space>
                        <Button
                          type={'link'}
                          onClick={() =>
                            history.push({
                              pathname: '/property/detail',
                              state: {propertyCode: value.propertyCode},
                            })
                          }
                          style={{padding: 0}}
                        >
                          查看
                        </Button>
                        {['1', '2'].includes(form.getFieldValue('rentType')) && (
                          <Button
                            type={'link'}
                            onClick={() => {
                              setSelectedProperty([value])
                              setTransferVisible(true)
                            }}
                            style={{padding: 0}}
                          >
                            转移
                          </Button>
                        )}
                      </Space>
                    )
                  },
                },
              ]}
              dataSource={propertyList}
              rowSelection={
                ['1', '2'].includes(form.getFieldValue('rentType')) && {
                  type: 'checkbox',
                  selectedRowKeys: selectedProperty.map(item => item.propertyCode),
                  onChange: (_, rows) => setSelectedProperty(rows),
                }
              }
              pagination={getPaginationProps(form, total)}
            />
          </div>
        </Tabs.TabPane>
      </Tabs>
      <Modal
        title={'转移归属团队'}
        visible={transferVisible}
        centered
        footer={null}
        onCancel={() => setTransferVisible(false)}
        afterClose={() => treeForm.resetFields()}
      >
        <Form
          form={treeForm}
          onFinish={async () => {
            const [, teamId] = treeForm.getFieldValue('teamId')
            await propertyModel.propertyChangeTeam({
              teamId,
              propertyCodeList: selectedProperty.map(item => item.propertyCode),
              condoIdList: selectedCommunity.map(item => item.code),
            })
            message.success('操作成功！')
            if (selectedCommunity.length) {
              setSelectedCommunity([])
              queryCommunityAggregateWithCount()
            }

            if (selectedProperty.length) {
              setSelectedProperty([])
              queryPropertyList()
            }
            setTransferVisible(false)
          }}
          onReset={() => setTransferVisible(false)}
        >
          <Form.Item name={'teamId'} rules={[{required: true, message: '请选择团队'}]}>
            <Cascader fieldNames={{label: 'name', value: 'teamId'}} options={teamTree} placeholder={'请选择团队'} />
          </Form.Item>
          <Row justify={'end'}>
            <Space>
              <Button htmlType={'reset'}>取消</Button>
              <Button type={'primary'} htmlType={'submit'}>
                确定
              </Button>
            </Space>
          </Row>
        </Form>
      </Modal>
    </>
  )
}
