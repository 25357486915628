import React, {useImperativeHandle, useState} from 'react'
import {Button, DatePicker, Form, Input, Modal, Row, Select, Space, Typography, message, Upload} from 'antd'
import {InboxOutlined} from '@ant-design/icons'
import {Album, UploadButton} from '@/components/Album'
import {baseUrl} from '@/common/config'
import {useCodeArraySync, useCodeSync} from '@/store/models/Common'
import moment from 'moment'
import {lessorModel} from '@/store/models/Lessor'
import {$api, createHeaders} from '@/store/service'

const {Text} = Typography
const {Option} = Select
function ConfirmBill(props: {onSuccess: () => void}, ref) {
  const [visible, setVisible] = useState(false)
  const [confirmForm] = Form.useForm()
  const chargeSourceArray = useCodeArraySync('team.chargeSource')
  const chargeSourceCode = useCodeSync('team.chargeSource')
  const [hasImg, setHasImg] = useState<boolean>(false)

  useImperativeHandle(ref, () => ({
    onDisplay: () => {
      setVisible(true)
    },

    onSetInfo: (detail: LessorBillVO | string[]) => {
      if (Array.isArray(detail)) {
        confirmForm.setFieldsValue({billIdList: detail})
      } else {
        const {billId, billAmtTotal, billName} = detail
        confirmForm.setFieldsValue({billIdList: [billId], billAmtTotal, billName})
      }
    },
  }))

  return (
    <Modal
      open={visible}
      title={
        <div style={{textAlign: 'center'}}>
          <Text>确认收款</Text>
        </div>
      }
      centered
      footer={null}
      onCancel={() => setVisible(false)}
      width={700}
      destroyOnClose
      afterClose={() => confirmForm.resetFields()}
    >
      <Form
        form={confirmForm}
        labelAlign={'right'}
        labelCol={{flex: '120px'}}
        wrapperCol={{span: 17}}
        onFinish={async () => {
          const {
            billPayTime,
            billIdList,
            billAmtTotal,
            billPayWayCode,
            billDetailRemark,
            billPayBatchId,
            imgUrlList,
            collectFileUrl,
            collectFileName,
          } = confirmForm.getFieldsValue(true)
          if (!imgUrlList?.length) return setHasImg(true)
          await lessorModel.userLessorBillConfirm({
            billDetailRemark,
            billPayTime: moment(billPayTime).format('YYYY-MM-DD HH:mm:ss'),
            billPayWayName: chargeSourceCode[billPayWayCode],
            billPayWayCode,
            billIdList,
            billPayAmt: billAmtTotal,
            billPayBatchId,
            imgUrl: imgUrlList.join(','),
            collectFileUrl,
            collectFileName,
          } as LessorBillConfirmDTO)
          props.onSuccess()
          message.success('收款成功')
          setVisible(false)
        }}
      >
        <Form.Item noStyle shouldUpdate>
          {f => {
            const {billName, billAmtTotal} = f.getFieldsValue(true)
            return (
              billName && (
                <>
                  <Form.Item label={'账单名称'}>
                    <Text style={{color: '#000000'}}>{billName}</Text>
                  </Form.Item>
                  <Form.Item label={'收款金额'}>
                    <Text style={{color: '#000000'}}>{billAmtTotal}</Text>
                  </Form.Item>
                </>
              )
            )
          }}
        </Form.Item>
        <Form.Item name={'billPayWayCode'} label={'收款方式'} rules={[{required: true, message: '请选择收款方式'}]}>
          <Select placeholder={'请选择'} style={{maxWidth: 210}} allowClear>
            {chargeSourceArray.map(item => (
              <Option key={item.id} value={item.id}>
                {item.label}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name={'billPayTime'} label={'收款时间'} rules={[{required: true, message: '请选择收款时间'}]}>
          <DatePicker showTime style={{width: 210}} />
        </Form.Item>
        <Form.Item label={'交易订单号'} name={'billPayBatchId'}>
          <Input placeholder={'请输入'} />
        </Form.Item>
        <Form.Item label={'照片凭证'} shouldUpdate required style={{marginBottom: 0}}>
          {form1 => {
            const imgUrlList = form1.getFieldValue('imgUrlList') ?? []
            return (
              <>
                <Album
                  limit={15}
                  multiple
                  accept={'.jpg, .jpeg, .png'}
                  action={baseUrl + '/user/lessor/bill/upload'}
                  onChange={info => {
                    if (info.file.status === 'done') {
                      const {
                        response: {data},
                      } = info.file
                      form1.setFieldsValue({imgUrlList: [...imgUrlList, data.imgUrl]})
                      setHasImg(false)
                    }
                    if (info.file.status === 'removed') {
                      form1.setFieldsValue({imgUrlList: []})
                    }
                  }}
                >
                  <UploadButton />
                </Album>
                <div
                  style={{
                    color: hasImg ? '#ff4d4f' : '#fff',
                    fontSize: '14px',
                    marginTop: '-7px',
                    transition: 'color 0.5s cubic-bezier(0.215, 0.61, 0.355, 1)',
                    overflow: 'hidden',
                  }}
                >
                  请上传图片!
                </div>
              </>
            )
          }}
        </Form.Item>
        <Form.Item label={'附件'} shouldUpdate>
          {form1 => {
            return (
              <Upload.Dragger
                maxCount={1}
                accept={'.doc, .docx, .pdf, .xlsx, .xls'}
                action={baseUrl + '/user/lessor/bill/upload'}
                customRequest={async options => {
                  const fd = new FormData()
                  fd.append('file', options.file)
                  const res: any = await $api
                    .postAsync({
                      uri: options.action,
                      body: fd,
                      requestOptions: {headers: {...createHeaders(), 'Content-Type': 'multipart/form-data'}},
                    })
                    .catch(e => {
                      message.error(e.message)
                      options.onError(e)
                    })
                  if (res) {
                    options.onProgress({percent: 100})
                    options.onSuccess(res.response)
                  }
                }}
                onChange={info => {
                  // console.log('info', info)
                  if (info.file.status === 'done') {
                    const {
                      response: {data},
                    } = info.file
                    form1.setFieldsValue({collectFileUrl: data.imgUrl, collectFileName: data.filename})
                  }
                  if (info.file.status === 'removed') {
                    form1.setFieldsValue({collectFileUrl: '', collectFileName: ''})
                  }
                }}
              >
                <p>
                  <InboxOutlined style={{fontSize: '40px'}} />
                </p>
                <p>点击或将文件拖拽到这里上传</p>
                <p>支持 .doc、.docx、.pdf、.xls、.xlsx</p>
              </Upload.Dragger>
            )
          }}
        </Form.Item>
        <Form.Item name={'billDetailRemark'} label={'备注'}>
          <Input.TextArea placeholder={'请输入备注'} allowClear autoSize={{minRows: 3}} />
        </Form.Item>
        <Row justify={'end'}>
          <Space>
            <Button onClick={() => setVisible(false)}>取消</Button>
            <Button type={'primary'} htmlType={'submit'}>
              确认收款
            </Button>
          </Space>
        </Row>
      </Form>
    </Modal>
  )
}

export default React.forwardRef(ConfirmBill)
