import React, {useCallback} from 'react'
import {Form, Input, message, Modal, Upload} from 'antd'
import {cleanModel} from '@/store/models/Clean'
import {Album, UploadButton} from '@/components/Album'
import {baseUrl} from '@/common/config'
import {onAlbumUploadEvent, onAlbumUploadProps} from '@/pages/service/utils/albumUpload'
import {STEWARD_SERVICE_THI_6} from '@/utils/clean'

function deepCheck(
  checkList: CleanOrderAttachPayloadDTO[],
  checkMode: 'off' | 'on',
  resList: CleanOrderAttachPayloadDTO[] = []
): CleanOrderAttachPayloadDTO[] {
  for (let i = 0; i < checkList.length; i++) {
    const item = checkList[i]
    if (checkMode === 'off' && item.offlineQaResult === 0) resList.push(item)
    if (checkMode === 'on' && item.onlineQaResult === 0) resList.push(item)
    if (item.subList && item.subList.length) {
      deepCheck(item.subList, checkMode, resList)
    }
  }
  return resList
}

function filterCheck(checkList: CleanOrderAttachPayloadDTO[], checkMode: 'off' | 'on'): CleanOrderAttachPayloadDTO[] {
  const resList = checkList?.reduce((acc, cur) => {
    const subList = cur?.subList?.filter(value =>
      checkMode === 'on' ? value.onlineQaResult === 0 : value.offlineQaResult === 0
    )
    return subList.length ? acc.concat([{...cur, subList}]) : acc
  }, [])
  return resList
}

export function useCleanOrderRework() {
  const [form] = Form.useForm()

  return useCallback(
    ({
      orderId,
      propertyAddr,
      categoryFirName,
      categorySecName,
      categoryThiName,
      categoryThi,
      payloadList,
      qaOfflineStatus,
      qaOfflineRate,
      qaOnlineStatus,
      onSuccess,
    }: Pick<
      CleanOrderVO,
      | 'orderId'
      | 'propertyAddr'
      | 'categoryFirName'
      | 'categorySecName'
      | 'categoryThiName'
      | 'categoryThi'
      | 'payloadList'
      | 'qaOfflineStatus'
      | 'qaOfflineRate'
      | 'qaOnlineStatus'
    > & {
      onSuccess?: () => void
    }) => {
      /**
       * 1.线场质检
       * 不合格项 第一张照片
       * 不合格项 保洁后 第一个视频
       *
       * 2.现场100%合格
       *
       * 3.线上质检
       * 不合格项 保洁后 第一张照片
       * 不合格项 保洁后 第一个视频
       */
      function toReload(resList: CleanOrderAttachPayloadDTO[], mode: 'off' | 'on', isPeriod: boolean) {
        const imgList = []
        const videoList = []
        const noteList = []
        // console.log('resList', resList)
        resList.forEach(item => {
          if (isPeriod) {
            item.subList?.forEach(value =>
              noteList.push(`${value.name}:${mode === 'off' ? value.offlineQaNotes ?? '' : value.onlineQaNotes ?? ''}`)
            )
          } else noteList.push(`${item.name}:${mode === 'off' ? item.offlineQaNotes ?? '' : item.onlineQaNotes ?? ''}`)
          if (mode === 'off') {
            if (item.offlineImageList && item.offlineImageList.length) imgList.push(item.offlineImageList[0])
          } else {
            const after = item.mediaList?.find(value => value.saveType === 2)
            if (after && after.imageList && after.imageList.length) imgList.push(after.imageList[0])
            if (after && after.videoList && after.videoList.length) videoList.push(after.videoList[0])
          }
        })
        form.setFieldsValue({imgList, videoList, orderDesc: noteList.join(',')})
      }

      // 是否是周期保洁
      const isPeriod = categoryThi === STEWARD_SERVICE_THI_6

      // 现场质检结果 && 合格率不是100%
      if (qaOfflineStatus === 1 && qaOfflineRate !== 1) {
        const resList = isPeriod ? filterCheck(payloadList ?? [], 'off') : deepCheck(payloadList ?? [], 'off')
        toReload(resList, 'off', isPeriod)
      } else if (qaOnlineStatus === 1) {
        // 线上质检结果
        const resList = isPeriod ? filterCheck(payloadList ?? [], 'on') : deepCheck(payloadList ?? [], 'on')
        toReload(resList, 'on', isPeriod)
      }

      Modal.confirm({
        title: '返工',
        icon: null,
        closable: true,
        centered: true,
        okText: '确认',
        width: '50%',
        onOk: async () => {
          await form.validateFields()
          form.submit()
        },
        afterClose: () => form.resetFields(),
        content: (
          <Form
            form={form}
            style={{marginTop: '20px'}}
            labelAlign={'right'}
            onFinish={async () => {
              const formValue = form.getFieldsValue(true)
              const imgIdList = formValue.imgList?.map(item => item.imgId) ?? []
              const imgUrlList = formValue.imgList?.map(item => item.imgUrl) ?? []
              const videoIdList = formValue.videoList?.map(item => item.imgId) ?? []
              const videoUrlList = formValue.videoList?.map(item => item.imgUrl) ?? []
              await cleanModel.cleanOrderRework({
                originOrderId: orderId,
                orderDesc: formValue.orderDesc,
                imgUrlList: [...imgUrlList, ...videoUrlList],
                imgIdList: [...imgIdList, ...videoIdList],
              })
              onSuccess?.()
              message.success('返工成功！')
            }}
          >
            <Form.Item label={'服务项目'}>
              {[categoryFirName, categorySecName, categoryThiName].filter(Boolean).join('-')}
            </Form.Item>
            <Form.Item label={'房源地址'}>{propertyAddr}</Form.Item>
            <Form.Item
              label={'图片'}
              name={'imgList'}
              getValueFromEvent={onAlbumUploadEvent}
              getValueProps={onAlbumUploadProps}
            >
              <Album
                limit={10}
                multiple={true}
                action={baseUrl + '/clean-order/upload-file'}
                accept={'.jpg, .jpeg, .png'}
                beforeUpload={file => {
                  if (file.size > 1024 * 1024 * 10) {
                    message.error('不能上传超过10M的照片')
                    return Upload.LIST_IGNORE
                  }
                }}
              >
                <UploadButton title={'上传照片'} />
              </Album>
            </Form.Item>
            <Form.Item
              label={'视频'}
              name={'videoList'}
              getValueFromEvent={onAlbumUploadEvent}
              getValueProps={onAlbumUploadProps}
            >
              <Album
                limit={10}
                multiple={true}
                action={baseUrl + '/clean-order/upload-file'}
                accept={'.mp4, .mkv, .avi'}
                beforeUpload={file => {
                  if (file.size > 1024 * 1024 * 30) {
                    message.error('不能上传超过30M的视频')
                    return Upload.LIST_IGNORE
                  }
                }}
              >
                <UploadButton title={'上传视频'} />
              </Album>
            </Form.Item>
            <Form.Item label={'需求描述'} name={'orderDesc'}>
              <Input.TextArea placeholder={'请输入'} maxLength={200} />
            </Form.Item>
          </Form>
        ),
      })
    },
    [form]
  )
}
