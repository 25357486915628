import React, {PropsWithChildren, useCallback, useContext, useMemo, useState} from 'react'

export const RouterCacheContext = React.createContext<{
  cache: string[]
  add: (path: string) => void
  remove: (path: string) => void
}>({cache: [], add: path => path, remove: path => path})

export const RouterCacheProvider: React.FC<PropsWithChildren<any>> = props => {
  const [cache, setCache] = useState<string[]>([])

  const add = useCallback((path: string) => {
    setCache(prevState => (prevState.includes(path) ? prevState : [...prevState, path]))
  }, [])

  const remove = useCallback((path: string) => {
    setCache(prevState => (prevState.includes(path) ? prevState.filter(value => value !== path) : prevState))
  }, [])

  return (
    <RouterCacheContext.Provider value={useMemo(() => ({cache, add, remove}), [add, cache, remove])}>
      {props.children}
    </RouterCacheContext.Provider>
  )
}

/**
 * @example
 *   const {remove} = useCacheManager()
 *   useFocusEffect(() => {
 *     remove('/sys-user/add')
 *   })
 */
export function useCacheManager() {
  return useContext(RouterCacheContext)
}
