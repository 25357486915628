import React, {useCallback, useEffect, useState} from 'react'
import './index.less'
import {Button, Table, Space, Form, Input, Row, Col} from 'antd'
import {sysRoleModel} from '@/store/models/SysRole'
import {useHistory} from 'react-router'
import {Breadcrumb} from '@/components/Breadcrumb'
import commonStyles from '@/assets/styles/common.module.less'
import {Permission, usePermission} from '@/components/Permission'
import {Page} from '@/components/Page'
import {useFocusEffect} from '@/common/hooks/useFocusEffect'
import {useCacheManager} from '@/common/hooks/useCacheManager'
import {getPaginationProps} from '@/common/biz/getPaginationProps'

export default function SysRoleList() {
  const [auth] = usePermission()
  const [dataSource, setDataSource] = useState<SysRoleVO[]>()
  const [total, setTotal] = useState(0)
  const loading = sysRoleModel.sysRoleList.useLoading()
  const history = useHistory()
  const {remove} = useCacheManager()

  const [form] = Form.useForm()

  useFocusEffect(() => {
    remove('/sys-role/add')
  })

  const search = useCallback(async () => {
    const value = form.getFieldsValue(true)
    const {
      response: {data, total},
    } = await sysRoleModel.sysRoleList(value)
    setDataSource(data)
    setTotal(total)
  }, [form])

  useFocusEffect(() => {
    search()
  })

  useEffect(() => {
    search()
  }, [search])

  //删除操作
  // async function confirm(arg: string) {
  //   await sysRoleModel.querySysRoleDelete(arg)
  //   message.success('删除成功')
  //   search()
  // }

  return (
    <Page
      breadcrumb={
        <Breadcrumb
          right={
            <Permission code={'003034002'}>
              <Button
                onClick={() => {
                  const location = {pathname: '/sys-role/add'}
                  history.push(location)
                }}
              >
                添加用户组
              </Button>
            </Permission>
          }
        />
      }
    >
      <div className={commonStyles.filterSection}>
        <Form initialValues={{pageNum: 1, pageSize: 10, pageable: true}} form={form} onFinish={search} onReset={search}>
          <Row gutter={12}>
            <Col span={8}>
              <Form.Item label={'用户组名称'} name={'name'}>
                <Input placeholder={'请输入'} />
              </Form.Item>
            </Col>
          </Row>
          <Row justify={'end'}>
            <Space>
              <Button type={'primary'} htmlType={'submit'} onClick={() => form.setFieldsValue({pageNum: 1})}>
                查询
              </Button>
              <Button htmlType={'reset'}>重置</Button>
            </Space>
          </Row>
        </Form>
      </div>
      <div className={commonStyles.filterSection}>
        <Table
          rowKey={'roleId'}
          dataSource={dataSource}
          loading={loading}
          columns={[
            {
              title: '用户组名称',
              dataIndex: 'roleName',
              key: 'roleName',
            },
            {title: '人数', dataIndex: 'userCount', key: 'userCount'},
            {
              title: '创建时间',
              dataIndex: 'insertTime',
              key: 'insertTime',
              defaultSortOrder: 'descend',
            },
            {title: '备注', dataIndex: 'remark', key: 'remark'},
            {
              title: '操作',
              dataIndex: 'roleId',
              key: 'handle',
              render: (text, record, index) => {
                return (
                  <Space>
                    {auth('002034002') && (
                      <Button
                        type={'link'}
                        size={'small'}
                        onClick={() => {
                          const location = {pathname: '/sys-role/add', state: {roleId: record.roleId}}
                          history.push(location)
                        }}
                        style={{padding: 0}}
                      >
                        编辑
                      </Button>
                    )}
                    {/*{auth('004034002') && (*/}
                    {/*  <Popconfirm title={'确认要删除吗'} onConfirm={() => confirm(record.roleId)}>*/}
                    {/*    <Button size={'small'} type={'link'} style={{padding: 0}}>*/}
                    {/*      删除*/}
                    {/*    </Button>*/}
                    {/*  </Popconfirm>*/}
                    {/*)}*/}
                  </Space>
                )
              },
            },
          ]}
          pagination={getPaginationProps(form, total)}
        />
      </div>
    </Page>
  )
}
