import React, {useCallback, useEffect, useState} from 'react'
import {Page} from '@/components/Page'
import {Breadcrumb} from '@/components/Breadcrumb'
import commonStyles from '@/assets/styles/common.module.less'
import {Button, Col, DatePicker, Form, message, Row, Space, Table} from 'antd'
import {zzajModel} from '@/store/models/Zzaj'
import moment from 'moment'

export default function AnJuServiceStatistics() {
  const [form] = Form.useForm()
  const [statistics, setStatistics] = useState(null)

  const search = useCallback(() => {
    const {beginTime, endTime} = form.getFieldsValue(true)
    if (!beginTime) return message.warning('统计日期不能为空！')
    zzajModel.zzajOpenOrderStatistics({beginTime, endTime}).then(({response: {data}}) => {
      setStatistics(data)
    })
  }, [])

  useEffect(() => {
    search()
  }, [search])

  return (
    <Page breadcrumb={<Breadcrumb />}>
      <div className={commonStyles.filterSection}>
        <Form
          form={form}
          onFinish={search}
          onReset={search}
          initialValues={{
            endTime: moment().format('YYYY-MM-DD'),
            beginTime: moment().startOf('month').format('YYYY-MM-DD'),
            time: [moment().startOf('month'), moment()],
          }}
        >
          <Row>
            <Col span={7}>
              <Form.Item label={'统计日期'} name={'time'}>
                <DatePicker.RangePicker
                  onChange={(_, dateStrings) => {
                    const [beginTime, endTime] = dateStrings
                    form.setFieldsValue({beginTime, endTime})
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row justify={'end'}>
            <Space>
              <Button htmlType={'submit'} type={'primary'}>
                查询
              </Button>
              <Button htmlType={'reset'}>重置</Button>
            </Space>
          </Row>
        </Form>
      </div>
      <div className={commonStyles.filterSection}>
        <Table
          rowKey={'num1'}
          dataSource={[statistics]}
          columns={[
            {title: '质保单位维修数量', dataIndex: 'num1'},
            {title: '牛油果维修数量', dataIndex: 'num2'},
            {title: '质保转牛油果维修数量', dataIndex: 'num3'},
          ]}
          pagination={false}
        />
      </div>
    </Page>
  )
}
