import React, {useCallback, useEffect, useState} from 'react'
import {Button, Col, DatePicker, Form, Input, Modal, Row, Select, Space, Table} from 'antd'
import commonStyles from '@/assets/styles/common.module.less'
import {saleModel} from '@/store/models/Sale'
import moment from 'moment'
import {useCodeArraySync, useCodeSync} from '@/store/models/Common'
import {Page} from '@/components/Page'
import {getPaginationProps} from '@/common/biz/getPaginationProps'
import {useAppHistory} from '@/common/hooks/useRouter'
import {usePermission} from '@/components/Permission'
import {Breadcrumb} from '@/components/Breadcrumb'
import {downloadFile} from '@/utils/util'

export default function ClientContract({userId}: {userId?: string}) {
  const history = useAppHistory()

  const [auth] = usePermission()

  // 耗材全包
  const materialInclusiveCode = useCodeSync('material.source')

  const cleanFeeTypeArr = useCodeArraySync('clean.fee.type')
  const cleanFeeTypeCode = useCodeSync('clean.fee.type')
  const stewardFeeTypeArr = useCodeArraySync('steward.fee.type')
  const stewardFeeTypeCode = useCodeSync('steward.fee.type')
  const saleContractStatus = useCodeArraySync('sale.contract.status')
  const saleContractStatusCode = useCodeSync('sale.contract.status')

  const [form] = Form.useForm()
  const [total, setTotal] = useState(0)
  const [contractList, setContractList] = useState<SaleCustomerContractVO[]>([])
  const querySaleCustomerContractList = useCallback(() => {
    const formValues = form.getFieldsValue(true)
    saleModel
      .saleCustomerContractList(userId ? {customerId: userId, pageable: false} : formValues)
      .then(({response: {data, total}}) => {
        setContractList(data)
        setTotal(total)
      })
  }, [form, userId])

  useEffect(() => {
    querySaleCustomerContractList()
  }, [querySaleCustomerContractList])

  const loading = saleModel.saleCustomerContractList.useLoading()

  const downloadButton = (record: SaleCustomerContractVO) => {
    return record.contractPdfUrl && auth('007121001') ? (
      <Button type={'link'} style={{padding: 0}} onClick={() => downloadFile(record.contractPdfUrl, '合同.pdf')}>
        查看合同
      </Button>
    ) : null
  }

  return (
    <Page breadcrumb={userId ? null : <Breadcrumb />} style={userId ? {marginLeft: 0, marginRight: 0} : {}}>
      {!userId && (
        <div className={commonStyles.filterSection}>
          <Form
            form={form}
            initialValues={{pageNum: 1, pageSize: 10, pageable: true, contractStatusList: ['1', '2', '3']}} // 合作中、已到期、合同终止
            onFinish={querySaleCustomerContractList}
            onReset={querySaleCustomerContractList}
            labelAlign={'left'}
          >
            <Row gutter={30}>
              <Col span={8}>
                <Form.Item name={'keyword'} label={'关键字'} labelCol={{flex: '100px'}}>
                  <Input placeholder={'请输入客户名称或手机号'} allowClear />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name={'holderName'} label={'BD'} labelCol={{flex: '100px'}}>
                  <Input placeholder={'请输入'} allowClear />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name={'contractType'} label={'合同签署类型'}>
                  <Select placeholder={'请选择'} allowClear>
                    <Select.Option value={'paper'}>纸质</Select.Option>
                    <Select.Option value={'elec'}>电子</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name={'stewardFeeType'} label={'管家收费类型'} labelCol={{flex: '100px'}}>
                  <Select placeholder={'请选择'} allowClear>
                    {stewardFeeTypeArr.map(item => (
                      <Select.Option key={item.id} value={item.id}>
                        {item.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name={'cleanFeeType'} label={'保洁收费类型'} labelCol={{flex: '100px'}}>
                  <Select placeholder={'请选择'} allowClear>
                    {cleanFeeTypeArr.map(item => (
                      <Select.Option key={item.id} value={item.id}>
                        {item.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name={'contractDate'} label={'合同周期'}>
                  <DatePicker.RangePicker
                    allowClear
                    onChange={(_, dateStrings) => {
                      const [contractBeginDate, contractEndDate] = dateStrings ?? []
                      form.setFieldsValue({
                        contractBeginDate: contractBeginDate ? contractBeginDate + ' 00:00:00' : undefined,
                        contractEndDate: contractEndDate ? contractEndDate + ' 23:59:59' : undefined,
                      })
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name={'contractCode'} label={'合同编号'}>
                  <Input placeholder={'请输入'} allowClear />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item name={'contractStatus'} label={'合同状态'}>
                  <Select placeholder={'请选择'} allowClear>
                    {saleContractStatus
                      .filter(item => ['1', '2', '3'].includes(item.id))
                      .map(item => (
                        <Select.Option value={item.id} key={item.id}>
                          {item.label}
                        </Select.Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <Row justify={'end'}>
              <Space>
                <Button type={'primary'} htmlType={'submit'} onClick={() => form.setFieldsValue({pageNum: 1})}>
                  查询
                </Button>
                <Button htmlType={'reset'}>重置</Button>
              </Space>
            </Row>
          </Form>
        </div>
      )}
      <div className={commonStyles.tableSection}>
        <Table
          rowKey={'contractId'}
          loading={loading}
          scroll={{x: 'max-content'}}
          columns={
            userId
              ? [
                  {title: '合同编号', dataIndex: 'contractCode', key: 'contractCode'},
                  {
                    title: '合同时间',
                    render: value => (
                      <span>
                        {[
                          value.contractBeginDate && moment(value.contractBeginDate).format('YYYY-MM-DD'),
                          value.contractEndDate && moment(value.contractEndDate).format('YYYY-MM-DD'),
                        ]
                          .filter(item => item)
                          .join('至')}
                      </span>
                    ),
                  },
                  {title: 'BD', dataIndex: 'holderName', key: 'holderName'},
                  {
                    title: '合同类型',
                    render: value => (
                      <span>
                        {[value.contractSteward === '1' && '管家', value.contractClean === '1' && '保洁']
                          .filter(item => item)
                          .join('、')}
                      </span>
                    ),
                  },
                  {
                    title: '绑定房源（间）',
                    dataIndex: 'propertyCount',
                    key: 'propertyCount',
                    render: value => value ?? 0,
                  },
                  {
                    title: '房源核算日',
                    dataIndex: 'propertyCountDateNum',
                    key: 'propertyCountDateNum',
                    render: value => value ?? '-',
                  },
                  {title: '付款日期', dataIndex: 'payDateNum', key: 'payDateNum', render: value => value ?? '/'},
                  {
                    title: '维修耗材',
                    dataIndex: 'materialInclusive',
                    key: 'materialInclusive',
                    render: value => (value ? <span>{materialInclusiveCode[value]}</span> : '/'),
                  },
                  {
                    title: '状态',
                    dataIndex: 'contractStatus',
                    key: 'contractStatus',
                    render: value => <span>{saleContractStatusCode[value]}</span>,
                  },
                  {
                    title: '操作',
                    fixed: 'right',
                    render: (_, value) => {
                      return (
                        <Space>
                          {value.contractStatus === '1' && (
                            <>
                              {auth('005048002') && (
                                <Button
                                  type={'link'}
                                  style={{padding: 0}}
                                  onClick={() =>
                                    Modal.confirm({
                                      title: '提示',
                                      content: '您确定要终止合同吗？',
                                      onOk: async () => {
                                        await saleModel.saleCustomerContractTerminate({contractId: value.contractId})
                                        querySaleCustomerContractList()
                                      },
                                    })
                                  }
                                >
                                  终止合作
                                </Button>
                              )}
                            </>
                          )}
                          {auth('001048002') && (
                            <Button
                              type={'link'}
                              style={{padding: 0}}
                              onClick={() =>
                                history.push({
                                  pathname: '/client/contract_detail',
                                  state: {contractId: value.contractId, customerId: value.customerId},
                                })
                              }
                            >
                              查看
                            </Button>
                          )}
                          {downloadButton(value)}
                        </Space>
                      )
                    },
                  },
                ]
              : [
                  {title: '合同编号', dataIndex: 'contractCode'},
                  {title: '合同状态', dataIndex: 'contractStatus', render: value => saleContractStatusCode[value]},
                  {title: '客户名称', dataIndex: 'name'},
                  {title: '手机号', dataIndex: 'mobile'},
                  {
                    title: '合同周期',
                    render: value => (
                      <span>
                        {[
                          value.contractBeginDate && moment(value.contractBeginDate).format('YYYY-MM-DD'),
                          value.contractEndDate && moment(value.contractEndDate).format('YYYY-MM-DD'),
                        ]
                          .filter(item => item)
                          .join('至')}
                      </span>
                    ),
                  },
                  {title: 'BD', dataIndex: 'holderName', key: 'holderName'},
                  {
                    title: '合同类型',
                    render: value => (
                      <span>
                        {[value.contractSteward === '1' && '管家', value.contractClean === '1' && '保洁']
                          .filter(item => item)
                          .join('、')}
                      </span>
                    ),
                  },
                  {
                    title: '管家收费类型',
                    dataIndex: 'stewardFeeType',
                    render: value => stewardFeeTypeCode[value] ?? '/',
                  },
                  {title: '保洁收费类型', dataIndex: 'cleanFeeType', render: value => cleanFeeTypeCode[value] ?? '/'},
                  {
                    title: '耗材全包',
                    dataIndex: 'materialInclusive',
                    key: 'materialInclusive',
                    render: value => (value ? <span>{materialInclusiveCode[value]}</span> : '/'),
                  },
                  {
                    title: '操作',
                    fixed: 'right',
                    render: (_, value) => {
                      return (
                        <Space>
                          {value.contractStatus === '1' && auth('005048002') && (
                            <Button
                              type={'link'}
                              style={{padding: 0}}
                              onClick={() =>
                                Modal.confirm({
                                  title: '提示',
                                  content: '您确定要终止合同吗？',
                                  onOk: async () => {
                                    await saleModel.saleCustomerContractTerminate({contractId: value.contractId})
                                    form.submit()
                                  },
                                })
                              }
                            >
                              终止合作
                            </Button>
                          )}
                          {value.contractStatus === '0' && auth('004048002') && (
                            <Button
                              type={'link'}
                              style={{padding: 0}}
                              onClick={() =>
                                Modal.confirm({
                                  title: '提示',
                                  content: '您确定要终止签约吗？',
                                  onOk: async () => {
                                    await saleModel.saleCustomerContractDestroy({contractId: value.contractId})
                                    form.submit()
                                  },
                                })
                              }
                            >
                              终止签约
                            </Button>
                          )}
                          {auth('001048002') && (
                            <Button
                              type={'link'}
                              style={{padding: 0}}
                              onClick={() =>
                                history.push({
                                  pathname: '/client/contract_detail',
                                  state: {contractId: value.contractId, customerId: value.customerId},
                                })
                              }
                            >
                              查看
                            </Button>
                          )}
                          {downloadButton(value)}
                        </Space>
                      )
                    },
                  },
                ]
          }
          pagination={userId ? {position: ['bottomCenter']} : getPaginationProps(form, total)}
          dataSource={contractList}
        />
      </div>
    </Page>
  )
}
