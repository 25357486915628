import React, {useCallback, useEffect, useRef, useState} from 'react'
import commonStyle from '@/assets/styles/common.module.less'
import {Breadcrumb} from '@/components/Breadcrumb'
import {Button, Col, Divider, Form, Input, message, Modal, Row, Select, Space, Table, Typography} from 'antd'
import {useCodeArraySync, useCodeSync} from '@/store/models/Common'
import {storageModel} from '@/store/models/Storage'
import {AddAddress} from '@/pages/oa/component/AddAdress'
import {usePermission} from '@/components/Permission'
import {Page} from '@/components/Page'
import {getPaginationProps} from '@/common/biz/getPaginationProps'

const {Option} = Select
export default function AddressManagement() {
  const addressType = useCodeSync('purchase.addressType')
  const addressTypeArr = useCodeArraySync('purchase.addressType').filter(item => item.id !== '0')
  const [list, setList] = useState([] as PurchaseAddressVO[])
  const [total, setTotal] = useState(0)
  const [addressId, setAddressId] = useState('')
  const [deleteVisible, setDeleteVisible] = useState(false)
  const loading = storageModel.purchaseAddressList.useLoading()
  const [form] = Form.useForm<PurchaseAddressQueryDTO>()
  const addRef = useRef(null)
  const [auth] = usePermission()

  const queryList = useCallback(() => {
    const values = form.getFieldsValue(true)
    storageModel.purchaseAddressList(values).then(({response: {data, total}}) => {
      setList(data)
      setTotal(total)
    })
  }, [form])

  useEffect(() => {
    form.submit()
  }, [form])

  return (
    <Page
      breadcrumb={
        <Breadcrumb
          right={
            auth('003062002') && (
              <Button
                type={'primary'}
                onClick={() => {
                  addRef.current.edit()
                }}
              >
                添加新地址
              </Button>
            )
          }
        />
      }
    >
      <div className={commonStyle.filterSection}>
        <Form form={form} initialValues={{pageNum: 1, pageSize: 10}} onFinish={queryList} onReset={queryList}>
          <Row>
            <Col span={6}>
              <Form.Item label={'收货人'} name={'consigneeName'}>
                <Input placeholder={'请输入收货人'} allowClear />
              </Form.Item>
            </Col>
            <Col span={6} offset={2}>
              <Form.Item label={'手机号'} name={'consigneeMobile'}>
                <Input placeholder={'请输入手机号'} allowClear />
              </Form.Item>
            </Col>
            <Col span={6} offset={2}>
              <Form.Item label={'收货地址'} name={'address'}>
                <Input placeholder={'请输入收货地址'} allowClear />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={6}>
              <Form.Item name={'addressType'} label={'类别'}>
                <Select placeholder={'请选择'} allowClear>
                  {addressTypeArr.map(item => (
                    <Option value={item.id} key={item.id}>
                      {item.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row justify={'end'}>
            <Space>
              <Button type={'primary'} htmlType={'submit'} onClick={() => form.setFieldsValue({pageNum: 1})}>
                查询
              </Button>
              <Button htmlType={'reset'}>重置</Button>
            </Space>
          </Row>
        </Form>
      </div>
      <div className={commonStyle.tableSection}>
        <Table
          dataSource={list}
          rowKey={'addressId'}
          loading={loading}
          columns={[
            {title: '编号', dataIndex: 'addressId'},
            {title: '收货人', dataIndex: 'consigneeName'},
            {title: '手机号', dataIndex: 'consigneeMobile'},
            {title: '收货地址', dataIndex: 'address', render: (text, record) => `${record.district + text}`},
            {title: '类别', dataIndex: 'addressType', render: value => addressType[value]},
            {
              title: '操作',
              render: record => (
                <Space size={0}>
                  <Button
                    type={'link'}
                    style={{padding: 0}}
                    onClick={() => {
                      addRef.current.edit(record)
                    }}
                  >
                    编辑
                  </Button>
                  <Divider type={'vertical'} />
                  <Button
                    style={{padding: 0}}
                    type={'link'}
                    onClick={() => {
                      setAddressId(record.addressId)
                      setDeleteVisible(true)
                    }}
                  >
                    删除
                  </Button>
                </Space>
              ),
            },
          ]}
          pagination={getPaginationProps(form, total)}
        />
      </div>

      {/* 添加或编辑 */}
      <AddAddress
        ref={addRef}
        onSuccess={() => {
          form.submit()
        }}
      />

      {/* 删除 */}
      <Modal centered visible={deleteVisible} footer={null} title={'提示'} onCancel={() => setDeleteVisible(false)}>
        <div>
          <div>
            <Typography.Text>是否确认删除此地址？</Typography.Text>
          </div>
          <Row justify={'end'}>
            <Space>
              <Button onClick={() => setDeleteVisible(false)}>取消</Button>
              <Button
                type={'primary'}
                onClick={async () => {
                  await storageModel.purchaseAddressDelete({addressId})
                  message.success('删除成功')
                  form.submit()
                  setDeleteVisible(false)
                }}
              >
                确认
              </Button>
            </Space>
          </Row>
        </div>
      </Modal>
    </Page>
  )
}
