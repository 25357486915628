import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {Table, Form, Button, Row, Col, Input, Select, Space, DatePicker} from 'antd'
import {propertyModel} from '@/store/models/Property'
import {baseCodes} from '@/store/models/Common/baseCodes'
import commonStyles from '@/assets/styles/common.module.less'
import {Permission, usePermission} from '@/components/Permission'
import {useFocusEffect} from '@/common/hooks/useFocusEffect'
import {getPaginationProps} from '@/common/biz/getPaginationProps'
import {useAppHistory, useAppLocation} from '@/common/hooks/useRouter'
import {Page} from '@/components/Page'
import {useCodeArraySync, useCodeSync} from '@/store/models/Common'
import {Breadcrumb} from '@/components/Breadcrumb'
import moment from 'moment/moment'
import {FORMAT_DATE_END, FORMAT_DATE_START} from '@/common/date'

export default function PropertyList() {
  const history = useAppHistory()
  const location = useAppLocation<'/property'>()
  const [auth] = usePermission()
  const cleanFeeTypeArr = useCodeArraySync('clean.fee.type')
  const cleanFeeType = useCodeSync('clean.fee.type')
  const stewardFeeTypeArr = useCodeArraySync('steward.fee.type')
  const stewardFeeType = useCodeSync('steward.fee.type')

  // 租房类型
  const rentTypeCode = useMemo(() => baseCodes.rentType, [])

  // 列表
  const [dataList, setDataList] = useState<PropertyInfoVO[]>([])
  // 数据条数
  const [total, setTotal] = useState(0)

  const loading = propertyModel.queryPropertyList.useLoading()

  const [form] = Form.useForm()

  const search = useCallback(() => {
    const {_time, ...values} = form.getFieldsValue(true)
    const [insertTimeBegin, insertTimeEnd] = _time
    propertyModel.queryPropertyList({...values, insertTimeBegin, insertTimeEnd}).then(({response: {data, total}}) => {
      /**
       * rentStatus
       * 0 => 空置
       * 1 => 判断 contractId 是否为空 => 空：待录  非空：已租
       */
      const list = data.map(item => {
        return {
          ...item,
          rentType: rentTypeCode[item.rentType],
          rentStatus: item.rentStatus === '0' ? '空置' : item.contractId ? '已租' : '待录',
        }
      })
      setDataList(list)

      setTotal(total)
    })
  }, [form, rentTypeCode])

  // 设置页面初始参数
  const lessor = location.state?.lessor
  useEffect(() => {
    if (lessor) {
      form.setFieldsValue({lessor})
      location.state.lessor = undefined
    }
  }, [form, lessor, location.state])

  useFocusEffect(() => {
    form.submit()
  }, 'PUSH|POP')

  useEffect(() => {
    form.submit()
  }, [form])

  return (
    <Page
      breadcrumb={
        <Breadcrumb
          right={
            <Permission code={'003005001'}>
              <Button onClick={() => history.push('/property/add')}>添加房源</Button>
            </Permission>
          }
        />
      }
    >
      <div className={commonStyles.filterSection}>
        <Form
          form={form}
          initialValues={{
            pageNum: 1,
            pageSize: 10,
            pageable: true,
            _time: [moment().subtract(31, 'day').format(FORMAT_DATE_START), moment().format(FORMAT_DATE_END)],
          }}
          onReset={search}
          onFinish={search}
        >
          {/**名称*/}
          <Row>
            <Col span={7}>
              <Form.Item name='lessor' label={'房东名称'}>
                <Input placeholder={'房东姓名/房东手机'} allowClear />
              </Form.Item>
            </Col>
            <Col span={7} offset={1}>
              <Form.Item name='lessee' label={'租客名称'}>
                <Input placeholder={'租客姓名/租客手机'} allowClear />
              </Form.Item>
            </Col>
            <Col span={7} offset={1}>
              <Form.Item name='propertyCode' label={'房源code'}>
                <Input placeholder={'房源code'} allowClear />
              </Form.Item>
            </Col>
          </Row>

          {/**添加时间*/}
          <Row>
            <Col span={7}>
              <Form.Item
                name='_time'
                label={'添加时间'}
                getValueProps={value => ({value: value ? value.map(item => moment(item)) : []})}
                getValueFromEvent={(_, dateStrings) => {
                  const [insertTimeBegin, insertTimeEnd] = dateStrings
                  if (insertTimeBegin && insertTimeEnd) {
                    return [
                      moment(insertTimeBegin).format(FORMAT_DATE_START),
                      moment(insertTimeEnd).format(FORMAT_DATE_END),
                    ]
                  }
                  return undefined
                }}
                rules={[
                  {
                    validator: (rule, value, callback) => {
                      if (value) {
                        const [insertTimeBegin, insertTimeEnd] = value
                        const intervalDay = moment(insertTimeEnd).diff(insertTimeBegin, 'day')
                        if (intervalDay > 31) return callback('添加时间间隔不超过31天')
                      } else {
                        return callback('添加时间不能为空')
                      }
                      return callback()
                    },
                  },
                ]}
              >
                <DatePicker.RangePicker />
              </Form.Item>
            </Col>
            <Col span={7} offset={1}>
              <Form.Item name='title' label={'房源地址'}>
                <Input placeholder={'小区/公寓'} allowClear />
              </Form.Item>
            </Col>
            <Col span={7} offset={1}>
              <Form.Item name='rentType' label={'房源类别'}>
                <Select placeholder='全部/整租/合租/公寓房' allowClear>
                  <Select.Option value=''>全部</Select.Option>
                  <Select.Option value='1'>整租</Select.Option>
                  <Select.Option value='2'>合租</Select.Option>
                  <Select.Option value='3'>公寓房</Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={7}>
              <Form.Item name={'status'} label={'房源状态'}>
                <Select placeholder={'全部/空置/已租/待录'} allowClear>
                  <Select.Option value={''}>全部</Select.Option>
                  <Select.Option value={'1'}>空置</Select.Option>
                  <Select.Option value={'2'}>已租</Select.Option>
                  <Select.Option value={'3'}>待录</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={7} offset={1}>
              <Form.Item name={'stewardFeeType'} label={'维修收费类型'}>
                <Select placeholder={'请选择'} allowClear>
                  {stewardFeeTypeArr.map(item => (
                    <Select.Option value={item.id} key={item.id}>
                      {item.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={7} offset={1}>
              <Form.Item name={'cleanFeeType'} label={'保洁收费类型'}>
                <Select placeholder={'请选择'} allowClear>
                  {cleanFeeTypeArr.map(item => (
                    <Select.Option value={item.id} key={item.id}>
                      {item.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row justify={'end'}>
            <Space>
              <Button type={'primary'} htmlType={'submit'} onClick={() => form.setFieldsValue({pageNum: 1})}>
                查询
              </Button>
              <Button htmlType={'reset'}>重置</Button>
            </Space>
          </Row>
        </Form>
      </div>

      <div className={commonStyles.tableSection}>
        <Table
          rowKey={'propertyCode'}
          dataSource={dataList}
          columns={[
            {title: 'ID', dataIndex: 'propertyCode'},
            {title: '维修收费类型', dataIndex: 'stewardFeeType', render: value => stewardFeeType[value] || '/'},
            {title: '保洁收费类型', dataIndex: 'cleanFeeType', render: value => cleanFeeType[value] || '/'},
            {title: '房东名称', dataIndex: 'lessorName', render: value => value || '/'},
            {title: '类别', dataIndex: 'rentType', key: 'rentType'},
            {title: '房东手机', dataIndex: 'lessorMobile', key: 'lessorMobile'},
            {title: '添加时间', dataIndex: 'insertTime', key: 'insertTime'},
            {title: '添加渠道', dataIndex: 'creatorSource'},
            {title: '添加人', dataIndex: 'creatorName'},
            {title: '添加人手机号', dataIndex: 'creatorMobile'},
            {title: '门牌号', dataIndex: 'title', key: 'title'},
            {title: '所属团队', dataIndex: 'teamName', key: 'teamName'},
            {title: '状态', dataIndex: 'rentStatus', key: 'rentStatus'},
            {
              title: '操作',
              dataIndex: 'propertyCode',
              key: 'propertyCode',
              fixed: 'right',
              width: '100px',
              render: value =>
                auth('001005002') && (
                  <Button
                    type={'link'}
                    size={'small'}
                    onClick={() =>
                      history.push({
                        pathname: '/property/detail',
                        state: {propertyCode: value},
                      })
                    }
                    style={{padding: 0}}
                  >
                    查看
                  </Button>
                ),
            },
          ]}
          scroll={{x: 'max-content'}}
          loading={loading}
          pagination={getPaginationProps(form, total)}
        />
      </div>
    </Page>
  )
}
