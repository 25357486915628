import React, {useCallback, useEffect, useState} from 'react'
import {Page} from '@/components/Page'
import commonStyles from '@/assets/styles/common.module.less'
import {Button, Col, Descriptions, Drawer, Form, Input, message, Modal, Row, Select, Space, Table, Timeline} from 'antd'
import {getPaginationProps} from '@/common/biz/getPaginationProps'
import {websiteModel} from '@/store/models/Website'
import DividerLine from '@/pages/property/component/DividerLine'

const recordStatus = [
  {label: '待回访', value: '0'},
  {label: '已回访', value: '1'},
]

export default function CooperationList() {
  const [form] = Form.useForm()
  const [result, setResult] = useState('')
  const [recordId, setRecordId] = useState('')
  const [detail, setDetail] = useState({} as WebsiteCooperationVO)

  const [dataSource, setDataSource] = useState<WebsiteCooperationVO[]>([])
  const [total, setTotal] = useState(0)
  const search = useCallback(async () => {
    const value = form.getFieldsValue(true)
    const {
      response: {data, total},
    } = await websiteModel.websiteCooperationList(value)
    setTotal(total)
    setDataSource(data)
  }, [form])

  // 页面加载时触发
  useEffect(() => {
    form.submit()
  }, [form])

  return (
    <Page>
      <div className={commonStyles.filterSection}>
        <Form initialValues={{pageNum: 1, pageSize: 10, pageable: true}} form={form} onFinish={search} onReset={search}>
          <Row gutter={30}>
            <Col span={8}>
              <Form.Item label={'称呼'} name={'name'}>
                <Input allowClear placeholder={'请输入'} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={'手机号'} name={'mobile'}>
                <Input allowClear placeholder={'请输入'} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={'回访状态'} name={'recordStatus'}>
                <Select placeholder={'请选择'} options={recordStatus} allowClear />
              </Form.Item>
            </Col>
          </Row>

          <Row justify={'end'}>
            <Space>
              <Button type={'primary'} htmlType={'submit'} onClick={() => form.setFieldsValue({pageNum: 1})}>
                查询
              </Button>
              <Button htmlType={'reset'}>重置</Button>
            </Space>
          </Row>
        </Form>
      </div>

      <div className={commonStyles.tableSection}>
        <Table
          dataSource={dataSource}
          rowKey={'recordId'}
          columns={[
            //
            {title: '编号', dataIndex: 'recordId'},
            {title: '称呼', dataIndex: 'name'},
            {title: '手机号', dataIndex: 'mobile'},
            {title: '提交时间', dataIndex: 'insertTime'},
            {
              title: '回访状态',
              dataIndex: 'recordStatus',
              render: text => recordStatus.find(value => value.value === text)?.label,
            },
            {
              title: '操作',
              render: (_, record) => (
                <Space size={0}>
                  <Button
                    type={'link'}
                    style={{paddingLeft: 0}}
                    onClick={async () => {
                      const {
                        response: {data},
                      } = await websiteModel.websiteCooperationGet({recordId: record.recordId})
                      setDetail(data)
                    }}
                  >
                    查看
                  </Button>
                  <Button type={'link'} onClick={() => setRecordId(record.recordId)}>
                    提交回访
                  </Button>
                </Space>
              ),
            },
          ]}
          pagination={getPaginationProps(form, total)}
        />
      </div>

      <Modal
        title={'提交回访'}
        visible={!!recordId}
        okText={'提交'}
        onCancel={() => setRecordId('')}
        onOk={async () => {
          await websiteModel.websiteCooperationHandle({result, recordId})
          message.success('提交成功')
          setRecordId('')
          form.submit()
        }}
        afterClose={() => setResult('')}
      >
        <Form.Item label={'回访结果'}>
          <Input.TextArea value={result} onChange={event => setResult(event.target.value)} placeholder={'请输入备注'} />
        </Form.Item>
      </Modal>

      <Drawer
        visible={!!detail.recordId}
        title={'意向详情'}
        width={700}
        onClose={() => setDetail({} as WebsiteCooperationVO)}
      >
        <DividerLine title='基本信息' bottom={15} />
        <Descriptions column={2}>
          <Descriptions.Item label={'编号'}>{detail.recordId}</Descriptions.Item>
          <Descriptions.Item label={'称呼'}>{detail.name}</Descriptions.Item>
          <Descriptions.Item label={'手机号'}>{detail.mobile}</Descriptions.Item>
          <Descriptions.Item label={'提交时间'}>{detail.insertTime}</Descriptions.Item>
        </Descriptions>
        <Descriptions column={1}>
          <Descriptions.Item label={'留言内容'}>{detail.msg}</Descriptions.Item>
          <Descriptions.Item label={'回访状态'}>{detail.recordStatus === '0' ? '待回访' : '已回访'}</Descriptions.Item>
        </Descriptions>
        <DividerLine title='回访记录' style={{marginTop: 20}} bottom={24} />
        <Timeline>
          {detail.handleList?.reverse()?.map((item, index) => (
            <Timeline.Item
              key={index}
              dot={
                <div
                  style={{width: 16, height: 16, background: index === 0 ? '#10BBB3' : '#D8D8D8', borderRadius: 8}}
                />
              }
            >
              <Space direction={'vertical'} style={{color: '#909399'}}>
                <span>{item.result}</span>
                <span>操作人：{item.operatorName}</span>
                <span>操作时间：{item.insertTime}</span>
              </Space>
            </Timeline.Item>
          ))}
        </Timeline>
      </Drawer>
    </Page>
  )
}
