import {Breadcrumb} from '@/components/Breadcrumb'
import {Page} from '@/components/Page'
import React, {HTMLAttributes, useCallback, useEffect, useMemo, useState} from 'react'
import commonStyles from '@/assets/styles/common.module.less'
import {Button, Checkbox, Form, Input, message} from 'antd'
import {CheckboxChangeEventTarget} from 'antd/lib/checkbox/Checkbox'
import styles from './index.module.less'
import {condoModel} from '@/store/models/Condo'
import {useAppHistory, useAppLocation} from '@/common/hooks/useRouter'
import BackButton from '@/components/BackButton'

const CHECK_ALL_ID = '0'
export default function AddPost() {
  const history = useAppHistory()
  const location = useAppLocation<'/condo-team-manage/add-post'>()
  const [permissionTree, setPermissionTree] = useState<TeamPermissionTreeNodeVO[]>([])
  const [roleSet, setRoleSet] = useState({permissionCodeList: []} as CondoProjectRoleDTO)
  const disabled = location.state?.disabled
  const roleId = location.state?.roleId

  const permissionTreeSearch = useCallback(() => {
    condoModel.condoProjectTeamRoleListAllPermissionTree({}).then(({response: {data}}) => {
      setPermissionTree(data)
    })
  }, [])

  useEffect(() => {
    if (roleId) {
      condoModel.condoProjectTeamRoleInfo({roleId}).then(({response: {data}}) => {
        setRoleSet(data)
      })
    }
  }, [roleId])

  const childPermissions = useMemo(() => {
    const getIds = (permission: TeamPermissionTreeNodeVO, selected = []) => {
      if (permission.id?.length === 9) selected.push(permission.id)
      permission.children?.forEach(value => getIds(value, selected))
      return selected
    }

    return permissionTree.map(value => getIds(value)).flat()
  }, [permissionTree])

  useEffect(() => {
    permissionTreeSearch()
  }, [permissionTreeSearch])

  return (
    <Page
      breadcrumb={
        <Breadcrumb
          right={
            !disabled && (
              <Button
                type={'primary'}
                onClick={async () => {
                  if (!roleSet.roleName) return message.error('岗位名称不能为空')
                  if (!roleSet.permissionCodeList.length) return message.error('权限列表不能为空')
                  await condoModel.condoProjectTeamRoleUpsert(roleSet)
                  message.success('操作成功')
                  history.goBack()
                }}
              >
                保存
              </Button>
            )
          }
        />
      }
    >
      <BackButton />
      <div className={commonStyles.filterSection}>
        <Form>
          <Form.Item wrapperCol={{span: 5}} label={'岗位名称'}>
            <Input
              value={roleSet.roleName}
              onChange={e => {
                const roleName = e.target.value
                setRoleSet(prevState => ({...prevState, roleName}))
              }}
              allowClear
              placeholder='请输入'
              disabled={!!roleId}
            />
          </Form.Item>
        </Form>
        <div style={{display: 'flex', flexDirection: 'column'}}>
          <Recursion
            id=''
            onChange={target => {
              setRoleSet(prevState => ({...prevState, permissionCodeList: target.checked ? childPermissions : []}))
            }}
            disabled={disabled}
            value={roleSet.permissionCodeList.length === childPermissions.length ? [CHECK_ALL_ID] : []}
            label={'模块'}
            children={[{id: '', label: '权限', children: [{id: CHECK_ALL_ID, label: '全选', children: []}]}]}
          />
          {permissionTree.map(value => (
            <Recursion
              key={value.id}
              value={roleSet.permissionCodeList}
              disabled={disabled}
              onChange={target => {
                setRoleSet(prevState => ({
                  ...prevState,
                  permissionCodeList: target.checked
                    ? [...prevState.permissionCodeList, target.value]
                    : prevState.permissionCodeList.filter(item => item !== target.value),
                }))
              }}
              {...value}
            />
          ))}
        </div>
      </div>
    </Page>
  )
}

const RecursionRow: React.FC<{
  span?: number
  width?: number
  unFlex?: boolean
  style?: HTMLAttributes<any>['style']
  className?: string
}> = props => {
  return (
    <div
      className={`${styles.flexBox} ${props.className ? `${props.className}` : ''}`}
      style={{
        ...(props.span
          ? {width: `${(props.span / 24) * 100}%`}
          : props.width
          ? {width: props.width}
          : !props.unFlex
          ? {flex: 1}
          : {}),
        ...props.style,
      }}
    >
      {props.children}
    </div>
  )
}

// 递归渲染权限
function Recursion({
  depth = 0,
  ...props
}: TeamPermissionTreeNodeVO & {
  depth?: number
  unFlex?: boolean
  value: string[]
  onChange?: (target: CheckboxChangeEventTarget) => void
  disabled?: boolean
}) {
  const leaf = !props.children?.length
  const isEnd = !props.children?.[0]?.children?.length

  return !leaf ? (
    <RecursionRow unFlex={props.unFlex} className={depth === 0 ? styles.listRow : ''}>
      <RecursionRow className={depth === 0 ? styles.listTitle : ''} width={120}>
        {props.label}
      </RecursionRow>
      <RecursionRow style={!isEnd ? {flexDirection: 'column'} : {}}>
        {props.children.map(value => (
          <Recursion
            disabled={props.disabled}
            key={value.id}
            {...value}
            unFlex
            value={props.value}
            onChange={props.onChange}
            depth={depth + 1}
          />
        ))}
      </RecursionRow>
    </RecursionRow>
  ) : (
    <RecursionRow span={4} className={styles.listItem}>
      <Checkbox
        value={props.id}
        checked={props.value?.includes(props.id)}
        disabled={props.disabled}
        onChange={e => props.onChange?.(e.target)}
      >
        {props.label}
      </Checkbox>
    </RecursionRow>
  )
}
