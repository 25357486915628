import React, {useEffect, useMemo, useState} from 'react'
import {Row, Col, Button, Modal} from 'antd'
import {propertyModel} from '@/store/models/Property'
import {baseCodes} from '@/store/models/Common/baseCodes'
import {getImg} from '@/utils/util'
import '../index.less'
import commonStyles from '@/assets/styles/common.module.less'
import {useAppHistory, useAppLocation} from '@/common/hooks/useRouter'
import {Page} from '@/components/Page'
import BackButton from '@/components/BackButton'

const property = {
  // 户型
  houseType(params: Partial<Pick<PropertyAdminInfoVO, 'bedroom' | 'bathroom' | 'kitchen' | 'parlor'>>) {
    const {bedroom, bathroom, parlor, kitchen} = params
    return [bedroom && `${bedroom}室`, parlor && `${parlor}厅`, kitchen && `${kitchen}厨`, bathroom && `${bathroom}卫`]
      .filter(item => item)
      .join('')
  },

  // 付款方式
  payType(params: Pick<PropertyAdminInfoVO, 'rentPayType' | 'rentPledgeType'>) {
    const {rentPayType, rentPledgeType} = params
    return [rentPayType && `付${rentPayType}`, rentPledgeType && `押${rentPledgeType}`].filter(item => item).join('')
  },
}

export default function PropertyDetail() {
  const history = useAppHistory()
  const location = useAppLocation<'/property/detail'>()
  // 房源code
  const propertyCode = location.state?.propertyCode
  // 房源类型
  const rentTypeCode = useMemo(() => baseCodes.rentType, [])
  // 朝向
  const orientationCode = useMemo(() => baseCodes.orientation, [])
  // 电梯
  const liftCode = useMemo(() => baseCodes.lift, [])
  // 出租状态
  const rentStatusCode = useMemo(() => baseCodes.rentStatus, [])
  // 房屋类型
  const propertyTypeCode = useMemo(() => baseCodes.propertyType, [])

  // 详情数据
  const [detailData, setDetailData] = useState({} as PropertyAdminInfoVO)

  // 选中的图片
  const [selectImg, setSelectImg] = useState<{imgUrl: string; visible: boolean}>({imgUrl: '', visible: false})

  useEffect(
    function () {
      propertyModel.queryPropertyDetail({propertyCode}).then(({response: {data}}) => {
        // console.log('房源详情 data =>', data)
        setDetailData(() => {
          return {
            ...data,
            equipment: JSON.parse(data.equipment as string),
          }
        })
      })
    },
    [propertyCode]
  )

  return (
    <Page>
      <BackButton />
      <div className={`${commonStyles.tableSection} pageDetail`}>
        <div className='table'>
          {/** 基础信息 */}
          <Row className='table--rowHeader'>
            <Col>基础信息：</Col>
            <Col>（房源ID：{detailData.propertyCode}）</Col>
          </Row>
          <Row className='table--rowNormal'>
            <Col span={detailData?.rentType === '2' ? 12 : 8}>房源类型：{rentTypeCode[detailData.rentType]}</Col>
            <div className='vertical' />
            <Col span={detailData?.rentType === '2' ? 11 : 8}>房源小区（公寓名）：{detailData.communityName}</Col>
            {detailData?.rentType === '1' || detailData?.rentType === '3' ? (
              <>
                <div className='vertical' />
                <Col span={7}>
                  户型：
                  {property.houseType({
                    bedroom: detailData.bedroom,
                    bathroom: detailData.bathroom,
                    kitchen: detailData.kitchen,
                    parlor: detailData.parlor,
                  })}
                </Col>
              </>
            ) : null}
          </Row>
          {detailData?.rentType === '2' ? (
            <Row className={'table--rowNormal'}>
              <Col span={8}>
                户型：
                {property.houseType({
                  bedroom: detailData.bedroom,
                  bathroom: detailData.bathroom,
                  parlor: detailData.parlor,
                })}
              </Col>
              <div className='vertical' />
              <Col span={8}>独立卫生间：{detailData?.selfBathroom === '1' ? '有' : '无'}</Col>
              <div className='vertical' />
              <Col span={7}>独立厨房：{detailData?.selfKitchen === '1' ? '有' : '无'}</Col>
            </Row>
          ) : null}
          <Row className='table--rowNormal'>具体地址：{`${detailData.communityAddress} ${detailData.title}`}</Row>
          <Row className='table--rowNormal'>
            <Col span={8}>默认房租：{detailData.rentPrice}</Col>
            <div className='vertical' />
            <Col span={8}>
              付款方式：
              {property.payType({rentPayType: detailData.rentPayType, rentPledgeType: detailData.rentPledgeType})}
            </Col>
            <div className='vertical' />
            <Col span={7}>出租状态：{rentStatusCode[detailData.rentStatus]}</Col>
          </Row>
          <Row className='table--rowNormal'>
            <Col span={8}>面积：{detailData.floorage}㎡</Col>
            <div className='vertical' />
            <Col span={8}>朝向：{orientationCode[detailData.orientation]}</Col>
            <div className='vertical' />
            <Col span={7}>定金：{detailData.deposit}</Col>
          </Row>
          <Row className='table--rowNormal'>
            房间配置：
            {(detailData?.equipment as {id: string; label: string; checked}[])
              ?.filter(item => item.checked)
              ?.map(item => item.label)
              ?.join(',')}
          </Row>
          <Row className='table--rowNormal'>备注：{detailData.remark}</Row>
          <Row className='table--rowImg'>
            房源照片：
            {detailData.imgList?.map(item => {
              return (
                <div key={item.imgId} className='table--img'>
                  <img
                    src={getImg(item.imgUrl)}
                    alt={'房源照片'}
                    width={88}
                    height={88}
                    onClick={() => setSelectImg({imgUrl: item.imgUrl, visible: true})}
                  />
                </div>
              )
            })}
          </Row>

          {/** 更多信息 */}
          <Row className='table--rowHeader'>更多信息</Row>
          {detailData.propertyPublic?.publicId ? (
            <Row className='table--rowNormal'>
              <Col flex={1}>房屋类型：{propertyTypeCode[detailData.propertyPublic?.propertyType] || ''}</Col>
              <div className='vertical' />
              <Col flex={1}>是否电梯：{liftCode[detailData.propertyPublic?.lift]}</Col>
              <div className='vertical' />
              <Col flex={1}>所在楼层：{detailData.propertyPublic?.floor}</Col>
              <div className='vertical' />
              <Col flex={1}>户型面积（合租）：{detailData.propertyPublic?.floorage}㎡</Col>
            </Row>
          ) : (
            <Row className='table--rowNormal'>
              <Col flex={1}>房屋类型：{propertyTypeCode[detailData.propertyType]}</Col>
              <div className='vertical' />
              <Col flex={1}>是否电梯：{liftCode[detailData.lift]}</Col>
              <div className='vertical' />
              <Col flex={1}>所在楼层：{detailData.floor}</Col>
              <div className='vertical' />
              <Col flex={1}>户型面积（合租）：{detailData.floorage}㎡</Col>
            </Row>
          )}

          {detailData.condoInfo?.imgList && (
            <Row className='table--rowImg'>
              公共区域照片：
              {detailData.condoInfo?.imgList?.map(item => {
                return (
                  <div key={item.imgId} className='table--img'>
                    <img
                      src={getImg(item.imgUrl)}
                      alt={'公区照片'}
                      width={88}
                      height={88}
                      onClick={() => setSelectImg({imgUrl: item.imgUrl, visible: true})}
                    />
                  </div>
                )
              })}
            </Row>
          )}

          {detailData.propertyPublic?.imgList && (
            <Row className='table--rowImg'>
              公共区域照片：
              {detailData.propertyPublic?.imgList?.map(item => {
                return (
                  <div key={item.imgId} className='table--img'>
                    <img
                      src={getImg(item.imgUrl)}
                      width={88}
                      height={88}
                      alt={'公区照片'}
                      onClick={() => setSelectImg({imgUrl: item.imgUrl, visible: true})}
                    />
                  </div>
                )
              })}
            </Row>
          )}

          {/** 租约信息 */}
          <Row className='table--rowHeader'>租约信息</Row>
          <Row justify={'center'} className='table--rowNormal'>
            <Button
              type='link'
              onClick={() => {
                // const location = {
                //   pathname: '../contract',
                //   state: {propertyCode},
                // }
                history.push('/contract', {propertyCode})
              }}
            >
              查看该房源的租约列表
            </Button>
          </Row>

          {/** 租约信息 */}
          <Row className='table--rowHeader'>历史服务单</Row>
          <Row justify={'center'} className='table--rowNormal'>
            <Button type='link' onClick={() => history.push('/order-list', {propertyCode})}>
              查看该房源的历史服务单
            </Button>
          </Row>
        </div>
        <div className='backView'>
          <Button type={'primary'} className='backBtn' onClick={() => history.goBack()}>
            返回
          </Button>
        </div>
      </div>

      {/** 预览图片 */}
      <Modal
        visible={selectImg.visible}
        title={'图片预览'}
        footer={null}
        onCancel={() => setSelectImg(state => ({...state, visible: false}))}
      >
        <img src={getImg(selectImg.imgUrl)} alt={'图片预览'} width='100%' />
      </Modal>
    </Page>
  )
}
