import React, {useCallback, useEffect} from 'react'
import commonStyles from '@/assets/styles/common.module.less'
import {condoModel} from '@/store/models/Condo'
import {Button, Checkbox, Form, InputNumber, message, Select, Space, Switch} from 'antd'
import {useAppHistory} from '@/common/hooks/useRouter'
import {useCodeArraySync} from '@/store/models/Common'
import {usePermission} from '@/components/Permission'

interface OperationSetComponentProps {
  condoProjectId: string
}
const billDateAhead2Days = new Array(16).fill(1).map((_, index) => index)
const billDays = new Array(32).fill(1).map((_, index) => ({id: index, label: `${index}天`}))
const billDates = new Array(28).fill(1).map((_, index) => ({id: index + 1, label: `${index + 1}日`}))
const OperationSetComponent: React.FC<OperationSetComponentProps> = ({condoProjectId}) => {
  const history = useAppHistory()
  const [form] = Form.useForm()
  const condoProjectBillDateType = useCodeArraySync('condo.project.billDateType')
  const [auth] = usePermission()

  const queryProjectInfo = useCallback(() => {
    condoModel.condoProjectInfo({condoProjectId}).then(({response: {data}}) => {
      form.setFieldsValue({
        projectName: data.projectName,
        condoProjectId: data.condoProjectId,
        contractBeginDay: data.contractBeginDay,
        displayTag: [
          data.displayTag2 === 1 && 'displayTag2',
          data.displayTag3 === 1 && 'displayTag3',
          data.displayTag4 === 1 && 'displayTag4',
          data.displayTag5 === 1 && 'displayTag5',
        ].filter(item => item),
        reserveAmt: data.reserveAmt,
        roomChangeFee: data.roomChangeFee,
        roomSubletFee: data.roomSubletFee,
        rentPayType: data.rentPayType,
        rentPledgeType: data.rentPledgeType,
        billDateType: data.billDateType,
        billDateAhead: data.billDateAhead,
        billDateAhead2: data.billDateType === '2' ? data.billDateAhead2 : undefined,
        propertyFee: data.propertyFee,
        propertyStatistics: data.propertyStatistics,
      })
    })
  }, [condoProjectId, form])

  useEffect(() => {
    if (condoProjectId && auth('006073002')) {
      queryProjectInfo()
    }
  }, [condoProjectId, queryProjectInfo, auth])

  return (
    <div className={commonStyles.filterSection}>
      <Form
        form={form}
        wrapperCol={{span: 13}}
        labelCol={{span: 3}}
        onFinish={async () => {
          const {displayTag, billDateType, ...formValues} = form.getFieldsValue(true)
          await condoModel.condoProjectConfig({
            ...formValues,
            displayTag2: displayTag.includes('displayTag2') ? 1 : 0,
            displayTag3: displayTag.includes('displayTag3') ? 1 : 0,
            displayTag4: displayTag.includes('displayTag4') ? 1 : 0,
            displayTag5: displayTag.includes('displayTag5') ? 1 : 0,
            billDateType,
          })
          message.success('设置成功！')
          history.goBack()
        }}
      >
        <Form.Item label={'项目名称'} shouldUpdate>
          {f => f.getFieldValue('projectName')}
        </Form.Item>
        <Form.Item label={'预定金额'} style={{marginBottom: 0}} required>
          <Space>
            <Form.Item name={'reserveAmt'} rules={[{required: true, message: '请输入预定金额'}]}>
              <InputNumber min={0} style={{width: 450}} />
            </Form.Item>
            <Form.Item>
              <span>元</span>
            </Form.Item>
          </Space>
        </Form.Item>
        <Form.Item label={'约定租期起始日'} style={{marginBottom: 0}} required>
          <Space>
            <Form.Item>
              <span>可选</span>
            </Form.Item>
            <Form.Item name={'contractBeginDay'} rules={[{required: true, message: '请输入约定租期起始日'}]}>
              <InputNumber placeholder={'请输入'} precision={0} min={0} style={{width: 414}} />
            </Form.Item>
            <Form.Item>
              <span>天内日期</span>
            </Form.Item>
          </Space>
        </Form.Item>
        <Form.Item label={' '} colon={false}>
          <span style={{color: 'rgba(0, 0, 0, 0.65)'}}>
            注：该数据限制预定公寓房间时，约定租期的起始日仅可选择，包括当日内的最晚日期。
          </span>
        </Form.Item>
        <Form.Item label={'换房费'} style={{marginBottom: 0}} required>
          <Space>
            <Form.Item name={'roomChangeFee'} rules={[{required: true, message: '请输入换房费'}]}>
              <InputNumber min={0} style={{width: 450}} />
            </Form.Item>
            <Form.Item>
              <span>元</span>
            </Form.Item>
          </Space>
        </Form.Item>
        <Form.Item label={'物业费'} style={{marginBottom: 0}} required>
          <Space>
            <Form.Item name={'propertyFee'} rules={[{required: true, message: '请输入物业费'}]}>
              <InputNumber defaultValue={0} min={0} max={9999} style={{width: 450}} />
            </Form.Item>
            <Form.Item>
              <span>元</span>
            </Form.Item>
          </Space>
        </Form.Item>
        <Form.Item label={'转租费'} style={{marginBottom: 0}} required>
          <Space>
            <Form.Item name={'roomSubletFee'} rules={[{required: true, message: '请输入转租费'}]}>
              <InputNumber min={0} style={{width: 450}} />
            </Form.Item>
            <Form.Item>
              <span>元</span>
            </Form.Item>
          </Space>
        </Form.Item>
        <Form.Item label={'收租类型'} style={{marginBottom: 0}} shouldUpdate required>
          {f => {
            const billDateType = f.getFieldValue('billDateType')
            return (
              <Space>
                <Form.Item name={'billDateType'} rules={[{required: true, message: '请选择收租类型'}]}>
                  <Select
                    style={{width: 195}}
                    placeholder={'请选择'}
                    allowClear
                    onClick={() => form.setFieldsValue({billDateAhead: undefined, billDateAhead2: undefined})}
                  >
                    {condoProjectBillDateType.map(item => (
                      <Select.Option value={item.id} key={item.id}>
                        {item.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                {billDateType === '1' && (
                  <Form.Item
                    name={'billDateAhead'}
                    label={'提前天数'}
                    rules={[{required: true, message: '请选择提前天数'}]}
                  >
                    <Select style={{width: 195}} placeholder={'请选择'} allowClear>
                      {billDays.map(item => (
                        <Select.Option key={item.id} value={item.id}>
                          {item.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                )}
                {billDateType === '2' && (
                  <Form.Item
                    name={'billDateAhead'}
                    label={'每月'}
                    rules={[{required: true, message: '请选择每月日期'}]}
                  >
                    <Select style={{width: 195}} placeholder={'请选择'} allowClear>
                      {billDates.map(item => (
                        <Select.Option key={item.id} value={item.id}>
                          {item.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                )}
              </Space>
            )
          }}
        </Form.Item>
        <Form.Item shouldUpdate noStyle style={{marginBottom: 0}}>
          {f => {
            return (
              f.getFieldValue('billDateType') === '2' && (
                <Form.Item label={'账单收款日'} style={{marginBottom: 0}} required>
                  <Space>
                    <Form.Item>
                      <span>提前</span>
                    </Form.Item>
                    <Form.Item name={'billDateAhead2'} rules={[{required: true, message: '请选择账单收款日'}]}>
                      <Select style={{width: 180}} placeholder={'请选择'}>
                        {billDateAhead2Days.map(item => (
                          <Select.Option value={item} key={item}>
                            {item}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item>
                      <span>天</span>
                    </Form.Item>
                  </Space>
                </Form.Item>
              )
            )
          }}
        </Form.Item>
        <Form.Item label={'付款方式'} style={{marginBottom: 0}}>
          <Space size={20}>
            <Form.Item>
              <span>付</span>
            </Form.Item>
            <Form.Item name={'rentPayType'}>
              <InputNumber placeholder={'请输入'} min={0} style={{width: 180}} />
            </Form.Item>
            <Form.Item>
              <span>押</span>
            </Form.Item>
            <Form.Item name={'rentPledgeType'}>
              <InputNumber placeholder={'请输入'} min={0} style={{width: 180}} />
            </Form.Item>
          </Space>
        </Form.Item>
        <Form.Item
          label={'房东数据统计'}
          name={'propertyStatistics'}
          getValueProps={value => ({checked: value === '1'})}
          valuePropName={'checked'}
          getValueFromEvent={event => (event ? '1' : '0')}
        >
          <Switch />
        </Form.Item>
        <Form.Item name={'displayTag'} label={'租客登记'}>
          <Checkbox.Group>
            <Checkbox value={'displayTag2'}>紧急联络人</Checkbox>
            <Checkbox value={'displayTag3'}>联络电话</Checkbox>
            <Checkbox value={'displayTag4'}>签约人</Checkbox>
            <Checkbox value={'displayTag5'}>获客渠道</Checkbox>
          </Checkbox.Group>
        </Form.Item>
        <Form.Item label={' '} colon={false}>
          <Space>
            <Button loading={condoModel.condoProjectConfig.useLoading()} type={'primary'} htmlType={'submit'}>
              提交
            </Button>
            <Button onClick={() => history.goBack()}>取消</Button>
          </Space>
        </Form.Item>
      </Form>
    </div>
  )
}

export default OperationSetComponent
