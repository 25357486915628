import React, {useCallback, useEffect, useImperativeHandle, useState} from 'react'
import {Button, Col, DatePicker, Descriptions, Form, Input, Modal, Row, Select, Space, Table} from 'antd'
import commonStyles from '@/assets/styles/common.module.less'
import {getPaginationProps} from '@/common/biz/getPaginationProps'
import DividerLine from '@/pages/property/component/DividerLine'
import {userModel} from '@/store/models/User'
import moment from 'moment'
import {FORMAT_DATE_END, FORMAT_DATE_START} from '@/common/date'
import {useCodeArraySync, useCodeSync} from '@/store/models/Common'
import {usePermission} from '@/components/Permission'
import {downloadBlob} from '@/utils/util'

interface InvoiceListComponentProps {
  userId?: string
}

function _InvoiceListComponent({userId}: InvoiceListComponentProps, ref) {
  const [auth] = usePermission()
  const [form] = Form.useForm()
  const [list, setList] = useState<UserLessorBillInvoiceVO[]>([])
  const [info, setInfo] = useState({} as UserLessorBillInvoiceVO)
  const [total, setTotal] = useState<number>(0)
  const [open, setOpen] = useState<boolean>(false)
  const invoiceStatusArr = useCodeArraySync('wework.approvalStatus')
  const invoiceStatus = useCodeSync('wework.approvalStatus')
  const invoiceCompanyArr = useCodeArraySync('invoice.company')
  const invoiceCompany = useCodeSync('invoice.company')
  const invoiceType = useCodeSync('invoice.type')
  const invoiceTitleTypeArr = useCodeArraySync('invoice.title.type')
  const invoiceTitleType = useCodeSync('invoice.title.type')
  const loading = userModel.userLessorBillInvoiceList.useLoading()
  const [invoiceAmount, setInvoiceAmount] = useState(0)

  useEffect(() => {
    if (userId) {
      form.setFieldsValue({customerId: userId})
    }
  }, [form, userId])

  const search = useCallback(() => {
    const formValues = form.getFieldsValue(true)
    userModel
      .userLessorBillInvoiceList(formValues as UserLessorBillInvoiceListDTO)
      .then(({response: {data, total}}) => {
        setList(data)
        setTotal(total)
      })

    userModel.userLessorBillInvoiceStatistics(formValues).then(({response: {data}}) => {
      setInvoiceAmount(data)
    })
  }, [form])

  useImperativeHandle(ref, () => ({
    export: () => {
      const formValues = form.getFieldsValue(true)
      downloadBlob({url: '/user/lessor/bill/invoice/list-excel', body: {...formValues, pageable: false}})
    },
  }))

  useEffect(() => {
    search()
  }, [search])

  return (
    <>
      <div className={commonStyles.filterSection}>
        <Form form={form} onFinish={search} onReset={search} initialValues={{pageNum: 1, pageSize: 10, pageable: true}}>
          <Row>
            {!userId && (
              <>
                <Col span={7}>
                  <Form.Item label={'客户名称'} name={'customerName'}>
                    <Input placeholder={'请输入'} disabled={!!userId} />
                  </Form.Item>
                </Col>
                <Col span={7} offset={1}>
                  <Form.Item label={'客户手机号'} name={'customerMobile'}>
                    <Input placeholder={'请输入'} disabled={!!userId} />
                  </Form.Item>
                </Col>
              </>
            )}
            <Col span={7} offset={userId ? 0 : 1}>
              <Form.Item label={'抬头类型'} name={'titleType'}>
                <Select options={invoiceTitleTypeArr} fieldNames={{value: 'id'}} placeholder={'请选择'} allowClear />
              </Form.Item>
            </Col>
            <Col span={7} offset={userId ? 1 : 0}>
              <Form.Item label={'发票抬头'} name={'invoiceTitle'}>
                <Input placeholder={'请输入'} allowClear />
              </Form.Item>
            </Col>
            <Col span={7} offset={1}>
              <Form.Item label={'开票公司'} name={'invoiceCompany'}>
                <Select options={invoiceCompanyArr} fieldNames={{value: 'id'}} placeholder={'请选择'} allowClear />
              </Form.Item>
            </Col>
            <Col span={7} offset={userId ? 0 : 1}>
              <Form.Item label={'开票申请状态'} name={'invoiceStatus'}>
                <Select options={invoiceStatusArr} fieldNames={{value: 'id'}} placeholder={'请选择'} allowClear />
              </Form.Item>
            </Col>
            <Col span={7} offset={userId ? 1 : 0}>
              <Form.Item label={'创建人'} name={'operatorName'}>
                <Input placeholder={'请输入'} />
              </Form.Item>
            </Col>
            <Col span={7} offset={1}>
              <Form.Item
                label={'申请时间'}
                name={'insertTimeBegin'}
                getValueFromEvent={(_, dateStrings) => {
                  form.setFieldsValue({
                    insertTimeEnd: dateStrings?.[1] && moment(dateStrings[1]).format(FORMAT_DATE_END),
                  })
                  return dateStrings?.[0] && moment(dateStrings[0]).format(FORMAT_DATE_START)
                }}
                getValueProps={value => ({
                  value: value ? [moment(value), moment(form.getFieldValue('insertTimeEnd'))] : value,
                })}
              >
                <DatePicker.RangePicker />
              </Form.Item>
            </Col>
          </Row>
          <Row justify={'end'}>
            <Space>
              <Button type={'primary'} htmlType={'submit'} onClick={() => form.setFieldsValue({pageNum: 1})}>
                查询
              </Button>
              <Button htmlType={'reset'}>重置</Button>
            </Space>
          </Row>
        </Form>
      </div>
      <div className={commonStyles.tableSection}>
        <Table
          rowKey={'invoiceId'}
          loading={loading}
          title={() => <span>{`合计：开票金额：${invoiceAmount}(${total}笔)`}</span>}
          columns={[
            ...(!userId
              ? [
                  {title: '客户', dataIndex: 'customerName'},
                  {title: '手机号', dataIndex: 'customerMobile'},
                ]
              : []),
            {
              title: '抬头类型',
              dataIndex: 'titleType',
              render: value => invoiceTitleType[value],
            },
            {title: '发票抬头', dataIndex: 'invoiceTitle'},
            {title: '税号', dataIndex: 'taxpayerIdentificationNumber'},
            {title: '开票公司', dataIndex: 'invoiceCompany', render: value => invoiceCompany[value]},
            {title: '开票金额', dataIndex: 'invoiceAmount'},
            {title: '开票申请状态', dataIndex: 'invoiceStatus', render: value => invoiceStatus[value]},
            {title: '申请人', dataIndex: 'operatorName'},
            {title: '开票申请时间', dataIndex: 'insertTime'},
            {
              title: '操作',
              dataIndex: 'invoiceId',
              render: invoiceId => {
                return (
                  (auth('001201002') || (userId && auth('001199002'))) && (
                    <Button
                      type={'link'}
                      onClick={async () => {
                        const {
                          response: {data},
                        } = await userModel.userLessorBillInvoiceInfo({invoiceId})
                        setOpen(true)
                        setInfo(data)
                      }}
                    >
                      查看
                    </Button>
                  )
                )
              },
            },
          ]}
          dataSource={list}
          pagination={getPaginationProps(form, total)}
        />
      </div>

      <Modal
        title={'查看详情'}
        open={open}
        width={600}
        footer={null}
        centered
        destroyOnClose
        onCancel={() => setOpen(false)}
      >
        <DividerLine title={'申请信息'} bottom={15} />
        <Descriptions column={2}>
          <Descriptions.Item label={'申请人'}>{info.operatorName}</Descriptions.Item>
          <Descriptions.Item label={'开票申请时间'}>{info.insertTime}</Descriptions.Item>
          <Descriptions.Item label={'申请状态'}>{invoiceStatus[info.invoiceStatus]}</Descriptions.Item>
          {['2', '4'].includes(info.invoiceStatus) && (
            <Descriptions.Item label={'审批时间'}>{info.updateTime}</Descriptions.Item>
          )}
        </Descriptions>
        <DividerLine title={'开票详情'} bottom={15} />
        <Descriptions column={2}>
          <Descriptions.Item label={'账单id'}>{info.billIdList?.join(',')}</Descriptions.Item>
          <Descriptions.Item label={'开票公司'}>{invoiceCompany[info.invoiceCompany]}</Descriptions.Item>
          <Descriptions.Item label={'客户名称'}>{info.customerName}</Descriptions.Item>
          <Descriptions.Item label={'发票类型'}>{invoiceType[info.invoiceType]}</Descriptions.Item>
          <Descriptions.Item label={'开票金额'}>{info.invoiceAmount}</Descriptions.Item>
          <Descriptions.Item label={'抬头类型'}>{invoiceTitleType[info.titleType]}</Descriptions.Item>
          <Descriptions.Item label={'发票抬头'}>{info.invoiceTitle}</Descriptions.Item>
          <Descriptions.Item label={'纳税人识别号'}>{info.taxpayerIdentificationNumber ?? '/'}</Descriptions.Item>
          <Descriptions.Item label={'单位地址'}>{info.companyAddress ?? '/'}</Descriptions.Item>
          <Descriptions.Item label={'单位电话'}>{info.companyPhone ?? '/'}</Descriptions.Item>
          <Descriptions.Item label={'开户银行'}>{info.bankName ?? '/'}</Descriptions.Item>
          <Descriptions.Item label={'银行账户'}>{info.bankAccount ?? '/'}</Descriptions.Item>
          <Descriptions.Item label={'收款时间'}>{info.paymentTime ?? '/'}</Descriptions.Item>
          <Descriptions.Item label={'联系人'}>{info.contactPerson ?? '/'}</Descriptions.Item>
          <Descriptions.Item label={'联系电话'}>{info.contactPhone ?? '/'}</Descriptions.Item>
          <Descriptions.Item label={'邮箱'}>{info.email ?? '/'}</Descriptions.Item>
          <Descriptions.Item label={'备注'}>{info.remark ?? '/'}</Descriptions.Item>
        </Descriptions>
      </Modal>
    </>
  )
}

export const InvoiceListComponent = React.forwardRef(_InvoiceListComponent)
