import {userModel} from '@/store/models/User'
import React from 'react'
import StewardLeadHome from './StewardLeadHome'

export default function Home() {
  //账号角色列表
  const roleIdList = userModel.data.userInfo?.roleIdList

  return <>{roleIdList?.find(item => ['steward_director', 'steward_manager'].includes(item)) && <StewardLeadHome />}</>
}
