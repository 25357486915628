import {Page} from '@/components/Page'
import {Button, Col, Descriptions, Form, Input, message, Modal, Row, Select, Space, Switch, Table} from 'antd'
import React, {useCallback, useEffect, useState} from 'react'
import commonStyles from '@/assets/styles/common.module.less'
import DividerLine from '@/pages/property/component/DividerLine'
import {sysModel} from '@/store/models/Sys'
import {sysRoleModel} from '@/store/models/SysRole'
import {debounce} from '@/utils/util'
import {usePermission} from '@/components/Permission'
import {userModel} from '@/store/models/User'
import {getPaginationProps} from '@/common/biz/getPaginationProps'

export default function ReportManagement() {
  const orgId = userModel.data.userInfo?.orgId
  const [auth] = usePermission()
  const [form] = Form.useForm()
  const [list, setList] = useState<SysReportVO[]>([])
  const loading = sysModel.sysReportList.useLoading()
  const [editVisible, setEditVisible] = useState(false)
  const [editForm] = Form.useForm()
  const [roleList, setRoleList] = useState<SysRoleVO[]>([])
  const [info, setInfo] = useState({} as SysReportVO)

  const search = useCallback(() => {
    const formValues = form.getFieldsValue(true)
    sysModel.sysReportList({...formValues, reportOrgIdRelativeLike: orgId}).then(({response: {data, total}}) => {
      setList(data)
    })
  }, [form, orgId])

  const searchRoleList = useCallback(
    debounce(async value => {
      const {
        response: {data},
      } = await sysRoleModel.sysRoleList({status: '1', pageable: false} as SysListDTO)
      if (value) {
        setRoleList(data.filter(item => item.roleName.indexOf(value) !== -1))
      } else {
        setRoleList(data)
      }
    }),
    []
  )

  useEffect(() => {
    search()
  }, [search])

  return (
    <Page>
      <Form form={form} onFinish={search} onReset={search} initialValues={{pageNum: 1, pageSize: 10}}>
        <div className={commonStyles.filterSection}>
          <Row>
            <Col>
              <Form.Item label={'名称'} name={'reportName'}>
                <Input placeholder='请输入名称' allowClear />
              </Form.Item>
            </Col>
          </Row>
          <Row justify='end'>
            <Space>
              <Button type={'primary'} htmlType={'submit'} onClick={() => form.setFieldsValue({pageNum: 1})}>
                查询
              </Button>
              <Button htmlType={'reset'}>重置</Button>
            </Space>
          </Row>
        </div>
        <div className={commonStyles.filterSection}>
          <Table
            rowKey={'reportId'}
            loading={loading}
            pagination={getPaginationProps(form, list.length)}
            dataSource={list}
            columns={[
              {title: '报表名称', dataIndex: 'reportName'},
              {title: '角色', dataIndex: 'reportRoleList', render: text => text.map(item => item.value)?.join(',')},
              {title: '组织', dataIndex: 'reportOrgIdRelativeName'},
              {title: '状态', dataIndex: 'valid', render: text => (text === 1 ? '正常' : '禁用')},
              {
                title: '操作',
                render: record => {
                  return (
                    <Space>
                      {auth('023103002') && (
                        <Switch
                          checked={record.valid === 1}
                          onChange={value => {
                            Modal.confirm({
                              title: '提示',
                              content: `是否确认${value ? '启用' : '禁用'}该报表？`,
                              centered: true,
                              icon: null,
                              onOk: async () => {
                                await sysModel.sysReportUpdateValid({
                                  reportId: record.reportId,
                                  valid: value,
                                  reportRoleIdList: record.reportRoleIdList,
                                })
                                message.success(`报表已${value ? '启用' : '禁用'}！`)
                                search()
                              },
                            })
                          }}
                        />
                      )}
                      {auth('002103002') && (
                        <Button
                          style={{paddingLeft: 0, paddingRight: 0}}
                          type={'link'}
                          onClick={() => {
                            editForm.setFieldsValue({
                              reportId: record.reportId,
                              reportName: record.reportName,
                              reportRoleIdList: record.reportRoleList.map(item => item.key),
                            })
                            searchRoleList('')
                            setEditVisible(true)
                          }}
                        >
                          编辑
                        </Button>
                      )}
                      {auth('001103002') && (
                        <Button
                          style={{paddingLeft: 0, paddingRight: 0}}
                          type={'link'}
                          onClick={() => {
                            setInfo(record)
                          }}
                        >
                          查看
                        </Button>
                      )}
                    </Space>
                  )
                },
              },
            ]}
          />
        </div>
      </Form>

      {/* 报表详情 */}
      <Modal
        title={'报表详情'}
        visible={!!info.reportId}
        onCancel={() => setInfo({} as SysReportVO)}
        footer={null}
        centered
        width={700}
      >
        <DividerLine title={'基本信息'} style={{marginBottom: 10}} />
        <Descriptions column={2}>
          <Descriptions.Item labelStyle={{color: 'rgba(0, 0, 0, 0.65)'}} label={'报表名称'}>
            {info.reportName}
          </Descriptions.Item>
          <Descriptions.Item label={'ID'} labelStyle={{color: 'rgba(0, 0, 0, 0.65)'}}>
            {info.reportId}
          </Descriptions.Item>
          <Descriptions.Item label={'状态'} labelStyle={{color: 'rgba(0, 0, 0, 0.65)'}}>
            {info.valid === 1 ? '正常' : '禁用'}
          </Descriptions.Item>
          <Descriptions.Item label={'使用部门'} labelStyle={{color: 'rgba(0, 0, 0, 0.65)'}}>
            {info.reportOrgIdTarget}
          </Descriptions.Item>
          <Descriptions.Item label={'关联组织'} labelStyle={{color: 'rgba(0, 0, 0, 0.65)'}}>
            {info.reportOrgIdRelativeName}
          </Descriptions.Item>
          <Descriptions.Item label={'角色'} labelStyle={{color: 'rgba(0, 0, 0, 0.65)'}}>
            {info.reportRoleList?.map(item => item.value)?.join(',')}
          </Descriptions.Item>
          <Descriptions.Item label={'新增时间'} labelStyle={{color: 'rgba(0, 0, 0, 0.65)'}}>
            {info.insertTime}
          </Descriptions.Item>
          <Descriptions.Item label={'更新时间'} labelStyle={{color: 'rgba(0, 0, 0, 0.65)'}}>
            {info.updateTime}
          </Descriptions.Item>
          <Descriptions.Item label={'更新人'} labelStyle={{color: 'rgba(0, 0, 0, 0.65)'}}>
            {info.updateBy}
          </Descriptions.Item>
        </Descriptions>
      </Modal>

      {/* 编辑报表 */}
      <Modal
        title={'编辑报表'}
        afterClose={() => editForm.resetFields()}
        destroyOnClose
        visible={editVisible}
        onCancel={() => setEditVisible(false)}
        footer={null}
        centered
      >
        <Form
          form={editForm}
          onFinish={async () => {
            const {reportId, reportRoleIdList} = editForm.getFieldsValue(true)
            await sysModel.sysReportUpdateRole({reportId, reportRoleIdList} as SysReportUpdateDTO)
            setEditVisible(false)
            search()
            message.success('编辑成功！')
          }}
        >
          <Form.Item label={'报表名称'} shouldUpdate>
            {() => <span style={{fontWeight: 500}}>{editForm.getFieldValue('reportName')}</span>}
          </Form.Item>
          <Form.Item label={'角色'} wrapperCol={{span: 15}} name={'reportRoleIdList'}>
            <Select placeholder={'请选择'} mode={'multiple'} showSearch onSearch={searchRoleList} filterOption={false}>
              {roleList.map(item => (
                <Select.Option value={item.roleId} key={item.roleId}>
                  {item.roleName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Row justify={'end'}>
            <Space>
              <Button onClick={() => setEditVisible(false)}>取消</Button>
              <Button type={'primary'} htmlType={'submit'}>
                确认
              </Button>
            </Space>
          </Row>
        </Form>
      </Modal>
    </Page>
  )
}
