import React from 'react'
import {MapContext} from './mapContext'
import {renderToString} from 'react-dom/server'

export interface MarkerProps {
  lat: number
  lng: number
  children?: React.ReactNode
  options?: Record<string, any>

  onClick?: (event: any) => void
  /**
   * 更新依赖，主要用来更新 children 依赖
   */
  dependencies?: any[]
}

export class Marker extends React.Component<MarkerProps> {
  static contextType = MapContext
  context: React.ContextType<typeof MapContext>

  private instance = null

  componentDidMount() {
    this.init()
  }

  componentWillUnmount() {
    this.removeMarker()
  }

  componentDidUpdate(prevProps: Readonly<MarkerProps>) {
    const {dependencies, lng, lat} = this.props

    if (!dependencies || isDiff(dependencies, prevProps.dependencies)) {
      this.removeMarker()
      this.init()
    } else {
      if (prevProps.lng !== lng || prevProps.lat !== lat) {
        this.instance.setPosition(new window.BMapGL.Point(lng, lat))
      }
    }
  }

  private init = () => {
    const {lat, lng, options} = this.props
    // const offset = options?.offset // anchor 才真正起偏移作用
    const anchor = options?.anchor
    const markerOptions = {
      ...options,
      // ...(offset ? {offset: new window.BMapGL.Size(offset.width, offset.height)} : {}),
      ...(anchor ? {anchor: new window.BMapGL.Size(anchor.width, anchor.height)} : {}),
    }

    const point = new window.BMapGL.Point(lng, lat)
    this.instance = this.props.children
      ? new window.BMapGLLib.RichMarker(renderToString(<div>{this.props.children}</div>), point, markerOptions)
      : new window.BMapGL.Marker(point, markerOptions)
    if (this.props.onClick) {
      this.instance.addEventListener('onclick', this.props.onClick)
    }
    this.context.addOverlay(this.instance)
  }

  private removeMarker = () => {
    if (this.props.onClick) {
      this.instance?.removeEventListener('onclick', this.props.onClick)
    }
    this.context.removeOverlay(this.instance)
  }

  render() {
    // 不能返回 dom，否则在 Unmount 截断会报 react 错误
    return null
  }
}

function isDiff<T>(prev: T[], next: T[]) {
  if (prev === next) return false

  if (prev?.length !== next?.length) return true

  const maxLength = Math.max(prev?.length, next?.length)
  for (let i = 0; i < maxLength; i++) {
    if (prev[i] !== next[i]) return true
  }
  return false
}
