import {Page} from '@/components/Page'
import {Button, Col, DatePicker, Form, Input, Modal, Row, Select, Space, Table, Tabs, TimePicker} from 'antd'
import React, {useCallback, useEffect, useState} from 'react'
import commonStyles from '@/assets/styles/common.module.less'
import {Breadcrumb} from '@/components/Breadcrumb'
import {adminModel} from '@/store/models/Admin'
import {useCodeArraySync, useCodeSync} from '@/store/models/Common'
import {getPaginationProps} from '@/common/biz/getPaginationProps'
import {useAppHistory} from '@/common/hooks/useRouter'
import {userModel} from '@/store/models/User'
import {downloadBlob} from '@/utils/util'
import {usePermission} from '@/components/Permission'
import moment from 'moment'
import {condoModel} from '@/store/models/Condo'
import {useFocusEffect} from '@/common/hooks/useFocusEffect'

export default function CRMLookOrder() {
  const history = useAppHistory()
  const userId = userModel.data.userInfo?.userId
  const [form] = Form.useForm()
  const [list, setList] = useState<CondoCustomerVisitVO[]>([])
  const [total, setTotal] = useState(0)
  const visitStatusArr = useCodeArraySync('condo.cms.visitStatus')
  const visitStatus = useCodeSync('condo.cms.visitStatus')
  const visitResultArr = useCodeArraySync('condo.cms.visitResult')
  const visitResult = useCodeSync('condo.cms.visitResult')
  const recordSourceCode = useCodeSync('condo.cms.recordSource')
  const recordSourceArr = useCodeArraySync('condo.cms.recordSource')
  const [auth] = usePermission()
  const [activeKey, setActiveKey] = useState(() => {
    const permissionObj = {
      '001112001': '1',
      '003112002': '2',
    }
    const permission = Object.keys(permissionObj).find(item => auth(item))
    return permissionObj[permission]
  })

  const [editForm] = Form.useForm()
  const [editVisible, setEditVisible] = useState(false)
  const [params1, setParams1] = useState({})
  const [params2, setParams2] = useState({})
  const search = useCallback(() => {
    const values = form.getFieldsValue(true)
    adminModel
      .adminCondoCustomerVisitList({creatorId: activeKey === '2' ? userId : '', ...values})
      .then(({response: {data, total}}) => {
        setList(data)
        setTotal(total)
      })
  }, [activeKey, form, userId])

  useEffect(() => {
    search()
  }, [search])

  useFocusEffect(() => {
    search()
  })

  const [projectList, setProjectList] = useState<PairModel[]>([])
  useEffect(() => {
    condoModel.condoProjectTeamUserGet({sysUserId: userId}).then(({response: {data}}) => {
      if (data) {
        setProjectList(data.projectList)
      }
    })
  }, [userId])

  return (
    <Page
      breadcrumb={
        <Breadcrumb
          right={
            activeKey === '1' && (
              <Button
                type={'primary'}
                onClick={() => {
                  const values = form.getFieldsValue(true)
                  downloadBlob({url: '/admin/condo/customer/visit/list-excel', body: {...values}})
                }}
              >
                导出
              </Button>
            )
          }
        />
      }
    >
      <Tabs
        className={commonStyles.tabs}
        onChange={key => {
          const values = form.getFieldsValue(true)
          form.resetFields()
          if (activeKey === '1') setParams1(values)
          if (key === '1') form.setFieldsValue(params1)
          if (activeKey === '2') setParams2(values)
          if (key === '2') form.setFieldsValue(params2)
          setActiveKey(key)
        }}
        activeKey={activeKey}
      >
        <Tabs.TabPane tab={'全部'} key={'1'} disabled={!auth('001112001')} />
        <Tabs.TabPane tab={'个人创建'} key={'2'} disabled={!auth('003112002')} />
      </Tabs>
      <div className={commonStyles.filterSection}>
        <Form
          form={form}
          onFinish={search}
          onReset={search}
          initialValues={{pageNum: 1, pageSize: 10}}
          labelCol={{flex: '80px'}}
          labelAlign={'left'}
        >
          <Row gutter={35}>
            <Col span={8}>
              <Form.Item label={'编号'} name={'visitId'}>
                <Input placeholder='请输入' allowClear />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={'姓名'} name={'customerName'}>
                <Input placeholder='请输入' allowClear />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={'手机号'} name={'customerMobile'}>
                <Input placeholder='请输入' allowClear />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={'接单人'} name={'ownerName'}>
                <Input placeholder='请输入' allowClear />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={'预约公寓'} name={'appointCondoName'}>
                <Input placeholder='请输入' allowClear />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={'接收状态'} name={'acceptStatus'}>
                <Select placeholder={'请选择'} allowClear>
                  <Select.Option value={0}>未接收</Select.Option>
                  <Select.Option value={1}>已接收</Select.Option>
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={'带看单状态'} name={'visitStatus'}>
                <Select placeholder={'请选择'} allowClear>
                  {visitStatusArr.map(item => (
                    <Select.Option key={item.id} value={item.id}>
                      {item.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={'结果'} name={'visitResult'}>
                <Select placeholder={'请选择'} allowClear>
                  {visitResultArr.map(item => (
                    <Select.Option key={item.id} value={item.id}>
                      {item.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={'创建时间'} name={'time'}>
                <DatePicker.RangePicker
                  onChange={(_, dateStrings) => {
                    const [insertTimeBegin, insertTimeEnd] = dateStrings ?? []
                    form.setFieldsValue({
                      insertTimeBegin: insertTimeBegin ? insertTimeBegin + ' 00:00:00' : undefined,
                      insertTimeEnd: insertTimeEnd ? insertTimeEnd + ' 23:59:59' : undefined,
                    })
                  }}
                />
              </Form.Item>
            </Col>
            {activeKey === '1' && (
              <>
                <Col span={8}>
                  <Form.Item label={'创建人'} name={'creatorName'}>
                    <Input placeholder='请输入' allowClear />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  {/* 预约公寓id列表(WEB端默认会取当前用户的) */}
                  <Form.Item label={'所在项目'} name={'appointCondoIdList'}>
                    <Select allowClear showArrow showSearch={false} placeholder='全部' mode={'multiple'}>
                      {projectList.map(value => (
                        <Select.Option key={value.key} value={value.key}>
                          {value.value}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </>
            )}
            {activeKey === '1' && (
              <Col span={8}>
                <Form.Item label={'带看单来源'} name={'recordSource'}>
                  <Select placeholder={'请选择'} allowClear>
                    {recordSourceArr.map(item => (
                      <Select.Option key={item.id} value={item.id}>
                        {item.label}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            )}
          </Row>
          <Row justify={'end'}>
            <Space>
              <Button type={'primary'} htmlType={'submit'} onClick={() => form.setFieldsValue({pageNum: 1})}>
                查询
              </Button>
              <Button htmlType={'reset'}>重置</Button>
            </Space>
          </Row>
        </Form>
      </div>
      <div className={commonStyles.tableSection}>
        <Table
          rowKey={'visitId'}
          dataSource={list}
          columns={[
            {title: '编号', dataIndex: 'visitId'},
            {title: '带看单来源', dataIndex: 'recordSource', render: value => recordSourceCode[value]},
            {title: '姓名', dataIndex: 'customerName'},
            {title: '手机号', dataIndex: 'customerMobile', render: text => text ?? '/'},
            {title: '接单人', dataIndex: 'ownerName', render: value => value ?? '/'},
            {title: '预约时间', dataIndex: 'appointTime'},
            {title: '预约公寓', dataIndex: 'appointCondoName'},
            {title: '接收状态', dataIndex: 'acceptStatus', render: text => (text === '0' ? '未接收' : '已接收')},
            {title: '带看单状态', dataIndex: 'visitStatus', render: text => visitStatus[text]},
            {title: '结果', dataIndex: 'visitResult', render: text => visitResult[text] ?? '/'},
            {title: '创建人', dataIndex: 'creatorName'},
            {title: '创建时间', dataIndex: 'insertTime'},
            {
              title: '操作',
              render: record => (
                <Space>
                  <Button
                    type={'link'}
                    style={{paddingLeft: 0, paddingRight: 0}}
                    onClick={() => history.push({pathname: '/crm-look/detail', state: {visitId: record.visitId}})}
                  >
                    查看
                  </Button>
                  {activeKey === '1' && (
                    <Button
                      type={'link'}
                      style={{paddingLeft: 0, paddingRight: 0}}
                      onClick={() => {
                        Modal.confirm({
                          title: '提示',
                          content: '是否确认删除？',
                          centered: true,
                          icon: null,
                          onOk: async () => {
                            await adminModel.adminCondoCustomerVisitDelete({visitId: record.visitId})
                            form.submit()
                          },
                        })
                      }}
                    >
                      删除
                    </Button>
                  )}
                  {activeKey === '2' && record.visitStatus === '1' && (
                    <Button
                      type={'link'}
                      style={{paddingLeft: 0, paddingRight: 0}}
                      onClick={() => {
                        setEditVisible(true)
                        editForm.setFieldsValue({
                          time: moment(record.appointTime),
                          minute: moment(record.appointTime),
                          createRemark: record.createRemark,
                          visitId: record.visitId,
                        })
                      }}
                    >
                      编辑
                    </Button>
                  )}
                </Space>
              ),
            },
          ]}
          scroll={{x: 'max-content'}}
          pagination={getPaginationProps(form, total)}
        />
      </div>

      {/* 编辑 */}
      <Modal
        visible={editVisible}
        title={'编辑'}
        centered
        okText={'提交'}
        onCancel={() => setEditVisible(false)}
        afterClose={() => {
          editForm.resetFields()
        }}
        onOk={async () => {
          const {time, minute, ...values} = editForm.getFieldsValue(true)
          await editForm.validateFields()
          await adminModel.adminCondoCustomerVisitEdit({
            appointTime: moment(time).format('YYYY-MM-DD') + moment(minute).format(' HH:mm:ss'),
            ...values,
          })
          search()
          setEditVisible(false)
        }}
      >
        <Form form={editForm}>
          <Form.Item label={'预约时间'} required style={{marginBottom: 0}}>
            <Space>
              <Form.Item name={'time'} rules={[{required: true, message: '请选择预约日期'}]}>
                <DatePicker />
              </Form.Item>
              <Form.Item name={'minute'} rules={[{required: true, message: '请选择预约时间'}]}>
                <TimePicker showNow={false} minuteStep={30} format={'HH:mm'} />
              </Form.Item>
            </Space>
          </Form.Item>
          <Form.Item name={'createRemark'} label={'带看备注'}>
            <Input.TextArea placeholder='请输入' maxLength={200} showCount rows={3} />
          </Form.Item>
        </Form>
      </Modal>
    </Page>
  )
}
