import React, {useState} from 'react'
import {Button, Image} from 'antd'

export function PreviewButton(props: {src: string}) {
  const [visible, setVisible] = useState(false)

  return (
    <>
      <Button type='primary' shape='circle' onClick={() => setVisible(true)}>
        签
      </Button>
      <Image
        style={{display: 'none'}}
        preview={{
          visible,
          scaleStep: 0.5,
          src: props.src,
          onVisibleChange: value => setVisible(value),
        }}
      />
    </>
  )
}
