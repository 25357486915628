import React from 'react'
import {TreeSelect} from 'antd'

function setDefaultProps<P>(Component: React.ComponentType<P>, defaultProps: P) {
  if ('defaultProps' in Component) {
    Object.assign(Component.defaultProps, defaultProps)
  } else {
    Component.defaultProps = defaultProps
  }
}

setDefaultProps(TreeSelect, {fieldNames: {label: 'label', value: 'key', children: 'children'}})
