import React, {useEffect, useState} from 'react'
import {useHistory} from 'react-router-dom'
import {Button, Col, DatePicker, Form, Input, Modal, Row, Select, Space, Switch, Table} from 'antd'
import {ExclamationCircleFilled} from '@ant-design/icons'
import {lessorModel} from '@/store/models/Lessor'
import {useCodeSync} from '@/store/models/Common'
import './index.less'
import commonStyles from '@/assets/styles/common.module.less'
import {usePermission} from '@/components/Permission'
import {useFocusEffect} from '@/common/hooks/useFocusEffect'
import {getPaginationProps} from '@/common/biz/getPaginationProps'
import {Page} from '@/components/Page'

const {confirm} = Modal

interface InitProps {
  pageNum: number
  pageSize: number | undefined
}

const customers = [
  {id: '0', label: '否'},
  {id: '1', label: '是'},
]

export default function LessorList() {
  const [auth] = usePermission()
  const history = useHistory()
  const [form] = Form.useForm()
  const [total, setTotal] = useState(0)
  const [dataSource, setDataSource] = useState<LessorListVO[]>()
  const loading = lessorModel.queryLessorList.useLoading()

  const gender = useCodeSync('gender')
  const userStatus = useCodeSync('userStatus')

  const queryLessorList = () => {
    const values = form.getFieldsValue(true)
    lessorModel.queryLessorList(values).then(({response: {data, total}}) => {
      setDataSource(data)
      setTotal(total)
    })
  }

  useFocusEffect(() => {
    form.submit()
  })

  useEffect(() => {
    form.submit()
  }, [form])

  function changeStatus(checked: boolean, record: LessorListVO, event) {
    const {userId, certName, mobile, status} = record
    confirm({
      title: '提示',
      icon: <ExclamationCircleFilled />,
      content: (
        <>
          请确认已经核实过房东用户<span style={{color: '#FAAD14'}}>{certName}</span>手机号
          <span style={{color: '#FAAD14'}}>{mobile}</span>的相关信息，并符合{status === '1' ? '禁用' : '启用'}标准
        </>
      ),
      onOk() {
        lessorModel.lessorChangeStatus(checked ? '1' : '0', userId).then(() => {
          form.submit()
        })
      },
      // onCancel() {},
    })

    event.stopPropagation()
  }

  function edit(record, event) {
    const location = {
      pathname: '/lessor/addoredit',
      state: {userId: record.userId},
    }
    history.push(location)
    event.stopPropagation()
  }

  return (
    <Page>
      <div className={commonStyles.filterSection}>
        <Form
          form={form}
          autoComplete={'off'}
          initialValues={{pageNum: 1, pageSize: 10}}
          onFinish={queryLessorList}
          onReset={queryLessorList}
        >
          <Row gutter={20}>
            <Col span={7}>
              <Form.Item name={'certName'} label={'用户姓名'}>
                <Input allowClear placeholder={'请输入用户姓名'} />
              </Form.Item>
            </Col>
            <Col span={7} offset={1}>
              <Form.Item name={'mobile'} label={'手机号'}>
                <Input allowClear placeholder={'请输入手机号'} />
              </Form.Item>
            </Col>
            <Col span={7} offset={1}>
              <Form.Item name={'registerTime'} label={'注册时间'}>
                <DatePicker.RangePicker
                  allowClear
                  onChange={(_, formatString) => {
                    form.setFieldsValue({
                      registerTimeBegin: formatString?.[0],
                      registerTimeEnd: formatString?.[1],
                    })
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={20}>
            <Col span={7}>
              <Form.Item name={'customer'} label={'是否为客户'}>
                <Select allowClear placeholder={'请选择'}>
                  {customers.map(item => {
                    return (
                      <Select.Option key={item?.id} value={item?.id}>
                        {item?.label}
                      </Select.Option>
                    )
                  })}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row justify={'end'}>
            <Space>
              <Button type={'primary'} htmlType={'submit'} onClick={() => form.setFieldsValue({pageNum: 1})}>
                查询
              </Button>
              <Button htmlType={'reset'}>重置</Button>
            </Space>
          </Row>
        </Form>
      </div>

      <div className={commonStyles.tableSection}>
        <Table
          rowKey={'userId'}
          dataSource={dataSource}
          // size={'middle'}
          loading={loading}
          columns={[
            {
              title: '用户姓名',
              dataIndex: 'certName',
              key: 'certName',
            },
            {
              title: '性别',
              dataIndex: 'gender',
              key: 'gender',
              render: text => {
                return <>{text ? gender[text] : ''}</>
              },
            },
            {title: '注册手机', dataIndex: 'mobile', key: 'mobile'},
            {
              title: '是否为客户',
              dataIndex: 'customer',
              key: 'customer',
              render: text => {
                return text === '0' ? '否' : text === '1' ? '是' : ''
              },
            },
            {title: '注册时间', dataIndex: 'registerTime', key: 'registerTime'},
            {
              title: '状态',
              dataIndex: 'status',
              key: 'status',
              render: text => {
                return <span>{userStatus[text]}</span>
              },
            },
            {
              title: '操作',
              dataIndex: 'operation',
              width: 180,
              render: (text, record) => {
                return (
                  <Space>
                    {auth('005030002') && (
                      <Switch
                        checked={record.status === '1'}
                        onChange={(checked, event) => changeStatus(checked, record, event)}
                        size={'small'}
                      />
                    )}
                    {auth('002030001') && (
                      <Button type={'link'} size={'small'} onClick={event => edit(record, event)}>
                        编辑
                      </Button>
                    )}
                    {auth('001039001') && (
                      <Button
                        type={'link'}
                        size={'small'}
                        onClick={() =>
                          history.push({
                            pathname: '/lessor/detail',
                            state: {userId: record.userId},
                          })
                        }
                      >
                        查看
                      </Button>
                    )}
                  </Space>
                )
              },
            },
          ]}
          pagination={getPaginationProps(form, total)}
        />
      </div>
    </Page>
  )
}
