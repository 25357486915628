import {useEffect, useRef, useState} from 'react'
import {useHistory} from 'react-router-dom'

import type {Action, Location} from 'history'

type FocusAction = Action | 'PUSH|POP' | 'PUSH|REPLACE' | 'POP|REPLACE' | 'PUSH|POP|REPLACE'

/**
 * 页面聚焦时触发 callback，用在 Keep Alive 的页面
 * 可选择触发方式，默认返回(POP)触发
 * @example
 * useFocusEffect(() => {
 *   search()
 * })
 */
export function useFocusEffect(callback?: (focusLocation: Location) => void, actions: FocusAction = 'POP') {
  const history = useHistory()
  const [pathname] = useState(history.location.pathname)

  const callbackRef = useRef(callback)
  callbackRef.current = callback

  useEffect(() => {
    return history.listen((location, action) => {
      if (actions.includes(action) && location.pathname === pathname) {
        callbackRef.current(location)
      }
    })
  }, [actions, history, pathname])
}
