import React, {useEffect, useState} from 'react'
import './addSysUser.less'
import {Button, Checkbox, Form, Input, Transfer} from 'antd'
import {sysRoleModel} from '@/store/models/SysRole'
import commonStyles from '@/assets/styles/common.module.less'
import {sysPermissionModel} from '@/store/models/SysPermission'
import {useAppHistory, useAppLocation} from '@/common/hooks/useRouter'
import {Page} from '@/components/Page'
import BackButton from '@/components/BackButton'
import {useListener} from '@/common/hooks/listener'
import {useCodeArraySync} from '@/store/models/Common'

const {TextArea} = Input

interface LeftPermissionsData {
  title: string
  key: string
}

export default function AddSysUser() {
  const history = useAppHistory()
  const location = useAppLocation<'/sys-role/add'>()
  // 主键
  const roleId = location.state?.roleId

  const [form] = Form.useForm()
  const [leftPermissionsData, setLeftPermissionsData] = useState<LeftPermissionsData[]>()
  const [selectKeys] = useState<string[]>()
  const [targetKeys, setTargetKeys] = useState<string[]>()

  const roleMsgArr = useCodeArraySync('role.msg')
  const todoListArr = useCodeArraySync('role.todo.list')

  useEffect(() => {
    //左侧权限角色列表
    sysPermissionModel.sysPermissionList({pageNum: 1, pageSize: 10, pageable: false}).then(({response: {data}}) => {
      const result = data.map(item => {
        return {
          title: item.permissionName,
          key: item.permissionId,
        }
      })
      setLeftPermissionsData(result)
    })

    //更新系统角色
    if (roleId) {
      sysRoleModel.sysRoleDetail(roleId).then(({response: {data}}) => {
        form.setFieldsValue({...data, orderTodoTypeIdList: data.sysRoleSysTodoIdList})
        setTargetKeys(data.permissionIdList)
      })
    }
  }, [form, roleId])

  useListener('AddSysUser: ids', (ids: string[]) => {
    setTargetKeys(ids)
  })

  //新增或修改系统角色
  function onFinish() {
    const args = form.getFieldsValue(true) as SysRoleDTO
    sysRoleModel
      .upsertSysRole({...args, permissionIdList: targetKeys?.filter(item => item)})
      .then(() => history.goBack())
  }

  //备注验证
  function remarkVaildator(rule: any, value: any, callback: Function) {
    if (value && value.length > 200) {
      callback('备注长度应在0~200之间')
      return
    }
    callback()
  }

  return (
    <Page>
      <BackButton />
      <div className={commonStyles.tableSection}>
        <Form labelCol={{span: 3}} wrapperCol={{span: 4}} colon={false} form={form} onFinish={onFinish}>
          <Form.Item name={'roleName'} label={'用户组名称'} rules={[{required: true, message: '用户组名称不能为空'}]}>
            <Input size={'middle'} placeholder={'请输入用户组名称'} allowClear />
          </Form.Item>
          <Form.Item name={'roleId'} label={'用户组描述'} rules={[{required: true, message: '用户组描述不能为空！'}]}>
            <Input size={'middle'} allowClear placeholder={'请输入字母的用户组描述'} />
          </Form.Item>
          <Form.Item name={'permissionIdList'} wrapperCol={{flex: 1}} label={'权限'} labelCol={{span: 3}}>
            <Transfer
              dataSource={leftPermissionsData}
              render={item => item.title}
              selectedKeys={selectKeys}
              targetKeys={targetKeys}
              showSearch
              filterOption={(inputValue, item) => item.title?.includes(inputValue as any)}
              listStyle={{width: '50%', height: 400}}
              locale={{itemUnit: '', itemsUnit: ''}}
              titles={['可分配菜单权限', '已分配菜单权限']}
              onChange={targetKeys => setTargetKeys(targetKeys)}
              // showSelectAll={false}
              // selectAllLabels={['全部', '全部']}
            />
          </Form.Item>
          <Form.Item label={'集中式权限'}>
            <Input
              placeholder={'请配置集中式权限'}
              onClick={() => {
                history.push('/oms-permission', {permissionIdList: targetKeys})
              }}
            />
          </Form.Item>
          <Form.Item label={'消息提醒类型'} wrapperCol={{span: 24}} shouldUpdate>
            {form1 => {
              const msgTypeIdList = form1.getFieldValue('msgTypeIdList') ?? []
              const indeterminate = msgTypeIdList.length > 0 && msgTypeIdList.length < roleMsgArr.length
              return (
                <>
                  <Checkbox
                    indeterminate={indeterminate}
                    onChange={e => {
                      form1.setFieldsValue({
                        msgTypeIdList: e.target.checked
                          ? roleMsgArr.reduce((acc, item) => {
                              return [...acc, item.id]
                            }, [])
                          : [],
                      })
                    }}
                    checked={roleMsgArr.length === msgTypeIdList.length}
                  >
                    全选
                  </Checkbox>
                  <Form.Item name={'msgTypeIdList'} noStyle>
                    <Checkbox.Group>
                      {roleMsgArr.map(item => (
                        <Checkbox key={item.id} value={item.id}>
                          {item.label}
                        </Checkbox>
                      ))}
                    </Checkbox.Group>
                  </Form.Item>
                </>
              )
            }}
          </Form.Item>
          <Form.Item label={'待办处理'} wrapperCol={{span: 24}} shouldUpdate>
            {form1 => {
              const orderTodoTypeIdList = form1.getFieldValue('orderTodoTypeIdList') ?? []
              const indeterminate = orderTodoTypeIdList.length > 0 && orderTodoTypeIdList.length < todoListArr.length
              return (
                <>
                  <Checkbox
                    indeterminate={indeterminate}
                    onChange={e => {
                      form1.setFieldsValue({
                        orderTodoTypeIdList: e.target.checked
                          ? todoListArr.reduce((acc, item) => {
                              return [...acc, item.id]
                            }, [])
                          : [],
                      })
                    }}
                    checked={todoListArr.length === orderTodoTypeIdList.length}
                  >
                    全选
                  </Checkbox>
                  <Form.Item name={'orderTodoTypeIdList'} noStyle>
                    <Checkbox.Group>
                      {todoListArr.map(item => (
                        <Checkbox key={item.id} value={item.id}>
                          {item.label}
                        </Checkbox>
                      ))}
                    </Checkbox.Group>
                  </Form.Item>
                </>
              )
            }}
          </Form.Item>
          <Form.Item
            name={'remark'}
            label={'备注信息'}
            wrapperCol={{span: 6}}
            rules={[
              {
                validator: remarkVaildator,
              },
            ]}
          >
            <TextArea allowClear rows={3} placeholder={'请输入备注信息'} />
          </Form.Item>
          <div className={'bottom'}>
            <Button type={'primary'} htmlType={'submit'} style={{marginRight: 20}}>
              保存并提交
            </Button>
            <Button type={'default'} onClick={() => history.goBack()}>
              取消
            </Button>
          </div>
        </Form>
      </div>
    </Page>
  )
}
