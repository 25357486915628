import React, {useCallback, useEffect, useMemo, useState} from 'react'
import commonStyle from '@/assets/styles/common.module.less'
import {Breadcrumb} from '@/components/Breadcrumb'
import {
  Col,
  Form,
  Input,
  Row,
  Select,
  DatePicker,
  Space,
  Button,
  Table,
  Tabs,
  TreeSelect,
  Modal,
  Tree,
  message,
} from 'antd'
import {saleModel} from '@/store/models/Sale'
import {commonModel, useCodeArraySync, useCodeSync} from '@/store/models/Common'
import {downloadBlob} from '@/utils/util'
import {Permission, usePermission} from '@/components/Permission'
import {Page} from '@/components/Page'
import styles from './index.module.less'
import moment from 'moment'
import {userModel} from '@/store/models/User'
import {sysModel} from '@/store/models/Sys'
import {useAppHistory} from '@/common/hooks/useRouter'
import {OrgRoleId} from '@/common/constants'
import {getPaginationProps} from '@/common/biz/getPaginationProps'
import {useFocusEffect} from '@/common/hooks/useFocusEffect'

// const city = [
//   {code: '310100', name: '上海'},
//   {code: '440100', name: '广州'},
// ]
export default function SaleClientList() {
  const userInfo = userModel.useData(data => data.userInfo)
  const [auth] = usePermission()
  const history = useAppHistory()
  //意向度
  const customerStatusList = useCodeArraySync('sale.customer.status')?.filter(item => item.id !== '4')
  const customerStatus = useCodeSync('sale.customer.status')
  //邀约状态
  const visitStatusArr = useCodeArraySync('sale.customer.visit.status')
  const visitStatus = useCodeSync('sale.customer.visit.status')
  const [myDistrict, setMyDistrict] = useState([] as CityDataProps[])
  const [myBizCircle, setMyBizCircle] = useState([] as CityDataProps[])
  const [teamDistrict, setTeamDistrict] = useState([] as CityDataProps[])
  const [teamBizCircle, setTeamBizCircle] = useState([] as CityDataProps[])
  const loading = saleModel.saleCustomerList.useLoading()
  const [selectIds, setSelectIds] = useState<string[]>([])
  const defaultActiveKey = useMemo(() => {
    const permissionMap = {
      '001046001': '1',
      '001085001': '2',
      '001087001': '3',
    }
    const permission = Object.keys(permissionMap).find(value => auth(value))
    return permissionMap[permission]
  }, [auth])
  const [tabKey, setTabKey] = useState(defaultActiveKey)
  const [list, setList] = useState([] as SaleCustomerVO[])
  const [total, setTotal] = useState(0)
  const [form1] = Form.useForm()
  const [form2] = Form.useForm()
  const [form3] = Form.useForm()
  const newForm = useMemo(
    () =>
      ({
        '1': form1,
        '2': form2,
        '3': form3,
      }[tabKey]),
    [form1, form2, form3, tabKey]
  )
  const search = useCallback(() => {
    const values = newForm.getFieldsValue(true)
    saleModel.saleCustomerList(values).then(({response: {data, total}}) => {
      setList(data)
      setTotal(total)
    })
  }, [newForm])

  useFocusEffect(() => {
    switch (tabKey) {
      case '1':
        return form1.submit()
      case '2':
        return form2.submit()
      case '3':
        return form3.submit()
    }
  })

  useEffect(() => {
    search()
  }, [search])

  const [teamTree, setTeamTree] = useState<SysOrgTreeNodeVO[]>([])
  const [followForm] = Form.useForm()
  const [followVisible, setFollowVisible] = useState(false)
  const [outVisible, setOutVisible] = useState(false)
  const [outTree, setOutTree] = useState([])
  const [selectHold, setSelectHold] = useState({name: '', userId: ''} as {name: any; userId: any})

  useEffect(() => {
    sysModel
      .sysOrgTree({fetchUser: true, orgIdLike: userInfo.orgId, orgRoleId: OrgRoleId.cus_manager} as SysOrgTreeQueryDTO)
      .then(({response: {data}}) => {
        setTeamTree(data)
      })
  }, [userInfo.orgId])

  //团队选择树
  const renderArr = (data: SysOrgTreeNodeVO[]) => {
    const arr = data.map(item => {
      if (item.children) {
        const newChildren = renderArr(item.children)
        return {
          key: item.key,
          title: item.label,
          value: item.key,
          children: newChildren,
          type: item.type,
        }
      } else {
        return {
          key: item.key,
          title: item.label,
          value: item.key,
          children: item.children,
          type: item.type,
        }
      }
    })

    return arr
  }

  // 转出选择树
  async function selectTree() {
    const {
      response: {data},
    } = await sysModel.sysOrgTree({
      fetchUser: true,
      orgIdLike: userInfo.orgId,
      orgRoleId: OrgRoleId.cus_manager,
    } as SysOrgTreeQueryDTO)

    function changeTree(data: SysOrgTreeNodeVO[]) {
      const arr = data.map(item => {
        if (item.children) {
          const newChildren = changeTree(item.children)
          return {
            key: item.key,
            title: item.label,
            value: item.key,
            disabled: true,
            children: newChildren,
          }
        } else {
          return {
            key: item.key,
            title: item.label,
            value: item.key,
            disabled: item.type === 'org',
            children: item.children,
          }
        }
      })

      return arr
    }

    const newData = changeTree(data)
    setOutTree(newData)
  }

  const followRecord = async ({userId, customerId}: {userId?: string; customerId: string}) => {
    const {
      response: {data},
    } = await saleModel.saleCustomerFollowList({
      pageable: false,
      userId,
      customerId,
    } as SaleFollowListDTO)
    Modal.info({
      width: 900,
      title: '跟进记录',
      icon: null,
      centered: true,
      okText: '关闭',
      content: (
        <Table
          columns={[
            {title: '跟进人', dataIndex: 'holderName', width: 120},
            {
              title: '邀约状态',
              width: 120,
              dataIndex: 'visitStatus',
              render: text => visitStatus[text] || '/',
            },
            {title: '跟进时间', width: 150, dataIndex: 'followDate'},
            {title: '跟进详情', dataIndex: 'remark'},
          ]}
          rowKey={'followId'}
          dataSource={data}
          pagination={{position: ['bottomCenter']}}
        />
      ),
    })
  }

  const [city, setCity] = useState<CityDataProps[]>([])

  useEffect(() => {
    commonModel.queryCityCode({type: '2', parentCode: ''}).then(({response: {data}}) => setCity(data))
  }, [])

  return (
    <Page
      breadcrumb={
        <Breadcrumb
          right={
            <>
              {['1', '2'].includes(tabKey) && (
                <Permission code={['007046002', '007085001']}>
                  <Button
                    onClick={() => {
                      const value = newForm.getFieldsValue(true)
                      downloadBlob({
                        url: '/sale/customer/list-excel',
                        body: value,
                      })
                    }}
                  >
                    导出
                  </Button>
                </Permission>
              )}
            </>
          }
        />
      }
    >
      <Tabs
        defaultActiveKey={defaultActiveKey}
        className={styles.tabs}
        activeKey={tabKey}
        onChange={key => {
          setTabKey(key)
          setSelectIds([])
        }}
        tabBarExtraContent={
          <>
            {tabKey === '1' && (
              <Permission code={'003046002'}>
                <Button type={'primary'} onClick={() => history.push({pathname: './sale-client/add-customer'})}>
                  新增客户
                </Button>
              </Permission>
            )}
            {tabKey === '2' && (
              <Space>
                <Permission code={'024085001'}>
                  <Button
                    type={'primary'}
                    onClick={async () => {
                      if (!selectIds.length) return message.warning('请选择转出的客户！')
                      if (!outTree.length) {
                        await selectTree()
                      }
                      setOutVisible(true)
                    }}
                  >
                    批量转出
                  </Button>
                </Permission>
                <Permission code={'024085002'}>
                  <Button
                    type={'primary'}
                    onClick={async () => {
                      if (!selectIds.length) return message.warning('请选择转出的客户！')
                      await saleModel.saleCustomerMove({
                        customerIdList: selectIds,
                        toPublic: '1',
                        userId: userInfo.userId,
                      })
                      setSelectIds([])
                      search()
                    }}
                  >
                    转入公共客户池
                  </Button>
                </Permission>
              </Space>
            )}
          </>
        }
      >
        <Tabs.TabPane tab={'我的客户'} key={'1'} disabled={!auth('001046001')} forceRender>
          <div className={commonStyle.filterSection}>
            <Form
              form={form1}
              onFinish={search}
              onReset={search}
              initialValues={{pageNum: 1, pageSize: 10, holderId: userInfo.userId, publicCustomer: '0'}}
            >
              <Row>
                <Col span={8}>
                  <Form.Item label={'关键字'} name={'keyword'}>
                    <Input placeholder={'请输入客户名称或手机号'} style={{width: 200}} allowClear />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label={'意向度'} name={'customerStatus'}>
                    <Select style={{width: 200}} placeholder={'请选择'} allowClear>
                      {customerStatusList.map(item => (
                        <Select.Option value={item.id} key={item.id}>
                          {item.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label={'邀约状态'} name={'visitStatus'}>
                    <Select style={{width: 200}} placeholder={'请选择'} allowClear>
                      {visitStatusArr.map(item => (
                        <Select.Option key={item.id} value={item.id}>
                          {item.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Space>
                    <Form.Item label={'客户区域'} name={'cityCode'}>
                      <Select
                        style={{width: 100}}
                        placeholder={'请选择'}
                        onChange={(value: string) => {
                          if (value) {
                            commonModel.queryCityCode({type: '3', parentCode: value}).then(({response: {data}}) => {
                              setMyDistrict(data)
                              form1.setFieldsValue({bizCircleCode: undefined, districtCode: undefined})
                            })
                          } else {
                            setMyDistrict([])
                            form1.setFieldsValue({bizCircleCode: undefined, districtCode: undefined})
                          }
                          setMyBizCircle([])
                        }}
                        allowClear
                      >
                        {city.map(item => (
                          <Select.Option value={item.code} key={item.code}>
                            {item.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item name={'districtCode'}>
                      <Select
                        style={{width: 100}}
                        placeholder={'请选择'}
                        onChange={(value: string) => {
                          if (value) {
                            commonModel.queryCityCode({type: '4', parentCode: value}).then(({response: {data}}) => {
                              setMyBizCircle(data)
                              form1.setFieldsValue({bizCircleCode: undefined})
                            })
                          } else {
                            setMyBizCircle([])
                            form1.setFieldsValue({bizCircleCode: undefined})
                          }
                        }}
                        allowClear
                      >
                        {myDistrict.map(item => (
                          <Select.Option value={item.code} key={item.code}>
                            {item.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item name={'bizCircleCode'}>
                      <Select style={{width: 100}} placeholder={'请选择'} allowClear>
                        {myBizCircle.map(item => (
                          <Select.Option value={item.code} key={item.code}>
                            {item.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Space>
                </Col>
                <Col offset={1}>
                  <Form.Item label={'录入时间'} name={'time'}>
                    <DatePicker.RangePicker
                      showTime
                      onChange={value => {
                        const [insertTimeBegin, insertTimeEnd] = value
                          ? value.map(item => moment(item).format('YYYY-MM-DD HH:mm:ss'))
                          : []
                        form1.setFieldsValue({insertTimeBegin, insertTimeEnd})
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row justify={'end'}>
                <Space>
                  <Button type={'primary'} htmlType={'submit'} onClick={() => form1.setFieldsValue({pageNum: 1})}>
                    查询
                  </Button>
                  <Button htmlType={'reset'}>重置</Button>
                </Space>
              </Row>
            </Form>
          </div>
        </Tabs.TabPane>

        {/* 团队客户 */}
        <Tabs.TabPane tab={'团队客户'} key={'2'} disabled={!auth('001085001')} forceRender>
          <div className={commonStyle.filterSection}>
            <Form
              form={form2}
              onFinish={search}
              onReset={search}
              initialValues={{pageNum: 1, pageSize: 10, orgId: userInfo.orgId, publicCustomer: '0'}}
            >
              <Row>
                <Col span={8}>
                  <Form.Item label={'关键字'} name={'keyword'}>
                    <Input placeholder={'请输入客户名称或手机号'} style={{width: 200}} allowClear />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label={'意向度'} name={'customerStatus'}>
                    <Select style={{width: 200}} placeholder={'请选择'} allowClear>
                      {customerStatusList.map(item => (
                        <Select.Option value={item.id} key={item.id}>
                          {item.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label={'邀约状态'} name={'visitStatus'}>
                    <Select style={{width: 200}} placeholder={'请选择'} allowClear>
                      {visitStatusArr.map(item => (
                        <Select.Option key={item.id} value={item.id}>
                          {item.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Space>
                    <Form.Item label={'客户区域'} name={'cityCode'}>
                      <Select
                        style={{width: 100}}
                        placeholder={'请选择'}
                        onChange={(value: string) => {
                          if (value) {
                            commonModel.queryCityCode({type: '3', parentCode: value}).then(({response: {data}}) => {
                              setTeamDistrict(data)
                              form2.setFieldsValue({bizCircleCode: undefined, districtCode: undefined})
                            })
                          } else {
                            setTeamDistrict([])
                            form2.setFieldsValue({bizCircleCode: undefined, districtCode: undefined})
                          }
                          setTeamBizCircle([])
                        }}
                        allowClear
                      >
                        {city.map(item => (
                          <Select.Option value={item.code} key={item.code}>
                            {item.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item name={'districtCode'}>
                      <Select
                        style={{width: 100}}
                        placeholder={'请选择'}
                        onChange={(value: string) => {
                          if (value) {
                            commonModel.queryCityCode({type: '4', parentCode: value}).then(({response: {data}}) => {
                              setTeamBizCircle(data)
                              form2.setFieldsValue({bizCircleCode: undefined})
                            })
                          } else {
                            setTeamBizCircle([])
                            form2.setFieldsValue({bizCircleCode: undefined})
                          }
                        }}
                        allowClear
                      >
                        {teamDistrict.map(item => (
                          <Select.Option value={item.code} key={item.code}>
                            {item.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item name={'bizCircleCode'}>
                      <Select style={{width: 100}} placeholder={'请选择'} allowClear>
                        {teamBizCircle.map(item => (
                          <Select.Option value={item.code} key={item.code}>
                            {item.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Space>
                </Col>
                <Col offset={1} span={8}>
                  <Form.Item label={'团队选择'} name={'_org'}>
                    <TreeSelect
                      showSearch
                      dropdownStyle={{maxHeight: 400, overflow: 'auto'}}
                      placeholder='请选择'
                      allowClear
                      treeDefaultExpandAll
                      onSelect={(value, node) => {
                        if (node.type === 'org') {
                          form2.setFieldsValue({orgId: value, holderId: ''})
                        } else {
                          form2.setFieldsValue({holderId: value, orgId: ''})
                        }
                      }}
                      treeData={renderArr(teamTree)}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Item label={'录入时间'} name={'time'}>
                    <DatePicker.RangePicker
                      showTime
                      onChange={value => {
                        const [insertTimeBegin, insertTimeEnd] = value
                          ? value.map(item => moment(item).format('YYYY-MM-DD HH:mm:ss'))
                          : []
                        form2.setFieldsValue({insertTimeBegin, insertTimeEnd})
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row justify={'end'}>
                <Space>
                  <Button type={'primary'} htmlType={'submit'} onClick={() => form2.setFieldsValue({pageNum: 1})}>
                    查询
                  </Button>
                  <Button htmlType={'reset'}>重置</Button>
                </Space>
              </Row>
            </Form>
          </div>
        </Tabs.TabPane>

        {/* 公共客户 */}
        <Tabs.TabPane tab={'公共客户'} key={'3'} disabled={!auth('001087001')} forceRender>
          <div className={commonStyle.filterSection}>
            <Form
              form={form3}
              onFinish={search}
              onReset={search}
              initialValues={{pageNum: 1, pageSize: 10, orgId: userInfo.orgId, publicCustomer: '1'}}
            >
              <Row>
                <Col span={8}>
                  <Form.Item label={'关键字'} name={'keyword'}>
                    <Input placeholder={'请输入客户名称或手机号'} style={{width: 200}} allowClear />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label={'意向度'} name={'customerStatus'}>
                    <Select style={{width: 200}} placeholder={'请选择'} allowClear>
                      {customerStatusList.map(item => (
                        <Select.Option value={item.id} key={item.id}>
                          {item.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label={'邀约状态'} name={'visitStatus'}>
                    <Select style={{width: 200}} placeholder={'请选择'} allowClear>
                      {visitStatusArr.map(item => (
                        <Select.Option key={item.id} value={item.id}>
                          {item.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Space>
                    <Form.Item label={'客户区域'} name={'cityCode'}>
                      <Select
                        style={{width: 100}}
                        placeholder={'请选择'}
                        onChange={(value: string) => {
                          if (value) {
                            commonModel.queryCityCode({type: '3', parentCode: value}).then(({response: {data}}) => {
                              setTeamDistrict(data)
                              form3.setFieldsValue({bizCircleCode: undefined, districtCode: undefined})
                            })
                          } else {
                            setTeamDistrict([])
                            form3.setFieldsValue({bizCircleCode: undefined, districtCode: undefined})
                          }
                          setTeamBizCircle([])
                        }}
                        allowClear
                      >
                        {city.map(item => (
                          <Select.Option value={item.code} key={item.code}>
                            {item.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item name={'districtCode'}>
                      <Select
                        style={{width: 100}}
                        placeholder={'请选择'}
                        onChange={(value: string) => {
                          if (value) {
                            commonModel.queryCityCode({type: '4', parentCode: value}).then(({response: {data}}) => {
                              setTeamBizCircle(data)
                              form3.setFieldsValue({bizCircleCode: undefined})
                            })
                          } else {
                            setTeamBizCircle([])
                            form3.setFieldsValue({bizCircleCode: undefined})
                          }
                        }}
                        allowClear
                      >
                        {teamDistrict.map(item => (
                          <Select.Option value={item.code} key={item.code}>
                            {item.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item name={'bizCircleCode'}>
                      <Select style={{width: 100}} placeholder={'请选择'} allowClear>
                        {teamBizCircle.map(item => (
                          <Select.Option value={item.code} key={item.code}>
                            {item.name}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Space>
                </Col>
                <Col offset={1}>
                  <Form.Item label={'录入时间'} name={'time'}>
                    <DatePicker.RangePicker
                      showTime
                      onChange={value => {
                        const [insertTimeBegin, insertTimeEnd] = value
                          ? value.map(item => moment(item).format('YYYY-MM-DD HH:mm:ss'))
                          : []
                        form3.setFieldsValue({insertTimeBegin, insertTimeEnd})
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row justify={'end'}>
                <Space>
                  <Button type={'primary'} htmlType={'submit'} onClick={() => form3.setFieldsValue({pageNum: 1})}>
                    查询
                  </Button>
                  <Button htmlType={'reset'}>重置</Button>
                </Space>
              </Row>
            </Form>
          </div>
        </Tabs.TabPane>
      </Tabs>

      <div className={commonStyle.tableSection}>
        <Table
          dataSource={list}
          rowKey={'customerId'}
          loading={loading}
          rowSelection={{
            selectedRowKeys: selectIds,
            onChange: keys => setSelectIds(keys as string[]),
          }}
          columns={[
            {title: 'ID', dataIndex: 'customerCode'},
            {title: '姓名', dataIndex: 'name'},
            {title: '手机号', dataIndex: 'mobile'},
            ...(tabKey === '1'
              ? [{title: '类型', dataIndex: 'type', render: text => (text === '1' ? '个人' : '机构')}]
              : []),
            ...(tabKey !== '1'
              ? [{title: '归属销售', dataIndex: 'holderName', render: text => (text ? text : '公共客户池')}]
              : []),
            {title: '意向度', dataIndex: 'customerStatus', render: text => customerStatus[text]},
            {
              title: '区域',
              render: record =>
                `${record.province ?? '/'}-${record.city ?? '/'}-${record.district ?? '/'}-${record.bizCircle ?? '/'}`,
            },
            {
              title: '邀约状态',
              dataIndex: 'visitStatus',
              render: text => visitStatus[text] || '/',
            },
            {title: '录入时间', dataIndex: 'insertTime'},
            {
              title: '操作',
              align: 'center',
              render: record => {
                return (
                  <>
                    {tabKey === '1' && (
                      <Space>
                        <Permission code={'001083002'}>
                          <Button
                            type={'link'}
                            style={{paddingLeft: 0, paddingRight: 0}}
                            onClick={() => followRecord({userId: record.holderId, customerId: record.customerId})}
                          >
                            查看
                          </Button>
                        </Permission>
                        <Permission code={'022046002'}>
                          <Button
                            type={'link'}
                            style={{paddingLeft: 0, paddingRight: 0}}
                            onClick={() => {
                              Modal.confirm({
                                title: '提示',
                                content: '是否确定将客户转出到公共客户？',
                                icon: null,
                                centered: true,
                                onOk: async () => {
                                  await saleModel.saleCustomerMove({
                                    customerIdList: [record.customerId],
                                    toPublic: '1',
                                    userId: record.holderId,
                                  })
                                  search()
                                },
                              })
                            }}
                          >
                            转出
                          </Button>
                        </Permission>
                        <Permission code={'002046002'}>
                          <Button
                            type={'link'}
                            style={{paddingLeft: 0, paddingRight: 0}}
                            onClick={() => {
                              history.push({pathname: '/sale-client/detail', state: {customerId: record.customerId}})
                            }}
                          >
                            编辑
                          </Button>
                        </Permission>
                        <Permission code={'003083002'}>
                          <Button
                            type={'link'}
                            style={{paddingLeft: 0, paddingRight: 0}}
                            onClick={() => {
                              followForm.setFieldsValue({
                                name: record.name,
                                mobile: record.mobile,
                                customerId: record.customerId,
                              })
                              setFollowVisible(true)
                            }}
                          >
                            录入跟进
                          </Button>
                        </Permission>
                      </Space>
                    )}
                    {tabKey === '2' && (
                      <Space>
                        <Permission code={'001085002'}>
                          <Button
                            type={'link'}
                            onClick={() => {
                              history.push({
                                pathname: '/sale-client/detail',
                                state: {customerId: record.customerId, disable: true},
                              })
                            }}
                            style={{paddingLeft: 0, paddingRight: 0}}
                          >
                            查看
                          </Button>
                        </Permission>
                        <Permission code={'022085002'}>
                          <Button
                            type={'link'}
                            onClick={async () => {
                              if (!outTree.length) {
                                await selectTree()
                              }
                              setSelectIds([record.customerId])
                              setOutVisible(true)
                            }}
                            style={{paddingLeft: 0, paddingRight: 0}}
                          >
                            转出
                          </Button>
                        </Permission>
                        <Button
                          type={'link'}
                          onClick={() => followRecord({customerId: record.customerId})}
                          style={{paddingLeft: 0, paddingRight: 0}}
                        >
                          跟进记录
                        </Button>
                      </Space>
                    )}
                    {tabKey === '3' && (
                      <Space>
                        <Permission code={'001087002'}>
                          <Button
                            type={'link'}
                            style={{paddingLeft: 0, paddingRight: 0}}
                            onClick={() => {
                              history.push({
                                pathname: '/sale-client/detail',
                                state: {customerId: record.customerId, disable: true},
                              })
                            }}
                          >
                            查看
                          </Button>
                        </Permission>
                        <Permission code={'022087003'}>
                          <Button
                            type={'link'}
                            style={{paddingLeft: 0, paddingRight: 0}}
                            onClick={() => {
                              Modal.confirm({
                                title: '提示',
                                icon: null,
                                centered: true,
                                content: '是否确定将客户转为个人客户？',
                                onOk: async () => {
                                  await saleModel.saleCustomerMove({
                                    customerIdList: [record.customerId],
                                    toPublic: '0',
                                  } as MoveSaleCustomerDTO)
                                  search()
                                },
                              })
                            }}
                          >
                            转出
                          </Button>
                        </Permission>
                        <Button
                          type={'link'}
                          onClick={() => followRecord({customerId: record.customerId})}
                          style={{paddingLeft: 0, paddingRight: 0}}
                        >
                          跟进记录
                        </Button>
                      </Space>
                    )}
                  </>
                )
              },
            },
          ]}
          pagination={getPaginationProps(newForm, total)}
        />
      </div>

      {/* 跟进录入 */}
      <Modal
        title={'跟进录入'}
        open={followVisible}
        onCancel={() => setFollowVisible(false)}
        onOk={async () => {
          await followForm.validateFields()
          const value = followForm.getFieldsValue(true)
          await saleModel.saleCustomerFollowInsert({...value, holderId: userInfo.userId})
          setFollowVisible(false)
        }}
        afterClose={() => followForm.resetFields()}
        centered
      >
        <Form form={followForm} labelCol={{span: 5}}>
          <Form.Item label={'客户名称'}>
            <span>{followForm.getFieldValue('name')}</span>
          </Form.Item>
          <Form.Item label={'手机号'}>
            <span>{followForm.getFieldValue('mobile')}</span>
          </Form.Item>
          <Form.Item
            label={'邀约状态'}
            rules={[{required: true, message: '请选择邀约状态'}]}
            wrapperCol={{span: 15}}
            name={'visitStatus'}
          >
            <Select placeholder={'请选择'} allowClear>
              {visitStatusArr.map(item => (
                <Select.Option value={item.id} key={item.id}>
                  {item.label}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          {/*<Form.Item label={'意向度'} name={'customerStatus'} rules={[{required: true, message: '请选择意向度'}]}>*/}
          {/*  <Select style={{width: 200}} placeholder={'请选择'} allowClear>*/}
          {/*    {customerStatusList.map(item => (*/}
          {/*      <Select.Option value={item.id} key={item.id}>*/}
          {/*        {item.label}*/}
          {/*      </Select.Option>*/}
          {/*    ))}*/}
          {/*  </Select>*/}
          {/*</Form.Item>*/}
          <Form.Item
            label={'跟进详情'}
            name={'remark'}
            rules={[{required: true, message: '请输入跟进详情'}]}
            wrapperCol={{span: 15}}
          >
            <Input.TextArea maxLength={200} placeholder='请输入' rows={3} allowClear />
          </Form.Item>
        </Form>
      </Modal>

      {/* 转出 */}
      <Modal
        visible={outVisible}
        destroyOnClose
        onCancel={() => {
          setOutVisible(false)
        }}
        afterClose={() => {
          setSelectHold({name: '', userId: ''})
          setSelectIds([])
        }}
        title={'人员选择'}
        centered
        onOk={async () => {
          if (selectHold.userId) {
            await saleModel.saleAppCustomerMoveTo({
              customerIdList: selectIds,
              userId: selectHold.userId,
              toPublic: '0',
            })
            search()
            setOutVisible(false)
          } else {
            message.error('请选择转出人员')
          }
        }}
      >
        <Tree
          defaultExpandAll
          treeData={outTree}
          onSelect={(_, {node}) => {
            setSelectHold({name: node.title, userId: node.key})
          }}
        />
      </Modal>
    </Page>
  )
}
