import React, {useEffect} from 'react'
import {Page} from '@/components/Page'
import {useAppHistory, useAppLocation} from '@/common/hooks/useRouter'
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  message,
  PageHeader,
  Radio,
  Row,
  Select,
  Space,
  Upload,
} from 'antd'
import commonStyles from '@/assets/styles/common.module.less'
import {Album, UploadButton} from '@/components/Album'
import DividerLine from '@/pages/property/component/DividerLine'
import {MinusCircleOutlined, PlusCircleOutlined, UploadOutlined} from '@ant-design/icons'
import {baseUrl} from '@/common/config'
import {onAlbumUploadSingleEvent, onAlbumUploadSingleProps} from '@/pages/service/utils/albumUpload'
import moment from 'moment'
import {omsModel} from '@/store/models/Oms'
import {createHeaders} from '@/store/service'
import {commonModel} from '@/store/models/Common'
import {useListener, emit} from '@/common/hooks/listener'
import {Breadcrumb} from '@/components/Breadcrumb'
import currency from 'currency.js'

const companyTypeArr = [
  {id: '1', label: '国有企业'},
  {id: '2', label: '私营企业'},
  {id: '3', label: '事业单位或社会团体'},
  {id: '4', label: '中外合资'},
  {id: '5', label: '外商独资'},
  {id: '6', label: '其它'},
]

export default function CompanyInsert() {
  const history = useAppHistory()
  const location = useAppLocation<'/company-insert'>()
  const companyId = location.state?.companyId
  const isEdit = !!companyId

  const [form] = Form.useForm()

  useEffect(() => {
    if (companyId) {
      omsModel.omsCompanyGet({companyId}).then(({response: {data}}) => {
        form.setFieldsValue({
          ...data,
          limitMember: data.limitOfMember === -1 ? '1' : '2',
          limitProperty: data.limitOfProperty === -1 ? '1' : '2',
        })
      })
    }
  }, [companyId, form])

  useListener('CompanyInsert: contractModulePermissionList', (contractModulePermissionList: string[]) => {
    if (contractModulePermissionList.length) {
      form.setFieldsValue({
        contractModulePermissionList,
        permissionDec: `${
          contractModulePermissionList.filter(item => ['OPT01', 'OPT02', 'OPT03'].includes(item)).length
        }个端口，${contractModulePermissionList.filter(item => item.length === 8).length}个模块`,
      })
    } else {
      form.setFieldsValue({contractModulePermissionList: [], permissionDec: undefined})
    }
  })

  return (
    <Page
      header={<PageHeader title={'返回'} ghost={false} onBack={() => history.goBack()} />}
      breadcrumb={
        <Breadcrumb
          path={isEdit ? ['集中式sass', '编辑'] : ['集中式sass', '新增公司']}
          style={{backgroundColor: '#fff'}}
        />
      }
    >
      <div className={commonStyles.filterSection}>
        <Form
          form={form}
          layout={'vertical'}
          initialValues={{userList: [{}]}}
          onFinish={async () => {
            const formValues = form.getFieldsValue(true)
            if (formValues.companyId) {
              await omsModel.omsCompanyUpdate(formValues)
            } else {
              const {
                userList,
                contractBegin,
                contractEnd,
                contractFee,
                contractModulePermissionList,
                contractScannedCopy,
                limitOfMember,
                limitOfProperty,
              } = formValues
              const mobile = new Set(userList?.map(item => item.mobile))
              const loginAccount = new Set(userList?.map(item => item.loginAccount))
              if (mobile.size !== userList.length) return message.warning('手机号不能重复')
              if (loginAccount.size !== userList.length) return message.warning('账号不能重复')
              const params = {
                ...formValues,
                contract: {
                  contractBegin: moment(contractBegin).format('YYYY-MM-DD HH:mm:ss'),
                  contractEnd: moment(contractEnd).format('YYYY-MM-DD HH:mm:ss'),
                  contractFee,
                  contractModulePermissionList,
                  contractScannedCopy,
                },
                limitOfMember,
                limitOfProperty,
              }
              // console.log('params', params)
              await omsModel.omsCompanyInsert(params)
            }
            message.success('操作成功！')
            emit('CompanyInsert')
            history.goBack()
          }}
        >
          <DividerLine title={'公司信息'} style={{marginBottom: '24px'}} />
          <Row gutter={20}>
            <Col span={8}>
              <Form.Item label={'公司名称'} name={'companyName'} rules={[{required: true}, {type: 'string', max: 30}]}>
                <Input placeholder={'请填写公司名称'} readOnly={isEdit} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label={'公司详细地址'}
                name={'companyAddress'}
                rules={[{required: true}, {type: 'string', max: 30}]}
              >
                <Input placeholder={'请填写公司详细地址'} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label={'统一社会信用代码'}
                name={'companyCertNo'}
                rules={[{required: true}, {type: 'string', max: 18}]}
              >
                <Input placeholder={'请填写社会信用代码'} readOnly={isEdit} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={'类型'} name={'companyType'} rules={[{required: true, message: '请选择类型'}]}>
                <Select placeholder={'请选择类型'}>
                  {companyTypeArr.map(item => (
                    <Select.Option key={item.id} value={item.id}>
                      {item.label}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label={'公司法人'}
                name={'legalPersonName'}
                rules={[{required: true}, {type: 'string', max: 10}]}
              >
                <Input placeholder={'请填写公司法人'} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label={'法人身份证号'}
                name={'legalPersonCertNo'}
                rules={[
                  {
                    required: false,
                    validator: (_, value, callback) => {
                      if (value) {
                        const certNoReg = /^[1-9]\d{5}(18|19|20|(3\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/
                        if (!certNoReg.test(value)) callback('身份证号格式不正确')
                      }
                      callback()
                    },
                  },
                ]}
              >
                <Input placeholder={'法人身份证号'} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={'法人身份证照片'} shouldUpdate>
                {form1 => {
                  const legalPersonCertFront = form1.getFieldValue('legalPersonCertFront')
                  const legalPersonCertBack = form1.getFieldValue('legalPersonCertBack')
                  const legalPersonCertHold = form1.getFieldValue('legalPersonCertHold')
                  // console.log('legalPersonCertFront', legalPersonCertFront)
                  return (
                    <Space>
                      <Form.Item
                        name={'legalPersonCertFront'}
                        getValueFromEvent={onAlbumUploadSingleEvent}
                        getValueProps={onAlbumUploadSingleProps}
                      >
                        <Album
                          limit={1}
                          multiple={false}
                          accept={'.jpg, .jpeg, .png'}
                          action={baseUrl + '/oms/company/file-upload'}
                        >
                          {!legalPersonCertFront && <UploadButton title={'身份证人像面'} />}
                        </Album>
                      </Form.Item>
                      <Form.Item
                        name={'legalPersonCertBack'}
                        getValueFromEvent={onAlbumUploadSingleEvent}
                        getValueProps={onAlbumUploadSingleProps}
                      >
                        <Album limit={1} action={baseUrl + '/oms/company/file-upload'} accept={'.jpg, .jpeg, .png'}>
                          {!legalPersonCertBack && <UploadButton title={'身份证国徽'} />}
                        </Album>
                      </Form.Item>
                      <Form.Item
                        name={'legalPersonCertHold'}
                        getValueFromEvent={onAlbumUploadSingleEvent}
                        getValueProps={onAlbumUploadSingleProps}
                      >
                        <Album limit={1} action={baseUrl + '/oms/company/file-upload'} accept={'.jpg, .jpeg, .png'}>
                          {!legalPersonCertHold && <UploadButton title={'手持身份证照片'} />}
                        </Album>
                      </Form.Item>
                    </Space>
                  )
                }}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item noStyle shouldUpdate>
                {form1 => {
                  const companyBizLicense = form1.getFieldValue('companyBizLicense')
                  return (
                    <Form.Item
                      label={'营业执照'}
                      name={'companyBizLicense'}
                      getValueFromEvent={onAlbumUploadSingleEvent}
                      getValueProps={onAlbumUploadSingleProps}
                      rules={[{required: true, message: '请上传营业执照'}]}
                    >
                      <Album limit={1} action={baseUrl + '/oms/company/file-upload'} accept={'.jpg, .jpeg, .png'}>
                        {!companyBizLicense && <UploadButton title={' 公司营业执照'} />}
                      </Album>
                    </Form.Item>
                  )
                }}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={'可创建房间数量'} required shouldUpdate style={{marginBottom: 0}}>
                {f => {
                  const limitProperty = f.getFieldValue('limitProperty')
                  return (
                    <Space>
                      <Form.Item name={'limitProperty'} rules={[{required: true, message: '请选择'}]}>
                        <Radio.Group
                          onChange={({target: {value}}) => f.setFieldsValue({limitOfProperty: value === '1' ? -1 : 0})}
                        >
                          <Radio value={'1'}>不限</Radio>
                          <Radio value={'2'}>限定</Radio>
                        </Radio.Group>
                      </Form.Item>
                      {limitProperty === '2' && (
                        <Form.Item name={'limitOfProperty'} rules={[{required: true, message: '请输入限制值'}]}>
                          <InputNumber
                            placeholder={'请输入'}
                            min={0}
                            step={1}
                            style={{width: '180px'}}
                            addonAfter={'间'}
                          />
                        </Form.Item>
                      )}
                    </Space>
                  )
                }}
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={'可创建团队'} required shouldUpdate style={{marginBottom: 0}}>
                {f => {
                  const limitMember = f.getFieldValue('limitMember')
                  return (
                    <Space>
                      <Form.Item name={'limitMember'} rules={[{required: true, message: '请选择'}]}>
                        <Radio.Group
                          onChange={({target: {value}}) => f.setFieldsValue({limitOfMember: value === '1' ? -1 : 0})}
                        >
                          <Radio value={'1'}>不限</Radio>
                          <Radio value={'2'}>限定</Radio>
                        </Radio.Group>
                      </Form.Item>
                      {limitMember === '2' && (
                        <Form.Item name={'limitOfMember'} rules={[{required: true, message: '请输入限制值'}]}>
                          <InputNumber
                            placeholder={'请输入'}
                            min={0}
                            step={1}
                            style={{width: '180px'}}
                            addonAfter={'人'}
                          />
                        </Form.Item>
                      )}
                    </Space>
                  )
                }}
              </Form.Item>
            </Col>
            <Col span={16}>
              <Form.Item label={'公司介绍'} name={'companyDesc'}>
                <Input.TextArea placeholder={'请填写介绍信息'} maxLength={300} showCount />
              </Form.Item>
            </Col>
          </Row>

          <DividerLine title={'对公账户'} style={{marginBottom: '24px'}} />
          <Row gutter={20}>
            <Col span={8}>
              <Form.Item label={'银行账号'} name={'bankAccountNo'} rules={[{required: false}, {max: 19}]}>
                <Input placeholder={'请填写银行账号'} style={{width: '100%'}} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={'户名'} name={'bankAccountName'} rules={[{required: false}, {max: 10}]}>
                <Input placeholder={'请填写户名'} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={'银行名称'} name={'bankName'} rules={[{required: false}, {max: 18}]}>
                <Input placeholder={'请填写银行名称'} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={'开户支行'} name={'bankBranchName'} rules={[{required: false}, {max: 30}]}>
                <Input placeholder={'请填写开户支行'} />
              </Form.Item>
            </Col>
          </Row>

          <DividerLine title={'支付信息'} style={{marginBottom: '24px'}} />
          <Row>
            <Col span={8} offset={16} pull={16}>
              <Form.Item
                label={'平台手续费'}
                rules={[{required: true, message: '请输入平台手续费'}]}
                name={'payCommissions'}
                getValueFromEvent={event =>
                  event && !isNaN(event) ? currency(event, {precision: 4}).divide(100).value : event
                }
                getValueProps={value => ({
                  value: value && !isNaN(value) ? currency(value, {precision: 4}).multiply(100).value : value,
                })}
              >
                <InputNumber
                  placeholder={'请填写平台手续费'}
                  style={{width: '100%'}}
                  min={0}
                  max={100}
                  precision={2}
                  addonAfter={'%'}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item label={'通联'} required>
                <Row gutter={12}>
                  {[
                    {label: '支付宝', value: '01'},
                    {label: '微信', value: '02'},
                    {label: '银行卡', value: '03'},
                  ].map((item, index) => (
                    <Col span={8} key={item.value}>
                      <Form.Item
                        noStyle
                        rules={[{required: true, message: `请输入${item.label}手续费`}]}
                        name={['payCommissionsAllinList', index, 'payCommissions']}
                        getValueFromEvent={event =>
                          event && !isNaN(event) ? currency(event, {precision: 4}).divide(100).value : event
                        }
                        getValueProps={value => ({
                          value: value && !isNaN(value) ? currency(value, {precision: 4}).multiply(100).value : value,
                        })}
                      >
                        <InputNumber
                          placeholder={`请填写${item.label}手续费`}
                          style={{width: '100%'}}
                          min={0}
                          max={100}
                          precision={2}
                          addonBefore={item.label}
                          addonAfter={'%'}
                          onChange={() => form.setFieldValue(['payCommissionsAllinList', index, 'payType'], item.value)}
                        />
                      </Form.Item>
                    </Col>
                  ))}
                </Row>
              </Form.Item>
            </Col>
          </Row>

          {!isEdit && (
            <>
              <DividerLine title={'合同约定'} style={{marginBottom: '24px'}} />
              <Row gutter={20}>
                <Col span={8}>
                  <Form.Item
                    label={'系统使用开始时间'}
                    name={'contractBegin'}
                    rules={[{required: true, message: '请填写系统使用开始时间'}]}
                  >
                    <DatePicker
                      placeholder={'请填写系统使用开始时间'}
                      style={{width: '100%'}}
                      disabledDate={date => date.isBefore(moment().add(-1, 'day') as any)}
                      showToday={false}
                    />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item noStyle shouldUpdate>
                    {form1 => {
                      const contractBegin = form1.getFieldValue('contractBegin')
                      return (
                        <Form.Item
                          label={'系统使用结束时间'}
                          name={'contractEnd'}
                          rules={[{required: true, message: '请填写系统使用结束时间'}]}
                        >
                          <DatePicker
                            placeholder={'请填写系统使用结束时间'}
                            style={{width: '100%'}}
                            disabledDate={date =>
                              date.isBefore(
                                contractBegin ? (moment(contractBegin).add(1, 'day') as any) : (moment() as any)
                              )
                            }
                            showToday={false}
                          />
                        </Form.Item>
                      )
                    }}
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item noStyle shouldUpdate={(prev, next) => prev.permissionDec !== next.permissionDec}>
                    {form1 => {
                      const permissionIdList = form1.getFieldValue('contractModulePermissionList') ?? []
                      return (
                        <Form.Item
                          label={'系统使用模块'}
                          name={'permissionDec'}
                          rules={[{required: true, message: '请选择系统使用模块'}]}
                        >
                          <Input
                            placeholder={'请选择系统使用模块'}
                            onClick={() =>
                              history.push('/company-permission', {permissionIdList, type: 'insert-company'})
                            }
                          />
                        </Form.Item>
                      )
                    }}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label={'新增管理员账号'}
                    colon={false}
                    style={{flexDirection: 'row', alignItems: 'center'}}
                  />
                </Col>

                <Col span={24}>
                  <Form.List name={'userList'}>
                    {(fields, {add, remove}) =>
                      fields.map((field, index) => {
                        const userList = form.getFieldValue('userList') ?? []
                        const len = userList.length
                        return (
                          <Row gutter={20} key={index}>
                            <Col span={6}>
                              <Form.Item
                                label={
                                  <div style={{marginTop: '8px'}}>
                                    <span
                                      style={{color: '#ff4d4f', fontSize: '14px', fontFamily: 'SimSun, sans-serif'}}
                                    >
                                      *<span style={{paddingLeft: '4px', color: 'rgba(0, 0, 0, 0.65)'}}>姓名</span>
                                    </span>
                                  </div>
                                }
                                labelCol={{span: 4}}
                                name={[field.name, 'certName']}
                                fieldKey={[field.fieldKey, 'certName']}
                                rules={[
                                  {required: true, message: '请填写姓名'},
                                  {type: 'string', max: 10, message: '姓名长度不能超过10'},
                                ]}
                                style={{flexDirection: 'row', alignItems: 'center'}}
                                required={false}
                              >
                                <Input
                                  placeholder={'请填写姓名'}
                                  onBlur={({target: {value}}) => {
                                    if (value) {
                                      commonModel.commonAppFuncZhToPinyin({key: value}).then(({response: {data}}) => {
                                        form.setFields([{name: ['userList', field.name, 'loginAccount'], value: data}])
                                        form.validateFields([['userList', field.name, 'loginAccount']])
                                      })
                                    }
                                  }}
                                />
                              </Form.Item>
                            </Col>
                            <Col span={6}>
                              <Form.Item
                                label={
                                  <div style={{marginTop: '8px'}}>
                                    <span
                                      style={{color: '#ff4d4f', fontSize: '14px', fontFamily: 'SimSun, sans-serif'}}
                                    >
                                      *<span style={{paddingLeft: '4px', color: 'rgba(0, 0, 0, 0.65)'}}>账号</span>
                                    </span>
                                  </div>
                                }
                                labelCol={{span: 4}}
                                name={[field.name, 'loginAccount']}
                                fieldKey={[field.fieldKey, 'loginAccount']}
                                rules={[{required: true, message: '请填写账号'}]}
                                style={{flexDirection: 'row', alignItems: 'center'}}
                                required={false}
                              >
                                <Input placeholder={'请填写账号'} />
                              </Form.Item>
                            </Col>
                            <Col span={7}>
                              <Form.Item
                                label={
                                  <div style={{marginTop: '8px'}}>
                                    <span
                                      style={{color: '#ff4d4f', fontSize: '14px', fontFamily: 'SimSun, sans-serif'}}
                                    >
                                      *<span style={{paddingLeft: '4px', color: 'rgba(0, 0, 0, 0.65)'}}>手机号</span>
                                    </span>
                                  </div>
                                }
                                labelCol={{span: 4}}
                                name={[field.name, 'mobile']}
                                fieldKey={[field.fieldKey, 'mobile']}
                                rules={[{required: true, message: '请填写手机号'}]}
                                style={{flexDirection: 'row', alignItems: 'center'}}
                                required={false}
                              >
                                <Input placeholder={'请填写手机号'} />
                              </Form.Item>
                            </Col>
                            <Col>
                              {len - 1 === index ? (
                                <>
                                  {len !== 1 && (
                                    <Button
                                      type={'link'}
                                      icon={<MinusCircleOutlined />}
                                      onClick={() => remove(index)}
                                    />
                                  )}
                                  <Button type={'link'} icon={<PlusCircleOutlined />} onClick={() => add()} />
                                </>
                              ) : (
                                <Button type={'link'} icon={<MinusCircleOutlined />} onClick={() => remove(index)} />
                              )}
                            </Col>
                          </Row>
                        )
                      })
                    }
                  </Form.List>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label={'网址'}
                    name={'companyDomain'}
                    rules={[{required: true}, {type: 'string', max: 100}]}
                  >
                    <Input placeholder={'请填写网址'} />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label={'线下合同扫描件'}
                    name={'_contractScannedCopy'}
                    rules={[{required: true, message: '请上传线下合同扫描件'}]}
                  >
                    <Upload
                      name='file'
                      maxCount={1}
                      headers={createHeaders()}
                      action={baseUrl + '/oms/company/file-upload'}
                      accept={'.pdf, .doc, .docx'}
                      onChange={info => {
                        if (info.file?.status === 'done') {
                          form.setFieldsValue({contractScannedCopy: info.file?.response?.data?.imgUrl})
                        }
                        if (info.file?.status === 'removed') {
                          form.setFieldsValue({contractScannedCopy: null})
                          form.resetFields(['_contractScannedCopy'])
                        }
                      }}
                    >
                      <Button icon={<UploadOutlined />}>选择上传文件</Button>
                    </Upload>
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item
                    label={'系统使用费'}
                    name={'contractFee'}
                    rules={[
                      {required: true, message: '请填写系统使用费'},
                      {type: 'number', max: 9999999, min: 0},
                    ]}
                  >
                    <InputNumber placeholder={'请填写系统使用费'} style={{width: '100%'}} />
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item noStyle shouldUpdate>
                    {form1 => {
                      const companyLogo = form1.getFieldValue('companyLogo')
                      return (
                        <Form.Item
                          label={'登录LOGO'}
                          name={'companyLogo'}
                          getValueFromEvent={onAlbumUploadSingleEvent}
                          getValueProps={onAlbumUploadSingleProps}
                        >
                          <Album limit={1} action={baseUrl + '/oms/company/file-upload'} accept={'.jpg, .jpeg, .png'}>
                            {!companyLogo && <UploadButton title={'上传图片'} />}
                          </Album>
                        </Form.Item>
                      )
                    }}
                  </Form.Item>
                </Col>
                <Col>
                  <Form.Item noStyle shouldUpdate>
                    {form1 => {
                      const companyLogoMini = form1.getFieldValue('companyLogoMini')
                      return (
                        <Form.Item
                          label={'右上角LOGO'}
                          name={'companyLogoMini'}
                          getValueFromEvent={onAlbumUploadSingleEvent}
                          getValueProps={onAlbumUploadSingleProps}
                        >
                          <Album limit={1} action={baseUrl + '/oms/company/file-upload'} accept={'.jpg, .jpeg, .png'}>
                            {!companyLogoMini && <UploadButton title={'上传图片'} />}
                          </Album>
                        </Form.Item>
                      )
                    }}
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}

          <DividerLine title={'接入方'} style={{marginBottom: '24px'}} />
          <Row gutter={20}>
            <Col span={8}>
              <Form.Item label={'应用ID'} name={'stewardAppId'}>
                <Input placeholder={'请输入'} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={'应用密钥'} name={'stewardAppSecret'}>
                <Input placeholder={'请输入'} />
              </Form.Item>
            </Col>
          </Row>

          <Row justify={'center'}>
            <Space>
              <Button type={'primary'} htmlType={'submit'}>
                提交
              </Button>
              <Button htmlType={'reset'}>重置</Button>
            </Space>
          </Row>
        </Form>
      </div>
    </Page>
  )
}
