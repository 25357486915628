import React, {useCallback, useEffect, useState} from 'react'
import {Page} from '@/components/Page'
import commonStyles from '@/assets/styles/common.module.less'
import {Button, Col, Form, Input, message, Modal, Row, Select, Space, Table} from 'antd'
import {getPaginationProps} from '@/common/biz/getPaginationProps'
import {zzajModel} from '@/store/models/Zzaj'
import {usePermission} from '@/components/Permission'
import {sysUserModel} from '@/store/models/SysUser'

export default function AnJuProjectList() {
  const [form] = Form.useForm()
  const [total, setTotal] = useState(0)
  const [list, setList] = useState<ZzajPropertyListVO[]>([])
  const loading = zzajModel.zzajProjectList.useLoading()
  const [auth] = usePermission()

  const [addOpen, setAddOpen] = useState(false)
  const [addForm] = Form.useForm()
  const [userList, setUserList] = useState<SysUserVO[]>([])

  const search = useCallback(() => {
    const values = form.getFieldsValue(true)
    zzajModel.zzajProjectList(values).then(({response: {data, total}}) => {
      setList(data)
      setTotal(total)
    })
  }, [form])

  useEffect(() => {
    search()
  }, [search])

  const searchUserList = useCallback(async () => {
    const {
      response: {data},
    } = await sysUserModel.querySysUserList({
      roleId: 'ZZAJ_CFRY',
      pageNum: 1,
      pageSize: 1,
      pageable: false,
      status: '1',
    })
    setUserList(data)
  }, [])

  return (
    <Page>
      <div className={commonStyles.filterSection}>
        <Form form={form} onFinish={search} onReset={search} initialValues={{pageNum: 1, pageSize: 10, pageable: true}}>
          <Row justify={'space-between'}>
            <Col span={7}>
              <Form.Item label={'项目编号'} name={'projectId'}>
                <Input placeholder={'请输入'} allowClear />
              </Form.Item>
            </Col>
            <Col span={7} offset={1}>
              <Form.Item label={'所属公司'} name={'companyName'}>
                <Input placeholder={'请输入'} allowClear />
              </Form.Item>
            </Col>
            <Col span={7} offset={1}>
              <Form.Item label={'项目名称'} name={'projectName'}>
                <Input placeholder={'请输入'} allowClear />
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item label={'诚服人员'} name={'userName'}>
                <Input placeholder={'请输入'} allowClear />
              </Form.Item>
            </Col>
            <Space>
              <Button htmlType={'submit'} type={'primary'} onClick={() => form.setFieldsValue({pageNum: 1})}>
                查询
              </Button>
              <Button htmlType={'reset'}>重置</Button>
            </Space>
          </Row>
        </Form>
      </div>
      <div className={commonStyles.filterSection}>
        <Table
          rowKey={'projectId'}
          dataSource={list}
          loading={loading}
          columns={[
            {title: '项目编号', dataIndex: 'projectId'},
            {title: '所属公司', dataIndex: 'userName'},
            {title: '项目名称', dataIndex: 'projectName'},
            {title: '诚服人员', dataIndex: 'userList', render: value => value?.map(item => item.value).join(',')},
            {
              title: '操作',
              render: (_, {projectId, userList}) => {
                return (
                  <Space>
                    {auth('002177014') && (
                      <Button
                        type={'link'}
                        style={{paddingLeft: 0, paddingRight: 0}}
                        onClick={async () => {
                          await searchUserList()
                          addForm.setFieldsValue({projectId, userIdList: userList.map(item => item.key)})
                          setAddOpen(true)
                        }}
                      >
                        编辑
                      </Button>
                    )}
                  </Space>
                )
              },
            },
          ]}
          pagination={getPaginationProps(form, total)}
        />
      </div>

      <Modal
        open={addOpen}
        title={'编辑'}
        centered
        onCancel={() => setAddOpen(false)}
        destroyOnClose
        afterClose={() => addForm.resetFields()}
        width={500}
        onOk={async () => {
          await addForm.validateFields()
          await zzajModel.zzajProjectUserInsert(addForm.getFieldsValue(true))
          message.success('编辑成功！')
          setAddOpen(false)
          search()
        }}
      >
        <Form form={addForm} wrapperCol={{span: 15}} labelCol={{flex: '120px'}}>
          <Form.Item label={'诚服人员'} name={'userIdList'}>
            <Select
              options={userList}
              fieldNames={{label: 'name', value: 'userId'}}
              showSearch
              filterOption={(inputValue, option) => {
                return option.name?.indexOf(inputValue) > -1
              }}
              placeholder={'请选择'}
              mode={'multiple'}
              allowClear
            />
          </Form.Item>
        </Form>
      </Modal>
    </Page>
  )
}
