import {Model} from '@redux-model/web'
import {$api} from '@/store/service'

export interface TeamModel {}

class Team extends Model<TeamModel> {
  protected initReducer(): TeamModel {
    return {}
  }

  // 团队管理-团队架构
  // queryTeamTrees = $api.action(() => this.post<ApiResponse<TeamTreesVO[]>>('/team/query-my-team-trees'))

  // 团队管理-查询组织或团队成员
  // queryTeamMember = $api.action((args: {teamId: string}) =>
  //   this.post<ApiResponse<TeamMemberInfo[]>>('/team/query-my-team-member').body(args)
  // )

  // 团队管理-增加成员
  // teamMemberUpsert = $api.action((args: TeamUpsertDTO) => this.post('/team/member/upsert').body(args))

  // 团队管理-新增组织
  // teamPinsert = $api.action((args: {name: string}) => this.post('/team/p-insert').body(args))

  // 团队管理-新增团队
  // teamCinsert = $api.action((args: {teamId: string; name: string}) => this.post('/team/c-insert').body(args))

  // 团队管理-重命名
  // teamRename = $api.action((args: {teamId: string; name: string}) => this.post('/team/rename').body(args))

  // 团队管理-删除团队或组织
  // teamDelete = $api.action((args: {teamId: string}) => this.post('/team/delete').body(args))

  // 团队管理-修改成员权限
  // teamMemberRoleUpdate = $api.action(
  //   (args: {teamPid: string; teamRole: string; teamUserId: string; teamIdList: string[]}) =>
  //     this.post('/team/member/role/update').body(args)
  // )

  // 团队管理-更换成员团队
  // teamMemberTeamUpdate = $api.action((args: {teamIdList: string[]; teamUserId: string}) =>
  //   this.post('/team/member/team/update').body(args)
  // )

  // 团队管理-删除成员
  // teamMemberDelete = $api.action((args: {teamUserId: string}) => this.post('/team/member/delete').body(args))

  // 企业认证-记录-提交
  // teamAuthRecordInsert = $api.action((args: TeamAuthSubmitDTO) => this.post('/team/auth/record/insert').body(args))

  // 企业认证-记录-详情
  teamAuthRecordDetail = $api.action((args: {recordId: string}) =>
    this.post<ApiResponse<TeamAuthRecordVO>>('/team/auth/record/detail').body(args)
  )

  // 企业认证记录列表
  teamAuthRecordList = $api.action((params: Partial<TeamAuthRecordQueryDTO>) =>
    this.post<ApiResponse<TeamAuthRecordVO[]>>('/team/auth/record/list').body({
      ...params,
      pageable: true,
    })
  )

  // 企业认证记录审核
  teamAuthRecordAudit = $api.action((recordId: string, companyNameSign: string, remark: string, pass: Boolean) =>
    this.post<ApiResponse>('/team/auth/record/audit').body({recordId, companyNameSign, remark, pass})
  )

  // 查询组织账户余额
  // teamBalance = $api.action(() => this.post<ApiResponse<number>>('/team/balance'))

  // // 扣款记录列表
  // teamBalanceRecordList = $api.action((args: Partial<CustomerRecordListDTO>) =>
  //   this.post<ApiResponse<CustomerRecordListVO[]>>('/team/balance/record/list').body(args)
  // )

  // // 充值记录
  // teamChargeList = $api.action((args: Partial<LessorChargeListDTO>) =>
  //   this.post<ApiResponse<LessorChargeVO[]>>('/team/charge/list').body(args)
  // )

  // // 对公账户服务费充值
  // teamChargeInsert = $api.action((args: LessorChargePendingDTO) => this.post('/team/charge/insert').body(args))

  // 组织成员-删除成员  |  object:{ownerId:客户id,userId:成员用户编号}
  // customerMemberDelete = $api.action((args: Record<string, any>) =>
  //   this.post<ApiResponse<Record<string, any>>>('/customer/member/delete').body(args)
  // )

  // 组织成员-成员详情  |  object:{ownerId:客户id,userId:成员用户编号}
  // customerMemberDetail = $api.action((args: Record<string, any>) =>
  //   this.post<ApiResponse<TeamMemberVO>>('/customer/member/detail').body(args).metas(args.userId)
  // )

  // 组织成员-编辑成员
  // customerMemberEdit = $api.action((args: TeamMemberUpdateDTO) =>
  //   this.post<ApiResponse<Record<string, any>>>('/customer/member/edit').body(args)
  // )

  // 组织成员-添加成员
  // customerMemberInsert = $api.action((args: TeamMemberInsertDTO) =>
  //   this.post<ApiResponse<Record<string, any>>>('/customer/member/insert').body(args)
  // )

  // 图片上传
  // teamV2MemberUploadFile = $api.action(() => this.post<ApiResponse<ImgVO>>('/team-v2/member/upload-file'))

  // 岗位角色-岗位列表  |  object:{ownerId:客户id}
  // customerRoleList = $api.action((args: Record<string, any>) =>
  //   this.post<ApiResponse<TeamRoleVO[]>>('/customer/role/list').body(args)
  // )

  // 岗位角色-查询所有的权限列表树
  // customerRoleListAllPermissionTree = $api.action((args: Record<string, any>) =>
  //   this.post<ApiResponse<TeamPermissionTreeNodeVO[]>>('/customer/role/list-all-permission-tree').body(args)
  // )

  // 岗位角色-根据岗位查询成员列表  |  object:{ownerId:客户id,teamRole:岗位编号}
  // customerRoleListMember = $api.action((args: Record<string, any>) =>
  //   this.post<ApiResponse<TeamUserVO[]>>('/customer/role/list-member').body(args)
  // )

  // 岗位角色-新增/编辑岗位
  // customerRoleSave = $api.action((args: TeamRoleSaveDTO) =>
  //   this.post<ApiResponse<string>>('/customer/role/save').body(args)
  // )

  // 组织架构-删除组织/团队  |  object:{ownerId:客户id,teamId:组织/团队id}
  // customerTeamDelete = $api.action((args: Record<string, any>) =>
  //   this.post<ApiResponse<Record<string, any>>>('/customer/team/delete').body(args)
  // )

  // 组织架构-组织架构tree  |  object:{ownerId:客户id}
  // customerTeamGetTeamTree = $api.action((args: Record<string, any>) =>
  //   this.post<ApiResponse<TeamTreeNodeVO[]>>('/customer/team/get-team-tree').body(args)
  // )

  // 组织架构-新增组织/团队  |  object:{ownerId:客户id,teamId:组织id(可空),name:名称}
  // customerTeamInsert = $api.action((args: Record<string, any>) =>
  //   this.post<ApiResponse<Record<string, any>>>('/customer/team/insert').body(args)
  // )

  // 组织架构-根据组织查询成员列表  |  object:{ownerId:客户id,teamId:组织/团队id}
  // customerTeamListMember = $api.action((args: Record<string, any>) =>
  //   this.post<ApiResponse<TeamUserVO[]>>('/customer/team/list-member').body(args)
  // )

  // 组织架构-重命名组织/团队  |  object:{teamId:组织/团队id,name:名称}
  // customerTeamRename = $api.action((args: Record<string, any>) =>
  //   this.post<ApiResponse<Record<string, any>>>('/customer/team/rename').body(args)
  // )

  // 团队-余额-充值-记录列表
  teamBalanceChargeList = $api.action((args: Partial<TeamWalletChargeQueryDTO>) =>
    this.post<ApiResponse<TeamBalanceChargeListVO[]>>('/team/balance/charge/list').body({
      pageNum: 1,
      pageSize: 10,
      pageable: true,
      ...args,
    })
  )

  // 团队-余额-充值
  teamBalanceChargeMake = $api.action((args: Partial<TeamBalanceChargeDTO>) =>
    this.post('/team/balance/charge/make').body(args)
  )

  // 团队-余额-充值-记录详情  |  object:{chargeId:充值记录id}
  teamBalanceChargeDetail = $api.action((chargeId: string) =>
    this.post<ApiResponse<TeamBalanceChargeListVO>>('/team/balance/charge/detail').body({chargeId})
  )

  // 团队-余额-充值作废  |  object:{chargeId:充值记录id}
  teamBalanceChargeCancel = $api.action((chargeId: string) => this.post('/team/balance/charge/cancel').body({chargeId}))

  // 团队-余额-扣款-记录列表
  teamBalanceRecordList = $api.action((args: Partial<TeamWalletRecordQueryDTO>) =>
    this.post<ApiResponse<TeamWalletRecordVO[]>>('/team/balance/record/list').body({
      pageNum: 1,
      pageSize: 10,
      pageable: true,
      ...args,
    })
  )

  // 团队-余额-扣款-新增
  teamBalanceRecordInsert = $api.action((args: TeamBalanceRecordInsertDTO) =>
    this.post<ApiResponse<string>>('/team/balance/record/insert').body(args)
  )

  // 团队-余额-扣款-记录详情  |  object:{recordId:记录id}
  teamBalanceRecordDetail = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<TeamWalletRecordVO>>('/team/balance/record/detail').body(args)
  )

  // 团队-余额-扣款-作废  |  object:{recordId:扣款记录id}
  teamBalanceRecordCancel = $api.action((recordId: string) => this.post('/team/balance/record/cancel').body({recordId}))

  // 团队-余额-列表
  teamBalanceList = $api.action((args: Partial<TeamBalanceListQueryDTO>) =>
    this.post<ApiResponse<TeamBalanceVO[]>>('/team/balance/list').body({
      pageSize: 10,
      pageNum: 1,
      pageable: true,
      ...args,
    })
  )

  // 团队-提现-新增记录
  teamBalanceWithdrawInsert = $api.action((args: TeamWalletWithdrawInsertDTO) =>
    this.post<ApiResponse<string>>('/team/balance/withdraw/insert').body(args)
  )

  // 团队-提现-记录列表
  teamBalanceWithdrawList = $api.action((args: TeamWalletWithdrawQueryDTO) =>
    this.post<ApiResponse<TeamWalletWithdrawVO[]>>('/team/balance/withdraw/list').body(args)
  )

  // 团队-提现-发送提现短信
  teamBalanceWithdrawSendMsg = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<TeamWalletWithdrawVO[]>>('/team/balance/withdraw/send-msg').body(args)
  )

  // 果币记录列表
  teamPointsRecordList = $api.action((args: TeamPointsRecordQueryDTO) =>
    this.post<ApiResponse<TeamPointsRecordVO[]>>('/team/points/record/list').body(args)
  )

  // 果币记录详情  |  notes:{recordId:记录id}
  teamPointsRecordGet = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<TeamPointsRecordVO>>('/team/points/record/get').body(args)
  )

  // 设置抵扣比例
  teamPointsSetScale = $api.action((args: TeamPointsSetScaleDTO) =>
    this.post<ApiResponse<Record<string, any>>>('/team/points/set-scale').body(args)
  )

  // 充值果币
  teamPointsAdd = $api.action((args: TeamPointsAddDTO) =>
    this.post<ApiResponse<Record<string, any>>>('/team/points/add').body(args)
  )

  // 抵扣果币
  teamPointsMinus = $api.action((args: TeamPointsMinusDTO) =>
    this.post<ApiResponse<Record<string, any>>>('/team/points/minus').body(args)
  )

  // 果币详情  |  notes:{teamId:团队id}
  teamPointsGetByTeamId = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<TeamPointsVO>>('/team/points/get-by-teamId').body(args)
  )

  // 果币记录取消  |  notes:{recordId:记录id}
  teamPointsRecordCancel = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<TeamPointsRecordVO>>('/team/points/record/cancel').body(args)
  )
}

export const teamModel = new Team()
