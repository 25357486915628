import React, {useCallback, useEffect, useState} from 'react'
import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Row,
  Select,
  Space,
  Table,
  Tabs,
  Tooltip,
} from 'antd'
import commonStyles from '@/assets/styles/common.module.less'
import {stewardModel} from '@/store/models/Steward'
import {Page} from '@/components/Page'
import {useHistory} from 'react-router'
import {sysModel} from '@/store/models/Sys'
import {useCodeArraySync} from '@/store/models/Common'
import moment from 'moment'
import {getPaginationProps} from '@/common/biz/getPaginationProps'
import {storageModel} from '@/store/models/Storage'
import {usePermission} from '@/components/Permission'
import {Breadcrumb} from '@/components/Breadcrumb'
import {useFocusEffect} from '@/common/hooks/useFocusEffect'
import {downloadBlob} from '@/utils/util'

// 确认状态
const checkStatus = [
  {label: '已确认', value: '1'},
  {label: '未确认', value: '0'},
]

// 核销凭证
const verificationVoucherType = [
  {label: '发票', value: '1'},
  {label: '其他', value: '99'},
]

// 判断是否可以核销确认
const canVerification = (record: Pick<StewardImprestListVO, 'checkStatus' | 'verificationStatus'>) =>
  record.checkStatus === '0' && record.verificationStatus === '4'

export default function ImprestApply() {
  const [auth] = usePermission()
  const [activeKey, setActiveKey] = useState<string>('')

  const [orgList, setOrgList] = useState<{label: string; value: string}[]>()
  const [stationList, setStationList] = useState<{label: string; value: string}[]>()
  const [selection, setSelection] = useState<StewardImprestListVO[]>([])

  // 备用金用途
  const imprestUseType = useCodeArraySync('steward.imprest.use.type')

  /** 列表相关 */
  const [form] = Form.useForm<StewardImprestListDTO>()
  const [list, setList] = useState<StewardImprestListVO[]>([])
  const [total, setTotal] = useState<number>()
  const [sum, setSum] = useState<StewardImprestStatisticVO>({} as StewardImprestStatisticVO)
  const history = useHistory()
  const optionArr = useCodeArraySync('steward.imprest.approval.status').map(item => ({
    value: item.id,
    label: item.label,
  }))
  const statusArr = useCodeArraySync('steward.imprest.verification.approval.status').map(item => ({
    value: item.id,
    label: item.label,
  }))

  // // 组织列表
  // useEffect(() => {
  //   sysModel.sysOrgTree({fetchUser: false, orgIdLike: orgId} as SysOrgTreeQueryDTO).then(({response: {data}}) => {
  //     setTree(data)
  //   })
  // }, [orgId])

  const queryList = useCallback(() => {
    stewardModel.stewardImprestList(form.getFieldsValue(true)).then(({response: {data, total}}) => {
      setList(data)
      setTotal(total)
    })
  }, [form])

  const listLoading = stewardModel.stewardImprestList.useLoading()

  // 总计
  const querySum = useCallback(() => {
    stewardModel
      .stewardImprestStatistic({...form.getFieldsValue(true), pageable: true, pageNum: 1, pageSize: 10})
      .then(({response: {data}}) => {
        setSum(data)
      })
  }, [form])

  useEffect(() => {
    form.submit()
  }, [form])

  useFocusEffect(() => {
    form.submit()
  })

  // 组织列表
  useEffect(() => {
    sysModel.sysOrgList({orgPid: ''}).then(({response}) => {
      setOrgList(response?.data?.map(item => ({label: item.orgName, value: item.orgId})))
    })
  }, [])

  const filterOption = (input: string, option?: {label: string; value: string}) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())

  // 站点列表
  useEffect(() => {
    storageModel.stewardWarehouseList({pageable: false}).then(res => {
      setStationList(res.response.data.map(item => ({label: item.name, value: item.warehouseId})))
    })
  }, [])

  const [show] = useVerificationCheck()

  return (
    <Page
      breadcrumb={
        <Breadcrumb
          right={
            <Space>
              {auth('018190003') && (
                <Button
                  type={'primary'}
                  onClick={async () => {
                    if (!selection.length) return message.warn('请选择申请单')
                    if (selection.some(value => !canVerification(value)))
                      return message.warn('当前操作数据中包含已确认备用金核销记录，请重新选择。')

                    await show(
                      selection.map(value => value.verificationId),
                      '批量核销确认'
                    )
                    message.success('操作成功')
                    setSelection([])
                    form.submit()
                  }}
                >
                  批量核销确认
                </Button>
              )}
              {auth('007190004') && (
                <Button
                  type={'primary'}
                  onClick={() =>
                    downloadBlob({
                      url: '/steward-imprest/export',
                      body: {...form.getFieldsValue(true), pageable: false},
                    })
                  }
                >
                  导出
                </Button>
              )}
            </Space>
          }
        />
      }
    >
      <div className={commonStyles.filterSection}>
        <Form
          form={form}
          labelCol={{flex: '120px'}}
          onFinish={() => {
            queryList()
            querySum()
          }}
          onReset={() => {
            queryList()
            querySum()
          }}
          initialValues={{pageable: true, pageNum: 1, pageSize: 10}}
        >
          <Row gutter={30}>
            <Col span={8}>
              <Form.Item name={'applyUserName'} label={'姓名'}>
                <Input placeholder={'请输入管家名称'} allowClear />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name={'applyImprestAmt'} label={'金额'}>
                <InputNumber placeholder={'请输入'} style={{width: '100%'}} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name={'mobile'} label={'手机号'}>
                <Input placeholder={'请输入'} allowClear />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name={'orgId'} label={'组织'}>
                <Select placeholder={'请选择'} allowClear options={orgList} showSearch filterOption={filterOption} />
              </Form.Item>
              {/* <Form.Item name={'orgId'} label={'组织'}>
                <TreeSelect placeholder={'请选择'} allowClear showSearch treeDefaultExpandAll treeData={tree} />
              </Form.Item> */}
            </Col>
            <Col span={8}>
              <Form.Item name={'warehouseId'} label={'所属站点'}>
                <Select
                  placeholder={'请选择'}
                  allowClear
                  options={stationList}
                  showSearch
                  filterOption={filterOption}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name={'applyUserStatus'} label={'状态'}>
                <Select
                  placeholder={'全部'}
                  allowClear
                  options={[
                    {label: '在职', value: '1'},
                    {label: '离职', value: '0'},
                  ]}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name={'approvalStatus'} label={'申请状态'}>
                <Select placeholder={'全部'} allowClear options={optionArr} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name={'insertTimeBegin'}
                label={'申请时间'}
                getValueFromEvent={(_, formatString) => {
                  form.setFieldValue(
                    'insertTimeEnd',
                    formatString?.[1] ? formatString?.[1] + ' 23:59:59' : formatString?.[1]
                  )
                  return formatString?.[0] ? formatString?.[0] + ' 00:00:00' : formatString?.[0]
                }}
                getValueProps={value => ({
                  value: value ? [moment(value), moment(form.getFieldValue('insertTimeEnd'))] : value,
                })}
              >
                <DatePicker.RangePicker />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name={'approvalTimeBegin'}
                label={'审批时间'}
                getValueFromEvent={(_, formatString) => {
                  form.setFieldValue(
                    'approvalTimeEnd',
                    formatString?.[1] ? formatString?.[1] + ' 23:59:59' : formatString?.[1]
                  )
                  return formatString?.[0] ? formatString?.[0] + ' 00:00:00' : formatString?.[0]
                }}
                getValueProps={value => ({
                  value: value ? [moment(value), moment(form.getFieldValue('approvalTimeEnd'))] : value,
                })}
              >
                <DatePicker.RangePicker />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name={'verificationTimeBegin'}
                label={'核销时间'}
                getValueFromEvent={(_, formatString) => {
                  form.setFieldValue(
                    'verificationTimeEnd',
                    formatString?.[1] ? formatString?.[1] + ' 23:59:59' : formatString?.[1]
                  )
                  return formatString?.[0] ? formatString?.[0] + ' 00:00:00' : formatString?.[0]
                }}
                getValueProps={value => ({
                  value: value ? [moment(value), moment(form.getFieldValue('verificationTimeEnd'))] : value,
                })}
              >
                <DatePicker.RangePicker />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name={'imprestUseType'} label={'备用金用途'}>
                <Select placeholder={'请选择'} options={imprestUseType} fieldNames={{label: 'label', value: 'id'}} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name={'verificationVoucherType'} label={'核销凭证'}>
                <Select placeholder={'请选择'} options={verificationVoucherType} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name={'checkStatus'} label={'确认状态'}>
                <Select placeholder={'请选择'} options={checkStatus} />
              </Form.Item>
            </Col>
          </Row>
          <Row justify={'end'}>
            <Space>
              <Button type={'primary'} htmlType={'submit'} onClick={() => form.setFieldsValue({pageNum: 1})}>
                查询
              </Button>
              <Button htmlType={'reset'} onClick={() => setActiveKey('')}>
                重置
              </Button>
            </Space>
          </Row>
        </Form>
      </div>

      <div className={commonStyles.tableSection}>
        <Tabs
          style={{marginTop: -15}}
          activeKey={activeKey}
          items={[{label: '全部', key: ''}, ...statusArr.map(({label, value}) => ({label, key: value}))]}
          onChange={value => {
            setActiveKey(value)
            form.setFieldsValue({
              verificationStatus: value,
            })
            queryList()
            querySum()
          }}
        />
        {!form.getFieldValue('verificationStatus') && (
          <div style={{marginBottom: 15}}>
            <span style={{fontWeight: 700}}>合计：</span>审批通过备用金：
            {sum.imprestPassAmt}
            元({sum.imprestPassNum}笔)&nbsp;&nbsp;&nbsp;&nbsp;已核销备用金：
            {sum.imprestVerificationAmt}
            元({sum.imprestVerificationNum}笔)&nbsp;&nbsp;&nbsp;&nbsp;备用金余额：
            {((sum.imprestPassAmt * 100 - sum.imprestVerificationAmt * 100) * 0.01).toFixed(2)}
            元&nbsp;&nbsp;&nbsp;&nbsp;核销中备用金：
            {sum.imprestVerificationPendingAmt}
            元({sum.imprestVerificationPendingNum}笔)&nbsp;&nbsp;&nbsp;&nbsp;审批中备用金：
            {sum.imprestApprovingAmt}元({sum.imprestApprovingNum}笔)
          </div>
        )}
        <Table
          rowKey={'applyId'}
          loading={listLoading}
          dataSource={list}
          rowSelection={{
            selectedRowKeys: selection.map(item => item.applyId),
            onChange: (_, selectedRows) => setSelection(selectedRows),
          }}
          columns={[
            {title: '姓名', dataIndex: 'applyUserName'},
            {title: '手机号', dataIndex: 'mobile'},
            {title: '组织', dataIndex: 'orgName', width: 60},
            {
              title: '所属站点',
              dataIndex: 'warehouseName',
              render: value => (
                <Tooltip title={value}>
                  {value ? (value.length > 16 ? value.substring(0, 16) + '……' : value) : ''}
                </Tooltip>
              ),
            },
            {
              title: '状态',
              dataIndex: 'applyUserStatus',
              width: 60,
              render: value => (value === '0' ? '离职' : '在职'),
            },
            {title: '申请单id', dataIndex: 'applyId'},
            {
              title: '申请状态',
              dataIndex: 'approvalStatus',
              width: 88,
              render: value => optionArr.find(item => item.value === value)?.label,
            },
            {title: '申请备用金金额（元）', dataIndex: 'applyImprestAmt', width: 172},
            {
              title: '备用金用途',
              dataIndex: 'imprestUseType',
              render: value => imprestUseType.find(item => item.id === value)?.label,
            },
            {title: '申请时间', dataIndex: 'insertTime'},
            {title: '审批时间', dataIndex: 'approvalTime', render: value => value ?? '/'},
            {
              title: '核销状态',
              dataIndex: 'verificationStatus',
              width: 88,
              render: value => statusArr.find(item => item.value === value)?.label ?? '/',
            },
            {
              title: '核销凭证',
              dataIndex: 'verificationVoucherType',
              render: value => verificationVoucherType.find(item => item.value === value)?.label ?? '/',
            },
            {title: '核销时间', dataIndex: 'verificationTime', render: value => value ?? '/'},
            {
              title: '确认状态',
              dataIndex: 'checkStatus',
              render: value => checkStatus.find(item => item.value === value)?.label ?? '/',
            },
            {
              title: '操作',
              fixed: 'right',
              dataIndex: 'applyId',
              render: (applyId, record) => {
                return (
                  <Space>
                    {auth('001190002') && (
                      <Button
                        type='link'
                        style={{padding: 0}}
                        onClick={() => {
                          history.push({
                            pathname: '/imprest-apply/detail',
                            state: {applyId, verificationId: record.verificationId, applyUserId: record.applyUserId},
                          })
                        }}
                      >
                        查看
                      </Button>
                    )}
                    {canVerification(record) && auth('018190003') && (
                      <Button
                        type='link'
                        style={{padding: 0}}
                        onClick={async () => {
                          await show([record.verificationId])
                          message.success('操作成功')
                          form.submit()
                        }}
                      >
                        核销确认
                      </Button>
                    )}
                  </Space>
                )
              },
            },
          ]}
          scroll={{x: 'max-content'}}
          pagination={getPaginationProps(form, total)}
        />
      </div>
    </Page>
  )
}

function useVerificationCheck() {
  const [form] = Form.useForm()

  const show = useCallback(
    (idList: string[], title = '核销确认') =>
      new Promise<void>((resolve, reject) => {
        Modal.confirm({
          title,
          icon: null,
          width: 560,
          onOk: async () => {
            await form.validateFields()
            const values = form.getFieldsValue(true)
            await stewardModel.stewardImprestVerificationCheck({
              idList,
              checkNotes: values.checkNotes,
            })
            resolve()
          },
          afterClose: () => {
            form.resetFields()
            reject()
          },
          content: (
            <Form form={form}>
              <Form.Item label={'确认备注'} name={'checkNotes'} rules={[{required: true, message: '请输入确认备注'}]}>
                <Input.TextArea showCount placeholder={'请输入'} maxLength={100} autoSize={{minRows: 5}} />
              </Form.Item>
            </Form>
          ),
        })
      }),
    [form]
  )

  return [show]
}
