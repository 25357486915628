import React, {useCallback, useEffect, useState} from 'react'
import {Page} from '@/components/Page'
import commonStyles from '@/assets/styles/common.module.less'
import {Button, DatePicker, Form, Input, message, PageHeader, Select, Space, TimePicker} from 'antd'
import {Breadcrumb} from '@/components/Breadcrumb'
import pageStyle from '@/components/Page/index.module.less'
import {useAppHistory, useAppLocation} from '@/common/hooks/useRouter'
import {adminModel} from '@/store/models/Admin'
import {condoModel} from '@/store/models/Condo'
import {propertyModel} from '@/store/models/Property'
import moment from 'moment'

export default function AddLookOrder() {
  const location = useAppLocation<'/crm/add-look'>()
  const history = useAppHistory()
  const customerId = location.state?.customerId
  const [form] = Form.useForm()
  const [condoList, setCondoList] = useState<CondoProjectVO[]>([])
  // const [condoUserList, setCondoUserList] = useState<SysUserVO[]>([])
  const [propertyList, setPropertyList] = useState<PropertyInfoVO[]>([])

  useEffect(() => {
    adminModel.adminCondoCustomerGet({customerId} as CondoCustomerGetDTO).then(({response: {data}}) => {
      const {customerId, customerName, customerMobile} = data
      form.setFieldsValue({customerId, customerName, customerMobile})
    })

    condoModel.condoProjectList({pageable: false} as CondoProjectListDTO).then(({response: {data}}) => {
      setCondoList(data)
    })

    // sysUserModel
    //   .querySysUserList({pageable: false, roleId: 'condo_manager', status: '1'})
    //   .then(({response: {data}}) => {
    //     setCondoUserList(data)
    //   })
  }, [customerId, form])

  const queryPropertyList = useCallback((code: string) => {
    propertyModel.queryPropertyList({communityCode: code, pageable: false}).then(({response: {data}}) => {
      setPropertyList(data)
    })
  }, [])

  return (
    <Page
      breadcrumb={<Breadcrumb style={{background: '#fff'}} />}
      header={<PageHeader title={'新增带看单'} className={pageStyle.header} />}
    >
      <div className={commonStyles.tableSection}>
        <Form
          labelCol={{span: 3}}
          form={form}
          wrapperCol={{span: 10}}
          onFinish={async () => {
            const {appointRoom, time, minute, ...values} = form.getFieldsValue(true)
            await adminModel.adminCondoCustomerVisitInsert({
              appointRoom: appointRoom?.join(','),
              appointTime: moment(time).format('YYYY-MM-DD ') + moment(minute).format('HH:mm:00'),
              ...values,
            })
            history.goBack()
            message.success('新增带看单成功')
          }}
        >
          <Form.Item label={'ID'} shouldUpdate>
            {() => <span>{form.getFieldValue('customerId')}</span>}
          </Form.Item>
          <Form.Item label={'姓名'} shouldUpdate>
            {() => <span>{form.getFieldValue('customerName')}</span>}
          </Form.Item>
          <Form.Item label={'手机号'} shouldUpdate>
            {() => <span>{form.getFieldValue('customerMobile') ?? '/'}</span>}
          </Form.Item>
          <Form.Item label={'预约时间'} required style={{marginBottom: 0}}>
            <Space>
              <Form.Item name={'time'} rules={[{required: true, message: '请选择预约日期'}]}>
                <DatePicker />
              </Form.Item>
              <Form.Item name={'minute'} rules={[{required: true, message: '请选择预约时间'}]}>
                <TimePicker showNow={false} minuteStep={30} format={'HH:mm'} />
              </Form.Item>
            </Space>
          </Form.Item>
          <Form.Item label={'预约公寓'} name={'appointCondoId'} rules={[{required: true, message: '请选择预约公寓'}]}>
            <Select
              placeholder={'请选择'}
              allowClear
              onChange={(value: string, option: any) => {
                form.setFieldsValue({appointRoom: undefined, appointCondoName: option?.children})
                if (value) {
                  queryPropertyList(value)
                  return
                }

                setPropertyList([])
              }}
            >
              {condoList.map(item => (
                <Select.Option value={item.condoId} key={item.condoId}>
                  {item.condoName}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label={'预约房间'}
            name={'appointRoom'}
            rules={[
              () => ({
                validator(_, value, callback) {
                  if (value?.length > 3) {
                    callback('最多能预约3个房间！')
                    form.setFieldsValue({appointRoom: value.slice(0, 3)})
                    return
                  }
                  return callback()
                },
              }),
            ]}
          >
            <Select
              placeholder={'请选择'}
              allowClear
              showSearch
              mode={'multiple'}
              filterOption={(value, option: any) => {
                return option.value?.indexOf(value) > -1
              }}
            >
              {propertyList.map(item => (
                <Select.Option value={item.houseNo} key={item.propertyCode}>
                  {item.houseNo}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          {/* <Form.Item label={'接单人'} name={'ownerId'} rules={[{required: true, message: '请选择接单人'}]}>
            <Select placeholder={'请选择'} allowClear>
              {condoUserList.map(item => (
                <Select.Option value={item.userId} key={item.userId}>
                  {item.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item> */}
          <Form.Item label={'带看备注'} name={'createRemark'}>
            <Input.TextArea rows={3} maxLength={200} showCount />
          </Form.Item>
          <Form.Item label={' '} colon={false}>
            <Space>
              <Button type={'primary'} htmlType={'submit'}>
                提交
              </Button>
              <Button onClick={() => history.goBack()}>取消</Button>
            </Space>
          </Form.Item>
        </Form>
      </div>
    </Page>
  )
}
