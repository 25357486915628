import React, {useEffect, useState} from 'react'
import {Form, Input, Row, Select, Typography, Col, Space, Button, message, PageHeader} from 'antd'
import commonStyle from '@/assets/styles/common.module.less'
import DividerLine from '@/pages/property/component/DividerLine'
import styles from './index.module.less'
import {saleModel} from '@/store/models/Sale'
import {useCodeSync, useCodeArraySync} from '@/store/models/Common'
import moment from 'moment'
import TimeLine from '@/pages/saleManagement/component/TimeLine'
import {useAppHistory, useAppLocation} from '@/common/hooks/useRouter'
import {Page} from '@/components/Page'
import AreaCascader from '@/components/AreaCascader'
import {addressValid} from '@/pages/saleManagement/AddCustomer'

const {Option} = Select
export default function SaleClientDetail() {
  const location = useAppLocation<'/sale-client/detail'>()
  const {customerId, disable} = location.state ?? {}
  const history = useAppHistory()
  const saleCustomerHistoryCode = useCodeSync('sale.customer.history')
  const visitStatusArr = useCodeArraySync('sale.customer.visit.status')

  const [form] = Form.useForm()
  const [customerHistory, setCustomerHistory] = useState([] as SaleCustomerHistoryVO[])
  const customerTypeList = useCodeArraySync('sale.customer.type')
  const customerStatusList = useCodeArraySync('sale.customer.status')
  const customerSourceList = useCodeArraySync('sale.customer.source')
  const [mobileVerify, setMobileVerify] = useState(false)
  const [certVerify, setCertVerify] = useState(false)

  useEffect(() => {
    saleModel.saleCustomerInfo({customerId}).then(({response: {data}}) => {
      const {province, provinceCode, city, cityCode, district, districtCode, bizCircle, bizCircleCode} = data
      form.setFieldsValue({
        ...data,
        addressValue: {province, provinceCode, city, cityCode, district, districtCode, bizCircle, bizCircleCode},
      })
      setCertVerify(data.certVerify === '1')
      setMobileVerify(data.mobileVerify === '1')
    })

    saleModel.saleCustomerHistory({customerId}).then(({response: {data}}) => {
      setCustomerHistory(data)
    })
  }, [customerId, form])

  async function submit() {
    const {addressValue, ...formValues} = form.getFieldsValue(true)
    await saleModel.saleCustomerUpsert({...formValues, ...addressValue})
    message.success('保存成功')
    history.goBack()
  }

  function mobileValid(rule: any, value: any, callback: Function) {
    const mobileReg = /^1[0-9]{10}$/
    if (!value) return callback('手机号不能为空')
    if (!mobileReg.test(value)) return callback('手机号格式不正确')
    callback()
  }

  return (
    <Page header={<PageHeader title={'客户详情'} ghost={false} onBack={() => history.goBack()} />}>
      <div className={commonStyle.section}>
        <div className={styles.container}>
          <div className={styles.left}>
            <DividerLine title={'客户基本信息'} />
            <Form form={form} onFinish={submit} labelCol={{span: 5}} wrapperCol={{span: 16}} className={styles.form}>
              <Form.Item label={'ID'} name={'customerCode'}>
                <Typography.Text>{form.getFieldValue('customerCode')}</Typography.Text>
              </Form.Item>
              <Form.Item label={'姓名'} name={'name'} rules={[{required: true, message: '请输入姓名'}]}>
                <Input disabled={disable || certVerify} allowClear />
              </Form.Item>
              <Form.Item label={'手机号码'} name={'mobile'} rules={[{required: true, validator: mobileValid}]}>
                <Input disabled={disable || mobileVerify || certVerify} allowClear />
              </Form.Item>
              <Form.Item label={'身份证号'} name={'certNo'}>
                <Input placeholder={'请输入'} disabled={disable || certVerify} allowClear />
              </Form.Item>
              <Form.Item label={'年龄'} name={'age'}>
                <Input disabled={disable} placeholder={'请输入'} allowClear />
              </Form.Item>
              <Form.Item label={'房间数量'} name={'roomNum'}>
                <Input disabled={disable} placeholder={'请输入'} allowClear />
              </Form.Item>
              <Form.Item label={'邀约状态'} name={'visitStatus'} rules={[{required: true, message: '请选择邀约状态'}]}>
                <Select placeholder={'请选择邀约状态'} fieldNames={{value: 'id'}} allowClear options={visitStatusArr} />
              </Form.Item>
              <Form.Item label={'归属销售'} name={'holderId'}>
                <Typography.Text>{form.getFieldValue('holderName') || '公共客户池'}</Typography.Text>
              </Form.Item>
              <Form.Item label={'性别'} name={'sex'} rules={[{required: true, message: '请选择性别'}]}>
                <Select disabled={disable} allowClear placeholder={'请选择'}>
                  <Option value={'1'}>男</Option>
                  <Option value={'2'}>女</Option>
                </Select>
              </Form.Item>
              <Form.Item label={'类型'} name={'type'} rules={[{required: true, message: '请选择类型'}]}>
                <Select disabled={disable} allowClear placeholder={'请选择'}>
                  {customerTypeList.map(item => (
                    <Option value={item.id} key={item.id}>
                      {item.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item label={'意向度'} name={'customerStatus'} rules={[{required: true, message: '请选择意向度'}]}>
                <Select disabled={disable} allowClear placeholder={'请选择'}>
                  {customerStatusList
                    .filter(item => (disable ? true : item.id !== '4'))
                    .map(item => (
                      <Option value={item.id} key={item.id}>
                        {item.label}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
              <Form.Item label={'客户来源'} name={'customerSource'}>
                <Select disabled={disable} allowClear placeholder={'请选择'}>
                  {customerSourceList.map(item => (
                    <Option value={item.id} key={item.id}>
                      {item.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item label={'区域'} name={'addressValue'} rules={[{required: true, validator: addressValid}]}>
                <AreaCascader />
              </Form.Item>
              <Form.Item label={'地址'} name={'address'}>
                <Input disabled={disable} placeholder={'请输入'} allowClear />
              </Form.Item>
              <Form.Item label={'备注信息'} name={'remark'}>
                <Input.TextArea disabled={disable} placeholder={'请输入'} allowClear />
              </Form.Item>
              <Row>
                <Col offset={5}>
                  <Space>
                    {!disable && (
                      <Button type={'primary'} htmlType={'submit'}>
                        保存
                      </Button>
                    )}
                    <Button onClick={() => history.goBack()}>返回</Button>
                  </Space>
                </Col>
              </Row>
            </Form>
          </div>
          <div className={styles.right}>
            <DividerLine title={'历史记录'} />
            <div style={{marginTop: 20}}>
              {customerHistory.map((item, index) => (
                <TimeLine
                  key={index}
                  label={moment(item.insertTime).format('YYYY-MM-DD')}
                  dot={
                    index === 0 ? (
                      <div style={{width: 16, height: 16, borderRadius: 8, backgroundColor: '#10BBB3'}} />
                    ) : (
                      <div style={{width: 16, height: 16, borderRadius: 8, backgroundColor: '#D8D8D8'}} />
                    )
                  }
                  line={index !== customerHistory.length - 1}
                >
                  <div>
                    <Typography.Text style={{color: '#222832', fontWeight: 600}}>
                      {saleCustomerHistoryCode[item.historyType]}
                    </Typography.Text>
                  </div>
                  <div>
                    <Typography.Text style={{marginTop: '8px', color: '#909399'}}>{item.historyDesc}</Typography.Text>
                  </div>
                  <div>
                    <Typography.Text
                      style={{marginTop: '8px', color: '#909399'}}
                    >{`操作人：${item.operatorName}`}</Typography.Text>
                  </div>
                </TimeLine>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Page>
  )
}
