import React, {useCallback, useEffect, useState} from 'react'
import {Breadcrumb} from '@/components/Breadcrumb'
import {Page} from '@/components/Page'
import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Space,
  Switch,
  Table,
  Tabs,
  message,
  TreeSelect,
  Tooltip,
} from 'antd'
import styles from './index.module.less'
import commonStyles from '@/assets/styles/common.module.less'
import {useAppHistory} from '@/common/hooks/useRouter'
import {condoModel} from '@/store/models/Condo'
import {usePermission} from '@/components/Permission'
import {userModel} from '@/store/models/User'
import {sysModel} from '@/store/models/Sys'
import {getPaginationProps} from '@/common/biz/getPaginationProps'
import {useFocusEffect} from '@/common/hooks/useFocusEffect'

export default function CondoTeamManage() {
  const history = useAppHistory()
  const [userForm] = Form.useForm()
  const [userList, setUserList] = useState<CondoProjectUserVO[]>([])
  const [userTotal, setUserTotal] = useState(0)
  const userLoading = condoModel.condoProjectTeamUserList.useLoading()
  const teamSyncLoading = condoModel.condoProjectTeamSyncV2.useLoading()
  const orgId = userModel.data.userInfo?.orgId

  const [roleForm] = Form.useForm()
  const [roleList, setRoleList] = useState<CondoProjectRoleVO[]>([])
  const [roleTotal, setRoleTotal] = useState(0)
  const roleLoading = condoModel.condoProjectTeamRoleList.useLoading()
  const [auth] = usePermission()
  const [tabKey, setTabKey] = useState(() => {
    const permissionObj = {
      '001098001': '1',
      '001099001': '2',
      '001107001': '3',
    }

    const permission = Object.keys(permissionObj).find(item => auth(item))
    return permissionObj[permission]
  })

  const [tree, setTree] = useState<SysOrgTreeNodeVO[]>([])

  const [visible, setVisible] = useState(false)
  const [editForm] = Form.useForm()

  const userSearch = useCallback(() => {
    const formValues = userForm.getFieldsValue(true)

    condoModel.condoProjectTeamUserList(formValues).then(({response: {data, total}}) => {
      setUserList(data)
      setUserTotal(total)
    })
  }, [userForm])

  useEffect(() => {
    sysModel.sysOrgTree({fetchUser: false, orgIdLike: orgId} as SysOrgTreeQueryDTO).then(({response: {data}}) => {
      setTree(data)
    })
  }, [orgId])

  const roleSearch = useCallback(() => {
    const formValues = roleForm.getFieldsValue(true)

    condoModel.condoProjectTeamRoleList({...formValues, pageable: false}).then(({response: {data, total}}) => {
      setRoleList(data)
      setRoleTotal(total)
    })
  }, [roleForm])

  useFocusEffect(() => {
    switch (tabKey) {
      case '1':
        return userForm.submit()
      case '2':
        return roleForm.submit()
      case '3':
        return outUserForm.submit()
    }
  })

  useEffect(() => {
    roleForm.submit()
  }, [roleForm])

  const [outUserForm] = Form.useForm()
  const [extraList, setExtraList] = useState<CondoProjectUserExtraVO[]>([])
  const [extraTotal, setExtraTotal] = useState(0)
  const extraLoading = condoModel.condoProjectTeamUserExtraList.useLoading()
  const [condoProjectList, setCondoProjectList] = useState<CondoProjectVO[]>([])
  const [extraVisible, setExtraVisible] = useState(false)
  const [addExtraForm] = Form.useForm()
  const outUserSearch = useCallback(() => {
    const formValues = outUserForm.getFieldsValue(true)
    condoModel.condoProjectTeamUserExtraList(formValues).then(({response: {data, total}}) => {
      setExtraList(data)
      setExtraTotal(total)
    })
  }, [outUserForm])

  useEffect(() => {
    condoModel.condoProjectList({pageable: false} as CondoProjectListDTO).then(({response: {data}}) => {
      setCondoProjectList(data)
    })
  }, [])

  useEffect(() => {
    switch (tabKey) {
      case '1':
        return userForm.submit()
      case '2':
        return roleForm.submit()
      case '3':
        return outUserForm.submit()
    }
  }, [outUserForm, roleForm, tabKey, userForm])

  return (
    <Page
      breadcrumb={
        <Breadcrumb
          right={
            <Space>
              {tabKey === '1' && auth('017098002') && (
                <Button
                  type={'primary'}
                  loading={teamSyncLoading}
                  onClick={() => {
                    Modal.confirm({
                      title: '提示',
                      centered: true,
                      icon: null,
                      content: '是否确认同步房东端？',
                      onOk: async () => {
                        await condoModel.condoProjectTeamSyncV2()
                        message.success('同步房东端成功！')
                      },
                    })
                  }}
                >
                  同步房东端
                </Button>
              )}
              {tabKey === '2' && auth('003099002') && (
                <Button
                  type={'primary'}
                  onClick={() => {
                    history.push({pathname: '/condo-team-manage/add-post'})
                  }}
                >
                  新增岗位角色
                </Button>
              )}
              {tabKey === '3' && auth('003107002') && (
                <Button type={'primary'} onClick={() => setExtraVisible(true)}>
                  添加账号
                </Button>
              )}
            </Space>
          }
        />
      }
    >
      <Tabs className={styles.tabs} activeKey={tabKey} onChange={key => setTabKey(key)}>
        <Tabs.TabPane key={'1'} tab={'人员管理'} disabled={!auth('001098001')}>
          <Form
            form={userForm}
            onFinish={userSearch}
            onReset={userSearch}
            initialValues={{pageSize: 10, pageNum: 1, orgId: '0006'}}
          >
            <div className={commonStyles.filterSection}>
              <Row>
                <Col span={6}>
                  <Form.Item label={'人员名称'} name={'name'}>
                    <Input placeholder='请输入人员名称' allowClear />
                  </Form.Item>
                </Col>
                <Col span={6} offset={1}>
                  <Form.Item label={'手机号'} name={'mobile'}>
                    <Input placeholder='请输入人员手机号' allowClear />
                  </Form.Item>
                </Col>
                <Col offset={1} span={6}>
                  <Form.Item label={'状态'} name={'lessorAccountStatus'}>
                    <Select placeholder={'请选择'} allowClear>
                      <Select.Option value={'1'}>启用</Select.Option>
                      <Select.Option value={'0'}>禁用</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label={'岗位'} name={'roleId'}>
                    <Select placeholder={'请选择'} allowClear>
                      {roleList
                        .filter(item => item.enable === '1')
                        .map(item => (
                          <Select.Option value={item.roleId} key={item.roleId}>
                            {item.roleName}
                          </Select.Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={6} offset={1}>
                  <Form.Item label={'组织'} name={'orgId'}>
                    <TreeSelect placeholder={'请选择'} allowClear treeData={tree} />
                  </Form.Item>
                </Col>
              </Row>
              <Row justify={'end'}>
                <Space>
                  <Button type={'primary'} htmlType={'submit'} onClick={() => userForm.setFieldsValue({pageNum: 1})}>
                    查询
                  </Button>
                  <Button htmlType={'reset'}>重置</Button>
                </Space>
              </Row>
            </div>
            <div className={commonStyles.filterSection}>
              <Table
                rowKey={'sysUserId'}
                dataSource={userList}
                loading={userLoading}
                style={{marginBottom: 10}}
                columns={[
                  {title: '姓名', dataIndex: 'name', key: 'name'},
                  {title: '手机号', dataIndex: 'mobile', key: 'mobile'},
                  {title: '岗位', dataIndex: 'roleName', key: 'roleName'},
                  {
                    title: '所在项目',
                    dataIndex: 'projectList',
                    render: text =>
                      text?.length > 1 ? (
                        <Tooltip title={text?.map(item => item.value)?.join(',')}>
                          <span>{text?.[0].value + '...'}</span>
                        </Tooltip>
                      ) : (
                        text?.map(item => item.value)?.join(',')
                      ),
                  },
                  {
                    title: '状态',
                    dataIndex: 'lessorAccountStatus',
                    key: 'lessorAccountStatus',
                    render: text => (text === '1' ? '正常' : '禁用'),
                  },
                  {
                    title: '操作',
                    render: (_, record) => {
                      return (
                        <Space>
                          {auth('002098002') && (
                            <Button
                              style={{padding: 0}}
                              type={'link'}
                              onClick={() => {
                                editForm.setFieldsValue({
                                  name: record.name,
                                  mobile: record.mobile,
                                  sysUserId: record.sysUserId,
                                  roleId: record.roleId,
                                  lessorAccountStatus: record.lessorAccountStatus,
                                  projectList: record.projectList?.map(item => item.key),
                                })
                                setVisible(true)
                              }}
                            >
                              编辑
                            </Button>
                          )}
                          {auth('023098002') && (
                            <Switch
                              checked={record.lessorAccountStatus === '1'}
                              onChange={value =>
                                Modal.confirm({
                                  title: '提示',
                                  icon: null,
                                  content: `是否确认${
                                    record.lessorAccountStatus === '1' ? '禁用' : '启用'
                                  }该人员账号？`,
                                  centered: true,
                                  onOk: async () => {
                                    await condoModel.condoProjectTeamUserUpdateStatus({
                                      sysUserId: record.sysUserId,
                                      // roleId: record.roleId,
                                      lessorAccountStatus: value ? '1' : '0',
                                    } as CondoProjectUserStatusDTO)
                                    userForm.submit()
                                  },
                                })
                              }
                            />
                          )}
                        </Space>
                      )
                    },
                  },
                ]}
                pagination={getPaginationProps(userForm, userTotal)}
              />
            </div>
          </Form>
        </Tabs.TabPane>
        <Tabs.TabPane key={'2'} tab={'岗位配置'} forceRender disabled={!auth('001099001')}>
          <Form form={roleForm} onFinish={roleSearch} onReset={roleSearch} initialValues={{pageNum: 1, pageSize: 10}}>
            <div className={commonStyles.filterSection}>
              <Row>
                <Col span={5}>
                  <Form.Item label={'岗位'} name={'roleName'}>
                    <Input placeholder='请输入' allowClear />
                  </Form.Item>
                </Col>
                <Col offset={1} span={5}>
                  <Form.Item label={'状态'} name={'enable'}>
                    <Select placeholder={'请选择'} allowClear>
                      <Select.Option value={'1'}>启用</Select.Option>
                      <Select.Option value={'0'}>禁用</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row justify={'end'}>
                <Space>
                  <Button type={'primary'} htmlType={'submit'} onClick={() => roleForm.setFieldsValue({pageNum: 1})}>
                    查询
                  </Button>
                  <Button htmlType={'reset'}>重置</Button>
                </Space>
              </Row>
            </div>
            <div className={commonStyles.filterSection}>
              <Table
                style={{marginBottom: 10}}
                rowKey={'roleId'}
                dataSource={roleList}
                loading={roleLoading}
                columns={[
                  {title: '角色名称', dataIndex: 'roleName', key: 'roleName'},
                  {title: '岗位人数', dataIndex: 'userCount', key: 'userCount', render: text => text ?? '0'},
                  {title: '状态', dataIndex: 'enable', key: 'enable', render: text => (text === '1' ? '正常' : '禁用')},
                  {
                    title: '操作',
                    render: record => {
                      const {roleId} = record
                      return (
                        <Space>
                          {auth('023099002') && (
                            <Switch
                              checked={record.enable === '1'}
                              onChange={value => {
                                if (record.enable === '1' && record.userCount)
                                  return message.error('该岗位还有人员，不能禁用！')
                                Modal.confirm({
                                  title: '提示',
                                  icon: null,
                                  content: `是否确认${record.enable === '1' ? '禁用' : '启用'}该岗位？`,
                                  centered: true,
                                  onOk: async () => {
                                    await condoModel.condoProjectTeamRoleUpdateStatus({
                                      roleId: record.roleId,
                                      enable: value ? '1' : '0',
                                    })
                                    roleForm.submit()
                                  },
                                })
                              }}
                            />
                          )}

                          {record.enable !== '1' && auth('004099002') && (
                            <Button
                              style={{padding: 0}}
                              type={'link'}
                              onClick={() => {
                                Modal.confirm({
                                  title: '提示',
                                  content: '是否确认删除该岗位？',
                                  centered: true,
                                  icon: null,
                                  onOk: async () => {
                                    await condoModel.condoProjectTeamRoleDelete({roleId: record.roleId})
                                    roleForm.submit()
                                    userForm.submit()
                                  },
                                })
                              }}
                            >
                              删除
                            </Button>
                          )}
                          {auth('002099002') && (
                            <Button
                              style={{padding: 0}}
                              type={'link'}
                              onClick={() => {
                                history.push({
                                  pathname: '/condo-team-manage/add-post',
                                  state: {roleId},
                                })
                              }}
                            >
                              编辑
                            </Button>
                          )}

                          {auth('001099002') && (
                            <Button
                              style={{padding: 0}}
                              type={'link'}
                              onClick={() => {
                                history.push({
                                  pathname: '/condo-team-manage/add-post',
                                  state: {roleId, disabled: true},
                                })
                              }}
                            >
                              查看
                            </Button>
                          )}
                        </Space>
                      )
                    },
                  },
                ]}
                pagination={getPaginationProps(roleForm, roleTotal)}
              />
            </div>
          </Form>
        </Tabs.TabPane>
        <Tabs.TabPane key={'3'} tab={'外部人员'} forceRender disabled={!auth('001107001')}>
          <Form
            form={outUserForm}
            onFinish={outUserSearch}
            onReset={outUserSearch}
            initialValues={{pageSize: 10, pageNum: 1, projectList: []}}
          >
            <div className={commonStyles.filterSection}>
              <Row>
                <Col span={6}>
                  <Form.Item label={'人员名称'} name={'name'}>
                    <Input placeholder='请输入' allowClear />
                  </Form.Item>
                </Col>
                <Col span={6} offset={1}>
                  <Form.Item label={'手机号'} name={'mobile'}>
                    <Input placeholder='请输入' allowClear />
                  </Form.Item>
                </Col>
                <Col span={6} offset={1}>
                  <Form.Item label={'状态'} name={'lessorAccountStatus'}>
                    <Select placeholder={'请选择'} allowClear>
                      <Select.Option value={'1'}>正常</Select.Option>
                      <Select.Option value={'0'}>禁用</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item label={'岗位'} name={'roleId'}>
                    <Select placeholder={'请选择'} allowClear>
                      {roleList.map(item => (
                        <Select.Option value={item.roleId} key={item.roleId}>
                          {item.roleName}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={8} offset={1}>
                  <Form.Item label={'所在项目'} name={'projectList'}>
                    <Select placeholder={'全部'} mode={'multiple'} allowClear>
                      {condoProjectList.map(item => (
                        <Select.Option value={item.condoId} key={item.condoId}>
                          {item.projectName}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row justify='end'>
                <Space>
                  <Button type={'primary'} htmlType={'submit'} onClick={() => outUserForm.setFieldsValue({pageNum: 1})}>
                    查询
                  </Button>
                  <Button htmlType={'reset'}>重置</Button>
                </Space>
              </Row>
            </div>
            <div className={commonStyles.filterSection}>
              <Table
                rowKey={'extraUserId'}
                loading={extraLoading}
                dataSource={extraList}
                columns={[
                  {title: '姓名', dataIndex: 'name'},
                  {title: '手机号', dataIndex: 'mobile'},
                  {title: '岗位', dataIndex: 'roleName'},
                  {
                    title: '所在项目',
                    dataIndex: 'projectList',
                    render: text =>
                      text?.length > 1 ? (
                        <Tooltip title={text?.map(item => item.value)?.join(',')}>
                          <span>{text?.[0].value + '...'}</span>
                        </Tooltip>
                      ) : (
                        text?.map(item => item.value)?.join(',')
                      ),
                  },
                  {title: '状态', dataIndex: 'lessorAccountStatus', render: text => (text === '1' ? '正常' : '禁用')},
                  {
                    title: '操作',
                    render: (_, record) => {
                      const {projectList, name, mobile, extraUserId, roleId} = record
                      return (
                        <Space>
                          {auth('002107002') && (
                            <Button
                              type={'link'}
                              onClick={() => {
                                addExtraForm.setFieldsValue({
                                  projectList: projectList?.map(item => item.key),
                                  name,
                                  mobile,
                                  extraUserId,
                                  roleId,
                                })
                                setExtraVisible(true)
                              }}
                            >
                              编辑
                            </Button>
                          )}
                          {auth('023107002') && (
                            <Switch
                              checked={record.lessorAccountStatus === '1'}
                              onChange={check => {
                                Modal.confirm({
                                  title: '提示',
                                  content: `是否确认${check ? '启用' : '禁用'}该人员？`,
                                  centered: true,
                                  icon: null,
                                  onOk: async () => {
                                    await condoModel.condoProjectTeamUserExtraUpdateStatus({
                                      lessorAccountStatus: check ? '1' : '0',
                                      extraUserId,
                                    } as CondoProjectUserExtraDTO)
                                    outUserForm.submit()
                                  },
                                })
                              }}
                            />
                          )}
                        </Space>
                      )
                    },
                  },
                ]}
                pagination={getPaginationProps(outUserForm, extraTotal)}
              />
            </div>
          </Form>
        </Tabs.TabPane>
      </Tabs>

      {/* 编辑人员 */}
      <Modal
        open={visible}
        onCancel={() => setVisible(false)}
        centered
        title={'编辑人员'}
        afterClose={() => editForm.resetFields()}
        footer={null}
      >
        <Form form={editForm} wrapperCol={{span: 15}}>
          <Form.Item label={'姓名'} name={'name'}>
            <Input disabled />
          </Form.Item>
          <Form.Item label={'手机号'} name={'mobile'}>
            <Input disabled />
          </Form.Item>
          <Form.Item label={'角色'} name={'roleId'}>
            <Select placeholder={'请选择'} allowClear>
              {roleList
                .filter(item => item.enable === '1')
                .map(item => (
                  <Select.Option key={item.roleId} value={item.roleId}>
                    {item.roleName}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item
            label={'所在项目'}
            name={'projectList'}
            wrapperCol={{span: 15}}
            rules={[{required: true, message: '请选择所在项目'}]}
          >
            <TreeSelect
              placeholder={'请选择'}
              treeData={[
                {
                  value: '1',
                  title: '全部',
                  key: '1',
                  label: '全部',
                  children: condoProjectList.map(item => ({
                    value: item.condoId,
                    title: item.projectName,
                    key: item.condoId,
                    label: item.projectName,
                  })),
                },
              ]}
              allowClear
              treeDefaultExpandAll
              treeCheckable
            />
          </Form.Item>
          <Row justify='end'>
            <Space>
              <Button onClick={() => setVisible(false)}>取消</Button>
              <Button
                type={'primary'}
                onClick={async () => {
                  const {sysUserId, roleId, projectList} = editForm.getFieldsValue(true)
                  await condoModel.condoProjectTeamUserUpdate({
                    sysUserId,
                    roleId,
                    // lessorAccountStatus,
                    projectList: condoProjectList
                      .filter(item => projectList.includes(item.condoId))
                      .map(item => ({key: item.condoId, value: item.projectName})),
                  })
                  userForm.submit()
                  roleForm.submit()
                  setVisible(false)
                }}
                loading={condoModel.condoProjectTeamUserUpdate.useLoading()}
              >
                保存
              </Button>
            </Space>
          </Row>
        </Form>
      </Modal>

      <Modal
        open={extraVisible}
        destroyOnClose
        afterClose={() => {
          addExtraForm.resetFields()
        }}
        title={addExtraForm.getFieldValue('extraUserId') ? '编辑' : '添加人员'}
        centered
        width={556}
        footer={null}
        onCancel={() => setExtraVisible(false)}
      >
        <Form
          labelCol={{span: 5}}
          form={addExtraForm}
          wrapperCol={{span: 10}}
          onFinish={async () => {
            const {projectList, ...formValues} = addExtraForm.getFieldsValue(true)
            await condoModel.condoProjectTeamUserExtraUpdate({
              ...formValues,
              projectList: condoProjectList
                .filter(item => projectList.includes(item.condoId))
                .map(item => ({key: item.condoId, value: item.projectName})),
              projectAll: condoProjectList.length === projectList.length ? '1' : '0',
            })
            setExtraVisible(false)
            outUserForm.submit()
          }}
        >
          <Form.Item label={'姓名'} name={'name'} rules={[{required: true, message: '请输入姓名'}]}>
            <Input
              placeholder='请输入'
              allowClear
              maxLength={5}
              disabled={!!addExtraForm.getFieldValue('extraUserId')}
            />
          </Form.Item>
          <Form.Item label={'手机号'} name={'mobile'} rules={[{required: true, message: '请输入手机号'}]}>
            <Input
              placeholder='请输入'
              allowClear
              maxLength={11}
              disabled={!!addExtraForm.getFieldValue('extraUserId')}
            />
          </Form.Item>
          <Form.Item label={'角色'} name={'roleId'} rules={[{required: true, message: '请选择角色'}]}>
            <Select placeholder={'请选择'} allowClear>
              {roleList
                .filter(item => item.enable === '1')
                .map(item => (
                  <Select.Option value={item.roleId} key={item.roleId}>
                    {item.roleName}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
          <Form.Item
            label={'所在项目'}
            name={'projectList'}
            wrapperCol={{span: 15}}
            rules={[{required: true, message: '请选择所在项目'}]}
          >
            <TreeSelect
              placeholder={'请选择'}
              treeData={[
                {
                  value: '1',
                  key: '1',
                  label: '全部',
                  title: '全部',
                  children: condoProjectList.map(item => ({
                    value: item.condoId,
                    key: item.condoId,
                    title: item.projectName,
                    label: item.projectName,
                  })),
                },
              ]}
              allowClear
              treeDefaultExpandAll
              treeCheckable
            />
          </Form.Item>
          <Row justify={'end'}>
            <Space>
              <Button onClick={() => setExtraVisible(false)}>取消</Button>
              <Button type={'primary'} htmlType={'submit'}>
                确认
              </Button>
            </Space>
          </Row>
        </Form>
      </Modal>
    </Page>
  )
}
