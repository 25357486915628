import React, {useCallback} from 'react'
import {Form, Input, message, Modal, Select} from 'antd'
import {cleanModel} from '@/store/models/Clean'
import {useCodeArraySync} from '@/store/models/Common'

export function useCleanOrderCancel() {
  const cleanOrderCancelReason = useCodeArraySync('clean.order.cancelReason')
  const [form] = Form.useForm()

  return useCallback(
    ({orderId, onSuccess}: {orderId: string; onSuccess?: () => void}) => {
      Modal.confirm({
        title: '取消原因',
        icon: null,
        closable: true,
        centered: true,
        okText: '提交',
        onOk: async () => {
          await form.validateFields()
          form.submit()
        },
        afterClose: () => form.resetFields(),
        content: (
          <Form
            form={form}
            initialValues={{orderId}}
            onFinish={async () => {
              await cleanModel.cleanOrderCancel(form.getFieldsValue(true))
              message.success('取消成功！')
              onSuccess?.()
            }}
            style={{marginTop: '20px'}}
            labelAlign={'right'}
            labelCol={{span: 6}}
          >
            <Form.Item label={'取消原因'} name={'cancelReason'} rules={[{required: true, message: '请选择取消原因'}]}>
              <Select placeholder={'请选择'} allowClear>
                {cleanOrderCancelReason.map(item => (
                  <Select.Option key={item.id} value={item.label}>
                    {item.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label={'具体描述'} name={'cancelNotes'}>
              <Input.TextArea placeholder={'请输入'} allowClear maxLength={200} showCount />
            </Form.Item>
          </Form>
        ),
      })
    },
    [cleanOrderCancelReason, form]
  )
}
