import {useEffect, useState} from 'react'
import {userModel} from '@/store/models/User'

const AuthGate = props => {
  const [isReady, setIsReady] = useState(!userModel.data.token)

  useEffect(() => {
    if (!isReady) {
      const init = async () => {
        try {
          await userModel.queryUserInfo()
        } catch (reason) {
          if ((reason as any)?.response?.data?.code !== 200) {
            userModel.clearUserInfo()
          }
        } finally {
          setIsReady(true)
        }
      }
      init()
    }
  }, [isReady])

  return isReady ? props.children : null // null 可以替换成 loading
}

export default AuthGate
