export const renderServer = {
  title(props: {categoryFirName: string; categorySecName: string; categoryThiName: string}) {
    return [props.categoryFirName, props.categorySecName, props.categoryThiName].join('-')
  },
}

interface RenderSectionList<T, Title = any> {
  renderTitle: (item: T) => Title
  groupBy: (item: T) => any
  extProps?: (item: T) => object // Partial<T>
}

export type RenderSectionResult<T, Title = any> = {
  title: Title
  data: T[]
  [key: string]: any
}

export function renderSectionList<T, Title = any>(
  list: T[] = [],
  {renderTitle, groupBy, extProps}: RenderSectionList<T, Title>
): RenderSectionResult<T, Title>[] {
  if (!list || !list.length) return []
  const renderObj = list.reduce(
    (result, item) => {
      const groupKey = groupBy(item)
      if (!result.obj[groupKey]) {
        result.keys.push(groupKey)
        result.obj[groupKey] = {
          title: renderTitle(item),
          ...(typeof extProps === 'function' && extProps(item)),
          data: [],
        }
      }
      result.obj[groupKey].data.push(item)
      return result
    },
    {keys: [], obj: {}}
  )
  return renderObj.keys.map(key => renderObj.obj[key])
}

export const communityTitle = {
  // 集中：公寓名-X层+室号(houseNo)（模板名）
  condo(params: {houseNo: string; templateName: string}) {
    return [params.houseNo && `${params.houseNo}室`, params.templateName && `(${params.templateName})`]
      .filter(item => item)
      .join('')
  },
  // 1 分散 整租：小区名-X栋+X单元+室号
  scatterWhole(params: {unitNo: string; houseNo: string}) {
    return [params.unitNo && `${params.unitNo}单元`, params.houseNo && `${params.houseNo}室`]
      .filter(item => item)
      .join('-')
  },
  // 2 分散 合租：小区名-X栋+X单元+室号-room-A+房间名（有则展示，无为空）
  scatterJoin(params: {unitNo: string; houseNo: string; roomCode: string; roomName: string}) {
    return [
      params.unitNo && `${params.unitNo}单元`,
      params.houseNo && `${params.houseNo}室`,
      params.roomCode && params.roomCode,
      params.roomName && `(${params.roomName})`,
    ]
      .filter(item => item)
      .join('-')
  },
}
