import React, {useCallback, useEffect, useState} from 'react'
import {Page} from '@/components/Page'
import commonStyles from '@/assets/styles/common.module.less'
import {
  Button,
  Col,
  DatePicker,
  Descriptions,
  Form,
  Input,
  message,
  Modal,
  Popover,
  Row,
  Select,
  Space,
  Table,
  Tooltip,
  TreeSelect,
} from 'antd'
import {Breadcrumb} from '@/components/Breadcrumb'
import {getPaginationProps} from '@/common/biz/getPaginationProps'
import {stewardModel} from '@/store/models/Steward'
import {sysModel} from '@/store/models/Sys'
import {userModel} from '@/store/models/User'
import {useCodeArraySync, useCodeSync} from '@/store/models/Common'
import moment from 'moment/moment'
import {usePermission} from '@/components/Permission'
import {downloadBlob} from '@/utils/util'

export default function AssessmentManagementList() {
  const [form] = Form.useForm()
  const [total, setTotal] = useState(0)
  const [list, setList] = useState<StewardSalaryEvaluationVO[]>([])
  const loading = stewardModel.stewardSalaryEvaluationList.useLoading()
  const orgId = userModel.useData(data => data.userInfo?.orgId)
  const positionLevelArr = useCodeArraySync('sys.user.steward.position.level')
  const positionLevel = useCodeSync('sys.user.steward.position.level')
  const [confirmForm] = Form.useForm()
  const [selectedRows, setSelectedRows] = useState<StewardSalaryEvaluationVO[]>([])
  const [auth] = usePermission()

  const search = useCallback(() => {
    const values = form.getFieldsValue(true)
    stewardModel.stewardSalaryEvaluationList(values).then(({response: {data, total}}) => {
      setList(data)
      setTotal(total)
    })
  }, [form])

  const [openAppList, setOpenAppList] = useState<StewardOpenAppVO[]>([])
  const [tree, setTree] = useState<SysOrgV2VO[]>([])

  useEffect(() => {
    stewardModel
      .stewardOpenAppList({
        pageable: false,
        pageNum: 1,
        pageSize: 1,
        appStatus: 1,
      } as StewardAppOpenListDTO)
      .then(({response: {data}}) => {
        setOpenAppList(data)
      })

    sysModel.sysOrgV2Tree({orgId: '', orgPid: '', orgName: '', allChildren: true}).then(({response: {data}}) => {
      setTree(data)
    })
  }, [orgId])

  useEffect(() => {
    form.submit()
  }, [form])

  return (
    <Page
      breadcrumb={
        <Breadcrumb
          right={
            <Space>
              {auth('026189001') && (
                <Button
                  type={'primary'}
                  onClick={() => {
                    if (!selectedRows.length) return message.warning('请选择要确认数据！')
                    const appName = selectedRows[0].appName?.split(',')
                    const month = selectedRows[0].evaluationMonth
                    if (!selectedRows.every(item => item.appName.split(',').every(name => appName.includes(name))))
                      return message.warning('只能操作相同服务标签的评定确认！')
                    if (!selectedRows.every(item => item.evaluationMonth === month))
                      return message.warning('只能操作相同月份的评定确认！')
                    if (!selectedRows.every(item => item.evaluationStatus !== '1'))
                      return message.warning('只能操作未评定数据！')

                    Modal.confirm({
                      title: '确认',
                      content: `是否批量确认当前所有人${moment(month).format('YYYY年MM月')}的评定。`,
                      icon: null,
                      centered: true,
                      width: 500,
                      onOk: async () => {
                        const ids = selectedRows.map(item => item.id)
                        await stewardModel.stewardSalaryEvaluationConfirm(ids)
                        message.success('确认成功！')
                        form.submit()
                        setSelectedRows([])
                      },
                    })
                  }}
                >
                  评定确认
                </Button>
              )}
              {auth('007189001') && (
                <Button
                  type={'primary'}
                  onClick={() =>
                    downloadBlob({
                      url: '/steward-salary-evaluation/export',
                      body: {...form.getFieldsValue(true), pageable: false},
                    })
                  }
                >
                  导出
                </Button>
              )}
            </Space>
          }
        />
      }
    >
      <div className={commonStyles.filterSection}>
        <Form form={form} onFinish={search} onReset={search} initialValues={{pageNum: 1, pageSize: 10, pageable: true}}>
          <Row>
            <Col span={7}>
              <Form.Item label={'姓名'} name={'userName'}>
                <Input placeholder={'请输入姓名'} allowClear />
              </Form.Item>
            </Col>
            <Col span={7} offset={1}>
              <Form.Item label={'服务标签'} name={'appId'}>
                <Select
                  options={openAppList}
                  fieldNames={{value: 'appId', label: 'appName'}}
                  placeholder={'请选择'}
                  allowClear
                />
              </Form.Item>
            </Col>
            <Col span={7} offset={1}>
              <Form.Item label={'运营组织'} name={'userOrgId'}>
                <TreeSelect
                  placeholder={'请选择'}
                  allowClear
                  treeData={tree}
                  fieldNames={{label: 'orgName', value: 'orgId', children: 'children'}}
                  treeDefaultExpandAll
                  showSearch={true}
                  filterTreeNode={(inputValue: string, treeNode) => {
                    if (!inputValue) return true
                    return treeNode.orgName?.includes(inputValue)
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item label={'评定月份'} name={'evaluationMonth'}>
                <DatePicker.RangePicker
                  picker={'month'}
                  onChange={(_, dateStrings) => {
                    const [evaluationMonthBegin, evaluationMonthEnd] = dateStrings ?? []
                    form.setFieldsValue({evaluationMonthBegin, evaluationMonthEnd})
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={7} offset={1}>
              <Form.Item label={'岗位等级'} name={'userPositionLevel'}>
                <Select options={positionLevelArr} fieldNames={{value: 'id'}} placeholder={'请选择'} allowClear />
              </Form.Item>
            </Col>
            <Col span={7} offset={1}>
              <Form.Item label={'状态'} name={'evaluationStatus'}>
                <Select
                  options={[
                    {value: '0', label: '未确认'},
                    {value: '1', label: '已确认'},
                  ]}
                  placeholder={'请选择'}
                  allowClear
                />
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item label={'管家状态'} name={'userStatus'}>
                <Select
                  options={[
                    {value: '1', label: '在职'},
                    {value: '0', label: '离职'},
                  ]}
                  placeholder={'请选择'}
                  allowClear
                />
              </Form.Item>
            </Col>
          </Row>
          <Row justify={'end'}>
            <Space>
              <Button type={'primary'} htmlType={'submit'}>
                查询
              </Button>
              <Button htmlType={'reset'}>重置</Button>
            </Space>
          </Row>
        </Form>
      </div>
      <div className={commonStyles.tableSection}>
        <Table
          rowKey={'id'}
          loading={loading}
          dataSource={list}
          rowSelection={{
            selectedRowKeys: selectedRows.map(item => item.id),
            onChange(_, selectedRows) {
              setSelectedRows(selectedRows)
            },
          }}
          columns={[
            {dataIndex: 'userName', title: '姓名'},
            {dataIndex: 'appName', title: '服务标签'},
            {
              dataIndex: 'userOrgName',
              title: '运营组织',
              render: value => (
                <Popover content={value}>
                  <span>{value.length > 10 ? value.slice(0, 10) + '...' : value}</span>
                </Popover>
              ),
            },
            {
              dataIndex: 'userRoleName',
              title: '用户组',
              render: value => (
                <Popover content={value}>
                  <span>{value.length > 10 ? value.slice(0, 10) + '...' : value}</span>
                </Popover>
              ),
            },
            {dataIndex: 'userPositionLevel', title: '岗位等级', render: value => positionLevel[value]},
            {dataIndex: 'userStatus', title: '管家状态', render: value => (value === '1' ? '在职' : '离职')},
            {dataIndex: 'evaluationMonth', title: '绩效月份'},
            {dataIndex: 'evaluationLevel', title: '评定'},
            {
              dataIndex: 'remark',
              title: '评定原因',
              render: value =>
                value ? (
                  value.length > 10 ? (
                    <Tooltip title={value}>
                      <span>{value.substring(0, 11) + '...'}</span>
                    </Tooltip>
                  ) : (
                    value
                  )
                ) : (
                  '/'
                ),
            },
            {dataIndex: 'evaluationStatus', title: '状态', render: value => (value === '0' ? '未确认' : '已确认')},
            {dataIndex: 'lastEvaluator', title: '最后评定人'},
            {dataIndex: 'lastEvaluationTime', title: '最后评定时间'},
            {dataIndex: 'confirmer', title: '确认人'},
            {dataIndex: 'confirmTime', title: '确认时间'},
            {
              dataIndex: 'id',
              fixed: 'right',
              title: '操作',
              render: (value, record) => (
                <Space>
                  {auth('001189002') && (
                    <Button
                      type={'link'}
                      onClick={async () => {
                        const {
                          response: {data},
                        } = await stewardModel.stewardSalaryEvaluationDetail({id: value})
                        Modal.info({
                          title: '评定详情',
                          icon: null,
                          centered: true,
                          width: 600,
                          content: (
                            <Descriptions column={2}>
                              <Descriptions.Item label={'姓名'}>{data.userName}</Descriptions.Item>
                              <Descriptions.Item label={'服务标签'}>{data.appName}</Descriptions.Item>
                              <Descriptions.Item label={'组织'}>{data.userOrgName}</Descriptions.Item>
                              <Descriptions.Item label={'用户组'}>{data.userRoleName}</Descriptions.Item>
                              <Descriptions.Item label={'岗位等级'}>
                                {positionLevel[data.userPositionLevel]}
                              </Descriptions.Item>
                              <Descriptions.Item label={'管家状态'}>
                                {data.userStatus === '1' ? '在职' : '离职'}
                              </Descriptions.Item>
                              <Descriptions.Item label={'绩效月份'}>{data.evaluationMonth}</Descriptions.Item>
                              <Descriptions.Item label={'评定'}>{data.evaluationLevel}</Descriptions.Item>
                              <Descriptions.Item label={'评定原因'}>{data.remark}</Descriptions.Item>
                              <Descriptions.Item label={'最后评定人'}>{data.lastEvaluator}</Descriptions.Item>
                              <Descriptions.Item label={'最后评定时间'}>{data.lastEvaluationTime}</Descriptions.Item>
                              <Descriptions.Item label={'确认人'}>{data.confirmer ?? '/'}</Descriptions.Item>
                              <Descriptions.Item label={'确认时间'}>{data.confirmTime ?? '/'}</Descriptions.Item>
                            </Descriptions>
                          ),
                          okText: '关闭',
                        })
                      }}
                      style={{paddingLeft: 0, paddingRight: 0}}
                    >
                      查看
                    </Button>
                  )}
                  {auth('018189001') && (
                    <Button
                      type={'link'}
                      style={{paddingLeft: 0, paddingRight: 0}}
                      disabled={record.evaluationStatus === '1'}
                      onClick={() => {
                        confirmForm.setFieldsValue(record)
                        Modal.confirm({
                          title: '确认',
                          icon: null,
                          centered: true,
                          width: 500,
                          afterClose: () => confirmForm.resetFields(),
                          onOk: async () => {
                            await confirmForm.validateFields()
                            const {appId, evaluationMonth, ...values} = confirmForm.getFieldsValue(true)
                            await stewardModel.stewardSalaryEvaluationUpsert({
                              evaluationMonth,
                              upsertInfo: [{...values, evaluationStatus: 1}],
                              appId,
                            })
                            message.success('确认成功')

                            form.submit()
                          },
                          content: (
                            <Form form={confirmForm} labelCol={{flex: '100px'}}>
                              <Form.Item label={`${confirmForm.getFieldValue('userName')}`} name={'evaluationLevel'}>
                                <Select
                                  options={[
                                    {label: 'A', value: 'A'},
                                    {label: 'B', value: 'B'},
                                    {label: 'C', value: 'C'},
                                    {label: 'D', value: 'D'},
                                    {label: 'E', value: 'E'},
                                  ]}
                                />
                              </Form.Item>
                              <Form.Item name={'remark'} rules={[{required: true, message: '请输入评价信息'}]}>
                                <Input.TextArea rows={3} showCount maxLength={500} placeholder={'评价信息'} />
                              </Form.Item>
                              <Form.Item label={'最后评定人'}>
                                <span>{confirmForm.getFieldValue('lastEvaluator')}</span>
                              </Form.Item>
                              <Form.Item label={'最后评定时间'}>
                                <span>{confirmForm.getFieldValue('lastEvaluationTime')}</span>
                              </Form.Item>
                            </Form>
                          ),
                        })
                      }}
                    >
                      评定确认
                    </Button>
                  )}
                </Space>
              ),
            },
          ]}
          scroll={{x: 'max-content'}}
          pagination={getPaginationProps(form, total)}
        />
      </div>
    </Page>
  )
}
