import React, {useCallback, useEffect, useState} from 'react'
import {Page} from '@/components/Page'
import commonStyle from '@/assets/styles/common.module.less'
import {Button, Col, DatePicker, Form, Input, message, Modal, Row, Select, Space, Table} from 'antd'
import {Breadcrumb} from '@/components/Breadcrumb'
import {getPaginationProps} from '@/common/biz/getPaginationProps'
import {stewardModel} from '@/store/models/Steward'
import {useAppHistory} from '@/common/hooks/useRouter'

export default function OpenAppManageList() {
  const history = useAppHistory()
  const [form] = Form.useForm()
  const [list, setList] = useState<StewardOpenAppVO[]>([])
  const [total, setTotal] = useState(0)
  const loading = stewardModel.stewardOpenAppList.useLoading()

  const search = useCallback(() => {
    const formValues = form.getFieldsValue(true)
    stewardModel.stewardOpenAppList(formValues).then(({response: {data, total}}) => {
      setTotal(total)
      setList(data)
    })
  }, [form])

  useEffect(() => {
    search()
  }, [search])

  return (
    <Page
      breadcrumb={
        <Breadcrumb
          right={<Button onClick={() => history.push({pathname: '/open-app-list/add'})}>新增接入方</Button>}
        />
      }
    >
      <div className={commonStyle.filterSection}>
        <Form
          form={form}
          labelAlign={'right'}
          labelCol={{flex: '80px'}}
          initialValues={{pageNum: 1, pageSize: 10, pageable: true}}
          onFinish={search}
          onReset={search}
        >
          <Row gutter={20}>
            <Col span={8}>
              <Form.Item label={'接入方名称'} name={'appName'}>
                <Input placeholder={'请输入'} allowClear />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={'负责人'} name={'registerUname'}>
                <Input placeholder={'请输入'} allowClear />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={'手机号'} name={'registerMobile'}>
                <Input placeholder={'请输入'} allowClear />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={'状态'} name={'appStatus'}>
                <Select
                  options={[
                    {label: '启用', value: '1'},
                    {label: '禁用', value: '0'},
                  ]}
                  placeholder={'请选择'}
                  allowClear
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={'应用ID'} name={'appId'}>
                <Input placeholder={'请输入'} allowClear />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={'应用密钥'} name={'appSecret'}>
                <Input placeholder={'请输入'} allowClear />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={'有效期'}>
                <DatePicker.RangePicker
                  allowClear
                  style={{width: '100%'}}
                  onChange={(_, dateString) => {
                    const [validBeginDate, validEndDate] = dateString ?? []
                    form.setFieldsValue({
                      validBeginDate: validBeginDate ? validBeginDate + ' 00:00:00' : validBeginDate,
                      validEndDate: validEndDate ? validEndDate + ' 23:59:59' : validEndDate,
                    })
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={'创建日期'}>
                <DatePicker.RangePicker
                  allowClear
                  style={{width: '100%'}}
                  onChange={(_, dateString) => {
                    const [insertBeginTime, insertEndTime] = dateString ?? []
                    form.setFieldsValue({
                      insertBeginTime: insertBeginTime ? insertBeginTime + ' 00:00:00' : insertBeginTime,
                      insertEndTime: insertEndTime ? insertEndTime + ' 23:59:59' : insertEndTime,
                    })
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row justify={'end'}>
            <Space>
              <Button type={'primary'} htmlType={'submit'}>
                查询
              </Button>
              <Button htmlType={'reset'}>重置</Button>
            </Space>
          </Row>
        </Form>
      </div>
      <div className={commonStyle.filterSection}>
        <Table
          dataSource={list}
          loading={loading}
          rowKey={'appId'}
          columns={[
            {title: '接入方名称', dataIndex: 'appName'},
            {title: '负责人', dataIndex: 'registerUname'},
            {title: '手机号', dataIndex: 'registerMobile'},
            {title: '应用ID', dataIndex: 'appId'},
            {title: '应用密钥', dataIndex: 'appSecret'},
            {title: '有效期', render: (_, record) => `${record.validBeginDate}至${record.validEndDate}`},
            {title: '创建人', dataIndex: 'insertBy'},
            {title: '创建时间', dataIndex: 'insertTime'},
            {
              title: '状态',
              dataIndex: 'appStatus',
              render: (_, record) => (record.appStatus === 1 && '启用') || (record.appStatus === 0 && '禁用') || '/',
            },
            {
              title: '操作',
              fixed: 'right',
              render: (_, record) => {
                const appId = record.appId
                const appStatus = record.appStatus
                return (
                  <Space>
                    {[0, 1].includes(appStatus) && (
                      <Button
                        type={'link'}
                        style={{paddingLeft: 0, paddingRight: 0}}
                        onClick={() => {
                          Modal.confirm({
                            title: '提示',
                            icon: null,
                            centered: true,
                            content: `是否确认${appStatus === 1 ? '禁用' : '启用'}？`,
                            onOk: async () => {
                              await stewardModel.stewardOpenAppEnable({appId, appStatus: appStatus === 1 ? 0 : 1})
                              message.success('操作成功')
                              search()
                            },
                          })
                        }}
                      >
                        {appStatus === 1 ? '禁用' : '启用'}
                      </Button>
                    )}

                    <Button
                      type={'link'}
                      style={{paddingLeft: 0, paddingRight: 0}}
                      onClick={() => {
                        history.push('/open-app-list/detail', {appId})
                      }}
                    >
                      查看
                    </Button>
                    <Button
                      type={'link'}
                      style={{paddingLeft: 0, paddingRight: 0}}
                      onClick={() => {
                        history.push('/open-app-list/add', {appId})
                      }}
                    >
                      编辑
                    </Button>
                  </Space>
                )
              },
            },
          ]}
          scroll={{x: 'max-content'}}
          pagination={getPaginationProps(form, total)}
        />
      </div>
    </Page>
  )
}
