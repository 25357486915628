import React, {useCallback, useEffect, useState} from 'react'
import {Page} from '@/components/Page'
import {Button, Descriptions, Form, InputNumber, message, Modal, PageHeader, Radio, Space, Typography} from 'antd'
import {useAppHistory, useAppLocation} from '@/common/hooks/useRouter'
import commonStyles from '@/assets/styles/common.module.less'
import DividerLine from '@/pages/property/component/DividerLine'
import {stewardModel} from '@/store/models/Steward'
import {useCodeSync} from '@/store/models/Common'

const answerCode = ['A', 'B', 'C', 'D']

const paperStatusCode = {'0': '未作答', '1': '已作答'}

const PaperList = React.memo(
  (args: {
    papers: StewardQuestionPaperContextDTO[]
    onClick: (item: StewardQuestionPaperContextDTO) => void
    start: number
    selectedId?: string
  }) => {
    const {papers = [], onClick, start, selectedId} = args
    return (
      <Space wrap>
        {papers.length
          ? papers.map((item, index) => (
              <Button
                type={item.questionId === selectedId ? 'primary' : 'ghost'}
                shape='circle'
                key={item.questionId}
                onClick={() => onClick(item)}
                danger={item.questionType === '4' ? false : !item.correct}
              >
                {start + index + 1}
              </Button>
            ))
          : '/'}
      </Space>
    )
  }
)

export default function StewardExamDetail() {
  const history = useAppHistory()
  const location = useAppLocation<'/steward-exam-management/detail'>()
  const type = location.state?.type
  const paperId = location.state?.paperId

  const stewardPaperStatusCode = useCodeSync('steward.paper.status')

  const [dailyInfo, setDailyInfo] = useState({} as StewardQuestionPaperDailyVO)
  const [paperInfo, setPaperInfo] = useState({} as StewardQuestionPaperVO)
  const [paperObj, setPaperObj] = useState<{[key: string]: StewardQuestionPaperContextDTO[]}>({})
  const [curPaper, setCurPaper] = useState({} as StewardQuestionPaperContextDTO)

  const queryInfo = useCallback(() => {
    function paperToObj(paper: StewardQuestionPaperContextDTO[] = []) {
      return paper?.reduce((acc, item) => {
        if (acc[item.questionType]) {
          acc[item.questionType] = [...acc[item.questionType], item]
        } else {
          acc[item.questionType] = [item]
        }
        return acc
      }, {})
    }
    if (type === '1') {
      stewardModel.stewardAdminQuestionPaperDailyInfo({paperId}).then(({response: {data}}) => {
        setDailyInfo(data)
        setPaperObj(paperToObj(data.paper))
      })
    } else {
      stewardModel.stewardAdminQuestionPaperInfo({paperId}).then(({response: {data}}) => {
        setPaperInfo(data)
        setPaperObj(paperToObj(data.paper))
      })
    }
  }, [paperId, type])

  useEffect(() => {
    queryInfo()
  }, [queryInfo])

  const auditLoading = stewardModel.stewardAdminQuestionPaperAudit.useLoading()
  const markLoading = stewardModel.stewardAdminQuestionPaperMark.useLoading()

  const calcStart = useCallback(
    (questionType: '2' | '3' | '4') => {
      const radio = paperObj['1'] ? paperObj['1'].length : 0
      const multiple = paperObj['3'] ? paperObj['3'].length : 0
      const judge = paperObj['2'] ? paperObj['2'].length : 0
      // 多选题的计数根据单选题
      if (questionType === '3') return radio
      // 判断题的计数根据单选题和多选题
      if (questionType === '2') return radio + multiple
      if (questionType === '4') return radio + multiple + judge
    },
    [paperObj]
  )

  return (
    <Page
      header={
        <PageHeader
          title={type === '1' ? '练习详情' : '考试详情'}
          ghost={false}
          onBack={() => history.goBack()}
          extra={
            <>
              {type === '2' && paperInfo.paperStatus === '1' && (
                <Button
                  type={'primary'}
                  onClick={() => {
                    let tmp = '1'
                    Modal.confirm({
                      title: '通过',
                      content: (
                        <Form.Item label={'试题生成方式'}>
                          <Radio.Group
                            defaultValue={'1'}
                            onChange={({target: {value}}) => {
                              tmp = value
                            }}
                          >
                            <Radio value={'1'}>系统生成</Radio>
                            <Radio value={'2'}>自定义</Radio>
                          </Radio.Group>
                        </Form.Item>
                      ),
                      icon: null,
                      closable: true,
                      centered: true,
                      okText: '确认',
                      okButtonProps: {loading: auditLoading},
                      onOk: async () => {
                        if (tmp === '2') {
                          history.push('/steward-exam-management/generation', {paperId})
                        } else {
                          await stewardModel.stewardAdminQuestionPaperAudit({
                            paperId,
                            score: 100,
                          } as StewardQuestionPaperSubmitDTO)
                          message.success('操作成功！')
                          queryInfo()
                        }
                      },
                    })
                  }}
                >
                  通过
                </Button>
              )}
              {type === '2' && paperInfo.paperStatus === '3' && (
                <Button
                  type={'primary'}
                  onClick={() => {
                    Modal.confirm({
                      title: '提示',
                      content: '是否确认批阅完成？',
                      icon: null,
                      closable: true,
                      centered: true,
                      okText: '确认',
                      okButtonProps: {loading: markLoading},
                      onOk: async () => {
                        const paper = Object.values(paperObj).flat()
                        if (paper.some(item => [null, undefined].includes(item.goal)))
                          return message.warning('存在未判分的简答题！')
                        await stewardModel.stewardAdminQuestionPaperMark({
                          paperId,
                          paper,
                        } as StewardQuestionPaperSubmitDTO)
                        message.success('操作成功！')
                        queryInfo()
                      },
                    })
                  }}
                >
                  批阅完成
                </Button>
              )}
            </>
          }
        />
      }
    >
      <div className={commonStyles.tableSection}>
        <DividerLine title={'基础信息'} style={{marginBottom: '15px'}} />
        {type === '1' && (
          <Descriptions title='' column={2}>
            <Descriptions.Item label='管家'>{dailyInfo.userName}</Descriptions.Item>
            <Descriptions.Item label='日期'>{dailyInfo.createDate}</Descriptions.Item>
            <Descriptions.Item label='题数'>{dailyInfo.totalNum}</Descriptions.Item>
            <Descriptions.Item label='作答状态'>{paperStatusCode[dailyInfo.paperStatus]}</Descriptions.Item>
            <Descriptions.Item label='正确率'>
              {[null, undefined].includes(dailyInfo.correctRate) ? '/' : `${dailyInfo.correctRate}%`}
            </Descriptions.Item>
            <Descriptions.Item label='提交时间'>{dailyInfo.commitTime ?? '/'}</Descriptions.Item>
          </Descriptions>
        )}

        {type === '2' && (
          <Descriptions title='' column={2}>
            <Descriptions.Item label='管家'>{paperInfo.userName}</Descriptions.Item>
            <Descriptions.Item label='申请时间'>{paperInfo.pendingTime}</Descriptions.Item>
            <Descriptions.Item label='考试状态'>{stewardPaperStatusCode[paperInfo.paperStatus]}</Descriptions.Item>
            <Descriptions.Item label='审批人'>{paperInfo.auditUserName ?? '/'}</Descriptions.Item>
            <Descriptions.Item label='审批时间'>{paperInfo.auditTime ?? '/'}</Descriptions.Item>
            <Descriptions.Item label='交卷时间'>{paperInfo.commitTime ?? '/'}</Descriptions.Item>
            <Descriptions.Item label='阅卷人'>{paperInfo.markUserName ?? '/'}</Descriptions.Item>
            <Descriptions.Item label='阅卷时间'>{paperInfo.markTime ?? '/'}</Descriptions.Item>
            <Descriptions.Item label='总分'>{paperInfo.paperStatus === '4' ? paperInfo.score : '/'}</Descriptions.Item>
          </Descriptions>
        )}

        {(dailyInfo.paperStatus === '1' || ['3', '4'].includes(paperInfo.paperStatus)) && (
          <>
            <DividerLine title={'作答信息'} style={{margin: '30px 0 15px'}} />
            <Descriptions title='' column={2} layout={'vertical'} colon={false} labelStyle={{color: '#000'}}>
              <Descriptions.Item label='单选题'>
                <PaperList
                  papers={paperObj['1']}
                  onClick={item => setCurPaper(item)}
                  start={0}
                  selectedId={curPaper.questionId}
                />
              </Descriptions.Item>
              <Descriptions.Item label='多选题'>
                <PaperList
                  papers={paperObj['3']}
                  onClick={item => setCurPaper(item)}
                  start={calcStart('3')}
                  selectedId={curPaper.questionId}
                />
              </Descriptions.Item>
              <Descriptions.Item label='判断题'>
                <PaperList
                  papers={paperObj['2']}
                  onClick={item => setCurPaper(item)}
                  start={calcStart('2')}
                  selectedId={curPaper.questionId}
                />
              </Descriptions.Item>
              <Descriptions.Item label='简答题'>
                <PaperList
                  papers={paperObj['4']}
                  onClick={item => setCurPaper(item)}
                  start={calcStart('4')}
                  selectedId={curPaper.questionId}
                />
              </Descriptions.Item>
            </Descriptions>

            <Typography.Paragraph style={{margin: '30px 0 10px', color: 'rgba(0, 0, 0, 0.85)'}}>
              {curPaper.questionTitle}
            </Typography.Paragraph>
            {curPaper.questionType !== '4' ? (
              // 非简单题
              <>
                <div style={{display: 'flex', flexDirection: 'row'}}>
                  <Space direction={'vertical'} style={{width: '75%'}}>
                    {curPaper.answerList?.map((item, index) => (
                      <Typography.Paragraph key={item}>{`${answerCode[index]} ${item}`}</Typography.Paragraph>
                    ))}
                  </Space>
                  {!!curPaper.answer && (
                    <Space
                      direction={'vertical'}
                      style={{display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', width: '25%'}}
                      align={'end'}
                    >
                      <Typography.Paragraph>
                        管家作答：<span style={{color: '#FF6666'}}>{curPaper.answer}</span>
                      </Typography.Paragraph>
                      <Typography.Paragraph>
                        正确答案：<span style={{color: '#00B7AE'}}>{curPaper.answerRight}</span>
                      </Typography.Paragraph>
                    </Space>
                  )}
                </div>
                {/*考试详情：批阅中、已完成*/}
                {type === '2' && !!curPaper.questionId && (
                  <Typography.Paragraph style={{marginTop: '8px'}}>本题得分：{curPaper.goal}分</Typography.Paragraph>
                )}
              </>
            ) : (
              // 简答题
              <Space direction={'vertical'}>
                <Typography.Paragraph>
                  <Typography.Text style={{color: '#000'}}>管家作答：</Typography.Text>
                  {curPaper.answer}
                </Typography.Paragraph>
                <Typography.Paragraph>
                  <Typography.Text style={{color: '#000'}}>参考答案：</Typography.Text>
                  {curPaper.answerRight}
                </Typography.Paragraph>
                {/*考试详情：批阅中、已完成*/}
                {type === '2' && (
                  <Space>
                    <span>本题得分：</span>
                    {/* 批阅中 */}
                    {paperInfo.paperStatus === '3' && (
                      <InputNumber
                        value={curPaper.goal}
                        style={{width: '210px'}}
                        placeholder={`请输入，不得高于${curPaper.score}`}
                        onInput={text => {
                          if (+text < 0 || +text > curPaper.score)
                            return message.warning(`得分范围为0-${curPaper.score}`)
                          setCurPaper(prevState => ({...prevState, goal: +text}))
                          setPaperObj(prevState => ({
                            ...prevState,
                            '4': prevState['4'].map(item =>
                              item.questionId === curPaper.questionId ? {...item, goal: +text} : item
                            ),
                          }))
                        }}
                      />
                    )}
                    {/* 已完成 */}
                    {paperInfo.paperStatus === '4' && <span>{curPaper.goal ?? 0}分</span>}
                  </Space>
                )}
              </Space>
            )}
          </>
        )}
      </div>
    </Page>
  )
}
