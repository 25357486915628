import React, {useEffect, useState} from 'react'
import {Divider} from 'antd'
import styles from './index.module.less'
import {homeModel} from '@/store/models/Home'
import MenuBar from '../components/MenuBar'
import HeaderList from '@/pages/home/components/HeaderList'
import ChartView from '../components/ChartView'
import Statistics from '@/pages/home/components/Statistics'
import {useAppHistory} from '@/common/hooks/useRouter'

export default function StewardLeadHome() {
  const history = useAppHistory()

  //待办事项、工单概览接口返回
  const [toDoList, setTodoList] = useState([])
  const [orderList, setOrderList] = useState([])
  useEffect(() => {
    homeModel.homeStewardStatistic1({}).then(({response: {data}}) => {
      setTodoList([
        {
          label: '接单审批',
          num: data.num1,
          tip: '系统目前所有待处理的接单审批申请数量',
          onClick: () => {
            history.push({pathname: '/steward-calling', state: {connectionCheckStatus: '1'}})
          },
        },
        {
          label: '客户对接',
          num: data.num2,
          tip: '系统目前所有签约后未对接的客户数量',
          onClick: () => {
            history.push({pathname: '/client-docking', state: {extraStatus: '1'}})
          },
        },
        {
          label: '意见处理',
          num: data.num3,
          tip: '系统目前所有待处理的意见反馈数量',
          onClick: () => {
            history.push({pathname: '/feedback', state: {operationStatus: '0'}})
          },
        },
        {
          label: '待购物品',
          num: data.num4,
          tip: '系统目前所有待采购物品数量',
          onClick: () => {
            history.push({pathname: '/purchase', state: {purchaseStatus: '02'}})
          },
        },
        {
          label: '待收物品',
          num: data.num5,
          tip: '系统目前所有待收货物品数量',
          show: false,
          onClick: () => {
            history.push({pathname: '/purchase', state: {purchaseStatus: '03'}})
          },
        },
      ])

      setOrderList([
        {label: '待沟通', num: data.num6, tip: '系统目前所有待沟通的服务单数量'},
        {label: '已沟通', num: data.num7, tip: '系统目前所有已沟通的服务单数量'},
        {label: '上门中', num: data.num8, tip: '系统目前所有上门中的服务单数量'},
        {label: '服务中', num: data.num9, tip: '系统目前所有服务中的服务单数量', show: false},
      ])
    })
  }, [history])

  return (
    <div className={styles.index}>
      <div className={styles.headerContent}>
        <div className={styles.left}>
          <TemplateView title='待办事项'>
            <div style={{padding: '20px 0 35px 0'}}>
              <HeaderList data={toDoList} width={'20%'} />
            </div>
          </TemplateView>
        </div>
        <div className={styles.right}>
          <TemplateView title='工单概览'>
            <div style={{padding: '20px 0 35px 0'}}>
              <HeaderList data={orderList} width={'25%'} />
            </div>
          </TemplateView>
        </div>
      </div>
      <div style={{marginTop: 20}}>
        <TemplateView title='快捷入口'>
          <div style={{overflowX: 'auto'}}>
            <MenuBar />
          </div>
        </TemplateView>
      </div>
      <div style={{marginTop: 20}}>
        <TemplateView title='数据统计'>
          <Statistics />
        </TemplateView>
      </div>
      <div style={{marginTop: 20}}>
        <ChartView />
      </div>
    </div>
  )
}

interface TemplateProps {
  title: string
}
const TemplateView: React.FC<TemplateProps> = ({title, children}) => {
  return (
    <div className={styles.content}>
      <div className={styles.header}>
        <span className={styles.titleStyle}>{title}</span>
      </div>
      <Divider style={{margin: 0}} />
      {children}
    </div>
  )
}
