import React, {CSSProperties} from 'react'

interface BubbleProps {
  arrowPlace?: 'center' | number
  className?: string
  style?: CSSProperties
  backgroundColor?: string
}

const arrowSize = 6
export const Bubble: React.FC<BubbleProps> = props => {
  const backgroundColor = props.backgroundColor ?? props.style?.backgroundColor ?? 'white'
  const left = props.arrowPlace === 'center' ? '50%' : props.arrowPlace
  return (
    <div
      style={{
        position: 'relative',
        transform: `translateY(-${arrowSize}px)`,
        boxShadow: `0px 4px 4px 0px #d7d7d7`,
        whiteSpace: 'nowrap',
        color: '#262626',
        backgroundColor,
        ...props.style,
      }}
      className={props.className}
    >
      {props.children}
      <div
        style={{
          position: 'absolute',
          bottom: 0,
          left,
          transform: 'translate(-50%, 100%)',
          border: `${arrowSize}px solid transparent`,
          borderTopColor: backgroundColor,
        }}
      />
    </div>
  )
}
///////////////////////////////////////////////////////
export const Absolute: React.FC<{offset?: number; style?: CSSProperties}> = props => {
  return (
    <div
      style={{
        position: 'absolute',
        left: '50%',
        bottom: '100%',
        transform: 'translateX(-50%)',
        ...props.style,
      }}
    >
      {props.children}
    </div>
  )
}
