import {useEffect, useRef} from 'react'

class Event {
  static listeners = []

  static on(event: string, callback: (...args: any[]) => void) {
    this.listeners.push({event, callback})
  }

  static off(event: string, callback?: (...args: any[]) => void) {
    if (!callback) {
      this.listeners = this.listeners.filter(value => value.event !== event)
    } else {
      this.listeners = this.listeners.filter(value => value.callback !== callback)
    }
  }

  static emit(event: string, ...args: any[]) {
    this.listeners.forEach(value => {
      if (value.event === event) {
        value.callback(...args)
      }
    })
  }
}

/**
 * 全局监听
 * @example
 ```ts
 import * as listener from '@/common/hooks/listener'

 listener.useListener('delete-property', (propertyCode: string) => {})
 listener.emit('delete-property', '123')
 ```
 */
export function useListener(type: string, callback: (...args: any[]) => void) {
  const func = useRef(callback)
  useEffect(() => {
    func.current = callback
  }, [callback])

  useEffect(() => {
    const listener = (...args) => {
      func.current(...args)
    }

    Event.on(type, listener)
    return () => Event.off(type, listener)
  }, [type])
}

export function emit(type: string, ...args: any[]) {
  Event.emit(type, ...args)
}
