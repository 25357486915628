import {Model} from '@redux-model/web'
import {$api} from '@/store/service'

interface OmsModel {}

class Oms extends Model<OmsModel> {
  protected initReducer(): OmsModel {
    return {}
  }

  // 公司详情  |  object:{companyId:公司ID}
  omsCompanyGet = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<RpcOmsCompanyVO>>('/oms/company/get').body(args)
  )

  // 新增公司
  omsCompanyInsert = $api.action((args: RpcOmsCompanyInsertDTO) =>
    this.post<ApiResponse<string>>('/oms/company/insert').body(args)
  )

  // 公司列表
  omsCompanyList = $api.action((args: RpcOmsCompanyListDTO) =>
    this.post<ApiResponse<RpcOmsCompanyVO[]>>('/oms/company/list').body(args)
  )

  // 同步公司
  omsCompanySync = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<string>>('/oms/company/sync').body(args)
  )

  // 编辑公司
  omsCompanyUpdate = $api.action((args: RpcOmsCompanyUpdateDTO) =>
    this.post<ApiResponse<Record<string, any>>>('/oms/company/update').body(args)
  )

  // 公司合同详情  |  object:{contractId:合同ID}
  omsCompanyContractDetail = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<RpcOmsCompanyContractVO>>('/oms/company-contract/detail').body(args)
  )

  // 公司合同上所有可选择的权限
  omsCompanyContractGetPermissionTree = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<TreeModel[]>>('/oms/company-contract/get-permission-tree').body(args)
  )

  // 新增公司合同
  omsCompanyContractInsert = $api.action((args: RpcOmsCompanyContractInsertDTO) =>
    this.post<ApiResponse<string>>('/oms/company-contract/insert').body(args)
  )

  // 公司合同列表  |  object:{companyId:公司ID}
  omsCompanyContractListByCompanyId = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<RpcOmsCompanyContractVO[]>>('/oms/company-contract/list-by-companyId').body(args)
  )

  // 编辑公司合同权限
  omsCompanyContractUpdatePermission = $api.action((args: RpcOmsCompanyContractUpdatePermissionDTO) =>
    this.post<ApiResponse<string>>('/oms/company-contract/update-permission').body(args)
  )
}

export const omsModel = new Oms()
