import {Model} from '@redux-model/web'
import {$api} from '@/store/service'

interface ProjectModel {}

class Project extends Model<ProjectModel> {
  protected initReducer(): ProjectModel {
    return {}
  }

  // 项目管理-节点管理-节点删除  |  object:{nodeCode:节点编号}
  projectAdminNodeDelete = $api.action((args: {nodeCode: string}) =>
    this.post<ApiResponse>('/project/admin/node/delete').body(args)
  )

  // 项目管理-节点管理-节点详情  |  object:{nodeCode:节点编号}
  projectAdminNodeInfo = $api.action((args: {nodeCode: string}) =>
    this.post<ApiResponse<ProjectNodeVO>>('/project/admin/node/info').body(args)
  )

  // 项目管理-节点管理-节点列表
  projectAdminNodeList = $api.action((args: ProjectAdminNodeListDTO) =>
    this.post<ApiResponse<ProjectNodeVO[]>>('/project/admin/node/list').body(args)
  )

  // 项目管理-节点管理-节点启用/暂停  |  object:{nodeCode:节点编号, nodeStatus:节点状态（project.nodeStatus）}
  projectAdminNodeStatus = $api.action((args: {nodeCode: string; nodeStatus: string}) =>
    this.post<ApiResponse>('/project/admin/node/status').body(args)
  )

  // 项目管理-节点管理-节点新增/更新
  projectAdminNodeUpsert = $api.action((args: ProjectNodeDTO) =>
    this.post<ApiResponse>('/project/admin/node/upsert').body(args)
  )

  // 项目管理-工程管理-工程详情  |  object:{workCode:工程编号}
  projectAdminWorkInfo = $api.action((args: {workCode: string}) =>
    this.post<ApiResponse<ProjectWorkVO>>('/project/admin/work/info').body(args)
  )

  // 项目管理-工程管理-工程列表
  projectAdminWorkList = $api.action((args: ProjectWorkListDTO) =>
    this.post<ApiResponse<ProjectWorkVO[]>>('/project/admin/work/list').body({
      pageNum: 1,
      pageSize: 10,
      pageable: true,
      ...args,
    })
  )

  // 项目管理-工程管理-工程节点-详情  |  object:{workNodeId:工程节点id}
  projectAdminWorkNodeInfo = $api.action((args: {workNodeId: string}) =>
    this.post<ApiResponse<ProjectWorkNodeVO>>('/project/admin/work/node/info').body(args)
  )

  // 项目管理-工程管理-工程节点-列表  |  object:{workCode:工程编号}
  projectAdminWorkNodeList = $api.action((args: {workCode: string}) =>
    this.post<ApiResponse<ProjectWorkNodeVO[]>>('/project/admin/work/node/list').body(args)
  )

  // 项目管理-工程管理-工程节点-移动  |  object:{workNodeId:工程节点id,up:布尔值（上移true、下移false）}
  projectAdminWorkNodeMove = $api.action((args: {workNodeId: string; up: boolean}) =>
    this.post<ApiResponse>('/project/admin/work/node/move').body(args)
  )

  // 项目管理-工程管理-工程节点-新增/更新
  projectAdminWorkNodeUpsert = $api.action((args: ProjectWorkNodeDTO) =>
    this.post<ApiResponse>('/project/admin/work/node/upsert').body(args)
  )

  // 项目管理-工程管理-工程新增/更新
  projectAdminWorkUpsert = $api.action((args: ProjectWorkDTO) =>
    this.post<ApiResponse>('/project/admin/work/upsert').body(args)
  )

  // 项目管理-工程管理-工程删除  |  object:{workCode:工程编号}
  projectAdminWorkDelete = $api.action((args: {workCode: string}) =>
    this.post<ApiResponse>('/project/admin/work/delete').body(args)
  )

  // 项目管理-工程管理-工程启用/禁用/作废  |  object:{workCode:工程编号, workStatus:工程状态（project.workStatus）}
  projectAdminWorkStatus = $api.action((args: {workCode: string; workStatus: string}) =>
    this.post<ApiResponse>('/project/admin/work/status').body(args)
  )

  // 项目管理-工程管理-工程节点-审核通过  |  object:{workHistoryId:工程节点历史id,workNodeStatus:工程状态（project.workNodeStatus）,auditRemark:驳回说明}
  projectAdminWorkNodeAudit = $api.action(
    (args: {workHistoryId: string; workNodeStatus: string; auditRemark?: string}) =>
      this.post<ApiResponse>('/project/admin/work/node/audit').body(args)
  )

  // 项目管理-工程管理-工程节点-人员列表  |  object:{workCode:工程编号, nodeCode:节点编号}
  projectAdminWorkNodeOperatorList = $api.action((args: {workCode: string; nodeCode: string}) =>
    this.post<ApiResponse<SysUserVO[]>>('/project/admin/work/node/operator-list').body(args)
  )

  // 项目管理-工程管理-工程节点-历史  |  object:{workNodeId:工程节点id}
  projectAdminWorkNodeHistory = $api.action((args: {workNodeId: string}) =>
    this.post<ApiResponse<ProjectWorkNodeHistoryVO[]>>('/project/admin/work/node/history').body(args)
  )

  // 项目管理-工程管理-工程节点-删除  |  object:{workNodeId:工程节点id}
  projectAdminWorkNodeDelete = $api.action((args: {workNodeId: string}) =>
    this.post<ApiResponse>('/project/admin/work/node/delete').body(args)
  )
}

export const projectModel = new Project()
