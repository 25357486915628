import React, {useCallback, useEffect, useState} from 'react'
import {Page} from '@/components/Page'
import commonStyle from '@/assets/styles/common.module.less'
import {
  Button,
  Col,
  DatePicker,
  Descriptions,
  Drawer,
  Form,
  Input,
  message,
  Modal,
  Radio,
  Row,
  Select,
  Space,
  Table,
} from 'antd'
import {stewardModel} from '@/store/models/Steward'
import {getPaginationProps} from '@/common/biz/getPaginationProps'
import DividerLine from '@/pages/property/component/DividerLine'
import locale from 'antd/es/date-picker/locale/zh_CN'
import moment from 'moment'
import {commonModel, useCodeSync} from '@/store/models/Common'
import {condoModel} from '@/store/models/Condo'
import {Breadcrumb} from '@/components/Breadcrumb'
import {sysModel} from '@/store/models/Sys'
import {usePermission} from '@/components/Permission'

const BEIKE_TOKEN = 'BEIKE_TOKEN'
const XIANGYU_TOKEN = 'XIANGYU_TOKEN'

export default function OpenAppService() {
  const orderStatusCode = useCodeSync('steward.orderStatus')

  const [auth] = usePermission()

  const [form] = Form.useForm()
  const [orderList, setOrderList] = useState<StewardOpenOrderVO[]>([])
  const [orderTotal, setOrderTotal] = useState(0)

  const [oForm] = Form.useForm()
  const [orderDetail, setOrderDetail] = useState({} as StewardOpenOrderVO)

  const queryStewardOpenOrderList = useCallback(() => {
    const formValues = form.getFieldsValue(true)
    stewardModel.stewardOpenOrderList(formValues).then(({response: {data, total}}) => {
      setOrderList(data)
      setOrderTotal(total)
    })
  }, [form])

  useEffect(() => {
    queryStewardOpenOrderList()
  }, [queryStewardOpenOrderList])

  // 城市列表
  const [cityList, setCityList] = useState<CityDataProps[]>([])

  useEffect(() => {
    commonModel.queryCityCode({type: '2', parentCode: null}).then(({response: {data}}) => {
      setCityList(data)
    })
  }, [])

  const [sysCity, setSysCity] = useState<Option[]>([])
  useEffect(() => {
    sysModel.sysConfigGet({k: 'CONFIG_SYS_CITY'}).then(({response: {data}}) => {
      setSysCity(data?.city)
    })
  }, [])

  const [orderId, setOrderId] = useState('')
  // 站点
  const [warehouseId, setWarehouseId] = useState('')
  const [warehouseList, setWarehouseList] = useState<StewardWarehouseListItemVO[]>([])
  // 管家
  const [stewardId, setStewardId] = useState('')
  const [stewardList, setStewardList] = useState<SysUserVO[]>([])
  // 服务单
  const [processingList, setProcessingList] = useState<StewardOrderQueryVO[]>([])

  const queryOrderListStewardByWarehouseId = warehouseId => {
    setWarehouseId(warehouseId)

    stewardModel.stewardOpenOrderListStewardByWarehouseId({warehouseId}).then(({response: {data}}) => {
      setStewardList(data)
      // 切换站点时清空 管家和服务单
      setStewardId('')
      setProcessingList([])
    })
  }

  const queryOrderProcessingList = stewardId => {
    setStewardId(stewardId)

    stewardModel
      .stewardOpenOrderListOrderProcessing({stewardId, orderId})
      .then(({response: {data}}) => setProcessingList(data))
  }

  const queryCondoList = useCallback(
    ({condoName, cityCode}: {condoName: string; cityCode: string}) => {
      condoModel
        .condoList({pageNum: 1, pageSize: 20, pageable: true, condoName, cityCode} as CondoInfoAdminQueryDTO)
        .then(({response: {data}}) =>
          oForm.setFieldsValue({_condoList: data.map(item => ({label: item.condoName, value: item.condoId}))})
        )
    },
    [oForm]
  )

  const queryCommunityList = useCallback(
    ({name, cityCode}: {name: string; cityCode: string}) => {
      sysModel.communityList({pageNum: 1, pageSize: 20, pageable: true, name, cityCode}).then(({response: {data}}) => {
        oForm.setFieldsValue({
          _communityList: data.map(item => ({label: item.name, value: item.code})),
        })
      })
    },
    [oForm]
  )

  const [tokenForm] = Form.useForm<{cookie: string; token: string; version: number}>()

  const orderLoading = stewardModel.stewardOpenOrderList.useLoading()
  const warehouseLoading = stewardModel.stewardOpenOrderListWarehouse.useLoading()
  const stewardLoading = stewardModel.stewardOpenOrderListStewardByWarehouseId.useLoading()
  const processLoading = stewardModel.stewardOpenOrderListOrderProcessing.useLoading()
  const condoLoading = condoModel.condoList.useLoading()
  const communityLoading = sysModel.communityList.useLoading()
  const configLoading = sysModel.sysConfigGet.useLoading()

  const configuration = (token: string, title: string) => {
    Modal.confirm({
      width: 800,
      icon: null,
      title,
      content: (
        <Form form={tokenForm} labelCol={{flex: '80px'}}>
          {token === BEIKE_TOKEN && (
            <Form.Item label={'token'} name={'token'} rules={[{required: true, message: 'token不能为空'}]}>
              <Input allowClear />
            </Form.Item>
          )}
          <Form.Item label={'cookie'} name={'cookie'} rules={[{required: true, message: 'cookie不能为空'}]}>
            <Input allowClear />
          </Form.Item>
          <Form.Item label={'version'} name={'version'}>
            <FormText />
          </Form.Item>
        </Form>
      ),
      onOk: async () => {
        await tokenForm.validateFields()
        const values = tokenForm.getFieldsValue(true)
        values.version++
        await sysModel.sysConfigSave({k: token, v: values})
        message.success('成功！')
      },
      afterClose: () => tokenForm.resetFields(),
    })
  }

  return (
    <Page
      breadcrumb={
        <Breadcrumb
          right={
            auth('002162002') && (
              <Space>
                <Button
                  loading={configLoading}
                  onClick={async () => {
                    const {
                      response: {data},
                    } = await sysModel.sysConfigGet({k: XIANGYU_TOKEN})

                    tokenForm.setFieldsValue({version: 1, ...(data || {})})

                    configuration(XIANGYU_TOKEN, '相寓同步配置')
                  }}
                >
                  相寓同步配置
                </Button>
                <Button
                  loading={configLoading}
                  onClick={async () => {
                    const {
                      response: {data},
                    } = await sysModel.sysConfigGet({k: BEIKE_TOKEN})

                    tokenForm.setFieldsValue({version: 1, ...(data || {})})

                    configuration(BEIKE_TOKEN, '贝壳同步配置')
                  }}
                >
                  贝壳同步配置
                </Button>
              </Space>
            )
          }
        />
      }
    >
      <div className={commonStyle.filterSection}>
        <Form
          form={form}
          labelAlign={'right'}
          labelCol={{flex: '100px'}}
          initialValues={{pageNum: 1, pageSize: 10, pageable: true}}
          onFinish={queryStewardOpenOrderList}
          onReset={queryStewardOpenOrderList}
        >
          <Row gutter={20}>
            <Col span={8}>
              <Form.Item name={'cityCode'} label={'城市'}>
                <Select
                  placeholder={'请选择'}
                  allowClear
                  fieldNames={{label: 'label', value: 'id'}}
                  options={sysCity}
                  showSearch
                  filterOption={(inputValue, option) => option.label?.includes(inputValue)}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={'提交日期'}>
                <DatePicker.RangePicker
                  allowClear
                  style={{width: '100%'}}
                  onChange={(_, dateString) => {
                    const [insertTimeBegin, insertTimeEnd] = dateString ?? []
                    form.setFieldsValue({
                      insertTimeBegin: insertTimeBegin ? insertTimeBegin + ' 00:00:00' : insertTimeBegin,
                      insertEndTime: insertTimeEnd ? insertTimeEnd + ' 23:59:59' : insertTimeEnd,
                    })
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={'服务项目'} name={'appName'}>
                <Input placeholder={'请输入'} allowClear />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={'服务房源'} name={'propertyAddr'}>
                <Input placeholder={'请输入'} allowClear />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={'下单人'} name={'registerUname'}>
                <Input placeholder={'请输入'} allowClear />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={'联系手机号'} name={'contactMobile'}>
                <Input placeholder={'请输入'} allowClear />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={'报修编号'} name={'orderId'}>
                <Input placeholder={'请输入'} allowClear />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={'对接方订单号'} name={'appOrderId'}>
                <Input placeholder={'请输入'} allowClear />
              </Form.Item>
            </Col>
          </Row>
          <Row justify={'end'}>
            <Space>
              <Button type={'primary'} htmlType={'submit'}>
                查询
              </Button>
              <Button htmlType={'reset'}>重置</Button>
            </Space>
          </Row>
        </Form>
      </div>

      <div className={commonStyle.filterSection}>
        <Table
          dataSource={orderList}
          loading={orderLoading}
          rowKey={'orderId'}
          columns={[
            {title: '报修编号', dataIndex: 'orderId'},
            {title: '对接方', dataIndex: 'appName'},
            {title: '对接方订单号', dataIndex: 'appOrderId'},
            {title: '提交时间', dataIndex: 'insertTime'},
            {title: '服务项目', dataIndex: 'serviceName'},
            {title: '服务房源', dataIndex: 'propertyAddr'},
            {title: '下单人', dataIndex: 'contactName'},
            {title: '联系手机号', dataIndex: 'contactMobile'},
            {
              title: '操作',
              fixed: 'right',
              dataIndex: 'orderId',
              render: (value, record) => {
                return (
                  <Button
                    type={'link'}
                    style={{paddingLeft: 0, paddingRight: 0}}
                    onClick={async () => {
                      setOrderDetail(record)
                      const {
                        response: {data},
                      } = await stewardModel.stewardOpenOrderListWarehouse({orderId: value})
                      if (data && data.length) {
                        setOrderId(record.orderId)
                        setWarehouseList(data)
                        queryOrderListStewardByWarehouseId(data[0].warehouseId)
                      }
                    }}
                  >
                    查看
                  </Button>
                )
              },
            },
          ]}
          scroll={{x: 'max-content'}}
          pagination={getPaginationProps(form, orderTotal)}
        />
      </div>

      <Drawer
        visible={!!orderDetail.appId}
        title={'服务单详情'}
        width={'75%'}
        onClose={() => {
          setOrderDetail({} as StewardOpenOrderVO)
        }}
      >
        <DividerLine
          title={'基本信息'}
          bottom={15}
          right={
            !orderDetail.serverId && (
              <Button
                type={'link'}
                onClick={() => {
                  Modal.confirm({
                    title: '接单',
                    icon: null,
                    centered: true,
                    afterClose: () => oForm.resetFields(),
                    onOk: async () => {
                      await oForm.validateFields()
                      const {
                        orderAppointTime,
                        serverId,
                        orderNotes,
                        condoId,
                        _condoList,
                        _communityList,
                        _type,
                      } = oForm.getFieldsValue(true)
                      if (moment(orderAppointTime).isBefore(moment())) {
                        message.warning('约定上门时间必须在当前时间以后！')
                        return Promise.reject()
                      }
                      const condoName =
                        _type === '1'
                          ? _condoList?.find(item => item.value === condoId)?.label
                          : _communityList?.find(item => item.value === condoId)?.label
                      await stewardModel.stewardOpenOrderTakeOrder({
                        condoId,
                        condoName,
                        orderAppointTime: moment(orderAppointTime).format('YYYY-MM-DD HH:mm:ss'),
                        serverId,
                        orderNotes,
                        orderId: orderDetail.orderId,
                      })
                      message.success('接单成功！')
                      setOrderDetail({} as StewardOpenOrderVO)
                      queryStewardOpenOrderList()
                    },
                    content: (
                      <Form form={oForm} labelAlign={'right'} labelCol={{flex: '110px'}}>
                        <Form.Item label={'匹配对象'} name={'_cityCode'}>
                          <Select
                            placeholder={'请选择城市'}
                            options={cityList}
                            fieldNames={{label: 'name', value: 'code'}}
                            onChange={() => {
                              oForm.setFieldsValue({_condoList: [], _communityList: [], condoId: null})
                            }}
                          />
                        </Form.Item>
                        <Form.Item label={' '} colon={false} name={'_type'}>
                          <Radio.Group
                            onChange={() => {
                              oForm.setFieldsValue({_condoList: [], _communityList: [], condoId: null})
                            }}
                          >
                            <Radio value={'1'}>公寓</Radio>
                            <Radio value={'2'}>小区</Radio>
                          </Radio.Group>
                        </Form.Item>
                        <Form.Item noStyle shouldUpdate>
                          {form1 => {
                            const _condoList = form1.getFieldValue('_condoList')
                            const _communityList = form1.getFieldValue('_communityList')
                            const _cityCode = form1.getFieldValue('_cityCode')
                            const _type = form1.getFieldValue('_type')

                            return (
                              <Form.Item label={' '} colon={false} name={'condoId'}>
                                {_type === '1' ? (
                                  <Select
                                    placeholder={'请输入小区/公寓名搜索'}
                                    options={_condoList}
                                    filterOption={false}
                                    showSearch
                                    onSearch={value => {
                                      if (_cityCode) queryCondoList({condoName: value, cityCode: _cityCode})
                                    }}
                                    loading={condoLoading}
                                  />
                                ) : (
                                  <Select
                                    placeholder={'请输入小区/公寓名搜索'}
                                    options={_communityList}
                                    filterOption={false}
                                    showSearch
                                    onSearch={value => {
                                      if (_cityCode) queryCommunityList({name: value, cityCode: _cityCode})
                                    }}
                                    loading={communityLoading}
                                  />
                                )}
                              </Form.Item>
                            )
                          }}
                        </Form.Item>
                        <Form.Item
                          label={'约定上门时间'}
                          name={'orderAppointTime'}
                          rules={[{required: true, message: '请选择约定上门时间'}]}
                        >
                          <DatePicker
                            style={{width: '100%'}}
                            locale={locale}
                            showTime={true}
                            disabledDate={value => moment().subtract(1, 'days').isAfter(value)}
                            onChange={async value => {
                              const {
                                response: {data},
                              } = await stewardModel.stewardOpenOrderListStewardWorking({
                                timeAppoint: value.format('YYYY-MM-DD HH:mm:ss'),
                              })
                              oForm.setFieldsValue({
                                serverList: data?.map(item => ({label: item.value, value: item.key})),
                              })
                            }}
                          />
                        </Form.Item>
                        <Form.Item noStyle shouldUpdate>
                          {form1 => {
                            const serverList = form1.getFieldValue('serverList') ?? []
                            return (
                              <Form.Item
                                label={'服务人员'}
                                name={'serverId'}
                                rules={[{required: true, message: '请选择服务人员'}]}
                              >
                                <Select
                                  placeholder={'请选择'}
                                  options={serverList}
                                  disabled={!serverList.length}
                                  showSearch
                                  optionFilterProp={'label'}
                                />
                              </Form.Item>
                            )
                          }}
                        </Form.Item>
                        <Form.Item label={'订单备注'} name={'orderNotes'}>
                          <Input.TextArea maxLength={200} placeholder={'请输入'} showCount />
                        </Form.Item>
                      </Form>
                    ),
                  })
                }}
              >
                接单
              </Button>
            )
          }
        />
        <Descriptions column={2}>
          <Descriptions.Item label={'提交时间'}>{orderDetail.insertTime}</Descriptions.Item>
          <Descriptions.Item label={'服务房源'}>{orderDetail.propertyAddr}</Descriptions.Item>
        </Descriptions>
        <DividerLine title={'管家约单信息'} top={25} bottom={15} />
        <div style={{display: 'flex', flexDirection: 'row'}}>
          <Table
            rowKey={'warehouseId'}
            loading={warehouseLoading}
            dataSource={warehouseList}
            columns={[
              {
                title: '站点',
                dataIndex: 'name',
                render: (value, record) => (
                  <span style={record.warehouseId === warehouseId ? {color: '#1677ff'} : {}}>{value}</span>
                ),
              },
            ]}
            pagination={false}
            onRow={record => {
              return {
                onClick: () => queryOrderListStewardByWarehouseId(record.warehouseId),
              }
            }}
          />
          <Table
            rowKey={'userId'}
            loading={stewardLoading}
            dataSource={stewardList}
            columns={[
              {
                title: '管家',
                dataIndex: 'name',
                render: (value, record) => (
                  <span style={record.userId === stewardId ? {color: '#1677ff'} : {}}>{value}</span>
                ),
              },
            ]}
            pagination={false}
            onRow={record => {
              return {
                onClick: () => queryOrderProcessingList(record.userId),
              }
            }}
          />
          <Table
            rowKey={'orderId'}
            loading={processLoading}
            dataSource={processingList}
            columns={[
              {
                title: '最新约定上门时间',
                dataIndex: 'timeAppoint',
                sorter: {
                  compare: (a, b) => new Date(a.timeAppoint).getTime() - new Date(b.timeAppoint).getTime(),
                },
              },
              {title: '服务地址', dataIndex: 'propertyAddr'},
              {
                title: '距离',
                dataIndex: 'distance',
                render: value => `${Number.isNaN(Number(value)) ? 0 : (Number(value) / 1000).toFixed(2)}km`,
                sorter: {
                  compare: (a, b) =>
                    !Number.isNaN(a.distance) && !Number.isNaN(b.distance)
                      ? Number(a.distance) - Number(b.distance)
                      : 0,
                },
              },
              {title: '状态', dataIndex: 'orderStatus', render: value => orderStatusCode[value]},
            ]}
            style={{flexGrow: 1}}
          />
        </div>
      </Drawer>
    </Page>
  )
}

function FormText(props: {value?: any}) {
  return <span>{props.value}</span>
}
