import React from 'react'
import {Form, Input, Select, DatePicker, Row, Col, InputProps, Space, Button} from 'antd'
import {RangePickerProps} from 'antd/es/date-picker'
import {FormProps} from 'antd/lib/form/Form'

type CleanBaseItemProps = {name: string; label: string}

type CleanInputItemProps = CleanBaseItemProps & {type: 'input'; placeholder: string} & Partial<InputProps>

type CleanSelectItemProps = CleanBaseItemProps & {
  type: 'select'
  placeholder: string
  options: {id: string; label: string}[]
  mode?: 'multiple' | 'tags'
}

type CleanRangePickerItemProps = CleanBaseItemProps & {type: 'range'} & Pick<RangePickerProps, 'onChange'>

type FilterProps = CleanInputItemProps | CleanSelectItemProps | CleanRangePickerItemProps

type CleanFilterProps = {form: FormProps; filter: FilterProps[]}

export default function CleanFilterComponent({filter, form}: CleanFilterProps) {
  return (
    <Form {...form}>
      <Row gutter={40}>
        {filter.map(item => {
          switch (item.type) {
            case 'input':
              return (
                <Col span={8} key={item.name}>
                  <Form.Item label={item.label} name={item.name}>
                    <Input placeholder={item.placeholder} allowClear />
                  </Form.Item>
                </Col>
              )
            case 'select':
              return (
                <Col span={8} key={item.name}>
                  <Form.Item label={item.label} name={item.name}>
                    <Select placeholder={item.placeholder} allowClear mode={item.mode}>
                      {item.options.map(option => (
                        <Select.Option key={option.id} value={option.id}>
                          {option.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              )
            case 'range':
              return (
                <Col span={8} key={item.name}>
                  <Form.Item label={item.label} name={item.name}>
                    <DatePicker.RangePicker allowClear format={'YYYY-MM-DD'} onChange={item.onChange} />
                  </Form.Item>
                </Col>
              )
          }
        })}
      </Row>
      <Row justify={'end'}>
        <Space>
          <Button type={'primary'} htmlType={'submit'} onClick={() => form.form.setFieldsValue({pageNum: 1})}>
            查询
          </Button>
          <Button htmlType={'reset'}>重置</Button>
        </Space>
      </Row>
    </Form>
  )
}
