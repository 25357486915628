import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {Breadcrumb} from '@/components/Breadcrumb'
import commonStyles from '@/assets/styles/common.module.less'
import {
  Button,
  Col,
  DatePicker,
  Descriptions,
  Drawer,
  Form,
  Input,
  message,
  Modal,
  Row,
  Select,
  Space,
  Table,
} from 'antd'
import {useHistory} from 'react-router'
import {stewardModel} from '@/store/models/Steward'
import styles from './index.module.less'
import {renderServer} from '@/utils/render'
import {serviceMap} from '@/pages/steward/OrderBatchAdd'
import {Permission, usePermission} from '@/components/Permission'
import {Page} from '@/components/Page'
import {useFocusEffect} from '@/common/hooks/useFocusEffect'
import {getPaginationProps} from '@/common/biz/getPaginationProps'
import {cleanServiceArr} from '@/utils/clean'
import {FORMAT_DATE_END, FORMAT_DATE_START} from '@/common/date'

const statusOptions = [
  {value: '1', label: '已创建'},
  {value: '2', label: '未创建'},
]

// 频次
const periodFreqMap = {
  '1': '每周1次',
  '2': '两周1次',
  '3': '每月1次',
  '4': '每月3次',
  '5': '每月8次',
  '6': '每天1次',
}

export default function OrderBatchList() {
  const [auth] = usePermission()
  const history = useHistory()

  const [form] = Form.useForm()
  const [dataSource, setDataSource] = useState<StewardOrderBatchVO[]>([])
  const [total, setTotal] = useState(dataSource.length)

  const [detail, setDetail] = useState({} as StewardOrderBatchVO)
  const [batchStatus, setBatchStatus] = useState<string>(undefined)
  const [searchValue, setSearchValue] = useState<string>('')
  const renderDetailList = useMemo(() => {
    if (!batchStatus && !searchValue) return detail.detailList
    if (!batchStatus && searchValue) return detail.detailList?.filter(value => value.propertyAddr.includes(searchValue))
    if (batchStatus === statusOptions[0].value && !searchValue) return detail.detailList?.filter(value => value.orderId)
    if (batchStatus === statusOptions[1].value && !searchValue)
      return detail.detailList?.filter(value => !value.orderId)
    if (batchStatus === statusOptions[0].value && searchValue)
      return detail.detailList
        ?.filter(value => value.orderId)
        ?.filter(value => value.propertyAddr.includes(searchValue))

    if (batchStatus === statusOptions[1].value && searchValue)
      return detail.detailList
        ?.filter(value => !value.orderId)
        ?.filter(value => value.propertyAddr.includes(searchValue))
  }, [batchStatus, detail.detailList, searchValue])

  const searchLoading = stewardModel.stewardOrderBatchList.useLoading()
  const search = useCallback(async () => {
    const args: StewardOrderBatchQueryDTO = form.getFieldsValue(true)
    const {data, total} = await stewardModel.stewardOrderBatchList(args).then(value => value.response)
    setDataSource(data)
    setTotal(total)
  }, [form])

  const [visible, setVisible] = useState(false)
  const [batchDetailId, setBatchDetailId] = useState('')

  useFocusEffect(() => {
    form.submit()
  })

  useEffect(() => {
    form.submit()
  }, [form])

  return (
    <Page
      breadcrumb={
        <Breadcrumb
          right={
            <Permission code={'003063002'}>
              <Button
                type={'primary'}
                onClick={() => {
                  history.push('/order-batch-list/add')
                }}
              >
                新建申请
              </Button>
            </Permission>
          }
        />
      }
    >
      <Form
        form={form}
        labelCol={{span: 5}}
        wrapperCol={{span: 13}} // 不用铺满，留点余地
        initialValues={{pageNum: 1, pageSize: 10, pageable: true}}
        onReset={search}
        onFinish={search}
      >
        <div className={commonStyles.filterSection}>
          <Row>
            <Col span={8}>
              <Form.Item label={'申请编号'} name={'batchId'}>
                <Input placeholder={'请输入'} allowClear />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                label={'申请时间'}
                wrapperCol={{span: 16}}
                name={'_insertTime'} // 添加name时为了触发重置功能
                // 因为不需要回显，所以不用 normalize
                getValueFromEvent={value => {
                  form.setFieldsValue({
                    insertTimeBegin: value?.[0]?.format(FORMAT_DATE_START),
                    insertTimeEnd: value?.[1]?.format(FORMAT_DATE_END),
                  })
                  return value
                }}
              >
                <DatePicker.RangePicker style={{width: '100%'}} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={'申请人'} name={'creatorName'}>
                <Input placeholder={'请输入'} allowClear />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={'服务项目'} name={'categoryName'}>
                <Input placeholder={'请输入'} allowClear />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={'客户名称'} name={'customerName'}>
                <Input placeholder={'请输入'} allowClear />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={'客户手机号'} name={'customerMobile'}>
                <Input placeholder={'请输入'} allowClear />
              </Form.Item>
            </Col>
          </Row>
          <Space style={{display: 'flex', justifyContent: 'flex-end'}}>
            <Button type={'primary'} htmlType={'submit'} onClick={() => form.setFieldsValue({pageNum: 1})}>
              查询
            </Button>
            <Button htmlType={'reset'}>重置</Button>
          </Space>
        </div>
      </Form>
      <div className={commonStyles.tableSection}>
        <Table
          loading={searchLoading}
          rowKey={'batchId'}
          dataSource={dataSource}
          columns={[
            {title: '申请编号', key: 'batchId', dataIndex: 'batchId'},
            {title: '申请时间', key: 'insertTime', dataIndex: 'insertTime'},
            {title: '申请人', key: 'creatorName', dataIndex: 'creatorName'},
            {title: '服务项目', render: (_, record) => renderServer.title(record)},
            {
              title: '客户',
              dataIndex: 'customerName',
              render: (value, record) => `${value}（${record.customerMobile}）`,
            },
            {title: '房源数', key: 'propertyNum', dataIndex: 'propertyNum'},
            ...(auth('001063002')
              ? [
                  {
                    title: '操作',
                    render: (_, record) => (
                      <Button
                        type={'link'}
                        style={{paddingLeft: 0}}
                        onClick={async () => {
                          const {data} = await stewardModel
                            .stewardOrderBatchDetail({batchId: record.batchId})
                            .then(value => value.response)
                          setDetail(data)
                        }}
                      >
                        查看
                      </Button>
                    ),
                  },
                ]
              : []),
          ]}
          pagination={getPaginationProps(form, total)}
        />
      </div>

      <Drawer
        title='申请详情'
        placement='right'
        width={'70%'}
        afterOpenChange={visible => {
          if (!visible) {
            setBatchStatus(undefined)
            setSearchValue('')
          }
        }}
        destroyOnClose
        onClose={() => setDetail({} as StewardOrderBatchVO)}
        open={!!detail.batchId}
      >
        <Descriptions title='基本信息' column={2} className={styles.descriptionsTitle}>
          <Descriptions.Item label='服务项目'>{renderServer.title(detail)}</Descriptions.Item>
          <Descriptions.Item label='客户名称'>{`${detail.customerName}（${detail.customerMobile}）`}</Descriptions.Item>
          <Descriptions.Item label='申请人'>{detail.creatorName}</Descriptions.Item>
          <Descriptions.Item label='申请时间'>{detail.insertTime}</Descriptions.Item>
          <Descriptions.Item label='服务房源数'>{detail.propertyNum}</Descriptions.Item>
          <Descriptions.Item label='频次'>{periodFreqMap[detail.periodFreq]}</Descriptions.Item>
          <Descriptions.Item label='周期'>
            {detail.periodFreq === '6'
              ? `${detail.periodTimes}天`
              : serviceMap[detail.periodFreq]?.find(value => value.id === detail.periodTimes)?.label}
          </Descriptions.Item>
          <Descriptions.Item label='首次服务时间'>{detail.periodBeginTime}</Descriptions.Item>
          <Descriptions.Item label='服务人员'>{detail.serverName}</Descriptions.Item>
          <Descriptions.Item label='服务联系手机号'>{detail.contactMobile}</Descriptions.Item>
          <Descriptions.Item label='备注'>{detail.orderDesc || '无'}</Descriptions.Item>
        </Descriptions>

        <Descriptions
          title='详细信息'
          column={2}
          className={styles.descriptionsTitle}
          extra={
            <Space>
              <Button
                type='link'
                onClick={() => {
                  Modal.confirm({
                    title: '提示',
                    icon: null,
                    centered: true,
                    content: <span>是否确认创建服务单？</span>,
                    onOk: async () => {
                      const resList = renderDetailList.filter(item => !item.orderId)
                      for (const value of resList) {
                        await stewardModel.stewardOrderBatchCreateOrder({batchDetailId: value.batchDetailId})
                      }
                      const {
                        response: {data},
                      } = await stewardModel.stewardOrderBatchDetail({batchId: detail.batchId})
                      setDetail(data)
                      message.success('操作成功！')
                    },
                  })
                }}
              >
                全部创建
              </Button>
              <Button type='link' onClick={() => setVisible(true)}>
                一键删除
              </Button>
            </Space>
          }
        />
        <Row>
          <Col>
            <Form.Item label={'房源'}>
              <Input placeholder='请输入' onChange={e => setSearchValue(e.target.value)} allowClear />
            </Form.Item>
          </Col>
          <Col span={8} offset={1}>
            <Form.Item label={'状态'}>
              <Select
                options={statusOptions}
                placeholder={'请选择'}
                allowClear
                onChange={(value: string) => setBatchStatus(value)}
              />
            </Form.Item>
          </Col>
        </Row>
        <Table
          rowKey={'batchDetailId'}
          dataSource={renderDetailList}
          columns={[
            {title: 'ID', key: 'batchDetailId', dataIndex: 'batchDetailId'},
            {title: '上门时间', key: 'timeAppoint', dataIndex: 'timeAppoint'},
            {title: '房源', key: 'propertyAddr', dataIndex: 'propertyAddr'},
            {title: '状态', render: (_, record) => statusOptions[record.orderId ? 0 : 1].label},
            {
              title: '操作',
              render: (_, record) => {
                if (record.orderId) {
                  if (!cleanServiceArr.map(item => item.id).includes(record.categoryThi)) {
                    return (
                      <Button
                        type={'link'}
                        style={{padding: 0}}
                        onClick={() => {
                          history.push('/steward/detail', {orderId: record.orderId})
                          setDetail({} as StewardOrderBatchVO)
                        }}
                      >
                        查看
                      </Button>
                    )
                  } else return null
                } else {
                  return (
                    <Space>
                      <Button
                        type='link'
                        style={{padding: 0}}
                        onClick={() =>
                          Modal.confirm({
                            title: '提示',
                            icon: null,
                            centered: true,
                            content: <span>是否确认创建服务单？</span>,
                            onOk: async () => {
                              await stewardModel.stewardOrderBatchCreateOrder({batchDetailId: record.batchDetailId})
                              const {
                                response: {data},
                              } = await stewardModel.stewardOrderBatchDetail({batchId: detail.batchId})
                              setDetail(data)
                              message.success('操作成功！')
                            },
                          })
                        }
                      >
                        创建
                      </Button>
                      <Button
                        type='link'
                        style={{padding: 0}}
                        onClick={() => {
                          setVisible(true)
                          setBatchDetailId(record.batchDetailId)
                        }}
                      >
                        删除
                      </Button>
                    </Space>
                  )
                }
              },
            },
          ]}
          pagination={{
            total: renderDetailList?.length,
            position: ['bottomCenter'],
            // current: form.getFieldValue('pageNum'),
            // pageSize: form.getFieldValue('pageSize'),
            // onChange: (pageNum, pageSize) => {
            //   console.log('pageNum, pageSize', pageNum, pageSize, typeof pageNum, typeof pageSize)
            //   form.setFieldsValue({pageNum, pageSize})
            //   search()
            // },
            showSizeChanger: true,
          }}
        />
      </Drawer>

      {/* 删除 */}
      <Modal
        title={'提示'}
        centered
        open={visible}
        onCancel={() => {
          setVisible(false)
        }}
        afterClose={() => {
          setBatchDetailId('')
        }}
        onOk={async () => {
          if (batchDetailId) {
            await stewardModel.stewardOrderBatchDeleteDetail({batchDetailId})
          } else {
            await stewardModel.stewardOrderBatchDelete({batchId: detail.batchId})
          }
          const {data} = await stewardModel
            .stewardOrderBatchDetail({batchId: detail.batchId})
            .then(value => value.response)
          setDetail(data)
          setVisible(false)
        }}
      >
        <span>是否确认删除？</span>
      </Modal>
    </Page>
  )
}
