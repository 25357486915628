import React, {useCallback, useEffect, useImperativeHandle, useState} from 'react'
import commonStyles from '@/assets/styles/common.module.less'
import {Button, Col, Form, Input, InputNumber, message, Modal, Row, Select, Space, Table, Tabs} from 'antd'
import {condoModel} from '@/store/models/Condo'
import {getPaginationProps} from '@/common/biz/getPaginationProps'
import {usePermission} from '@/components/Permission'
import {Album, UploadButton} from '@/components/Album'
import {getImg, downloadBlob} from '@/utils/util'
import {baseUrl} from '@/common/config'
import {objectToArray} from '@/store/models/Common'

interface PropertyManagementComponentProps {
  condoProjectId: string
}

// 由 occupancyStatus+emptyStatus 组合而成
const propertyStatus = {
  '11': '空净',
  '12': '空脏',
  '20': '自住',
  '30': '装修',
}
const propertyStatusArr = objectToArray(propertyStatus)
const _PropertyManagementComponent = ({condoProjectId}: PropertyManagementComponentProps, ref) => {
  const [auth] = usePermission()
  const [form] = Form.useForm()
  const [editPropertyForm] = Form.useForm()
  const [propertyList, setPropertyList] = useState<CondoProjectPropertyVO[]>([])
  const [propertyTotal, setPropertyTotal] = useState(0)
  const propertyLoading = condoModel.condoProjectPropertyList.useLoading()
  const [configPropertyList, setConfigPropertyList] = useState<CondoProjectPropertyVO[]>([])
  // 房源模板筛选list
  const [propertyTemplateList, setPropertyTemplateList] = useState<CondoProjectTemplateVO[]>([])
  const [readonly, setReadonly] = useState(false)
  const [editPropertyVisible, setEditPropertyVisible] = useState(false)
  // 公寓楼层高
  const [floors, setFloors] = useState([])

  const propertySearch = useCallback(() => {
    const formValues = form.getFieldsValue(true)
    condoModel.condoProjectPropertyList({...formValues, condoId: condoProjectId}).then(({response: {data, total}}) => {
      setPropertyList(data)
      setPropertyTotal(total)
    })
  }, [condoProjectId, form])

  useEffect(() => {
    if (condoProjectId && auth('001096001')) {
      propertySearch()
      condoModel
        .condoProjectTemplateList({pageable: false, condoProjectId, enable: '1'} as CondoProjectTemplateListDTO)
        .then(({response: {data}}) => {
          setPropertyTemplateList(data)
        })
    }
  }, [condoProjectId, propertySearch, auth])

  // ref操作
  useImperativeHandle(ref, () => ({
    exportProperty: () => {
      const formValues = form.getFieldsValue(true)
      downloadBlob({
        url: '/condo/project/property/list-excel',
        body: {...formValues, condoId: condoProjectId},
      })
    },
    loadProperty: () => form.submit(),
    upProperty: () => {
      if (!configPropertyList.length) return message.warning('请选择房源进行上架')
      if (!configPropertyList.every(item => item.propertyEnable === '0'))
        return message.warning('选择的房源有已上架的房源，无法进行上架！')
      Modal.confirm({
        title: '提示',
        icon: null,
        centered: true,
        content: '是否确认上架选择的房源？',
        onOk: async () => {
          await condoModel.condoProjectPropertyEnable({
            condoProjectId,
            propertyEnable: '1',
            propertyCodeList: configPropertyList.map(item => item.propertyCode),
          } as CondoProjectPropertyEnableDTO)
          form.submit()
          message.success('上架房源成功！')
          setConfigPropertyList([])
        },
      })
    },
    downProperty: () => {
      if (!configPropertyList.length) return message.warning('请选择房源进行下架')
      if (!configPropertyList.every(item => item.propertyEnable === '1'))
        return message.warning('选择的房源有已下架的房源，无法进行下架！')
      Modal.confirm({
        title: '提示',
        icon: null,
        centered: true,
        content: '是否确认下架选择的房源？',
        onOk: async () => {
          await condoModel.condoProjectPropertyEnable({
            condoProjectId,
            propertyEnable: '0',
            propertyCodeList: configPropertyList.map(item => item.propertyCode),
          } as CondoProjectPropertyEnableDTO)
          form.submit()
          message.success('下架房源成功！')
          setConfigPropertyList([])
        },
      })
    },
  }))

  return (
    <Form
      form={form}
      onFinish={propertySearch}
      onReset={propertySearch}
      initialValues={{propertyEnable: '', pageNum: 1, pageSize: 10}}
    >
      <div className={commonStyles.filterSection}>
        <Row>
          <Col>
            <Form.Item label={'房源状态'} shouldUpdate>
              {() => (
                <Space>
                  {[
                    {id: '', label: '全部'},
                    {id: '1', label: '已上架'},
                    {id: '0', label: '未上架'},
                  ].map(item => (
                    <Button
                      type={form.getFieldValue('propertyEnable') === item.id ? 'primary' : 'text'}
                      key={item.id}
                      onClick={() => form.setFieldsValue({propertyEnable: item.id})}
                    >
                      {item.label}
                    </Button>
                  ))}
                </Space>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={5}>
            <Form.Item label={'房号'} name={'title'}>
              <Input placeholder='请输入' allowClear />
            </Form.Item>
          </Col>
          <Col offset={1} span={5}>
            <Form.Item label={'楼层'} name={'floor'}>
              <InputNumber min={1} max={99} placeholder={'请输入'} style={{width: 180}} />
            </Form.Item>
          </Col>
          <Col offset={1} span={5}>
            <Form.Item label={'户型模板'} name={'templateId'}>
              <Select placeholder={'请选择'} allowClear>
                {propertyTemplateList.map(item => (
                  <Select.Option value={item.templateId} key={item.templateId}>
                    {item.templateName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row justify='end'>
          <Space>
            <Button type='primary' htmlType='submit' onClick={() => form.setFieldsValue({pageNum: 1})}>
              查询
            </Button>
            <Button htmlType={'reset'}>重置</Button>
          </Space>
        </Row>
      </div>
      <div className={commonStyles.filterSection}>
        <Table
          style={{marginBottom: 10}}
          rowKey={'propertyCode'}
          loading={propertyLoading}
          dataSource={propertyList}
          rowSelection={{
            selectedRowKeys: configPropertyList.map(item => item.propertyCode),
            onChange: (_, selectedRow) => {
              setConfigPropertyList(selectedRow)
            },
          }}
          columns={[
            {title: '状态', dataIndex: 'propertyEnable', render: text => (text === '1' ? '已上架' : '未上架')},
            {title: '房源标题', dataIndex: 'title'},
            {title: '楼层', dataIndex: 'floor'},
            {title: '状态', dataIndex: 'statusDesc'},
            {title: '租金', dataIndex: 'rentPrice'},
            {title: '物业费', dataIndex: 'propertyFee'},
            {title: '户型模板', dataIndex: 'templateName'},
            {
              title: '操作',
              render: record => (
                <Space>
                  {record.propertyEnable === '1' ? (
                    auth('001096002') && (
                      <Button
                        type='link'
                        style={{padding: 0}}
                        onClick={async () => {
                          const {
                            response: {data},
                          } = await condoModel.condoProjectPropertyInfo({propertyCode: record.propertyCode})
                          editPropertyForm.setFieldsValue(data)
                          // 拼房源状态字段的值
                          const mergeStatus = `${data.occupancyStatus || 0}${data.emptyStatus || 0}`
                          if (Object.keys(propertyStatus).includes(mergeStatus)) {
                            editPropertyForm.setFieldsValue({status: mergeStatus})
                          }
                          setEditPropertyVisible(true)
                          setReadonly(true)
                        }}
                      >
                        查看
                      </Button>
                    )
                  ) : (
                    <Space>
                      {auth('004096002') && (
                        <Button
                          type='link'
                          style={{padding: 0}}
                          onClick={() => {
                            Modal.confirm({
                              title: '提示',
                              icon: null,
                              centered: true,
                              content: '是否确认删除该房源？',
                              onOk: async () => {
                                await condoModel.condoProjectPropertyDelete({propertyCode: record.propertyCode})
                                form.submit()
                              },
                            })
                          }}
                        >
                          删除
                        </Button>
                      )}
                      {auth('002096002') && (
                        <Button
                          type='link'
                          style={{padding: 0}}
                          onClick={async () => {
                            const {
                              response: {data},
                            } = await condoModel.condoProjectPropertyInfo({propertyCode: record.propertyCode})
                            const {
                              response: {
                                data: {topFloor},
                              },
                            } = await condoModel.condoProjectInfo({condoProjectId})
                            setFloors(Array.from({length: topFloor}).map((_, index) => index + 1))

                            editPropertyForm.setFieldsValue(data)
                            // 拼房源状态字段的值
                            const mergeStatus = `${data.occupancyStatus || 0}${data.emptyStatus || 0}`
                            if (Object.keys(propertyStatus).includes(mergeStatus)) {
                              editPropertyForm.setFieldsValue({status: mergeStatus})
                            }
                            setEditPropertyVisible(true)
                          }}
                        >
                          编辑
                        </Button>
                      )}
                    </Space>
                  )}
                  <Button
                    type={'link'}
                    style={{padding: 0}}
                    onClick={async () => {
                      const {
                        response: {data, total},
                      } = await condoModel.condoProjectPropertyHistory({
                        propertyCode: record.propertyCode,
                        pageable: false,
                      } as CondoProjectPropertyHistoryListDTO)
                      Modal.info({
                        title: '历史记录',
                        centered: true,
                        icon: null,
                        width: 700,
                        cancelText: '关闭',
                        content: (
                          <Table
                            title={() => (
                              <span>
                                共查询到<span style={{color: '#00B7AE'}}>{total}</span>条数据
                              </span>
                            )}
                            rowKey={'historyId'}
                            columns={[
                              {title: '时间', dataIndex: 'insertTime'},
                              {title: '操作人', dataIndex: 'operatorName'},
                              {title: '事件', dataIndex: 'desc'},
                            ]}
                            dataSource={data}
                            pagination={{position: ['bottomCenter'], total}}
                          />
                        ),
                      })
                    }}
                  >
                    历史记录
                  </Button>
                </Space>
              ),
            },
          ]}
          pagination={getPaginationProps(form, propertyTotal)}
        />
      </div>

      {/* 编辑房间 */}
      <Modal
        visible={editPropertyVisible}
        centered
        width={550}
        footer={null}
        title={editPropertyForm.getFieldValue('title')}
        onCancel={() => setEditPropertyVisible(false)}
        destroyOnClose
        afterClose={() => {
          setReadonly(false)
          editPropertyForm.resetFields()
        }}
      >
        <Form
          form={editPropertyForm}
          onFinish={async () => {
            const {imgList, propertyCode, ...values} = editPropertyForm.getFieldsValue(true)
            await condoModel.condoProjectPropertyUpdate({...values, propertyCode})
            await condoModel.condoProjectPropertyUpdateImg({propertyCode, imgList: imgList ?? []})
            form.submit()
            setEditPropertyVisible(false)
            message.success('房源编辑成功！')
          }}
          wrapperCol={{span: 15}}
          labelAlign={'right'}
          labelCol={{span: 4}}
        >
          <Tabs>
            <Tabs.TabPane tab={'房源基本信息'} key={'1'}>
              <Form.Item label={'房间号'} name={'title'} rules={[{required: true, message: '请输入房间号'}]}>
                <Input placeholder='请输入' allowClear disabled={readonly} />
              </Form.Item>
              <Form.Item label={'所在楼层'} name={'floor'} rules={[{required: true, message: '请输入所在楼层'}]}>
                <Select placeholder={'请选择'} disabled={readonly} allowClear>
                  {floors.map(item => (
                    <Select.Option value={item} key={item}>
                      {item}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item label={'户型模板'} name={'templateId'} rules={[{required: true, message: '请选择户型模板'}]}>
                <Select placeholder={'请选择'} disabled={readonly} allowClear>
                  {propertyTemplateList.map(item => (
                    <Select.Option key={item.templateId} value={item.templateId}>
                      {item.templateName}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
              <Form.Item label={'租金'} required>
                <Space>
                  <Form.Item noStyle name={'rentPrice'} rules={[{required: true, message: '请输入租金'}]}>
                    <InputNumber disabled={readonly} min={10} max={99999} placeholder={'请输入'} />
                  </Form.Item>
                  <span>元</span>
                </Space>
              </Form.Item>
              <Form.Item label={'物业费'} required>
                <Space>
                  <Form.Item noStyle name={'propertyFee'} rules={[{required: true, message: '请输入物业费'}]}>
                    <InputNumber disabled={readonly} min={0} max={99999} placeholder={'请输入'} />
                  </Form.Item>
                  <span>元</span>
                </Space>
              </Form.Item>
              <Form.Item label={'房源状态'} shouldUpdate required>
                {f =>
                  f.getFieldValue('rentStatus') === '1' || f.getFieldValue('reserveStatus') === '1' ? (
                    f.getFieldValue('statusDesc')
                  ) : (
                    <Form.Item name={'status'} rules={[{required: true, message: '房源状态不能为空'}]}>
                      <Select disabled={readonly}>
                        {propertyStatusArr.map(value => (
                          <Select.Option key={value.id} value={value.id}>
                            {value.label}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  )
                }
              </Form.Item>
              <Form.Item label={'房源描述'} name={'desc'}>
                <Input.TextArea
                  disabled={readonly}
                  allowClear
                  rows={3}
                  maxLength={100}
                  placeholder={'这是一段房源描述的文字限定字数100字以内'}
                />
              </Form.Item>
            </Tabs.TabPane>
            <Tabs.TabPane tab={'照片'} key={'2'} forceRender>
              <Form.Item label={'模板照片'} shouldUpdate wrapperCol={{}}>
                {() => {
                  const imgList = editPropertyForm.getFieldValue('templateImgList') ?? []
                  return (
                    <Album
                      disabled
                      fileList={imgList
                        .filter(item => item.video === '0')
                        .map(item => ({...item, uid: item.imgId, url: getImg(item.imgUrl)}))}
                    >
                      {null}
                    </Album>
                  )
                }}
              </Form.Item>
              <Form.Item label={'模板视频'} shouldUpdate wrapperCol={{}}>
                {() => {
                  const imgList = editPropertyForm.getFieldValue('templateImgList') ?? []
                  return (
                    <Album
                      disabled
                      iconRender={() => (
                        <div style={{display: 'flex', alignItems: 'center', height: '100%', justifyContent: 'center'}}>
                          <video
                            src={getImg((imgList as PropertyImgVO[])?.filter(item => item.video === '1')[0]?.imgUrl)}
                          />
                        </div>
                      )}
                      fileList={imgList
                        .filter(item => item.video === '1')
                        .map(item => ({...item, uid: item.imgId, url: getImg(item.imgUrl)}))}
                    >
                      {null}
                    </Album>
                  )
                }}
              </Form.Item>

              <Form.Item label={'房源照片'} shouldUpdate wrapperCol={{}}>
                {() => {
                  const imgList = editPropertyForm.getFieldValue('imgList') ?? []
                  return (
                    <Album
                      disabled={readonly}
                      accept={'image/png, image/jpeg, image/jpg'}
                      action={baseUrl + '/condo/project/template/upload-file'}
                      data={{video: '0', type: '1'}}
                      limit={9}
                      fileList={imgList
                        .filter(item => item.video === '0')
                        .map(item => ({...item, uid: item.imgId, url: getImg(item.imgUrl)}))}
                      onDone={fileList => {
                        editPropertyForm.setFieldsValue({
                          imgList: [
                            ...imgList.filter(item => item.video === '1'),
                            ...fileList.map(item => ({imgId: item.imgId, imgUrl: item.imgUrl, video: '0'})),
                          ],
                        })
                      }}
                    >
                      {readonly || imgList.filter(item => item.video === '0').length === 9 ? null : <UploadButton />}
                    </Album>
                  )
                }}
              </Form.Item>
              <Form.Item label={'房源视频'} shouldUpdate wrapperCol={{}}>
                {() => {
                  const imgList = editPropertyForm.getFieldValue('imgList') ?? []
                  return (
                    <Album
                      disabled={readonly}
                      accept={'video/mp4'}
                      action={baseUrl + '/condo/project/template/upload-file'}
                      data={{video: '1', type: '1'}}
                      limit={2}
                      iconRender={() => (
                        <div style={{display: 'flex', alignItems: 'center', height: '100%', justifyContent: 'center'}}>
                          <video
                            src={getImg((imgList as PropertyImgVO[])?.filter(item => item.video === '1')[0]?.imgUrl)}
                          />
                        </div>
                      )}
                      fileList={imgList
                        .filter(item => item.video === '1')
                        .map(item => ({...item, uid: item.imgId, url: getImg(item.imgUrl)}))}
                      onDone={fileList => {
                        editPropertyForm.setFieldsValue({
                          imgList: [
                            ...imgList.filter(item => item.video === '0'),
                            ...fileList.map(item => ({imgId: item.imgId, imgUrl: item.imgUrl, video: '1'})),
                          ],
                        })
                      }}
                    >
                      {readonly || imgList.filter(item => item.video === '1').length === 2 ? null : (
                        <UploadButton title='上传视频' />
                      )}
                    </Album>
                  )
                }}
              </Form.Item>
            </Tabs.TabPane>
          </Tabs>
          <Row justify='end'>
            <Space>
              <Button onClick={() => setEditPropertyVisible(false)}>取消</Button>
              {!readonly && (
                <Button type={'primary'} htmlType={'submit'}>
                  保存并提交
                </Button>
              )}
            </Space>
          </Row>
        </Form>
      </Modal>
    </Form>
  )
}

const PropertyManagementComponent = React.forwardRef(_PropertyManagementComponent)

export default PropertyManagementComponent
