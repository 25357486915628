import React, {useCallback, useEffect, useState} from 'react'
import {Page} from '@/components/Page'
import commonStyles from '@/assets/styles/common.module.less'
import {
  Button,
  Col,
  DatePicker,
  Descriptions,
  Drawer,
  Form,
  Input,
  message,
  Modal,
  Radio,
  Row,
  Select,
  Space,
  Table,
} from 'antd'
import {getPaginationProps} from '@/common/biz/getPaginationProps'
import {useCodeArraySync, useCodeSync} from '@/store/models/Common'
import {zzajModel} from '@/store/models/Zzaj'
import {usePermission} from '@/components/Permission'
import {FORMAT_DATE_END, FORMAT_DATE_START} from '@/common/date'
import DividerLine from '@/pages/property/component/DividerLine'
import moment from 'moment'
import {sysUserModel} from '@/store/models/SysUser'
import useSorter from '@/common/lib/useSorter'
import {Album} from '@/components/Album'
import {getImg} from '@/utils/util'

export default function AnjuRepairList() {
  const [form] = Form.useForm()
  const sorter = useSorter(form)
  const [list, setList] = useState<StewardOpenOrderVO[]>([])
  const [total, setTotal] = useState(0)
  const loading = zzajModel.zzajOpenOrderList.useLoading()
  const [auth] = usePermission()
  const handleStatusArray = useCodeArraySync('handle.status')
  const handleStatus = useCodeSync('handle.status')
  const [detail, setDetail] = useState({} as StewardOpenOrderVO)
  const [cancelForm] = Form.useForm()
  const [handleForm] = Form.useForm()
  const [updateForm] = Form.useForm()
  const [userList, setUserList] = useState<SysUserVO[]>([])

  const search = useCallback(() => {
    const values = form.getFieldsValue(true)
    zzajModel.zzajOpenOrderList(values).then(({response: {data, total}}) => {
      setList(data)
      setTotal(total)
    })
  }, [form])

  async function searchDetail(orderId: string) {
    const {
      response: {data},
    } = await zzajModel.zzajOpenOrderGet({orderId})
    setDetail(data)
  }

  useEffect(() => {
    form.submit()

    sysUserModel
      .sysUserList({pageNum: 1, pageSize: 1, pageable: false, status: '1', roleId: 'ZZAJ_CFRY'} as SysListDTO)
      .then(({response: {data}}) => {
        setUserList(data)
      })
  }, [form])

  return (
    <Page>
      <div className={commonStyles.filterSection}>
        <Form
          form={form}
          initialValues={{pageNum: 1, pageSize: 10, pageable: true, appId: 'ZZAJ'}}
          onFinish={search}
          onReset={search}
        >
          <Row>
            <Col span={7}>
              <Form.Item label={'关键字'} name={'keyword1'}>
                <Input placeholder={'申报人/服务联系手机号/房源搜索'} allowClear />
              </Form.Item>
            </Col>
            <Col span={7} offset={1}>
              <Form.Item label={'租客手机号'} name={'lesseeMobile'}>
                <Input placeholder={'请输入'} allowClear />
              </Form.Item>
            </Col>
            <Col span={7} offset={1}>
              <Form.Item label={'申报日期'} name={'_timeMake'}>
                <DatePicker.RangePicker
                  onChange={value => {
                    form.setFieldsValue({
                      insertTimeBegin: value?.[0]?.format?.(FORMAT_DATE_START),
                      insertTimeEnd: value?.[1]?.format?.(FORMAT_DATE_END),
                    })
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item label={'确认状态'} name={'handleStatus'}>
                <Select
                  options={handleStatusArray}
                  fieldNames={{value: 'id', label: 'label'}}
                  placeholder={'请选择'}
                  allowClear
                />
              </Form.Item>
            </Col>
            <Col span={7} offset={1}>
              <Form.Item label={'处理日期'} name={'_timeFinished'}>
                <DatePicker.RangePicker
                  onChange={value => {
                    form.setFieldsValue({
                      handleTimeBegin: value?.[0]?.format?.(FORMAT_DATE_START),
                      handleTimeEnd: value?.[1]?.format?.(FORMAT_DATE_END),
                    })
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row justify={'end'}>
            <Space>
              <Button htmlType={'submit'} type={'primary'} onClick={() => form.setFieldsValue({pageNum: 1})}>
                查询
              </Button>
              <Button htmlType={'reset'}>重置</Button>
            </Space>
          </Row>
        </Form>
      </div>
      <div className={commonStyles.filterSection}>
        <Table
          rowKey={'orderId'}
          loading={loading}
          dataSource={list}
          {...sorter}
          scroll={{x: 'max-content'}}
          columns={[
            {title: '编号', dataIndex: 'orderId'},
            {title: '服务项目', dataIndex: 'serviceName'},
            {
              title: '申报人',
              dataIndex: 'orderCreatorName',
              render: (value, record) => (
                <Space direction={'vertical'}>
                  <span>{value || '/'}</span>
                  <span>{record.orderCreatorMobile}</span>
                </Space>
              ),
            },
            {title: '租客手机号', dataIndex: 'lesseeMobile'},
            {title: '服务联系手机号', dataIndex: 'contactMobile'},
            {title: '申报时间', dataIndex: 'insertTime', sorter: true},
            {title: '确认状态', dataIndex: 'handleStatus', render: value => handleStatus[value]},
            {title: '处理时间', dataIndex: 'handleTime', sorter: true, render: value => value ?? '/'},
            {title: '服务单号', dataIndex: 'orderId'},
            {title: '服务房源', dataIndex: 'propertyAddr'},
            {
              title: '操作',
              fixed: 'right',
              render: (_, {orderId}) => {
                return (
                  auth('001177018') && (
                    <Button
                      type={'link'}
                      onClick={async () => {
                        await searchDetail(orderId)
                      }}
                    >
                      查看
                    </Button>
                  )
                )
              },
            },
          ]}
          pagination={getPaginationProps(form, total)}
        />
      </div>

      <Drawer
        title={'申报详情'}
        open={!!detail.orderId}
        onClose={() => setDetail({} as StewardOpenOrderVO)}
        width={800}
      >
        <DividerLine
          title={'基本信息'}
          right={
            <Space size={0}>
              <Button
                type={'link'}
                onClick={async () => {
                  const {
                    response: {data, total},
                  } = await zzajModel.zzajQaPropertyGet({propertyCode: detail.propertyCode})
                  Modal.info({
                    title: '房源内质保项目',
                    icon: null,
                    centered: true,
                    okText: '关闭',
                    width: 600,
                    content: (
                      <Table
                        rowKey={'itemId'}
                        columns={[
                          {dataIndex: 'itemName', title: '质保项目'},
                          {dataIndex: 'itemId', title: '编号'},
                          {
                            dataIndex: 'itemServiceList',
                            title: '关联服务项目',
                            render: value => value.map(item => item.categoryThiName).join(','),
                          },
                          {
                            dataIndex: 'qaTimeBegin',
                            title: '质保起止时间',
                            render: (value, record) =>
                              `${moment(record.qaTimeBegin).format('YYYY-MM-DD')}至${moment(record.qaTimeEnd).format(
                                'YYYY-MM-DD'
                              )}`,
                          },
                        ]}
                        dataSource={data.propertyItemList}
                        pagination={{total, defaultCurrent: 1, defaultPageSize: 10, position: ['bottomCenter']}}
                      />
                    ),
                  })
                }}
              >
                查看房源内质保项目
              </Button>
              {detail.handleStatus === 0 && (
                <Button
                  type={'link'}
                  onClick={() => {
                    Modal.confirm({
                      title: '取消',
                      icon: null,
                      centered: true,
                      width: 500,
                      afterClose: () => cancelForm.resetFields(),
                      content: (
                        <Form form={cancelForm}>
                          <Form.Item
                            label={'取消原因'}
                            name={'notes'}
                            rules={[{required: true, message: '请输入取消原因'}]}
                          >
                            <Input.TextArea placeholder={'请输入'} rows={3} maxLength={200} showCount />
                          </Form.Item>
                        </Form>
                      ),
                      onOk: async () => {
                        await cancelForm.validateFields()
                        const {notes} = cancelForm.getFieldsValue(true)
                        await zzajModel.zzajOpenOrderCancel({orderId: detail.orderId, notes})
                        message.success('取消成功')
                        form.submit()
                        await searchDetail(detail.orderId)
                      },
                    })
                  }}
                >
                  取消
                </Button>
              )}
              {detail.handleStatus === 0 && (
                <Button
                  type={'link'}
                  onClick={async () => {
                    const {
                      response: {data: companyList},
                    } = await zzajModel.zzajQaPropertyCompanyList({
                      propertyCode: detail.propertyCode,
                    })

                    // 带服务id去查质保单位默认回显
                    const {
                      response: {data: defaultList},
                    } = await zzajModel.zzajQaPropertyCompanyList({
                      propertyCode: detail.propertyCode,
                      categoryThi: detail.serverId,
                    })
                    if (defaultList.length) {
                      const {companyId, companyUserList} = defaultList[0]
                      handleForm.setFieldsValue({companyUserList, handleCompanyId: companyId})
                    }
                    Modal.confirm({
                      title: '处理',
                      icon: null,
                      centered: true,
                      width: 600,
                      afterClose: () => {
                        handleForm.resetFields()
                      },
                      content: (
                        <Form form={handleForm} initialValues={{handleWayCode: '1'}}>
                          <Form.Item
                            label={'处理方式'}
                            name={'handleWayCode'}
                            rules={[{required: true, message: '请选择处理方式'}]}
                          >
                            <Radio.Group>
                              <Radio value={'1'}>牛油果管家处理</Radio>
                              <Radio value={'2'}>质保单位处理</Radio>
                              <Radio value={'3'}>诚服人员处理</Radio>
                            </Radio.Group>
                          </Form.Item>
                          <Form.Item noStyle shouldUpdate={(prev, next) => prev.handleWayCode !== next.handleWayCode}>
                            {f => {
                              const handleWayCode = f.getFieldValue('handleWayCode')
                              const companyUserList = f.getFieldValue('companyUserList')
                              return (
                                <>
                                  {handleWayCode === '1' && (
                                    <Form.Item
                                      label={'是否生成租客账单'}
                                      rules={[{required: true, message: '请选择'}]}
                                      name={'lesseeBillTag'}
                                    >
                                      <Radio.Group>
                                        <Radio value={1}>是</Radio>
                                        <Radio value={0}>否</Radio>
                                      </Radio.Group>
                                    </Form.Item>
                                  )}
                                  {handleWayCode === '2' && (
                                    <>
                                      <Form.Item
                                        label={'质保单位'}
                                        name={'handleCompanyId'}
                                        rules={[{required: true, message: '请选择质保单位'}]}
                                      >
                                        <Select
                                          options={companyList}
                                          fieldNames={{label: 'companyName', value: 'companyId'}}
                                          onChange={(value, option: any) =>
                                            f.setFieldsValue({companyUserList: option.companyUserList})
                                          }
                                          placeholder={'请选择'}
                                        />
                                      </Form.Item>
                                      <Form.Item
                                        label={'质保人员'}
                                        name={'serverId'}
                                        rules={[{required: true, message: '请选择质保人员'}]}
                                      >
                                        <Select
                                          options={companyUserList}
                                          fieldNames={{label: 'value', value: 'key'}}
                                          placeholder={'请选择'}
                                        />
                                      </Form.Item>
                                    </>
                                  )}
                                  {handleWayCode === '3' && (
                                    <Form.Item
                                      label={'诚服人员'}
                                      name={'userId'}
                                      rules={[{required: true, message: '请选择诚服人员'}]}
                                    >
                                      <Select
                                        options={userList}
                                        fieldNames={{label: 'name', value: 'userId'}}
                                        placeholder={'请选择'}
                                      />
                                    </Form.Item>
                                  )}
                                </>
                              )
                            }}
                          </Form.Item>
                        </Form>
                      ),
                      onOk: async () => {
                        await handleForm.validateFields()
                        const {
                          handleWayCode,
                          lesseeBillTag,
                          handleCompanyId,
                          serverId,
                          userId,
                        } = handleForm.getFieldsValue(true)
                        const params =
                          handleWayCode === '1'
                            ? {lesseeBillTag, handleWayCode}
                            : handleWayCode === '2'
                            ? {handleWayCode, lesseeBillTag: 0, handleCompanyId, serverId}
                            : {handleWayCode, lesseeBillTag: 0, serverId: userId}
                        await zzajModel.zzajOpenOrderHandle({
                          ...params,
                          orderId: detail.orderId,
                        } as StewardOpenOrderZzajTakeDTO)
                        message.success('处理成功！')
                        form.submit()
                        await searchDetail(detail.orderId)
                      },
                    })
                  }}
                >
                  处理
                </Button>
              )}
            </Space>
          }
        />
        <Descriptions column={2}>
          <Descriptions.Item label={'编号'}>{detail.orderId}</Descriptions.Item>
          <Descriptions.Item label={'服务项目'}>{detail.serviceName}</Descriptions.Item>
          <Descriptions.Item label={'申报人'}>{`${detail.orderCreatorName || '/'}（${
            detail.orderCreatorMobile
          }）`}</Descriptions.Item>
          <Descriptions.Item label={'租客手机号'}>{detail.lesseeMobile}</Descriptions.Item>
          <Descriptions.Item label={'服务联系手机号'}>{detail.contactMobile}</Descriptions.Item>
          <Descriptions.Item label={'申报时间'}>{detail.insertTime}</Descriptions.Item>
          <Descriptions.Item label={'确认状态'}>{handleStatus[detail.handleStatus]}</Descriptions.Item>
          <Descriptions.Item label={'故障现象'}>{detail.orderTags ?? '/'}</Descriptions.Item>
          <Descriptions.Item label={'服务房源'}>{detail.propertyAddr}</Descriptions.Item>
          <Descriptions.Item label={'需求描述'}>{detail.orderNotes ?? '/'}</Descriptions.Item>
          <Descriptions.Item label={'图片和视频'}>
            {detail.orderImgList?.length && (
              <Album disabled fileList={detail.orderImgList.map(item => ({url: getImg(item), uid: item}))}>
                {null}
              </Album>
            )}
          </Descriptions.Item>
        </Descriptions>
        {detail.handleStatus === -1 && (
          <>
            <DividerLine title={'取消原因'} top={20} bottom={10} />
            <Descriptions column={2}>
              <Descriptions.Item label={'取消人'}>{detail.handleUname}</Descriptions.Item>
              <Descriptions.Item label={'取消时间'}>{detail.handleTime}</Descriptions.Item>
              <Descriptions.Item label={'取消原因'}>{detail.handleNotes}</Descriptions.Item>
            </Descriptions>
          </>
        )}
        {detail.handleStatus === 1 && (
          <>
            <DividerLine
              title={'处理结果'}
              top={20}
              bottom={10}
              right={
                detail.handleStatus === 1 &&
                detail.zzaj.handleWayCode === '1' && (
                  <Button
                    type={'link'}
                    onClick={() => {
                      updateForm.setFieldsValue({lesseeBillTag: detail.zzaj.lesseeBillTag})
                      Modal.confirm({
                        title: '修改是否生成租客账单',
                        icon: null,
                        centered: true,
                        width: 500,
                        afterClose: () => updateForm.resetFields(),
                        content: (
                          <Form form={updateForm}>
                            <Form.Item
                              label={'是否生成租客账单'}
                              name={'lesseeBillTag'}
                              rules={[{required: true, message: '请选择'}]}
                            >
                              <Radio.Group>
                                <Radio value={1}>是</Radio>
                                <Radio value={0}>否</Radio>
                              </Radio.Group>
                            </Form.Item>
                          </Form>
                        ),
                        onOk: async () => {
                          await updateForm.validateFields()
                          const lesseeBillTag = updateForm.getFieldValue('lesseeBillTag')
                          await zzajModel.zzajOpenOrderUpdateLesseeBillTag({lesseeBillTag, orderId: detail.orderId})
                          await searchDetail(detail.orderId)
                        },
                      })
                    }}
                  >
                    修改是否生成租客账单
                  </Button>
                )
              }
            />
            <Descriptions column={2}>
              <Descriptions.Item label={'处理人'}>{detail.handleUname}</Descriptions.Item>
              <Descriptions.Item label={'处理时间'}>{detail.handleTime}</Descriptions.Item>
              <Descriptions.Item label={'处理方式'}>
                {{'1': '牛油果管家处理', '2': '质保单位处理', '3': '诚服人员处理'}[detail.zzaj?.handleWayCode]}
              </Descriptions.Item>
              {detail.zzaj?.handleWayCode === '1' && (
                <Descriptions.Item label={'是否生成租客账单'}>
                  {detail.zzaj?.lesseeBillTag === 1 ? '是' : '否'}
                </Descriptions.Item>
              )}
              {detail.zzaj?.handleWayCode === '2' && (
                <>
                  <Descriptions.Item label={'质保单位'}>{detail.zzaj.handleCompanyName}</Descriptions.Item>
                  <Descriptions.Item label={'质保人员'}>{detail.serverName}</Descriptions.Item>
                </>
              )}
              {detail.zzaj?.handleWayCode === '3' && (
                <Descriptions.Item label={'诚服人员'}>{detail.serverName}</Descriptions.Item>
              )}
            </Descriptions>
          </>
        )}
      </Drawer>
    </Page>
  )
}
