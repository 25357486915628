import React, {useCallback, useEffect, useState} from 'react'
import commonStyles from '@/assets/styles/common.module.less'
import {Button, Col, DatePicker, Drawer, Form, Input, Row, Select, Space, Table} from 'antd'
import {logModel} from '@/store/models/Log'
import moment from 'moment'
import './index.less'
import {hasPermission} from '@/components/Permission'
import {Page} from '@/components/Page'
import {getPaginationProps} from '@/common/biz/getPaginationProps'

const {Option} = Select

const LogDetailTitle = React.memo(function (props: {title: string}) {
  const {title} = props
  return (
    <div className={'titleView'}>
      <div className={'titleLine'} />
      <span className={'titleSpan'}>{title}</span>
    </div>
  )
})

const LogDetailItem = React.memo(function (props: {label: string; value: string}) {
  const {label, value} = props
  return (
    <div className={'itemView'}>
      <span className={'itemLabel'}>{label}</span>
      <span className={'itemValue'}> {value ? value : '/'}</span>
    </div>
  )
})

function convertLogItem(str) {
  if (str) {
    const arr = str.split('、')
    const res = arr.map(item => (item ? item : '/'))
    return res.join('、')
  }
}

const opTypeArray = [
  {id: '管家分配', label: '管家分配'},
  {id: '换绑租客房源', label: '换绑租客房源'},
]
const opObjArray = [
  {id: '客户', label: '客户'},
  {id: '房源', label: '房源'},
  {id: '租客', label: '租客'},
  {id: '站点', label: '站点'},
  {id: '服务单', label: '服务单'},
]
const opPlatformArray = [
  {id: 'web', label: 'web'},
  {id: 'ios', label: 'ios'},
  {id: 'android', label: 'android'},
  {id: 'mini', label: 'mini'},
  {id: '系统', label: '系统'},
]
const opObjCode = {客户: '客户手机', 房源: '房源地址', 租客: '租客手机', 站点: '站点名称', 服务单: '服务单号'}

export default function LogList() {
  const [form] = Form.useForm()

  // const [logParams, setLogParams] = useState<Partial<LogDistributionQueryDTO>>({
  //   pageNum: 1,
  //   pageSize: 10,
  // })
  const [total, setTotal] = useState(0)
  const [logList, setLogList] = useState<LogDistributionVO[]>([])

  const tableLoading = logModel.logDistributionList.useLoading()

  const [visible, setVisible] = useState(false)
  const [curLog, setCurLog] = useState({} as LogDistributionVO)

  const search = useCallback(() => {
    const values = form.getFieldsValue(true)
    logModel.logDistributionList(values).then(({response: {data, total}}) => {
      setTotal(total)
      setLogList(data)
    })
  }, [form])

  useEffect(() => {
    form.submit()
  }, [form])

  const withDetailTitle = (props: {title: string; top: number}) => {
    const {top, ...res} = props
    return (
      <div style={{marginTop: `${top}px`}}>
        <LogDetailTitle {...res} />
      </div>
    )
  }

  const withDetailItem = (props: {items: {label: string; value: string}[]; top: number}) => {
    const {top = 0, items = []} = props
    return (
      <div style={{marginTop: `${top}px`}}>
        <Row>
          {items.map((item, index) => (
            <Col key={index} span={12}>
              <LogDetailItem {...item} />
            </Col>
          ))}
        </Row>
      </div>
    )
  }

  return (
    <Page>
      <div className={commonStyles.filterSection}>
        <Form
          form={form}
          labelCol={{span: 6}}
          labelAlign={'left'}
          initialValues={{pageNum: 1, pageSize: 10}}
          onFinish={search}
          onReset={search}
        >
          <Row>
            <Col span={7}>
              <Form.Item name={'logId'} label={'ID'}>
                <Input placeholder={'请输入日志ID'} allowClear />
              </Form.Item>
            </Col>
            <Col span={7} offset={1}>
              <Form.Item name={'opUserName'} label={'操作账号'}>
                <Input placeholder={'请输入操作帐号'} allowClear />
              </Form.Item>
            </Col>
            <Col span={7} offset={1}>
              <Form.Item name={'opName'} label={'操作人'}>
                <Input placeholder={'请输入操作人'} allowClear />
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item name={'opType'} label={'操作类型'}>
                <Select placeholder={'请选择'} allowClear>
                  {opTypeArray.map(item => (
                    <Option key={item.id} value={item.id}>
                      {item.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={7} offset={1}>
              <Form.Item name={'opTime'} label={'操作时间'}>
                <DatePicker.RangePicker
                  showTime={{
                    defaultValue: [moment('00:00:00', 'HH:mm:ss') as any, moment('23:59:59', 'HH:mm:ss') as any],
                  }}
                  placeholder={['开始时间', '结束时间']}
                  allowClear
                  onChange={(_, formatString) => {
                    form.setFieldsValue({
                      opTimeBegin: formatString?.[0],
                      opTimeEnd: formatString?.[1],
                    })
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={7} offset={1}>
              <Form.Item name={'opObj'} label={'操作对象'}>
                <Select placeholder={'请选择'} allowClear>
                  {opObjArray.map(item => (
                    <Option key={item.id} value={item.id}>
                      {item.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={7}>
              <Form.Item name={'opPlatform'} label={'操作终端'}>
                <Select placeholder={'请选择'} allowClear>
                  {opPlatformArray.map(item => (
                    <Option key={item.id} value={item.id}>
                      {item.label}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={7} offset={1}>
              <Form.Item name={'desc'} label={'关键字'}>
                <Input placeholder={'服务单号/手机号/房源/站点'} allowClear />
              </Form.Item>
            </Col>
          </Row>
          <Row justify={'end'}>
            <Space>
              <Button type={'primary'} htmlType={'submit'} onClick={() => form.setFieldsValue({pageNum: 1})}>
                查询
              </Button>
              <Button htmlType={'reset'}>重置</Button>
            </Space>
          </Row>
        </Form>
      </div>

      <div className={commonStyles.tableSection}>
        <Table
          rowKey={'logId'}
          loading={tableLoading}
          columns={[
            {title: 'ID', dataIndex: 'logId', key: 'logId'},
            {title: '操作账号', dataIndex: 'opUserName', key: 'opUserName'},
            {title: '操作人', dataIndex: 'opName', key: 'opName'},
            {title: '操作类型', dataIndex: 'opType', key: 'opType'},
            {title: '操作对象', dataIndex: 'opObj', key: 'opObj'},
            {title: '操作时间', dataIndex: 'opTime', key: 'opTime'},
            {title: '操作终端', dataIndex: 'opPlatform', key: 'opPlatform'},
            {
              title: '操作',
              render: values =>
                hasPermission('001055002') && (
                  <Button
                    type={'link'}
                    style={{padding: 0}}
                    onClick={() => {
                      setVisible(true)
                      setCurLog(values)
                    }}
                  >
                    查看
                  </Button>
                ),
            },
          ]}
          scroll={{x: 'max-content'}}
          dataSource={logList}
          pagination={getPaginationProps(form, total)}
        />
      </div>

      <Drawer title={'日志详情'} width={700} placement={'right'} visible={visible} onClose={() => setVisible(false)}>
        <div className={'logSection'}>
          <LogDetailTitle title={'基本信息'} />
          {withDetailItem({
            items: [
              {label: '日志ID：', value: curLog.logId},
              {label: '操作人：', value: curLog.opName},
            ],
            top: 15,
          })}
          {withDetailItem({
            items: [
              {label: '操作类型：', value: curLog.opType},
              {label: '操作对象：', value: curLog.opObj},
            ],
            top: 10,
          })}
          {withDetailItem({
            items: [
              {label: '操作终端：', value: curLog.opPlatform},
              {label: `${opObjCode[curLog.opObj]}：`, value: curLog.desc},
            ],
            top: 10,
          })}
          {withDetailTitle({title: '操作详情', top: 30})}
          {withDetailItem({
            items: [
              {label: '操作时间：', value: curLog.opTime},
              {label: '操作内容：', value: curLog.opContent},
            ],
            top: 15,
          })}
          {withDetailItem({
            items: [
              {label: '操作前内容：', value: convertLogItem(curLog.opContentBefore)},
              {label: '操作后内容：', value: convertLogItem(curLog.opContentAfter)},
            ],
            top: 10,
          })}
        </div>
      </Drawer>
    </Page>
  )
}
