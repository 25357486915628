import React, {useCallback, useEffect, useState} from 'react'
import {Page} from '@/components/Page'
import {Button, Col, Form, Input, Row, Space, Table} from 'antd'
import commonStyles from '@/assets/styles/common.module.less'
import {Breadcrumb} from '@/components/Breadcrumb'
import {SwapRightOutlined} from '@ant-design/icons'
import {ebassModel} from '@/store/models/Ebaas'
import {useAppHistory} from '@/common/hooks/useRouter'
import ebaas from '@/store/models/Common/ebaasCodes'
import {arrayToObject} from '@/store/models/Common'

const storeStatus = arrayToObject(ebaas.storeStatus)
const toAliStatus = arrayToObject(ebaas.toAliStatus)

export default function StoreManagement() {
  const history = useAppHistory()
  const [form] = Form.useForm()
  const [list, setList] = useState<EbaasStoreVO[]>([])
  const [total, setTotal] = useState(0)
  const search = useCallback(() => {
    const values = form.getFieldsValue(true)
    ebassModel.ebaasStoreList(values).then(({response: {data, total}}) => {
      setTotal(total)
      setList(data)
    })
  }, [form])

  useEffect(() => {
    form.submit()
  }, [form])

  return (
    <Page
      breadcrumb={
        <Breadcrumb
          right={
            <Button type={'primary'} onClick={() => history.push('/store-manage/add')}>
              新增门店
            </Button>
          }
        />
      }
    >
      <div className={commonStyles.filterSection}>
        <Form form={form} onFinish={search} onReset={search}>
          <Row>
            <Col span={6}>
              <Form.Item label={'门店名称'} name={'storeName'}>
                <Input placeholder='请输入' allowClear />
              </Form.Item>
            </Col>
            <Col offset={1}>
              <Form.Item label={'房间数量'} shouldUpdate>
                {() => (
                  <Space>
                    <Form.Item style={{marginBottom: 0}} name={'propertyNumMin'}>
                      <Input placeholder='最小数量' allowClear />
                    </Form.Item>
                    <SwapRightOutlined style={{color: 'rgba(0, 0, 0, 0.25)'}} />
                    <Form.Item style={{marginBottom: 0}} name={'propertyNumMax'}>
                      <Input placeholder='最大数量' allowClear />
                    </Form.Item>
                  </Space>
                )}
              </Form.Item>
            </Col>
          </Row>
          <Row justify={'end'}>
            <Space>
              <Button htmlType={'reset'}>重置</Button>
              <Button type={'primary'} htmlType={'submit'} onClick={() => form.setFieldsValue({pageNum: 1})}>
                查询
              </Button>
            </Space>
          </Row>
        </Form>
      </div>
      <div className={commonStyles.tableSection}>
        <Table
          rowKey={'storeNo'}
          dataSource={list}
          columns={[
            {title: '门店名称', dataIndex: 'storeName'},
            {title: '房间数量（间）', dataIndex: 'propertyNum'},
            {title: '门店状态', dataIndex: 'storeStatus', render: status => storeStatus[status]},
            {title: '是否同步至天猫', dataIndex: 'isToAli', render: status => toAliStatus[status] ?? '/'},
            {title: '天猫更新时间', dataIndex: 'latestSyncTime', render: text => text ?? '/'},
            {
              title: '操作',
              render: record => (
                <Space>
                  <Button
                    style={{paddingLeft: 0, paddingRight: 0}}
                    type={'link'}
                    onClick={() => history.push({pathname: '/store-manage/info', state: {storeNo: record.storeNo}})}
                  >
                    查看
                  </Button>
                </Space>
              ),
            },
          ]}
          pagination={{total, position: ['bottomCenter']}}
        />
      </div>
    </Page>
  )
}
