import React, {useState} from 'react'
import {Button, DatePicker, Descriptions, Drawer, Form, Input, InputNumber, Modal, Radio, Select, Space} from 'antd'
import {useCodeArraySync, useCodeSync} from '@/store/models/Common'
import {stewardModel} from '@/store/models/Steward'
import {sysUserModel} from '@/store/models/SysUser'
import {getImg} from '@/utils/util'
import DividerLine from '@/pages/property/component/DividerLine'
import moment from 'moment'
import {FORMAT_DATE, FORMAT_DATE_START} from '@/common/date'
import locale from 'antd/es/date-picker/locale/zh_CN'
import GuanYuBindMobile from '@/pages/steward/components/GuanYuBindMobile'

// 租客满意度
export function useTenantSatisfaction() {
  const callStatusArr = useCodeArraySync('todo.call.status')
  const [form] = Form.useForm()

  return ({
    todoId,
    appId,
    onSuccess,
    detail,
  }: {
    todoId: string
    appId: string
    onSuccess: () => void
    detail?: SysTodoVO
  }) => {
    const {
      callStatus,
      repairCompleted,
      appointmentBeforeVisit,
      onTimeVisit,
      serviceAttitude,
      dissatisfactionReason,
      stewardEvaluation,
      finishNotes,
    } = detail ?? {}

    form.setFieldsValue({
      callStatus,
      repairCompleted,
      appointmentBeforeVisit,
      onTimeVisit,
      serviceAttitude,
      dissatisfactionReason,
      stewardEvaluation,
      finishNotes,
    })

    Modal.confirm({
      title: '填写处理结果',
      centered: true,
      icon: null,
      closable: true,
      width: '40%',
      afterClose: () => form.resetFields(),
      content: (
        <Form form={form} labelCol={{span: 6}}>
          <Form.Item name={'callStatus'} label={'拨打状态'} rules={[{required: true, message: '请选择拨打状态'}]}>
            <Select placeholder={'请选择'} options={callStatusArr.map(item => ({label: item.label, value: item.id}))} />
          </Form.Item>
          <Form.Item name={'repairCompleted'} label={'维修是否完成'}>
            <Radio.Group>
              <Radio value={1}>是</Radio>
              <Radio value={2}>否</Radio>
              <Radio value={3}>不记得</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item name={'appointmentBeforeVisit'} label={'上门前是否有预约'}>
            <Radio.Group>
              <Radio value={1}>是</Radio>
              <Radio value={2}>否</Radio>
              <Radio value={3}>不记得</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item name={'onTimeVisit'} label={'是否在约定时间上门'}>
            <Radio.Group>
              <Radio value={1}>是</Radio>
              <Radio value={2}>否</Radio>
              <Radio value={3}>不记得</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item noStyle shouldUpdate>
            {form1 => {
              const serviceAttitude = form1.getFieldValue('serviceAttitude')
              return (
                <>
                  <Form.Item name={'serviceAttitude'} label={'服务态度'}>
                    <Radio.Group
                      onChange={({target: {value}}) => {
                        if (value === '1') form1.setFieldsValue({dissatisfactionReason: ''})
                      }}
                    >
                      <Radio value={'1'}>满意</Radio>
                      <Radio value={'2'}>不满意</Radio>
                    </Radio.Group>
                  </Form.Item>
                  {serviceAttitude === '2' ? (
                    <Form.Item name={'dissatisfactionReason'} colon={false} label={' '}>
                      <Input.TextArea placeholder={'请输入不满意原因'} showCount maxLength={200} />
                    </Form.Item>
                  ) : null}
                </>
              )
            }}
          </Form.Item>
          <Form.Item name={'stewardEvaluation'} label={'管家是否有好评要求'}>
            <Radio.Group>
              <Radio value={'1'}>是</Radio>
              <Radio value={'2'}>否</Radio>
              <Radio value={'3'}>不记得</Radio>
            </Radio.Group>
          </Form.Item>
          {appId === 'GUANYU' && (
            <Form.Item name={'toolOutfit'} label={'管家上门时是否穿着工装及佩戴工具箱'}>
              <Radio.Group>
                <Radio value={'1'}>是</Radio>
                <Radio value={'2'}>否</Radio>
                <Radio value={'3'}>不记得</Radio>
              </Radio.Group>
            </Form.Item>
          )}
          <Form.Item name={'finishNotes'} label={'备注'}>
            <Input.TextArea placeholder={'请输入'} showCount maxLength={200} />
          </Form.Item>
        </Form>
      ),
      onOk: async () => {
        await form.validateFields()
        const formValues = form.getFieldsValue(true)
        await stewardModel.stewardOrderTodoHandle({todoId, ...formValues})
        onSuccess?.()
      },
    })
  }
}

export function useLandlordSatisfaction() {
  const callStatusArr = useCodeArraySync('todo.call.status')
  const [form] = Form.useForm()

  return ({
    todoId,
    todoType,
    onSuccess,
    detail,
  }: {
    todoId: string
    todoType: string
    onSuccess: () => void
    detail?: SysTodoVO
  }) => {
    const {
      callStatus,
      stewardService,
      cleaningService,
      customerService,
      managerService,
      dissatisfactionReason,
      hasGroup,
      reminderMethod,
      finishNotes,
    } = detail ?? {}

    form.setFieldsValue({
      callStatus,
      stewardService,
      cleaningService,
      customerService,
      managerService,
      dissatisfactionReason,
      hasGroup,
      reminderMethod,
      finishNotes,
    })

    Modal.confirm({
      title: '填写处理结果',
      centered: true,
      icon: null,
      closable: true,
      width: '40%',
      afterClose: () => form.resetFields(),
      content: (
        <Form form={form} labelCol={{span: 6}}>
          <Form.Item name={'callStatus'} label={'拨打状态'} rules={[{required: true, message: '请选择拨打状态'}]}>
            <Select placeholder={'请选择'} options={callStatusArr.map(item => ({label: item.label, value: item.id}))} />
          </Form.Item>
          {['02', '04'].includes(todoType) && (
            <Form.Item name={'stewardService'} label={'管家服务'}>
              <Radio.Group>
                <Radio value={1}>是</Radio>
                <Radio value={2}>否</Radio>
              </Radio.Group>
            </Form.Item>
          )}
          {['03', '04'].includes(todoType) && (
            <Form.Item name={'cleaningService'} label={'保洁服务'}>
              <Radio.Group>
                <Radio value={1}>是</Radio>
                <Radio value={2}>否</Radio>
              </Radio.Group>
            </Form.Item>
          )}
          <Form.Item name={'customerService'} label={'客服服务'}>
            <Radio.Group>
              <Radio value={1}>是</Radio>
              <Radio value={2}>否</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item name={'managerService'} label={'客户经理服务'}>
            <Radio.Group>
              <Radio value={1}>是</Radio>
              <Radio value={2}>否</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item name={'dissatisfactionReason'} label={'不满意原因'}>
            <Input.TextArea placeholder={'请输入不满意原因'} showCount maxLength={200} />
          </Form.Item>
          <Form.Item name={'hasGroup'} label={'是否有群'}>
            <Radio.Group>
              <Radio value={1}>是</Radio>
              <Radio value={2}>否</Radio>
            </Radio.Group>
          </Form.Item>
          {['02', '04'].includes(todoType) && (
            <Form.Item name={'reminderMethod'} label={'提醒方式'}>
              <Radio.Group>
                <Radio value={'1'}>电话</Radio>
                <Radio value={'2'}>企业微信</Radio>
              </Radio.Group>
            </Form.Item>
          )}
          <Form.Item name={'finishNotes'} label={'备注'}>
            <Input.TextArea placeholder={'请输入'} showCount maxLength={200} />
          </Form.Item>
        </Form>
      ),
      onOk: async () => {
        await form.validateFields()
        const formValues = form.getFieldsValue(true)
        await stewardModel.stewardOrderTodoHandle({todoId, ...formValues})
        onSuccess?.()
      },
    })
  }
}

// 二次改约
export function useChangeAppointment() {
  const callStatusArr = useCodeArraySync('todo.call.status')
  const responsibilityArr = useCodeArraySync('sys.todo.responsibility')
  const [form] = Form.useForm()

  return ({todoId, onSuccess, detail}: {todoId: string; onSuccess: () => void; detail?: SysTodoVO}) => {
    const {callStatus, repairCompleted, appointmentBeforeVisit, responsibility, finishNotes} = detail ?? {}

    form.setFieldsValue({callStatus, repairCompleted, appointmentBeforeVisit, responsibility, finishNotes})

    Modal.confirm({
      title: '填写处理结果',
      centered: true,
      icon: null,
      closable: true,
      width: '40%',
      afterClose: () => form.resetFields(),
      content: (
        <Form form={form} labelCol={{span: 6}}>
          <Form.Item name={'callStatus'} label={'拨打状态'} rules={[{required: true, message: '请选择拨打状态'}]}>
            <Select placeholder={'请选择'} options={callStatusArr.map(item => ({label: item.label, value: item.id}))} />
          </Form.Item>
          <Form.Item name={'repairCompleted'} label={'维修是否完成'}>
            <Radio.Group>
              <Radio value={1}>是</Radio>
              <Radio value={2}>否</Radio>
              <Radio value={3}>改约时间</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item name={'appointmentBeforeVisit'} label={'上门前是否有预约'}>
            <Radio.Group>
              <Radio value={1}>是</Radio>
              <Radio value={2}>否</Radio>
              <Radio value={3}>不记得</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item name={'responsibility'} label={'责任归属'}>
            <Select
              placeholder={'请选择'}
              options={responsibilityArr.map(item => ({label: item.label, value: item.id}))}
            />
          </Form.Item>
          <Form.Item name={'finishNotes'} label={'备注'}>
            <Input.TextArea placeholder={'请输入'} showCount maxLength={200} />
          </Form.Item>
        </Form>
      ),
      onOk: async () => {
        await form.validateFields()
        const formValues = form.getFieldsValue(true)
        await stewardModel.stewardOrderTodoHandle({todoId, ...formValues})
        onSuccess?.()
      },
    })
  }
}

export function useQC() {
  const [form] = Form.useForm()
  return ({
    todoId,
    relatedId,
    onSuccess,
    detail,
  }: {
    todoId: string
    relatedId: string
    onSuccess: () => void
    detail?: SysTodoVO
  }) => {
    const {hasIntro, hasSpecificAppointment, hasSpecificFaultInquiry, hasConclusion, finishNotes} = detail ?? {}

    form.setFieldsValue({hasIntro, hasSpecificAppointment, hasSpecificFaultInquiry, hasConclusion, finishNotes})

    Modal.confirm({
      title: (
        <Space size={15}>
          <span>填写处理结果</span>
          <Button
            type={'link'}
            onClick={async () => {
              const {
                response: {data},
              } = await sysUserModel.privateNumberFeeGet({id: relatedId})
              window.open(getImg(data.nyg8VoiceUrl), '_blank', '')
            }}
          >
            查看录音
          </Button>
        </Space>
      ),
      centered: true,
      icon: null,
      closable: true,
      width: '40%',
      afterClose: () => form.resetFields(),
      content: (
        <Form form={form} labelCol={{span: 10}}>
          <Form.Item name={'hasIntro'} label={'是否有开场白（自报家门）'}>
            <Radio.Group>
              <Radio value={1}>是</Radio>
              <Radio value={2}>否</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item name={'hasSpecificAppointment'} label={'是否预约具体时间（几点至几点）'}>
            <Radio.Group>
              <Radio value={1}>是</Radio>
              <Radio value={2}>否</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item name={'isGoodAttitude'} label={'服务态度是否良好'}>
            <Radio.Group>
              <Radio value={1}>是</Radio>
              <Radio value={2}>否</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item name={'hasSpecificFaultInquiry'} label={'是否询问具体故障'}>
            <Radio.Group>
              <Radio value={1}>是</Radio>
              <Radio value={2}>否</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item name={'hasConclusion'} label={'是否有结束语'}>
            <Radio.Group>
              <Radio value={1}>是</Radio>
              <Radio value={2}>否</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item name={'finishNotes'} label={'备注'}>
            <Input.TextArea placeholder={'请输入'} showCount maxLength={200} />
          </Form.Item>
        </Form>
      ),
      onOk: async () => {
        await form.validateFields()
        const formValues = form.getFieldsValue(true)
        await stewardModel.stewardOrderTodoHandle({todoId, ...formValues})
        onSuccess?.()
      },
    })
  }
}

export function useBillPending() {
  const [form] = Form.useForm()
  return ({todoId, onSuccess, detail}: {todoId: string; onSuccess: () => void; detail?: SysTodoVO}) => {
    const {reminderMethod, finishNotes} = detail ?? {}

    form.setFieldsValue({reminderMethod, finishNotes})
    Modal.confirm({
      title: '填写处理结果',
      centered: true,
      icon: null,
      closable: true,
      width: '40%',
      afterClose: () => form.resetFields(),
      content: (
        <Form form={form} labelCol={{span: 6}}>
          <Form.Item name={'reminderMethod'} label={'提醒方式'}>
            <Radio.Group>
              <Radio value={'1'}>电话提醒</Radio>
              <Radio value={'2'}>服务群提醒</Radio>
            </Radio.Group>
          </Form.Item>

          <Form.Item name={'finishNotes'} label={'备注'}>
            <Input.TextArea placeholder={'请输入'} showCount maxLength={200} />
          </Form.Item>
        </Form>
      ),
      onOk: async () => {
        await form.validateFields()
        const formValues = form.getFieldsValue(true)
        await stewardModel.stewardOrderTodoHandle({todoId, ...formValues})
        onSuccess?.()
      },
    })
  }
}

export function useConfirm() {
  const [form] = Form.useForm()
  return ({
    todoId,
    onSuccess,
    rentPrice,
    billBegin,
    billEnd,
    rentConfirmationStatus,
  }: {
    todoId: string
    rentPrice: number
    billBegin: string
    billEnd: string
    rentConfirmationStatus?: number
    onSuccess: () => void
  }) => {
    form.setFieldsValue({
      rentConfirmation: rentPrice,
      bill: [billBegin, billEnd].filter(Boolean).map(item => moment(item)),
      billBegin,
      billEnd,
      rentConfirmationStatus,
    })

    Modal.confirm({
      title: '确认信息',
      centered: true,
      icon: null,
      closable: true,
      width: '40%',
      afterClose: () => form.resetFields(),
      content: (
        <Form form={form} labelCol={{span: 6}}>
          <Form.Item name={'rentConfirmation'} label={'租金（元/月）'}>
            <InputNumber />
          </Form.Item>
          <Form.Item name={'bill'} label={'租期'}>
            <DatePicker.RangePicker
              locale={locale}
              format={FORMAT_DATE_START}
              onChange={(_, dateStrings) => {
                const [billBegin, billEnd] = dateStrings ?? []
                form.setFieldsValue({billBegin, billEnd})
              }}
            />
          </Form.Item>
          <Form.Item
            name={'rentConfirmationStatus'}
            label={'确认状态'}
            rules={[{required: true, message: '请选择确认状态'}]}
          >
            <Radio.Group>
              <Radio value={1}>未确认</Radio>
              <Radio value={2}>部分确认</Radio>
              <Radio value={3}>已确认</Radio>
            </Radio.Group>
          </Form.Item>
        </Form>
      ),
      onOk: async () => {
        await form.validateFields()
        const formValues = form.getFieldsValue(true)
        await stewardModel.stewardOrderTodoHandle({todoId, ...formValues})
        onSuccess?.()
      },
    })
  }
}

// 贝壳预联
export function useBeiKePreContact() {
  const callStatusArr = useCodeArraySync('todo.call.status')
  const [form] = Form.useForm()

  return ({todoId, onSuccess, detail}: {todoId: string; onSuccess: () => void; detail?: SysTodoVO}) => {
    const {callStatus, finishNotes} = detail ?? {}

    form.setFieldsValue({callStatus, finishNotes})

    Modal.confirm({
      title: '填写处理结果',
      centered: true,
      icon: null,
      closable: true,
      width: '40%',
      afterClose: () => form.resetFields(),
      content: (
        <Form form={form} labelCol={{span: 6}}>
          <Form.Item name={'callStatus'} label={'拨打状态'} rules={[{required: true, message: '请选择拨打状态'}]}>
            <Select placeholder={'请选择'} options={callStatusArr.map(item => ({label: item.label, value: item.id}))} />
          </Form.Item>
          <Form.Item name={'finishNotes'} label={'备注'}>
            <Input.TextArea placeholder={'请输入'} showCount maxLength={200} />
          </Form.Item>
        </Form>
      ),
      onOk: async () => {
        await form.validateFields()
        const formValues = form.getFieldsValue(true)
        await stewardModel.stewardOrderTodoHandle({todoId, ...formValues})
        onSuccess?.()
      },
    })
  }
}

const reminderMethodCode1 = {'1': '电话', '2': '企业微信'}
const reminderMethodCode2 = {'1': '电话提醒', '2': '服务群提醒'}
const completedCode = {'1': '是', '2': '否', '3': '不记得'}
const repairCompleted = {'1': '是', '2': '否', '3': '改约时间'}
const attitudeCode = {'1': '满意', '2': '不满意'}
const confirmationStatusCode = {'1': '未确认', '2': '部分确认', '3': '已确认'}

/** 注意注意注意：有些字段是复用的，改的时候要搜一下，看看是否有其他地方也用到了 */
export function TodoDetail({
  title,
  visible,
  onClose,
  todoDetails,
  qaOnlineRate,
  onSuccess,
}: {
  title: React.ReactNode
  visible: boolean
  onClose: () => void
  todoDetails: SysTodoVO
  qaOnlineRate?: number
  onSuccess?: () => void
}) {
  const todoStatusCode = useCodeSync('sys.todo.status')
  const callStatusCode = useCodeSync('todo.call.status')
  const responsibilityCode = useCodeSync('sys.todo.responsibility')
  const tenantSatisfaction = useTenantSatisfaction()
  const landlordSatisfaction = useLandlordSatisfaction()
  const changeAppointment = useChangeAppointment()
  const qc = useQC()
  const billPending = useBillPending()
  const confirm = useConfirm()

  const [mobileVisible, setMobileVisible] = useState(false)

  return (
    <Drawer title={title} width={700} open={visible} onClose={onClose}>
      <DividerLine
        title='基本信息'
        style={{marginBottom: 10}}
        right={
          ['2', '3'].includes(todoDetails.todoStatus) &&
          todoDetails.appId === 'GUANYU' &&
          todoDetails.publicTag === 0 && (
            <Button
              type={'link'}
              onClick={() => {
                setMobileVisible(true)
              }}
            >
              绑定号码
            </Button>
          )
        }
      />
      <Descriptions column={2}>
        {/* 租客满意度 / 龙湖租客满意度 */}
        {['01', '13'].includes(todoDetails.todoType) && (
          <>
            <Descriptions.Item label={'订单编号'}>{todoDetails.orderId}</Descriptions.Item>
            <Descriptions.Item label={'服务项目'}>{todoDetails.categoryName}</Descriptions.Item>
            {todoDetails.appId === 'GUANYU' ? (
              <Descriptions.Item label={'下单人姓名'}>{todoDetails.orderMakerName}</Descriptions.Item>
            ) : (
              <Descriptions.Item label={'租客姓名'}>{todoDetails.lesseeName}</Descriptions.Item>
            )}
            {todoDetails.appId === 'GUANYU' ? (
              <Descriptions.Item label={'门店电话'}>{todoDetails.contactMobile}</Descriptions.Item>
            ) : (
              <Descriptions.Item label={'租客手机号'}>{todoDetails.lesseeMobile}</Descriptions.Item>
            )}
            <Descriptions.Item label={'管家'}>{todoDetails.stewardName}</Descriptions.Item>
            <Descriptions.Item label={'房源地址'}>{todoDetails.propertyAddr}</Descriptions.Item>
            <Descriptions.Item label={'下单时间'}>{todoDetails.orderTime}</Descriptions.Item>
            <Descriptions.Item label={'最新约定上门时间'}>{todoDetails.timeAppointLatest}</Descriptions.Item>
            <Descriptions.Item label={'订单完成时间'}>{todoDetails.timeFinished}</Descriptions.Item>
            <Descriptions.Item label={'评分'}>{todoDetails.remarkScore}</Descriptions.Item>
          </>
        )}

        {/* 管家房东满意度 & 保洁房东满意度 & 保洁&管家房东满意度 */}
        {['02', '03', '04'].includes(todoDetails.todoType) && (
          <>
            <Descriptions.Item label={'客户姓名'}>{todoDetails.lessorName}</Descriptions.Item>
            <Descriptions.Item label={'客户手机号'}>{todoDetails.lessorMobile}</Descriptions.Item>
            {todoDetails.todoType !== '03' && (
              <Descriptions.Item label={'维修收费类型'}>{todoDetails.stewardFeeType}</Descriptions.Item>
            )}
            <Descriptions.Item label={'联系人姓名'}>{todoDetails.contactName}</Descriptions.Item>
            <Descriptions.Item label={'联系人手机号'}>{todoDetails.contactPhone}</Descriptions.Item>
          </>
        )}

        {/* 二次改约 / 龙湖二次改约 */}
        {['05', '14'].includes(todoDetails.todoType) && (
          <>
            <Descriptions.Item label={'订单编号'}>{todoDetails.orderId}</Descriptions.Item>
            <Descriptions.Item label={'服务项目'}>{todoDetails.categoryName}</Descriptions.Item>
            {todoDetails.appId === 'GUANYU' ? (
              <Descriptions.Item label={'门店电话'}>{todoDetails.contactMobile}</Descriptions.Item>
            ) : (
              <Descriptions.Item label={'下单人类型'}>
                {(todoDetails.orderMakerType === 'lessor' && '房东') ||
                  (todoDetails.orderMakerType === 'lessee' && '租客')}
              </Descriptions.Item>
            )}
            <Descriptions.Item label={'下单人姓名'}>{todoDetails.orderMakerName}</Descriptions.Item>
            {todoDetails.appId !== 'GUANYU' && (
              <Descriptions.Item label={'服务联系手机号'}>{todoDetails.contactMobile}</Descriptions.Item>
            )}
            <Descriptions.Item label={'管家'}>{todoDetails.stewardName}</Descriptions.Item>
            <Descriptions.Item label={'房源地址'}>{todoDetails.propertyAddr}</Descriptions.Item>
            <Descriptions.Item label={'下单时间'}>{todoDetails.orderTime}</Descriptions.Item>
            <Descriptions.Item label={'约定上门修改次数'}>{todoDetails.appointTimes}</Descriptions.Item>
            <Descriptions.Item label={'操作原因'}>{todoDetails.reassignReason}</Descriptions.Item>
          </>
        )}

        {/* QC监听 */}
        {todoDetails.todoType === '08' && (
          <>
            <Descriptions.Item label={'订单编号'}>{todoDetails.orderId}</Descriptions.Item>
            <Descriptions.Item label={'服务项目'}>{todoDetails.categoryName}</Descriptions.Item>
            <Descriptions.Item label={'管家'}>{todoDetails.stewardName}</Descriptions.Item>
            <Descriptions.Item label={'房源地址'}>{todoDetails.propertyAddr}</Descriptions.Item>
            <Descriptions.Item label={'下单时间'}>{todoDetails.orderTime}</Descriptions.Item>
          </>
        )}

        {/* 线上质检 */}
        {todoDetails.todoType === '09' && (
          <>
            <Descriptions.Item label={'订单编号'}>{todoDetails.orderId}</Descriptions.Item>
            <Descriptions.Item label={'服务项目'}>{todoDetails.categoryName}</Descriptions.Item>
            <Descriptions.Item label={'客户姓名'}>{todoDetails.lessorName}</Descriptions.Item>
            <Descriptions.Item label={'下单时间'}>{todoDetails.orderTime}</Descriptions.Item>
            <Descriptions.Item label={'需求描述'}>{todoDetails.orderDesc}</Descriptions.Item>
            <Descriptions.Item label={'客户特殊需求'}>{todoDetails.checkSpecialNotes}</Descriptions.Item>
            <Descriptions.Item label={'保洁备注'}>{todoDetails.finishNotes}</Descriptions.Item>
            <Descriptions.Item label={'合格率'}>
              {![null, undefined].includes(qaOnlineRate) && +qaOnlineRate * 100 + '%'}
            </Descriptions.Item>
          </>
        )}

        {/* 账单代缴 */}
        {todoDetails.todoType === '10' && (
          <>
            <Descriptions.Item label={'客户姓名'}>{todoDetails.lessorName}</Descriptions.Item>
            <Descriptions.Item label={'客户手机号'}>{todoDetails.lessorMobile}</Descriptions.Item>
            <Descriptions.Item label={'BD手机号'}>{todoDetails.salesMobile}</Descriptions.Item>
            <Descriptions.Item label={'BD姓名'}>{todoDetails.salesName}</Descriptions.Item>
            <Descriptions.Item label={'账单名称'}>{todoDetails.billName}</Descriptions.Item>
            <Descriptions.Item label={'账单周期'}>
              {[todoDetails.billBegin, todoDetails.billEnd]
                .filter(Boolean)
                .map(item => moment(item).format(FORMAT_DATE))
                .join('至')}
            </Descriptions.Item>
            <Descriptions.Item label={'应收金额'}>{todoDetails.billAmtTotal}</Descriptions.Item>
            <Descriptions.Item label={'应收款日'}>{todoDetails.billDate}</Descriptions.Item>
          </>
        )}

        {/* 余额不足 */}
        {todoDetails.todoType === '11' && (
          <>
            <Descriptions.Item label={'客户姓名'}>{todoDetails.lessorName}</Descriptions.Item>
            <Descriptions.Item label={'客户手机号'}>{todoDetails.lessorMobile}</Descriptions.Item>
            <Descriptions.Item label={'BD手机号'}>{todoDetails.salesMobile}</Descriptions.Item>
            <Descriptions.Item label={'BD姓名'}>{todoDetails.salesName}</Descriptions.Item>
            <Descriptions.Item label={'组织'}>{todoDetails.teamName}</Descriptions.Item>
            <Descriptions.Item label={'账户余额'}>{todoDetails.accountBalance}</Descriptions.Item>
          </>
        )}

        {/* 租客租约租金 */}
        {todoDetails.todoType === '12' && (
          <>
            <Descriptions.Item label={'租客姓名'}>{todoDetails.lesseeName}</Descriptions.Item>
            <Descriptions.Item label={'租客手机号'}>{todoDetails.lesseeMobile}</Descriptions.Item>
            <Descriptions.Item label={'房源地址'}>{todoDetails.propertyAddr}</Descriptions.Item>
            <Descriptions.Item label={'所属团队'}>{todoDetails.teamName}</Descriptions.Item>
            <Descriptions.Item label={'租金'}>{todoDetails.rentPrice}</Descriptions.Item>
            <Descriptions.Item label={'租期'}>
              {[todoDetails.contractBegin, todoDetails.contractEnd]
                .filter(Boolean)
                .map(item => moment(item).format(FORMAT_DATE))
                .join('至')}
            </Descriptions.Item>
          </>
        )}

        {/* 贝壳预联 */}
        {todoDetails.todoType === '15' && (
          <>
            <Descriptions.Item label={'订单编号'}>{todoDetails.orderId}</Descriptions.Item>
            <Descriptions.Item label={'渠道订单号'}>{todoDetails.appOrderId}</Descriptions.Item>
            <Descriptions.Item label={'服务项目'}>{todoDetails.categoryName}</Descriptions.Item>
            <Descriptions.Item label={'下单人姓名'}>{todoDetails.orderMakerName}</Descriptions.Item>
            <Descriptions.Item label={'服务联系手机号'}>{todoDetails.contactMobile}</Descriptions.Item>
            <Descriptions.Item label={'管家'}>{todoDetails.stewardName}</Descriptions.Item>
            <Descriptions.Item label={'房源地址'}>{todoDetails.propertyAddr}</Descriptions.Item>
          </>
        )}

        {todoDetails.todoType !== '09' && (
          <Descriptions.Item label={'创建时间'}>{todoDetails.insertTime}</Descriptions.Item>
        )}
      </Descriptions>

      <DividerLine title='处理信息' style={{marginBottom: 10}} />
      <Descriptions column={2}>
        <Descriptions.Item label={'处理状态'}>{todoStatusCode[todoDetails.todoStatus]}</Descriptions.Item>
        {['01', '02', '03', '04', '05', '12', '13', '14', '15'].includes(todoDetails.todoType) && (
          <Descriptions.Item label={'拨打次数'}>{todoDetails.callNum}</Descriptions.Item>
        )}
        <Descriptions.Item label={'处理客服'}>{todoDetails.serviceStaff}</Descriptions.Item>
        <Descriptions.Item label={'分配时间'}>{todoDetails.assignTime}</Descriptions.Item>
        <Descriptions.Item label={'处理完成时间'}>{todoDetails.finishTime}</Descriptions.Item>
      </Descriptions>

      <DividerLine
        title='处理结果'
        style={{marginBottom: 10}}
        right={
          todoDetails.todoType !== '09' &&
          todoDetails.todoStatus === '3' &&
          onSuccess && (
            <Button
              type={'link'}
              onClick={() => {
                const {todoType, todoId, relatedId, rentPrice, billBegin, billEnd, rentConfirmationStatus} = todoDetails

                if (todoType === '12') {
                  confirm({
                    todoId,
                    rentPrice,
                    billBegin,
                    billEnd,
                    rentConfirmationStatus,
                    onSuccess,
                  })
                }
                if (['01', '13'].includes(todoType)) {
                  tenantSatisfaction({todoId, appId: todoDetails.appId, onSuccess, detail: todoDetails})
                }
                if (['02', '03', '04'].includes(todoType)) {
                  landlordSatisfaction({todoId, todoType, onSuccess, detail: todoDetails})
                }
                if (['05', '14'].includes(todoType)) {
                  changeAppointment({todoId, onSuccess, detail: todoDetails})
                }
                if (todoType === '08') {
                  qc({todoId, relatedId, onSuccess, detail: todoDetails})
                }
                if (['10', '11'].includes(todoType)) {
                  billPending({todoId, onSuccess, detail: todoDetails})
                }
              }}
            >
              修改
            </Button>
          )
        }
      />
      <Descriptions column={2}>
        {/* 租客满意度 / 龙湖租客满意度 */}
        {['01', '13'].includes(todoDetails.todoType) && (
          <>
            <Descriptions.Item label={'拨打状态'}>{callStatusCode[todoDetails.callStatus]}</Descriptions.Item>
            <Descriptions.Item label={'维修是否完成'}>{completedCode[todoDetails.repairCompleted]}</Descriptions.Item>
            <Descriptions.Item label={'上门前是否有预约'}>
              {completedCode[todoDetails.appointmentBeforeVisit]}
            </Descriptions.Item>
            <Descriptions.Item label={'是否在约定时间上门'}>{completedCode[todoDetails.onTimeVisit]}</Descriptions.Item>
            <Descriptions.Item label={'服务态度'}>{attitudeCode[todoDetails.serviceAttitude]}</Descriptions.Item>
            <Descriptions.Item label={'不满意原因'}>{todoDetails.dissatisfactionReason}</Descriptions.Item>
            <Descriptions.Item label={'管家是否有好评要求'}>
              {completedCode[todoDetails.stewardEvaluation]}
            </Descriptions.Item>
          </>
        )}

        {/* 管家房东满意度 & 保洁房东满意度 & 保洁&管家房东满意度 */}
        {['02', '03', '04'].includes(todoDetails.todoType) && (
          <>
            <Descriptions.Item label={'拨打状态'}>{callStatusCode[todoDetails.callStatus]}</Descriptions.Item>
            {todoDetails.todoType !== '03' && (
              <Descriptions.Item label={'管家服务'}>{completedCode[todoDetails.stewardService]}</Descriptions.Item>
            )}
            {todoDetails.todoType !== '02' && (
              <Descriptions.Item label={'保洁服务'}>{completedCode[todoDetails.cleaningService]}</Descriptions.Item>
            )}
            <Descriptions.Item label={'客服服务'}>{completedCode[todoDetails.customerService]}</Descriptions.Item>
            <Descriptions.Item label={'客户经理服务'}>{completedCode[todoDetails.managerService]}</Descriptions.Item>
            <Descriptions.Item label={'不满意原因'}>{todoDetails.dissatisfactionReason}</Descriptions.Item>
            <Descriptions.Item label={'是否有群'}>{completedCode[todoDetails.hasGroup]}</Descriptions.Item>
            <Descriptions.Item label={'提醒方式'}>{reminderMethodCode1[todoDetails.reminderMethod]}</Descriptions.Item>
          </>
        )}

        {/* 二次改约 / 龙湖二次改约 */}
        {['05', '14'].includes(todoDetails.todoType) && (
          <>
            <Descriptions.Item label={'拨打状态'}>{callStatusCode[todoDetails.callStatus]}</Descriptions.Item>
            <Descriptions.Item label={'维修是否完成'}>{repairCompleted[todoDetails.repairCompleted]}</Descriptions.Item>
            <Descriptions.Item label={'上门前是否有预约'}>
              {completedCode[todoDetails.appointmentBeforeVisit]}
            </Descriptions.Item>
            <Descriptions.Item label={'责任归属'}>{responsibilityCode[todoDetails.responsibility]}</Descriptions.Item>
          </>
        )}

        {/* QC监听 */}
        {todoDetails.todoType === '08' && (
          <>
            <Descriptions.Item label={'是否有开场白（自报家门）'}>
              {completedCode[todoDetails.hasIntro]}
            </Descriptions.Item>
            <Descriptions.Item label={'是否预约具体时间（几点至几点间）'}>
              {completedCode[todoDetails.hasSpecificAppointment]}
            </Descriptions.Item>
            <Descriptions.Item label={'服务态度是否良好'}>
              {(todoDetails.isGoodAttitude === 1 && '是') || (todoDetails.isGoodAttitude === 2 && '否')}
            </Descriptions.Item>
            <Descriptions.Item label={'是否询问具体故障'}>
              {completedCode[todoDetails.hasSpecificFaultInquiry]}
            </Descriptions.Item>
            <Descriptions.Item label={'是否有结束语'}>{completedCode[todoDetails.hasConclusion]}</Descriptions.Item>
          </>
        )}

        {/* 账单代缴&余额不足 */}
        {['10', '11'].includes(todoDetails.todoType) && (
          <Descriptions.Item label={'提醒方式'}>{reminderMethodCode2[todoDetails.reminderMethod]}</Descriptions.Item>
        )}

        {/* 租客租约租金 */}
        {todoDetails.todoType === '12' && (
          <>
            <Descriptions.Item label={'确认状态'}>
              {confirmationStatusCode[todoDetails.rentConfirmationStatus]}
            </Descriptions.Item>
            <Descriptions.Item label={'确认租金'}>{todoDetails.rentConfirmation}</Descriptions.Item>
            <Descriptions.Item label={'确认租期'}>
              {[todoDetails.billBegin, todoDetails.billEnd]
                .filter(Boolean)
                .map(item => moment(item).format(FORMAT_DATE))
                .join('至')}
            </Descriptions.Item>
          </>
        )}

        {todoDetails.todoType === '15' && (
          <Descriptions.Item label={'拨打状态'}>{callStatusCode[todoDetails.callStatus]}</Descriptions.Item>
        )}

        {!['09', '12'].includes(todoDetails.todoType) && (
          <Descriptions.Item label={'备注'}>{todoDetails.finishNotes}</Descriptions.Item>
        )}

        {todoDetails.appId === 'GUANYU' && todoDetails.todoType === '13' && (
          <Descriptions.Item label={'管家上门时是否穿着工装及佩戴工具箱'}>
            {completedCode[todoDetails.toolOutfit]}
          </Descriptions.Item>
        )}
      </Descriptions>

      {todoDetails.todoStatus === '4' && (
        <>
          <DividerLine title='作废信息' style={{marginBottom: 10}} />
          <Descriptions column={2}>
            <Descriptions.Item label={'操作人'}>{todoDetails.cancelBy}</Descriptions.Item>
            <Descriptions.Item label={'操作时间'}>{todoDetails.cancelTime}</Descriptions.Item>
            <Descriptions.Item label={'作废原因'}>{todoDetails.cancelRemake}</Descriptions.Item>
          </Descriptions>
        </>
      )}

      {/* 绑定号码 */}
      <GuanYuBindMobile
        visible={mobileVisible}
        onCancel={() => setMobileVisible(false)}
        orderId={todoDetails.orderId}
      />
    </Drawer>
  )
}
