import {Model} from '@redux-model/web'
import {$api} from '@/store/service'

export interface ZzajModel {}

class Zzaj extends Model<ZzajModel> {
  protected initReducer(): ZzajModel {
    return null
  }

  // 获取自在安居相关配置
  zzajConfigGet = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<OpenZiZaiAnJuConfigVO>>('/zzaj/config/get').body(args)
  )

  // 更新自在安居相关配置
  zzajConfigSave = $api.action((args: OpenZiZaiAnJuConfigVO) =>
    this.post<ApiResponse<Record<string, any>>>('/zzaj/config/save').body(args)
  )

  // 删除质保单位  |  object:{companyId:质保单位id}}
  zzajQaCompanyDelete = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<Record<string, any>>>('/zzaj/qa-company/delete').body(args)
  )

  // 质保单位-列表
  zzajQaCompanyList = $api.action((args: ZzajQaCompanyListDTO) =>
    this.post<ApiResponse<ZzajQaCompanyListVO[]>>('/zzaj/qa-company/list').body(args)
  )

  // 新增或修改质保单位
  zzajQaCompanyUpset = $api.action((args: ZzajQaCompanyUpsetDTO) =>
    this.post<ApiResponse<string>>('/zzaj/qa-company/upset').body(args)
  )

  // 删除质保项目  |  object:{itemId:质保项目id}}
  zzajQaItemDelete = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<string>>('/zzaj/qa-item/delete').body(args)
  )

  // 质保项目-列表
  zzajQaItemList = $api.action((args: ZzajQaItemListDTO) =>
    this.post<ApiResponse<ZzajQaItemListVO[]>>('/zzaj/qa-item/list').body(args)
  )

  // 新增或修改质保项目
  zzajQaItemUpset = $api.action((args: ZzajQaItemUpsetDTO) =>
    this.post<ApiResponse<string>>('/zzaj/qa-item/upset').body(args)
  )

  // 质保房源-详情  |  object:{propertyCode:质保房源id}}
  zzajQaPropertyGet = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<ZzajQaPropertyDetailVO>>('/zzaj/qa-property/get').body(args)
  )

  // 质保房源-质保项-删除  |  object:{id:id}}
  zzajQaPropertyItemDelete = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<Record<string, any>>>('/zzaj/qa-property/item/delete').body(args)
  )
  // 质保房源-质保项-新增/修改
  zzajQaPropertyItemUpset = $api.action((args: ZzajQaPropertyUpsetDTO) =>
    this.post<ApiResponse<Record<string, any>>>('/zzaj/qa-property/item/upset').body(args)
  )

  // 质保房源-列表
  zzajQaPropertyList = $api.action((args: ZzajQaPropertyListDTO) =>
    this.post<ApiResponse<ZzajQaPropertyDetailVO[]>>('/zzaj/qa-property/list').body(args)
  )

  // 代替租客下单
  zzajOpenOrderInsert = $api.action((args: StewardOpenOrderZzajMakeDTO) =>
    this.post<ApiResponse<string>>('/zzaj/open-order/insert').body(args)
  )

  // 自在安居-服务数据统计(num1-num3代表质保维修、牛油果维修、质保转牛油果)  |  object:{beginTime:开始时间,endTime:结束时间}}
  zzajOpenOrderStatistics = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<Record<string, any>>>('/zzaj/open-order/statistics').body(args)
  )

  // 自在安居-项目-列表
  zzajProjectList = $api.action((args: ZzajProjectListDTO) =>
    this.post<ApiResponse<ZzajPropertyListVO[]>>('/zzaj/project/list').body(args)
  )

  // 自在安居-项目-新增城市服务人员
  zzajProjectUserInsert = $api.action((args: ZzajProjectUserInsertDTO) =>
    this.post<ApiResponse<Record<string, any>>>('/zzaj/project/user/insert').body(args)
  )

  // 安居报修列表
  zzajOpenOrderList = $api.action((args: StewardOpenOrderQueryDTO) =>
    this.post<ApiResponse<StewardOpenOrderVO[]>>('/zzaj/open-order/list').body(args)
  )

  // 安居报修详情  |  object:{orderId:服务单ID}
  zzajOpenOrderGet = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<StewardOpenOrderVO>>('/zzaj/open-order/get').body(args)
  )

  // 取消待确认记录  |  object:{orderId:服务单ID,notes:备注}
  zzajOpenOrderCancel = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<StewardOpenOrderVO>>('/zzaj/open-order/cancel').body(args)
  )

  // 质保房源-质保项关联服务  |  object:{propertyCode:质保房源code，categoryThi:服务类型3 id}
  zzajQaPropertyCompanyList = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<ZzajQaCompanyListVO[]>>('/zzaj/qa-property/company/list').body(args)
  )

  // 处理自在安居-待确认服务单
  zzajOpenOrderHandle = $api.action((args: StewardOpenOrderZzajTakeDTO) =>
    this.post<ApiResponse<Record<string, any>>>('/zzaj/open-order/handle').body(args)
  )

  // 处理自在安居-修改是否生成租约账单  |  object:{orderId:服务单ID,lesseeBillTag:是否生成租客账单}
  zzajOpenOrderUpdateLesseeBillTag = $api.action((args: Record<string, any>) =>
    this.post<ApiResponse<Record<string, any>>>('/zzaj/open-order/updateLesseeBillTag').body(args)
  )
}

export const zzajModel = new Zzaj()
